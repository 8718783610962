import "./DagStatus.scss";
import {
  BPMButton,
  FilterTableContainer,
  OverlayTrigger,
  Page,
  Spinner,
  Tooltip,
} from "../Components";
import { UsersLambdaFetch, awaitJSON, DagLambdaFetch } from "../utils/fetch-utils";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import { validDate } from "../utils/date-utils";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import * as uuid from "uuid";
import React, { useState, useEffect, useCallback } from "react";

const UPDATE_RESTART_STATUSES = 20 * 1000; // If any restarts are detected, check for new dag status
const agencies = ["tinuiti"];

const DagStatus = () => {
  const [dagStatusMap, setDagStatusMap] = useState();
  const [restarts, setRestarts] = useState({});
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const isNOC = useSelector(UserRedux.isNOCSelector);
  const role = useSelector(UserRedux.roleSelector);
  const setError = useSetError();

  const getDagStatus = useCallback(async () => {
    for (let agency of agencies) {
      try {
        let params = {
          agency,
        };
        const dagStatusResp = await UsersLambdaFetch("/dagStatus", {
          params,
        });
        const dagStatus = await awaitJSON(dagStatusResp);
        setDagStatusMap(dagStatus);
      } catch (e) {
        setError({
          message: `Failed to load dag status info: ${e.message}`,
          reportError: e,
        });
      }
    }
  }, [setError]);

  useEffect(() => {
    if ((isInternal || isNOC) && !dagStatusMap) {
      getDagStatus();
    }
  }, [dagStatusMap, getDagStatus, isInternal, isNOC]);

  setInterval(() => {
    if ((isInternal || isNOC) && !R.isEmpty(restarts)) {
      getDagStatus();
    }
  }, UPDATE_RESTART_STATUSES);

  const updateRestarts = useCallback(
    args => {
      try {
        setRestarts({
          [`${args.batch_job_name}_${args.company}_${args.dag}`]: args,
          ...restarts,
        });
      } catch (reportError) {
        setError({
          message: `Failed to update build info. Error: ${reportError.message}`,
          reportError,
        });
      }
    },
    [restarts, setError]
  );

  const restartBuild = useCallback(
    async args => {
      const { batch_job_name, company, dag, job_start_time } = args;
      updateRestarts({ batch_job_name, company, dag, job_start_time, restarting: true });
      try {
        const result = await DagLambdaFetch("/start", {
          method: "POST",
          body: JSON.stringify({
            dag,
            start_task_name: batch_job_name,
            retries: 0,
          }),
        });
        let parsedResult = await awaitJSON(result);
        if (!parsedResult.success) {
          setError({
            message: parsedResult.message,
          });
        }
      } catch (reportError) {
        setError({
          message: `Failed to restart build. Error: ${reportError.message}`,
          reportError,
        });
        updateRestarts({ batch_job_name, company, dag, restarting: false });
      }
    },
    [setError, updateRestarts]
  );

  const headers = R.concat(
    [
      {
        label: "Build",
        name: "batch_job_name",
        flex: 3,
        minFlexWidth: 300,
        renderer: data => (
          <a href={data.batch_log_url} target="_blank" rel="noopener noreferrer">
            {data.batch_job_name}
          </a>
        ),
      },
      {
        label: "Status",
        name: "status_batch",
        width: 80,
        renderer: data => data.status_batch,
      },
      {
        label: "Previous Builds",
        name: "previous_builds",
        width: 300,
        renderer: data => {
          return R.isNil(data) | R.isNil(data.previous_builds) ||
            R.isEmpty(data.previous_builds) ? (
            "N/A"
          ) : (
            <div>
              {R.map(build => {
                return (
                  <OverlayTrigger
                    key={`${uuid.v4()}`}
                    placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                    overlay={
                      <Tooltip id={`${uuid.v4()}`}>
                        Status: {build.status_batch}
                        <br />
                        Job End Time: {build.job_end_time}
                      </Tooltip>
                    }
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={build.batch_log_url}
                      className="previousBuild"
                    >
                      {build.status_batch}
                    </a>
                  </OverlayTrigger>
                );
              }, data.previous_builds)}
            </div>
          );
        },
      },
      {
        label: "Expected Completion (Local)",
        name: "lastmodified",
        width: 260,
        renderer: data => {
          if (
            data.status_batch === "SUCCEEDED" ||
            data.status_batch === "FAILED" ||
            !validDate(data.now) ||
            !data.total_expected_runtime
          ) {
            return "-";
          } else {
            return Dfns.format(
              "yyyy-MM-dd hh:mma",
              Dfns.addSeconds(data.total_expected_runtime, new Date(data.now))
            );
          }
        },
      },

      {
        label: "Job Start Time",
        name: "job_start_time",
        width: 200,
        flex: 1,
        renderer: data => {
          if (!data.job_start_time || !validDate(data.job_start_time) || !validDate(data.now)) {
            return "-";
          } else {
            return Dfns.format("yyyy-MM-dd hh:mma", new Date(data.job_start_time));
          }
        },
      },
      {
        label: "Job End Time",
        name: "job_end_time",
        width: 200,
        flex: 1,
        renderer: data => {
          if (!data.job_end_time || !validDate(data.job_end_time) || !validDate(data.now)) {
            return "-";
          } else {
            return Dfns.format("yyyy-MM-dd hh:mma", new Date(data.job_end_time));
          }
        },
      },
    ],
    role === 0 || isAdmin || isNOC
      ? [
          {
            label: "Restartable",
            name: "running",
            width: 140,
            renderer: data => {
              let newRestarts = restarts;
              const dagKey = `${data.batch_job_name}_${data.company}_${data.dag}`;
              const restartable =
                data.status_batch === "SUCCEEDED" || data.status_batch === "FAILED";
              const wasRestarted = !R.isNil(newRestarts[dagKey]);
              if (
                wasRestarted &&
                (!restartable || data.job_start_time !== newRestarts[dagKey].job_start_time)
              ) {
                delete newRestarts[dagKey];
                setRestarts(newRestarts);
              }
              let restartingStatus =
                wasRestarted && newRestarts[dagKey] ? newRestarts[dagKey].restarting : false;
              return data && restartable ? (
                <BPMButton
                  onClick={() => {
                    restartBuild(data);
                    getDagStatus();
                  }}
                >
                  {restartingStatus ? <Spinner /> : "Restart"}
                </BPMButton>
              ) : (
                <div></div>
              );
            },
          },
        ]
      : []
  );

  return (
    <Page title={"Dag Status"} pageType={"Dag Status"} minHeight={600}>
      <div className="dag-status">
        {(() => {
          if (dagStatusMap) {
            return (
              <div className="reconcile-body builds">
                <FilterTableContainer
                  data={dagStatusMap}
                  defaultTokens={{
                    advanced:
                      agencies && agencies.length === 1 && agencies[0] === "tinuiti"
                        ? ["Build", "is like", "MOBIUS"]
                        : ["Status", "is not like", "SUCCEEDED"],
                  }}
                  defaultAdvancedFilter
                  headers={headers}
                />
              </div>
            );
          } else {
            return <Spinner size={200} />;
          }
        })()}
      </div>
    </Page>
  );
};

export default DagStatus;
