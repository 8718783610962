import { CompanySpecificFilterRow } from "@blisspointmedia/bpm-types/dist/pgTables/CompanySpecificFilters";
import * as R from "ramda";

interface UpdatedCompanySpecificFilterCardProps {
  newRow: CompanySpecificFilterRow;
  oldRow: CompanySpecificFilterRow;
}

interface UpdatedArrayFieldProps {
  oldVals: any[];
  newVals: any[];
  title: string;
}

const UpdatedArrayField: React.FC<UpdatedArrayFieldProps> = ({ oldVals, newVals, title }) => {
  let allVals = R.union(oldVals, newVals);
  let arrayEquals = (a, b) => {
    let setA = new Set(a);
    let setB = new Set(b);
    let setEquals = (x, y) => x.size === y.size && [...x].every(value => y.has(value));
    return setEquals(setA, setB);
  };
  if (allVals.length && !arrayEquals(oldVals, newVals)) {
    return (
      <div className="changedBigField">
        <div className="fieldTitle">{title}</div>
        <div className="arrayRow">
          {allVals.map(contact => {
            if (oldVals.includes(contact) && newVals.includes(contact)) {
              return <div className="unchangedValue">{contact}</div>;
            } else if (oldVals.includes(contact) && !newVals.includes(contact)) {
              return <div className="oldVal">{contact}</div>;
            } else if (!oldVals.includes(contact) && newVals.includes(contact)) {
              return <div className="newVal">{contact}</div>;
            }
            return <div></div>;
          })}
        </div>
      </div>
    );
  } else if (allVals.length && arrayEquals(oldVals, newVals)) {
    return (
      <div className="unchangedBigField">
        <div className="fieldTitle">{title}</div>
        <div className="arrayRow">
          {allVals.map(contact => (
            <div className="unchangedValue">{contact}</div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="unchangedBigField">
        <div className="fieldTitle">{title}</div>
        <div className="arrayRow">
          <div className="unchangedValue">N/A</div>
        </div>
      </div>
    );
  }
};

const UpdatedCompanySpecificFilterCard: React.FC<UpdatedCompanySpecificFilterCardProps> = ({
  newRow,
  oldRow,
}) => {
  return (
    <div className="updatedCompanySpecificFilterCard">
      <div className="cardRow">
        <div className="cardTitle">{newRow.cid[0].toUpperCase() + newRow.cid.slice(1)}</div>
      </div>
      <div className="cardRow">
        <UpdatedArrayField
          title="Blocked Networks"
          oldVals={oldRow.blocked_networks}
          newVals={newRow.blocked_networks}
        ></UpdatedArrayField>
      </div>
      <div className="cardRow">
        <UpdatedArrayField
          title="Blocked ISCIs"
          oldVals={oldRow.blocked_iscis}
          newVals={newRow.blocked_iscis}
        ></UpdatedArrayField>
      </div>
    </div>
  );
};

export default UpdatedCompanySpecificFilterCard;
