import React, { useEffect, useRef, useMemo, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";

import * as R from "ramda";

import { Provider } from "react-redux";

import { Form } from "react-bootstrap";

import { DMATableData } from "@blisspointmedia/bpm-types/dist/Slides";

import { SetError } from "../redux/modals";
import { reduxStore } from "../redux";

import { DateRange } from "../utils/types";
import { convertSVGStringToPNGURI } from "../utils/download-utils";

import {
  KpiPicker,
  OldDropdown,
  DmaMap,
  MAP_ASPECT_RATIO,
  GeoData,
  useKpiPickerData,
  RelativeDatePicker,
} from "../Components";

import {
  SlideType,
  SettingsComponentProps,
  SlideContext,
  S3PromiseFunction,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  SharedStateFetcher,
} from "./slidesTypes";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";
import { LAG_INFO, useGeoData } from "../StreamingGeoResponse/StreamingGeoResponse";
import { parseInputToInt } from "../utils/data";
import { SharedState, SlideState } from "./slideTemplateConstants";
import {
  computeResolvedDate,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";

const FOOTNOTE_DATE_FORMAT = "M/d/yyyy";

type Lag = string;

interface SlideGeoChartResult {
  elem: SVGSVGElement | null;
  top: DMATableData[];
  bottom: DMATableData[];
}

interface SlideGeoChartOnLoad {
  (results: SlideGeoChartResult): void;
}

interface SlideGeoChartProps {
  lag: Lag;
  dates: DateRange;
  kpi: string;
  topNumber: number;
  bottomNumber: number;
  onLoad: SlideGeoChartOnLoad;
}

const MAP_WIDTH = 1000;

const SlideGeoChart: React.FC<SlideGeoChartProps> = ({
  lag,
  dates,
  kpi,
  topNumber,
  bottomNumber,
  onLoad,
}) => {
  const { geoData, renderColor } = useGeoData(
    { startDate: dates.start, endDate: dates.end },
    "streaming",
    kpi,
    lag
  );

  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  const [top, bottom] = useMemo<[] | [DMATableData[], DMATableData[]]>(() => {
    if (!(geoData && renderColor)) {
      return [];
    }
    let geoRows = R.values(geoData);
    let sortedGeoRows = R.pipe<GeoData[], GeoData[], GeoData[]>(
      R.filter(({ households }: GeoData) => households > 500000),
      R.sort(R.descend(R.prop("index")))
    )(geoRows);
    let top = R.take(topNumber, sortedGeoRows);
    let bottom = R.takeLast(bottomNumber, sortedGeoRows);
    let transform = ({ name, index, households, dma }: GeoData): DMATableData => ({
      name,
      index,
      households,
      color: renderColor(dma),
    });
    return [top.map(transform), bottom.map(transform)];
  }, [geoData, topNumber, bottomNumber, renderColor]);

  const [pathLoaded, setPathLoaded] = useState(false);

  useLayoutEffect(() => {
    if (pathLoaded && geoData && top && bottom) {
      setTimeout(() => {
        onLoad({
          elem: mapSvgRef.current,
          top,
          bottom,
        });
      }, 100);
    }
  }, [pathLoaded, geoData, onLoad, top, bottom]);

  return (
    <DmaMap
      width={MAP_WIDTH}
      height={MAP_WIDTH / MAP_ASPECT_RATIO}
      renderColor={renderColor}
      ref={mapSvgRef}
      onPathLoad={setPathLoaded}
    />
  );
};

export interface StreamingGeoSlideState {
  title: string;
  lag: Lag;
  dates: RelativeDateRange;
  topNumber: number;
  bottomNumber: number;
  kpi?: string;
  kpiName?: string;
}

interface StreamingGeoSlideData {
  title: string;
  chartURL: string;
  footnote: string;
  table: {
    top: DMATableData[];
    bottom: DMATableData[];
  };
}

export const strGeoLagsSharedStateKey = "streamingGeoLags" as const;
export type StreamingGeoLagsSharedState = string[];

export const strGeoLagsSharedFetcher: SharedStateFetcher<StreamingGeoLagsSharedState> = async (
  kpi: string,
  setError: SetError
) => {
  try {
    let validLagRes = await ToolsLambdaFetch("/geo_lags", {
      params: { kpi },
    });
    let validLags: Lag[] = await awaitJSON(validLagRes);
    return R.sortBy(lag => LAG_INFO[lag].index, validLags);
  } catch (e) {
    const reportError = e as Error;
    setError({
      message: `Could not get valid streaming geo lags. Error: ${reportError.message}`,
      reportError,
    });
  }
  return [];
};

class StreamingGeoSlide extends SlideType {
  static typeKey = "streamingGeo";
  static displayKey = "Streaming Geo";
  static defaultState: StreamingGeoSlideState = {
    title: "Streaming Performance by DMA",
    lag: "7d",
    dates: {
      start: {
        pivotDate: "today",
        adjustment: 1,
        adjustmentType: "month",
      },
      end: {},
    },
    topNumber: 5,
    bottomNumber: 5,
  };

  static SettingsComponent: React.FC<SettingsComponentProps<StreamingGeoSlideState>> = React.memo(
    ({ state, setState, sharedState, sharedFetch, id }) => {
      const { title, lag, dates, topNumber, bottomNumber, kpi, kpiName } = state;

      const validLags: string[] | undefined = useMemo(
        () => R.path([strGeoLagsSharedStateKey, kpi ?? ""], sharedState),
        [sharedState, kpi]
      );

      const { kpiMap } = useKpiPickerData({ mediaType: "streaming" });

      useEffect(() => {
        if (kpi && !validLags) {
          (async () => {
            await sharedFetch(strGeoLagsSharedStateKey, kpi);
          })();
        }
      }, [validLags, sharedFetch, kpi]);

      useEffect(() => {
        if (kpi && validLags && !validLags.find(R.equals(lag))) {
          if (validLags.find(R.equals(StreamingGeoSlide.defaultState.lag))) {
            setState({ lag: StreamingGeoSlide.defaultState.lag });
          } else {
            setState({ lag: validLags[0] });
          }
        }
      }, [validLags, setState, kpi, lag]);

      useEffect(() => {
        if (kpi && R.keys(kpiMap)) {
          let ourKpiName = kpiMap[kpi]?.name;
          if (ourKpiName && kpiName !== ourKpiName) {
            setState({ kpiName: ourKpiName });
          }
        }
      }, [kpi, kpiName, kpiMap, setState]);
      const lagOptions = useMemo(() => {
        if (validLags) {
          return validLags.map(lag => ({
            label: LAG_INFO[lag]?.name || "",
            value: lag,
          }));
        }
        return [
          {
            label: LAG_INFO[lag]?.name || "",
            value: lag,
          },
        ];
      }, [validLags, lag]);

      return (
        <div className="settingsBox">
          <div>
            <Form.Group className="flex">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title}
                onChange={e => setState({ title: e.currentTarget.value })}
              />
            </Form.Group>
          </div>
          <div>
            <KpiPicker
              mediaType="streaming"
              kpi={kpi}
              kpiMap={kpiMap}
              onChange={kpi => setState({ kpi })}
            />
          </div>
          <div className="wrappingColumn">
            <Form.Group>
              <Form.Label>Start</Form.Label>
              <RelativeDatePicker
                state={dates.start}
                onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>End</Form.Label>
              <RelativeDatePicker
                state={dates.end}
                onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
              />
            </Form.Group>
          </div>
          <div className="wrappingColumn tight">
            <Form.Group>
              <Form.Label>Lag</Form.Label>
              <OldDropdown
                value={lag}
                options={lagOptions}
                disabled={!validLags}
                onChange={lag => setState({ lag })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Num. of top performers</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={0}
                value={`${topNumber}`}
                onChange={e => setState({ topNumber: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Num. of bottom performers</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={0}
                value={`${bottomNumber}`}
                onChange={e => setState({ bottomNumber: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
          </div>
        </div>
      );
    }
  );

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<StreamingGeoSlideData> => {
    const {
      title,
      lag,
      dates,
      topNumber,
      bottomNumber,
      kpi,
      kpiName,
    } = state as StreamingGeoSlideState;

    let sandbox = await claimSandbox();
    let {
      svgString,
      top,
      bottom,
    }: Omit<SlideGeoChartResult, "elem"> & { svgString: string } = await new Promise(resolve => {
      const resolvedDates = {
        start: computeResolvedDate(dates.start),
        end: computeResolvedDate(dates.end),
      };
      const load = () => {
        ReactDOM.render(
          <Provider store={reduxStore}>
            <SlideGeoChart
              lag={lag}
              dates={resolvedDates}
              kpi={kpi || ""}
              topNumber={topNumber}
              bottomNumber={bottomNumber}
              onLoad={({ elem, top, bottom }) => {
                if (elem) {
                  console.log("geo chart success!");
                  let boundingBox = elem.getBoundingClientRect();
                  let svgString = convertSVGStringToPNGURI(
                    elem.outerHTML,
                    boundingBox.width,
                    boundingBox.height
                  );
                  // NOTE: we need this because otherwise the next geo slide generated will reuse
                  // the chart already rendered for some reason.
                  if (sandbox.element) {
                    ReactDOM.unmountComponentAtNode(sandbox.element);
                  }
                  resolve({ svgString, top, bottom });
                } else {
                  console.log("chart failed, retrying");
                  // if (sandbox.element) {
                  //   ReactDOM.unmountComponentAtNode(sandbox.element);
                  // }
                  setTimeout(load, 100);
                }
              }}
            />
          </Provider>,
          sandbox.element
        );
      };
      setTimeout(load, 100);
    });

    releaseSandbox(sandbox);

    let chartURL = await addS3Image(svgString);

    let footnote = `${computeResolvedDate(
      dates.start,
      {},
      FOOTNOTE_DATE_FORMAT
    )} - ${computeResolvedDate(
      dates.end,
      {},
      FOOTNOTE_DATE_FORMAT
    )}; DMAS < 500K HHs excluded; KPI is ${kpiName || "unknown"}`;
    if (lag !== "all_business") {
      footnote = `${footnote} with ${lag} window`;
    }
    footnote = `${footnote}.`;
    return {
      title,
      chartURL,
      footnote,
      table: {
        top,
        bottom,
      },
    };
  };
}

export default StreamingGeoSlide;
