import { AdOpsToolProps } from "../AdOps";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Card, InfoTooltip, Spinner } from "../../Components";
import { FlashtalkingLambdaFetch } from "../../utils/fetch-utils";
import React, { useState, useCallback } from "react";

const FlashtalkingCampaignAdder: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const [advertiserId, setAdvertiserId] = useState<number | null>(null);
  const [campaignId, setCampaignId] = useState<number | null>(null);
  const [campaignName, setCampaignName] = useState("");
  const [company, setCompany] = useState("");
  const [isManagedService, setIsManagedService] = useState(false);
  const { setError } = utils;

  const addFlashtalkingCampaign = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await FlashtalkingLambdaFetch("/addFlashtalkingCampaign", {
        method: "POST",
        body: JSON.stringify({
          advertiser_id: advertiserId,
          campaign_id: campaignId,
          campaign: campaignName,
          company,
          is_managed_service: isManagedService,
        }),
      });
      setAdvertiserId(null);
      setCampaignId(null);
      setCampaignName("");
      setCompany("");
      setIsManagedService(false);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: reportError.message,
        reportError,
      });
    }
    setActiveAPICall(false);
  }, [advertiserId, campaignId, campaignName, company, isManagedService, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">
        Add Flashtalking Campaign
        <InfoTooltip size="reg">
          The company you input should be the all lowercased company ID used in master_companies and
          in our app URLs.
          <br></br>
          <br></br>
          You can find the Advertiser Name and ID by selecting your client
          <a
            href="https://admin.flashtalking.net/advertisers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here.
          </a>
          <br></br>
          <br></br>
          You can find the Campaign Name and ID by searching for your client
          <a
            href="https://campaign-manager.flashtalking.net/choose/campaign/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
          .
        </InfoTooltip>
      </div>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company} onChange={e => setCompany(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Advertiser Id</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={advertiserId || ""}
          onChange={e => setAdvertiserId(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Campaign Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={campaignName} onChange={e => setCampaignName(e.target.value)} />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Campaign Id</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={campaignId || ""}
          onChange={e => setCampaignId(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Is Managed Service/DCO Campaign?</InputGroup.Text>
          <Form.Check
            checked={isManagedService}
            onChange={() => setIsManagedService(!isManagedService)}
          />
        </InputGroup.Prepend>
      </InputGroup>
      <Button className="linearAdOpsElem" onClick={addFlashtalkingCampaign}>
        {activeAPICall ? <Spinner /> : "Add"}
      </Button>
    </Card>
  );
};

export default FlashtalkingCampaignAdder;
