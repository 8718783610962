import React from "react";
import * as R from "ramda";
import { MdDelete, MdArrowForward } from "react-icons/md";
import { BPMButton } from "../Components";
import { SegmentEditsMap, SegmentRow } from "./SegmentationLabeling";

interface PendingCampaignEditsProps {
  campaign_key: string;
  editsMap: SegmentEditsMap;
  setEditsMap?: React.Dispatch<React.SetStateAction<SegmentEditsMap>>;
  editTypes: string[];
  onlyShowUnset?: boolean;
}

const PendingCampaignEdits: React.FC<PendingCampaignEditsProps> = ({
  campaign_key,
  editsMap,
  setEditsMap,
  editTypes,
  onlyShowUnset = false,
}) => {
  const row: SegmentRow = editsMap[campaign_key];
  const { account_name, campaign_name, edits } = row;

  const deleteEdit = (editType: string) => {
    if (!setEditsMap) {
      return;
    }

    // Create a new row that has the edit removed.
    let removedEdit = {
      ...row,
      edits: R.omit([editType], edits),
    } as SegmentRow;

    // If after removing the edit, the "edits" field is empty, delete the whole row from editsMap
    // because there are no edits left for this row.
    if (R.isEmpty(removedEdit.edits)) {
      let removedRow = R.omit([campaign_key], editsMap);
      setEditsMap(removedRow);
    } else {
      // Otherwise, there are still other edits remaining for this row so just update editsMap with
      // the removedEdit row.
      setEditsMap(current => ({ ...current, [campaign_key]: removedEdit }));
    }
  };

  return (
    <div className="editContainer">
      <div className="editTitle">
        {account_name}s - {campaign_name}
      </div>
      <div className="editsList">
        {editTypes.map(editType => {
          const newValue = (row.edits && row.edits[editType]?.label) ?? null;
          const oldValue = row[editType];

          if ((onlyShowUnset && newValue) || (newValue && newValue === oldValue)) {
            return null;
          }

          return (
            <div className="edit" key={editType}>
              {newValue && setEditsMap && (
                <BPMButton
                  className="deleteButton"
                  size="sm"
                  variant="outline-danger"
                  onClick={() => deleteEdit(editType)}
                >
                  <MdDelete size={20} />
                </BPMButton>
              )}
              {!newValue && setEditsMap && <div className="deleteButtonPlaceholder" />}
              <span className="editType">{editType}:</span>
              {oldValue !== newValue && (
                <>
                  <span className="oldValue">{oldValue || "Not Set"}</span>
                  <MdArrowForward />
                </>
              )}
              <span className={newValue ? "newValue" : "oldValue"}>{newValue || "Not Set"}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PendingCampaignEdits;
