import axios from "axios";
import "./Slides.scss";
import { getGlobalBrand } from "../Performance/performanceUtils";
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET } from "../Login/Login";
import { refreshGoogleAccessToken, signInGoogleOAuth } from "../utils/auth";
import { StateSetter } from "../utils/types";
import { useCompanyInfo } from "../redux/company";
import { useCreativeMap } from "../redux/creative";
import { useDerivedNetworkMap } from "../redux/networks";
import { useDrag, useDrop } from "react-dnd";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { useSetError, useSetAreYouSure, SetAreYouSure, SetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import Cookies from "js-cookie";
import FeedbackModalButton from "../Components/FeedbackModal";
import useLocation from "../utils/hooks/useLocation";
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect,
  MutableRefObject,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import {
  MdAdd,
  MdContentCopy,
  MdDelete,
  MdDeleteForever,
  MdDragHandle,
  MdSave,
} from "react-icons/md";
import {
  S3PutPng,
  GetS3SignedUrl,
  awaitJSON,
  SlidesLambdaFetch,
  poll,
  ToolsLambdaFetch,
  S3SignedUrlFetch2,
  makePollingErrorChecker,
  SingleChannelLambdaFetch,
} from "../utils/fetch-utils";
import {
  Page,
  BPMButton,
  Skeleton,
  RectSkeleton,
  GridPickerModal,
  GridPickerOption,
  OldDropdown,
} from "../Components";
import {
  SlideType,
  S3PromiseFunction,
  SlideContext,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  SandboxObject,
} from "./slidesTypes";
import {
  COPY_TEMPLATE,
  DECK_TEMPLATE_OPTIONS,
  DeckTemplate,
  INIT_SHARED_STATE,
  NEW_SLIDE_OPTIONS,
  SHARED_FETCHER_MAP,
  SharedState,
  SLIDE_TYPE_MAP,
  SlideState,
  SlideTemplate,
  AUDIO_PERFORMANCE_GRAPH_NEW_TEMPLATE,
  STREAMING_PERFORMANCE_GRAPH_NEW_TEMPLATE,
  MOBIUS_DECK_TEMPLATE_OPTIONS,
  MOBIUS_NEW_SLIDE_OPTIONS,
  DISPLAY_PERFORMANCE_GRAPH_NEW_TEMPLATE,
} from "./slideTemplateConstants";
import {
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  GetPresetsParams as GetTablePresetsParams,
  PresetIDGroups as SimpleMetricsTablePreset,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

const SKELETON_GUTTER = 20;
const SKELETON_CONTROL_BAR_HEIGHT = 38;
const SKELETON_TOC_WIDTH = 200;

const SlidePageSkeleton = React.memo(() => (
  <Skeleton redesign>
    <RectSkeleton height={SKELETON_CONTROL_BAR_HEIGHT} />
    <RectSkeleton
      height={height => height - SKELETON_GUTTER - SKELETON_CONTROL_BAR_HEIGHT}
      y={SKELETON_CONTROL_BAR_HEIGHT + SKELETON_GUTTER}
      width={SKELETON_TOC_WIDTH}
    />
    <RectSkeleton
      height={height => height - SKELETON_GUTTER - SKELETON_CONTROL_BAR_HEIGHT}
      y={SKELETON_CONTROL_BAR_HEIGHT + SKELETON_GUTTER}
      x={SKELETON_TOC_WIDTH + SKELETON_GUTTER}
      width={width => width - SKELETON_GUTTER - SKELETON_TOC_WIDTH}
    />
  </Skeleton>
));

const DATE_FORMAT = "yyyy-MM-dd";
const GOOGLE_DOCS_URL_PREFIX = "https://docs.google.com/presentation";

export const SLIDE_ASSET_S3_BUCKET = "bpm-slides-assets";

const PROMISE_LOOP_TIMEOUT = 500;
const S3_LOOP_CONCURRENT_COUNT = 3;

export interface SharedStateFetch {
  (stateKey: keyof SharedState, key: string, context?: any): Promise<void>;
}
const useSharedSlideData = (
  company: string,
  setLoadingMap: (setter: (existing: Record<string, boolean>) => Record<string, boolean>) => void
): {
  sharedFetch: SharedStateFetch;
  sharedState: SharedState;
} => {
  const setError = useSetError();
  const fetchMap: MutableRefObject<
    Partial<Record<keyof SharedState, Record<string, boolean>>>
  > = useRef({});
  const [sharedState, setSharedState] = useState<SharedState>(INIT_SHARED_STATE);
  const [pagePresetsMap, setPagePresetsMap] = useState<Record<string, MetricsPagePreset[]>>({});
  const [tablePresetsMap, setTablePresetsMap] = useState<
    Record<string, SimpleMetricsTablePreset[]>
  >({});
  const companyInfo = useCompanyInfo();

  const { creativeMap } = useCreativeMap({
    company: company,
    mediaTypes: ["audio", "streaming", "display", "linear"],
  });

  const derivedNetworkMap = useDerivedNetworkMap(company);
  const globalBrand = companyInfo
    ? getGlobalBrand(companyInfo, companyInfo.streaming_performance_default_kpi)
    : undefined;
  const audioIsGraph = useExperimentFlag("enableAudioGraphPerformance") as boolean;
  const displayIsGraph = useExperimentFlag("enableDisplayGraphPerformance") as boolean;
  const streamingIsGraph = useExperimentFlag("enableGraphPerformance") as boolean;

  const sharedFetch: SharedStateFetch = useCallback(
    async (stateKey, key, context?) => {
      if (
        R.path(["current", stateKey, key], fetchMap) &&
        (!context || (context && !context.updateState))
      ) {
        return;
      }
      setLoadingMap(existing => ({
        ...existing,
        [`${stateKey}_${key}`]: true,
      }));
      fetchMap.current[stateKey] = {
        ...fetchMap.current[stateKey],
        [key]: true,
      };
      let res = await SHARED_FETCHER_MAP[stateKey](key, setError, context);
      setSharedState(existing => ({
        ...existing,
        [stateKey]: {
          ...(existing[stateKey] || {}),
          [key]: res,
        },
      }));
      setLoadingMap(existing => ({
        ...existing,
        [`${stateKey}_${key}`]: false,
      }));
    },
    [setError, setLoadingMap]
  );

  useEffect(() => {
    if (R.isNil(sharedState.companyInfo)) {
      setSharedState(sharedState => ({
        ...sharedState,
        companyInfo,
      }));
    }
  }, [companyInfo, sharedState]);

  useEffect(() => {
    if (R.isNil(sharedState.creativeMap) && creativeMap && !R.isEmpty(creativeMap)) {
      let creativeNameMap = {};
      for (let key of R.keys(creativeMap)) {
        const { name } = creativeMap[key];
        if (R.isNil(creativeNameMap[name]) || !creativeNameMap[name].file) {
          creativeNameMap[name] = creativeMap[key];
        }
        setSharedState(sharedState => ({
          ...sharedState,
          creativeMap,
          creativeNameMap,
        }));
      }
    }
  }, [creativeMap, sharedState]);

  useEffect(() => {
    if (
      R.isNil(sharedState.derivedNetworkMap) &&
      derivedNetworkMap &&
      !R.isEmpty(derivedNetworkMap)
    ) {
      setSharedState(sharedState => ({
        ...sharedState,
        derivedNetworkMap,
      }));
    }
  }, [derivedNetworkMap, sharedState]);

  useEffect(() => {
    if (R.isNil(sharedState.globalBrand) && globalBrand) {
      setSharedState(sharedState => ({
        ...sharedState,
        globalBrand: {
          globalBrand,
        },
      }));
    }
  }, [globalBrand, sharedState]);

  useEffect(() => {
    if (R.isNil(sharedState.isGraph.audio) && !R.isNil(audioIsGraph)) {
      setSharedState(sharedState => ({
        ...sharedState,
        isGraph: {
          ...sharedState.isGraph,
          audio: audioIsGraph,
        },
      }));
    }
    if (R.isNil(sharedState.isGraph.display) && !R.isNil(displayIsGraph)) {
      setSharedState(sharedState => ({
        ...sharedState,
        isGraph: {
          ...sharedState.isGraph,
          display: displayIsGraph,
        },
      }));
    }
    if (R.isNil(sharedState.isGraph.streaming) && !R.isNil(streamingIsGraph)) {
      setSharedState(sharedState => ({
        ...sharedState,
        isGraph: {
          ...sharedState.isGraph,
          streaming: streamingIsGraph,
        },
      }));
    }
  }, [
    audioIsGraph,
    displayIsGraph,
    sharedState.isGraph.audio,
    sharedState.isGraph.display,
    sharedState.isGraph.streaming,
    streamingIsGraph,
  ]);

  useEffect(() => {
    (async () => {
      if (companyInfo.media_types) {
        const pagePresetsMap = {};
        const pagePresetsPromises: Promise<Response>[] = [];
        for (const media_type of companyInfo.media_types) {
          const mediatype = media_type === "tv" ? "linear" : media_type;
          pagePresetsPromises.push(
            SingleChannelLambdaFetch<GetPagePresetsParams>("/metrics_page_presets", {
              params: { company, mediatype },
            })
          );
        }
        const pagePresetsResponses = await Promise.all(pagePresetsPromises);
        for (let i = 0; i < companyInfo.media_types.length; i++) {
          const mediatype =
            companyInfo.media_types[i] === "tv" ? "linear" : companyInfo.media_types[i];
          pagePresetsMap[mediatype] = R.filter(
            preset => !preset.temporary,
            (await awaitJSON(pagePresetsResponses[i])) as MetricsPagePreset[]
          );
        }
        setPagePresetsMap(pagePresetsMap);
      }
    })();
  }, [company, companyInfo]);

  useEffect(() => {
    (async () => {
      if (companyInfo.media_types) {
        const tablePresetsMap = {};
        const tablePresetsPromises: Promise<Response>[] = [];
        for (const media_type of companyInfo.media_types) {
          const mediatype = media_type === "tv" ? "linear" : media_type;
          tablePresetsPromises.push(
            SingleChannelLambdaFetch<GetTablePresetsParams>("/metrics_table_presets", {
              params: { company, mediatype },
            })
          );
        }
        const tablePresetsResponses = await Promise.all(tablePresetsPromises);
        for (let i = 0; i < companyInfo.media_types.length; i++) {
          const mediatype =
            companyInfo.media_types[i] === "tv" ? "linear" : companyInfo.media_types[i];
          tablePresetsMap[mediatype] = R.filter(
            tablePreset => !tablePreset.temporary,
            (await awaitJSON(tablePresetsResponses[i])) as SimpleMetricsTablePreset[]
          );
        }
        setTablePresetsMap(tablePresetsMap);
      }
    })();
  }, [company, companyInfo]);

  useEffect(() => {
    if (R.isNil(sharedState.pagePresets) && !R.isEmpty(pagePresetsMap)) {
      setSharedState(sharedState => ({
        ...sharedState,
        pagePresets: pagePresetsMap,
      }));
    }
  }, [pagePresetsMap, sharedState]);

  useEffect(() => {
    if (R.isNil(sharedState.tablePresets) && !R.isEmpty(tablePresetsMap)) {
      setSharedState(sharedState => ({
        ...sharedState,
        tablePresets: tablePresetsMap,
      }));
    }
  }, [sharedState, tablePresetsMap]);

  return {
    sharedFetch,
    sharedState,
  };
};

type SlideList = SlideType[];
type StateMap = Record<string, SlideState>;
type PartialStateMap = Record<string, Partial<SlideState>>;

interface PartialSlidesInfo {
  slides: SlideList;
  stateMap: PartialStateMap;
}

interface SlidesInfo extends PartialSlidesInfo {
  stateMap: StateMap;
}

interface TemplateObject {
  id: number;
  name: string;
  settings: PartialSlidesInfo;
}

type FetchStatus = "unfetched" | "fetching" | "saving" | "fetched" | "deleting";
const useTemplates = (
  setAreYouSure: SetAreYouSure,
  setSlidesInfo: StateSetter<SlidesInfo>,
  slides: SlideList,
  stateMap: StateMap,
  isMobiusPage: boolean
) => {
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>("unfetched");
  const [newDeckName, setNewDeckName] = useState<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [templates, setTemplates] = useState<TemplateObject[]>([]);
  const { cid: company } = useCompanyInfo();
  const companyInfo = useCompanyInfo();
  const setError = useSetError();

  const loadSettings = useCallback(
    (slides: SlideTemplate[]) => {
      let ourSlides: SlideList = [];
      let stateMap = {};
      slides = R.filter(slide => {
        if (companyInfo.media_types) {
          const media_types = R.map(m => m.toLowerCase(), companyInfo.media_types);
          const hasAudio = media_types.includes("audio");
          const hasDisplay = media_types.includes("display");
          const hasLinear = media_types.includes("tv");
          const hasStreaming = media_types.includes("streaming");
          const hasYoutube = media_types.includes("youtube");
          if (
            (slide.name.toLowerCase().includes("audio") && !hasAudio) ||
            (slide.name.toLowerCase().includes("display") && !hasDisplay) ||
            (slide.name.toLowerCase().includes("linear") && !hasLinear) ||
            (slide.name.toLowerCase().includes("streaming") && !hasStreaming) ||
            (slide.name.toLowerCase().includes("youtube") && !hasYoutube)
          ) {
            return false;
          }
        }
        return true;
      }, slides);
      for (let { type, name, state } of slides) {
        let SlideClass = SLIDE_TYPE_MAP[type];
        if (SlideClass) {
          let slide = new SlideClass(name);
          stateMap[slide.id] = {
            ...SlideClass.defaultState,
            ...state,
          };
          ourSlides.push(slide);
        } else {
          setError({ message: `"${type}" is not a valid type of slide` });
          continue;
        }
      }

      setSlidesInfo({
        slides: ourSlides,
        stateMap,
      });
    },
    [setSlidesInfo, companyInfo, setError]
  );

  const loadNewDeck = useCallback(
    (name: string, template: SlideTemplate[]) => {
      setNewDeckName(name);
      loadSettings(template);
      setSelectedTemplate(-1);
    },
    [loadSettings]
  );

  const loadTemplate = useCallback(
    (template: TemplateObject) => {
      setSelectedTemplate(template.id);
      let { slides, stateMap } = template.settings;
      loadSettings(
        slides.map(({ type, name, id }) => ({
          type,
          name,
          state: stateMap[id],
        }))
      );
    },
    [loadSettings]
  );

  const selectTemplate = useCallback(
    (templateId: number) => {
      let template = templates.find(({ id }) => id === templateId);
      if (template) {
        setNewDeckName(undefined);
        loadTemplate(template);
      }
    },
    [templates, loadTemplate]
  );

  useEffect(() => {
    if (fetchStatus === "unfetched") {
      setFetchStatus("fetching");
      (async () => {
        try {
          let res = await SlidesLambdaFetch("/settings", {
            params: {
              company,
            },
          });
          let templates: TemplateObject[] = await awaitJSON(res);
          if (isMobiusPage) {
            const nonMobiusSlides = R.map(slide => slide.type, NEW_SLIDE_OPTIONS);
            if (!R.isEmpty(templates)) {
              templates = R.filter(template => {
                for (let slide of template.settings.slides) {
                  if (nonMobiusSlides.includes(slide.type)) {
                    return false;
                  }
                }
                return true;
              }, templates);
            }
          } else {
            const mobiusSlides = R.map(slide => slide.type, MOBIUS_NEW_SLIDE_OPTIONS);
            if (!R.isEmpty(templates)) {
              templates = R.filter(template => {
                for (let slide of template.settings.slides) {
                  if (mobiusSlides.includes(slide.type)) {
                    return false;
                  }
                }
                return true;
              }, templates);
            }
          }
          setTemplates(templates);
          if (templates.length) {
            loadTemplate(templates[0]);
          }
          setFetchStatus("fetched");
        } catch (e) {
          let error = e as Error;
          setError({
            message: `Couldn't fetch saved configs. ${error.message}`,
            reportError: error,
          });
        }
      })();
    }
  }, [fetchStatus, setError, company, loadTemplate, loadSettings, isMobiusPage]);

  const templateOptions = useMemo(() => {
    let options: { id: number; name: string }[] = [];
    if (newDeckName) {
      options.push({
        name: `${newDeckName}*`,
        id: -1,
      });
    }
    for (let { id, name } of templates) {
      options.push({ id, name });
    }
    return options;
  }, [newDeckName, templates]);

  const saveTemplate = useCallback(async () => {
    setFetchStatus("saving");
    try {
      let settings = {
        slides,
        stateMap,
      };
      let body: { company: string; settings: typeof settings; name?: string; id?: number } = {
        company,
        settings,
      };
      if (newDeckName) {
        body.name = newDeckName;
      } else {
        body.id = selectedTemplate;
      }
      let res = await SlidesLambdaFetch("/settings", {
        method: "POST",
        body,
      });
      let { id }: { id: number } = await awaitJSON(res);
      if (newDeckName) {
        setNewDeckName(undefined);
        setTemplates(templates => [
          { id, name: newDeckName, settings: { slides, stateMap } },
          ...templates,
        ]);
        setSelectedTemplate(id);
      } else {
        setTemplates(
          R.map(template => {
            if (template.id === id) {
              return {
                ...template,
                settings: {
                  slides,
                  stateMap,
                },
              };
            }
            return template;
          })
        );
      }
    } catch (e) {
      let error = e as Error;
      setError({
        message: error.message,
        reportError: error,
      });
    }
    setFetchStatus("fetched");
  }, [setError, setFetchStatus, company, newDeckName, selectedTemplate, slides, stateMap]);

  const deleteTemplate = useCallback(() => {
    setAreYouSure({
      title: "Delete Slide Deck",
      message: "You are about to delete this slide template, are you sure?",
      variant: "warning",
      cancelText: "Never mind",
      okayText: "Yes",
      onOkay: async () => {
        setFetchStatus("deleting");
        try {
          const body = { id: selectedTemplate };
          await SlidesLambdaFetch("/settings", {
            method: "DELETE",
            body,
          });
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
        let newTemplates = R.filter(template => template.id !== selectedTemplate, templates);
        setTemplates(newTemplates);
        let currentTemplate = newTemplates[0];
        if (currentTemplate && currentTemplate.settings) {
          setSelectedTemplate(currentTemplate.id);
          setNewDeckName(undefined);
          let { slides, stateMap } = currentTemplate.settings;
          loadSettings(
            slides.map(({ type, name, id }) => ({
              type,
              name,
              state: stateMap[id],
            }))
          );
        } else {
          setSelectedTemplate(undefined);
          setNewDeckName(undefined);
          loadSettings([]);
        }
        setFetchStatus("fetched");
      },
    });
  }, [setAreYouSure, selectedTemplate, templates, loadSettings, setError]);

  return {
    fetchStatus,
    selectedTemplate,
    selectTemplate,
    templateOptions,
    loadNewDeck,
    saveTemplate,
    deleteTemplate,
  };
};

interface TemplatePickerProps {
  templateId?: number;
  selectTemplate: (id: number) => void;
  options: { id: number; name: string }[];
  openNewSlideForm: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const TemplatePicker = ({
  templateId,
  selectTemplate,
  options,
  openNewSlideForm,
  onSave,
  onDelete,
}: TemplatePickerProps) => {
  return (
    <div className="templatePicker">
      {R.isNil(templateId) ? (
        <BPMButton onClick={openNewSlideForm}>Create a New Deck</BPMButton>
      ) : (
        <>
          <div className="templateLabel">Deck:</div>
          <OldDropdown
            value={`${templateId}`}
            onChange={id => selectTemplate(parseInt(id))}
            options={options.map(({ id, name }) => ({ label: name, value: `${id}` }))}
          />
          <BPMButton variant="outline-primary" onClick={onSave}>
            <MdSave />
          </BPMButton>
          <BPMButton variant="outline-primary" onClick={onDelete}>
            <MdDelete />
          </BPMButton>
          <BPMButton variant="outline-primary" onClick={openNewSlideForm}>
            <MdAdd />
          </BPMButton>
        </>
      )}
    </div>
  );
};

interface NewDeckFormProps extends SlidesInfo {
  onClose: () => void;
  onSubmit: (name: string, template: SlideTemplate[]) => void;
  templateNames: string[];
  isMobiusPage: boolean;
}
const NewDeckForm: React.FC<NewDeckFormProps> = ({
  onClose,
  onSubmit: providedOnSubmit,
  templateNames,
  slides,
  stateMap,
  isMobiusPage,
}) => {
  const setError = useSetError();
  const companyInfo = useCompanyInfo();
  const [name, setName] = useState("");
  const [option, setOptionRaw] = useState("");

  const [newTemplateOptions, templateMap] = useMemo(() => {
    let options: { key: string; thumbnail: string; caption: string }[] = [
      {
        key: "copy",
        thumbnail: COPY_TEMPLATE.name,
        caption: COPY_TEMPLATE.caption,
      },
    ];
    let map: Record<string, DeckTemplate> = {
      copy: {
        ...COPY_TEMPLATE,
        template: slides.map(({ name, type, id }) => ({
          name,
          type,
          state: stateMap[id],
        })),
      },
    };
    for (let option of isMobiusPage ? MOBIUS_DECK_TEMPLATE_OPTIONS : DECK_TEMPLATE_OPTIONS) {
      const { name, caption } = option;
      map[name] = option;
      if (companyInfo.media_types) {
        const media_types = R.map(m => m.toLowerCase(), companyInfo.media_types);
        const hasAudio = media_types.includes("audio");
        const hasDisplay = media_types.includes("display");
        const hasLinear = media_types.includes("tv");
        const hasStreaming = media_types.includes("streaming");
        const hasYoutube = media_types.includes("youtube");
        if (
          (name.toLowerCase().includes("audio") && !hasAudio) ||
          (name.toLowerCase().includes("display") && !hasDisplay) ||
          (name.toLowerCase().includes("linear") && !hasLinear) ||
          (name.toLowerCase().includes("streaming") && !hasStreaming) ||
          (name.toLowerCase().includes("youtube") && !hasYoutube)
        ) {
          continue;
        }
      }
      options.push({
        key: name,
        thumbnail: name,
        caption,
      });
    }
    return [options, map];
  }, [companyInfo, isMobiusPage, slides, stateMap]);
  const setOption = useCallback(
    (option: string) => {
      let ourOption = templateMap[option];
      setOptionRaw(option);
      setName(ourOption.name);
    },
    [templateMap]
  );
  const nameTaken = useMemo(() => !!templateNames.find(R.equals(name)), [templateNames, name]);
  const onSubmit = useCallback(() => {
    if (templateMap[option]) {
      providedOnSubmit(name, templateMap[option].template);
    } else {
      console.error(JSON.stringify(templateMap));
      console.error(option);
      setError({
        message: "An unknown error occurred",
        reportError: new Error(`Error submitting new deck form. Option: ${option}`),
      });
    }
  }, [providedOnSubmit, name, templateMap, option, setError]);

  return (
    <GridPickerModal
      name={name}
      setName={setName}
      options={newTemplateOptions}
      selectedOption={option}
      selectOption={setOption}
      onClose={onClose}
      onSubmit={onSubmit}
      invalid={nameTaken || !name}
      title="New Deck"
      bodyText="Pick a template to start with. You will be able to add, remove, and rearrange slides after you load the template."
      nameFieldLabel={nameTaken ? "Name (this one is already taken)" : "Name"}
      submitButtonText="Load Template"
    />
  );
};

interface AddSlideFormProps {
  onSelect: (template: SlideTemplate, name: string) => void;
  onClose: () => void;
  slides: SlideList;
  defaultTemplate?: SlideTemplate;
  isMobiusPage;
}

const AddSlideForm: React.FC<AddSlideFormProps> = ({
  onSelect,
  slides,
  onClose,
  defaultTemplate,
  isMobiusPage,
}) => {
  const enableNewSlides = useExperimentFlag("enableNewSlides");
  const shouldEnableGraphPerformance = useExperimentFlag("enableGraphPerformance");
  const shouldEnableAudioGraphPerformance = useExperimentFlag("enableAudioGraphPerformance");
  const shouldEnableDisplayGraphPerformance = useExperimentFlag("enableDisplayGraphPerformance");
  const [name, setName] = useState<string>(defaultTemplate?.name ?? "");
  const companyInfo = useCompanyInfo();
  const [selectedOption, setSelectedOptionRaw] = useState<string>(defaultTemplate ? "default" : "");

  const setSelectedOption = useCallback((name: string) => {
    setName(name);
    setSelectedOptionRaw(name);
  }, []);

  const [options, optionMap] = useMemo(() => {
    let map: Record<string, SlideTemplate> = {};
    let options: GridPickerOption[] = [];
    if (defaultTemplate) {
      map.default = defaultTemplate;
      options.push({
        key: "default",
        thumbnail: defaultTemplate.name,
        caption: defaultTemplate.caption,
      });
    }
    let slideOptions = isMobiusPage ? MOBIUS_NEW_SLIDE_OPTIONS : NEW_SLIDE_OPTIONS;
    if (enableNewSlides) {
      // Insert Experiment Slides Here:
    }
    if (!shouldEnableGraphPerformance && !isMobiusPage) {
      // We want to give clients who haven't fully switched over the option to use
      // graph data. If the client is fully moved over to graph data there is no
      // need to insert this extra template since the regular performance slide
      // already uses graph presets/data.
      slideOptions.push(STREAMING_PERFORMANCE_GRAPH_NEW_TEMPLATE);
    }
    if (!shouldEnableAudioGraphPerformance && !isMobiusPage) {
      slideOptions.push(AUDIO_PERFORMANCE_GRAPH_NEW_TEMPLATE);
    }
    if (!shouldEnableDisplayGraphPerformance && !isMobiusPage) {
      slideOptions.push(DISPLAY_PERFORMANCE_GRAPH_NEW_TEMPLATE);
    }

    slideOptions = R.filter(slide => {
      if (companyInfo.media_types) {
        const media_types = R.map(m => m.toLowerCase(), companyInfo.media_types);
        const hasAudio = media_types.includes("audio");
        const hasDisplay = media_types.includes("display");
        const hasLinear = media_types.includes("tv");
        const hasStreaming = media_types.includes("streaming");
        const hasYoutube = media_types.includes("youtube");
        if (
          (slide.name.toLowerCase().includes("audio") && !hasAudio) ||
          (slide.name.toLowerCase().includes("display") && !hasDisplay) ||
          (slide.name.toLowerCase().includes("linear") && !hasLinear) ||
          (slide.name.toLowerCase().includes("streaming") && !hasStreaming) ||
          (slide.name.toLowerCase().includes("youtube") && !hasYoutube)
        ) {
          return false;
        }
      }
      return true;
    }, slideOptions);

    for (let option of slideOptions) {
      map[option.name] = option;
      options.push({ key: option.name, thumbnail: option.name, caption: option.caption });
    }
    return [options, map];
  }, [
    companyInfo.media_types,
    defaultTemplate,
    enableNewSlides,
    isMobiusPage,
    shouldEnableAudioGraphPerformance,
    shouldEnableDisplayGraphPerformance,
    shouldEnableGraphPerformance,
  ]);

  const takenNameMap = useMemo(() => {
    let map: Record<string, true> = {};
    for (let slide of slides) {
      map[slide.name] = true;
    }
    return map;
  }, [slides]);

  const nameTaken = useMemo(() => !!takenNameMap[name], [name, takenNameMap]);

  const onSubmit = useCallback(() => {
    let option = optionMap[selectedOption];
    if (option) {
      onSelect(option, name);
    }
  }, [optionMap, selectedOption, onSelect, name]);

  return (
    <GridPickerModal
      name={name}
      setName={setName}
      options={options}
      selectedOption={selectedOption}
      selectOption={setSelectedOption}
      onClose={onClose}
      onSubmit={onSubmit}
      invalid={!(name && selectedOption) || nameTaken}
      nameFieldLabel={nameTaken ? "Name (this one is already taken)" : "Name"}
      title="Add Slide"
      submitButtonText="Add Slide"
    />
  );
};

interface LegendItemProps {
  id: string;
  name: string;
  scrolledItem: string;
  setScrolledItem: (item: string) => void;
  onDrop: (itemId: string, targetId: string) => void;
}

const LEGEND_ITEM_DND_TYPE = "slidesLegendItem";

interface LegendItemDragItem {
  type: typeof LEGEND_ITEM_DND_TYPE;
  id: string;
}

const LegendItem: React.FC<LegendItemProps> = ({
  id,
  name,
  scrolledItem,
  setScrolledItem,
  onDrop,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: LEGEND_ITEM_DND_TYPE, id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: LEGEND_ITEM_DND_TYPE,
    drop: (item: LegendItemDragItem) => onDrop(item.id, id),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const className = useMemo(() => {
    let classes: string[] = [];
    if (id === scrolledItem) {
      classes.push("current");
    }
    if (isDragging) {
      classes.push("isDragging");
    }
    if (isOver) {
      classes.push("isOver");
    }
    return classes.join(" ");
  }, [id, scrolledItem, isDragging, isOver]);

  drag(drop(preview(ref)));
  return (
    <div
      ref={ref}
      id={`slidePageLegendItem_${id}`}
      className={className}
      onClick={() => {
        let item = document.getElementById(id);
        if (item) {
          item.scrollIntoView();
          setScrolledItem(id);
        }
      }}
    >
      <div>{name}</div>
      <div ref={drag} className="dragHandle">
        <MdDragHandle />
      </div>
    </div>
  );
};

interface AddSlideButtonProps {
  onClick: () => void;
  onDrop: (itemId: string) => void;
}
const AddSlideButton: React.FC<AddSlideButtonProps> = ({ onClick, onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: LEGEND_ITEM_DND_TYPE,
    drop: (item: LegendItemDragItem) => onDrop(item.id),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} className={isOver ? "isOver" : ""}>
      <BPMButton block bordered variant="outline-primary" onClick={onClick}>
        Add Slide
      </BPMButton>
    </div>
  );
};

interface SlideStateSetter {
  (newState: Partial<SlideState> | ((existing: SlideState) => SlideState)): void;
}

export const checkGoogleAccessTokens = async (setError: SetError): Promise<string> => {
  let googleAccessToken = Cookies.get("google_api_access_token");
  let googleIDToken = Cookies.get("access_token");
  let loginMethod = Cookies.get("login_method");
  if (!(loginMethod === "Google") || !googleIDToken) {
    let e = new Error(
      "You are not signed in with Google. Please sign out and sign in with the Sign-In With Google Button!"
    );
    setError({
      message: e.message,
      reportError: e,
    });
    return "";
  } else if (!googleAccessToken) {
    try {
      refreshGoogleAccessToken(googleIDToken);
      return "";
    } catch (e) {
      let error = e as Error;
      setError({
        message: error.message,
        reportError: error,
      });
      return "";
    }
  } else {
    const scopeResponse = await axios.get(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleAccessToken}`
    );
    if (
      scopeResponse &&
      scopeResponse.data &&
      scopeResponse.data.scope &&
      (!scopeResponse.data.scope.includes("https://www.googleapis.com/auth/drive.file") ||
        !scopeResponse.data.scope.includes("auth/drive.file")) &&
      window[`${"google"}`]
    ) {
      const googleObject = window[`${"google"}`];
      const codeClient = googleObject.accounts.oauth2.initCodeClient({
        client_id: GOOGLE_CLIENT_ID,
        scope:
          "profile email openid https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly",
        ux_mode: "popup",
        redirect_uri: "postmessage",
        callback: async response => {
          const code_receiver_uri = "https://oauth2.googleapis.com/token";
          const xhr = new XMLHttpRequest();
          xhr.open("POST", code_receiver_uri, true);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
          // Set custom header for CRSF
          xhr.setRequestHeader("X-Requested-With", "XmlHttpRequest");
          xhr.onload = async () => {
            try {
              await signInGoogleOAuth(JSON.parse(xhr.responseText));
            } catch (e) {
              const error = e as Error;
              throw new Error(error.message);
            }
          };
          const postBody = `code=${response.code}&redirect_uri=postmessage&scope=${response.scope}&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&grant_type=authorization_code`;
          await xhr.send(postBody);
        },
      });
      codeClient.requestCode();
      return "";
    }
    return googleAccessToken;
  }
};

// TODO: could we do something where it hits the pptx download links and sees if they succeed, and
// if not it retries with `u/n` in the url? Or, could we somehow integrate with google to get a list
// of "logged in users" -> "u id"s that we can use to let the user pick, or even scrape for an
// @*pointmedia.com one
const Slides = (): JSX.Element => {
  const { cid: company } = useCompanyInfo();
  const { location } = useLocation();
  const isMobiusPage = location.pathname.includes("mobius_slides");
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure();

  const sandboxRef = useRef<HTMLDivElement>(null);

  // TODO: these two can be editable some day
  const [today] = useState(() => Dfns.format(DATE_FORMAT, new Date()));
  const [week] = useState(() => R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date()));

  const slideContext = useMemo<SlideContext>(() => ({ company, today, week }), [
    company,
    today,
    week,
  ]);

  const [{ slides, stateMap }, setSlidesInfoRaw] = useState<SlidesInfo>({
    slides: [],
    stateMap: {},
  });

  const {
    selectedTemplate,
    selectTemplate,
    templateOptions,
    loadNewDeck,
    fetchStatus: templateFetchStatus,
    saveTemplate,
    deleteTemplate,
  } = useTemplates(setAreYouSure, setSlidesInfoRaw, slides, stateMap, isMobiusPage);

  const templateNames = useMemo(() => R.pluck("name", templateOptions), [templateOptions]);
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});

  const isLoading = useMemo(() => {
    let values = R.values(loadingMap);
    return !slides || R.any(R.identity, values);
  }, [slides, loadingMap]);

  const { sharedState, sharedFetch } = useSharedSlideData(company, setLoadingMap);

  // This is to "memoize" the slide and loading setters so they don't force rerenders.
  const [slideStateSetterMap, slideLoadingSetterMap] = useMemo(() => {
    type SlideID = string;
    let stateSetterMap: Record<SlideID, SlideStateSetter> = {};
    let loadingSetterMap: Record<SlideID, (loading: boolean) => void> = {};
    for (let slide of slides || []) {
      const { id } = slide;
      stateSetterMap[id] = newState => {
        setSlidesInfoRaw(({ slides, stateMap }) => ({
          slides,
          stateMap: {
            ...stateMap,
            [id]: {
              ...stateMap[id],
              ...(typeof newState === "function" ? newState(stateMap[id]) : newState),
            } as SlideState,
          },
        }));
      };
      loadingSetterMap[id] = loading => setLoadingMap(map => ({ ...map, [id]: loading }));
    }
    return [stateSetterMap, loadingSetterMap];
  }, [slides]);

  const [resultID, setResultID] = useState<string | null>(null);
  const [generating, setGenerating] = useState(false);
  const generate = useCallback(async () => {
    const googleAccessToken = await checkGoogleAccessTokens(setError);
    if (!googleAccessToken || isLoading || generating || !(slides && stateMap)) {
      return;
    }
    setGenerating(true);
    setResultID(null);
    let s3Queue: Promise<void>[] = [];

    let sandboxMutex: string | null = null;
    let sandboxQueue: ((id: string) => void)[] = [];

    const claimSandbox: ClaimSandboxFunction = () => {
      // if (sandboxRef.current) {
      //   ReactDOM.unmountComponentAtNode(sandboxRef.current);
      // }
      return new Promise(resolve => {
        sandboxQueue.push((id: string) => {
          resolve({
            id,
            element: sandboxRef.current,
          });
        });
      });
    };
    const releaseSandbox: ReleaseSandboxFunction = (sandbox: SandboxObject) => {
      if (sandboxMutex === sandbox.id) {
        sandboxMutex = null;
      }
    };
    const addS3Image: S3PromiseFunction = pngString =>
      new Promise((resolve, reject) => {
        const id = uuid.v4();
        const key = `${id}.png`;
        s3Queue.push(
          new Promise(queueResolve => {
            S3PutPng(SLIDE_ASSET_S3_BUCKET, key, pngString)
              .then(() => GetS3SignedUrl(`${SLIDE_ASSET_S3_BUCKET}/${key}`))
              .then(url => {
                resolve(url);
                queueResolve();
              })
              .catch(reject);
          })
        );
      });

    let killPromiseLoops = false;

    const pollSandboxPromises = () => {
      if (!killPromiseLoops) {
        if (sandboxQueue.length && !sandboxMutex) {
          let id = uuid.v4();
          sandboxMutex = id;
          let nextItem = sandboxQueue.shift();
          if (nextItem) {
            nextItem(id);
          }
        }
        setTimeout(pollSandboxPromises, PROMISE_LOOP_TIMEOUT);
      }
    };

    const pollS3Promises = async () => {
      if (!killPromiseLoops) {
        let thisBatch: Promise<void>[] = [];
        for (let i = 0; i < S3_LOOP_CONCURRENT_COUNT; ++i) {
          if (s3Queue.length) {
            // Forcing type "Promise<void>" instead of "Promise<void> | undefined" on the shift
            // because I'm checking above that it's not empty, so it won't be undefined.
            thisBatch.push(s3Queue.shift() as Promise<void>);
          } else {
            break;
          }
        }
        await Promise.all(thisBatch);
        setTimeout(pollS3Promises, PROMISE_LOOP_TIMEOUT);
      }
    };
    let slidePromises: Promise<{ type: string; [propName: string]: any }>[] = [];
    for (let slide of slides) {
      slidePromises.push(
        (async () => ({
          type: slide.type,
          ...(await slide.generate(
            slideContext,
            (stateMap || {})[slide.id],
            sharedState,
            claimSandbox,
            releaseSandbox,
            addS3Image
          )),
        }))()
      );
    }

    pollSandboxPromises();
    pollS3Promises();

    let slideData: { type: string; [propName: string]: any }[] = [];
    try {
      slideData = await Promise.all(slidePromises);
    } catch (e) {
      killPromiseLoops = true;
      let error = e as Error;
      setError({ message: error.message });
      setGenerating(false);
      return;
    }

    killPromiseLoops = true;

    if (sandboxRef.current) {
      ReactDOM.unmountComponentAtNode(sandboxRef.current);
    }

    try {
      let [requestsId, fileId] = await Promise.all([
        (async () => {
          let res = await SlidesLambdaFetch("/requests", {
            method: "POST",
            body: {
              company,
              date: today,
              slides: JSON.stringify(slideData),
            },
          });
          let { id } = await awaitJSON(res);

          await poll({
            fetchMaker: async () => {
              let res = await ToolsLambdaFetch("/s3_exists", {
                params: { bucket: SLIDE_ASSET_S3_BUCKET, key: `${id}.json` },
              });
              let existObj = await awaitJSON<{ exists: boolean }>(res);
              return existObj.exists;
            },
            errorCheckMaker: () =>
              makePollingErrorChecker(() =>
                S3SignedUrlFetch2(SLIDE_ASSET_S3_BUCKET, `${id}.error.txt`, "text/plain")
              ),
            timeout: 60 * 5,
          });

          return id;
        })(),
        (async () => {
          let res = await SlidesLambdaFetch("/empty_deck", {
            method: "POST",
            body: {
              company,
              access_token: googleAccessToken,
            },
          });
          let { id } = await awaitJSON(res);
          return id;
        })(),
      ]);
      let res = await SlidesLambdaFetch("/generate", {
        method: "POST",
        body: {
          requestsId,
          fileId,
          access_token: googleAccessToken,
          company,
        },
      });
      let { id } = await awaitJSON(res);
      setResultID(id);
    } catch (e) {
      let error = e as Error;
      setError({
        message: error.message,
        reportError: error,
      });
    }

    setGenerating(false);
  }, [
    slides,
    stateMap,
    slideContext,
    setError,
    company,
    today,
    isLoading,
    generating,
    sharedState,
  ]);

  const [downloadLink, altDownloadLink, googleSlidesLink] = useMemo(() => {
    if (!resultID) {
      return [];
    }
    let end = `d/${resultID}/export/pptx`;
    return [
      `${GOOGLE_DOCS_URL_PREFIX}/${end}`,
      `${GOOGLE_DOCS_URL_PREFIX}/u/1/${end}`,
      `${GOOGLE_DOCS_URL_PREFIX}/d/${resultID}`,
    ];
  }, [resultID]);

  let generateText = "";
  if (isLoading) {
    generateText = "Loading...";
  } else if (generating) {
    generateText = "Generating...";
  } else if (resultID) {
    generateText = "Regenerate";
  } else {
    generateText = "Generate";
  }

  const deckSlideListRef = useRef<HTMLDivElement>(null);
  const legendRef = useRef<HTMLDivElement>(null);

  const [scrolledItem, setScrolledItem] = useState<string>((slides || [])[0]?.id);

  useLayoutEffect(() => {
    if (!slides) {
      return;
    }
    let scrollSetter = () => {
      const parentRect = deckSlideListRef.current?.getBoundingClientRect();
      if (!parentRect) {
        return;
      }
      for (let { id } of slides) {
        const elemBox = document.getElementById(id)?.getBoundingClientRect();
        if (elemBox && elemBox.y + elemBox.height - parentRect.y > 0) {
          setScrolledItem(existing => {
            if (existing !== id) {
              const legendRect = legendRef.current?.getBoundingClientRect();
              let listItem = document.getElementById(`slidePageLegendItem_${id}`);
              let itemRect = listItem?.getBoundingClientRect();
              if (
                listItem &&
                legendRect &&
                itemRect &&
                (legendRect.y + legendRect.height - (itemRect.y + itemRect.height) < 0 ||
                  itemRect.y < legendRect.y)
              ) {
                listItem.scrollIntoView();
              }
            }
            return id;
          });
          return;
        }
      }
    };
    scrollSetter();
    let interval = setInterval(scrollSetter, 600);
    return () => {
      clearInterval(interval);
    };
  }, [slides, setScrolledItem]);

  const [addSlideForm, setAddSlideForm] = useState<SlideTemplate | "no default">();

  const addSlide = useCallback(
    (template: SlideTemplate, name: string) => {
      const SlideClass = SLIDE_TYPE_MAP[template.type];
      if (SlideClass) {
        let slide = new SlideClass(name);

        setSlidesInfoRaw(({ slides, stateMap }) => ({
          slides: [...slides, slide],
          stateMap: {
            ...stateMap,
            [slide.id]: {
              ...SlideClass.defaultState,
              ...R.clone(template.state),
            } as SlideState,
          },
        }));
        setAddSlideForm(undefined);
      } else {
        setError({ message: `"${template.type}" is not a valid type of slide` });
      }
    },
    [setError]
  );

  const deleteSlide = useCallback(
    (deleteId: string) => {
      if (slides) {
        let slideIndex = slides.findIndex(({ id }) => id === deleteId);
        if (slideIndex > -1) {
          let slide = slides[slideIndex] as SlideType;
          setAreYouSure({
            title: "Delete Slide?",
            message: `You're about to delete slide "${slide.name}". Are you sure?`,
            okayText: "Delete Slide",
            cancelText: "Never mind",
            onOkay: () => {
              setSlidesInfoRaw((existing: SlidesInfo) => ({
                slides: R.remove(slideIndex, 1, existing.slides),
                stateMap: R.omit([deleteId], existing.stateMap),
              }));
            },
          });
        }
      }
    },
    [setAreYouSure, slides]
  );

  const [newDeckForm, setNewDeckForm] = useState(false);

  const onNewDeckFormSubmit = useCallback(
    (name: string, template: SlideTemplate[]) => {
      setNewDeckForm(false);
      loadNewDeck(name, template);
    },
    [loadNewDeck]
  );

  const onDrop = useCallback(
    (itemId: string, targetId: string) => {
      let itemIndex = -1;
      let targetIndex = -1;
      for (let i = 0; i < slides.length; ++i) {
        let { id } = slides[i];
        if (itemId === id) {
          itemIndex = i;
        }
        if (targetId === id) {
          targetIndex = i;
        }
        if (itemIndex !== -1 && targetIndex !== -1) {
          break;
        }
      }
      setSlidesInfoRaw(existing => ({
        ...existing,
        slides: R.move(itemIndex, targetIndex - (itemIndex < targetIndex ? 1 : 0), slides),
      }));
    },
    [slides]
  );

  const onDropEnd = useCallback(
    (itemId: string) => {
      let index = slides.findIndex(({ id }) => id === itemId);
      setSlidesInfoRaw(existing => ({
        ...existing,
        slides: R.move(index, slides.length - 1, slides),
      }));
    },
    [slides]
  );

  return (
    <Page
      title={isMobiusPage ? "Mobius Slides" : "Slides"}
      pageType="Slides"
      minWidth="900px"
      actions={
        <div className="slidesPageActionBar">
          <FeedbackModalButton epic="DEV-56" />
          <BPMButton
            variant="outline-primary"
            as="a"
            href="https://blisspointmedia.atlassian.net/secure/Dashboard.jspa?selectPageId=10107"
            target="_blank"
            rel="noopener noreferrer"
          >
            View JIRA Dashboard
          </BPMButton>
        </div>
      }
    >
      <div className="slidesPageContainer">
        {templateFetchStatus === "fetched" ? (
          <>
            <div className="controls">
              <div className="buttons">
                {company && (
                  <BPMButton onClick={generate} disabled={isLoading || generating}>
                    {generateText}
                  </BPMButton>
                )}
                {resultID && (
                  <>
                    <BPMButton as="a" href={downloadLink} target="_blank" rel="noopener noreferrer">
                      Download
                    </BPMButton>
                    <BPMButton
                      as="a"
                      href={googleSlidesLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open with Google Slides
                    </BPMButton>
                    <a
                      className="permissionsHelp"
                      href={altDownloadLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Permissions issues? Try this.
                    </a>
                  </>
                )}
              </div>
              {company && (
                <TemplatePicker
                  templateId={selectedTemplate}
                  options={templateOptions}
                  selectTemplate={selectTemplate}
                  openNewSlideForm={() => setNewDeckForm(true)}
                  onSave={saveTemplate}
                  onDelete={deleteTemplate}
                />
              )}
            </div>
            <div className="workspace">
              {!R.isNil(selectedTemplate) && (
                <div className="tableOfContents sleekScroll" ref={legendRef}>
                  {slides.map(({ name, id }) => (
                    <LegendItem
                      key={id}
                      id={id}
                      name={name}
                      scrolledItem={scrolledItem}
                      setScrolledItem={setScrolledItem}
                      onDrop={onDrop}
                    />
                  ))}
                  <AddSlideButton
                    onClick={() => setAddSlideForm("no default")}
                    onDrop={onDropEnd}
                  />
                </div>
              )}
              {company && (
                <div className="deckSlideList" ref={deckSlideListRef}>
                  {slides.length ? (
                    slides.map(slide => (
                      <div key={slide.id} id={slide.id} className="slideSettingsContainer">
                        <div className="slideHeader">
                          <div className="slideName">
                            <div className="name">{slide.name}</div>
                            {slide.displayName !== slide.name && (
                              <small className="type">({slide.displayName})</small>
                            )}
                          </div>
                          <BPMButton
                            className="toggleSlideButton"
                            size="sm"
                            variant="outline-primary"
                            onClick={() =>
                              setAddSlideForm({
                                type: slide.type,
                                name: `${slide.name} (Copy)`,
                                caption: `Copy of "${slide.name}".`,
                                state: stateMap[slide.id],
                              })
                            }
                          >
                            <MdContentCopy />
                          </BPMButton>
                          <BPMButton
                            className="toggleSlideButton"
                            size="sm"
                            variant="outline-danger"
                            onClick={() => deleteSlide(slide.id)}
                          >
                            <MdDeleteForever />
                          </BPMButton>
                        </div>
                        <slide.Settings
                          id={slide.id}
                          slideContext={slideContext}
                          state={stateMap[slide.id]}
                          setState={slideStateSetterMap[slide.id]}
                          setLoading={slideLoadingSetterMap[slide.id]}
                          sharedState={sharedState}
                          sharedFetch={sharedFetch}
                        />
                      </div>
                    ))
                  ) : R.isNil(selectedTemplate) ? (
                    <div className="bigAddSlides" onClick={() => setNewDeckForm(true)}>
                      Create a New Deck
                    </div>
                  ) : (
                    <div className="bigAddSlides" onClick={() => setAddSlideForm("no default")}>
                      Add the First Slide
                    </div>
                  )}
                </div>
              )}
            </div>
            {(isLoading || generating) && <div className="loadingOverlay" />}
            {addSlideForm && company && (
              <AddSlideForm
                slides={slides}
                onClose={() => setAddSlideForm(undefined)}
                onSelect={addSlide}
                defaultTemplate={addSlideForm === "no default" ? undefined : addSlideForm}
                isMobiusPage={isMobiusPage}
              />
            )}
            {newDeckForm && company && (
              <NewDeckForm
                slides={slides}
                stateMap={stateMap}
                onClose={() => setNewDeckForm(false)}
                onSubmit={onNewDeckFormSubmit}
                templateNames={templateNames}
                isMobiusPage={isMobiusPage}
              />
            )}
          </>
        ) : (
          <SlidePageSkeleton />
        )}
      </div>
      <div ref={sandboxRef} className="slideGenerateFloatingSandbox" />
    </Page>
  );
};

export default Slides;
