import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";

import { BPMButton, Page, Spinner } from "../Components";
import { DagLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { useSetError } from "../redux/modals";

import { Form } from "react-bootstrap";

import "./DagStart.scss";
import { useSelector } from "react-redux";
import * as UserRedux from "./../redux/user";

const DagStart: React.FC = () => {
  const isAdmin = useSelector(UserRedux.isAdminSelector);

  const setError = useSetError();
  const [dagNames, setDagNames] = useState<string[]>();
  const [tasks, setTasks] = useState<string[]>();
  const [taskNames, setTaskNames] = useState<string[]>();
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedTask, setSelectedTask] = useState<string>("");
  const [selectedTaskname, setSelectedTaskName] = useState<string>("");
  const [messageStart, setMessageStart] = useState<string[]>();
  const [savingStart, setSavingStart] = useState(false);

  const [selectedNameStartSingleJob, setSelectedNameStartSingleJob] = useState<string>("");
  const [selectedTasknameStartSingleJob, setSelectedTaskNameStartSingleJob] = useState<string>("");
  const [taskNamesStartSingleJob, setTaskNamesStartSingleJob] = useState<string[]>();
  const [messageStartSingleJob, setMessageStartSingleJob] = useState<string[]>();
  const [savingStartSingleJob, setSavingStartSingleJob] = useState(false);

  const [select, setSelect] = useState("");
  const [exclude, setExclude] = useState("");

  useEffect(() => {
    if (!dagNames) {
      (async () => {
        const res = await DagLambdaFetch<string>("/dag_graphs");
        const dags = await awaitJSON(res);

        let dagNamesHolder: string[] = [];
        for (const dagObj of dags) {
          dagNamesHolder.push(dagObj.name);
        }
        dagNamesHolder.sort();
        setDagNames(dagNamesHolder);
      })();
    }
  });

  const dagOptionList = useMemo(() => (dagNames || []).map(value => ({ value, label: value })), [
    dagNames,
  ]);

  interface getTasksFromGraphNameBody {
    dagName: string;
  }

  const getTasksFromDagName = async (name: string, startSingleJob: boolean) => {
    const res = await DagLambdaFetch<getTasksFromGraphNameBody>("/get_tasks_from_dag_name", {
      method: "GET",
      params: {
        dagName: name,
      },
    });
    const tasks = await awaitJSON(res);

    let tasksHolder: string[] = [];
    let taskNamesHolder: string[] = [];
    for (const task of tasks) {
      tasksHolder.push(task.task);
      taskNamesHolder.push(task.task_name);
    }
    tasksHolder.sort();
    taskNamesHolder.sort();
    setTasks(tasksHolder);
    startSingleJob ? setTaskNamesStartSingleJob(taskNamesHolder) : setTaskNames(taskNamesHolder);
  };

  const taskOptionList = useMemo(() => (tasks || []).map(value => ({ value, label: value })), [
    tasks,
  ]);

  const taskNameOptionListStartSingleJob = useMemo(
    () => (taskNamesStartSingleJob || []).map(value => ({ value, label: value })),
    [taskNamesStartSingleJob]
  );

  const taskNameOptionList = useMemo(
    () => (taskNames || []).map(value => ({ value, label: value })),
    [taskNames]
  );

  const submitStart = async () => {
    try {
      setSavingStart(true);
      const overrideCheckbox = document.getElementById("overrideCheckbox") as HTMLInputElement;
      const override = overrideCheckbox?.checked;
      const res = await DagLambdaFetch("/start", {
        method: "POST",
        body: {
          dag: selectedName,
          start_task: selectedTask,
          start_task_name: selectedTaskname,
          override: override,
          retries: 0,
        },
      });
      const message = await awaitJSON(res);

      const messageHolder: string[] = [];
      messageHolder.push(message.success);
      messageHolder.push(message.message);
      setMessageStart(messageHolder);

      setSavingStart(false);
    } catch (e) {
      setSavingStart(false);

      let error: Error = e as Error;
      setError({
        message: `Failed to start dag: ${error.message}`,
        reportError: error,
      });
    }
  };

  const submitStartSingleJob = async () => {
    try {
      setSavingStartSingleJob(true);
      const overrideCheckboxStartSingleJob = document.getElementById(
        "overrideCheckboxStartSingleJob"
      ) as HTMLInputElement;
      const overrideStartSingleJob = overrideCheckboxStartSingleJob?.checked;
      const res = await DagLambdaFetch("/start_single_job", {
        method: "POST",
        body: {
          dag: selectedNameStartSingleJob,
          task_name: selectedTasknameStartSingleJob,
          select: select,
          exclude: exclude,
          override: overrideStartSingleJob,
          retries: 0,
        },
      });
      const message = await awaitJSON(res);

      const messageHolder: string[] = [];
      messageHolder.push(message.success);
      messageHolder.push(message.message);
      setMessageStartSingleJob(messageHolder);

      setSavingStartSingleJob(false);
    } catch (e) {
      setSavingStartSingleJob(false);

      let error: Error = e as Error;
      setError({
        message: `Failed to start dag: ${error.message}`,
        reportError: error,
      });
    }
  };

  return (
    <Page title="Dag Start" pageType="dag start" minHeight="600px">
      <div className="dagStartPageContainer">
        <div className="inputs">
          <div className="selector">
            <label>Dag Name</label>
            <Select
              className="dagNameSelect"
              isSearchable={true}
              isClearable={true}
              options={dagOptionList}
              onChange={name => {
                if (!name) {
                  return;
                }
                setSelectedName(name.value);
                getTasksFromDagName(name.value, false);
              }}
            />
          </div>
          <div className="selector">
            <label>Task Select (optional)</label>
            <Select
              className="taskSelect"
              isSearchable={true}
              isClearable={true}
              options={taskOptionList}
              onChange={task => {
                if (!task) {
                  return;
                }
                setSelectedTask(task.value);
              }}
            />
          </div>
          <div className="selector">
            <label>Task Name Select (optional)</label>
            <Select
              className="taskNameSelect"
              isSearchable={true}
              isClearable={true}
              options={taskNameOptionList}
              onChange={taskName => {
                if (!taskName) {
                  return;
                }
                setSelectedTaskName(taskName.value);
              }}
            />
          </div>
          <div className="selector">
            <label>
              <input type="checkbox" id="overrideCheckbox"></input> Override? (optional)
            </label>
          </div>
          <div className="submitButton">
            <BPMButton
              disabled={savingStart}
              onClick={e => {
                submitStart();
              }}
            >
              {savingStart ? <Spinner color="white" /> : "Submit"}
            </BPMButton>
          </div>
        </div>
        <div className="lambdaOutput">
          {messageStart ? (
            <div>
              <hr></hr>
              <br></br>
              <h5>Lambda Output</h5>
              <span>Success: {messageStart[0].toString()}</span>
              <br></br>
              <span>Message: </span>
              <br></br>
              <span>{messageStart[1]}</span>
            </div>
          ) : (
            <b></b>
          )}
        </div>
      </div>
      {isAdmin && (
        <div className="dagStartPageContainer">
          <div className="inputs">
            <div className="selector">
              <label>Dag Name</label>
              <Select
                className="dagNameSelect"
                isSearchable={true}
                isClearable={true}
                options={dagOptionList}
                onChange={name => {
                  if (!name) {
                    return;
                  }
                  setSelectedNameStartSingleJob(name.value);
                  getTasksFromDagName(name.value, true);
                }}
              />
            </div>
            <div className="selector">
              <label>Task Name Select</label>
              <Select
                className="taskNameSelect"
                isSearchable={true}
                isClearable={true}
                options={taskNameOptionListStartSingleJob}
                onChange={taskName => {
                  if (!taskName) {
                    return;
                  }
                  setSelectedTaskNameStartSingleJob(taskName.value);
                }}
              />
            </div>
            <Form.Group className="dagStartRow">
              <div className="dagStartleft">
                <Form.Label className="dagStartFontSize">Select (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  value={select}
                  onChange={e => setSelect(e.target.value)}
                />
              </div>
              <div className="dagStartright">
                <Form.Label className="dagStartFontSize">Exclude (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  value={exclude}
                  onChange={e => setExclude(e.target.value)}
                />
              </div>
            </Form.Group>
            <div className="selector">
              <label>
                <input type="checkbox" id="overrideCheckboxStartSingleJob"></input> Override?
                (optional)
              </label>
            </div>
            <div className="submitButton">
              <BPMButton
                disabled={savingStartSingleJob}
                onClick={e => {
                  submitStartSingleJob();
                }}
              >
                {savingStartSingleJob ? <Spinner color="white" /> : "Submit"}
              </BPMButton>
            </div>
          </div>
          <div className="lambdaOutput">
            {messageStartSingleJob ? (
              <div>
                <hr></hr>
                <br></br>
                <h5>Lambda Output</h5>
                <span>Success: {messageStartSingleJob[0].toString()}</span>
                <br></br>
                <span>Message: </span>
                <br></br>
                <span>{messageStartSingleJob[1]}</span>
              </div>
            ) : (
              <b></b>
            )}
          </div>
        </div>
      )}
    </Page>
  );
};

export default DagStart;
