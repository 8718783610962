import React, { useEffect, useState } from "react";
import { SlideState } from "./slideTemplateConstants";
import { SlideType, SlideContext, SettingsComponentProps } from "./slidesTypes";
import { Form } from "react-bootstrap";

import { DateRange } from "../utils/types";
import { BPMDateRange, Dropdown, DropdownToggleType, BPMToggleButton } from "../Components";
import * as R from "ramda";
import { awaitJSON, MobiusSlidesLambdaFetch } from "../utils/fetch-utils";
import { GrowthRate } from "@blisspointmedia/bpm-types/dist/MobiusSlides";
import "./PaidMediaBreakdown.scss";

export interface PaidMediaBreakdownSlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  dateStart: string;
  dateEnd: string;
  byMonth: boolean;
  growthRate: string;
}

interface PaidMediaBreakdownSlideData {
  title: string;
  subTitle: string;
  fetchedPaidMediaBreakdownCurrentData: string[][];
  fetchedPaidMediaBreakdownPreviousData: string[][];
}

class PaidMediaBreakdownSlide extends SlideType {
  static typeKey = "PaidMediaBreakdown";
  static displayKey = "PaidMediaBreakdown";
  static defaultState: PaidMediaBreakdownSlideState = {
    byMonth: true,
    dates: { start: "2023-01-01", end: "2023-04-01" },
    dateStart: "2023-01-01",
    dateEnd: "2023-04-01",
    title: "Paid Media Breakdown by Channel - YoY",
    subTitle: "QBP - Q1 2023 - January 1 - March 31",
    growthRate: "yearly",
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<PaidMediaBreakdownSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const [minDate, setMinDate] = useState<string>("");
    const [maxDate, setMaxDate] = useState<string>("");
    const [dates, setDates] = useState<DateRange>({ start: "2023-01-01", end: "2023-04-01" });
    let { company } = slideContext;

    useEffect(() => {
      (async () => {
        let res = await MobiusSlidesLambdaFetch("/getMinMaxDate", {
          params: { company },
        });
        let results = await awaitJSON(res);
        const minDate = results.data[0][0].month;
        const maxDate = results.data[1][0].month;
        setMinDate(minDate);
        setMaxDate(maxDate);
        setState({ dateStart: minDate });
        setState({ dateEnd: maxDate });
        setDates({ start: minDate, end: maxDate });
      })();
    }, [company, setState]);

    const { byMonth, growthRate, title, subTitle } = state;
    const growthRatesDropdownOptions: {
      value: string;
      label: string;
    }[] = [
      { value: GrowthRate.YEARLY, label: GrowthRate.YEARLY },
      { value: GrowthRate.QUARTERLY, label: GrowthRate.QUARTERLY },
      { value: GrowthRate.MONTHLY, label: GrowthRate.MONTHLY },
    ];

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="paidMediaBreakdownInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              isOutsideRange={date => !minDate || !maxDate || date < minDate || date > maxDate}
              onChange={option => {
                setDates(option);
                setState({ dateStart: option.start });
                setState({ dateEnd: option.end });
              }}
            />
          </div>
          <div>
            <Form.Label> Dimension</Form.Label>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Channel",
                },
                {
                  key: "Month",
                },
              ]}
              selectedOption={byMonth ? "Month" : "Channel"}
              onChange={key => setState({ byMonth: key === "Month" })}
            />
          </div>
          <div>
            <Form.Label> Growth Rate</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={growthRate}
              options={growthRatesDropdownOptions}
              onChange={change => setState({ growthRate: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState
  ): Promise<PaidMediaBreakdownSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      dateStart,
      dateEnd,
      byMonth,
      growthRate,
    } = state as PaidMediaBreakdownSlideState;
    const fields = {
      company,
      dateStart,
      dateEnd,
      byMonth,
      growthRate,
    };
    let results;
    try {
      let res = await MobiusSlidesLambdaFetch("/paid_media_breakdown", {
        params: { ...fields },
      });
      results = await awaitJSON(res);
    } catch (e) {
      throw new Error("No data available");
    }
    const currentYearRes = results.data[0];
    const previousYearRes = results.data[1];
    if (byMonth && previousYearRes.length === currentYearRes.length) {
      currentYearRes.sort((a, b) => a.month.localeCompare(b.month));
      previousYearRes.sort((a, b) => a.month.localeCompare(b.month));
    } else if (byMonth) {
      currentYearRes.sort((a, b) => a.month.localeCompare(b.month));
    }

    const extractValues = R.pipe(R.map(R.values));
    const extractColumnsChannel = R.pipe(
      R.map(R.pick(["platform", "cost", "orders", "revenue", "cpa", "clicks"]))
    );
    const extractColumnsMonth = R.pipe(
      R.map(R.pick(["month", "cost", "orders", "revenue", "cpa", "clicks"]))
    );

    let dataCurrent = byMonth
      ? extractColumnsMonth(currentYearRes)
      : extractColumnsChannel(currentYearRes);
    let fetchedPaidMediaBreakdownCurrentData: string[][] = extractValues(dataCurrent);
    let headerInfo = R.keys(dataCurrent[0]);
    fetchedPaidMediaBreakdownCurrentData.unshift(headerInfo);

    let dataPreviousYear;
    let fetchedPaidMediaBreakdownPreviousData: string[][];

    if (previousYearRes.length === currentYearRes.length) {
      dataPreviousYear = byMonth
        ? extractColumnsMonth(previousYearRes)
        : extractColumnsChannel(previousYearRes);
      fetchedPaidMediaBreakdownPreviousData = extractValues(dataPreviousYear);
      fetchedPaidMediaBreakdownPreviousData.unshift(headerInfo);
    } else {
      fetchedPaidMediaBreakdownPreviousData = [];
    }

    return Promise.resolve({
      title,
      subTitle,
      fetchedPaidMediaBreakdownCurrentData,
      fetchedPaidMediaBreakdownPreviousData,
    });
  };
}
export default PaidMediaBreakdownSlide;
