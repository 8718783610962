import React, { useState, useEffect, useMemo } from "react";

import * as R from "ramda";

import { Tooltip } from "react-bootstrap";

import { useSetError } from "../redux/modals";

import { StreamingOptimizationsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { HEAT_MAP_GREEN, HEAT_MAP_YELLOW, HEAT_MAP_RED, makeHeatMap } from "../utils/colors";

import { BPMTable, Header, FullPageSpinner, Img, OverlayTrigger } from "../Components";

import "./StreamingStageTwoDiagnostics.scss";

const HEAT_MAP_WHITE = "ffffff";
const HEAT_MAP_MID_WHITE = "b3ffd9";
const COLUMN_WIDTH = 150;

const createHeatMap = (rows, key, lowColor, midColor, highColor) => {
  let sorted: number[] = [];
  for (let row of rows) {
    let val = row[key];
    if (!R.isNil(val)) {
      sorted.push(val);
    }
  }
  sorted = R.sortBy(R.identity, sorted);

  let lowerPercentile = 0.05;
  let upperPercentile = 0.95;

  let min = sorted[Math.round((sorted.length - 1) * lowerPercentile)];
  let max = sorted[Math.round((sorted.length - 1) * upperPercentile)];

  let median = sorted[Math.floor(sorted.length / 2)];

  return makeHeatMap({
    min,
    max,
    midpoint: median,
    lowColor,
    midColor,
    highColor,
  });
};

const heatMapRenderer = (heatMap, key, formatAsDollar = false) => row => {
  let val = row[key];
  let hasValue = !R.isNil(row[key]) && !isNaN(val);
  let classes = ["heatMapCell"];

  return (
    <div className={classes.join(" ")} style={hasValue ? { backgroundColor: heatMap(val) } : {}}>
      {hasValue
        ? `${formatAsDollar ? "$" : ""}${val.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "-"}
    </div>
  );
};

interface StreamingStageTwoDiagnosticsProps {
  company: string;
  branch: string;
  platform: string;
  showDevOSCat: boolean;
  showUnseen: boolean;
  setTimestamp;
  setFilteredData;
  setKpi;
}

interface StageTwoDiagnosticsRow {
  placement: string;
  devcatos?: string;
  display_name?: string;
  pred_cr_per_m?: number;
  cpm: number;
  pred_cost_per_conversion?: number;
  pred_cr_per_dollar?: number;
  is_seen: string;
}

interface StageTwoDianosticsResult {
  rows: StageTwoDiagnosticsRow[];
  kpi: string;
  stageTwoFileTimeStamp: string;
}

const StageTwoDiagnostics: React.FC<StreamingStageTwoDiagnosticsProps> = ({
  company,
  branch,
  platform,
  setTimestamp,
  showDevOSCat,
  showUnseen,
  setFilteredData,
  setKpi,
}) => {
  const correctedPlatform = platform === "Audio" ? "audio" : "streaming";
  const setError = useSetError();
  const [dataMap, setDataMap] = useState({});

  let data = dataMap[`${company}_${branch}_${correctedPlatform}_${showDevOSCat}`];

  useEffect(() => {
    return () => {
      setTimestamp();
    };
  }, [setTimestamp]);

  useEffect(() => {
    const key = `${company}_${branch}_${correctedPlatform}_${showDevOSCat}`;
    if (dataMap[key]) {
      setTimestamp(dataMap[key].stageTwoFileTimeStamp);
      return;
    }
    let bail = false;
    (async () => {
      try {
        let res = await StreamingOptimizationsLambdaFetch("/stage_two_diagnostics", {
          params: {
            company,
            branch,
            prefix: showDevOSCat ? "placement_devcatos" : "placement",
            platform: correctedPlatform,
          },
        });
        let data = (await awaitJSON(res)) as StageTwoDianosticsResult;
        if (bail) {
          return;
        }
        setDataMap(current => ({
          ...current,
          [key]: data,
        }));
        setTimestamp(data.stageTwoFileTimeStamp);
        setKpi(data.kpi);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
    return () => {
      bail = true;
    };
  }, [dataMap, company, branch, correctedPlatform, setError, showDevOSCat, setTimestamp, setKpi]);

  // Create Headers and Super Headers
  const [headers] = useMemo(() => {
    if (!data || R.isEmpty(data)) {
      return [];
    }

    let headers: Header[] = [
      {
        label: "Placement",
        name: "display_name",
        width: 400,
        sortPriority: 1,
        sortAscending: true,
        renderer: data => {
          const network = data.placement.split("_")[0];
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
              overlay={<Tooltip id={data.placement}>{network}</Tooltip>}
            >
              <div className="stageTwoNetworkLogos">
                <Img src={`https://cdn.blisspointmedia.com/networks/${network}.png`} />
                {data.display_name}
              </div>
            </OverlayTrigger>
          );
        },
      },
    ];
    if (showDevOSCat) {
      headers = R.concat(headers, [
        {
          label: "DevOSCat",
          name: "devcatos",
          width: COLUMN_WIDTH,
          renderer: data => {
            const devcatos = data.devcatos.toUpperCase();
            let devcatosImage = devcatos;
            switch (devcatosImage) {
              case "LAPTOP":
                devcatosImage = "DESKTOP";
                break;
              case "MOBILE":
                devcatosImage = "PHONE";
                break;
              case "CTV":
                devcatosImage = "TV";
                break;
            }
            return (
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                overlay={<Tooltip id={`${data.placement}_${data.devcatos}`}>{devcatos}</Tooltip>}
              >
                <div className="stageTwoNetworkLogos">
                  <Img src={`https://cdn.blisspointmedia.com/networks/${devcatosImage}.png`} />
                  {devcatos}
                </div>
              </OverlayTrigger>
            );
          },
        },
      ]);
    }

    headers = R.concat(headers, [
      {
        label: "Predicted Conversion Rate per 1000 Imps",
        name: "pred_cr_per_m",
        width: COLUMN_WIDTH,
        nonFilterable: true,
        renderer: heatMapRenderer(
          createHeatMap(
            data.rows,
            "pred_cr_per_m",
            HEAT_MAP_WHITE,
            HEAT_MAP_MID_WHITE,
            HEAT_MAP_GREEN
          ),
          "pred_cr_per_m"
        ),
      },
      {
        label: "CPM",
        name: "cpm",
        width: COLUMN_WIDTH,
        nonFilterable: true,
        renderer: row => {
          return `$${row.cpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      {
        label: "Predicted Cost per Conversion",
        name: "pred_cost_per_conversion",
        width: COLUMN_WIDTH,
        nonFilterable: true,
        renderer: heatMapRenderer(
          createHeatMap(
            data.rows,
            "pred_cost_per_conversion",
            HEAT_MAP_GREEN,
            HEAT_MAP_YELLOW,
            HEAT_MAP_RED
          ),
          "pred_cost_per_conversion",
          true
        ),
      },
      {
        label: "Predicted Conversion Rate per Dollar",
        name: "pred_cr_per_dollar",
        width: COLUMN_WIDTH,
        nonFilterable: true,
        renderer: heatMapRenderer(
          createHeatMap(
            data.rows,
            "pred_cr_per_dollar",
            HEAT_MAP_RED,
            HEAT_MAP_YELLOW,
            HEAT_MAP_GREEN
          ),
          "pred_cr_per_dollar"
        ),
      },
    ]);

    return [headers];
  }, [data, showDevOSCat]);

  const displayTable = useMemo(() => {
    if (!data || R.isEmpty(data)) {
      return false;
    }
    data.rows = R.map((row: StageTwoDiagnosticsRow) => {
      const network = row.placement.split("_")[0];
      const description = row.placement.split("_")[1];
      const displayName = `${network} ${description}`.trim();
      row.display_name = displayName;
      return row;
    }, data.rows as StageTwoDiagnosticsRow[]) as StageTwoDiagnosticsRow[];
    if (showUnseen) {
      return data.rows;
    } else {
      return R.filter(row => row.is_seen === "t", data.rows as StageTwoDiagnosticsRow[]);
    }
  }, [data, showUnseen]);

  return (
    <div className="streamingStageTwoDiagnostics">
      {displayTable ? (
        !R.isEmpty(displayTable) ? (
          <BPMTable
            alternateColors={false}
            noRowsRenderer={() => <div className="noFailures">No data to show</div>}
            data={displayTable}
            headers={headers}
            onFilteredDataChange={setFilteredData}
          />
        ) : (
          <div className="noStageTwoData">No Stage Two Data for this KPI</div>
        )
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

export default StageTwoDiagnostics;
