import React, { useMemo } from "react";

import { Dropdown as BSDropdown } from "react-bootstrap";

import "./DownloadDropdown.scss";
import { DownloadToggle } from "./DownloadToggle";

export enum DownloadToggleType {
  FILLED = "filled",
  OUTLINED = "outlined",
  EMPTY = "empty",
}

interface DownloadDropdownProps {
  type?: DownloadToggleType;
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  onClickOptions: (() => void)[];
  menuOptions?: string[];
  disabledMenuOptions?: { XLSX: boolean; PNG: boolean };
  size?: "sm" | "lg";
  className?: string;
  disabled?: boolean;
}

const MENU_OPTIONS = ["XLSX", "PNG"];

export const DownloadDropdown: React.FC<DownloadDropdownProps> = React.memo(
  ({
    type = DownloadToggleType.FILLED,
    design = "primary",
    background = "light",
    onClickOptions,
    menuOptions = MENU_OPTIONS,
    disabledMenuOptions = { XLSX: false, PNG: false },
    size = "lg",
    disabled,
  }: DownloadDropdownProps): JSX.Element => {
    let items = useMemo(
      () =>
        menuOptions.reduce((menuItems, option, index) => {
          if (!disabledMenuOptions[option]) {
            menuItems.push(
              <BSDropdown.Item
                key={option}
                onClick={() => {
                  onClickOptions[index]();
                }}
              >
                {option}
              </BSDropdown.Item>
            );
          }
          return menuItems;
        }, [] as JSX.Element[]),
      [menuOptions, disabledMenuOptions, onClickOptions]
    );

    let menu = useMemo(() => {
      return <BSDropdown.Menu className={`cl-download-menu ${size}`}>{items}</BSDropdown.Menu>;
    }, [size, items]);

    return (
      <BSDropdown className="cl-download">
        <DownloadToggle
          type={type}
          design={design}
          background={background}
          size={size}
          disabled={disabled}
        />
        {menu}
      </BSDropdown>
    );
  }
);
