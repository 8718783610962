import React, { useContext, useEffect, useState } from "react";
import { BPMTable, ButtonType, OverlayTrigger, Button, FullPageSpinner } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { MdDelete, MdEdit, MdRule, MdMoreHoriz, MdPlayArrow } from "react-icons/md";
import { BiSolidDuplicate } from "react-icons/bi";
import { Tooltip } from "react-bootstrap";
import WtoStatus from "./WtoStatus";
import * as Dfns from "date-fns/fp";
import { FormContext } from "./WtoFormContext";
import "./WtoTable.scss";
import "../Components/BreadcrumbTitle.scss";
import * as R from "ramda";
import { Plover } from "@blisspointmedia/bpm-types/dist/webTrafficOptimization";
import { joinBackslashes } from "./WtoUtils";

const INNER_CELL_HEIGHT = 70;

export declare type ModalType = null | "Archive Route" | "Duplicate Route" | "Bulk Plover Upload";

const FILTER_OPTIONS = ["All", "Live", "Draft", "Archive"];
const DATE_FORMAT = "EEE, LLL dd, yyyy, hh:mm aa";

export interface WtoTableProps {
  data: Record<string, any>[];
  useCopyPlover: () => { tooltipText; setTooltipText; copyPloverUrl };
  setModalData: (modalType: string, data: Record<string, any>) => void;
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
  loading: boolean;
  domains: Record<string, any>[];
}

export const WtoTable: React.FC<WtoTableProps> = ({
  data,
  useCopyPlover,
  setModalData,
  setBreadcrumbPath,
  loading,
  domains,
}) => {
  const [statusFilter, setStatusFilter] = useState<typeof FILTER_OPTIONS[number]>("All");
  const [showOverlayMenu, setShowOverlayMenu] = useState(false);
  const [overlayMenuData, setOverlayMenuData] = useState<any>(null);
  const [overlayMenuDirection, setOverlayMenuDirection] = useState(["bottom", "left"]);
  const [overlayMenuCoords, setOverlayMenuCoords] = useState({ x: -1, y: -1 });
  const { setWtoFormObj } = useContext(FormContext);
  const { tooltipText, setTooltipText, copyPloverUrl } = useCopyPlover();
  const headers = [
    {
      label: "Route Name",
      name: "test_name",
      type: "text",
      flex: true,
      minFlexWidth: 150,
    },
    {
      label: "Tracking Parameter",
      name: "utm_test_id",
      type: "text",
      flex: true,
      minFlexWidth: 250,
    },
    {
      label: "Plover Name",
      name: "plover_url",
      type: "text",
      flex: true,
      minFlexWidth: 150,
      renderer: data => (
        <OverlayTrigger
          placement="bottom center"
          onExit={() => setTooltipText("Copy URL to Clipboard")}
          overlay={<Tooltip id="id:copy plover">{tooltipText}</Tooltip>}
        >
          <div
            className="copyPlover"
            onClick={e =>
              copyPloverUrl(
                e,
                `https://${data.domain || ""}${
                  data.domain ? (data.folder_path ? `/${data.folder_path}/` : "/") : ""
                }${data.domain ? data.plover_url || "" : ""}`
              )
            }
          >
            {data.plover_url}
          </div>
        </OverlayTrigger>
      ),
    },
    {
      label: "Last Modified",
      name: "updated_at",
      type: "text",
      sortPriority: 1,
      flex: true,
      minFlexWidth: 250,
      renderer: data => Dfns.format(DATE_FORMAT, new Date(data.updated_at)),
    },
    {
      label: "Status",
      name: "dri",
      type: "text",
      width: 120,
      nonInteractive: true,
      renderer: data => (
        <OverlayTrigger
          placement="bottom center"
          overlay={<Tooltip id="id:go to route details">Go to Route Details</Tooltip>}
        >
          <div
            onClick={() => {
              setWtoFormObj({
                ...data,
                folder_path: joinBackslashes(data.folder_path),
              });
              setBreadcrumbPath(["Route Details"]);
            }}
          >
            {renderStatus(data.status, data.is_deleted)}
          </div>
        </OverlayTrigger>
      ),
    },
    {
      label: "Actions",
      width: 90,
      nonInteractive: true,
      renderer: (data, idx) => (
        <div
          id={`actions_${idx}:${data.test_name}`}
          className="actionsSelector"
          onClick={e => clickActions(e, data)}
        >
          <MdMoreHoriz />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTooltipText("Copy URL to Clipboard");
  }, [setTooltipText]);

  const selectAction = e => {
    switch (e.target.children[1].textContent) {
      case "Details":
        setWtoFormObj({
          ...overlayMenuData,
          folder_path: joinBackslashes(overlayMenuData.folder_path),
        });
        setBreadcrumbPath(["Route Details"]);
        break;
      case "Rules":
        setWtoFormObj({
          ...overlayMenuData,
          folder_path: joinBackslashes(overlayMenuData.folder_path),
        });
        setBreadcrumbPath(["Route Details", "Rules"]);
        break;
      case "Duplicate":
        setModalData("Duplicate Route", overlayMenuData);
        break;
      case "Archive":
        setModalData("Archive Route", overlayMenuData);
        break;
      case "Activate Route":
        setModalData("Activate Route", overlayMenuData);
        break;
      default:
        break;
    }
  };

  const resetOverlay = () => {
    setOverlayMenuData(null);
    setShowOverlayMenu(false);
    setOverlayMenuCoords({ x: -1, y: -1 });
  };

  useEffect(() => {
    const listener = e => {
      const clickedClasses = e.target.classList;
      if (!R.includes("actionsSelector", clickedClasses)) {
        resetOverlay();
      }
    };
    window.addEventListener("click", listener);
    window.addEventListener("resize", resetOverlay);
    return () => {
      window.removeEventListener("click", listener);
      window.removeEventListener("resize", resetOverlay);
    };
  }, []);

  const resetOverlayMenu = () => {
    setOverlayMenuData(null);
    setShowOverlayMenu(false);
    setOverlayMenuCoords({ x: -1, y: -1 });
  };

  const clickActions = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: any) => {
    let clickedClasses;
    const { target } = e;
    if (target instanceof HTMLElement) {
      clickedClasses = target.classList;
    }
    if (R.includes("actionsSelector", clickedClasses)) {
      setOverlayMenuData({ ...data });
      setShowOverlayMenu(true);
      let horizontalDirection = e.clientX > window.innerWidth / 2 ? "right" : "left";
      let verticalDirection = e.clientY > window.innerHeight / 2 ? "bottom" : "top";
      setOverlayMenuCoords({
        x: horizontalDirection === "left" ? e.clientX : window.innerWidth - e.clientX,
        y: verticalDirection === "top" ? e.clientY : window.innerHeight - e.clientY,
      });
      setOverlayMenuDirection([horizontalDirection, verticalDirection]);
    } else {
      resetOverlayMenu();
    }
  };

  useEffect(() => {
    resetOverlayMenu();
  }, [statusFilter]);

  const renderStatus = (status: string, isDeleted: boolean) => {
    switch (status.toLocaleLowerCase()) {
      case "production_deployment_failed":
        return <WtoStatus color={["#ed4924"]} status={["Error"]} />;
      case "production":
        if (isDeleted) {
          return <WtoStatus color={["#efc63d"]} status={["Archived"]} />;
        }
        return <WtoStatus color={["#46b881"]} status={["Live"]} />;
      case "draft":
        if (isDeleted) {
          return <WtoStatus color={["#efc63d"]} status={["Archived"]} />;
        }
        return <WtoStatus color={["#9e9e9e"]} status={["Draft"]} />;
      default:
        return <WtoStatus color={["#fc9827"]} status={["Unknown"]} />;
    }
  };

  const filterData = data => {
    const filter = statusFilter.toLocaleLowerCase();
    return R.filter((row: Plover) => {
      const status = row.status.toLocaleLowerCase();
      if (
        (row.is_deleted && filter === "archive") ||
        (!row.is_deleted &&
          (filter === "all" ||
            (filter === "draft" && status === "draft") ||
            (filter === "live" && status === "production")))
      ) {
        return true;
      }
      return false;
    }, data);
  };

  return (
    <div className="wtoTable">
      {showOverlayMenu && overlayMenuData && (
        <div
          className="overlayMenu"
          style={{
            [overlayMenuDirection[0]]: overlayMenuCoords.x,
            [overlayMenuDirection[1]]: overlayMenuCoords.y,
          }}
        >
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<MdEdit />}
            onClick={selectAction}
            className="overlayMenuOption"
          >
            Details
          </Button>
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<MdRule />}
            onClick={selectAction}
            className="overlayMenuOption"
          >
            Rules
          </Button>
          {(overlayMenuData.status.toLocaleLowerCase() === "production" ||
            overlayMenuData.status.toLocaleLowerCase() === "draft") &&
            !overlayMenuData.is_deleted && (
              <>
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<BiSolidDuplicate />}
                  onClick={selectAction}
                  className="overlayMenuOption"
                >
                  Duplicate
                </Button>
                {overlayMenuData.status.toLocaleLowerCase() === "draft" && (
                  <Button
                    type={ButtonType.FILLED}
                    variant={ButtonFrameworkVariant.LEADING_ICON}
                    icon={<MdPlayArrow />}
                    onClick={selectAction}
                    className="overlayMenuOption"
                  >
                    Activate Route
                  </Button>
                )}
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<MdDelete />}
                  onClick={selectAction}
                  className="overlayMenuOption"
                >
                  Archive
                </Button>
              </>
            )}
        </div>
      )}
      <div className="statusFilterButtons">
        {FILTER_OPTIONS.map((status, index) => (
          <Button
            key={index}
            className={`statusFilterButton ${status === statusFilter ? "selected" : ""}`}
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.NO_ICON}
            onClick={() => setStatusFilter(status)}
          >
            {status}
          </Button>
        ))}
      </div>
      {loading ? (
        <FullPageSpinner />
      ) : (
        <BPMTable
          data={filterData(data)}
          headers={headers}
          noRowsRenderer={() => (
            <div className="noConfigurations">
              {domains.length > 0 ? "No data to show" : "Domains not configured for this partner"}
            </div>
          )}
          rowHeight={INNER_CELL_HEIGHT}
          pagination={true}
        />
      )}
    </div>
  );
};

export default WtoTable;
