import "./TableProgressBar.scss";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { appleGreen } from "../utils/colors";

export const BAR_WIDTH = "75%";
export const BAR_WRAPPER_WIDTH = "75%";
export const BASE_BG_COLOR = "#E7ECF8";

type TableProgressBarProps = {
  label: string;
  maxCompleted: number;
  value: number;
};

const TableProgressBar: React.FC<TableProgressBarProps> = ({ label, maxCompleted, value }) => {
  return (
    <div style={{ width: BAR_WRAPPER_WIDTH }}>
      <ProgressBar
        baseBgColor={BASE_BG_COLOR}
        bgColor={appleGreen}
        borderRadius="0"
        className="table-progress-bar"
        completed={value}
        customLabel={label}
        height="14px"
        labelAlignment="outside"
        labelClassName="progress-bar-label"
        maxCompleted={maxCompleted}
        width={BAR_WIDTH}
      />
    </div>
  );
};

export default TableProgressBar;
