// Experiment for creative update Extreme Reach integration.  If enabled, creative updates
// will be sent directly to Extreme Reach through their API from the streaming/creatives page.

const newSlidesUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "alex.echeverria@tinuiti.com",
  "justin.manus@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "jonathan.lerner@tinuiti.com",
  // Add users here!
];

export const shouldEnableNewSlidesForUser = (userEmail: string): boolean => {
  return newSlidesUsers.includes(userEmail);
};
