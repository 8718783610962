import React, { useContext, useState, useCallback, useMemo, useEffect } from "react";

import * as R from "ramda";

import { Button, Form } from "react-bootstrap";

import Select from "react-select";

import { BPMTable, Card, FullPageSpinner, Skeleton, TableSkeleton } from "../Components";

import { StreamingCreativesContext, CART_KEY, GENERATE_KEY } from "./StreamingCreatives";

import { PendingCreativesData } from "@blisspointmedia/bpm-types/dist/PendingCreatives";

import { awaitJSON, MiscLambdaFetch, StreamingLambdaFetch } from "../utils/fetch-utils";

import useLocation from "../utils/hooks/useLocation";

import { useSetAreYouSure, useSetError } from "../redux/modals";

import "./Approvals.scss";

import { useSelector } from "react-redux";

import { emailSelector } from "../redux/user";

import { useCreativeMap } from "../redux/creative";

import { ApprovalStages, Reviewer } from "@blisspointmedia/bpm-types/dist/CreativeApprovals";

import { ClientStrategyContact } from "@blisspointmedia/bpm-types/dist/AccountPlanning";

const Approvals = (): JSX.Element => {
  const { company } = useLocation();
  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const [reviewerOptions, setReviewerOptions] = useState<Reviewer[]>();

  const setAreYouSure = useSetAreYouSure();

  const [selectedReviewers, setSelectedReviewers] = useState<Reviewer[]>([]);

  const { tab, goToTab, approvalStageData, updateApprovalStage } = useContext(
    StreamingCreativesContext
  );

  const [approvalStage, setApprovalStage] = useState<ApprovalStages | null>(null);

  const setError = useSetError();

  const [commentBoxText, setCommentBoxText] = useState<string>();

  const [pendingCreatives, setPendingCreatives] = useState<PendingCreativesData>();

  const userEmail = useSelector(emailSelector);

  const [currentRequester, setCurrentRequester] = useState("");

  const [currentReviewer, setCurrentReviewer] = useState<string | null>(null);

  const [showCommentBox, setShowCommentBox] = useState(false);

  useEffect(() => {
    if (approvalStageData) {
      setApprovalStage(approvalStageData.stage || ApprovalStages.PRE_REVIEW);
      setCurrentRequester(approvalStageData.requester || userEmail);
      setSelectedReviewers(approvalStageData.reviewers || []);
      if (approvalStageData.reviewers) {
        for (const reviewer of approvalStageData.reviewers) {
          if (userEmail === reviewer.value) {
            setCurrentReviewer(reviewer.value);
          }
        }
      }
    }
  }, [approvalStageData, userEmail]);

  useEffect(() => {
    (async () => {
      if (company) {
        try {
          let res = await StreamingLambdaFetch("/getPendingCreatives", {
            params: {
              company,
            },
          });
          const pendingCreatives = await awaitJSON<PendingCreativesData>(res);
          setPendingCreatives(pendingCreatives);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: reportError.message,
            reportError,
          });
        }
      }
    })();
  }, [company, setError, tab]);

  useEffect(() => {
    (async () => {
      try {
        let res = await MiscLambdaFetch("/getClientStrategyContacts");
        let contacts = await awaitJSON<ClientStrategyContact[]>(res);
        let reviewerOptions: Reviewer[] = [];
        contacts.forEach(contact =>
          reviewerOptions.push({
            label: contact.full_name ? contact.full_name : contact.email,
            value: contact.email,
          })
        );
        reviewerOptions.push({ label: "Carling Sugarman", value: "carling.sugarman@tinuiti.com" });
        setReviewerOptions(reviewerOptions.sort((a, b) => a.label.localeCompare(b.label)));
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    })();
  }, [company, setError]);

  const sendReviewRequest = useCallback(() => {
    setApprovalStage(ApprovalStages.IN_REVIEW);
    updateApprovalStage(company, ApprovalStages.IN_REVIEW, selectedReviewers, currentRequester);
  }, [company, updateApprovalStage, selectedReviewers, currentRequester]);

  const requestChanges = useCallback(() => {
    setShowCommentBox(false);
    setApprovalStage(ApprovalStages.CHANGES_REQUESTED);
    updateApprovalStage(
      company,
      ApprovalStages.CHANGES_REQUESTED,
      selectedReviewers,
      currentRequester,
      currentReviewer,
      commentBoxText
    );
  }, [
    company,
    updateApprovalStage,
    selectedReviewers,
    currentRequester,
    currentReviewer,
    commentBoxText,
  ]);

  const approveRotation = useCallback(() => {
    setApprovalStage(ApprovalStages.APPROVED);
    updateApprovalStage(
      company,
      ApprovalStages.APPROVED,
      selectedReviewers,
      currentRequester,
      currentReviewer,
      commentBoxText
    );
  }, [
    company,
    updateApprovalStage,
    selectedReviewers,
    currentRequester,
    currentReviewer,
    commentBoxText,
  ]);

  const resetApprovalProcess = useCallback(() => {
    setAreYouSure({
      title: "Cancel Review Process",
      message: `${currentRequester} initiated this review process. Are you sure you want to cancel it?`,
      okayText: "Yes, Cancel",
      cancelText: "No, Don't Cancel",
      onOkay: () => {
        setApprovalStage(ApprovalStages.PRE_REVIEW);
        updateApprovalStage(
          company,
          ApprovalStages.PRE_REVIEW,
          selectedReviewers,
          currentRequester
        );
      },
    });
  }, [company, updateApprovalStage, currentRequester, selectedReviewers, setAreYouSure]);

  const [showActivePlacementsOnly, setShowActivePlacementsOnly] = useState<boolean>(false);

  // Restructure pending placements data for use in BPMTable.
  const processedData = useMemo(() => {
    let processedDataOutput: {
      placementName: string;
      dateKey: string;
      isci: string;
      pct: number;
      start_date: string;
      end_date: string | null;
      has_active_or_future_flights: boolean;
      creativeName: string | null;
    }[] = [];
    if (pendingCreatives && creativeMap) {
      let placementNames = Object.keys(pendingCreatives);
      placementNames.forEach(placementName => {
        let placement = pendingCreatives[placementName];
        let dates = Object.keys(placement);
        dates.forEach(dateKey => {
          let rotationData = placement[dateKey];
          rotationData.forEach(entry => {
            let { isci, pct, start_date, end_date, has_active_or_future_flights } = entry;
            processedDataOutput.push({
              placementName,
              dateKey,
              isci,
              pct,
              start_date: start_date || "None",
              end_date: end_date || "None",
              has_active_or_future_flights,
              creativeName: creativeMap[isci]?.name,
            });
          });
        });
      });

      if (showActivePlacementsOnly) {
        processedDataOutput = processedDataOutput.filter(row => row.has_active_or_future_flights);
      }

      return processedDataOutput;
    }
  }, [pendingCreatives, creativeMap, showActivePlacementsOnly]);

  const headers = [
    {
      label: "Placement Name",
      name: "placementName",
      flex: 2,
    },
    { label: "Creative Name", name: "creativeName", flex: 1 },
    { label: "ISCI", name: "isci", flex: 1 },
    { label: "Percent", name: "pct", flex: 1 },
    { label: "Start Date", name: "start_date", flex: 1 },
    { label: "End Date", name: "end_date", flex: 1 },
  ];

  return (
    <div className="streamingCreativesCardContainer">
      {approvalStage ? (
        <Card className="streamingCreativesCard">
          <div className="approvals">
            {processedData ? (
              <div className="approvalsDataContainer">
                <div className="activePlacementsOnlyCheckboxContainer">
                  <input
                    className="activePlacementsOnlyCheckbox"
                    type="checkbox"
                    checked={showActivePlacementsOnly}
                    onChange={() => setShowActivePlacementsOnly(R.not(showActivePlacementsOnly))}
                  />
                  <div className="activePlacementsOnlyCheckboxLabel">Active placements only</div>
                </div>
                <BPMTable
                  headers={headers}
                  data={processedData}
                  noRowsRenderer={() => <div>No rows to show.</div>}
                />
              </div>
            ) : (
              <Skeleton>
                <TableSkeleton />
              </Skeleton>
            )}
          </div>
          <div className="buttonsContainer">
            {approvalStage !== ApprovalStages.PRE_REVIEW && (
              <div className="bottomPageContainer">
                <Button variant="outline-dark" size="lg" onClick={() => goToTab(CART_KEY)}>
                  Back
                </Button>
                <Button
                  className="cancelButton"
                  variant="link"
                  size="lg"
                  onClick={resetApprovalProcess}
                >
                  Cancel Review
                </Button>
                {!showCommentBox && approvalStage === ApprovalStages.IN_REVIEW && (
                  <Button className="displayReviewers" variant="dark" disabled={true}>
                    {currentReviewer
                      ? `Review requested by ${currentRequester}.`
                      : `Review has been requested from ${selectedReviewers.map(reviewer =>
                          " ".concat(reviewer.label)
                        )}.`}
                  </Button>
                )}
                {approvalStage === ApprovalStages.APPROVED && (
                  <div className="approved">
                    <Button className="displayReviewers" variant="outline-primary" disabled={true}>
                      {`Changes have been approved by ${approvalStageData.currentReviewer}.`}
                    </Button>
                    <Button
                      className="nextButton"
                      variant="primary"
                      size="lg"
                      onClick={() => goToTab(GENERATE_KEY)}
                    >
                      Next
                    </Button>
                  </div>
                )}
                {approvalStage === ApprovalStages.CHANGES_REQUESTED && (
                  <div className="approved">
                    <Button className="displayReviewers" variant="outline-danger" disabled={true}>
                      {`Changes have been requested by ${currentReviewer}.`}
                    </Button>
                    {userEmail === currentRequester && (
                      <Button
                        className="rerequestReviewButton"
                        variant="primary"
                        size="lg"
                        onClick={sendReviewRequest}
                      >
                        Re-Request Review
                      </Button>
                    )}
                  </div>
                )}
                {approvalStage === ApprovalStages.IN_REVIEW && currentReviewer && !showCommentBox && (
                  <div className="commentBoxAndButtonsContainer">
                    <Button
                      className="approveButton"
                      variant="primary"
                      size="lg"
                      onClick={approveRotation}
                    >
                      Approve
                    </Button>
                    <Button
                      className="requestChangesButton"
                      variant="danger"
                      size="lg"
                      onClick={() => setShowCommentBox(true)}
                    >
                      Request Changes
                    </Button>
                  </div>
                )}
                {showCommentBox && (
                  <div className="commentBoxContainer">
                    <Form.Control
                      className="commentBox"
                      as="textarea"
                      rows={1}
                      placeholder="Changes Requested..."
                      value={commentBoxText || ""}
                      onChange={e => setCommentBoxText(e.target.value)}
                      onBlur={() => setCommentBoxText(text => (text || "").trim())}
                    />
                    <Button
                      className="confirmRequestChangesButton"
                      variant="danger"
                      size="lg"
                      onClick={requestChanges}
                    >
                      Request Changes
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          {approvalStage === ApprovalStages.PRE_REVIEW && (
            <div className="reviewControls">
              <Button variant="outline-dark" size="lg" onClick={() => goToTab(CART_KEY)}>
                Back
              </Button>
              <Select
                classNamePrefix="reviewerOptionsMenu"
                className="reviewerOptionsMenu"
                isMulti
                placeholder="Select Reviewers..."
                menuPlacement="top"
                isClearable={false}
                value={selectedReviewers}
                options={reviewerOptions}
                onChange={value => setSelectedReviewers(value)}
              />
              <Button
                disabled={!selectedReviewers?.length}
                variant="primary"
                size="lg"
                onClick={sendReviewRequest}
              >
                Request Review
              </Button>
            </div>
          )}
        </Card>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

export default Approvals;
