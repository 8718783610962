import { FullPageSpinner } from ".";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import NotFound from "../NotFound";
import React, { Suspense } from "react";

export const ProtectedRoute = ({
  path,
  component: Comp,
  suspense = false,
  roleRequired,
  ...restProps
}) => {
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const userFetched = useSelector(UserRedux.userFetchedSelector);
  const userRole = useSelector(UserRedux.roleSelector);

  // Allow each protected route to specify the role of the user they allow and default to isInternal
  // if not specified
  const userNotAllowed = roleRequired
    ? Array.isArray(roleRequired)
      ? ![0, ...roleRequired].includes(userRole)
      : roleRequired < userRole
    : !isInternal;

  if (userFetched) {
    if (userNotAllowed) {
      return <NotFound path={path} />;
    } else if (suspense) {
      return <SuspenseRoute component={Comp} {...restProps} />;
    } else {
      return <Comp {...restProps} />;
    }
  } else {
    return (
      <div className="bpmPage" path={path}>
        <FullPageSpinner />
      </div>
    );
  }
};

export const SuspenseRoute = ({ component: Comp, ...restProps }) => (
  <Suspense
    fallback={
      <div className="bpmPage">
        <FullPageSpinner />
      </div>
    }
  >
    <Comp {...restProps} />
  </Suspense>
);
