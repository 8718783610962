export const DATE_FORMAT = "yyyy/MM/dd";
export const STREAMING_IMPRESSIONS_DATE_FORMAT = "yyyy-MM-dd";
export const AD_SERVING_PRICE = 0.31;
export const INNER_CELL_HEIGHT = 35;
export const MIN_COLUMN_WIDTH = 100;
export const WEEK_RANGE = "weekRange";
export const SINGLE_WEEK = "singleWeek";
export const CHARTS_KEY = "charts";
export const INVESTMENT_AUDIENCE_KEY = "investmentAudience";
export const LINEAR_DELIVERY_KEY = "linearDelivery";
export const PERFORMANCE_OUTCOMES_KEY = "performanceOutcomes";
export const CLIENT_SPECIFIC_KEY = "clientSpecific";
export const TODAY = new Date();
export const expectedSparkCharts = ["Avg Audience ('000s)", "CPM", "TV", "Spend"];
export const LAG_OPTIONS = ["1d", "3d", "7d", "14d", "30d"];
export const ARBITRARY_STREAMING_DATE_CUTOFF = "2018-01-01";
