import React, { ComponentType } from "react";

export interface DateRange {
  start: string;
  end: string;
}
export interface Setter<T> {
  (newVal: T): void;
}
export type StateSetter<T> = Setter<T | ((oldVal: T) => T)>;

export type BootstrapVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "dark"
  | "light"
  | "link";

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-542793243
export const typedReactMemo: <T extends ComponentType<any>>(
  c: T,
  areEqual?: (prev: React.ComponentProps<T>, next: React.ComponentProps<T>) => boolean
) => T = React.memo;
