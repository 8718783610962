import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Page,
  Spinner,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  SelectorOption,
} from "../Components";
import "./LinearBuyingAssignments.scss";
import {
  GetLinearBuyingAssignmentsResponse,
  UpdateLinearBuyingAssignmentsParams,
} from "@blisspointmedia/bpm-types/dist/LinearAssignments";
import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

interface rowData {
  company: string;
  email: string;
  lastmodified?: string;
}

const LinearBuyingAssignments: React.FC = () => {
  const setError = useSetError();
  const [tableData, setTableData] = useState<rowData[]>();
  const [saving, setSaving] = useState(false);
  const [assignmentsData, setAssignmentsData] = useState<GetLinearBuyingAssignmentsResponse>();

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let res = await LinearBuyingLambdaFetch("/buying_assignments");
          let assignmentsData = await awaitJSON<GetLinearBuyingAssignmentsResponse>(res);
          setTableData(() =>
            R.map(
              assignment => ({
                company: assignment.company,
                email: assignment.email,
              }),
              assignmentsData.assignments
            )
          );
          setAssignmentsData(assignmentsData);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get linear buying assignments data ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [setError, tableData]);

  const assignedCompanies = useMemo(() => {
    if (tableData) {
      return R.pluck("company", tableData);
    }
    return [];
  }, [tableData]);

  const selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    if (assignmentsData) {
      let availCompanies = R.without(assignedCompanies, R.pluck("cid", assignmentsData.companies));
      return {
        companiesConstantOnSet: R.map(
          company => ({ label: company, value: company }),
          availCompanies
        ),
        emails: R.map(
          contact => ({ label: contact.email, value: contact.email }),
          assignmentsData.contacts
        ),
      };
    } else {
      return {
        companiesConstantOnSet: [],
        emails: [],
      };
    }
  }, [assignedCompanies, assignmentsData]);

  const saveData = useMemo(() => {
    const valuesAreSet = ({ company, email, lastmodified }: rowData) =>
      !R.isNil(company) && !R.isNil(email) && !R.isNil(lastmodified);

    if (tableData) {
      return R.filter(valuesAreSet, tableData);
    }
  }, [tableData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await LinearBuyingLambdaFetch<UpdateLinearBuyingAssignmentsParams[]>("/buying_assignments", {
        method: "POST",
        body: saveData,
      });
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to set linear buying assignments data ${reportError.message}`,
        reportError,
      });
    }
  }, [saveData, setError]);

  const linearBuyingAssignmentHeaders = [
    {
      label: "Company",
      field: "company",
      type: "select",
      options: "companiesConstantOnSet",
      flex: 1,
      modalRow: 0,
      modalWidth: 375,
      isClearable: false,
    },
    {
      label: "Buyer Email",
      field: "email",
      type: "select",
      options: "emails",
      flex: 1,
      modalRow: 0,
      modalWidth: 375,
      isClearable: false,
    },
  ];

  return (
    <Page
      title="Linear Buying Assignments"
      pageType="Linear Buying Assignments"
      minHeight="600px"
      actions={
        <div className="linearBuyingAssignmentsActions">
          <Button
            variant="primary"
            onClick={save}
            className="saveButton"
            disabled={R.isNil(saveData) || !saveData.length || saving}
          >
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      <div className="linearBuyingAssignmentsPageContainer">
        {tableData ? (
          <ModalEditTable
            className="linearBuyingAssignmentsTable"
            name="Assignments"
            headers={linearBuyingAssignmentHeaders}
            tableData={tableData || []}
            setTableData={newTableData => {
              setTableData(newTableData);
            }}
            selectorOptions={selectorOptions}
            filterBar
            enableDelete={false}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default LinearBuyingAssignments;
