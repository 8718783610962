import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Img } from "../../Components";
import "./BusinessImpact.scss";
import BusinessImpactOverview from "./BusinessImpactOverview";
import RevenueImpact from "./RevenueImpact";
import IncrementalRoas from "./IncrementalRoas";
import BusinessImpactModelInsights from "./BusinessImpactModelInsights";

interface BusinessImpactProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

const BusinessImpact = ({
  company,
  groupBy,
  setGroupByOptions,
  groupByMetric,
}: BusinessImpactProps & RouteComponentProps): JSX.Element => {
  return (
    <div>
      <div className="brandEquityHeader">
        <div className="brandEquityTextHeader">
          <div className="brandEquityTextHeaderTitle">
            A strong brand drives business outcomes and creates a tailwind for performance marketing
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Performance marketing generates results over days to weeks. Brand marketing operates
            over months to years, making unified assessment of the funnel challenging.
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Bliss Point Brand Health quantifies brand equity's impact on the business for a holistic
            understanding of ROAS.
          </div>
        </div>
        <div className="brandEquityHeaderImageContainer">
          <Img
            src="https://cdn.blisspointmedia.com/assets/img/BusinessImpactDemo/BrandEquity_Infographic.png"
            alt="brand health header"
          />
        </div>
      </div>
      <BusinessImpactOverview
        company={company}
        setGroupByOptions={setGroupByOptions}
        groupBy={groupBy}
        groupByMetric={groupByMetric}
      />
      <RevenueImpact
        company={company}
        setGroupByOptions={setGroupByOptions}
        groupBy={groupBy}
        groupByMetric={groupByMetric}
      />
      <IncrementalRoas
        company={company}
        setGroupByOptions={setGroupByOptions}
        groupBy={groupBy}
        groupByMetric={groupByMetric}
      />
      <BusinessImpactModelInsights company={company} groupByMetric={groupByMetric} />
    </div>
  );
};

export default BusinessImpact;
