import React, { useEffect, useState, useCallback } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import {
  LinearLambdaFetch,
  JavaLambdaFetch,
  UsersLambdaFetch,
  awaitJSON,
  pollS3,
  getCleanEmail,
} from "../utils/fetch-utils";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { Spinner, Skeleton, RectSkeleton, ListSkeleton } from "../Components";
import { MdDelete } from "react-icons/md";

interface CreditMemoModalProps {
  selectedRows: any[];
  selectedRowsIndex: number[];
  show: boolean;
  setShow: Function;
  patchCreditMemo: Function;
}

const SKELETON_LINE_HEIGHT = 30;
const SKELETON_PADDING = 10;
const SKELETON_BORDER_RADIUS = 5;
const MailerSkeleton = () => {
  return (
    <Skeleton>
      <ListSkeleton
        alignTop
        lineHeight={SKELETON_LINE_HEIGHT}
        lineSpacing={SKELETON_PADDING / 2}
        horizontalPadding={SKELETON_PADDING}
        verticalPadding={SKELETON_PADDING}
        height={height => height / 2}
        borderRadius={SKELETON_BORDER_RADIUS}
      />
      <RectSkeleton
        // We want padding on x/y/width, so we supply it as a prop. But it will also deduct from
        // height, so add it back in.
        height={SKELETON_LINE_HEIGHT + 2 * SKELETON_PADDING}
        y={height => height / 2}
        padding={SKELETON_PADDING}
      />
      <RectSkeleton
        height={height => height / 2 - SKELETON_LINE_HEIGHT - SKELETON_PADDING}
        y={height => height / 2 + SKELETON_LINE_HEIGHT + SKELETON_PADDING}
        padding={SKELETON_PADDING}
      />
    </Skeleton>
  );
};

export const CreditMemoModal = ({
  selectedRows,
  selectedRowsIndex,
  patchCreditMemo,
  show,
  setShow,
}: // data,
// setData,
CreditMemoModalProps): JSX.Element => {
  // ID must be the same as the ID passed to the PDF generation java lambda
  const selectedRow = selectedRows[0];
  const selectedRowIndex = selectedRowsIndex[0];
  const { id } = selectedRow;
  const oldId = [
    selectedRow.company,
    selectedRow.week,
    selectedRow.network,
    selectedRow.avail,
    selectedRow.id,
  ].join("___"); // 3 underscores
  const selectedIds = selectedRow.spotIDs;
  const [signedURL, setSignedURL] = useState<string>();
  const setError = useSetError();
  const getIsMounted = useIsMounted();
  const [sending, setSending] = useState(false);

  const [subject, setSubject] = useState("");
  const [to, setTo] = useState<string>("");
  const [cc, setCC] = useState<string[]>([]);
  const [readOnlyCC, setReadOnlyCC] = useState<string[]>([]);
  const [bodyText, setBodyText] = useState("");
  const [agency, setAgency] = useState("bpm");

  useEffect(() => {
    (async () => {
      try {
        if (getIsMounted()) {
          if (!to) {
            let creditInformationRaw = await LinearLambdaFetch("/credit_information", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: {
                id, // Doesn't appear to be used by the lambda
                selectedIds,
              },
            });
            let userRes = await UsersLambdaFetch("/me", {
              retries: 0,
            });
            let userInfo = await awaitJSON(userRes);

            const creditInformation = await awaitJSON<any>(creditInformationRaw);
            setTo(creditInformation.to);
            setCC(creditInformation.cc);
            setReadOnlyCC([creditInformation.readOnlyCC, userInfo.email]);
            setAgency(creditInformation.agency);
            setBodyText(creditInformation.body);
            setSubject(creditInformation.subject);
            // in development try the dev URL (so production S3 files aren't stepped on)
            if (process.env.REACT_APP_STAGE === "production") {
              try {
                // First try the new bucket
                const contents = await pollS3({
                  bucket: "credit-memo-pdfs",
                  mimeType: "application/pdf",
                  filename: `${id}.pdf`,
                  maxAttempts: 2,
                });
                const blob = contents.slice(0, contents.size, "application/pdf");

                setSignedURL(URL.createObjectURL(blob));
              } catch (err) {
                // Try the old bucket if it's not in the new bucket
                const contents = await pollS3({
                  bucket: "bpm-cache",
                  mimeType: "application/pdf",
                  filename: `${oldId}.pdf`,
                });
                const blob = contents.slice(0, contents.size, "application/pdf");

                setSignedURL(URL.createObjectURL(blob));
              }
            } else {
              const contents = await pollS3({
                bucket: "credit-memo-pdfs",
                mimeType: "application/pdf",
                filename: `${id}-dev.pdf`,
              });
              const blob = contents.slice(0, contents.size, "application/pdf");
              setSignedURL(URL.createObjectURL(blob));
            }
          }
        }
      } catch (e: any) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [setError, id, oldId, selectedIds, signedURL, getIsMounted, to]);

  const onSend = useCallback(async () => {
    setSending(true);
    let cleanTo = getCleanEmail(to);
    if (!cleanTo) {
      setError({
        title: "Invalid Email",
        message: `"${to}" is not a valid email address`,
      });
      setSending(false);
      return;
    }
    let newCC: string[] = [];
    for (let email of cc) {
      if (!email) {
        continue;
      }
      // remove all whitespace
      let cleanEmail = getCleanEmail(email);
      if (!cleanEmail) {
        setError({
          title: "Invalid Email",
          message: `"${email}" is not a valid email address`,
        });
        setSending(false);
        return;
      }
      newCC.push(cleanEmail);
    }
    newCC = [...newCC, ...readOnlyCC];

    let body = bodyText
      .replace(/\n/g, "<br />")
      .replace(/\*(.+?)\*/g, "<strong>$1</strong>")
      .replace(/_(.+?)_/g, "<em>$1</em>");
    try {
      await JavaLambdaFetch("/send_mail", {
        method: "POST",
        body: {
          to: cleanTo,
          agency,
          cc: newCC.join(", "),
          subject,
          body,
          attachment: `s3://credit-memo-pdfs/${id}.pdf`,
        },
      });

      // Update the status to "Sent"
      await patchCreditMemo(selectedRow, selectedRowIndex, "Sent");

      setSending(false);
      setShow(false);
    } catch (e: any) {
      setError({ message: `Failed to send PDF. ${e.message}`, reportError: e });
      setSending(false);
    }
  }, [
    agency,
    setSending,
    setError,
    cc,
    readOnlyCC,
    bodyText,
    to,
    id,
    setShow,
    subject,
    patchCreditMemo,
    selectedRow,
    selectedRowIndex,
  ]);

  return (
    <Modal dialogClassName="modal-90wh" keyboard={true} show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Send Credit Memo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sendView">
          <div className="mailPane">
            {to ? (
              <>
                <div className="emails">
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="inputLabel">To</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control value={to} onChange={e => setTo(e.target.value)} />
                  </InputGroup>
                  <div className="ccs">
                    {cc.map((email, i) => (
                      <InputGroup key={`${i}_${cc.length}`} size="sm">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="inputLabel">CC</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          value={email}
                          onChange={e => setCC(R.update(i, e.target.value))}
                        />
                        <InputGroup.Append>
                          <Button variant="outline-danger" onClick={() => setCC(R.remove(i, 1))}>
                            <MdDelete />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    ))}
                    {readOnlyCC.map((email, i) => (
                      <InputGroup key={`readonly_${i}_${cc.length}`} size="sm">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="inputLabel">CC</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control readOnly value={email} />
                      </InputGroup>
                    ))}
                  </div>
                  <Button block size="sm" onClick={() => setCC(cc => [...cc, ""])}>
                    Add CC
                  </Button>
                </div>
                <div className="content">
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="">Subject</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control value={subject} onChange={e => setSubject(e.target.value)} />
                  </InputGroup>
                  <Form.Control
                    as="textarea"
                    className="bodyTextArea"
                    value={bodyText}
                    onChange={e => setBodyText(e.target.value)}
                  />
                  <div className="hint">
                    You can use *<strong>Bold</strong>* and _<em>Italics</em>_, which will appear in
                    the email.
                  </div>
                </div>
                {sending ? (
                  <Spinner />
                ) : (
                  <div className="buttonRow">
                    <Button size="sm" variant="dark" onClick={() => setShow(false)}>
                      Cancel
                    </Button>
                    <Button size="sm" variant="primary" onClick={onSend}>
                      Send
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <MailerSkeleton />
            )}
          </div>
          <div className="pdfViewer">
            {signedURL ? (
              <iframe src={signedURL} title="pdfViewer" />
            ) : (
              <Skeleton>
                <RectSkeleton padding={SKELETON_PADDING} />
              </Skeleton>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
