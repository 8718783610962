import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Reddit";

const Reddit = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Reddit is a social media platform that is based around communities called subreddits.
              Reddit consists of over 100,000 active subreddits and maintains over 465 million
              global monthly active users. With the ability to user-target advertisements with a
              large user base, user-based incrementality is the chosen in-platform incrementality
              approach.
            </div>
            <br />
            <Img src={`${INC_CDN}/Reddit_ConversionLift.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Sample Output",
                "Signal Enrichment",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>The Reddit Conversion Lift study is a user-based incrementality test.</li>
                      <li>Users are randomly split into treatment or control groups.</li>
                      <li>
                        When the auction runs and the ad wins if the user is in the treatment group
                        the ad is shown, if the user is in the control group the next eligible ad is
                        shown.
                      </li>
                      <li>
                        The conversions are compared between the treatment and control groups.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Establishes a baseline of Reddit ad effectiveness through causal inference.
                      </li>
                      <li>
                        Reddit conversion lift gives measurements on ad type and device to see what
                        type has the highest incrementality.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Due to Reddit running the test we have less control over test execution.
                      </li>
                      <li>
                        The aggregated results that are provided make independently verifying the
                        test unrealistic.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              500
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Total test length may vary and will be decided based on a pre campaign
                        feasibility test.
                      </li>
                      <li>
                        May vary on a client to client basis, a one week dark period pre and post
                        campaign.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Testable Outcomes",
                  Details: (
                    <ul className="basicList">
                      <li>Page Visit</li>
                      <li>Sign Ups</li>
                      <li>Lead</li>
                      <li>Conversions</li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              300
            )}
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <iframe
              allowFullScreen
              title="Sample Output"
              src="https://docs.google.com/presentation/d/16NYrJR2lSeBWruyZe2CxrtAQnWPooBQOXEroun6SKfw/preview?authuser=0"
            />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              In order to run the Reddit Conversion Lift study, advertisers must have either the{" "}
              <a href="https://business.reddithelp.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website">
                Reddit Pixel
              </a>
              ,{" "}
              <a href="https://business.reddithelp.com/helpcenter/s/article/Conversions-API">
                Reddit Conversion API
              </a>
              , or a Mobile Measurement Partner (MMP) for mobile conversions. These tools allow
              Reddit to track users' next actions after interacting with an ad. Clients that are
              already live on Reddit will almost certainly have one of these active to track
              performance.
            </div>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
    </div>
  );
});

export default Reddit;
