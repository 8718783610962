import React from "react";
import { SlideState, SharedState } from "../slideTemplateConstants";
import {
  SlideType,
  SlideContext,
  SettingsComponentProps,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { Form } from "react-bootstrap";
import { DateRange } from "../../utils/types";
import { BPMDateRange, Dropdown, DropdownToggleType, LineAndBarChart } from "../../Components";
import moment from "moment";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { fetchSummarySlideData } from "./QueryServices";
import { tmrRetailerFilterOptions, tmrKpiPull, PROFILE_FILTER_MESSAGING } from "./utils";

export interface MarketplaceReportSummarySlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportSummarySlideData {
  title: string;
  subTitle: string;
}
/**
 * Slide typeKey must match in
 * slideTemplateConstant type
 */

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

class MarketplaceReportSummarySlide extends SlideType {
  static typeKey = "TmrSummarySlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportSummarySlideState = {
    title: "TMR Total Advertising",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportSummarySlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, dates, kpi, companyProfile} = state;
    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
         {<div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>}
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportSummarySlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      dates,
      retailer,
      companyProfile,
      kpi,
    } = state as MarketplaceReportSummarySlideState;
    const height = 300;
    const width = 600;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    const data = await fetchSummarySlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      profileNames
    );
    const BAR_COLOR = "#4077cf";
    const LINE_COLOR = "#f44336";
//acos vs roas data passing
    let kpiData = `data.${kpi}`;
    let kpiLabel = `Total ${kpi}}`;

    const kpiLineBarComponent = (
      <LineAndBarChart
        lineDataKey={kpiData}
        barDataKey="data.adSales"
        xAxisDataKey="dimension.date"
        barYAxisDataKey="data.adSales"
        barYAxisLabel="Total Ad Sales"
        lineYAxisDataKey={kpiData}
        lineYAxisLabel={kpiLabel}
        data={data.byDate.lineGraphData}
        legendLocation="top"
        isAnimationActive={false}
        height={height}
        width={width}
        barChartColor={BAR_COLOR}
        lineChartColor={LINE_COLOR}
      />
    );

    let kpiChartUrl = await convertComponentToImageUrl(
      kpiLineBarComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const AOV_BAR_COLOR = "#43a047";
    const AOV_LINE_COLOR = "#f99313";

    const aovLineBarComponent = (
      <LineAndBarChart
        lineDataKey="data.aov"
        barDataKey="data.orders"
        xAxisDataKey="dimension.date"
        barYAxisDataKey="data.orders"
        barYAxisLabel="Total Orders"
        lineYAxisDataKey="data.aov"
        lineYAxisLabel="Total AOV"
        data={data.byDate.lineGraphData}
        legendLocation="top"
        isAnimationActive={false}
        height={height}
        width={width}
        barChartColor={AOV_BAR_COLOR}
        lineChartColor={AOV_LINE_COLOR}
      />
    );

    let aovChartUrl = await convertComponentToImageUrl(
      aovLineBarComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    let totalData = data.byChannel.totalAdvertising;
    let { tableData } = data.byChannel;

    return Promise.resolve({
      title,
      subTitle,
      company,
      dateStart: dates.start,
      dateEnd: dates.end,
      retailer,
      kpiChartUrl,
      aovChartUrl,
      totalData,
      tableData,
      kpi,
    });
  };
}
export default MarketplaceReportSummarySlide;
