import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Setter } from "../utils/types";

export interface OnBlurInputProps
  extends Omit<React.ComponentProps<typeof Form.Control>, "onChange" | "onBlur"> {
  onChange: Setter<string>;
}

export const OnBlurInput: React.FC<OnBlurInputProps> = ({ onChange, value, ...rest }) => {
  const [local, setLocal] = useState(value);
  useEffect(() => {
    setLocal(value);
  }, [value]);
  return (
    <Form.Control
      value={local}
      onChange={e => setLocal(e.currentTarget.value)}
      onBlur={() => {
        onChange(local);
        setLocal(value);
      }}
      {...rest}
    />
  );
};
