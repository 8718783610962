import "./ActualVsPredicted.scss";
import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChartTooltip from "../../Components/Charts/ChartTooltip/ChartTooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { ActualVsPredictedData } from "./ModelOverview";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../../Components/Charts/ChartConstants";
import { headerFormatter, useDynamicYAxisWidth } from "../../Components/Charts/ChartUtils";

interface ActualVsPredictedProps {
  data: ActualVsPredictedData[];
  dateGrouping: string;
  legendValue: string;
  syncId?: number;
  tooltipFormatter?: (value: any) => string;
  yAxisFormatter?: (value: any) => string;
}

const ActualVsPredicted: React.FC<ActualVsPredictedProps> = ({
  data,
  dateGrouping,
  legendValue,
  syncId,
  tooltipFormatter,
  yAxisFormatter,
}) => {
  const trainEnd = data[data.findIndex(d => d.train_test === "test") - 1];
  const { yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 7,
    data: data
  });

  return (
    <div className="actualVsPredictedWrapper">
      <div className="actualVsPredictedGraph" style={{ height: "216px", width: "100%" }}>
        <div className="actVPredTitle">
          <div className="trainPeriod">Train Period</div>
          <div className="testPeriod">Test Period</div>
        </div>
        <AutoSizer>
          {({ width, height }) => (
            <ComposedChart
              data={data}
              height={height}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              syncId={syncId}
              ref={setChartRef}
              width={width}
            >
              <XAxis
                dataKey="date"
                tick={TICK_STYLE}
                tickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}T00:00:00`))
                }
              />
              <YAxis
                tick={TICK_STYLE}
                tickFormatter={yAxisFormatter}
                tickLine={false}
                axisLine={false}
                width={yAxisWidth}
              />
              <CartesianGrid
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                vertical={false}
              />
              <ReferenceLine stroke="#94A0B8" x={trainEnd?.date} />
              <ReferenceArea fill="#F8F7FF" x2={trainEnd?.date} />
              <Area
                dataKey="actual_kpi"
                fill="#CBD2E1"
                isAnimationActive={false}
                stroke="#CBD2E1"
                type="monotone"
              />
              <Line
                dataKey="predicted_kpi"
                dot={false}
                isAnimationActive={false}
                stroke="#6B2DEF"
                strokeWidth={3}
                type="monotone"
              />
              <Tooltip
                content={({ label, payload }) => {
                  if (!payload || !label) {
                    return null;
                  }

                  const formattedLabel = headerFormatter(dateGrouping, label);
                  let items = R.sortBy(
                    (item: any) => -Math.abs(item.value),
                    payload?.map(item => ({
                      label: (item?.name ?? "")
                        .toString()
                        .split("_")
                        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
                        .join(" ")
                        .replace("Kpi", "KPI") as string,
                      value: tooltipFormatter
                        ? tooltipFormatter(item.value)
                        : (item.value as number),
                      color: item.stroke,
                    })) || []
                  );

                  return <ChartTooltip headerLabel={formattedLabel} items={items} />;
                }}
                isAnimationActive={false}
              />
            </ComposedChart>
          )}
        </AutoSizer>
      </div>
      <div className="rightOfChart">
        <div className="legend">
          <div className="legendItem">
            <div className="square" style={{ backgroundColor: "#CBD2E1" }} />
            <div>{`Actual ${legendValue}`}</div>
          </div>
          <div className="legendItem">
            <div className="line" />
            <div>{`Predicted ${legendValue}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActualVsPredicted;
