import React, { useState } from "react";
import { XAxis, YAxis, BarChart, Bar, Cell, ResponsiveContainer, Tooltip } from "recharts";
import "./SpendAndEffectBarChart.scss";
import { Legend } from "../../Components/SnapshotChart";
import { getBarChartContributersData } from "../MMMUtils";
import { Setter } from "../../utils/types";

interface SpendAndEffectBarChartProps {
  barData: any[];
  data: any[];
  setBarData?: Setter<any>;
  actualValues: boolean;
  valueFormatter: (value: number) => string;
  activeChannel: string;
  setActiveChannel?: Setter<string>;
  className?: string;
  colorMap: {
    [key: string]: string;
  };
  expandedModal: boolean | undefined;
  barSize: number;
  setXAxisScale?: Setter<number>;
  xAxisScale?: number;
}

const genToolTipText = (perc: number, chan: string, type: string | boolean): string => {
  const from = type === "direct" ? "Direct" : type ? "Induced by" : "Attributed to";
  return `${perc.toFixed(1)}% ${from} ${chan}`;
};

const BAR_MAP = [
  {
    dataKey: "value",
    stackId: "spendModal",
    opacity: 0.5,
  },
  {
    dataKey: "value2",
    stackId: "revenueModal",
    opacity: 1,
  },
  {
    dataKey: "twoStageSpend",
    stackId: "spendModal",
    opacity: 0.5,
  },
  {
    dataKey: "twoStageEffect",
    stackId: "revenueModal",
    opacity: 1,
  },
];

export const SpendAndEffectBarChart: React.FC<SpendAndEffectBarChartProps> = ({
  barData,
  setBarData,
  actualValues,
  valueFormatter,
  setActiveChannel,
  activeChannel,
  data,
  className = "",
  colorMap,
  expandedModal,
  barSize,
  setXAxisScale,
  xAxisScale,
}) => {
  const [tooltipText, setTooltipText] = useState<string>("");
  const bars = BAR_MAP.reduce((acc: any[], el, i) => {
    el.dataKey === "twoStageSpend" || el.dataKey === "twoStageEffect"
      ? data.forEach((channel, j) => {
          const type = el.dataKey === "twoStageSpend" ? "Spend" : "Effect";
          acc.push(
            <Bar
              key={`subModalBar${type + j}`}
              barSize={barSize}
              dataKey={`${channel.name}:${type}`}
              isAnimationActive={false}
              opacity={el.opacity}
              stackId={el.stackId}
            >
              {barData.map(item => (
                <Cell
                  key={`subModalCell${item.name}${i}`}
                  fill={
                    item.added
                      ? `url(#shadedStripeNeg${
                          activeChannel === "All Channels" || channel.name === activeChannel
                            ? colorMap[item.name]
                            : item.name === activeChannel
                            ? colorMap[channel.name]
                            : "grey"
                        })`
                      : `url(#shadedStripe${
                          activeChannel === "All Channels" || channel.name === activeChannel
                            ? colorMap[item.name]
                            : item.name === activeChannel
                            ? colorMap[channel.name]
                            : "grey"
                        })`
                  }
                  stroke="white"
                  strokeWidth={expandedModal ? 3 : 0}
                  onClick={
                    expandedModal && setBarData && setActiveChannel
                      ? () => {
                          setBarData(getBarChartContributersData(data, item.contributers));
                          setActiveChannel(item.name);
                        }
                      : undefined
                  }
                  onMouseOver={
                    expandedModal
                      ? () =>
                          setTooltipText(
                            genToolTipText(
                              item[`${channel.name}:${type}`],
                              channel.name,
                              item.added
                            )
                          )
                      : undefined
                  }
                />
              ))}
            </Bar>
          );
        })
      : acc.push(
          <Bar
            key={`modalBar${i}`}
            barSize={barSize}
            dataKey={el.dataKey}
            isAnimationActive={false}
            opacity={el.opacity}
            stackId={el.stackId}
          >
            {barData.map(item => (
              <Cell
                key={`modalcell${item.name}${i}`}
                fill={
                  activeChannel === "All Channels" || activeChannel === item.name
                    ? colorMap[item.name]
                    : "grey"
                }
                onClick={
                  expandedModal && setBarData && setActiveChannel
                    ? () => {
                        setBarData(getBarChartContributersData(data, item.contributers));
                        setActiveChannel(item.name);
                      }
                    : undefined
                }
                onMouseOver={
                  expandedModal
                    ? () => setTooltipText(genToolTipText(item[el.dataKey], item.name, "direct"))
                    : undefined
                }
                stroke="white"
                strokeWidth={expandedModal ? 3 : 0}
              />
            ))}
          </Bar>
        );
    return acc;
  }, []);

  return (
    <div className={`SpendAndEffectBarChart ${className}`}>
      <div className={`chartLegend ${actualValues ? "" : "hideValues"}`}>
        {barData.map(item => (
          <Legend
            name={item.name}
            value={item.value + item.contributorsSpendSum}
            value2={item.value2 + item.contributorsEffectSum}
            formatter={valueFormatter}
            key={item.name}
            incrementality={item.incrementality}
            expanded={true}
            label={item.label}
            label2={item.label2.bottom}
            actualValues={actualValues}
            onClick={(expandedModal && setActiveChannel && setBarData) ? () => {
              setBarData(getBarChartContributersData(data, item.contributers));
              setActiveChannel(item.name);
            }: undefined}
          />
        ))}
      </div>
      <ResponsiveContainer className="rechartContainer" width="100%" height={barData.length * 70}>
        <BarChart layout="vertical" barCategoryGap={6} barGap={8} data={barData}>
          <XAxis
            type="number"
            domain={
              expandedModal && setXAxisScale
                ? [
                    0,
                    dataMax => {
                      if (!xAxisScale || dataMax > xAxisScale) {
                        setXAxisScale(dataMax);
                        return dataMax;
                      } else {
                        return xAxisScale;
                      }
                    },
                  ]
                : [0, "auto"]
            }
            hide
          />
          <YAxis type="category" dataKey="value" hide />
          {expandedModal && (
            <Tooltip
              shared={false}
              content={() => <div className="barChartTooltip">{tooltipText}</div>}
              cursor={{ fill: "transparent" }}
            />
          )}
          {bars}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
