import downloadjs from "downloadjs";
import Papa from "papaparse";
import Canvg from "canvg";
import ReactGA from "react-ga4";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import { Mixpanel, MxE } from "./mixpanelWrapper";

export const download = (
  csv: string | File | Blob | Uint8Array,
  fileName: string,
  mimeType?: string | undefined
): void => {
  // Log to GA4 so we can keep track of what's being downloaded from the app.
  ReactGA.event({
    category: "download",
    action: "download",
    label: fileName,
  });
  Mixpanel.track(MxE.CSV_DOWNLOAD, {
    fileName: fileName,
  });

  downloadjs(csv, fileName, mimeType);
};

export const downloadJSONToCSV = (data: Record<string, any>[], fileName: string): void => {
  if (!(data && fileName)) {
    console.error("Must provide data and fileName to download JSON to CSV");
    return;
  }
  const csv = Papa.unparse(data);
  download(csv, `${fileName}.csv`, "text/csv");
};

export const convertSVGStringToPNGURI = (
  svgString: string,
  width = 1920,
  height = 1080
): string => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return "";
  }
  let v = Canvg.fromString(ctx, svgString);
  v.resize(width, height, true);
  v.render();

  let pngUri = canvas.toDataURL("image/png");
  canvas.remove();

  return pngUri;
};

/*
 * Export an array of objects to an Excel file.
 */
export const exportToExcel = (data: Record<string, any>[], name: string): void => {
  const resolvedTitle = name.length > 31 ? name.slice(0, 31) : name;
  const fileName = `${name}.xlsx`;
  const sheetName = resolvedTitle;

  let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
  let worksheet = XLSX.utils.json_to_sheet(data);

  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = worksheet;

  Mixpanel.track(MxE.EXCEL_DOWNLOAD, {
    fileName: name,
  });

  XLSX.writeFile(workbook, fileName);
};

/*
 * Download a PNG of an HTML element.
 */
export const downloadPNG = async (
  elementName: string,
  fileName: string | string[],
  label = "",
  classNamesToRemove: string[] = []
): Promise<void> => {
  const ourFileNameString = Array.isArray(fileName) ? fileName.join("_") : fileName;
  const ourFileName = `${ourFileNameString.replace(/[- ]/g, char =>
    char === "-" ? "." : ""
  )}.png`;
  const contents = document.querySelector<HTMLElement>(elementName);
  if (!contents) {
    return;
  }
  Mixpanel.track(MxE.PNG_DOWNLOAD, {
    fileName: ourFileName,
    label: `${label} ${elementName}`,
  });

  const options = {
    backgroundColor: null,
    onclone: (_, el) => {
      const chart = el.querySelector(".chartContainer");
      if (chart) {
        chart.style.backgroundColor = "white";
      } else {
        el.style.backgroundColor = "white";
      }
      for (const name of classNamesToRemove) {
        const elementToRemove = el.querySelector(name);
        if (elementToRemove) {
          elementToRemove.remove();
        }
      }
    },
    scale: 1,
  };

  const canvas = await html2canvas(contents, options);

  const newCanvas = document.createElement("canvas");
  newCanvas.width = canvas.width;
  newCanvas.height = canvas.height + 30;
  const newCtx = newCanvas.getContext("2d");

  if (newCtx && label.length) {
    newCtx.drawImage(canvas, 0, 0);
    newCtx.font = "16px Graphik";
    newCtx.fillStyle = "black";
    newCtx.textAlign = "start";
    newCtx.fillText(label, 15, canvas.height + 20);
    const dataURL = newCanvas.toDataURL("image/png");
    downloadjs(dataURL, ourFileName, "image/png");
  } else {
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, ourFileName, "image/png");
  }
};
