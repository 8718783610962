const streamingGeoTargetingUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "alex.echeverria@tinuiti.com",
  "justin.manus@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  "kyle.chan@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "benjamin.contesse@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  "philip.rundall@tinuiti.com",
  "galen.murray@tinuiti.com",
  "paul.sims@tinuiti.com",
  "taymour.siddiqui@tinuiti.com",
  "matthew.black@tinuiti.com",
  "nina.grill@tinuiti.com",
  "garrett.cheadle@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "catherine.hayden@tinuiti.com",
  "hinling.yeung@tinuiti.com",
  "christopher.nilsson@tinuiti.com",
  "kira.cooper@tinuiti.com",
  "ryan.murphy@tinuiti.com",
  "jack.zahn@tinuiti.com",
  "michelle.ricciuti@tinuiti.com",
];

export const shouldEnableStreamingGeoTargeting = (userEmail: string): boolean => {
  return streamingGeoTargetingUsers.includes(userEmail);
};
