import React from "react";

import * as Dfns from "date-fns/fp";
import * as R from "ramda";

import { Form } from "react-bootstrap";
import { CheckBox, SingleDatePicker } from "../Components";
import { DATE_FORMAT, parseInputToInt } from "../utils/data";
import { SlideState } from "./slideTemplateConstants";
import { SlideType, SlideContext, SettingsComponentProps } from "./slidesTypes";

const DEFAULT_TITLE_BAR_OFFSET = 552;
const DEFAULT_TITLE_AGENCY_LOGO_POSITION = { left: 1094, top: 659 };
const DEFAULT_TITLE_COMPANY_LOGO_POSITION = { left: 32, top: 24 };

interface Logo {
  enabled: boolean;
  inverted: boolean;
  left: number;
  top: number;
}
export interface TitleSlideState {
  date: string;
  barOffset: number;
  logos: {
    agency: Logo;
    company: Logo;
  };
}

type TitleSlideData = TitleSlideState;

class TitleSlide extends SlideType {
  static typeKey = "title";
  static displayKey = "Title";
  static defaultState: TitleSlideState = {
    date: R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date()),
    barOffset: DEFAULT_TITLE_BAR_OFFSET,
    logos: {
      agency: {
        enabled: true,
        inverted: false,
        ...DEFAULT_TITLE_AGENCY_LOGO_POSITION,
      },
      company: {
        enabled: true,
        inverted: false,
        ...DEFAULT_TITLE_COMPANY_LOGO_POSITION,
      },
    },
  };

  static SettingsComponent: React.FC<SettingsComponentProps<TitleSlideState>> = React.memo(
    ({ state, setState }) => {
      const { date, barOffset, logos } = state;

      return (
        <div className="settingsBox">
          <div>
            <Form.Group>
              <Form.Label>Display Date</Form.Label>
              <SingleDatePicker
                block
                small={false}
                date={date}
                onChange={date => setState({ date })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Title Bar Top Offset (in px)</Form.Label>
              <Form.Control
                as="input"
                type="number"
                value={`${barOffset}`}
                onChange={e =>
                  setState({
                    barOffset: parseInputToInt(e.currentTarget.value),
                  })
                }
              />
            </Form.Group>
          </div>
          {[
            ["agency", "Agency"],
            ["company", "Company"],
          ].map(([key, label]) => (
            <div key={key}>
              <Form.Group className="checkboxFormGroup titleLabelColumn">
                <Form.Label>Use {label} Logo</Form.Label>
                <div>
                  <CheckBox
                    checked={logos[key].enabled}
                    onCheck={enabled =>
                      setState(existing => ({
                        ...existing,
                        logos: {
                          ...existing.logos,
                          [key]: {
                            ...existing.logos[key],
                            enabled,
                          },
                        },
                      }))
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="checkboxFormGroup">
                <Form.Label>Invert</Form.Label>
                <div>
                  <CheckBox
                    checked={logos[key].inverted}
                    onCheck={inverted =>
                      setState(existing => ({
                        ...existing,
                        logos: {
                          ...existing.logos,
                          [key]: {
                            ...existing.logos[key],
                            inverted,
                          },
                        },
                      }))
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Left Position (in px)</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  value={`${logos[key].left}`}
                  onChange={e => {
                    const { value } = e.currentTarget;
                    setState(existing => ({
                      ...existing,
                      logos: {
                        ...existing.logos,
                        [key]: {
                          ...existing.logos[key],
                          left: parseInputToInt(value),
                        },
                      },
                    }));
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Top Position (in px)</Form.Label>
                <Form.Control
                  as="input"
                  type="number"
                  value={`${logos[key].top}`}
                  onChange={e => {
                    const { value } = e.currentTarget;
                    setState(existing => ({
                      ...existing,
                      logos: {
                        ...existing.logos,
                        [key]: {
                          ...existing.logos[key],
                          top: parseInputToInt(value),
                        },
                      },
                    }));
                  }}
                />
              </Form.Group>
            </div>
          ))}
        </div>
      );
    }
  );
  generate = (context: SlideContext, state: SlideState): Promise<TitleSlideData> =>
    Promise.resolve(state as TitleSlideState);
}
export default TitleSlide;
