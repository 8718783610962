import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "@reach/router";

import {
  BPMTable,
  Card,
  DMACode,
  DmaMap,
  FullPageSpinner,
  GeoDataObject,
  Page,
  Spinner,
} from "../Components";

import { MdSave, MdEdit, MdAdd } from "react-icons/md";

import { Button, Form, Modal } from "react-bootstrap";

import { useMap } from "../utils/hooks/useData";

import AutoSizer from "react-virtualized-auto-sizer";

import Select from "react-select";

import { useSetError } from "../redux/modals";

import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";

import * as R from "ramda";

import { StreamingGeoTargetingGroupsRow } from "@blisspointmedia/bpm-types/dist/pgTables/StreamingGeoTargetingGroups";

import "./StreamingGeoTargeting.scss";

const FILL_SELECTED_COLOR = "#7e57c2";
const FILL_COLOR = "#f3f2f5";

interface GroupMenuOption {
  label: string;
  value: { dmas: string[]; exclude: boolean };
}

interface DmaMenuOption {
  label: string;
  value: string;
}

interface TableData {
  code: string;
  name: string;
  households: string;
}

const StreamingGeoTargeting = ({
  company,
  orderInfo,
}: {
  company: string;
  orderInfo: string;
}): React.ReactNode => {
  const setError = useSetError();

  const [dmaData, setDmaData] = useState<GeoDataObject>();

  useEffect(() => {
    (async () => {
      try {
        let dmaData = await ToolsLambdaFetch("/getDmaData");
        let res = await awaitJSON(dmaData);
        setDmaData(res);
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get DMA data: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [setError]);

  const renderTooltip = useCallback(
    (dma: DMACode): React.ReactNode => {
      if (!(dmaData && dmaData[dma])) {
        return null;
      }
      const { households, name } = dmaData[dma];

      return R.map(
        ([label, value]) => (
          <div key={label}>
            <span className="tooltipLabel">
              <b>{label}:</b>
            </span>
            {` ${value}`}
          </div>
        ),
        [
          ["DMA", name],
          ["Households", households.toLocaleString("en-us")],
        ]
      );
    },
    [dmaData]
  );

  const [selectedDmas, setSelectedDmasValue, setSelectedDmas] = useMap<string, boolean>();

  const [existingGroups, setExisitingGroups] = useState<StreamingGeoTargetingGroupsRow[]>();

  const [inUseGroups, setInUseGroups] = useState<Set<string>>(new Set<string>());

  const [selectedGroup, setSelectedGroup] = useState<GroupMenuOption>();

  const [editing, setEditing] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    (async () => {
      if (company && !editing) {
        try {
          let res = await ToolsLambdaFetch("/getGeoTargetingGroups", {
            params: {
              company,
            },
          });
          let groups = await awaitJSON(res);
          setExisitingGroups(groups);
          setInUseGroups(
            new Set<string>(groups.filter(group => group.inUse).map(group => group.group_name))
          );
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: reportError.message,
            reportError,
          });
        }
      }
    })();
  }, [company, setError, editing]);

  const updateGeoTargetingGroup = useCallback(
    dmaList => {
      (async () => {
        if (company && selectedGroup) {
          try {
            const body = {
              company,
              group_name: selectedGroup.label,
              dmas: dmaList,
              exclude: selectedGroup?.value.exclude,
            };

            await ToolsLambdaFetch("/updateGeoTargetingGroups", {
              method: "POST",
              body,
            });

            setSelectedGroup({
              label: selectedGroup.label,
              value: { dmas: dmaList, exclude: selectedGroup?.value.exclude },
            });

            setEditing(false);
            setSaving(false);
          } catch (e) {
            const reportError = e as Error;
            setError({
              message: reportError.message,
              reportError,
            });
          }
        }
      })();
    },
    [company, selectedGroup, setError]
  );

  const tableData = useMemo(() => {
    if (selectedGroup && existingGroups) {
      let tableData: TableData[] = [];
      selectedGroup.value.dmas.forEach(dma => {
        if (dmaData) {
          const { households, name } = dmaData[dma];
          setSelectedDmasValue(dma, true);
          tableData.push({ code: dma, name: name, households: households.toLocaleString("en-us") });
        }
      });

      return tableData;
    }
  }, [selectedGroup, dmaData, setSelectedDmasValue, existingGroups]);

  const tableHeaders = [
    { label: "Code", name: "code", flex: 1 },
    { label: "Name", name: "name", flex: 3 },
    { label: "Households", name: "households", flex: 1 },
  ];

  const groupMenuOptions = useMemo(() => {
    if (existingGroups) {
      let options: GroupMenuOption[] = [];
      existingGroups.forEach(group => {
        options.push({
          label: group.group_name,
          value: { dmas: group.dmas, exclude: group.exclude },
        });
      });
      return options;
    }
    return null;
  }, [existingGroups]);

  const dmaOptions = useMemo(() => {
    if (dmaData) {
      let options: DmaMenuOption[] = [];
      Object.keys(dmaData).forEach(dma => {
        const { name } = dmaData[dma];
        options.push({ label: name, value: dma });
      });
      return options;
    }
  }, [dmaData]);

  const selectedDmaOptions = useMemo(() => {
    return dmaOptions?.filter(option => selectedDmas[option.value]);
  }, [selectedDmas, dmaOptions]);

  const renderColor = useMemo(() => {
    if (selectedDmas) {
      return (dma: DMACode) => {
        if (selectedDmas[dma]) {
          return selectedGroup?.value.exclude ? FILL_COLOR : FILL_SELECTED_COLOR;
        }
        return selectedGroup?.value.exclude ? FILL_SELECTED_COLOR : FILL_COLOR;
      };
    }
  }, [selectedDmas, selectedGroup]);

  const onClickHandler = useCallback(
    (dma: DMACode) => {
      if (selectedDmas[dma]) {
        setSelectedDmasValue(dma, false);
      } else {
        setSelectedDmasValue(dma, true);
      }
    },
    [selectedDmas, setSelectedDmasValue]
  );

  const [showNewGroupNameModal, setShowNewGroupNameModal] = useState(false);

  const [newGroupName, setNewGroupName] = useState("");

  const customStyles = {
    valueContainer: provided => ({
      ...provided,
      maxHeight: "67px",
      overflow: "auto",
    }),
  };

  const [groupNameError, setGroupNameError] = useState<string>("");

  return (
    <Page
      title="Streaming Geo Targeting"
      pageType="Streaming Geo Targeting"
      actions={
        orderInfo ? (
          <div className="geoTargetingActions">
            <Link to={`/${company}/streaming/buying/${orderInfo}`}>Return to Order</Link>
          </div>
        ) : (
          <div></div>
        )
      }
    >
      {groupMenuOptions ? (
        <div className="streamingGeoTargeting">
          <Card className="sidePanel">
            <div className="groupMenuAndAddNewGroupButton">
              <Select
                isSearchable
                className="groupMenu"
                value={selectedGroup}
                options={groupMenuOptions}
                onChange={value => {
                  setSelectedGroup(value);
                  setSelectedDmas({});
                  setEditing(false);
                }}
              />
              <Modal
                size="sm"
                keyboard={true}
                show={showNewGroupNameModal}
                onHide={() => setShowNewGroupNameModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{"Enter a new group name:"}</Modal.Title>
                </Modal.Header>
                <div className="inputContainer">
                  <Form.Control
                    as="textarea"
                    placeholder="Name..."
                    rows={1}
                    value={newGroupName}
                    onChange={e => setNewGroupName(e.currentTarget.value)}
                  />
                </div>
                <Modal.Footer>
                  {groupNameError && <div>{groupNameError}</div>}
                  <Button
                    variant="success"
                    onClick={() => {
                      let groupNames: string[] = [];
                      existingGroups?.forEach(row => groupNames.push(row.group_name));
                      if (groupNames.includes(newGroupName)) {
                        setGroupNameError("There is already a group with this name.");
                      } else if (!newGroupName.trim().length) {
                        setGroupNameError("This name is not unique. Please choose another name.");
                      } else {
                        setShowNewGroupNameModal(false);
                        setEditing(true);
                        setSelectedGroup({
                          label: newGroupName.trim(),
                          value: { dmas: [], exclude: false },
                        });
                        setSelectedDmas({});
                        setNewGroupName("");
                        setGroupNameError("");
                      }
                    }}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
              <Button
                variant="primary"
                onClick={() => {
                  setShowNewGroupNameModal(true);
                }}
              >
                <MdAdd />
              </Button>
            </div>
            {editing ? (
              <div className="tableWhileEditing">{`Currently editing ${selectedGroup?.label}`}</div>
            ) : (
              <div className="tableContainer">
                <div className="tableTitle">
                  {selectedGroup?.value.exclude ? "Excluded DMAs" : "Included DMAs"}
                </div>
                <BPMTable
                  headers={tableHeaders}
                  data={tableData}
                  noRowsRenderer={() => <div>No DMAs to show.</div>}
                />
              </div>
            )}
          </Card>
          <div className="rightPanel">
            <div className="topRightPanel">
              <Button
                disabled={!selectedGroup}
                variant={editing ? "success" : "primary"}
                className="editingButton"
                onClick={() => {
                  if (editing) {
                    const dmaList = Object.keys(selectedDmas).filter(dma => selectedDmas[dma]);
                    let duplicateGroup = "";
                    existingGroups?.forEach(group => {
                      if (
                        group.dmas.toString() === dmaList.toString() &&
                        group.exclude === selectedGroup?.value.exclude
                      ) {
                        duplicateGroup = group.group_name;
                      }
                    });
                    if (duplicateGroup) {
                      setError({
                        message: `DMA selections already exist within the group ${duplicateGroup}.`,
                      });
                    } else {
                      updateGeoTargetingGroup(dmaList);
                      setSaving(true);
                    }
                  } else if (inUseGroups.has(selectedGroup?.label || "")) {
                    setError({
                      message: "Cannot edit a Geo Targeting Preset that is already in use.",
                    });
                  } else {
                    setEditing(true);
                  }
                }}
              >
                {editing ? saving ? <Spinner size={16} /> : <MdSave /> : <MdEdit />}
              </Button>
              {editing && selectedGroup && (
                <div className="checkBoxContainer">
                  <input
                    type="CheckBox"
                    onChange={() =>
                      setSelectedGroup({
                        label: selectedGroup.label,
                        value: {
                          dmas: Object.keys(selectedDmas),
                          exclude: R.not(selectedGroup.value.exclude),
                        },
                      })
                    }
                    checked={selectedGroup.value.exclude}
                  />
                  <div className="checkBoxTitle">Exclude</div>
                </div>
              )}
              {editing && (
                <div className="mapDirections">
                  Select DMAs on map or menu to add or remove from group.
                </div>
              )}
            </div>
            <div className="menuContainer">
              {editing && (
                <Select
                  isSearchable
                  isMulti
                  className="dmaMenu"
                  menuPlacement="top"
                  value={selectedDmaOptions}
                  options={dmaOptions}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  styles={customStyles}
                  onChange={(dmas: DmaMenuOption[]) => {
                    setSelectedDmas({});
                    if (dmas?.length) {
                      dmas.forEach(dma => {
                        setSelectedDmasValue(dma.value, true);
                      });
                    }
                  }}
                />
              )}
            </div>

            <div className="geoTargetingMap">
              <AutoSizer>
                {({ width, height }) => (
                  <DmaMap
                    width={width}
                    height={height}
                    renderTooltip={renderTooltip}
                    renderColor={renderColor}
                    onClick={editing ? onClickHandler : () => {}}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default StreamingGeoTargeting;
