import "./ConfigColumn.scss";
import { Button } from "react-bootstrap";
import { ColumnMetaDataMap } from "../MetricsTable/metricsTableUtils";
import { DimensionColumn, MetricsTablePreset } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { MoveColumn, DeleteColumn, DIMENSION_COLUMN_DND_TYPE } from "./configUtils";
import { OnBlurInput, Dropdown } from "../../Components";
import { roleMap } from "../../UserAdmin/UserAdmin";
import { StateSetter } from "../../utils/types";
import { useDrag, useDrop } from "react-dnd";
import * as R from "ramda";
import cn from "classnames";
import ColumnTop from "./ColumnTop";
import React, { useCallback, useRef } from "react";

interface ConfigDimensionColumnProps {
  addColumn: (i: number) => void;
  color?: string;
  column: DimensionColumn;
  columnMetaDataMap: ColumnMetaDataMap;
  deleteColumn: DeleteColumn;
  hoverItem?: string;
  index: number;
  isOnlyColumn: boolean;
  moveColumn: MoveColumn;
  setPresetChanges: StateSetter<MetricsTablePreset>;
}

const ConfigDimensionColumn = ({
  addColumn,
  color,
  column,
  columnMetaDataMap = {},
  deleteColumn,
  hoverItem,
  index,
  isOnlyColumn,
  moveColumn,
  setPresetChanges,
}: ConfigDimensionColumnProps): JSX.Element => {
  const { dimensionTypeName, icon, id, label, role } = column;
  const columnRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DIMENSION_COLUMN_DND_TYPE, id, index },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [{ isOver, dropI }, drop] = useDrop({
    accept: DIMENSION_COLUMN_DND_TYPE,
    canDrop: item => item.id !== id,
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
      dropI: monitor.getItem()?.i,
    }),
    drop: (item: { type: typeof DIMENSION_COLUMN_DND_TYPE; id: string }) => {
      moveColumn(item.id, column.id);
    },
  });

  drop(preview(columnRef));
  drag(handleRef);

  const onDimensionChange = useCallback(
    (i, key, value) => {
      setPresetChanges(changes => {
        const newChanges = {
          ...changes,
          dimensionColumns: R.adjust(
            i,
            column => ({
              ...column,
              [key]: value,
            }),
            changes.dimensionColumns
          ),
        };
        if (key === "dimensionTypeName") {
          const metadata = columnMetaDataMap[value];
          newChanges.dimensionColumns[i].dimensionVarName = metadata.dimensionVarName;
          newChanges.dimensionColumns[i].dimensionTypeName = value;
        }

        return newChanges;
      });
    },
    [setPresetChanges, columnMetaDataMap]
  );

  return (
    <div className="columnConfigOuter" ref={columnRef}>
      <div
        className={cn("columnConfig", {
          isOver,
          left: isOver && dropI > index,
          right: isOver && dropI <= index,
          dragging: isDragging,
          isHoverItem: hoverItem === id,
        })}
        key={index}
      >
        <ColumnTop noHeaders color={color} handleRef={handleRef} addColumn={addColumn} i={index} />
        <div className="basicSection">
          <Dropdown
            label="Type"
            onChange={val => onDimensionChange(index, "dimensionTypeName", val)}
            options={R.filter(
              column =>
                !(column as string).toLowerCase().includes("logo") &&
                !(column as string).toLowerCase().includes("icon") &&
                !(column as string).toLowerCase().includes("thumbnail"),
              R.keys(R.defaultTo({}, columnMetaDataMap))
            )}
            size="sm"
            value={dimensionTypeName}
          />
        </div>
        <div className="formSection">
          <div className="label">Label</div>
          <OnBlurInput
            onChange={val => onDimensionChange(index, "label", val)}
            placeholder={`${dimensionTypeName} (default)`}
            size="sm"
            value={label || ""}
          />
        </div>
        <div className="basicSection">
          <Dropdown
            label="Icon"
            onChange={val => onDimensionChange(index, "icon", val)}
            size="sm"
            value={R.defaultTo("none", icon)}
            options={[
              {
                label: "None",
                value: "none",
              },
              {
                label: "Has Icon",
                value: "hasIcon",
              },
              {
                label: "Icon Only",
                value: "iconOnly",
              },
            ]}
          />
        </div>
        <div className="basicSection">
          <Dropdown
            size="sm"
            label="Role"
            value={`${R.defaultTo(10, role)}`}
            options={R.map(
              role => ({
                label: roleMap[role],
                value: role,
              }),
              R.keys(roleMap)
            )}
            onChange={val => onDimensionChange(index, "role", val)}
          />
        </div>
        {!isOnlyColumn && (
          <Button
            size="sm"
            variant="outline-danger"
            className="deleteButton"
            onClick={() => deleteColumn(index)}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConfigDimensionColumn;
