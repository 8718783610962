import React, { useState } from "react";
import { Dropdown, Page } from "../Components";
import "./BrandEquity.scss";
import useLocation from "../utils/hooks/useLocation";
import BrandImpact from "./BrandImpact/BrandImpact";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import BusinessImpact from "./BusinessImpact/BusinessImpact";

const enum TabKey {
  BRAND_IMPACT = "brand-impact",
  BUSINESS_IMPACT = "business-impact",
  METRICS = "metrics",
  OFFLINE_INPUTS = "offline-inputs",
  FAQ = "FAQ",
}

const NAVS = [
  { label: "Brand Impact", key: TabKey.BRAND_IMPACT },
  { label: "Business Impact", key: TabKey.BUSINESS_IMPACT },
];

export const GROUP_BY_OPTIONS = [
  { label: "Channel", value: "channel" },
  { label: "Platform", value: "platform" },
  { label: "Tactic", value: "tactic" },
  { label: "Brand/Nonbrand", value: "brand/nonbrand" },
];

export const GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC = [
  { label: "Ad Awareness", value: "ad_awareness" },
  { label: "Aided Awareness", value: "awareness" },
  { label: "Buzz", value: "buzz" },
  { label: "Consideration", value: "consideration" },
];

const BrandEquity: React.FC = ({ navigate }: RouteComponentProps) => {
  const { company } = useLocation();
  const [groupBy, setGroupBy] = useState("channel");
  const [groupByOptions, setGroupByOptions] = useState(GROUP_BY_OPTIONS);
  const [groupByMetric, setGroupByMetric] = useState("awareness");

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "brand-equity",
    defaultKey: TabKey.BRAND_IMPACT,
  });

  return (
    <Page
      app2Redesign
      title="Brand Equity"
      pageType="Brand Equity"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        <div className="brandEquityRightActions">
          <Dropdown
            background="dark"
            className="brandEquityKpiDropdown"
            onChange={setGroupBy}
            options={groupByOptions}
            value={groupBy}
          />
          <Dropdown
            background="dark"
            className="brandEquityGroupByDropdown"
            onChange={setGroupByMetric}
            options={GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC}
            value={groupByMetric}
          />
        </div>
      }
    >
      <Router className="fullPageRouter brandEquityPage">
        <BrandImpact
          company={company}
          groupBy={groupBy}
          groupByMetric={groupByMetric}
          setGroupByOptions={setGroupByOptions}
          path={"/"}
        ></BrandImpact>
        <Redirect noThrow from="/brandimpact" to={`../${TabKey.BRAND_IMPACT}`} />
        <Redirect noThrow from="/brand_impact" to={`../${TabKey.BRAND_IMPACT}`} />
        <BusinessImpact
          company={company}
          groupBy={groupBy}
          groupByMetric={groupByMetric}
          setGroupByOptions={setGroupByOptions}
          path={TabKey.BUSINESS_IMPACT}
        />
        <Redirect noThrow from="/businessimpact" to={`../${TabKey.BUSINESS_IMPACT}`} />
        <Redirect noThrow from="/business_impact" to={`../${TabKey.BUSINESS_IMPACT}`} />
      </Router>
    </Page>
  );
};

export default BrandEquity;
