import React, { useState } from "react";
import * as R from "ramda";
import { Modal, Button } from "react-bootstrap";

import { BPMButton } from "../../Components";
import { NielsenEstimates } from "../linearBuyingUtils";
import { DEMO_GROUPINGS, OVERLAPPING_DEMO_LOOKUP } from "../linearBuyingConstants";
import { StateSetter } from "../../utils/types";
import "./AudiencePickerModal.scss";

interface SelectDemosContainerProps {
  selections: Record<string, boolean>;
  onSelect: (demoValue: string) => void;
}
const SelectDemosContainer: React.FC<SelectDemosContainerProps> = ({ selections, onSelect }) => {
  return (
    <div>
      {R.keys(DEMO_GROUPINGS).map(group => {
        const groupDemos = DEMO_GROUPINGS[group];
        return (
          <div className="demoGroup" key={group}>
            <strong>{group}:</strong>
            <div className="demoButtons">
              {R.keys(groupDemos).map(prettyDemo => {
                const demoValue = groupDemos[prettyDemo];
                return (
                  <Button
                    className="demoButton"
                    key={prettyDemo}
                    size="sm"
                    variant={selections[demoValue] ? "primary" : "outline-primary"}
                    onClick={() => onSelect(demoValue)}
                  >
                    {prettyDemo}
                  </Button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface AudiencePickerModalProps {
  selectedDemos: Record<string, boolean>;
  setSelectedDemos: StateSetter<Record<string, boolean>>;
  setNielsenEstimates: StateSetter<NielsenEstimates>;
  setUniverseEstimate: StateSetter<number | undefined>;
  onClose: () => void;
}

const AudiencePickerModal: React.FC<AudiencePickerModalProps> = ({
  selectedDemos,
  setSelectedDemos,
  setNielsenEstimates,
  setUniverseEstimate,
  onClose,
}) => {
  const [selections, setSelections] = useState(selectedDemos ?? {});

  const onSave = () => {
    const demosSelected: Record<string, boolean> = R.pickBy(val => val === true, selections);
    setSelectedDemos(demosSelected);
    setNielsenEstimates({});
    setUniverseEstimate(undefined);
    onClose();
  };

  const onSelect = (demoValue: string) => {
    // If you select a "Meta" demo, unselect every other demo.
    if (R.values(DEMO_GROUPINGS.Meta).includes(demoValue)) {
      setSelections({ [demoValue]: true });
    } else {
      setSelections(current => {
        // If you select a demo that is not a "Meta", unselect the "Meta" demo.
        const removeMetaDemo: Record<string, boolean> = R.pickBy(
          (val, key) => !R.values(DEMO_GROUPINGS.Meta).includes(key),
          current
        );
        // Deselect overlapping demographics
        const removeOverlapDemo: Record<string, boolean> = R.pickBy(
          (val, key) => !R.values(OVERLAPPING_DEMO_LOOKUP[demoValue]).includes(key),
          removeMetaDemo
        );
        return {
          ...removeOverlapDemo,
          [demoValue]: !current[demoValue],
        };
      });
    }
  };

  return (
    <Modal show className="audiencePickerModal" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Demographics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectDemosContainer selections={selections} onSelect={onSelect} />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <BPMButton
            variant="danger"
            style={{ alignSelf: "start" }}
            onClick={() => setSelections({})}
          >
            Clear
          </BPMButton>
          <div>
            <BPMButton variant="dark" onClick={onClose} style={{ marginRight: "0.5rem" }}>
              Cancel
            </BPMButton>
            <BPMButton variant="primary" onClick={onSave}>
              Save
            </BPMButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AudiencePickerModal;
