import React from "react";
import { Page } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import MetaBuyingCreate from "./MetaBuyingCreate";
import MetaBuyingUpload from "./MetaBuyingUpload";
import MetaBuyingDraft from "./MetaBuyingDraft";
import MetaBuyingPublished from "./MetaBuyingPublished";

const enum PageTab {
  CREATE = "create",
  UPLOAD = "upload",
  DRAFTS = "drafts",
  PUBLISHED = "published",
}

const NAVS = [
  { label: "Create", key: PageTab.CREATE },
  { label: "Upload", key: PageTab.UPLOAD },
  { label: "Drafts", key: PageTab.DRAFTS },
  { label: "Published", key: PageTab.PUBLISHED },
];

const MetaBuying = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "social/meta-buying",
    defaultKey: PageTab.CREATE,
  });

  return (
    <Page
      app2Redesign
      title="Meta Buying"
      pageType="Meta Buying"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="metaBuyingPage">
        <Router className="fullPageRouter">
          <MetaBuyingCreate default path={PageTab.CREATE} />
          <MetaBuyingUpload path={PageTab.UPLOAD} />
          <MetaBuyingDraft path={PageTab.DRAFTS} />
          <MetaBuyingPublished path={PageTab.PUBLISHED} />
        </Router>
      </div>
    </Page>
  );
});

export default MetaBuying;
