import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import Papa from "papaparse";

import { ReactComponent as Save } from "../icons/Save.svg";

import { CostPerPathRow } from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line, Tooltip } from "recharts";
import { AxesSkeleton, Skeleton, PathSkeleton } from "../../Components";
import { seriesColors } from "../../utils/colors";
import { useCallback, useMemo } from "react";
import { download } from "../../utils/download-utils";

interface CostPerPathChartProps {
  data?: CostPerPathRow[];
  label: string;
  decimals?: number;
  volume?: boolean;
}

const VOLUME_KEYS = [
  "volume_lag1d",
  "volume_lag3d",
  "volume_lag7d",
  "volume_lag14d",
  "volume_lag30d",
] as const;
const CPP_KEYS = ["lag1d", "lag3d", "lag7d", "lag14d", "lag30d"] as const;

const TICK_DATE_FORMAT = "MMM d";

export const CostPerPathChart: React.FC<CostPerPathChartProps> = ({
  data,
  label,
  decimals,
  volume,
}) => {
  const yAxisWidth = useMemo(() => {
    let max = 0;
    if (!data) {
      return 0;
    }
    for (let row of data) {
      for (let key of R.keys(row)) {
        if (key !== "date" && key.startsWith(volume ? "volume" : "lag") && row[key] > max) {
          max = row[key];
        }
      }
    }

    // number of digits of largest number, plus decimals, plus the dollar sign, multiplied by ~6.5px
    // per letter + 10px for the axis and the padding. These are derived from eyeballing.
    return (`${Math.round(max)}`.length + (decimals || 0) + 1) * 6.5 + 10;
  }, [data, decimals, volume]);

  const downloadData = useCallback(() => {
    if (data) {
      let csv = Papa.unparse(R.project(["date", ...(volume ? VOLUME_KEYS : CPP_KEYS)], data));
      download(csv, `${label}.csv`, "text/csv");
    }
  }, [data, label, volume]);

  const tickFormatter = useCallback(
    val => {
      if (volume) {
        return Math.round(val as number).toLocaleString("en-us");
      }
      return (val as number).toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: decimals || 0,
      });
    },
    [decimals, volume]
  );
  const labelFormatter = useCallback(
    (value: string, name: string) => {
      let formatted: [string, string];
      if (volume) {
        formatted = [
          Math.round(parseFloat(value)).toLocaleString("en-us"),
          name.replace("volume_lag", ""),
        ];
        return formatted;
      }
      formatted = [
        parseFloat(value).toLocaleString("en-us", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: decimals,
        }),
        name.replace("lag", ""),
      ];
      return formatted;
    },
    [decimals, volume]
  );
  return (
    <div className="cppChart section overviewChart">
      <div className="sectionHeader cppHeader">
        <div>{label}</div>
        <div className="saveButton" onClick={downloadData}>
          <Save />
        </div>
      </div>
      <div className="chart">
        {data ? (
          data.length ? (
            <ResponsiveContainer>
              <LineChart data={data}>
                <YAxis width={yAxisWidth} tickFormatter={tickFormatter} />
                <XAxis
                  dataKey="date"
                  height={15}
                  tickFormatter={R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))}
                />
                {(volume ? VOLUME_KEYS : CPP_KEYS).map((lag, i) => (
                  <Line
                    key={lag}
                    dataKey={lag}
                    type="monotone"
                    stroke={seriesColors[i]}
                    dot={false}
                    strokeWidth={2}
                  />
                ))}
                <Tooltip
                  labelFormatter={R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))}
                  formatter={labelFormatter}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="noData">No Data</div>
          )
        ) : (
          <Skeleton>
            <PathSkeleton
              points={[
                [0, 0.3],
                [0.25, 0.7],
                [0.5, 0.2],
                [0.75, 0.9],
                [1, 0.5],
              ]}
              thickness={10}
            />

            <AxesSkeleton size={5} />
          </Skeleton>
        )}
      </div>
    </div>
  );
};
