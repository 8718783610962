// Radio Buying Experiment

// If opted into this experiment, you'll see the "Market" column on the Buying Page
// And also be able to create local iscis for audio
const radioCompanies: string[] = [
  "blisspoint",
  "elf",
  "instacart",
  "repipespecialists",
  "trugreen",
  // Add companies here!
];

export const shouldEnableRadioBuying = (company: string): boolean => {
  return radioCompanies.includes(company);
};
