import React, { useState, useContext, useEffect } from "react";
import WtoCancelModal from "./WtoCancelModal";
import { Button, ButtonType, OverlayTrigger, FullPageSpinner } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import Select from "react-select";
import { InputGroup, Col, Form, Tooltip } from "react-bootstrap";
import { HiOutlinePlus } from "react-icons/hi";
import { MdContentCopy, MdDelete } from "react-icons/md";
import { FormContext, formObjDefault } from "./WtoFormContext";
import { useSetError } from "../redux/modals";
import {
  updateParamFunnelStep,
  updateParamTestName,
  updateParamVersionNumber,
  getParamFunnelStep,
  getParamTestName,
  getParamVersionNumber,
  isSavedDraft,
  buildPloverUrl,
  trimBackslashes,
  formatRules,
  formatDefaultId,
} from "./WtoUtils";
import { formValidator } from "./WtoFormValidator";
import WtoValidationErrors from "./WtoValidationErrors";
import { WtoLambdaFetch } from "../utils/fetch-utils";
import "./WtoDetailsForm.scss";
import * as R from "ramda";

const selectStyle = {
  option: styles => ({
    ...styles,
    cursor: "pointer",
  }),
  control: styles => ({
    ...styles,
    cursor: "pointer",
  }),
};

export interface WtoDetailsFormProps {
  useCopyPlover: () => { tooltipText; setTooltipText; copyPloverUrl };
  domains: Record<string, any>[];
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
  readOnly: boolean;
  setReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
  formEdited: boolean;
  setFormEdited: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelConfirmation: boolean;
  setShowCancelConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  userEmail: string;
  fetchPlovers: () => void;
  companyId: string;
}

export const WtoDetailsForm: React.FC<WtoDetailsFormProps> = ({
  useCopyPlover,
  domains = [],
  setBreadcrumbPath,
  readOnly,
  setReadOnly,
  formEdited,
  setFormEdited,
  showCancelConfirmation,
  setShowCancelConfirmation,
  userEmail,
  fetchPlovers,
  companyId,
}) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialVariants, setInitialVariants] = useState<
    { variant_id: string; variant_url: string }[]
  >([]);

  const { wtoFormObj, setWtoFormObj } = useContext(FormContext);
  const { tooltipText, setTooltipText, copyPloverUrl } = useCopyPlover();
  const setError = useSetError();

  useEffect(() => {
    setTooltipText("Copy URL to Clipboard");
  }, [setTooltipText]);

  let ploverUrl = buildPloverUrl(wtoFormObj.domain, wtoFormObj.folder_path, wtoFormObj.plover_url);

  useEffect(() => {
    if (R.isEmpty(initialVariants)) {
      setInitialVariants(wtoFormObj.variants);
    }
    let edited = false;
    const editableFields = [
      "test_name",
      "domain",
      "folder_path",
      "plover_url",
      "utm_test_id",
      "default_id",
      "default_url",
      "notes",
    ];
    editableFields.forEach(field => {
      if (wtoFormObj[field] && wtoFormObj[field].length) {
        edited = field === "folder_path" ? wtoFormObj.folder_path !== "/" : true;
      }
    });
    if (edited) {
      setFormEdited(true);
    } else {
      setFormEdited(false);
    }
    if (wtoFormObj.status.toLocaleLowerCase() === "draft" || wtoFormObj.status === "") {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [wtoFormObj, setFormEdited, setReadOnly, initialVariants]);

  const validateForm = (draft?: boolean) => {
    let errors = formValidator(
      wtoFormObj,
      [
        "test_name",
        "domain",
        "folder_path",
        "plover_url",
        "utm_test_id",
        "variants",
        "default_id",
        "default_url",
      ],
      {
        isBulkPlover: false,
      }
    );
    wtoFormObj.variants.forEach((currVariant, currIndex) => {
      const sameIdIndex = R.findIndex(
        (variant: { variant_id: string; split: number }) =>
          variant.variant_id === currVariant.variant_id,
        R.remove(currIndex, 1, wtoFormObj.variants)
      );
      const fDashIndex = currVariant.variant_id.indexOf("f-");
      if (sameIdIndex !== -1) {
        errors.push(
          `Multiple variants with ID '${currVariant.variant_id}' detected; variant IDs must be unique`
        );
      }
      if (fDashIndex === 0) {
        errors.push(
          `Variant ID '${currVariant.variant_id}' includes the prefix 'f-'; this prefix is reserved for fallback variant IDs`
        );
      }
    });
    errors = R.uniq(errors);
    if (errors.length > 0) {
      setValidationErrors(errors);
    } else if (draft) {
      saveAsDraft();
    } else {
      if (!R.equals(wtoFormObj.variants, initialVariants)) {
        const newRules = R.clone(wtoFormObj.rules);
        wtoFormObj.rules.forEach((rule, ruleIndex) => {
          const newRule = R.clone(rule);
          newRule.additional_utm = [];
          newRule.cookie_operation = [];
          newRule.cookie_operation_url = [];
          newRule.dow_operation = [];
          newRules[ruleIndex] = newRule;
        });
        updateFormField("rules", newRules);
      }
      setBreadcrumbPath(["Route Details", "Rules"]);
    }
  };

  const modifyVariantInRules = (
    oldVariant: { variant_id: string; variant_url: string },
    newVariant: { variant_id: string; variant_url: string }
  ) => {
    const newRules = R.clone(wtoFormObj.rules);
    wtoFormObj.rules.forEach((rule, ruleIndex) => {
      const newRule = R.clone(rule);
      const variantIndex = R.findIndex(
        (variant: { variant_id: string; split: number }) =>
          variant.variant_id === oldVariant.variant_id,
        rule.variant_splits
      );

      // update rules to use updated variants
      const newVariants = R.clone(rule.variant_splits);
      if (variantIndex !== -1) {
        newVariants[variantIndex] = {
          variant_id: newVariant.variant_id,
          split: rule.variant_splits[variantIndex].split,
        };
      }
      newRule.variant_splits = newVariants;
      newRules[ruleIndex] = newRule;
    });
    return newRules;
  };

  const renderVariants = () => {
    let updatedVariants = R.clone(wtoFormObj.variants);
    return (
      <div className="variantsWrapper">
        {wtoFormObj.variants.map((variant, index) => (
          <InputGroup key={index}>
            <InputGroup.Text>{index + 1}.</InputGroup.Text>
            <Form.Control
              className="variantId"
              value={variant.variant_id || ""}
              placeholder="Variant ID"
              disabled={readOnly}
              onChange={e => {
                const updatedVariant = {
                  variant_id: e.target.value,
                  variant_url: updatedVariants[index].variant_url,
                };
                updatedVariants[index] = updatedVariant;
                const updatedRules = modifyVariantInRules(
                  wtoFormObj.variants[index],
                  updatedVariant
                );
                updateFormField("variants", updatedVariants);
                updateFormField("rules", updatedRules);
              }}
            />
            <Form.Control
              className="variantUrl"
              value={variant.variant_url || ""}
              placeholder="Variant URL"
              disabled={readOnly}
              onChange={e => {
                const updatedVariant = {
                  variant_url: e.target.value,
                  variant_id: updatedVariants[index].variant_id,
                };
                updatedVariants[index] = updatedVariant;
                const updatedRules = modifyVariantInRules(
                  wtoFormObj.variants[index],
                  updatedVariant
                );
                updateFormField("variants", updatedVariants);
                updateFormField("rules", updatedRules);
              }}
            />
            {wtoFormObj.variants.length > 1 && !readOnly && (
              <Button
                type={ButtonType.FILLED}
                icon={<MdDelete />}
                onClick={e => {
                  e.preventDefault();
                  let variantInUse = false;
                  wtoFormObj.rules.forEach(rule => {
                    const filteredSplits = R.filter(
                      (split: { split: string; variant_id: string }) =>
                        split.variant_id === wtoFormObj.variants[index].variant_id,
                      rule.variant_splits
                    );
                    if (filteredSplits.length > 0) {
                      variantInUse = true;
                    }
                  });
                  if (variantInUse) {
                    setValidationErrors([
                      "This variant can't be deleted because it is being used within a traffic split for a rule.",
                    ]);
                  } else {
                    setValidationErrors([]);
                    updateFormField("variants", wtoFormObj.variants.toSpliced(index, 1));
                  }
                }}
              />
            )}
          </InputGroup>
        ))}
      </div>
    );
  };

  const confirmCancel = () => {
    setWtoFormObj({ ...formObjDefault });
    setShowCancelConfirmation(false);
    setBreadcrumbPath([]);
  };

  const onCancelClick = () => {
    if (formEdited && !readOnly) {
      setShowCancelConfirmation(true);
    } else {
      confirmCancel();
    }
  };

  const updateFormField = (key, value) =>
    setWtoFormObj((current: any) => {
      return { ...current, [key]: value };
    });

  const saveAsDraft = async () => {
    setLoading(true);
    try {
      await WtoLambdaFetch(`/plover${isSavedDraft(wtoFormObj) ? `/${wtoFormObj.id}` : ""}`, {
        method: isSavedDraft(wtoFormObj) ? "PATCH" : "POST",
        params: {
          email: userEmail,
        },
        body: {
          company: companyId,
          test_name: wtoFormObj.test_name,
          utm_test_id: wtoFormObj.utm_test_id,
          default_url: wtoFormObj.default_url,
          default_id: formatDefaultId(wtoFormObj.default_id),
          plover_url: wtoFormObj.plover_url,
          folder_path: trimBackslashes(wtoFormObj.folder_path),
          notes: wtoFormObj.notes,
          variants: wtoFormObj.variants,
          domain: wtoFormObj.domain,
          rules: formatRules(wtoFormObj.rules),
          status: "draft",
        },
      });
      fetchPlovers();
      setBreadcrumbPath([]);
      setShowCancelConfirmation(false);
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to save draft. Error: ${error.message}`,
        reportError: error,
      });
    }
    setLoading(false);
  };

  return (
    <div className="wtoDetailsFormContainer">
      {loading ? (
        <FullPageSpinner />
      ) : (
        <Form className="wtoDetailsForm">
          <WtoValidationErrors validationErrors={validationErrors} />
          <Form.Row className="align-items-end">
            <Form.Group as={Col}>
              <Form.Label>Route Name</Form.Label>
              <Form.Control
                className={readOnly ? "disable" : "enable"}
                value={wtoFormObj.test_name || ""}
                disabled={readOnly}
                placeholder="Route Name. e.g. Quiz CTA Test"
                onChange={e => updateFormField("test_name", e.target.value)}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Route URL</Form.Label>
              <div className="helperText">
                <p>
                  Please select Domain Name and enter Route Name below. These would be used to
                  construct the following Route URL:
                </p>
                <div className="ploverUrlWrapper">
                  <span className="ploverUrl">{ploverUrl}</span>
                  <OverlayTrigger
                    placement="bottom center"
                    onExit={() => setTooltipText("Copy URL to Clipboard")}
                    overlay={<Tooltip id="id:copy plover">{tooltipText}</Tooltip>}
                  >
                    <div className="copyUrlIcon" onClick={e => copyPloverUrl(e, ploverUrl)}>
                      <MdContentCopy />
                    </div>
                  </OverlayTrigger>
                </div>
                <p>
                  <br />
                  Path must start and end with / character. If a folder does not exist it would be
                  created. Example: /pages/
                </p>
                <div className="ploverUrlBuilder">
                  <InputGroup>
                    <Select
                      styles={selectStyle}
                      className="ploverDomain"
                      value={
                        wtoFormObj.domain !== ""
                          ? { value: wtoFormObj.domain, label: wtoFormObj.domain }
                          : null
                      }
                      isDisabled={readOnly}
                      placeholder={"Domain"}
                      options={domains.map(({ web_url }) =>
                        web_url === "plover.insiderenvy.com"
                          ? { value: "s.insiderenvy.com", label: "s.insiderenvy.com" }
                          : { value: web_url, label: web_url }
                      )}
                      onChange={e => updateFormField("domain", e.value)}
                    />

                    <Form.Control
                      className={`ploverPath ${readOnly ? "disable" : "enable"}`}
                      value={wtoFormObj.folder_path || ""}
                      disabled={readOnly}
                      type="text"
                      placeholder="Path"
                      onChange={e => updateFormField("folder_path", e.target.value)}
                    />
                    <Form.Control
                      className={`ploverName ${readOnly ? "disable" : "enable"}`}
                      value={wtoFormObj.plover_url || ""}
                      disabled={readOnly}
                      type="text"
                      placeholder="Route Name"
                      onChange={e => updateFormField("plover_url", e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row className="trackingUrlParamWrapper">
            <Form.Group as={Col}>
              <Form.Label>Route Tracking URL Parameter</Form.Label>
              <div className="helperText">
                <p>
                  This field is for reporting and the value for this field should include a key
                  name, route name and proute version number. Values should be formatted as:
                  <br />
                  <br />
                  <span className="ploverUrl">
                    {
                      "utm_<insert key name>_exp=<insert route name>< insert route version number><optional custom identifiers for BI>"
                    }
                  </span>
                  <br />
                  <br />
                  Former Client Example: utm_home_exp=home4us
                  <br />
                  <br />
                  Additional notes on the Route Tracking URL Parameter:
                </p>
                <ul>
                  <li>Must be unique (no two Plover tests can have the same RTU parameter)</li>
                  <li>
                    If a Plover test was duplicated, you will want to update this field to your
                    liking
                  </li>
                  <li>
                    Once a Plover test has been deployed, the chosen variant ID will be appended to
                    the end of this parameter. Ex utm_home_exp=home1_
                    {"<variant id>"}
                  </li>
                </ul>
                <div className="trackingParameterBuilder trackingFields">
                  utm_
                  <Form.Control
                    value={getParamFunnelStep(wtoFormObj.utm_test_id)}
                    disabled={readOnly}
                    type="text"
                    placeholder="Funnel Step"
                    onChange={e =>
                      updateFormField(
                        "utm_test_id",
                        updateParamFunnelStep(e.target.value, wtoFormObj.utm_test_id)
                      )
                    }
                  />
                  _exp=
                  <InputGroup>
                    <Form.Control
                      className="testName"
                      value={getParamTestName(wtoFormObj.utm_test_id)}
                      disabled={readOnly}
                      type="text"
                      placeholder="Test Name"
                      onChange={e =>
                        updateFormField(
                          "utm_test_id",
                          updateParamTestName(e.target.value, wtoFormObj.utm_test_id)
                        )
                      }
                    />
                    <Form.Control
                      className="version"
                      value={getParamVersionNumber(wtoFormObj.utm_test_id)}
                      disabled={readOnly}
                      type="number"
                      placeholder="Version"
                      onChange={e =>
                        updateFormField(
                          "utm_test_id",
                          updateParamVersionNumber(Number(e.target.value), wtoFormObj.utm_test_id)
                        )
                      }
                    />
                  </InputGroup>
                </div>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Variants</Form.Label>
              <div className="helperText">
                <p>
                  Please enter a full variant URL, starting with HTTPS.
                  <br />
                  <br />
                  Example: https://www.insiderenvy.com/3-reasons-get-starz-v3-title1-syft
                  <br />
                  <br />
                  Additional notes on the Variant URL Parameter:
                </p>
                <ul>
                  <li>Please do not enter URL Parameters (UTMs) on this screen</li>
                  <li>
                    If you need to specify, change, or add, URL Parameters (UTMs) in the URL, please
                    use the URL Parameter functionality on the Rules screen
                  </li>
                  <li>
                    All incoming URL parameters will be carried over to the Variant URL, unless they
                    are modified using URL Parameter functionality on the Rules screen
                  </li>
                </ul>
              </div>
              {renderVariants()}
              {!readOnly && (
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<HiOutlinePlus />}
                  onClick={e => {
                    e.preventDefault();
                    updateFormField("variants", [
                      ...wtoFormObj.variants,
                      { variant_id: "", variant_url: "" },
                    ]);
                  }}
                >
                  Add Variant
                </Button>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Fallback Variant</Form.Label>
              <div className="helperText">
                <p>
                  Please enter a full fallback variant URL, starting with HTTPS. User would be shown
                  this variant if no others variants met split conditions or there was an error
                  evaluating traffic split conditions.
                  <br />
                  <br />
                  Example: https://www.insiderenvy.com/3-reasons-get-starz-v3-title1-syft
                  <br />
                  <br />
                  Please note that all fallback variant ID values will have "f-" automatically
                  prepended in front of the value if it is not present. This would help with
                  reporting and monitoring of traffic splits.
                </p>
              </div>
              <div className="fallbackVariantBuilder">
                <InputGroup>
                  <Form.Control
                    disabled={readOnly}
                    className="fallbackVariantId"
                    type="text"
                    placeholder="Fallback Variant ID"
                    value={wtoFormObj.default_id || ""}
                    onChange={e => {
                      updateFormField("default_id", e.target.value);
                    }}
                  />
                  <Form.Control
                    disabled={readOnly}
                    className="fallbackVariantUrl"
                    type="text"
                    placeholder="Fallback Variant URL"
                    value={wtoFormObj.default_url || ""}
                    onChange={e => {
                      updateFormField("default_url", e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Notes (optional)</Form.Label>
              <Form.Control
                disabled={readOnly}
                as="textarea"
                rows={3}
                className="notes"
                type="text"
                placeholder="Please enter your split description and split goals here."
                value={wtoFormObj.notes || ""}
                onChange={e => updateFormField("notes", e.target.value)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
      <div className="formButtons">
        <Button type={ButtonType.FILLED} onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          disabled={!formEdited || readOnly || loading}
          type={ButtonType.FILLED}
          onClick={(_: any) => validateForm(true)}
        >
          Save as Draft
        </Button>
        <Button
          disabled={loading}
          type={ButtonType.FILLED}
          onClick={e => {
            e.preventDefault();
            wtoFormObj.status.toLocaleLowerCase() === "draft"
              ? validateForm()
              : setBreadcrumbPath(["Route Details", "Rules"]);
          }}
        >
          Continue to Rules
        </Button>
      </div>
      <WtoCancelModal
        show={showCancelConfirmation}
        closeModal={() => setShowCancelConfirmation(false)}
        confirmCancel={confirmCancel}
      />
    </div>
  );
};

export default WtoDetailsForm;
