import { useState, useEffect, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import AutoSizer from "react-virtualized-auto-sizer";
import { MdSave, MdCancel } from "react-icons/md";
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import {
  Skeleton,
  PathSkeleton,
  BPMButton,
  KpiPicker,
  Spinner,
  InfoTooltip,
  SimpleTooltip,
  SimpleTooltipDirection,
  DownloadDropdown,
  Button,
  ButtonType,
} from "../Components";
import { abbreviateNumber } from "../utils/data";
import { formatNumberAsInt } from "../utils/format-utils";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  LINE_STROKE_WIDTH,
  TICK_STYLE,
  DAY_DATE_TOOLTIP_LABEL,
  X_AXIS_PADDING,
} from "./homePageConstants";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import ChartContainer from "../Components/ChartContainer";
import Funnel from "./Funnel.png";
import "./FunnelDynamics.scss";

interface FunnelDynamicsProps {
  company: string;
  start: string;
  end: string;
}

const AREA_CHART_COLORS = ["#3EC8FF", "#9DE3FF", "#CCF0FF"];

const ResponsesChart = ({ kpi, data, label, color }): JSX.Element => {
  const { responses, labels } = data;
  return (
    <div className="responsesChart">
      <div className="aboveChart">{label}</div>
      <AutoSizer>
        {({ width, height }) => (
          <AreaChart data={responses} width={width} height={height}>
            <CartesianGrid
              vertical={false}
              stroke={CARTESIAN_GRID_STROKE}
              strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
            />
            <Tooltip
              isAnimationActive={false}
              labelFormatter={time =>
                R.pipe(Dfns.parseISO, Dfns.format(DAY_DATE_TOOLTIP_LABEL))(time)
              }
              // @ts-ignore
              formatter={(value: number, name: string) => [formatNumberAsInt(value), labels[name]]}
              itemStyle={{ color: "black" }}
            />
            <XAxis
              dataKey="date"
              padding={X_AXIS_PADDING}
              tick={TICK_STYLE}
              tickFormatter={date => Dfns.format("M/dd/yy", Dfns.parseISO(date))}
            />
            <YAxis
              tickLine={false}
              tick={TICK_STYLE}
              tickFormatter={number => `${abbreviateNumber(number)}`}
              interval={1}
            />
            <Area
              dataKey={kpi}
              name={kpi}
              type="monotone"
              dot={false}
              isAnimationActive={false}
              stroke={color}
              fill={color}
            />
          </AreaChart>
        )}
      </AutoSizer>
    </div>
  );
};

const KPIResponsesCharts = ({ data, kpis }): JSX.Element => {
  if (!data?.kpiResponses.length) {
    return <div className="noFunnelKPIs">No funnel KPIs set for this chart.</div>;
  }
  return (
    <div className="kpiResponsesCharts">
      {kpis.map((kpi, i) => (
        <ResponsesChart
          data={{ responses: data?.kpiResponses, labels: data?.kpiLabels }}
          kpi={kpi}
          label={data?.kpiLabels[kpi]}
          color={AREA_CHART_COLORS[i]}
          key={kpi}
        />
      ))}
    </div>
  );
};

const ConversionChart = ({ conversion, data }): JSX.Element => {
  return (
    <div className="conversionChart">
      <div className="aboveChart">{`Conversion Rate: ${conversion}`}</div>
      <AutoSizer>
        {({ width, height }) => (
          <LineChart data={data?.conversionRates} width={width} height={height}>
            <CartesianGrid
              vertical={false}
              stroke={CARTESIAN_GRID_STROKE}
              strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
            />
            <Tooltip
              isAnimationActive={false}
              labelFormatter={time =>
                R.pipe(Dfns.parseISO, Dfns.format(DAY_DATE_TOOLTIP_LABEL))(time)
              }
              // @ts-ignore
              formatter={(value: number) => `${(Math.round(value * 1000) / 10).toFixed(1)}%`}
            />
            <XAxis
              dataKey="date"
              padding={X_AXIS_PADDING}
              tick={TICK_STYLE}
              tickFormatter={date => Dfns.format("M/dd/yy", Dfns.parseISO(date))}
            />
            <YAxis
              tickLine={false}
              tick={TICK_STYLE}
              tickFormatter={number => `${(Math.round(number * 1000) / 10).toFixed(0)}%`}
            />
            <Line
              dataKey={conversion}
              name={conversion}
              type="monotone"
              strokeWidth={LINE_STROKE_WIDTH}
              dot={false}
              isAnimationActive={false}
              stroke="#4E4E50"
            />
          </LineChart>
        )}
      </AutoSizer>
    </div>
  );
};

const ConversionRateCharts = ({ data }): JSX.Element => {
  return (
    <div className="conversionRateCharts">
      {data.conversionLabels.map(conversion => (
        <ConversionChart data={data} conversion={conversion} key={conversion} />
      ))}
    </div>
  );
};

const FunnelDynamics: React.FC<FunnelDynamicsProps> = ({ company, start, end }) => {
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const setError = useSetError();

  const [dataMap, setDataMap] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editKPIs, setEditKPIs] = useState({});
  const [showSaveTooltip, setShowSaveTooltip] = useState(false);
  const [showApplyTempKpisTooltip, setShowApplyTempKpisTooltip] = useState(false);

  const dataKey = `${start}_${end}`;

  const getKpis = useCallback(
    async tempKpis => {
      try {
        let params: any = { company, start, end };
        if (R.isEmpty(tempKpis)) {
          tempKpis = undefined;
        }
        if (tempKpis) {
          const ourTempKpis: string[] = [];
          for (let kpi of dataMap[dataKey].kpis) {
            ourTempKpis.push(kpi);
          }
          for (let [index, kpi] of Object.entries(tempKpis)) {
            ourTempKpis[index] = kpi;
          }

          const joinedTempKpis = R.values(ourTempKpis).join(",");
          params = { ...params, tempKpis: joinedTempKpis };
        }
        const res = await ToolsLambdaFetch("/getFunnelDynamics", {
          params,
        });
        const data = await awaitJSON(res);
        return data;
      } catch (e) {
        setError({
          message: `Failed to get funnel dynamics KPIs. Error: ${e.message}`,
          reportError: e,
        });
        return {};
      }
    },
    [company, end, start, setError, dataKey, dataMap]
  );

  useEffect(() => {
    if (dataMap[dataKey]) {
      return;
    }
    (async () => {
      const data = await getKpis(undefined);
      setDataMap(current => ({ ...current, [dataKey]: data }));
    })();
  }, [company, start, end, dataKey, dataMap, getKpis, editKPIs]);

  const data = dataMap[dataKey];

  const setKpiOnChange = useCallback((value, index) => {
    setEditKPIs(current => ({ ...current, [index]: value }));
  }, []);

  const saveChanges = useCallback(async () => {
    try {
      if (data) {
        const ourKPIs: string[] = [];
        for (let kpi of data.kpis) {
          ourKPIs.push(kpi);
        }
        for (let [index, kpi] of Object.entries(editKPIs)) {
          ourKPIs[index] = kpi;
        }
        setSaving(true);
        await ToolsLambdaFetch("/setFunnelKPIs", {
          method: "POST",
          body: { company, kpis: ourKPIs, start, end },
        });
        setEditKPIs({});
        setDataMap({});
        setEditMode(false);
        setSaving(false);
      }
    } catch (e) {
      setSaving(false);
      setError({ message: e.message, reportError: e });
    }
  }, [company, data, editKPIs, end, setError, start]);

  // Fetch selected KPIs, but don't save the preset to the database.
  const applyTempKpis = useCallback(async () => {
    try {
      setSaving(true);
      const res = await getKpis(editKPIs);
      setDataMap(current => ({ ...current, [dataKey]: res }));
      setEditKPIs({});
      setEditMode(false);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      setError({ message: e.message, reportError: e });
    }
  }, [dataKey, editKPIs, getKpis, setError]);

  const clearChanges = () => {
    setEditKPIs({});
    setEditMode(false);
  };

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_FunnelDynamics.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet((data || {}).kpiResponses);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, data]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".funnelDynamics");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_FunnelDynamics.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Funnel Dynamics"
      rightActions={
        <>
          {isInternal && (
            <>
              <InfoTooltip>
                Edit KPIs is internal only. The KPIs you save will be visible by everyone who visits
                this client. "Apply temporary" will be visible only for you until you leave the
                page.
              </InfoTooltip>
              {!editMode && (
                <Button
                  type={ButtonType.FILLED}
                  design="secondary"
                  size="sm"
                  onClick={() => setEditMode(prev => !prev)}
                >
                  Edit KPIs
                </Button>
              )}
            </>
          )}
          {isInternal && editMode && (
            <>
              <BPMButton size="sm" variant="danger" onClick={clearChanges}>
                {saving ? <Spinner /> : <MdCancel />}
              </BPMButton>
              <SimpleTooltip
                text="Apply temporary KPIs only for you"
                horizontalShift="-40px"
                verticalShift="-180%"
                onMouseEnter={() => setShowApplyTempKpisTooltip(true)}
                onMouseLeave={() => setShowApplyTempKpisTooltip(false)}
                showTooltip={showApplyTempKpisTooltip}
                direction={SimpleTooltipDirection.BOTTOM}
                maxWidth="200px"
              >
                <BPMButton size="sm" variant="success" onClick={applyTempKpis} disabled={saving}>
                  {saving ? <Spinner /> : <div>Apply temporary</div>}
                </BPMButton>
              </SimpleTooltip>
              <SimpleTooltip
                text="Save for all users"
                horizontalShift="-40px"
                verticalShift="-130%"
                onMouseEnter={() => setShowSaveTooltip(true)}
                onMouseLeave={() => setShowSaveTooltip(false)}
                showTooltip={showSaveTooltip}
                direction={SimpleTooltipDirection.BOTTOM}
                maxWidth="200px"
              >
                <BPMButton size="sm" variant="success" onClick={saveChanges} disabled={saving}>
                  {saving ? <Spinner /> : <MdSave />}
                </BPMButton>
              </SimpleTooltip>
            </>
          )}
          <DownloadDropdown
            size="sm"
            onClickOptions={[exportToExcel, downloadPNG]}
            disabled={!data}
          />
        </>
      }
    >
      {data ? (
        <div className="funnelDynamics">
          <div className="left">
            <KPIResponsesCharts data={data} kpis={data.kpis} />
          </div>
          <div
            className="middle"
            style={{
              backgroundImage: `url(${Funnel})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {[0, 1, 2].map(i => {
              return (
                <div key={i}>
                  {editMode ? (
                    <KpiPicker
                      onChange={val => setKpiOnChange(val, i)}
                      kpi={editKPIs[i] ?? data.kpis[i]}
                    />
                  ) : (
                    data.kpiLabels[data.kpis[i]]
                  )}
                </div>
              );
            })}
          </div>
          <div className="right">
            <ConversionRateCharts data={data} />
          </div>
        </div>
      ) : (
        <Skeleton>
          <PathSkeleton
            points={[
              [0, 0.3],
              [0.25, 0.7],
              [0.5, 0.2],
              [0.75, 0.9],
              [1, 0.5],
            ]}
          />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default FunnelDynamics;
