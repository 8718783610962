import "./FilterPane.scss";
import { awaitJSON, FiltersLambdaFetch } from "../../utils/fetch-utils";
import { BPMButton, CheckBox } from "../";
import {
  Category,
  FilterPaneAdvancedState,
  FilterPaneBasicState,
  FilterPaneState,
} from "@blisspointmedia/bpm-types/dist/FilterPane";
import {
  DeleteFilterPresetParams,
  GetFilterPresetsParams,
  SaveFilterPresetBody,
} from "@blisspointmedia/bpm-types/dist/FilterPresets";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import {
  filterTokenizer,
  makeSuggestionGenerator,
  tokensToWhereClause,
} from "@blisspointmedia/bpm-types/dist/advancedFilterParser";
import { Form, Spinner } from "react-bootstrap";
import {
  MdAddToPhotos,
  MdCancel,
  MdCheckCircle,
  MdClose,
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdExpandLess,
  MdExpandMore,
  MdFilterList,
  MdOutlineCircle,
  MdSave,
  MdSearch,
} from "react-icons/md";
import { StateSetter } from "../../utils/types";
import { useSetAreYouSure, useSetError } from "../../redux/modals";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
const FILTER_PANE_WIDTH = "400px";

interface FilterPresetRowProps {
  autoFocus: boolean;
  checkBoxOnClick: () => void;
  checked: boolean;
  className?: string;
  copyOnClick: () => void;
  deleteOnClick: () => void;
  editMode: boolean;
  editOnClick: () => void;
  name: string;
  newName: string;
  setNewName: (name: string) => void;
  setName: (name: string) => void;
}

export const FilterPresetRow: React.FC<FilterPresetRowProps> = React.memo(
  ({
    autoFocus,
    checkBoxOnClick,
    checked,
    copyOnClick,
    deleteOnClick,
    editMode,
    editOnClick,
    name,
    newName,
    setName,
    setNewName,
  }) => {
    return (
      <div className="filterPresetRow">
        <div className="filterPresetSelection">
          {checked ? (
            <MdCheckCircle className="filterPresetRowCheckbox" onClick={checkBoxOnClick} />
          ) : (
            <MdOutlineCircle className="filterPresetRowCheckbox" onClick={checkBoxOnClick} />
          )}
          {checked && editMode ? (
            <input
              autoFocus={autoFocus}
              className="filterPresetName"
              key={uuid.v4()}
              type="text"
              onChange={e => {
                setNewName(e.target.value);
              }}
              value={newName}
            />
          ) : (
            <div className="filterPresetName">{name}</div>
          )}
        </div>
        <div className="filterPresetRowIcons">
          {checked && editMode ? (
            <MdSave
              onClick={() => {
                setName(newName);
                editOnClick();
              }}
            />
          ) : (
            <MdEdit
              onClick={() => {
                editOnClick();
              }}
            />
          )}
          <MdContentCopy onClick={copyOnClick} />
          <MdDelete onClick={deleteOnClick} />
        </div>
      </div>
    );
  }
);

interface FilterPaneBasicProps {
  categories: Category[];
  state: FilterPaneBasicState;
  setState: StateSetter<FilterPaneBasicState>;
  readonly?: boolean;
}

const FilterPaneBasic: React.FC<FilterPaneBasicProps> = ({
  categories,
  state,
  setState,
  readonly,
}) => {
  const [openSection, setOpenSection] = useState("");

  const { notMap, selectedMap } = state;

  const setNotMap = useCallback(
    (category: string, not: boolean) => {
      setState(
        R.mergeDeepLeft({
          notMap: {
            [category]: not,
          },
        })
      );
    },
    [setState]
  );

  const setCheckBox = useCallback(
    (category: string, key: string, checked: boolean) => {
      setState(
        R.mergeDeepLeft({
          selectedMap: {
            [category]: {
              [key]: checked,
            },
          },
        })
      );
    },
    [setState]
  );

  const [subFilterMap, setSubFilterMap] = useState<Record<string, string | undefined>>({});

  return (
    <div className="filterPaneBody">
      {categories.map(({ label, key: category, options }) => {
        const expanded = openSection === category;
        const not = notMap[category];
        const selectedItemMap = selectedMap[category] || {};
        let selectedOptions: typeof options = [];
        for (let option of options) {
          let key = typeof option === "string" ? option : option.value;
          if (selectedItemMap[key]) {
            selectedOptions.push(option);
          }
        }
        if (readonly && !selectedOptions.length) {
          return null;
        }
        let excludeClasses = ["not"];
        if (not) {
          excludeClasses.push("selected");
        }
        if (readonly) {
          excludeClasses.push("disabled");
        }
        let headerClasses = ["categoryHeader"];
        if (readonly) {
          headerClasses.push("disabled");
        }
        let filterTerm = subFilterMap[category] || "";
        let filterTermLC = filterTerm.toLowerCase();
        return (
          <div key={category} className="categoryContainer">
            <div
              className={headerClasses.join(" ")}
              onClick={() => !readonly && setOpenSection(expanded ? "" : category)}
            >
              {label}
              {(expanded || not) && (
                <div
                  className={excludeClasses.join(" ")}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!readonly) {
                      setNotMap(category, !not);
                    }
                  }}
                >
                  Exclude
                </div>
              )}
              {!readonly && (
                <div className="expander">{expanded ? <MdExpandLess /> : <MdExpandMore />}</div>
              )}
            </div>
            {selectedOptions.length > 0 && (
              <div className="selectedItems">
                {selectedOptions.map(option => {
                  let label: string;
                  let value: string;
                  if (typeof option === "string") {
                    label = value = option;
                  } else {
                    ({ label, value } = option);
                  }
                  return (
                    <div className="selectedPill" key={value}>
                      {label || <span className="none">(none)</span>}
                      {!readonly && (
                        <div className="remove" onClick={() => setCheckBox(category, value, false)}>
                          <MdCancel />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {expanded && !readonly && (
              <>
                <div className="filterBox">
                  <Form.Control
                    size="sm"
                    className="tinyFilterBar"
                    placeholder="Search"
                    value={filterTerm}
                    onChange={e => {
                      let { value } = e.currentTarget;
                      setSubFilterMap(map => ({ ...map, [category]: value }));
                    }}
                  />
                  <BPMButton
                    size="sm"
                    variant="outline-primary"
                    className="selectAll"
                    onClick={() => {
                      let checkList: Record<string, true> = {};
                      for (let option of options) {
                        let value: string;
                        let label = "";
                        if (typeof option === "string") {
                          value = option;
                        } else {
                          ({ value, label } = option);
                        }
                        if (
                          !filterTerm ||
                          label.toLowerCase().includes(filterTermLC) ||
                          value.toLowerCase().includes(filterTermLC)
                        ) {
                          checkList[value] = true;
                        }
                      }
                      setState(
                        R.mergeDeepLeft({
                          selectedMap: {
                            [category]: checkList,
                          },
                        })
                      );
                    }}
                  >
                    Select All
                  </BPMButton>
                  {selectedOptions.length ? (
                    <BPMButton
                      size="sm"
                      variant="outline-primary"
                      className="selectAll"
                      onClick={() => {
                        setState(state => ({
                          ...state,
                          selectedMap: { ...state.selectedMap, [category]: {} },
                        }));
                      }}
                    >
                      Clear
                    </BPMButton>
                  ) : (
                    ""
                  )}
                </div>
                <div className="categoryItems">
                  {options.map(option => {
                    let label: string;
                    let value: string;
                    if (typeof option === "string") {
                      label = option;
                      value = option;
                    } else {
                      ({ label, value } = option);
                    }
                    if (
                      filterTerm &&
                      !(
                        label.toLowerCase().includes(filterTermLC) ||
                        value.toLowerCase().includes(filterTermLC)
                      )
                    ) {
                      return null;
                    }
                    const checked = R.path([category, value], selectedMap) as boolean;
                    let classes = ["categoryItem"];
                    if (readonly) {
                      classes.push("disabled");
                    }
                    return (
                      <div
                        key={value}
                        className={classes.join(" ")}
                        onClick={() => !readonly && setCheckBox(category, value, !checked)}
                      >
                        <CheckBox checked={checked} disabled={readonly} />
                        <div className="itemLabel">
                          {label || <span className="none">(none)</span>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

interface FilterPaneAdvancedProps {
  categories: Category[];
  compileError: string;
  readonly?: boolean;
  setEditing: StateSetter<boolean>;
  setState: StateSetter<FilterPaneAdvancedState>;
  state: string;
}

const FilterPaneAdvanced: React.FC<FilterPaneAdvancedProps> = ({
  categories,
  compileError,
  readonly,
  setEditing,
  setState,
  state,
}) => {
  const advancedText = state;
  const setAdvancedTextRaw = useCallback((text: string) => setState(text), [setState]);

  const [cursorPosition, setCursorPosition] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateCursorPosition = useCallback(() => {
    const pos = textAreaRef.current?.selectionStart;
    if (!R.isNil(pos)) {
      setCursorPosition(pos);
    }
  }, []);

  const setAdvancedText = useCallback(
    (text: string) => {
      setAdvancedTextRaw(text);
      updateCursorPosition();
    },
    [updateCursorPosition, setAdvancedTextRaw]
  );

  const forceCursorPosition = useCallback((pos: number) => {
    if (textAreaRef.current) {
      textAreaRef.current.setSelectionRange(pos, pos);
      setCursorPosition(pos);
    }
  }, []);

  const suggestionGenerator = useMemo(() => makeSuggestionGenerator(categories), [categories]);

  const suggestionObject = useMemo(() => {
    const tokens = filterTokenizer(advancedText);
    const suggestionObject = suggestionGenerator(advancedText, tokens, cursorPosition);
    return suggestionObject;
  }, [advancedText, suggestionGenerator, cursorPosition]);

  const insertText = useCallback(
    (text: string) => {
      const [newText, newPos] = suggestionObject.inserter(text);
      setAdvancedText(newText);
      forceCursorPosition(newPos);
    },
    [forceCursorPosition, suggestionObject, setAdvancedText]
  );

  return (
    <div className="filterPaneBody">
      <Form.Control
        disabled={readonly}
        spellCheck={false}
        ref={textAreaRef}
        className="filterPaneAdvancedBox"
        as="textarea"
        rows={6}
        value={advancedText}
        onKeyUp={updateCursorPosition}
        onClick={updateCursorPosition}
        onChange={e => setAdvancedText(e.currentTarget.value)}
        onFocus={() => setEditing(true)}
        onBlur={() => setEditing(false)}
      />
      {compileError && <div className="compileError">{compileError}</div>}
      {!readonly && (
        <div className="suggestionList">
          {suggestionObject.suggestions.map(suggestion => (
            <div key={suggestion} className="suggestion" onClick={() => insertText(suggestion)}>
              {suggestion === "" ? '""' : suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const DEFAULT_FILTER_PANE_STATE = {
  isAdvanced: false,
  basic: {
    notMap: {},
    selectedMap: {},
  },
  advanced: "",
  customSegments: {},
};

export const useFilterPaneState = (
  init?: FilterPaneState
): [FilterPaneState, StateSetter<FilterPaneState>, () => void] => {
  const [state, setState] = useState<FilterPaneState>({ ...DEFAULT_FILTER_PANE_STATE, ...init });
  const resetState = useCallback(() => setState(DEFAULT_FILTER_PANE_STATE), []);
  return [state, setState, resetState];
};

export const hasFilter = (state: FilterPaneState): boolean => {
  if (state.isAdvanced) {
    return !!state.advanced;
  }
  for (let category of R.keys(state.basic.selectedMap)) {
    for (let option of R.keys(state.basic.selectedMap[category])) {
      if (state.basic.selectedMap[category][option]) {
        return true;
      }
    }
  }
  return false;
};

interface FilterPaneProps {
  categories: Category[];
  company?: string;
  filterID?: number;
  highlightWhenFiltered?: boolean;
  platform?: string;
  readonly?: boolean;
  save: (newFilterState: any) => void;
  setFilterID?: StateSetter<number | undefined>;
  setState: StateSetter<FilterPaneState>;
  state: FilterPaneState;
}

export const FilterPane: React.FC<FilterPaneProps> = ({
  categories,
  company,
  filterID,
  highlightWhenFiltered = true,
  platform,
  readonly,
  save,
  setFilterID = () => {},
  setState,
  state,
}) => {
  const [advanced, setAdvanced] = useState<FilterPaneAdvancedState>(state.advanced);
  const [basic, setBasic] = useState<FilterPaneBasicState>(state.basic);
  const [editPresetMode, setEditPresetMode] = useState<boolean>(false);
  const [expandPresets, setExpandPresets] = useState<boolean>(false);
  const [filterNameFilter, setFilterNameFilter] = useState<string>("");
  const [filterPresetsMap, setFilterPresetsMap] = useState<Record<string, FilterPreset>>();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(state.isAdvanced);
  const [isNewFilterPreset, setIsNewFilterPreset] = useState<boolean>(false);
  const [newFilterPresetName, setNewFilterPresetName] = useState<string | undefined>();
  const [selectedFilterPreset, setSelectedFilterPreset] = useState<FilterPreset>();
  const [editingAdvanced, setEditingAdvanced] = useState<boolean>(false);
  const [updatingPresets, setUpdatingPresets] = useState<boolean>(true);
  const setAreYouSure = useSetAreYouSure(true);
  const setError = useSetError();

  const loadState = useCallback(state => {
    setIsAdvanced(state.isAdvanced);
    setBasic(state.basic);
    setAdvanced(state.advanced);
  }, []);

  const reset = useCallback(() => {
    loadState(state);
  }, [loadState, state]);

  useEffect(() => {
    reset();
  }, [state, reset]);

  const weHaveFilter = useMemo(() => hasFilter(state), [state]);

  const clear = useCallback(() => {
    setBasic({
      notMap: {},
      selectedMap: {},
    });
    setAdvanced("");
  }, []);

  const [show, setShowRaw] = useState(false);

  const setShow = useCallback<StateSetter<boolean>>(
    show => {
      setShowRaw(show);
      reset();
    },
    [reset]
  );

  const [compileError, setCompileError] = useState("");

  useEffect(() => {
    setCompileError("");
  }, [advanced]);

  // Take existing state and get current tokens
  const apply = useCallback(() => {
    if (isAdvanced) {
      let tokens = filterTokenizer(advanced);
      try {
        tokensToWhereClause(tokens);
      } catch (e) {
        let error = e as Error;
        setCompileError(error.message);
        return;
      }
      setCompileError("");
    }

    let refreshedFilterState = { basic, advanced, isAdvanced };

    if (categories && !R.isEmpty(categories)) {
      // Transform the filter option into a map to better access data;
      const filterMap = {};
      for (let elem of categories) {
        const elemMap = {};
        for (let entry of elem.options) {
          if (entry) {
            // Some of these entries are strings are others are label, value pairs so check
            // before assigning
            if (entry[`${"label"}`]) {
              elemMap[entry[`${"label"}`]] = true;
            }
            if (entry[`${"value"}`]) {
              elemMap[entry[`${"value"}`]] = true;
            }
            if (R.isNil(entry[`${"label"}`]) && R.isNil(entry[`${"value"}`])) {
              elemMap[entry as string] = true;
            }
          }
        }
        filterMap[elem.key.toLowerCase()] = elemMap;
      }
      if (refreshedFilterState.basic) {
        for (let map of R.keys(refreshedFilterState.basic)) {
          for (let category of R.keys(refreshedFilterState.basic[map])) {
            for (let key of R.keys(refreshedFilterState.basic[map][category])) {
              if (!filterMap[category.toLowerCase()][key]) {
                // If we have renamed a creative, network, etc. we should delete from
                // our selected or not selected map to avoid build up and errors when
                // applying to queries
                delete refreshedFilterState.basic[map][category][key];
              }
            }
          }
        }
      }
      if (refreshedFilterState.advanced) {
        for (let map of R.keys(refreshedFilterState.advanced)) {
          for (let category of R.keys(refreshedFilterState.advanced[map])) {
            for (let key of R.keys(refreshedFilterState.advanced[map][category])) {
              if (!filterMap[category.toLowerCase()][key]) {
                // If we have renamed a creative, network, etc. we should delete from
                // our selected or not selected map to avoid build up and errors when
                // applying to queries
                delete refreshedFilterState.advanced[map][category][key];
              }
            }
          }
        }
      }
    }
    loadState({ advanced, basic, isAdvanced });
  }, [isAdvanced, basic, advanced, categories, loadState]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [setShow]);

  useEffect(() => {
    if (company && platform && R.isNil(filterPresetsMap)) {
      (async () => {
        try {
          const params = {
            company,
            platform,
          };
          let parsedFilterPresets: FilterPreset[] = [];
          const res = await FiltersLambdaFetch<GetFilterPresetsParams>("/getFilters", {
            params,
          });
          parsedFilterPresets = await awaitJSON(res);
          let filterPresets: FilterPreset[] = [];
          let filterMap: Record<string, FilterPreset> = {};
          if (parsedFilterPresets.length) {
            filterPresets = parsedFilterPresets;
            for (let filter of filterPresets) {
              filterMap[filter.id] = filter;
            }
          }
          setFilterPresetsMap(filterMap);
          if (filterID && filterMap[filterID]) {
            const filter = filterMap[filterID];
            setSelectedFilterPreset(filter);
            loadState(filter.filter_state);
            apply();
            setState(filterMap[filterID].filter_state);
          }
          setUpdatingPresets(false);
        } catch (e) {
          let error = e as Error;
          setError({
            reportError: error,
            message: `Failed to fetch filter presets. Error: ${error.message}`,
          });
        }
      })();
    }
  }, [
    apply,
    company,
    filterID,
    filterPresetsMap,
    loadState,
    platform,
    setError,
    setFilterPresetsMap,
    setState,
  ]);

  useEffect(() => {
    if (filterPresetsMap) {
      if (filterID) {
        if (!R.isEmpty(filterPresetsMap) && R.isNil(filterPresetsMap[R.defaultTo("", filterID)])) {
          clear();
          setSelectedFilterPreset(undefined);
        } else if (!R.isNil(filterPresetsMap[R.defaultTo("", filterID)])) {
          const filter: FilterPreset = filterPresetsMap[R.defaultTo("", filterID)];
          if (filter.filter_state) {
            loadState(filter.filter_state);
          } else {
            clear();
          }
          setSelectedFilterPreset(filter);
        }
      } else if (!isNewFilterPreset) {
        setSelectedFilterPreset(undefined);
        clear();
      }
    }
  }, [clear, filterID, filterPresetsMap, isNewFilterPreset, loadState]);

  useEffect(() => {
    if (!editPresetMode) {
      setNewFilterPresetName(undefined);
    }
  }, [editPresetMode, setNewFilterPresetName]);

  const addNewFilter = useCallback(
    (name?: string, state?: FilterPaneState) => {
      if (filterPresetsMap && !R.isNil(filterPresetsMap.new)) {
        const message =
          "There's already a new filter preset, please save or discard that preset to create another";
        setError({
          reportError: new Error(message),
          message,
        });
      }
      setExpandPresets(true);
      setIsNewFilterPreset(true);
      const newFilterPreset = {
        platform,
        company,
        filter_state: state ? state : { advanced, basic, isAdvanced },
        isAdvanced: isAdvanced,
        name: name ? name : "New Filter Preset",
      } as FilterPreset;
      setSelectedFilterPreset(newFilterPreset);
      setFilterID(undefined);
      setFilterPresetsMap(map => ({
        ...map,
        new: newFilterPreset,
      }));
      setEditPresetMode(true);
    },
    [advanced, basic, company, filterPresetsMap, isAdvanced, platform, setError, setFilterID]
  );

  const saveFilter = useCallback(
    (name?: string, state?: FilterPaneState) => {
      (async () => {
        if (selectedFilterPreset) {
          setUpdatingPresets(true);
          apply();
          let filterPresetToBeSaved: Partial<FilterPreset> = {
            platform,
            company,
            filter_state: state ? state : { advanced, basic, isAdvanced },
            isAdvanced: isAdvanced,
          };
          filterPresetToBeSaved.id = selectedFilterPreset.id;
          filterPresetToBeSaved.name = name ? name : selectedFilterPreset.name;
          if (
            R.isNil(filterPresetToBeSaved.name) ||
            filterPresetToBeSaved.name.trim().length < 1 ||
            filterPresetToBeSaved.name.trim().toLowerCase() === "none"
          ) {
            throw new Error("Must provide a valid filter name");
          }
          try {
            const body = {
              filter: filterPresetToBeSaved as FilterPreset,
            };
            const res = await FiltersLambdaFetch<SaveFilterPresetBody>("/saveFilter", {
              method: "POST",
              body,
            });
            const savedFilter: FilterPreset = await awaitJSON(res);
            setFilterPresetsMap(map => {
              if (map && map.new) {
                delete map.new;
              }
              return { ...map, [savedFilter.id]: savedFilter };
            });
            setIsNewFilterPreset(false);
            setFilterID(savedFilter.id);
            setUpdatingPresets(false);
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: `Failed to save filter preset. Error: ${error.message}`,
            });
            setUpdatingPresets(false);
          }
        }
      })();
    },
    [
      advanced,
      apply,
      basic,
      company,
      isAdvanced,
      platform,
      selectedFilterPreset,
      setError,
      setFilterID,
    ]
  );

  const deleteFilter = useCallback(
    (id: number) => {
      setAreYouSure({
        title: "Delete Filter Preset",
        message: "You are about to delete this filter preset, are you sure?",
        variant: "warning",
        cancelText: "Never mind",
        okayText: "Yes",
        onOkay: async () => {
          if (id) {
            setUpdatingPresets(true);
            const params: DeleteFilterPresetParams = {
              id,
            };
            try {
              await FiltersLambdaFetch<DeleteFilterPresetParams>("/deleteFilter", {
                method: "DELETE",
                params,
              });
              setFilterID(undefined);
              setFilterPresetsMap(map => {
                if (map) {
                  delete map[id];
                  return map;
                }
              });
              setUpdatingPresets(false);
            } catch (e) {
              let error = e as Error;
              setError({
                reportError: error,
                message: `Failed to save filter preset. Error: ${error.message}`,
              });
              setUpdatingPresets(false);
            }
          } else {
            setFilterPresetsMap(map => {
              if (map) {
                delete map.new;
                return map;
              }
            });
          }
          if (selectedFilterPreset && selectedFilterPreset.id === id) {
            setSelectedFilterPreset(undefined);
            setFilterID(undefined);
            setEditPresetMode(false);
            setIsNewFilterPreset(false);
            clear();
          }
        },
      });
    },
    [clear, selectedFilterPreset, setAreYouSure, setError, setFilterID]
  );

  useEffect(() => {
    if (!expandPresets && (filterID || (selectedFilterPreset && selectedFilterPreset.id))) {
      setExpandPresets(true);
    }
  }, [expandPresets, filterID, selectedFilterPreset]);

  const noFiltersSelected =
    advanced === "" &&
    !R.values(basic.notMap).includes(true) &&
    !R.flatten(R.map(elem => R.values(elem), R.values(basic.selectedMap))).includes(true);
  const wasFilterPaneEmpty =
    state.advanced === "" &&
    !R.values(state.basic.notMap).includes(true) &&
    !R.flatten(R.map(elem => R.values(elem), R.values(state.basic.selectedMap))).includes(true);

  const [applyButton, clearButton] = useMemo(() => {
    let applyText = "Apply Filters";
    let applyOnClick = () => {
      apply();
      setState({ advanced, basic, isAdvanced });
      save({ advanced, basic, isAdvanced });
      setShow(false);
    };
    let clearText = "Clear Filters";
    let clearOnClick = () => {
      clear();
    };

    if (selectedFilterPreset) {
      if (editPresetMode) {
        applyText = "Save & Apply";
        applyOnClick = () => {
          if (newFilterPresetName) {
            const newElem = { ...selectedFilterPreset, name: newFilterPresetName };
            setSelectedFilterPreset(newElem);
            setFilterPresetsMap(map => {
              if (map) {
                return {
                  ...map,
                  [newElem.id ? newElem.id : "new"]: newElem,
                };
              }
            });
          }
          saveFilter(newFilterPresetName);
          setEditPresetMode(false);
        };
        clearText = "Discard Changes";
        clearOnClick = () => {
          loadState(selectedFilterPreset.filter_state);
          setEditPresetMode(false);
        };
      } else {
        applyText = "Apply Preset";
        applyOnClick = () => {
          apply();
          if (selectedFilterPreset) {
            saveFilter();
          }
          setState({ advanced, basic, isAdvanced });
          save({ advanced, basic, isAdvanced });
          setShow(false);
        };
        clearText = "Edit Preset";
        clearOnClick = () => {
          setEditPresetMode(true);
        };
      }
    } else if (noFiltersSelected && !R.isNil(filterPresetsMap)) {
      clearText = "Close";
      clearOnClick = () => {
        if (!wasFilterPaneEmpty) {
          setState({ advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false });
        }
        setShow(false);
      };
    }

    return [
      <BPMButton variant="primary" onClick={applyOnClick} disabled={updatingPresets}>
        {noFiltersSelected && !R.isNil(filterPresetsMap) && R.isNil(filterPresetsMap.new) ? (
          <MdAddToPhotos />
        ) : undefined}
        {applyText}
      </BPMButton>,
      <BPMButton variant="outline-primary" onClick={clearOnClick} disabled={updatingPresets}>
        {clearText}
      </BPMButton>,
    ];
  }, [
    advanced,
    apply,
    basic,
    clear,
    editPresetMode,
    filterPresetsMap,
    isAdvanced,
    loadState,
    newFilterPresetName,
    noFiltersSelected,
    save,
    saveFilter,
    selectedFilterPreset,
    setShow,
    setState,
    updatingPresets,
    wasFilterPaneEmpty,
  ]);

  return (
    <>
      <BPMButton
        size="sm"
        variant={weHaveFilter && highlightWhenFiltered ? "primary" : "outline-primary"}
        className="BPMFilterPaneButton"
        icon={<MdFilterList />}
        onClick={() => setShow(R.not)}
      >
        Filters
      </BPMButton>
      {ReactDOM.createPortal(
        <div className="BPMFilterPane" style={{ width: show ? FILTER_PANE_WIDTH : "0" }}>
          <div className="filterPaneInner" style={{ minWidth: FILTER_PANE_WIDTH }}>
            <div className="filterPaneTop">
              Filters
              <div className="closeButton" onClick={() => setShow(false)}>
                <MdClose />
              </div>
            </div>
            {
              <div className="filterPresets">
                <div className={"filterPresetsTitleContainer"}>
                  <div
                    className="filterPresetsTitle"
                    onClick={() => {
                      setExpandPresets(!expandPresets);
                    }}
                  >
                    <div className="filterPresetsTitleText">Filter Presets</div>
                    <div
                      className="filterPresetsTitleExpander"
                      hidden={
                        !R.isNil(filterID) ||
                        (!R.isNil(selectedFilterPreset) && R.isNil(selectedFilterPreset.id))
                      }
                    >
                      {expandPresets ? <MdExpandLess /> : <MdExpandMore />}
                    </div>
                  </div>
                  <div
                    className={
                      R.isNil(filterPresetsMap) || !R.isNil(filterPresetsMap.new)
                        ? "filterPaneDisabled"
                        : undefined
                    }
                  >
                    <MdAddToPhotos
                      className="addNewPresetButton"
                      onClick={() => {
                        addNewFilter();
                      }}
                    />
                  </div>
                </div>

                {expandPresets && company && platform && (
                  <div className="filterPresetRowsContainer">
                    {R.isNil(filterPresetsMap) || updatingPresets ? (
                      <Spinner animation={"border"} />
                    ) : R.isEmpty(filterPresetsMap) ? (
                      <div>No saved presets!</div>
                    ) : (
                      <div>
                        <div className="filterNameFilterContainer">
                          <Form.Control
                            className="filterNameFilter"
                            onChange={e => {
                              setFilterNameFilter(e.target.value);
                            }}
                            placeholder="Search for Presets"
                            size="sm"
                            value={filterNameFilter}
                          />
                          <MdSearch className="filterBarSearchIcon" />
                        </div>
                        <div className="filterPresetRows">
                          {R.map(
                            (elem: FilterPreset) => {
                              return (
                                <FilterPresetRow
                                  key={uuid.v4()}
                                  autoFocus={!editingAdvanced}
                                  checked={
                                    !R.isNil(selectedFilterPreset) &&
                                    selectedFilterPreset.id === elem.id
                                  }
                                  checkBoxOnClick={() => {
                                    setEditPresetMode(false);
                                    setFilterID(
                                      selectedFilterPreset && selectedFilterPreset.id === elem.id
                                        ? undefined
                                        : elem.id
                                    );
                                    setSelectedFilterPreset(
                                      selectedFilterPreset && selectedFilterPreset.id === elem.id
                                        ? undefined
                                        : elem
                                    );
                                  }}
                                  copyOnClick={() => {
                                    addNewFilter(`${elem.name} Copy`, elem.filter_state);
                                  }}
                                  deleteOnClick={() => {
                                    deleteFilter(elem.id);
                                    if (
                                      selectedFilterPreset &&
                                      selectedFilterPreset.id === elem.id
                                    ) {
                                      setEditPresetMode(false);
                                    }
                                  }}
                                  editMode={editPresetMode}
                                  editOnClick={() => {
                                    setNewFilterPresetName(elem.name);
                                    if (
                                      selectedFilterPreset &&
                                      selectedFilterPreset.id === elem.id
                                    ) {
                                      setEditPresetMode(!editPresetMode);
                                    } else if (
                                      selectedFilterPreset &&
                                      selectedFilterPreset.id !== elem.id
                                    ) {
                                      setEditPresetMode(true);
                                    }
                                    if (
                                      R.isNil(selectedFilterPreset) ||
                                      selectedFilterPreset.id !== elem.id
                                    ) {
                                      setFilterID(elem.id);
                                      setSelectedFilterPreset(elem);
                                    }
                                  }}
                                  name={elem.name}
                                  newName={newFilterPresetName ? newFilterPresetName : elem.name}
                                  setNewName={setNewFilterPresetName}
                                  setName={newName => {
                                    const newElem = { ...elem, name: newName };
                                    setSelectedFilterPreset(newElem);
                                    setFilterPresetsMap(map => {
                                      if (map) {
                                        return {
                                          ...map,
                                          [newElem.id ? newElem.id : "new"]: newElem,
                                        };
                                      }
                                    });
                                    saveFilter(newName);
                                  }}
                                />
                              );
                            },
                            R.sort(
                              (a: FilterPreset, b: FilterPreset) => {
                                return a.name === "New Filter Preset" || a.name < b.name ? -1 : 1;
                              },
                              R.filter(
                                elem =>
                                  elem.name
                                    .toLowerCase()
                                    .includes(filterNameFilter.toLowerCase()) ||
                                  filterNameFilter.length === 0,
                                R.values(R.defaultTo({}, filterPresetsMap))
                              )
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            <div
              className={
                !editPresetMode && selectedFilterPreset
                  ? "filterPaneFilterSelection filterPaneDisabled"
                  : "filterPaneFilterSelection"
              }
            >
              <div className="filterPaneTypeSelect">
                <div className={isAdvanced ? "" : "selected"} onClick={() => setIsAdvanced(false)}>
                  Basic
                </div>
                <div className={isAdvanced ? "selected" : ""} onClick={() => setIsAdvanced(true)}>
                  Advanced
                </div>
              </div>
              {isAdvanced ? (
                <FilterPaneAdvanced
                  categories={categories}
                  state={advanced}
                  setState={setAdvanced}
                  compileError={compileError}
                  readonly={readonly}
                  setEditing={setEditingAdvanced as StateSetter<boolean>}
                />
              ) : (
                <FilterPaneBasic
                  categories={categories}
                  state={basic}
                  setState={setBasic}
                  readonly={readonly}
                />
              )}
            </div>
            {!readonly && (
              <div className="filterPaneControls">
                <div className="filterPaneControlButtons">
                  {clearButton}
                  {applyButton}
                </div>
                <div
                  className={
                    R.isNil(filterPresetsMap) || !R.isNil(filterPresetsMap.new)
                      ? "filterPaneDisabled"
                      : undefined
                  }
                >
                  <div
                    className="addNewFilterPresetButton"
                    onClick={() => {
                      addNewFilter();
                    }}
                  >
                    <MdAddToPhotos />
                    <div> Save selection as new preset</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>,
        document.body
      )}
    </>
  );
};
