import React, { useState, useCallback } from "react";

import { Modal, Form } from "react-bootstrap";

import { useSetError } from "../redux/modals";
import { awaitJSON, SlidesLambdaFetch } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";

import { BPMButton } from "../Components";

import "./FeedbackModal.scss";

interface FeedbackModalProps {
  epic: string;
  size?: "lg" | "sm";
}
const FeedbackModalButton = ({ epic, size }: FeedbackModalProps): JSX.Element => {
  const setError = useSetError();

  const { company } = useLocation();

  const [sendFeedback, setSendFeedback] = useState(false);

  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const submit = useCallback(async () => {
    setLoading(true);
    try {
      let res = await SlidesLambdaFetch("/feedback", {
        method: "POST",
        body: {
          summary,
          description: `${company}\n\n${description}`,
          epic,
        },
      });
      let { link } = await awaitJSON(res);
      setError({
        variant: "success",
        title: "Ticket Created",
        message: (
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {link}
          </a>
        ),
      });
      setLoading(false);
      setSendFeedback(false);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
      setLoading(false);
    }
  }, [summary, description, setError, company, epic]);

  return (
    <>
      <BPMButton
        className="bpmFeedbackModalButton"
        size={size}
        onClick={() => setSendFeedback(true)}
      >
        Send Feedback
      </BPMButton>
      {sendFeedback && (
        <Modal show onHide={() => setSendFeedback(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Send Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Summary</Form.Label>
              <Form.Control
                disabled={loading}
                value={summary}
                onChange={e => setSummary(e.currentTarget.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                disabled={loading}
                as="textarea"
                rows={5}
                value={description}
                onChange={e => setDescription(e.currentTarget.value)}
              />
            </Form.Group>
            <BPMButton disabled={loading} onClick={submit}>
              Send
            </BPMButton>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default FeedbackModalButton;
