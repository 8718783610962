import React, { useRef, useCallback, useMemo, useState, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import { MdSave } from "react-icons/md";
import { typedReactMemo } from "../utils/types";

import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { download, convertSVGStringToPNGURI } from "../utils/download-utils";
import {
  Page,
  FullPageSpinner,
  BPMTable,
  DateRangePicker,
  IconButton,
  DmaMap,
  MAP_ASPECT_RATIO,
  DMACode,
  GeoDataMap,
  GeoData,
  GeoDataObject,
  KpiPicker,
} from "../Components";

import { makeHeatMap } from "../utils/colors";

import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";

import "./StreamingGeoResponse.scss";

const IMPRESSIONS_CUTOFF_PERCENTAGE = 0.0005;
// Defines the range of captured values in terms of the standard deviation
const COLOR_RANGE_SENSITIVITY = 2;
const DISABLED_COLOR = "#F2F2F2";
const MID_COLOR = "#FFFFFF";
const HIGH_COLOR = "#00AA47";

const DOWNLOAD_WIDTH = 1000;
const DOWNLOAD_HEIGHT = DOWNLOAD_WIDTH / MAP_ASPECT_RATIO;

const DATE_FORMAT = "yyyy-MM-dd";

const DEFAULT_LAG = "7d";
type KPI = string;
type Lag = string;
export const LAG_INFO: Record<Lag, { index: number; name: string }> = {
  "1d": { index: 0, name: "1 Day" },
  "3d": { index: 1, name: "3 Days" },
  "7d": { index: 2, name: "7 Days" },
  "14d": { index: 3, name: "14 Days" },
  "30d": { index: 4, name: "30 Days" },
  all_business: { index: 5, name: "Overall Business" },
};

const TABLE_HEADERS = [
  { name: "name", label: "DMA Name", width: 170 },
  { name: "dma", label: "Code", width: 60 },
  { name: "index", label: "Index", width: 60 },
  { name: "impressions", label: "Impressions", flex: 1 },
  { name: "responses", label: "Responses", flex: 1 },
  { name: "households", label: "Households", flex: 1 },
];

interface DateRange {
  startDate: string;
  endDate: string;
}

export const useGeoData = (
  dateRange: DateRange,
  prefix: S.Prefix,
  kpi?: string,
  lag?: string
): {
  geoData?: GeoDataObject;
  renderColor?: (dma: DMACode) => string;
} => {
  const setError = useSetError();

  const dataKey = useMemo(
    () => `${dateRange.startDate}_${dateRange.endDate}_${kpi}_${lag}_${prefix}`,
    [kpi, lag, dateRange, prefix]
  );

  const [geoDataMap, setGeoDataMap] = useState<Record<string, GeoDataObject>>({});
  const geoData = useMemo(() => geoDataMap[dataKey], [geoDataMap, dataKey]);

  useEffect(() => {
    if (R.isNil(geoData) && kpi && lag) {
      setGeoDataMap(map => ({
        ...map,
        [dataKey]: false,
      }));
      (async () => {
        try {
          let geoData = await ToolsLambdaFetch("/geo", {
            params: { kpi, lag, prefix, ...dateRange },
          });
          let res = await awaitJSON(geoData);
          setGeoDataMap(map => ({ ...map, [dataKey]: res }));
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get geo data: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [geoData, kpi, lag, dataKey, dateRange, setError, prefix]);

  const heatMapData = useMemo(() => {
    if (geoData) {
      const totalImpressions = R.pipe<GeoDataMap, GeoData[], number>(
        R.values,
        R.reduce((impressions, dmaData) => impressions + dmaData.impressions, 0)
      )(geoData);

      const impressionsCutoff = totalImpressions * IMPRESSIONS_CUTOFF_PERCENTAGE;

      const geoIndices: number[] = [];
      for (let dmaData of R.values(geoData)) {
        if (dmaData.impressions > impressionsCutoff) {
          geoIndices.push(dmaData.index);
        }
      }

      const mean = R.reduce(R.add, 0, geoIndices) / geoIndices.length;

      const std =
        (R.reduce((acc, geoIndex) => acc + (geoIndex - mean) ** 2, 0, geoIndices) /
          geoIndices.length) **
        (1 / 2);

      return {
        min: mean - COLOR_RANGE_SENSITIVITY * std,
        max: mean + COLOR_RANGE_SENSITIVITY * std,
        impressionsCutoff,
      };
    }
  }, [geoData]);

  const renderColor = useMemo(() => {
    if (geoData) {
      return (dma: DMACode) => {
        if (heatMapData && geoData && geoData[dma]) {
          const { index, impressions } = geoData[dma];
          const { min, max, impressionsCutoff } = heatMapData;

          if (impressions < impressionsCutoff) {
            return DISABLED_COLOR;
          }

          let heatMap = makeHeatMap({
            min,
            max,
            midColor: MID_COLOR,
            highColor: HIGH_COLOR,
          });
          return heatMap(index);
        }
        return "transparent";
      };
    }
  }, [heatMapData, geoData]);

  return {
    geoData,
    renderColor,
  };
};

interface StreamingGeoResponseProps {
  prefix: S.Prefix;
}

const StreamingGeoResponse = typedReactMemo<React.FC<StreamingGeoResponseProps>>(({ prefix }) => {
  const setError = useSetError();

  const [kpi, setKpi] = useState<KPI>();

  const [lag, setLag] = useState<string>();

  const [selection, setSelection] = useState<string>();
  const [dateRange, setDateRange] = useState(() => ({
    startDate: R.pipe(Dfns.subMonths(1), Dfns.format(DATE_FORMAT))(new Date()),
    endDate: Dfns.format(DATE_FORMAT, new Date()),
  }));

  const [validLagMap, setValidLagMap] = useState<Record<KPI, Lag[] | undefined>>({});
  const validLags = useMemo(() => validLagMap[kpi || ""], [validLagMap, kpi]);
  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  const { geoData, renderColor } = useGeoData(dateRange, prefix, kpi, lag);

  useEffect(() => {
    if (kpi && !validLags) {
      (async () => {
        try {
          let validLagRes = await ToolsLambdaFetch("/geo_lags", {
            params: { kpi },
          });
          let validLags: Lag[] = await awaitJSON(validLagRes);
          validLags = R.sortBy(lag => LAG_INFO[lag].index, validLags);
          setValidLagMap(map => ({ ...map, [kpi]: validLags }));
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get lag data: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [kpi, validLags, setError]);

  useEffect(() => {
    if (validLags) {
      setLag(currentLag => {
        if (R.isNil(currentLag)) {
          return validLags.includes(DEFAULT_LAG) ? DEFAULT_LAG : validLags[0];
        } else if (!validLags.includes(currentLag)) {
          return validLags[0];
        } else {
          return currentLag;
        }
      });
    }
  }, [validLags]);

  const tableData = useMemo(
    () =>
      R.pipe<GeoDataMap, GeoData[], GeoData[]>(
        R.values,
        R.sortWith([R.descend(R.prop("index"))])
      )(geoData || {}),
    [geoData]
  );

  const downloadPNG = useCallback(() => {
    if (mapSvgRef.current) {
      let uri = convertSVGStringToPNGURI(
        mapSvgRef.current?.outerHTML,
        DOWNLOAD_WIDTH,
        DOWNLOAD_HEIGHT
      );
      download(uri, `${kpi}_geo_heatmap`);
    }
  }, [kpi]);

  const handleMouseEnter = useCallback(dma => () => setSelection(dma), []);
  const handleMouseLeave = useCallback(() => setSelection(undefined), []);

  const cellRenderer = useCallback(
    ({ data, rowData: { dma }, style, classes }) => (
      <span
        style={style}
        className={classes.join(" ")}
        onMouseEnter={handleMouseEnter(dma)}
        onMouseLeave={handleMouseLeave}
      >
        {data.toLocaleString("en-us")}
      </span>
    ),
    [handleMouseLeave, handleMouseEnter]
  );

  const isDateOutsideRange = useCallback(date => Dfns.format(DATE_FORMAT, new Date()) < date, []);

  const renderTooltip = useCallback(
    (dma: DMACode): React.ReactNode => {
      if (!(geoData && geoData[dma])) {
        return null;
      }
      const { impressions, responses, households, index, name } = geoData[dma];

      return R.map(
        ([label, value]) => (
          <div key={label}>
            <span className="tooltipLabel">
              <b>{label}:</b>
            </span>
            {` ${value}`}
          </div>
        ),
        [
          ["DMA", name],
          ["Response Index", index.toLocaleString("en-us")],
          ["Households", households.toLocaleString("en-us")],
          ["Impressions", impressions.toLocaleString("en-us")],
          ["Responses", responses.toLocaleString("en-us")],
        ]
      );
    },
    [geoData]
  );

  const ourSetLag = useCallback((option: string | null) => {
    if (!R.isNil(option)) {
      setLag(option);
    }
  }, []);

  return (
    <Page
      title={`${prefix === "audio" ? "Audio" : "Streaming"} Geo Response`}
      pageType={`${prefix === "audio" ? "Audio" : "Streaming"} Geo Response`}
      actions={
        <div className="geoControls">
          <div className="kpiSelector">
            <KpiPicker mediaType="streaming" kpi={kpi} onChange={setKpi} />
          </div>
          {validLags && lag && (
            <DropdownButton
              id="geoLagPicker"
              className="lagSelector"
              onSelect={ourSetLag}
              title={`Lag: ${LAG_INFO[lag].name}`}
              size="sm"
            >
              {R.map(
                lag => (
                  <Dropdown.Item key={lag} eventKey={lag}>
                    {LAG_INFO[lag].name}
                  </Dropdown.Item>
                ),
                validLags
              )}
            </DropdownButton>
          )}
          <DateRangePicker
            startDate={dateRange.startDate}
            startDateId="StreamingGeoStartDate"
            endDate={dateRange.endDate}
            endDateId="StreamingGeoEndDate"
            isOutsideRange={isDateOutsideRange}
            onChange={setDateRange}
          />
          <IconButton
            className="downloadPngButton"
            variant="outline-primary"
            icon={<MdSave />}
            onClick={downloadPNG}
            size="sm"
          >
            PNG
          </IconButton>
        </div>
      }
    >
      <div className="bpmStreamingGeo">
        {R.isNil(validLags) || !geoData ? (
          <FullPageSpinner />
        ) : (
          <>
            <div className="geoTable">
              <BPMTable
                csvDownload={`${kpi}_geo_response_index`}
                data={tableData}
                headers={TABLE_HEADERS}
                cellRenderer={cellRenderer}
                cellClassName="geoEntry"
              />
            </div>
            <div className="geoMap">
              <AutoSizer>
                {({ width, height }) => (
                  <DmaMap
                    width={width}
                    height={height}
                    selection={selection || ""}
                    renderColor={renderColor}
                    renderTooltip={renderTooltip}
                    ref={mapSvgRef}
                  />
                )}
              </AutoSizer>
            </div>
          </>
        )}
      </div>
    </Page>
  );
});

export default StreamingGeoResponse;
