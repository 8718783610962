import React, { useMemo } from "react";

import * as R from "ramda";

import { Setter } from "../../utils/types";
import { CheckBox } from "../../Components";
import { OverviewConfigItem } from "../performanceUtils";

interface OverviewToggleProps {
  checked: boolean | undefined;
  onCheck: Setter<boolean>;
  overviewConfigInfo: OverviewConfigItem;
}

const OverviewToggle: React.FC<OverviewToggleProps> = ({
  checked,
  onCheck,
  overviewConfigInfo,
}) => {
  const actuallyChecked = useMemo(() => {
    return checked || (!overviewConfigInfo.defaultOff && R.isNil(checked));
  }, [checked, overviewConfigInfo]);
  return (
    <div className="overviewToggle">
      <div className="label">{overviewConfigInfo.displayName}</div>
      <CheckBox checked={actuallyChecked} onCheck={onCheck} />
    </div>
  );
};

export default OverviewToggle;
