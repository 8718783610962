import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";

import { Card, BPMDateRange } from "../../Components";
import { InputGroup, Form, ListGroup, Button, Spinner } from "react-bootstrap";
import {
  awaitJSON,
  SheetsLambdaFetch,
  StreamingV2LambdaFetch,
  CreativeLambdaFetch,
  StreamingUtilsLambdaFetch,
} from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

import { DateRange } from "../../utils/types";
import { DerivedNetwork } from "../../redux/networks";
import { useMap } from "../../utils/hooks/useData";
import { useSelector } from "react-redux";
import { emailSelector } from "../../redux/user";

const TransferSpendAndImpressions: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const email = useSelector(emailSelector);

  const [dates, setDates] = useState<DateRange>();
  const [dateList, setDateList] = useState<string[]>([]);

  const [notes, setNotes] = useState("");

  const [company, setCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState<string[]>();

  useEffect(() => {
    if (!companyOptions) {
      (async () => {
        let res = await SheetsLambdaFetch("/get_company_ids");
        let companies = await awaitJSON<{ ids: string[] }>(res);
        setCompanyOptions(companies.ids);
      })();
    }
  });

  const companyOptionList = useMemo(
    () => (companyOptions || []).map(value => ({ value, label: value })),
    [companyOptions]
  );

  const [network, setNetwork] = useState("");
  const [networkTo, setNetworkTo] = useState("");
  const [derivedNetworkMap, setDerivedNetworkMap] = useState({});

  useEffect(() => {
    (async () => {
      let res = await StreamingV2LambdaFetch("/derived", { params: { company } });
      let derivedNetworks = await awaitJSON<Record<string, DerivedNetwork>>(res);
      setDerivedNetworkMap(derivedNetworks);
    })();
  }, [company]);

  const derivedNetworkOptionList = useMemo(
    () => Object.keys(derivedNetworkMap || {}).map(value => ({ value, label: value })),
    [derivedNetworkMap]
  );

  const [isciOptions, setIsciOptions] = useState({});

  useEffect(() => {
    if (company) {
      (async () => {
        let res = await CreativeLambdaFetch("/", {
          params: {
            company,
            timeline: 1,
            include_retired: true,
          },
        });
        const isciOptions = await awaitJSON(res);
        setIsciOptions(isciOptions);
      })();
    }
  }, [company]);

  const isciOptionList = useMemo(
    () => Object.keys(isciOptions || {}).map(value => ({ value, label: value })),
    [isciOptions]
  );

  interface DerivedIdInfo {
    newCount?: number;
    newCost?: number;
  }

  const [creative, setCreative] = useState("");
  const [derivedIdMap, setDerivedIdMapValue, setDerivedIdMap] = useMap<string, DerivedIdInfo>({});
  const [derivedIdChange, setDerivedIdChange] = useState<boolean>(false);

  const transferSpendAndImpressions = useCallback(async () => {
    try {
      setActiveAPICall(true);
      if (derivedIdChange) {
        let params = JSON.stringify({
          dateList,
          network,
          networkTo,
          notes,
          email,
          derivedIdChange,
        });
        await StreamingUtilsLambdaFetch("/transferSpendAndImpressions", {
          method: "POST",
          body: params,
        });
      } else {
        let params = JSON.stringify({
          dateList,
          company,
          network,
          creative,
          derivedIdMap,
          notes,
          email,
          derivedIdChange,
        });
        await StreamingUtilsLambdaFetch("/transferSpendAndImpressions", {
          method: "POST",
          body: params,
        });
      }

      setCompany("");
      setNotes("");
      setDates(undefined);
      setDateList([]);
      setNetwork("");
      setNetworkTo("");
      setCreative("");
      setDerivedIdMap({});
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [
    derivedIdChange,
    setDerivedIdMap,
    dateList,
    network,
    networkTo,
    notes,
    email,
    company,
    creative,
    derivedIdMap,
    setError,
  ]);
  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Transfer Spend and Impressions</div>
      <BPMDateRange
        range={dates}
        onChange={option => {
          setDates(option);
          let datesList: string[] = [];
          let tempDate = new Date(option.start);
          tempDate.setDate(tempDate.getDate() + 1);
          tempDate.setHours(0, 0, 0, 0);
          let endDate = new Date(option.end);
          endDate.setDate(endDate.getDate() + 1);
          endDate.setHours(0, 0, 0, 0);
          while (tempDate <= endDate) {
            let dateToPush = new Date(tempDate);
            datesList.push(dateToPush.toISOString().slice(0, 10));
            tempDate.setDate(tempDate.getDate() + 1);
          }
          setDateList(datesList);
        }}
      />
      <Select
        className="select adOpsElem"
        placeholder="Select from existing companies"
        isSearchable
        isClearable
        options={companyOptionList}
        onChange={option => {
          setCompany(option?.value);
        }}
      />
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Notes</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={notes} onChange={e => setNotes(e.target.value)} />
      </InputGroup>
      <div className="adOpsElem">
        <Form.Check
          className="adOpsFormCheck"
          inline
          label="derivedIdChange"
          type="checkbox"
          onChange={() => {
            setDerivedIdChange(!derivedIdChange);
            setDerivedIdMap({});
            setNetwork("");
            setNetworkTo("");
          }}
        ></Form.Check>
      </div>
      {company && derivedIdChange && (
        <Select
          className="select adOpsElem"
          placeholder="Select old Derived ID"
          isSearchable
          isClearable
          options={derivedNetworkOptionList}
          onChange={option => {
            setNetwork(option?.value);
          }}
        />
      )}
      {company && derivedIdChange && (
        <Select
          className="select adOpsElem"
          placeholder="Select new Derived ID"
          isSearchable
          isClearable
          options={derivedNetworkOptionList}
          onChange={option => {
            setNetworkTo(option?.value);
          }}
        />
      )}
      {company && !derivedIdChange && (
        <div>
          <Select
            className="select adOpsElem"
            placeholder="Select Derived ID"
            isSearchable
            isClearable
            options={derivedNetworkOptionList}
            onChange={option => {
              setNetwork(option?.value);
            }}
          />
          <Select
            className="select adOpsElem"
            placeholder="Select Isci"
            isClearable
            isSearchable
            options={isciOptionList}
            onChange={option => {
              setCreative(option?.value);
            }}
          />
        </div>
      )}
      {!!dateList.length &&
        !derivedIdChange &&
        network &&
        dateList.map(date => (
          <ListGroup.Item title={date} key={date} className="creativeRow">
            <div>{date}</div>
            <InputGroup size="sm" className="adOpsElem">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputLabel">newCount</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={derivedIdMap[date]?.newCount || ""}
                onChange={event => {
                  setDerivedIdMapValue(date, {
                    ...derivedIdMap[date],
                    newCount: parseInt(event.target.value),
                  });
                }}
              />
            </InputGroup>
            <InputGroup size="sm" className="adOpsElem">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputLabel">newCost</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                value={derivedIdMap[date]?.newCost || ""}
                onChange={event => {
                  setDerivedIdMapValue(date, {
                    ...derivedIdMap[date],
                    newCost: parseFloat(event.target.value),
                  });
                }}
              />
            </InputGroup>
          </ListGroup.Item>
        ))}
      <Button className="adOpsElem" onClick={transferSpendAndImpressions}>
        {activeAPICall ? <Spinner animation={"border"} /> : "Submit"}
      </Button>
    </Card>
  );
};

export default TransferSpendAndImpressions;
