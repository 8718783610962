/* eslint-disable */
import React, { useState } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Modal, ListGroup } from "react-bootstrap";

import { Img, DateBox } from "../../Components";

import "./DeviceBreakoutModal.scss";
import { formatMoney, formatMoneyAsInt, formatNumber } from "../../utils/format-utils";

const CANCELED_TIMESTAMP_FORMAT = "MM/dd/yyy @ h:mm a";
const makeCanceledTimestamp = R.pipe(Dfns.parseISO, Dfns.format(CANCELED_TIMESTAMP_FORMAT));
const makeCPM = (spend, impressions) =>
  impressions ? Math.round((spend / impressions) * 10000) / 10 : 0;

const DeviceBreakoutModal = ({ data, flightMap, byNetwork, onClose }) => {
  // TODO: A modal for the Network view.
  const { flights, network, placement } = data;
  const [expandedFlightMap, setExpandedFlightMap] = useState({});
  return (
    <Modal show onHide={onClose} dialogClassName="activeOTTDetailModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <Img
            src={`https://cdn.blisspointmedia.com/${byNetwork && `networks/${network}`}.png`}
            unloader={network}
          />
          <div className="headerName">{byNetwork && placement}</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="dataSection">
          <div className="dataLabel">Flights</div>
          <div className="valRow">
            <ListGroup className="flightList">
              {flights.map(flight => {
                let expanded = expandedFlightMap[flight];
                return (
                  <ListGroup.Item
                    key={flight}
                    action
                    onClick={() =>
                      setExpandedFlightMap(map => ({
                        ...map,
                        [flight]: !expanded,
                      }))
                    }
                  >
                    <div className="mainFlightRow">
                      <div>
                        <div className="flightIDs">
                          Flight #{flight}
                          <small>(Order #{flightMap[flight].orderID})</small>
                        </div>
                        <DateBox
                          start={flightMap[flight].startDate}
                          end={flightMap[flight].endDate}
                        />
                      </div>
                      <div>
                        <div className="labeled">
                          <span>Spend:</span>
                          {formatMoneyAsInt(flightMap[flight].spend)}
                        </div>
                        <div className="labeled">
                          <span>CPM:</span>
                          {flightMap[flight].cpm ? formatMoney(flightMap[flight].cpm) : "$0"}
                        </div>
                        <div className="labeled">
                          <span>Impressions:</span>
                          {formatNumber(flightMap[flight].impressions)}
                        </div>
                      </div>
                    </div>
                    {expanded && (
                      <div className="expandedSection">
                        {byNetwork && (
                          <>
                            <div className="labeled">
                              <span>Platform:</span>
                              {flightMap[flight].platform || "No Description"}
                            </div>
                            <div className="labeled">
                              <span>Description:</span>
                              {flightMap[flight].description || "No Description"}
                            </div>
                          </>
                        )}
                        {R.path(["properties", "length"], flight) ? (
                          <div className="labeled">
                            <span>Properties:</span>
                            {flightMap[flight].properties.join(", ")}
                          </div>
                        ) : null}
                        {flightMap[flight].canceled && (
                          <div className="labeled">
                            <span>Canceled:</span>
                            {makeCanceledTimestamp(flightMap[flight].canceled)}
                          </div>
                        )}
                        {flightMap[flight].overrides && (
                          <div className="expandedRow">
                            <div className="labeled">
                              <span>
                                Overrides <small>(spend/cpm/imps)</small>:
                              </span>
                              {formatMoneyAsInt(flightMap[flight].overrides.spend)} /{" "}
                              {formatMoney(
                                makeCPM(
                                  flightMap[flight].overrides.spend,
                                  flightMap[flight].overrides.impressions
                                )
                              )}{" "}
                              / {formatNumber(flightMap[flight].overrides.impressions)}
                            </div>
                          </div>
                        )}
                        {flightMap[flight].derivedNetwork && (
                          <div className="labeled">
                            <span>Derived Network:</span>
                            {flightMap[flight].derivedNetwork}
                          </div>
                        )}
                        <div className="labeled">
                          <span>PDF Text</span>
                        </div>
                        <div className="pdfText">
                          {flightMap[flight].pdfText
                            ? flightMap[flight].pdfText
                                .split("\n")
                                .map((text, i) => <p key={`${text}_${i}`}>{text || "\u00a0"}</p>)
                            : "None"}
                        </div>
                      </div>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeviceBreakoutModal;
