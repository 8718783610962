import React, { useState, useEffect, useMemo } from "react";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import { Form } from "react-bootstrap";

export const ColorPicker = ({ color: colorProp = "#ffffff", onChange, ...passthrough }) => {
  const getIsMounted = useIsMounted();
  const [, setPrevColorProp] = useState(colorProp);
  const [color, setColorRaw] = useState(colorProp);
  useEffect(() => {
    if (colorProp !== color) {
      setPrevColorProp(prevColor => {
        if (prevColor !== colorProp) {
          setColorRaw(colorProp);
          return colorProp;
        }
        return prevColor;
      });
    }
  }, [colorProp, color]);
  const setColor = useMemo(() => {
    let currentColor;
    return color => {
      setColorRaw(color);
      currentColor = color;
      setTimeout(() => {
        if (currentColor === color && getIsMounted()) {
          setPrevColorProp(color);
          onChange(color);
        }
      }, 300);
    };
  }, [onChange, getIsMounted]);
  return (
    <Form.Control
      type="color"
      value={color}
      onChange={e => setColor(e.target.value)}
      {...passthrough}
    />
  );
};
