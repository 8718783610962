import { Neutral200 } from "../utils/colors";
export const STREAMING_STROKE_COLOR = "#6F30EF";
export const AUDIO_STROKE_COLOR = "#47E6BB";
export const LINEAR_STROKE_COLOR = "#06A750";
export const DISPLAY_STROKE_COLOR = "#D5950D";

export const STREAMING_BAR_COLOR = "#D3B7FB";
export const AUDIO_BAR_COLOR = "#B8F5E4";
export const LINEAR_BAR_COLOR = "#B0FDCA";
export const DISPLAY_BAR_COLOR = "#FBE8A9";

const AUDIO_TITLE_COLOR = "#1EAD93";

export const BUDGET_SNAPSHOT_GREY = "#E6E6E6";
export const GRAY_TEXT_COLOR = "#ADADAD";
export const INACTIVE_COLOR = Neutral200;

export const DAY_DATE_LABEL = "M/dd/yy";
export const WEEK_DATE_LABEL = "MM/dd";
export const MONTH_DATE_LABEL = "MMM yyyy";
export const QUARTER_DATE_LABEL = "QQQ yyyy";
export const YEAR_DATE_LABEL = "yyyy";

export const DAY_DATE_TOOLTIP_LABEL = "MMMM do, yyyy";
export const MONTH_DATE_TOOLTIP_LABEL = "MMMM yyyy";

export const X_AXIS_PADDING = { left: 15, right: 15 };
export const CHART_MARGIN_WITH_LEGEND = { top: 10, right: 10 };
export const CARTESIAN_GRID_STROKE = "#E6EAEE";
export const CARTESIAN_GRID_STROKE_WIDTH = 1;
export const LINE_STROKE_WIDTH = 2;
export const TICK_STYLE = { fontSize: 12 };

export const HOME_PAGE_MEDIA_TYPES = ["audio", "display", "streaming", "tv"];
export const DATE_FORMAT = "yyyy-MM-dd";
export type DateGroupingKey = "Day" | "Week" | "Month" | "Quarter" | "Year";

export interface DateGroupingOption {
  value: DateGroupingKey;
  label: string;
}
export const DATE_GROUPING_OPTIONS: DateGroupingOption[] = [
  { value: "Day", label: "Daily" },
  { value: "Week", label: "Weekly" },
  { value: "Month", label: "Monthly" },
  { value: "Quarter", label: "Quarterly" },
  { value: "Year", label: "Yearly" },
];

export const MEDIA_TYPE_TO_PRETTY_NAME = {
  audio: "Audio",
  display: "Display",
  streaming: "Streaming",
  tv: "Linear TV",
} as const;

export const MEDIA_TYPE_TO_STROKE_COLOR = {
  streaming: STREAMING_STROKE_COLOR,
  audio: AUDIO_STROKE_COLOR,
  tv: LINEAR_STROKE_COLOR,
  display: DISPLAY_STROKE_COLOR,
};

export const MEDIA_TYPE_TO_BAR_COLOR = {
  streaming: STREAMING_BAR_COLOR,
  audio: AUDIO_BAR_COLOR,
  tv: LINEAR_BAR_COLOR,
  display: DISPLAY_BAR_COLOR,
};

export const MEDIA_TYPE_TO_TITLE_COLOR = {
  streaming: STREAMING_STROKE_COLOR,
  audio: AUDIO_TITLE_COLOR,
  tv: LINEAR_STROKE_COLOR,
  display: DISPLAY_STROKE_COLOR,
};

export const METRIC_TO_PRETTY_NAME = {
  imps: "Impressions",
  spend: "Spend",
  volume: "Volume",
  roas: "ROAS",
  cpx: "CPX",
  cpm: "CPM",
  clicks: "Clicks",
  orders: "Orders",
  revenue: "Revenue",
  impressions: "Impressions",
} as const;
