import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import "./CollapseButton.scss";

type CollapseButtonProps = {
  onClick: () => void;
};

export const CollapseButton: React.FC<CollapseButtonProps> = ({ onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    onClick();
  };

  return (
    <button
      onClick={handleToggle}
      className={`expandCollapseButton ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <MdArrowForwardIos className="icon" />
    </button>
  );
};

export default CollapseButton;
