import React from "react";

import { Modal } from "react-bootstrap";
import { BPMButton } from "../../Components";

interface NoRateChangesModalProps {
  onClose: () => void;
}

const NoRateChangesModal: React.FC<NoRateChangesModalProps> = ({ onClose }) => {
  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>No rates needed refreshing!</Modal.Title>
      </Modal.Header>
      <Modal.Body>All rates are up-to-date.</Modal.Body>
      <Modal.Footer>
        <BPMButton variant="primary" onClick={onClose}>
          Okay
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default NoRateChangesModal;
