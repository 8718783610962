import React, { useMemo } from "react";

import { MdOutlineFileDownload } from "react-icons/md";

import { Dropdown as BSDropdown } from "react-bootstrap";

import "./DownloadToggle.scss";
import { DownloadToggleType } from "./DownloadDropdown";

interface DownloadToggleProps {
  type?: DownloadToggleType;
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  className?: string;
  label?: string;
  leadingIcon?: React.ReactElement;
  size?: "sm" | "lg";
  disabled?: boolean;
}

export const DownloadToggle: React.FC<DownloadToggleProps> = React.memo(
  ({
    type = DownloadToggleType.FILLED,
    design = "primary",
    background = "light",
    className,
    size = "lg",
    disabled,
  }: DownloadToggleProps): JSX.Element => {
    const resolvedClassName = useMemo(() => {
      let classes = ["cl-download-toggle", type, design, background, size, className];
      if (disabled) {
        classes.push("disabled");
      }

      return classes.join(" ");
    }, [type, design, background, size, className, disabled]);

    return (
      <BSDropdown.Toggle className={resolvedClassName} disabled={disabled}>
        <div className="download-icon">
          <MdOutlineFileDownload />
        </div>
      </BSDropdown.Toggle>
    );
  }
);
