import "../IncrementalityPlaybook.scss";
import { CommonShoppingSections } from "./Shopping";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";

const PAGE_TITLE = "Google Search";

const GoogleSearch = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Search ads is the concept of getting your website or ad campaigns listed at the top of
              the search results for any questions, keywords or phrases.
            </div>
            <br />
            <div className="basicText">
              Of note, methods outlined below are the same across google platforms for Search,
              Shopping, Display (DV360) & Discovery.
            </div>
            <br />
            <Img src={`${INC_CDN}/Google_SponsoredSearch.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <CommonShoppingSections
        pageTitle={PAGE_TITLE}
        sections={[
          "Incrementality Testing Approaches",
          "Media Options & Considerations",
          "Sample Output",
          "Signal Enrichment",
          "Google Power Calculation",
        ]}
      />
    </div>
  );
});

export default GoogleSearch;
