import { useMemo, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";
import { ReactComponent as Save } from "../icons/Save.svg";
import { primary, secondary } from "../../utils/colors";
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line, Tooltip, Legend } from "recharts";
import { AxesSkeleton, Skeleton, PathSkeleton } from "../../Components";
import { download } from "../../utils/download-utils";
import { formatNumberAsInt } from "../../utils/format-utils";
import { abbreviateNumber } from "../../utils/data";
import "./LiftFromTvChart.scss";

const LINE_STROKE_PRIMARY = primary;
const LINE_STROKE_SECONDARY = secondary;
const LINE_STROKES = [LINE_STROKE_PRIMARY, LINE_STROKE_SECONDARY];
const LINE_STROKE_WIDTH = 2;
const TICK_DATE_FORMAT = "MMM d";

interface LiftFromTvData {
  liftFromTv: any[];
  liftModelGradientRanges: any[];
}

interface LiftFromTvChartProps {
  data: LiftFromTvData | undefined;
}

export const formatDateLabel = (date: string | undefined): string => {
  if (!date) {
    return "";
  }
  return R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))(date);
};

export const LiftFromTvChart: React.FC<LiftFromTvChartProps> = ({ data }) => {
  const { liftFromTv, liftModelGradientRanges } = data || {};

  const downloadData = useCallback(() => {
    if (liftFromTv) {
      let csv = Papa.unparse(liftFromTv || []);
      download(csv, "LiftFromTV.csv", "text/csv");
    }
  }, [liftFromTv]);

  const keys = useMemo(() => {
    if (!liftFromTv) {
      return [];
    }
    let liftFromTvKeys: string[] = [];

    for (let key of R.keys((liftFromTv || {})[0])) {
      if (key !== "date") {
        liftFromTvKeys.push(key);
      }
    }

    return liftFromTvKeys;
  }, [liftFromTv]);

  return (
    <div className="cppChart section overviewChart">
      <div className="sectionHeader cppHeader">
        <div>Lift From TV</div>
        <div className="saveButton" onClick={downloadData}>
          <Save />
        </div>
      </div>
      <div className="chart">
        {data ? (
          (liftFromTv || []).length ? (
            <ResponsiveContainer>
              <LineChart data={liftFromTv}>
                <defs>
                  <linearGradient id="liftChartStrokeGradient1" x1="0%" x2="100%" y1="0%" y2="0%">
                    {(liftModelGradientRanges || []).map(range => {
                      const { start, end, live } = range;
                      let opacity = live ? 1 : 0.3;
                      return [
                        <stop
                          key={`${start}_${end}_start`}
                          offset={`${start}%`}
                          stopColor={LINE_STROKE_PRIMARY}
                          stopOpacity={opacity}
                        />,
                        <stop
                          key={`${start}_${end}_end`}
                          offset={`${end}%`}
                          stopColor={LINE_STROKE_PRIMARY}
                          stopOpacity={opacity}
                        />,
                      ];
                    })}
                  </linearGradient>
                  <linearGradient id="liftChartStrokeGradient2" x1="0%" x2="100%" y1="0%" y2="0%">
                    {(liftModelGradientRanges || []).map(range => {
                      const { start, end, live } = range;
                      let opacity = live ? 1 : 0.3;
                      return [
                        <stop
                          key={`${start}_${end}_start`}
                          offset={`${start}%`}
                          stopColor={LINE_STROKE_SECONDARY}
                          stopOpacity={opacity}
                        />,
                        <stop
                          key={`${start}_${end}_end`}
                          offset={`${end}%`}
                          stopColor={LINE_STROKE_SECONDARY}
                          stopOpacity={opacity}
                        />,
                      ];
                    })}
                  </linearGradient>
                </defs>
                <YAxis tickFormatter={number => `${abbreviateNumber(number)}`} />
                <XAxis
                  dataKey="date"
                  tickFormatter={R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))}
                  height={15}
                />
                <Legend
                  verticalAlign="top"
                  height={26}
                  iconType="circle"
                  align="right"
                  wrapperStyle={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                />
                <Line
                  dataKey={keys[0]}
                  type="monotone"
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  isAnimationActive={false}
                  stroke="url(#liftChartStrokeGradient1)"
                />
                <Line
                  dataKey={keys[1]}
                  type="monotone"
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  isAnimationActive={false}
                  stroke="url(#liftChartStrokeGradient2)"
                />
                <Tooltip
                  content={({ label, payload }) => (
                    <LiftFromTvTooltip label={label} payload={payload} />
                  )}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="noData">No Data</div>
          )
        ) : (
          <Skeleton>
            <PathSkeleton
              points={[
                [0, 0.3],
                [0.25, 0.7],
                [0.5, 0.2],
                [0.75, 0.9],
                [1, 0.5],
              ]}
              thickness={10}
            />

            <AxesSkeleton size={5} />
          </Skeleton>
        )}
      </div>
    </div>
  );
};

interface LiftFromTvTooltipProps {
  label: string;
  payload: any[] | undefined;
}

const LiftFromTvTooltip: React.FC<LiftFromTvTooltipProps> = ({ label, payload }) => {
  return (
    <div className="liftFromTvTooltip">
      <div className="label">{formatDateLabel(label)}</div>
      <div className="itemList">
        {(payload || []).map((item, i) => {
          const { value } = item;
          return (
            <div className="itemRow" key={i}>
              <div
                className="circle"
                style={{
                  backgroundColor: LINE_STROKES[i],
                }}
              />
              <div className="name">:</div>
              <div className="value">{formatNumberAsInt(value)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
