import "./MonthPicker.scss";
import React, { Dispatch, SetStateAction } from "react";

import { MdInfoOutline } from "react-icons/md";
import { BPMButton } from "../../Components/BPMButton/BPMButton";
import * as Dfns from "date-fns/fp";
import { Dropdown, DropdownToggleType } from "../Dropdown";

// Turn "Aug 2023" into "2023-08-01" or last day of month for end
export const formatDate = (date: string, type: string): string => {
  let parsedDate = new Date(Date.parse(date));

  const newDate =
    type === "end"
      ? Dfns.format("yyyy-MM-dd", Dfns.lastDayOfMonth(parsedDate))
      : Dfns.format("yyyy-MM-dd", parsedDate);

  return `${newDate}`;
};

// set  date to monthsBack in Aug 2023 format
export const startDateRange = (monthsBack: number): string => {
  let date = Dfns.subMonths(monthsBack, new Date());

  return Dfns.format("MMM yyyy", date);
};

interface MonthPickerProps {
  dateOptions: { value: string }[];
  endDateSelection: string;
  startDateSelection: string;
  updateDateRange: Dispatch<SetStateAction<{ endDate: string; startDate: string }>>;
  updateEndDateSelection: Dispatch<SetStateAction<string>>;
  updateStartDateSelection: Dispatch<SetStateAction<string>>;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({
  dateOptions,
  endDateSelection,
  startDateSelection,
  updateDateRange,
  updateEndDateSelection,
  updateStartDateSelection,
}) => {
  return (
    <div
      className="month-picker-row"
      style={{ alignItems: "center", display: "flex", maxWidth: "325px" }}
    >
      <Dropdown
        type={DropdownToggleType.OUTLINED}
        design="secondary"
        onChange={option => updateStartDateSelection(option)}
        options={dateOptions}
        value={startDateSelection}
      />
      <div style={{ fontWeight: 500, margin: "0 5px" }}>to</div>
      <Dropdown
        type={DropdownToggleType.OUTLINED}
        design="secondary"
        onChange={option => updateEndDateSelection(option)}
        options={dateOptions}
        value={endDateSelection}
      />
      <BPMButton
        onClick={() =>
          updateDateRange({
            endDate: formatDate(endDateSelection, "end"),
            startDate: formatDate(startDateSelection, "start"),
          })
        }
        size="sm"
        style={{ borderSize: "2px", marginLeft: "10px" }}
      >
        Apply
      </BPMButton>
      {new Date().getDate() < 15 && (
        <div className="month-picker-info-text">
          <MdInfoOutline
            style={{ fontSize: "13px", marginRight: "5px", position: "relative", top: "1px" }}
          />
          Previous month report not completed until the 15th
        </div>
      )}
    </div>
  );
};
