import React, { useEffect, useState, useMemo, useContext } from "react";

import * as R from "ramda";

import { LinearOptimizationsContext } from "./LinearOptimizations";
import { Form, Button, Modal } from "react-bootstrap";
import { FullPageSpinner, NumberFormatter, ModalEditTable } from "../Components";
import {
  MdBubbleChart,
  MdNotInterested,
  MdAttachMoney,
  MdAllOut,
  MdPeople,
  MdOutlineWarningAmber,
} from "react-icons/md";

import "./LinearOptimizations.scss";

const GLOBAL_FIELD_DEFINITIONS = [
  {
    name: "Single 15s Spot Max Cost",
    key: "single15sSpotMaxCost",
  },
  {
    name: "Single 30s Spot Max Cost",
    key: "single30sSpotMaxCost",
  },
  {
    name: "Single 60s Spot Max Cost",
    key: "single60sSpotMaxCost",
  },
  {
    name: "Max per Network Spend %",
    key: "networkMaxSpendPct",
  },
  {
    name: "Max Hourly per Network/Day/Daypart Spend",
    key: "networkDayDaypartMaxSpendPerHour",
  },
  {
    name: "Max Hourly per Satellite Network/Day/Daypart Spend",
    key: "networkDayDaypartSatelliteMaxSpendPerHour",
  },
  {
    name: "Max Hourly Spots per Network/Day/Daypart",
    key: "networkDayDaypartCountPerHour",
  },
  {
    name: "Max Hourly Spots per Network/Day/Daypart/Avail (National)",
    key: "networkDayDaypartReachTypeCountPerHour",
  },
  {
    name: "Max Hourly Spots per Network/Day/Daypart/Avail (Local)",
    key: "networkDayDaypartReachTypeLocalCountPerHour",
  },
  {
    name: "Max Hourly Spots per Satellite Network/Day/Daypart",
    key: "networkSatelliteDayDaypartCountPerHour",
  },
  {
    name: "Total Spots",
    key: "totalSpots",
  },
  {
    name: "Network/Avail/Rotation Relaxation Start (value - value + 3)",
    key: "minExtrapolation",
  },
  {
    name: "Unseen Max Spend %",
    key: "unseenMaxSpendPct",
  },
];

const ConstraintView = React.memo(() => {
  const {
    constraintData,
    setDirtyConstraint,
    setInvalidConstraint,
    setConstraintData,
    buyableNetworkAvailRotations,
    creativeMap,
    rotationsByNetwork,
    insightsCategoriesData,
  } = useContext(LinearOptimizationsContext);
  const [data, setData] = useState();
  const [showModifyTotalSpots, setShowModifyTotalSpots] = useState(false);
  const [invalidAudienceText, setInvalidAudienceText] = useState("");
  const [showInvalidAudienceConstraintWarning, setShowInvalidAudienceConstraintWarning] = useState(
    false
  );
  const isTraffic = useMemo(() => {
    return constraintData?.optimizationType === "traffic";
  }, [constraintData]);

  const checkIsAudienceValid = audience => {
    if (!(audience?.minValue > 0)) {
      setInvalidAudienceText("Min Delivery must be set to a value greater than 0");
      return false;
    }
    if (!(audience?.name?.length > 0)) {
      setInvalidAudienceText("Constraint must be named");
      return false;
    }
    if (!("metric" in audience)) {
      setInvalidAudienceText("Metric must be set");
      return false;
    }
    if (!("demographic" in audience) || audience.demographic.length === 0) {
      setInvalidAudienceText("Demographic must be set");
      return false;
    }
    if (
      audience?.metric === "TRPs" &&
      !(
        audience?.demographic.length === 1 &&
        ["hh", "m18plus", "a18plus", "w18plus"].includes(audience?.demographic[0])
      )
    ) {
      setInvalidAudienceText(
        "When using TRPs as metric, demographic must be HH, M18+, W18+, or A18+"
      );
      return false;
    }
    setInvalidAudienceText("");
    return true;
  };

  const updateAudienceWarning = audienceConstraintArray => {
    if (audienceConstraintArray.every(constraint => checkIsAudienceValid(constraint))) {
      setShowInvalidAudienceConstraintWarning(false);
    } else {
      setShowInvalidAudienceConstraintWarning(true);
    }
  };

  const audienceTableOverhead = useMemo(() => {
    return showInvalidAudienceConstraintWarning ? (
      <span style={{ color: "#7e57c2" }}>
        <MdPeople fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
        &nbsp;&nbsp;Audience constraints
        <span style={{ marginLeft: "20px", color: "#8c8a97", fontSize: "15px", fontWeight: 400 }}>
          <MdOutlineWarningAmber fill="#8c8a97" size="22" style={{ margin: "-2px 2px 0 0" }} />
          Some constraints are invalid
        </span>
      </span>
    ) : (
      <span style={{ color: "#7e57c2" }}>
        <MdPeople fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
        &nbsp;&nbsp;Audience constraints
      </span>
    );
  }, [showInvalidAudienceConstraintWarning]);

  useEffect(() => {
    if (constraintData) {
      setData(constraintData);
    }
  }, [constraintData]);

  const creativeOptions = useMemo(() => {
    return R.map(
      e => ({ label: e, value: e }),
      R.pipe(
        R.values,
        R.filter(R.prop("liveLinear")),
        R.pluck("name"),
        R.uniq,
        R.sortBy(R.identity)
      )(creativeMap)
    );
  }, [creativeMap]);

  const selectorOptions = useMemo(() => {
    return {
      creatives: creativeOptions,
      rotation: R.map(e => ({ label: e, value: e }), [
        "Overnight",
        "Early Morning",
        "Daytime",
        "Early Fringe",
        "Prime",
        "Late Fringe",
        "Broad Rotator",
        "Weekend",
      ]),
      days: R.map(e => ({ label: e, value: e }), ["Weekday", "Weekend"]),
      avail: R.map(e => ({ label: e, value: e }), ["N", "L"]),
      length: R.map(e => ({ label: e, value: e }), ["15", "30", "60", "30s Equiv"]),
      vingtiles: R.map(e => ({ label: e, value: e }), R.range(1, 21)),
      network: R.pipe(
        R.keys,
        R.map(key => ({ value: key, label: key })),
        R.append({ label: "Each Network", value: "Each Network" })
      )(buyableNetworkAvailRotations),
      networkRotation: R.mapObjIndexed(
        list => R.map(row => ({ value: row.fullName, label: row.fullName }), list),
        rotationsByNetwork
      ),
      insights: R.map(e => ({ label: e, value: e }), insightsCategoriesData || []),
      metric: ["TRPs", "Impressions"].map(e => ({ label: e, value: e })),
    };
  }, [creativeOptions, insightsCategoriesData, buyableNetworkAvailRotations, rotationsByNetwork]);

  const budgetHeaders = [
    {
      type: "text",
      field: "name",
      label: "Name",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "creatives",
      label: "Creatives",
      isMulti: true,
      options: "creatives",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "insights",
      label: "Insights Categories",
      isMulti: true,
      options: "insights",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "type",
      label: "Length",
      isMulti: false,
      options: "length",
      modalRow: 1,
      modalWidth: 150,
    },
    {
      type: "currency",
      field: "minValue",
      label: "Min $",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "percent",
      field: "minPct",
      label: "Min %",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "currency",
      field: "maxValue",
      label: "Max $",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "percent",
      field: "maxPct",
      label: "Max %",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
  ];

  const spotHeaders = [
    {
      type: "text",
      field: "name",
      label: "Name",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "creatives",
      label: "Creatives",
      isMulti: true,
      options: "creatives",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "insights",
      label: "Insights Categories",
      isMulti: true,
      options: "insights",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "type",
      label: "Length",
      isMulti: false,
      options: "length",
      modalRow: 1,
      modalWidth: 150,
    },
    {
      type: "decimal",
      field: "minValue",
      label: "Min",
      modalRow: 3,
      modalWidth: "25%",
    },
    {
      type: "decimal",
      field: "maxValue",
      label: "Max",
      modalRow: 3,
      modalWidth: "25%",
    },
  ];

  const audienceHeaders = [
    {
      type: "text",
      field: "name",
      label: "Name",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "creatives",
      label: "Creatives",
      isMulti: true,
      options: "creatives",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "insights",
      label: "Insights Categories",
      isMulti: true,
      options: "insights",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "type",
      label: "Length",
      isMulti: false,
      options: "length",
      modalRow: 1,
      modalWidth: 150,
    },
    {
      type: "select",
      field: "metric",
      label: "Metric",
      isMulti: false,
      options: "metric",
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "demographic",
      field: "demographic",
      label: "Demographic",
      modalRow: 3,
      modalFlex: 1,
      renderer: row => (
        <div>{Object.keys(row.demographic ?? {}).length > 0 ? "Custom" : "Not set"}</div>
      ),
    },
    {
      type: "decimal",
      field: "minValue",
      label: "Min Delivery",
      modalRow: 4,
      modalFlex: 1,
    },
    {
      type: "checkbox",
      field: "includeLocals",
      label: "Include Locals",
      modalRow: 4,
      modalFlex: 1,
    },
  ];

  const avoidHeaders = [
    {
      type: "select",
      field: "type",
      label: "Length",
      isMulti: false,
      options: "length",
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "creatives",
      label: "Creatives",
      isMulti: true,
      options: "creatives",
      modalRow: 2,
      modalFlex: 1,
      flex: 1,
    },
    {
      type: "select",
      field: "insights",
      label: "Insights Categories",
      isMulti: true,
      options: "insights",
      modalRow: 2,
      modalFlex: 1,
    },
  ];

  // Networks, Avail, Rotation only for non-traffic constraints
  if (!isTraffic) {
    budgetHeaders.push(
      {
        type: "select",
        field: "network",
        label: "Networks",
        isMulti: true,
        options: "network",
        clearKeys: ["avail", "rotation"],
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "reach",
        label: "Avail",
        options: "avail",
        clearKeys: ["rotation", "days"],
        modalRow: 1,
        modalWidth: 150,
      },
      {
        type: "select",
        field: "rotation",
        label: "Rotation",
        isMulti: true,
        options: "rotation",
        modalRow: 1,
        modalFlex: 1,
      }
    );

    spotHeaders.push(
      {
        type: "select",
        field: "network",
        label: "Network",
        options: "network",
        clearKeys: ["avail", "rotation"],
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "reach",
        label: "Avail",
        options: "avail",
        clearKeys: ["rotation"],
        modalRow: 1,
        modalWidth: 150,
      },
      {
        type: "select",
        field: "rotation",
        label: "Rotation",
        isMulti: true,
        options: "networkRotation",
        modalRow: 1,
        modalFlex: 1,
      }
    );

    audienceHeaders.push(
      {
        type: "select",
        field: "network",
        label: "Networks",
        isMulti: true,
        options: "network",
        clearKeys: ["avail", "rotation"],
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "reach",
        label: "Avail",
        options: "avail",
        clearKeys: ["rotation", "days"],
        modalRow: 1,
        modalWidth: 150,
      },
      {
        type: "select",
        field: "rotation",
        label: "Rotation",
        isMulti: true,
        options: "rotation",
        modalRow: 1,
        modalFlex: 1,
      }
    );

    avoidHeaders.push(
      {
        type: "checkbox",
        field: "rated",
        label: "Rated only",
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "network",
        label: "Network",
        options: "network",
        clearKeys: ["avail", "rotation"],
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "reach",
        label: "Avail",
        options: "avail",
        clearKeys: ["rotation"],
        modalRow: 0,
        modalFlex: 1,
      },
      {
        type: "select",
        field: "rotation",
        label: "Rotation",
        isMulti: true,
        options: "rotation",
        modalRow: 1,
        modalFlex: 2,
      }
    );
  }

  const globalHeaders = [
    {
      label: "Name",
      field: "name",
      flex: 1,
      minFlexWidth: 300,
      readOnly: true,
      modalRow: 0,
      modalFlex: 4,
    },
    {
      label: "Value",
      field: "value",
      type: "decimal",
      modalRow: 0,
      modalFlex: 1,
      renderer: row => {
        if (row.key.indexOf("Pct") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"%"} />
            </span>
          );
        } else if (row.key.indexOf("Spend") >= 0 || row.key.indexOf("Cost") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"$"} />
            </span>
          );
        } else if (row.key.indexOf("CountPer") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"#2"} />
            </span>
          );
        } else {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"#"} />
            </span>
          );
        }
      },
      rendererType: row => {
        if (row.key.indexOf("Pct") >= 0) {
          return "percent";
        } else if (row.key.indexOf("Spend") >= 0 || row.key.indexOf("Cost") >= 0) {
          return "currency";
        } else if (row.key.indexOf("CountPer") >= 0) {
          return "real";
        } else {
          return "decimal";
        }
      },
    },
  ];

  const globalConstraints = useMemo(() => {
    return (
      constraintData &&
      R.pipe(
        R.map(index => ({
          ...GLOBAL_FIELD_DEFINITIONS[index],
          index,
          value: R.path(
            ["constraints", "global", GLOBAL_FIELD_DEFINITIONS[index].key],
            constraintData
          ),
        }))
      )(R.range(0, GLOBAL_FIELD_DEFINITIONS.length))
    );
  }, [constraintData]);

  const UpdateTotalSpotsConstraint = ({ show, setShowModifyTotalSpots }) => {
    const totalBudget = data.constraints.total;
    const newTotalSpotsConstraint = Math.min(4000, Math.ceil(totalBudget / 100.0));
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowModifyTotalSpots(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Total Spots Constraint?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to update the Total Spots constraint to {newTotalSpotsConstraint}{" "}
          (currently {data.constraints.global.totalSpots})?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowModifyTotalSpots(false);
              setConstraintData({
                ...data,
                constraints: {
                  ...data.constraints,
                  global: {
                    ...data.constraints.global,
                    totalSpots: newTotalSpotsConstraint,
                  },
                },
              });
              setDirtyConstraint(true);
            }}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModifyTotalSpots(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (!data) {
    return <FullPageSpinner />;
  }

  return (
    <div className="constraintViewBody">
      {isTraffic ? (
        false
      ) : (
        <div className="overallBudgetContainer">
          <UpdateTotalSpotsConstraint
            show={showModifyTotalSpots}
            setShowModifyTotalSpots={setShowModifyTotalSpots}
          />
          <div className="tableHeader">
            <div className="tableName">Overall Budget</div>
          </div>
          <Form.Control
            className="overallBudget"
            value={data.constraints.total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            onChange={event => {
              setConstraintData({
                ...data,
                constraints: {
                  ...data.constraints,
                  total: parseInt(event.target.value.replace(/[^0-9]/g, "")),
                },
              });
              setDirtyConstraint(true);
            }}
            onBlur={() => {
              setShowModifyTotalSpots(true);
            }}
          />
        </div>
      )}
      <div className="constraintView">
        <ModalEditTable
          className="constraintTable"
          headers={budgetHeaders}
          name={
            <span style={{ color: "#7e57c2" }}>
              <MdAttachMoney fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
              &nbsp;&nbsp;Budget constraints
            </span>
          }
          tableData={data.constraints.budget}
          setTableData={tableData => {
            setConstraintData({
              ...data,
              constraints: { ...data.constraints, budget: tableData },
            });
            setDirtyConstraint(true);
          }}
          selectorOptions={selectorOptions}
          enableActionColumn={true}
        />
        <ModalEditTable
          className="constraintTable"
          headers={spotHeaders}
          name={
            <span style={{ color: "#7e57c2" }}>
              <MdBubbleChart fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
              &nbsp;&nbsp;Spot constraints
            </span>
          }
          tableData={data.constraints.spot}
          setTableData={tableData => {
            setConstraintData({
              ...data,
              constraints: { ...data.constraints, spot: tableData },
            });
            setDirtyConstraint(true);
          }}
          selectorOptions={selectorOptions}
          enableActionColumn={true}
        />
        {isTraffic ? (
          false
        ) : (
          <ModalEditTable
            className="constraintTable"
            headers={audienceHeaders}
            name={audienceTableOverhead}
            tableData={data.constraints.audience}
            setTableData={tableData => {
              setConstraintData({
                ...data,
                constraints: { ...data.constraints, audience: tableData },
              });
              setDirtyConstraint(true);
              updateAudienceWarning(tableData);
            }}
            selectorOptions={selectorOptions}
            invalidText={invalidAudienceText}
            checkIsValid={checkIsAudienceValid}
            enableActionColumn={true}
          />
        )}
        <div className="avoidGlobalGroup">
          <ModalEditTable
            className="constraintTable avoidTable"
            style={{ marginRight: isTraffic ? "0px" : "12.5px;" }}
            headers={avoidHeaders}
            name={
              <span style={{ color: "#7e57c2" }}>
                <MdNotInterested fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
                &nbsp;&nbsp;Avoid constraints
              </span>
            }
            tableData={data.constraints.avoid}
            setTableData={tableData => {
              const isInvalid = R.any(
                row => R.without(["index", "lastModified"], R.keys(row)).length === 0,
                tableData
              );

              setConstraintData({
                ...data,
                constraints: { ...data.constraints, avoid: tableData },
              });
              setDirtyConstraint(true);
              setInvalidConstraint(isInvalid);
            }}
            selectorOptions={selectorOptions}
            enableActionColumn={true}
          />
          {isTraffic ? (
            false
          ) : (
            <ModalEditTable
              className="constraintTable globalTable"
              headers={globalHeaders}
              name={
                <div style={{ color: "#7e57c2", height: "37px" }}>
                  <MdAllOut fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
                  &nbsp;&nbsp;Global constraints
                </div>
              }
              enableAdd={false}
              tableData={globalConstraints}
              enableDelete={false}
              setTableData={tableData => {
                setConstraintData({
                  ...data,
                  constraints: {
                    ...data.constraints,
                    global: R.fromPairs(R.map(row => [row.key, row.value], tableData)),
                  },
                });
                setDirtyConstraint(true);
              }}
              selectorOptions={selectorOptions}
              enableActionColumn={true}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ConstraintView;
