// Mobius iframe experiment

const mobiusIntegrationUsers: string[] = [
  "justin.manus@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "lakshmi.ramesh@tinuiti.com",
  "rochell.rotenberg@tinuiti.com",
  "christopher.robinson@tinuiti.com",
  "aj.jones@tinuiti.com",
  "sean.odlum@tinuiti.com",
  "automation@tinuiti.com",
  "scott.silveus@tinuiti.com",
  "brenna.huggins@tinuiti.com",
  // Add users here!
];

export const shouldEnableMobiusIntegration = (user: string): boolean => {
  return mobiusIntegrationUsers.includes(user);
};
