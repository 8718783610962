import React, { useState } from "react";
import { CURRENT_WEEK_START } from "../LinearBuying/linearBuyingConstants";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Page, DateRangePicker } from "../Components";
import { Form } from "react-bootstrap";
import "./LinearUpfrontReconciliation.scss";
export const DATE_FORMAT = "yyyy-MM-dd";
export const CUTOFF_DATE_PAST = R.pipe(
  Dfns.parseISO,
  Dfns.subMonths(6),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);
export const CUTOFF_DATE_FUTURE = R.pipe(
  Dfns.parseISO,
  Dfns.subDays(7),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);

const LinearUpfrontReconciliation: React.FC = () => {
  const [start, setStart] = useState(
    R.pipe(Dfns.parseISO, Dfns.subMonths(6), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [end, setEnd] = useState(
    R.pipe(Dfns.parseISO, Dfns.subDays(7), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  return (
    <Page
      title="Linear Upfront Reconciliation"
      pageType="LinearUpfrontReconciliation"
      minHeight="300"
      actions={
        <div className="linearUpfrontActions">
          <Form.Group>
            <DateRangePicker
              isOutsideRange={date => date < CUTOFF_DATE_PAST || date > CUTOFF_DATE_FUTURE}
              startDate={start}
              endDate={end}
              startDateId="tableDataStartDate"
              endDateId="tableDataStartDate"
              mondayOnly={true}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStart(startDate);
                  setEnd(endDate);
                }
              }}
            />
          </Form.Group>
        </div>
      }
    ></Page>
  );
};

export default LinearUpfrontReconciliation;
