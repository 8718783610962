import "../OptimizationsUtils/Optimizations.scss";
import { Button, Tooltip, Modal, Form, Row } from "react-bootstrap";
import {
  DateRangePicker,
  OldDropdown,
  FilterTableContainer,
  FullPageSpinner,
  NumberFormatter,
  OverlayTrigger,
  Spinner,
} from "../Components";
import {
  MdAdd,
  MdAttachMoney,
  MdContentCopy,
  MdDelete,
  MdDirectionsCar,
  MdPeople,
  MdPlayArrow,
} from "react-icons/md";
import { navigate } from "@reach/router";
import { StreamingOptimizationsContext, ROUTES } from "./StreamingOptimizations";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useContext, useEffect, useState } from "react";

const DATE_FORMAT = "yyyy-MM-dd";
const CUTOFF_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

interface ShowLogosModal {
  kpi: string;
  buildNumber: string;
  start: string;
  end: string;
}

const OptimizationHome = (): JSX.Element => {
  const {
    baseURL,
    company,
    constraintsList,
    copyConstraint,
    deleteConstraint,
    newConstraint,
    optimizations,
    origin,
    refreshingOptimizations,
    runOptimization,
    streamingType,
  } = useContext(StreamingOptimizationsContext);
  const [showCopyConstraint, setShowCopyConstraint] = useState(false);
  const [showNewConstraint, setShowNewConstraint] = useState(false);
  const [showDeleteConstraint, setShowDeleteConstraint] = useState(false);
  const [showRunOptimization, setShowRunOptimization] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState<ShowLogosModal>();
  const [fromConstraintId, setFromConstraintId] = useState();
  const [deleteConstraintId, setDeleteConstraintId] = useState();
  const [runOptimizationId, setRunOptimizationId] = useState();
  const [runOptimizationType, setRunOptimizationType] = useState();
  const [runOptimizationStart, setRunOptimizationStart] = useState();
  const [runOptimizationEnd, setRunOptimizationEnd] = useState();

  const [dates, setDates] = useState({
    start: Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date())),
    end: Dfns.format(DATE_FORMAT, Dfns.endOfISOWeek(new Date())),
  });

  const isConstraintNameValid = name => {
    return name.length > 0 && name !== "." && name !== "..";
  };

  const LogsModal = ({ show, setShowLogsModal }) => {
    if (!show) {
      return null;
    }

    return (
      <Modal size="lg" keyboard={true} show={!!show} onHide={() => setShowLogsModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Logs for build {show.buildNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnRlx-sns-prod-us-west-2;filter=${show.kpi}_${show.buildNumber}_${streamingType};start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>IterationConstraints</h2>
          </a>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-python-optimizer-prod-OptimizeIteration;filter=${show.kpi}_${show.buildNumber}_${streamingType};start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>PyIPOPT</h2>
          </a>
          <a
            href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnPvt-sns-prod-us-west-2;filter=${show.kpi}_${show.buildNumber}_${streamingType};start=${show.start};end=${show.end}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>IterationPivots</h2>
          </a>
        </Modal.Body>
      </Modal>
    );
  };

  const NewConstraintConfirmation = ({ show, setShowNewConstraint, newConstraint }) => {
    const [toConstraintId, setToConstraintId] = useState("");
    const [optimizationType, setOptimizationType] = useState("Buying");
    const [validDateRange, setValidDateRange] = useState<{ start: string; end: string }>({
      start: Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date())),
      end: Dfns.format(DATE_FORMAT, Dfns.endOfISOWeek(new Date())),
    });

    useEffect(() => {
      (async () => {
        if (R.isNil(validDateRange)) {
          const getOrdersResponse = await StreamingV2LambdaFetch("/orders", {
            params: { company },
          });
          const getOrdersResult = await awaitJSON(getOrdersResponse);
          let start = new Date();
          let end = new Date();
          for (let elem of R.values(getOrdersResult)) {
            for (let { start_date, end_date } of elem) {
              const newStartDate = new Date(start_date);
              if (start > newStartDate) {
                start = newStartDate;
              }
              const newEndDate = new Date(end_date);
              if (end < newEndDate) {
                end = newEndDate;
              }
            }
          }
          setValidDateRange({
            start: Dfns.format(DATE_FORMAT, start),
            end: Dfns.format(DATE_FORMAT, end),
          });
        }
      })();
    }, [validDateRange]);

    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowNewConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={toConstraintId}
              onChange={e => setToConstraintId(e.target.value.replace(/ /g, ""))}
            />
          </Form.Group>
          <Form.Group as={Row} style={{ justifyContent: "space-between", margin: "0 1rem 1rem 0" }}>
            <Form.Group>
              <OldDropdown
                label="Optimization Type"
                value={optimizationType}
                options={["Buying", "Traffic"]}
                onChange={type => setOptimizationType(type)}
              />
            </Form.Group>
            {optimizationType === "Traffic" ? (
              <Form.Group as={Row}>
                <Form.Label style={{ paddingTop: "6px" }}>Allocation Date Range</Form.Label>
                <DateRangePicker
                  endDate={dates.end}
                  endDateId="constraintEnd"
                  startDate={dates.start}
                  startDateId="constraintStart"
                  isOutsideRange={date => {
                    return (
                      date < CUTOFF_DATE && date > validDateRange.start && date < validDateRange.end
                    );
                  }}
                  onChange={dates => {
                    setDates({
                      start: dates.startDate,
                      end: dates.endDate,
                    });
                  }}
                />
              </Form.Group>
            ) : (
              false
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
            overlay={
              (optimizationType === "Traffic" && !dates.start && !dates.end) ||
              !isConstraintNameValid(toConstraintId) ? (
                !isConstraintNameValid(toConstraintId) ? (
                  <Tooltip id={uuid.v4()}>Name your constraint to continue</Tooltip>
                ) : (
                  <Tooltip id={uuid.v4()}>Select an allocation start and end to continue</Tooltip>
                )
              ) : (
                <></>
              )
            }
          >
            <div style={{ position: "relative" }}>
              {(optimizationType === "Traffic" && !dates.start && !dates.end) ||
              !isConstraintNameValid(toConstraintId) ? (
                <div
                  style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
                ></div>
              ) : (
                false
              )}
              <Button
                variant="success"
                disabled={
                  (optimizationType === "Traffic" && !dates.start && !dates.end) ||
                  !isConstraintNameValid(toConstraintId)
                }
                onClick={async () => {
                  setShowNewConstraint(false);
                  await newConstraint({
                    to: toConstraintId,
                    optimizationType: optimizationType.toLowerCase(),
                    start: dates.start,
                    end: dates.end,
                  });
                }}
              >
                Create
              </Button>
            </div>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    );
  };

  const CopyConstraintConfirmation = ({
    show,
    setShowCopyConstraint,
    copyConstraint,
    fromConstraintId,
    fromStart,
    fromEnd,
    fromOptimizationType,
  }) => {
    const [toConstraintId, setToConstraintId] = useState("");
    const optimizationType = fromOptimizationType
      ? fromOptimizationType[0].toUpperCase() + fromOptimizationType.slice(1)
      : "";
    const start = fromStart;
    const end = fromEnd;
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowCopyConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control type="text" value={fromConstraintId} readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Label>To</Form.Label>
            <Form.Control
              type="text"
              value={toConstraintId}
              onChange={e => setToConstraintId(e.target.value.replace(/ /g, ""))}
            />
          </Form.Group>
          <Form.Group as={Row} style={{ justifyContent: "space-between", margin: "0 1rem 1rem 0" }}>
            <Form.Group>
              <Form.Label style={{ paddingTop: "6px" }}>
                Optimization Type: {optimizationType}
              </Form.Label>
            </Form.Group>
            {optimizationType === "Traffic" ? (
              <Form.Group as={Row}>
                <Form.Label style={{ paddingTop: "6px" }}>Allocation Date Range</Form.Label>
                <DateRangePicker
                  endDate={dates.end}
                  endDateId="constraintEnd"
                  startDate={dates.start}
                  startDateId="constraintStart"
                  isOutsideRange={date => {
                    return date < CUTOFF_DATE && !R.isNil(date);
                  }}
                  onChange={dates => {
                    setDates({
                      start: dates.startDate,
                      end: dates.endDate,
                    });
                  }}
                />
              </Form.Group>
            ) : (
              false
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
            overlay={
              (optimizationType === "Traffic" && !start && !end) ||
              !isConstraintNameValid(toConstraintId) ? (
                !isConstraintNameValid(toConstraintId) ? (
                  <Tooltip id={uuid.v4()}>Name your constraint to continue</Tooltip>
                ) : (
                  <Tooltip id={uuid.v4()}>Select an allocation start and end to continue</Tooltip>
                )
              ) : (
                <></>
              )
            }
          >
            <div style={{ position: "relative" }}>
              {(optimizationType === "Traffic" && !start && !end) ||
              !isConstraintNameValid(toConstraintId) ? (
                <div
                  style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
                ></div>
              ) : (
                false
              )}
              <Button
                variant="success"
                disabled={
                  (optimizationType === "Traffic" && !start && !end) ||
                  !isConstraintNameValid(toConstraintId)
                }
                onClick={async () => {
                  setShowCopyConstraint(false);
                  await copyConstraint({
                    from: fromConstraintId,
                    to: toConstraintId,
                    optimizationType: fromOptimizationType,
                    start: fromStart,
                    end: fromEnd,
                  });
                }}
              >
                Copy
              </Button>
            </div>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteConstraintConfirmation = ({
    show,
    setShowDeleteConstraint,
    deleteConstraint,
    deleteConstraintId,
  }) => {
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowDeleteConstraint(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Constraint {deleteConstraintId}?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={async () => {
              setShowDeleteConstraint(false);
              await deleteConstraint({
                deleteConstraintId,
              });
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RunOptimizationConfirmation = ({
    show,
    setShowRunOptimization,
    runOptimization,
    name,
    type,
    start,
    end,
  }) => {
    const [branch, setBranch] = useState("v4");
    const prettyType = type ? type[0].toUpperCase() + type.slice(1) : "";
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowRunOptimization(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Run Constraint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Label>Branch</Form.Label>
            <Form.Control
              type="text"
              defaultValue={branch}
              onChange={e => setBranch(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Optimization Type</Form.Label>
            <Form.Control type="text" value={prettyType} readOnly />
          </Form.Group>
          {type === "traffic" ? (
            <Form.Group>
              <Form.Label>Allocation Start</Form.Label>
              <Form.Control type="text" value={start} readOnly />
              <Form.Label>Allocation End</Form.Label>
              <Form.Control type="text" value={end} readOnly />
            </Form.Group>
          ) : (
            false
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={async () => {
              setShowRunOptimization(false);
              await runOptimization({
                name,
                branch,
                optimizationType: type,
                start,
                end,
              });
            }}
          >
            Run
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const optimizationHeaders: Record<string, any> = [
    {
      label: "Number",
      name: "build_number",
      flex: true,
      renderer: row => {
        if (row.status === "SUCCESS") {
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={<Tooltip id={uuid.v4()}>Open Run {row.build_number}</Tooltip>}
            >
              <Button
                style={{ minWidth: 140, fontWeight: 500 }}
                variant="link"
                onClick={() => {
                  navigate(`${origin}/${baseURL}/${ROUTES.RUNS.uri}/${row.build_number}`);
                }}
              >
                {row.build_number}
              </Button>
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={<Tooltip id={uuid.v4()}>Debug Run {row.build_number}</Tooltip>}
            >
              <Button
                style={{ minWidth: 140, fontWeight: 400, color: "black" }}
                variant="link"
                onClick={() => {
                  setShowLogsModal({
                    kpi: row.company_id,
                    buildNumber: row.build_number,
                    start: row.start,
                    end: row.end,
                  });
                }}
              >
                {row.build_number}
              </Button>
            </OverlayTrigger>
          );
        }
      },
    },
    {
      label: "Status",
      name: "status",
      width: 180,
      renderer: row => {
        const statusText = (
          <div>
            {row.status} (
            <NumberFormatter
              value={Number.isFinite(row.progress) ? row.progress : 1}
              type={"%"}
              decimals={0}
            />
            )
          </div>
        );
        return statusText;
      },
    },
    {
      label: "Date (Eastern)",
      name: "date",
      minFlexWidth: 200,
      flex: 1,
      renderer: (row: Record<string, any>) => {
        return Dfns.format("yyyy-MM-dd hh:mma", Dfns.parseISO(R.prop("date", row)));
      },
    },
    {
      label: "Constraint Name",
      name: "constraint_name",
      flex: 2,
      minFlexWidth: 150,
    },
    {
      label: "Branch",
      name: "branch",
      flex: 3,
      minFlexWidth: 150,
    },
    {
      label: "OF",
      name: "objective_value",
      renderer: row => <NumberFormatter value={row.objective_value} type={""} decimals={0} />,
    },
    {
      label: "Cost",
      name: "cost",
      renderer: row => <NumberFormatter value={row.cost} type={"$"} decimals={0} />,
    },
    {
      label: "Budget",
      name: "budget",
      renderer: row => <NumberFormatter value={row.budget} type={"$"} decimals={0} />,
    },
  ];

  const constraintHeaders: Record<string, any> = [
    {
      label: "Name",
      name: "name",
      width: 180,
      renderer: row => (
        <Button
          variant="link"
          onClick={() => {
            navigate(`${origin}/${baseURL}/${ROUTES.CONSTRAINTS.uri}/${row.name}`);
          }}
        >
          {row.name}
        </Button>
      ),
    },
    {
      label: "Last Modified (Eastern)",
      name: "lastmodified",
      flex: 1,
      minFlexWidth: 200,
      renderer: (row: Record<string, any>) => {
        return Dfns.format("yyyy-MM-dd hh:mma", Dfns.parseISO(R.prop("lastmodified", row)));
      },
    },
    {
      label: "",
      name: "actions",
      width: 200,
      renderer: data => (
        <div className="constraintActions">
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip id={uuid.v4()}>Run optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setRunOptimizationId(data.name);
                setRunOptimizationType(data.optimizationType);
                setRunOptimizationStart(data.start);
                setRunOptimizationEnd(data.end);
                setShowRunOptimization(true);
              }}
            >
              <MdPlayArrow />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip id={uuid.v4()}>Copy optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setFromConstraintId(data.name);
                setRunOptimizationType(data.optimizationType);
                setRunOptimizationStart(data.start);
                setRunOptimizationEnd(data.end);
                setShowCopyConstraint(true);
              }}
            >
              <MdContentCopy />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
            overlay={<Tooltip id={uuid.v4()}>Delete optimization</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                setDeleteConstraintId(data.name);
                setShowDeleteConstraint(true);
              }}
            >
              <MdDelete />
            </Button>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  constraintHeaders.unshift({
    label: "",
    nonInteractive: true,
    name: "optimizationType",
    width: 30,
    renderer: row => (
      <OverlayTrigger
        placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
        overlay={
          <Tooltip id={uuid.v4()}>
            {row.optimizationType[0].toUpperCase() + row.optimizationType.slice(1)} optimization
          </Tooltip>
        }
      >
        {row.optimizationType === "buying" ? (
          <MdAttachMoney style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        ) : row.optimizationType === "traffic" ? (
          <MdDirectionsCar style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        ) : (
          <MdPeople style={{ height: "1.3rem", width: "1.3rem", fill: "#7e57c2" }} />
        )}
      </OverlayTrigger>
    ),
  });

  if (!optimizations || !constraintsList) {
    return <FullPageSpinner />;
  }

  // Experimental new constraint button
  const newConstraintButton = (
    <div className="constraintTableHeader">
      <span>Constraints</span>
      <OverlayTrigger
        placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
        overlay={<Tooltip id={uuid.v4()}>New Constraint</Tooltip>}
      >
        <Button
          variant="outline-primary"
          onClick={() => {
            setShowNewConstraint(true);
          }}
        >
          <MdAdd />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <div className="listOptimizations">
      <div className="listOptimizationsBody optimizations">
        <FilterTableContainer
          /// @ts-ignore TODO: remove this when we typescript-ify BPMTable
          headerHeight={25}
          rowHeight={25}
          data={optimizations}
          defaultTokens={{
            advanced: ["Status", "is not like", "FAILURE"],
          }}
          defaultAdvancedFilter
          headers={optimizationHeaders}
          title={
            <div className="optimizationTableHeader">
              <span>Optimizations</span>
              {refreshingOptimizations && <Spinner size={50} />}
            </div>
          }
        />
      </div>
      <div className="listOptimizationsBody constraints">
        <FilterTableContainer
          /// @ts-ignore TODO: remove this when we typescript-ify BPMTable
          headerHeight={25}
          rowHeight={25}
          data={constraintsList}
          headers={constraintHeaders}
          title={newConstraintButton}
        />
        <CopyConstraintConfirmation
          show={showCopyConstraint}
          setShowCopyConstraint={setShowCopyConstraint}
          copyConstraint={copyConstraint}
          fromConstraintId={fromConstraintId}
          fromStart={runOptimizationStart}
          fromEnd={runOptimizationEnd}
          fromOptimizationType={runOptimizationType}
        />
        <NewConstraintConfirmation
          show={showNewConstraint}
          setShowNewConstraint={setShowNewConstraint}
          newConstraint={newConstraint}
        />
        <DeleteConstraintConfirmation
          show={showDeleteConstraint}
          setShowDeleteConstraint={setShowDeleteConstraint}
          deleteConstraint={deleteConstraint}
          deleteConstraintId={deleteConstraintId}
        />
        <RunOptimizationConfirmation
          show={showRunOptimization}
          setShowRunOptimization={setShowRunOptimization}
          name={runOptimizationId}
          type={runOptimizationType}
          start={runOptimizationStart}
          end={runOptimizationEnd}
          runOptimization={runOptimization}
        />
        <LogsModal show={showLogsModal} setShowLogsModal={setShowLogsModal} />
      </div>
    </div>
  );
};

export default OptimizationHome;
