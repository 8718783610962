import { useCallback } from "react";
import * as Sentry from "@sentry/browser";

import * as R from "ramda";

import { useDispatch, useSelector } from "react-redux";

import { makeReducer } from "./utils";

import { FetchError } from "../utils/fetch-utils";
import { BootstrapVariant } from "../utils/types";

const ROOT = "modals";

const SET_ERROR = `${ROOT}/SET_ERROR`;
const SET_ARE_YOU_SURE = `${ROOT}/SET_ARE_YOU_SURE`;
const CLEAR_MODALS = `${ROOT}/CLEAR_MODALS`;

export interface ModalError {
  title?: string;
  message: React.ReactNode;
  okayText?: string;
  variant?: BootstrapVariant;
  centered?: boolean;
  reportError?: {
    message: string;
  };
  onOkay?: () => void;
}
export interface ModalAreYouSure extends ModalError {
  promise?: boolean;
  cancelText?: string;
  onCancel?: () => void;
}

interface ModalReduxState {
  error?: ModalError | null;
  areYouSure?: ModalAreYouSure | null;
}
interface ModalReduxRootState {
  [ROOT]: ModalReduxState;
}

export interface SetError {
  (error: ModalError): Promise<void>;
}

export const dispatchError = (
  dispatch: ReturnType<typeof useDispatch>,
  error: ModalError
): Promise<void> =>
  new Promise<void>(resolve => {
    dispatch({
      type: SET_ERROR,
      error: {
        ...error,
        onOkay: () => {
          if (error.onOkay) {
            error.onOkay();
          }
          resolve();
        },
      },
    });
  });

export const useSetError = (): SetError => {
  const dispatch = useDispatch();
  const setError = useCallback((error: ModalError) => dispatchError(dispatch, error), [dispatch]);
  return setError;
};

export interface SetAreYouSure {
  (areYouSure: ModalAreYouSure): Promise<void>;
}

// TODO: we should probably migrate the whole app to doing this the promise way. If we ever succeed,
// we can remove this flag.
export const useSetAreYouSure = (asPromise = false): SetAreYouSure => {
  const dispatch = useDispatch();
  const setAreYouSure = useCallback(
    (areYouSure: ModalAreYouSure) =>
      new Promise<void>((resolve, reject) => {
        dispatch({
          type: SET_ARE_YOU_SURE,
          areYouSure: {
            ...areYouSure,
            onOkay: () => {
              if (areYouSure.onOkay) {
                areYouSure.onOkay();
              }
              resolve();
            },
            onCancel: () => {
              if (areYouSure.onCancel) {
                areYouSure.onCancel();
              }
              // The magic JavaScript secret is that a function that returns a promise like this
              // will actually behave like a normal function if it's not awaited/then'd. We can
              // await this now, and it will resolve if they say okay and reject if they cancel.
              // However, if they don't await it, it will still run (so they can do it the
              // non-promise way). However, if they do it that way and hit cancel, we'll reject the
              // promise and they'll get an error about an un-caught promise rejection. So the only
              // real difference between promise mode and not-promise mode is whether or not we send
              // this reject.
              if (R.isNil(areYouSure.promise) ? asPromise : areYouSure.promise) {
                reject();
              }
            },
          },
        });
      }),
    [dispatch, asPromise]
  );
  return setAreYouSure;
};

export const useModalInfo = (): {
  error: Partial<ModalError> | null | undefined;
  areYouSure: ModalAreYouSure | null | undefined;
} => {
  const dispatch = useDispatch();
  const clearModals = useCallback(() => {
    dispatch({
      type: CLEAR_MODALS,
    });
  }, [dispatch]);

  let rawError = useSelector<ModalReduxRootState, Partial<ModalError> | null | undefined>(
    R.path([ROOT, "error"])
  );
  let error = rawError;
  if (rawError) {
    error = {
      title: "Error",
      message: "Error occurred",
      okayText: "Okay",
      variant: "danger",
      ...rawError,
      onOkay: () => {
        if (rawError?.onOkay) {
          rawError.onOkay();
        }
        clearModals();
      },
    };
  }
  let rawAreYouSure = useSelector<ModalReduxRootState, ModalAreYouSure | null | undefined>(
    R.path([ROOT, "areYouSure"])
  );
  let areYouSure = rawAreYouSure;
  if (areYouSure) {
    areYouSure = {
      title: "Are you sure?",
      message: "Are you sure?",
      cancelText: "Cancel",
      okayText: "Okay",
      variant: "warning",
      ...rawAreYouSure,
      onCancel: () => {
        if (rawAreYouSure?.onCancel) {
          rawAreYouSure.onCancel();
        }
        clearModals();
      },
      onOkay: () => {
        if (rawAreYouSure?.onOkay) {
          rawAreYouSure.onOkay();
        }
        clearModals();
      },
    };
  }
  return {
    error,
    areYouSure,
  };
};

const setError = (state: ModalReduxState, { error }: { error: ModalError }) => {
  if (error.reportError) {
    console.error(error.message);
    if (!(error.reportError instanceof FetchError) || error.reportError.status >= 500) {
      Sentry.captureException(error.reportError);
    }
  }
  return {
    ...state,
    error,
  };
};

const setAreYouSure = (
  state: ModalReduxState,
  { areYouSure }: { areYouSure: ModalAreYouSure }
) => ({
  ...state,
  areYouSure,
});

const clearModals = (state: ModalReduxState) => ({
  ...state,
  error: null,
  areYouSure: null,
});

export default makeReducer<ModalReduxState>(
  {},
  {
    [SET_ERROR]: setError,
    [SET_ARE_YOU_SURE]: setAreYouSure,
    [CLEAR_MODALS]: clearModals,
  }
);
