import React from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { Button, ButtonType } from "../../Components";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { ReactComponent as EmptyIcon } from "./empty.svg";
import "./EmptyInputs.scss";

interface EmptyInputsProps {
  inputType: string;
  showAddMediaInputModal: () => void;
}

export const EmptyInputs: React.FC<EmptyInputsProps> = ({ inputType, showAddMediaInputModal }) => {
  return (
    <div className="emptyInputsContainer">
      <EmptyIcon />
      <h1 className="emptyInputsTitle">No data to show here yet.</h1>
      <h2 className="emptyInputsSubtitle">Add {inputType} to get started!</h2>
      <Button
        type={ButtonType.FILLED}
        design="primary"
        onClick={showAddMediaInputModal}
        icon={<HiOutlinePlus />}
        variant={ButtonFrameworkVariant.LEADING_ICON}
      >
        Add {inputType}
      </Button>
    </div>
  );
};
