import React, { SyntheticEvent } from "react";
import { MdMoreHoriz } from "react-icons/md";

import "./ThreeDotsButton.scss";

export const ThreeDotsButton = React.forwardRef<
  HTMLDivElement,
  { onClick: (e: SyntheticEvent) => void }
>(({ onClick }, ref) => (
  <div
    ref={ref}
    className="optionsButton"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <MdMoreHoriz />
  </div>
));
