import React, { useState, useEffect } from "react";
import * as uuid from "uuid";
import * as R from "ramda";
import { Form } from "react-bootstrap";
import { MdAdd, MdCancel, MdSave } from "react-icons/md";

import { BPMButton, Spinner, FullPageSpinner } from "../Components";
import { useSetError } from "../redux/modals";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";

import "./StreamingNetworkProperties.scss";

const MULTI_SELECT_ATTRIBUTE = "multi_select";
const BOOLEAN_ATTRIBUTE = "boolean";
const NUMBER_ATTRIBUTE = "number";
const FREE_TEXT_ATTRIBUTE = "text";

interface Attribute {
  name: string;
  type: string;
  options: string[];
  uniqueId?: string;
  new?: boolean;
}

interface ExistingAttribute {
  attribute: string;
  id: number;
  type: string;
  value: string[] | string;
}

const StreamingNetworkProperties = (): JSX.Element => {
  const [existingAttributes, setExistingAttributes] = useState<Record<string, ExistingAttribute>>();
  const [newAttributes, setNewAttributes] = useState<Record<string, Attribute>>({});
  const [saving, setSaving] = useState(false);
  const setError = useSetError();

  // Fetch existing attributes
  useEffect(() => {
    if (!existingAttributes) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/get_global_streaming_network_properties");
          let formattedRes = await awaitJSON(res);
          setExistingAttributes(formattedRes);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [existingAttributes, setError]);

  // Adds a new network attribute
  const addNewNetworkAttribute = (type: string) => {
    let uniqueId = uuid.v4();
    let emptyAttribute: Attribute = { name: "", options: [], new: true, uniqueId, type };
    setNewAttributes(current => {
      return { ...current, [uniqueId]: emptyAttribute };
    });
  };

  // Adds option to new attribute
  const addOption = (id: string) => {
    setNewAttributes(current => {
      return {
        ...current,
        [id]: { ...(current[id] || {}), options: [...current[id].options, ""] },
      };
    });
  };

  // Discard all unsaved changes
  const discardChanges = () => {
    setNewAttributes({});
    setNewOptions({});
  };

  // Save Changes
  const saveChanges = async () => {
    setSaving(true);

    try {
      await StreamingV2LambdaFetch("/add_global_streaming_network_properties", {
        method: "post",
        body: { newAttributes, newOptions },
      });
      setNewAttributes({});
      setNewOptions({});
      setExistingAttributes(undefined);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      setError({ message: e.message, reportError: e });
    }
  };

  const [newOptions, setNewOptions] = useState({});

  return existingAttributes ? (
    <div className="streamingNetworkProperties">
      <div className="addNewAttributeContainer">
        <div className="buttons">
          <BPMButton
            className="newAttributeButton"
            onClick={() => addNewNetworkAttribute(MULTI_SELECT_ATTRIBUTE)}
            variant="outline-primary"
          >
            <span>Multi-Select</span>
            <MdAdd />
          </BPMButton>

          <BPMButton
            className="newAttributeButton"
            onClick={() => addNewNetworkAttribute(BOOLEAN_ATTRIBUTE)}
            variant="outline-primary"
          >
            <span>True/False</span>
            <MdAdd />
          </BPMButton>

          <BPMButton
            className="newAttributeButton"
            onClick={() => addNewNetworkAttribute(NUMBER_ATTRIBUTE)}
            variant="outline-primary"
          >
            <span>Number</span>
            <MdAdd />
          </BPMButton>
          <BPMButton
            className="newAttributeButton"
            onClick={() => addNewNetworkAttribute(FREE_TEXT_ATTRIBUTE)}
            variant="outline-primary"
          >
            <span>Free Text</span>
            <MdAdd />
          </BPMButton>
        </div>
      </div>
      <div className="attributesList">
        {existingAttributes &&
          R.keys(existingAttributes).map(key => {
            let { attribute, id, value, type } = existingAttributes[key];
            return (
              <div className="attributeBox" key={id}>
                <div className="attributeName">
                  <div>{attribute}</div>
                </div>
                {type === "multi_select" && value && (
                  <div className="attributeOptions">
                    {(value as string[]).map(option => {
                      return (
                        <div className="option" key={option}>
                          {option}
                        </div>
                      );
                    })}
                    {newOptions[id] &&
                      newOptions[id].map((option, i) => {
                        return (
                          <Form.Control
                            size="sm"
                            placeholder="New Option"
                            type="text"
                            value={option}
                            key={i}
                            onChange={e => {
                              let val = e.target.value;
                              setNewOptions(current => {
                                return {
                                  ...current,
                                  [id]: R.update(i, val, newOptions[id]),
                                };
                              });
                            }}
                          />
                        );
                      })}
                    <BPMButton
                      size="sm"
                      onClick={() =>
                        setNewOptions(current => ({
                          ...current,
                          [id]: [...(newOptions[id] || []), ""],
                        }))
                      }
                    >
                      <MdAdd />
                    </BPMButton>
                  </div>
                )}
                {existingAttributes[attribute].type === "boolean" && <div>True/False Value</div>}
                {existingAttributes[attribute].type === "number" && <div>Number Value</div>}
                {existingAttributes[attribute].type === "text" && <div>Free Text</div>}
              </div>
            );
          })}
        {newAttributes &&
          R.keys(newAttributes).map((id, i) => {
            if (newAttributes[id].type === MULTI_SELECT_ATTRIBUTE) {
              return (
                <div className="attributeBox" key={i}>
                  <div className="attributeName">
                    <Form.Control
                      placeholder="Attribute Name"
                      type="text"
                      value={newAttributes[id].name}
                      onChange={e => {
                        const newName = e.target.value;
                        setNewAttributes(current => {
                          return {
                            ...current,
                            [id]: { ...current[id], name: newName },
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="attributeOptions">
                    {newAttributes[id] &&
                      newAttributes[id].options.map((option, i) => {
                        return (
                          <Form.Control
                            size="sm"
                            placeholder="Option"
                            type="text"
                            value={option}
                            key={i}
                            onChange={e => {
                              const newOption = e.target.value;
                              setNewAttributes(current => {
                                return {
                                  ...current,
                                  [id]: {
                                    ...current[id],
                                    options: R.update(i, newOption, current[id].options),
                                  },
                                };
                              });
                            }}
                          />
                        );
                      })}
                    <BPMButton size="sm" onClick={() => addOption(id)}>
                      <MdAdd />
                    </BPMButton>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="attributeBox" key={i}>
                  <div className="attributeName">
                    <Form.Control
                      placeholder="Attribute Name"
                      type="text"
                      value={newAttributes[id].name}
                      onChange={e => {
                        const newName = e.target.value;
                        setNewAttributes(current => {
                          return {
                            ...current,
                            [id]: { ...current[id], name: newName },
                          };
                        });
                      }}
                    />
                  </div>
                  <div>{newAttributes[id].type}</div>
                </div>
              );
            }
          })}
      </div>

      {(!R.isEmpty(newAttributes) || !R.isEmpty(newOptions)) && (
        <div className="floatingControls">
          <BPMButton onClick={discardChanges} variant="danger">
            <MdCancel />
          </BPMButton>

          <BPMButton onClick={saveChanges} variant="success">
            {saving ? <Spinner /> : <MdSave />}
          </BPMButton>
        </div>
      )}
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default StreamingNetworkProperties;
