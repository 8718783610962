import { awaitJSON, S3SignedUrlFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { Form } from "react-bootstrap";
import { RelativeDatePicker } from "../Components";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as R from "ramda";
import React, { useEffect } from "react";

const dataHeaders = ["Network", "Bonus", "Credits", "Total"];
interface CreditWeek {
  BonusMedia: number;
  CreditedMedia: number;
  WeekOf: string;
}

interface BonusAndCreditSummarySlideData {
  companyColor: string | null;
  creditWeeks: CreditWeek[];
  end: string;
  start: string;
}

export interface BonusAndCreditSummarySlideState extends BonusAndCreditSummarySlideData {
  dates: RelativeDateRange;
  kpi: string;
}

class BonusAndCreditSummarySlide extends SlideType {
  static typeKey = "bonusAndCreditSummary";
  static displayKey = "Bonus & Credit Summary";
  static defaultState: BonusAndCreditSummarySlideState = {
    companyColor: null,
    creditWeeks: [],
    dates: {
      start: { pivotDate: "monday", adjustment: 7, adjustmentType: "day" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    end: "",
    kpi: "",
    start: "",
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<BonusAndCreditSummarySlideState>
  > = React.memo(({ state, setState }) => {
    const { dates, kpi } = state;
    const { color, streaming_performance_default_kpi } = useCompanyInfo();

    useEffect(() => {
      setState({ companyColor: color });
    }, [color, setState]);

    useEffect(() => {
      if (kpi === "") {
        setState({ kpi: streaming_performance_default_kpi });
      }
    }, [kpi, setState, streaming_performance_default_kpi]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <RelativeDatePicker
              state={dates.start}
              onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
            />
            <Form.Label>End Date</Form.Label>
            <RelativeDatePicker
              state={dates.end}
              onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
            />
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<BonusAndCreditSummarySlideData> => {
    let { companyColor, dates, kpi } = state as BonusAndCreditSummarySlideState;
    const start = computeResolvedDate(dates.start);
    const end = computeResolvedDate(dates.end);
    let dataMap: Record<string, CreditWeek> = {};
    const res = await S3SignedUrlFetch(`bpm-ml-data/v4/${kpi}/latest/dashboard.json.gz`);
    const dashboardData = await awaitJSON(res);
    const metrics: any[] | undefined = R.path(["bonusReportMetrics"], dashboardData);
    if (metrics) {
      for (let elem of metrics) {
        let week = elem.title.split(" ")[2];
        if (week) {
          if (new Date(start) <= new Date(week) && new Date(end) >= new Date(week)) {
            let bonus = 0;
            let credits = 0;
            if (elem.data) {
              for (let entry of elem.data) {
                if (entry.length === dataHeaders.length) {
                  bonus += R.defaultTo(0.0, entry[dataHeaders.indexOf("Bonus")].value);
                  credits += R.defaultTo(0.0, entry[dataHeaders.indexOf("Credits")].value);
                }
              }
            }
            if (dataMap[week]) {
              dataMap[week].BonusMedia += bonus;
              dataMap[week].CreditedMedia += credits;
            } else {
              dataMap[week] = {
                WeekOf: week,
                BonusMedia: bonus,
                CreditedMedia: credits,
              };
            }
          }
        }
      }
    }
    const creditWeeks = R.values(dataMap);
    if (R.isNil(creditWeeks) || creditWeeks.length < 2) {
      throw new Error(
        `No data avaialable for this date range: start=${start}, end=${end}, kpi=${kpi} (Bonus & Credit Summary Slide)`
      );
    }
    return {
      companyColor,
      creditWeeks,
      end,
      start,
    };
  };
}

export default BonusAndCreditSummarySlide;
