import React, { useRef, useEffect, useState } from "react";

import { Spinner } from "./";

export const Video = ({
  src,
  unloader = <span />,
  spinnerSize = 20,
  className = "",
  alt = "",
  ...props
}) => {
  const videoRef = useRef();

  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (!videoRef.current) {
      videoRef.current = document.createElement("video");
    }
  }, [videoRef]);

  useEffect(() => {
    const videoPlayer = document.getElementById("video");
    if (videoPlayer) {
      if (props.currentTime || props.currentTime === 0) {
        videoPlayer.currentTime = props.currentTime;
      }
    }
  }, [props.currentTime]);

  useEffect(() => {
    let stale = false;
    if (videoRef.current) {
      videoRef.current.src = src;
      videoRef.current.addEventListener("canplay", () => {
        if (!stale) {
          setLoadingState("loaded");
        }
      });
      videoRef.current.addEventListener("error", () => {
        if (!stale) {
          setLoadingState("error");
        }
      });
    }
    return () => {
      stale = true;
    };
  }, [videoRef, src, setLoadingState]);

  switch (loadingState) {
    case "loaded":
      return <video {...props} alt={alt} src={src} className={className} id={"video"} />;
    case "loading":
      return <Spinner size={spinnerSize} />;
    case "error":
      return unloader;
    default:
      return <span />;
  }
};

export default Video;
