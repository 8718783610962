// Linear Buying experiments.

// If opted into this experiment, you'll see the "Campaign" column on the Buying and Orders pages.
const campaignCompanies: string[] = [
  "care",
  "casper",
  "hers",
  "hims",
  "homechef",
  "instacart",
  "julie_scratchpad",
  "oura",
  "vinnie_scratchpad",
  // Add companies here!
];

export const shouldEnableLinearCampaigns = (company: string): boolean => {
  return campaignCompanies.includes(company);
};

// Experiment for making updates to linear buying page. Access by user until all features are live. This changes the lambdas and front end
// use a date range instead of one date however the multiple date picker is being hid so that people can only select one week at a time
// functionality shouldn't change as expected on current page

const newLinearBuyingUsers: string[] = [
  "brenna.huggins@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "justin.manus@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  // Add users here!
];

export const shouldEnableDateRangePickerUsers = (userEmail: string): boolean => {
  return newLinearBuyingUsers.includes(userEmail);
};

const newLinearBuyingCompany: string[] = [
  "michelle_scratchpad",
  "rachelcostanzo_scratchpad",
  // Add users here!
];

export const shouldEnableDateRangePickerCompany = (company: string): boolean => {
  return newLinearBuyingCompany.includes(company);
};
