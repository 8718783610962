import React from "react";
import { SlideState, SharedState } from "../slideTemplateConstants";
import {
  SlideType,
  SlideContext,
  SettingsComponentProps,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { Form } from "react-bootstrap";
import { DateRange } from "../../utils/types";
import {
  tmrKpiPull,
  PROFILE_FILTER_MESSAGING,
  TMR_COLORS,
  tmrRetailerFilterOptions,
  tmrSortByOptions,
  CHART_FILL_COLORS,
} from "./utils";
import { BPMDateRange, Dropdown, DropdownToggleType, PieChart } from "../../Components";
import moment from "moment";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { fetchProductSlideData } from "./QueryServices";

export interface MarketplaceReportProductTargetingSlideState {
  title: string;
  subTitle: string;
  sort: string;
  dates: DateRange;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportProductTargetingSlideData {
  title: string;
  subTitle: string;
}

type SalesPieData = {
  name: string;
  value: number;
  color?: string;
};

type SpendPieData = {
  name: string;
  value: number;
  color?: string;
};

/**
 * Slide typeKey must match in
 * slideTemplateConstant type
 */

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

const addColorsToSalesData = (data: any): SalesPieData[] => {
  return data.map((item, index) => ({
    name: item.productCategory,
    value: item.sales === 0 ? 0.001 : item.sales,
    color: CHART_FILL_COLORS[index] || TMR_COLORS,
  }));
};

const addColorsToSpendData = (data: any): SpendPieData[] => {
  return data.map((item, index) => ({
    name: item.productCategory,
    value: item.spend,
    color: CHART_FILL_COLORS[index] || TMR_COLORS,
  }));
};

const pieComponent = (height: number, width: number, data: {}[]) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    {/* This is because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
    {/*// @ts-ignore */}
    <PieChart data={data} size={300} />
  </div>
);
class MarketplaceReportProductTargetingSlide extends SlideType {
  static typeKey = "TmrProductTargetingSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportProductTargetingSlideState = {
    title: "TMR Product ID and Category Targeting",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    sort: "sales",
    dates: { start: DATE_START, end: DATE_END },
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportProductTargetingSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, sort, dates, kpi, companyProfile } = state;

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label> Sort by </Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={sort}
              options={tmrSortByOptions}
              onChange={change => setState({ sort: change })}
            />
          </div>
          <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportProductTargetingSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      sort,
      dates,
      retailer,
      companyProfile,
      kpi,
    } = state as MarketplaceReportProductTargetingSlideState;
    const height = 300;
    const width = 600;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    let data = await fetchProductSlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      sort,
      profileNames
    );

    let salesPieData = addColorsToSalesData(data.categorySales);
    const salesPieComponent = pieComponent(height, width, salesPieData);

    let spendPieData = addColorsToSpendData(data.categorySpend);
    const spendPieComponent = pieComponent(height, width, spendPieData);

    let salesPieUrl = await convertComponentToImageUrl(
      salesPieComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    let spendPieUrl = await convertComponentToImageUrl(
      spendPieComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      dates,
      data,
      salesPieUrl,
      spendPieUrl,
      retailer,
      salesPieData,
      spendPieData,
      kpi,
    });
  };
}
export default MarketplaceReportProductTargetingSlide;
