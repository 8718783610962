import { useCallback, useMemo, useState } from "react";
import * as Dfns from "date-fns/fp";

export const headerFormatter = (dateGrouping: string | undefined, label: string): string => {
  let formattedLabel;
  const labelWithTime = label.length < 11 ? `${label}T00:00:00`: label;
  switch (dateGrouping) {
    case "Day":
      formattedLabel = Dfns.format("M/d/yyyy", new Date(labelWithTime));
      break;
    case "Week":
      formattedLabel = `Week of ${Dfns.format("M/d/yyyy", new Date(labelWithTime))}`;
      break;
    case "Month":
      formattedLabel = Dfns.format("MMMM", new Date(labelWithTime));
      break;
    case "Quarter":
      formattedLabel = `Q${Dfns.getQuarter(Dfns.addDays(1, new Date(labelWithTime)))}, ${Dfns.format(
        "yyyy",
        Dfns.addDays(1, new Date(labelWithTime))
      )}`;
      break;
  }
  return formattedLabel;
};

const RECHART_CARTESIAN_YAXIS_TICK_VALUE_SELECTOR = `.recharts-cartesian-axis-tick-value[orientation="left"],
.recharts-cartesian-axis-tick-value[orientation="right"]`;

const RECHART_CARTESIAN_XAXIS_TICK_VALUE_SELECTOR = `.recharts-cartesian-axis-tick-value[orientation="bottom"],
.recharts-cartesian-axis-tick-value[orientation="top"]`;

type Props = {
  xAxisHeightModifier?: (number) => number;
  yAxisWidthModifier?: (number) => number;
  data?: any[];
};

type ReturnValues = {
  setChartRef: (any) => void;
  xAxisHeight: number;
  yAxisWidth: number;
};

export const useDynamicYAxisWidth = (props: void | Props): ReturnValues => {
  const { xAxisHeightModifier, yAxisWidthModifier, data } = props || {};
  const [yAxisWidthState, setYAxisWidthState] = useState(40);
  const [xAxisHeightState, setXAxisHeightState] = useState(30);

  const setChartRef = useCallback(
    chartRef => {
      if (chartRef != null && chartRef.container != null) {
        const yTickValueElements = chartRef.container.querySelectorAll(
          RECHART_CARTESIAN_YAXIS_TICK_VALUE_SELECTOR
        );
        const highestWidth = [...yTickValueElements]
          .map(el => {
            const boundingRect = el.getBoundingClientRect();
            if (boundingRect != null && boundingRect.width != null) {
              return boundingRect.width;
            }
            return 0;
          })
          .reduce((accumulator, value) => {
            if (accumulator < value) {
              return value;
            }
            return accumulator;
          }, 0);
        setYAxisWidthState(highestWidth);

        const xTickValueElements = chartRef.container.querySelectorAll(
          RECHART_CARTESIAN_XAXIS_TICK_VALUE_SELECTOR
        );
        const highestHeight = [...xTickValueElements]
          .map(el => {
            const boundingRect = el.getBoundingClientRect();
            if (boundingRect != null && boundingRect.height != null) {
              return boundingRect.height;
            }
            return 0;
          })
          .reduce((accumulator, value) => {
            if (accumulator < value) {
              return value;
            }
            return accumulator;
          }, 0);
        setXAxisHeightState(highestHeight);
      }
    },
    // add data as a dependency to force ref to update
    // eslint-disable-next-line
    [setYAxisWidthState, setXAxisHeightState, data]
  );

  const yAxisWidth = useMemo(() => {
    if (yAxisWidthModifier != null && yAxisWidthState != null) {
      return yAxisWidthModifier(yAxisWidthState);
    }
    return yAxisWidthState;
  }, [yAxisWidthModifier, yAxisWidthState]);

  const xAxisHeight = useMemo(() => {
    if (xAxisHeightModifier != null && xAxisHeightState != null) {
      return xAxisHeightModifier(xAxisHeightState);
    }
    return xAxisHeightState;
  }, [xAxisHeightModifier, xAxisHeightState]);

  return {
    xAxisHeight,
    yAxisWidth,
    setChartRef,
  };
};
