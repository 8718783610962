import React from "react";

import { BPMTable, Spinner } from ".";

import "./FilterTableContainer.scss";

export const FilterTableContainer = React.memo(
  ({
    data,
    headers,
    defaultTokens,
    defaultAdvancedFilter,
    title,
    headerHeight = 40,
    rowHeight,
  }) => {
    if (!data) {
      return <Spinner size={75} />;
    }

    let hasData = data && data.length > 0;

    return (
      <div className={hasData ? "filterTableContainer" : "filterTableContainerEmpty"}>
        {title && (
          <div className="filterTableContainerHeader">
            <div className="filterTableContainerName">{title}</div>
          </div>
        )}
        <BPMTable
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          headers={headers}
          data={data}
          defaultTokens={defaultTokens}
          defaultAdvancedFilter={defaultAdvancedFilter}
          noRowsRenderer={() => <div>No rows to show.</div>}
        />
      </div>
    );
  }
);
