import "./FilterBar.scss";
import { Button, ButtonType, TextToggleButton } from "..";
import { Overlay, Tooltip, Modal, Carousel, Table } from "react-bootstrap";
import { makeFilter, getNextOptions } from "../../utils/boolean-logic-parser-creator";
import { MdChevronLeft, MdChevronRight, MdClose, MdHelpOutline, MdSearch } from "react-icons/md";
import { useStateFunction } from "../../utils/hooks/useData";
import * as R from "ramda";
import CreatableSelect from "react-select/creatable";
import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import * as uuid from "uuid";

const DEFAULT_FILTER_BAR_HEIGHT = 32;
const DEFAULT_FILTER_BAR_WIDTH = 400;

interface Option {
  isFixed?: boolean;
  key?: string;
  label: string;
  name?: string;
  type?: "bool" | "boolean" | "cat" | "closeParen" | "op" | "openParen" | "string" | "val";
  value?: string;
}

interface DefaultTokens {
  advanced?: string[];
  basic?: string[];
}

type ValidValue = string | number | boolean;

const isLike = (tokens: Option[]) => {
  const lastElem = R.last(tokens);
  return (
    !R.isNil(lastElem) &&
    lastElem.type &&
    lastElem.type === "op" &&
    lastElem.value &&
    lastElem.value.includes("like")
  );
};

const isValidNewOption = (elem: any, tokens: Option[]) => {
  if (!elem) {
    return false;
  }
  return isLike(tokens);
};

const makeNewOption = (text: string, length: number): Option => ({
  label: text,
  key: text,
  value: `${text}~${length}`,
  type: "val",
});

const basicToAdvanced = (tokens: Option[], options: string[] | Option[]) => {
  let fakeTokens: Option[] = [];
  if (options.length) {
    for (let token of tokens) {
      fakeTokens.push({
        label: "(",
        type: "openParen",
      });
      for (let option of options) {
        let label, name;
        if (typeof option === "string") {
          label = option;
          name = option;
        } else {
          ({ label, name } = option);
        }
        const tokenLC = R.defaultTo("", token.value).toLowerCase().replace("!", "");
        if (
          (option && tokenLC === R.defaultTo("", name).toLowerCase()) ||
          tokenLC === label.toLowerCase()
        ) {
          fakeTokens.push({
            label,
            key: name,
            type: "cat",
          });
          fakeTokens.push({
            label: "is",
            type: "op",
            key: "is",
          });
          let isNot = R.defaultTo("", token.value).charAt(0) === "!";
          fakeTokens.push({
            label: isNot ? "false" : "true",
            type: "val",
            key: isNot ? "false" : "true",
          });
          fakeTokens.push({
            label: "or",
            type: "bool",
            key: "or",
          });
        } else {
          fakeTokens.push({
            label,
            key: name,
            type: "cat",
          });
          fakeTokens.push({
            label: "is like",
            type: "op",
            key: "is like",
          });
          fakeTokens.push({
            label: token.label,
            type: "val",
            key: token.value,
          });
          fakeTokens.push({
            label: "or",
            type: "bool",
            key: "or",
          });
        }
      }
      // We have an extra "or"
      fakeTokens.pop();
      fakeTokens.push({
        label: ")",
        type: "closeParen",
      });
      fakeTokens.push({
        label: "and",
        type: "bool",
        key: "and",
      });
    }
    // We have an extra "and"
    fakeTokens.pop();
  }
  return fakeTokens;
};

// Given an array of strings representing tokens, a list of categories, and a list of filterable lines, construct a
// token object. This is meant to be used to convert a list of tokens taken as a parameter into the internal token
// representation.
export const parseRawTokenArray = (
  rawTokens: string[],
  options: Option[],
  lines: Record<string, ValidValue>[]
): Option[] => {
  let tokens: Option[] = [];
  // Go over the token strings we got
  for (let rawToken of rawTokens) {
    // Look at the options we have available to us with the tokens we've added thus-far
    let ourOptions = getNextOptions(tokens, options, lines) as Option[];
    // Does the next raw token match one of the options?
    let ourToken: Option | undefined = R.find(
      R.pipe(R.prop("label"), label => label === rawToken),
      ourOptions
    );
    // If so, use the valid token. If not, we might be allowed to make a "custom option", which is the free-text option
    // (generally for "is like" filters). If we can do that, do that. Otherwise, we have an invalid token so we'll just
    // back out.
    if (ourToken) {
      tokens.push({
        ...ourToken,
        isFixed: true,
      });
    } else if (isValidNewOption(rawToken, tokens)) {
      tokens.push({
        ...makeNewOption(rawToken, tokens.length),
        isFixed: true,
      });
    } else {
      console.error(
        `Unable to parse default filter bar tokens. Un-matchable token was: "${rawToken}"`
      );
      break;
    }
  }
  if (tokens.length) {
    tokens[tokens.length - 1].isFixed = false;
  }
  return tokens;
};

interface AdvancedFilterBarProps<T = Record<string, ValidValue>> {
  defaultTokens?: Option[];
  disableAutoFilter?: boolean;
  dismissMalformed?: () => void;
  dynamicTokens?: Option[];
  height: number;
  lines: T[];
  onChange?: (newTokens: Option[]) => void;
  onFilter: (filter?, tokens?) => void;
  options: string[] | Option[];
  placeholder?: string;
  setDynamicTokens?: (dynamicTokens: string[]) => void;
  setMalformed?: (error: string | Error) => void;
  setShowHelp?: (show: boolean) => void;
  size?: "sm" | "lg";
  tokens?: Option[];
  width: number;
}

// lines: [{ key1: val, key2: val, ...}]
// options: [key1, key2] or [{ name: key1, label: label1, type: string}, { name: key2, label: label2, type: boolean}]
export const AdvancedFilterBar = <T extends Record<string, ValidValue>>(
  props: AdvancedFilterBarProps<T>
): JSX.Element => {
  const {
    defaultTokens = [],
    disableAutoFilter,
    dismissMalformed = () => {},
    dynamicTokens = [],
    height,
    lines,
    onChange = (newTokens: Option[]) => {},
    onFilter = (filter, tokens) => {},
    options,
    placeholder = "Search Statement",
    setDynamicTokens = (dynamicTokens: string[]) => {},
    setMalformed = error => {},
    setShowHelp = show => {},
    size = "lg",
    tokens,
    width,
  } = props;
  // Checks if there's saved tokens for this page in the Session Storage. If there are, sets the default tokens to them
  const savedTokens: string = R.defaultTo(
    "",
    window.sessionStorage.getItem(`advanced_filter_${window.location.pathname}`)
  );
  const parsedSavedTokens = useMemo(() => {
    try {
      return JSON.parse(savedTokens);
    } catch (e) {
      return [];
    }
  }, [savedTokens]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [input, setInput] = useState("");
  const [localTokens, setLocalTokens] = useState(
    parsedSavedTokens && parsedSavedTokens.length > 0 ? parsedSavedTokens : defaultTokens
  );

  const ourTokens = tokens || localTokens;

  // This takes our tokens and creates a filter. If the makeFilter function fails, we don't set a filter and display
  // an error message (unless displayMalformed is false).
  let submitFilter = useCallback(
    (tokens = [], displayMalformed = true) => {
      let ourFilter;
      if (!tokens.length) {
        ourFilter = () => true;
      } else {
        try {
          ourFilter = makeFilter(tokens, options);
        } catch (e) {
          const error = e as Error;
          if (displayMalformed) {
            setMalformed(error.message || error);
          }
          return;
        }
      }
      dismissMalformed();
      onFilter(ourFilter, tokens);
    },
    [setMalformed, dismissMalformed, onFilter, options]
  );

  // We only want this to run on mount, so we don't want it to rerun whenever the tokens or anything else changes
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    submitFilter(ourTokens);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Since `displayMalformed` is false, then this will submit the filter if it's valid, and do nothing otherwise.
  let submitFilterIfValid = useCallback(tokens => submitFilter(tokens, false), [submitFilter]);

  const setTokens = useCallback(
    newTokens => {
      setLocalTokens(newTokens);
      onChange(newTokens);
      if (!disableAutoFilter) {
        submitFilterIfValid(newTokens);
      }

      // When the tokens change, they're saved in session storage so that the filter query isn't lost on page reload
      window.sessionStorage.setItem(
        `advanced_filter_${window.location.pathname}`,
        JSON.stringify(newTokens)
      );
    },
    [setLocalTokens, onChange, disableAutoFilter, submitFilterIfValid]
  );

  // Turns a selected option ('elem') into a filter token
  const addOption = useCallback(
    elem => {
      setInput("");
      let newTokens = [...ourTokens];
      // Setting 'isFixed' ensures that only the last token in the query can be deleted
      if (newTokens.length) {
        newTokens[newTokens.length - 1].isFixed = true;
      }
      newTokens.push({
        ...elem,
        isFixed: false,
      });
      setTokens(newTokens);
    },
    [setInput, setTokens, ourTokens]
  );

  // Removes the last token / selected option in the filter
  const popOption = useCallback(() => {
    setInput("");
    let newTokens = [...ourTokens];
    newTokens.pop();
    if (newTokens.length) {
      newTokens[newTokens.length - 1].isFixed = false;
    }
    setTokens(newTokens);
  }, [setInput, setTokens, ourTokens]);

  const clearFilter = useCallback(() => {
    setTokens([]);
    submitFilter();
    dismissMalformed();
  }, [setTokens, submitFilter, dismissMalformed]);

  // Force set tokens if dynamic tokens has been passed (tokens have been set programmatically in a parent)
  useEffect(() => {
    if (dynamicTokens.length > 0) {
      // Clear dynamically set token so it's not set again every rerender
      setDynamicTokens([]); // App completely bricks if we remove this ?
      // Update filter bar
      setTokens(dynamicTokens);
    }
  }, [dynamicTokens, setDynamicTokens, setTokens]);

  return (
    <>
      <div className="filterBarComponent" style={{ height, width }}>
        <div style={{ height, width }}>
          <CreatableSelect
            blurInputOnSelect={false}
            classNamePrefix="filterBarSelect"
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator: null,
              MultiValueRemove: props =>
                props.data.isFixed ? (
                  <div />
                ) : (
                  <div
                    className="filterBarSelect__multi-value__remove"
                    {...props.innerProps}
                    {...props.selectProps}
                  >
                    <MdClose />
                  </div>
                ),
            }}
            formatCreateLabel={label => `Search for "${label}"`}
            getOptionLabel={option => option.label || "\u00a0"}
            height={height}
            inputValue={input}
            isClearable={false}
            isMulti
            isValidNewOption={elem => isValidNewOption(elem, ourTokens)}
            noOptionsMessage={() =>
              isLike(ourTokens) ? "Start typing what you want to filter for" : "No options"
            }
            options={getNextOptions(ourTokens, options, lines)}
            placeholder={placeholder}
            tabIndex={1}
            value={ourTokens}
            width={width - 32}
            onMenuClose={() => setMenuOpen(false)}
            onMenuOpen={() => {
              dismissMalformed();
              setMenuOpen(true);
            }}
            onKeyDown={e => {
              if (menuOpen) {
                return;
              }
              if (e.key === "Enter") {
                submitFilter(ourTokens);
                e.preventDefault();
              }
            }}
            onInputChange={input => setInput(input)}
            // See https://react-select.com/props
            onChange={(elems, { action, removedValue }) => {
              switch (action) {
                case "remove-value":
                case "pop-value":
                  if (removedValue && !removedValue.isFixed) {
                    popOption();
                  }
                  return;
                case "create-option":
                  {
                    let last = elems.pop();
                    addOption(makeNewOption(last.label, elems.length));
                  }
                  return;
                case "clear":
                  clearFilter();
                  return;
                default:
                  break;
              }
              if (!R.prop("length", elems)) {
                setInput("");
                setTokens([]);
                return;
              }
              let last = elems.pop();
              addOption(last);
            }}
          />
        </div>
        <div className="filterBarHelp">
          <MdHelpOutline
            className="filterBarHelpIcon"
            onClick={() => {
              setShowHelp(true);
            }}
          />
        </div>
      </div>
      <Button
        className="filterSubmit"
        design="primary"
        icon={<MdSearch className="submitFilterIcon" />}
        onClick={() => {
          submitFilter(ourTokens);
        }}
        size={size}
        tabIndex={2}
        type={ButtonType.FILLED}
      />
    </>
  );
};

interface BasicFilterBarProps {
  defaultTokens?: string[];
  disableAutoFilter?: boolean;
  hasSubmit?: boolean;
  height: number;
  onChange?: (newTokens: Option[]) => void;
  onFilter: (filter, tokens: Option[]) => void;
  options: string[] | Option[];
  placeholder?: string;
  tokens?: Option[];
  width: number;
}

export const BasicFilterBar = React.memo<BasicFilterBarProps>(
  ({
    defaultTokens = [],
    disableAutoFilter,
    hasSubmit = false,
    height,
    onChange = (newTokens: Option[]) => {},
    onFilter = (filter, tokens) => {},
    options,
    placeholder = "Search",
    tokens,
    width,
  }) => {
    // Checks if there's saved tokens for this page in the Session Storage. If there are, sets the default tokens to them
    const savedTokens: string = R.defaultTo(
      "",
      window.sessionStorage.getItem(`basic_filter_${window.location.pathname}`)
    );
    const parsedSavedTokens = useMemo(() => {
      try {
        return JSON.parse(savedTokens);
      } catch (e) {
        return [];
      }
    }, [savedTokens]);

    const [input, setInput] = useState("");
    const [localTokens, setLocalTokens] = useState(parsedSavedTokens || defaultTokens);

    const ourTokens = tokens || localTokens;

    const triggerFilter = useCallback(
      tokens => {
        let fakeTokens = basicToAdvanced(tokens, options);
        let filter = makeFilter(fakeTokens, options);
        onFilter(filter, fakeTokens);
      },
      [options, onFilter]
    );

    // On mount, do it! We only want this to run on mount, so we don't want it to rerun whenever the tokens or anything else changes
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      triggerFilter(ourTokens);
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    const setTokens = useCallback(
      newTokens => {
        setLocalTokens(newTokens);
        onChange(newTokens);
        if (!disableAutoFilter) {
          triggerFilter(newTokens);
        }

        // When the tokens change, they're saved in session storage so that the filter query isn't lost on page reload
        window.sessionStorage.setItem(
          `basic_filter_${window.location.pathname}`,
          JSON.stringify(newTokens)
        );
      },
      [setLocalTokens, onChange, disableAutoFilter, triggerFilter]
    );

    // Turns a selected option ('value') into a filter token
    const addOption = useCallback(
      value => {
        let newTokens = [
          ...ourTokens,
          {
            value,
            label: value,
          },
        ];
        setInput("");
        setTokens(newTokens);
      },
      [ourTokens, setInput, setTokens]
    );

    // Removes the specified selected option / token ('value')
    const removeOption = useCallback(
      value => {
        let i;
        if (!value) {
          i = ourTokens.length - 1;
        } else {
          i = R.findIndex(elem => elem.value === value, ourTokens as Option[]);
        }
        if (R.isNil(i)) {
          return;
        }
        setInput("");
        setTokens(R.remove(i, 1, ourTokens));
      },
      [ourTokens, setInput, setTokens]
    );

    const clearFilter = useCallback(() => {
      setTokens([]);
      setInput("");
    }, [setTokens, setInput]);

    const searchIcon = (
      <div className="searchIconContainer">
        <MdSearch className="searchIcon" />
      </div>
    );

    const submitButton = (
      <Button
        className="filterSubmit"
        design="primary"
        icon={<MdSearch className="searchIcon" />}
        onClick={() => {
          if (input === "") {
            triggerFilter(ourTokens);
          } else {
            addOption(input);
          }
        }}
        tabIndex={2}
        type={ButtonType.FILLED}
      />
    );

    return (
      <>
        {!hasSubmit && searchIcon}
        <div className="filterBarComponent" style={{ height, width }}>
          <CreatableSelect
            classNamePrefix="filterBarSelect"
            components={{ DropdownIndicator: null }}
            height={height}
            inputValue={input}
            isMulti
            menuIsOpen={false}
            placeholder={placeholder}
            tabIndex={1}
            value={ourTokens}
            width={width - (hasSubmit ? 32 : 0)}
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === "Tab") {
                if (input !== "") {
                  addOption(input);
                } else {
                  triggerFilter(ourTokens);
                }
                e.preventDefault();
              }
            }}
            onInputChange={(input, { action }) => {
              if (action === "input-change") {
                setInput(input);
                if (!disableAutoFilter) {
                  triggerFilter([...ourTokens, { value: input, label: input }]);
                }
              }
            }}
            onChange={(elems, { action, removedValue }) => {
              switch (action) {
                case "remove-value":
                  if (removedValue) {
                    removeOption(removedValue.value);
                  }
                  return;
                case "pop-value":
                  if (ourTokens.length) {
                    removeOption(undefined);
                  }
                  return;
                case "clear":
                  clearFilter();
                  return;
                default:
                  break;
              }
              let last = elems.pop();
              addOption(last);
            }}
          />
        </div>
        {hasSubmit && submitButton}
      </>
    );
  }
);

const useMalformed = () => {
  const [malformedObject, setMalformedObject] = useState<Error>();
  const [showMalformed, setShowMalformed] = useState(false);

  const exposedSetMalformed = useCallback(
    (error: string | Error | undefined) => {
      if (error) {
        setMalformedObject(typeof error === "string" ? new Error(error) : error);
        setShowMalformed(true);
        let stillUndoing = true;
        setTimeout(() => {
          if (stillUndoing) {
            setShowMalformed(false);
          }
        }, 2000);
        return () => {
          stillUndoing = false;
        };
      } else {
        setShowMalformed(false);
      }
    },
    [setShowMalformed, setMalformedObject]
  );
  const dismissMalformed = useCallback(() => exposedSetMalformed(undefined), [exposedSetMalformed]);
  return {
    malformedObject,
    showMalformed,
    setShowMalformed,
    setMalformed: exposedSetMalformed,
    dismissMalformed,
  };
};

export interface FilterBarTokens {
  advanced: Option[];
  basic: Option[];
}

interface FilterBarProps<T = Record<string, ValidValue>> {
  advanced?: boolean;
  className?: string;
  defaultAdvanced?: boolean;
  defaultTokens?: DefaultTokens;
  disableAutoFilter?: boolean;
  dynamicTokens?: string[];
  hasAdvanced?: boolean;
  height?: number;
  lines?: T[];
  onChange?: (newTokens: FilterBarTokens) => void;
  onFilter: (filter: (line: T) => boolean, tokens) => void;
  onSetAdvanced?: (boolean) => void;
  options: string[] | Option[];
  placeholder?: string;
  setDynamicTokens?: (dynamicTokens: string[]) => void;
  size?: "sm" | "lg";
  tokens?: FilterBarTokens;
  variant?: "Dropdown" | "Widget";
  width?: number;
}

export const FilterBar = <T extends Record<string, ValidValue>>(
  props: FilterBarProps<T>
): JSX.Element => {
  const {
    advanced,
    className,
    defaultAdvanced = false,
    defaultTokens = {},
    disableAutoFilter = false,
    dynamicTokens = [],
    hasAdvanced: inputHasAdvanced = false,
    height = DEFAULT_FILTER_BAR_HEIGHT,
    lines = [],
    onChange = (newTokens: FilterBarTokens) => {},
    onFilter = (filter, tokens) => {},
    onSetAdvanced = () => {},
    options: inputOptions,
    placeholder,
    setDynamicTokens,
    size = "lg",
    tokens = {} as FilterBarTokens,
    variant = "Widget",
    width = DEFAULT_FILTER_BAR_WIDTH,
  } = props;
  const hasAdvanced = variant === "Dropdown" ? false : inputHasAdvanced;

  const options = useMemo(() => {
    const options: Option[] = [];
    for (const elem of inputOptions) {
      if (typeof elem === "string") {
        options.push({
          label: elem,
          name: elem,
          value: elem,
        });
      } else if (typeof elem === "object") {
        options.push(elem);
      }
    }
    return options;
  }, [inputOptions]);

  const barRef = useRef();
  const transformedDefaultAdvancedTokens = useMemo(() => {
    if (defaultTokens.advanced) {
      return parseRawTokenArray(defaultTokens.advanced, options, lines);
    }
  }, [defaultTokens.advanced, options, lines]);

  const transformedDynamicAdvancedTokens = useMemo(() => {
    if (dynamicTokens) {
      return parseRawTokenArray(dynamicTokens, options, lines);
    }
  }, [dynamicTokens, options, lines]);

  const isAdvancedStored = useMemo(() => {
    try {
      return JSON.parse(
        R.defaultTo("", window.sessionStorage.getItem(`filter_state_${window.location.pathname}`))
      );
    } catch (e) {
      return false;
    }
  }, []);

  const [localAdvancedMode, setLocalAdvancedMode] = useState(
    R.isNil(window.sessionStorage.getItem(`filter_state_${window.location.pathname}`))
      ? defaultAdvanced
      : isAdvancedStored
  );

  const [showHelp, setShowHelp] = useState(false);
  const {
    malformedObject,
    showMalformed,
    setShowMalformed,
    setMalformed,
    dismissMalformed,
  } = useMalformed();

  const advancedMode = R.isNil(advanced) ? localAdvancedMode : advanced;
  const setAdvancedMode = useCallback(
    setting => {
      onSetAdvanced(setting);
      setLocalAdvancedMode(setting);
      window.sessionStorage.setItem(
        `filter_state_${window.location.pathname}`,
        JSON.stringify(setting)
      );
    },
    [setLocalAdvancedMode, onSetAdvanced]
  );

  const onBasicChange = useCallback(
    (basic: Option[]) => {
      onChange({ ...tokens, basic });
    },
    [onChange, tokens]
  );

  const onAdvancedChange = useCallback(
    (advanced: Option[]) => {
      onChange({ ...tokens, advanced: advanced });
    },
    [onChange, tokens]
  );

  useEffect(() => {
    if (variant === "Dropdown" || !hasAdvanced) {
      setAdvancedMode(false);
    }
  });

  return (
    <div
      className={`BPMFilterBarComponentContainer ${variant} ${size} ${className ? className : ""}`}
      ref={barRef as any}
    >
      {hasAdvanced && (
        <TextToggleButton
          options={["Advanced", "Basic"]}
          selectedOption={advancedMode ? "Advanced" : "Basic"}
          onChange={option => {
            setAdvancedMode(option === "Advanced");
          }}
          className={advancedMode ? "advancedOn" : "basicOn"}
        />
      )}
      {advancedMode ? (
        <AdvancedFilterBar
          defaultTokens={R.defaultTo([], transformedDefaultAdvancedTokens)}
          disableAutoFilter={disableAutoFilter}
          dismissMalformed={dismissMalformed}
          dynamicTokens={R.defaultTo([], transformedDynamicAdvancedTokens)}
          height={height}
          lines={lines}
          onChange={onAdvancedChange}
          onFilter={onFilter}
          options={options}
          placeholder={placeholder}
          setDynamicTokens={R.defaultTo(dynamicTokens => {}, setDynamicTokens)}
          setMalformed={setMalformed}
          setShowHelp={setShowHelp}
          tokens={tokens.advanced}
          width={width}
        />
      ) : (
        <BasicFilterBar
          defaultTokens={R.defaultTo([], defaultTokens.basic)}
          disableAutoFilter={disableAutoFilter}
          hasSubmit={variant === "Widget"}
          height={height}
          onChange={onBasicChange}
          onFilter={onFilter}
          options={options}
          placeholder={placeholder}
          tokens={tokens.basic}
          width={width}
        />
      )}
      <Overlay target={barRef.current as any} show={showMalformed} placement="bottom-start">
        {props => (
          <Tooltip
            id={uuid.v4()}
            {...props}
            style={{
              ...props.style,
              cursor: "pointer",
            }}
            // This is a dumb bootstrap bug/idiosyncrasy
            show={!R.isNil(props.show.toString())}
            onClick={() => {
              setShowMalformed(false);
            }}
          >
            {malformedObject && malformedObject.message ? malformedObject.message : ""}
          </Tooltip>
        )}
      </Overlay>
      <FilterBarHelp show={showHelp} setShowHelp={setShowHelp} />
    </div>
  );
};

const SAMPLE_OPTIONS = ["Creative", "Network", "Spend"];
const SAMPLE_NETWORKS = ["HULUCOMEDY", "MTV", "MTV2", "NBCCOMEDY", "NBCFEM"];
const SAMPLE_CREATIVES = ["Celebrity", "Sale"];

const FilterDemo = ({
  advanced = false,
  data,
}: {
  advanced?: boolean;
  data: Record<string, string>[];
}) => {
  const [filter, setFilter] = useStateFunction(() => true);
  const filteredData = useMemo(() => R.filter(filter, data), [filter, data]);

  return (
    <div className="sampleTable">
      <div className="BPMFilterBarComponentContainer Widget">
        {advanced ? (
          <AdvancedFilterBar
            height={DEFAULT_FILTER_BAR_HEIGHT}
            lines={data}
            onFilter={filter => setFilter(filter)}
            options={SAMPLE_OPTIONS}
            width={DEFAULT_FILTER_BAR_WIDTH}
          />
        ) : (
          <BasicFilterBar
            hasSubmit={true}
            height={DEFAULT_FILTER_BAR_HEIGHT}
            onFilter={filter => setFilter(filter)}
            options={SAMPLE_OPTIONS}
            width={DEFAULT_FILTER_BAR_WIDTH}
          />
        )}
      </div>
      <div className="filterTable">
        <Table size="sm" borderless>
          <thead>
            <tr>
              {SAMPLE_OPTIONS.map(header => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map(row => (
              <tr key={JSON.stringify(row)}>
                {SAMPLE_OPTIONS.map(option => (
                  <td key={option}>{row[option]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const makeCommand = (command: string[]): (JSX.Element | string)[] =>
  R.tail(
    R.flatten(
      R.addIndex(R.map)((token, i) => [" + ", <strong key={i}>{token as string}</strong>])(command)
    ) as (JSX.Element | string)[]
  );

const FilterBarHelp = ({ show, setShowHelp }) => {
  const SAMPLE_DATA = useMemo(() => {
    let data: Record<string, string>[] = [];
    for (let Network of SAMPLE_NETWORKS) {
      for (let Creative of SAMPLE_CREATIVES) {
        data.push({
          Network,
          Creative,
          Spend: `$${(500 + Math.floor(Math.random() * 1000)).toLocaleString()}`,
        });
      }
    }
    return data;
  }, []);

  // https://react-bootstrap.github.io/components/carousel/#controlled
  const [index, setIndex] = useState(0);

  const handleSelect = useCallback(
    selectedIndex => {
      setIndex(selectedIndex);
    },
    [setIndex]
  );

  const NUM_SLIDES = 8;

  return (
    <Modal
      size="lg"
      keyboard={false}
      show={show}
      onHide={() => setShowHelp(false)}
      dialogClassName="filterBarHowto"
    >
      <Modal.Header closeButton>
        <Modal.Title>How To Use The Filter Bar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="carouselContainer">
          <div
            className="carouselControl"
            onClick={() => {
              setIndex(Math.max(0, index - 1));
            }}
          >
            {index > 0 && (
              <>
                <MdChevronLeft />
                <Button type={ButtonType.OUTLINED} design="primary">
                  Back
                </Button>
              </>
            )}
          </div>
          <div className="carouselInterior">
            <Carousel
              interval={null}
              controls={false}
              slide={false}
              activeIndex={index}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <h3>Basic</h3>
                <p>
                  Type <strong>NBCFEM</strong> and hit enter (the filter bar is always
                  case-insensitive). This will give you only the "NBCFEM" lines.
                </p>
                <FilterDemo data={SAMPLE_DATA} />
                <p>
                  Press the "x" next to NBCFEM in the filter bar, or hit backspace, to remove the
                  filter.
                </p>
                <p>
                  Type <strong>NBC</strong> and hit enter. This will give you all (and only) the
                  lines with networks that include "NBC".
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Basic: Multiple Filters</h3>
                <p>
                  Type <strong>comedy</strong> and hit enter. Then type <strong>Sale</strong> and
                  hit enter. This gives you only "comedy" networks with the "Sale" creative.
                </p>
                <FilterDemo data={SAMPLE_DATA} />
                <p>
                  You can press the "x" next to either "NBC" or "Sale" in the filter bar. This will
                  remove that filter and leave the other. You can also hit the "x" on the far-right
                  side of the filter bar to clear all filters.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced</h3>
                <p>
                  Click inside the filter bar and start typing <strong>Network</strong>. Once it's
                  highlighted in the dropdown you can hit enter. Do this again for{" "}
                  <strong>is</strong>, and finally for <strong>MTV</strong>. Click outside the
                  filter bar, hit "Esc" on your keyboard, or click "Filter".
                </p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  In the basic bar you cannot get "MTV" without "MTV2". In the advanced bar you can
                  be more precise. You can use the backspace, enter key, and the "clear all" button
                  as you can in the basic bar.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced: Or</h3>
                <p>
                  Enter {makeCommand(["Network", "is", "MTV"])} like before. You can click in the
                  dropdown, use the arrow keys and the enter key, or start typing to pare down the
                  dropdown list. Add {makeCommand(["or", "Network", "is", "NBCFEM"])}.
                </p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  In the basic bar, each row must satisfy all filters. In the advanced bar, when you
                  use <strong>or</strong>, lines only need to match one of the criteria. You can
                  chain as many <strong>or</strong> filters as you want. You can also use{" "}
                  <strong>and</strong>, which requires both to match.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced: Not</h3>
                <p>Enter {makeCommand(["Network", "is not", "MTV2"])}</p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  This will return every line with a network that is <em>not</em> "MTV2".
                </p>
                <p>
                  You can chain this with "or" and "and". Try{" "}
                  {makeCommand(["Network", "is", "MTV", "and", "Creative", "is not", "Sale"])}. This
                  gives you everything from "MTV", without lines with the "Sale" creative.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced: Is Like</h3>
                <p>
                  Enter {makeCommand(["Network", "is like"])}, then ype <strong>NBC</strong> and hit
                  enter.
                </p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  This works like the basic bar: the text only needs to match, not equal, the
                  entered text. However, here you confine your filter to one column. If you are
                  running on "Drama" networks, but with a creative called "Drama", this is
                  essential.
                </p>
                <p>
                  You can also use <strong>is not like</strong> to get a negative match.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced: Parentheses</h3>
                <p>
                  Enter{" "}
                  {makeCommand([
                    "(",
                    "Network",
                    "is",
                    "MTV",
                    "and",
                    "Creative",
                    "is",
                    "Sale",
                    ")",
                    "or",
                    "(",
                    "Network",
                    "is",
                    "NBCFEM",
                    "and",
                    "Creative",
                    "is",
                    "Celebrity",
                    ")",
                  ])}
                </p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  Parentheses group logic together. This gives you every line that has MTV/Sale plus
                  every line that has NBCFEM/Celebrity.
                </p>
                <p>
                  Without parentheses, <strong>and</strong> and <strong>or</strong> will be applied
                  in order. With long and/or chains you'll probably need parentheses to get the
                  result you want.
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <h3>Advanced: Parentheses Continued</h3>
                <p>
                  Try to get lines with the "Celebrity" creative but only for "HULUCOMEDY" and "MTV"
                  with
                  {makeCommand([
                    "Creative",
                    "is",
                    "Celebrity",
                    "and",
                    "Network",
                    "is",
                    "HULUCOMEDY",
                    "or",
                    "Network",
                    "is",
                    "MTV",
                  ])}
                  .
                </p>
                <FilterDemo advanced data={SAMPLE_DATA} />
                <p>
                  This gives you "MTV/Sale" because it took all the lines with "Celebrity" and
                  "HULUCOMEDY", <em>then</em> it looked for any other lines with "Network" as "MTV".
                  The proper filter would be{" "}
                  {makeCommand([
                    "Creative",
                    "is",
                    "Celebrity",
                    "and",
                    "(",
                    "Network",
                    "is",
                    "HULUCOMEDY",
                    "or",
                    "Network",
                    "is",
                    "MTV",
                    ")",
                  ])}
                </p>
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className="carouselControl"
            onClick={() => {
              setIndex(Math.min(NUM_SLIDES - 1, index + 1));
            }}
          >
            {index < NUM_SLIDES - 1 && (
              <>
                <MdChevronRight />
                <Button type={ButtonType.OUTLINED}>Next</Button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type={ButtonType.FILLED} onClick={() => setShowHelp(false)}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterBar;
