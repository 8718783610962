import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Page,
  Spinner,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  SelectorOption,
} from "../Components";
import "./SalesAndTrafficContacts.scss";
import {
  SalesAndTrafficContact,
  GetSalesAndTrafficContactsResponse,
  UpdateSalesAndTrafficContactsParams,
} from "@blisspointmedia/bpm-types/dist/SalesAndTrafficContacts";
import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

interface rowData extends SalesAndTrafficContact {
  lastmodified?: string;
}

const SalesAndTrafficContacts: React.FC = () => {
  const setError = useSetError();
  const [deletedRows, setDeletedRows] = useState<rowData[]>([]);
  const [tableData, setTableData] = useState<rowData[]>();
  const [saving, setSaving] = useState(false);
  const [contactsData, setContactsData] = useState<GetSalesAndTrafficContactsResponse>();

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let res = await LinearBuyingLambdaFetch("/sales_and_traffic_contacts");
          let contactsData = await awaitJSON<GetSalesAndTrafficContactsResponse>(res);
          setTableData(contactsData.contacts);
          setContactsData(contactsData);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to get linear buying assignments data ${error.message}`,
            reportError: error,
          });
        }
      })();
    }
  }, [setError, tableData]);

  const selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    if (contactsData) {
      return {
        avail: R.map(e => ({ label: e, value: e }), ["N", "L"]),
        general: R.map(e => ({ label: e, value: e }), ["G"]),
        buyingType: R.map(e => ({ label: e, value: e }), ["Upfront", "General", "Regular"]),
        department: R.map(e => ({ label: e, value: e }), ["Sales", "Traffic"]),
        station: R.map(
          e => ({ label: e["Station Name"], value: e["Station Name"] }),
          contactsData.networks
        ),
        primary: R.map(e => ({ label: e, value: e }), ["x"]),
      } as Record<string, SelectorOption[]>;
    } else {
      return {};
    }
  }, [contactsData]);

  const saveData = useMemo(() => {
    const stationSetValuesChanged = ({ "Canonical Station": station, lastmodified }: rowData) =>
      !R.isNil(lastmodified) && !R.isNil(station);

    const idSet = ({ id }: rowData) => !R.isNil(id);

    if (tableData) {
      let modifiedRows = R.filter(stationSetValuesChanged, tableData);

      let updateRows = R.filter(idSet, modifiedRows);
      let insertRows = R.without(updateRows, modifiedRows);
      let validDeleteRows = R.filter(idSet, deletedRows);

      return {
        insert: insertRows,
        update: updateRows,
        delete: validDeleteRows,
      };
    }
  }, [deletedRows, tableData]);
  const save = useCallback(async () => {
    try {
      setSaving(true);
      await LinearBuyingLambdaFetch<UpdateSalesAndTrafficContactsParams>(
        "/sales_and_traffic_contacts",
        {
          method: "POST",
          body: saveData,
        }
      );
      window.location.reload();
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to set linear buying assignments data ${error.message}`,
        reportError: error,
      });
    }
  }, [saveData, setError]);

  const contactsHeader = [
    {
      label: "Station",
      field: "Canonical Station",
      type: "select",
      options: "station",
      width: 450,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      label: "Short Code",
      field: "Short Code",
      width: 200,
      modalRow: 0,
      modalFlex: 1,
      readOnly: true,
    },
    {
      label: "Avail",
      field: "Avail",
      type: "select",
      options: "avail",
      width: 120,
      modalRow: 0,
      modalWidth: 120,
    },
    {
      label: "Primary",
      field: "Primary",
      type: "select",
      options: "primary",
      width: 120,
      modalRow: 0,
      modalWidth: 120,
    },
    {
      label: "First Name",
      field: "First Name",
      type: "text",
      flex: 1,
      modalRow: 2,
      modalFlex: 1,
    },
    {
      label: "Last Name",
      field: "Last Name",
      type: "text",
      flex: 1,
      modalRow: 2,
      modalFlex: 1,
    },
    {
      label: "Phone Number",
      field: "Phone",
      type: "text",
      flex: 1,
      modalRow: 3,
      modalFlex: 2,
    },
    {
      label: "Email",
      field: "Email",
      type: "text",
      width: 360,
      modalRow: 3,
      modalFlex: 3,
    },
    {
      label: "General",
      field: "General",
      type: "select",
      options: "general",
      modalRow: 4,
      width: 120,
      modalWidth: 120,
    },
    {
      label: "Department",
      field: "Department",
      type: "select",
      options: "department",
      modalWidth: 120,
      modalRow: 4,
      modalFlex: 1,
    },
    {
      label: "Notes",
      field: "Notes",
      type: "text",
      width: 360,
      modalRow: 5,
      modalFlex: 1,
    },
    {
      label: "Buying Type",
      field: "buying_type",
      type: "select",
      options: "buyingType",
      modalRow: 5,
      width: 160,
      modalWidth: 160,
    },
  ];

  return (
    <Page
      title="Sales and Traffic Contacts"
      pageType="Sales and Traffic Contacts"
      minHeight="600px"
      actions={
        <div className="SalesAndTrafficContactsActions">
          <Button
            variant="primary"
            onClick={save}
            className="saveButton"
            disabled={
              R.isNil(saveData) ||
              (!saveData.insert.length && !saveData.update.length && !saveData.delete.length) ||
              saving
            }
          >
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      <div className="salesAndTrafficContactsPageContainer">
        {tableData ? (
          <ModalEditTable
            className="salesAndTrafficContactsTable"
            name="Contacts"
            headers={contactsHeader}
            tableData={tableData}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            filterBar
            deletedRows={deletedRows}
            setDeletedRows={setDeletedRows}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default SalesAndTrafficContacts;
