import React, { useState, useContext, useCallback, useEffect, useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { MdKeyboardTab } from "react-icons/md";

import { Modal, ListGroup, Button } from "react-bootstrap";

import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import useLocation from "../utils/hooks/useLocation";
import { useSetError } from "../redux/modals";

import { ListGroupSkeleton, Skeleton, Spinner, DateBox, CheckBox } from "../Components";

import { OrderViewContext } from "./OrderView";

import "./MoveFlightModal.scss";

const makePrettyMoney = num =>
  (num || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
const makePrettyInteger = num => (num || 0).toLocaleString();

const makePrettyDate = R.pipe(Dfns.parseISO, Dfns.format("M/dd/yyyy"));
const MoveFlightModal = ({ flight, onClose, onSave }) => {
  const setError = useSetError();
  const getIsMounted = useIsMounted();
  const { company } = useLocation();

  const { order, network } = useContext(OrderViewContext);
  const [orders, setOrders] = useState();

  useEffect(() => {
    if (!orders) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/orders", { params: { company } });
          let orders = await awaitJSON(res);
          if (getIsMounted()) {
            R.pipe(
              R.prop(network),
              R.defaultTo([]),
              R.filter(({ id }) => id !== order.id),
              R.sort(R.descend(R.prop("start"))),
              setOrders
            )(orders);
          }
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [getIsMounted, company, network, setError, order, orders]);

  const [showHelp, setShowHelp] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState();

  const [saving, setSaving] = useState(false);

  const [applyToSubsequent, setApplyToSubsequent] = useState(false);
  const [showSubsequentHelp, setShowSubsequentHelp] = useState(false);

  const descriptionDisplay = useMemo(() => {
    const partsToDisplay = [];
    if (flight.showTitle) {
      partsToDisplay.push(flight.showTitle);
    }
    if (flight.spotPosition) {
      partsToDisplay.push(flight.spotPosition);
    }
    if (flight.description) {
      partsToDisplay.push(flight.description);
    }
    return partsToDisplay.join(" | ");
  }, [flight]);

  const ourOnSave = useCallback(async () => {
    setSaving(true);
    try {
      let res = await StreamingV2LambdaFetch("/orders/move_flight", {
        method: "post",
        body: {
          id: flight.id,
          orderID: selectedOrder,
          applyToSubsequent,
        },
      });
      let { newOrderID } = await awaitJSON(res);
      await setError({
        variant: "success",
        title: "Successfully Moved Flight",
        message: (
          <p>
            Successfully moved flight to order{" "}
            <a target="_blank" rel="noopener noreferrer" href={`./${newOrderID}`}>
              #{newOrderID}
            </a>
            . Make sure you send the updated versions of this and that flight.
          </p>
        ),
      });
      onSave();
    } catch (e) {
      setError({
        message: `Failed to move flight. Error: ${e.message}`,
        reportError: e,
      });
      setSaving(false);
    }
  }, [onSave, setError, flight, selectedOrder, applyToSubsequent]);

  const ourOnClose = useCallback(() => {
    if (saving) {
      setError({
        message: "You can't close until the flight is moved.",
      });
    } else {
      onClose();
    }
  }, [saving, setError, onClose]);
  return (
    <Modal show onHide={ourOnClose} className="streamingBuyingMoveFlightModal">
      <Modal.Header closeButton>
        <Modal.Title className="header">
          <MdKeyboardTab className="headerIcon" />
          <span>Move Flight #{flight.id}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flightInfo">
          <div>
            <div className="labeledItem">
              <span className="label">Spend:</span>
              <span>{makePrettyMoney(flight.spend)}</span>
            </div>
            <div className="labeledItem">
              <span className="label">CPM:</span>
              <span>{makePrettyMoney(flight.cpm)}</span>
            </div>
            <div className="labeledItem">
              <span className="label">Imps:</span>
              <span>{makePrettyInteger(flight.impressions)}</span>
            </div>
          </div>
          <div>
            <div className="labeledItem">
              <span className="label">Description:</span>
              <span>{descriptionDisplay}</span>
            </div>
          </div>
          <div>
            <div className="labeledItem">
              <span className="label">Dates:</span>
              <DateBox start={flight.startDate} end={flight.endDate} />
            </div>
          </div>
        </div>

        <div className="applyToSubsequent">
          <CheckBox checked={applyToSubsequent} onCheck={setApplyToSubsequent} />
          <div className="label" onClick={() => setApplyToSubsequent(R.not)}>
            Apply To Subsequent Flights
          </div>
          <Button
            size="sm"
            variant="link"
            className="helpButton"
            onClick={() => setShowSubsequentHelp(R.not)}
          >
            What does this mean?
          </Button>
        </div>

        {showSubsequentHelp && (
          <div className="help">
            <div>
              When you check this box, in addition to moving the flight you've selected, any flight
              in this IO that occurs after will be automatically canceled, and they will be assigned
              the new canceled derived network.
              <br />
              <br />
              Do this if you want to change from a weekly to a monthly IO. This lets you change the
              end date of the moved flight in the new IO to overlap with the subsequent flights.
            </div>
          </div>
        )}

        <div className={`orderListContainer${orders ? "" : " loading"}`}>
          {orders ? (
            <ListGroup>
              <ListGroup.Item
                action
                active={selectedOrder === "new"}
                onClick={() => setSelectedOrder(id => (id === "new" ? null : "new"))}
              >
                New Order
              </ListGroup.Item>
              {orders.map(({ id, start_date, end_date }) => (
                <ListGroup.Item
                  action
                  key={id}
                  className="orderRow"
                  active={selectedOrder === id}
                  onClick={() => setSelectedOrder(selectedID => (selectedID === id ? null : id))}
                >
                  <div>Order ID #{id}</div>
                  <div>{makePrettyDate(start_date)}</div>
                  <div>{makePrettyDate(end_date)}</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <Skeleton>
              <ListGroupSkeleton />
            </Skeleton>
          )}
        </div>
        <Button size="sm" variant="link" className="helpButton" onClick={() => setShowHelp(R.not)}>
          What happens when I do this?
        </Button>
        {showHelp && (
          <div className="help">
            <div>
              Moving this flight to another IO will copy this flight in that IO. It will then take
              this flight, cancel it, and set spend and impressions to 0 (if in the future).
              Internal derived networks will be adjusted so impressions will match to the new,
              copied flight, not the canceled one left in this IO.
              <br />
              <br />
              After moving this flight, you should resend this IO and the IO the flight was moved
              to. The changes will automatically be applied to the database, but will not be
              automatically sent to the networks.
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={ourOnClose}>
          Cancel
        </Button>
        <Button
          variant="warning"
          disabled={!selectedOrder}
          onClick={ourOnSave}
          className="saveButton"
        >
          Move Flight
        </Button>
      </Modal.Footer>
      {saving && (
        <div className="savingBox">
          <Spinner size={60} />
        </div>
      )}
    </Modal>
  );
};

export default MoveFlightModal;
