import "./KpiMapping.scss";
import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { useCompanyInfo } from "../redux/company";
import { FullPageSpinner, Page } from "../Components";
import EditKpiMapping from "./EditKpiMapping";
import useLocation from "../utils/hooks/useLocation";
import { useSetError } from "../redux/modals";
import { CrossChannelLambdaFetch } from "../utils/fetch-utils";
import { KpiMappings, AccountInfo } from "@blisspointmedia/bpm-types/dist/KpiMapping";
import KpiFunnels from "./KpiFunnels";

interface KpiMappingsContextType {
  sourceIdMap: Record<string, number>;
  kpiOptions: Record<string, string[]>;
  kpiMappings: KpiMappings;
  sourcesWithKpis: string[];
  sourceToPrettyName: Record<string, string>;
  accountInfoBySource: Record<string, AccountInfo[]>;
  editMode: boolean;
}

export const KpiMappingsContext = React.createContext<KpiMappingsContextType>({
  sourceIdMap: {},
  kpiOptions: {},
  kpiMappings: {},
  sourcesWithKpis: [],
  sourceToPrettyName: {},
  accountInfoBySource: {},
  editMode: false,
});

enum NAV {
  KPI_MAPPING = "KPI Mapping",
  CROSS_CHANNEL_FUNNEL = "Cross Channel Funnels",
}

const NAVS = [
  { label: NAV.KPI_MAPPING, key: NAV.KPI_MAPPING },
  { label: NAV.CROSS_CHANNEL_FUNNEL, key: NAV.CROSS_CHANNEL_FUNNEL },
];

const KpiMapping = (): JSX.Element => {
  const { company } = useLocation();
  const { cross_channel_kpis: crossChannelKpis } = useCompanyInfo();
  const setError = useSetError();
  const [editMode, setEditMode] = useState(false);
  const [kpiMappings, setKpiMappings] = useState<KpiMappings | undefined>();
  const [kpiOptions, setKpiOptions] = useState<Record<string, string[]>>();
  const [sourceIdMap, setSourceIdMap] = useState<Record<string, number>>();
  const [sourceToPrettyName, setSourceToPrettyName] = useState<Record<string, string>>();
  const [accountInfoBySource, setAccountInfoBySource] = useState<Record<string, AccountInfo[]>>();
  const [selectedNav, setSelectedNav] = useState(NAV.KPI_MAPPING);
  const [kpiFunnels, setKpiFunnels] = useState<any[]>();

  useEffect(() => {
    if (!kpiMappings) {
      (async () => {
        try {
          const res = await CrossChannelLambdaFetch("/getKpiMappings", {
            params: {
              company,
            },
          });
          const data = await res.json();
          const { kpiMappings, sourceIdMap, sourceToPrettyName, accountInfoBySource } = data;
          setKpiMappings(kpiMappings || {});
          setSourceIdMap(sourceIdMap || {});
          setSourceToPrettyName(sourceToPrettyName || {});
          setAccountInfoBySource(accountInfoBySource || {});
        } catch (e) {
          setError({
            message: `Failed to fetch KPI mapping data for ${company}. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, kpiMappings, setError]);

  useEffect(() => {
    (async () => {
      try {
        const res = await CrossChannelLambdaFetch("/getKpiOptions", {
          params: {
            company,
          },
        });
        const data = await res.json();
        setKpiOptions(data || {});
      } catch (e) {
        setError({
          message: `Failed to fetch source KPI options for ${company}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [company, setError]);

  useEffect(() => {
    if (!kpiFunnels) {
      (async () => {
        try {
          const res = await CrossChannelLambdaFetch("/getKpiFunnels", {
            params: {
              company,
            },
          });
          const data = await res.json();
          setKpiFunnels(data || []);
        } catch (e) {
          setError({
            message: `Failed to fetch KPI Funnels for ${company}. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, kpiFunnels, setError]);

  // Display the sources that we have KPI dropdown options for
  const sourcesWithKpis = useMemo(() => (kpiOptions ? R.keys(kpiOptions).sort() : []), [
    kpiOptions,
  ]);

  return (
    <Page
      title="KPI Mapping"
      pageType="KPI Mapping"
      navs={NAVS}
      selectedNav={selectedNav}
      onNav={val => {
        setSelectedNav(val as NAV);
        setEditMode(false);
      }}
    >
      {selectedNav === NAV.CROSS_CHANNEL_FUNNEL ? (
        <KpiFunnels
          funnels={kpiFunnels}
          crossChannelKpis={crossChannelKpis || []}
          company={company}
          setKpiFunnels={setKpiFunnels}
        />
      ) : (
        <div className="kpiMappingPage">
          {kpiMappings && kpiOptions && sourcesWithKpis && sourceToPrettyName ? (
            <KpiMappingsContext.Provider
              value={{
                sourceIdMap: sourceIdMap || {},
                kpiOptions,
                kpiMappings,
                sourcesWithKpis,
                sourceToPrettyName,
                accountInfoBySource: accountInfoBySource || {},
                editMode,
              }}
            >
              <EditKpiMapping
                company={company}
                data={kpiMappings}
                setEditMode={setEditMode}
                setKpiMappings={setKpiMappings}
              />
            </KpiMappingsContext.Provider>
          ) : (
            <FullPageSpinner />
          )}
        </div>
      )}
    </Page>
  );
};

export default KpiMapping;
