import "./IncrementalityPlaybook.scss";
import { CDN } from "../SingleChannel/MetricsTable/metricsTableUtils";
import { Img, Page, Spinner } from "../Components";
import { navigate, RouteComponentProps } from "@reach/router";
import { ReactComponent as MultiChannelIcon } from "./Icons/MultiChannelIcon.svg";
import { typedReactMemo } from "../utils/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import About from "./Pages/About";
import AllChannels from "./Pages/AllChannels";
import Amazon from "./Pages/Amazon";
import Commerce from "./Pages/Commerce";
import Discovery from "./Pages/Discovery";
import Display from "./Pages/Display";
import GeoTesting from "./Pages/GeoTesting";
import GoogleDV360 from "./Pages/GoogleDV360";
import GoogleSearch from "./Pages/GoogleSearch";
import Incrementality101 from "./Pages/Incrementality101";
import IncrementalityNavBar from "./IncrementalityNavBar";
import LinearTV from "./Pages/LinearTV";
import Meta from "./Pages/Meta";
import MultiChannel from "./Pages/MultiChannel";
import PerformanceMax from "./Pages/PerformanceMax";
import Pinterest from "./Pages/Pinterest";
import Reddit from "./Pages/Reddit";
import Search from "./Pages/Search";
import Shopping from "./Pages/Shopping";
import Snapchat from "./Pages/Snapchat";
import Social from "./Pages/Social";
import Spotify from "./Pages/Spotify";
import Streaming from "./Pages/Streaming";
import TikTok from "./Pages/TikTok";
import TradeDesk from "./Pages/TradeDesk";
import UserLevelCalculator from "./Pages/UserLevelCalculator";
import YouTube from "./Pages/YouTube";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLanguage,
  MdLibraryBooks,
  MdOutlineCalculate,
  MdSchool,
} from "react-icons/md";

export const PAGE_PREFIX = `${window.location.origin}/incrementality-playbook`;
export const INC_CDN = `${CDN}/incrementality`;

export const INC_PLAYBOOK_PAGES: Record<
  "Channels" | "Information" | "Tools",
  Record<
    string,
    {
      content: JSX.Element;
      icon?: JSX.Element;
      subPages?: Record<string, JSX.Element>;
    }
  >
> = {
  Information: {
    About: {
      content: <About />,
      icon: <MdLibraryBooks />,
    },
    "Incrementality 101": { content: <Incrementality101 />, icon: <MdSchool /> },
  },
  Channels: {
    "All Channels": {
      content: <AllChannels />,
      icon: <MdLanguage />,
      subPages: {
        GeoTesting: <GeoTesting />,
      },
    },
    Commerce: {
      content: <Commerce />,
      icon: <Img src={`${INC_CDN}/Icon_ConnectedCommerce.png`} />,
      subPages: {
        Amazon: <Amazon />,
      },
    },
    Display: {
      content: <Display />,
      subPages: {
        "Google (DV360)": <GoogleDV360 />,
        "The Trade Desk": <TradeDesk />,
      },
    },
    "Linear TV": {
      content: <LinearTV />,
      icon: <Img src={`${INC_CDN}/Icon_LinearTV.png`} />,
    },
    "Multi-Channel": {
      content: <MultiChannel />,
      icon: <MultiChannelIcon />,
      subPages: {
        Discovery: <Discovery />,
        "Performance Max": <PerformanceMax />,
      },
    },
    Search: {
      content: <Search />,
      subPages: { "Google Search": <GoogleSearch /> },
    },
    Shopping: {
      content: <Shopping />,
      icon: <Img src={`${INC_CDN}/Icon_Shoppable.png`} />,
    },
    Social: {
      content: <Social />,
      subPages: {
        Meta: <Meta />,
        Pinterest: <Pinterest />,
        Reddit: <Reddit />,
        Snapchat: <Snapchat />,
        TikTok: <TikTok />,
      },
    },
    Streaming: {
      content: <Streaming />,
      icon: <Img src={`${INC_CDN}/Icon_StreamingVideo.png`} />,
      subPages: {
        Spotify: <Spotify />,
        YouTube: <YouTube />,
      },
    },
  },
  Tools: {
    "User Level Calculator": { content: <UserLevelCalculator />, icon: <MdOutlineCalculate /> },
  },
};

export const FLAT_INC_PLAYBOOK_PAGES: Record<string, JSX.Element> = (() => {
  const flatPages = {};
  for (const section of R.keys(INC_PLAYBOOK_PAGES)) {
    for (const key of R.keys(INC_PLAYBOOK_PAGES[section])) {
      flatPages[key] = INC_PLAYBOOK_PAGES[section][key].content;
      if (INC_PLAYBOOK_PAGES[section][key].subPages) {
        for (const subKey of R.keys(INC_PLAYBOOK_PAGES[section][key].subPages)) {
          flatPages[subKey] = (INC_PLAYBOOK_PAGES[section][key].subPages as Record<
            string,
            JSX.Element
          >)[subKey];
        }
      }
    }
  }
  return flatPages;
})();

export interface IncPlaybookSectionBoxProps {
  className?: string;
  content: JSX.Element;
  id?: string;
  pageTitle?: string;
  title: string;
}

export const navigateToSection = (sectionID: string): void => {
  const sectionElement = window.document.getElementById(sectionID);
  if (sectionElement && !sectionElement.className.includes("expanded")) {
    const titleID = `${sectionID}_title`;
    const titleElement = window.document.getElementById(titleID);
    if (titleElement) {
      titleElement.click();
    }
    sectionElement.scrollIntoView({ behavior: "smooth" });
  }
};

export const IncPlaybookSectionBox = typedReactMemo<React.FC<IncPlaybookSectionBoxProps>>(
  ({ className, content, id, pageTitle, title }) => {
    const [expanded, setExpanded] = useState<boolean>(true);

    return (
      <div className={`incPlaybookSectionBox ${expanded ? "expanded" : ""} ${className}`} id={id}>
        <div
          className="titleContainer"
          id={id ? `${id}_title` : ""}
          onClick={() => setExpanded(!expanded)}
        >
          <div className="labelContainer">
            <div className="sectionTitle"> {title}</div>
            {pageTitle && pageTitle.length && (
              <>
                <div className="pageTitle">|</div>
                <div className="pageTitle"> {pageTitle}</div>
              </>
            )}
          </div>
          <div className="iconContainer">
            {expanded ? <MdArrowForwardIos /> : <MdArrowBackIos />}
          </div>
        </div>
        {expanded && (
          <div className="dividerLineContainer">
            <div className="dividerLine" />
          </div>
        )}
        {expanded && <div className="contentContainer">{content}</div>}
      </div>
    );
  }
);

interface IncrementalityPlaybookProps extends RouteComponentProps {
  page?: string;
}

const IncrementalityPlaybook = typedReactMemo<React.FC<IncrementalityPlaybookProps>>(({ page }) => {
  const [activePage, setActivePage] = useState<string>(
    page && FLAT_INC_PLAYBOOK_PAGES[page] ? page : "About"
  );
  const [rerender, setRerender] = useState<boolean>(false);

  const setActivePageURL = useCallback(page => {
    for (const key of R.keys(FLAT_INC_PLAYBOOK_PAGES)) {
      if (page.toLowerCase() === key.toLowerCase().replace(/ /g, "")) {
        navigate(`${PAGE_PREFIX}/${key.replace(/ /g, "")}`);
        setActivePage(key);
      }
    }
  }, []);

  useEffect(() => {
    const pageFromURL = window.location.href.slice(PAGE_PREFIX.length + 1);
    const routeKeys = R.map(
      key => R.toLower(key).replace(/ /g, ""),
      R.keys(FLAT_INC_PLAYBOOK_PAGES)
    );
    if (R.isNil(pageFromURL) || !routeKeys.includes(pageFromURL.toLowerCase())) {
      setActivePageURL("About");
    } else if (
      pageFromURL !== activePage.replace(/ /g, "") &&
      routeKeys.includes(pageFromURL.toLowerCase())
    ) {
      setActivePageURL(page);
    }
  }, [activePage, page, setActivePage, setActivePageURL]);

  // Component Sizing
  const windowResizeFunction = () => setRerender(true);
  if (window && window.onresize !== windowResizeFunction) {
    window.onresize = windowResizeFunction;
  }
  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const renderedPage = useMemo(
    () =>
      rerender ? (
        <Spinner size={100} />
      ) : (
        <div className="incPlaybookPageContainer">
          {FLAT_INC_PLAYBOOK_PAGES[activePage] ? (
            FLAT_INC_PLAYBOOK_PAGES[activePage]
          ) : (
            <Spinner size={100} />
          )}
        </div>
      ),
    [activePage, rerender]
  );

  return (
    <Page
      app2Redesign
      minWidth="500px"
      pageType={"Incrementality Playbook"}
      title={"Incrementality Playbook"}
    >
      <IncrementalityNavBar activePage={activePage} setActivePageURL={setActivePageURL} />
      {renderedPage}
    </Page>
  );
});

export default IncrementalityPlaybook;
