import "./CompetitiveLandscape.scss";
import { awaitJSON, S3SignedUrlFetch, SlidesLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import { CompetitiveData, SpendChart } from "../Components/SpendChart";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { Form } from "react-bootstrap";
import { getPrevWeekTrend, getAllWeeksTrend } from "../Components/CompetitiveChartBox";
import { GOOGLE_SLIDE_IMAGE_SCALING_FACTOR } from "./slideFormatConstants";
import { OldDropdown, RelativeDatePicker } from "../Components";
import { Provider } from "react-redux";
import { reduxStore } from "../redux";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface CompanyOption {
  label: string;
  value: string;
}

const CHART_HEIGHT = 108.9 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // 197.6px by 108.9px sized image
const CHART_WIDTH = 197.6 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // Scaling factor used to make a 199.9px
const DEFAULT_COLOR = "#737AFF";
const selectedCompaniesIndexes = [0, 1, 2, 3];
export const CHART_ASPECT_RATIO = CHART_WIDTH / CHART_HEIGHT;
export const competitiveLandscapeSharedStateKey = "competitiveLandscape" as const;

const getS3Data = async (filename, selectedCompetitor) => {
  if (!selectedCompetitor) {
    return null;
  }
  let res = await S3SignedUrlFetch(
    `bpm-ml-data/competitive/latest/${selectedCompetitor}_${filename}.json.gz`
  );
  try {
    let data = await awaitJSON(res);
    return data;
  } catch (e) {
    let error = e as Error;
    if ((R.prop("message", error) || "").includes("NoSuchKey")) {
      throw new Error("No data found");
    }
    throw e;
  }
};

interface RawCostPersWeek {
  colHeaders: string[];
  impressions: {};
  name: string;
  of: number[][];
  rowHeaders: string[];
  spend: number[][];
  week: string;
  spots: number[][];
}

interface RawCostPers {
  weeks: RawCostPersWeek[];
  buildNumber: number;
  decimals: number;
}

const formatS3Data = (s3Data: RawCostPers, startDate: Date, endDate: Date): CompetitiveData[] => {
  let competitiveChartData: Record<string, CompetitiveData> = {};
  let weekCount = 0;
  let currentDate = new Date(
    R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(weekCount), Dfns.format(DATE_FORMAT))(endDate)
  );

  while (currentDate >= startDate) {
    let dateString = R.pipe(
      Dfns.startOfISOWeek,
      Dfns.subWeeks(weekCount),
      Dfns.format(DATE_FORMAT)
    )(endDate);
    currentDate = new Date(dateString);
    if (currentDate >= startDate) {
      competitiveChartData[`${dateString}`] = { date: dateString, count: 0 };
    }
    weekCount++;
  }

  for (let week of s3Data.weeks) {
    if (week && week.week) {
      let currDateString = week.week;
      let weekDateObject = new Date(currDateString);
      let spend = 0;
      if (
        competitiveChartData[currDateString] ||
        (weekDateObject >= startDate && weekDateObject <= endDate)
      ) {
        for (let spendRow of week.spend) {
          spend += R.sum(spendRow);
        }
        competitiveChartData[currDateString] = { date: currDateString, count: spend };
      }
    }
  }

  return R.sort((a, b) => {
    let bDate = new Date(b.date);
    let aDate = new Date(a.date);
    return (
      Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()) -
      Date.UTC(bDate.getFullYear(), bDate.getMonth(), bDate.getDate())
    );
  }, R.values(competitiveChartData));
};

interface SlideCompetitiveChartResult {
  elem: SVGSVGElement | null;
  previousWeekTrend: number | null;
  allWeeksTrend: number | null;
  estimatedSpend: number | null; // should be the last week of data
  numWeeks: number | null;
  isNoData?: boolean;
}

interface SlideCompetitiveChartOnLoad {
  (results: SlideCompetitiveChartResult): void;
}

interface SlideCompetitiveChartProps {
  competitiveChartData: CompetitiveData[];
  onLoad: SlideCompetitiveChartOnLoad;
}

const SlideCompetitiveChart: React.FC<SlideCompetitiveChartProps> = ({
  competitiveChartData,
  onLoad,
}) => {
  const [clear, setClear] = useState<boolean>(false);
  const [pathLoaded, setPathLoaded] = useState<boolean>(false);
  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    if (clear) {
      mapSvgRef.current = null;
      setClear(false);
    } else if (competitiveChartData.length > 1 && pathLoaded) {
      const previousWeekTrend = getPrevWeekTrend(R.map(elem => elem.count, competitiveChartData));
      const allWeeksTrend = getAllWeeksTrend(R.map(elem => elem.count, competitiveChartData));
      const estimatedSpend = competitiveChartData[competitiveChartData.length - 1].count;
      onLoad({
        elem: mapSvgRef.current,
        previousWeekTrend,
        allWeeksTrend,
        estimatedSpend,
        isNoData: false,
        numWeeks: competitiveChartData.length,
      });
      setClear(true);
    }
  }, [clear, competitiveChartData, onLoad, pathLoaded]);

  if (competitiveChartData === null) {
    return null;
  } else {
    return (
      <SpendChart
        chartData={competitiveChartData}
        color={DEFAULT_COLOR}
        height={CHART_WIDTH / CHART_ASPECT_RATIO}
        ref={mapSvgRef}
        width={CHART_WIDTH}
        onPathLoad={setPathLoaded}
      />
    );
  }
};

interface CompetitiveLandscapeSlideData {
  start?: string;
  end?: string;
  competitiveMap: Record<string, string>;
}

export interface CompetitiveLandscapeSlideState extends CompetitiveLandscapeSlideData {
  selectedCompanies: string[];
  competitiveCompanyOptions: CompanyOption[];
  dates: RelativeDateRange;
}

export type CompetitiveLandscapeSharedState = string[];
export const competitiveLandscapeSharedFetcher: SharedStateFetcher<CompetitiveLandscapeSharedState> = async (
  company: string,
  setError: SetError
) => {
  try {
    let res = await SlidesLambdaFetch("/getCompetitiveCompanies", {
      params: { company },
    });
    let competitiveCompanies: CompetitiveLandscapeSharedState = await awaitJSON(res);
    return competitiveCompanies;
  } catch (e) {
    let error: Error = e as Error;
    setError({
      message: `Could not get valid competitive companies. Error: ${error.message}`,
      reportError: error,
    });
  }
  return [];
};

class CompetitiveLandscapeSlide extends SlideType {
  static typeKey = "competitiveLandscape";
  static displayKey = "Competitive Landscape";
  static defaultState: CompetitiveLandscapeSlideState = {
    selectedCompanies: ["", "", "", ""],
    competitiveMap: {},
    competitiveCompanyOptions: [],
    dates: {
      start: { pivotDate: "monday", adjustment: 90, adjustmentType: "day" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<CompetitiveLandscapeSlideState>
  > = React.memo(({ state, setState, sharedFetch, sharedState }) => {
    const { selectedCompanies, competitiveCompanyOptions, dates } = state;
    const info = useCompanyInfo();

    const setSlide = useCallback(slide => setSlide({ slide }), []);

    useEffect(() => {
      (async () => {
        await sharedFetch(competitiveLandscapeSharedStateKey, info.cid);
      })();
      if (sharedState.competitiveLandscape[info.cid]) {
        let sortedCompanies = sharedState.competitiveLandscape[info.cid].sort();

        const competitiveCompanyOptions = [{ label: "None", value: "" }];
        for (let company of sortedCompanies) {
          if (company) {
            competitiveCompanyOptions.push({
              label: company,
              value: company,
            });
          }
        }
        setState({
          competitiveCompanyOptions,
        });
      }
    }, [info.cid, setState, sharedFetch, sharedState]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group>
            <Form.Label>Start</Form.Label>
            <RelativeDatePicker
              state={dates.start}
              onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
            />
            <Form.Label>End</Form.Label>
            <RelativeDatePicker
              state={dates.end}
              onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
            />
          </Form.Group>
          <Form.Group>
            <div className="competitorDropdowns">
              {selectedCompaniesIndexes.map(index => {
                let key = `competitorDropdown${index}`;
                let company = selectedCompanies[index];
                let disabled = true;
                if (index === 0 || !!selectedCompanies[index - 1]) {
                  disabled = false;
                }
                return (
                  <div className={"competitorDropdown"} key={key}>
                    <OldDropdown
                      label={`Competitor ${(index + 1).toString()}`}
                      value={company}
                      onChange={selection => {
                        let newSelections = selectedCompanies;
                        newSelections[index] = selection;
                        if (selection === "") {
                          newSelections.splice(index, 1);
                          newSelections.push("");
                        }
                        setState({
                          selectedCompanies: newSelections,
                        });
                      }}
                      options={competitiveCompanyOptions}
                      disabled={disabled}
                    />
                  </div>
                );
              })}
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<CompetitiveLandscapeSlideData> => {
    const { selectedCompanies: companies, dates } = state as CompetitiveLandscapeSlideState;
    const start = computeResolvedDate(dates.start);
    const end = computeResolvedDate(dates.end);
    const selectedCompanies = R.filter(elem => !!elem, companies);
    let competitiveMap = {};
    if (selectedCompanies.length === 0) {
      throw new Error("No companies selected for competitive landscape slide.");
    }

    for (let company of selectedCompanies) {
      const filename = "rawCostPers";
      let competitiveS3Fetch = await getS3Data(filename, company);
      const competitiveChartData = formatS3Data(competitiveS3Fetch, new Date(start), new Date(end));
      if (R.isNil(competitiveChartData) || competitiveChartData.length < 2) {
        throw new Error(
          `No data available for ${company} for the given date range: ${start}-${end}`
        );
      }

      const sandbox = await claimSandbox();
      const {
        allWeeksTrend,
        estimatedSpend,
        numWeeks,
        previousWeekTrend,
        svgString,
      }: Omit<SlideCompetitiveChartResult, "elem"> & { svgString: string } = await new Promise(
        (resolve, reject) => {
          try {
            const load = () => {
              ReactDOM.render(
                <Provider store={reduxStore}>
                  <SlideCompetitiveChart
                    competitiveChartData={competitiveChartData.slice(0, -1)}
                    onLoad={({
                      elem,
                      previousWeekTrend,
                      allWeeksTrend,
                      estimatedSpend,
                      isNoData,
                      numWeeks,
                    }) => {
                      if (isNoData) {
                        reject(
                          new Error(
                            `No data available for ${company} for the given date range: ${start}-${end}`
                          )
                        );
                      }
                      if (elem) {
                        let boundingBox = elem.getBoundingClientRect();
                        let svgString = convertSVGStringToPNGURI(
                          elem.outerHTML,
                          boundingBox.width,
                          boundingBox.height
                        );
                        if (sandbox.element) {
                          ReactDOM.unmountComponentAtNode(sandbox.element);
                        }
                        resolve({
                          svgString,
                          previousWeekTrend,
                          allWeeksTrend,
                          estimatedSpend,
                          numWeeks,
                        });
                      }
                    }}
                  />
                </Provider>,
                sandbox.element
              );
            };
            load();
          } catch (e) {
            reject(e);
          }
        }
      );

      releaseSandbox(sandbox);

      const chartURL = await addS3Image(svgString);
      competitiveMap[company] = {
        allWeeksTrend,
        chartURL,
        estimatedSpend,
        numWeeks,
        previousWeekTrend,
      };
    }

    return {
      competitiveMap,
      end,
      start,
    };
  };
}

export default CompetitiveLandscapeSlide;
