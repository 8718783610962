import React from "react";
import { SlideState } from "./slideTemplateConstants";
import { SlideType, SlideContext, SettingsComponentProps } from "./slidesTypes";
import { Form } from "react-bootstrap";

import { Dropdown, DropdownToggleType } from "../Components";
import "./PaidMediaBreakdown.scss";

export interface OpportunityBannerSlideState {
  title?: string;
  subTitle?: string;
  banners?: string;
}

type OpportunityBannerSlideData = OpportunityBannerSlideState;

class OpportunityBannerSlide extends SlideType {
  static typeKey = "OpportunityBanner";
  static displayKey = "OpportunityBanner";
  static defaultState: OpportunityBannerSlideState = {
    title: "Q1 2023 Opportunities",
    subTitle: "QBP - Q4 2022 - Oct 1 - Dec 31",
    banners: "Three",
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<OpportunityBannerSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { title, subTitle, banners } = state;
    const bannerDropdownOptions: {
      value: string;
      label: string;
    }[] = [
      { value: "Three", label: "Three" },
      { value: "Four", label: "Four" },
      { value: "Five", label: "Five" },
    ];

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="paidMediaBreakdownInput">
          <div>
            <Form.Label>Number of Banners</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={banners || ""}
              options={bannerDropdownOptions}
              onChange={change => setState({ banners: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = (context: SlideContext, state: SlideState): Promise<OpportunityBannerSlideData> => {
    const { title, subTitle, banners } = state as OpportunityBannerSlideData;

    return Promise.resolve({
      title,
      subTitle,
      banners,
    });
  };
}
export default OpportunityBannerSlide;
