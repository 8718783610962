import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DIMENSION_COLUMN_METADATA_MAP as LINEAR_DIMENSION_COLUMN_METADATA_MAP } from "../LinearPerformance/linearPerformanceUtils";
import { DIMENSION_COLUMN_METADATA_MAP as STREAMING_DIMENSION_COLUMN_METADATA_MAP } from "../StreamingPerformance/streamingPerformanceUtils";
import { DIMENSION_COLUMN_METADATA_MAP as YOUTUBE_DIMENSION_COLUMN_METADATA_MAP } from "../YouTubePerformance/youtubePerformanceUtils";
import * as R from "ramda";

import cn from "classnames";

import * as uuid from "uuid";

import {
  Preset,
  PresetChanges,
  PerformanceDimensionColumn as DimensionColumn,
  SortInfo,
  Column,
  ColumnHeader,
} from "@blisspointmedia/bpm-types/dist/Performance";

import { StateSetter } from "../../utils/types";

import { useSetAreYouSure } from "../../redux/modals";
import { DimensionColumnMetaData, adjustSupersAfterRemoval } from "../performanceUtils";
import { MoveColumn, DeleteColumn, HeaderInfo } from "./configUtils";

import { getSeriesColor } from "../../utils/colors";
import ConfigColumn from "./ConfigColumn";
import ConfigDimensionColumn from "./ConfigDimensionColumn";
import SuperHeader from "./SuperHeader";
import SortingBox from "./SortingBox";

import "./PerformanceGridConfig.scss";

// NOTE: change the constants in the CSS if you change these
const CONFIG_COLUMN_WIDTH = 220 as const;
const CONFIG_COLUMN_DIVIDER_WIDTH = 10 as const;
const CONFIG_COLUMN_GUTTER = 16 as const;

interface PerformanceGridConfigProps<P extends Preset> {
  preset: P;
  presetChanges: PresetChanges<P>;
  setPresetChanges: StateSetter<PresetChanges<P>>;
  isLinear?: boolean;
  isYoutube?: boolean;
  isInternal?: boolean;
}

const PerformanceGridConfig = <P extends Preset>({
  preset,
  presetChanges,
  setPresetChanges,
  isLinear,
  isYoutube,
  isInternal,
}: PerformanceGridConfigProps<P>): JSX.Element => {
  const setAreYouSure = useSetAreYouSure();

  const [colorMap, setColorMap] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    setColorMap(colorMap => {
      let newColorMap = { ...colorMap };
      let count = R.keys(newColorMap).length;
      for (let cols of [presetChanges.performanceDimensionColumns, presetChanges.columns]) {
        for (let col of cols) {
          if (!newColorMap[col.id]) {
            newColorMap[col.id] = getSeriesColor(count++);
          }
        }
      }
      return newColorMap;
    });
  }, [preset, presetChanges]);

  const moveColumn = useCallback<MoveColumn>(
    (sourceId, destinationId) => {
      let sourceI = -1;
      let destinationI = -1;
      for (let i = 0; i < presetChanges.columns.length; ++i) {
        let col = presetChanges.columns[i];
        if (col.id === sourceId) {
          sourceI = i;
        }
        if (col.id === destinationId) {
          destinationI = i;
        }
      }

      if (sourceI !== -1 && destinationI !== -1) {
        let reordered = R.move(sourceI, destinationI, presetChanges.columns);
        setPresetChanges(current => ({
          ...current,
          columns: reordered,
          headers: R.reduce(
            (headers, header) => {
              // If it's a one-column-wide header and that column is the one we're moving, delete
              // the header
              if (header.start === header.end && header.start === sourceI) {
                return headers;
              }

              // S = Source, D = Destination, Res = Result
              // U = Under, B = Before, A = After, N = Nothing
              // S/E +/- 1 = Adjust start/end position
              //  S | D | Res           | Notes
              // ---+---+---------------+-----------------------------------------------------------
              //  U | U | N             | Swapping under the same header doesn't do anything
              //  U | B | S + 1         | Under to before shrinks S, but E relative pos is the same
              //  U | A | E - 1         | Like above, but S stays and E shrinks
              //  B | U | S - 1         | Taking from before to under grows S, but E stays
              //  B | B | N             | Swapping two things before cancels out
              //  B | A | S - 1, E - 1  | Before to after shifts the header to the left
              //  A | U | E + 1         | Like B | O but S stays the same and E grows
              //  A | B | S + 1, E + 1  | Like B | A but it shifts to the right
              //  A | A | N             | Anything happening after has no effect on positions

              const sU = header.start <= sourceI && header.end >= sourceI;
              const dU = header.start <= destinationI && header.end >= destinationI;
              const sB = header.start > sourceI;
              const dB = header.start > destinationI;
              const sA = header.end < sourceI;
              const dA = header.end < destinationI;
              if ((sU && dU) || (sB && dB) || (sA && dA)) {
                return [...headers, header];
              }
              let newHeader = {
                ...header,
              };
              if (sA) {
                newHeader.end += 1;
              }
              if (sB) {
                newHeader.start -= 1;
              }
              if (dB) {
                newHeader.start += 1;
              }
              if (dA) {
                newHeader.end -= 1;
              }
              return [...headers, newHeader];
            },
            [] as typeof current.headers,
            current.headers
          ),
        }));
      }
    },
    [presetChanges, setPresetChanges]
  );

  const deleteColumn = useCallback<DeleteColumn>(
    async (index: number) => {
      try {
        await setAreYouSure({
          title: "Delete Column?",
          message: "Are you sure you want to delete this column?",
          okayText: "Delete",
          cancelText: "Never mind",
        });

        setPresetChanges(current => {
          let columns: Column[] = [];
          for (let i = 0; i < current.columns.length; ++i) {
            if (i !== index) {
              columns.push(current.columns[i]);
            }
          }
          let defaultSorting: SortInfo[] | undefined;
          if (current.defaultSorting) {
            let { id } = current.columns[index];
            let newDefaultSorting: SortInfo[] = [];
            for (let sortCol of current.defaultSorting as SortInfo[]) {
              if (sortCol.id !== id) {
                newDefaultSorting.push(sortCol);
              }
            }
            if (newDefaultSorting.length) {
              defaultSorting = newDefaultSorting;
            }
          }
          return {
            ...current,
            columns,
            defaultSorting,
            headers: adjustSupersAfterRemoval(current.headers, index),
          };
        });
      } catch (e) {}
    },
    [setAreYouSure, setPresetChanges]
  );

  const addColumn = useCallback(
    (i: number) => {
      const newColumnType = isLinear || isYoutube ? "impressions" : "imps";
      setPresetChanges(current => ({
        ...current,
        columns: R.insert(
          i,
          {
            id: uuid.v4(),
            type: newColumnType,
          },
          current.columns
        ),
        headers: current.headers.map(header => {
          if (i <= header.start) {
            return {
              ...header,
              start: header.start + 1,
              end: header.end + 1,
            };
          }
          if (i <= header.end) {
            return {
              ...header,
              end: header.end + 1,
            };
          }
          return header;
        }),
      }));
    },
    [isYoutube, isLinear, setPresetChanges]
  );

  const headerInfoMap = useMemo(() => {
    let map: Record<string, HeaderInfo | true> = {};

    for (let headerIndex = 0; headerIndex < presetChanges.headers.length; ++headerIndex) {
      let header = presetChanges.headers[headerIndex];
      // Take gutter out so half gutter on either side is removed
      let width = -CONFIG_COLUMN_GUTTER;
      for (let i = header.start; i <= header.end; ++i) {
        map[`${i}`] = true;
        width += CONFIG_COLUMN_WIDTH + CONFIG_COLUMN_GUTTER;
        if (presetChanges.columns[i].divider) {
          width += CONFIG_COLUMN_DIVIDER_WIDTH;
        }
      }
      map[`${header.start}`] = {
        text: header.text,
        width,
        headerIndex,
      };
    }
    return map;
  }, [presetChanges]);

  const onHeaderChange = useCallback<StateSetter<ColumnHeader[]>>(
    change => {
      setPresetChanges(current => ({
        ...current,
        headers: typeof change === "function" ? change(current.headers) : change,
      }));
    },
    [setPresetChanges]
  );

  const deleteDimensionColumn = useCallback<DeleteColumn>(
    async (index: number) => {
      try {
        await setAreYouSure({
          title: "Delete Column?",
          message: "Are you sure you want to delete this column?",
          okayText: "Delete",
          cancelText: "Never mind",
        });
        setPresetChanges(current => {
          let performanceDimensionColumns: DimensionColumn[] = [];
          for (let i = 0; i < current.performanceDimensionColumns.length; ++i) {
            if (i !== index) {
              performanceDimensionColumns.push(current.performanceDimensionColumns[i]);
            }
          }
          let defaultSorting: SortInfo[] | undefined;
          if (current.defaultSorting) {
            let { id } = current.performanceDimensionColumns[index];
            let newDefaultSorting: SortInfo[] = [];
            for (let sortCol of current.defaultSorting as SortInfo[]) {
              if (sortCol.id !== id) {
                newDefaultSorting.push(sortCol);
              }
            }
            if (newDefaultSorting.length) {
              defaultSorting = newDefaultSorting;
            }
          }
          return {
            ...current,
            performanceDimensionColumns,
            defaultSorting,
          };
        });
      } catch (e) {}
    },
    [setAreYouSure, setPresetChanges]
  );

  const moveDimensionColumn = useCallback<MoveColumn>(
    (sourceId, destinationId) => {
      let sourceI = -1;
      let destinationI = -1;
      for (let i = 0; i < presetChanges.performanceDimensionColumns.length; ++i) {
        let col = presetChanges.performanceDimensionColumns[i];
        if (col.id === sourceId) {
          sourceI = i;
        }
        if (col.id === destinationId) {
          destinationI = i;
        }
      }

      if (sourceI !== -1 && destinationI !== -1) {
        let reordered = R.move(sourceI, destinationI, presetChanges.performanceDimensionColumns);
        setPresetChanges(current => ({
          ...current,
          performanceDimensionColumns: reordered,
        }));
      }
    },
    [presetChanges, setPresetChanges]
  );

  const dimensionColumnMetaDataMap: Record<string, DimensionColumnMetaData> = useMemo(() => {
    return isYoutube
      ? YOUTUBE_DIMENSION_COLUMN_METADATA_MAP
      : isLinear
      ? LINEAR_DIMENSION_COLUMN_METADATA_MAP
      : STREAMING_DIMENSION_COLUMN_METADATA_MAP;
  }, [isLinear, isYoutube]);

  const addDimensionColumn = useCallback(
    (i: number) => {
      setPresetChanges(current => ({
        ...current,
        performanceDimensionColumns: R.insert(
          i,
          R.isEmpty(dimensionColumnMetaDataMap) || R.isNil(dimensionColumnMetaDataMap)
            ? {
                id: uuid.v4(),
                dimension: "Network",
                type: "Network Logo",
              }
            : {
                id: uuid.v4(),
                dimension:
                  dimensionColumnMetaDataMap[R.keys(dimensionColumnMetaDataMap)[0]].dimension,
                type: R.keys(dimensionColumnMetaDataMap)[0] as any,
              },

          current.performanceDimensionColumns
        ),
      }));
    },
    [dimensionColumnMetaDataMap, setPresetChanges]
  );

  const addSuperHeader = useCallback(
    (columnI: number) => {
      onHeaderChange(current => {
        let ourHeader = {
          text: "",
          start: columnI,
          end: columnI,
        };

        if (!current.length) {
          return [ourHeader];
        }
        const headers: typeof current = [];
        let inserted = false;
        for (let header of current) {
          let prevHeader = headers[headers.length - 1];
          // If there was a last header, and it was before this one, and the next one is after, then
          // we insert here. Similarly, if there was no previous (meaning this is the first) and
          // this one is after where we're inserting, we add it.
          if (
            (prevHeader && prevHeader.end < columnI && header.start > columnI) ||
            (!prevHeader && header.start > columnI)
          ) {
            headers.push(ourHeader);
            inserted = true;
          }
          headers.push(header);
        }
        if (!inserted) {
          headers.push(ourHeader);
        }
        return headers;
      });
    },
    [onHeaderChange]
  );

  const hasHeaderMap = useMemo(() => {
    let hasHeaderList: boolean[] = [];
    let headerList = [...presetChanges.headers];
    let colIndex = 0;
    while (colIndex < presetChanges.columns.length) {
      let firstHeader = headerList[0];
      if (!firstHeader || firstHeader.start > colIndex) {
        hasHeaderList.push(false);
        colIndex++;
      } else if (firstHeader.end < colIndex) {
        headerList.shift();
      } else {
        hasHeaderList.push(true);
        colIndex++;
      }
    }
    return hasHeaderList;
  }, [presetChanges]);

  const [hoverItem, setHoverItem] = useState<string>();

  return (
    <div className="configBox">
      <SortingBox
        presetChanges={presetChanges}
        setPresetChanges={setPresetChanges}
        setHoverItem={setHoverItem}
        colorMap={colorMap}
      />
      <div className={cn("configBoxInner", { hasHeaders: !!presetChanges.headers.length })}>
        {presetChanges.performanceDimensionColumns.map((column, i) => (
          <React.Fragment key={column.id}>
            <ConfigDimensionColumn
              i={i}
              column={column}
              setPresetChanges={setPresetChanges}
              moveColumn={moveDimensionColumn}
              deleteColumn={deleteDimensionColumn}
              isLast={presetChanges.performanceDimensionColumns.length <= 1}
              color={colorMap[column.id]}
              addColumn={addDimensionColumn}
              hoverItem={hoverItem}
              isLinear={isLinear}
              isYoutube={isYoutube}
            />
          </React.Fragment>
        ))}
        <div className="divider" />
        {presetChanges.columns.map((column, i) => (
          <React.Fragment key={column.id}>
            <SuperHeader
              headers={presetChanges.headers}
              info={headerInfoMap}
              columnI={i}
              onHeaderChange={onHeaderChange}
              colCount={presetChanges.columns.length}
            />
            <ConfigColumn
              i={i}
              column={column}
              setPresetChanges={setPresetChanges}
              moveColumn={moveColumn}
              deleteColumn={deleteColumn}
              isLast={presetChanges.columns.length <= 1}
              color={colorMap[column.id]}
              hasSuperHeader={hasHeaderMap[i]}
              addColumn={addColumn}
              onSuperHeaderAdd={() => addSuperHeader(i)}
              hoverItem={hoverItem}
              isLinear={isLinear}
              isYoutube={isYoutube}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PerformanceGridConfig;
