import React from "react";
import * as R from "ramda";
import { Modal, Button } from "react-bootstrap";
import { BPMButton } from "../Components";
import { FormattedOrderRow } from "./LinearOrders";
import { TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import "./OrderHistoryModal.scss";

interface AddNewNetworkModalProps {
  orderInfo: FormattedOrderRow;
  downloadPDF: (id: any, path: any, isV1: any) => Promise<void>;
  onClose: () => void;
}

// TODO: The below functions also live in bpm-lambdas. Now that I'm using here too, should move them to bpm-types
/**
 * Returns the order version, based on 1) whether it's order or traffic and 2) its revision number.
 */
const getOrderVersion = (orderInfo: FormattedOrderRow, revisionNumber: number) => {
  const { is_traffic } = orderInfo;
  if (is_traffic) {
    return revisionNumber === 1 ? "New Traffic Instructions" : "Revised Traffic Instructions";
  } else {
    return revisionNumber === 1 ? "New Order" : "Revised Order";
  }
};

/**
 * Creates the S3 path for the order PDF.
 *
 * i.e. "production/123/123-1_ABC_N_New Order_Regular.pdf"
 */
const makeS3Path = (orderInfo: FormattedOrderRow, revisionNumber: number) => {
  const { id, network, avail, type } = orderInfo;
  const orderVersion = getOrderVersion(orderInfo, revisionNumber);
  const orderType = TYPES_TO_NAMES[`${type}`];

  const path = `production/${id}/${id}-${revisionNumber}_${network}_${avail}_${orderVersion}_${orderType}.pdf`;
  return path;
};

/**
 * Creates the S3 path for orders that were made on the old buying page.
 *
 * i.e. "production/123/456-1_ABC_N_New Order_Regular.pdf"
 */
const makeS3PathV1 = (orderInfo: FormattedOrderRow, revisionNumber: number) => {
  const { id, order_id, network, avail, type, is_traffic } = orderInfo;
  const orderVersion = getOrderVersion(orderInfo, revisionNumber);
  const orderType = is_traffic ? "Traffic" : type === 1 ? "Precommitted" : "Regular";

  const path = `production/${id}/${order_id}-${revisionNumber}_${network}_${avail}_${orderVersion}_${orderType}.pdf`;
  return path;
};

/**
 * Creates the S3 path for the order PDF.
 *
 * i.e. "production/123/123-1_ABC_N_New Order_Regular.pdf"
 */
const makeConfirmedS3Path = (orderInfo: FormattedOrderRow, revisionNumber: number) => {
  const { id, network, avail } = orderInfo;
  const path = `production/${id}/${id}-${revisionNumber}_${network}_${avail}_Approved.pdf`;
  return path;
};

const OrderHistoryModal: React.FC<AddNewNetworkModalProps> = ({
  orderInfo,
  downloadPDF,
  onClose,
}) => {
  const { id, isV1, revision, is_traffic } = orderInfo;
  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>PDF Revision History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="orderList">
          <div>Sent</div>
          {R.range(1, revision + 1).map(revisionNumber => {
            const currentOrderNumber = `${id}-${revisionNumber}`;
            const currentS3Path = isV1
              ? makeS3PathV1(orderInfo, revisionNumber)
              : makeS3Path(orderInfo, revisionNumber);
            return (
              <Button className="pdfLink" onClick={() => downloadPDF(id, currentS3Path, isV1)}>
                {currentOrderNumber}
              </Button>
            );
          })}
          {!is_traffic && (
            <>
              <div>Confirmed</div>
              {R.range(1, revision + 1).map(revisionNumber => {
                const currentOrderNumber = `${id}-${revisionNumber}`;
                const currentS3Path = makeConfirmedS3Path(orderInfo, revisionNumber);
                return (
                  <Button className="pdfLink" onClick={() => downloadPDF(id, currentS3Path, true)}>
                    {currentOrderNumber}
                  </Button>
                );
              })}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="outline-primary" onClick={onClose}>
          Cancel
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderHistoryModal;
