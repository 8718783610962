import React, { useContext, useEffect, useState } from "react";
import { Modal, Col, Form, InputGroup } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../Components";
import { MdWarning } from "react-icons/md";
import { WtoLambdaFetch } from "../utils/fetch-utils";
import { FormContext, formObjDefault } from "./WtoFormContext";
import { formValidator } from "./WtoFormValidator";
import { useSetError } from "../redux/modals";
import {
  trimBackslashes,
  updateParamFunnelStep,
  updateParamTestName,
  updateParamVersionNumber,
  formatRules,
  formatDefaultId,
} from "./WtoUtils";
import * as R from "ramda";
import "./WtoDuplicateModal.scss";
import { Plover } from "@blisspointmedia/bpm-types/dist/webTrafficOptimization";

export interface WtoDuplicateModalProps {
  show: boolean;
  closeModal: () => void;
  data: Plover;
  userEmail: string;
  companyId: string;
  fetchPlovers: () => void;
}

export const WtoDuplicateModal: React.FC<WtoDuplicateModalProps> = ({
  show,
  closeModal,
  data,
  userEmail,
  companyId,
  fetchPlovers,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { wtoFormObj, setWtoFormObj } = useContext(FormContext);

  const setError = useSetError();

  useEffect(() => {
    if (show) {
      setWtoFormObj(() => {
        return {
          company: companyId,
          created_by: userEmail,
          default_id: data.default_id,
          default_url: data.default_url,
          domain: data.domain,
          folder_path: data.folder_path,
          notes: data.notes,
          plover_url: data.plover_url,
          rules: data.rules,
          status: "DRAFT",
          test_name: "",
          utm_test_id: "",
          variants: data.variants,
        };
      });
    }
  }, [show, data, setWtoFormObj, userEmail, companyId]);

  const cancelDuplication = () => {
    setWtoFormObj({ ...formObjDefault });
    closeModal();
  };

  const confirmDuplication = async () => {
    setLoading(true);
    try {
      await WtoLambdaFetch("/plover", {
        method: "POST",
        body: JSON.stringify({
          ...wtoFormObj,
          default_id: formatDefaultId(wtoFormObj.default_id),
          rules: formatRules(wtoFormObj.rules, true),
          folder_path: trimBackslashes(wtoFormObj.folder_path),
        }),
      });
      fetchPlovers();
      closeModal();
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to duplicate plover. Error: ${error.message}`,
        reportError: error,
      });
    }
    setLoading(false);
  };

  const validateForm = () => {
    const errors = formValidator(wtoFormObj, ["test_name", "utm_test_id"], {
      isBulkPlover: false,
    });
    if (errors.length > 0) {
      setError({ title: "Validation Error(s)", message: `• ${R.join("\n• ", errors)}` });
    } else {
      confirmDuplication();
    }
  };

  const updateFormField = (key, value) =>
    setWtoFormObj((current: any) => {
      return { ...current, [key]: value };
    });

  return (
    <Modal
      show={show}
      onHide={() => (loading ? null : cancelDuplication())}
      className="wtoDuplicateModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Duplicate Route</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner size={50} />
        ) : (
          <>
            <div className="infoBox warning">
              <div className="icon">
                <MdWarning color="orange" className="alertIcon" />
              </div>
              <p className="message">
                Before duplicating the test <span>{data?.test_name}</span>, please enter unique
                values for the fields below:
              </p>
            </div>
            <Form className="form">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Route Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Route Name. e.g. Quiz CTA Test"
                    onChange={e => updateFormField("test_name", e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Route Tracking URL Parameter</Form.Label>
                  <p className="helperText">
                    Former Client Example: utm_home_exp=home4us
                    <br />
                    <br />
                    Live Preview:{" "}
                  </p>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Funnel Step"
                      onChange={e =>
                        updateFormField(
                          "utm_test_id",
                          updateParamFunnelStep(e.target.value, wtoFormObj.utm_test_id)
                        )
                      }
                    />
                    <Form.Control
                      type="text"
                      placeholder="Test Name"
                      onChange={e =>
                        updateFormField(
                          "utm_test_id",
                          updateParamTestName(e.target.value, wtoFormObj.utm_test_id)
                        )
                      }
                    />
                    <Form.Control
                      type="number"
                      placeholder="Version"
                      onChange={e =>
                        updateFormField(
                          "utm_test_id",
                          updateParamVersionNumber(Number(e.target.value), wtoFormObj.utm_test_id)
                        )
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={cancelDuplication}>
          Cancel
        </Button>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={validateForm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WtoDuplicateModal;
