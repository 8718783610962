import React, { useCallback, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { BPMButton, OldFilterBar, SuggestionInput } from ".";
import { STANDARD_GROUP_NAME, ALL_GROUP_NAME } from "../Performance/performanceUtils";

import "./GridPickerModal.scss";
import { Spinner } from "./Spinner";

export interface GridPickerOption<KeyType extends string = string> {
  key: KeyType;
  // TODO: it would be cool to let them include a custom node, such as an image or something
  // thumbnail: string | React.ReactNode;
  thumbnail: string;
  caption?: string;
}
interface GridPickerModalProps<KeyType extends string = string> {
  name: string;
  setName: (name: string) => void;
  groups?: string[];
  setGroups?: (groups: string[]) => void;
  options: GridPickerOption<KeyType>[];
  selectedOption: KeyType | "";
  selectOption: (optionKey: KeyType) => void;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  invalid?: boolean;
  saving?: boolean;
  title?: string;
  bodyText?: string;
  nameFieldLabel?: string;
  groupFieldLabel?: string;
  createGroupFieldLabel?: string;
  submitButtonText?: string;
  groupOptions?: string[];
  hasGroups?: boolean;
}

const FILTER_OPTIONS = [
  {
    name: "thumbnail",
    label: "Name",
  },
  {
    name: "caption",
    label: "Caption",
  },
];

export const GridPickerModal = <KeyType extends string = string>({
  name,
  setName,
  groups = [],
  setGroups = function (groups: string[]): void {
    throw new Error("Function not implemented.");
  },
  options,
  selectedOption,
  selectOption,
  onClose,
  onSubmit,
  loading,
  invalid,
  saving,
  title = "Pick",
  bodyText,
  nameFieldLabel = "Name",
  groupFieldLabel = "Group",
  submitButtonText = "Submit",
  groupOptions = [],
  hasGroups,
}: GridPickerModalProps<KeyType>): JSX.Element => {
  const [filteredLines, setFilteredLines] = useState(options);
  const onFilter = useCallback(
    (filter: (option: GridPickerOption) => boolean) => {
      setFilteredLines(options.filter(filter));
    },
    [options]
  );

  return (
    <Modal size="lg" show onHide={onClose} className="BPMGridPickerModal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bodyText && <p className="bodyText">{bodyText}</p>}
        <OldFilterBar<GridPickerOption>
          options={FILTER_OPTIONS}
          lines={filteredLines}
          onFilter={onFilter}
        />
        <div className="gridPicker">
          {filteredLines.map(({ key, thumbnail, caption }) => (
            <div
              className={`option${key === selectedOption ? " selected" : ""}`}
              key={key}
              onClick={() => selectOption(key)}
            >
              <div className="thumbnail">{thumbnail}</div>
              {caption && <div className="caption">{caption}</div>}
            </div>
          ))}
        </div>
        <Form.Group>
          <Form.Label>{nameFieldLabel}</Form.Label>
          <Form.Control
            disabled={loading}
            value={name}
            placeholder="Name..."
            onChange={e => setName(e.currentTarget.value)}
          />
          {hasGroups && (
            <div className="gridPickerSection">
              <Form.Label className="gridPickerSectionElements">{groupFieldLabel}</Form.Label>
              <SuggestionInput
                placeholder="Select Group..."
                value={groups.length ? groups[0] : ""}
                options={groupOptions.map(groupOption => ({
                  value: groupOption,
                  label: groupOption,
                }))}
                formatCreateLabel={value => `New group: ${value}`}
                nonEmpty
                isClearable
                maxMenuHeight={250}
                onChange={option => {
                  if (option !== STANDARD_GROUP_NAME && option !== ALL_GROUP_NAME) {
                    setGroups([option]);
                  }
                }}
              />
            </div>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="outline-primary" disabled={loading} onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton
          className="saveButton"
          disabled={loading || invalid || saving || !selectedOption || !name}
          onClick={onSubmit}
        >
          {submitButtonText}
          {saving && <Spinner color="white" />}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};
