import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";
import { useSetError } from "../redux/modals";
import { awaitJSON, LinearBuyingLambdaFetch } from "../utils/fetch-utils";
import { Page, Spinner, Skeleton, TableSkeleton, ModalEditTable } from "../Components";
import "./LinearCanonicalNetworks.scss";
import { LinearCanonicalNetworkEditedRow } from "@blisspointmedia/bpm-types/dist/LinearAssignments";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

const headers = [
  {
    label: "Network Group",
    field: "Network Group",
    modalRow: 0,
    modalWidth: 200,
  },
  {
    label: "Short Code",
    field: "Short Code",
    modalRow: 0,
    modalWidth: 200,
  },
  {
    label: "Station Name",
    field: "Station Name",
    modalRow: 1,
    modalWidth: 200,
  },
  {
    label: "Channel",
    field: "channel",
    type: "select",
    options: "channel",
    modalRow: 1,
    modalWidth: 200,
  },
  {
    label: "Feed",
    field: "Feed",
    type: "select",
    options: "feed",
    modalRow: 2,
    modalWidth: 200,
  },
  {
    label: "Language",
    field: "Language",
    type: "select",
    options: "language",
    modalRow: 2,
    modalWidth: 200,
  },
  {
    label: "SD Only",
    field: "SD Only",
    type: "checkbox",
    modalRow: 3,
    modalWidth: 200,
  },
  {
    label: "SD Companion",
    field: "SD Companion",
    type: "checkbox",
    modalRow: 3,
    modalWidth: 200,
  },
  {
    label: "S&P Clearance",
    field: "S&P Clearance",
    type: "checkbox",
    modalRow: 3,
    modalWidth: 200,
  },
  {
    label: "Traffic Endpoint",
    field: "Traffic Endpoint",
    modalRow: 4,
    modalWidth: 200,
  },
  {
    label: "XR Destination",
    field: "XR Destination",
    modalRow: 4,
    modalWidth: 200,
  },
  {
    label: "HH UEs",
    field: "HH UEs",
    modalRow: 5,
    modalWidth: 200,
  },
  {
    label: "UEs Likely to Mislead",
    field: "UEs Likely to Mislead",
    modalRow: 5,
    modalWidth: 200,
  },
  {
    label: "Media Tier",
    field: "Media Tier",
    type: "select",
    options: "mediaTier",
    modalRow: 6,
    modalWidth: 200,
  },
  {
    label: "Genre",
    field: "Genre",
    modalRow: 6,
    modalWidth: 200,
  },
  {
    label: "Color",
    field: "Color",
    modalRow: 7,
    modalWidth: 200,
  },
  {
    label: "Network Group Color",
    field: "Network Group Color",
    modalRow: 7,
    modalWidth: 200,
  },
  {
    label: "Destination ID",
    field: "destination_id",
    modalRow: 8,
    modalWidth: 200,
  },
];

const selectorOptions: {} = {
  mediaTier: [
    { value: "Lower Tier", label: "Lower Tier" },
    { value: "Mid Tier", label: "Mid Tier" },
    { value: "Upper Tier", label: "Upper Tier" },
  ],
  feed: [
    { value: "Single", label: "Single" },
    { value: "Dual", label: "Dual" },
    { value: "Triple", label: "Triple" },
    { value: "Quad", label: "Quad" },
  ],
  language: [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
    { value: "Bilingual", label: "Bilingual" },
    { value: "", label: "Leave blank" },
  ],
  channel: [
    { value: "Linear", label: "Linear" },
    { value: "Radio", label: "Radio" },
  ],
};

const LinearCanonicalNetworks: React.FC = () => {
  const setError = useSetError();
  const [tableData, setTableData] = useState<LinearCanonicalNetworkEditedRow[]>();
  const [saving, setSaving] = useState(false);
  const enableMarketChannelColumns = useExperimentFlag("enableMarketChannelColumns");

  useEffect(() => {
    if (!tableData) {
      (async () => {
        let tableData;
        try {
          tableData = await LinearBuyingLambdaFetch("/linear_canonical_networks");
          tableData = await awaitJSON(tableData);
          setTableData(
            enableMarketChannelColumns
              ? tableData
              : tableData.filter(row => row.channel !== "Radio")
          );
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get linear networks data ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [enableMarketChannelColumns, setError, tableData]);

  const postBody = useMemo(() => {
    const valuesAreSet = (row: LinearCanonicalNetworkEditedRow) => {
      return enableMarketChannelColumns
        ? !R.isNil(row["Short Code"]) &&
            !R.isNil(row["Station Name"]) &&
            !R.isNil(row["Media Tier"]) &&
            !R.isNil(row.Feed) &&
            !R.isNil(row.lastmodified) &&
            !R.isNil(row.channel)
        : !R.isNil(row["Short Code"]) &&
            !R.isNil(row["Station Name"]) &&
            !R.isNil(row["Media Tier"]) &&
            !R.isNil(row.Feed) &&
            !R.isNil(row.lastmodified);
    };

    if (tableData) {
      return R.filter(valuesAreSet, tableData);
    }
  }, [enableMarketChannelColumns, tableData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await LinearBuyingLambdaFetch("/linear_canonical_networks", {
        method: "POST",
        body: postBody,
      });
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      setError({ message: `Failed to save linear networks data: ${reportError.message}` });
    }
  }, [setError, postBody]);

  return (
    <Page
      title="Linear Canonical Networks"
      pageType="Linear Canonical Networks"
      minHeight="600px"
      actions={
        <div className="linearCanonicalNetworksActions">
          <Button
            variant="primary"
            onClick={save}
            className="saveButton"
            disabled={R.isNil(postBody) || !postBody.length || saving}
          >
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      <div className="linearCanonicalNetworksPageContainer">
        {tableData ? (
          <ModalEditTable
            className="linearCanonicalNetworksTable"
            name="Linear Networks"
            headers={
              enableMarketChannelColumns
                ? headers
                : headers.filter(header => header.field !== "channel")
            }
            tableData={tableData || []}
            setTableData={setTableData}
            selectorOptions={selectorOptions}
            filterBar
            rowHeight={50}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default LinearCanonicalNetworks;
