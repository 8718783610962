import { awaitJSON, FiltersLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { computeTotals, fetchLinearDelivery, useOtherMap } from "../LinearDelivery/LinearDelivery";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { CreativeMap, useCreativeMap } from "../redux/creative";
import { DailyDeliveryData, useColorMap } from "../StreamingDelivery/StreamingDelivery";
import { DateRange } from "../utils/types";
import {
  DropdownOption,
  OldDropdown as Dropdown,
  RelativeDatePicker,
  BPMToggleButton,
} from "../Components";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { Form } from "react-bootstrap";
import { GetFilterPresetsParams } from "@blisspointmedia/bpm-types/dist/FilterPresets";
import { getGlobalBrand } from "../Performance/performanceUtils";
import { getLegendInfo } from "../StreamingDelivery/Legend";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import DeliveryChart from "../StreamingDelivery/DeliveryChart";
import React, { useEffect, useRef, useMemo, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

const DIMENSIONS: Record<string, string> = {
  network: "Network",
  creative: "Creative",
  length: "Length",
  daypart: "Daypart",
  avail: "Avail",
  campaign: "Campaign",
};

interface LegendItem {
  key: string;
  color: string;
  overrideText?: string | undefined;
}

interface SlideDeliveryChartProps {
  absolute: boolean;
  bySpend: boolean;
  data: DailyDeliveryData;
  dimension: string;
  useLogo: boolean;
  onLoad: (deliveryChartRefCurrent: { elem: any; legend: LegendItem[] }) => void;
}

const SlideDeliveryChart: React.FC<SlideDeliveryChartProps> = ({
  absolute,
  bySpend,
  data,
  dimension,
  useLogo,
  onLoad,
}) => {
  const ref = useRef();
  const activeKeyMap = useMemo(() => R.mapObjIndexed(R.always(true), data.keys), [data]);
  const totals = useMemo(() => {
    return computeTotals({ data, activeKeyMap }).totals;
  }, [data, activeKeyMap]);

  const networkColorMap = useColorMap({});
  const defaultColorMap = useColorMap({});

  const otherMap = useOtherMap(data, activeKeyMap, bySpend);

  const colorMap = useMemo(() => {
    let colorMap;
    switch (dimension) {
      case "network":
        colorMap = networkColorMap;
        break;
      default:
        colorMap = defaultColorMap;
        break;
    }
    return colorMap;
  }, [defaultColorMap, dimension, networkColorMap]);

  useLayoutEffect(() => {
    // We have to put this at the bottom of the event queue to wait for recharts to draw it
    // apparently.
    setTimeout(() => {
      let keys = R.keys(activeKeyMap);
      onLoad({
        elem: ref.current,
        legend: R.map(key => {
          const { color, logoSrc } = getLegendInfo({
            key,
            colorMap,
            info: data.keys[key],
            dimension,
          });
          let overrideText;
          if (["avail", "daypart", "length"].includes(dimension) || !useLogo) {
            overrideText = key;
          }
          return { color, overrideText, url: logoSrc, key };
        }, keys),
      });
    }, 100);
  }, [colorMap, onLoad, activeKeyMap, data, dimension, useLogo]);

  return (
    <DeliveryChart
      ref={ref}
      powerpoint
      hasTooltip={false}
      data={data.weekly}
      totals={totals}
      activeKeyMap={activeKeyMap}
      absolute={absolute}
      isSpend={bySpend}
      colorMap={colorMap}
      daily={false}
      dimension={dimension}
      otherMap={otherMap}
    />
  );
};

export interface LinearDeliverySlideState {
  absolute: boolean;
  audience: L.Audience;
  bySpend: boolean;
  creativeMap: CreativeMap | undefined;
  dates: RelativeDateRange;
  dimension: string;
  filterPreset: FilterPreset | null;
  globalBrand: string;
  prefix: string;
  title: string;
  useLogo: boolean;
}

interface LinearDeliverySlideData {
  title: string;
  chartURL: string;
  legend: LegendItem[];
}

export const linDelivSharedStateKey = "linearDelivery" as const;
export interface LinearDeliverySharedState {
  filterPresets: FilterPreset[];
}

export const linDelivSharedFetcher: SharedStateFetcher<LinearDeliverySharedState> = async (
  company: string,
  setError: SetError
) => {
  let res: LinearDeliverySharedState = {
    filterPresets: [],
  };
  try {
    const params = {
      company,
      platform: "linear",
    };
    const filterPresetRes = await FiltersLambdaFetch<GetFilterPresetsParams>("/getFilters", {
      params,
    });
    const parsedFilterPresets = await awaitJSON(filterPresetRes);
    res.filterPresets = parsedFilterPresets;
  } catch (e) {
    let error: Error = e as Error;
    setError({
      message: `Could not get valid linear filter presets. Error: ${error.message}`,
      reportError: error,
    });
  }
  return res;
};

class LinearDeliverySlide extends SlideType {
  static typeKey = "linearDelivery";
  static displayKey = "Linear Delivery";
  static defaultState: LinearDeliverySlideState = {
    absolute: false,
    audience: "HH",
    bySpend: true,
    creativeMap: undefined,
    dates: {
      start: { pivotDate: "monday", adjustment: 6, adjustmentType: "week" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    dimension: "network",
    filterPreset: null,
    globalBrand: "",
    prefix: "linear",
    title: "Linear Delivery & Pacing",
    useLogo: true,
  };

  static SettingsComponent: React.FC<SettingsComponentProps<LinearDeliverySlideState>> = React.memo(
    ({ state, setState, slideContext, sharedState, sharedFetch, id }) => {
      const { company } = slideContext;
      const {
        absolute,
        audience,
        bySpend,
        creativeMap,
        dates,
        dimension,
        filterPreset,
        globalBrand,
        title,
        useLogo,
      } = state;
      const companyInfo = useCompanyInfo();
      const { filterPresets } =
        R.isNil(sharedState) ||
        R.isNil(sharedState[linDelivSharedStateKey]) ||
        R.isNil(sharedState[linDelivSharedStateKey][company])
          ? { filterPresets: [] }
          : sharedState[linDelivSharedStateKey][company];

      useEffect(() => {
        if (!globalBrand) {
          setState({
            globalBrand: R.defaultTo("", getGlobalBrand(companyInfo, companyInfo.initial_kpi)),
          });
        }
      }, [sharedState, sharedFetch, company, globalBrand, setState, companyInfo]);

      const { creativeMap: fetchedCreativeMap } = useCreativeMap({
        company: globalBrand || company,
        mediaTypes: ["linear"],
      });

      useEffect(() => {
        if (!creativeMap && fetchedCreativeMap) {
          setState({
            creativeMap: fetchedCreativeMap,
          });
        }
      }, [
        sharedState,
        sharedFetch,
        company,
        globalBrand,
        setState,
        companyInfo,
        creativeMap,
        fetchedCreativeMap,
      ]);

      useEffect(() => {
        if (!R.path([linDelivSharedStateKey, company], sharedState)) {
          (async () => {
            await sharedFetch(linDelivSharedStateKey, company);
          })();
        }
      }, [sharedState, sharedFetch, company]);

      return (
        <div className="settingsBox">
          <div>
            <Form.Group className="flex">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title}
                onChange={e => setState({ title: e.currentTarget.value })}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group></Form.Group>
            <Form.Group>
              <BPMToggleButton
                options={[
                  {
                    key: "Network Logo",
                  },
                  {
                    key: "Network Name",
                  },
                ]}
                selectedOption={useLogo ? "Network Logo" : "Network Name"}
                onChange={e => {
                  setState({ useLogo: e === "Network Logo" });
                }}
              />
            </Form.Group>
          </div>
          <div className="wrappingColumn">
            <Form.Group>
              <Dropdown
                label={"Filter"}
                value={filterPreset ? filterPreset.id.toString() : "undefined"}
                options={R.map(filterPreset => {
                  return { label: filterPreset.name, value: filterPreset.id.toString() };
                }, R.defaultTo([], filterPresets) as FilterPreset[])}
                onChange={filterID => {
                  let filterPreset: FilterPreset | null = null;
                  for (let preset of R.defaultTo([], filterPresets)) {
                    if (filterID === preset.id.toString()) {
                      filterPreset = preset;
                    }
                  }
                  setState({ filterPreset });
                }}
              />
              <Dropdown
                label="Dimension"
                value={dimension}
                options={R.pipe<Record<string, string>, string[], DropdownOption[]>(
                  R.keys,
                  R.map(value => ({ value, label: DIMENSIONS[value] }))
                )(DIMENSIONS)}
                onChange={val => setState({ dimension: val })}
              />
            </Form.Group>
            <Form.Group>
              <Dropdown
                size="sm"
                label="Audience"
                className="deliveryDropdown"
                value={audience}
                options={L.AUDIENCES}
                onChange={val => setState({ audience: val })}
              />
            </Form.Group>
            <Form.Group>
              <BPMToggleButton
                block
                bordered
                options={[
                  {
                    key: "Relative",
                  },
                  {
                    key: "Absolute",
                  },
                ]}
                selectedOption={absolute ? "Absolute" : "Relative"}
                onChange={key => setState({ absolute: key === "Absolute" })}
              />
            </Form.Group>
            <Form.Group>
              <BPMToggleButton
                block
                bordered
                options={[
                  {
                    key: "spend",
                    label: "By Spend",
                  },
                  {
                    key: "imps",
                    label: "By Impressions",
                  },
                ]}
                selectedOption={bySpend ? "spend" : "imps"}
                onChange={key => setState({ bySpend: key === "spend" })}
              />
            </Form.Group>
          </div>
          <div className="wrappingColumn">
            <Form.Group>
              <Form.Label>Start</Form.Label>
              <RelativeDatePicker
                state={dates.start}
                onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>End</Form.Label>
              <RelativeDatePicker
                state={dates.end}
                onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
              />
            </Form.Group>
          </div>
          <div>
            <small>Note: Dates will be clipped based on the available data.</small>
          </div>
        </div>
      );
    }
  );

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<LinearDeliverySlideData> => {
    const { company } = context;
    const {
      absolute,
      audience,
      bySpend,
      creativeMap,
      dates: relativeDates,
      dimension,
      filterPreset,
      globalBrand,
      prefix,
      title,
      useLogo,
    } = state as LinearDeliverySlideState;
    let start = computeResolvedDate(relativeDates.start);
    let end = Dfns.format(
      DATE_FORMAT,
      Dfns.startOfISOWeek(Dfns.addDays(1, new Date(computeResolvedDate(relativeDates.end))))
    );
    let dates: DateRange = { start, end };

    let data = await fetchLinearDelivery({
      audience,
      company,
      creativeMap,
      dates: dates,
      dimension,
      filterID: filterPreset ? filterPreset.id : undefined,
      filterState: filterPreset
        ? filterPreset.filter_state
        : { advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false },
      globalBrand,
      prefix,
    });

    if (R.isEmpty(data.keys) || R.isEmpty(data.weekly)) {
      throw new Error(
        `We are missing data for the date range: ${start}-${end} with the following settings:
        audience: ${audience}
        company: ${company}
        dimension: ${dimension}
        prefix: ${prefix}
        ${filterPreset ? `filter: ${filterPreset.name}` : ""}
        Please verify on the Streaming Delivery Page that there is data for these settings or reach out to the engineering team!`
      );
    }

    let sandbox = await claimSandbox();
    let { svgString, legend }: { svgString: string; legend: LegendItem[] } = await new Promise(
      resolve => {
        const load = () => {
          console.log("loading delivery chart");
          ReactDOM.render(
            <SlideDeliveryChart
              absolute={absolute}
              data={data}
              dimension={dimension}
              bySpend={bySpend}
              useLogo={useLogo}
              onLoad={({ elem, legend }) => {
                if (elem) {
                  console.log("delivery chart success!");
                  let svg = elem.container.getElementsByTagName("svg")[0];
                  let boundingBox = svg.getBoundingClientRect();
                  let svgString = convertSVGStringToPNGURI(
                    svg.outerHTML,
                    boundingBox.width,
                    boundingBox.height
                  );
                  resolve({ svgString, legend });
                } else {
                  console.log("delivery chart failed, retrying");
                  setTimeout(load, 100);
                }
              }}
            />,
            sandbox.element
          );
        };
        setTimeout(load, 100);
      }
    );

    releaseSandbox(sandbox);
    let chartURL = await addS3Image(svgString);
    return { chartURL, legend, title };
  };
}

export default LinearDeliverySlide;
