import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BPMButton, Spinner } from "../../Components";

type ImportType = "bulk";

interface ImportModalProps {
  importType: ImportType;
  importFunction: (file: any) => Promise<void>;
  onClose: () => void;
}

const TITLES = {
  bulk: "Bulk Import (from Excel)",
};

const DESCRIPTIONS = {
  bulk:
    "During bulk import rows, duplicates are ignored, changes are applied to existing rows and new rows are inserted. Leave id column blank for new rows.",
};

const ImportModal: React.FC<ImportModalProps> = ({ importType, importFunction, onClose }) => {
  const [file, setFile] = useState<File | undefined>();
  const [importing, setImporting] = useState(false);

  const importPlan = () => {
    setImporting(true);

    const reader = new FileReader();
    reader.onload = async e => {
      const binaryString = e?.target?.result;

      await importFunction(binaryString);

      setImporting(false);
      onClose();
    };
    reader.readAsArrayBuffer(file as Blob);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{TITLES[importType]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{DESCRIPTIONS[importType]}</div>
        <input
          type="file"
          onChange={e => {
            const uploadedFile = e.target.files;
            const file = uploadedFile?.[0];
            setFile(file);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <BPMButton disabled={!file} variant="primary" onClick={importPlan}>
          {importing ? <Spinner /> : "Import"}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
