import React, { useMemo, useState, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { MdSave } from "react-icons/md";

import { download } from "../../utils/download-utils";
import { Dropdown, DropdownButton, Button, Tooltip } from "react-bootstrap";
import { BPMTable, DateRangePicker, OverlayTrigger } from "../../Components";
import { INNER_CELL_HEIGHT, MIN_COLUMN_WIDTH, WEEK_RANGE } from "../constants";
import FormattedInnerCell from "./FormattedInnerCell";
import "../Dashboard.scss";

const DashboardTableBody = ({ data, details, actions, filteredData }) => {
  const [downloadCsvContents, setDownloadCsvContents] = useState("");
  const { headers, superHeaders } = useMemo(() => {
    if (!(data.transformedData && data.headers)) {
      return {};
    }
    const makeHeader = ({ label, key, type, explainer, sortPriority, minWidth }) => ({
      label,
      name: key,
      explainer,
      flex: 1,
      sortPriority,
      minFlexWidth: minWidth || MIN_COLUMN_WIDTH,
      renderer: cellData => <FormattedInnerCell data={cellData[key]} type={type} decimals={0} />,
    });
    const headers = [];
    const superHeaders = [];
    let hasSubHeaders = false;
    for (let header of data.headers) {
      if (header.subHeaders) {
        hasSubHeaders = true;
        superHeaders.push({
          data: header.label,
          span: header.subHeaders.length,
        });
        for (let subHeader of header.subHeaders) {
          headers.push(makeHeader(subHeader));
        }
      } else {
        superHeaders.push({ span: 1 });
        headers.push(makeHeader(header));
      }
    }
    return {
      headers,
      superHeaders: hasSubHeaders ? superHeaders : null,
    };
  }, [data]);

  const downloadCSV = useCallback(() => {
    download(downloadCsvContents, `${details.csvName}`, "text/csv");
  }, [downloadCsvContents, details.csvName]);

  const headersRenderer = useCallback(
    ({ data, columnIndex }) => {
      let { explainer } = headers[columnIndex];
      let content = <div className="dashboardTableColumnHeader">{data}</div>;

      if (explainer) {
        return (
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
            overlay={<Tooltip>{explainer}</Tooltip>}
          >
            {content}
          </OverlayTrigger>
        );
      }
      return content;
    },
    [headers]
  );

  if (!(R.prop("transformedData", data) && headers && details)) {
    return null;
  }

  return (
    <div className="dashboardWidget">
      <div className="tableHeaderDateContainer">
        <div className="dashboardWidgetHeader">{details.title}</div>
        {actions && <div className="tableHeaderActionsContainer">{actions}</div>}
        <Button
          className="tableSaveButton"
          variant="outline-secondary"
          size="sm"
          onClick={downloadCSV}
        >
          <MdSave />
        </Button>
      </div>
      <BPMTable
        alternateColors
        headers={headers}
        data={filteredData || data.transformedData}
        headersRenderer={headersRenderer}
        headerHeight={details.tallHeaders ? INNER_CELL_HEIGHT * 1.75 : INNER_CELL_HEIGHT}
        superHeaders={superHeaders}
        onCsvChange={csvContents => setDownloadCsvContents(csvContents)}
        rowHeight={INNER_CELL_HEIGHT}
        filterBar={false}
      />
    </div>
  );
};

const withWeekRangeFilter = BaseComponent => props => {
  // if the maximum date that exists in the data is before the start date, set the start date to the max date
  const tempStart = Dfns.parseISO(props.details.start);
  const tempMax = Dfns.parseISO(props.data.maxDate);
  const actualStart = Dfns.isBefore(tempStart, tempMax) ? tempMax : tempStart;
  const [dates, setDates] = useState({
    start: actualStart,
    end: tempMax,
  });

  let filteredData = useMemo(() => {
    if (!(R.prop(dates.start) && props.data)) {
      return null;
    }

    let filtered = R.filter(record => {
      let parsedStart = dates.start.length === 10 ? Dfns.parseISO(dates.start) : dates.start;
      let parsedEnd = dates.end.length === 10 ? Dfns.parseISO(dates.end) : dates.end;

      return (
        record.date !== "Invalid Date" &&
        Dfns.isAfter(Dfns.subDays(1, parsedStart), Dfns.parseISO(record.date)) &&
        Dfns.isBefore(Dfns.addWeeks(1, parsedEnd), Dfns.parseISO(record.date))
      );
    }, props.data.transformedData);
    if (R.prop("onFilterChange", props.details)) {
      return props.details.onFilterChange(filtered);
    }
    return filtered;
  }, [dates, props.data, props.details]);

  return (
    <BaseComponent
      {...props}
      filteredData={filteredData}
      actions={
        <>
          <DateRangePicker
            mondayOnly
            startDateId={`${props.details.title}Start`}
            endDateId={`${props.details.title}End`}
            startDate={dates.start}
            endDate={dates.end}
            isOutsideRange={date => date < props.data.minDate || date > props.data.maxDate}
            onChange={({ startDate, endDate }) => {
              setDates({ start: startDate, end: endDate });
            }}
          />
          {props.details.actions}
        </>
      }
    />
  );
};

const DashboardTable = ({ data, details, onSetField = () => {} }) => {
  if (!(data && details)) {
    return null;
  }
  switch (details.dateFilter) {
    default:
      return (
        <DashboardTableBody
          data={data}
          details={details}
          actions={
            details.actions &&
            details.actions.length > 0 &&
            details.actions.map(action => (
              <DropdownButton
                key={action.prefix}
                className="pill"
                size="sm"
                variant="outline-primary"
                title={`${action.prefix}: ${action.selected}`}
              >
                {action.options.map(opt => (
                  <Dropdown.Item
                    key={`${action.prefix}_${opt}`}
                    onClick={() => onSetField({ field: action.field, value: opt })}
                  >
                    {opt}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            ))
          }
        />
      );
    case WEEK_RANGE: {
      const WeekRangeComponent = withWeekRangeFilter(DashboardTableBody);
      return <WeekRangeComponent data={data} details={details} onSetField={onSetField} />;
    }
  }
};

export default DashboardTable;
