import React, { useCallback, useContext, useState } from "react";
import * as R from "ramda";
import { MdArrowBackIosNew, MdArrowForwardIos, MdDoubleArrow } from "react-icons/md";
import Select from "react-select";
import { KpiMappingsContext } from "./KpiMapping";
import {
  CombinedCrossChannelKpis,
  HandleKpiEditInput,
  HandleNewRowInput,
  makeEditKey,
} from "./EditKpiMapping";
import {
  KpiEdits,
  SourceMapping,
  AccountInfo,
  KpiMappings,
} from "@blisspointmedia/bpm-types/dist/KpiMapping";
import { Button, ButtonType, OverlayTrigger, Tooltip } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

const getValuesToUse = (
  accountIdSourceMappings: SourceMapping[],
  key: string,
  editsMap: KpiEdits
) => {
  if (R.has(key, editsMap)) {
    return R.pathOr([], [key], editsMap).map((edit: SourceMapping) => ({
      label: edit.kpi,
      value: edit.kpi,
    }));
  } else {
    return accountIdSourceMappings.map(mapping => ({ label: mapping.kpi, value: mapping.kpi }));
  }
};

interface SourceColumnProps {
  combinedKpiMappings: any;
  source: string;
  crossChannelKpis: CombinedCrossChannelKpis;
  editsMap: KpiEdits;
  newRows: KpiMappings;
  accounts: AccountInfo[];
  handleKpiEdit: (input: HandleKpiEditInput) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
}
const SourceColumn: React.FC<SourceColumnProps> = React.memo(
  ({
    combinedKpiMappings,
    source,
    crossChannelKpis,
    editsMap,
    newRows,
    accounts,
    handleKpiEdit,
    handleNewRow,
  }) => {
    const { sourceIdMap, kpiOptions, editMode, sourceToPrettyName } = useContext(
      KpiMappingsContext
    );

    const [collapsed, setCollapsed] = useState(false);

    const handleOnChange = useCallback(
      ({
        source,
        crossChannelKpiId,
        isCrossChannelKpiLabel,
        isNewRow,
        key,
        mappings,
        accountId,
      }) => {
        if (isNewRow) {
          handleNewRow({
            crossChannelKpiId,
            kpiLabel: "",
            sourceLabel: source,
            isCrossChannelKpiLabel,
            sourceMappings: mappings,
            accountId,
          });
        } else {
          handleKpiEdit({
            key,
            mappings,
          });
        }
      },
      [handleKpiEdit, handleNewRow]
    );

    let accountsWithFill = accounts;
    // For cases like TVAD, account ID will be null so this will be empty but we need a placeholder
    if (accounts.length === 0) {
      //@ts-ignore
      accountsWithFill = [{ accountId: null, accountName: null }];
    }

    /**
     * Apply the selected KPI(s) to all accounts for the given source
     */
    const applyKpiToAllAccounts = useCallback(
      ({ accountIdSourceMappings, editsKey, crossChannelKpiId, accountId, isNewRow }) => {
        if (isNewRow) {
          // Get new row mappings for source
          const newKpis: SourceMapping[] = R.pathOr(
            [],
            [crossChannelKpiId, "mappings", source],
            newRows
          );

          // Get new row mappings for the account that you are extending from
          const newKpisForAccount = newKpis.filter(kpi => kpi.accountId === accountId);

          // For each account for this source, apply the same KPIs to all of them
          accounts.forEach(account => {
            const edits = newKpisForAccount.map((edit: SourceMapping) => ({
              ...edit,
              accountId: account.accountId,
            }));
            handleOnChange({
              source,
              crossChannelKpiId,
              isNewRow,
              isCrossChannelKpiLabel: false,
              key: makeEditKey({ crossChannelKpiId, source, accountId: account.accountId }),
              mappings: edits,
              accountId: account.accountId,
            });
          });
        } else {
          if (!R.has(editsKey, editsMap) && R.isEmpty(accountIdSourceMappings)) {
            return;
          }

          // Use either the pending edits or the existing mappings
          const mappingsToUse = editsMap[editsKey] || accountIdSourceMappings;

          // Apply mappings to all accounts for this source
          accounts.forEach(account => {
            handleOnChange({
              source,
              crossChannelKpiId,
              isNewRow,
              isCrossChannelKpiLabel: false,
              key: makeEditKey({ crossChannelKpiId, source, accountId: account.accountId }),
              mappings: mappingsToUse,
              accountId: account.accountId,
            });
          });
        }
      },
      [accounts, editsMap, handleOnChange, source, newRows]
    );

    const selectOnChange = useCallback(
      ({ newVals, source, crossChannelKpiId, accountId, editsKey, isNewRow }) => {
        let mappings: SourceMapping[] = [];
        if (!R.isNil(newVals)) {
          mappings = newVals.map(val => ({
            kpi: val.value,
            crossChannelKpiId,
            source,
            sourceId: sourceIdMap[source],
            accountId,
          }));
        }

        handleOnChange({
          newVals,
          source,
          crossChannelKpiId,
          isNewRow,
          isCrossChannelKpiLabel: false,
          key: editsKey,
          mappings: mappings,
          accountId,
        });
      },
      [handleOnChange, sourceIdMap]
    );

    return (
      <div className="sourceColumn">
        <div className="columnHeader">
          <div>{sourceToPrettyName[source] || source}</div>
          {accountsWithFill.length > 1 && (
            <Button
              size="sm"
              style={{ marginLeft: "8px", height: "18px" }}
              type={ButtonType.EMPTY}
              onClick={() => setCollapsed(prev => !prev)}
              icon={collapsed ? <MdArrowForwardIos /> : <MdArrowBackIosNew />}
            />
          )}
        </div>
        <div className="accountIdColumns">
          {collapsed ? (
            <div style={{ minWidth: "200px", marginTop: "32px" }}>
              Expand to see raw KPIs for all {sourceToPrettyName[source] || source} accounts.
            </div>
          ) : (
            accountsWithFill.map((accountInfo, i) => {
              const { accountId, accountName } = accountInfo;
              return (
                <div className="accountIdColumn" key={accountId}>
                  <div className="accountIdLabel">
                    <div className="labelPrefix">Account:</div>
                    <div className="labelValue">{accountName || accountId || "N/A"}</div>
                  </div>
                  {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
                    // If this is existing row, the key is the crossChannelKpiLabel. If it's new row, the key is the crossChannelKpiId (which is a UUID)
                    const kpiMappingsKey = isNewRow ? crossChannelKpiId : crossChannelKpiLabel;

                    const sourceMappings = R.pathOr(
                      [] as SourceMapping[],
                      [kpiMappingsKey, "mappings", source],
                      combinedKpiMappings
                    );

                    const accountIdSourceMappings = R.filter(
                      R.propEq("accountId", accountId),
                      sourceMappings
                    );

                    const editsKey = makeEditKey({ crossChannelKpiId, source, accountId });

                    const valuesToUse = getValuesToUse(accountIdSourceMappings, editsKey, editsMap);

                    return (
                      <div key={crossChannelKpiId} className="sourceItem">
                        {i === 0 && accountsWithFill.length > 1 && editMode && (
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            delay={500}
                            overlay={
                              <Tooltip>
                                Apply selected KPI(s) to all accounts for{" "}
                                {sourceToPrettyName[source]}
                              </Tooltip>
                            }
                          >
                            <Button
                              size="sm"
                              style={{ height: "38px", marginRight: "5px" }}
                              varant={ButtonFrameworkVariant.ICON_ONLY}
                              type={ButtonType.OUTLINED}
                              onClick={() =>
                                applyKpiToAllAccounts({
                                  accountIdSourceMappings,
                                  editsKey,
                                  crossChannelKpiId,
                                  accountId,
                                  isNewRow,
                                })
                              }
                            >
                              <MdDoubleArrow />
                            </Button>
                          </OverlayTrigger>
                        )}
                        <div className="sourceKpiDropdown">
                          <Select
                            isDisabled={!editMode}
                            className="kpiSelectorDropdown"
                            placeholder="Choose Raw KPI(s)"
                            isClearable
                            isMulti
                            options={(kpiOptions[source] || []).map(option => {
                              return { label: option, value: option };
                            })}
                            value={valuesToUse}
                            onChange={(newVals: { label: string; value: string }[]) =>
                              selectOnChange({
                                newVals,
                                source,
                                crossChannelKpiId,
                                accountId,
                                editsKey,
                                isNewRow,
                              })
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
);

export default SourceColumn;
