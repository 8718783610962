import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { MdCached, MdWarning, MdSave, MdCancel } from "react-icons/md";

import {
  Spinner,
  OldFilterBar,
  FullPageSpinner,
  BPMTable,
  NumberFormatter,
  SelfFocusingInput,
  BPMButton,
  OverlayTrigger,
  CellRenderer,
} from "../Components";
import { useSetError } from "../redux/modals";
import { PacingPane, PacingBar, PacingBarTypes } from "../StreamingPacing/StreamingPacing";

import { AdminLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { formatPacingNumber } from "../utils/format-utils";

import {
  ACCEPTED_LABEL,
  COMPANY_DIMENSION,
  NETWORK_DIMENSION,
} from "../LinearPacing/LinearPacingUtils";

import "../StreamingPacing/StreamingPacing.scss";
import "../LinearPacing/LinearPacing.scss";
import {
  calculateAdjustedSpend,
  makeNetworkLabel,
  makeCompanyLabel,
  BOTTOM_SUPER_HEADERS,
  createLogsHeaderRow,
} from "./linearPacingUtils";
import { convert24hrTo12hr } from "../utils/time-utils";
import { TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { Tooltip } from "react-bootstrap";

interface SpendByDay {
  [day: string]: {
    spend: number;
  };
}
interface SpendInfo {
  spend: number;
  days: SpendByDay;
}

export interface DataRow {
  company: string;
  campaign: string | null;
  tvBudget: number;
  commission: number;
  assumedClearanceRate: number;

  logs: SpendInfo; // "All" (Unsecured + Secured) & "Accepted"
  logsAll: SpendInfo; // "All" tab (Unsecured + Secured) & "All" (Accepted+)

  securedLogs: SpendInfo; // Used for "Secured" tab & "Accepted" tab
  securedLogsAll: SpendInfo; // "Secured" tab & "All" (Accepted+) tab

  unsecuredLogs: SpendInfo; // "Unsecured" & "Accepted"
  unsecuredLogsAll: SpendInfo; // "Unsecured" & "All" (Accepted+)

  estimated: SpendInfo; // Combines Unsecured and Secured always
  estimatedAll: SpendInfo;

  booked: SpendInfo;

  network: DataRow[];

  key: string;
}

interface Spot {
  dow_iso: number;
  cost: number;
  count: number;
}

export interface BottomDataRow {
  id: number;
  avail: string;
  campaign: string;
  company: string;
  cost: number;
  count: number;
  daypart_start: string;
  daypart_end: string;
  dow: string;
  length: number;
  network: string;
  notes: string | null;
  rotation: string;
  spend: number;
  spots: Spot[];
  type: number;
  spend_override: number | null;
}

interface LinearPacingDetailProps {
  spendDimension: string;
  acceptedDimension: string;
  mediaTypeDimension: string;
  leftKey: string;
  start: string;
}

const LinearPacingDetail2 = ({
  spendDimension,
  acceptedDimension,
  mediaTypeDimension,
  leftKey,
  start,
}: LinearPacingDetailProps): JSX.Element => {
  const [data, setData] = useState<DataRow[]>();
  const [bottomData, setBottomData] = useState<BottomDataRow[]>();
  const [filteredBottomData, setFilteredBottomData] = useState<BottomDataRow[]>();
  const [filters, setFilters] = useState({
    left: () => true,
    right: () => true,
  });
  const rightKey = useMemo(
    () => (leftKey === COMPANY_DIMENSION ? NETWORK_DIMENSION : COMPANY_DIMENSION),
    [leftKey]
  );
  const [selectedLeft, setSelectedLeft] = useState<string>();
  const [selectedRight, setSelectedRight] = useState<string>();

  const [leftFilterTokens, setLeftFilterTokens] = useState({});
  const [leftFilterAdvanced, setLeftFilterAdvanced] = useState(false);
  const leftFilterBarOptions = useMemo(() => [leftKey], [leftKey]);
  const [rightFilterTokens, setRightFilterTokens] = useState({});
  const [rightFilterAdvanced, setRightFilterAdvanced] = useState(false);
  const rightFilterBarOptions = useMemo(() => [rightKey], [rightKey]);

  const [spendOverrides, setSpendOverrides] = useState({});
  const [focusedCell, setFocusedCell] = useState("");
  const [saving, setSaving] = useState(false);

  const setError = useSetError();

  useEffect(() => {
    (async () => {
      try {
        if (data) {
          return;
        }
        const response = await AdminLambdaFetch("/getLinearPacingSummary", {
          params: { week: start, grouping: leftKey, type: mediaTypeDimension.toLowerCase() },
        });
        const dataRes = await awaitJSON(response);
        setData(dataRes);
      } catch (e) {
        const reportError = e as Error;
        setError({ message: "Could not fetch data for pacing detail page", reportError });
      }
    })();
  }, [start, leftKey, mediaTypeDimension, setError, data]);

  const {
    leftMax,
    leftData,
    // leftCsv TODO: Doesn't even currently work on existing page
  } = useMemo(() => {
    if (!data) {
      return {};
    }

    let leftMax = 0;
    const filtered = data.filter(filters.left);
    const leftData = R.pipe(
      R.map((item: DataRow) => {
        const { booked, estimated, estimatedAll } = item;
        leftMax = Math.max(
          leftMax,
          booked.spend,
          acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend
        );
        item.key = item[leftKey];
        if (leftKey === "company" && item.campaign) {
          item.key = `${item[leftKey]}_${item.campaign}`;
        }
        return item;
      }),
      R.filter((item: DataRow) => !Array.isArray(item.key)),
      // Doing "- Math.abs" is not a typo. Instead of doing an R.desc or R.reverse, this achieves the same result
      R.sortBy(
        ({ booked, estimated, estimatedAll }) =>
          -Math.abs(
            booked.spend -
              (acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend)
          )
      )
    )(filtered);

    return {
      leftMax,
      leftData,
      // leftCsv
    };
  }, [data, filters.left, leftKey, acceptedDimension]);

  const {
    rightMax,
    rightData,
    rightDataFiltered,
    // rightCsv TODO
  } = useMemo(() => {
    if (
      !data ||
      !selectedLeft ||
      !R.find(
        row => (leftKey === "company" ? row.key === selectedLeft : row[leftKey] === selectedLeft),
        data
      )
    ) {
      return {};
    }

    let rightMax = 0;
    let selectedRow = R.find(({ key }) => key === selectedLeft, data);
    let rightData = (selectedRow || {})[rightKey] as DataRow[];

    const estimatedKey = acceptedDimension === ACCEPTED_LABEL ? "estimated" : "estimatedAll";
    const rightDataFiltered = R.filter(filters.right, rightData).map(row => {
      rightMax = Math.max(rightMax, row.booked.spend, row[estimatedKey].spend);
      row.key = row[rightKey] as string;
      if (leftKey === "network" && row.campaign) {
        row.key = `${row[rightKey]}_${row.campaign}`;
      }
      return row;
    });

    return {
      rightMax,
      rightData,
      rightDataFiltered,
      // rightCsv
    };
  }, [data, selectedLeft, filters, leftKey, rightKey, acceptedDimension]);

  const PacingBarOverlay = useCallback(
    ({
      booked,
      estimated,
      estimatedAll,
      logs,
      logsAll,
      unsecuredLogs,
      unsecuredLogsAll,
      securedLogs,
      securedLogsAll,
      tvBudget,
      commission,
      assumedClearanceRate,
    }) => {
      let estimatedSpend = calculateAdjustedSpend(
        acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
        commission,
        spendDimension
      );
      let logsSpend = calculateAdjustedSpend(
        acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
        commission,
        spendDimension
      );
      let unsecuredLogsSpend = calculateAdjustedSpend(
        acceptedDimension === ACCEPTED_LABEL ? unsecuredLogs.spend : unsecuredLogsAll.spend,
        commission,
        spendDimension
      );
      let securedLogsSpend = calculateAdjustedSpend(
        acceptedDimension === ACCEPTED_LABEL ? securedLogs.spend : securedLogsAll.spend,
        commission,
        spendDimension
      );
      let bookedSpend = calculateAdjustedSpend(booked.spend, commission, spendDimension);
      let budgetSpend = tvBudget && calculateAdjustedSpend(tvBudget, commission, spendDimension);

      let prettyForecast = formatPacingNumber(estimatedSpend, "$");
      let prettyLogs = formatPacingNumber(logsSpend, "$");
      let prettyUnsecuredLogs = formatPacingNumber(unsecuredLogsSpend, "$");
      let prettySecuredLogs = formatPacingNumber(securedLogsSpend, "$");
      let prettyBooked = formatPacingNumber(bookedSpend, "$");

      return (
        <div style={{ textAlign: "left" }}>
          {logs.spend === 0 && bookedSpend > 1000 ? (
            <div>
              Reprocessing
              <br />
            </div>
          ) : (
            <div />
          )}
          On the logs: {prettyLogs} / Pacing: {prettyForecast} / Booked: {prettyBooked}
          {securedLogsSpend ? (
            <div>
              <br />
              Secured {prettySecuredLogs} / Unsecured: {prettyUnsecuredLogs}
            </div>
          ) : (
            <div />
          )}
          {tvBudget ? (
            <div>
              <br />
              Client Target: {formatPacingNumber(budgetSpend, "$")} / Booked Target:{" "}
              {formatPacingNumber(bookedSpend * assumedClearanceRate, "$")} / Assumed Clear:{" "}
              {(100 * assumedClearanceRate).toFixed(0)}%
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    },
    [spendDimension, acceptedDimension]
  );

  useEffect(() => {
    if (selectedLeft && selectedRight) {
      (async () => {
        try {
          let company = leftKey === COMPANY_DIMENSION ? selectedLeft : selectedRight;
          const network = leftKey === COMPANY_DIMENSION ? selectedRight : selectedLeft;
          let campaign;
          if (company.includes("_")) {
            [company, campaign] = company.split("_");
          }
          const res = await AdminLambdaFetch("/getCompanyNetworkLogs", {
            params: {
              week: start,
              company,
              network,
              type: mediaTypeDimension.toLowerCase(),
              campaign: campaign || "",
            },
          });
          const data = (await awaitJSON(res)) as BottomDataRow[];
          setBottomData(data);
          setFilteredBottomData(data);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: "Could not fetch detailed data for pacing detail page",
            reportError,
          });
        }
      })();
    }
  }, [selectedLeft, selectedRight, mediaTypeDimension, start, setError, leftKey]);

  const BOTTOM_COL_HEADERS = [
    { label: "Avail", name: "avail", width: 70 },
    { label: "Len", name: "length", width: 70 },
    { label: "Days", name: "dow", flex: 1 },
    {
      label: "Start",
      name: "daypart_start",
      flex: 1,
      renderer: (row: BottomDataRow): string => convert24hrTo12hr(row.daypart_start),
    },
    {
      label: "End",
      name: "daypart_end",
      flex: 1,
      renderer: (row: BottomDataRow): string => convert24hrTo12hr(row.daypart_end),
    },
    {
      label: "Rotation",
      name: "rotation",
      flex: 1,
      minFlexWidth: 200,
      renderer: (row: BottomDataRow): string => row.rotation,
    },
    {
      label: "Type",
      name: "type",
      renderer: (row: BottomDataRow): string => TYPES_TO_NAMES[`${row.type}`],
    },
    {
      label: "#",
      name: "count",
      renderer: (row: BottomDataRow): JSX.Element => (
        <NumberFormatter value={row.count} type={"#"} decimals={0} />
      ),
      width: 50,
    },
    {
      label: "Cost",
      name: "cost",
      renderer: (row: BottomDataRow): JSX.Element => (
        <NumberFormatter value={row.cost} type={"$"} decimals={0} />
      ),
    },
    {
      label: "Spend",
      name: "spend",
      renderer: (row: BottomDataRow): JSX.Element => (
        <NumberFormatter value={row.spend} type={"$"} decimals={0} />
      ),
    },
    createLogsHeaderRow("M", 0),
    createLogsHeaderRow("Tu", 1),
    createLogsHeaderRow("W", 2),
    createLogsHeaderRow("Th", 3),
    createLogsHeaderRow("F", 4),
    createLogsHeaderRow("Sa", 5),
    createLogsHeaderRow("Su", 6),
    {
      label: "Total",
      name: "logSpend",
      renderer: (row: BottomDataRow): JSX.Element => (
        <>
          {focusedCell === `${row.id}` ? (
            <SelfFocusingInput
              onBlur={() => setFocusedCell("")}
              type="number"
              value={
                spendOverrides[row.id] ||
                row.spend_override ||
                R.sum(R.map(spot => spot.cost, row.spots))
              }
              onChange={e => {
                let spendOverride = parseInt(e.target.value) || 0;

                if (spendOverride < 0) {
                  spendOverride = Math.abs(spendOverride);
                }

                setSpendOverrides(current => ({ ...current, [row.id]: spendOverride }));
              }}
            />
          ) : (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                row.spend_override ? (
                  <Tooltip id={`${row.id}`}>This is an overridden estimate</Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <div
                onClick={() => setFocusedCell(`${row.id}`)}
                className={`${row.spend_override ? "pacingSpendOverride" : ""}`}
              >
                <NumberFormatter
                  value={
                    spendOverrides[row.id] ||
                    row.spend_override ||
                    R.sum(R.map(spot => spot.cost, row.spots))
                  }
                  type={"$"}
                  decimals={0}
                />
              </div>
            </OverlayTrigger>
          )}
        </>
      ),
    },
    {
      label: "%",
      name: "pct",
      renderer: (row: BottomDataRow): JSX.Element => (
        <NumberFormatter
          value={R.sum(R.map(spot => spot.cost, row.spots)) / row.spend}
          type={"%"}
          decimals={0}
        />
      ),
      width: 70,
    },
  ];

  const bottomTotalsRow = useMemo(() => {
    if (!filteredBottomData) {
      return {};
    }

    const logSpend = R.pipe(
      R.map((row: BottomDataRow) =>
        R.pipe(
          R.map((spot: Spot) => spot.cost),
          R.sum
        )(row.spots)
      ),
      R.sum
    )(filteredBottomData);
    const spends = R.pluck("spend", filteredBottomData);
    const bookedSpend = R.sum(spends);
    const counts = R.pluck("count", filteredBottomData);
    const totalCount = R.sum(counts);

    return {
      count: `#${totalCount}`,
      spend: `$${bookedSpend}`,
      M: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[0].cost),
        R.sum
      )(filteredBottomData)}`,
      Tu: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[1].cost),
        R.sum
      )(filteredBottomData)}`,
      W: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[2].cost),
        R.sum
      )(filteredBottomData)}`,
      Th: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[3].cost),
        R.sum
      )(filteredBottomData)}`,
      F: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[4].cost),
        R.sum
      )(filteredBottomData)}`,
      Sa: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[5].cost),
        R.sum
      )(filteredBottomData)}`,
      Su: `$${R.pipe(
        R.map((row: BottomDataRow) => row.spots[6].cost),
        R.sum
      )(filteredBottomData)}`,
      logSpend: `$${logSpend}`,
      pct: `%${logSpend / bookedSpend}`,
    };
  }, [filteredBottomData]);

  const totalsRenderer: CellRenderer<Element | number | string | undefined> = ({
    data,
    style = {},
    classes = [],
  }) => {
    let output;
    let type = "#";
    if (typeof data === "string") {
      if (data && data.indexOf("$") >= 0) {
        type = "$";
      } else if (data && data.indexOf("%") >= 0) {
        type = "%";
      }

      try {
        output = parseFloat(data.replace(/%/g, "").replace(/\$/g, "").replace(/#/g, ""));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    if (Number.isFinite(data)) {
      output = <NumberFormatter value={data} type={type} decimals={0} />;
    }

    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {output}
      </div>
    );
  };

  const onFilteredBottomDataChange = useCallback(
    data => {
      if (!R.equals(filteredBottomData, data)) {
        setFilteredBottomData(data);
      }
    },
    [filteredBottomData, setFilteredBottomData]
  );

  const saveEdits = async () => {
    try {
      setSaving(true);
      const rows = R.keys(spendOverrides).map(id => ({
        id: parseInt(id),
        spend: spendOverrides[id],
      }));
      await AdminLambdaFetch("/setLinearPacingEstimateOverride", {
        method: "POST",
        body: { rows },
      });
      setSpendOverrides({});
      setData(undefined);
      setSelectedRight(undefined);
      setBottomData(undefined);
      setSaving(false);
    } catch (e) {
      const reportError = e as Error;
      setSaving(false);
      setError({ message: reportError.message, reportError });
    }
  };

  return data ? (
    <div className="pacingDashboard">
      {leftData ? (
        <div className="chartPanes">
          {!R.isEmpty(spendOverrides) && (
            <div className="pacingEditControls">
              <BPMButton size="sm" variant="danger" onClick={() => setSpendOverrides({})}>
                <MdCancel />
              </BPMButton>
              <BPMButton size="sm" variant="success" onClick={saveEdits} disabled={saving}>
                {saving ? <Spinner /> : <MdSave />}
              </BPMButton>
            </div>
          )}
          <div className="leftPane">
            <PacingPane
              items={leftData as any}
              selectedKey={selectedLeft}
              onSelect={key => {
                setSelectedRight(undefined);
                setSelectedLeft(key);
                setBottomData(undefined);
              }}
              filterRenderer={() => (
                <div style={{ width: "100%", display: "flex" }}>
                  <OldFilterBar
                    options={leftFilterBarOptions}
                    lines={R.pipe(R.project(leftFilterBarOptions), R.uniq)(data)}
                    onFilter={filter => {
                      setFilters({
                        ...filters,
                        left: filter as () => true,
                      });
                    }}
                    /// @ts-ignore
                    tokens={leftFilterTokens}
                    onChange={leftFilterTokens => setLeftFilterTokens(leftFilterTokens)}
                    advanced={leftFilterAdvanced}
                    onSetAdvanced={advanced => setLeftFilterAdvanced(advanced)}
                  />
                </div>
              )}
              overlayRenderer={PacingBarOverlay}
              labelRenderer={leftKey === COMPANY_DIMENSION ? makeCompanyLabel : makeNetworkLabel}
              lineItemRenderer={({
                key,
                booked,
                logs,
                logsAll,
                unsecuredLogs,
                unsecuredLogsAll,
                securedLogs,
                securedLogsAll,
                estimated,
                estimatedAll,
                tvBudget,
                hasPrelogs,
                commission,
                assumedClearanceRate,
              }) => {
                let estimatedSpend = calculateAdjustedSpend(
                  acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
                  commission,
                  spendDimension
                );
                let logsSpend = calculateAdjustedSpend(
                  acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
                  commission,
                  spendDimension
                );
                let unsecuredLogsSpend = calculateAdjustedSpend(
                  acceptedDimension === ACCEPTED_LABEL
                    ? unsecuredLogs.spend
                    : unsecuredLogsAll.spend,
                  commission,
                  spendDimension
                );
                let securedLogsSpend = calculateAdjustedSpend(
                  acceptedDimension === ACCEPTED_LABEL ? securedLogs.spend : securedLogsAll.spend,
                  commission,
                  spendDimension
                );
                let bookedSpend = calculateAdjustedSpend(booked.spend, commission, spendDimension);
                let budgetSpend =
                  tvBudget && calculateAdjustedSpend(tvBudget, commission, spendDimension);

                let parts =
                  logsSpend > 0
                    ? R.filter(R.identity, [
                        // type, label, color, val, leftVal = 0
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          className: "pacing",
                          val: estimatedSpend,
                          leftVal: 0,
                        },
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: formatPacingNumber(unsecuredLogsSpend, "$"),
                          className: "deliveryUnsecured",
                          val: unsecuredLogsSpend + securedLogsSpend,
                          leftVal: securedLogsSpend,
                        },
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: formatPacingNumber(securedLogsSpend, "$"),
                          className: "deliverySecured",
                          val: securedLogsSpend,
                        },
                        {
                          type: PacingBarTypes.VERTICAL,
                          className: "bookedTarget",
                          val: bookedSpend * assumedClearanceRate,
                        },
                        budgetSpend && {
                          type: PacingBarTypes.VERTICAL,
                          className: "clientTarget",
                          val: budgetSpend,
                        },
                        {
                          type: PacingBarTypes.LABEL,
                          label: `${Math.round(
                            (estimatedSpend / bookedSpend) * 100
                          )}% / ${formatPacingNumber(estimatedSpend, "$")}`,
                          leftVal: estimatedSpend,
                        },
                      ])
                    : [
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: (
                            <div>
                              Reprocessing&nbsp;
                              <MdCached />
                            </div>
                          ),
                          className: "pacing",
                          val: leftMax,
                        },
                      ];

                if (R.isNil(commission) && !hasPrelogs) {
                  parts.push({
                    type: PacingBarTypes.ALERT,
                    className: "warningIcon",
                    label: <MdWarning />,
                  });
                }
                return <PacingBar key={key} max={leftMax} parts={parts} />;
              }}
              rowClassName={undefined}
              summaryData={undefined}
            />
          </div>
          {selectedLeft &&
            (!rightData ? (
              <div className="rightPane">
                <div className="topPane">
                  <Spinner size={100} />
                </div>
              </div>
            ) : (
              <div className="rightPane">
                <div className="topPane">
                  <PacingPane
                    items={rightDataFiltered as any}
                    selectedKey={selectedRight}
                    onSelect={subKey => {
                      setSelectedRight(subKey);
                      setBottomData(undefined);
                    }}
                    filterRenderer={() => (
                      <div style={{ width: "100%", display: "flex" }}>
                        <OldFilterBar
                          options={rightFilterBarOptions}
                          lines={R.pipe(R.project(rightFilterBarOptions), R.uniq)(rightData)}
                          onFilter={filter => {
                            setFilters({
                              ...filters,
                              right: filter as () => true,
                            });
                          }}
                          /// @ts-ignore
                          tokens={rightFilterTokens}
                          onChange={rightFilterTokens => setRightFilterTokens(rightFilterTokens)}
                          advanced={rightFilterAdvanced}
                          onSetAdvanced={advanced => setRightFilterAdvanced(advanced)}
                        />
                      </div>
                    )}
                    labelRenderer={
                      leftKey === COMPANY_DIMENSION ? makeNetworkLabel : makeCompanyLabel
                    }
                    overlayRenderer={PacingBarOverlay}
                    lineItemRenderer={({
                      key,
                      booked,
                      estimated,
                      estimatedAll,
                      logs,
                      logsAll,
                      unsecuredLogs,
                      unsecuredLogsAll,
                      securedLogs,
                      securedLogsAll,
                      commission,
                      hasPrelogs,
                    }) => {
                      const estimatedSpend = calculateAdjustedSpend(
                        acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
                        commission,
                        spendDimension
                      );
                      const logsSpend = calculateAdjustedSpend(
                        acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
                        commission,
                        spendDimension
                      );
                      let unsecuredLogsSpend = calculateAdjustedSpend(
                        acceptedDimension === ACCEPTED_LABEL
                          ? unsecuredLogs.spend
                          : unsecuredLogsAll.spend,
                        commission,
                        spendDimension
                      );
                      let securedLogsSpend = calculateAdjustedSpend(
                        acceptedDimension === ACCEPTED_LABEL
                          ? securedLogs.spend
                          : securedLogsAll.spend,
                        commission,
                        spendDimension
                      );
                      const bookedSpend = calculateAdjustedSpend(
                        booked.spend,
                        commission,
                        spendDimension
                      );

                      let parts =
                        logsSpend > 0
                          ? [
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                className: "pacing",
                                val: estimatedSpend,
                                leftVal: logsSpend,
                              },
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: formatPacingNumber(unsecuredLogsSpend, "$"),
                                className: "deliveryUnsecured",
                                val: unsecuredLogsSpend + securedLogsSpend,
                                leftVal: securedLogsSpend,
                              },
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: formatPacingNumber(securedLogsSpend, "$"),
                                className: "deliverySecured",
                                val: securedLogsSpend,
                              },
                              {
                                type: PacingBarTypes.VERTICAL,
                                className: "booked",
                                val: bookedSpend,
                              },
                              {
                                type: PacingBarTypes.LABEL,
                                label: `${Math.round(
                                  (estimatedSpend / bookedSpend) * 100
                                )}% / ${formatPacingNumber(estimatedSpend, "$")}`,
                                leftVal: estimatedSpend,
                              },
                            ]
                          : [
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: (
                                  <div>
                                    Reprocessing&nbsp;
                                    <MdCached />
                                  </div>
                                ),
                                className: "pacing",
                                val: rightMax,
                              },
                            ];

                      if (!hasPrelogs) {
                        parts.push({
                          type: PacingBarTypes.ALERT,
                          className: "warningIcon",
                          /// @ts-ignore
                          label: <MdWarning />,
                        });
                      }

                      return <PacingBar key={key} max={rightMax} parts={parts} />;
                    }}
                    rowClassName={undefined}
                    summaryData={undefined}
                  />
                </div>
                {selectedRight &&
                  (bottomData ? (
                    <div className="bottomPane">
                      <BPMTable<
                        Omit<BottomDataRow, "count" | "spend"> & {
                          count: number | string;
                          spend: number | string;
                        }
                      >
                        alternateColors
                        headers={BOTTOM_COL_HEADERS}
                        superHeaders={BOTTOM_SUPER_HEADERS}
                        data={bottomData}
                        totals={bottomTotalsRow}
                        totalsRenderer={totalsRenderer}
                        onFilteredDataChange={onFilteredBottomDataChange}
                      />
                    </div>
                  ) : (
                    <div className="bottomPane loadingPane">
                      <Spinner size={100} />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  ) : (
    <Spinner size={100} />
  );
};

export default LinearPacingDetail2;
