import React, { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Tooltip as RechartsTooltip } from "recharts";

import {
  FullPageSpinner,
  Page,
  KpiPickerLegacy,
  BPMTable,
  OverlayTrigger,
  Img,
  SingleDatePicker,
} from "../Components";

import { Button, Tooltip, DropdownButton, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useCreativeMap } from "../redux/creative";
import AutoSizer from "react-virtualized-auto-sizer";
import { MdClose, MdMoreVert } from "react-icons/md";

import * as CompanyRedux from "../redux/company";
import useLocation from "../utils/hooks/useLocation";
import { useSetError } from "../redux/modals";
import { ToolsLambdaFetch, awaitJSON, S3SignedUrlFetch } from "../utils/fetch-utils";
import { download, downloadJSONToCSV } from "../utils/download-utils";
import { primary } from "../utils/colors";
import "./SpikeChart.scss";
import { getSpikeChartData, pngUri, SpikeChart as InnerSpikeChart } from "../Components/SpikeChart";
import { TEST_COMPANIES, TEST_CREATIVES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { overrideDate } from "../utils/test-account-utils";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

const TABLE_DATE_FORMAT = "iii MMM do yyyy h:mm:ss a";
const TOOLTIP_DATE_FORMAT = "yyyy-MM-dd h:mm:ss a";
const DATE_FORMAT = "yyyy-MM-dd";
const AREA_FILL = primary;
const DOT_FILL = primary;
const DOT_BORDER = "#D4BDF2";

const getTestIsci = data => {
  const info = TEST_CREATIVES.find(info => info.name === data.creative);
  const fakeIsci = (info || {}).isci;
  return fakeIsci;
};

const DEFAULT_DATE = () => {
  let today = Dfns.format(DATE_FORMAT, new Date());
  let startOfWeek = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

  if (today === startOfWeek) {
    return R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(1), Dfns.format(DATE_FORMAT))(new Date());
  }

  return R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
};

const CUTOFF_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const SpikeChart = () => {
  const { company } = useLocation();
  const [kpi, setKpi] = useState(useSelector(CompanyRedux.initialKpiSelector(company)));
  const setError = useSetError();
  const [week, setWeek] = useState(DEFAULT_DATE);
  const [dataMap, setDataMap] = useState({});

  const dataKey = JSON.stringify({ week, kpi });
  const data = dataMap[dataKey];

  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [spotAttributionExists, setSpotAttributionExists] = useState(false);
  const [top, setTop] = useState("auto");
  const [bottom, setBottom] = useState(0);
  const [selectedPoint, setSelectedPoint] = useState();
  const [creativeTooltipProps, setCreativeTooltipProps] = useState();
  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["linear"],
  });
  const [coordinateMap, setCoordinateMap] = useState({});
  const chartRef = useRef();
  const spikeKpis = CompanyRedux.useCompanyInfo().spike_kpis;
  const isTestCompany = TEST_COMPANIES.includes(company);

  // Map spot times to their Recharts coordinates
  // This allows hovering on a row in the table to know where to show the tooltip
  useLayoutEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {
        setCoordinateMap(prev => {
          let { points } = chartRef.current.props;
          let first = points[0];
          if ((prev[first.payload.time] || {}).y === first.y) {
            return prev;
          }
          let newPoints = {};
          for (let point of points) {
            newPoints[point.payload.time] = R.pick(["x", "y"], point);
          }
          return newPoints;
        });
      }
    }, 0);
  });

  // Download CSV
  const downloadCSV = () => {
    let fileName = `${week}_${kpi}`;

    let formattedArray = tableData.map(row => {
      return {
        "Spot Time": row.time,
        Station: row.network,
        Avail: row.avail,
        Program: row.program,
        Creative: row.creative,
        Length: row.length,
        Cost: parseInt(row.cost).toLocaleString(),
        Spike: row.kpi,
      };
    });
    downloadJSONToCSV(formattedArray, fileName);
  };

  const downloadSpotAttribution = useCallback(async () => {
    let response = await S3SignedUrlFetch(`bpm-ml-data/v4/${kpi}/latest/spot_attribution.csv.gz`);
    if (response.status !== 200) {
      console.error(response);
      throw new Error(response.statusText);
    }
    let blob = await response.blob();
    download(blob, `spot_attribution_${kpi}.csv`, "text/csv");
  }, [kpi]);

  useEffect(() => {
    (async () => {
      let res = await ToolsLambdaFetch("/s3_exists", {
        params: { bucket: "bpm-ml-data", key: `v4/${kpi}/latest/spot_attribution.csv.gz` },
      });
      let existObj = await awaitJSON(res);
      setSpotAttributionExists(existObj.exists);
    })();
  }, [kpi]);

  // Fetch data for page
  useEffect(() => {
    (async () => {
      try {
        setDataMap({});
        setTop("auto");
        const dateToUse = overrideDate(company, week);
        let spikeData = await getSpikeChartData(kpi, dateToUse);
        if (!spikeData) {
          let error = new Error("No data available for this week, please select another");
          setError({ message: error.message, reportError: error });
          spikeData = [];
        }
        let spikeChart = spikeData.map(item => {
          let time = Dfns.parseISO(item.formattedTime.split(" ")[0]).getTime();
          let creativeInfo = [];
          if (item.creativeInfo) {
            for (let airing of item.creativeInfo) {
              // If on a test company, randomly use a fake creative so that real client creative names/thumbnails aren't displayed.
              if (isTestCompany) {
                const random = Math.floor(Math.random() * TEST_CREATIVES.length);
                const { name } = TEST_CREATIVES[random];
                creativeInfo.push({ ...airing, timeslot: time, creative: name });
              } else {
                creativeInfo.push({ ...airing, timeslot: time });
              }
            }
          }
          if (R.length(creativeInfo)) {
            return { ...item, time, creativeInfo };
          }
          return { ...item, time };
        });
        setDataMap(current => {
          return { ...current, [dataKey]: spikeChart };
        });
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [kpi, week, setError, dataKey, isTestCompany, company]);

  // Construct table data
  const tableData = useMemo(() => {
    let tableData = [];
    if (data) {
      let unflattened = R.pipe(
        R.filter(item => item.dotValue),
        R.pluck("creativeInfo"),
        R.values
      )(data);

      if (unflattened) {
        let odd = false;
        for (let spotList of unflattened) {
          for (let spot of spotList) {
            tableData.push({ ...spot, odd });
          }
          odd = !odd;
        }
      }
    }

    return tableData;
  }, [data]);

  // Scroll to row after selecting dot on chart
  const stickyTableScrollToRef = useRef();
  useLayoutEffect(() => {
    if (R.length(tableData) > 0 && stickyTableScrollToRef.current) {
      for (let i = 0; i < tableData.length; ++i) {
        if (R.path([i, "timeslot"], tableData) === selectedPoint) {
          stickyTableScrollToRef.current(i, 0);
          break;
        }
      }
    }
  }, [tableData, selectedPoint]);

  // Zoom Out
  const zoomOut = () => {
    setLeft("dataMin");
    setRight("dataMax");
    setBottom(0);
    setTop("auto");
  };

  // Custom Tooltip
  const HoverTooltip = React.memo(({ payload }) => {
    let time = useMemo(() => {
      if (!R.isEmpty(payload)) {
        return R.pipe(Dfns.format(TOOLTIP_DATE_FORMAT))(
          new Date(payload[0].payload.formattedTime.split(" ")[0])
        );
      }
      return null;
    }, [payload]);
    if (creativeTooltipProps) {
      let isci;
      for (let creative of R.keys(creativeMap)) {
        if (creativeMap[creative].name.replace(/\s/g, "") === creativeTooltipProps.creative) {
          isci = creativeMap[creative].file;
          break;
        }
      }

      if (isTestCompany) {
        isci = getTestIsci(creativeTooltipProps);
      }

      let { network } = creativeTooltipProps;

      let windowWidth = window.innerWidth;

      let classes = ["creativeTooltip"];
      if (
        creativeTooltipProps.fromTable &&
        (creativeTooltipProps.timeslot > right || creativeTooltipProps.timeslot < left)
      ) {
        return <div></div>;
      }

      if (
        creativeTooltipProps.fromTable &&
        (coordinateMap[creativeTooltipProps.timeslot] || {}).x > 0.65 * windowWidth
      ) {
        classes.push("flipTooltip");
      }

      const creativeTimeStr = creativeTooltipProps.time.split(" ")[0];
      return (
        <div className={classes.join(" ")}>
          <div className="creativeThumbnail">
            <Img
              src={getCreativeThumbnail(company, isci)}
              unloader={<div className="tooltipCreativeImageUnloader">{isci}</div>}
            />
          </div>
          <div className="spotInfo">
            <div className="spotNetwork">
              <Img
                src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
                unloader={<div className="tooltipNetworkImageUnloader">{network}</div>}
              />
            </div>
            <div className="spotTime">
              {R.pipe(Dfns.parseISO, Dfns.format(TABLE_DATE_FORMAT))(creativeTimeStr)}
              {creativeTooltipProps.time.split(" ").length > 1
                ? creativeTooltipProps.time.replace(creativeTimeStr, "")
                : ""}
            </div>
            <div className="kpi">
              <span className="kpiLabel">KPI: </span>
              <span className="kpiCount">{creativeTooltipProps.kpi}</span>
            </div>
          </div>
        </div>
      );
    }

    let count;
    if (payload.length) {
      count = (payload[0] || {}).payload.kpiCount;
    }

    return (
      <div className="hoverTooltip">
        <div className="time">
          <span className="timeLabel">Time: </span>
          <span className="timeDate">{time}</span>
        </div>
        <div className="kpi">
          <span className="kpiLabel">KPI: </span>
          <span className="kpiCount">{count}</span>
        </div>
      </div>
    );
  });

  // Render dots on area chart
  const renderDot = useCallback(
    props => {
      if (props.payload.dotValue) {
        return (
          <circle
            key={props.cx}
            cx={props.cx}
            cy={props.cy}
            r={5}
            fill={DOT_FILL}
            fillOpacity={1}
            stroke={DOT_BORDER}
            strokeWidth={3}
            onClick={() => {
              if (selectedPoint === props.payload.time) {
                setSelectedPoint(null);
                setCreativeTooltipProps(null);
                return;
              }
              setSelectedPoint(props.payload.time);
            }}
            onMouseOver={() => setCreativeTooltipProps(props.payload.creativeInfo[0])}
            onMouseOut={() => setCreativeTooltipProps(null)}
          />
        );
      }
      return null;
    },
    [selectedPoint]
  );

  const tooltipProps = useMemo(() => {
    if (creativeTooltipProps && creativeTooltipProps.fromTable) {
      let coordinate = coordinateMap[creativeTooltipProps.timeslot];
      return {
        position: coordinate,
        payload: {
          payload: creativeTooltipProps,
        },
      };
    }
    return {};
  }, [creativeTooltipProps, coordinateMap]);

  return (
    <Page
      title="Spike Chart"
      pageType="Spike Chart"
      actions={
        <div className="spikeChartActions">
          <KpiPickerLegacy
            onChange={newKpi => {
              setKpi(newKpi);
            }}
            defaultKpi={spikeKpis ? spikeKpis[0] : kpi}
            customFilter={kpis => {
              return R.isEmpty(R.filter(kpi => spikeKpis && R.includes(kpi.id, spikeKpis), kpis))
                ? kpis
                : R.filter(kpi => spikeKpis && R.includes(kpi.id, spikeKpis), kpis);
            }}
          />
          <div className="rightAlignedActions">
            <SingleDatePicker
              small
              mondayOnly
              date={week}
              isOutsideRange={date => date > CUTOFF_DATE}
              onChange={date => {
                setSelectedPoint(null);
                setCreativeTooltipProps(null);
                setWeek(date);
              }}
            />
            <DropdownButton
              size="sm"
              variant="light"
              title={<MdMoreVert className="dropdownIcon" />}
              className="dropdownButton"
            >
              <Dropdown.Item onSelect={() => downloadCSV()}>CSV</Dropdown.Item>
              <Dropdown.Item onSelect={() => download(pngUri("spike_chart"), `${kpi}_spike_chart`)}>
                PNG
              </Dropdown.Item>
              {spotAttributionExists && (
                <Dropdown.Item onSelect={() => downloadSpotAttribution()}>
                  Spot Attribution
                </Dropdown.Item>
              )}
            </DropdownButton>
          </div>
        </div>
      }
    >
      <div className="spikeChartPageContainer">
        {data ? (
          !R.isEmpty(data) ? (
            <>
              <div
                className={`spikeChart${creativeTooltipProps ? " hoverTooltipMode" : ""}`}
                id="spike_chart"
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <InnerSpikeChart
                      bottom={bottom}
                      chartData={data}
                      fillColor={AREA_FILL}
                      fillOpacity={0.5}
                      grid={{ vertical: false, stroke: "#b9bed2", strokeWidth: 0.35 }}
                      height={height}
                      kpi={kpi}
                      left={left}
                      margin={{ top: 55, bottom: 30, left: 25, right: 50 }}
                      rechartsToolTip={
                        <RechartsTooltip
                          {...tooltipProps}
                          content={({ label, payload }) => {
                            return <HoverTooltip label={label} payload={payload} />;
                          }}
                          isAnimationActive={false}
                        />
                      }
                      ref={chartRef}
                      renderDot={renderDot}
                      returnInner={true}
                      right={right}
                      setBottom={setBottom}
                      setLeft={setLeft}
                      setRight={setRight}
                      setTop={setTop}
                      strokeColor={AREA_FILL}
                      top={top}
                      width={width}
                      xAxisLine={false}
                      xAxisPadding={{ left: 25, right: 25 }}
                      xAxisTickLine={false}
                      yAxisLine={false}
                      yAxisTickCount={5}
                      yAxisTickLine={false}
                    />
                  )}
                </AutoSizer>
                <div className="spikeChartLegend">
                  {left === "dataMin" && right === "dataMax" ? (
                    <div className="legendExplainer">* Click and drag bar to zoom in</div>
                  ) : (
                    <Button className="zoomOutButton" onClick={() => zoomOut()}>
                      <MdClose size={22} className="exitIcon" />
                      <div className="zoomOutText">Reset to Default</div>
                    </Button>
                  )}
                </div>
              </div>
              <div className="spotTable">
                <BPMTable
                  alternateColors={false}
                  scrollToRef={stickyTableScrollToRef}
                  data={tableData}
                  filterBar={false}
                  headers={[
                    {
                      label: "Spot Time",
                      name: "time",
                      width: 275,
                      nonInteractive: true,
                      renderer: data => {
                        let classes = [];

                        if (data.timeslot === selectedPoint) {
                          classes.push("selectedSpot");
                        }
                        if (data.odd) {
                          classes.push("odd");
                        }

                        const dataTimeStr =
                          data && data.time && data.time.length > 0 ? data.time.split(" ")[0] : "";
                        return (
                          <div
                            className={classes.join(" ")}
                            onMouseOver={() => {
                              setCreativeTooltipProps({ ...data, fromTable: true });
                            }}
                            onMouseOut={() => {
                              setCreativeTooltipProps(null);
                            }}
                          >
                            {dataTimeStr &&
                              dataTimeStr.length > 0 &&
                              R.pipe(
                                Dfns.parseISO,
                                Dfns.format(TABLE_DATE_FORMAT)
                              )(dataTimeStr)}{" "}
                            {data && data.time && data.time.split(" ").length > 1
                              ? data.time.replace(dataTimeStr, "")
                              : ""}
                          </div>
                        );
                      },
                    },
                    {
                      label: "Network",
                      name: "network",
                      width: 180,
                      nonInteractive: true,
                      renderer: data => {
                        let classes = ["logoContainer"];
                        if (data.timeslot === selectedPoint) {
                          classes.push("selectedSpot");
                        }
                        if (data.odd) {
                          classes.push("odd");
                        }
                        return (
                          <div
                            className={classes.join(" ")}
                            onMouseOver={() => {
                              setCreativeTooltipProps({ ...data, fromTable: true });
                            }}
                            onMouseOut={() => {
                              setCreativeTooltipProps(null);
                            }}
                          >
                            <OverlayTrigger
                              placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                              overlay={<Tooltip>{data.network}</Tooltip>}
                            >
                              <div className="networkLogo">
                                <Img
                                  src={`https://cdn.blisspointmedia.com/networks/${data.network}.png`}
                                  unloader={
                                    <div className="networkImageUnloader">{data.network}</div>
                                  }
                                />
                              </div>
                            </OverlayTrigger>
                            {data.avail === "L" && <div className="avail">{`(${data.avail})`}</div>}
                          </div>
                        );
                      },
                    },
                    {
                      label: "Cost",
                      name: "cost",
                      width: 100,
                      nonInteractive: true,
                      renderer: data => {
                        let classes = [];
                        if (data.timeslot === selectedPoint) {
                          classes.push("selectedSpot");
                        }
                        if (data.odd) {
                          classes.push("odd");
                        }
                        return (
                          <div
                            className={classes.join(" ")}
                            onMouseOver={() => {
                              setCreativeTooltipProps({ ...data, fromTable: true });
                            }}
                            onMouseOut={() => {
                              setCreativeTooltipProps(null);
                            }}
                          >{`$${parseInt(data.cost).toLocaleString()}`}</div>
                        );
                      },
                    },
                    {
                      label: "Program",
                      name: "program",
                      flex: 1,
                      nonInteractive: true,
                      renderer: data => {
                        let classes = [];
                        if (data.timeslot === selectedPoint) {
                          classes.push("selectedSpot");
                        }
                        if (data.odd) {
                          classes.push("odd");
                        }
                        return (
                          <div
                            className={classes.join(" ")}
                            onMouseOver={() => {
                              setCreativeTooltipProps({ ...data, fromTable: true });
                            }}
                            onMouseOut={() => {
                              setCreativeTooltipProps(null);
                            }}
                          >
                            {data.program}
                          </div>
                        );
                      },
                    },
                    {
                      label: "Creative",
                      name: "creative",
                      flex: 1,
                      nonInteractive: true,
                      renderer: data => {
                        let classes = ["creativeContainer"];
                        if (data.timeslot === selectedPoint) {
                          classes.push("selectedSpot");
                        }
                        if (data.odd) {
                          classes.push("odd");
                        }

                        let isci;
                        for (let creative of R.keys(creativeMap)) {
                          if (creativeMap[creative].name.replace(/\s/g, "") === data.creative) {
                            isci = creativeMap[creative].file;
                            break;
                          }
                        }

                        if (isTestCompany) {
                          isci = getTestIsci(data);
                        }

                        return (
                          <div
                            className={classes.join(" ")}
                            onMouseOver={() => {
                              setCreativeTooltipProps({ ...data, fromTable: true });
                            }}
                            onMouseOut={() => {
                              setCreativeTooltipProps(null);
                            }}
                          >
                            <div className="creativeImage">
                              <Img
                                src={getCreativeThumbnail(company, isci)}
                                unloader={<div className="creativeImageUnloader">{isci}</div>}
                              />
                            </div>
                            <div className="creativeName">{`${data.creative} (${data.length}s)`}</div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </>
          ) : (
            <div className="noSpikeData">No data for the selected week.</div>
          )
        ) : (
          <FullPageSpinner />
        )}
      </div>
    </Page>
  );
};

export default SpikeChart;
