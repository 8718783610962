import "./PercentChange.scss";
import { MdCallMade } from "react-icons/md";
import React from "react";

interface PercentChangeProps {
  decimals?: number;
  percentChange: number;
  size: "sm" | "lg";
}

const PercentChange: React.FC<PercentChangeProps> = React.memo(
  ({ decimals = 0, percentChange, size = "lg" }) => {
    const scale = percentChange >= 1000000 ? "M" : percentChange >= 1000 ? "K" : "";
    const scaleDivisor = scale === "M" ? 1000000 : scale === "K" ? 1000 : 1;
    const scaledPercentChange = (percentChange * 1.0) / scaleDivisor;
    const inverted = scaledPercentChange < 0 ? "inverted" : "";
    return (
      <div className={`percentChange ${inverted} ${size}`}>
        <div className="percentChangeIcon">
          <MdCallMade className={`percentArrow ${inverted}`} />
        </div>
        <div className="percentChangeLabel">
          {`${scaledPercentChange.toFixed(decimals)}${scale}%`}
        </div>
      </div>
    );
  }
);

export default PercentChange;
