// Experiment for breaking down creative insights into smaller categories.
const insightsCategoryCompanies: string[] = [
  "blisspoint",
  "instacart",
  "hims",
  "hers",
  // Add companies here!
];

export const shouldEnableInsightsCategories = (company: string): boolean => {
  return insightsCategoryCompanies.includes(company);
};
