import { AdOpsToolProps } from "../AdOps";
import { awaitJSON, FlashtalkingLambdaFetch } from "../../utils/fetch-utils";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Card, Dropdown, DropdownOption, InfoTooltip, Spinner } from "../../Components";
import * as R from "ramda";
import React, { useState, useCallback, useEffect } from "react";

interface FlashtalkingCreativeLibraryRow {
  advertiser_name: string;
  company: string;
  creative_library_id: number;
  library_name: string;
}

const FlashtalkingCreativeLibraryAdder: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const [advertiserName, setAdvertiserName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [creativeLibraryID, setCreativeLibraryID] = useState<number>();
  const [libraries, setLibraries] = useState<FlashtalkingCreativeLibraryRow[]>();
  const [selectedLibrary, setSelectedLibrary] = useState<FlashtalkingCreativeLibraryRow>();
  const [name, setName] = useState<string>();

  const { setError } = utils;
  const clMap = R.indexBy(
    library => `${library.creative_library_id}`,
    R.defaultTo([], libraries) as FlashtalkingCreativeLibraryRow[]
  );

  useEffect(() => {
    (async () => {
      if (R.isNil(libraries)) {
        const librariesResp = await FlashtalkingLambdaFetch("/getFlashtalkingCreativeLibraries", {
          method: "GET",
          params: {},
        });
        setLibraries((await awaitJSON(librariesResp)) as FlashtalkingCreativeLibraryRow[]);
      }
    })();
  }, [libraries]);

  const addFlashtalkingCreativeLibrary = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await FlashtalkingLambdaFetch("/addFlashtalkingCreativeLibrary", {
        method: "POST",
        body: {
          advertiser_name: advertiserName,
          company,
          creative_library_id: creativeLibraryID,
          library_name: name,
        },
      });
      setLibraries(undefined);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: reportError.message,
        reportError,
      });
    }
    setActiveAPICall(false);
  }, [advertiserName, company, creativeLibraryID, name, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">
        Add/Update Flashtalking Creative Libraries
        <InfoTooltip size="reg">
          Use this tool to update the company name of creative libraries
        </InfoTooltip>
      </div>
      <Dropdown
        value={selectedLibrary ? `${selectedLibrary?.creative_library_id}` : ""}
        options={
          R.map(
            library => ({
              value: `${library.creative_library_id}`,
              label: `${library.library_name} (${library.creative_library_id})`,
            }),
            R.defaultTo([], libraries) as FlashtalkingCreativeLibraryRow[]
          ) as DropdownOption[]
        }
        onChange={id => {
          setSelectedLibrary(clMap[id]);
          setAdvertiserName(clMap[id].advertiser_name);
          setCompany(clMap[id].company);
          setCreativeLibraryID(clMap[id].creative_library_id);
          setName(clMap[id].library_name);
        }}
      />
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Creative Library ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={!R.isNil(creativeLibraryID) ? creativeLibraryID : ""}
          onChange={e => {
            if (e.target.value === "") {
              setCreativeLibraryID(undefined);
            }
            if (!isNaN(parseInt(e.target.value))) {
              setCreativeLibraryID(parseInt(e.target.value));
            }
          }}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Library Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={name} onChange={e => setName(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Advertiser Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={advertiserName ? advertiserName : ""}
          onChange={e => setAdvertiserName(e.target.value)}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company ? company : ""} onChange={e => setCompany(e.target.value)} />
      </InputGroup>
      <Button className="linearAdOpsElem" onClick={addFlashtalkingCreativeLibrary}>
        {activeAPICall ? <Spinner /> : "Add"}
      </Button>
    </Card>
  );
};

export default FlashtalkingCreativeLibraryAdder;
