import React, { useMemo, useContext } from "react";

import * as Dfns from "date-fns/fp";
import * as R from "ramda";

import { ComposedChart, Tooltip, Line, Bar, YAxis, XAxis, Label, Cell } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";

import { makeMemoizedGetter, abbreviateNumber } from "../utils/data";

import { shadeColor, moneyColor } from "../utils/colors";

import "./DeliveryChart.scss";
import {
  DailyDeliveryDataMap,
  TotalSummary,
  ActiveKeyMap,
  ColorMap,
  OtherMap,
  StreamingDeliveryContext,
} from "./StreamingDelivery";

const POWERPOINT_FONT = "Century Gothic, CenturyGothic, AppleGothic, sans-serif";
const REGULAR_FONT = "Graphik";
const GREYED_OUT_GREY = "#C7C7C7";
const OTHER_COLOR = "#666666";

const getTooltipDate = makeMemoizedGetter({
  calculate: R.pipe(Dfns.parseISO, Dfns.format("MMM do, yyyy")),
});
const getTickDate = makeMemoizedGetter({
  calculate: R.pipe(Dfns.parseISO, Dfns.format("MMM do")),
});

interface YAxisTickProps {
  x: number;
  y: number;
  value: number;
  percent?: boolean;
  dollar?: boolean;
  invert?: boolean;
  powerpoint?: boolean;
}
const YAxisTick = React.memo<YAxisTickProps>(
  ({ percent, dollar, invert, x, y, value, powerpoint }) => {
    let display: string | number = "";
    if (percent) {
      display = `${value * 100}%`;
    } else {
      display = abbreviateNumber(value);
      if (dollar) {
        display = `$${display}`;
      }
    }

    let style = {
      fontSize: powerpoint ? "10px" : "12px",
      fill: "#666666",
      fontFamily: powerpoint ? POWERPOINT_FONT : "Graphik",
    };
    return (
      <text
        textAnchor={invert ? "start" : "end"}
        x={x}
        y={y}
        fontFamily={powerpoint ? POWERPOINT_FONT : "Graphik"}
        style={style}
      >
        {display}
      </text>
    );
  }
);

const prettyNumber = (data: number, isSpend = false, cents = false) => {
  let formattedValue = "";
  if (isSpend) {
    let num: string;
    if (cents) {
      num = data.toFixed(2);
    } else {
      num = Math.round(data).toLocaleString();
    }
    formattedValue = `$${num}`;
  } else {
    formattedValue = Math.round(data / 1000).toLocaleString();
  }

  return formattedValue;
};

interface BarData {
  key: string;
  value: number;
  color: string;
  otherKeys?: string[];
}
interface ChartItem {
  date: string;
  cost: number;
  count: number;
  ecpm: number;
  [key: number]: BarData;
}
interface TooltipItem extends BarData {
  label: string;
  prettyVal: string;
}

interface TooltipData {
  ecpm: number;
  totalLabel: string;
  total: string;
  items: TooltipItem[];
}
interface BarTooltipProps {
  data: Record<string, TooltipData | undefined>;
  date: string;
  height: number;
  daily: boolean;
  dimension: string;
}
const BarTooltip = React.memo<BarTooltipProps>(({ date, data, height, daily, dimension }) => {
  let ourData = data[date];
  if (!ourData) {
    return null;
  }

  return (
    <div className="streamingDeliveryChartTooltip">
      <div className="cardTitle">
        {daily ? "" : <small className="wo">w/o</small>}
        {getTooltipDate(date)}
      </div>
      <div className="totalsSubtitle">
        <span>
          <span>eCPM:</span> {prettyNumber(ourData.ecpm, true, true)}
        </span>
        <span>
          <span>{ourData.totalLabel}:</span> {ourData.total}
        </span>
      </div>

      <div className="barItemListContainer" style={{ maxHeight: height - 145 }}>
        <div className="barItemList">
          {ourData.items.map(({ key, label, prettyVal, color }) => (
            <div key={key} className={dimension === "creative" ? "creative" : ""}>
              <div className="circle" style={{ backgroundColor: color }} />
              <span>{label}:</span>
              <span>{prettyVal}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

interface DeliveryChartProps {
  data: DailyDeliveryDataMap;
  totals: Record<string, TotalSummary>;
  activeKeyMap: ActiveKeyMap;
  colorMap: ColorMap;
  dimension: string;
  absolute: boolean;
  isSpend: boolean;
  daily: boolean;
  otherMap: OtherMap;
  hasTooltip?: boolean;
  powerpoint?: boolean;
  ref: any;
}

export default React.memo<DeliveryChartProps>(
  React.forwardRef(
    (
      {
        data,
        totals,
        activeKeyMap,
        otherMap,
        absolute = false,
        isSpend = false,
        colorMap,
        daily = false,
        dimension = "network_group",
        hasTooltip = true,
        powerpoint = false,
      },
      ref: any
    ) => {
      const { hoverKey } = useContext(StreamingDeliveryContext);

      const { chartData, tooltipData, activeKeyCount } = useMemo(() => {
        let activeKeyCount = 0;
        for (let val of R.values(activeKeyMap)) {
          if (val) {
            activeKeyCount++;
          }
        }
        let chartData: ChartItem[] = [];
        for (let date of R.keys(data)) {
          let groups = data[date];
          const { cost, count, ecpm } = totals[date];
          const ourOtherMap = otherMap[date] || {};
          let barData: BarData[] = [];
          let other: BarData | null = null;
          for (let key of R.keys(groups)) {
            if (!activeKeyMap[key]) {
              continue;
            }
            let value: number = R.path([key, isSpend ? "cost" : "count"], groups) || 0;
            if (ourOtherMap[key]) {
              if (other) {
                other.value += value;
                other.otherKeys?.push(key);
              } else {
                other = {
                  key: "Other",
                  value,
                  color: OTHER_COLOR,
                  otherKeys: [key],
                };
              }
            } else {
              let color = colorMap(key);

              barData.push({
                key,
                value,
                color,
              });
            }
          }
          barData = R.sort(R.ascend<BarData>(R.prop("value")), barData);
          if (other) {
            barData.unshift(other);
          }

          chartData.push({
            date,
            cost,
            count,
            ecpm,
            ...barData,
          });
        }
        chartData = R.sort(R.ascend<ChartItem>(R.prop("date")), chartData);
        let tooltipData: Record<string, TooltipData> = {};
        for (let chartItem of chartData) {
          let items: TooltipItem[] = [];
          for (let i = 0; i < activeKeyCount; ++i) {
            let info = chartItem[i];
            if (R.isNil(info)) {
              break;
            }
            // Make the keys pretty creative names
            let label = info.key;
            if (dimension === "creative" && info.key !== "Other") {
              let [isci, creative, length] = info.key.split("_");
              label = `${creative} (${length}s) (${isci})`;
            }

            items.push({
              ...info,
              label,
              prettyVal: absolute
                ? prettyNumber(info.value, isSpend)
                : `${
                    Math.round((1000 * info.value) / (isSpend ? chartItem.cost : chartItem.count)) /
                    10
                  }%`,
            });
          }

          let { cost, count, ecpm, date } = chartItem;
          let total: string;
          let totalLabel: string;

          if (isSpend) {
            totalLabel = "Spend";
            total = prettyNumber(cost, true);
          } else {
            totalLabel = "Impressions (000's)";
            total = prettyNumber(count);
          }
          tooltipData[date] = {
            total,
            totalLabel,
            ecpm,
            items: R.reverse(items),
          };
        }
        return {
          activeKeyCount,
          chartData,
          tooltipData,
        };
      }, [data, activeKeyMap, colorMap, dimension, isSpend, totals, absolute, otherMap]);

      const barFillGetter = useMemo(
        () =>
          makeMemoizedGetter({
            calculate: ({
              hoverKey,
              keyIndex,
              dateIndex,
            }: {
              hoverKey: string | undefined;
              keyIndex: number;
              dateIndex: number;
            }) => {
              let cellData: BarData | undefined = R.path([dateIndex, keyIndex], chartData);
              if (cellData) {
                if (!hoverKey) {
                  return shadeColor(cellData.color, 0.2);
                } else if (hoverKey === cellData?.key) {
                  return cellData.color;
                } else if (cellData.otherKeys?.find(R.equals(hoverKey))) {
                  return OTHER_COLOR;
                } else {
                  return GREYED_OUT_GREY;
                }
              }
              return "";
            },
          }),
        [chartData]
      );

      const bars = useMemo(() => {
        let bars: JSX.Element[] = [];
        for (let keyIndex = 0; keyIndex < activeKeyCount; ++keyIndex) {
          let cells: JSX.Element[] = [];
          for (let dateIndex = 0; dateIndex < chartData.length; ++dateIndex) {
            let fill: string = barFillGetter({ hoverKey, keyIndex, dateIndex });
            cells.push(<Cell key={dateIndex} fill={fill} />);
          }
          bars.push(
            <Bar
              key={keyIndex}
              isAnimationActive={false}
              dataKey={val => R.path([keyIndex, "value"], val) || 0}
              stackId="a"
            >
              {cells}
            </Bar>
          );
        }
        return bars;
      }, [chartData, activeKeyCount, hoverKey, barFillGetter]);

      // Type is necessary because otherwise recharts gets pissed off. Typescript infers that
      // fontWeight and textAnchor are string,s and recharts needs them to be from a specific set of
      // strings.
      const axisLabelStyle = useMemo<{
        fontSize: string;
        fontFamily: string;
        fill: string;
        textAnchor: "middle";
        fontWeight: "bold" | number;
        userSelect: "none";
      }>(
        () => ({
          textAnchor: "middle",
          fontSize: powerpoint ? "14px" : "16px",
          fontFamily: powerpoint ? POWERPOINT_FONT : REGULAR_FONT,
          fill: "#4E4E50",
          fontWeight: 700,
          userSelect: "none",
        }),
        [powerpoint]
      );

      // Checks if data is empty
      const isEmpty = useMemo<boolean>(() => {
        return R.values(data).every(element => R.keys(element).length === 0);
      }, [data]);

      return (
        <div className="streamingDeliveryChart">
          {isEmpty ? (
            <div className="noDataLabel">No data found for selected date range and options</div>
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <ComposedChart
                  ref={ref}
                  data={chartData}
                  width={width}
                  height={height}
                  stackOffset={absolute ? "none" : "expand"}
                  barCategoryGap="25%"
                  margin={{ top: 15, right: 10, left: 10 }}
                >
                  {hasTooltip && (
                    <Tooltip
                      content={({ label }) => (
                        <BarTooltip
                          date={label}
                          data={tooltipData}
                          height={height}
                          daily={daily}
                          dimension={dimension}
                        />
                      )}
                      isAnimationActive={false}
                    />
                  )}
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={getTickDate}
                    interval={chartData.length <= 7 ? 0 : Math.floor(chartData.length / 8) - 1}
                    /// @ts-ignore https://github.com/recharts/recharts/pull/2275
                    fontFamily={powerpoint ? POWERPOINT_FONT : REGULAR_FONT}
                    fontSize="12px"
                  />
                  <YAxis
                    /// @ts-ignore between React and Recharts, someone is struggling. Recharts is
                    /// demanding the return type of this function be an SVGElement. It's an Element,
                    /// but it's returning a <text> element, which is an SVGElement. So one of the two
                    /// is confused.
                    tick={({ x, y, payload }) => (
                      <YAxisTick
                        x={x}
                        y={y}
                        value={payload.value}
                        percent={!absolute}
                        dollar={isSpend}
                        powerpoint={powerpoint}
                      />
                    )}
                    axisLine={false}
                    tickLine={false}
                    type="number"
                  >
                    <Label
                      /// @ts-ignore https://github.com/recharts/recharts/pull/2275
                      angle={-90}
                      position="insideLeft"
                      style={axisLabelStyle}
                    >
                      {isSpend ? "Spend" : "Impressions"}
                    </Label>
                  </YAxis>
                  <YAxis
                    yAxisId="right"
                    /// @ts-ignore see above comment for other YAxis
                    tick={({ x, y, payload }) => (
                      <YAxisTick
                        invert
                        dollar
                        x={x}
                        y={y}
                        value={payload.value}
                        powerpoint={powerpoint}
                      />
                    )}
                    axisLine={false}
                    tickLine={false}
                    orientation="right"
                    type="number"
                  >
                    <Label
                      /// @ts-ignore https://github.com/recharts/recharts/pull/2275
                      angle={90}
                      position="insideRight"
                      style={axisLabelStyle}
                    >
                      eCPM
                    </Label>
                  </YAxis>
                  {bars}
                  <Line
                    type="monotone"
                    isAnimationActive={false}
                    dot={false}
                    strokeDasharray="10 10"
                    strokeWidth={3}
                    dataKey="ecpm"
                    name="eCPM"
                    yAxisId="right"
                    stroke={moneyColor}
                  />
                </ComposedChart>
              )}
            </AutoSizer>
          )}
        </div>
      );
    }
  )
);
