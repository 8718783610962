import React from "react";
import { Area, Line, Bar, YAxis } from "recharts";
import { INACTIVE_COLOR, TICK_STYLE } from "../../TVADCrossChannel/homePageConstants";
import { abbreviateNumber } from "../../utils/data";
import { DateRange } from "../../utils/types";
import { getPrettyMetricName, makePrettyDateRange } from "../crossChannelUtils";
import { Brand60, Channel2, Channel3, Channel4 } from "../../utils/colors";

const COMPARISON_LINE_STROKE_COLORS = [Channel4, Brand60, Channel2, Channel3] as const;
const GREY_COLOR = "#CBD2E1";

interface CartesianComponentsResponse {
  bars: JSX.Element | undefined;
  lines: JSX.Element[];
  yAxes: JSX.Element[];
  legend: JSX.Element;
}

export const getCompareMetricsCartesianComponents = (
  primaryMetric: string,
  comparisonMetrics: string[],
  dateRange: DateRange,
  otherDateRange: DateRange,
  includeOtherDateRange: boolean,
  showPercentChange: boolean,
  hoveredLegendItem: string,
  setHoveredLegendItem: (metric: string) => void,
  focusedMetric: string,
  setFocusedMetric: (metric: string | ((prevState: string) => string)) => void,
  metricsToPrettyNameMap: Record<string, string> = {}
): CartesianComponentsResponse => {
  const hideYAxes = comparisonMetrics.length > 1 || showPercentChange; // Once we have 3 or more total metrics, hide the y axes. Or if we're showing percent change.
  const allMetrics = [primaryMetric, ...comparisonMetrics];
  let bars;
  let lines;
  let yAxes;
  /**
   * We only make a bar when comparing 2 metrics and only looking at one date range.
   * Otherwise, all metrics are lines.
   */
  if (comparisonMetrics.length <= 1 && !includeOtherDateRange) {
    yAxes = [];
    const primaryDataKey = `metrics.${primaryMetric}`;
    const comparisonDataKey = `metrics.${comparisonMetrics[0]}`;
    if (focusedMetric === "" || focusedMetric === primaryMetric) {
      bars = (
        <Bar
          dataKey={primaryDataKey}
          name={
            metricsToPrettyNameMap[primaryMetric]
              ? metricsToPrettyNameMap[primaryMetric]
              : getPrettyMetricName(primaryMetric)
          }
          stroke={
            hoveredLegendItem && hoveredLegendItem !== primaryMetric
              ? GREY_COLOR
              : COMPARISON_LINE_STROKE_COLORS[0]
          }
          fill={
            hoveredLegendItem && hoveredLegendItem !== primaryMetric
              ? GREY_COLOR
              : COMPARISON_LINE_STROKE_COLORS[0]
          }
          yAxisId={0}
        />
      );
      yAxes.push(
        <YAxis
          tick={TICK_STYLE}
          tickLine={false}
          tickFormatter={number =>
            `${["spend", "revenue"].includes(primaryMetric) ? "$" : ""}${abbreviateNumber(number)}`
          }
          axisLine={false}
          yAxisId={0}
        />
      );
    }
    if (focusedMetric === "" || focusedMetric === comparisonMetrics[0]) {
      lines = (
        <Line
          key={comparisonMetrics[0]}
          dataKey={comparisonDataKey}
          name={
            metricsToPrettyNameMap[comparisonMetrics[0]]
              ? metricsToPrettyNameMap[comparisonMetrics[0]]
              : getPrettyMetricName(comparisonMetrics[0])
          }
          type="monotone"
          strokeWidth={3}
          dot={false}
          isAnimationActive={false}
          stroke={
            hoveredLegendItem && hoveredLegendItem !== comparisonMetrics[0]
              ? GREY_COLOR
              : COMPARISON_LINE_STROKE_COLORS[1]
          }
          yAxisId={1}
        />
      );
      yAxes.push(
        <YAxis
          tick={TICK_STYLE}
          tickLine={false}
          tickFormatter={number =>
            `${["spend", "revenue"].includes(comparisonMetrics[0]) ? "$" : ""}${abbreviateNumber(
              number
            )}`
          }
          axisLine={false}
          yAxisId={1}
          orientation="right"
        />
      );
    }
  } else {
    const { lines: ourLines, yAxes: ourYAxes } = makeCompareMetricsLinesAndYAxes(
      allMetrics,
      includeOtherDateRange,
      hideYAxes,
      showPercentChange,
      hoveredLegendItem,
      focusedMetric,
      metricsToPrettyNameMap
    );
    lines = ourLines;
    yAxes = ourYAxes;
  }

  const legend = makeLegend(
    allMetrics,
    includeOtherDateRange,
    dateRange,
    otherDateRange,
    showPercentChange,
    hoveredLegendItem,
    setHoveredLegendItem,
    focusedMetric,
    setFocusedMetric,
    metricsToPrettyNameMap
  );

  return { bars, lines, yAxes, legend };
};

const makeCompareMetricsLinesAndYAxes = (
  metrics: string[],
  includeOtherDateRange: boolean,
  hideYAxes: boolean,
  showPercentChange: boolean,
  hoveredLegendItem,
  focusedMetric: string,
  metricsToPrettyNameMap: Record<string, string> = {}
) => {
  let lines: JSX.Element[] = [];
  let yAxes: JSX.Element[] = [];
  for (let i = 0; i < metrics.length; i++) {
    const metric = metrics[i];
    if (focusedMetric === "" || focusedMetric === metric) {
      if (showPercentChange) {
        lines.push(
          <Line
            key={metric}
            dataKey={`metrics.${metric}`}
            name={
              metricsToPrettyNameMap[metric]
                ? metricsToPrettyNameMap[metric]
                : getPrettyMetricName(metric)
            }
            type="monotone"
            strokeWidth={3}
            dot={false}
            isAnimationActive={false}
            stroke={
              hoveredLegendItem && hoveredLegendItem !== metric
                ? GREY_COLOR
                : COMPARISON_LINE_STROKE_COLORS[i]
            }
            yAxisId={i}
          />
        );
      } else {
        const dataKey = `metrics.${metric}`;
        const otherDataKey = `metrics_other.${metric}`;
        lines.push(
          <Line
            key={metric}
            dataKey={dataKey}
            name={
              metricsToPrettyNameMap[metric]
                ? metricsToPrettyNameMap[metric]
                : getPrettyMetricName(metric)
            }
            type="monotone"
            strokeWidth={3}
            dot={false}
            isAnimationActive={false}
            stroke={
              hoveredLegendItem && hoveredLegendItem !== metric
                ? GREY_COLOR
                : COMPARISON_LINE_STROKE_COLORS[i]
            }
            yAxisId={i}
          />
        );
        if (includeOtherDateRange) {
          lines.push(
            <Line
              key={`${metric}_other`}
              dataKey={otherDataKey}
              name={
                metricsToPrettyNameMap[metric]
                  ? metricsToPrettyNameMap[metric]
                  : getPrettyMetricName(metric)
              }
              type="monotone"
              strokeWidth={3}
              dot={false}
              isAnimationActive={false}
              stroke={
                hoveredLegendItem && hoveredLegendItem !== metric
                  ? GREY_COLOR
                  : COMPARISON_LINE_STROKE_COLORS[i]
              }
              yAxisId={i}
              strokeDasharray="4"
            />
          );
        }
        yAxes.push(
          <YAxis
            key={metric}
            tick={TICK_STYLE}
            tickLine={false}
            tickFormatter={number =>
              showPercentChange
                ? `${number}%`
                : `${["spend", "revenue"].includes(metric) ? "$" : ""}${abbreviateNumber(number)}`
            }
            axisLine={false}
            yAxisId={i}
            {...(i > 0 ? { orientation: "right" } : {})} // Only the first axis is on the left.
            {...(hideYAxes ? { hide: true } : {})}
          />
        );
      }
    }
  }
  return { lines, yAxes };
};

const makeLegend = (
  metrics: string[],
  includeOtherDateRange: boolean,
  dateRange: DateRange,
  otherDateRange: DateRange,
  showPercentChange: boolean,
  hoveredLegendItem: string,
  setHoveredLegendItem: (metric: string) => void,
  focusedMetric: string,
  setFocusedMetric: (metric: string | ((prevState: string) => string)) => void,
  metricsToPrettyNameMap: Record<string, string> = {}
) => {
  if (showPercentChange) {
    return (
      <div className="cm-Legend">
        <div className="legendDateRange">% Change</div>
        {metrics.map((metric, i) => {
          return (
            <LegendItem
              key={metric}
              index={i}
              metric={metric}
              hoveredLegendItem={hoveredLegendItem}
              setHoveredLegendItem={setHoveredLegendItem}
              dotted={false}
              focusedMetric={focusedMetric}
              setFocusedMetric={setFocusedMetric}
              metricsToPrettyNameMap={metricsToPrettyNameMap}
            />
          );
        })}
      </div>
    );
  }
  return (
    <div className="cm-Legend">
      {includeOtherDateRange && (
        <div className="legendDateRange">{makePrettyDateRange(dateRange.start, dateRange.end)}</div>
      )}
      {metrics.map((metric, i) => {
        return (
          <LegendItem
            key={metric}
            index={i}
            metric={metric}
            hoveredLegendItem={hoveredLegendItem}
            setHoveredLegendItem={setHoveredLegendItem}
            dotted={false}
            focusedMetric={focusedMetric}
            setFocusedMetric={setFocusedMetric}
            metricsToPrettyNameMap={metricsToPrettyNameMap}
          />
        );
      })}
      {includeOtherDateRange && (
        <div className="legendDateRange">
          {makePrettyDateRange(otherDateRange.start, otherDateRange.end)}
        </div>
      )}
      {includeOtherDateRange &&
        metrics.map((metric, i) => {
          return (
            <LegendItem
              key={metric}
              index={i}
              metric={metric}
              hoveredLegendItem={hoveredLegendItem}
              setHoveredLegendItem={setHoveredLegendItem}
              dotted={true}
              focusedMetric={focusedMetric}
              setFocusedMetric={setFocusedMetric}
              metricsToPrettyNameMap={metricsToPrettyNameMap}
            />
          );
        })}
    </div>
  );
};

interface LegendItemProps {
  index: number;
  metric: string;
  hoveredLegendItem: string;
  setHoveredLegendItem: (metric: string) => void;
  dotted: boolean;
  focusedMetric: string;
  setFocusedMetric: (metric: string | ((prevState: string) => string)) => void;
  metricsToPrettyNameMap?: Record<string, string>;
}

const LegendItem: React.FC<LegendItemProps> = React.memo(
  ({
    index,
    metric,
    hoveredLegendItem,
    setHoveredLegendItem,
    dotted,
    focusedMetric,
    setFocusedMetric,
    metricsToPrettyNameMap = {},
  }) => {
    let styles: Record<string, any> = {
      height: 3,
      width: 18,
    };
    const resolvedColor =
      focusedMetric === ""
        ? hoveredLegendItem === "" || hoveredLegendItem === metric
          ? COMPARISON_LINE_STROKE_COLORS[index]
          : GREY_COLOR
        : focusedMetric === metric
        ? COMPARISON_LINE_STROKE_COLORS[index]
        : GREY_COLOR;

    if (dotted) {
      styles = {
        ...styles,
        borderTop: `dotted 3px ${resolvedColor}`,
      };
    } else {
      styles = {
        ...styles,
        backgroundColor: resolvedColor,
      };
    }

    return (
      <div
        className="legendItem"
        key={metric}
        onClick={() => {
          setFocusedMetric(f => (f === metric ? "" : metric));
          setHoveredLegendItem("");
        }}
        onMouseOver={() => {
          if (focusedMetric === "") {
            setHoveredLegendItem(metric);
          }
        }}
        onMouseOut={() => {
          if (focusedMetric === "") {
            setHoveredLegendItem("");
          }
        }}
      >
        <hr className="colorKey" style={styles} />
        <div className={focusedMetric === "" || focusedMetric === metric ? "" : "unselected"}>
          {metricsToPrettyNameMap[metric]
            ? metricsToPrettyNameMap[metric]
            : getPrettyMetricName(metric)}
        </div>
      </div>
    );
  }
);

export const getDeliveryBreakdownCartesianComponents = (
  metric: string,
  channels: string[],
  includeOtherDateRange: boolean,
  channelColors: Record<string, string>,
  hoverChannel: string,
  focusedChannel: string
): JSX.Element[] => {
  let chartElements: JSX.Element[] = [];

  // If not comparing date ranges, we show an area graph. Otherwise line graph.
  if (!includeOtherDateRange) {
    channels.forEach((channel, i) => {
      if (focusedChannel === "" || focusedChannel === channel) {
        const resolvedColor =
          focusedChannel === channel
            ? channelColors[channel]
            : hoverChannel === "" || hoverChannel === channel
            ? channelColors[channel]
            : INACTIVE_COLOR;
        chartElements.push(
          <Area
            activeDot={false}
            dataKey={`channels.${channel}.${metric}`}
            fill={resolvedColor}
            fillOpacity={1}
            isAnimationActive={false}
            key={channel}
            name={channel}
            stackId="1"
            stroke={resolvedColor}
            strokeWidth={0}
            type="monotone"
          />
        );
      }
    });
  } else {
    channels.forEach((channel, i) => {
      if (focusedChannel === "" || focusedChannel === channel) {
        const resolvedColor =
          focusedChannel === channel
            ? channelColors[channel]
            : hoverChannel === "" || hoverChannel === channel
            ? channelColors[channel]
            : INACTIVE_COLOR;
        chartElements.push(
          <Line
            dataKey={`channels.${channel}.${metric}`}
            dot={false}
            isAnimationActive={false}
            key={channel}
            name={channel}
            stroke={resolvedColor}
            strokeWidth={3}
            type="monotone"
          />
        );
        chartElements.push(
          <Line
            dataKey={`channels_other.${channel}.${metric}`}
            dot={false}
            isAnimationActive={false}
            key={`${channel}_other`}
            name={channel}
            stroke={resolvedColor}
            strokeDasharray="4"
            strokeWidth={3}
            type="monotone"
          />
        );
      }
    });
  }

  return chartElements;
};

export const getPerformanceBreakdownCartesianComponents = (
  metric: string,
  channels: string[],
  includeOtherDateRange: boolean,
  channelColors: Record<string, string>,
  hoverChannel: string,
  focusedChannel: string
): JSX.Element[] => {
  let chartElements: JSX.Element[] = [];

  channels.forEach((channel, i) => {
    if (focusedChannel === "" || focusedChannel === channel) {
      const resolvedColor =
        focusedChannel === channel
          ? channelColors[channel]
          : hoverChannel === "" || hoverChannel === channel
          ? channelColors[channel]
          : INACTIVE_COLOR;
      chartElements.push(
        <Line
          dataKey={`channels.${channel}.${metric}`}
          dot={false}
          isAnimationActive={false}
          key={channel}
          name={channel}
          stroke={resolvedColor}
          strokeWidth={3}
          type="monotone"
        />
      );

      // If comparing date ranges, add additional comparison line.
      if (includeOtherDateRange) {
        chartElements.push(
          <Line
            dataKey={`channels_other.${channel}.${metric}`}
            dot={false}
            isAnimationActive={false}
            key={`${channel}_other`}
            name={channel}
            stroke={resolvedColor}
            strokeDasharray="4"
            strokeWidth={3}
            type="monotone"
          />
        );
      }
    }
  });

  return chartElements;
};
