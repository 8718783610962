import React, { useState, useCallback, useEffect, useRef } from "react";

import { StateSetter } from "../types";

export const useLocalStorageState = <T>(
  key: string,
  init?: T | ((previous?: T) => T)
): [T | undefined, StateSetter<T | undefined>, () => void] => {
  const [state, setStateRaw] = useState<T | undefined>(() => {
    let localVal = window.localStorage.getItem(key);
    if (localVal) {
      try {
        let val = JSON.parse(localVal) as T;
        if (init instanceof Function) {
          return init(val);
        }
        return val;
      } catch (e) {
        console.error("Failed to parse local storage value. Error:", e.message);
      }
    }
    if (init instanceof Function) {
      return init();
    }
    return init;
  });

  const setLocalStorageState: StateSetter<T | undefined> = useCallback(
    val => {
      setStateRaw(oldVal => {
        let newVal: T | undefined;
        if (val instanceof Function) {
          newVal = val(oldVal);
        } else {
          newVal = val;
        }
        window.localStorage.setItem(key, JSON.stringify(newVal));
        return newVal;
      });
    },
    [key]
  );

  const clearLocalStorageState = useCallback(() => {
    setStateRaw(undefined);
    window.localStorage.removeItem(key);
  }, [key]);

  return [state, setLocalStorageState, clearLocalStorageState];
};

type StateInit<T> = T | (() => T);

type MapType<T extends string, U> = Partial<Record<T, U>>;
type MapInit<T extends string, U> = StateInit<MapType<T, U>>;
type MapReturn<T extends string, U> = [
  MapType<T, U>,
  (key: T, value: U) => void,
  StateSetter<MapType<T, U>>
];

export const useMap = <Key extends string, Value>(
  init?: MapInit<Key, Value>
): MapReturn<Key, Value> => {
  const [map, setMap] = useState<MapType<Key, Value>>(init || {});

  const setVal = useCallback((key: Key, value: Value) => {
    setMap(map => ({
      ...map,
      [key]: value,
    }));
  }, []);

  return [map, setVal, setMap];
};

export const useLocalStorageMap = <Key extends string, Value>(
  localStorageKey: string,
  init: MapInit<Key, Value>
): MapReturn<Key, Value> => {
  const [localStorageState, setLocalStorageState] = useLocalStorageState(localStorageKey, init);
  const [map, setMapElem, setMap] = useMap(localStorageState);

  useEffect(() => {
    if (map) {
      setLocalStorageState(map);
    }
  }, [map, setLocalStorageState]);
  return [map, setMapElem, setMap];
};

export const useList = <T>(): [T[], StateSetter<T[]>, (item: T) => void] => {
  const [list, setList] = useState<T[]>([]);

  return [list, setList, item => setList([...list, item])];
};

export const useStateFunction = <T>(initFunc: T): [T, (func: T) => void] => {
  const [funcObj, setFuncObj] = useState<{ func: T }>({
    func: initFunc,
  });

  return [funcObj.func, func => setFuncObj({ func })];
};

interface UseDebugEffect {
  (
    effect: Parameters<typeof React.useEffect>[0],
    deps: Parameters<typeof React.useEffect>[1],
    label?: string
  ): ReturnType<typeof React.useEffect>;
}

export const useDebugEffect: UseDebugEffect = (effect, deps, label = "anonymous") => {
  const prevDeps = useRef<typeof deps>([]);

  useEffect(() => {
    console.log("debug effect: ", label);
    for (let i = 0; i < (deps || []).length; ++i) {
      let current = (prevDeps.current || [])[i];
      let next = (deps || [])[i];
      if (current !== next) {
        console.log(`Diff dep ${i}:`, current, next);
      }
    }
    prevDeps.current = deps;
    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
