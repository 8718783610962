export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_LABEL_FORMAT = "MMM d, y";
export const FILTER_COLUMN_WIDTH = 300;
export const BAR_FILL_COLOR = "#0874f4";
export const ATTRIBUTION_MODELS = ["First Touch", "Last Touch", "Linear", "Position"];
export const HEADER_HEIGHT = 30;
export const BAR_CHART_HEIGHT = 500;
export const BAR_CHART_FONT_SIZE = 12;
export const HEATMAP_BLUE = { r: 0, g: 114, b: 240 };
export const HEATMAP_TEAL = { r: 0, g: 182, b: 203 };
export const HEATMAP_MAGENTA = { r: 242, g: 1, b: 150 };
export const HEATMAP_PURPLE = { r: 133, g: 48, b: 206 };
export const HEATMAP_ORANGE = { r: 246, g: 110, b: 1 };
export const HEATMAP_GOLD = { r: 255, g: 168, b: 0 };

export const formatNumberWithCommas = (value: string | number): string =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatDollarValue = (value: string | number): string => {
  const decimalSplit = `${(Math.round(Number(value) * 10000) / 10000).toFixed(2)}`.split(".");
  return `$${formatNumberWithCommas(decimalSplit[0])}.${decimalSplit[1]}`;
};

export const formatPercentage = (value: string | number): string => `${Number(value).toFixed(3)}%`;

interface CampaignPathTableData {
  impressions: number;
  path: string;
  pathType: string;
  reach: number;
  totalCost: number;
  totalProductSales: number;
  totalPurchases: number;
  userThatPurchased: number;
  heatMapValue_reach: number;
  heatMapValue_impressions: number;
  heatMapValue_totalCost: number;
  heatMapValue_totalPurchases: number;
  heatMapValue_totalProductSales: number;
}

interface CampaignAttributionTableData {
  "First Touch": number;
  "Last Touch": number;
  Linear: number;
  Position: number;
  campaign: string;
  heatMapValue_firstTouch: number;
  heatMapValue_lastTouch: number;
  heatMapValue_linear: number;
  heatMapValue_position: number;
}

interface AdTypePathTableData {
  impressions: number;
  path: string;
  pathType: string;
  reach: number;
  totalCost: number;
  totalProductSales: number;
  totalPurchases: number;
  userThatPurchased: number;
  purchaseRate: number;
  heatMapValue_reach: number;
  heatMapValue_impressions: number;
  heatMapValue_totalCost: number;
  heatMapValue_userThatPurchased: number;
  heatMapValue_totalPurchases: number;
  heatMapValue_purchaseRate: number;
}

const TableCell = ({
  data,
  textAlignment = "center",
  heatMap = { r: 0, g: 0, b: 0, a: 0 },
  formatType = "",
}) => (
  <div
    className={`table-cell align-${textAlignment}`}
    style={{ backgroundColor: `rgba(${heatMap.r},${heatMap.g},${heatMap.b},${heatMap.a})` }}
  >
    {formatType === ","
      ? formatNumberWithCommas(data)
      : formatType === "$"
      ? formatDollarValue(data)
      : formatType === "%"
      ? formatPercentage(data)
      : data}
  </div>
);

export const campaignPathTableHeaders = [
  {
    name: "path",
    label: "Campaign Path",
    flex: true,
    minFlexWidth: 300,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell data={data.path} textAlignment={"left"} />
    ),
  },
  {
    name: "reach",
    label: "Reach",
    width: 130,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell
        data={data.reach}
        textAlignment={"right"}
        heatMap={{ ...HEATMAP_BLUE, a: data.heatMapValue_reach }}
        formatType=","
      />
    ),
  },
  {
    name: "impressions",
    label: "Impressions",
    width: 130,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell
        data={data.impressions}
        textAlignment={"right"}
        heatMap={{ ...HEATMAP_TEAL, a: data.heatMapValue_impressions }}
        formatType=","
      />
    ),
  },
  {
    name: "totalCost",
    label: "Spend",
    width: 130,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell
        data={data.totalCost}
        textAlignment={"right"}
        heatMap={{ ...HEATMAP_MAGENTA, a: data.heatMapValue_totalCost }}
        formatType="$"
      />
    ),
  },
  {
    name: "totalPurchases",
    label: "Purchases",
    width: 130,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell
        data={data.totalPurchases}
        textAlignment={"right"}
        heatMap={{ ...HEATMAP_PURPLE, a: data.heatMapValue_totalPurchases }}
        formatType=","
      />
    ),
  },
  {
    name: "totalProductSales",
    label: "Total Sales",
    width: 130,
    renderer: (data: CampaignPathTableData): JSX.Element => (
      <TableCell
        data={data.totalProductSales}
        textAlignment={"right"}
        heatMap={{ ...HEATMAP_GOLD, a: data.heatMapValue_totalProductSales }}
        formatType="$"
      />
    ),
  },
];

export const campaignAttrTableHeaders = [
  {
    name: "campaign",
    label: "Campaign",
    flex: true,
    minFlexWidth: 300,
    renderer: (data: CampaignAttributionTableData): JSX.Element => (
      <TableCell data={data.campaign} textAlignment={"left"} />
    ),
  },
  {
    name: "First Touch",
    label: "First Touch ROAS",
    width: 160,
    renderer: (data: CampaignAttributionTableData): JSX.Element => (
      <TableCell
        data={data["First Touch"]}
        heatMap={{ ...HEATMAP_BLUE, a: data.heatMapValue_firstTouch }}
        formatType="$"
      />
    ),
  },
  {
    name: "Last Touch",
    label: "Last Touch ROAS",
    width: 160,
    renderer: (data: CampaignAttributionTableData): JSX.Element => (
      <TableCell
        data={data["Last Touch"]}
        heatMap={{ ...HEATMAP_TEAL, a: data.heatMapValue_lastTouch }}
        formatType="$"
      />
    ),
  },
  {
    name: "Linear",
    label: "Linear ROAS",
    width: 160,
    renderer: (data: CampaignAttributionTableData): JSX.Element => (
      <TableCell
        data={data.Linear}
        heatMap={{ ...HEATMAP_MAGENTA, a: data.heatMapValue_linear }}
        formatType="$"
      />
    ),
  },
  {
    name: "Position",
    label: "Position ROAS",
    width: 160,
    renderer: (data: CampaignAttributionTableData): JSX.Element => (
      <TableCell
        data={data.Position}
        heatMap={{ ...HEATMAP_ORANGE, a: data.heatMapValue_position }}
        formatType="$"
      />
    ),
  },
];

export const campaignAttrTableTooltips = {
  "First Touch ROAS":
    "The First Touch model attributes the entire value of a sale or conversion to the first customer interaction with an ad within the lookback period.",
  "Last Touch ROAS":
    "The Last Touch model attributes the entire value of a sale or conversion to the last customer interaction with an ad prior to a conversion within the lookback period.",
  "Linear ROAS":
    "The Linear model distributes the credit for a sale evenly across all the ads the customer interacted with during their purchasing journey.",
  "Position ROAS":
    "The Position Based model, also known as the U-shaped model, allocates more credit to the first and last interactions (typically 40% each), with the remaining 20% spread out among other touch points.",
};

export const adTypePathTableHeaders = [
  {
    name: "path",
    label: "Ad Path",
    flex: true,
    minFlexWidth: 300,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell data={data.path} textAlignment={"left"} />
    ),
  },
  {
    name: "reach",
    label: "Reach",
    width: 130,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell
        data={data.reach}
        heatMap={{ ...HEATMAP_BLUE, a: data.heatMapValue_reach }}
        formatType=","
      />
    ),
  },
  {
    name: "impressions",
    label: "Impressions",
    width: 130,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell
        data={data.impressions}
        heatMap={{ ...HEATMAP_TEAL, a: data.heatMapValue_impressions }}
        formatType=","
      />
    ),
  },
  {
    name: "userThatPurchased",
    label: "Purchaser",
    width: 130,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell
        data={data.userThatPurchased}
        heatMap={{ ...HEATMAP_MAGENTA, a: data.heatMapValue_userThatPurchased }}
        formatType=","
      />
    ),
  },
  {
    name: "totalPurchases",
    label: "Total Purchases",
    width: 130,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell
        data={data.reach}
        heatMap={{ ...HEATMAP_ORANGE, a: data.heatMapValue_totalPurchases }}
        formatType=","
      />
    ),
  },
  {
    name: "purchaseRate",
    label: "Purchase Rate",
    width: 130,
    renderer: (data: AdTypePathTableData): JSX.Element => (
      <TableCell
        data={data.purchaseRate}
        heatMap={{ ...HEATMAP_GOLD, a: data.heatMapValue_purchaseRate }}
        formatType="%"
      />
    ),
  },
];

export const AD_TYPE_CHART_COLORS = {
  olv: {
    label: "OLV",
    fillColor: "#7cb342",
    textColor: "#434748",
  },
  sd: {
    label: "SD",
    fillColor: "#f66e01",
    textColor: "#434748",
  },
  sb: {
    label: "SB",
    fillColor: "#0072f0",
    textColor: "#fff",
  },
  display: {
    label: "Display",
    fillColor: "#f20196",
    textColor: "#fff",
  },
  sp: {
    label: "SP",
    fillColor: "#00b6cb",
    textColor: "#434748",
  },
  stv: {
    label: "STV",
    fillColor: "#ffa800",
    textColor: "#434748",
  },
  pv: {
    label: "PV",
    fillColor: "#ffa800",
    textColor: "#434748",
  },
  "": {
    label: "Unknown",
    fillColor: "#000",
    textColor: "#fff",
  },
};
