import "./MarketingInputsLegend.scss";
import React from "react";

interface Channel {
  channel: string;
  color: string;
}

interface MarketingInputsLegendProps {
  channels: Channel[];
  channelClassification: Record<string, string>;
}

const ColoredSquare = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 10,
      width: 10,
      borderColor: color,
      borderRadius: "50%",
    }}
  />
);

const MarketingInputsLegend: React.FC<MarketingInputsLegendProps> = ({
  channels,
  channelClassification,
}) => {
  return (
    <div className="marketingInputsLegend">
      {channels.map(channelInfo => {
        const { channel, color } = channelInfo;
        return (
          <div className="legendItem" key={channel}>
            <span className="key">
              <ColoredSquare color={color} />
            </span>
            <div className={`title ${channelClassification[channel]}`}>{channel}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MarketingInputsLegend;
