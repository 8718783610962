import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as R from "ramda";
import { CellRenderer, CornerLocation, Header, StickyTable } from "..";
import AutoSizer from "react-virtualized-auto-sizer";
import { useScrollbarSizes } from "../../utils/hooks/useDOMHelpers";
import "./FrozenColumnsTable.scss";

const MIN_COLUMN_WIDTH = 100;

interface FrozenColumnsTableProps {
  leftWidth: number;
  leftRenderer: CellRenderer<any>;
  cornerRenderer: (location: CornerLocation) => JSX.Element;
  rightTableHeaders: Header[];
  rightTableHeaderNames: string[];
  originalRightData: any[];
  leftDataFields: string[];
  rightData: any[][];
  setRightData: React.Dispatch<React.SetStateAction<any[][]>>;
  noRowsRenderer: () => JSX.Element;
}

/*
    New component that utilizes the StickyTable component to create a table with frozen columns.
    The left columns are frozen and the right columns are scrollable. This component does not
    include a filter bar, so that needs to be added in the parent component. This component also
    only has sorting functionality for the right columns. The data's sorting depends on rightData,
    so setRightData must be used in parent component to manually sort the data when left
    column headers are clicked. Example can be found in Segmentation Labeling Tool.
*/
export const FrozenColumnsTable: React.FC<FrozenColumnsTableProps> = ({
  leftWidth,
  leftRenderer,
  cornerRenderer,
  rightTableHeaders,
  rightTableHeaderNames,
  originalRightData,
  leftDataFields,
  rightData,
  setRightData,
  noRowsRenderer,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollbarSizes = useScrollbarSizes(containerRef);
  const rightHeadersRenderer = useCallback(({ data }) => data, []);
  const topData: string[] = useMemo(
    () => R.defaultTo([] as string[], R.pluck("label", rightTableHeaders)) as string[],
    [rightTableHeaders]
  );

  const cellRenderer = useCallback(
    ({ data, style, classes, columnIndex }) => {
      classes = [...classes];
      let { renderer } = rightTableHeaders[columnIndex];

      return (
        <div className={classes.join(" ")} style={style}>
          {renderer ? renderer(originalRightData[data.originalRowIndex]) : ""}
        </div>
      );
    },
    [rightTableHeaders, originalRightData]
  );

  const columnWidth: (tableWidth: number, index: number) => number = useMemo(() => {
    return (tableWidth: number, i: number) => {
      let nonFlexWidth = scrollbarSizes.width;
      let flexSum = 0;

      let headerSizes: {
        flex?: number;
        minFlexWidth?: number;
        width?: number;
      }[] = [];

      for (let { width, minFlexWidth, flex: flexGeneric } of rightTableHeaders) {
        let flex: number | undefined =
          typeof flexGeneric === "boolean" ? (flexGeneric ? 1 : 0) : flexGeneric;
        let ourWidth = R.isNil(width) ? MIN_COLUMN_WIDTH : width;
        if (flex) {
          flexSum += flex;
        } else {
          nonFlexWidth += R.isNil(width) ? MIN_COLUMN_WIDTH : width;
        }
        headerSizes.push({
          flex,
          width: ourWidth,
          minFlexWidth,
        });
      }

      let flexElementTooSmall;
      let flexScraps;
      do {
        flexElementTooSmall = false;
        flexScraps = 0;
        let perFlexUnitWidthRaw = (tableWidth - nonFlexWidth) / flexSum;
        let perFlexUnitWidth = Math.floor(perFlexUnitWidthRaw);
        let perFlexUnitScrap = perFlexUnitWidthRaw - perFlexUnitWidth;
        for (let i = 0; i < headerSizes.length; ++i) {
          const { flex, minFlexWidth } = headerSizes[i];
          if (flex) {
            let actualMin = Math.max(minFlexWidth || 0, MIN_COLUMN_WIDTH || 0);
            let ourWidth = perFlexUnitWidth * flex;
            if (ourWidth < actualMin) {
              flexElementTooSmall = true;
              headerSizes[i] = {
                width: actualMin,
              };
              nonFlexWidth += actualMin;
              flexSum -= flex;
            } else {
              flexScraps += perFlexUnitScrap * flex;
              headerSizes[i] = {
                width: ourWidth,
                minFlexWidth,
                flex,
              };
            }
          }
        }
      } while (flexElementTooSmall);

      let headerWidths = R.pluck("width", headerSizes) as number[];
      let scrapDistribution = Math.floor(flexScraps / headerWidths.length);
      let scrapBonuses = flexScraps % headerWidths.length;
      for (let i = 0; i < headerSizes.length; ++i) {
        headerWidths[i] += scrapDistribution;
        if (scrapBonuses > 1) {
          headerWidths[i] += 1;
          scrapBonuses--;
        }
      }

      return headerWidths[i];
    };
  }, [rightTableHeaders, scrollbarSizes.width]);

  useEffect(() => {
    let data: any[][] = [];
    for (let i = 0; i < originalRightData.length; ++i) {
      let obj = originalRightData[i];
      let row: any[] = [];
      for (let name of rightTableHeaderNames) {
        let content = obj[R.defaultTo("undefined", name)];
        row.push({
          originalRowIndex: i,
          content: R.isNil(content) ? "" : content,
        });
      }
      data.push(row);
    }
    setRightData(data);
  }, [originalRightData, setRightData, rightTableHeaderNames]);

  const leftData = useMemo(() => {
    if (rightData.length === originalRightData.length) {
      let tempDataMap = {};
      rightData.forEach(arr => {
        let row = originalRightData[arr[0].originalRowIndex];

        leftDataFields.forEach(field => {
          let currObj = tempDataMap[arr[0].originalRowIndex] || {};
          tempDataMap[arr[0].originalRowIndex] = { ...currObj, ...{ [field]: row[field] } };
        });
      });

      let output: any[] = [];
      rightData.forEach(row => {
        output.push(tempDataMap[row[0].originalRowIndex]);
      });

      return output;
    }
  }, [rightData, originalRightData, leftDataFields]);

  const topRenderer = useCallback(
    ({ data, columnIndex, style, classes }) => {
      let ourClasses = [...classes];

      return (
        <div style={style} className={ourClasses.join(" ")}>
          <span>
            {rightHeadersRenderer({
              data,
              columnIndex,
            })}
          </span>
        </div>
      );
    },
    [rightHeadersRenderer]
  );

  const frozenColumnsTable = useMemo(() => {
    return rightData.length && leftData?.length && topData.length ? (
      <div ref={containerRef} className="stickyTableContainer">
        <AutoSizer>
          {({ width, height }) => {
            return (
              <StickyTable
                data={rightData}
                width={width}
                height={height}
                cellRenderer={cellRenderer}
                columnWidth={i => columnWidth(width, i)}
                topData={topData}
                topRenderer={topRenderer}
                leftWidth={leftWidth}
                leftData={leftData}
                leftRenderer={leftRenderer}
                cornerRenderer={cornerRenderer}
              ></StickyTable>
            );
          }}
        </AutoSizer>
      </div>
    ) : (
      noRowsRenderer()
    );
  }, [
    rightData,
    noRowsRenderer,
    cellRenderer,
    topData,
    topRenderer,
    leftWidth,
    leftData,
    leftRenderer,
    cornerRenderer,
    columnWidth,
  ]);

  return <div className="frozenColumnsTable">{frozenColumnsTable}</div>;
};

export default FrozenColumnsTable;
