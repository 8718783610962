import React, { useState, useEffect } from "react";
import { Popover } from "react-bootstrap";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { useSetError } from "../redux/modals";

import { awaitJSON, DagLambdaFetch } from "../utils/fetch-utils";

import { Page, Spinner, BPMTable, OverlayTrigger } from "../Components";

import "./KPIStatus.scss";

const AVAILABLE = "available";
const UNAVAILABLE_ALERT_ONLY = "unavailable_alert_only";
const UNAVAILABLE_CHECKING = "unavailable_checking";
const UNAVAILABLE_FAILED = "unavailable_failed";
const DATE_FORMAT = "yyyy-MM-dd";
const TODAY = Dfns.format(DATE_FORMAT, new Date());

const KpiStatusTable = ({ data, headers }) => {
  if (!data) {
    return <Spinner size={75} />;
  }
  const sortedDates = R.pipe(R.map(R.prop("run_date_pacific")), R.uniq, R.sortBy(R.identity))(data);
  let maxDate = R.length(sortedDates) ? sortedDates[R.length(sortedDates) - 1] : TODAY;

  return (
    <BPMTable
      headers={headers}
      data={data}
      noRowsRenderer={() => <div>No rows to show.</div>}
      rowHeight={45}
      headerHeight={40}
      defaultTokens={{
        advanced: ["Run Date (Pacific)", "is", maxDate],
      }}
      defaultAdvancedFilter
    />
  );
};
const KPIStatusMessagePopover = ({ message, alert }) => {
  return (
    <OverlayTrigger
      placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
      trigger={OverlayTrigger.TRIGGERS.CLICK}
      overlay={
        <Popover>
          <Popover.Content className="bpm-kpi-status-popover-body">{message}</Popover.Content>
        </Popover>
      }
    >
      {alert}
    </OverlayTrigger>
  );
};

const KPIStatusAlert = React.memo(({ status_type, message }) => {
  switch (status_type) {
    case AVAILABLE:
      return (
        <div className="kpi-status-alert kpi-status-alert-success">
          <h4>Available</h4>
        </div>
      );
    case UNAVAILABLE_FAILED:
      return (
        <KPIStatusMessagePopover
          message={message}
          alert={
            <div className="kpi-status-alert kpi-status-alert-danger">
              <h4>Unavailable</h4>
              <span>(stopped ingestions)</span>
            </div>
          }
        />
      );
    case UNAVAILABLE_ALERT_ONLY:
      return (
        <KPIStatusMessagePopover
          message={message}
          alert={
            <div className="kpi-status-alert kpi-status-alert-primary">
              <h4>Non-essential KPI data is unavailable</h4>
              <span>(continuing ingestions)</span>
            </div>
          }
        />
      );
    case UNAVAILABLE_CHECKING:
      return (
        <KPIStatusMessagePopover
          message={message}
          alert={
            <div className="kpi-status-alert kpi-status-alert-warning">
              <h4>Unavailable</h4>
              <span>(will continue to check every 30 minutes)</span>
            </div>
          }
        />
      );
    default:
      return null;
  }
});

const KPIStatus = () => {
  const setError = useSetError();
  const [statuses, setStatuses] = useState();

  useEffect(() => {
    let allStatuses = [];
    // show the last week's worth of statuses for each client
    let start = Dfns.subWeeks(1, new Date());
    (async () => {
      try {
        let statusesByClient = await DagLambdaFetch("/kpi_status/get", {
          params: {
            start: Dfns.format(DATE_FORMAT, start),
          },
        });

        let parsedResult = await awaitJSON(statusesByClient);
        allStatuses = R.pipe(
          R.values,
          R.pluck("statuses"),
          R.filter(R.length),
          R.flatten
        )(parsedResult);

        let result = R.map(res => {
          if (res.available) {
            res.status_type = AVAILABLE;
          } else if (res.failed) {
            res.status_type = UNAVAILABLE_FAILED;
          } else if (res.alert_only) {
            res.status_type = UNAVAILABLE_ALERT_ONLY;
          } else {
            res.status_type = UNAVAILABLE_CHECKING;
          }

          return res;
        }, allStatuses);
        setStatuses(result);
      } catch (e) {
        setError({ message: "Failed to fetch KPI statuses", reportError: e });
        setStatuses([]);
      }
    })();
  }, [setError]);

  return (
    <Page title="KPI Status" pageType="KPI Status" minHeight={600}>
      <div className="bpm-kpi-status">
        {statuses ? (
          <KpiStatusTable
            data={statuses}
            headers={[
              {
                sortPriority: 1,
                sortAscending: true,
                flex: 2,
                label: "Company",
                name: "company",
                renderer: data => {
                  return R.prop("company", data);
                },
              },
              {
                flex: 2,
                label: "Run Date (Pacific)",
                name: "run_date_pacific",
                renderer: data => {
                  return R.prop("run_date_pacific", data);
                },
              },
              {
                flex: 3,
                label: "Status",
                name: "status_type",
                renderer: data => {
                  let status_type = R.prop("status_type", data);
                  let attempts = R.prop("attempts", data);
                  let message = R.prop("message", data);
                  return (
                    <div className="kpi-status-alert-card">
                      <KPIStatusAlert
                        status_type={status_type}
                        attempts={attempts}
                        message={message}
                      />
                    </div>
                  );
                },
              },
              {
                flex: 3,
                label: "Last Checked",
                name: "last_modified",
                renderer: data => {
                  return (
                    <div className="kpi-status-last-checked">
                      <div className="last-modified-time">
                        {Dfns.format(
                          "yyyy-MM-dd kk:mm:ss",
                          Dfns.parseISO(R.prop("last_modified", data))
                        )}
                      </div>
                      <div className="attempts">{`Attempt number: ${R.prop(
                        "attempts",
                        data
                      )}`}</div>
                    </div>
                  );
                },
              },
            ]}
          />
        ) : (
          <Spinner size={100} />
        )}
      </div>
    </Page>
  );
};

export default KPIStatus;
