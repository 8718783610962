// Experiment for eBay page.

const ebayIntegrationCompanies = [
  "blisspoint",
  // Add companies here!
];

export const shouldEnableEbayIntegration = (company: string): boolean => {
  return ebayIntegrationCompanies.includes(company);
};
