import React, { useRef, useLayoutEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";

export const SelfFocusingInput: React.FC<React.ComponentProps<typeof Form.Control>> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return <Form.Control ref={inputRef} {...props} />;
};

export const SelfFocusingSelect: React.FC<React.ComponentProps<typeof Select>> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return <Select ref={inputRef} menuPlacement="auto" {...props} />;
};
