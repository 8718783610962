import React, { useMemo } from "react";
import "./CheckBox.scss";

interface CheckBoxProps {
  checked: boolean;
  label: string;
  onCheck: (key: string, value: boolean) => void;
  className?: string;
  [passthroughProp: string]: any;
}

const CheckBox: React.FC<CheckBoxProps> = React.memo(
  ({ checked, label, onCheck, className: providedClassName, ...passthrough }) => {
    const className = useMemo(() => {
      let classes = ["checkBoxContainer"];
      if (providedClassName) {
        classes.push(providedClassName);
      }
      return classes.join(" ");
    }, [providedClassName]);
    return (
      <div className={className}>
        <div
          className={checked ? "checkBox checked" : "checkBox"}
          onClick={() => onCheck(label, !checked)}
          {...passthrough}
        ></div>
        <div className={checked ? "label checked" : "label"}>{label}</div>
      </div>
    );
  }
);

export default CheckBox;
