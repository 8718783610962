import { AdOpsToolProps } from "../AdOps";
import { awaitJSON, FlashtalkingLambdaFetch } from "../../utils/fetch-utils";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Card, Dropdown, DropdownOption, InfoTooltip, Spinner } from "../../Components";
import * as R from "ramda";
import React, { useState, useCallback, useEffect } from "react";

interface FlashtalkingSiteRow {
  id: number;
  name: string;
  network_contacts: string[];
  pixel: string;
  streaming_networks_short_codes: string[];
  url: string;
}

const FlashtalkingSiteAdder: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const [flashtalkingSites, setFlashtalkingSites] = useState<FlashtalkingSiteRow[]>();
  const [networkContacts, setNetworkContacts] = useState<string[]>();
  const [placementTypes, setPlacementTypes] = useState<("audio_vast" | "pixel" | "video_vast")[]>(
    []
  );
  const [prettyName, setPrettyName] = useState<string>();
  const [selectedFlashtalkingSite, setSelectedFlashtalkingSite] = useState<FlashtalkingSiteRow>();
  const [siteID, setSiteID] = useState<number>();
  const [siteName, setSiteName] = useState<string>();
  const [streamingNetworkShortCodes, setStreamingNetworkShortCodes] = useState<string[]>();
  const [url, setURL] = useState<string>();

  const { setError } = utils;
  const siteMap = R.indexBy(
    site => `${site.id}`,
    R.defaultTo([], flashtalkingSites) as FlashtalkingSiteRow[]
  );

  useEffect(() => {
    (async () => {
      if (R.isNil(flashtalkingSites)) {
        const sitesResp = await FlashtalkingLambdaFetch("/getFlashtalkingSites", {
          method: "GET",
          params: {},
        });
        setFlashtalkingSites((await awaitJSON(sitesResp)) as FlashtalkingSiteRow[]);
      }
    })();
  }, [flashtalkingSites]);

  const addFlashtalkingSite = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await FlashtalkingLambdaFetch("/addFlashtalkingSite", {
        method: "POST",
        body: {
          site: {
            id: siteID,
            name: siteName,
            pretty_name: prettyName,
            network_contacts: R.filter(
              emails => emails !== "",
              R.defaultTo([], networkContacts) as string[]
            ),
            placement_types: placementTypes,
            pixel: null,
            streaming_networks_short_codes: R.filter(
              codes => codes !== "",
              R.defaultTo([], streamingNetworkShortCodes) as string[]
            ),
            url,
          },
        },
      });
      setFlashtalkingSites(undefined);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: reportError.message,
        reportError,
      });
    }
    setActiveAPICall(false);
  }, [
    networkContacts,
    placementTypes,
    prettyName,
    setError,
    siteID,
    siteName,
    streamingNetworkShortCodes,
    url,
  ]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">
        Add/Update Flashtalking Site
        <InfoTooltip size="reg">
          Use the Associated Streaming Network Short Codes to associate a site with streaming
          networks other than ones we can match on based on the site's name.
        </InfoTooltip>
      </div>
      <Dropdown
        value={selectedFlashtalkingSite ? `${selectedFlashtalkingSite.id}` : ""}
        options={
          R.map(
            site => ({ value: `${site.id}`, label: `${site.name} (${site.id})` }),
            R.defaultTo([], flashtalkingSites) as FlashtalkingSiteRow[]
          ) as DropdownOption[]
        }
        onChange={id => {
          setSelectedFlashtalkingSite(siteMap[id]);
          setNetworkContacts(siteMap[id].network_contacts);
          setSiteID(parseInt(id));
          setSiteName(siteMap[id].name);
          setPrettyName(siteMap[id].pretty_name);
          setStreamingNetworkShortCodes(siteMap[id].streaming_networks_short_codes);
          setURL(siteMap[id].url);
          setPlacementTypes(siteMap[id].placement_types);
        }}
      />
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Site ID</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={!R.isNil(siteID) ? siteID : ""}
          onChange={e => {
            if (e.target.value === "") {
              setSiteID(undefined);
            }
            if (!isNaN(parseInt(e.target.value))) {
              setSiteID(parseInt(e.target.value));
            }
          }}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Site Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={siteName} onChange={e => setSiteName(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">BPM Site Pretty Name Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={prettyName} onChange={e => setPrettyName(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">URL</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={url ? url : ""} onChange={e => setURL(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Network Contacts</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={R.defaultTo([], networkContacts).join(",")}
          onChange={e => {
            if (e.target.value === "") {
              setNetworkContacts([]);
            } else {
              setNetworkContacts(e.target.value.split(","));
            }
          }}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">
            Associated Streaming Network Short Codes
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={R.defaultTo([], streamingNetworkShortCodes).join(",")}
          onChange={e => {
            if (e.target.value === "") {
              setStreamingNetworkShortCodes([]);
            } else {
              setStreamingNetworkShortCodes(e.target.value.split(","));
            }
          }}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Text className="inputLabel">Audio VAST</InputGroup.Text>
        <Form.Check
          checked={R.includes("audio_vast", placementTypes)}
          onChange={() => {
            if (R.includes("audio_vast", placementTypes)) {
              setPlacementTypes(R.filter(type => type !== "audio_vast", placementTypes));
            } else {
              setPlacementTypes(
                R.sort((a, b) => a.localeCompare(b), R.uniq(["audio_vast", ...placementTypes]))
              );
            }
          }}
        />
        <InputGroup.Text className="inputLabel">Video VAST</InputGroup.Text>
        <Form.Check
          checked={R.includes("video_vast", placementTypes)}
          onChange={() => {
            if (R.includes("video_vast", placementTypes)) {
              setPlacementTypes(R.filter(type => type !== "video_vast", placementTypes));
            } else {
              setPlacementTypes(
                R.sort((a, b) => a.localeCompare(b), R.uniq(["video_vast", ...placementTypes]))
              );
            }
          }}
        />
        <InputGroup.Text className="inputLabel">Pixel</InputGroup.Text>
        <Form.Check
          checked={R.includes("pixel", placementTypes)}
          onChange={() => {
            if (R.includes("pixel", placementTypes)) {
              setPlacementTypes(R.filter(type => type !== "pixel", placementTypes));
            } else {
              setPlacementTypes(
                R.sort((a, b) => a.localeCompare(b), R.uniq(["pixel", ...placementTypes]))
              );
            }
          }}
        />
      </InputGroup>
      <Button className="linearAdOpsElem" onClick={addFlashtalkingSite}>
        {activeAPICall ? <Spinner /> : "Add"}
      </Button>
    </Card>
  );
};

export default FlashtalkingSiteAdder;
