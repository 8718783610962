import "./IncrementalRoas.scss";
import React, { useCallback, useEffect, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import Papa from "papaparse";
import ComposedChart from "../../Components/Charts/ComposedChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown } from "../../Components";
import { currFormat } from "../BrandImpact/BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface IncrementalRoasProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

export const GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC = [
  { label: "Ad Awareness", value: "ad_awareness" },
  { label: "Awareness", value: "awareness" },
  { label: "Buzz", value: "buzz" },
  { label: "Consideration", value: "consideration" },
];

export const IncrementalRoas: React.FC<IncrementalRoasProps> = ({ groupByMetric }) => {
  const [incrementalRoasData, setIncrementalRoasData] = useState<any[]>([]);

  useEffect(() => {
    const fetchTimeSeriesAndImplicationsData = async () => {
      try {
        let incrementalRoasDataResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/iroas_tab.csv"
        );
        const incrementalRoasDataText = await incrementalRoasDataResponse.text();

        const { data: incrementalRoasDataParsed }: Papa.ParseResult<any> = Papa.parse(
          incrementalRoasDataText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );

        const dataFixed = incrementalRoasDataParsed.map((item: any) => ({
          date: item.date,
          IncrementalRoas: item.iroas,
          Revenue: item.revenue,
          Spend: item.paid_media_spend,
        }));
        const sortedAreaChartData = dataFixed.sort((a, b) => a.date.localeCompare(b.date));
        setIncrementalRoasData(sortedAreaChartData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    fetchTimeSeriesAndImplicationsData();
  }, [groupByMetric]);

  const excelDownloadWeeklyAdAwarenessAreaChart = useCallback(() => {
    exportToExcel(incrementalRoasData, "weekly_ad_awareness_area_chart");
  }, [incrementalRoasData]);

  const pngDownloadWeeklyAdAwarenessAreaGraph = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", "weekly_ad_awareness_area_graph");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header="What is our incremental ROAS from media-driven brand equity?"
      subHeader="Brand media investments' contribution to the bottom line."
    >
      <div className="left">
        <div className="leftLeft">
          <ChartContainer
            enableHoverDesign
            rightActions={
              <div className="incrementalRoasRightActions">
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[
                    excelDownloadWeeklyAdAwarenessAreaChart,
                    pngDownloadWeeklyAdAwarenessAreaGraph,
                  ]}
                />
              </div>
            }
            title={
              <>
                <div>
                  Weekly Incremental ROAS
                  <span style={{ color: "#1F003F", fontWeight: 500, fontSize: "1rem" }}>
                    {" (Revenue From Media-Driven Brand Equity / Spend)"}
                  </span>
                </div>
              </>
            }
            titleAfterDashText="12/20/20 – 12/24/23"
            beforeTooltipText="Incremental ROAS"
            rightSideText="Revenue"
          >
            {!R.isEmpty(incrementalRoasData) && (
              <ComposedChart
                data={incrementalRoasData}
                xAxisDataKey="date"
                xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                dateGrouping="Week"
                areaYAxisTickFormatter={value => `${currFormat(value, 0)}`}
                yAxisWidth={150}
                colorOverrides={{
                  "Revenue From Media-Driven Brand Equity": "#6B2DEF",
                  IncrementalRoas: "#79DFF5",
                }}
                areas={[
                  {
                    name: "Revenue From Media-Driven Brand Equity",
                    dataKey: "Revenue",
                  },
                  {
                    name: "Incremental ROAS",
                    dataKey: "IncrementalRoas",
                    line: true,
                  },
                ]}
                tooltipFormatter={val => {
                  if (!val) {
                    return val;
                  }
                  return `${currFormat(val, 0)}`;
                }}
                reverseToolTipItems={true}
                reverseLegend={true}
                areaYAxisOrientation="right"
              ></ComposedChart>
            )}
          </ChartContainer>
        </div>
        <div className="leftRight">
          <ChartContainer title="Takeaways" titleAfterDashText="TTM" enableHoverDesign>
            <div className="parentIncrementalTakeaways">
              <div className="topIncremental">
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Spend </div>
                  <div className="topText">$32M</div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Brand</div>
                  <div className="topText">Equity</div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div>Incremental</div>
                  <div className="topText">Revenue</div>
                  <div className="topText">~$56M</div>
                </div>
              </div>
              <div className="middleIncremental">
                <div className="middleText">Incremental ROAS = 1.75</div>
              </div>
              <div className="bottomIncremental">
                <div className="bottomText">
                  We estimate paid media-driven brand equity led to an incremental ROAS of 1.75 over
                  the trailing twelve months.
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default IncrementalRoas;
