import { ApolloClient, InMemoryCache } from "@apollo/client";

export const tmrCache = new InMemoryCache({
  typePolicies: {
    SummaryTotalAdvertising: {},
    SummaryTotalAdvertisingByDimension: {},
  },
});

export const tmrClient = new ApolloClient({
  cache: tmrCache,
  uri: process.env.REACT_APP_MOBIUSX_DATA_API,
  headers: {
    "x-api-key": process.env.REACT_APP_MOBIUSX_DATA_API_KEY || "",
  },
});
