import React, { useEffect, useState, useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { ReportInfo } from "@blisspointmedia/bpm-types/dist/Reporting";

import { ListGroupSkeleton, Page, Skeleton, OldFilterBar } from "../Components";
import { useSetError } from "../redux/modals";
import useLocation from "../utils/hooks/useLocation";

import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";
import { abbreviateNumber } from "../utils/data";

import {
  MdExpandMore,
  MdExpandLess,
  MdCheck,
  MdError,
  MdContentCopy,
  MdSearch,
} from "react-icons/md";
import { Toast } from "react-bootstrap";

import { magicMintGreen, redOrange, softBlue, schoolBusYellow, white } from "../utils/colors";

import "./Reporting.scss";
import { useStateFunction } from "../utils/hooks/useData";

const colorForStatus = status => {
  switch (status) {
    case "SUCCESS":
      return magicMintGreen;
    case "FAILURE":
      return redOrange;
    case "STARTED":
      return softBlue;
    case "WAITING":
      return schoolBusYellow;
    default:
      return white;
  }
};

const SIZE_ALERT_THRESHOLD = 0.95;

const ReportsPageSkeleton = React.memo(() => (
  <Skeleton redesign>
    <ListGroupSkeleton lineHeight={70} lineSpacing={10} />
  </Skeleton>
));

const toPrettyDate = R.pipe(Dfns.parseISO, Dfns.format("M/d/yyyy @ h:mm:ss a (z)"));

const Reporting = (): JSX.Element => {
  const { company } = useLocation();

  const setError = useSetError();

  const [data, setData] = useState<ReportInfo[]>();
  const [expandedRun, setExpandedRun] = useState<string>();
  const [expandedType, setExpandedType] = useState<string>();
  const [showCopiedToast, setShowCopiedToast] = useState(false);

  const [filter, setFilter] = useStateFunction<(ReportInfo) => boolean>(() => true);

  useEffect(() => {
    if (company && !data) {
      (async () => {
        try {
          let res = await MiscLambdaFetch("/reporting", { params: { company } });
          const newLines = await awaitJSON<ReportInfo[]>(res);
          setData(newLines);
        } catch (e) {
          setError({
            reportError: e,
            message: `Can't fetch reporting info. Error: ${e.message}`,
          });
        }
      })();
    }
  }, [data, company, setError]);

  const visibleLines = useMemo(() => {
    if (data && filter) {
      const visible = filter ? R.filter(filter, data) : data;
      const byName = R.groupBy(row => row.name, visible);
      const allByName = R.groupBy(row => row.name, data);
      const avgCompletionTimeByName = R.mapObjIndexed(list => {
        const finishedReports = R.filter(row => !!row.completed, list);
        const minutes = R.map(row => {
          const d = row.completed ? Dfns.parseISO(row.completed) : 0;
          return Dfns.getHours(d) * 60 + Dfns.getMinutes(d);
        }, finishedReports);
        return R.sum(minutes) / minutes.length;
      }, allByName);
      const fileSizeByName: Record<string, Record<string, number[]>> = {};
      for (const row of data) {
        if (!fileSizeByName[row.name]) {
          fileSizeByName[row.name] = {};
        }
        if (row.files) {
          for (const file of row.files) {
            if (file.success) {
              if (!fileSizeByName[row.name][file.path]) {
                fileSizeByName[row.name][file.path] = [];
              }
              fileSizeByName[row.name][file.path].push(file.size);
            }
          }
        }
      }

      const avgFileSizeByName = {};
      for (const name of R.keys(fileSizeByName)) {
        if (!avgFileSizeByName[name]) {
          avgFileSizeByName[name] = {};
        }
        for (const path of R.keys(fileSizeByName[name])) {
          avgFileSizeByName[name][path] =
            R.sum(fileSizeByName[name][path]) / fileSizeByName[name][path].length;
        }
      }

      for (const row of visible) {
        const d = row.completed ? Dfns.parseISO(row.completed) : new Date();
        const minutes = Dfns.getHours(d) * 60 + Dfns.getMinutes(d);
        row.is_late = minutes > avgCompletionTimeByName[row.name] + 30;
        for (const file of row.files || []) {
          if (avgFileSizeByName[row.name] && avgFileSizeByName[row.name][file.path]) {
            file.is_too_small =
              file.size < avgFileSizeByName[row.name][file.path] * SIZE_ALERT_THRESHOLD;
          }
        }
      }

      const latest = R.map(
        list => R.sortBy(row => row.created, list)[list.length - 1],
        R.values(byName)
      );
      if (expandedType && expandedType !== "") {
        return R.uniq(
          R.concat(
            R.reverse(R.sortBy(row => row.created, byName[expandedType])),
            R.reverse(R.sortBy(row => row.created, latest))
          )
        );
      } else {
        return R.reverse(R.sortBy(row => row.created, latest));
      }
    } else {
      return [];
    }
  }, [data, filter, expandedType]);

  const options = [
    { label: "Name", name: "name", width: 0 },
    { label: "Lookback", name: "lookback", width: 0 },
    { label: "Status", name: "status", width: 0 },
  ];

  return (
    <Page title="Reporting" pageType="Reporting">
      <div className="reportingPage">
        {data ? (
          data.length ? (
            <div>
              <div className="filterBar">
                <OldFilterBar
                  options={options}
                  lines={data}
                  onFilter={newFilter => setFilter(newFilter)}
                />
              </div>
              <div className="listContainer">
                {visibleLines.map(row => (
                  <div key={row.build_number} className="listItem">
                    <div className="headerRow">
                      <div
                        className="expand"
                        onClick={() =>
                          setExpandedRun(expandedRun === row.build_number ? "" : row.build_number)
                        }
                      >
                        <div>{expandedRun !== "" ? <MdExpandMore /> : <MdExpandLess />}</div>
                      </div>
                      <div className="name">
                        <div>
                          Name
                          <span
                            className="expandedType"
                            onClick={() =>
                              setExpandedType(expandedType === row.name ? "" : row.name)
                            }
                          >
                            <MdSearch />
                          </span>
                        </div>
                        <div>{row.name}</div>
                      </div>
                      <div className="lookback">
                        <div>Look back</div>
                        <div>{row.lookback}</div>
                      </div>
                      <div className="status">
                        <div>Status</div>
                        <div
                          style={{ backgroundColor: colorForStatus(row.status), width: "200px" }}
                        >
                          {row.status}
                        </div>
                      </div>
                      <div className="date">
                        <div>Started</div>
                        <div>{toPrettyDate(row.created)}</div>
                      </div>
                      <div className="date">
                        <div>Finished</div>
                        <div
                          style={{
                            marginRight: "20px",
                            backgroundColor: row.is_late ? redOrange : magicMintGreen,
                          }}
                        >
                          {row.completed ? toPrettyDate(row.completed) : "n/a"}
                        </div>
                      </div>
                      <div className="uploads">
                        <div>Uploads</div>
                        <div
                          style={{
                            backgroundColor:
                              row.files && R.filter(file => file.is_too_small, row.files).length > 0
                                ? redOrange
                                : magicMintGreen,
                          }}
                        >
                          {row.files ? R.filter(row => row.success, row.files).length : 0} /{" "}
                          {row.files ? row.files.length : 0} (
                          {row.files
                            ? abbreviateNumber(
                                R.sum(R.map(row => (row.success ? row.size : 0), row.files))
                              )
                            : "0"}
                          b)
                        </div>
                      </div>
                    </div>
                    {expandedRun === row.build_number && (
                      <div className="details">
                        <div className="queries">
                          <div className="sectionHeader">
                            Queries{" "}
                            {row.queries && row.queries.length ? (
                              <div
                                className="queryCopy"
                                onClick={() => {
                                  /// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
                                  navigator.clipboard.writeText(row.queries.join("\n"));
                                  setShowCopiedToast(true);
                                }}
                              >
                                <MdContentCopy />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="sectionHeader">Redshift Tables</div>
                          {row.tables && row.tables.length
                            ? row.tables.map((table, i) => (
                                <div key={i} className="fileInfo">
                                  {table}
                                </div>
                              ))
                            : "Reporting tables unavailable"}
                        </div>
                        <Toast
                          className="copiedToast"
                          onClose={() => setShowCopiedToast(false)}
                          show={showCopiedToast}
                          delay={1000}
                          autohide
                        >
                          <Toast.Body>Copied!</Toast.Body>
                        </Toast>
                        <div className="files">
                          <div className="sectionHeader">Files</div>
                          {row.files && row.files.length
                            ? row.files.map((file, i) => (
                                <div
                                  key={i}
                                  className="fileInfo"
                                  style={{
                                    backgroundColor: file.is_too_small ? redOrange : "transparent",
                                  }}
                                >
                                  {file.path}{" "}
                                  <span className="success">
                                    ({file.success ? <MdCheck /> : <MdError />}{" "}
                                    {abbreviateNumber(file.size)}b)
                                  </span>
                                </div>
                              ))
                            : "File info unavailable"}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="noReports">No Reports</div>
          )
        ) : (
          <ReportsPageSkeleton />
        )}
      </div>
    </Page>
  );
};

export default Reporting;
