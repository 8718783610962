export interface MoveColumn {
  (sourceId: string, destinationId: string): void;
}

export interface DeleteColumn {
  (i: number): Promise<void>;
}

export const COLUMN_DND_TYPE = "COLUMN_DND_TYPE" as const;
export const DIMENSION_COLUMN_DND_TYPE = "DIMENSION_COLUMN_DND_TYPE" as const;

export interface HeaderInfo {
  end: number;
  start: number;
  superHeaderIndex: number;
  text: string;
  width: number;
}
