import React from "react";

import * as R from "ramda";

import { StateSetter } from "../../utils/types";
import { RelativeDatePicker } from "../../Components";
import { RelativeDatePickerFullState } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";

interface DateConfigProps {
  defaultDateState: {
    start: Partial<RelativeDatePickerFullState>;
    end: Partial<RelativeDatePickerFullState>;
  };
  setPresetChanges: StateSetter<any>;
}

const DateConfig = ({ defaultDateState, setPresetChanges }: DateConfigProps): JSX.Element => {
  return (
    <div className="editControls">
      <div className="relativePicker">
        <div className="label">Default Start</div>
        <RelativeDatePicker
          state={defaultDateState.start}
          onChange={start => setPresetChanges(R.mergeDeepLeft({ defaultDateState: { start } }))}
        />
      </div>
      <div className="relativePicker">
        <div className="label">Default End</div>
        <RelativeDatePicker
          state={defaultDateState.end}
          onChange={end => setPresetChanges(R.mergeDeepLeft({ defaultDateState: { end } }))}
        />
      </div>
    </div>
  );
};

export default DateConfig;
