import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../Components";
import { MdWarning } from "react-icons/md";
import { WtoLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { Plover } from "@blisspointmedia/bpm-types/dist/webTrafficOptimization";

export interface WtoArchiveModalProps {
  show: boolean;
  closeModal: () => void;
  data: Plover;
  userEmail: string;
  fetchPlovers: () => void;
}

export const WtoArchiveModal: React.FC<WtoArchiveModalProps> = ({
  show,
  closeModal,
  data,
  userEmail,
  fetchPlovers,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const setError = useSetError();

  const confirmArchive = async () => {
    setLoading(true);
    try {
      await WtoLambdaFetch(`/plover/${data.id}`, {
        method: "DELETE",
        params: {
          email: userEmail,
        },
      });
      fetchPlovers();
      closeModal();
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to archive plover. Error: ${error.message}`,
        reportError: error,
      });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => (loading ? null : closeModal())}>
      <Modal.Header closeButton>
        <Modal.Title>Archive Route</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner size={50} />
        ) : (
          <div className="infoBox warning">
            <div className="icon">
              <MdWarning color="orange" className="alertIcon" />
            </div>
            <p className="message">
              Are you sure you would like to archive this route: <span>{data.test_name}</span>?
              <br />
              <br />
              Archived Routes cannot be reactivated, new routes with similar parameters can be
              created.
              <br />
              <br />
              <i>
                IMPORTANT: This does not remove the Plover file from the website. Please contact
                your Engineering Lead assigned to your partner to remove file from the website.
              </i>
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={confirmArchive}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WtoArchiveModal;
