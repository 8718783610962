import React, { useState, useCallback } from "react";

import { Card, Spinner } from "../../Components";
import { Button, Form, InputGroup } from "react-bootstrap";
import { StreamingUtilsLambdaFetch } from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

const NexxenAdvertiserAdder: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [company, setCompany] = useState("");
  const [advertiserId, setAdvertiserId] = useState<number | null>(null);

  const addNexxenAdvertiserId = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await StreamingUtilsLambdaFetch("/addNexxenAdvertiserId", {
        method: "POST",
        body: JSON.stringify({
          company,
          advertiserId,
        }),
      });
      setCompany("");
      setAdvertiserId(null);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [company, advertiserId, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Add Nexxen Advertiser</div>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company} onChange={e => setCompany(e.target.value)} />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Nexxen Advertiser Id</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={advertiserId || ""}
          onChange={e => setAdvertiserId(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <Button className="linearAdOpsElem" onClick={addNexxenAdvertiserId}>
        {activeAPICall ? <Spinner /> : "Add"}
      </Button>
    </Card>
  );
};

export default NexxenAdvertiserAdder;
