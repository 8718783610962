import { awaitJSON, SingleChannelLambdaFetch } from "../utils/fetch-utils";
import { CheckBox, Dropdown, RelativeDatePicker } from "../Components";
import { CreativeMap, CreativeMapItem } from "../redux/creative";
import { DEFAULT_DATE_SETTING, WHITE, pixelsToInches } from "./slideUtils";
import { DerivedNetworkMap } from "../redux/networks";
import { Form } from "react-bootstrap";
import { MetricsPagePreset } from "@blisspointmedia/bpm-types/dist/MetricsPage";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useEffect } from "react";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  DimensionData,
  PerformanceTableData,
} from "@blisspointmedia/bpm-types/dist/PerformanceSlide";
import {
  PresetIDGroups as SimpleMetricsTablePreset,
  MetricsTablePreset,
  GetPresetParams as GetTablePresetParams,
  Column,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  getSingleChannelMetricsTableColumnMetaDataMap,
  getSingleChannelMetricsTableData,
  getSingleChannelMetricsTableDataFetchKey,
  getSingleChannelMetricsTableDimensionCellMap,
  validateSingleChannelMetricsTableDataColumns,
} from "./SingleChanelMetricsTableFetchers";
import {
  CellData,
  constructTableData,
  getHeatMap,
  getHeatMapInfo,
  sortData,
} from "../SingleChannel/MetricsTable/metricsTableUtils";

const MAX_DATES = 4;
const MAX_FONT_SIZE = 20;
const EMPTY_ROW_DATA = {
  color: WHITE,
  content: "-",
  divider: false,
  value: -1,
};
const MEDIATYPE_MAP = {
  audio: "Audio",
  display: "Display",
  social: "Social",
  streaming: "Streaming",
  youtube: "YouTube",
};

export const makeDimKey = (dimensionData: DimensionData): string => {
  let dimKey = "";
  for (let key of R.keys(dimensionData)) {
    // Don't want to add type of dimensions just their values
    if (key !== "dimensions") {
      dimKey = `${dimKey}_${dimensionData[key]}`;
    }
  }
  return dimKey;
};

interface SingleChannelMetricsTableSlideData {
  companyColor: string | null;
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  footnote: string;
  singleChannelMetricsTableData: PerformanceTableData[] | null;
}

export interface SingleChannelMetricsTableSlideState {
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  dates: RelativeDateRange[];
  datesSort: boolean[];
  footnote: string;
  mediatype: string;
  numDates: number;
  numRows: number;
  selectedPagePreset: MetricsPagePreset | null;
  selectedTablePreset: SimpleMetricsTablePreset | null;
}
export const singleChannelMetricsTableSharedStateKey = "singleChannelMetricsTable" as const;

export interface SingleChannelMetricsTableSharedState {
  fetchedTablePresetMap: Record<string, MetricsTablePreset>;
}

export interface SingleChannelMetricsTableSharedStateContext {
  tablePresetID: number;
  state: SingleChannelMetricsTableSharedState;
}

export const singleChannelMetricsTableSharedFetcher: SharedStateFetcher<SingleChannelMetricsTableSharedState> = async (
  key: string,
  setError: SetError,
  context: SingleChannelMetricsTableSharedStateContext
) => {
  const [company, mediaType] = key.split("_");
  let res: SingleChannelMetricsTableSharedState = {
    fetchedTablePresetMap: {},
  };
  if (R.isNil(context)) {
    return res;
  } else {
    res.fetchedTablePresetMap = {
      ...context.state.fetchedTablePresetMap,
    };
    if (
      !R.isNil(context.tablePresetID) &&
      R.isNil(context.state.fetchedTablePresetMap[context.tablePresetID])
    ) {
      try {
        const tablePresetResp = await SingleChannelLambdaFetch<GetTablePresetParams>(
          "/metrics_table_preset",
          {
            params: { company, id: context.tablePresetID, mediatype: mediaType },
          }
        );
        const tablePreset = await awaitJSON<MetricsTablePreset>(tablePresetResp);
        res.fetchedTablePresetMap[context.tablePresetID] = tablePreset;
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    }
  }
  return res;
};

class SingleChannelMetricsTableSlide extends SlideType {
  static typeKey = "singleChannelMetricsTable";
  static displayKey = "Single Channel Metrics Table";
  static defaultState: SingleChannelMetricsTableSlideState = {
    customSlideHeader: "",
    customTableColumnWidth: 32,
    customTableFontSize: 9,
    customTableRowHeight: 30,
    dates: [DEFAULT_DATE_SETTING],
    datesSort: [true, false, false, false],
    footnote: "",
    mediatype: "streaming",
    numDates: 1,
    numRows: 20,
    selectedPagePreset: null,
    selectedTablePreset: null,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<SingleChannelMetricsTableSlideState>
  > = React.memo(({ slideContext, state, setState, sharedFetch, sharedState }) => {
    const {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      mediatype,
      numDates,
      numRows,
      selectedPagePreset,
      selectedTablePreset,
    } = state;
    const { company } = slideContext;
    const extremaKey = `${company}_${mediatype}`;
    const { pagePresets: pagePresetsMap, tablePresets: tablePresetsMap } = sharedState;
    const fetchedTablePresetMap =
      sharedState &&
      sharedState[singleChannelMetricsTableSharedStateKey] &&
      sharedState[singleChannelMetricsTableSharedStateKey][extremaKey]
        ? sharedState[singleChannelMetricsTableSharedStateKey][extremaKey].fetchedTablePresetMap
        : undefined;
    const pagePresets =
      pagePresetsMap && pagePresetsMap[mediatype] && pagePresetsMap[mediatype] !== "fetching"
        ? (pagePresetsMap[mediatype] as MetricsPagePreset[])
        : undefined;
    const tablePresets =
      tablePresetsMap && tablePresetsMap[mediatype] && tablePresetsMap[mediatype] !== "fetching"
        ? (tablePresetsMap[mediatype] as SimpleMetricsTablePreset[])
        : undefined;

    useEffect(() => {
      if (!R.path([singleChannelMetricsTableSharedStateKey, extremaKey], sharedState)) {
        (async () => {
          await sharedFetch(singleChannelMetricsTableSharedStateKey, extremaKey, null);
        })();
      }
    }, [sharedState, sharedFetch, extremaKey]);

    useEffect(() => {
      if (
        sharedState &&
        sharedState[singleChannelMetricsTableSharedStateKey] &&
        sharedState[singleChannelMetricsTableSharedStateKey][extremaKey]
      ) {
        (async () => {
          if (!R.isNil(tablePresets) && !R.isEmpty(tablePresets) && !R.isNil(selectedTablePreset)) {
            if (!R.map(elem => elem.id, tablePresets).includes(selectedTablePreset.id)) {
              setState({ selectedTablePreset: null });
            }
          }
          if (
            selectedTablePreset &&
            !R.isNil(fetchedTablePresetMap) &&
            R.isNil(fetchedTablePresetMap[selectedTablePreset.id])
          ) {
            try {
              await sharedFetch(singleChannelMetricsTableSharedStateKey, extremaKey, {
                state: {
                  ...sharedState[singleChannelMetricsTableSharedStateKey][extremaKey],
                },
                tablePresetID: selectedTablePreset.id,
                updateState: true,
              });
            } catch (e) {
              setState({ selectedTablePreset: null });
            }
          }
        })();
      }
    }, [
      extremaKey,
      fetchedTablePresetMap,
      selectedTablePreset,
      setState,
      sharedFetch,
      sharedState,
      tablePresets,
    ]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group className="options">
            <div className="presetSelector">
              {pagePresets && (
                <>
                  <Form.Label>Page Preset:</Form.Label>
                  <Dropdown
                    label={"Page Preset"}
                    size="lg"
                    value={selectedPagePreset ? selectedPagePreset.id.toString() : "undefined"}
                    options={R.map(preset => {
                      return { label: preset.name, value: preset.id.toString() };
                    }, pagePresets)}
                    onChange={presetID => {
                      let selectedPagePreset: MetricsPagePreset | null = null;
                      for (const preset of pagePresets) {
                        if (presetID === preset.id.toString()) {
                          selectedPagePreset = preset;
                        }
                      }
                      setState({ selectedPagePreset });
                    }}
                  />
                </>
              )}
              {tablePresets && (
                <>
                  <Form.Label>Table Preset:</Form.Label>
                  <Dropdown
                    label={"Table Preset"}
                    size="lg"
                    value={selectedTablePreset ? selectedTablePreset.id.toString() : "undefined"}
                    options={R.map(preset => {
                      return { label: preset.name, value: preset.id.toString() };
                    }, tablePresets)}
                    onChange={presetID => {
                      let selectedTablePreset: SimpleMetricsTablePreset | null = null;
                      for (const preset of tablePresets) {
                        if (presetID === preset.id.toString()) {
                          selectedTablePreset = preset;
                        }
                      }
                      setState({ selectedTablePreset });
                    }}
                  />
                </>
              )}
            </div>
            <div>
              <Form.Label>Font Size</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_FONT_SIZE}
                value={customTableFontSize}
                onChange={e => {
                  const size = R.defaultTo(1, parseInt(e.target.value));
                  const sizeInPxs = Math.ceil(size * (1 + 1 / 3));
                  if (customTableColumnWidth < sizeInPxs) {
                    setState({ customTableColumnWidth: sizeInPxs });
                  }
                  if (customTableRowHeight < sizeInPxs) {
                    setState({ customTableRowHeight: sizeInPxs });
                  }
                  setState({ customTableFontSize: size });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Date Ranges in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_DATES}
                value={numDates}
                onChange={e => {
                  let newDatesSort = [false, false, false, false];
                  let sortByIndex = 0;
                  const numDates = R.defaultTo(1, parseInt(e.target.value));
                  let newDates = Array(numDates);
                  for (let i = 0; i < numDates; i++) {
                    newDates[i] = dates[i] && i < dates.length ? dates[i] : DEFAULT_DATE_SETTING;
                    if (datesSort[i]) {
                      sortByIndex = i;
                    }
                  }
                  newDatesSort[sortByIndex] = true;
                  setState({ dates: newDates, datesSort: newDatesSort, numDates: numDates });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Rows in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={numRows}
                onChange={e => {
                  setState({ numRows: R.defaultTo(1, parseInt(e.target.value)) });
                }}
              />
            </div>
            <Form.Label className="note">
              NOTE: Remember to sort the page by the relevant column if the number of rows exceeds
              20 and to specify the global kpi and lag being used in the table and if any columns
              have a custom kpi or lag set in the optional footnote!
            </Form.Label>
            <div>
              <Form.Label>
                Custom Table Column Width in Pixels(
                {pixelsToInches(customTableColumnWidth).toFixed(2)} in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={32}
                value={customTableColumnWidth}
                onChange={e => {
                  let newWidth;
                  try {
                    newWidth = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newWidth = customTableColumnWidth;
                  }
                  setState({ customTableColumnWidth: newWidth });
                }}
              />
            </div>
            <div>
              <Form.Label>
                Custom Table Row Height in Pixels({pixelsToInches(customTableRowHeight).toFixed(2)}{" "}
                in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={10}
                value={customTableRowHeight}
                onChange={e => {
                  let newHeight;
                  try {
                    newHeight = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newHeight = customTableRowHeight;
                  }
                  setState({ customTableRowHeight: newHeight });
                }}
              />
            </div>
            <div className="footnoteDiv">
              Optional Footnote:
              <Form.Control
                className="footnoteTextEntry"
                value={footnote}
                onChange={e => {
                  setState({ footnote: e.target.value });
                }}
              />
            </div>
            <div>
              {`${
                footnote.length
                  ? `Optional Footnote on ${MEDIATYPE_MAP[mediatype]} Performance Slide:`
                  : ""
              }`}
            </div>
            <Form.Label className="note">{`${
              footnote.length ? "Note: " : ""
            }${footnote}`}</Form.Label>
          </Form.Group>
          <Form.Group className="datePickers">
            <div className="customSlideHeaderText">
              Optional Custom Slide Header:
              <Form.Control
                className="customSlideHeader"
                value={customSlideHeader ? customSlideHeader : ""}
                onChange={e => {
                  setState({ customSlideHeader: e.target.value });
                }}
              />
            </div>
            <div className="dp">
              {dates.length > 0 &&
                dates.map((date: RelativeDateRange, index: number) => {
                  if (date && date.start && date.end) {
                    return (
                      <div key={`Dates Pickers ${index + 1}`}>
                        <div className="dateRangeLabelAndSortBy">
                          <Form.Label className="dateRangeText">{`Start Date ${
                            index + 1
                          }`}</Form.Label>
                          <div className="dateRangeSortBy">
                            <Form.Label className="dateRangeSortByText">
                              Sort By Date Range?
                            </Form.Label>
                            <CheckBox
                              checked={datesSort[index]}
                              onCheck={e => {
                                let newDatesSort = [false, false, false, false];
                                newDatesSort[index] = e;
                                setState({ datesSort: newDatesSort });
                              }}
                              size="md"
                            />
                          </div>
                        </div>
                        <RelativeDatePicker
                          state={date.start}
                          onChange={start => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start, end: datesUpdate[index].end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                        <Form.Label className="dateRangeText">{`End Date ${index + 1}`}</Form.Label>
                        <RelativeDatePicker
                          state={date.end}
                          onChange={end => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start: datesUpdate[index].start, end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<SingleChannelMetricsTableSlideData> => {
    const { company } = context;
    let {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      mediatype,
      numDates,
      numRows,
      selectedPagePreset,
      selectedTablePreset,
    } = state as SingleChannelMetricsTableSlideState;
    const extremaKey = `${company}_${mediatype}`;
    const { fetchedTablePresetMap } = _.singleChannelMetricsTable[extremaKey];
    const { companyInfo, creativeMap, creativeNameMap, derivedNetworkMap } = _;
    if (R.isNil(companyInfo)) {
      throw new Error(`No default kpi or lag found for: ${company}`);
    }
    const { color: companyColor = null } = companyInfo;
    const mediatypeLabel =
      MEDIATYPE_MAP[mediatype.toLowerCase()] ||
      mediatype.charAt(0).toUpperCase() + mediatype.slice(1);
    if (R.isNil(selectedPagePreset)) {
      throw new Error(
        `No page preset selected. (${mediatypeLabel} Single Channel Metrics Table ${customSlideHeader}).`
      );
    }
    if (R.isNil(selectedTablePreset)) {
      throw new Error(
        `No table preset selected. (${mediatypeLabel} Single Channel Metrics Table ${customSlideHeader}).`
      );
    }
    const fetchedTablePreset = fetchedTablePresetMap[selectedTablePreset.id];
    if (R.isNil(fetchedTablePreset)) {
      throw new Error(
        `We are still loading your table presets, please wait. (${mediatypeLabel} Single Channel Metrics Table ${customSlideHeader}`
      );
    }
    let singleChannelMetricsTableData: PerformanceTableData[] = [];

    for (let i = 0; i < numDates; i++) {
      const dateObj = dates[i];
      const start = computeResolvedDate(dateObj.start);
      const end = Dfns.format(
        DATE_FORMAT,
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      const startLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.start)))
      );
      const endLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      if (fetchedTablePreset === null) {
        throw new Error(
          `No preset data (${mediatypeLabel} Single Channel Metrics Table ${customSlideHeader}, ${startLabel}-${endLabel}).`
        );
      }
      const slideName = `${customSlideHeader ? `${customSlideHeader}, ` : ""}PagePreset: ${
        selectedPagePreset.name
      }`;
      const fields = {
        agency: companyInfo.agency,
        company,
        end,
        id: 0, // TODO: remove this
        pageID: R.defaultTo({ id: 0 }, selectedPagePreset).id,
        start,
        tableID: selectedTablePreset.id,
      };
      let fetchedPerformanceData;
      try {
        fetchedPerformanceData = await getSingleChannelMetricsTableData[mediatype](fields);
      } catch (e) {
        throw new Error(
          `No data available for this date range (${mediatypeLabel} Single Channel Metrics Table ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      }
      let hasCreative = false;
      if (fetchedTablePreset && fetchedTablePreset.dimensionColumns) {
        for (let i = 0; i < fetchedTablePreset.dimensionColumns.length; ++i) {
          if (
            fetchedTablePreset.dimensionColumns[i].dimensionVarName
              .toLowerCase()
              .includes("creative")
          ) {
            hasCreative = true;
            break;
          }
        }
      }
      if (
        fetchedTablePreset === null ||
        !fetchedTablePreset.dataColumns ||
        !fetchedTablePreset.dimensionColumns ||
        R.isEmpty(fetchedTablePreset.dimensionColumns)
      ) {
        throw new Error(
          `No preset data (${mediatypeLabel} Single Channel Metrics Table ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (
        !fetchedPerformanceData ||
        !fetchedPerformanceData.data ||
        R.isEmpty(fetchedPerformanceData.data)
      ) {
        throw new Error(
          `No data available for this date range (${mediatypeLabel} Single Channel Metrics Table ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      } else if (hasCreative && (creativeMap === null || R.isEmpty(creativeMap))) {
        throw new Error(
          `Error with Creative Map (${mediatypeLabel} Single Channel Metrics Table ${slideName}, ${startLabel}-${endLabel}).
          Verify that streaming and/or audio and/or display is enabled for this client`
        );
      } else {
        if (!R.isNil(selectedPagePreset) && !R.isNil(selectedPagePreset.globalOptions)) {
          fetchedTablePreset.dataColumns = validateSingleChannelMetricsTableDataColumns(
            companyInfo,
            R.defaultTo([], fetchedTablePreset.dataColumns) as Column[],
            selectedPagePreset
          );
        }
        const columnMetaDataMap = getSingleChannelMetricsTableColumnMetaDataMap[mediatype];
        const { aggregateData, dimensionData, tableData } = constructTableData(
          columnMetaDataMap,
          fetchedTablePreset.dataColumns,
          fetchedTablePreset.dimensionColumns,
          getSingleChannelMetricsTableDimensionCellMap(
            creativeMap as CreativeMap,
            creativeNameMap as Record<string, CreativeMapItem>,
            derivedNetworkMap as DerivedNetworkMap
          )[mediatype],
          getSingleChannelMetricsTableDataFetchKey[mediatype],
          fetchedPerformanceData.data
        );
        const [sortedDimensionData, sortedTableData] =
          fetchedTablePreset.defaultSorting && fetchedTablePreset.defaultSorting.length
            ? sortData(
                fetchedTablePreset.dataColumns,
                dimensionData,
                fetchedTablePreset.dimensionColumns,
                fetchedTablePreset.defaultSorting,
                tableData,
                true
              )
            : [dimensionData, tableData];
        const heatMap = getHeatMap(fetchedTablePreset.dataColumns, sortedTableData);
        let aggColumnIndex = 0;
        // Convert data to performance slide data interfaces
        singleChannelMetricsTableData.push({
          headerData: R.map(
            column =>
              ({
                decimals: R.defaultTo(
                  columnMetaDataMap[column.dataVarName].decimals,
                  column.decimals
                ),
                divider: column.divider,
                heatMapping: column.heatMapping,
                id: column.id,
                label: R.defaultTo(columnMetaDataMap[column.dataVarName].displayName, column.label),
              } as any),
            fetchedTablePreset.dataColumns as Column[]
          ),
          rowData: R.map(tableDataRow => {
            let columnIndex = 0;
            const row = R.map((tableCell: CellData) => {
              const heatMapColor = getHeatMapInfo(
                columnIndex,
                columnMetaDataMap,
                fetchedTablePreset.dataColumns,
                heatMap,
                typeof tableCell.value === "number" ? tableCell.value : parseFloat(tableCell.value)
              ).backgroundColor;
              const color =
                tableCell.label !== "--" && heatMapColor !== "transparent" ? heatMapColor : WHITE;
              const cell = {
                color,
                content: tableCell.label,
                divider: fetchedTablePreset.dataColumns[columnIndex].divider,
                value: tableCell.value,
              } as any;
              columnIndex++;
              return cell;
            }, tableDataRow as CellData[]);
            return row;
          }, sortedTableData as CellData[][]),
          aggregateData: R.map(cell => {
            const aggCell = {
              divider: fetchedTablePreset.dataColumns[aggColumnIndex].divider,
              text: cell.label,
            } as any;
            aggColumnIndex++;
            return aggCell;
          }, aggregateData as CellData[]),
          dimensionData: R.map(dimensionRow => {
            const dimRow = {
              dimensions: [] as { label: string; url?: string }[],
            };
            for (const dimHeader of fetchedTablePreset.dimensionColumns) {
              const dimCell = dimensionRow[dimHeader.dimensionTypeName];
              if (dimHeader.icon === "hasIcon") {
                dimRow.dimensions.push({
                  headerLabel: R.defaultTo(
                    columnMetaDataMap[dimHeader.dimensionTypeName].displayName,
                    dimHeader.label
                  ),
                  label: dimCell.label,
                  url: dimCell.url,
                } as any);
                dimRow.dimensions.push({
                  headerLabel: "",
                  label: dimCell.label,
                } as any);
              } else if (dimHeader.icon === "iconOnly") {
                dimRow.dimensions.push({
                  headerLabel: R.defaultTo(
                    columnMetaDataMap[dimHeader.dimensionTypeName].displayName,
                    dimHeader.label
                  ),
                  label: dimCell.label,
                  url: dimCell.url,
                } as any);
              } else {
                dimRow.dimensions.push({
                  headerLabel: R.defaultTo(
                    columnMetaDataMap[dimHeader.dimensionTypeName].displayName,
                    dimHeader.label
                  ),
                  label: dimCell.label,
                } as any);
              }
            }
            return dimRow as any;
          }, sortedDimensionData),
          start,
          end,
        });
      }
    }

    let sortIndex = 0;
    for (let i = 0; i < datesSort.length; i++) {
      if (datesSort[i]) {
        sortIndex = i;
      }
    }

    let dimensionKeys: Record<string, number> = {};
    let numRowsAdjusted = numRows;
    if (
      singleChannelMetricsTableData[sortIndex].dimensionData &&
      singleChannelMetricsTableData[sortIndex].dimensionData.length < numRowsAdjusted
    ) {
      numRowsAdjusted = singleChannelMetricsTableData[sortIndex].dimensionData.length;
    } else if (
      !singleChannelMetricsTableData[sortIndex].dimensionData ||
      !singleChannelMetricsTableData[sortIndex].rowData
    ) {
      throw new Error(
        `Failed to get streaming performance data (${mediatypeLabel} Performance New)`
      );
    }

    // Store indexes of dimension data in case subsequent date ranges are not in the same order
    for (let j = 0; j < numRowsAdjusted; j++) {
      const { dimensionData } = singleChannelMetricsTableData[sortIndex];
      const dimKey = makeDimKey(dimensionData[j]);
      dimensionKeys[dimKey] = j;
    }

    for (let i = 0; i < numDates; i++) {
      // If data is missing, enter zeros
      const { dimensionData, rowData, headerData } = singleChannelMetricsTableData[i];
      let sortedRowData = Array(numRowsAdjusted).fill(
        Array(R.defaultTo([], headerData).length).fill(EMPTY_ROW_DATA)
      );
      if (dimensionData && rowData) {
        if (i === sortIndex) {
          sortedRowData = rowData.slice(0, numRowsAdjusted);
        } else {
          for (let k = 0; k < R.defaultTo(0, rowData.length); k++) {
            const dimKey = makeDimKey(dimensionData[k]);
            // Must check for zero or else this index will be ignored
            if (dimensionKeys[dimKey] || dimensionKeys[dimKey] === 0) {
              sortedRowData[dimensionKeys[dimKey]] = rowData ? rowData[k] : EMPTY_ROW_DATA;
            }
          }
        }
      }
      // Sort data using first date range
      const dimData = singleChannelMetricsTableData[sortIndex].dimensionData;
      singleChannelMetricsTableData[i].dimensionData = dimData
        ? dimData.slice(0, numRowsAdjusted)
        : dimData;
      singleChannelMetricsTableData[i].rowData = sortedRowData;
    }
    footnote = footnote.trim();
    if (footnote) {
      footnote = `Note: ${footnote}`;
    }
    if (!customSlideHeader) {
      customSlideHeader = `${MEDIATYPE_MAP[mediatype.toLowerCase()]} Single Channel Metrics Table`;
    }
    return {
      companyColor,
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      footnote,
      singleChannelMetricsTableData,
    };
  };
}

export default SingleChannelMetricsTableSlide;
