import "./DefaultLegend.scss";
import React from "react";
import { Neutral200 as INACTIVE_COLOR } from "../../../../utils/colors";
import { MdOutlineWarningAmber } from "react-icons/md";
import MoreInfo from "../../../../MMM/MoreInfo";

interface DefaultLegendProps {
  areas: {
    name: string;
    grouping?: { channel: string; platform: string; tactic: string; brand_nonbrand: string };
    incrementality?: boolean;
    toolTip?: string;
    toolTipColor?: string;
    line?: boolean;
  }[];
  activeArea: string | null;
  activeGroup: string | null;
  handleLegendClick: (area: string) => void;
  setHoveredLegend: React.Dispatch<React.SetStateAction<string | null>>;
  handleLegendEnter: (area: string) => void;
  hoveredLegend: string | null;
  incrementality?: boolean;
  colorMap: any;
  reverseLegend?: boolean;
}

const DefaultLegend = ({
  areas,
  activeArea,
  hoveredLegend,
  colorMap,
  handleLegendClick,
  handleLegendEnter,
  incrementality,
  setHoveredLegend,
  reverseLegend,
}: DefaultLegendProps): JSX.Element => {
  const displayedAreas = reverseLegend ? [...areas].reverse() : areas;
  const renderItem = displayedAreas.map(area => {
    const isActiveArea = activeArea === null ? true : activeArea === area.name;
    const isHoveredLegend = hoveredLegend === null ? true : hoveredLegend === area.name;
    const activeColor = isActiveArea ? colorMap?.[area.name] : INACTIVE_COLOR;
    const hoverColor = isHoveredLegend ? colorMap?.[area.name] : INACTIVE_COLOR;
    return (
      <div
        className="legendItem"
        key={area.name}
        onClick={() => handleLegendClick(area.name)}
        onMouseEnter={() => handleLegendEnter(area.name)}
        onMouseLeave={() => setHoveredLegend(null)}
      >
        <div className="legendValue">
          <div className={`${isActiveArea ? "" : "inactiveLegend"}`}>
            <div style={{ display: "flex" }}>
              <div
                className={area.line ? "dash" : "square"}
                style={{
                  backgroundColor: `${activeArea ? activeColor : hoverColor}`,
                }}
              />
              <div className="legendHeader">{`${
                area.grouping ? area.grouping.channel : area.name
              }`}</div>
              {area.toolTip && (
                <div className="toolTipBox">
                  <MoreInfo color={area.toolTipColor} size="sm">
                    {area.toolTip}
                  </MoreInfo>
                </div>
              )}
              {area.incrementality && incrementality && (
                <MdOutlineWarningAmber
                  style={{
                    color: `${isActiveArea || activeArea === null ? "orange" : INACTIVE_COLOR}`,
                    marginLeft: "5px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <div className="legend">{renderItem}</div>;
};

export default DefaultLegend;
