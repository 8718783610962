import { AreaChart, Area, CartesianGrid, ReferenceArea, XAxis, YAxis } from "recharts";
import { awaitJSON, LinearLambdaFetch } from "../utils/fetch-utils";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { primary } from "../utils/colors";
import { Spinner } from ".";
import { useSetError } from "../redux/modals";
import * as CompanyRedux from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useState, useEffect, useMemo, ReactElement, useCallback } from "react";

const DATE_FORMAT = "yyyy-MM-dd";
const TICK_COUNT = 9;
const TICK_STYLE = { fontSize: 12, FontFace: "Century Gothic" };

export const DEFAULT_DATE = (): string => {
  let today = Dfns.format(DATE_FORMAT, new Date());
  let startOfWeek = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

  if (today === startOfWeek) {
    return R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(1), Dfns.format(DATE_FORMAT))(new Date());
  }

  return R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
};

// Download PNG
export const pngUri = (id: string): string => {
  let chart = document.querySelectorAll(`#${id} svg`);
  let serializer = new XMLSerializer();
  let svgData = serializer.serializeToString(chart[0]);

  return convertSVGStringToPNGURI(svgData);
};

interface GetXAxisTicksInput {
  formattedTime: string;
}

export const getXAxisTicks = (data: GetXAxisTicksInput[], tickCount?: number): number[] | null => {
  if (!data) {
    return null;
  }
  if (R.isEmpty(data)) {
    return null;
  }

  let ticks: number[] = [];
  let tickMap = {};
  let count = 0;
  for (let elem of data) {
    let dateTick: string = Dfns.format("MMM do", new Date(elem.formattedTime).getTime());
    if (tickMap[dateTick] === undefined && count < R.defaultTo(0, tickCount)) {
      ticks.push(new Date(elem.formattedTime).getTime());
      tickMap[dateTick] = true;
      count++;
    }
  }
  return ticks;
};

export const getSpikeChartData = async (
  kpi: string,
  week: string
): Promise<SpikeChartData[] | null> => {
  try {
    let res = await LinearLambdaFetch("/spike_chart", { params: { kpi: kpi, week: week } });
    let spikeData = await awaitJSON(res);
    let spikeChartData: SpikeChartData[] = spikeData.map(item => {
      let time = Dfns.parseISO(item.formattedTime).getTime();
      let creativeInfo: CreativeInfo[] = [];
      if (item.creativeInfo) {
        for (let airing of item.creativeInfo) {
          creativeInfo.push({ ...airing, timeslot: time });
        }
      }
      if (R.length(creativeInfo)) {
        return { ...item, time, creativeInfo };
      }
      return { ...item, time };
    });
    if (spikeChartData && spikeChartData.length > 1) {
      spikeChartData = R.sort((a, b) => {
        let bDate = new Date(Dfns.parseISO(b.formattedTime).getTime());
        let aDate = new Date(Dfns.parseISO(a.formattedTime).getTime());
        return (
          Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()) -
          Date.UTC(bDate.getFullYear(), bDate.getMonth(), bDate.getDate())
        );
      }, spikeChartData);
    }
    return spikeChartData || null;
  } catch (e) {
    const error = e as Error;
    throw error;
  }
};

export const getCreativeInfoFromSpikeChartData = (
  data: SpikeChartData[]
): CreativeInfo[] | null => {
  if (!data) {
    return null;
  }
  let maxY = Math.max(...R.map(elem => elem.kpiCount, data));
  let minX = data[0].time;
  let maxX = data[data.length - 1].time;
  let xRange = maxX - minX;

  let dataWithCreativeInfo = R.filter(entry => {
    return !!entry.creativeInfo && !!entry.dotValue;
  }, data) as SpikeChartData[];
  let creativeInfo = R.flatten(R.map(elem => elem.creativeInfo, dataWithCreativeInfo));
  creativeInfo = R.map(elem => {
    elem.xPercentage = (elem.timeslot - minX) / xRange;
    elem.yPercentage = 1 - R.defaultTo(0, elem.kpi) / maxY;
    return elem;
  }, creativeInfo);

  return R.sort((a, b) => {
    return b.kpi - a.kpi;
  }, creativeInfo as OutputCreativeInfo[]);
};

interface GetYAxisDomainDataInput {
  time: number;
  kpiCount: number;
}

// Y-Axis Domain
export const getYAxisDomain = (
  data: GetYAxisDomainDataInput[],
  left: number,
  right: number
): number[] => {
  const refData = data.filter(item => item.time >= left && item.time <= right);
  let [bottom, top] = [refData[0].kpiCount, refData[0].kpiCount];

  for (let item of refData) {
    if (item.kpiCount > top) {
      top = item.kpiCount;
    }
    if (item.kpiCount < bottom) {
      bottom = item.kpiCount;
    }
  }

  return [bottom, Math.ceil(top * 1.05)];
};

export interface CreativeInfo {
  avail: string;
  cost: number;
  creative: string;
  kpi: number;
  length: number;
  network: string;
  networkName: string;
  program: string;
  time: string;
  timeslot: number;
  xPercentage?: number;
  yPercentage?: number;
  overrideImage?: boolean;
}

export interface OutputCreativeInfo {
  avail: string;
  cost: number;
  creative: string;
  kpi: number;
  length: number;
  network: string;
  networkName: string;
  program: string;
  time: string;
  timeslot: number;
  xPercentage: number;
  yPercentage: number;
  overrideImage: boolean;
}

export interface SpikeChartData {
  time: number;
  formattedTime: string;
  kpiCount: number;
  dotValue: number;
  creativeInfo: CreativeInfo[];
}

interface Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
interface Grid {
  vertical: boolean;
  stroke: string;
  strokeWidth: number;
}

interface SpikeChartProps {
  bottom?: number | string;
  chartData?: SpikeChartData[];
  fillColor?: string;
  fillOpacity?: number;
  grid?: Grid;
  height: number;
  kpi: string;
  left?: number | string;
  margin?: Margin;
  noXAxisTitle?: boolean;
  noYAxisTitle?: boolean;
  onPathLoad?: (loaded: boolean) => void;
  rechartsToolTip?: any;
  renderDot?:
    | ReactElement<SVGElement>
    | ((props: any) => ReactElement<SVGElement>)
    | ((props: any) => ReactElement<SVGElement>)
    | boolean;
  returnInner?: boolean;
  right?: number | string;
  setBottom?: (bottom: number) => void;
  setLeft?: (left: number) => void;
  setRight?: (right: number) => void;
  setTop?: (top: number) => void;
  strokeColor?: string;
  strokeWidth?: number;
  top?: number | string;
  width: number;
  xAxisLine?: boolean;
  xAxisPadding?: Omit<Margin, "top" | "bottom">;
  xAxisTickCount?: number;
  xAxisTickLine?: boolean;
  yAxisLine?: boolean;
  yAxisPadding?: Omit<Margin, "left" | "right">;
  yAxisTickCount?: number;
  yAxisTickLine?: boolean;
}
export const SpikeChart = React.forwardRef<any, SpikeChartProps>(
  (
    {
      bottom = 0,
      chartData,
      fillColor = primary,
      fillOpacity = 0.5,
      grid,
      height,
      kpi,
      left = "dataMin",
      margin,
      noXAxisTitle = false,
      noYAxisTitle = false,
      onPathLoad,
      rechartsToolTip,
      renderDot,
      returnInner = false,
      right = "dataMax",
      setBottom,
      setLeft,
      setRight,
      setTop,
      strokeColor = primary,
      strokeWidth = 2,
      top = "auto",
      width,
      xAxisLine = true,
      xAxisPadding,
      xAxisTickCount = TICK_COUNT,
      xAxisTickLine = true,
      yAxisLine = false,
      yAxisPadding,
      yAxisTickCount = TICK_COUNT,
      yAxisTickLine = true,
    },
    ref
  ) => {
    const setError = useSetError();
    const week = DEFAULT_DATE();
    const [data, setData] = useState<SpikeChartData[]>([] as SpikeChartData[]);

    const { kpis } = CompanyRedux.useCompanyInfo();
    const [yAxisTitle, setYAxisTitle] = useState<string | null>(null);

    useEffect(() => {
      for (let kpiInfo of kpis) {
        if (kpiInfo.id === kpi) {
          setYAxisTitle(kpiInfo.name);
        }
      }
    }, [kpi, kpis]);

    useEffect(() => {
      (async () => {
        // If we need to use chartData outside of this function we can use the exported get call and
        // pass the data in here
        if (!chartData) {
          let pulledData = await getSpikeChartData(kpi, week);
          if (pulledData != null) {
            setData(pulledData);
          }
        } else {
          setData(chartData);
        }
      })();
    }, [kpi, week, setError, chartData, returnInner]);

    // Generate X-axis ticks
    const xTicks = useMemo(() => {
      return getXAxisTicks(data, xAxisTickCount);
    }, [data, xAxisTickCount]);

    useEffect(() => {
      if (data && xTicks) {
        if (onPathLoad) {
          onPathLoad(true);
        }
      }
    }, [data, onPathLoad, xTicks]);

    // Generate Y-axis ticks
    const yTicks = useMemo(() => {
      if (!data) {
        return null;
      }
      if (R.isEmpty(data)) {
        return null;
      }

      let tickCount = yAxisTickCount ? yAxisTickCount : TICK_COUNT;
      let maxY = Math.max(...R.map(elem => elem.kpiCount, data));
      let ticks: number[] = [];

      for (let i = 0; i < tickCount; i++) {
        ticks.push(Math.round((i * maxY) / (tickCount - 1)));
      }

      return ticks;
    }, [data, yAxisTickCount]);

    const [refAreaLeft, setRefAreaLeft] = useState<number>(-1);
    const [refAreaRight, setRefAreaRight] = useState<number>(-1);

    // Zoom In
    const zoom = useCallback(() => {
      if (refAreaLeft && refAreaRight && refAreaLeft !== refAreaRight) {
        let newLeft = refAreaLeft;
        let newRight = refAreaRight;
        if (refAreaLeft > refAreaRight) {
          [newLeft, newRight] = [newRight, newLeft];
        }
        const [bottom, top] = getYAxisDomain(data, newLeft, newRight);
        if (setLeft) {
          setLeft(newLeft);
        }
        if (setRight) {
          setRight(newRight);
        }
        if (setTop) {
          setTop(top);
        }
        if (setBottom) {
          setBottom(bottom);
        }
      }
      setRefAreaLeft(-1);
      setRefAreaRight(-1);
    }, [refAreaLeft, refAreaRight, data, setLeft, setRight, setTop, setBottom]);

    const chart = useMemo(() => {
      if (data && xTicks && yTicks) {
        const cGrid = grid ? (
          <CartesianGrid
            vertical={grid.vertical}
            stroke={grid.stroke}
            strokeWidth={grid.strokeWidth}
          />
        ) : null;
        const innerChartArea = returnInner ? (
          <Area
            ref={ref}
            type="natural"
            dataKey="kpiCount"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            fillOpacity={fillOpacity}
            fill={fillColor}
            isAnimationActive={false}
            activeDot={false}
            dot={renderDot}
          />
        ) : (
          <Area
            type="natural"
            dataKey="kpiCount"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            fillOpacity={fillOpacity}
            fill={fillColor}
            isAnimationActive={false}
            activeDot={false}
            dot={renderDot}
          />
        );
        const xAxis = (
          <XAxis
            dataKey="time"
            type="number"
            domain={[left === -1 ? "dataMin" : left, right === -1 ? "dataMax" : right]}
            allowDataOverflow
            padding={{
              left: xAxisPadding ? xAxisPadding.left : 0,
              right: xAxisPadding ? xAxisPadding.right : 0,
            }}
            tickLine={xAxisTickLine}
            axisLine={xAxisLine}
            tickFormatter={time => {
              let tick = Dfns.format("MMM do", new Date(time).getTime());
              return tick;
            }}
            tick={TICK_STYLE}
            label={{
              value: !noXAxisTitle ? "Eastern Spot Time" : "",
              angle: 0,
              position: "bottom",
            }}
            ticks={xTicks}
            tickCount={xAxisTickCount ? xAxisTickCount : xTicks.length}
          />
        );
        const yAxis = (
          <YAxis
            tickLine={yAxisTickLine}
            axisLine={yAxisLine}
            domain={[bottom, top]}
            label={{
              value: !noYAxisTitle && yAxisTitle ? yAxisTitle : "",
              angle: -90,
              position: "insideLeft",
            }}
            tick={TICK_STYLE}
            allowDataOverflow
            tickCount={yAxisTickCount ? yAxisTickCount : yTicks.length}
            ticks={yTicks}
            padding={{
              top: yAxisPadding ? yAxisPadding.top : 0,
              bottom: yAxisPadding ? yAxisPadding.bottom : 0,
            }}
          />
        );
        return (
          <AreaChart
            data={data}
            width={width}
            height={height}
            margin={margin}
            onMouseDown={e => {
              let mouseEvent = e || {};
              setRefAreaLeft((mouseEvent.activeLabel as unknown) as number);
            }}
            onMouseMove={e => {
              let mouseEvent = e || {};
              return refAreaLeft && setRefAreaRight((mouseEvent.activeLabel as unknown) as number);
            }}
            onMouseUp={() => zoom()}
          >
            {cGrid}
            {innerChartArea}
            {xAxis}
            {yAxis}
            {rechartsToolTip ? rechartsToolTip : null}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea x1={refAreaLeft} x2={refAreaRight} />
            ) : null}
          </AreaChart>
        );
      } else {
        return null;
      }
    }, [
      bottom,
      data,
      fillColor,
      fillOpacity,
      grid,
      height,
      left,
      margin,
      noXAxisTitle,
      noYAxisTitle,
      rechartsToolTip,
      ref,
      refAreaLeft,
      refAreaRight,
      renderDot,
      returnInner,
      right,
      strokeColor,
      strokeWidth,
      top,
      width,
      xAxisLine,
      xAxisPadding,
      xAxisTickCount,
      xAxisTickLine,
      xTicks,
      yAxisLine,
      yAxisPadding,
      yAxisTickCount,
      yAxisTickLine,
      yAxisTitle,
      yTicks,
      zoom,
    ]);
    if (returnInner) {
      return chart;
    } else {
      return (
        <div className="spikeChart" style={{ width, height }}>
          {chart ? (
            <svg ref={ref} viewBox={`0 0 ${width} ${height}`}>
              {chart}
            </svg>
          ) : (
            <Spinner />
          )}
        </div>
      );
    }
  }
);
