import { useState, useMemo, useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import * as R from "ramda";
import { XAxis, YAxis, BarChart, Bar, LabelList, Cell } from "recharts";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { abbreviateNumber } from "../utils/data";
import {
  Skeleton,
  BarGraphSkeleton,
  AxesSkeleton,
  DownloadDropdown,
  DropdownToggleType,
  Dropdown,
} from "../Components";
import {
  BUDGET_SNAPSHOT_GREY,
  TICK_STYLE,
  MEDIA_TYPE_TO_BAR_COLOR,
  MEDIA_TYPE_TO_STROKE_COLOR,
} from "./homePageConstants";
import ChartContainer from "../Components/ChartContainer";
import LinkSelector from "./LinkSelector";
import BudgetSnapshotLegend from "./BudgetSnapshotLegend";
import "./BudgetSnapshot.scss";

interface BudgetSnapshotData {
  mediaType: string;
  budgetLastWeek: number;
  spendLastWeek: number;
  budgetThisWeek: number;
}

interface BudgetSnapshotProps {
  data: BudgetSnapshotData[];
  company: string;
  toggledMediaTypes: Record<string, boolean>;
}

const BUDGET_COMPARATOR_OPTIONS = [{ value: "spend" }, { value: "this week's budget" }];

const BudgetSnapshot: React.FC<BudgetSnapshotProps> = ({ data, company, toggledMediaTypes }) => {
  const [budgetComparator, setBudgetComparator] = useState<string>("spend");

  const linkMetadata = {
    title: "delivery page",
    links: [
      { name: "Streaming", href: `/${company}/streaming/delivery` },
      { name: "Audio", href: `/${company}/audio/delivery` },
      { name: "Linear", href: `/${company}/linear/delivery` },
      { name: "Display", href: `/${company}/display/delivery` },
    ],
  };

  const totals = useMemo(() => {
    let totalLastWeekBudget = 0;
    let totalLastWeekSpend = 0;
    let totalThisWeekBudget = 0;

    if (data) {
      /// @ts-ignore
      for (let item of data) {
        totalLastWeekBudget += item.budgetLastWeek;
        totalLastWeekSpend += item.spendLastWeek;
        totalThisWeekBudget += item.budgetThisWeek;
      }
    }

    if (budgetComparator === "spend") {
      const diff = totalLastWeekSpend - totalLastWeekBudget;
      return {
        label: diff > 0 ? "over budget" : "under budget",
        value: `${diff > 0 ? "+" : "-"}$${abbreviateNumber(Math.abs(diff))}`,
      };
    } else {
      const diff = totalThisWeekBudget - totalLastWeekBudget;
      return {
        label: diff > 0 ? "increase" : "decrease",
        value: `${diff > 0 ? "+" : "-"}$${abbreviateNumber(Math.abs(diff))}`,
      };
    }
  }, [data, budgetComparator]);

  const filteredData = useMemo(
    () =>
      (data || [])
        .filter(row => toggledMediaTypes[row.mediaType])
        .sort((a, b) => a.mediaType.localeCompare(b.mediaType)),
    [data, toggledMediaTypes]
  );

  // This is a hack to dynamically calculate how large the 2 bars (per media type) should be in relation to each other.
  const [budgetBarSize, otherBarSize] = useMemo(() => {
    // The max size based on the current fixed height of the container.
    const TOTAL_SIZE = 250;

    if (filteredData) {
      const budgetBarSize = TOTAL_SIZE / filteredData.length;
      const otherBarSize = budgetBarSize * 0.4;
      return [budgetBarSize, otherBarSize];
    } else {
      return [0, 0];
    }
  }, [filteredData]);

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_BudgetSnapshot.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet(filteredData);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, filteredData]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".budgetSnapshot");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_BudgetSnapshot.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Budget Snapshot"
      leftActions={<LinkSelector title={linkMetadata.title} links={linkMetadata.links} />}
      rightActions={
        <>
          <DownloadDropdown
            size="sm"
            onClickOptions={[exportToExcel, downloadPNG]}
            disabled={!filteredData}
          ></DownloadDropdown>
        </>
      }
    >
      {data ? (
        <div className="budgetSnapshot">
          <div className="aboveChart">
            <div className="budgetSnapshotControls">
              <div className="budgetSpendSelector">
                <span>
                  Last week's{" "}
                  <span style={{ backgroundColor: BUDGET_SNAPSHOT_GREY, padding: "4px" }}>
                    budget
                  </span>{" "}
                  vs{" "}
                </span>
                <Dropdown
                  className="budgetSnapshotDropdown"
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={budgetComparator}
                  options={BUDGET_COMPARATOR_OPTIONS}
                  onChange={option => setBudgetComparator(option)}
                />
              </div>
              <div className="totalBudgetPacing">
                Total: {totals.label}, {totals.value}
              </div>
            </div>
          </div>
          <div className="chartContents">
            <div id="budgetSnapshotChart" className="budgetSnapshotChart">
              <AutoSizer>
                {({ width, height }) => (
                  <BarChart
                    width={width}
                    height={height}
                    barGap={-30}
                    data={filteredData}
                    layout="vertical"
                  >
                    <YAxis type="category" dataKey="mediaType" yAxisId={0} hide />
                    <YAxis
                      type="category"
                      dataKey="mediaType"
                      yAxisId={1}
                      padding={{ top: -5, bottom: 5 }}
                      hide
                    />
                    <XAxis
                      type="number"
                      tick={TICK_STYLE}
                      tickFormatter={number => `$${abbreviateNumber(number)}`}
                    />
                    <Bar
                      dataKey="budgetLastWeek"
                      fill={BUDGET_SNAPSHOT_GREY}
                      barSize={budgetBarSize}
                      yAxisId={0}
                      isAnimationActive={false}
                    >
                      <LabelList
                        dataKey="budgetLastWeek"
                        position="insideBottomLeft"
                        formatter={value => `$${abbreviateNumber(value)}`}
                        fill={"#000000"}
                      />
                    </Bar>
                    <Bar
                      dataKey={budgetComparator === "spend" ? "spendLastWeek" : "budgetThisWeek"}
                      barSize={otherBarSize}
                      yAxisId={1}
                      radius={[0, 20, 20, 0]}
                      isAnimationActive={false}
                    >
                      <LabelList
                        dataKey={budgetComparator === "spend" ? "spendLastWeek" : "budgetThisWeek"}
                        position="insideTopLeft"
                        formatter={value => `$${abbreviateNumber(value)}`}
                        fill={"#000000"}
                      />
                      {filteredData.map(entry => {
                        return (
                          <Cell
                            key={entry.mediaType}
                            fill={MEDIA_TYPE_TO_BAR_COLOR[entry.mediaType]}
                            stroke={MEDIA_TYPE_TO_STROKE_COLOR[entry.mediaType]}
                            color={"#000000"}
                          />
                        );
                      })}
                    </Bar>
                  </BarChart>
                )}
              </AutoSizer>
            </div>
            <div className="rightOfChart">
              <BudgetSnapshotLegend
                mediaTypes={R.keys(R.pickBy(v => v, toggledMediaTypes))}
                data={data}
                budgetComparator={budgetComparator}
              />
            </div>
          </div>
        </div>
      ) : (
        <Skeleton>
          <BarGraphSkeleton barWidth={14} gutter={3} verticalPadding={5} />
          <AxesSkeleton size={3} />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default BudgetSnapshot;
