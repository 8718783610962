import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import {
  BPMDateRange,
  Dropdown,
  DropdownToggleType,
  LineAndBarChart,
  LineChart,
} from "../../Components";
import { DateRange } from "../../utils/types";
import { TMR_COLORS, tmrKpiPull, PROFILE_FILTER_MESSAGING } from "./utils";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { SharedState, SlideState } from "../slideTemplateConstants";
import {
  SettingsComponentProps,
  SlideContext,
  SlideType,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { fetchDspSlideData } from "./QueryServices";

export interface MarketplaceReportDspSlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportDspSlideData {
  title: string;
  subTitle: string;
}

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

class MarketplaceReportDspSlide extends SlideType {
  static typeKey = "TmrDspSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportDspSlideState = {
    title: "DSP: Display Advertising",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportDspSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { title, subTitle, dates, kpi, companyProfile } = state;
    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
         <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportDspSlideData> => {
    let { company } = context;
    const { title, subTitle, dates, companyProfile, kpi } = state as MarketplaceReportDspSlideState;
    const lineHeight = 300;
    const lineWidth = 500;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }

    let data = await fetchDspSlideData(company, dates.start, dates.end, profileNames);

    const salesKpiBarChart = (
      <LineAndBarChart
        barChartColor={TMR_COLORS.BLUE}
        barDataKey="sales"
        barYAxisDataKey="sales"
        barYAxisLabel="Sales"
        data={data.dataByWeek}
        height={lineHeight}
        isAnimationActive={false}
        legendLocation="top"
        lineChartColor={TMR_COLORS.PINK}
        lineDataKey={kpi}
        lineYAxisDataKey={kpi}
        lineYAxisLabel={kpi.toUpperCase()}
        width={lineWidth}
        xAxisDataKey="week"
      />
    );

    const salesKpiUrl = await convertComponentToImageUrl(
      salesKpiBarChart,
      lineHeight,
      lineWidth,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const spendImpressionBarChart = (
      <LineAndBarChart
        data={data.dataByWeek}
        lineDataKey="impressions"
        barDataKey="spend"
        xAxisDataKey="week"
        barYAxisDataKey="spend"
        barYAxisLabel="Spend"
        lineYAxisDataKey="impressions"
        lineYAxisLabel="Impressions"
        legendLocation="top"
        isAnimationActive={false}
        height={lineHeight}
        width={lineWidth}
        barChartColor={TMR_COLORS.YELLOW}
        lineChartColor={TMR_COLORS.PURPLE}
      />
    );

    const spendImpressionUrl = await convertComponentToImageUrl(
      spendImpressionBarChart,
      lineHeight,
      lineWidth,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const clicksChart = (
      <LineChart
        data={data.dataByWeek}
        height={lineHeight}
        isAnimationActive={false}
        legendLocation="top"
        lineDataKeys={[{ dataKey: "clicks", yAxisId: 0 }]}
        width={lineWidth}
        xAxisDataKey="week"
        rightYAxisDataKey="clicks"
        rightYAxisLabel="Clicks"
      />
    );

    const clicksChartUrl = await convertComponentToImageUrl(
      clicksChart,
      lineHeight,
      lineWidth,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const dpvChart = (
      <LineChart
        data={data.dataByWeek}
        height={lineHeight}
        isAnimationActive={false}
        legendLocation="top"
        lineChartColors={[TMR_COLORS.GREEN]}
        lineDataKeys={[{ dataKey: "dpv", yAxisId: 0 }]}
        width={lineWidth}
        xAxisDataKey="week"
        rightYAxisDataKey="dpv"
        rightYAxisLabel="DPV"
      />
    );

    const dpvChartUrl = await convertComponentToImageUrl(
      dpvChart,
      lineHeight,
      lineWidth,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      dates,
      data,
      salesKpiUrl,
      spendImpressionUrl,
      clicksChartUrl,
      dpvChartUrl,
      kpi,
    });
  };
}
export default MarketplaceReportDspSlide;
