import {
  awaitJSON,
  SingleChannelLambdaFetch,
  StreamingPerformanceLambdaFetch,
  YoutubePerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import { DateRange, typedReactMemo } from "../../utils/types";
import { DimensionMap } from "../MetricsTable/metricsTableUtils";
import { fetchDailyDelivery } from "../../YouTubeDelivery/YouTubeDelivery";
import { FullPageSpinner } from "../../Components";
import { overrideDateRange } from "../../utils/test-account-utils";
import { RouteComponentProps } from "@reach/router";
import { useCompanyInfo } from "../../redux/company";
import { useSetError } from "../../redux/modals";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import React, { useCallback, useEffect, useState } from "react";
import SingleChannelPage from "../SingleChannelPage";
import useLocation from "../../utils/hooks/useLocation";
import {
  columnMetaDataMap,
  dimensionColumnMetaDataMap,
  getDimensionCell as YT_GET_DIMENSION_CELL,
  GLOSSARY,
} from "./youtubePerformanceUtils";
import {
  DeletePresetParams as DeletePagePresetParams,
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  SavePresetParams as SaveTablePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

interface YoutubeSingleChannelProps extends RouteComponentProps {
  urlSettings: string;
}

const YoutubeSingleChannel = typedReactMemo<React.FC<YoutubeSingleChannelProps>>(
  ({ location, urlSettings }) => {
    const [dates, setDates] = useState<DateRange>();
    const [fetchingPagePresets, setFetchingPagePresets] = useState(false);
    const [fetchingTablePresets, setFetchingTablePresets] = useState(false);
    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();
    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();
    const [pagePresets, setPagePresets] = useState<MetricsPagePreset[]>();
    const [tablePresets, setTablePresets] = useState<MetricsTablePreset[]>();
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();
    const setError = useSetError();

    const getPagePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_page_presets",
            {
              params: {
                company,
                mediatype: "youtube",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setPagePresets(parsedPresets as MetricsPagePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch page presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingPagePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_table_presets",
            {
              params: {
                company,
                mediatype: "youtube",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setTablePresets(parsedPresets as MetricsTablePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch table presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingTablePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            const res = await SingleChannelLambdaFetch<GetTablePresetParams>(
              "/metrics_table_preset",
              {
                params,
              }
            );
            return await awaitJSON<MetricsTablePreset>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to fetch table preset. Error: ${error.message}`,
              reportError: error,
            });
          }
        })();
      },
      [setError]
    );

    const savePagePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<SavePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save page preset. Error: ${error.message}`,
              reportError: error,
            });
            presetResponse = { message: `Failed to save page preset. Error: ${error.message}` };
          } finally {
            getPagePresets();
            return presetResponse;
          }
        })();
      },
      [getPagePresets, setError]
    );

    const saveTablePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<SaveTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
            return presetResponse;
          }
        })();
      },
      [getTablePresets, setError]
    );

    const deletePagePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<DeletePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete page preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getPagePresets();
          }
        })();
      },

      [getPagePresets, setError]
    );

    const deleteTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<DeleteTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
          }
        })();
      },
      [getTablePresets, setError]
    );

    const getDeliveryData = useCallback(params => {
      return (async () => {
        return await fetchDailyDelivery(params);
      })();
    }, []);

    const getPerformanceData = useCallback(
      params => {
        return (async () => {
          const dateRangeToUse = overrideDateRange(company, params.dates);
          const res = await await YoutubePerformanceLambdaFetch<Y.GetPageDataParams>("/", {
            params: {
              ...params,
              ...(dateRangeToUse || {}),
              agency: companyInfo.agency,
            },
          });
          return await awaitJSON<Y.GetPageDataResponse>(res);
        })();
      },
      [company, companyInfo]
    );

    const getDimensionCell = useCallback(
      (dimensionData: DimensionMap, dimensionHeader: DimensionColumn) =>
        YT_GET_DIMENSION_CELL(dimensionData, dimensionHeader, {}),
      []
    );

    useEffect(() => {
      if (company && !pagePresets) {
        getPagePresets();
      }
    }, [company, getPagePresets, pagePresets, setError, urlSettings]);

    useEffect(() => {
      if (company && !tablePresets) {
        getTablePresets();
      }
    }, [company, getTablePresets, pagePresets, setError, tablePresets, urlSettings]);

    useEffect(() => {
      if (company && !filterData) {
        (async () => {
          try {
            const res = await YoutubePerformanceLambdaFetch<Y.GetFilterOptionsParams>(
              "/filter_options",
              {
                params: {
                  company,
                } as any,
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, filterData, setError]);

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<P.GetKpiMetaDataParams>("/kpis", {
              params: {
                company,
              },
            });
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [kpiMetaData, company, setError]);

    return pagePresets && tablePresets ? (
      <SingleChannelPage
        columnMetaDataMap={columnMetaDataMap}
        dataFetchKey={header => `${header.kpi}`}
        dates={dates as any}
        deletePagePreset={deletePagePreset}
        deleteTablePreset={deleteTablePreset}
        deliveryDimensionMap={{
          "Account ID": "account id",
          "Account Name": "account name",
          "Ad Group": "ad group",
          Ad: "ad",
          Age: "age",
          Campaign: "campaign",
          Device: "device",
          Gender: "gender",
        }}
        dimensionColumnMetaDataMap={dimensionColumnMetaDataMap}
        fetchingPagePresets={fetchingPagePresets}
        fetchingTablePresets={fetchingTablePresets}
        filterData={R.defaultTo([], filterData)}
        getDeliveryData={getDeliveryData}
        getDimensionCell={getDimensionCell}
        getPerformanceData={getPerformanceData}
        getTablePreset={getTablePreset}
        glossary={GLOSSARY}
        kpiMetaData={R.defaultTo({}, kpiMetaData)}
        location={location}
        pagePresets={R.defaultTo([], pagePresets)}
        prefix={"youtube"}
        savePagePreset={savePagePreset}
        saveTablePreset={saveTablePreset}
        setDates={setDates as any}
        setPagePresets={setPagePresets}
        setTablePresets={setTablePresets}
        sparkChartMetricsMap={{
          clicks: "clicks",
          cpm: "averagecpm",
          cpx: "averagecpv",
          impressions: "impressions",
          revenue: "revenue",
          roas: "roas",
          spend: "cost",
          volume: "conversions",
        }}
        sparkChartMetricPrettyNameMap={{
          clicks: "Clicks",
          cpm: "CPM",
          cpx: "CPV",
          impressions: "Impressions",
          revenue: "Revenue",
          roas: "ROAS",
          spend: "Cost",
          volume: "Conversions",
        }}
        tablePresets={R.defaultTo([], tablePresets)}
        urlSettings={urlSettings}
      />
    ) : (
      <FullPageSpinner />
    );
  }
);

export default YoutubeSingleChannel;
