import React, { useMemo } from "react";
import "./SimpleTooltipText.scss";
import { SimpleTooltipDirection } from "../SimpleTooltip/SimpleTooltip";

interface SimpleTooltipTextProps {
  children: React.ReactNode;
  showTooltip: boolean;
  horizontalShift: string; // Can be a percentage or exact amount in px, rem, or em.
  verticalShift: string; // Can be a percentage or exact amount in px, rem, or em.
  direction?: SimpleTooltipDirection;
  maxWidth?: string;
  className?: string;
}

export const SimpleTooltipText: React.FC<SimpleTooltipTextProps> = React.memo(
  ({
    children,
    showTooltip,
    horizontalShift,
    verticalShift,
    direction = SimpleTooltipDirection.TOP,
    maxWidth = "102px",
    className,
  }: SimpleTooltipTextProps): JSX.Element => {
    const resolvedClassName = useMemo(() => {
      let classes = ["simpleTooltipText"];
      if (className) {
        classes.push(className);
      }
      if (direction === SimpleTooltipDirection.BOTTOM) {
        classes.push("bottom");
      }
      return classes.join(" ");
    }, [className, direction]);

    return showTooltip ? (
      <span
        className={resolvedClassName}
        id="simpleTooltipText"
        style={{ left: horizontalShift, bottom: verticalShift, maxWidth: maxWidth }}
      >
        {children}
      </span>
    ) : (
      <></>
    );
  }
);
