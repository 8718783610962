import * as R from "ramda";

import { makeMemoizedGetter, MemoizedGetter } from "./data";

/**
 * Used to lighten or darken a given color by a percentage
 * @param {string} color
 * @param {number} percent
 */
export const shadeColor = (color: string, percent: number): string => {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
};

export const appleGreen = "#2ECC40";
export const aqua = "#00FFFF";
export const aquamarine = "#90E6CC";
export const bahamaBlue = "#1F4E79";
export const bitterSweetOrange = "#FF6666";
export const bpmColor = "#737AFF"; // light slate blue
export const burntSienna = "#E86A4F";
export const cosmicLatte = "#E8FAF4";
export const cosmicLatte2 = "#D0F8EC";
export const darkGreen = "#006400";
export const deepBlush = "#DF717E";
export const deepSeaGreen = "#137B5B";
export const downyGreen = "#63DCB7";
export const eastBay = "#44546A";
export const electricBlue = "#7FDBFF";
export const electricViolet = "#B10DC9";
export const elfGreen = "#17906B";
export const gray = "#cccccc";
export const fountainBlue = "#659DBD";
export const freeSpeechRed = "#C00000";
export const greenLeaf = "#548235";
export const hummingBirdGreen = "#D2F5EA";
export const lightSeaGreen = "#21CE99";
export const limeGreen = "#3DBB3D";
export const magenta = "#FF00FF";
export const magicMintGreen = "#A6EBD6";
export const magicMintGreen2 = "#9DF0D6";
export const magicMintGreen3 = "#BFF5E5";
export const maroon = "#85144B";
export const mayaBlue = "#45C8FC";
export const meadowGreen = "#1BAE81";
export const mintGreen = "#90E6CC";
export const mistyRose = "#FFE5E5";
export const melonPink = "#FFAEAA";
export const melonPink2 = "#FFB2B2";
export const midnightBlue = "#001F3F";
export const monaLisaRed = "#FF8882";
export const monaLisaRed2 = "#FF9999";
export const moneyColor = "#21CE99"; // light sea green
export const moneyLightColor = cosmicLatte;
export const mountainMeadowGreen = "#1DB989";
export const mountainMeadowGreen2 = "#1DBF8D";
export const mountainMeadowGreen3 = "#20D09A";
export const negativeColor = "#D45671"; // cabaret
export const neutralColor = "#ECEEEF"; // tyrian purple
export const oceanGreen = "#3D9970";
export const orange = "#E5AC00";
export const orangePeel = "#FF9900";
export const orangeRed = "#F23708";
export const pink = "#FFCCCC";
export const pumpkinOrange = "#FF851B";
export const red = "#FF0000";
export const redOrange = "#FF4136";
export const redOrange2 = "#FF3232";
export const sameColor = "black";
export const schoolBusYellow = "#FFDC00";
export const scienceBlue = "#0074D9";
export const seaGreen = "#168C68";
export const shamrockGreen = "#26DEA5";
export const shockingPink = "#F012BE";
export const softBlue = "#6F9FD8";
export const softOrange = "#FA9A85";
export const springGreen = "#01FF70";
export const sunsetOrange = "#FF4C4C";
export const tacao = "#E8A87C";
export const topaz = "#88888A";
export const torchRed = "#FF1919";
export const turquoise = "#39CCCC";
export const turquoise2 = "#48E3B3";
export const turquoiseBlue = "#7BEBC8";
export const viola = "#C38D9E";
export const vividTangerine = "#FF7F7F";
export const vividViolet = "#662D91";
export const waterLeafGreen = "#BCF0E0";
export const white = "#FFFFFF";
export const yellowGreen = "#A4C639";
export const yourPink = "#FFC7C4";

export const Brand100 = "#1F003F";
export const Brand90 = "#21005E";
export const Brand80 = "#390094";
export const Brand70 = "#5200CE";
export const Brand60 = "#6B2DEF";
export const Brand50 = "#8154FF";
export const Brand40 = "#977AFF";
export const Brand30 = "#B5A6FF";
export const Brand25 = "#D1CDFF";
export const Brand20 = "#E8E5FF";
export const Brand10 = "#F8F7FF";
export const Brand0 = "#FFFFFF";

export const Neutral160 = "#fbfcff";
export const Neutral200 = "#f1f3f9";
export const Neutral300 = "#e1e6ef";
export const Neutral400 = "#cbd2e1";
export const Neutral500 = "#94a0b8";
export const Neutral600 = "#5f6c84";
export const Neutral700 = "#3f444d";
export const Neutral1000 = "#0a0d14";

export const Channel1 = "#BD32FF";
export const Channel2 = "#FF7342";
export const Channel3 = "#FFC42E";
export const Channel4 = "#2AE0F6";

const PRIMARY_VISUALIZATION_COLORS = [
  "#2AE0F6", // Channel 1a
  "#9EF04E", // Channel 2a
  "#E7EE2B", // Channel 3a
  "#0194FF", // Channel 4a
  "#FF5275", // Channel 5a
  "#FF7342", // Channel 6a
  "#BD32FF", // Channel 7a
  "#543CCE", // Channel 8a
];

const SECONDARY_VISUALIZATION_COLORS = [
  "#543CCE", // Channel 1b
  "#0194FF", // Channel 2b
  "#005D5D", // Channel 3b
  "#9F1853", // Channel 4b
  "#FF5275", // Channel 5b
  "#520408", // Channel 6b
  "#197F38", // Channel 7b
  "#002D9D", // Channel 8b
  "#EE5398", // Channel 9b
  "#B38702", // Channel 10b
  "#009D9A", // Channel 11b
  "#01274A", // Channel 12b
  "#8A3800", // Channel 13b
  "#BD32FF", // Channel 14b
  "#D3CCD8", // Channel 15b
  "#644B6A", // Channel 16b
  "#E39BC3", // Channel 17b
  "#65E4D8", // Channel 18b
  "#E1471C", // Channel 19b
  "#F9C683", // Channel 20b
];

export const colorMap = (
  data: any[],
  hardCodedColorName?: string,
  hardCodedColor?: string,
  usePrimaryColors?: boolean
): any => {
  if (R.isEmpty(data)) {
    return {};
  }

  const usePrimaryPalette = shouldUsePrimaryPalette(data.length, usePrimaryColors);
  const sortedData = R.sortBy(R.compose(R.toLower, R.prop("name")))(data);

  return sortedData.reduce((prev, curr, i) => {
    if (curr.name === hardCodedColorName) {
      return { ...prev, [curr.name]: hardCodedColor };
    }
    return { ...prev, [`${curr.name}`]: getChannelSeriesColor(i, usePrimaryPalette) };
  }, {});
};

interface CustomColorMapping {
  name: string;
  color: string;
}

export const customColorMapping = (
  data: any[],
  colorMappings?: CustomColorMapping[],
  usePrimaryColors?: boolean
): any => {
  if (R.isEmpty(data)) {
    return {};
  }

  const usePrimaryPalette = shouldUsePrimaryPalette(data.length, usePrimaryColors);
  const sortedData = R.sortBy(R.compose(R.toLower, R.prop("name")))(data);

  return sortedData.reduce((prev, curr, i) => {
    const mapping = colorMappings?.find(mapping => mapping.name === curr.name);
    if (mapping) {
      return { ...prev, [curr.name]: mapping.color };
    }
    return { ...prev, [curr.name]: getChannelSeriesColor(i, usePrimaryPalette) };
  }, {});
};

// If the number of items that we are visualizing together is less than or equal to the number of
// primary colors, use the primary color palette. Otherwise, use the secondary color palette.
export const shouldUsePrimaryPalette = (itemLength: number, usePrimaryColors = true): boolean =>
  itemLength <= PRIMARY_VISUALIZATION_COLORS.length && usePrimaryColors;

// Get the color to use based on item index and the color palette we're using.
const overflowChannelColors: Record<string, string> = {};
export const getChannelSeriesColor = (i: number, usePrimaryPalette: boolean): string => {
  const ourColors = usePrimaryPalette
    ? PRIMARY_VISUALIZATION_COLORS
    : SECONDARY_VISUALIZATION_COLORS;
  if (i < ourColors.length) {
    return ourColors[i];
  }
  if (overflowChannelColors[`${i}`]) {
    return overflowChannelColors[`${i}`];
  }
  const newRandomColor = `#${randomColorPart()}${randomColorPart()}${randomColorPart()}`;
  overflowChannelColors[`${i}`] = newRandomColor;
  return newRandomColor;
};

export const pieSeries = [bahamaBlue, greenLeaf, orange, freeSpeechRed, eastBay];
// export const seriesColors = [
//   scienceBlue,
//   appleGreen,
//   schoolBusYellow,
//   redOrange,
//   electricViolet,
//   midnightBlue,
//   pumpkinOrange,
//   turquoise,
//   maroon,
//   electricBlue,
//   springGreen,
//   red,
//   oceanGreen,
//   shockingPink,
//   darkGreen,
//   mayaBlue,
//   freeSpeechRed,
//   mountainMeadowGreen,
//   bahamaBlue,
//   sunsetOrange,
//   vividViolet,
//   orange,
//   bpmColor,
//   yellowGreen,
//   softBlue,
// ];

export const warning = "#f9a825";
export const success = "#43a047";
export const info = "#1565c0";
export const error = "#b71c1c";

export const primary = "#6500cc";
export const secondary = "#4176cf";
export const tertiary = "#41da93";

export const calendarColors = {
  0: neutralColor,
  1: springGreen,
  2: turquoise,
  3: pumpkinOrange,
};

// From designer
export const seriesColors = [
  primary,
  secondary,
  tertiary,
  "#4DD0E1",
  "#AEEA00",
  "#FF77A9",
  "#009FAF",
  "#C4001D",
  "#9E00C5",
  "#00AA47",
  "#FFE430",
  "#79B700",
  "#EFC800",
  "#FF4C64",
  "#D500F9",
  "#009FDF",
  "#6A1B9A",
  "#FF6F00",
  "#FFB232",
  "#41DA93",
  "#E91E63",
  "#3D5AFE",
];

export const randomColorPart = (): string =>
  Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");

const overflowColors: Record<string, string> = {};
export const getSeriesColor = (i: number): string => {
  if (i < seriesColors.length) {
    return seriesColors[i];
  }
  if (overflowColors[`${i}`]) {
    return overflowColors[`${i}`];
  }
  const newRandomColor = `#${randomColorPart()}${randomColorPart()}${randomColorPart()}`;
  overflowColors[`${i}`] = newRandomColor;
  return newRandomColor;
};

export const lightSeriesColors = seriesColors.map(color => shadeColor(color, 0.5));
export const getLightSeriesColor = (i: number): string =>
  lightSeriesColors[i % lightSeriesColors.length];
export const DeviceColorMap = {
  android: yellowGreen,
  tbd_android: yellowGreen,
  phone_android: yellowGreen,
  desktop: mayaBlue,
  desktop_: mayaBlue,
  firetv: orangePeel,
  firetv_firetv: orangePeel,
  tv_firetv: orangePeel,
  hulu: limeGreen,
  hulu_hulu: limeGreen,
  iphone: deepBlush,
  phone_ios: deepBlush,
  phone_apple: deepBlush,
  tablet_ios: topaz,
  roku: vividViolet,
  tv_roku: vividViolet,
  tv_ios: magenta,
  tv: orangeRed,
};

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export const hexToRgb = (hex: string): RGB => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

export const rgbToHex = R.pipe<RGB, number[], string[], string[], string>(
  R.props(["r", "g", "b"]),
  R.map(num => Math.floor(num).toString(16).padStart(2, "0")),
  R.prepend("#"),
  R.join("")
);

/**
 * Ported from sass implementation in C
 * https://github.com/sass/libsass/blob/0e6b4a2850092356aa3ece07c6b249f0221caced/functions.cpp#L209
 */
export const mixColors = (color1: RGB, color2: RGB, pct: number): RGB => {
  const weight1 = 1 - pct;
  const weight2 = pct;

  const r = Math.round(weight1 * color1.r + weight2 * color2.r);
  const g = Math.round(weight1 * color1.g + weight2 * color2.g);
  const b = Math.round(weight1 * color1.b + weight2 * color2.b);

  return { r, g, b };
};

export const HEAT_MAP_RED = "#ff6347";
export const HEAT_MAP_YELLOW = "ffff66";
export const HEAT_MAP_GREEN = "66ffb2";

interface HeatMapProps {
  min?: number;
  max?: number;
  midpoint?: number;
  lowColor?: string;
  midColor?: string;
  highColor?: string;
}

type HeatMap = MemoizedGetter<number, string>;
// Make a heat map memo table, where numbers between the min and the midpoint are given a
// ratio-based color between the low and mid colors, and values higher than the midpoint are given a
// ratio-based color between the mid and high colors. If no midpoint is specified, the average of
// the min and max numbers is used.
export const makeHeatMap = ({
  min = 0,
  max = 100,
  midpoint,
  lowColor = HEAT_MAP_RED,
  midColor = HEAT_MAP_YELLOW,
  highColor = HEAT_MAP_GREEN,
}: HeatMapProps): HeatMap => {
  let mid = midpoint || (max + min) / 2;
  let lowRGB = hexToRgb(lowColor);
  let midRGB = hexToRgb(midColor);
  let highRGB = hexToRgb(highColor);
  return makeMemoizedGetter<number, string>({
    calculate: value => {
      let leftColor = lowRGB;
      let leftBound = min;
      let rightColor = midRGB;
      let rightBound = mid;

      if (value > mid) {
        leftColor = midRGB;
        rightColor = highRGB;
        leftBound = mid;
        rightBound = max;
      }

      if (value < leftBound) {
        value = leftBound;
      }

      if (value > rightBound) {
        value = rightBound;
      }

      let mixRatio = (value - leftBound) / (rightBound - leftBound);

      if (leftBound === rightBound) {
        mixRatio = value < leftBound ? 0 : 1;
      }

      let resultantColor = mixColors(leftColor, rightColor, mixRatio);

      return rgbToHex(resultantColor);
    },
  });
};

// The regular heat map, but where lower is better (green is min and red is max)
export const makeInvertedHeatMap = (args: HeatMapProps): HeatMap =>
  makeHeatMap({
    ...args,
    lowColor: HEAT_MAP_GREEN,
    highColor: HEAT_MAP_RED,
  });

export const computeTextColor = makeMemoizedGetter({
  calculate: (c: string | null | undefined) => {
    // https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
    if (!c) {
      return "#000000";
    }

    let rgb = parseInt(c.substring(1), 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 128 ? c : "#000000";
  },
});

export const AREA_LINE_CHART_COLOR = "#7e57c2";
export const BAR_CHART_COLOR = "#009fdf";

// // Metrics Colors
export const DIVERGING_COLOR_SCHEME: Record<"green" | "midBorder" | "grey", RGB> = {
  green: {
    // Success
    r: 126,
    g: 214,
    b: 0,
  },
  midBorder: {
    r: 126,
    g: 214,
    b: 0,
  },
  grey: {
    // Neutral 400
    r: 203,
    g: 210,
    b: 225,
  },
};

export const SEQUENTIAL_COLOR_SCHEME: Record<"lightPurple" | "darkPurple", RGB> = {
  lightPurple: {
    // Brand 10
    r: 248,
    g: 247,
    b: 255,
  },
  darkPurple: {
    // Brand 30
    r: 181,
    g: 166,
    b: 255,
  },
};
