import React, { useState, useEffect } from "react";
import * as R from "ramda";

import { useSetError } from "../redux/modals";
import { LinearLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { Page, Skeleton, TableSkeleton, BPMTable, NumberFormatter } from "../Components";

import "./LinearRatesComparisons.scss";

const headers = [
  {
    name: "week",
    label: "Week",
  },
  {
    name: "company",
    label: "Company",
  },
  {
    name: "network",
    label: "Network",
  },
  {
    name: "avail",
    label: "Avail",
  },
  {
    name: "length",
    label: "Length",
  },
  {
    name: "dow",
    label: "DOW",
  },
  {
    name: "daypart",
    label: "Daypart",
  },
  {
    name: "rotation",
    label: "Rotation",
    width: 250,
  },
  {
    name: "cost",
    label: "Cost",
    renderer: row => <NumberFormatter value={row.cost} type="$" />,
  },
  {
    name: "others",
    label: "Others",
    renderer: row => {
      return (
        <div className="companyList">
          {R.map(
            otherRow => (
              <div
                className="companyEntry"
                key={`${row.week}_${row.network}_${row.avail}_${row.rotation}_${otherRow.company}`}
              >
                {otherRow.company} ({<NumberFormatter value={otherRow.cost} type="$" />})
              </div>
            ),
            row.others
          )}
        </div>
      );
    },
    flex: 1,
  },
];

const LinearRatesComparisons = () => {
  const setError = useSetError();

  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let result = await LinearLambdaFetch("/cost_differences");
          result = await awaitJSON(result);
          setTableData(result);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [setError, tableData]);

  return (
    <Page title="Linear Rates Comparisons" pageType="Linear Rates Comparisons" minHeight={850}>
      <div className="bpmLinearRatesComparisons">
        {tableData ? (
          <div className="linearRatesComparisonsTable">
            <BPMTable alternateColors headers={headers} data={tableData} />
          </div>
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default LinearRatesComparisons;
