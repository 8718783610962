import React from "react";

import { Router } from "@reach/router";

import { useTabbedNav } from "../utils/hooks/useNav";

import { NonLiveReport } from "./NonLiveReport";
import { ExtremeReachReport } from "./ExtremeReachReport";
import InvalidRotationsReport from "./InvalidRotationsReport";

import { Page } from "../Components";

import "./StreamingReports.scss";

const NON_LIVE_KEY = "nonlive";
const ER_REPORT_KEY = "er-sync";
const INVALID_ROTATION_KEY = "invalid-rotations";

export default React.memo(({ navigate }) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "streaming/reports",
    defaultKey: NON_LIVE_KEY,
  });

  return (
    <Page
      title="Streaming Reports"
      pageType="Streaming Reports"
      navs={[
        {
          label: "Non-Live",
          key: NON_LIVE_KEY,
        },
        {
          label: "ER vs BPM",
          key: ER_REPORT_KEY,
        },
        {
          label: "Invalid Rotations",
          key: INVALID_ROTATION_KEY,
        },
      ]}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="streamingReportsPage">
        <Router className="fullPageRouter">
          <NonLiveReport path={NON_LIVE_KEY} />
          <ExtremeReachReport path={ER_REPORT_KEY} />
          <InvalidRotationsReport path={INVALID_ROTATION_KEY} />
          <NonLiveReport default />
        </Router>
      </div>
    </Page>
  );
});
