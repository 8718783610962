// Experiment for Streaming AdOps page with Bonuser Tools.

const bonuserToolsUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "miguel.garcia@tinuiti.com",
  "nina.grill@tinuiti.com",
  "kira.cooper@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "justin.manus@tinuiti.com",
  "stephanie.delreal@tinuiti.com",
  "kevin.kiehm@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  "skye.bishop@tinuiti.com",
  // Add users here!
];

export const shouldEnableBonuserTools = (userEmail: string): boolean => {
  return bonuserToolsUsers.includes(userEmail);
};
