import "./WidgetContainer.scss";
import React, { useLayoutEffect, useRef, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

interface WidgetContainerProps {
  backgroundColor?: "gray" | "white";
  bottom?: string | React.ReactNode;
  children: React.ReactNode;
  collapsible?: boolean;
  design?: "primary" | "secondary";
  enableHoverDesign?: boolean; // enables hoverdesign on chartcontainers when cursor is in widget
  header: string | React.ReactNode;
  headerCollapsibleOnclick?: boolean;
  overRideWidgetClosedHeight?: number | null;
  rightActions?: string | React.ReactNode;
  titleSize?: "regular" | "large";
  startOpen?: boolean;
  subHeader?: string | React.ReactNode;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({
  bottom,
  children,
  collapsible = false,
  design = "primary",
  enableHoverDesign = true,
  header,
  headerCollapsibleOnclick = false,
  overRideWidgetClosedHeight,
  rightActions,
  startOpen = true,
  subHeader,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [widgetClosedHeight, setWidgetClosedHeight] = useState(0);
  const widgetHeaderRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (widgetHeaderRef.current) {
      const headerRowHeight = widgetHeaderRef.current?.getBoundingClientRect().height;
      const subHeaderHeight =
        widgetHeaderRef.current?.querySelector(".subheader")?.getBoundingClientRect().height || 0;
      const widgetClosedHeight = headerRowHeight - subHeaderHeight;
      // header row height plus 16px padding top and bottom for secondary design, 32px top and bottom for primary design
      const paddingToAdd = design === "primary" ? 64 : 25;

      setWidgetClosedHeight(widgetClosedHeight + paddingToAdd);
    }
  }, [design]);

  return (
    <div
      className={`widgetContainer ${isOpen ? "open" : "closed"} ${
        enableHoverDesign ? "enableHoverDesign" : ""
      } ${design}`}
      onClick={() => (!isOpen ? setIsOpen(!isOpen) : null)}
      style={{
        height: isOpen
          ? "auto"
          : `${overRideWidgetClosedHeight ? overRideWidgetClosedHeight : widgetClosedHeight}px`,
      }}
    >
      <div
        className="headerRow"
        onClick={() =>
          !isOpen || design === "secondary" || headerCollapsibleOnclick ? setIsOpen(!isOpen) : null
        }
        ref={widgetHeaderRef}
      >
        <div className="headerLeft">
          <div className={`${design}TitleSize`}>{header}</div>
          {isOpen && subHeader && (
            <div className={`subheader ${isOpen ? "open" : "closed"}`}>
              <div className="text">{subHeader}</div>
            </div>
          )}
        </div>
        <div className="headerRight">
          {rightActions && isOpen && <div className="rightActions">{rightActions}</div>}
          {collapsible && (
            <div className="openCloseArrow">
              <MdArrowForwardIos
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  height: "22px",
                  width: "22px",
                  transform: `${isOpen ? "rotate(-90deg)" : "rotate(90deg)"}`,
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className={`widgetBody ${isOpen ? "open" : "closed"}`}>{children}</div>
      {bottom && isOpen && <div className="widgetBottom">{bottom}</div>}
    </div>
  );
};

export default WidgetContainer;
