import "./OfflineInputs.scss";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Nav } from "react-bootstrap";
import { useSetError } from "../../redux/modals";
import useLocation from "../../utils/hooks/useLocation";
import { fetchTables } from "./OfflineInputsUtils";
import {
  MediaTable,
  OfflineInputsTable,
  OfflineInputTabs,
  OutcomeDataTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import MediaInputs from "./MediaInputs";
import { LeaveConfirmationDialog } from "./OfflineInputsDialogs";
import OutcomeData from "./OutcomeData";

const renderTabs = [
  { label: "Paid Media Inputs", key: OfflineInputTabs.PAID_MEDIA_INPUTS, disabled: false },
  {
    label: "Non-Paid Media Inputs",
    key: OfflineInputTabs.NON_PAID_MEDIA_INPUTS,
    disabled: false,
  },
  { label: "Control Variables", key: OfflineInputTabs.CONTROL_VARIABLES, disabled: true },
  {
    label: "Outcome Data",
    key: OfflineInputTabs.OUTCOME_DATA,
    disabled: false,
  },
  { label: "Promos", key: OfflineInputTabs.PROMOS, disabled: true },
  {
    label: "Incrementality Testing",
    key: OfflineInputTabs.INCREMENTALITY_TESTING,
    disabled: true,
  },
];

const defaultTablesByTab = Object.assign(
  {},
  ...Object.values(OfflineInputTabs).map(tab => ({ [tab]: [] }))
);
const defaultUpdatedByTab = Object.assign(
  {},
  ...Object.values(OfflineInputTabs).map(tab => ({ [tab]: { by: undefined, at: undefined } }))
);

const OfflineInputs: React.FC<RouteComponentProps> = () => {
  const [tablesByTab, setTablesByTab] = useState<
    {
      [tabKey in OfflineInputTabs]: OfflineInputsTable[];
    }
  >(defaultTablesByTab);
  const [updatedByTab, setUpdatedByTab] = useState<
    {
      [tabKey in OfflineInputTabs]: { by?: string; at?: string };
    }
  >(defaultUpdatedByTab);
  const [activeTab, setActiveTab] = useState<OfflineInputTabs>(OfflineInputTabs.PAID_MEDIA_INPUTS);
  const [tabToNavTo, setTabToNavTo] = useState<OfflineInputTabs>();
  const [fetching, setFetching] = useState<boolean>(true);
  const [showLeaveConfirmationDialog, setShowLeaveConfirmationDialog] = useState<boolean>(false);
  const [editingUnlocked, setEditingUnlocked] = useState<boolean>(false);
  const [dispatchStateReset, setDispatchStateReset] = useState<boolean>(false);
  const { company } = useLocation();
  const setError = useSetError();

  const refetch = async () => {
    setFetching(true);
    await fetchTables(company, setTablesByTab, setError, setUpdatedByTab);
    setFetching(false);
  };

  useEffect(() => {
    fetchTables(company, setTablesByTab, setError, setUpdatedByTab).then(() => {
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const renderBody = (tab: keyof typeof OfflineInputTabs) => {
    switch (tab) {
      case OfflineInputTabs.PAID_MEDIA_INPUTS:
        return (
          <MediaInputs
            inputType={OfflineInputTabs.PAID_MEDIA_INPUTS}
            originalTables={tablesByTab[OfflineInputTabs.PAID_MEDIA_INPUTS] as MediaTable[]}
            updated={updatedByTab[OfflineInputTabs.PAID_MEDIA_INPUTS]}
            fetching={fetching}
            refetch={refetch}
            editingUnlocked={editingUnlocked}
            setEditingUnlocked={setEditingUnlocked}
            dispatchStateReset={dispatchStateReset}
            setDispatchStateReset={setDispatchStateReset}
            company={company}
            setError={setError}
          />
        );
      case OfflineInputTabs.NON_PAID_MEDIA_INPUTS:
        return (
          <MediaInputs
            inputType={OfflineInputTabs.NON_PAID_MEDIA_INPUTS}
            originalTables={tablesByTab[OfflineInputTabs.NON_PAID_MEDIA_INPUTS] as MediaTable[]}
            updated={updatedByTab[OfflineInputTabs.NON_PAID_MEDIA_INPUTS]}
            fetching={fetching}
            refetch={refetch}
            editingUnlocked={editingUnlocked}
            setEditingUnlocked={setEditingUnlocked}
            dispatchStateReset={dispatchStateReset}
            setDispatchStateReset={setDispatchStateReset}
            company={company}
            setError={setError}
          />
        );
      case OfflineInputTabs.OUTCOME_DATA:
        return (
          <OutcomeData
            inputType={OfflineInputTabs.OUTCOME_DATA}
            originalTables={tablesByTab[OfflineInputTabs.OUTCOME_DATA] as OutcomeDataTable[]}
            updated={updatedByTab[OfflineInputTabs.OUTCOME_DATA]}
            fetching={fetching}
            refetch={refetch}
            editingUnlocked={editingUnlocked}
            setEditingUnlocked={setEditingUnlocked}
            dispatchStateReset={dispatchStateReset}
            setDispatchStateReset={setDispatchStateReset}
            company={company}
            setError={setError}
          />
        );
      default:
        return <div>{tab} not yet implemented.</div>;
    }
  };

  return (
    <div className="offlineInputsContainer">
      <LeaveConfirmationDialog
        onLeave={() => {
          setDispatchStateReset(true);
          if (tabToNavTo) {
            setActiveTab(tabToNavTo);
          }
        }}
        onStay={() => {
          setTabToNavTo(undefined);
        }}
        show={showLeaveConfirmationDialog}
        onHide={() => setShowLeaveConfirmationDialog(false)}
      />
      <Nav
        activeKey={activeTab}
        onSelect={value => {
          const valueTab = value as keyof typeof value;

          if (!valueTab || valueTab === activeTab) {
            return;
          }
          if (editingUnlocked) {
            setTabToNavTo(valueTab);
            setShowLeaveConfirmationDialog(true);
          } else {
            setActiveTab(valueTab);
          }
        }}
      >
        {renderTabs.map(({ label, key, disabled }) => (
          <Nav.Item key={key}>
            <Nav.Link eventKey={key} disabled={disabled}>
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {renderBody(activeTab)}
    </div>
  );
};

export default OfflineInputs;
