import React, { useCallback, useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import { Button, Table, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import { MdSave } from "react-icons/md";

import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { KpiPickerLegacy, Page, FullPageSpinner, CheckBox, Spinner } from "../Components";

import config from "./Config.json";

import "./Properties.scss";

const PRETTY_TYPE_NAMES = {
  int: "Integer",
  float: "Real",
  bool: "",
  str: "Text",
  dropdown: "",
};

const Properties = () => {
  const { company } = useLocation();
  const setError = useSetError();
  const [changes, setChanges] = useState({});
  const [properties, setProperties] = useState();
  const [kpi, setKpi] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!properties) {
      (async () => {
        let properties;

        try {
          properties = await ToolsLambdaFetch("/get_properties", { params: { company } });
        } catch (e) {
          setError({
            message: `Failed to load company info: ${e.message}`,
            reportError: e,
          });
        }

        properties = await awaitJSON(properties);
        setProperties(properties);
      })();
    }
  }, [properties, company, setError]);

  const onKpiChange = useCallback(newKpi => {
    setKpi(newKpi);
    setChanges({});
  }, []);

  const defaults = useMemo(() => {
    if (properties) {
      return R.find(({ cid }) => cid === "default", properties);
    }
  }, [properties]);

  const kpiProperties = useMemo(() => {
    if (kpi && properties) {
      return R.find(({ cid }) => cid === kpi, properties);
    }
  }, [kpi, properties]);

  const invalidSet = useMemo(() => {
    const typeMap = {};
    R.pipe(
      R.keys,
      R.forEach(key => {
        R.pipe(
          R.keys,
          R.forEach(subKey => {
            typeMap[subKey] = config[key][subKey];
          })
        )(config[key]);
      })
    )(config);

    let invalidSet = {};
    R.pipe(
      R.keys,
      R.forEach(key => {
        const { type } = typeMap[key];
        let value = changes[key];
        let invalid = false;

        if (type === "int") {
          invalid = !Number.isInteger(Number.parseFloat(value));
        } else if (type === "float") {
          invalid = isNaN(value) || value === "";
        }

        if (invalid) {
          invalidSet[key] = true;
        }
      })
    )(changes);

    return invalidSet;
  }, [changes]);

  const onSubmit = useCallback(() => {
    if (!R.isEmpty(invalidSet)) {
      setError({ message: "Please fix any invalid modifications before saving" });
      return;
    }

    if (R.isEmpty(changes)) {
      setError({ message: "No changes have been made" });
      return;
    }

    (async () => {
      setSaving(true);
      let body = { kpi, changes };

      try {
        await ToolsLambdaFetch("/set_properties", {
          method: "POST",
          body,
        });
      } catch (e) {
        setError({
          message: `Failed to set company info: ${e.message}`,
          reportError: e,
        });
      }

      setProperties(
        R.map(kpiProperties => {
          if (kpiProperties.cid === kpi) {
            return R.mergeRight(kpiProperties, changes);
          } else {
            return kpiProperties;
          }
        }, properties)
      );
      setSaving(false);
    })();
  }, [setError, invalidSet, changes, kpi, properties]);

  const onDiscard = useCallback(() => setChanges({}), []);

  return (
    <Page
      title="Properties"
      pageType="Properties"
      actions={
        <div className="propertiesButtons">
          <KpiPickerLegacy onChange={onKpiChange} />
          <div className="buttonBox">
            <Button
              className="discardButton"
              disabled={saving}
              onClick={onDiscard}
              variant="danger"
              size="sm"
            >
              Discard Changes
            </Button>
            <Button className="saveButton" disabled={saving} onClick={onSubmit} size="sm">
              {saving ? <Spinner /> : <MdSave />}
            </Button>
          </div>
        </div>
      }
    >
      {kpiProperties ? (
        <div className="bpmProperties">
          <Body
            changes={changes}
            setChanges={setChanges}
            invalidSet={invalidSet}
            properties={kpiProperties}
            defaults={defaults}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

const Body = ({ changes, setChanges, invalidSet, properties, defaults }) => {
  const localValues = useMemo(() => ({ ...properties, ...changes }), [properties, changes]);

  const handleChange = useCallback(
    (newValue, tableSection, subKey) => {
      if (`${newValue}` === (R.isNil(properties[subKey]) ? "" : `${properties[subKey]}`)) {
        let updatedChanges = R.omit([subKey], changes);
        setChanges(updatedChanges);
      } else {
        setChanges(existingChanges => ({
          ...existingChanges,
          [subKey]: newValue,
        }));
      }
    },
    [properties, changes, setChanges]
  );

  return R.pipe(
    R.keys,
    R.map(tableSection => (
      <div key={tableSection} className="tableSection">
        <div className="tableSectionHeader">{tableSection}</div>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Type</th>
              <th>Default</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody>
            {R.pipe(
              R.prop(tableSection),
              R.keys,
              R.map(subKey => {
                let inputField;
                if (R.path([tableSection, subKey, "type"], config) === "dropdown") {
                  inputField = (
                    <td className="dropdown">
                      <DropdownButton
                        title={localValues[subKey] || defaults[subKey]}
                        onSelect={newValue => handleChange(newValue, tableSection, subKey)}
                      >
                        {R.map(
                          options => (
                            <Dropdown.Item key={options} eventKey={options}>
                              {options}
                            </Dropdown.Item>
                          ),
                          R.path([tableSection, subKey, "options"], config)
                        )}
                      </DropdownButton>
                    </td>
                  );
                } else if (R.path([tableSection, subKey, "type"], config) === "bool") {
                  inputField = (
                    <td className="checkbox">
                      <CheckBox
                        checked={
                          R.isNil(localValues[subKey]) ? defaults[subKey] : localValues[subKey]
                        }
                        onCheck={newValue => handleChange(newValue, tableSection, subKey)}
                      />
                    </td>
                  );
                } else {
                  inputField = (
                    <td className="textInput">
                      <Form.Control
                        value={R.isNil(localValues[subKey]) ? "" : localValues[subKey]}
                        onChange={e => handleChange(e.target.value, tableSection, subKey)}
                        isInvalid={!R.isNil(invalidSet[subKey])}
                      />
                    </td>
                  );
                }

                return (
                  <tr key={subKey}>
                    <td>{subKey}</td>
                    {inputField}
                    <td>{PRETTY_TYPE_NAMES[R.path([tableSection, subKey, "type"], config)]}</td>
                    <td>{R.isNil(defaults[subKey]) ? "None" : defaults[subKey].toString()}</td>
                    <td>{R.path([tableSection, subKey, "info"], config)}</td>
                  </tr>
                );
              })
            )(config)}
          </tbody>
        </Table>
      </div>
    ))
  )(config);
};

export default Properties;
