import React from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonType } from "../Components";
import { MdWarning } from "react-icons/md";

export interface WtoCancelModalProps {
  show: boolean;
  closeModal: () => void;
  confirmCancel: () => void;
}

export const WtoCancelModal: React.FC<WtoCancelModalProps> = ({
  show,
  closeModal,
  confirmCancel,
}) => (
  <Modal show={show} onHide={closeModal}>
    <Modal.Body>
      <div className="infoBox warning">
        <div className="icon">
          <MdWarning color="orange" className="alertIcon" />
        </div>
        <p className="message">Are you sure? Any unsaved changes will be lost.</p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button type={ButtonType.FILLED} onClick={closeModal}>
        Cancel
      </Button>
      <Button type={ButtonType.FILLED} onClick={confirmCancel}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default WtoCancelModal;
