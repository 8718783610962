import React, { useMemo, useState, useCallback, useContext, useEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { Form, ListGroup, Button } from "react-bootstrap";

import { MdEdit, MdCheck, MdBlock } from "react-icons/md";

import { SingleDatePicker, FullPageSpinner, Spinner } from "../Components";

import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { useMap } from "../utils/hooks/useData";
import useLocation from "../utils/hooks/useLocation";

import { useSetError, useSetAreYouSure } from "../redux/modals";

import { CreativeMapContext, MENU_KEY, useReportingCreative } from "./CreativeMap";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

const DATE_FORMAT = "yyyy-MM-dd";

const LANGUAGES = {
  english: {
    abbrev: "E",
    label: "English",
  },
  spanish: {
    abbrev: "S",
    label: "Spanish",
  },
  french: {
    abbrev: "F",
    label: "French",
  },
};

export default React.memo(() => {
  const { company } = useLocation();

  const usesReportingCreative = useReportingCreative();

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { goToTab } = useContext(CreativeMapContext);

  const [creativeName, setCreativeNameRaw] = useState("");
  const [abbreviation, setAbbreviationRaw] = useState("");
  const [customAbbreviation, setCustomAbbreviation] = useState(false);
  const [isBackToBack, setIsBackToBack] = useState(false);
  const [creativeList, setCreativeList] = useState([]);
  const [backToBackISCIS, setBackToBackISCIS] = useState({});

  const shouldEnableInsightsCategories = useExperimentFlag("enableInsightsCategories");

  useEffect(() => {
    if (company && R.isEmpty(creativeList)) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/", {
            params: {
              company,
              timeline: 1,
            },
          });
          let data = await awaitJSON(res);

          let creatives = [];

          for (let key of R.keys(data)) {
            creatives.push({ label: `${data[key].name} (${key})`, value: key });
          }
          setCreativeList(creatives);
        } catch (e) {
          let message = `Couldn't fetch creative map: ${e.message}`;
          setError({ message, reportError: e });
        }
      })();
    }
  }, [setError, company, creativeList]);

  const setAbbreviation = useCallback(
    str => {
      setAbbreviationRaw((str || "").toUpperCase());
    },
    [setAbbreviationRaw]
  );

  const setCreativeName = useCallback(
    newName => {
      setCreativeNameRaw(newName);
      if (!customAbbreviation) {
        let abbreviation = "";
        let words = newName.split(" ").filter(word => word.match(/^[a-zA-Z]/));
        if (words.length >= 3) {
          for (let i = 0; i < 3; ++i) {
            abbreviation = `${abbreviation}${words[i].charAt(0).toUpperCase()}`;
          }
        } else {
          abbreviation = newName
            .replace(/[^a-zA-Z]/g, "")
            .substring(0, 3)
            .toUpperCase()
            .padEnd(3, "X");
        }
        setAbbreviationRaw(abbreviation);
      }
    },
    [customAbbreviation]
  );

  const [version, setVersion] = useState("A");

  const [languageMap, setLanguage] = useMap({
    english: true,
    spanish: false,
  });

  // Display Dimension Options
  const dimensionOptions = useMemo(
    () => [
      "300X250",
      "300X600",
      "970X250",
      "160X600",
      "720X90",
      "320X50",
      "300X50",
      "320x60",
      "728x90",
      "1920x480",
      "900x600",
      "800x1200",
      "720x480",
      "970x550",
      "1080x1080",
      "1080x1920",
      "1920x1080",
      "480x320",
    ],
    []
  );

  const dimensionSelectOptions = useMemo(
    () =>
      dimensionOptions.sort().map(option => {
        return { value: option, label: option.toLowerCase() };
      }),
    [dimensionOptions]
  );

  const [dimensions, setDimensions] = useState([]);

  const [lengthMap, setLength] = useMap({
    15: false,
    30: false,
    60: false,
  });

  const lengthLabel = useMemo(() => {
    if (lengthMap["30"]) {
      return "30";
    }
    if (lengthMap["15"]) {
      return "15";
    }
    if (lengthMap["60"]) {
      return "60";
    }
    return "_";
  }, [lengthMap]);

  const languageLabel = useMemo(() => {
    if (languageMap.english) {
      return LANGUAGES.english.abbrev;
    } else if (languageMap.spanish) {
      return LANGUAGES.spanish.abbrev;
    } else if (languageMap.french) {
      return LANGUAGES.french.abbrev;
    }
    return "_";
  }, [languageMap]);

  const [mediaTypeMap, setMediaTypeMap] = useState({
    streaming: false,
    linear: false,
    audio: false,
  });

  // When they set a media type, if they are turning audio or display on, turn off all other media types.
  // Otherwise, keep everything else, add the new key, and turn audio and/or display off if they're on.
  const setMediaType = useCallback(
    (key, check) => {
      if (key === "audio" && check) {
        setMediaTypeMap({
          audio: true,
          linear: false,
          streaming: false,
          display: false,
        });
      } else if (key === "display" && check) {
        setMediaTypeMap({
          audio: false,
          linear: false,
          streaming: false,
          display: true,
        });
      } else {
        setMediaTypeMap(map => ({
          ...map,
          [key]: check,
          audio: false,
          display: false,
        }));
      }
    },
    [setMediaTypeMap]
  );

  const [includeLocals, setIncludeLocals] = useState(true);
  const [isLocalAudio, setIsLocalAudio] = useState(false);

  const [dates, setDates] = useState({
    start: Dfns.format(DATE_FORMAT, new Date()),
  });

  const [clickthrough, setClickthrough] = useState("");
  const [reportingCreative, setReportingCreative] = useState("");
  const [parentCreative, setParentCreative] = useState("");

  const hasChosenLanguage = useMemo(() => R.pipe(R.values, R.any(R.identity))(languageMap), [
    languageMap,
  ]);
  const hasChosenLength = useMemo(() => R.pipe(R.values, R.any(R.identity))(lengthMap), [
    lengthMap,
  ]);
  const hasChosenDimensions = useMemo(() => dimensions.length, [dimensions]);
  const hasChosenMediaType = useMemo(() => R.pipe(R.values, R.any(R.identity))(mediaTypeMap), [
    mediaTypeMap,
  ]);

  const hasMissingInfo = !(
    hasChosenLanguage &&
    (mediaTypeMap.display ? hasChosenDimensions : hasChosenLength) &&
    hasChosenMediaType
  );

  const [preFillData, setPreFillData] = useState();

  const [creativeAttributes, setCreativeAttributes] = useState();
  const [checkedAttributeOptions, setCheckedAttributeOptions] = useState({});
  const [concepts, setConcepts] = useState([]);
  const [selectedConcept, setSelectedConcept] = useState({});
  const [insightsCategoriesOptions, setInsightsCategoriesOptions] = useState([]);
  const [selectedInsightsCategories, setSelectedInsightsCategories] = useState([]);

  // Concept Options
  let conceptDropdownOptions = [];
  if (concepts.length > 0) {
    for (let concept of concepts) {
      conceptDropdownOptions.push({
        value: concept.concept,
        label: concept.concept,
        id: concept.id,
      });
    }
  }

  // Concept NA map
  let conceptNaMap = {};
  for (let concept of concepts) {
    conceptNaMap[concept.concept] = concept.categories;
  }

  // Parent Creative and Variant
  useEffect(() => {
    if (!preFillData) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/bulk_add_prefill", {
            params: { company },
          });
          let preFillData = await awaitJSON(res);
          setPreFillData(preFillData);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [preFillData, company, setError]);

  // Creative Attributes
  useEffect(() => {
    if (!creativeAttributes) {
      (async () => {
        try {
          let cid = company;
          let res = await CreativeLambdaFetch("/get_creative_options", {
            params: { cid },
          });
          let formattedRes = await awaitJSON(res);
          setCreativeAttributes(formattedRes.categoryData);
          setConcepts(formattedRes.conceptData);
          setInsightsCategoriesOptions(formattedRes.insightsCategoryData);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [creativeAttributes, company, setError]);

  // If radio buying has been enabled we also need to allow the creation of local creatives for audio
  const enableRadioBuying = useExperimentFlag("enableRadioBuying");

  const generatedCreatives = useMemo(() => {
    const creatives = [];
    if (abbreviation && version && !hasMissingInfo) {
      for (let language of R.keys(languageMap)) {
        let hasLanguage = languageMap[language];
        if (hasLanguage) {
          const languageLetter = R.path([language, "abbrev"], LANGUAGES) || "E";
          let iscis = [];

          if (mediaTypeMap.display) {
            for (const dimension of dimensions) {
              let isciInit = `${R.prop(
                "abbreviation",
                preFillData
              )}${abbreviation}${version}${dimension}${languageLetter}`;

              let isci = `${isciInit}NH`;
              iscis.push({
                isci,
                avail: "National",
                dimension,
              });
            }
          } else {
            for (let length of R.keys(lengthMap)) {
              let hasLength = lengthMap[length];
              if (hasLength) {
                let isciInit = `${R.prop(
                  "abbreviation",
                  preFillData
                )}${abbreviation}${version}${length}${languageLetter}`;

                let isci = `${isciInit}NH`;

                if (!(mediaTypeMap.audio && isLocalAudio && enableRadioBuying)) {
                  iscis.push({
                    isci,
                    avail: "National",
                    length,
                  });
                }
                if (
                  (mediaTypeMap.linear && includeLocals) ||
                  (mediaTypeMap.audio && isLocalAudio && enableRadioBuying)
                ) {
                  iscis.push({
                    isci: `${isciInit}LH`,
                    avail: "Local",
                    length,
                    nationalISCI: isci,
                  });
                }
              }
            }
          }

          if (iscis.length) {
            creatives.push({
              name: `${creativeName}${
                language && language !== "english"
                  ? ` (${language.charAt(0).toUpperCase() + language.slice(1)})`
                  : ""
              }`,
              language,
              iscis,
            });
          }
        }
      }
    }
    return creatives;
  }, [
    abbreviation,
    version,
    hasMissingInfo,
    languageMap,
    mediaTypeMap.display,
    mediaTypeMap.audio,
    mediaTypeMap.linear,
    dimensions,
    preFillData,
    lengthMap,
    isLocalAudio,
    enableRadioBuying,
    includeLocals,
    creativeName,
  ]);

  const [overrideMap, setOverride] = useMap();
  const [editingCreativeMap, setEditingCreative] = useMap();

  const [saving, setSaving] = useState(false);

  const [createAnother, setCreateAnother] = useState(false);

  const createISCIs = useCallback(async () => {
    setSaving(true);
    if (!creativeName) {
      setError({
        title: "Name Required",
        message: "Creative Name is required.",
      });
      setSaving(false);
      return;
    }
    let badMatch = creativeName.match(/[^a-zA-Z0-9-&!?/:()$%+. ]/);
    if (badMatch) {
      setError({
        title: "Name Has Illegal Characters",
        message: (
          <>
            <p>
              Creative names can only have numbers, letters, spaces, or any of{" "}
              <code>"-&amp;!?/:()$%+."</code>.
            </p>
            <p>
              Your creative name has the character <code>"{badMatch[0]}"</code>
            </p>
          </>
        ),
      });
      setSaving(false);
      return;
    }
    if (!abbreviation) {
      setError({
        title: "Creative Code Required",
        message: "Creative Code is required.",
      });
      setSaving(false);
      return;
    }
    if (abbreviation.length !== 3) {
      try {
        await setAreYouSure({
          title: "Creative Code isn't three letters",
          message: `Creative Codes should generally be three letters long. You entered one with ${
            abbreviation.length
          } letter${abbreviation.length === 1 ? "" : "s"}. Are you sure you want to continue?`,
          okayText: `Make ISCIs with ${abbreviation.length} letter codes`,
        });
      } catch (e) {
        setSaving(false);
        return;
      }
    }
    if (!isBackToBack && R.isEmpty(selectedConcept)) {
      setError({
        title: "No Concept",
        message: "Concept is required. None specified.",
      });
      setSaving(false);
      return;
    }
    if (isBackToBack && (!backToBackISCIS.first || !backToBackISCIS.second)) {
      setError({
        title: "ISCI 1 and ISCI 2 Required",
        message: "ISCI 1 and ISCI 2 are both required for a Back-to-Back asset.",
      });
      setSaving(false);
      return;
    }
    if (!hasChosenLanguage) {
      setError({
        title: "No Language",
        message: "You must pick at least one language.",
      });
      setSaving(false);
      return;
    }
    if (!mediaTypeMap.display && !hasChosenLength) {
      setError({
        title: "No Length",
        message: "You must pick at least one length.",
      });
      setSaving(false);
      return;
    }
    if (mediaTypeMap.display && !hasChosenDimensions) {
      setError({
        title: "No Dimensions",
        message: "You must pick at least one set of dimensions.",
      });
      setSaving(false);
      return;
    }
    if (!hasChosenMediaType) {
      setError({
        title: "No Media Type",
        message: "You must pick at least one media type.",
      });
      setSaving(false);
      return;
    }
    if (!generatedCreatives.length) {
      setError({
        title: "No Creatives",
        message: "You aren't generating any creatives. You may have left a field blank.",
      });
      setSaving(false);
      return;
    }
    let lines = [];
    for (let { name, language, iscis } of generatedCreatives) {
      let [overriddenName, overriddenClickthrough, overriddenISCIs] = R.pipe(
        R.prop(language),
        R.defaultTo({}),
        R.props(["name", "clickthrough", "iscis"])
      )(overrideMap);
      let coalescedName = R.isNil(overriddenName) ? name : overriddenName;
      if (!coalescedName) {
        setError({
          title: "Name Required",
          message: "Creative Name is required.",
        });
        setSaving(false);
        return;
      }
      badMatch = coalescedName.match(/[^a-zA-Z0-9-&!?/:()$%+. ]/);
      if (badMatch) {
        setError({
          title: "Name Has Illegal Characters",
          message: (
            <>
              <p>
                Creative names can only have numbers, letters, spaces, or any of{" "}
                <code>"-&amp;!?/:()$%+."</code>.
              </p>
              <p>
                Your creative name has the character <code>"{badMatch[0]}"</code>
              </p>
            </>
          ),
        });
        setSaving(false);
        return;
      }
      let coalescedClickthrough = overriddenClickthrough || clickthrough;
      if (coalescedClickthrough && !R.test(/^https:\/\//, coalescedClickthrough)) {
        setError({
          title: "Click-through HTTPS",
          message: `Click-through URLs must begin with "https://". You entered "${coalescedClickthrough}"`,
        });
        setSaving(false);
        return;
      }
      for (let { isci, avail, length, dimension, nationalISCI } of iscis) {
        let { isci: overriddenISCI, reporting: overriddenReporting, skip } =
          R.prop(`${avail}_${length}`, overriddenISCIs) || {};
        if (skip) {
          continue;
        }
        if (R.test(/[^\d\w]/, overriddenISCI)) {
          setError({
            title: "Invalid ISCI",
            message: `ISCIs must only be letters and numbers. You entered "${overriddenISCI}"`,
          });
          setSaving(false);
          return;
        }
        let ourISCI = overriddenISCI || isci;
        let file = ourISCI;
        if (nationalISCI) {
          let { isci: overriddenNationalISCI } =
            R.prop(`National_${length}`, overriddenISCIs) || {};
          file = overriddenNationalISCI || nationalISCI;
        }
        lines.push({
          isci: ourISCI,
          length,
          dimension,
          avail: avail.charAt(0), // Assuming the only two avails are National and Local
          name: overriddenName || name,
          language: LANGUAGES[language].label,
          file,
          reporting: overriddenReporting || reportingCreative,
          clickthrough: overriddenClickthrough || clickthrough,
        });
      }
    }
    if (!lines.length) {
      setError({
        title: "No ISCIs",
        message: "You have to generate at least one ISCI",
      });
      setSaving(false);
      return;
    }

    let mediaTypes = [];
    for (let mediaType of R.keys(mediaTypeMap)) {
      if (mediaTypeMap[mediaType]) {
        mediaTypes.push(mediaType);
      }
    }

    const payload = {
      lines,
      company,
      mediaTypes,
      dates,
      checkedAttributeOptions,
      selectedConcept,
      backToBackISCIS,
      parent: parentCreative,
    };

    if (dates.end && dates.start > dates.end) {
      setError({
        title: "Invalid Date Range",
        message: "You cannot have your creatives start after they end.",
      });
      setSaving(false);
      return;
    }

    if (!dates.start) {
      try {
        await setAreYouSure({
          title: "Never Live",
          message:
            "You did not specify a live start date. This means these creatives will never go live. Are you sure you want to continue?",
          okayText: "Yes, Create Non-Live Creatives",
        });
      } catch (e) {
        setSaving(false);
        return;
      }
    }

    let response;
    try {
      let res = await CreativeLambdaFetch("/bulk_add", {
        method: "post",
        body: payload,
      });
      response = await awaitJSON(res);
    } catch (e) {
      // TODO: if we ever change the lambda fetchers and/or awaitJSON to properly give us status codes, I want to use
      // that here to check for the 409
      if ((e.message || "").includes("The following creative name is already taken")) {
        try {
          await setAreYouSure({
            title: "Creative Already Defined",
            message: `${e.message} The ISCIs you're currently adding might be grouped together with these existing creatives on certain pages. Are you sure you want to continue?`,
            okayText: "Add Them Anyway",
          });
        } catch (e) {
          setSaving(false);
          return;
        }
        try {
          let res = await CreativeLambdaFetch("/bulk_add", {
            method: "post",
            body: {
              ...payload,
              skipNameCheck: true,
            },
          });
          response = await awaitJSON(res);
        } catch (e) {
          let message = `Failed to add ISCIs. ${e.message}`;
          setError({
            message,
            reportError: e,
          });
          setSaving(false);
          return;
        }
      } else {
        let message = `Failed to add ISCIs. ${e.message}`;
        setError({ message, reportError: e });
        setSaving(false);
        return;
      }
    }
    await setError({
      title: "Success",
      variant: "success",
      message: `Successfully added ${response.insertCount} creative${
        response.insertCount === 1 ? "" : "s"
      } and ${response.dateCount} live date range${response.dateCount === 1 ? "" : "s"}.`,
    });
    if (createAnother) {
      // This is a little tacky. A better solution might be to clear out local state, but to do that we'd need to keep
      // track of a lot of different state variables, or refactor everything to useReducer or something. We'd also need
      // to either refetch the dropdown options to include new ones, or manually add new ones. There might be a good way
      // to force this component to remount, which might be a happy medium.
      window.location.reload();
    } else {
      goToTab(MENU_KEY);
    }
  }, [
    company,
    generatedCreatives,
    overrideMap,
    clickthrough,
    reportingCreative,
    setError,
    mediaTypeMap,
    dates,
    setAreYouSure,
    creativeName,
    abbreviation,
    goToTab,
    hasChosenLanguage,
    hasChosenLength,
    hasChosenMediaType,
    createAnother,
    checkedAttributeOptions,
    selectedConcept,
    backToBackISCIS,
    isBackToBack,
    parentCreative,
    hasChosenDimensions,
  ]);

  const enableDisplayIscis = useExperimentFlag("enableDisplayIscis");
  const mediaTypeChoices = useMemo(() => {
    let choices = [
      ["streaming", "Streaming"],
      ["linear", "Linear"],
      ["audio", "Audio"],
    ];
    if (enableDisplayIscis) {
      choices.push(["display", "Display"]);
    }
    return choices;
  }, [enableDisplayIscis]);

  return (
    <div className="bulkAddPage">
      {preFillData ? (
        <>
          <h1 className="previewName">
            <code>
              {preFillData.abbreviation} + {abbreviation || "_"} + {version || "_"} +{" "}
              {mediaTypeMap.display ? dimensions[0] || "_" : lengthLabel} + {languageLabel} + NH
            </code>
          </h1>
          <div className="content">
            <div className="leftPane">
              <div className="addForm">
                <div>
                  <Form.Group>
                    <Form.Label>Creative Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={creativeName}
                      onChange={e => setCreativeName(e.target.value)}
                      onBlur={() => setCreativeNameRaw(val => (val || "").trim())}
                    />
                  </Form.Group>
                  <Form.Group className="creativeCode">
                    <Form.Label>Creative Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={abbreviation}
                      onChange={e => {
                        setCustomAbbreviation(true);
                        setAbbreviation(e.target.value);
                      }}
                      onBlur={() =>
                        setAbbreviationRaw(val => (val || "").replace(/[^a-zA-Z]/g, ""))
                      }
                    />
                  </Form.Group>
                  <Form.Group className="repeat">
                    <Form.Label>Version</Form.Label>
                    <Form.Control
                      className="versionInput"
                      value={version}
                      onChange={e => setVersion((e.target.value || "").toUpperCase())}
                      onBlur={() => setVersion(val => (val || "").replace(/[^a-zA-Z]/g, ""))}
                    />
                    {!version && (
                      <div className="invalidFeedback">You must specify the version.</div>
                    )}
                  </Form.Group>
                </div>
                <div>
                  <Form.Group>
                    <Form.Label>Media Types</Form.Label>
                    <Form.Group>
                      {mediaTypeChoices.map(([key, label]) => (
                        <Form.Check
                          inline
                          type="checkbox"
                          id={key}
                          key={key}
                          label={label}
                          checked={mediaTypeMap[key]}
                          onChange={e => setMediaType(key, e.target.checked)}
                        />
                      ))}
                    </Form.Group>
                  </Form.Group>
                </div>
                <div>
                  <div>
                    <Form.Group>
                      <Form.Label>Languages</Form.Label>
                      <Form.Group>
                        {R.keys(LANGUAGES).map(key => (
                          <Form.Check
                            inline
                            id={key}
                            key={key}
                            label={LANGUAGES[key].label}
                            type="checkbox"
                            checked={languageMap[key]}
                            onChange={e => setLanguage(key, e.target.checked)}
                          />
                        ))}
                        {!hasChosenLanguage && (
                          <div className="invalidFeedback">
                            You must select at least one language.
                          </div>
                        )}
                      </Form.Group>
                    </Form.Group>
                  </div>
                  {mediaTypeMap.display && (
                    <div>
                      <Form.Group>
                        <Form.Label>Dimensions</Form.Label>
                        <Form.Group>
                          <CreatableSelect
                            isMulti
                            options={dimensionSelectOptions}
                            onChange={selections => {
                              setDimensions((selections || []).map(selection => selection.value));
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                    </div>
                  )}
                  {(mediaTypeMap.linear || mediaTypeMap.streaming || mediaTypeMap.audio) && (
                    <div>
                      <Form.Group>
                        <Form.Label>Lengths</Form.Label>
                        <Form.Group>
                          {["15", "30", "60"].map(length => (
                            <Form.Check
                              inline
                              type="checkbox"
                              key={length}
                              id={length}
                              label={length}
                              checked={lengthMap[length]}
                              onChange={e => setLength(length, e.target.checked)}
                            />
                          ))}
                        </Form.Group>
                      </Form.Group>
                    </div>
                  )}
                </div>
                {mediaTypeMap.linear && (
                  <div>
                    <Form.Check
                      inline
                      type="checkbox"
                      id="includeAvails"
                      label="Include Local Avails"
                      checked={includeLocals}
                      onChange={e => setIncludeLocals(e.target.checked)}
                    />
                    {mediaTypeMap.streaming && includeLocals && (
                      <div className="formNote">
                        * The local ISCIs will not be marked streaming.
                      </div>
                    )}
                  </div>
                )}
                {mediaTypeMap.audio && enableRadioBuying && (
                  <div>
                    <Form.Check
                      inline
                      type="checkbox"
                      id="includeAvails"
                      label="Local Audio for Radio"
                      checked={isLocalAudio}
                      onChange={e => setIsLocalAudio(e.target.checked)}
                    />
                  </div>
                )}
                <div>
                  <Form.Group>
                    <Form.Label>Live Dates</Form.Label>
                    <div className="dateRange">
                      <div>Start on</div>
                      <SingleDatePicker
                        showClearDate
                        block={false}
                        openDirection="up"
                        placeholder="Start"
                        date={dates.start}
                        onChange={start => setDates(dates => ({ ...dates, start }))}
                      />
                      <div>and run through</div>
                      <SingleDatePicker
                        showClearDate
                        block={false}
                        openDirection="up"
                        placeholder="Forever"
                        date={dates.end}
                        onChange={end => setDates(dates => ({ ...dates, end }))}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="doubleInputRow">
                  <Form.Group>
                    <Form.Label>Clickthrough URL</Form.Label>
                    <Form.Control
                      type="text"
                      value={clickthrough}
                      onChange={e => setClickthrough(e.target.value)}
                      onBlur={() => setClickthrough(val => (val || "").trim())}
                    />
                  </Form.Group>
                  {usesReportingCreative && (
                    <Form.Group>
                      <Form.Label>Reporting Creative</Form.Label>
                      <Form.Control
                        type="text"
                        value={reportingCreative}
                        onChange={e => setReportingCreative(e.target.value)}
                        onBlur={() => setReportingCreative(val => (val || "").trim())}
                      />
                    </Form.Group>
                  )}
                  {usesReportingCreative && (
                    <Form.Group>
                      <Form.Label>Parent Creative</Form.Label>
                      <Form.Control
                        type="text"
                        value={parentCreative}
                        onChange={e => setParentCreative(e.target.value)}
                        onBlur={() => setParentCreative(val => (val || "").trim())}
                      />
                    </Form.Group>
                  )}
                </div>
                <div className="backToBackCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Back-to-Back Creative"
                    checked={isBackToBack}
                    onChange={() => {
                      setIsBackToBack(current => !current);
                      setBackToBackISCIS({});
                      setSelectedConcept({});
                      setCheckedAttributeOptions({});
                    }}
                  />
                </div>
                {isBackToBack && (
                  <div className="backToBackSelectContainer">
                    <div className="backToBackDropdown">
                      <div className="title">ISCI 1</div>
                      <Select
                        menuPlacement="top"
                        options={creativeList}
                        onChange={selection =>
                          setBackToBackISCIS(current => {
                            return { ...current, first: selection.value };
                          })
                        }
                      />
                    </div>

                    <div className="backToBackDropdown">
                      <div className="title">ISCI 2</div>
                      <Select
                        menuPlacement="top"
                        options={creativeList}
                        onChange={selection =>
                          setBackToBackISCIS(current => {
                            return { ...current, second: selection.value };
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                {!isBackToBack && (
                  <div className="conceptSelect">
                    <div>Concept</div>
                    <Select
                      className="networkSelect"
                      options={conceptDropdownOptions}
                      onChange={selection =>
                        setSelectedConcept({
                          conceptName: selection.value,
                          conceptId: selection.id,
                        })
                      }
                    />
                  </div>
                )}

                {shouldEnableInsightsCategories && (
                  <div className="metaData">
                    <div>
                      <div>Insights Categories</div>
                      <Select
                        isMulti={true}
                        className="insightsCategoriesSelect"
                        value={selectedInsightsCategories.map(cat => {
                          return { label: cat, value: cat };
                        })}
                        options={insightsCategoriesOptions.map(cat => {
                          return { label: cat, value: cat };
                        })}
                        onChange={selection => {
                          const newValues = selection || [];
                          setSelectedInsightsCategories(newValues.map(cat => cat.value));
                          const newInsightsCategoriesObject = {};
                          newValues.forEach(cat => (newInsightsCategoriesObject[cat.value] = true));
                          setCheckedAttributeOptions(current => {
                            return {
                              ...current,
                              "Insights Category": newInsightsCategoriesObject,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

                {creativeAttributes && !isBackToBack && (
                  <div className="creativeAttributes">
                    <div className="title">Creative Attributes</div>
                    <div className="selectedAttributeBoxes">
                      {R.map(attribute => {
                        return (
                          <div className="attributeBox" key={attribute.name}>
                            <div className="name">
                              <div>{attribute.name}</div>
                            </div>
                            <div className="options">
                              {attribute.options.map(option => {
                                return (
                                  <div className="option" key={option}>
                                    <Form.Check
                                      key={option}
                                      type="checkbox"
                                      label={option}
                                      checked={
                                        ((checkedAttributeOptions || {})[attribute.name] || {})[
                                          option
                                        ]
                                      }
                                      disabled={
                                        R.path(
                                          [selectedConcept.conceptName, attribute.name],
                                          conceptNaMap
                                        ) === false || R.isEmpty(selectedConcept)
                                      }
                                      onChange={e => {
                                        let { checked } = e.target;
                                        setCheckedAttributeOptions(current => {
                                          return {
                                            ...current,
                                            [attribute.name]: {
                                              ...checkedAttributeOptions[attribute.name],
                                              [option]: checked,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }, creativeAttributes)}
                    </div>
                  </div>
                )}
              </div>
              <div className="controls">
                <div>
                  <Button variant="dark" onClick={() => goToTab(MENU_KEY)}>
                    Back
                  </Button>
                </div>
                <div>
                  <Button variant="outline-primary" onClick={() => goToTab(MENU_KEY)}>
                    Cancel
                  </Button>
                  <Button className="createButton" disabled={saving} onClick={createISCIs}>
                    <span>Create ISCIs</span>
                    {saving && <Spinner className="savingSpinner" color="white" />}
                  </Button>
                  <Form.Check
                    inline
                    id="createAnotherCheckbox"
                    label="Create Another"
                    type="checkbox"
                    checked={createAnother}
                    onChange={e => setCreateAnother(e.target.checked)}
                  />
                </div>
              </div>
            </div>
            <div className="results">
              {generatedCreatives.length ? (
                <ListGroup variant="flush" className="generatedList">
                  {generatedCreatives.map(({ name, iscis, language }) => {
                    const editing = editingCreativeMap[language];
                    let override = overrideMap[language] || {};

                    let overriddenValues = [
                      ["name", name],
                      ["clickthrough", clickthrough],
                    ].reduce((map, [key, value]) => {
                      map[key] = R.isNil(override[key]) ? value : override[key];
                      return map;
                    }, {});

                    return (
                      <ListGroup.Item key={language} className="creativeItem">
                        <div className={`creativeName${editing ? " editing" : ""}`}>
                          {editing ? (
                            <Form.Control
                              value={overriddenValues.name}
                              onChange={e => {
                                setOverride(language, {
                                  ...override,
                                  name: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            <div className="name">{overriddenValues.name}</div>
                          )}
                          <Button
                            className="editButton"
                            size="sm"
                            variant={`${editing ? "" : "outline-"}primary`}
                            onClick={() => setEditingCreative(language, !editing)}
                          >
                            {editing ? <MdCheck /> : <MdEdit />}
                          </Button>
                        </div>
                        <div className={`subMetaData${editing ? " editing" : ""}`}>
                          {editing ? (
                            <>
                              <div className="formLabel">Click-through URL</div>
                              <Form.Control
                                size="sm"
                                value={overriddenValues.clickthrough}
                                onChange={e => {
                                  setOverride(language, {
                                    ...override,
                                    clickthrough: e.target.value,
                                  });
                                }}
                              />
                            </>
                          ) : (
                            <div>
                              Click-through URL:{" "}
                              <span className="value">
                                {overriddenValues.clickthrough || "None"}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="isciList">
                          {iscis.map(({ isci, avail, length, dimension }) => {
                            const isciKey = `${avail}_${mediaTypeMap.display ? dimension : length}`;
                            const isciOverride = R.path(["iscis", isciKey], override) || {};
                            const overriddenISCI = R.isNil(isciOverride.isci)
                              ? isci
                              : isciOverride.isci;
                            const overriddenReporting = R.isNil(isciOverride.reporting)
                              ? reportingCreative
                              : isciOverride.reporting;
                            let classes = ["isciRow"];
                            if (editing) {
                              classes.push("editing");
                            } else if (isciOverride.skip) {
                              classes.push("skipped");
                            }
                            return (
                              <React.Fragment key={isciKey}>
                                <div className={classes.join(" ")}>
                                  {mediaTypeMap.linear && <div className="avail">{avail}</div>}
                                  <div className="length">{length}s</div>
                                  <div className="isci">
                                    {editing ? (
                                      <Form.Control
                                        size="sm"
                                        value={overriddenISCI}
                                        onChange={e => {
                                          setOverride(
                                            language,
                                            R.mergeDeepRight(override, {
                                              iscis: {
                                                [isciKey]: {
                                                  isci: (e.target.value || "")
                                                    .replace(/[^\w]/g, "")
                                                    .toUpperCase(),
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                    ) : (
                                      overriddenISCI
                                    )}
                                  </div>
                                  {editing && (
                                    <Button
                                      size="sm"
                                      variant={`${isciOverride.skip ? "" : "outline-"}danger`}
                                      onClick={() => {
                                        setOverride(
                                          language,
                                          R.mergeDeepRight(override, {
                                            iscis: {
                                              [isciKey]: {
                                                skip: !isciOverride.skip,
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <MdBlock />
                                    </Button>
                                  )}
                                  {!editing && isciOverride.skip && (
                                    <div className="skipLabel">(Skipping)</div>
                                  )}
                                </div>
                                {usesReportingCreative && (
                                  <div className={`subMetaData${editing ? " editing" : ""}`}>
                                    {editing ? (
                                      <>
                                        <div className="formLabel">Reporting Creative</div>
                                        <Form.Control
                                          size="sm"
                                          value={overriddenReporting}
                                          onChange={e => {
                                            setOverride(
                                              language,
                                              R.mergeDeepRight(override, {
                                                iscis: {
                                                  [isciKey]: {
                                                    reporting: e.target.value,
                                                  },
                                                },
                                              })
                                            );
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <div>
                                        Reporting Creative{" "}
                                        <span className="value">
                                          {overriddenReporting || "None"}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              ) : (
                <div className="emptyList">No Creatives</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
});
