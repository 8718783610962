import "../IncrementalityPlaybook.scss";
import "./Incrementality101.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";
import { playbookTable } from "../PlaybookTable";

const Incrementality101 = typedReactMemo<React.FC>(() => {
  const methodHeaders = [
    {
      flex: 2,
      label: "Method",
      name: "Method",
      nonInteractive: true,
      renderer: row => {
        return (
          //Reuse the channel class from the Playbook.scss
          <div className="channelNames">
            <div className="channelName single">
              <Img src={`${INC_CDN}/${row.Method.img}`} />
              <div className="label">{row.Method.label}</div>
            </div>
          </div>
        );
      },
    },
    {
      flex: 3,
      label: "Description",
      name: "Description",
      nonInteractive: true,
      renderer: row => {
        return <div className="bulletedList">{row.Description}</div>;
      },
    },
    {
      flex: 3,
      label: "Pros",
      name: "Pros",
      nonInteractive: true,
      renderer: row => {
        return <div className="bulletedList">{row.Pros}</div>;
      },
    },
    {
      flex: 3,
      label: "Cons",
      name: "Cons",
      nonInteractive: true,
      renderer: row => {
        return <div className="bulletedList">{row.Cons}</div>;
      },
    },
  ];
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Incrementality testing is a method used to measure the causal impact of a marketing
              campaign or channel. The objective is to understand whether the campaign is actually
              responsible for driving specific outcomes or if those outcomes would have happened
              anyway.
            </div>
            <br />
            <div className="incLineChartText">
              Incrementality testing is the gold standard of measurement and will provide an
              unbiased, isolated estimate of the streaming campaign’s impact.
            </div>
            <Img className="incLineChart" src={`${INC_CDN}/IncrementalityLineChart.png`} />
            <br />
            <div className="basicText">
              To determine causal impact, incrementality testing involves creating two groups: a
              treatment group (also called the “exposed” or “test” group) and a control group. The
              treatment group is exposed to the marketing activity, while the control group is not.
            </div>
            <br />
            <div className="basicText">
              By comparing the outcomes of the two groups, any differences observed can be
              attributed to the campaign itself. This is because the only significant difference
              between the groups is the exposure to the campaign, making it possible to estimate the
              causal impact of the marketing intervention.
            </div>
            <br />
            <Img src={`${INC_CDN}/IncrementalityExperimentSetup.png`} /> <br />
            <div className="basicText">
              The concept of incrementality is essential because it measures the true effectiveness
              of marketing efforts and enables informed and data-driven decisions about resource
              allocation, targeting strategies, and campaign optimization. The goal is to ensure
              that marketing budgets are efficiently spent on initiatives that generate incremental
              value.
            </div>
          </div>
        }
        id="what-is-incrementality-testing"
        title={"What is Incrementality Testing?"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <a
              href="#what-is-incrementality-testing"
              onClick={() => navigateToSection("what-is-incrementality-testing")}
            >
              What is Incrementality Testing?
            </a>
            <a
              href="#incrementality-testing-options-and-considerations"
              onClick={() => navigateToSection("incrementality-testing-options-and-considerations")}
            >
              Incrementality Testing Options and Considerations
            </a>
            <ul className="minimalList">
              {R.map(
                section => (
                  <li>
                    <a
                      href={`#inc_${section.toLowerCase().replace(/ /g, "-")}`}
                      onClick={() => {
                        navigateToSection("incrementality-testing-options-and-considerations");
                      }}
                    >
                      {section}
                    </a>
                  </li>
                ),
                ["Methodology", "Platform-Native vs Out-of-platform", "Sample Size Calculations"]
              )}
            </ul>

            <a href="#ghost-bidding" onClick={() => navigateToSection("ghost-bidding")}>
              Ghost bidding
            </a>
            <ul className="minimalList">
              {R.map(
                section => (
                  <li>
                    <a
                      href={`#ghost_${section.toLowerCase().replace(/ /g, "-")}`}
                      onClick={() => {
                        navigateToSection("ghost-bidding");
                      }}
                    >
                      {section}
                    </a>
                  </li>
                ),
                [
                  "Overview",
                  "Methodology",
                  "How it works",
                  "Identification of Winning Control Bids",
                ]
              )}
            </ul>
          </div>
        }
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader" id="inc_methodology">
              Methodology
            </div>
            <Img src={`${INC_CDN}/DemographicVsRegion.png`} />
            <div className="basicText">
              There are 2 main types of incrementality tests depending on what level you are
              randomizing: user-split or geo-split.
            </div>
            <br />
            <div className="basicText">
              Randomization ensures that both groups are equivalent in terms of characteristics and
              behaviors and that the only difference between the groups is the marketing activity
              exposure. This allows us to isolate the marketing activity’s impact.
            </div>
            <br />
            <div className="basicText">
              In addition to randomization, analysis can also be done at the geographic or user
              level. Analysis conducted after the test has completed involves comparing outcomes
              using statistical techniques such as hypothesis testing to estimate the causal impact
              of the campaign. In some hybrid experimental designs, randomization might be done at
              the geo-level but results analyzed at the user level.
            </div>
            <br />
            {playbookTable(
              [
                {
                  Method: {
                    label: "User-based Testing",
                    img: "Icon_PSATesting.png",
                  },
                  Description: (
                    <ul className="basicList">
                      <li>
                        In user-based testing, individual users are randomly assigned to the
                        treatment or control group. This allows for a granular analysis of the
                        impact of the campaign on specific user segments.
                      </li>
                      <li>
                        A common option to do this is PSA testing , in which the treatment group is
                        exposed to client ads, and the control group is exposed to control ads or
                        public service announcements.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        User-level data provides analytical flexibility , allowing different cuts of
                        performance.
                      </li>
                      <li>
                        User-level data allows for larger sample sizes , making it easier to achieve
                        statistical significance and obtain more robust results.
                      </li>
                      <li>
                        User-based testing is generally cheaper, quicker, and simpler to implement
                        compared to other incrementality testing options, making it an attractive
                        choice for testing smaller-scale campaigns.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Coordinating a consistent control across different inventory types and
                        channels can be challenging on some platforms, leading to
                        cross-contamination concerns . This may limit the ability to test a large
                        portion of the portfolio at once.
                      </li>
                      <li>
                        User-based testing relies on user-level data , which may not always be
                        available or easy to access, especially in cases where user privacy concerns
                        arise.
                      </li>
                      <li>
                        In a PSA test because the algorithm will optimize towards users based off
                        the PSA ad . It is possible that users who tend to respond or complete an ad
                        are different from users who complete a PSA. In other words, this process
                        can slightly undo the IP randomization process where we end up comparing
                        apples and oranges.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: {
                    label: "Geo Testing",
                    img: "Icon_Geotesting.png",
                  },
                  Description: (
                    <ul className="basicList">
                      <li>
                        In geographic-based testing, specific geographic regions are randomly
                        assigned to the treatment or control group.
                      </li>
                      <li>
                        By comparing the outcomes in the treatment regions to the control regions,
                        the causal impact of the campaign can be assessed at a broader level.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Easier cross-channel testing as the control group is separated by geography
                        across all channels, so testing across different inventory types or channels
                        becomes easier.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Geo segmentation results in smaller sample sizes , making it harder to
                        achieve statistical significance and more expensive and time-consuming
                        compared to user-based testing.
                      </li>
                      <li>
                        Going dark in all the control DMAs may result in higher opportunity costs
                        for the marketing campaign.
                      </li>
                      <li>
                        Despite efforts to choose similar geographies for the exposed and holdout
                        groups, there may still be unaccounted trends or differences that can affect
                        the accuracy of the results.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              i => (i === 0 ? 600 : 450)
            )}
            <br />
            <div className="basicHeader" id="inc_platform-native-vs-out-of-platform">
              Platform-Native vs Out-of-platform
            </div>
            <br />
            <div className="basicText">Platform Native</div>
            <div className="basicText">
              Refers to conducting experiments within the platform or ecosystem where the marketing
              campaigns are being executed. The platform itself facilitates the randomization,
              exposure, and measurement of outcomes.
            </div>
            <ul className="basicList">
              <li>
                The advantage of platform-native incrementality testing is that it is often easier
                to implement since it leverages the platform's existing infrastructure and often
                will be executed entirely by their team. It provides a seamless integration with
                campaign execution, measurement, and optimization within the platform.
              </li>
            </ul>
            <br />
            <div className="basicText">Out of Platform</div>
            <div className="basicText">
              Refers to conducting experiments outside the platform or ecosystem where the marketing
              campaigns are being executed.
            </div>
            <ul className="basicList">
              <li>
                The advantage of non-platform native incrementality testing is that it provides more
                flexibility and control over the experimental setup and analysis. However, it may
                require additional resources, expertise, and effort to set up and manage the
                experiments independently.
              </li>
            </ul>
            <br />
            <div className="basicHeader" id="inc_sample-size-calculations">
              Sample Size Calculations
            </div>
            <div className="basicText">
              Sample size calculations use informed assumptions and historical data to determine the
              right level of spend needed to properly power the test.
            </div>
            <br />
            <div className="basicText">
              Sample size calculations are an essential step in designing the experiment, as they
              ensure that the study has enough statistical power to detect meaningful effects and
              provide reliable conclusions. This helps avoid situations where we spend money on a
              test but are unable to draw meaningful conclusions from it.
            </div>
            <br />
            <div className="basicText">
              Calculating the sample size involves considering several factors, like how certain we
              want to be about the results, the size of the effect we expect to see, how much the
              data tends to vary, and how likely we are to detect the effect. Effect size is like
              the strength of the difference we're looking for, and variability is how spread out
              the data is.
            </div>
          </div>
        }
        id="incrementality-testing-options-and-considerations"
        title={"Incrementality Testing Options and Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader" id="ghost_overview">
              Overview
            </div>
            <div className="basicText">
              Ghost bidding is a popular method utilized by many platforms in their conversion lift
              products, providing cost-effective incrementality testing with no cost for the control
              group.
            </div>
            <br />
            <div className="basicText">
              It is a method for identifying users that we would have served an ad to without
              actually serving an ad, which is why it allows platforms to provide a no control cost
              option for audience split incrementality testing.
            </div>
            <br />
            <div className="basicText">
              This method of logging individuals in the control group doesn’t have to be turned off
              because it’s costless! Since our impression is never served in the control group we
              don’t have to pay it, instead the next best bid advertisement is served.
            </div>
            <br />
            <a href="https://www.thinkwithgoogle.com/intl/en-emea/marketing-strategies/monetisation-strategies/a-revolution-in-measuring-ad-effectiveness/">
              A Revolution in Measuring Ad Effectiveness: Knowing Who Would Have Been Exposed
            </a>
            <br />
            {playbookTable(
              [
                {
                  Method: {
                    label: "Ghost Bidding",
                    img: "Icon_Ghost.png",
                  },
                  Description: (
                    <ul className="basicList">
                      <li>
                        In user-based testing, individual users are randomly assigned to the
                        treatment or control group. This allows for a granular analysis of the
                        impact of the campaign on specific user segments.
                      </li>
                      <li>
                        A common option to do this is PSA testing , in which the treatment group is
                        exposed to client ads, and the control group is exposed to control ads or
                        public service announcements.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Since the ad is not actually served to users in the control group, there are
                        no additional ad-serving costs for the advertiser, however, the client will
                        still need to pay for their ads that are actually served to the treatment
                        group.
                      </li>
                      <li>
                        Ghost bidding captures the defensive effect of advertising in the real
                        marketplace, accounting for the presence of competitive ads from other
                        advertisers. This provides a more realistic measure of incremental impact of
                        the focal advertiser's campaign.
                      </li>
                      <li>
                        Ghost bidding avoids potential biases introduced by ad-serving algorithms in
                        PSA testing. By not actually serving the ad to the control group, the
                        analysis can more accurately compare similar user segments.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        The effectiveness of ghost bidding is contingent on the platform's ability
                        to accurately log the winning control bids or identify ghost wins. Methods
                        may vary by their accuracy in identifying winning bids and not all platforms
                        may support this method, limiting its applicability.
                      </li>
                      <li>
                        Platforms that don’t have the capability to accurately identify the winning
                        control bids (ghost wins) may use an intent-to-treat approach, which may
                        lead to less precise results compared to platforms capable of identifying
                        ghost wins.
                      </li>
                    </ul>
                  ),
                },
              ],
              R.filter(header => header.name !== "Description", methodHeaders),
              600
            )}
            <br />
            <div className="basicHeader" id="ghost_methodology">
              Methodology
            </div>
            <div className="basicText">
              We want to compare the response rates of individuals that actually saw an ad to the
              response rates of individuals that would have seen the ad but didn’t. This assignment
              to “treatment” and “control” is random.
            </div>
            <br />
            <div className="basicHeader" id="ghost_how-it-works">
              How it works
            </div>
            <ol className="basicList">
              <li>
                Users are randomized into treatment and control, typically using hashing functions
                and after users have been qualified by the campaign constraints and filters.
              </li>
              <li>
                Users that are in the treatment are bid on as normal and shown an ad if we win the
                auction. In the control group, users are withheld from the auction and those that we
                would have bid on are identified. These are what we call ghost bids.
              </li>
              <li>
                These control users are bid on as normal by other advertisers but withheld from
                being bid on by our client.
              </li>
              <li>
                After the auction, a variety of methods may be used to identify which ghost bids we
                would have won. For example, some platforms may have access to the price that they
                would have bid for that user and the win price.
              </li>
            </ol>
            <br />
            <div className="basicHeader" id="ghost_identification-of-winning-control-bids">
              Identification of Winning Control Bids
            </div>
            <div className="basicText">
              Placement targeting (geo, demo, device, psycho-graphic etc.) and the bid optimization
              is used to qualify users and identify users we would like to bid on and what we would
              have bid. After this process, qualified users are split into Treatment and Control
              groups. In the control group, users are bid on by other advertisers but not the focal
              advertiser and we want to identify of these users that we bid on which ones would we
              have won to expose an ad.
            </div>
            <br />
            <ol className="basicList">
              <li>
                After the auction, a number of strategies are used depending on the platform.
                Regardless of method used, the idea is that we want to compare the wins on the
                treatment side to the wins on the control side (those that would have seen the ad).
              </li>
              <li>
                Some platforms back into the winning responses using calculations, others identify
                the winning control bid by comparing the final winning price to the price that we
                would have bid and some use modeling.
              </li>
              <li>
                Other platforms may not have the ability to identify winning ghost bids and so rely
                on{" "}
                <a href="https://www.remerge.io/findings/blog-post/incrementality-tests-101-intent-to-treat-psa-ghost-ads-and-ghost-bids">
                  the Intent-to-Treat (ITT) approach
                </a>{" "}
                which is less precise because it includes all participants in the analysis,
                regardless of their compliance or adherence to the treatment. This can lead to
                dilution of treatment effects and potentially underestimate the true impact of the
                intervention. The main disadvantage of using the ITT approach is that it may
                introduce bias and make it challenging to draw accurate conclusions about the
                treatment's effectiveness, especially if there are high rates of non-compliance or
                dropout in the study.
              </li>
            </ol>
          </div>
        }
        id="ghost-bidding"
        title={"Ghost Bidding"}
      />
    </div>
  );
});

export default Incrementality101;
