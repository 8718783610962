import React, { useEffect, useMemo, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import "./MoreInfo.scss";

enum MoreInfoTooltipDirection {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_LEFT = "bottom-left",
}

interface MoreInfoTooltipProps {
  background?: string;
  children: React.ReactNode;
  direction?: MoreInfoTooltipDirection;
  rightLabel?: string | React.ReactNode;
  size?: "reg" | "sm";
  color?: string;
}

const backgroundColors = {
  dark: "#6B2DEF",
  light: "#fff",
};

const MoreInfoTooltip: React.FC<MoreInfoTooltipProps> = React.memo(
  ({
    background = "dark",
    children,
    direction = MoreInfoTooltipDirection.BOTTOM_RIGHT,
    rightLabel,
    size = "reg",
    color,
  }): JSX.Element => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [resolvedDirection, setResolvedDirection] = useState<MoreInfoTooltipDirection>(direction);
    const dimensions = useMemo(() => {
      return {
        width: size === "reg" ? "22px" : "18px",
        height: size === "reg" ? "22px" : "18px",
        color: color,
      };
    }, [color, size]);
    const toolTipTextStyle = useMemo(() => {
      if (resolvedDirection === MoreInfoTooltipDirection.TOP_LEFT) {
        return {
          bottom: size === "reg" ? "26px" : "21.85px",
          right: size === "reg" ? "-6.9px" : "-14.5px",
        };
      } else if (resolvedDirection === MoreInfoTooltipDirection.TOP_RIGHT) {
        return {
          bottom: size === "reg" ? "26px" : "21.85px",
          left: size === "reg" ? "-12.7px" : "-14.5px",
        };
      } else if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_LEFT) {
        return {
          top: size === "reg" ? "26px" : "21.85px",
          right: size === "reg" ? "-6.9px" : "-14.5px",
        };
      } else {
        return {
          top: size === "reg" ? "26px" : "21.85px",
          left: size === "reg" ? "-12.7px" : "-14.5px",
        };
      }
    }, [resolvedDirection, size]);

    const verticallyBound = (parentDiv, childDiv) => {
      let parentRect = parentDiv.getBoundingClientRect();
      let childRect = childDiv.getBoundingClientRect();

      return parentRect.top <= childRect.top && parentRect.bottom >= childRect.bottom;
    };

    const horizontallyBound = (parentDiv, childDiv) => {
      let parentRect = parentDiv.getBoundingClientRect();
      let childRect = childDiv.getBoundingClientRect();

      return parentRect.left <= childRect.left && parentRect.right >= childRect.right;
    };

    const [isVerticallyBound, setIsVerticallyBound] = useState<boolean>(true);
    const [isHorizontallyBound, setIsHorizontallyBound] = useState<boolean>(true);
    useEffect(() => {
      if (showTooltip) {
        const parentDiv = document.getElementsByClassName("body")[0];
        const childDiv = document.getElementById("dropDownTooltipText");

        setIsVerticallyBound(verticallyBound(parentDiv, childDiv));
        setIsHorizontallyBound(horizontallyBound(parentDiv, childDiv));
      } else {
        setIsVerticallyBound(true);
        setIsHorizontallyBound(true);
      }
    }, [showTooltip]);

    useEffect(() => {
      if (!isVerticallyBound && !isHorizontallyBound) {
        if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_LEFT) {
          setResolvedDirection(MoreInfoTooltipDirection.TOP_RIGHT);
        } else if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_RIGHT) {
          setResolvedDirection(MoreInfoTooltipDirection.TOP_LEFT);
        } else if (resolvedDirection === MoreInfoTooltipDirection.TOP_LEFT) {
          setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_RIGHT);
        } else {
          setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_LEFT);
        }
      } else {
        if (!isVerticallyBound && isHorizontallyBound) {
          if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_LEFT) {
            setResolvedDirection(MoreInfoTooltipDirection.TOP_LEFT);
          } else if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_RIGHT) {
            setResolvedDirection(MoreInfoTooltipDirection.TOP_RIGHT);
          } else if (resolvedDirection === MoreInfoTooltipDirection.TOP_LEFT) {
            setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_LEFT);
          } else {
            setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_RIGHT);
          }
        }
        if (isVerticallyBound && !isHorizontallyBound) {
          if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_LEFT) {
            setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_RIGHT);
          } else if (resolvedDirection === MoreInfoTooltipDirection.BOTTOM_RIGHT) {
            setResolvedDirection(MoreInfoTooltipDirection.BOTTOM_LEFT);
          } else if (resolvedDirection === MoreInfoTooltipDirection.TOP_LEFT) {
            setResolvedDirection(MoreInfoTooltipDirection.TOP_RIGHT);
          } else {
            setResolvedDirection(MoreInfoTooltipDirection.TOP_LEFT);
          }
        }
      }
      setIsHorizontallyBound(true);
      setIsVerticallyBound(true);
    }, [isHorizontallyBound, isVerticallyBound, resolvedDirection]);

    return (
      <div
        className="tooltip-container"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <MdInfoOutline
          className={`infoIcon ${showTooltip ? "hover" : ""}`}
          color={backgroundColors[background]}
          style={dimensions}
        />
        <div className="rightLabel">{rightLabel}</div>
        {showTooltip && (
          <span
            className={`toolTiptext ${resolvedDirection}`}
            style={toolTipTextStyle}
            id="dropDownTooltipText"
          >
            {children}
          </span>
        )}
      </div>
    );
  }
);

export default MoreInfoTooltip;
