import { useState, createContext, ReactNode, useEffect } from "react";

export const formObjDefault = {
  company: "",
  test_name: "",
  utm_test_id: "",
  default_url: "",
  default_id: "",
  plover_url: "",
  folder_path: "/",
  notes: "",
  variants: [{ variant_id: "", variant_url: "" }],
  domain: "",
  created_by: "",
  updated_by: "",
  rules: [],
  status: "draft",
  created_at: new Date(),
  updated_at: new Date(),
  is_deleted: false,
};

export const defaultRule = {
  additional_utm: [],
  conditions: [],
  cookie_operation: [],
  cookie_operation_url: [],
  customRetention: {},
  dow_operation: [],
  rule_name: "",
  rule_order: "0",
  timeframe_enabled: false,
  variant_splits: [
    {
      split: 0,
      variant_id: "",
    },
  ],
};

export const defaultCustomRetention = {
  customRetentionStatus: true,
  formattedStartDate: "",
  startDate: "",
  formattedStartTime: "",
  startTime: "",
  time_zone: "UTC",
};

export const defaultDowRow = {
  day: [],
  end_time: "",
  formattedEndTime: "",
  formattedStartTime: "",
  start_time: "",
  variants: [],
};

export const defaultTimeframe = {
  endDateTime: "",
  formattedEndDateTime: "",
  formattedStartDateTime: "",
  startDateTime: "",
  time_zone: "UTC",
  timeframe_utm_test_id_enabled: false,
  utm_test_id: "",
};

export const FormContext = createContext<any>([[], () => {}]);

export interface WTOFormContextProps {
  children: ReactNode;
}

export const WtoFormProvider = (props: WTOFormContextProps): JSX.Element => {
  const [wtoFormObj, setWtoFormObj] = useState(formObjDefault);
  const [configurations, setConfigurations] = useState([]);
  const [newRule, setNewRule] = useState(defaultRule);
  useEffect(() => {}, [configurations]);

  return (
    <FormContext.Provider
      value={{ wtoFormObj, setWtoFormObj, configurations, setConfigurations, newRule, setNewRule }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default WtoFormProvider;
