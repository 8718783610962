import React, { useRef, useEffect, useState } from "react";

import { Spinner } from "./";

export const Audio = ({
  src,
  unloader = <span />,
  spinnerSize = 20,
  className = "",
  alt = "",
  ...props
}) => {
  const audioRef = useRef();

  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = document.createElement("audio");
    }
  }, [audioRef]);

  useEffect(() => {
    let stale = false;
    if (audioRef.current) {
      audioRef.current.src = src;
      audioRef.current.addEventListener("canplay", () => {
        if (!stale) {
          setLoadingState("loaded");
        }
      });
      audioRef.current.addEventListener("error", () => {
        if (!stale) {
          setLoadingState("error");
        }
      });
    }
    return () => {
      stale = true;
    };
  }, [audioRef, src, setLoadingState]);

  switch (loadingState) {
    case "loaded":
      return <audio {...props} alt={alt} src={src} className={className} />;
    case "loading":
      return <Spinner size={spinnerSize} />;
    case "error":
      return unloader;
    default:
      return <span />;
  }
};

export default Audio;
