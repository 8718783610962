import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DateRange } from "../utils/types";

export const SHORT_DATE_FORMAT = "MM/dd";
const DATE_FORMAT = "yyyy-MM-dd";

/**
 * Converts a date range into an array of individual weeks that exist with the range (inclusive of
 * start and end date)
 */
export const getWeeksFromRange = (dateRange: DateRange): string[] => {
  let weeks: string[] = [];
  let currentWeek = dateRange.start;
  while (currentWeek <= dateRange.end) {
    weeks.push(currentWeek);
    currentWeek = R.pipe(Dfns.parseISO, Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(currentWeek);
  }

  return weeks;
};

export const validDate = (date: Date): boolean => {
  if (R.isNil(date)) {
    return false;
  }
  const timeNow = new Date(date);
  return !R.isNil(timeNow) && !R.isNil(timeNow.getDate()) && !isNaN(timeNow.getDate());
};
