import React from "react";

import { Router } from "@reach/router";

import { MdMenu, MdSettings } from "react-icons/md";

import { useTabbedNav } from "../utils/hooks/useNav";

import { Page, ToggleNav, ToggleNavButton } from "../Components";

import StreamingNetworkMenu from "./StreamingNetworkMenu";
import StreamingNetworkProperties from "./StreamingNetworkProperties";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

export const MENU_KEY = "menu";
export const PROPERTIES_KEY = "properties";

export const StreamingNetworksContext = React.createContext();

export default React.memo(({ navigate }) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "networks",
    defaultKey: MENU_KEY,
  });

  const shouldEnableStreamingNetworksUpdates = useExperimentFlag("enableStreamingNetworksUpdates");

  return (
    <Page
      title="Streaming Networks"
      pageType="Streaming Networks"
      minWidth={900}
      actions={
        !shouldEnableStreamingNetworksUpdates && (
          <div className="streamingNetworksActions">
            <ToggleNav activeKey={tab} onChange={goToTab}>
              <ToggleNavButton value={MENU_KEY}>
                <MdMenu />
              </ToggleNavButton>
              <ToggleNavButton value={PROPERTIES_KEY}>
                <MdSettings />
              </ToggleNavButton>
            </ToggleNav>
          </div>
        )
      }
    >
      <div className="creativeMapPage">
        <StreamingNetworksContext.Provider
          value={{
            goToTab,
            shouldEnableStreamingNetworksUpdates,
          }}
        >
          <Router className="fullPageRouter">
            {/* <StreamingNetworkMenu path={MENU_KEY} /> */}
            <StreamingNetworkProperties path={PROPERTIES_KEY} />
            <StreamingNetworkMenu network={tab} default />
          </Router>
        </StreamingNetworksContext.Provider>
      </div>
    </Page>
  );
});
