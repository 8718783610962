import React, { useContext, useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdArrowCircleRight,
  MdEdit,
  MdDelete,
} from "react-icons/md";
import { BiSolidDuplicate } from "react-icons/bi";
import { Button, ButtonType, ThreeDotsButton } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { FormContext } from "./WtoFormContext";
import { useDrag, useDrop } from "react-dnd";
import { DAYS } from "./WtoUtils";
import * as R from "ramda";
import "./WtoRulesFormModal.scss";

interface WtoRuleCardProps {
  data: Record<string, any>;
  index: number;
  show: boolean;
  hideCard: (number) => void;
  ruleModal: (index?: number) => void;
  reorderEnabled: boolean;
  setDuplicateRuleIndex: React.Dispatch<React.SetStateAction<number>>;
  showRuleCards: boolean[];
  setShowRuleCards: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const WtoRuleCard: React.FC<WtoRuleCardProps> = ({
  data,
  index,
  show,
  hideCard,
  ruleModal,
  reorderEnabled,
  setDuplicateRuleIndex,
  showRuleCards,
  setShowRuleCards,
}) => {
  const { wtoFormObj, setWtoFormObj } = useContext(FormContext);

  const cardIndex = Number(data.rule_order);

  const updateFormField = (key, value) =>
    setWtoFormObj((current: any) => {
      return { ...current, [key]: value };
    });

  interface RuleCardDragItem {
    type: typeof RULE_CARD_DND_TYPE;
    index: number;
    data: any;
  }

  const id = `rule:${data.rule_name}`;

  const RULE_CARD_DND_TYPE = "RULE_CARD_DND_TYPE" as const;

  const ref = useRef<HTMLDivElement>(null);

  const onDrop = (droppedData, droppedIndex) => {
    let reorderedRules = R.clone(wtoFormObj.rules);
    let reorderedCardsShown = R.clone(showRuleCards);
    reorderedRules[droppedIndex] = {
      ...data,
      rule_order: droppedIndex,
    };
    reorderedRules[index] = {
      ...droppedData,
      rule_order: index,
    };
    reorderedCardsShown[index] = showRuleCards[droppedIndex];
    reorderedCardsShown[droppedIndex] = showRuleCards[index];
    setShowRuleCards(reorderedCardsShown);
    setWtoFormObj((current: any) => {
      return { ...current, rules: reorderedRules };
    });
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: RULE_CARD_DND_TYPE, id, index, data },
    canDrag: reorderEnabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: RULE_CARD_DND_TYPE,
    drop: (item: RuleCardDragItem) => onDrop(item.data, item.index),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const getVariantUrl = id => {
    const variant = R.find(R.propEq("variant_id", id), wtoFormObj.variants);
    if (variant && R.has("variant_url", variant)) {
      return variant.variant_url as string;
    }
    return null;
  };

  const deleteRule = () => {
    let updatedRules = R.remove(cardIndex, 1, wtoFormObj.rules);
    let updatedCardsShown = R.remove(cardIndex, 1, showRuleCards);
    [...updatedRules].forEach((rule: any, index) => {
      let ruleOrder = Number(rule.rule_order);
      updatedRules[index] = {
        ...rule,
        rule_order: ruleOrder > cardIndex ? (ruleOrder - 1).toString() : ruleOrder.toString(),
      };
    });
    updateFormField("rules", updatedRules);
    setShowRuleCards(updatedCardsShown);
  };

  const isValid = value => !R.isNil(value) && !R.isEmpty(value);

  drop(drag(preview(ref)));

  return (
    <div
      ref={ref}
      className={`ruleCard ${reorderEnabled ? `reorderable ${isDragging ? "isDragging" : isOver ? "isUnder" : ""}` : ""
        }`}
      id={id}
    >
      {reorderEnabled ? (
        <div className="ruleTitle">
          <div className="ruleIndex">{Number(data.rule_order) + 1}</div>
          <span className="ruleName">{data.rule_name}</span>
        </div>
      ) : (
        <>
          <div className="ruleHeader">
            <div className="ruleTitle">
              <div className="ruleIndex">{Number(data.rule_order) + 1}</div>
              <span className="ruleName">{data.rule_name}</span>
            </div>
            <div className="ruleToolbar">
              <Button
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                icon={show ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
                onClick={e => {
                  e.preventDefault();
                  hideCard(index);
                }}
              />
              {(wtoFormObj.status.toLocaleLowerCase() === "production" ||
                wtoFormObj.status.toLocaleLowerCase() === "draft") && (
                  <Dropdown className="actionsDropdown">
                    <Dropdown.Toggle as={ThreeDotsButton} />
                    <Dropdown.Menu className="actionsMenu">
                      <Dropdown.Item key="edit" eventKey="edit" onSelect={() => ruleModal(cardIndex)}>
                        <MdEdit />
                        Edit
                      </Dropdown.Item>
                      {wtoFormObj.status.toLocaleLowerCase() === "draft" && (
                        <>
                          <Dropdown.Item
                            key="duplicate"
                            eventKey="duplicate"
                            onSelect={() => setDuplicateRuleIndex(index)}
                          >
                            <BiSolidDuplicate />
                            Duplicate
                          </Dropdown.Item>
                          <Dropdown.Item key="delete" eventKey="delete" onSelect={deleteRule}>
                            <MdDelete />
                            Delete
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </div>
          </div>
          {show && (
            <div className="ruleSpecifications">
              <div className="ruleSpecification">
                <div className="ruleSpecBody">
                  <div className="ruleSpecRow">
                    <div className="operator">SPLIT</div>
                    <div className="ruleSpecCol">
                      {data.variant_splits.map((variantSplit, index) => (
                        <div className="ruleSpecRow" key={index}>
                          <div className="ruleSpecCol plover">
                            {variantSplit.variant_id}
                            <span className="lightText">
                              {getVariantUrl(variantSplit.variant_id)}
                            </span>
                          </div>
                          <div className="ploverPercentage">{variantSplit.split}%</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {isValid(data.customRetention) && (
                    <div className="ruleSpecRow retention">
                      <Form.Check
                        inline
                        label="Reset Variant Retention"
                        type="checkbox"
                        checked={true}
                        readOnly={true}
                      />
                      <div className="ruleSpecCol timestamp">
                        Start Date & Time:
                        <span className="lightText">{data.customRetention.formattedStartDate}</span>
                      </div>
                      <div className="ruleSpecCol timezone">
                        Timezone:
                        <span className="lightText">{data.customRetention.time_zone}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(isValid(data.conditions) || data.timeframe_enabled) && (
                <div className="ruleSpecification">
                  <span className="ruleSpecTitle">Conditions</span>
                  <hr />
                  <div className="ruleSpecBody">
                    {isValid(data.conditions) &&
                      data.conditions.map((condition: any, index) => (
                        <div className="ruleSpecRow" key={index}>
                          <div className="operator">{condition.operator}</div>
                          <div className="trigger">{condition.trigger}</div>
                          <div className="operator rule">{condition.rule}</div>
                          <div className="value">{condition.value}</div>
                        </div>
                      ))}
                    {data.timeframe_enabled && (
                      <div className="ruleSpecRow">
                        <div className="operator">AND</div>
                        <div className="time-frame-card">
                          <div>
                            <div>
                              <span className="time-frame-header">Start: </span>
                              <span className="time-frame-value">
                                {data.timeframe.formattedStartDateTime}
                              </span>
                            </div>
                            <div>
                              <span className="time-frame-header">End: </span>
                              <span className="time-frame-value">
                                {data.timeframe.formattedEndDateTime}
                              </span>
                            </div>
                          </div>
                          <span className="time-frame-header">Timezone: </span>
                          <span className="time-frame-value">{data.timeframe.time_zone}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isValid(data.additional_utm) &&
                data.additional_utm.map((utm: any, inputIndex: number) => (
                  <div key={inputIndex}>
                    {isValid(utm.additional_utm_operation) && isValid(utm.additional_utm_url) && (
                      <div className="ruleSpecification">
                        <span className="ruleSpecTitle">URL for UTM Parameters</span>
                        <hr />
                        <div className="ruleSpecBody">
                          {utm.additional_utm_url.map((variant, variantIndex) => (
                            <div key={variantIndex} className="ruleSpecRow utmVariants">
                              <div className="utmVariantId">{variant.utm_variant_id}</div>
                              <div className="lightText">{variant.utm_variant_url}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {isValid(utm.additional_utm_operation) && (
                      <div className="ruleSpecification">
                        {isValid(utm.additional_utm_url) && (
                          <>
                            <span className="ruleSpecTitle">URL Parameter Operations</span>
                            <hr />
                          </>
                        )}
                        <div className="ruleSpecBody">
                          <div className="ruleSpecRow">
                            <div className="operator">ALSO</div>
                            <div className="ruleSpecCol">
                              {utm.additional_utm_operation?.map((utmData: any, idx: any) => (
                                <div key={idx} className="ruleSpecRow">
                                  <div className="operationName">{utmData.operation}:</div>
                                  {utmData.operation !== "Replace" ? (
                                    <div className="lightText">
                                      {utmData.utm_key}
                                      {utmData.utm_value && "="}
                                      {utmData.utm_value}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="lightText">
                                        {utmData.utm_key}
                                        {utmData.utm_value && "="}
                                        {utmData.utm_value}
                                      </div>
                                      <div className="iconWrapper">
                                        <MdArrowCircleRight />
                                      </div>
                                      <div className="lightText">
                                        {utmData.replace_key}
                                        {utmData.replace_value && "="}
                                        {utmData.replace_value}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {isValid(data.cookie_operation_url) && (
                <div className="ruleSpecification">
                  <span className="ruleSpecTitle">URL for Cookie Updates</span>
                  <hr />
                  <div className="ruleSpecBody">
                    {data.cookie_operation_url.map((cookie: any, index) => (
                      <div className="ruleSpecRow" key={index}>
                        <div className="ruleSpecCol">{cookie.cookie_variant_id}</div>
                        <div className="ruleSpecCol lightText">{cookie.cookie_variant_url}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {isValid(data.cookie_operation) && (
                <div className="ruleSpecification">
                  <span className="ruleSpecTitle">Cookie Operations</span>
                  <hr />
                  <div className="ruleSpecBody">
                    <div className="ruleSpecRow">
                      <div className="operator">ALSO</div>
                      <div className="ruleSpecCol">
                        {data.cookie_operation?.map((cookieData: any, idx: any) => (
                          <div key={idx} className="ruleSpecRow">
                            <div className="operationName">{cookieData.operation}:</div>
                            {cookieData.operation !== "Replace" ? (
                              <div className="lightText">
                                {cookieData.cookie_key}
                                {cookieData.cookie_value && "="}
                                {cookieData.cookie_value}
                              </div>
                            ) : (
                              <>
                                <div className="lightText">
                                  {cookieData.cookie_key}
                                  {cookieData.cookie_value && "="}
                                  {cookieData.cookie_value}
                                </div>
                                <div className="iconWrapper">
                                  <MdArrowCircleRight />
                                </div>
                                <div className="lightText">
                                  {cookieData.cki_replace_key}
                                  {cookieData.cki_replace_value && "="}
                                  {cookieData.cki_replace_value}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isValid(data.dow_operation) && (
                <div className="ruleSpecification">
                  <span className="ruleSpecTitle">Day of the Week</span>
                  <hr />
                  <div className="ruleSpecBody">
                    {data.dow_operation.map((operation: any, index) => (
                      <div className="ruleSpecRow" key={index}>
                        <div className="days">
                          {DAYS.map((day, dayIndex) => (
                            <span
                              key={dayIndex}
                              className={R.includes(day.number, operation.day) ? "selected" : ""}
                            >
                              {day.label}
                            </span>
                          ))}
                        </div>
                        <div className="ruleSpecRow dowDetails">
                          {isValid(operation.formattedEndTime) &&
                            isValid(operation.formattedStartTime) && (
                              <div className="ruleSpecCol timeRange">
                                <div>
                                  <span>Start:</span>
                                  <span className="timeOfDay lightText">
                                    {operation.formattedStartTime.split(" ")[1]}
                                  </span>
                                </div>
                                <div>
                                  <span>End:</span>
                                  <span className="timeOfDay lightText">
                                    {operation.formattedEndTime.split(" ")[1]}
                                  </span>
                                </div>
                              </div>
                            )}
                          <div className="ruleSpecCol dowName">
                            {operation.variants.map((variant, index) => (
                              <div className="ruleSpecRow dowVariant" key={index}>
                                <div className="ruleSpecCol dowName">
                                  <div className="ruleSpecRow">{variant.name}</div>
                                  <div className="ruleSpecRow lightText">{variant.url}</div>
                                </div>
                                <div className="ruleSpecCol dowPercentage">{variant.split}%</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WtoRuleCard;
