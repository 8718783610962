import "../IncrementalityPlaybook.scss";
import { IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { playbookData, playbookTable } from "../PlaybookTable";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Social";

const Social = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="mainTable">
            {playbookTable(
              R.filter(row => row.Channel[0].value === "Social", playbookData as any[])
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
    </div>
  );
});

export default Social;
