import { LINEAR_OVERVIEW_CONFIG } from "../LinearPerformance/linearPerformanceUtils";
import { Preset, PresetChanges } from "@blisspointmedia/bpm-types/dist/Performance";
import { StateSetter } from "../../utils/types";
import { STREAMING_OVERVIEW_CONFIG } from "../StreamingPerformance/streamingPerformanceUtils";
import { YOUTUBE_OVERVIEW_CONFIG } from "../YouTubePerformance/youtubePerformanceUtils";
import * as R from "ramda";
import OverviewToggle from "./OverviewToggle";
import React from "react";

interface OverviewMetricsConfigProps<P extends Preset> {
  isLinear?: boolean;
  isYoutube?: boolean;
  presetChanges: PresetChanges<P>;
  setPresetChanges: StateSetter<PresetChanges<P>>;
}

const OverviewMetricsConfig = <P extends Preset>({
  isLinear,
  isYoutube,
  presetChanges,
  setPresetChanges,
}: OverviewMetricsConfigProps<P>): JSX.Element => {
  const OVERVIEW_CONFIG = isYoutube
    ? YOUTUBE_OVERVIEW_CONFIG
    : isLinear
    ? LINEAR_OVERVIEW_CONFIG
    : STREAMING_OVERVIEW_CONFIG;

  return (
    <div className="editControls overviewConfig">
      <div className="bigLabel">Overview Metrics: </div>
      {R.keys(OVERVIEW_CONFIG).map(key => (
        <OverviewToggle
          key={key}
          overviewConfigInfo={OVERVIEW_CONFIG[key]}
          checked={(presetChanges.overviewConfig || {})[key]}
          onCheck={checked =>
            setPresetChanges(changes => ({
              ...changes,
              overviewConfig: {
                ...(changes.overviewConfig || {}),
                [key]: checked,
              },
            }))
          }
        />
      ))}
    </div>
  );
};

export default OverviewMetricsConfig;
