import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import * as UserRedux from "../../redux/user";
import { useSetError } from "../../redux/modals";
import OnboardingFormPage from "../OnboardingFormPage";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { questions } from "./DataQuestions";
import { saveClientInfo, getCompletedPercentage } from "../OnboardingUtils";

enum DataTabs {
  DATA_MEASUREMENT = "data-measurement",
}

interface DataProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
}

const Data: React.FC<DataProps> = ({ clientInfo, onPercentageChange }) => {
  const [initialResponses, setInitialResponses] = useState<Response[]>(clientInfo.responses);
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [saveSubmitting, setSaveSubmitting] = useState<boolean>(false);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);

  useEffect(() => {
    setSaveSubmitting(initialResponses !== allResponses.responses);
  }, [initialResponses, allResponses]);

  const [completedPercentage, setCompletedPercentage] = useState<number>(getCompletedPercentage(allResponses.responses, questions.length, "6"));

  const handleDataMeasurementChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
  };

  useEffect(() => {
    const newPercentage = getCompletedPercentage(allResponses.responses, questions.length, "6");
    setCompletedPercentage(newPercentage);
    onPercentageChange(newPercentage);
  }, [allResponses, onPercentageChange]);

  const renderTabs = [
    {
      label: "Data and Measurement Durability",
      headerLabel: "A. Data and Measurement Durability",
      key: DataTabs.DATA_MEASUREMENT,
      percentage: completedPercentage,
      children: <QuestionOnlyForm responses={clientInfo.responses} questions={questions} onChange={handleDataMeasurementChange} />,
    },
  ];

  const handleSave = async () => {
    try {
      await saveClientInfo({ company: allResponses.company, responses: allResponses.responses, user });
      setInitialResponses(allResponses.responses);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={DataTabs.DATA_MEASUREMENT}
      tabs={Object.values(DataTabs)}
      saveSubmitting={saveSubmitting}
      onSave={handleSave}
    />
  );
};

export default Data;
