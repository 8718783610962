import React from "react";
import moment from "moment";
import { Form } from "react-bootstrap";
import { BPMDateRange, Dropdown, DropdownToggleType, BPMToggleButton } from "../../Components";
import { DateRange } from "../../utils/types";
import { tmrKpiPull, tmrRetailerFilterOptions, PROFILE_FILTER_MESSAGING } from "./utils";
import { SharedState, SlideState } from "../slideTemplateConstants";
import { SettingsComponentProps, SlideContext, SlideType } from "../slidesTypes";
import { fetchBiggestMoverSlideData } from "./QueryServices";

export interface MarketplaceReportBiggestMoverSlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  retailer: string;
  isSpend: boolean;
  dollarMin: number;
  companyProfile: string;
  kpi: string;
}

interface MarketplaceReportBiggestMoverSlideData {
  title: string;
  subTitle: string;
}

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

class MarketplaceReportBiggestMoverSlide extends SlideType {
  static typeKey = "TmrBiggestMoverSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportBiggestMoverSlideState = {
    title: "Biggest Movers",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    retailer: "null",
    isSpend: true,
    dollarMin: 0,
    companyProfile: PROFILE_FILTER_MESSAGING,
    kpi: "acos",
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportBiggestMoverSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, dates, dollarMin, isSpend, kpi, companyProfile } = state;
    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
       <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
          <div>
            <Form.Label>Minimum Filter</Form.Label>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Spend",
                },
                {
                  key: "Sales",
                },
              ]}
              selectedOption={isSpend ? "Spend" : "Sales"}
              onChange={key => setState({ isSpend: key === "Spend" })}
            />
          </div>
          <div>
            <Form.Label>Minimum ($)</Form.Label>
            <Form.Control
              type="number"
              value={dollarMin}
              onChange={e => setState({ dollarMin: Number(e.currentTarget.value) })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState
  ): Promise<MarketplaceReportBiggestMoverSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      retailer,
      dates,
      isSpend,
      dollarMin,
      companyProfile,
      kpi,
    } = state as MarketplaceReportBiggestMoverSlideState;

    let filterField = isSpend ? "spend" : "sales";
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    let data = await fetchBiggestMoverSlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      filterField,
      dollarMin,
      profileNames
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      data,
      retailer,
      kpi,
    });
  };
}
export default MarketplaceReportBiggestMoverSlide;
