import "./SiteMonitoring.scss";
import "../Components/BreadcrumbTitle.scss";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Page,
  FullPageSpinner,
  Button,
  ButtonType,
  BPMTable,
  InfoTooltip,
  ThreeDotsButton,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { HiOutlinePlus } from "react-icons/hi";
import { Modal, Col, DropdownButton, Dropdown, Form } from "react-bootstrap";
import { awaitJSON, SiteMonitoringLambdaFetch } from "../utils/fetch-utils";
import { emailSelector } from "../redux/user";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { ConfigurationRow } from "@blisspointmedia/bpm-types/dist/SiteMonitoring";

const INNER_CELL_HEIGHT = 70;

const WIDTHS = {
  COMPANY: 300,
  PAGE_URL: 350,
  FREQUENCY: 200,
  SLACK_CHANNEL: 150,
  DRI: 200,
  ACTIONS: 100,
};

const VALID_URL_REGEXP = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/;
const FREQUENCIES = ["30 Min", "Hourly", "Daily"];
const TAB_OPTIONS = ["View Site Monitors", "Create Site Monitor", "Update Site Monitor"];

const SiteMonitoring = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<typeof TAB_OPTIONS[number]>(TAB_OPTIONS[0]);
  const [frequency, setFrequency] = useState<string>(FREQUENCIES[0]);
  const [pageUrl, setPageUrl] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [slackChannel, setSlackChannel] = useState<string>("");
  const [dri, setDri] = useState<string>("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [configurations, setConfigurations] = useState<ConfigurationRow[]>([]);
  const [currentConfiguration, setCurrentConfiguration] = useState<ConfigurationRow>();

  const setError = useSetError();

  const userEmail = useSelector(emailSelector);

  useEffect(() => {
    const fetcher = async () => {
      try {
        let res = await SiteMonitoringLambdaFetch("/configurations");
        let { data } = await awaitJSON(res);
        setConfigurations(data);
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to fetch site monitor configurations. Error: ${error.message}`,
        });
      }
      setLoading(false);
    };
    fetcher();
  }, [setError]);

  useEffect(() => {
    if (currentTab !== "Update Site Monitor") {
      setCompany("");
      setSlackChannel("");
      setDri("");
      setFrequency(FREQUENCIES[0]);
      setPageUrl("");
    }
  }, [currentTab]);

  const onSelectUpdate = useCallback(data => {
    setCurrentConfiguration(data);
    setCompany(data.company);
    setSlackChannel(R.defaultTo("", data.slack_channel));
    setDri(R.defaultTo("", data.dri));
    setFrequency(R.defaultTo("30 Min", data.frequency));
    setPageUrl(R.defaultTo("", data.page_url));
    setCurrentTab("Update Site Monitor");
  }, []);

  const onSelectDelete = useCallback(data => {
    setCurrentConfiguration(data);
    setShowDeleteConfirmation(true);
  }, []);

  const deleteConfiguration = useCallback(
    async id => {
      setShowDeleteConfirmation(false);
      setLoading(true);
      try {
        let res = await SiteMonitoringLambdaFetch(`/configurations/${id}`, {
          method: "DELETE",
        });
        let { data } = await awaitJSON(res);
        if (data.id === id && data.is_delete === true) {
          const index = R.findIndex(R.propEq("id", id), configurations);
          const updatedConfigurations = R.remove(index, 1, configurations);
          setConfigurations(updatedConfigurations);
        }
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to delete site monitor configuration. Error: ${error.message}`,
        });
      }
      setLoading(false);
    },
    [configurations, setError]
  );

  const updateConfiguration = useCallback(
    async id => {
      if (!VALID_URL_REGEXP.test(pageUrl)) {
        setError({
          message: "Please enter a valid page URL, including HTTPS protocol",
        });
      } else {
        setLoading(true);
        setCurrentTab("View Site Monitors");
        try {
          let res = await SiteMonitoringLambdaFetch(`/configurations/${id}`, {
            method: "PATCH",
            body: {
              page_url: pageUrl,
              slack_channel: slackChannel,
              dri,
              frequency,
            },
            params: {
              email: userEmail,
            },
          });
          const { data } = await awaitJSON(res);
          if (data.id === id && data.is_delete === false) {
            const index = R.findIndex(R.propEq("id", id), configurations) + 1;
            let updatedConfigurations = [...configurations];
            updatedConfigurations.unshift(data);
            updatedConfigurations = R.remove(index, 1, updatedConfigurations);
            setConfigurations(updatedConfigurations);
          }
        } catch (e) {
          let error = e as Error;
          setError({
            message: `Failed to update site monitor configuration. Error: ${error.message}`,
          });
        }
        setLoading(false);
      }
    },
    [configurations, dri, frequency, pageUrl, setError, slackChannel, userEmail]
  );

  const createConfiguration = useCallback(async () => {
    if (!VALID_URL_REGEXP.test(pageUrl)) {
      setError({
        message: "Please enter a valid page URL, including HTTPS protocol",
      });
    } else {
      setLoading(true);
      setCurrentTab("View Site Monitors");
      try {
        const res = await SiteMonitoringLambdaFetch("/configurations", {
          method: "POST",
          body: {
            company,
            page_url: pageUrl,
            slack_channel: slackChannel,
            dri,
            frequency,
          },
          params: {
            email: userEmail,
          },
        });
        const { data } = await awaitJSON(res);
        if (data.is_delete === false) {
          let updatedConfigurations = [...configurations];
          updatedConfigurations.unshift(data);
          setConfigurations(updatedConfigurations);
        }
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to create site monitor configuration. Error: ${error.message}`,
        });
      }
      setLoading(false);
    }
  }, [company, configurations, dri, frequency, pageUrl, setError, slackChannel, userEmail]);

  const actionsRenderer = data => {
    return (
      <Dropdown className="actionsDropdown">
        <Dropdown.Toggle as={ThreeDotsButton} />
        <Dropdown.Menu className="actionsMenu">
          <Dropdown.Item key="update" eventKey="update" onSelect={() => onSelectUpdate(data)}>
            Update
          </Dropdown.Item>
          <Dropdown.Item key="delete" eventKey="delete" onSelect={() => onSelectDelete(data)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const TableCell = ({ data }) => <div className="tableCell">{data}</div>;

  const configurationsHeaders = [
    {
      label: "Company",
      name: "company",
      type: "text",
      flex: true,
      minFlexWidth: 300,
      renderer: data => <TableCell data={data.company} />,
    },
    {
      label: "Page URL",
      name: "page_url",
      type: "text",
      flex: true,
      minFlexWidth: 350,
      renderer: data => <TableCell data={data.page_url} />,
    },
    {
      label: "Frequency",
      name: "frequency",
      type: "text",
      flex: true,
      minFlexWidth: 200,
      renderer: data => <TableCell data={data.frequency} />,
    },
    {
      label: "Slack Channel",
      name: "slack_channel",
      type: "text",
      flex: true,
      minFlexWidth: 150,
      renderer: data => <TableCell data={data.slack_channel} />,
    },
    {
      label: "DRI Slack Username",
      name: "dri",
      type: "text",
      flex: true,
      minFlexWidth: 200,
      renderer: data => <TableCell data={data.dri} />,
    },
    {
      label: "Actions",
      flex: true,
      minFlexWidth: WIDTHS.ACTIONS,
      renderer: actionsRenderer,
    },
  ];

  const breadCrumbsRenderer = () => {
    return (
      <div className="breadcrumbTitle">
        <div className="mainTitle" onClick={() => setCurrentTab("View Site Monitors")}>
          Site Monitoring
        </div>
        {currentTab === "Create Site Monitor" && <div className="crumb">Create Site Monitor</div>}
        {currentTab === "Update Site Monitor" && <div className="crumb">Update Site Monitor</div>}
      </div>
    );
  };

  return (
    <Page
      title={breadCrumbsRenderer()}
      pageType="Admin Functions"
      actions={
        <div className="siteMonitoringActions">
          {currentTab === "View Site Monitors" && !loading && (
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<HiOutlinePlus />}
              onClick={() => setCurrentTab("Create Site Monitor")}
            >
              Add Site Monitor
            </Button>
          )}
        </div>
      }
    >
      <div className="siteMonitoring">
        {loading ? (
          <FullPageSpinner />
        ) : currentTab === "Create Site Monitor" || currentTab === "Update Site Monitor" ? (
          <Form className="form">
            <Form.Row>
              <div className="helperText">
                <p>
                  • Please make sure Slack app <b>AMP-Portal Reporting Bot</b> is added to the
                  channel.
                </p>
                <p>
                  • Use the following{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/Adding-Reporting-Bot-7ffa196bfa0348e794a12a0eb7ae38ee?pvs=4"
                  >
                    Document
                  </a>{" "}
                  to learn how to add reporting app to the slack channel.
                </p>
              </div>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  value={company}
                  placeholder="Company"
                  disabled={currentTab === "Update Site Monitor"}
                  onChange={e => {
                    setCompany(e.currentTarget.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Page URL</Form.Label>
                <Form.Control
                  type="text"
                  value={pageUrl}
                  placeholder="Page URL"
                  onChange={e => {
                    setPageUrl(e.currentTarget.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Slack Channel Name</Form.Label>
                <Form.Control
                  type="text"
                  value={slackChannel}
                  placeholder="Slack Channel Name"
                  onChange={e => {
                    setSlackChannel(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <InfoTooltip>
                Add the '-' or '_' in between the Slack channel name, please do not include space
                and # in the Slack channel name.
              </InfoTooltip>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>DRI Slack Username</Form.Label>
                <Form.Control
                  type="text"
                  value={dri}
                  placeholder="DRI Slack Username"
                  onChange={e => {
                    setDri(e.currentTarget.value);
                  }}
                />
              </Form.Group>
              <InfoTooltip>
                DRI should include all analysts, leads, directors, and product managers working with
                a client. DRIs will be tagged in Slack message when an alert fires. You can find
                slack Username by navigating to your profile on Slack &gt; dropdown on right hand
                side &gt; copy display name. E.g. sarah.malone. <br />
                <br />
                Separate multiple Usernames with using commas.
              </InfoTooltip>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Frequency</Form.Label>
                <div className="frequencyContainer">
                  <DropdownButton className="frequencyDropdown" title={frequency}>
                    {FREQUENCIES.map(frequency => (
                      <Dropdown.Item
                        key={frequency}
                        eventKey={frequency}
                        onSelect={frequency => setFrequency(R.defaultTo("", frequency))}
                      >
                        {frequency}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <InfoTooltip>
                    Suggested frequency is 30 minutes. You may use a lower frequency for very low
                    impact pages in order to save marginally on cost of alerting.
                  </InfoTooltip>
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  type={ButtonType.FILLED}
                  onClick={() => setCurrentTab("View Site Monitors")}
                >
                  Cancel
                </Button>
                {currentTab === "Create Site Monitor" ? (
                  <Button
                    type={ButtonType.FILLED}
                    disabled={
                      R.isEmpty(company) ||
                      R.isEmpty(slackChannel) ||
                      R.isEmpty(frequency) ||
                      R.isEmpty(dri) ||
                      R.isEmpty(pageUrl)
                    }
                    onClick={e => {
                      createConfiguration();
                      e.preventDefault();
                    }}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    type={ButtonType.FILLED}
                    disabled={
                      currentConfiguration?.slack_channel === slackChannel &&
                      currentConfiguration?.frequency === frequency &&
                      currentConfiguration?.dri === dri &&
                      currentConfiguration?.page_url === pageUrl
                    }
                    onClick={e => {
                      updateConfiguration(currentConfiguration?.id);
                      e.preventDefault();
                    }}
                  >
                    Update
                  </Button>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        ) : (
          <BPMTable
            data={configurations}
            headers={configurationsHeaders}
            noRowsRenderer={() => <div className="noConfigurations">No data to show</div>}
            rowHeight={INNER_CELL_HEIGHT}
            pagination={true}
          />
        )}

        <Modal show={showDeleteConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you really want to remove this record? Reactivating a deleted record is not possible.
            <br />
            <br /> Contains page URL -
            <b> {currentConfiguration ? currentConfiguration.page_url : ""} </b>
          </Modal.Body>
          <Modal.Footer>
            <Button type={ButtonType.FILLED} onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </Button>
            <Button
              type={ButtonType.FILLED}
              onClick={() => deleteConfiguration(currentConfiguration?.id)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Page>
  );
};

export default SiteMonitoring;
