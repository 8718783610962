import gql from "graphql-tag";

export const GET_AMC_REPORT_TYPES = gql`
  query GetAMCInsights($amcInstanceId: String) {
    amc {
      reports(amcInstanceId: $amcInstanceId) {
        amcInstanceId
        reportType
      }
    }
  }
`;

export const GET_MEDIA_MIX = gql`
  query getMediaMix($amcInstanceId: String, $dateRange: DateRangeInput!) {
    amc {
      mediaMixDataReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        availableFilters {
          months
        }
        newToBrandShoppers {
          graphData {
            avgOrderSize
            date
            purchased
            reach
          }
          tableData {
            avgOrderSize
            reach
            purchased
            purchaseRate
            mediaType
          }
        }
        prospectiveShoppers {
          graphData {
            avgOrderSize
            date
            purchased
            reach
          }
          tableData {
            avgOrderSize
            reach
            purchased
            purchaseRate
            mediaType
          }
        }
      }
    }
  }
`;

export const GET_NTB_REPORT = gql`
  query getNTBReport($amcInstanceId: String, $dateRange: DateRangeInput!) {
    amc {
      newToBrandReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        availableFilters {
          months
        }
        queryDuration {
          startDate
          endDate
          duration
        }
        overview {
          cost {
            cpaAll
            cpaNtb
            ntbCustomers
            spend
          }
          revenue {
            spend
            ntbSales
            roasAll
            ntbRoas
          }
        }
        overviewGraph {
          date
          ntbCpa
          ntbRoas
        }
        ntbVsRepeatBehavior {
          customers {
            month
            ntbCustomers
            ntbRepeatCustomers
          }
          purchases {
            month
            ntbCustomers
            repeatCustomers
          }
        }
        behaviorByGroup {
          customersConverted
          trackedAsin
          ntbRate
          ntbCustomers
          ntbPurchases
          ntbRepeat
          ntbRepeatRate
          purchases
        }
      }
    }
  }
`;

export const GET_GEO_REPORT = gql`
  query GeoQuery($amcInstanceId: String, $dateRange: DateRangeInput!, $state: String) {
    amc {
      geoReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange, state: $state) {
        availableFilters {
          months
        }
        table: data {
          state
          impressions
          reach
          dpv
          customersConverted
          orders
          revenue
          ntbCustomers
          dpvRate
          conversionRate
          ntbRepeatRate
          ntbPurchaseRate
        }
        tableGrandTotal: total {
          state
          impressions
          reach
          dpv
          customersConverted
          orders
          revenue
          ntbCustomers
          dpvRate
          conversionRate
          ntbRepeatRate
          ntbPurchaseRate
        }
        stateMap: data {
          state
          impressions
        }
      }
    }
  }
`;

export const GET_PA_FILTERS = gql`
  query PathingAnalysisFilters($amcInstanceId: String!, $dateRange: DateRangeInput!) {
    amc {
      pathingAnalysisReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        availableFilters {
          granularity
          monthlyStartDates
          weeklyStartDates
        }
      }
    }
  }
`;

export const GET_PA_TOUCHPOINT_DATA = gql`
  query PathingAnalysisReport($amcInstanceId: String!, $dateRange: DateRangeInput!) {
    amc {
      pathingAnalysisReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        adTypeTouchpointData {
          adType
          amcInstanceId
          impressions
          newToBrandTotalProductSales
          newToBrandTotalPurchases
          ntbUser
          reach
          reportDate
          reportEndDate
          reportStartDate
          totalProductSales
          totalPurchases
          touchpoint
          userThatPurchased
        }
      }
    }
  }
`;

export const GET_PA_OCCURENCE_DATA = gql`
  query PathingAnalysisOccurence($amcInstanceId: String!, $dateRange: DateRangeInput!) {
    amc {
      pathingAnalysisReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        campaignAndAdTypeOccuranceData {
          amcInstanceId
          campaignAndAdType
          dimensionLevel
          impressions
          reach
          reportDate
          reportEndDate
          reportStartDate
          totalCost
          totalPurchases
          userThatPurchased
        }
      }
    }
  }
`;

export const GET_PA_PATH_DATA = gql`
  query PathingAnalysisPath($amcInstanceId: String!, $dateRange: DateRangeInput!) {
    amc {
      pathingAnalysisReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        campaignAndAdTypePathData {
          impressions
          path
          pathType
          reach
          totalCost
          totalProductSales
          totalPurchases
          userThatPurchased
        }
      }
    }
  }
`;

export const GET_PA_COMBINED_DATA = gql`
  query PathingAnalysisCombined($amcInstanceId: String!, $dateRange: DateRangeInput!) {
    amc {
      pathingAnalysisReport(amcInstanceId: $amcInstanceId, dateRange: $dateRange) {
        firstLastLinearAndPositionCombinedData {
          amcInstanceId
          attributionModel
          campaign
          clicks
          conversions
          impressions
          reportDate
          reportEndDate
          reportStartDate
          spend
          totalProductSales
          totalUnitsSold
          userReach
          usersThatConverted
        }
      }
    }
  }
`;
