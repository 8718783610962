import * as R from "ramda";
import { formatPacingNumber } from "../utils/format-utils";

export const DEFAULT_WEEKS_BACK = 12;
export const DATE_FORMAT = "yyyy-MM-dd";
export const GROSS_LABEL = "Gross";
export const NET_LABEL = "Net";
export const ACCEPTED_LABEL = "Accepted";
export const ALL_LABEL = "All";

export const COMPANY_DIMENSION = "company";
export const COMPANY_LABEL = "Company";
export const NETWORK_DIMENSION = "network";
export const NETWORK_LABEL = "Network";

export const createSeriesData = (groupingKey, data, range) => {
  const { data: respData } = data;
  let groupedByKey = R.groupBy(R.prop(groupingKey), respData);
  const bySpend = R.descend(R.prop("sum"));

  // gives us the networks sorted by gross booked
  const groupedBySpend = R.pipe(
    R.keys,
    R.map(key => {
      let group = groupedByKey[key];
      let groupSum = R.pipe(R.pluck("spend_expected"), R.sum)(group);

      return { [groupingKey]: key, sum: groupSum };
    }),
    R.sort(bySpend)
  )(groupedByKey);

  groupedByKey = R.map(group => {
    if (range.length === 0) {
      return group;
    }

    // make sure that the clearance rate is out of 100
    group = group.map(item => {
      item.clearance_rate = Math.floor(item.clearance_rate * 100);
      item.rate = Math.floor(item.rate * 100);
      return item;
    });

    let newList = [];
    let rangeIdx = 0;
    let groupIdx = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (groupIdx >= group.length && rangeIdx >= range.length) {
        break;
      }

      let groupHead = group[groupIdx];
      let rangeHead = range[rangeIdx];

      if (groupIdx >= group.length) {
        let toAdd = {
          ...groupHead,
          week: range[rangeIdx],
          clearance_rate: null,
          count_actual: 0,
          count_expected: 0,
          spend_actual: 0,
          spend_expected: 0,
        };

        newList.push(toAdd);
        rangeIdx++;
        continue;
      }

      if (rangeIdx >= range.length) {
        newList.push(group[groupIdx]);
        rangeIdx++;
        groupIdx++;
        continue;
      }

      if (rangeHead !== groupHead.week) {
        let toAdd = {
          ...groupHead,
          week: rangeHead,
          clearance_rate: null,
          count_actual: 0,
          count_expected: 0,
          spend_actual: 0,
          spend_expected: 0,
        };

        newList.push(toAdd);
        rangeIdx++;
        continue;
      } else {
        newList.push(groupHead);
        rangeIdx++;
        groupIdx++;
        continue;
      }
    }

    return newList;
  }, groupedByKey);

  let spendMap = {};
  let clearanceMap = {};

  // eslint-disable-next-line guard-for-in
  for (let key in groupedByKey) {
    spendMap[key] = R.pipe(R.pluck("spend_expected"), R.sum, v => formatPacingNumber(v, "$"))(
      groupedByKey[key]
    );
    clearanceMap[key] = `${(
      (100 * R.pipe(R.pluck("spend_actual"), R.sum)(groupedByKey[key])) /
      R.pipe(R.pluck("spend_expected"), R.sum)(groupedByKey[key])
    ).toFixed(0)}%`;
  }

  const keyOrder = R.pluck(groupingKey, groupedBySpend);

  return {
    keyOrder,
    groupedByKey,
    spendMap,
    clearanceMap,
  };
};
