import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdSave } from "react-icons/md";
import { Response, ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../../utils/hooks/useLocation";
import { useSetError } from "../../redux/modals";
import * as UserRedux from "../../redux/user";
import WidgetContainer from "../../Components/WidgetContainer";
import { Button, ButtonType } from "../../Components";
import { PLATFORM_OPTIONS, PlatformOptions } from "./PlatformChecklistOptions";
import OnboardingFreeForm from "../OnboardingFreeForm";
import { saveClientInfo } from "../OnboardingUtils";

interface PlatformChecklistProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
}

const getCompletedPercentage = (responses: Response[], platformOptions: PlatformOptions) => {
  const totalSections = Object.keys(platformOptions).length;
  if (!responses || responses.length === 0) {
    return 0;
  }

  const completedSections = Object.keys(platformOptions).filter(sectionKey => {
    return responses.some(response =>
      response.questionNumber.startsWith(platformOptions[sectionKey].simpleId)
    );
  }).length;

  return Math.round((completedSections / totalSections) * 100);
};

const PlatformChecklist: React.FC<PlatformChecklistProps> = ({
  clientInfo,
  onPercentageChange,
}) => {
  const { company } = useLocation();
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);

  useEffect(() => {
    const totalPercentage = getCompletedPercentage(allResponses.responses, PLATFORM_OPTIONS);
    onPercentageChange(totalPercentage);
  }, [allResponses.responses, onPercentageChange]);

  const handlePlatformChecklistChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      company,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
  };

  const platformForm = (key: string, option) => {
    return (
      <div>
        <OnboardingFreeForm
          questions={option.table}
          responses={allResponses.responses}
          // renderAddButton
          onDataChange={handlePlatformChecklistChange}
        />
      </div>
    );
  };

  const handleSave = async () => {
    try {
      await saveClientInfo({
        company: allResponses.company,
        responses: allResponses.responses,
        user,
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>
        This is the platform / source checklist so we know which platforms and sources to populate
        later on in these pages.
      </h2>
      {Object.keys(PLATFORM_OPTIONS).map(key => {
        return (
          <WidgetContainer
            key={key}
            backgroundColor="white"
            header={PLATFORM_OPTIONS[key].title}
            design="secondary"
            collapsible
            startOpen={false}
            rightActions={
              <Button type={ButtonType.FILLED} icon={<MdSave />} onClick={handleSave} />
            }
          >
            {platformForm(key, PLATFORM_OPTIONS[key])}
          </WidgetContainer>
        );
      })}
    </div>
  );
};

export default PlatformChecklist;
