const DEFAULT_AT_ONCE = 4;

export const artificialWait = (time: number): Promise<void> =>
  new Promise(resolve => setTimeout(() => resolve(), time));

// Like Promise.all, but it only dispatches a few at a time. This is a thread-pool queueing design pattern.
export const staggeredAll = (
  promises: (() => Promise<any>)[],
  atATime = DEFAULT_AT_ONCE
): Promise<any[]> =>
  new Promise(resolveItAll => {
    let results: any[] = [];
    let promiseIndex = 0;
    let completedCount = 0;

    if (!promises.length) {
      resolveItAll([]);
    }

    const makePromise = async index => {
      let promise = promises[index];
      let res = await promise();

      results[index] = res;
      if (++completedCount === promises.length) {
        resolveItAll(results);
      } else if (promiseIndex < promises.length) {
        makePromise(promiseIndex++);
      }
    };
    for (let i = 0; i < atATime; ++i) {
      if (promiseIndex >= promises.length) {
        break;
      }
      makePromise(promiseIndex++);
    }
  });
