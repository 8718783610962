import "./BusinessImpactOverview.scss";
import React, { useCallback, useEffect, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import Papa from "papaparse";
import AreaChart from "../../Components/Charts/AreaChart";
import LineChart from "../../Components/Charts/LineChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG, downloadJSONToCSV } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import { capitalizeWords, currencyFormatter, numberFormatter } from "../../MMM/MMMUtils";
import {
  groupDataByCadence,
  standardizeMetricName,
  standardizeMetricNameOriginal,
} from "../BrandImpact/BrandImpactUtils";
import { Channel1, Channel2, Channel3, Channel4 } from "../../utils/colors";

interface OverviewProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

export const GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC = [
  { label: "Ad Awareness", value: "ad_awareness" },
  { label: "Awareness", value: "awareness" },
  { label: "Buzz", value: "buzz" },
  { label: "Consideration", value: "consideration" },
];

interface OverviewData {
  additional_spend_per_1_perc: string;
  average_metric: number;
  average_spend: number;
  dcmp_rssd: number;
  iterations: number;
  metric: string;
  nrmse: number;
  pm_contribution: number;
  rsq: number;
  solution: string;
  trials: number;
}

interface LineChartData {
  date: string;
  ad_awareness: number;
  awareness: number;
  buzz: number;
  consideration: number;
}

export const Overview: React.FC<OverviewProps> = ({ company, groupByMetric }) => {
  const [weeklySpendAreaData, setWeeklySpendAreaData] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState<any[]>([]);
  const [overviewData, setOverviewData] = useState<OverviewData[]>([]);

  useEffect(() => {
    const fetchLineChart = async () => {
      try {
        let lineChartResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/revenue/raw_data.csv"
        );
        const lineChartText = await lineChartResponse.text();
        const { data: lineChartParsed }: Papa.ParseResult<any> = Papa.parse(lineChartText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });

        const lineChartFixed: LineChartData[] = lineChartParsed.map((item: any) => ({
          date: item.week,
          ad_awareness: item.ad_awareness_p,
          awareness: item.awareness_p,
          buzz: item.buzz_p,
          consideration: item.consideration_p,
        }));
        setLineChartData(lineChartFixed);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    const fetchModelOverviewData = async () => {
      try {
        let overviewResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/stage_1_topline.csv"
        );
        const overviewText = await overviewResponse.text();
        const { data: overviewParsed }: Papa.ParseResult<any> = Papa.parse(overviewText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });

        const filteredData = overviewParsed.filter(
          item => item.metric === standardizeMetricNameOriginal(groupByMetric)
        );
        setOverviewData(filteredData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    const fetchAreaChartData = async () => {
      try {
        let areaTimeSeriesResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/data/REV_elf.csv"
        );
        const areaTimeSeriesText = await areaTimeSeriesResponse.text();
        const { data: areaTimeSeriesParsed }: Papa.ParseResult<any> = Papa.parse(
          areaTimeSeriesText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );
        const dataFixed = areaTimeSeriesParsed.map((item: any) => ({
          date: item.date,
          revenue: item.revenue,
        }));
        const sortedAreaChartData = dataFixed.sort((a, b) => a.date.localeCompare(b.date));
        const groupedData = groupDataByCadence(sortedAreaChartData, "Week");
        setWeeklySpendAreaData(groupedData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };
    fetchModelOverviewData();
    fetchAreaChartData();
    fetchLineChart();
  }, [groupByMetric]);

  const excelDownloadWeeklyBrandHealth = useCallback(() => {
    exportToExcel(lineChartData, "weekly_brand_health");
  }, [lineChartData]);

  const excelDownloadWeeklySpend = useCallback(() => {
    exportToExcel(weeklySpendAreaData, "weekly_spend");
  }, [weeklySpendAreaData]);

  const excelDownloadModelOverview = useCallback(() => {
    exportToExcel(overviewData, "model_overview");
  }, [overviewData]);

  const pngDownloadWeeklyBrandHealth = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents", "weekly_brand_health");
  }, []);

  const pngDownloadWeeklySpend = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents .areaChart", "weekly_spend");
  }, []);

  const csvDownloadModelOverview = useCallback(() => {
    downloadJSONToCSV(overviewData, `${company}_model_overview`);
  }, [overviewData, company]);

  return (
    <WidgetContainer
      collapsible
      header={"Overview"}
      subHeader={
        <>
          Assessing the impact of brand equity on Revenue outcomes.
          <MoreInfo rightLabel="More info" size="sm">
            The goal is to understand and quantify the independent effect of paid media on a set of
            brand health outcomes. We employ a multivariate time series model that regresses each
            brand outcome on the set of paid media activities and a series of control variables. In
            the estimation, the model accounts for non-linearities in effects and the often
            significant timing differences between inputs and outputs.
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(overviewData) && !R.isEmpty(lineChartData) && !R.isEmpty(weeklySpendAreaData) && (
        <div className="businessImpactOverview">
          <div className="businessImpactOverviewleft">
            <ChartContainer
              enableHoverDesign
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadWeeklyBrandHealth, pngDownloadWeeklyBrandHealth]}
                />
              }
              title="Weekly Brand Health"
              titleAfterDashText="12/20/20 – 12/24/23"
              beforeTooltipText="Positive Response"
              tooltipText="Positive response indicates the percentage of survey respondents expressing favorable perceptions or experiences related to each brand health metric."
            >
              <LineChart
                customTooltipHeader={val => `Week of ${val}`}
                data={lineChartData}
                lineDataKeys={[
                  {
                    name: "Ad Awareness",
                    dataKey: "ad_awareness",
                    toolTipText:
                      "Respondents are asked to select from a list of brands which they have seen or heard advertising for recently. If they select your brand, that counts as a positive response.",
                  },
                  {
                    name: "Aided Awareness",
                    dataKey: "awareness",
                    toolTipText:
                      "Respondents are asked to select from a list of brands which they have ever heard of. If they select your brand, that counts as a positive response.",
                  },
                  {
                    name: "Buzz",
                    dataKey: "buzz",
                    toolTipText:
                      "Respondents are asked to select from a list of brands which they have heard positive references to recently. If they select your brand, that counts as a positive response.",
                  },
                  {
                    name: "Consideration",
                    dataKey: "consideration",
                    toolTipText:
                      "Respondents are asked to select from a list of brands which they would consider purchasing whenever they’re next in the market. If they select your brand, that counts as a positive response.",
                  },
                ]}
                colorMappings={[
                  { name: "Ad Awareness", color: Channel1 },
                  { name: "Aided Awareness", color: Channel4 },
                  { name: "Buzz", color: Channel3 },
                  { name: "Consideration", color: Channel2 },
                ]}
                tooltipLabelFormatter={val => capitalizeWords(val)}
                tooltipFormatter={val => (val ? `${numberFormatter.format(val, 0)}%` : val)}
                tooltipShape="line"
                usePercentageYAxis={true}
                xAxisDataKey="date"
                xAxisTickFormatter={val => val}
              />
            </ChartContainer>
            <ChartContainer
              enableHoverDesign
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadWeeklySpend, pngDownloadWeeklySpend]}
                />
              }
              title="Weekly Revenue"
              titleAfterDashText="12/20/20 – 12/24/23"
            >
              <AreaChart
                data={weeklySpendAreaData}
                xAxisDataKey="date"
                xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                dateGrouping="Week"
                yAxisWidth={75}
                yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                tooltipFormatter={val => {
                  if (!val) {
                    return val;
                  }
                  return val < 1000
                    ? currencyFormatter.format(val, 0)
                    : currencyFormatter.format(val, 1);
                }}
                tooltipShape="line"
                areas={[
                  {
                    name: "Revenue",
                    dataKey: "revenue",
                  },
                ]}
                hardCodedColorName="Revenue"
                hardCodedColor="#0194FF"
              ></AreaChart>
            </ChartContainer>
          </div>
          <div className="brandEquityRight">
            <ChartContainer
              enableHoverDesign
              title="Model Overview"
              rightActions={
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[excelDownloadModelOverview, csvDownloadModelOverview]}
                  menuOptions={["XLSX", "CSV"]}
                />
              }
            >
              <div
                className="chartBody"
                style={{ display: "flex", flexDirection: "column", gap: "32px" }}
              >
                <div className="brandEquityDataSection">
                  <div className="brandEquityDataSectionTitle">Training Data</div>
                  <div className="dataBody">
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">First model observation:</div>
                      <div className="brandEquityValue">12/20/20</div>
                    </div>
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">Last model observation:</div>
                      <div className="brandEquityValue">12/24/23</div>
                    </div>
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">Average weekly spend:</div>
                      <div className="brandEquityValue">
                        {currencyFormatter.format(overviewData[0].average_spend, 0)}
                      </div>
                    </div>
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">Outcome variable:</div>
                      <div className="brandEquityValue brandEquityOutcomeVariable">
                        {standardizeMetricName(groupByMetric)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="brandEquityDataSection">
                  <div className="brandEquityDataSectionTitle">Recency</div>
                  <div
                    className="dataBody"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">Last refresh date:</div>
                      <div className="brandEquityValue">04/01/24</div>
                    </div>
                  </div>
                </div>
                <div className="brandEquityDataSection">
                  <div className="brandEquityDataSectionTitle">Statistics</div>
                  <div className="dataBody">
                    <div className="brandEquityDataSectionRow">
                      <div className="brandEquityLabel">
                        Out-of-sample R<sup>2</sup>:{" "}
                        <MoreInfo size="reg">
                          The percentage of variation in the unseen test data outcomes that is
                          accounted for by the model's explanatory variables. Formally, this is 1 -
                          (residual sum of squares) / (total sum of squares)
                        </MoreInfo>
                      </div>
                      <div className="brandEquityValue">{overviewData[0].rsq.toFixed(2)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </ChartContainer>
          </div>
        </div>
      )}
    </WidgetContainer>
  );
};

export default Overview;
