import React, { useState, useCallback } from "react";

import { Card, InfoTooltip, Spinner } from "../../Components";
import { Button, Form, InputGroup } from "react-bootstrap";
import { ExtremeReachLambdaFetch } from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

const ExtremeReachCampaignAdder: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [company, setCompany] = useState("");
  const [advertiserName, setAdvertiserName] = useState("");
  const [advertiserId, setAdvertiserId] = useState<number | null>(null);
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState<number | null>(null);

  const addERAdvertiserAndCampaign = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await ExtremeReachLambdaFetch("/addERAdvertiserAndCampaign", {
        method: "POST",
        body: JSON.stringify({
          company,
          advertiserName,
          advertiserId,
          campaignName,
          campaignId,
        }),
      });
      setCompany("");
      setAdvertiserName("");
      setAdvertiserId(null);
      setCampaignName("");
      setCampaignId(null);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [company, advertiserName, advertiserId, campaignName, campaignId, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">
        Add Extreme Reach Campaign
        <InfoTooltip size="reg">
          The company you input should be the all lowercased company ID used in master_companies and
          in our app URLs.
          <br></br>
          <br></br>
          You can find the Advertiser Name and ID by selecting your client
          <a
            href="https://app.extremereach.com/adbridge/dist/Home/Index"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
          . Once you find your client, you should see their advertiser ID in the page's URL as
          "advertiserId=".
          <br></br>
          <br></br>
          You can find the Campaign Name and ID by searching for your client
          <a
            href="https://app.extremereach.com/vid/traffic/mediaplans/search"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
          .
        </InfoTooltip>
      </div>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={company} onChange={e => setCompany(e.target.value)} />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Advertiser Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={advertiserName} onChange={e => setAdvertiserName(e.target.value)} />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Advertiser Id</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={advertiserId || ""}
          onChange={e => setAdvertiserId(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Campaign Name</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={campaignName} onChange={e => setCampaignName(e.target.value)} />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Campaign Id</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={campaignId || ""}
          onChange={e => setCampaignId(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <Button className="linearAdOpsElem" onClick={addERAdvertiserAndCampaign}>
        {activeAPICall ? <Spinner /> : "Add"}
      </Button>
    </Card>
  );
};

export default ExtremeReachCampaignAdder;
