import React, { useState, useMemo, useEffect, useCallback } from "react";
import * as Dfns from "date-fns/fp";
import {
  Accordion,
  Card,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Dropdown,
  DropdownButton,
  Popover,
  Tooltip,
} from "react-bootstrap";
import {
  MdAdd,
  MdDelete,
  MdChevronLeft,
  MdChevronRight,
  MdRefresh,
  MdError,
  MdFileDownload,
  MdEdit,
  MdInfoOutline,
  MdList,
} from "react-icons/md";
import { ComposedChart, Bar, XAxis, YAxis, Legend, Tooltip as RechartsTooltips } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import { primary, secondary } from "../utils/colors";

import { useSetAreYouSure, useSetError } from "../redux/modals";
import CreatableSelect from "react-select/creatable";
import {
  Page,
  BPMButton,
  Spinner,
  FullPageSpinner,
  SingleDatePicker,
  KpiPicker,
  CheckBox,
  useKpiPickerData,
  NumberFormatter,
  Img,
  OverlayTrigger,
} from "../Components";
import {
  awaitJSON,
  poll,
  StreamingUtilsLambdaFetch,
  StreamingV2LambdaFetch,
} from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import * as R from "ramda";
import {
  StreamingExperimentFormArgs,
  StreamingExperimentResponse,
} from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import moment from "moment";
import Papa from "papaparse";
import { download } from "../utils/download-utils";

import "./StreamingExperiments.scss";

const DATE_FORMAT_MOMENT = "YYYY-MM-DD";
const STREAMING_ONLY = "streaming";
const DIRECT_TOGGLE = "direct";
const HALO_TOGGLE = "halo";
const DIRECT_HALO_TOGGLES = [DIRECT_TOGGLE, HALO_TOGGLE];
const DIRECT_HALO_VALUES = { [DIRECT_TOGGLE]: "Direct", [HALO_TOGGLE]: "+ Halo" };

interface DropdownOptions {
  label: string;
  value: string;
}

type ChevronState = "open" | "closed";

const roundedPValue = (x: Number | undefined) => {
  if (x) {
    const rounded = Number.parseFloat(`${x}`).toExponential(0);
    const roundedParts = rounded.split("e");
    if (x < 0.000000000000000001) {
      return (
        <span className="pvalueGreen">
          &lt; 10<sup>-16</sup>
        </span>
      );
    } else {
      return (
        <span className={x < 0.05 ? "pvalueGreen" : "pvalueBlack"}>
          {roundedParts[0]} x 10<sup>{roundedParts[1]}</sup>
        </span>
      );
    }
  }
  return "0";
};

const DOLLAR_ROUNDING_THRESHOLD = 20;
const roundedCpa = (x: Number | undefined) => {
  if (x) {
    if (x >= DOLLAR_ROUNDING_THRESHOLD) {
      return <NumberFormatter value={Math.round(Number(x))} type={"$"} decimals={0} />;
    } else {
      return <NumberFormatter value={Number(x)} type={"$"} decimals={2} />;
    }
  } else {
    return "0";
  }
};

const roundedResponseRate = (x: Number | undefined): string => {
  if (x) {
    const hundredThousand = Number(x) * 100000;
    return `${hundredThousand.toFixed(0)} per 100K`;
  }
  return "0";
};

const getPath = (x, y, width, height) => `M${x},${y + height}
          L${x + width},${y + height}
          M${x + width / 2},${y + height}
          L${x + width / 2},${y}
          M${x},${y}
          L${x + width},${y}
          Z`;

interface TooltipPayload {
  name: string;
  value: number;
  fill: string;
}

const CustomTooltip = args => {
  // eslint-disable-next-line prefer-destructuring
  const payload: TooltipPayload[] = args.payload;
  const { active, label } = args;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="label">{label}</div>
        {R.map(
          elem => (
            <div className="elem" key={elem.name}>
              <div className="name" style={{ color: elem.fill }}>
                {elem.name}
              </div>
              <div className="value">{roundedResponseRate(elem.value)}</div>
            </div>
          ),
          payload
        )}
        <div className="desc">
          Upon repeated experiments, Confidence Intervals will contain the true population response
          rate 95% of the time.
        </div>
      </div>
    );
  }

  return null;
};

const ErrorBar = props => {
  const { fill, x, y, width, height } = props;

  //determine the scale
  const responseRate = parseFloat(props[`${props.prefix}_response_rate`]);
  const scale = responseRate === 0 ? 0 : height / responseRate;
  const barTop = Math.min(height, scale * 2 * parseFloat(props[`${props.prefix}_rr_se`]));
  const barBottom = scale * -2 * parseFloat(props[`${props.prefix}_rr_se`]);

  return (
    <>
      <rect width={width} x={x} y={y} height={height} fill={fill} />
      <path
        d={getPath(x, y + barBottom, width, barTop - barBottom)}
        stroke="black"
        fill="none"
        strokeWidth={2}
      />
    </>
  );
};

const RANGE_INPUT_FORMAT = "MM/dd/yy";
const RANGE_OUTPUT_FORMAT = "MMMM do, yyyy";
const formatDateRange = range => {
  const [startStr, endStr] = range.split(" - ");
  const start = Dfns.parse(new Date(), RANGE_INPUT_FORMAT, startStr);
  const end = Dfns.parse(new Date(), RANGE_INPUT_FORMAT, endStr);
  return `${Dfns.format(RANGE_OUTPUT_FORMAT, start)} - ${Dfns.format(RANGE_OUTPUT_FORMAT, end)}`;
};

const StreamingExperiments = (): JSX.Element => {
  const { company } = useLocation();
  const { defaultKpi } = useKpiPickerData({ mediaType: STREAMING_ONLY });
  const [saving, setSaving] = useState(false);
  const [networkDropdownOptions, setNetworkDropdownOptions] = useState<DropdownOptions[]>();
  const [streamingNetworkDescriptions, setStreamingNetworkDescriptions] = useState<
    Record<string, string>
  >();
  const [lagArray, setLagArray] = useState<JSX.Element[]>([]);
  const [lagValues, setLagValues] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<StreamingExperimentResponse[][][]>();
  const [chevronArray, setChevronArray] = useState<ChevronState[]>([]);
  const [directHaloToggle, setDirectHaloToggle] = useState<Record<string, string>>({});
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure();

  const initialFormData = {
    description: "Description",
    exposed_description: "Lift",
    control_description: "Control",
    company: company,
    kpi_table: `bpm.kpis_${company}`,
    kpi_name: [
      {
        label: "KPI Group 1",
        kpis: [defaultKpi],
      },
    ],
    lag: [1, 3, 7, 14, 30],
    exposed_network: [],
    control_network: [],
    start: "",
    end: "",
    contamination_start: "",
    supress_psa_exposures: false,
    cpm: "",
  };
  const [formData, setFormData] = useState<StreamingExperimentFormArgs>(initialFormData);

  const makeNewKpiPicker = () => {
    return <div className="kpiPicker"></div>;
  };
  const [kpisArray, setKpisArray] = useState<JSX.Element[][]>([[makeNewKpiPicker()]]);

  const addKpiPicker = useCallback(
    (outerIndex: number) => {
      if (
        formData.kpi_name[outerIndex] &&
        formData.kpi_name[outerIndex].kpis &&
        kpisArray[outerIndex]
      ) {
        const copyFormData = { ...formData };
        const newNestedKpis = R.concat(formData.kpi_name[outerIndex].kpis, [defaultKpi]);
        copyFormData.kpi_name[outerIndex].kpis = newNestedKpis;
        setFormData(copyFormData);

        const newKpisArray = R.concat(kpisArray[outerIndex], [makeNewKpiPicker()]);
        const updatedKpisArray = R.update(outerIndex, newKpisArray, kpisArray);
        setKpisArray(updatedKpisArray);
      }
    },
    [kpisArray, defaultKpi, formData]
  );

  const deleteKpiPicker = useCallback(
    (outerIndex: number) => {
      if (
        formData.kpi_name[outerIndex] &&
        formData.kpi_name[outerIndex].kpis &&
        kpisArray[outerIndex]
      ) {
        const copyFormData = { ...formData };
        const newKpiName = R.dropLast(1, formData.kpi_name[outerIndex].kpis);
        copyFormData.kpi_name[outerIndex].kpis = newKpiName;
        setFormData(copyFormData);

        const newKpisArray = R.dropLast(1, kpisArray[outerIndex]);
        const updatedKpisArray = R.update(outerIndex, newKpisArray, kpisArray);
        setKpisArray(updatedKpisArray);
      }
    },
    [kpisArray, formData]
  );

  const makeNewKpiGroup = useCallback(() => {
    return <div className="kpiGroup"></div>;
  }, []);
  const [toRenderArray, setToRenderArray] = useState<JSX.Element[]>([makeNewKpiGroup()]);

  const addKpiGroup = useCallback(() => {
    const newKpiGroup = makeNewKpiGroup();
    const newToRenderArray = R.concat(toRenderArray, [newKpiGroup]);
    setToRenderArray(newToRenderArray);

    const newKpisArray = R.concat(kpisArray, [[makeNewKpiPicker()]]);
    setKpisArray(newKpisArray);

    const newKpiName = R.concat(formData.kpi_name, [
      {
        label: `KPI Group ${newToRenderArray.indexOf(newKpiGroup) + 1}`,
        kpis: [defaultKpi],
      },
    ]);
    setFormData({ ...formData, kpi_name: newKpiName });
  }, [toRenderArray, kpisArray, defaultKpi, formData, makeNewKpiGroup]);

  const deleteKpiGroup = useCallback(() => {
    const newToRenderArray = R.dropLast(1, toRenderArray);
    setToRenderArray(newToRenderArray);

    const newKpisArray = R.dropLast(1, kpisArray);
    setKpisArray(newKpisArray);

    const newKpiName = R.dropLast(1, formData.kpi_name);
    setFormData({ ...formData, kpi_name: newKpiName });
  }, [toRenderArray, kpisArray, formData]);

  const makeDefaultLags = (defaultLag: number) => {
    return (
      <div className="lagItem" key={defaultLag}>
        <CheckBox
          checked={formData && formData.lag.indexOf(defaultLag) >= 0}
          onCheck={checked => {
            const idx = formData.lag.indexOf(defaultLag);
            const newLag =
              idx >= 0 && !checked
                ? R.remove(idx, 1, formData.lag)
                : R.concat(formData.lag, [defaultLag]);
            setFormData({ ...formData, lag: newLag });
          }}
        />
        <div className="lagNumber">{defaultLag}</div>
      </div>
    );
  };

  const defaultLagArray: JSX.Element[] = [
    makeDefaultLags(1),
    makeDefaultLags(3),
    makeDefaultLags(7),
    makeDefaultLags(14),
    makeDefaultLags(30),
  ];

  const makeNewVariableLag = useCallback(
    (lagIndex: number) => {
      return (
        <div className="customLag" key={lagIndex}>
          <Form.Label>Custom Lag #{lagIndex + 1}:</Form.Label>
          <Form.Control
            key={lagIndex}
            min="0"
            value={lagValues[lagIndex]}
            type="number"
            onChange={e => {
              let currentValue = e.target.value;
              const newLag = lagValues[lagIndex]
                ? R.update(lagIndex, parseInt(currentValue), lagValues)
                : R.concat(lagValues, [parseInt(currentValue)]);
              setLagValues(newLag);
              setFormData({ ...formData });
            }}
          />
        </div>
      );
    },
    [lagValues, formData]
  );

  const addLag = useCallback(() => {
    const newLagValues = R.concat(lagValues, [-1]);
    setLagValues(newLagValues);

    const variableLag = makeNewVariableLag(lagArray.length);
    const newLagArray = R.concat(lagArray, [variableLag]);
    setLagArray(newLagArray);

    setFormData({ ...formData });
  }, [lagArray, makeNewVariableLag, formData, lagValues]);

  const deleteLag = useCallback(() => {
    const newLagArray = R.dropLast(1, lagArray);
    setLagArray(newLagArray);

    const newLagValues = R.dropLast(1, lagValues);
    setLagValues(newLagValues);

    setFormData({ ...formData });
  }, [lagValues, lagArray, formData]);

  // Network Dropdown Options
  useEffect(() => {
    (async () => {
      if (!networkDropdownOptions) {
        let options;
        try {
          options = await StreamingUtilsLambdaFetch("/streaming_experiments/networks");
        } catch (e) {
          setError({ message: `Failed to get dropdown options: ${e.message}` });
        }
        options = await awaitJSON(options);
        const dropdownOptions = options.map(elem => {
          return { label: elem, value: elem };
        });
        setNetworkDropdownOptions(dropdownOptions);
      }
    })();
  }, [setError, networkDropdownOptions]);

  // Network Dropdown Options
  useEffect(() => {
    (async () => {
      if (!streamingNetworkDescriptions) {
        let descriptions;
        try {
          descriptions = await StreamingV2LambdaFetch("/old_streaming_networks");
        } catch (e) {
          setError({ message: `Failed to get dropdown options: ${e.message}` });
        }
        descriptions = await awaitJSON(descriptions);
        setStreamingNetworkDescriptions(
          R.zipObj(
            R.map(row => row.shortCode, descriptions),
            R.map(row => row.name, descriptions)
          )
        );
      }
    })();
  }, [setError, streamingNetworkDescriptions]);
  //

  // Existing Experiments on Home Page
  useEffect(() => {
    (async () => {
      if (!initialData) {
        let existingExperiments: StreamingExperimentResponse[][][] = [];
        try {
          const res = await StreamingUtilsLambdaFetch("/streaming_experiments/existing", {
            method: "POST",
            body: { company: company },
          });
          existingExperiments = await awaitJSON<StreamingExperimentResponse[][][]>(res);
        } catch (e) {
          setError({ message: `Failed to get existing experiments data: ${e.message}` });
        }

        setInitialData(existingExperiments);
        setChevronArray(existingExperiments.map(() => "closed"));

        const numRunning = R.filter(
          row => row[0][0].status !== "FAILED" && row[0][0].status !== "SUCCEEDED",
          existingExperiments
        ).length;
        if (numRunning > 0) {
          let parsed: StreamingExperimentResponse[][][];
          await poll({
            fetchMaker: async () => {
              let res = await StreamingUtilsLambdaFetch("/streaming_experiments/existing", {
                method: "POST",
                body: { company: company },
              });
              parsed = await awaitJSON<StreamingExperimentResponse[][][]>(res);
              const newNumRunning = R.filter(
                row => row[0][0].status !== "FAILED" && row[0][0].status !== "SUCCEEDED",
                parsed
              ).length;
              return newNumRunning < numRunning;
            },
            timeout: 3600,
          });
          window.location.reload();
        }
      }
    })();
  }, [company, initialData, setError]);

  const downloadCsv = rows => {
    const flattenedRows = R.flatten(rows);

    const header = [
      "Network",
      "Description",
      "Date Range",
      "eCPM",
      "Control Sample",
      "Treatment Sample",
      "Max Response Latency",
      "KPI",
      "Treatment Matches",
      "Control Matches",
      "P-Value",
      "Implied CPA",
      "Treatment Response Rate",
      "Control Response Rate",
      "Treatment Matches",
      "Control Matches",
      "P-Value",
      "Implied CPA",
      "Treatment Response Rate",
      "Control Response Rate",
    ];
    const csvRows = R.map(
      row => [
        row.exposed_network,
        row.descriptive_text,
        row.date_range,
        row.cpm,
        row.n_control,
        row.n_exposed,
        row.lag,
        row.kpi_label,
        row.matched_exposed_direct,
        row.matched_control_direct,
        row.fisher_pvalue_direct,
        row.implied_cpa_direct,
        row.direct_exposed_response_rate,
        row.direct_control_response_rate,
        row.matched_exposed_halo,
        row.matched_control_halo,
        row.fisher_pvalue_halo,
        row.implied_cpa_halo,
        row.halo_exposed_response_rate,
        row.halo_control_response_rate,
      ],
      flattenedRows
    );

    csvRows.unshift(header);
    const csv = Papa.unparse(csvRows);
    download(
      csv,
      `${flattenedRows[0].exposed_network}_${flattenedRows[0].date_range}.csv`,
      "text/csv"
    );
  };

  const postBody = useMemo(() => {
    if (
      formData.start !== "" &&
      formData.end !== "" &&
      formData.exposed_network.length > 0 &&
      !R.isNil(formData.exposed_description) &&
      formData.control_network.length > 0 &&
      !R.isNil(formData.control_description) &&
      !R.isNil(formData.company) &&
      formData.contamination_start !== "" &&
      !R.isNil(formData.kpi_table) &&
      !R.isNil(formData.kpi_name) &&
      !R.isNil(formData.lag) &&
      !R.isNil(formData.supress_psa_exposures) &&
      formData.cpm !== "" &&
      !R.isNil(formData.description)
    ) {
      if (formData.kpi_name[0].kpis[0] === "") {
        formData.kpi_name[0].kpis[0] = defaultKpi;
      }
      setFormData(formData);
      return formData;
    }
  }, [formData, defaultKpi]);

  const toggleChevron = useCallback(
    (initialData: StreamingExperimentResponse[][][], elem: StreamingExperimentResponse[][]) => {
      let newChevronArray;
      if (chevronArray[initialData.indexOf(elem)]) {
        const newValue = chevronArray[initialData.indexOf(elem)] === "closed" ? "open" : "closed";
        newChevronArray = R.update(initialData.indexOf(elem), newValue, chevronArray);
      } else {
        newChevronArray = R.concat(chevronArray, ["closed"]);
      }
      setChevronArray(newChevronArray);
    },
    [setChevronArray, chevronArray]
  );

  const refresh = useCallback(
    async (description: string) => {
      try {
        await setAreYouSure({
          title: `Re-run Experiment "${description}"?`,
          message: "Are you sure you want to re-run this experiment?",
          okayText: "Yes, re-run",
          cancelText: "Never mind",
        });
        await StreamingUtilsLambdaFetch("/streaming_experiments/refresh", {
          method: "POST",
          body: { description: description, company: company },
        });
        window.location.reload();
      } catch (e) {
        setError({
          message: `Failed to re-run experiment with description ${description}: ${e.message}`,
        });
      }
    },
    [setAreYouSure, setError, company]
  );

  const removeRow = useCallback(
    async (description: string) => {
      try {
        await setAreYouSure({
          title: `Delete Experiment "${description}"?`,
          message: "Are you sure you want to delete this experiment?",
          okayText: "Delete",
          cancelText: "Never mind",
        });
        await StreamingUtilsLambdaFetch("/streaming_experiments/removeRow", {
          method: "POST",
          body: { description: description, company: company },
        });
        window.location.reload();
      } catch (e) {
        setError({ message: `Failed to remove row with description ${description}: ${e.message}` });
      }
    },
    [setAreYouSure, setError, company]
  );

  const save = useCallback(async () => {
    if (postBody) {
      const newLag = R.union(postBody.lag, lagValues);
      postBody.lag = R.filter(elem => elem > 0, newLag);
      try {
        setSaving(true);
        await StreamingUtilsLambdaFetch("/streaming_experiments", {
          method: "POST",
          body: postBody,
        });
        window.location.reload();
      } catch (e) {
        setError({ message: `Failed to get experiments data: ${e.message}` });
        setSaving(false);
      }
    } else {
      setError({ message: "Please fill out all inputs." });
    }
  }, [postBody, lagValues, setError]);

  return (
    <Page
      title="Streaming Experiments"
      pageType="Streaming Experiments"
      actions={
        <div className="streamingExperimentsActions">
          <BPMButton
            variant="primary"
            onClick={() => {
              setShowModal(true);
            }}
            className="addButton"
          >
            <MdAdd />
          </BPMButton>
        </div>
      }
    >
      {networkDropdownOptions ? (
        <div className="streamingExperimentsPage">
          {showModal && (
            <Modal
              size="lg"
              show={showModal}
              onHide={() => {
                setShowModal(false);
                setFormData({ ...initialFormData });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Inputs</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                  <div className="experimentsModal">
                    <Form.Group className="descriptionFormGroup">
                      <Form.Label className="inputTitle">Description</Form.Label>
                      <Form.Control
                        value={formData.description || ""}
                        onChange={e => {
                          let currentValue = e.target.value;
                          setFormData(current => {
                            return { ...current, description: currentValue };
                          });
                        }}
                      />
                    </Form.Group>

                    <div className="experimentsInputsRowOne">
                      <Form.Group className="startDateFormGroup">
                        <Form.Label className="inputTitle">Start</Form.Label>
                        <SingleDatePicker
                          small
                          date={formData.start}
                          isOutsideRange={() => false}
                          onChange={date => {
                            if (date) {
                              const dateAsMoment = moment(date, DATE_FORMAT_MOMENT);
                              setFormData(current => {
                                return {
                                  ...current,
                                  start: dateAsMoment.format(DATE_FORMAT_MOMENT),
                                };
                              });
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="endDateFormGroup">
                        <Form.Label className="inputTitle">End</Form.Label>
                        <SingleDatePicker
                          small
                          date={formData.end}
                          isOutsideRange={() => false}
                          onChange={date => {
                            if (date) {
                              const dateAsMoment = moment(date, DATE_FORMAT_MOMENT);
                              setFormData(current => {
                                return { ...current, end: dateAsMoment.format(DATE_FORMAT_MOMENT) };
                              });
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="contaminationStartFormGroup">
                        <Form.Label className="inputTitle">Contamination Start</Form.Label>
                        <SingleDatePicker
                          small
                          date={formData.contamination_start}
                          isOutsideRange={() => false}
                          onChange={date => {
                            if (date) {
                              const dateAsMoment = moment(date, DATE_FORMAT_MOMENT);
                              setFormData(current => {
                                return {
                                  ...current,
                                  contamination_start: dateAsMoment.format(DATE_FORMAT_MOMENT),
                                };
                              });
                            }
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="kpiRow">
                      <Form.Group className="kpiNameFormGroup">
                        <div className="inputTitle">
                          <Form.Label className="inputTitle">KPI Groups</Form.Label>
                          <Button
                            className="addBtn"
                            variant="outline-secondary"
                            size="sm"
                            onClick={addKpiGroup}
                          >
                            <MdAdd />
                          </Button>
                          <Button
                            className="lagBtn"
                            variant="outline-secondary"
                            size="sm"
                            onClick={deleteKpiGroup}
                            disabled={toRenderArray.length < 2}
                          >
                            <MdDelete />
                          </Button>
                        </div>
                        <InputGroup>
                          <div className="kpiGroupList">
                            {toRenderArray.map(elem => (
                              <div className="kpiGroup" key={toRenderArray.indexOf(elem)}>
                                <div className="kpiGroupHeader">
                                  <Form.Label>
                                    KPI Group #{toRenderArray.indexOf(elem) + 1} Label
                                  </Form.Label>
                                  <Form.Control
                                    value={
                                      formData &&
                                      formData.kpi_name &&
                                      formData.kpi_name[toRenderArray.indexOf(elem)]
                                        ? formData.kpi_name[toRenderArray.indexOf(elem)].label
                                        : `KPI Group ${toRenderArray.indexOf(elem) + 1}`
                                    }
                                    onChange={e => {
                                      let currentValue = e.target.value;

                                      const copyFormData = { ...formData };
                                      copyFormData.kpi_name[
                                        toRenderArray.indexOf(elem)
                                      ].label = currentValue;
                                      setFormData(copyFormData);
                                    }}
                                  />
                                  <Button
                                    className="kpiBtn addBtn"
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => {
                                      addKpiPicker(toRenderArray.indexOf(elem));
                                    }}
                                  >
                                    <MdAdd />
                                  </Button>
                                  <Button
                                    className="kpiBtn"
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => {
                                      deleteKpiPicker(toRenderArray.indexOf(elem));
                                    }}
                                    disabled={
                                      kpisArray[toRenderArray.indexOf(elem)] &&
                                      kpisArray[toRenderArray.indexOf(elem)].length < 2
                                    }
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                                <div>
                                  {kpisArray[toRenderArray.indexOf(elem)] &&
                                    kpisArray[toRenderArray.indexOf(elem)].map(kpi => (
                                      <KpiPicker
                                        key={`${kpisArray[toRenderArray.indexOf(elem)].indexOf(
                                          kpi
                                        )}-${toRenderArray.indexOf(elem)}`}
                                        kpi={
                                          formData &&
                                          formData.kpi_name &&
                                          formData.kpi_name[toRenderArray.indexOf(elem)] &&
                                          formData.kpi_name[toRenderArray.indexOf(elem)].kpis
                                            ? formData.kpi_name[toRenderArray.indexOf(elem)].kpis[
                                                kpisArray[toRenderArray.indexOf(elem)].indexOf(kpi)
                                              ]
                                            : defaultKpi
                                        }
                                        onChange={e => {
                                          formData.kpi_name[toRenderArray.indexOf(elem)].kpis[
                                            kpisArray[toRenderArray.indexOf(elem)].indexOf(kpi)
                                          ] = e;
                                          setFormData(current => {
                                            return { ...current, kpi_name: formData.kpi_name };
                                          });
                                        }}
                                        mediaType="streaming"
                                      />
                                    ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </div>

                    <div className="lagRow">
                      <Form.Group className="lagFormGroup">
                        <Form.Label className="inputTitle">Lag</Form.Label>
                        <Button
                          className="lagBtn addBtn"
                          variant="outline-secondary"
                          size="sm"
                          onClick={addLag}
                        >
                          <MdAdd />
                        </Button>
                        <Button
                          className="lagBtn"
                          variant="outline-secondary"
                          size="sm"
                          onClick={deleteLag}
                          disabled={lagArray.length === 0}
                        >
                          <MdDelete />
                        </Button>
                        <InputGroup>
                          <div className="defaultLagArray">{defaultLagArray}</div>
                          <div>{lagArray}</div>
                        </InputGroup>
                      </Form.Group>
                    </div>

                    <div className="lastRow">
                      <Form.Group className="psaFormGroup">
                        <Form.Label className="inputTitle">Suppress PSA Exposures</Form.Label>
                        <CheckBox
                          checked={formData.supress_psa_exposures}
                          onCheck={checked => {
                            setFormData(current => {
                              return { ...current, supress_psa_exposures: checked };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="cpmFormGroup">
                        <Form.Label className="inputTitle">CPM</Form.Label>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            value={formData.cpm || ""}
                            onChange={e => {
                              let currentValue = e.target.value;
                              setFormData(current => {
                                return { ...current, cpm: currentValue };
                              });
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="experimentsInputsRowTwo">
                      <Form.Group className="exposedNetworkFormGroup">
                        <Form.Label className="inputTitle">Exposed Network</Form.Label>
                        <CreatableSelect
                          isClearable
                          isMulti
                          value={
                            formData && formData.exposed_network
                              ? formData.exposed_network.map(elem => ({
                                  value: elem,
                                  label: elem,
                                  key: elem,
                                }))
                              : []
                          }
                          options={networkDropdownOptions}
                          onChange={selection => {
                            let newExposedNetworks = selection
                              ? selection.map(elem => elem.value)
                              : [];
                            setFormData(current => {
                              return { ...current, exposed_network: newExposedNetworks };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="exposedDescriptionFormGroup">
                        <Form.Label className="inputTitle">Exposed Description</Form.Label>
                        <InputGroup>
                          <Form.Control
                            value={formData.exposed_description || ""}
                            onChange={e => {
                              let currentValue = e.target.value;
                              setFormData(current => {
                                return { ...current, exposed_description: currentValue };
                              });
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="controlNetworkFormGroup">
                        <Form.Label className="inputTitle">Control Network</Form.Label>
                        <CreatableSelect
                          isClearable
                          isMulti
                          value={
                            formData && formData.control_network
                              ? formData.control_network.map(elem => ({
                                  value: elem,
                                  label: elem,
                                  key: elem,
                                }))
                              : []
                          }
                          options={networkDropdownOptions}
                          onChange={selection => {
                            let newControlNetworks = selection
                              ? selection.map(elem => elem.value)
                              : [];
                            setFormData(current => {
                              return { ...current, control_network: newControlNetworks };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="controlDescriptionFormGroup">
                        <Form.Label className="inputTitle">Control Description</Form.Label>
                        <InputGroup>
                          <Form.Control
                            value={formData.control_description || ""}
                            onChange={e => {
                              let currentValue = e.target.value;
                              setFormData(current => {
                                return { ...current, control_description: currentValue };
                              });
                            }}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer className="modalControls">
                <Button
                  variant="dark"
                  onClick={() => {
                    setShowModal(false);
                    setFormData({ ...initialFormData });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="sendButton"
                  disabled={!postBody}
                  onClick={save}
                >
                  {saving ? <Spinner color="white" /> : <span>Save</span>}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          {initialData ? (
            !initialData.length ? (
              <div className="noExperimentsText">There are no experiments to show.</div>
            ) : (
              initialData.map(elem => {
                return (
                  <Accordion key={initialData.indexOf(elem)}>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => {
                          toggleChevron(initialData, elem);
                        }}
                      >
                        <div className="experimentTitle">
                          <Button
                            className="expander"
                            onClick={() => {
                              toggleChevron(initialData, elem);
                            }}
                          >
                            {["DONE", "SUCCEEDED"].includes(elem[0][0].status) ? (
                              chevronArray[initialData.indexOf(elem)] === "open" ? (
                                <MdChevronLeft />
                              ) : (
                                <MdChevronRight />
                              )
                            ) : elem[0][0].status === "FAILED" ? (
                              <MdError />
                            ) : (
                              <Spinner />
                            )}
                          </Button>
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip id="runLogs">Logs</Tooltip>}
                          >
                            <Button
                              className="logs"
                              onClick={() => {
                                if (elem[0][0].log_url !== null) {
                                  window.open(elem[0][0].log_url, "_blank");
                                }
                              }}
                            >
                              <MdList />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip id="deleteExperiment">Delete</Tooltip>}
                          >
                            <Button
                              className="delete"
                              onClick={() => {
                                removeRow(elem[0][0].descriptive_text);
                              }}
                              disabled={elem[0][0].status === "RUNNING"}
                            >
                              <MdDelete />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip id="editExperiment">Edit</Tooltip>}
                          >
                            <Button
                              className="edit"
                              onClick={() => {
                                setShowModal(true);
                                setFormData(current => {
                                  return elem[0][0].input_data as StreamingExperimentFormArgs;
                                });
                              }}
                              disabled={elem[0][0].status === "RUNNING"}
                            >
                              <MdEdit />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip id="refreshExperiment">Refresh</Tooltip>}
                          >
                            <Button
                              className="refresh"
                              onClick={() => {
                                refresh(elem[0][0].descriptive_text);
                              }}
                              disabled={elem[0][0].status === "RUNNING"}
                            >
                              <MdRefresh />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip id="downloadExperiment">Download</Tooltip>}
                          >
                            <Button
                              className="downloadCsv"
                              onClick={() => {
                                downloadCsv(elem);
                              }}
                              disabled={elem[0][0].status === "RUNNING"}
                            >
                              <MdFileDownload />
                            </Button>
                          </OverlayTrigger>
                          <div className="experimentHeader">
                            {formatDateRange(elem[0][0].date_range)}
                          </div>
                          {elem[0][0]?.exposed_network?.split(",").map(network => {
                            return (
                              <div className="experimentHeader" key={network}>
                                <div>
                                  {streamingNetworkDescriptions &&
                                  streamingNetworkDescriptions[network]
                                    ? streamingNetworkDescriptions[network]
                                    : network}
                                </div>
                                <Img
                                  src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
                                  className="networkLogo"
                                  title={network}
                                  unloader={
                                    <div className="logoNoImgBox logo404">
                                      <span key={network}>{network}</span>
                                    </div>
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {elem[0][0].status === "FAILED" && elem[0][0].input_data !== undefined ? (
                            <p>
                              <span className="failedExperimentInfo">
                                <strong>
                                  Your experiment failed (
                                  {elem[0][0].notes
                                    ?.replace(/\[Amazon\]\([^)]*\) */, "")
                                    .replace(/;$/, "")}
                                  ). Please check your inputs, edit this experiment, and re-run.{" "}
                                  <br></br>
                                  Inputs: <br></br>
                                </strong>
                              </span>
                              <span>
                                <strong>Description: </strong>
                              </span>
                              {elem[0][0].input_data.description} <br></br>
                              <span>
                                <strong>Start: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.start} <br></br>
                              <span>
                                <strong>End: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.end} <br></br>
                              <span>
                                <strong>Contamination Start: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.contamination_start} <br></br>
                              <span>
                                <strong>KPI Groups: </strong> <br></br>
                              </span>{" "}
                              {elem[0][0].input_data.kpi_name.map(kpiGroup => {
                                return (
                                  <span>
                                    {kpiGroup.label}:{" "}
                                    {!kpiGroup.kpis.length
                                      ? "Missing KPI names"
                                      : kpiGroup.kpis.join(",")}{" "}
                                    {elem[0][0].input_data &&
                                    elem[0][0].input_data.kpi_name.indexOf(kpiGroup) !==
                                      elem[0][0].input_data.kpi_name.length - 1 ? (
                                      <br></br>
                                    ) : (
                                      <span></span>
                                    )}
                                  </span>
                                );
                              })}{" "}
                              <br></br>
                              <span>
                                <strong>Lag: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.lag.join(",")} <br></br>
                              <span>
                                <strong>Suppress PSA Exposures: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.supress_psa_exposures ? "true" : "false"}{" "}
                              <br></br>
                              <span>
                                <strong>CPM: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.cpm} <br></br>
                              <span>
                                <strong>Exposed Network: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.exposed_network.join(",")} <br></br>
                              <span>
                                <strong>Exposed Description: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.exposed_description} <br></br>
                              <span>
                                <strong>Control Network: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.control_network.join(",")} <br></br>
                              <span>
                                <strong>Control Description: </strong>
                              </span>{" "}
                              {elem[0][0].input_data.control_description} <br></br>
                            </p>
                          ) : (
                            <div>
                              <div className="experimentInfoOverall">
                                <strong>Description: {elem[0][0].descriptive_text}</strong>
                                <br />
                                <strong>
                                  Total Investment:&nbsp;
                                  <NumberFormatter
                                    value={R.defaultTo(
                                      Number(
                                        (Number(elem[0][0].cpm) *
                                          (R.defaultTo(0, Number(elem[0][0].n_control)) +
                                            R.defaultTo(0, Number(elem[0][0].n_exposed)))) /
                                          1000.0
                                      ),
                                      Number(elem[0][0].total_cost)
                                    )}
                                    type={"$"}
                                    decimals={0}
                                  />
                                </strong>
                                <br />
                                <span>eCPM: </span>
                                <NumberFormatter
                                  value={Number(elem[0][0].cpm)}
                                  type={"$"}
                                  decimals={2}
                                />
                                <br></br>
                                <span>Control Sample: </span>
                                <NumberFormatter value={Number(elem[0][0].n_control)} type={""} />
                                <OverlayTrigger
                                  exitOnBackgroundClick
                                  trigger="click"
                                  placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                  overlay={
                                    <Popover id="controlSample">
                                      Viewers who were <b>not</b> exposed to a brand-related ad.
                                      These viewers saw another ad that is not associated with the
                                      brand.
                                    </Popover>
                                  }
                                >
                                  <Button variant="outline-primary" className="infoButton">
                                    <MdInfoOutline />
                                  </Button>
                                </OverlayTrigger>
                                <br></br>
                                <span>Treatment Sample: </span>
                                <NumberFormatter value={Number(elem[0][0].n_exposed)} type={""} />
                                <OverlayTrigger
                                  exitOnBackgroundClick
                                  trigger="click"
                                  placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                  overlay={
                                    <Popover id="treatmentSample">
                                      Viewers who were exposed to a brand-related ad on the test
                                      network.
                                    </Popover>
                                  }
                                >
                                  <Button variant="outline-primary" className="infoButton">
                                    <MdInfoOutline />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                              {elem.map(lagTable => {
                                const dataMax =
                                  lagTable.length === 0
                                    ? 1
                                    : R.head(
                                        R.sort(
                                          R.identity,
                                          R.map(innerElem => {
                                            const prefix = R.defaultTo(
                                              "direct",
                                              directHaloToggle[
                                                `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                              ]
                                            );
                                            const responseRate = parseFloat(
                                              innerElem[`${prefix}_exposed_response_rate`]
                                            );
                                            const stdErr = parseFloat(
                                              innerElem[`${prefix}_exposed_rr_se`]
                                            );
                                            return responseRate + 3 * stdErr;
                                          }, lagTable)
                                        )
                                      );

                                return (
                                  <div
                                    className="experimentInfoWrapper"
                                    key={elem.indexOf(lagTable)}
                                  >
                                    <div className="experimentInfo">
                                      <span>
                                        <strong>{lagTable[0].lag}-Day Max Response Latency</strong>
                                      </span>
                                    </div>
                                    <div className="comboTableChart">
                                      <Table className="table-striped">
                                        <thead>
                                          <tr>
                                            <th colSpan={8}>
                                              <DropdownButton
                                                title={
                                                  DIRECT_HALO_VALUES[
                                                    R.defaultTo(
                                                      DIRECT_TOGGLE,
                                                      directHaloToggle[
                                                        `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                                      ]
                                                    )
                                                  ]
                                                }
                                              >
                                                {DIRECT_HALO_TOGGLES.map(toggle => (
                                                  <Dropdown.Item
                                                    as="button"
                                                    key={toggle}
                                                    onClick={() => {
                                                      setDirectHaloToggle({
                                                        ...directHaloToggle,
                                                        [`${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`]: toggle,
                                                      });
                                                    }}
                                                  >
                                                    {DIRECT_HALO_VALUES[toggle]}
                                                  </Dropdown.Item>
                                                ))}
                                              </DropdownButton>
                                            </th>
                                          </tr>
                                          <tr>
                                            <th className="kpiLabel">KPI</th>
                                            <th>Treatment Matches</th>
                                            <th>Control Matches</th>
                                            <th style={{ width: "150px" }}>
                                              P-Value
                                              <OverlayTrigger
                                                exitOnBackgroundClick
                                                trigger="click"
                                                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                                overlay={
                                                  <Popover id="popover_pvalue">
                                                    The likelihood that we would observe a
                                                    difference in response rates as large as that in
                                                    the experiment, if there were no true underlying
                                                    difference between them.
                                                  </Popover>
                                                }
                                              >
                                                <Button
                                                  variant="outline-primary"
                                                  className="infoButton"
                                                >
                                                  <MdInfoOutline />
                                                </Button>
                                              </OverlayTrigger>
                                            </th>
                                            <th style={{ width: "200px" }}>
                                              Implied CPA
                                              <OverlayTrigger
                                                exitOnBackgroundClick
                                                trigger="click"
                                                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                                overlay={
                                                  <Popover id="popover_impliedcpa">
                                                    This calculation uses the experimental response
                                                    rates, the test CPM, and average frequency to
                                                    back into an implied incremental CPA.
                                                  </Popover>
                                                }
                                              >
                                                <Button
                                                  variant="outline-primary"
                                                  className="infoButton"
                                                >
                                                  <MdInfoOutline />
                                                </Button>
                                              </OverlayTrigger>
                                            </th>
                                            <th>
                                              Treatment Response Rate
                                              <OverlayTrigger
                                                exitOnBackgroundClick
                                                trigger="click"
                                                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                                overlay={
                                                  <Popover id="popover_treatmentresponserate">
                                                    The likelihood of a viewer who saw a
                                                    brand-related ad responding to the KPI endpoint.
                                                  </Popover>
                                                }
                                              >
                                                <Button
                                                  variant="outline-primary"
                                                  className="infoButton"
                                                >
                                                  <MdInfoOutline />
                                                </Button>
                                              </OverlayTrigger>
                                            </th>
                                            <th>
                                              Control Response Rate
                                              <OverlayTrigger
                                                exitOnBackgroundClick
                                                trigger="click"
                                                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                                overlay={
                                                  <Popover id="popover_controlresponserate">
                                                    The likelihood of a viewer who{" "}
                                                    <b>did not see</b> a brand-related ad responding
                                                    to the KPI endpoint.
                                                  </Popover>
                                                }
                                              >
                                                <Button
                                                  variant="outline-primary"
                                                  className="infoButton"
                                                >
                                                  <MdInfoOutline />
                                                </Button>
                                              </OverlayTrigger>
                                            </th>
                                            <th>
                                              Lift
                                              <OverlayTrigger
                                                exitOnBackgroundClick
                                                trigger="click"
                                                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                                                overlay={
                                                  <Popover id="popover_lift">
                                                    The percentage increase in the treatment
                                                    response rate over the control response rate:
                                                    Treatment Response Rate / Control Response Rate
                                                    - 1
                                                  </Popover>
                                                }
                                              >
                                                <Button
                                                  variant="outline-primary"
                                                  className="infoButton"
                                                >
                                                  <MdInfoOutline />
                                                </Button>
                                              </OverlayTrigger>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {lagTable.map(kpiRow => {
                                            const lookupKey = `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`;
                                            return (
                                              <tr key={lagTable.indexOf(kpiRow)}>
                                                <td>{kpiRow.kpi_label}</td>
                                                <td>
                                                  <NumberFormatter
                                                    type={"#"}
                                                    value={parseFloat(
                                                      kpiRow[
                                                        `matched_exposed_${R.defaultTo(
                                                          "direct",
                                                          directHaloToggle[lookupKey]
                                                        )}`
                                                      ]
                                                    )}
                                                  />
                                                </td>
                                                <td>
                                                  <NumberFormatter
                                                    type={"#"}
                                                    value={parseFloat(
                                                      kpiRow[
                                                        `matched_control_${R.defaultTo(
                                                          "direct",
                                                          directHaloToggle[lookupKey]
                                                        )}`
                                                      ]
                                                    )}
                                                  />
                                                </td>
                                                <td>
                                                  {roundedPValue(
                                                    kpiRow[
                                                      `fisher_pvalue_${R.defaultTo(
                                                        "direct",
                                                        directHaloToggle[lookupKey]
                                                      )}`
                                                    ]
                                                  )}
                                                </td>
                                                <td>
                                                  {roundedCpa(
                                                    kpiRow[
                                                      `implied_cpa_${R.defaultTo(
                                                        "direct",
                                                        directHaloToggle[lookupKey]
                                                      )}`
                                                    ]
                                                  )}
                                                </td>
                                                <td>
                                                  {roundedResponseRate(
                                                    kpiRow[
                                                      `${R.defaultTo(
                                                        "direct",
                                                        directHaloToggle[lookupKey]
                                                      )}_exposed_response_rate`
                                                    ]
                                                  )}
                                                </td>
                                                <td>
                                                  {roundedResponseRate(
                                                    kpiRow[
                                                      `${R.defaultTo(
                                                        "direct",
                                                        directHaloToggle[lookupKey]
                                                      )}_control_response_rate`
                                                    ]
                                                  )}
                                                </td>
                                                <td>
                                                  {kpiRow !== undefined &&
                                                  kpiRow[
                                                    `${R.defaultTo(
                                                      "direct",
                                                      directHaloToggle[lookupKey]
                                                    )}_exposed_response_rate`
                                                  ] !== undefined &&
                                                  kpiRow[
                                                    `${R.defaultTo(
                                                      "direct",
                                                      directHaloToggle[lookupKey]
                                                    )}_control_response_rate`
                                                  ] !== undefined ? (
                                                    <NumberFormatter
                                                      value={
                                                        kpiRow[
                                                          `${R.defaultTo(
                                                            "direct",
                                                            directHaloToggle[lookupKey]
                                                          )}_exposed_response_rate`
                                                        ] /
                                                          kpiRow[
                                                            `${R.defaultTo(
                                                              "direct",
                                                              directHaloToggle[lookupKey]
                                                            )}_control_response_rate`
                                                          ] -
                                                        1
                                                      }
                                                      type={"%"}
                                                      decimals={0}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                      <div className="chartData">
                                        <AutoSizer>
                                          {({ width, height }) => (
                                            <ComposedChart
                                              data={lagTable}
                                              width={width}
                                              height={height}
                                              margin={{ bottom: 30 }}
                                            >
                                              <Legend
                                                verticalAlign="bottom"
                                                height={36}
                                                iconType="circle"
                                                align="center"
                                                wrapperStyle={{
                                                  fontSize: "12px",
                                                  fontWeight: 500,
                                                }}
                                              />
                                              <RechartsTooltips content={<CustomTooltip />} />
                                              <Bar
                                                dataKey={`${R.defaultTo(
                                                  "direct",
                                                  directHaloToggle[
                                                    `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                                  ]
                                                )}_exposed_response_rate`}
                                                name={"Treatment Response Rate"}
                                                fill={primary}
                                                barSize={20}
                                                shape={
                                                  <ErrorBar
                                                    prefix={`${R.defaultTo(
                                                      "direct",
                                                      directHaloToggle[
                                                        `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                                      ]
                                                    )}_exposed`}
                                                  />
                                                }
                                              />
                                              <Bar
                                                dataKey={`${R.defaultTo(
                                                  "direct",
                                                  directHaloToggle[
                                                    `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                                  ]
                                                )}_control_response_rate`}
                                                name={"Control Response Rate"}
                                                fill={secondary}
                                                barSize={20}
                                                shape={
                                                  <ErrorBar
                                                    prefix={`${R.defaultTo(
                                                      "direct",
                                                      directHaloToggle[
                                                        `${elem[0][0].descriptive_text}_${elem[0][0].date_range}_${lagTable[0].lag}`
                                                      ]
                                                    )}_control`}
                                                  />
                                                }
                                              />
                                              <YAxis
                                                tickLine={false}
                                                axisLine={false}
                                                tickFormatter={number =>
                                                  roundedResponseRate(number).split(" ")[0]
                                                }
                                                width={90}
                                                label={{
                                                  value: "per 100K",
                                                  angle: -90,
                                                  position: "outside",
                                                }}
                                                type="number"
                                                domain={[0, dataMax !== undefined ? dataMax : 1]}
                                              />
                                              <XAxis dataKey="kpi_label" />
                                            </ComposedChart>
                                          )}
                                        </AutoSizer>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })
            )
          ) : (
            <FullPageSpinner />
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default StreamingExperiments;
