import "./RevenueImpact.scss";
import React, { useCallback, useEffect, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import Papa from "papaparse";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG, downloadJSONToCSV } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown, InfoTooltip } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from "recharts";
import { Brand80, Brand50, Brand40, Neutral300 } from "../../utils/colors";
import { currencyFormatter } from "../../MMM/MMMUtils";
import {
  currFormat,
  groupDataByCadence,
  percFormat,
  standardizeMetricNameOriginal,
} from "../BrandImpact/BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface RevenueImpactProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

export const RevenueImpact: React.FC<RevenueImpactProps> = ({ company, groupByMetric }) => {
  const [weeklySpendAreaData, setWeeklySpendAreaData] = useState<any[]>([]);
  const [revenueDecompositionData, setRevenueDecompositionData] = useState<any[]>([]);
  const [overviewData, setOverviewData] = useState<any[]>([]);

  useEffect(() => {
    const fetchLineChart = async () => {
      try {
        let totalRevenueDecompositionResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/trailing_est.csv"
        );
        const totalRevenueDecompositionText = await totalRevenueDecompositionResponse.text();
        const { data: totalRevenueDecompositionParsed }: Papa.ParseResult<any> = Papa.parse(
          totalRevenueDecompositionText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );

        const totalRevenueDecompositionFixed = totalRevenueDecompositionParsed.map((item: any) => ({
          name: "revenue",
          MediaDrivenBrandEquity: item.paid_media_rev + item.spend_induced_brand_rev,
          NonMediaDrivenBrandEquity: item.brand_rev - item.spend_induced_brand_rev,
          OtherFactors:
            item.revenue -
            (item.paid_media_rev +
              item.spend_induced_brand_rev +
              item.brand_rev -
              item.spend_induced_brand_rev),
          MediaDrivenBrandEquityPercent:
            (item.paid_media_rev + item.spend_induced_brand_rev) /
            (item.paid_media_rev +
              item.spend_induced_brand_rev +
              (item.brand_rev - item.spend_induced_brand_rev) +
              (item.revenue -
                (item.paid_media_rev +
                  item.spend_induced_brand_rev +
                  item.brand_rev -
                  item.spend_induced_brand_rev))),
          NonMediaDrivenBrandEquityPercent:
            (item.brand_rev - item.spend_induced_brand_rev) /
            (item.paid_media_rev +
              item.spend_induced_brand_rev +
              (item.brand_rev - item.spend_induced_brand_rev) +
              (item.revenue -
                (item.paid_media_rev +
                  item.spend_induced_brand_rev +
                  item.brand_rev -
                  item.spend_induced_brand_rev))),
          OtherFactorsPercent:
            (item.revenue -
              (item.paid_media_rev +
                item.spend_induced_brand_rev +
                item.brand_rev -
                item.spend_induced_brand_rev)) /
            (item.paid_media_rev +
              item.spend_induced_brand_rev +
              (item.brand_rev - item.spend_induced_brand_rev) +
              (item.revenue -
                (item.paid_media_rev +
                  item.spend_induced_brand_rev +
                  item.brand_rev -
                  item.spend_induced_brand_rev))),
        }));
        setRevenueDecompositionData(totalRevenueDecompositionFixed);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    const fetchModelOverviewData = async () => {
      try {
        let overviewResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/stage_1_topline.csv"
        );
        const overviewText = await overviewResponse.text();
        const { data: overviewParsed }: Papa.ParseResult<any> = Papa.parse(overviewText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });

        const filteredData = overviewParsed.filter(
          item => item.metric === standardizeMetricNameOriginal(groupByMetric)
        );
        setOverviewData(filteredData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    const fetchAreaChartData = async () => {
      try {
        let areaTimeSeriesResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main/out/revenue/time_series_decomp.csv"
        );
        const areaTimeSeriesText = await areaTimeSeriesResponse.text();
        const { data: areaTimeSeriesParsed }: Papa.ParseResult<any> = Papa.parse(
          areaTimeSeriesText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );

        const mediaChannels = [
          "paid_display",
          "paid_search_nb",
          "paid_shopping_nb",
          "meta",
          "tik_tok",
          "streaming_video",
          "linear_television",
          "affiliate",
        ];

        const nonMediaChannels = ["buzz_p", "ad_awareness_p", "awareness_p", "consideration_p"];

        const aggregated: {
          [key: string]: { PaidMedia: number; NonMedia: number; Other: number };
        } = {};
        areaTimeSeriesParsed.forEach((row: any) => {
          const { date, channel, value } = row;
          if (!aggregated[date]) {
            aggregated[date] = { PaidMedia: 0, NonMedia: 0, Other: 0 };
          }
          if (mediaChannels.includes(channel)) {
            aggregated[date].PaidMedia += value;
          } else if (nonMediaChannels.includes(channel)) {
            aggregated[date].NonMedia += value;
          } else if (channel === "Other") {
            if (value >= 0) {
              aggregated[date].Other += value;
            } else {
              aggregated[date].Other += 0;
            }
          }
        });
        const aggregatedArray = Object.keys(aggregated).map(date => ({
          date: date,
          Media: aggregated[date].PaidMedia,
          NonMedia: aggregated[date].NonMedia,
          Other: aggregated[date].Other,
        }));
        const sortedAreaChartData = aggregatedArray.sort((a, b) => a.date.localeCompare(b.date));
        const groupedData = groupDataByCadence(sortedAreaChartData, "Week");
        setWeeklySpendAreaData(groupedData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };
    fetchModelOverviewData();
    fetchAreaChartData();
    fetchLineChart();
  }, [groupByMetric]);

  const excelDownloadWeeklyBrandHealth = useCallback(() => {
    exportToExcel(revenueDecompositionData, "weekly_brand_health");
  }, [revenueDecompositionData]);

  const excelDownloadWeeklySpend = useCallback(() => {
    exportToExcel(weeklySpendAreaData, "weekly_spend");
  }, [weeklySpendAreaData]);

  const excelDownloadModelOverview = useCallback(() => {
    exportToExcel(overviewData, "model_overview");
  }, [overviewData]);

  const pngDownloadWeeklyBrandHealth = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents", "weekly_brand_health");
  }, []);

  const pngDownloadWeeklySpend = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents .areaChart", "weekly_spend");
  }, []);

  const csvDownloadModelOverview = useCallback(() => {
    downloadJSONToCSV(overviewData, `${company}_model_overview`);
  }, [overviewData, company]);

  return (
    <WidgetContainer
      collapsible
      header={
        <div>
          {" "}
          How does our brand equity
          <MoreInfo size="reg">
            Brand equity is a combination of multiple underlying brand health metrics. We use brand
            equity to evaluate the combined impact of the brand health metrics on [KPI].
          </MoreInfo>
          impact Revenue?
        </div>
      }
      subHeader={
        <>
          Attribution of revenue to brand equity based on model parameter estimates.
          <MoreInfo rightLabel="More info" size="sm">
            Calculated for each point in time as (level of [KPI] at that point in time) - (level of
            media investments at that point in time) x (model parameter estimates of media impact on
            brand equity) x (model parameter estimates of brand equity impact on [KPI])
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(overviewData) &&
        !R.isEmpty(revenueDecompositionData) &&
        !R.isEmpty(weeklySpendAreaData) && (
          <div className="revenueImpact">
            <div className="revenueImpactLeft">
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklyBrandHealth, pngDownloadWeeklyBrandHealth]}
                  />
                }
                title="Total Revenue Decomposition"
                titleAfterDashText="Trailing Twelve Months (TTM)"
              >
                <div className="barChartHoldings">
                  <div className="titleContainer">
                    <div className="titleText">
                      {" "}
                      Total:{" "}
                      {currFormat(
                        revenueDecompositionData[0].MediaDrivenBrandEquity +
                          revenueDecompositionData[0].NonMediaDrivenBrandEquity +
                          revenueDecompositionData[0].OtherFactors,
                        0
                      ).slice(0, -1)}{" "}
                    </div>
                    <div className="mText">M</div>
                  </div>
                  <div className="topSegment"></div>
                  <div className="topOfBarchartLine"></div>
                  <ResponsiveContainer width="100%" height={65}>
                    <BarChart
                      data={revenueDecompositionData}
                      margin={{
                        top: 4.4,
                        right: 10,
                      }}
                      layout="vertical"
                    >
                      <XAxis type="number" hide={true} />
                      <YAxis type="category" dataKey="name" hide={true} />
                      <Bar dataKey="MediaDrivenBrandEquity" stackId="a" fill={Brand80} />
                      <Bar dataKey="NonMediaDrivenBrandEquity" stackId="a" fill={Brand50} />
                      <Bar dataKey="OtherFactors" stackId="a" fill={Neutral300} />
                    </BarChart>
                  </ResponsiveContainer>
                  <div className="barChartLegend">
                    <div className="legend">
                      <div className="topRow">
                        <div className="barOne"></div>
                        <div className="titleText">
                          {currFormat(revenueDecompositionData[0].MediaDrivenBrandEquity, 0).slice(
                            0,
                            -1
                          )}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          (
                          {percFormat(
                            revenueDecompositionData[0].MediaDrivenBrandEquityPercent * 100,
                            0
                          )}
                          )
                        </div>
                      </div>
                      <div className="bottomRow">Media-Driven Brand Equity</div>
                    </div>
                    <div className="legend">
                      <div className="topRow">
                        <div className="barTwo"></div>
                        <div className="titleText">
                          {currFormat(
                            revenueDecompositionData[0].NonMediaDrivenBrandEquity,
                            0
                          ).slice(0, -1)}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          (
                          {percFormat(
                            revenueDecompositionData[0].NonMediaDrivenBrandEquityPercent * 100,
                            0
                          )}
                          )
                        </div>
                      </div>
                      <div className="bottomRow">Non-Media-Driven Brand Equity</div>
                    </div>
                    <div className="legend">
                      <div className="topRow">
                        <div className="barThree"></div>
                        <div className="titleText">
                          {currFormat(revenueDecompositionData[0].OtherFactors, 0).slice(0, -1)}
                        </div>
                        <div className="mText">M</div>
                        <div>
                          ( {percFormat(revenueDecompositionData[0].OtherFactorsPercent * 100, 0)})
                        </div>
                      </div>
                      <div className="bottomRow">
                        Other Factors <InfoTooltip color="#5F6C84">Hello</InfoTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </ChartContainer>
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklySpend, pngDownloadWeeklySpend]}
                  />
                }
                title="Weekly Revenue Decomposition"
                titleAfterDashText="12/20/20 – 12/24/23"
              >
                <AreaChart
                  data={weeklySpendAreaData}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                  dateGrouping="Week"
                  yAxisWidth={150}
                  yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                  tooltipFormatter={val => {
                    if (!val) {
                      return val;
                    }
                    return val < 1000
                      ? currencyFormatter.format(val, 0)
                      : currencyFormatter.format(val, 1);
                  }}
                  tooltipShape="line"
                  colorOverrides={{
                    "Media-Driven Brand Equity": Brand80,
                    "Non-Media-Driven Brand Equity": Brand40,
                    "Other Factors": "#CBD2E1",
                  }}
                  reverseLegend={true}
                  reverseToolTipItems={true}
                  areas={[
                    {
                      name: "Other Factors",
                      dataKey: "Other",
                      toolTip: "Other Factors",
                      toolTipColor: "#5F6C84",
                    },
                    {
                      name: "Non-Media-Driven Brand Equity",
                      dataKey: "NonMedia",
                    },
                    {
                      name: "Media-Driven Brand Equity",
                      dataKey: "Media",
                    },
                  ]}
                  legendTitle="Revenue From:"
                ></AreaChart>
              </ChartContainer>
            </div>
            <div className="revenueImpactRight">
              <ChartContainer
                enableHoverDesign
                title="Takeaways"
                titleAfterDashText="TTM"
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadModelOverview, csvDownloadModelOverview]}
                    menuOptions={["XLSX", "CSV"]}
                  />
                }
              >
                <div className="takeAwaysContainer">
                  <div className="sectionOne">
                    <div className="sectionOneTextContainer">
                      <div className="textLeft">
                        <div>Brand</div>
                        <div>Equity</div>
                      </div>
                      <div className="textMiddle">
                        <MdArrowForward className="icon"></MdArrowForward>
                      </div>
                      <div className="textRight">
                        <div>Incremental</div>
                        <div className="titleTopText">Revenue</div>
                        <div className="dollarImpact">
                          <div className="titleTopText">
                            ~$
                            {Math.round(
                              (revenueDecompositionData[0].MediaDrivenBrandEquity +
                                revenueDecompositionData[0].NonMediaDrivenBrandEquity) /
                                1000000
                            )}
                          </div>
                          <div className="mText">M</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sectionTwo">
                    <div className="sectionTwoTextContainer">
                      <div className="textLeft">
                        <div>Media-Driven</div>
                        <div>Brand Equity</div>
                      </div>
                      <div className="textMiddle">
                        <MdArrowForward className="icon"></MdArrowForward>
                      </div>
                      <div className="textRight">
                        <div>Incremental</div>
                        <div className="titleText">Revenue</div>
                        <div className="dollarImpactBottom">
                          <div className="titleText">
                            ~$
                            {Math.round(
                              revenueDecompositionData[0].MediaDrivenBrandEquity / 1000000
                            )}
                          </div>
                          <div className="mText">M</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sectionThree">
                    <div className="sectionThreeText">
                      {`Brand equity accounts for about ${percFormat(
                        (revenueDecompositionData[0].MediaDrivenBrandEquityPercent +
                          revenueDecompositionData[0].NonMediaDrivenBrandEquityPercent) *
                          100,
                        0
                      )} or ${currFormat(
                        revenueDecompositionData[0].MediaDrivenBrandEquity +
                          revenueDecompositionData[0].NonMediaDrivenBrandEquity,
                        0
                      ).slice(0, -1)}M of the total trailing twelve
                      months revenue after controlling for other factors.`}
                    </div>
                  </div>
                </div>
              </ChartContainer>
            </div>
          </div>
        )}
    </WidgetContainer>
  );
};

export default RevenueImpact;
