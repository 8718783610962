import React from "react";

import { RowType } from "./pendingChangesUtils";
import { Header } from "../StickyTable";

import "./PendingChangesPanel.scss";

interface PendingDeletedRowProps<R> {
  oldRow: R;
  columns: Header[];
}

const PendingDeletedRow = <R extends RowType>({
  oldRow,
  columns,
}: PendingDeletedRowProps<R>): JSX.Element => {
  return (
    <div className="pendingChangesRowContainer pendingDeletedRowContainer">
      {columns.map(col => {
        const { label, field, renderer } = col;
        const oldVal = oldRow[field || ""] || "null";
        return (
          <div key={label}>
            <div className="pendingChangesRow">
              <b className="pendingChangesRowLabel">{label}:</b>
              <div>{renderer ? renderer(oldRow) : oldVal}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PendingDeletedRow;
