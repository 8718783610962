import React from "react";

import * as R from "ramda";

import { Form } from "react-bootstrap";

import { parseInputToInt } from "../utils/data";

import { CheckBox, BPMToggleButton } from "../Components";

import { SlideType, SettingsComponentProps, SlideContext } from "./slidesTypes";
import { SlideState } from "./slideTemplateConstants";

const DEFAULT_PLANS_LINES_PER_SLIDE = 15;
const DEFAULT_PLANS_NUM_SLIDES = 3;
const MAX_TABLE_SIZE = 380;

enum SortColumn {
  NETWORK = "network",
  LAST_WEEK = "lastWeek",
  THIS_WEEK = "thisWeek",
  NEXT_WEEK = "nextWeek",
}

enum SortField {
  SPEND = "spend",
  CPM = "cpm",
}
enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

interface SortInfo {
  column: SortColumn;
  field: SortField;
  order: SortOrder;
}

export interface StreamingPlansSlideState {
  numSlides: number;
  linesPerSlide: number;
  sortBy: SortInfo;
  adServing: boolean;
  commission: boolean;
  audio: boolean;
  display: boolean;
  lineHeight: number;
}

// TODO: filter
// TODO: per-slide subtotals?
// TODO: totals on each slide?
interface StreamingPlansSlideData {
  slides: number;
  linesPerSlide: number;
  week: string;
  sortBy: SortInfo;
  audio: boolean;
  adServing: boolean;
  commission: boolean;
  lineHeight: number;
}

class StreamingPlansSlide extends SlideType {
  static typeKey = "streamingPlans";
  static displayKey = "Streaming Plans";
  static defaultState: StreamingPlansSlideState = {
    numSlides: DEFAULT_PLANS_NUM_SLIDES,
    linesPerSlide: DEFAULT_PLANS_LINES_PER_SLIDE,
    sortBy: {
      column: SortColumn.THIS_WEEK,
      field: SortField.SPEND,
      order: SortOrder.DESC,
    },
    adServing: false,
    commission: false,
    audio: false,
    display: false,
    lineHeight: 32,
  };

  static SettingsComponent: React.FC<SettingsComponentProps<StreamingPlansSlideState>> = React.memo(
    ({ state, setState }) => {
      const { numSlides, linesPerSlide, sortBy, adServing, commission, lineHeight } = state;

      return (
        <div className="settingsBox">
          <div>
            <Form.Group>
              <Form.Label>Lines per Slide</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${linesPerSlide}`}
                onChange={e => setState({ linesPerSlide: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Line Height</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={20}
                max={MAX_TABLE_SIZE / linesPerSlide}
                value={`${lineHeight}`}
                onChange={e => setState({ lineHeight: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Maximum Number of Slides</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${numSlides}`}
                onChange={e => setState({ numSlides: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Sort Column</Form.Label>
              <BPMToggleButton
                block
                bordered
                options={[
                  {
                    key: SortColumn.NETWORK,
                    label: "Network",
                  },
                  {
                    key: SortColumn.LAST_WEEK,
                    label: "Last week",
                  },
                  {
                    key: SortColumn.THIS_WEEK,
                    label: "This week",
                  },
                  {
                    key: SortColumn.NEXT_WEEK,
                    label: "Next week",
                  },
                ]}
                selectedOption={sortBy.column}
                onChange={key =>
                  setState(existing => ({
                    ...existing,
                    sortBy: {
                      ...existing.sortBy,
                      column: key,
                    },
                  }))
                }
              />
            </Form.Group>
          </div>

          {sortBy.column !== SortColumn.NETWORK && (
            <div>
              <Form.Group>
                <Form.Label>Sort By</Form.Label>
                <BPMToggleButton
                  block
                  bordered
                  options={[
                    {
                      key: SortField.SPEND,
                      label: "Spend",
                    },
                    {
                      key: SortField.CPM,
                      label: "CPM",
                    },
                  ]}
                  selectedOption={sortBy.field}
                  onChange={key =>
                    setState(existing => ({
                      ...existing,
                      sortBy: {
                        ...existing.sortBy,
                        field: key,
                      },
                    }))
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Sort Direction</Form.Label>
                <BPMToggleButton
                  block
                  bordered
                  options={[
                    {
                      key: SortOrder.ASC,
                      label: "Ascending",
                    },
                    {
                      key: SortOrder.DESC,
                      label: "Descending",
                    },
                  ]}
                  selectedOption={sortBy.order}
                  onChange={key =>
                    setState(existing => ({
                      ...existing,
                      sortBy: {
                        ...existing.sortBy,
                        order: key,
                      },
                    }))
                  }
                />
              </Form.Group>
            </div>
          )}
          <div>
            <Form.Group className="checkboxItem">
              <div>Ad Serving</div>
              <CheckBox checked={adServing} onCheck={adServing => setState({ adServing })} />
            </Form.Group>
            <div className="checkboxItem">
              <div>Commission</div>
              <CheckBox checked={commission} onCheck={commission => setState({ commission })} />
            </div>
          </div>
        </div>
      );
    }
  );

  generate = (context: SlideContext, state: SlideState): Promise<StreamingPlansSlideData> => {
    let ourState = state as StreamingPlansSlideState;
    return Promise.resolve({
      slides: ourState.numSlides,
      week: context.week,
      ...R.pick(
        ["linesPerSlide", "sortBy", "audio", "display", "commission", "adServing", "lineHeight"],
        ourState
      ),
    });
  };
}

export default StreamingPlansSlide;
