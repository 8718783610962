import React, { useState, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { download } from "../utils/download-utils";
import { Router } from "@reach/router";
import { useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import * as CompanyRedux from "../redux/company";
import { useTabbedNav } from "../utils/hooks/useNav";
import useLocation from "../utils/hooks/useLocation";
import {
  S3SignedUrlFetch,
  StreamingUtilsLambdaFetch,
  awaitJSON,
  pollS3,
} from "../utils/fetch-utils";
import { Page, KpiPicker, BPMButton, DownloadDropdown } from "../Components";
import StageOneDiagnostics from "./StageOneDiagnostics";
import StageTwoDiagnostics from "./StageTwoDiagnostics";

import "./LinearMLDiagnostics.scss";

const DATE_FORMAT = "M/dd/yyyy h:mm a";

const CSV_MIME_TYPE = "text/csv";

const STAGE_ONE_KEY = "stage1";
const STAGE_TWO_KEY = "stage2";

const BranchPickerModal = ({
  branch,
  setBranch,
  showBranchPickerModal,
  setShowBranchPickerModal,
}) => {
  const [modalBranch, setModalBranch] = useState(branch);
  return (
    <Modal
      size="lg"
      keyboard={false}
      show={showBranchPickerModal}
      onHide={() => setShowBranchPickerModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="sm"
          className="branchNameInput"
          value={modalBranch}
          onChange={e => setModalBranch(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <BPMButton
          variant="success"
          onClick={() => {
            setShowBranchPickerModal(false);
            setBranch(modalBranch);
          }}
        >
          Update Branch
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(({ navigate }) => {
  const setError = useSetError();
  const { company } = useLocation();
  const [kpi, setKpi] = useState(useSelector(CompanyRedux.initialKpiSelector(company)));
  const companyInfo = CompanyRedux.useCompanyInfo();
  const [timestamp, setTimestamp] = useState();
  const [branch, setBranch] = useState("v4");
  const [showBranchPickerModal, setShowBranchPickerModal] = useState(false);
  const [fetchingCSV, setFetchingCSV] = useState(false);
  const [downloadingKpiDebug, setDownloadingKpiDebug] = useState(false);

  let downloadCSV = useCallback(async () => {
    setFetchingCSV(true);
    try {
      let filename = `${kpi}.csv`;
      let response = await S3SignedUrlFetch(
        `bpm-ml-data/${branch}/${kpi}/latest/mldiagnostics_v4_stage1_output_consolidated.csv.gz`
      );
      if (response.status !== 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      let blob = await response.blob();
      download(blob, filename, CSV_MIME_TYPE);
    } catch (e) {
      setError({
        message: "Could not find the file for the KPI.",
      });
    }
    setFetchingCSV(false);
  }, [kpi, branch, setError]);

  const downloadKpiDebug = useCallback(async () => {
    try {
      setDownloadingKpiDebug(true);
      const debugS3Response = await StreamingUtilsLambdaFetch("/kpi_debug_zip", {
        params: { kpi },
      });
      const debugS3Info = await awaitJSON(debugS3Response);
      await pollS3({
        bucket: debugS3Info.Bucket,
        mimeType: "application/zip",
        filename: debugS3Info.Key,
        overloadFilename: `${kpi}.zip`,
      });
    } catch (e) {
      setError({
        message: `Could not download KPI Debug. Error: ${e.message}`,
      });
    }

    setDownloadingKpiDebug(false);
  }, [kpi, setError]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "linear/diagnostics",
    defaultKey: STAGE_ONE_KEY,
  });
  return (
    <Page
      title="Linear ML Diagnostics"
      pageType="Linear ML Diagnostics"
      minWidth={800}
      navs={[
        {
          label: "Stage One",
          key: STAGE_ONE_KEY,
        },
        {
          label: "Stage Two",
          key: STAGE_TWO_KEY,
        },
      ]}
      selectedNav={tab}
      onNav={goToTab}
      actions={
        <div className="mlDiagnosticsActions">
          {timestamp ? (
            <div className="lastCompletionDate">{`Last Completion: ${R.pipe(
              Dfns.parseISO,
              Dfns.format(DATE_FORMAT)
            )(timestamp)} Pacific`}</div>
          ) : (
            ""
          )}
          <BPMButton
            variant="outline-primary"
            bordered="true"
            onClick={() => {
              setShowBranchPickerModal(true);
            }}
          >
            {branch}
          </BPMButton>
          <KpiPicker
            disableType
            onChange={newKpi => {
              setKpi(newKpi);
            }}
            defaultKpi={companyInfo.optimization_cid}
          />
          <DownloadDropdown
            onClickOptions={[downloadCSV, downloadKpiDebug]}
            menuOptions={["CSV", "KPI Debug"]}
            disabled={fetchingCSV || downloadingKpiDebug}
          />
          <BranchPickerModal
            showBranchPickerModal={showBranchPickerModal}
            setShowBranchPickerModal={setShowBranchPickerModal}
            branch={branch}
            setBranch={setBranch}
          />
        </div>
      }
    >
      <div className="mlDiagnostics">
        <Router className="fullPageRouter">
          <StageOneDiagnostics kpi={kpi} branch={branch} path={STAGE_ONE_KEY} />
          <StageTwoDiagnostics
            kpi={kpi}
            branch={branch}
            path={STAGE_TWO_KEY}
            setTimestamp={setTimestamp}
          />
          <StageOneDiagnostics kpi={kpi} branch={branch} default />
        </Router>
      </div>
    </Page>
  );
});
