import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../Components";
import { MdWarning } from "react-icons/md";
import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { WtoLambdaFetch } from "../utils/fetch-utils";
import { formatRules, trimBackslashes, isSavedDraft, formatDefaultId } from "./WtoUtils";
import { Plover } from "@blisspointmedia/bpm-types/dist/webTrafficOptimization";

export interface WtoActivateModalProps {
  show: boolean;
  closeModal: () => void;
  data: Plover;
  userEmail: string;
  fetchPlovers: () => void;
  action: string;
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
}

export const WtoActivateModal: React.FC<WtoActivateModalProps> = ({
  show,
  closeModal,
  data,
  userEmail,
  fetchPlovers,
  action,
  setBreadcrumbPath,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const confirmActivation = async () => {
    if (data.rules.length < 1) {
      setError({
        title: "Activation Error",
        message:
          "No rules detected for this test; at least 1 rule is required for a test to go live",
      });
    } else {
      setLoading(true);
      try {
        if (action.toLocaleLowerCase() === "activate route") {
          await WtoLambdaFetch(`/plover/${data.id}`, {
            method: "PATCH",
            body: {
              status: "production",
            },
            params: {
              email: userEmail,
            },
          });
        } else if (action.toLocaleLowerCase() === "update route") {
          await WtoLambdaFetch(`/plover/${data.id}`, {
            method: "PATCH",
            body: {
              rules: formatRules(data.rules),
            },
            params: {
              email: userEmail,
            },
          });
        } else if (action.toLocaleLowerCase() === "create route") {
          await WtoLambdaFetch(`/plover${isSavedDraft(data) ? `/${data.id}` : ""}`, {
            method: isSavedDraft(data) ? "PATCH" : "POST",
            body: {
              company: cid,
              test_name: data.test_name,
              utm_test_id: data.utm_test_id,
              default_url: data.default_url,
              default_id: formatDefaultId(data.default_id),
              plover_url: data.plover_url,
              folder_path: trimBackslashes(data.folder_path),
              notes: data.notes,
              variants: data.variants,
              domain: data.domain,
              rules: formatRules(data.rules),
              status: "production",
            },
            params: {
              email: userEmail,
            },
          });
        }
        fetchPlovers();
        closeModal();
        setBreadcrumbPath([]);
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to activate route. Error: ${error.message}`,
          reportError: error,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={() => (loading ? null : closeModal())}>
      <Modal.Header closeButton>
        <Modal.Title>{action}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner size={50} />
        ) : (
          <div className="infoBox warning">
            <div className="icon">
              <MdWarning color="orange" className="alertIcon" />
            </div>
            <p className="message">
              Are you sure you want to{" "}
              {action.toLocaleLowerCase() === "update route" ? "update" : "launch"} the following
              route: <span>{data.test_name}</span>?
              <br />
              <br />
              <i>
                {action.toLocaleLowerCase() === "update route"
                  ? "(Changes will be live after deployment completes.)"
                  : "IMPORTANT: Please make sure that no LIVE routes are using the same Route URL."}
              </i>
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={loading} type={ButtonType.FILLED} onClick={confirmActivation}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WtoActivateModal;
