import "./MetricsTable.scss";
import {
  DIVERGING_COLOR_SCHEME,
  mixColors,
  rgbToHex,
  SEQUENTIAL_COLOR_SCHEME,
} from "../../utils/colors";
import { mergeNumberObjects } from "../../utils/data";
import { SortInfo } from "@blisspointmedia/bpm-types/dist/Performance";
import * as C from "@blisspointmedia/bpm-types/dist/CommercePerformance";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as SOCIAL from "@blisspointmedia/bpm-types/dist/SocialPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import {
  Column,
  DimensionColumn,
  HeatMapData,
  PerformanceData,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { StateSetter } from "../../utils/types";
import { useCallback } from "react";
import { FilterPaneState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import { Placement } from "../../Components";

export interface GlossaryItem {
  term: string;
  definition: string;
}

export interface CellData {
  backgroundColor?: string;
  content?: JSX.Element; // Pass in custom elements
  label: string;
  overlayText?: string;
  url?: string;
  value: number | string;
}

export interface SuperHeader {
  leftDivider: boolean;
  rightDivider: boolean;
  text: string;
}

export const CDN = "https://cdn.blisspointmedia.com";

export const getCreativeAsset = (company: string, file: string): string =>
  `${CDN}/creativeAssets/assets/${company}/${file}`;
export const getCreativeThumbnail = (company: string, isci: string): string =>
  `${CDN}/creativeAssets/thumbnails/${company}/${isci}.png`;

export const TEXT_SIZE_MAP = {
  Metrics: "regular",
  SparkChart: "extraSmall",
  Widget: "small",
};
export const TEXT_FONT_SIZE_MAP = {
  Metrics: 14,
  SparkChart: 12,
  Widget: 12,
};

export type DimensionMap =
  | Record<L.Dimension, string>
  | Record<S.PerformanceDimension, string>
  | Record<Y.Dimension, string>
  | Record<CC.Dimension, string>
  | Record<SOCIAL.Dimension, string>
  | Record<C.Dimension, string>;

export const DEFAULT_GET_DIMENSION_CELL = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const value = dimensionData[dimensionHeader.dimensionVarName];
  return {
    label: value,
    value,
  };
};

const MAX_DECIMALS = 4;

export const resolveDecimals = (decimals: number): number => Math.min(MAX_DECIMALS, decimals);

export const toPrettyCPX: Formatter = (cpx: number, decimals = 0, zeroLabel = "--", min = 0.01) =>
  cpx && !isNaN(cpx) && cpx >= min
    ? cpx.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: cpx >= 15 ? 0 : 2,
        maximumFractionDigits: cpx >= 15 ? 0 : 2,
      })
    : zeroLabel;
export const toPrettySpend: Formatter = (
  spend: number,
  decimals = 0,
  zeroLabel = "--",
  min = 0.01
) =>
  spend && !isNaN(spend) && spend >= min
    ? spend.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: resolveDecimals(
          spend > 0 && spend < 1 && decimals === 0 ? 2 : decimals
        ),
        maximumFractionDigits: resolveDecimals(
          spend > 0 && spend < 1 && decimals === 0 ? 2 : decimals
        ),
      })
    : zeroLabel;
export const toPrettyNumber: Formatter = (num: number, decimals = 0, zeroLabel = "--", min = 0) =>
  num && !isNaN(num) && num >= min
    ? num.toLocaleString("en-US", {
        minimumFractionDigits: resolveDecimals(decimals),
        maximumFractionDigits: resolveDecimals(decimals),
      })
    : zeroLabel;
export const toPrettyPercent: Formatter = (num: number, decimals = 0, zeroLabel = "--", min = 0) =>
  num && !isNaN(num) && num >= min
    ? `${(num * 100.0).toLocaleString("en-US", {
        minimumFractionDigits: resolveDecimals(decimals),
        maximumFractionDigits: resolveDecimals(decimals),
      })}%`
    : zeroLabel;
export const toPretty1000sInteger: Formatter = (
  num: number,
  decimals = 0,
  zeroLabel = "--",
  min = 0
) =>
  num && !isNaN(num) && num / 1000.0 > 0 && num >= min
    ? ((num || 0) / 1000).toLocaleString("en-US", {
        minimumFractionDigits: resolveDecimals(decimals),
        maximumFractionDigits: resolveDecimals(decimals),
      })
    : zeroLabel;

interface Formatter {
  (num: number, decimals?: number, zeroLabel?: string, min?: number, row?: PerformanceData): string;
}

export interface ColumnMetaData {
  aggregator?: (agg: Record<string, string | number>) => number | string; // We use this to calculate the total in the table
  category?: string; // Used for data columns
  contentType?: "date" | "logo" | "number" | "text" | "thumbnail"; // Used for dimensions
  iconStyle?: "logo" | "thumbnail";
  decimals?: number;
  dimensionVarName?:
    | L.Dimension
    | S.PerformanceDimension
    | Y.Dimension
    | CC.Dimension
    | SOCIAL.Dimension
    | C.Dimension; // Used for dimensions
  displayName: string; // Name displayed in the column header, can be overridden by the column header
  fetchGetter?: (
    // We use this to calculate the value in the table if needed (done per cell)
    fetch: Record<string, string | number>,
    aggregatedData: Record<string, any>
  ) => number | string;
  formatValue?: Formatter;
  minIsBest?: boolean;
  overlayText?: string;
  overlayPlacement?: Placement;
  requiredTotalsColumns?: (string | { dataVarName: string; fetchGetter: Function })[]; // If we need specific totals that get used in aggregator, we can specify them here
}

export type ColumnMetaDataMap =
  | Partial<Record<L.ColumnType, ColumnMetaData>>
  | Partial<Record<L.DimensionColumnType, ColumnMetaData>>
  | Partial<Record<S.ColumnType, ColumnMetaData>>
  | Partial<Record<S.PerformanceDimensionColumnType, ColumnMetaData>>
  | Partial<Record<Y.ColumnType, ColumnMetaData>>
  | Partial<Record<Y.DimensionColumnType, ColumnMetaData>>
  | Partial<Record<CC.ColumnType, ColumnMetaData>>
  | Partial<Record<CC.DimensionColumnType, ColumnMetaData>>
  | Partial<Record<SOCIAL.ColumnType, ColumnMetaData>>
  | Partial<Record<SOCIAL.DimensionColumnType, ColumnMetaData>>
  | Partial<Record<C.ColumnType, ColumnMetaData>>
  | Partial<Record<C.DimensionColumnType, ColumnMetaData>>;

export const constructTableData = (
  columnMetaDataMap: ColumnMetaDataMap,
  dataHeaders: Column[],
  dimensionHeaders: DimensionColumn[],
  getDimensionCell: (
    dimensionData: DimensionMap,
    dimensionHeader: DimensionColumn,
    ...args // Can pass in creative map, network map etc.
  ) => CellData,
  dataFetchKey: (dataHeader: Column) => string,
  performanceData: PerformanceData[]
): {
  aggregateData: CellData[];
  dimensionData: Record<string, CellData>[];
  tableData: CellData[][];
} => {
  const aggregateData: CellData[] = []; // return type
  const aggregatedData: Record<string, any>[] = [];
  const dimensionData: Record<string, CellData>[] = [];
  const tableData: CellData[][] = [];
  // First go through the data and aggregate totals, we don't want to immediately
  // push to tableData because some columns might depend on totals.
  for (let columnIndex = 0; columnIndex < dataHeaders.length; ++columnIndex) {
    const dataHeader = dataHeaders[columnIndex];
    const columnMetaData =
      columnMetaDataMap &&
      dataHeaders &&
      dataHeader.dataVarName &&
      columnMetaDataMap[dataHeader.dataVarName]
        ? columnMetaDataMap[dataHeader.dataVarName]
        : undefined;
    const { requiredTotalsColumns } = R.defaultTo(
      {
        aggregator: undefined,
      },
      columnMetaData
    );
    for (let rowIndex = 0; rowIndex < performanceData.length; ++rowIndex) {
      const performanceRow = performanceData[rowIndex];
      const fetchKey = dataFetchKey(dataHeader);
      const fetch = R.defaultTo({}, performanceRow.fetches[fetchKey]);
      if (columnIndex === 0) {
        const dimensionRow: any = {};
        for (const dimensionHeader of dimensionHeaders) {
          const dimensionCell = getDimensionCell(
            performanceRow.dimensions as DimensionMap,
            dimensionHeader
          );
          dimensionRow[dimensionHeader.dimensionTypeName] = { ...dimensionCell };
        }
        dimensionData.push(dimensionRow);
      }
      const totals = {};
      if (requiredTotalsColumns) {
        for (const col of requiredTotalsColumns) {
          if (col === "numRows") {
            totals[col] = performanceData.length;
          } else if (typeof col === "string") {
            totals[col] = fetch[col];
          } else {
            totals[col.dataVarName] = col.fetchGetter(fetch);
          }
        }
      } else {
        totals[dataHeader.dataVarName] = fetch[dataHeader.dataVarName];
      }
      if (rowIndex === 0) {
        aggregatedData.push(totals);
      } else {
        aggregatedData[columnIndex] = mergeNumberObjects(aggregatedData[columnIndex], totals);
        if (aggregatedData[columnIndex] && aggregatedData[columnIndex].numRows) {
          aggregatedData[columnIndex].numRows = performanceData.length;
        }
      }
    }
  }
  // Now that we have totals, we can go through the data again and push to tableData
  // and bottomData
  for (let columnIndex = 0; columnIndex < dataHeaders.length; ++columnIndex) {
    const dataHeader = dataHeaders[columnIndex];
    const columnMetaData =
      columnMetaDataMap &&
      dataHeaders &&
      dataHeader &&
      dataHeader.dataVarName &&
      columnMetaDataMap[dataHeader.dataVarName]
        ? columnMetaDataMap[dataHeader.dataVarName]
        : undefined;
    const { aggregator, fetchGetter, formatValue } = R.defaultTo(
      {
        aggregator: undefined,
        fetchGetter: undefined,
        formatValue: undefined,
      },
      columnMetaData
    );
    const decimals = R.defaultTo(
      0,
      R.defaultTo(columnMetaData ? columnMetaData.decimals : 0, dataHeader.decimals)
    );
    const aggregatedCol = R.defaultTo({}, aggregatedData[columnIndex]);
    const value = aggregator ? aggregator(aggregatedCol) : aggregatedCol[dataHeader.dataVarName];
    const label = formatValue
      ? formatValue(value, decimals)
      : `${typeof value === "number" && value === 0 ? "--" : value}`;
    const aggColData = {
      label,
      value,
    };
    aggregateData.push(aggColData);
    for (let rowIndex = 0; rowIndex < performanceData.length; ++rowIndex) {
      const performanceRow = performanceData[rowIndex];
      const fetchKey = dataFetchKey(dataHeader);
      const fetch = R.defaultTo({}, performanceRow.fetches[fetchKey]);
      const value = fetchGetter
        ? fetchGetter(fetch, aggregatedData[columnIndex])
        : fetch[dataHeader.dataVarName];
      const label =
        columnMetaData && columnMetaData.formatValue
          ? columnMetaData.formatValue(value, decimals, undefined, undefined, performanceRow)
          : `${value}`;
      if (columnIndex === 0) {
        tableData.push([
          {
            label,
            value,
          },
        ]);
      } else {
        tableData[rowIndex].push({
          label,
          value,
        });
      }
    }
  }
  return {
    aggregateData,
    dimensionData,
    tableData,
  };
};

export const sortData = (
  dataHeaders: Column[],
  dimensionData: Record<string, CellData>[],
  dimensionDataHeaders: DimensionColumn[],
  sorting: SortInfo[],
  tableData: CellData[][],
  hasDimensionData: boolean
): [Record<string, CellData>[], CellData[][]] => {
  if (R.isNil(sorting) || R.isEmpty(sorting)) {
    return [dimensionData, tableData];
  }
  const combinedData = R.zip(
    hasDimensionData ? dimensionData : [...tableData].map(() => ({})),
    tableData
  );
  const reducedSorting = sorting.reduce((sorts, { id, asc }) => {
    const sorter = (() => {
      const func = asc ? R.ascend : R.descend;
      const dimensionCol = R.find(col => col.id === id, dimensionDataHeaders);
      if (dimensionCol) {
        const getter = row => {
          return row[0][dimensionCol.dimensionTypeName].value;
        };
        return func(getter);
      }
      const i = R.findIndex(col => col.id === id, dataHeaders) || 0;
      const column = dataHeaders[i];
      if (!column) {
        return undefined;
      }
      return func((row: any) => {
        return row[1][i].value;
      });
    })();
    if (sorter) {
      return [...sorts, sorter];
    }
    return sorts;
  }, [] as ((a: any, b: any) => number)[]);
  const sortedCombinedData = R.sortWith(reducedSorting, combinedData);
  const sortedDimensionData = R.map(elem => elem[0], sortedCombinedData);
  const sortedTableData = R.map(elem => elem[1], sortedCombinedData);
  return [sortedDimensionData, sortedTableData];
};
export const getHeatMap = (
  dataHeaders: Column[],
  tableData: CellData[][]
): (HeatMapData | null)[] => {
  const heatMap: (HeatMapData | null)[] = [];
  for (let colIndex = 0; colIndex < dataHeaders.length; ++colIndex) {
    const column = dataHeaders[colIndex];
    if (column.heatMapping) {
      let max = -Infinity;
      let min = Infinity;
      let sum = 0;
      const medianNumbers: number[] = [];
      for (let row of tableData) {
        const val = row[colIndex].value;
        if (typeof val !== "number") {
          continue;
        }
        if (val < min) {
          min = val;
        }
        if (val > max) {
          max = val;
        }
        sum += val;
        medianNumbers.push(val);
      }
      // If the config minimum is bigger than the actual minimum, use that one
      if (!R.isNil(column.heatMapping.min) && column.heatMapping.min > min) {
        ({ min } = column.heatMapping);
      }
      // If the config maximum is smaller than the actual maximum, use that one
      if (!R.isNil(column.heatMapping.max) && column.heatMapping.max < max) {
        ({ max } = column.heatMapping);
      }
      const mean = sum / medianNumbers.length;
      const stdClip = R.isNil(column.heatMapping.stdevClipping)
        ? 1
        : column.heatMapping.stdevClipping;
      if (stdClip > 0) {
        let stdevCalc = 0;
        for (let item of tableData) {
          if (
            typeof item[colIndex].value === "number" &&
            (!column.heatMapping.excludeZeros || item[colIndex].value !== 0)
          ) {
            stdevCalc += ((item[colIndex].value as number) - mean) ** 2;
          }
        }
        const stdev = Math.sqrt(stdevCalc / medianNumbers.length);
        min = Math.max(min, mean - stdev * stdClip);
        max = Math.min(max, mean + stdev * stdClip);
      }
      let midpoint: number;
      switch (column.heatMapping.midpoint) {
        case "MEDIAN":
          midpoint = medianNumbers.length
            ? medianNumbers.sort()[Math.floor(medianNumbers.length / 2)]
            : 0;
          break;
        case "MEAN":
          midpoint = mean;
          break;
        case "CUSTOM":
          midpoint = column.heatMapping?.customMidpoint || 0;
          break;
        default:
          midpoint = (max - min) / 2 + min;
          break;
      }
      heatMap.push({
        max,
        midpoint,
        min,
      });
    } else {
      heatMap.push(null);
    }
  }
  return heatMap;
};

export interface CellDataHeatMapInfo {
  backgroundColor: string;
  outline: string;
}

export const getHeatMapInfo = (
  columnIndex: number,
  columnMetaDataMap: ColumnMetaDataMap,
  dataHeaders: Column[],
  heatMap: (HeatMapData | null)[],
  value: number
): CellDataHeatMapInfo => {
  const heatMapInfo = heatMap[columnIndex];
  const col = dataHeaders[columnIndex] as Column;
  const colHeatMapMetaData = R.defaultTo(
    {
      colorScheme: "sequential",
      excludeZeros: false,
      minIsBest: undefined,
    },
    col.heatMapping
  );
  const colMetaDataMap = R.defaultTo(
    { minIsBest: undefined, contentReplacement: undefined },
    columnMetaDataMap[dataHeaders[columnIndex].dataVarName]
  );
  if (!(heatMap && colHeatMapMetaData && heatMapInfo)) {
    return { backgroundColor: "transparent", outline: "none" };
  }
  const { min, max, midpoint } = heatMapInfo as HeatMapData;
  const { colorScheme, excludeZeros, minIsBest: presetMinIsBest } = colHeatMapMetaData;
  const { minIsBest: columnMinIsBest, contentReplacement } = colMetaDataMap;
  const minIsBest = R.defaultTo(columnMinIsBest, presetMinIsBest);
  if (
    min === max ||
    (excludeZeros && value === 0) ||
    (contentReplacement && value <= contentReplacement.threshold)
  ) {
    return { backgroundColor: "transparent", outline: "none" };
  }
  const left = {
    r: 255,
    g: 255,
    b: 255,
  };
  if (colorScheme && colorScheme === "diverging") {
    let pct = 100;
    let right = {
      r: 255,
      g: 255,
      b: 255,
    };
    if (value > midpoint) {
      pct = (value - midpoint) / (max - midpoint);
      right = minIsBest ? DIVERGING_COLOR_SCHEME.grey : DIVERGING_COLOR_SCHEME.green;
    } else {
      pct = (midpoint - value) / (midpoint - min);
      right = minIsBest ? DIVERGING_COLOR_SCHEME.green : DIVERGING_COLOR_SCHEME.grey;
    }
    pct = Math.max(0, Math.min(1, pct));
    return {
      backgroundColor: rgbToHex(mixColors(left, right, pct)),
      outline: pct < 0.05 ? `1px solid${rgbToHex(DIVERGING_COLOR_SCHEME.midBorder)}` : "none",
    };
  } else if (colorScheme && colorScheme === "sequential") {
    const pct = Math.max(0, Math.min(1, (value - min) / (max - min)));
    const left = SEQUENTIAL_COLOR_SCHEME.lightPurple;
    const right = SEQUENTIAL_COLOR_SCHEME.darkPurple;
    return {
      backgroundColor: rgbToHex(mixColors(left, right, minIsBest ? 1 - pct : pct)),
      outline: "none",
    };
  }
  return {
    backgroundColor: "none",
    outline: "none",
  };
};

export const adjustSupersAfterRemoval = (headers: any[], i: number): any[] =>
  headers.reduce((headers, header) => {
    // If it's a one-column-wide header and that column is the one we're deleting, delete
    // the header
    if (header.start === header.end && header.start === i) {
      return headers;
    }
    // If the header contains the element, then the end position shifts down, but the
    // start position remains the same.
    if (header.start <= i && header.end >= i) {
      return [...headers, { ...header, end: header.end - 1 }];
    }
    // If the header is before this column, add it in and continue
    if (header.end < i) {
      return [...headers, header];
    }
    // At this point we know the header doesn't contain the column and isn't before the
    // column. Thus, it must be after. That means the start and end need to be shifted
    // down because a column before it was removed.
    return [...headers, { ...header, start: header.start - 1, end: header.end - 1 }];
  }, [] as any[]);

export const useSortAdder = (
  setSorting: StateSetter<SortInfo[]>,
  disableMulti = false
): ((id: string) => void) =>
  useCallback(
    (id: string) => {
      //  If a user selects  an already-sorted column, it flips it if it's descending, and removes it otherwise.
      setSorting(sorting => {
        let newSorting: SortInfo[] = [];
        let sawSelf = false;
        for (const sortItem of sorting) {
          if (sortItem.id === id) {
            sawSelf = true;
            // If it's descending, then flip. Otherwise, skip.
            if (!sortItem.asc) {
              newSorting.push({ id, asc: true });
            }
          } else {
            newSorting.push(sortItem);
          }
        }
        if (!sawSelf) {
          if (disableMulti) {
            // If we pick an entirely new column to sort, we want to clear out
            // all the current sorting.
            newSorting = [{ id, asc: false }];
          } else {
            newSorting.push({ id, asc: false });
          }
        }
        return newSorting;
      });
    },
    [disableMulti, setSorting]
  );

export const areFilterStatesEqual = (
  state1: FilterPaneState | null | undefined,
  state2: FilterPaneState | null | undefined
): boolean => {
  if (R.isNil(state1) || R.isNil(state2)) {
    // If one of the state is undefined, we can check that they are both undefined or not
    return state1 === state2;
  }
  if (state1.isAdvanced !== state2.isAdvanced) {
    return false;
  } else if (state1.isAdvanced) {
    return state1.advanced === state2.advanced;
  } else {
    const notMap1 = state1.basic.notMap;
    const notMap2 = state2.basic.notMap;
    if (R.keys(notMap1).length !== R.keys(notMap2).length) {
      return false;
    } else {
      const notMap1Keys = R.keys(notMap1).sort();
      const notMap2Keys = R.keys(notMap2).sort();
      for (let i = 0; i < notMap1Keys.length; i++) {
        const key1 = notMap1Keys[i];
        const key2 = notMap2Keys[i];
        if (key1 !== key2 || notMap1[key1] !== notMap2[key2]) {
          return false;
        }
      }
      const selectedMap1 = state1.basic.selectedMap;
      const selectedMap2 = state2.basic.selectedMap;
      if (R.keys(selectedMap1).length !== R.keys(selectedMap2).length) {
        return false;
      } else {
        const selectedMap1Keys = R.keys(selectedMap1).sort();
        const selectedMap2Keys = R.keys(selectedMap2).sort();
        for (let i = 0; i < selectedMap1Keys.length; i++) {
          const key1 = selectedMap1Keys[i];
          const key2 = selectedMap2Keys[i];
          if (key1 !== key2) {
            return false;
          }
          const dimensionMap1 = selectedMap1[key1];
          const dimensionMap2 = selectedMap2[key2];
          if (R.keys(dimensionMap1).length !== R.keys(dimensionMap2).length) {
            return false;
          } else {
            const dimensionMap1Keys = R.keys(dimensionMap1).sort();
            const dimensionMap2Keys = R.keys(dimensionMap2).sort();
            for (let i = 0; i < dimensionMap1Keys.length; i++) {
              const key1 = dimensionMap1Keys[i];
              const key2 = dimensionMap2Keys[i];
              if (key1 !== key2 || dimensionMap1[key1] !== dimensionMap2[key2]) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }
};
