// TODO: encode this in the DB somewhere
export const hasDeviceTags = line =>
  (line.network === "HULU" ||
    line.network === "DENT" ||
    line.network === "DSED" ||
    line.network === "DER" ||
    line.network === "DSEH") &&
  !line.platform.includes("RTB");
export const hasOSTags = line => line.network === "PANDORA" && !line.platform.includes("RTB");

export const DeviceNames = {
  MOBILE: "Mobile",
  CTV: "CTV",
  DESKTOP: "Desktop",
};

export const OSNames = {
  ANDROID: "Android",
  IOS: "iOS",
  DESKTOP: "Desktop",
};
