import "./SnapshotChart.scss";
import React, { useState } from "react";
import { formatNumberAsInt } from "../../utils/format-utils";

const DEFAULT_FORMATTER = (value: number): string => formatNumberAsInt(value);

export enum BarType {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
  HORIZONTAL_GREY = "HORIZONTAL_GREY",
}

export interface BarPart {
  className?: string;
  label?: string | JSX.Element;
  leftVal?: number;
  type: BarType;
  val?: number;
}

interface PacingBarProps {
  max?: number;
  parts: BarPart[];
  color: string;
}

export interface DataItem {
  label: string;
  subLabel: string | JSX.Element;
  topValue: number;
  bottomValue?: number;
  parts: BarPart[];
  color: string;
}

interface DropdownOption {
  value: string;
}

interface SnapshotChartProps {
  data: DataItem[];
  doubleBarChart?: boolean;
  dropdownOptions?: readonly DropdownOption[];
  dropdownValue?: string;
  header?: string;
  setDropdownValue?: (value: string) => void;
  valueFormatter?: (value: number) => string;
}

interface LabelsProps {
  label: string;
  subLabel: string | JSX.Element;
  hovered: boolean;
}

interface ValuesProps {
  topValue: number;
  bottomValue?: number;
  doubleBarChart?: boolean;
  valueFormatter: (value: number) => string;
}

const Labels: React.FC<LabelsProps> = ({ label, subLabel, hovered }) => {
  return (
    <div className="snapshotLabelsLeft" style={{ height: 45 }}>
      <div className="itemLabel">{label}</div>
      {hovered && <div className="itemSubLabel">{subLabel}</div>}
    </div>
  );
};

const Values: React.FC<ValuesProps> = ({
  topValue,
  bottomValue,
  doubleBarChart,
  valueFormatter,
}) => {
  return (
    <div
      className={`snapshotValuesRight ${doubleBarChart ? "doubleBarChart" : ""}`}
      style={{ height: 45 }}
    >
      <div className="topValue">{valueFormatter(topValue)}</div>
      <div className="bottomValue">{valueFormatter(bottomValue || 0)}</div>
    </div>
  );
};

const SnapshotChart: React.FC<SnapshotChartProps> = React.memo(
  ({ data, doubleBarChart, valueFormatter = DEFAULT_FORMATTER }) => {
    const [hoveredRow, setHoveredRow] = useState<string | null>(null);

    const maxValue = data.reduce((acc, item) => {
      const { parts } = item;
      const partsMax = parts.reduce((acc, part) => {
        return Math.max(acc, part?.val || 0);
      }, 0);
      return Math.max(acc, partsMax);
    }, 0);

    return (
      <div id="snapshotGraphContents" className="snapshotGraphContents">
        {data.map((item, i) => {
          const { label, subLabel, topValue, bottomValue, parts, color } = item;
          const hovered = hoveredRow === label;
          return (
            <div
              key={label}
              className={`snapshotRow ${hovered ? "hovered" : ""}`}
              onMouseEnter={() => setHoveredRow(label)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <Labels label={label} subLabel={subLabel} hovered={hovered} />
              {doubleBarChart ? (
                <DoubleBar parts={parts} max={maxValue} color={color} />
              ) : (
                <PacingBar parts={parts} max={maxValue} color={color} />
              )}
              <Values
                topValue={topValue}
                bottomValue={bottomValue}
                doubleBarChart={doubleBarChart}
                valueFormatter={valueFormatter}
              />
            </div>
          );
        })}
      </div>
    );
  }
);

const VERTICAL_BAR_WIDTH = 3;
export const PacingBar: React.FC<PacingBarProps> = ({ max = 1, parts, color }) => {
  let ratio = 100 / max;
  const spendPart = parts.find(part => part.className === "spend");
  const { val: localSpend } = spendPart || { val: 0 };
  return (
    <div className="itemBar">
      {parts.map(({ type, label = "", className, val = 0, leftVal = 0 }) => {
        let key = [type, label, className, val].join("_");
        let classes: string[] = [];
        if (className) {
          classes.push(className);
        }
        let style = {};
        switch (type) {
          case BarType.HORIZONTAL:
            classes.push("horizontal");
            style = {
              width: `${val * ratio}%`,
              paddingLeft: `${leftVal * ratio}%`,
              backgroundColor: color,
            };
            break;
          case BarType.VERTICAL:
            classes.push("vertical");
            style = {
              width: VERTICAL_BAR_WIDTH,
              left: `calc(${val * ratio}%)`,
            };
            break;
          case BarType.HORIZONTAL_GREY:
            classes.push("horizontal");
            style = {
              width: `${val * ratio}%`,
              marginLeft: `${(localSpend || 0) * ratio}%`,
            };
            break;
          default:
            return undefined;
        }
        return <div key={key} className={classes.join(" ")} style={style}></div>;
      })}
    </div>
  );
};

export const DoubleBar: React.FC<PacingBarProps> = ({ max = 1, parts, color }) => {
  let ratio = 100 / max;
  return (
    <div className="doubleItemBar">
      {parts.map(({ type, label = "", className, val = 0, leftVal = 0 }) => {
        let key = [type, label, className, val].join("_");
        let classes: string[] = [];
        if (className) {
          classes.push(className);
        }
        let style = {};
        switch (type) {
          case BarType.HORIZONTAL:
            classes.push("horizontal");
            style = {
              width: `${val * ratio}%`,
              paddingLeft: `${leftVal * ratio}%`,
              backgroundColor: color,
            };
            break;
          case BarType.HORIZONTAL_GREY:
            classes.push("horizontal grey");
            style = {
              width: `${val * ratio}%`,
              paddingLeft: `${leftVal * ratio}%`,
            };
            break;
          default:
            return undefined;
        }
        return <div key={key} className={classes.join(" ")} style={style}></div>;
      })}
    </div>
  );
};

export default SnapshotChart;
