import "./EditTablePresetModal.scss";
import { Button, ButtonType, ToggleSwitch } from "../../Components";
import { ColumnMetaData, ColumnMetaDataMap } from "../MetricsTable/metricsTableUtils";
import { Form, Modal } from "react-bootstrap";
import { GetKpiMetaDataResponse } from "@blisspointmedia/bpm-types/dist/Performance";
import { getSeriesColor } from "../../utils/colors";
import { GlobalOptions } from "@blisspointmedia/bpm-types/dist/MetricsPage";
import { MoveColumn, DeleteColumn, HeaderInfo } from "./configUtils";
import { StateSetter } from "../../utils/types";
import { useSetAreYouSure } from "../../redux/modals";
import { MdClose, MdEdit } from "react-icons/md";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as uuid from "uuid";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import cn from "classnames";
import ConfigColumn from "./ConfigColumn";
import ConfigDimensionColumn from "./ConfigDimensionColumn";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SortingBox from "./SortingBox";
import SuperHeader from "./SuperHeader";
import {
  Column,
  DimensionColumn,
  MetricsTablePreset,
  SortInfo,
  SuperHeader as PresetSuperHeader,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

interface EditTablePresetModalProps {
  columnMetaDataMap: ColumnMetaDataMap;
  defaultPresetNames?: string[];
  dimensionColumnMetaDataMap: ColumnMetaDataMap;
  globalOptions?: GlobalOptions;
  kpiMetaData: GetKpiMetaDataResponse;
  onHide?: () => void;
  prefix: S.Prefix | "linear" | "youtube" | "cross_channel" | "social" | "commerce";
  presetChanges: MetricsTablePreset;
  saveAndApplyOnClick: (tablePresetChanges: MetricsTablePreset) => void;
  setPresetChanges: StateSetter<MetricsTablePreset>;
}

export const DIMENSION_COLUMN_OPTIONS = [
  ...S.PERFORMANCE_DIMENSION_COLUMN_TYPES,
  ...L.DIMENSION_COLUMN_TYPES,
  ...Y.DIMENSION_COLUMN_TYPES,
];
export const DIMENSION_COLUMNS = [...S.PERFORMANCE_DIMENSIONS, ...L.DIMENSIONS, ...Y.DIMENSIONS];
// NOTE: change the constants in the CSS if you change these
const CONFIG_COLUMN_WIDTH = 270 as const;
const CONFIG_COLUMN_DIVIDER_WIDTH = 10 as const;
const CONFIG_COLUMN_GUTTER = 16 as const;

const EditTablePresetModal = ({
  columnMetaDataMap = {},
  defaultPresetNames = [],
  dimensionColumnMetaDataMap = {},
  globalOptions = { kpi: "" },
  kpiMetaData,
  onHide = () => {},
  prefix,
  presetChanges,
  saveAndApplyOnClick,
  setPresetChanges,
}: EditTablePresetModalProps): JSX.Element => {
  const [colorMap, setColorMap] = useState<Record<string, string | undefined>>({});
  const [hoverItem, setHoverItem] = useState<string>();
  const [newName, setNewName] = useState<string | undefined>();
  const [internalOnly, setInternalOnly] = useState(
    presetChanges && presetChanges.role ? presetChanges.role >= 2 : false
  );
  const setAreYouSure = useSetAreYouSure();

  useEffect(() => {
    setColorMap(colorMap => {
      let newColorMap = { ...colorMap };
      let count = R.keys(newColorMap).length;
      for (let cols of [presetChanges.dimensionColumns, presetChanges.dataColumns]) {
        for (let col of cols) {
          if (!newColorMap[col.id]) {
            newColorMap[col.id] = getSeriesColor(count++);
          }
        }
      }
      return newColorMap;
    });
  }, [presetChanges]);

  const moveColumn = useCallback<MoveColumn>(
    (sourceId, destinationId) => {
      let sourceI = -1;
      let destinationI = -1;
      for (let i = 0; i < presetChanges.dataColumns.length; ++i) {
        let col = presetChanges.dataColumns[i];
        if (col.id === sourceId) {
          sourceI = i;
        }
        if (col.id === destinationId) {
          destinationI = i;
        }
      }
      if (sourceI !== -1 && destinationI !== -1) {
        const reordered = R.move(sourceI, destinationI, presetChanges.dataColumns);
        let dataIndex = 0;
        setPresetChanges(current => ({
          ...current,
          dataColumns: reordered,
          superHeaders: R.reduce(
            (headers, header) => {
              // If it's a one-column-wide super header and that column is the one we're moving, delete
              // the header
              const start = dataIndex;
              const end = dataIndex + header.span - 1;
              if (start === end && start === sourceI) {
                return headers;
              }
              // S = Source, D = Destination, Res = Result
              // U = Under, B = Before, A = After, N = Nothing
              // S/E +/- 1 = Adjust start/end position
              //  S | D | Res           | Notes
              // ---+---+---------------+-----------------------------------------------------------
              //  U | U | N             | Swapping under the same header doesn't do anything
              //  U | B | S + 1         | Under to before shrinks S, but E relative pos is the same
              //  U | A | E - 1         | Like above, but S stays and E shrinks
              //  B | U | S - 1         | Taking from before to under grows S, but E stays
              //  B | B | N             | Swapping two things before cancels out
              //  B | A | S - 1, E - 1  | Before to after shifts the header to the left
              //  A | U | E + 1         | Like B | O but S stays the same and E grows
              //  A | B | S + 1, E + 1  | Like B | A but it shifts to the right
              //  A | A | N             | Anything happening after has no effect on positions
              const sU = start <= sourceI && end >= sourceI;
              const dU = start <= destinationI && end >= destinationI;
              const sB = start > sourceI;
              const dB = start > destinationI;
              const sA = end < sourceI;
              const dA = end < destinationI;
              if ((sU && dU) || (sB && dB) || (sA && dA)) {
                return [...headers, header];
              }
              let newHeader = {
                ...header,
              };
              if (sA) {
                newHeader.span += 1;
              }
              if (sB) {
                newHeader.span -= 1;
              }
              if (dB) {
                newHeader.span += 1;
              }
              if (dA) {
                newHeader.span -= 1;
              }
              dataIndex = end + 1;
              return [...headers, newHeader];
            },
            [] as typeof current.superHeaders,
            current.superHeaders
          ),
        }));
      }
    },
    [presetChanges, setPresetChanges]
  );

  const deleteColumn = useCallback<DeleteColumn>(
    async (index: number) => {
      try {
        await setAreYouSure({
          title: "Delete Column?",
          message: "Are you sure you want to delete this column?",
          okayText: "Delete",
          cancelText: "Never mind",
        });

        setPresetChanges(current => {
          const dataColumns: Column[] = [];
          for (let i = 0; i < current.dataColumns.length; ++i) {
            if (i !== index) {
              dataColumns.push(current.dataColumns[i]);
            }
          }
          let defaultSorting: SortInfo[] | undefined;
          if (current.defaultSorting) {
            const { id } = current.dataColumns[index];
            const newDefaultSorting: SortInfo[] = [];
            for (let sortCol of current.defaultSorting as SortInfo[]) {
              if (sortCol.id !== id) {
                newDefaultSorting.push(sortCol);
              }
            }
            if (newDefaultSorting.length) {
              defaultSorting = newDefaultSorting;
            }
          }
          let columnIndex = 0;
          const superHeaders = R.map(
            superHeader => {
              const start = columnIndex;
              const end = columnIndex + superHeader.span - 1;
              const newSuperHeader =
                index >= start && index <= end
                  ? {
                      ...superHeader,
                      span: superHeader.span - 1,
                    }
                  : superHeader;
              columnIndex = end + 1;
              return newSuperHeader;
            },
            R.defaultTo([], current.superHeaders) as PresetSuperHeader[] //as any[]
          );
          return {
            ...current,
            dataColumns,
            defaultSorting,
            superHeaders,
          };
        });
      } catch (e) {}
    },
    [setAreYouSure, setPresetChanges]
  );

  const addColumn = useCallback(
    (i: number) => {
      let dataVarName;
      for (const key of R.keys(columnMetaDataMap)) {
        if (columnMetaDataMap[key]) {
          dataVarName = key;
          break;
        }
      }
      if (dataVarName) {
        let dataColumnIndex = 0;
        setPresetChanges(current => ({
          ...current,
          dataColumns: R.insert(
            i,
            {
              id: uuid.v4(),
              dataVarName,
              role: 10,
            },
            current.dataColumns
          ),
          superHeaders: R.map(superHeader => {
            const start = dataColumnIndex;
            const end = dataColumnIndex + superHeader.span - 1;
            if (i >= start && i <= end) {
              return {
                ...superHeader,
                span: superHeader.span + 1,
              };
            }
            dataColumnIndex = end + 1;
            return superHeader;
          }, current.superHeaders),
        }));
      }
    },
    [setPresetChanges, columnMetaDataMap]
  );

  const superHeaderInfoMap = useMemo(() => {
    const map: Record<string, HeaderInfo | true> = {};
    let columnIndex = 0;
    if (presetChanges.superHeaders) {
      for (
        let superHeaderIndex = 0;
        superHeaderIndex < presetChanges.superHeaders.length;
        ++superHeaderIndex
      ) {
        const superHeader = presetChanges.superHeaders[superHeaderIndex];
        const start = columnIndex;
        const end = columnIndex + superHeader.span - 1;
        if (superHeader.data.text) {
          // Take gutter out so half gutter on either side is removed
          let width = -CONFIG_COLUMN_GUTTER;
          for (let i = start; i <= end; ++i) {
            map[i] = true;
            width += CONFIG_COLUMN_WIDTH + CONFIG_COLUMN_GUTTER;
            if (presetChanges.dataColumns[i] && presetChanges.dataColumns[i].divider) {
              width += CONFIG_COLUMN_DIVIDER_WIDTH;
            }
          }
          map[start] = {
            end,
            start,
            superHeaderIndex: superHeaderIndex,
            text: superHeader.data.text,
            width,
          };
        }
        columnIndex = end + 1;
      }
    }
    return map;
  }, [presetChanges]);

  const onSuperHeaderChange = useCallback(
    newSuperHeaders => {
      setPresetChanges(current => ({
        ...current,
        superHeaders: newSuperHeaders,
      }));
    },
    [setPresetChanges]
  );

  const deleteDimensionColumn = useCallback<DeleteColumn>(
    async (index: number) => {
      try {
        await setAreYouSure({
          title: "Delete Column?",
          message: "Are you sure you want to delete this column?",
          okayText: "Delete",
          cancelText: "Never mind",
        });
        setPresetChanges(current => {
          const dimensionColumns: DimensionColumn[] = [];
          for (let i = 0; i < current.dimensionColumns.length; ++i) {
            if (i !== index) {
              dimensionColumns.push(current.dimensionColumns[i]);
            }
          }
          let defaultSorting: SortInfo[] | undefined;
          if (current.defaultSorting) {
            const { id } = current.dimensionColumns[index];
            const newDefaultSorting: SortInfo[] = [];
            for (let sortCol of current.defaultSorting as SortInfo[]) {
              if (sortCol.id !== id) {
                newDefaultSorting.push(sortCol);
              }
            }
            if (newDefaultSorting.length) {
              defaultSorting = newDefaultSorting;
            }
          }
          return {
            ...current,
            dimensionColumns,
            defaultSorting,
          };
        });
      } catch (e) {}
    },
    [setAreYouSure, setPresetChanges]
  );

  const moveDimensionColumn = useCallback<MoveColumn>(
    (sourceId, destinationId) => {
      let sourceI = -1;
      let destinationI = -1;
      for (let i = 0; i < presetChanges.dimensionColumns.length; ++i) {
        let col = presetChanges.dimensionColumns[i];
        if (col.id === sourceId) {
          sourceI = i;
        }
        if (col.id === destinationId) {
          destinationI = i;
        }
      }
      if (sourceI !== -1 && destinationI !== -1) {
        const reordered = R.move(sourceI, destinationI, presetChanges.dimensionColumns);
        setPresetChanges(current => ({
          ...current,
          dimensionColumns: reordered,
        }));
      }
    },
    [presetChanges, setPresetChanges]
  );

  const addDimensionColumn = useCallback(
    (i: number) => {
      let dimensionTypeName;
      let dimensionVarName;
      for (const key of R.keys(dimensionColumnMetaDataMap)) {
        if (dimensionColumnMetaDataMap[key]) {
          dimensionTypeName = key;
          dimensionVarName = (dimensionColumnMetaDataMap[key] as ColumnMetaData)[
            `${"dimensionVarName"}`
          ];
          break;
        }
      }
      if (dimensionTypeName && dimensionVarName) {
        setPresetChanges(current => ({
          ...current,
          dimensionColumns: R.insert(
            i,
            {
              dimensionTypeName,
              dimensionVarName,
              id: uuid.v4(),
              role: 10,
            },
            current.dimensionColumns
          ),
        }));
      }
    },
    [dimensionColumnMetaDataMap, setPresetChanges]
  );

  const addSuperHeader = useCallback(
    (columnI: number) => {
      const newSuperHeaderData = {
        text: "New Super Header",
        leftDivider: false,
        rightDivider: false,
      };
      const superHeaders: PresetSuperHeader[] = [];
      let dataColumnIndex = 0;
      for (const superHeader of presetChanges.superHeaders) {
        const start = dataColumnIndex;
        const end = dataColumnIndex + superHeader.span - 1;
        if (start > columnI || end < columnI) {
          superHeaders.push(superHeader);
        } else if (start === end && start === columnI) {
          superHeaders.push({
            ...superHeader,
            data: newSuperHeaderData,
          });
        } else if (start === columnI) {
          superHeaders.push({
            span: 1,
            data: newSuperHeaderData,
          });
          superHeaders.push({
            ...superHeader,
            span: superHeader.span - 1,
          });
        } else if (end === columnI) {
          superHeaders.push({
            ...superHeader,
            span: superHeader.span - 1,
          });
          superHeaders.push({
            span: 1,
            data: newSuperHeaderData,
          });
        } else {
          const leftSpan = columnI - start;
          superHeaders.push({
            ...superHeader,
            span: leftSpan,
          });
          superHeaders.push({
            span: 1,
            data: newSuperHeaderData,
          });
          superHeaders.push({
            ...superHeader,
            span: superHeader.span - leftSpan - 1,
          });
        }
        dataColumnIndex = end + 1;
      }
      onSuperHeaderChange(superHeaders);
    },
    [onSuperHeaderChange, presetChanges.superHeaders]
  );

  const hasSuperHeaderMap = useMemo(() => {
    let hasSuperHeaderList: boolean[] = [];
    let superHeaderList = [...R.defaultTo([], presetChanges.superHeaders)];
    let colIndex = 0;
    for (const superHeader of superHeaderList) {
      for (let i = 0; i < superHeader.span; i++) {
        if (superHeader.data.text) {
          hasSuperHeaderList[colIndex] = true;
        }
        colIndex++;
      }
    }
    return hasSuperHeaderList;
  }, [presetChanges]);

  const modalHeader = (
    <Modal.Header>
      <div className="titleContainer">
        <Modal.Title>Table Preset:</Modal.Title>
        <div className="tablePresetNameContainer">
          {!R.isNil(newName) ? (
            <Form.Control
              className="newNameInput"
              onChange={e => setNewName(e.target.value)}
              placeholder={"New Preset Name"}
              value={newName}
            />
          ) : (
            <div className="tablePresetName">
              {presetChanges.temporary ? "Temporary Preset" : presetChanges.name}
            </div>
          )}
          {presetChanges &&
            presetChanges.name &&
            !defaultPresetNames.includes(presetChanges.name.toLowerCase()) &&
            (R.isNil(newName) ? (
              <div
                className="editTablePresetNameIcon"
                onClick={() => {
                  setNewName(presetChanges.name);
                }}
              >
                <MdEdit />
              </div>
            ) : (
              <div
                className="editPagePresetNameIcon"
                onClick={() => {
                  setNewName(undefined);
                }}
              >
                <MdClose />
              </div>
            ))}
        </div>
      </div>
      <ToggleSwitch
        checked={internalOnly}
        className="internalOnlyToggle"
        design="primary"
        label="Internal-only"
        onChange={setInternalOnly}
      />
      <div className="closeContainer" onClick={() => onHide()}>
        <MdClose />
      </div>
    </Modal.Header>
  );

  const modalBody = (
    <Modal.Body>
      <div className="configBox">
        <SortingBox
          colorMap={colorMap}
          presetChanges={presetChanges}
          setHoverItem={setHoverItem}
          setPresetChanges={setPresetChanges}
        />
        <div
          className={cn("configBoxInner", {
            hasHeaders:
              presetChanges.superHeaders &&
              ((presetChanges.superHeaders[0] && presetChanges.superHeaders[0].data.text) ||
                presetChanges.superHeaders.length > 1),
          })}
        >
          {presetChanges.dimensionColumns.map((column, i) => (
            <React.Fragment key={column.id}>
              <ConfigDimensionColumn
                addColumn={addDimensionColumn}
                color={colorMap[column.id]}
                column={column}
                columnMetaDataMap={dimensionColumnMetaDataMap}
                deleteColumn={deleteDimensionColumn}
                hoverItem={hoverItem}
                index={i}
                isOnlyColumn={presetChanges.dimensionColumns.length <= 1}
                moveColumn={moveDimensionColumn}
                setPresetChanges={setPresetChanges}
              />
            </React.Fragment>
          ))}
          <div className="divider" />
          {presetChanges.dataColumns.map((column, i) => (
            <React.Fragment key={column.id}>
              <SuperHeader
                colCount={presetChanges.dataColumns.length}
                columnI={i}
                info={superHeaderInfoMap}
                onSuperHeaderChange={onSuperHeaderChange}
                superHeaders={presetChanges.superHeaders as any}
              />
              <ConfigColumn
                addColumn={addColumn}
                color={colorMap[column.id]}
                column={column}
                columnMetaDataMap={columnMetaDataMap}
                deleteColumn={deleteColumn}
                globalKpi={globalOptions.kpi}
                hasSuperHeader={hasSuperHeaderMap[i]}
                hoverItem={hoverItem}
                index={i}
                isOnlyColumn={presetChanges.dataColumns.length <= 1}
                kpiMetaData={kpiMetaData}
                moveColumn={moveColumn}
                onSuperHeaderAdd={() => addSuperHeader(i)}
                prefix={prefix}
                setPresetChanges={setPresetChanges}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Modal.Body>
  );

  const modalFooter = (
    <Modal.Footer>
      <Button
        className="cancelButton"
        design="secondary"
        onClick={() => onHide()}
        size={"sm"}
        type={ButtonType.EMPTY}
      >
        Cancel
      </Button>
      <Button
        className="previewButton"
        design="secondary"
        onClick={() =>
          saveAndApplyOnClick({
            ...presetChanges,
            temporary: true,
            id: (presetChanges.temporary ? presetChanges.id : undefined) as any,
          })
        }
        size={"sm"}
        type={ButtonType.OUTLINED}
      >
        Temp Preview
      </Button>
      <Button
        className="saveAndApplyButton"
        onClick={() => {
          const newPreset = {
            ...presetChanges,
            name: R.defaultTo(presetChanges.name, newName),
          };
          saveAndApplyOnClick(newPreset);
          setPresetChanges(newPreset);
        }}
        size={"sm"}
        type={ButtonType.FILLED}
      >
        Save and Apply
      </Button>
    </Modal.Footer>
  );

  return (
    <Modal show centered size="lg" onHide={() => onHide()} className="editTablePresetModal">
      {modalHeader}
      {modalBody}
      {modalFooter}
    </Modal>
  );
};

export default EditTablePresetModal;
