import React, { useState, useMemo, useCallback, useEffect } from "react";

import * as R from "ramda";

import "./useSortHeader.scss";

export const useSortHeader = ({
  data,
  renderer = R.prop("data"),
  getter = R.identity,
  disableMap = {},
  initialSortParamList = [],
  // eslint-disable-next-line no-unused-vars
  onUpdateSortParamList = any => {},
  updateOffset,
}) => {
  const [sortParamList, setSortParamList] = useState(initialSortParamList);
  useEffect(() => {
    if (onUpdateSortParamList) {
      onUpdateSortParamList(sortParamList);
    }
  }, [onUpdateSortParamList, sortParamList]);

  const sortMap = useMemo(() => {
    if (updateOffset) {
      updateOffset(0);
    }
    let map = {};
    for (let i = 0; i < sortParamList.length; ++i) {
      let params = sortParamList[i];
      map[params.index] = {
        index: i,
        ascending: params.ascending,
        listIndex: i,
      };
    }
    return map;
  }, [sortParamList, updateOffset]);

  const toggleSort = useCallback(
    index => {
      let sortParams = sortMap[index];
      if (sortParams) {
        if (sortParams.ascending) {
          let newParams = {
            index,
            ascending: !sortParams.ascending,
          };
          setSortParamList(R.update(sortParams.listIndex, newParams, sortParamList));
        } else {
          setSortParamList(R.remove(sortParams.listIndex, 1, sortParamList));
        }
      } else {
        setSortParamList([...sortParamList, { index, ascending: true }]);
      }
    },
    [sortParamList, sortMap, setSortParamList]
  );

  const sortedData = useMemo(
    () =>
      R.sortWith(
        R.map(
          ({ index, ascending }) =>
            (ascending ? R.ascend : R.descend)(R.pipe(R.prop(index), getter, R.defaultTo(""))),
          sortParamList
        ),
        data
      ),
    [data, sortParamList, getter]
  );

  const headerRenderer = useCallback(
    ({ data, columnIndex, style, classes }) => {
      let isDisabled = disableMap[columnIndex];
      let ourClasses = [...classes];
      let onClick = () => {};

      if (!isDisabled) {
        let sortParams = sortMap[columnIndex];

        ourClasses.push("useSortHeaderCaret");

        onClick = () => toggleSort(columnIndex);

        if (sortParams) {
          ourClasses.push(sortParams.ascending ? "up" : "down");
        }
      }

      return (
        <div style={style} className={ourClasses.join(" ")} onClick={onClick}>
          <span>
            {renderer({
              data,
              columnIndex,
            })}
          </span>
        </div>
      );
    },
    [renderer, sortMap, toggleSort, disableMap]
  );

  return [sortedData, headerRenderer];
};

export default useSortHeader;
