import React, { useEffect, useMemo, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import "./InfoTooltip.scss";

export enum InfoTooltipDirection {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_LEFT = "bottom-left",
}

interface InfoTooltipProps {
  children: React.ReactNode;
  direction?: InfoTooltipDirection;
  size?: "reg" | "sm";
  storybook?: boolean;
  color?: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = React.memo(
  ({
    children,
    direction = InfoTooltipDirection.BOTTOM_RIGHT,
    size = "reg",
    storybook = false,
    color,
  }): JSX.Element => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [resolvedDirection, setResolvedDirection] = useState<InfoTooltipDirection>(direction);
    const dimensions = useMemo(() => {
      return {
        width: size === "reg" ? "24px" : "13px",
        height: size === "reg" ? "24px" : "13px",
        color: color,
      };
    }, [color, size]);
    const tooltipTextStyle = useMemo(() => {
      if (resolvedDirection === InfoTooltipDirection.TOP_LEFT) {
        return {
          bottom: size === "reg" ? "32px" : "21.85px",
          right: size === "reg" ? "-12px" : "-17.5px",
        };
      } else if (resolvedDirection === InfoTooltipDirection.TOP_RIGHT) {
        return {
          bottom: size === "reg" ? "32px" : "21.85px",
          left: size === "reg" ? "-12px" : "-17.5px",
        };
      } else if (resolvedDirection === InfoTooltipDirection.BOTTOM_LEFT) {
        return {
          top: size === "reg" ? "32px" : "21.85px",
          right: size === "reg" ? "-12px" : "-17.5px",
        };
      } else {
        return {
          top: size === "reg" ? "32px" : "21.85px",
          left: size === "reg" ? "-12px" : "-17.5px",
        };
      }
    }, [resolvedDirection, size]);

    const verticallyBound = (parentDiv, childDiv) => {
      let parentRect = parentDiv.getBoundingClientRect();
      let childRect = childDiv.getBoundingClientRect();

      return parentRect.top <= childRect.top && parentRect.bottom >= childRect.bottom;
    };

    const horizontallyBound = (parentDiv, childDiv) => {
      let parentRect = parentDiv.getBoundingClientRect();
      let childRect = childDiv.getBoundingClientRect();

      return parentRect.left <= childRect.left && parentRect.right >= childRect.right;
    };

    const [isVerticallyBound, setIsVerticallyBound] = useState<boolean>(true);
    const [isHorizontallyBound, setIsHorizontallyBound] = useState<boolean>(true);
    useEffect(() => {
      if (showTooltip && !storybook) {
        const parentDiv = document.getElementsByClassName("body")[0];
        const childDiv = document.getElementById("dropdownTooltipText");

        setIsVerticallyBound(verticallyBound(parentDiv, childDiv));
        setIsHorizontallyBound(horizontallyBound(parentDiv, childDiv));
      } else {
        setIsVerticallyBound(true);
        setIsHorizontallyBound(true);
      }
    }, [showTooltip, storybook]);

    useEffect(() => {
      if (!isVerticallyBound && !isHorizontallyBound) {
        if (resolvedDirection === InfoTooltipDirection.BOTTOM_LEFT) {
          setResolvedDirection(InfoTooltipDirection.TOP_RIGHT);
        } else if (resolvedDirection === InfoTooltipDirection.BOTTOM_RIGHT) {
          setResolvedDirection(InfoTooltipDirection.TOP_LEFT);
        } else if (resolvedDirection === InfoTooltipDirection.TOP_LEFT) {
          setResolvedDirection(InfoTooltipDirection.BOTTOM_RIGHT);
        } else {
          setResolvedDirection(InfoTooltipDirection.BOTTOM_LEFT);
        }
      } else {
        if (!isVerticallyBound && isHorizontallyBound) {
          if (resolvedDirection === InfoTooltipDirection.BOTTOM_LEFT) {
            setResolvedDirection(InfoTooltipDirection.TOP_LEFT);
          } else if (resolvedDirection === InfoTooltipDirection.BOTTOM_RIGHT) {
            setResolvedDirection(InfoTooltipDirection.TOP_RIGHT);
          } else if (resolvedDirection === InfoTooltipDirection.TOP_LEFT) {
            setResolvedDirection(InfoTooltipDirection.BOTTOM_LEFT);
          } else {
            setResolvedDirection(InfoTooltipDirection.BOTTOM_RIGHT);
          }
        }
        if (isVerticallyBound && !isHorizontallyBound) {
          if (resolvedDirection === InfoTooltipDirection.BOTTOM_LEFT) {
            setResolvedDirection(InfoTooltipDirection.BOTTOM_RIGHT);
          } else if (resolvedDirection === InfoTooltipDirection.BOTTOM_RIGHT) {
            setResolvedDirection(InfoTooltipDirection.BOTTOM_LEFT);
          } else if (resolvedDirection === InfoTooltipDirection.TOP_LEFT) {
            setResolvedDirection(InfoTooltipDirection.TOP_RIGHT);
          } else {
            setResolvedDirection(InfoTooltipDirection.TOP_LEFT);
          }
        }
      }
      setIsHorizontallyBound(true);
      setIsVerticallyBound(true);
    }, [isHorizontallyBound, isVerticallyBound, resolvedDirection]);

    return (
      <div
        className="tooltipContainer"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        style={dimensions}
      >
        <MdInfoOutline className={`infoIcon ${showTooltip ? "hover" : ""}`} style={dimensions} />
        {showTooltip && (
          <span
            className={`tooltipText ${resolvedDirection}`}
            style={tooltipTextStyle}
            id="dropdownTooltipText"
          >
            {children}
          </span>
        )}
      </div>
    );
  }
);
