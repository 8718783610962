import "./FAQ.scss";
import React, { useLayoutEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import WidgetContainer from "../../Components/WidgetContainer";
import * as UserRedux from "../../redux/user";
import { useSelector } from "react-redux";

const FAQ = (props: RouteComponentProps): JSX.Element => {
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const [screenWidth, setScreenWidth] = useState(0);

  useLayoutEffect(() => {
    const screenWidth = window.innerWidth;
    setScreenWidth(screenWidth);
  }, []);
  return (
    <div className="mmmFAQ">
      <div className="faqTitle">Frequently Asked Questions</div>
      <div className="questionsWrapper">
        <WidgetContainer collapsible header="General Overview" headerCollapsibleOnclick>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="What does MMM stand for?"
          >
            <div>
              <p>MMM refers to either “media mix modeling” or “marketing mix modeling.”</p>
              <p>
                Media mix modeling, as the name suggests, focuses primarily on the impact of media
                investments on outcomes of interest. It’s answering the question, “How should I
                allocate my media budget?”
              </p>
              <p>
                Marketing mix modeling is a more expansive, ambitious approach that attempts to
                integrate not only media but all types of marketing levers: pricing, promotions,
                partnerships, etc. It’s answering the question, “How should I manage and allocate
                all of the resources under the marketing function’s control?”
              </p>
              <p>
                The Bliss Point MMM is, by default, a media mix model. Marketing mix models are
                feasible, but the implementation will be significantly more involved and the data
                requirements of clients significantly greater.
              </p>
            </div>
          </WidgetContainer>
          <WidgetContainer design="secondary" startOpen={false} collapsible header="What is MMM?">
            <p>
              MMM is a statistical approach to solving a multivariate{" "}
              <a
                href="https://en.wikipedia.org/wiki/Statistical_inference#"
                rel="noreferrer"
                target="_blank"
              >
                inference problem
              </a>
              . For a marketer, the problem is of the following form: There are outcomes that the
              marketing function is responsible for driving to fuel business growth; these may be
              things like site traffic, app installs, revenue, etc. There are many things happening
              simultaneously that influence these outcomes: paid media like running ads on TV and
              Facebook, as well as other factors like seasonality, macroeconomic conditions,
              pandemics, and so on. The marketer desires to disentangle the effects of these various
              factors in order to understand causal relationships, i.e. the ways in which changes in
              specific factors will cause changes in the outcome(s) the marketer cares about. Armed
              with this understanding, a marketer can then make confident decisions about resource
              allocation, namely how to allocate scarce marketing resources in service of maximizing
              a business objective.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="How does MMM work?"
          >
            <p>
              A detailed explanation is beyond the scope of this FAQ, but all approaches to MMM are
              some form of{" "}
              <a href="https://en.wikipedia.org/wiki/Time_series" rel="noreferrer" target="_blank">
                time series
              </a>{" "}
              analysis of several variables. The model will attempt to{" "}
              <a
                href="https://en.wikipedia.org/wiki/Regression_analysis"
                rel="noreferrer"
                target="_blank"
              >
                regress
              </a>{" "}
              a time series representation of an outcome of interest (e.g. daily sales) on time
              series representations of several independent variables, including paid media
              channels, other factors such as macroeconomic indicators, as well as time itself.
            </p>
            <p>
              In essence, a time series model is attempting to “control” for a set of factors (such
              as macroeconomic indicators and elapsed time) while assessing the correlations between
              independent variables of interest (spending on Facebook, spending on YouTube, etc.)
              and an outcome variable of interest.
            </p>
          </WidgetContainer>
        </WidgetContainer>
        <WidgetContainer collapsible header="Applications" headerCollapsibleOnclick>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="What does MMM allow a marketer to do that they otherwise could not?
"
            headerCollapsibleOnclick
          >
            <p>
              MMM is not the answer to every question, but it does enable a marketer to do some
              critical things that are otherwise extremely difficult:
            </p>

            <ul>
              <li>
                <span className="italics">Investment Optimization</span> – At the level of the
                portfolio (SEM, paid social, TV, etc.), a high-quality MMM offers a systematic
                approach to optimizing investment allocation. This is the kind of answer that really
                cannot be built up from the individual channel level, and can only be arrived at
                with a framework that takes in the entire portfolio.
              </li>
              <li>
                <span className="italics">Cross-Channel Comparison</span> – An MMM puts every
                marketing activity on a common footing and provides performance insights in a common
                currency. Marketers frequently grapple with self-reported metrics from platforms,
                models that preference the last click, etc., and have to wonder how various channels
                really stack up. An MMM provides the clearest answer to these questions.
              </li>
              <li>
                <span className="italics">Scenario Planning</span> – An MMM allows a marketer to
                simulate the impact of various marketing strategies. For instance, a marketer might
                be interested to know how ROAS would be affected by a 25% increase in the overall
                marketing budget. Without an MMM, this type of if-then analysis is not possible.
              </li>
            </ul>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="For which kinds of questions is an MMM appropriate?
"
            headerCollapsibleOnclick
          >
            <p>
              Broadly speaking, an MMM is suitable for answering coarse-grained questions about a
              marketing program. By “coarse-grained” we mean high-level questions such as “How much
              budget should I allocate to display?” or “At what point do paid social investments
              begin to saturate?”
            </p>
            <p>
              MMM is not appropriate for answering fine-grained questions, such as “What is our most
              effective creative asset?” or “How should we optimize our streaming campaign?”
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Why isn’t MMM a good tool for fine-grained questions?"
            headerCollapsibleOnclick
          >
            <p>
              There are two main reasons. The first has to do with the number of causal
              relationships we’re interested in relative to the amount of data we have to estimate
              those relationships. Imagine a data set where rows are observations (units of time)
              and columns are parameters of interest; if the number of columns is large relative to
              the number of rows, then one just doesn’t have enough data to estimate all the
              relationships with confidence. And in the MMM context, we typically have only a few
              hundred rows of data. So we need to be very thoughtful about how many things we’re
              trying to estimate, avoiding the temptation to boil the ocean.
            </p>
            <p>
              The second reason has to do with the availability of better alternatives. For the
              high-level questions there are very few good alternatives to MMM; for lower-level
              questions like “How should we optimize our streaming campaign?” there are
              purpose-built tools like our Bliss Point Streaming ML product that can provide much
              more precise answers.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="For which kinds of outcomes is an MMM appropriate?"
            headerCollapsibleOnclick
          >
            <p>
              The answer depends on which kind of MMM we’re talking about (refer to the prior
              question ‘What does MMM stand for?’). A media mix model is generally appropriate for
              outcomes related to growth – new users, app installs, return on ad spend (ROAS), etc.
              Media mix models are less appropriate for outcomes related to retention, e.g.
              returning purchases, recurring revenue, etc.
            </p>
            <p>
              A marketing mix model is more appropriate for retention outcomes, as it would
              incorporate things like CRM data, pricing data, promotional data, etc. These models
              are significantly more complex however, and a very thorough assessment of data
              availability is required before embarking on the process.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Can an MMM be used to understand brand outcomes?
"
            headerCollapsibleOnclick
          >
            <p>
              Yes! Outcomes like brand awareness and brand consideration can be integrated in an MMM
              framework; in fact, our Brand Health product is built to do exactly this.
            </p>
            <p>
              An important consideration is availability of data on brand health outcomes for a
              given client, without which it’s not possible to build a model like this. Some of our
              clients are included in the{" "}
              <a
                href="https://business.yougov.com/product/brandindex"
                rel="noreferrer"
                target="_blank"
              >
                BrandIndex
              </a>{" "}
              database, which provides brand health data that can be used in our product.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Can an MMM be used to understand offline conversion outcomes?"
            headerCollapsibleOnclick
          >
            <p>Yes! Though admittedly, this is substantially more complex.</p>
            <p>
              The first critical ingredient is simply sourcing the data on offline conversion
              outcomes. At minimum, we need to be able to summarize these outcomes at the level of
              the day and the{" "}
              <a href="https://en.wikipedia.org/wiki/Media_market" rel="noreferrer" target="_blank">
                DMA
              </a>
              . If data cannot be sourced in this manner, we cannot build an MMM for offline
              conversions.
            </p>
            <p>
              For owned retail outlets (e.g. Warby Parker stores), clients will often have their own
              point-of-sale data. As long as it can be summarized in the form described above, it is
              usable for MMM purposes. For channel sales (e.g. Poppi soda sold in Whole Foods),
              we’ve established a partnership with{" "}
              <a
                href="https://www.gocrisp.com/catalog/tag/inbound-connectors"
                rel="noreferrer"
                target="_blank"
              >
                Crisp
              </a>
              , with highly favorable commercial terms that we can pass on to clients.
            </p>
            <p>
              Given suitable outcomes data, we use a modified form of MMM that incorporates{" "}
              <a
                href="https://en.wikipedia.org/wiki/Spatial_analysis#Spatial_regression"
                rel="noreferrer"
                target="_blank"
              >
                spatial modeling
              </a>
              , i.e. taking into account that media runs, and consumers respond, in specific
              geographic areas. Geography thus becomes another source of variation, and thus another
              tool to help us understand causal relationships.
            </p>
            <p>
              It’s reasonable to expect that the percentage of variation driven by media – i.e. how
              much paid media moves the needle on outcomes – will be substantially less for offline
              outcomes than it is for online outcomes (but this doesn’t mean it doesn’t matter! It
              can still matter a great deal).
            </p>
          </WidgetContainer>
        </WidgetContainer>
        {isInternal && (
          <WidgetContainer collapsible header="Product Experience" headerCollapsibleOnclick>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="What are some of the common pain points with MMM?"
              headerCollapsibleOnclick
            >
              <p>
                An exhaustive list would be very lengthy, but the pain points most commonly cited
                are:
              </p>

              <ul>
                <li>
                  <span className="italics">Labor Intensity</span> - It takes a lot of work to
                  collect, clean, and prepare all the data required to build an MMM; and then it
                  needs to be done all over again for each model refresh.
                </li>
                <li>
                  <span className="italics">Garbage In, Garbage Out (GIGO)</span> - Models built
                  with small data sets, which is the case with MMMs, can be very sensitive to even
                  small errors in the inputs. This means that any inaccuracy in the data fed into
                  the model can lead to the model having the wrong understanding of causal
                  relationships, which can lead to (potentially large) malinvestment. Even worse,
                  these are often “silent errors,” meaning that it can be very hard to detect that
                  anything is amiss.
                </li>
                <li>
                  <span className="italics">Low Velocity</span> - Closely related to the point above
                  on labor intensity, most MMMs are refreshed only a handful of times per year. This
                  makes it difficult for marketers to know how to respond when things are evolving
                  quickly.
                </li>
                <li>
                  <span className="italics">Irreconcilability with Key Data Point</span> - Not
                  infrequently, an MMM will produce a result that strongly clashes with other
                  measurements, such as incrementality test results. An example might be, “We ran a
                  conversion lift test on Facebook and it said our incremental CPA is $20, but our
                  MMM says our Facebook CPA Is $2,000!” This leaves a marketer with a very hard
                  decision to make about the channel and no obvious way to resolve the discrepancy.
                </li>
                <li>
                  <span className="italics">Opacity</span> - MMMs are, by their nature, complex
                  statistical models; and most marketers are not trained statisticians, making
                  genuine understanding a challenge. To make matters worse, many MMM vendors are
                  unwilling to expose the inner workings of their models, as doing so could reveal
                  sensitive intellectual property. This can put a marketer in a position to make
                  investment decisions of many millions of dollars without a strong understanding of
                  how the investment recommendations were reached.
                </li>
              </ul>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="How does our Bliss Point MMM deal with these pain points?"
              headerCollapsibleOnclick
            >
              <ul>
                <li>
                  <span className="italics">Labor Intensity</span> - In order to run our business,
                  we need to be able to source data at scale from every marketing platform we touch.
                  To that end, our engineering team has built industrial-strength data connectors to
                  all major platforms (Meta, Google, Amazon, etc.) to power our{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Extract,_transform,_load"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ETL
                  </a>{" "}
                  process. This turns out to be extremely useful for MMM – rather than asking
                  clients to collect all their data and send it to us, we need only ask clients to
                  grant us permission to read their data. Given this permission, we can source it
                  programmatically as part of our ETL process. This reduces the labor intensity
                  dramatically.
                </li>
                <li>
                  <span className="italics">Garbage In, Garbage Out (GIGO)</span> - Programmatic
                  data connectors are the solution here as well. By sourcing data directly from the
                  platforms, we essentially piggyback on the data integrity enforcement of the
                  platforms themselves; e.g. the ultimate authority on how many Facebook impressions
                  we ran is Facebook. And by omitting any human transcription steps (download the
                  data, pivot it, merge it with other data, etc.), we can enforce data integrity at
                  the level of code.
                </li>
                <li>
                  <span className="italics">Low Velocity</span> - Once again, programmatic data
                  connectors are the key solve. In addition to the marketing activities,
                  programmatic connection with a client’s outcome source of truth – a data
                  warehouse, Google Analytics, Adobe Analytics, etc. – allows us to source the
                  necessary data on an ongoing basis via our ETL process. With this framework in
                  place, we can refresh models much more frequently.
                </li>
                <li>
                  <span className="italics">Irreconcilability with Key Data Points</span> - This
                  typically happens because two sources of truth – an MMM and an incrementality test
                  – are using different data and different methods. Our approach to this problem is
                  to use the results from the incrementality test in the MMM! You can think of this
                  as using the incrementality test result to provide a “hint” to the MMM as to the
                  correct answer for that channel. The way we do this is by incorporating
                  experimental results into the MMM as{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Prior_probability"
                    rel="noreferrer"
                    target="_blank"
                  >
                    informative priors
                  </a>
                  . This does not rule out the possibility that test results and model results will
                  conflict, but it makes it significantly less likely.
                </li>
                <li>
                  <span className="italics">Opacity</span> - We have committed to radical
                  transparency with our clients as far as technical methods are concerned. The exact
                  details of how we will deliver this are still being worked out
                </li>
              </ul>
            </WidgetContainer>
          </WidgetContainer>
        )}
        <WidgetContainer collapsible header="Econometric Issues" headerCollapsibleOnclick>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Why is incrementality testing important for a high-quality MMM?"
          >
            <p>
              Incrementality testing is important in this context because MMM is a{" "}
              <a href="https://en.wikipedia.org/wiki/Small_data" rel="noreferrer" target="_blank">
                small data
              </a>{" "}
              problem with rampant{" "}
              <a
                href="https://en.wikipedia.org/wiki/Multicollinearity"
                rel="noreferrer"
                target="_blank"
              >
                multicollinearity
              </a>
              .
            </p>
            <p>
              Let’s back up – if one is interested in understanding a causal relationship, the
              natural thing to do is to run an experiment (such as a{" "}
              <a
                href="https://en.wikipedia.org/wiki/Randomized_controlled_trial"
                rel="noreferrer"
                target="_blank"
              >
                randomized controlled trial
              </a>
              ). Let’s say you wanted to know whether baking soda helps sunflowers to grow taller –
              you could get ten sunflower plants, randomly choose five of them to sprinkle on baking
              soda each day, and measure the height of the plants before and after the experiment.
              If the plants sprinkled with baking soda grew systematically taller, you’d be
              justified in attributing the acceleration in growth to the baking soda, because you
              changed just one thing.
            </p>
            <p>
              Unfortunately, not all domains of life lend themselves so easily to such experiments.
              No client is likely to allow us to spend their marketing budget with a high degree of
              randomness just so we can generate some scientific findings; they need to spend their
              money to produce business results! So we are left to using{" "}
              <a
                href="https://en.wikipedia.org/wiki/Observational_study"
                rel="noreferrer"
                target="_blank"
              >
                observational methods
              </a>
              , i.e. using data that were not generated under any experimental protocol. You can
              probably see the problem – with observational data, we can’t change just one thing.
              Lots of things are changing simultaneously, which introduces many possible{" "}
              <a href="https://en.wikipedia.org/wiki/Confounding" rel="noreferrer" target="_blank">
                confounders
              </a>{" "}
              that muddy the causal relationships we’re interested in.
            </p>
            <p>
              Our synthesis of the experimental and the observational is to combine them. We
              initialize the model with experimental results as{" "}
              <a
                href="https://en.wikipedia.org/wiki/Prior_probability"
                rel="noreferrer"
                target="_blank"
              >
                informative priors
              </a>
              , and then we use the model data to update those priors to form{" "}
              <a
                href="https://en.wikipedia.org/wiki/Posterior_probability"
                rel="noreferrer"
                target="_blank"
              >
                posteriors
              </a>
              , i.e. our ‘final’ beliefs about the causal relationships once we’ve integrated the
              experimental and the observational data.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Statistical jargon – what’s a prior? What is updating? What is a posterior?"
          >
            <ul>
              <li>
                A{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Prior_probability"
                  rel="noreferrer"
                  target="_blank"
                >
                  statistical prior
                </a>{" "}
                is an initial belief about the value of a parameter, expressed as a probability
                distribution. We will normally draw our priors from incrementality tests. For
                example, we may run Conversion Lift on YouTube and use the results to form a prior
                that it generates 0.5 conversions per thousand impressions with a standard deviation
                of 0.2 conversions.
              </li>
              <li>
                A{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Posterior_probability"
                  rel="noreferrer"
                  target="_blank"
                >
                  statistical posterior
                </a>{" "}
                is an updated belief after observing new data. Like the prior, it is also a
                probability distribution. So after observing new evidence we may “update” our belief
                about YouTube to a mean of 0.6 conversions per thousand impressions with a standard
                deviation of 0.3 conversions.
              </li>
              <li>
                Statistical updating, often referred to as “Bayesian updating,” is a formal process
                in which an initial belief (the “prior”) is evaluated in light of some new data (the
                “evidence”) in order to form a revised belief (the “posterior”).
              </li>
            </ul>
            <p>
              An example – imagine we want to know whether it will rain tomorrow. We know from past
              weather data that it rains on 40% of days this time of year; we also know that when it
              rains, the prior day’s dew point is high (above some threshold) 70% of the time.
              Finally, we know that the dew point is high on 50% of days at this time of year.
            </p>
            <p>
              So let’s say today’s dew point is high – what is the likelihood of rain tomorrow?
              Bayes’ rule tells us to multiply our prior probability of our hypothesis (rain
              tomorrow) by the likelihood of the evidence (high dew point today) given our
              hypothesis (rain tomorrow), and then divide by the probability of the evidence (high
              dew point today). Plugging all that in:
            </p>
            <p style={{ width: "100%", justifyContent: "center", display: "flex" }}>
              (40%) x (70%) / (50%) = 56%
            </p>
            <p>
              So we’ve taken our unconditional belief about rain tomorrow (40% on any given day),
              and “updated” it to 56%, conditioned on the evidence we’ve gathered about today’s dew
              point.
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="How does our MMM account for lagged effects of media, e.g. we may run a TV ad today but it will take a person who sees the ad several days to respond?"
          >
            <p>
              We handle this by applying an{" "}
              <a
                href="https://en.wikipedia.org/wiki/Advertising_adstock"
                rel="noreferrer"
                target="_blank"
              >
                adstock transformation
              </a>{" "}
              to each media variable. The intuition behind adstock is the notion that a media
              exposure has some initial effect, followed by a carry-over effect defined by a decay
              rate.
            </p>
            <p>
              You can envision the transformation as taking a point (say, social media impressions
              on a given day) and turning it into a wave, representing how those impressions
              influence consumer behavior over time. The shape of the wave for any particular
              channel is determined by maximizing its explanatory power over the data at hand – e.g.
              is a very fast decay or a very slow decay for social media more helpful in predicting
              the outcome(s) we’re interested in?
            </p>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="How does our MMM account for diminishing returns of investments in media channels?"
          >
            <p>
              We estimate diminishing returns using what’s known as a hill function. Intuitively,
              the hill function captures the idea that as you increase investment in a particular
              channel, the incremental returns (e.g., sales or conversions) tend to diminish after a
              certain point, with these quantitative relationships governed by certain shape and
              saturation parameters.
            </p>
            <p>Why do returns tend to diminish? For two main reasons:</p>
            <ul>
              <li>
                Frequency: as you continue running more ads, the frequency to an individual consumer
                rises, and past some point it ceases to have an incremental impact. The person has
                heard the message enough times already and isn’t further influenced by additional
                exposures.
              </li>
              <li>
                Targeting: if you’re in a channel with advanced targeting capabilities (e.g.
                Facebook), you’ll serve your first ads to the users who are most likely to be
                interested and to convert. Once you’ve targeted and converted those users, you’ll
                move on to slightly less qualified users, and then to yet less qualified users, etc.
                This will manifest as diminishing returns, because your first few ads are highly
                effective but become less so as you reach less and less qualified audiences.
              </li>
            </ul>
          </WidgetContainer>
          <WidgetContainer
            design="secondary"
            startOpen={false}
            collapsible
            header="Can our model account for outliers? E.g. When Deion Sanders wore Blenders sunglasses on College Gameday,
          sales spiked but there was no paid media running that caused it. How do we handle such cases?"
            overRideWidgetClosedHeight={screenWidth < 1445 ? 128 : null}
          >
            <p>
              When we know about such occurrences, handling them within the model is generally quite
              easy – we encode an outlier event corresponding to the abnormal sales spike, which
              prevents{" "}
              <a
                href="https://en.wikipedia.org/wiki/Omitted-variable_bias"
                rel="noreferrer"
                target="_blank"
              >
                omitted variable bias
              </a>{" "}
              and keeps our other parameter estimates (our views about media channels) stable.
              “Encode an outlier” simply refers to creating a new variable in the model and setting
              its value to “on” during the period of the abnormal spike.
            </p>
            <p>
              When we’re not aware of such occurrences, we can look for them in our{" "}
              <a
                href="https://en.wikipedia.org/wiki/Errors_and_residuals"
                rel="noreferrer"
                target="_blank"
              >
                residual
              </a>{" "}
              plots. A “residual” simply refers to the difference between the actual value of the
              outcome and the model’s prediction of that outcome; it is the model’s error term. When
              we look at a plot and see a large miss at a point in time, that’s a hint that there’s
              something “exogenous” going on; in these cases it’s good hygiene to check with our
              client for additional information and ultimately to encode an outlier.
            </p>
          </WidgetContainer>
        </WidgetContainer>
        {isInternal && (
          <WidgetContainer
            collapsible
            header="Implementation Considerations"
            headerCollapsibleOnclick
          >
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="Can we include channels that we don’t manage in a client’s MMM?"
            >
              <p>
                Yes! In fact, we must include all marketing channels of any materiality, whether
                they’re managed by us, by the client internally, or by another media partner. For
                channels we’re not managing ourselves, we simply need our client to grant us read
                access to their channel data so that we can ingest it via our ETL process.
              </p>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="How should a client provide us with insight on activities in marketing channels we don’t manage for them?"
              overRideWidgetClosedHeight={screenWidth < 1445 ? 102 : null}
            >
              <p>
                For all digital channels, clients should provide us with read-only access to the
                relevant platforms. Instructions on how to do this on a platform-by-platform basis
                can be found here. We want to strongly discourage clients providing us with data in
                any other way (flat files, spreadsheets, etc.); our policy is to only accept digital
                channel data programmatically. Exceptions will only be granted in extremely rare
                cases.
              </p>
              <p>
                For offline channels, we are in the process of creating a page in the Bliss Point
                App where clients can share data on offline channels that integrates with our
                models. In the meantime, we will accept these inputs in spreadsheet form.
              </p>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="Does a client need to be running incrementality tests in order to use Bliss Point MMM?"
            >
              <p>
                Yes. Proper incrementality testing is a critical component of a high-quality MMM, to
                the extent that we should really think of MMM + incrementality as intertwined
                products.
              </p>
              <p>
                If a client has not already established an incrementality testing program, it
                doesn’t mean we can’t develop and deploy MMM for them; but we will want to craft and
                implement a testing program with them as part of our process.
              </p>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="How much data is needed to start utilizing the MMM? When will we begin seeing MMM results?"
            >
              <p>
                A year’s worth of data should be sufficient. If we have more data, that’s even
                better. Once we have all source data and go through the data cleaning and ETL
                processes, we can expect to see MMM results in 2-3 weeks.
              </p>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="How often will our MMM be updated?"
            >
              <p>
                Typically, we recommend a monthly or bi-monthly refresh cadence for our MMM. While
                we could refresh our MMM results daily if we wanted to, generally, MMM results are
                not going to change that much day to day. When you accumulate results over 6-12
                weeks, or update the results of an incrementality test, it’s a good time to refresh
                the model.
              </p>
            </WidgetContainer>
            <WidgetContainer
              design="secondary"
              startOpen={false}
              collapsible
              header="How can a client stand up our MMM?"
            >
              <ul>
                <li>
                  [Client] Gain read access to all of the client’s marketing input data: ~1 week
                  pending Client’s approval; note that this step in particular can hold us up but is
                  completely dependent on how quickly you can provide access to us
                </li>
                <li>
                  Receive marketing input data standardized: ~4 weeks (take down to 2 weeks) pending
                  engineering approval for expedited setup
                </li>
                <li>
                  Gather any supplemental data like promotional calendars and historical
                  incrementality test results: ~1 week
                </li>
                <li>
                  Create the final data set with all of the above data: ~1 week after steps 1-3 are
                  complete
                </li>
                <li>
                  QA final data set with the Client’s team: ~1 day - a few weeks depending on
                  initial QA
                </li>
                <li>Build and review an initial model: ~2-3 weeks after step 4 is complete</li>
              </ul>
            </WidgetContainer>
          </WidgetContainer>
        )}
      </div>
    </div>
  );
};

export default FAQ;
