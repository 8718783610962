import "../IncrementalityPlaybook.scss";
import { CommonShoppingSections } from "./Shopping";
import { IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";

const PAGE_TITLE = "Performance Max";

const PerformanceMax = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Performance Max - is a goal-based, shopping campaign type in Google Ads that can be
              used to supplement your current marketing efforts.
            </div>
            <br />
            <ul className="basicList">
              <li>
                Through AI and machine learning, it integrates data from shopping and search
                campaigns into your ad structure, then advertises across YouTube, Display, Search,
                Discover, Gmail, and Maps to strengthen and optimize your pay-per-click (PPC)
                strategy, save you time on account management, and maximize successful performance
                and results.
              </li>
            </ul>
            <br />
            <div className="basicText">
              Of note, methods outlined below are the same across google platforms for Search,
              Shopping, Display (DV360) & Discovery.
            </div>
            <br />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <CommonShoppingSections pageTitle={PAGE_TITLE} />
    </div>
  );
});

export default PerformanceMax;
