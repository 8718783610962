type Action = any & { type: string };
interface ReducerFunction<T> {
  (state: T, action: Action): T;
}

export const makeReducer = <StateType>(
  init: StateType,
  config: Record<string, ReducerFunction<StateType>>
) => (
  // NOTE: though in general this eslint rule is totally great, this is an established redux pattern
  // eslint-disable-next-line default-param-last
  state = init,
  action: Action
): StateType => {
  let stateHandler = config[action.type];
  if (stateHandler) {
    return stateHandler(state, action);
  }
  return state;
};
