import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";

import { Router } from "@reach/router";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Table, ListGroup, ButtonGroup, Button, Form } from "react-bootstrap";

import { OPEN_UP, ANCHOR_RIGHT } from "react-dates-temp/constants";
import { useSetError } from "../redux/modals";

import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { useNestedNav } from "../utils/hooks/useNav";

import {
  Page,
  FullPageSpinner,
  Img,
  BreadcrumbTitle,
  DateRangePicker,
  DateBox,
  SingleDatePicker,
} from "../Components";

import "./StreamingWizard.scss";

const WizardContext = React.createContext({});

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "M/d/yyyy";

const ROUTES = [
  {
    key: "details",
    label: "*",
    uri: "*",
  },
];

const ACCEPT = "Accept";
const EXTEND = "Extend";
const ADD = "Add";

const MAX_COUNT = 100;

const toPrettyDate = R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT));
const toPrettyNumber = num => parseInt(num || 0).toLocaleString();
const toPrettySpend = num =>
  num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
const toPrettyCPM = num =>
  num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

// TODO: alex messages
// - Extending a flight beyond the last date of unmatched impressions
// - Not extending a flight far enough

const WizardList = ({ navigate, wizardData }) => {
  const { unmatchedGroups, networkData } = wizardData;
  const { setMessage } = useContext(WizardContext);

  useEffect(() => {
    if (unmatchedGroups) {
      if (R.length(unmatchedGroups)) {
        let big = R.find(group => R.prop("count", group) > MAX_COUNT, unmatchedGroups);
        if (big) {
          setMessage(
            `You have one with ${toPrettyNumber(
              big.count
            )}. That seems like a lot. Better check that one carefully.`
          );
        } else {
          setMessage("None of these look that big. Probably all bonus.");
        }
      } else {
        setMessage("Huzzah!!!");
      }
    } else {
      setMessage("");
    }
  }, [unmatchedGroups, setMessage]);

  if (!R.length(unmatchedGroups)) {
    return <div className="noWizardRequired">No unmatched impressions!</div>;
  }
  return (
    <Table bordered striped hover className="wizardTable">
      <thead>
        <tr>
          <th>Network</th>
          <th>Derived Network</th>
          <th>Description</th>
          <th>Platform</th>
          <th>Count</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        {unmatchedGroups.map(({ count, derivedNetwork, start, end }) => {
          let { description, network, platform } = networkData[derivedNetwork] || {};
          return (
            <tr key={derivedNetwork} onClick={() => navigate(`./${derivedNetwork}`)}>
              <td className="networkLogoCell">
                <Img
                  className="networkLogo"
                  src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
                />
              </td>
              <td>{derivedNetwork}</td>
              <td>{description}</td>
              <td>{platform}</td>
              <td>{toPrettyNumber(count)}</td>
              <td>{toPrettyDate(start)}</td>
              <td>{toPrettyDate(end)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const Accept = ({ selectedFlight, unmatchedInfo }) => {
  const { setSaving, resetWizard } = useContext(WizardContext);
  const setError = useSetError();

  const [because, setBecause] = useState("");

  const onSave = useCallback(async () => {
    setSaving(true);
    try {
      await StreamingV2LambdaFetch("/wizard", {
        method: "POST",
        body: {
          derivedNetwork: unmatchedInfo.derivedNetwork,
          type: "ACCEPT",
          dates: R.pipe(R.prop("dates"), R.pluck("date"))(unmatchedInfo),
          because,
        },
      });
      resetWizard();
    } catch (e) {
      setError({
        message: `Couldn't submit wizarding. Error: ${e.message}`,
        reportError: e,
      });
      setSaving(false);
    }
  }, [setSaving, setError, resetWizard, unmatchedInfo, because]);
  if (selectedFlight.length) {
    return (
      <div>You cannot accept impressions as bonus "on a flight". Please un-select the flight.</div>
    );
  }
  return (
    <>
      <Form.Control
        as="textarea"
        className="because"
        placeholder="Because..."
        value={because}
        onChange={e => setBecause(e.target.value)}
      />
      <Button block variant="primary" onClick={onSave}>
        Accept {toPrettyNumber(unmatchedInfo.count)} impression
        {unmatchedInfo.count === 1 ? "" : "s"} as bonus
      </Button>
    </>
  );
};
const Extend = ({ selectedFlight, flights }) => {
  const setError = useSetError();
  const { setSaving, resetWizard } = useContext(WizardContext);

  const isSubFlight = useMemo(() => selectedFlight[0] === "sub", [selectedFlight]);
  let [flight, maxExtended] = useMemo(() => {
    if (!selectedFlight.length) {
      return [];
    }

    // Flights are coming to us pre-sorted. If I keep track of the last start date, once I find the
    // flight in question, I know I can only extend it up to that date.
    let lastStart;
    let ourFlight;
    for (let flight of flights) {
      if (flight.id === selectedFlight[isSubFlight ? 2 : 0]) {
        if (isSubFlight) {
          for (let sub of flight.subFlights) {
            if (sub.id === selectedFlight[1]) {
              ourFlight = sub;
              break;
            } else {
              lastStart = sub.start;
            }
          }
        } else {
          ourFlight = flight;
        }
        break;
      } else if (!isSubFlight) {
        lastStart = flight.start;
      }
    }
    let maxExtended = Infinity;
    if (lastStart) {
      maxExtended =
        Dfns.differenceInDays(Dfns.parseISO(ourFlight.end), Dfns.parseISO(lastStart)) - 1;
    }
    return [ourFlight, maxExtended];
  }, [isSubFlight, selectedFlight, flights]);

  const [days, setDays] = useState(1);
  const [daysRaw, setDaysRaw] = useState("1");
  const newExtended = useMemo(() => {
    if (!flight || !flight.end) {
      return null;
    }
    return R.pipe(
      R.prop("end"),
      Dfns.parseISO,
      Dfns.addDays(days),
      Dfns.format(DATE_FORMAT)
    )(flight);
  }, [flight, days]);

  const onSave = useCallback(async () => {
    setSaving(true);
    try {
      await StreamingV2LambdaFetch("/wizard", {
        method: "POST",
        body: {
          derivedNetwork: flight.derived_network,
          type: "EXTEND",
          newExtended,
          id: flight.id,
          isSubFlight,
        },
      });
      resetWizard();
    } catch (e) {
      setError({
        message: `Couldn't submit wizarding. Error: ${e.message}`,
        reportError: e,
      });
      setSaving(false);
    }
  }, [setSaving, setError, resetWizard, flight, newExtended, isSubFlight]);
  if (!selectedFlight.length) {
    return <div>Please select a flight from the list above to extend.</div>;
  }
  if (isSubFlight && !flight.end) {
    return <div>You can't extend a sub flight past its parent.</div>;
  }
  return (
    <div>
      Extend flight <strong>#{flight.id}</strong> by{" "}
      <Form.Control
        className="daysInput"
        size="sm"
        type="number"
        min="0"
        max={maxExtended}
        value={daysRaw}
        onChange={e => {
          let val = e.target.value;
          setDaysRaw(val);
          let parsed = parseInt(val);
          if (!isNaN(parsed)) {
            setDays(parsed);
          }
        }}
        onBlur={() => {
          let cleanVal = Math.min(maxExtended, Math.abs(parseInt(daysRaw) || 0));
          setDays(cleanVal);
          setDaysRaw(cleanVal);
        }}
      />{" "}
      day{days === 1 ? "" : "s"}, making the extended end date{" "}
      <strong>{toPrettyDate(newExtended)}</strong>.
      <Button block variant="primary" disabled={!days} onClick={onSave}>
        Extend Flight
      </Button>
    </div>
  );
};
const Add = ({ selectedFlight, flights, unmatchedInfo }) => {
  const setError = useSetError();
  const { setSaving, resetWizard } = useContext(WizardContext);

  const isSubFlight = useMemo(() => selectedFlight[0] === "sub", [selectedFlight]);
  const flight = useMemo(() => {
    if (!selectedFlight.length || isSubFlight) {
      return null;
    }
    return R.find(flight => flight.id === selectedFlight[0], flights);
  }, [selectedFlight, flights, isSubFlight]);

  const [startDate, setStartDate] = useState();
  const [days, setDays] = useState(0);
  const [daysRaw, setDaysRaw] = useState("0");

  const newExtended = useMemo(() => {
    if (!flight) {
      return null;
    }
    return R.pipe(
      Dfns.parseISO,
      Dfns.addDays(days),
      Dfns.format(DATE_FORMAT)
    )(startDate || flight.start);
  }, [flight, startDate, days]);

  const onSave = useCallback(async () => {
    setSaving(true);
    try {
      await StreamingV2LambdaFetch("/wizard", {
        method: "POST",
        body: {
          derivedNetwork: unmatchedInfo.derivedNetwork,
          type: "ADD",
          startDate,
          newExtended,
          id: flight.id,
        },
      });
      resetWizard();
    } catch (e) {
      setError({
        message: `Couldn't submit wizarding. Error: ${e.message}`,
        reportError: e,
      });
      setSaving(false);
    }
  }, [setSaving, setError, resetWizard, unmatchedInfo, flight, newExtended, startDate]);

  if (!selectedFlight.length) {
    return <div>Please select a flight from the list above to extend.</div>;
  }
  if (isSubFlight) {
    return <div>You cannot add a sub flight to a sub flight.</div>;
  }
  return (
    <div>
      Add zero-length sub-flight to flight <strong>#{flight.id}</strong> starting on{" "}
      <SingleDatePicker
        block={false}
        regular={false}
        small
        showClearDate
        placeholder="same day as flight"
        openDirection={OPEN_UP}
        anchorDirection={ANCHOR_RIGHT}
        date={startDate}
        onChange={setStartDate}
        isOutsideRange={date => date < flight.start || date > (flight.extended || flight.end)}
      />{" "}
      and extending{" "}
      <Form.Control
        className="daysInput"
        size="sm"
        type="number"
        min="0"
        value={daysRaw}
        onChange={e => {
          let val = e.target.value;
          setDaysRaw(val);
          let parsed = parseInt(val);
          if (!isNaN(parsed)) {
            setDays(parsed);
          }
        }}
        onBlur={() => {
          let cleanVal = Math.abs(parseInt(daysRaw) || 0);
          setDays(cleanVal);
          setDaysRaw(cleanVal);
        }}
      />{" "}
      day{days === 1 ? "" : "s"}, making the extended end date{" "}
      <strong>{toPrettyDate(newExtended)}</strong>.
      <Button block variant="primary" disabled={!newExtended} onClick={onSave}>
        Add Property to Flight
      </Button>
    </div>
  );
};
const ActionBox = ({ action, ...rest }) => {
  let Comp;
  switch (action) {
    case ACCEPT:
      Comp = Accept;
      break;
    case EXTEND:
      Comp = Extend;
      break;
    case ADD:
      Comp = Add;
      break;
    default:
      return null;
  }
  return (
    <div className="actionBox">
      <Comp {...rest} />
    </div>
  );
};

const Details = ({ derivedNetwork, wizardData }) => {
  const { setMessage } = useContext(WizardContext);

  const [networkData, unmatchedInfo] = useMemo(
    () => [
      R.path(["networkData", derivedNetwork], wizardData),
      R.pipe(
        R.prop("unmatchedGroups"),
        R.find(group => group.derivedNetwork === derivedNetwork)
      )(wizardData),
    ],
    [derivedNetwork, wizardData]
  );

  const [selectedFlight, setSelectedFlight] = useState([]);
  const [selectedAction, setSelectedAction] = useState();

  const hasSubs = !!R.prop("parent", networkData);

  const sortedImpRows = useMemo(
    () =>
      unmatchedInfo
        ? R.pipe(R.prop("dates"), R.sort(R.descend(R.prop("date"))))(unmatchedInfo)
        : null,
    [unmatchedInfo]
  );

  // TODO: make a method for action box components to pass messages

  useEffect(() => {
    setMessage("");
  }, [setMessage]);
  useEffect(() => {
    if (unmatchedInfo && sortedImpRows && networkData) {
      let message = (() => {
        if (unmatchedInfo.count < MAX_COUNT) {
          return `You have fewer than ${MAX_COUNT} impressions here. You probably want to just mark them as bonus.`;
        }
        let lastFlight;
        // Everything will be based on the first imp date we have
        let firstImpDate = R.path([sortedImpRows.length - 1, "date"], sortedImpRows);
        // We want flights in ascending chronological order.
        let flights = R.pipe(R.prop("flights"), R.reverse)(networkData);

        if (!R.length(flights)) {
          return `You have NO flights for ${derivedNetwork}. That shouldn't happen. Either accept these as bonus or make a fake flight for them in the buying platform.`;
        }

        for (let flight of flights) {
          // flight ends before first imp, then you save in last flight and move on
          if (flight.end < firstImpDate) {
            lastFlight = flight;
          } else if (firstImpDate < flight.start) {
            // If the flight is after the imp, break and we'll use whatever "lastFlight" is
            break;
          } else {
            // If the imp isn't after the flight or before the flight, it has to be DURING the
            // flight.

            // If an impression overlaps with a non-DGO flight, then it's literally matched to that
            // flight. The only time that isn't true is in the DGO case.
            if (!hasSubs) {
              return "Uh oh. It looks like you have impressions that overlap with a flight and don't match, but this isn't a network with a parent. This should not be possible. Tell someone from engineering!";
            }

            let bestSub;
            for (let subFlight of flight.subFlights || []) {
              // If this sub flight starts after the first impression, it won't work
              if (subFlight.start && subFlight.start > firstImpDate) {
                continue;
              }
              let effectiveEnd = subFlight.extended || subFlight.end;
              // If our flight ends, and ends before the impressions, then it's a contender
              if (effectiveEnd && effectiveEnd < firstImpDate) {
                // If we don't have a reigning best sub flight, this is it
                if (!bestSub) {
                  bestSub = subFlight;
                  continue;
                }
                // If we already have a best sub, this one dethrones it if it ends later.
                let bestEffectiveEnd = bestSub.extended || bestSub.end;
                if (bestEffectiveEnd < effectiveEnd) {
                  bestSub = subFlight;
                }
              }
            }
            if (bestSub) {
              return `Some of these impressions overlap with flight #${bestSub.flight_id}, which had this property on it before. I think you should extend sub-flight #${bestSub.id}.`;
            }
            return `Some of these impressions overlap with flight #${flight.id}, but it doesn't have a good sub flight to extend. I think you should use the "add" feature on it.`;
          }
        }

        if (!lastFlight) {
          return "Your impressions start before your first flight. They are probably test impressions that you should just accept.";
        }
        return `I think you should extend flight #${lastFlight.id}.`;
      })();

      if (networkData.platform.includes("RTB")) {
        message = `${message}\nBy the way, this is RTB. Accepting impressions doesn't make them "bonus", just hidden.`;
      }
      setMessage(message);
    }
  }, [setMessage, sortedImpRows, networkData, unmatchedInfo, hasSubs, derivedNetwork]);

  if (!(networkData && unmatchedInfo)) {
    return <div className="noWizardRequired">No Data</div>;
  }
  return (
    <div className="details">
      <div className="header">
        <div className="logo">
          <Img
            className="networkLogo"
            src={`https://cdn.blisspointmedia.com/networks/${networkData.network}.png`}
          />
        </div>
        <div className="headerSection">
          <div className="top">
            <div className="derivedID">{derivedNetwork}</div>
            <div className="dash" />
            <div className="network">
              {networkData.network}
              {networkData.parent && <small>(child of {networkData.parent})</small>}
            </div>
            {networkData.description && (
              <>
                <div className="dash" />
                <div className="description">{networkData.description}</div>
              </>
            )}
            <div className="dash" />
            <div className="platform">{networkData.platform}</div>
            {networkData.dsp && (
              <>
                <div className="dash" />
                <div className="dsp">{networkData.dsp}</div>
              </>
            )}
          </div>
          <div className="bottom">
            <strong>{toPrettyNumber(unmatchedInfo.count)}</strong>impression
            {unmatchedInfo.count === 1 ? "" : "s"} from{" "}
            <strong>{toPrettyDate(unmatchedInfo.start)}</strong>through{" "}
            <strong>{toPrettyDate(unmatchedInfo.end)}</strong>
          </div>
        </div>
      </div>
      <div className="wizardBody">
        <div className="flightList">
          <ListGroup>
            {networkData.flights.map(
              ({ id, order_id, start, end, extended, spend, cpm, canceled, subFlights }) => (
                <React.Fragment key={id}>
                  <ListGroup.Item
                    className={`flightItem${selectedFlight[0] === id ? " selected" : ""}`}
                    action
                    onClick={() => setSelectedFlight(selected => (selected[0] === id ? [] : [id]))}
                  >
                    <div className="idBox">
                      <div>ID: {id}</div>
                      <div className="subBox">(order {order_id})</div>
                    </div>

                    <div className="spendInfo">
                      <div>{toPrettySpend(spend)}</div>
                      <div className="subBox">(CPM: {toPrettyCPM(cpm)})</div>
                    </div>
                    <div className="dateBox">
                      <DateBox start={start} end={end} />
                      <div className="subBox">
                        {extended && <small>(extended to {toPrettyDate(extended)})</small>}
                      </div>
                    </div>
                    {canceled && <div className="canceledBox">Canceled</div>}
                  </ListGroup.Item>
                  {subFlights &&
                    subFlights.map(({ id: subID, start, end, extended, zero_length }) => {
                      let classes = ["flightItem", "subFlight"];
                      if (selectedFlight[1] === subID) {
                        classes.push("selected");
                      }
                      return (
                        <ListGroup.Item
                          key={subID}
                          action
                          className={classes.join(" ")}
                          onClick={() =>
                            setSelectedFlight(selected =>
                              selected[1] === subID ? [] : ["sub", subID, id]
                            )
                          }
                        >
                          <div className="idBox">ID: {subID}</div>
                          {zero_length && <div className="zeroLength">Zero-length</div>}
                          <div className="dateBox">
                            <DateBox
                              noParse
                              start={start ? toPrettyDate(start) : <em>same as parent</em>}
                              end={end ? toPrettyDate(end) : <em>same as parent</em>}
                            />
                            <div className="subBox">
                              {extended && <small>(extended to {toPrettyDate(extended)})</small>}
                            </div>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                </React.Fragment>
              )
            )}
          </ListGroup>
        </div>
        <div className="impressionTable">
          <Table striped bordered size="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {sortedImpRows.map(({ date, count }) => (
                <tr key={date}>
                  <td>{toPrettyDate(date)}</td>
                  <td>{toPrettyNumber(count)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="wizardAction">
        <ButtonGroup className="actionButtons">
          {[ACCEPT, EXTEND, ...(hasSubs ? [ADD] : [])].map(key => (
            <Button
              key={key}
              variant={`${selectedAction === key ? "" : "outline-"}primary`}
              onClick={() => setSelectedAction(action => (action === key ? null : key))}
            >
              {key}
            </Button>
          ))}
        </ButtonGroup>
        <ActionBox
          action={selectedAction}
          selectedFlight={selectedFlight}
          flights={networkData.flights}
          unmatchedInfo={unmatchedInfo}
        />
      </div>
    </div>
  );
};

export const StreamingWizard = ({ navigate }) => {
  const setError = useSetError();
  const path = useNestedNav({
    baseURL: "streaming/wizard",
    routes: ROUTES,
  });
  const { company } = useLocation();
  const [wizardData, setWizardData] = useState();
  const [dates, setDatesRaw] = useState(() => ({
    start: R.pipe(Dfns.subDays(60), Dfns.format(DATE_FORMAT))(new Date()),
    end: R.pipe(Dfns.addDays(1), Dfns.format(DATE_FORMAT))(new Date()),
  }));

  useEffect(() => {
    if (!wizardData) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/wizard", {
            params: {
              company,
              ...dates,
            },
          });
          let data = await awaitJSON(res);
          setWizardData(data);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [setError, wizardData, company, dates]);

  const setDates = useCallback(dates => {
    setDatesRaw(dates);
    setWizardData();
  }, []);

  const [message, setMessage] = useState();
  const [wizardHidden, setWizardHidden] = useState(false);

  const [saving, setSaving] = useState(false);

  const resetWizard = useCallback(() => {
    navigate("./", { replace: true });
    setSaving(false);
    setWizardData();
  }, [setSaving, navigate]);

  return (
    <Page
      title={<BreadcrumbTitle path={path} title="Streaming Wizard" navigate={navigate} />}
      pageType="Streaming Wizard"
      minHeight={600}
      minWidth={900}
      actions={
        <div>
          <DateRangePicker
            startDate={dates.start}
            endDate={dates.end}
            startDateId="wizardStartDate"
            endDateId="wizardEndDate"
            onChange={({ startDate, endDate }) =>
              setDates({
                start: startDate,
                end: endDate,
              })
            }
          />
        </div>
      }
    >
      <WizardContext.Provider
        value={{
          setMessage,
          setSaving,
          resetWizard,
        }}
      >
        <div className="streamingWizard">
          {wizardData ? (
            <Router>
              <Details path=":derivedNetwork" wizardData={wizardData} />
              <WizardList default wizardData={wizardData} />
            </Router>
          ) : (
            <FullPageSpinner />
          )}
          <img
            className={`wizardHimself${wizardHidden ? " hidden" : ""}`}
            src="https://cdn.blisspointmedia.com/assets/img/wizard.png"
            alt="wizard"
            onClick={() => setWizardHidden(R.not)}
          />
          {!wizardHidden && (
            <div className="speechBubble" onClick={() => setWizardHidden(R.not)}>
              {!R.path([0, "resolvedPath"], path) && <div>Welcome to Streaming V2!</div>}
              {message && (
                <div>
                  {message.split("\n").map((chunk, i) => (
                    <p key={i}>{chunk}</p>
                  ))}
                </div>
              )}
              <div className="hint">(You can click on me to hide me)</div>
            </div>
          )}
          {saving && (
            <div className="saving">
              <FullPageSpinner />
            </div>
          )}
        </div>
      </WizardContext.Provider>
    </Page>
  );
};

export default StreamingWizard;
