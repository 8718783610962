import "./MTModalRowEditor.scss";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { CheckBox, Header, TableDataType } from "../Components";
import * as R from "ramda";
import React, { Dispatch, useCallback, useState, useMemo } from "react";
import TargetingCheckBox from "../MasterTargeting/TargetingCheckBox";

interface MTModalRowEditorProps<T> {
  deletedRows: T[];
  headers: Header[];
  invalidText: string;
  isAddingNewRow: boolean;
  name: string | JSX.Element;
  onHide: (args?) => void;
  rowData: T;
  setDeletedRows: Dispatch<T[]>;
  setIsAddingNewRow: Dispatch<boolean>;
  setRowData: (rowData: T, oldIndex: number, newIndex: number) => void;
}

const MTModalRowEditor = <T extends TableDataType>(
  props: MTModalRowEditorProps<T>
): JSX.Element => {
  const {
    deletedRows,
    headers,
    invalidText = "",
    isAddingNewRow,
    name,
    onHide,
    rowData,
    setDeletedRows = () => [],
    setIsAddingNewRow,
    setRowData,
  } = props;

  const [editedData, setEditedData] = useState<T>({} as T);
  const [shouldShowInvalidText, setShouldShowInvalidText] = useState<boolean>(false);

  const data: T = useMemo(() => R.mergeRight(rowData, editedData), [editedData, rowData]);

  const onDelete = useCallback(() => {
    if (!R.isNil(rowData.index)) {
      deletedRows.push(rowData);
      setDeletedRows && setDeletedRows(deletedRows);
      setRowData(data, rowData.index, -1);
      setIsAddingNewRow(false);
      setShouldShowInvalidText(false);
    }
  }, [data, deletedRows, rowData, setDeletedRows, setIsAddingNewRow, setRowData]);

  const onCancel = useCallback(() => {
    if (isAddingNewRow) {
      onDelete();
    }
    onHide();
    setShouldShowInvalidText(false);
  }, [isAddingNewRow, onDelete, onHide]);

  const onSave = useCallback(() => {
    if (!R.isNil(rowData.index) && !R.isNil(data.index)) {
      setRowData(data, rowData.index, data.index);
      setIsAddingNewRow(false);
      setShouldShowInvalidText(false);
    } else {
      setShouldShowInvalidText(true);
    }
  }, [data, rowData.index, setIsAddingNewRow, setRowData]);

  const invalidSet = useMemo(
    () =>
      R.reduce(
        (invalidSet, header) =>
          header.isInvalid && header.isInvalid(data[R.defaultTo("", header.field)])
            ? R.assoc(R.defaultTo("", header.field), true, invalidSet)
            : invalidSet,
        {},
        headers
      ),
    [data, headers]
  );

  const makeInputElement = useCallback(
    header => {
      let headerType = header.type;
      if (header.rendererType) {
        headerType = header.rendererType(data);
      }
      if (header.uneditable) {
        return;
      }
      if (headerType === "currency") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              !R.isNil(data[header.field])
                ? parseFloat(data[header.field]).toLocaleString("en-US", {
                    style: headerType,
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : ""
            }
            onChange={event => {
              let newVal: number = parseFloat(event.target.value.replace(/[^0-9]/g, ""));
              if (headerType === "percent" && !isNaN(newVal)) {
                newVal /= 100;
                newVal = Math.min(1, Math.max(0, newVal));
              }
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "real") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0
                ? parseFloat(data[header.field]).toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : ""
            }
            onChange={event => {
              let newVal = parseFloat(event.target.value.replace(/[^0-9\\.]/g, ""));
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "integer") {
        return (
          <Form.Control
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0 ? parseFloat(data[header.field]) : ""
            }
            onChange={event => {
              let newVal = parseInt(event.target.value.replace(/[^0-9\\.]/g, ""));
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "decimal") {
        return (
          <Form.Control
            type={"number"}
            isInvalid={invalidSet[header.field]}
            value={
              data[header.field] || data[header.field] === 0 ? parseFloat(data[header.field]) : ""
            }
            onChange={event => {
              let newVal = parseFloat(event.target.value);
              setEditedData({
                ...editedData,
                [header.field]: isNaN(newVal) ? null : newVal,
              });
            }}
          />
        );
      } else if (headerType === "checkbox") {
        return (
          <CheckBox
            checked={data[header.field]}
            onCheck={checked => {
              setEditedData({ ...editedData, [header.field]: +checked });
            }}
          />
        );
      } else if (headerType === "targetingCheckBox") {
        return (
          <TargetingCheckBox
            checkBoxState={data[header.field]}
            onCheck={checkBoxState => {
              setEditedData({ ...editedData, [header.field]: checkBoxState });
            }}
          ></TargetingCheckBox>
        );
      }

      return (
        <Form.Control
          isInvalid={invalidSet[header.field]}
          type={"text"}
          defaultValue={data[header.field]}
          key={header.field}
          readOnly={header.readOnly}
          onChange={e =>
            setEditedData({
              ...editedData,
              [header.field]: e.target.value ? e.target.value.replace(/,/g, "").trim() : null,
            })
          }
        />
      );
    },
    [data, editedData, invalidSet]
  );

  const makeGroupClassName = useCallback(row => {
    const { field } = row[0];
    if (field === "m_f") {
      return "demoTargetingGroup";
    } else if (field === "desktop") {
      return "deviceTargetingGroup";
    } else if (field === "dma") {
      return "geoTargetingGroup";
    } else if (field === "advertiser_first_party_data") {
      return "advancedTargetingGroup1";
    } else if (field === "retargeting") {
      return "advancedTargetingGroup2";
    } else if (field === "ott") {
      return "mediaTypeGroup";
    } else if (field === "beeswax") {
      return "dspsGroup";
    } else {
      return "defaultGroup";
    }
  }, []);

  const makeHeaderElement = useCallback((field, modalRow) => {
    if (field === "m_f") {
      return (
        <div key={field} className="modalRowHeader demoTargetingHeader">
          Demo Targeting
        </div>
      );
    } else if (field === "desktop") {
      return (
        <div key={field} className="modalRowHeader deviceTargetingHeader">
          Device Targeting
        </div>
      );
    } else if (field === "dma") {
      return (
        <div key={field} className="modalRowHeader geoTargetingHeader">
          Geo Targeting
        </div>
      );
    } else if (field === "advertiser_first_party_data") {
      return (
        <div key={field} className="modalRowHeader advancedTargetingHeader">
          Advanced Targeting
        </div>
      );
    } else if (field === "ott") {
      return (
        <div key={field} className="modalRowHeader mediaTypeHeader">
          Media Type
        </div>
      );
    } else if (field === "beeswax") {
      return (
        <div key={field} className="modalRowHeader dspsHeader">
          DSPs
        </div>
      );
    } else {
      return <></>;
    }
  }, []);

  return (
    <Modal
      size="lg"
      keyboard={true}
      show={true}
      onHide={onCancel}
      className="constraintViewEditModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        {R.values(
          R.map(
            row => (
              <div key={row[0].label} className={makeGroupClassName(row)}>
                {makeHeaderElement(row[0].field, row[0].modalRow)}
                <div key={R.path([0, "modalRow"], row)} className="modalRow">
                  {row.map(header => {
                    return header.uneditable ? (
                      <div></div>
                    ) : (
                      <Form.Group
                        key={header.field}
                        style={{
                          flex: header.modalFlex || 0,
                          minWidth: header.modalWidth || 0,
                        }}
                      >
                        {header.type !== "" && (
                          <Form.Label className="modalHeaderLabel">{header.label}</Form.Label>
                        )}
                        {makeInputElement(header)}
                      </Form.Group>
                    );
                  })}
                </div>
              </div>
            ),
            R.sortBy(
              elem => elem[0].modalRow as R.Ord,
              R.values(R.groupBy(elem => R.prop("modalRow", elem) as string, headers))
            )
          )
        )}
        {shouldShowInvalidText && (
          <div className="mtAlertContainer">
            <Alert className="invalidText" variant="danger">
              {invalidText}
            </Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modalControls">
        <Button variant="danger" className="delete" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="dark" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="sendButton"
          disabled={!R.isEmpty(invalidSet)}
          onClick={onSave}
        >
          <span>Save</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MTModalRowEditor;
