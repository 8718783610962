import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import AutoSizer from "react-virtualized-auto-sizer";

import { Tooltip, Button } from "react-bootstrap";

import { useSetError } from "../redux/modals";

import { ToolsLambdaFetch, awaitJSON, SheetsLambdaFetch } from "../utils/fetch-utils";

import { InfoTooltip } from "../Components/InfoTooltip/InfoTooltip";

import { useStateFunction } from "../utils/hooks/useData";

import { makeMemoizedGetter } from "../utils/data";

import { MdError, MdTv, MdHeadset, MdImage, MdRadio } from "react-icons/md";
import { ReactComponent as StreamingIcon } from "../NavBar/streaming.svg";

import { computeTextColor } from "../utils/colors";

import {
  Page,
  StickyTable,
  Img,
  Skeleton,
  TableSkeleton,
  OverlayTrigger,
  OldFilterBar,
} from "../Components";

import "./Calendar.scss";

const ROW_HEIGHT = 110;
const LEFT_WIDTH = 90;
const COLUMN_WIDTH = 280;
const TOP_HEIGHT = 100;
const HEADER_HEIGHT = 100;
const SHORT_DATE_FORMAT = "MM/dd";
const DATE_FORMAT = "yyyy-MM-dd";

const normalizeSpend = spend => `$${Math.round(spend / 1000)}K`;

const startOfThisWeek = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const getIsCurrentWeek = makeMemoizedGetter({
  calculate: date => {
    let nextWeek = R.pipe(Dfns.parseISO, Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(date);
    let twoWeeksAgo = R.pipe(Dfns.subWeeks(2), Dfns.format(DATE_FORMAT))(new Date());
    return date <= twoWeeksAgo && nextWeek > twoWeeksAgo;
  },
});

const inWarningRange = date => {
  let currentWeek = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
  let nextWeek = R.pipe(
    Dfns.startOfISOWeek,
    Dfns.addWeeks(1),
    Dfns.format(DATE_FORMAT)
  )(new Date());
  return date >= currentWeek && date <= nextWeek;
};

const Calendar = () => {
  const setError = useSetError();
  const [masterCompanyInfo, setMasterCompanyInfo] = useState();
  const [holidays, setHolidays] = useState([]);
  const [calendarData, setCalendarData] = useState({});
  const [filter, setFilter] = useStateFunction(() => true);

  // Fetch calendar data
  useEffect(() => {
    if (!R.keys(masterCompanyInfo).length) {
      (async () => {
        try {
          const res = await ToolsLambdaFetch("/getMediaSummary");
          const resData = await awaitJSON(res);
          const { data, calendarCompanies } = resData;

          setMasterCompanyInfo(calendarCompanies);
          setCalendarData(data);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [setError, masterCompanyInfo]);

  // Fetch holidays
  useEffect(() => {
    if (!holidays.length) {
      (async () => {
        try {
          let res = await SheetsLambdaFetch("/holidays");
          let data = await awaitJSON(res);
          setHolidays(data);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  });

  // Filter Bar
  const filterOptions = [
    { name: "cid", label: "Company" },
    { name: "mediaTypes", label: "Media" },
  ];
  const filterBarLines = useMemo(
    () =>
      R.keys(masterCompanyInfo || {}).map(cid => ({
        cid,
        mediaTypes: masterCompanyInfo[cid].mediaTypes.sort(),
      })),
    [masterCompanyInfo]
  );

  const { leftData, topData, tableData } = useMemo(() => {
    if (!masterCompanyInfo) {
      return {};
    }
    let weekSet = {};

    for (let mediaGroup of R.values(calendarData)) {
      for (let mediaType of R.values(mediaGroup)) {
        for (let week of R.keys(mediaType)) {
          weekSet[week] = true;
        }
      }
    }

    // Top Data
    const topData = R.pipe(R.filter(filter), R.pluck("cid"), R.sortBy(R.identity))(filterBarLines);

    // Left Data
    const leftData = R.pipe(R.keys, R.sortBy(R.identity))(weekSet);

    // Table Data
    let tableData = [];
    for (let week of leftData) {
      let row = [];
      for (let company of topData) {
        if (!calendarData[company]) {
          row.push({ company, week });
        } else {
          const companyInfo = calendarData[company];
          let cell = {};
          for (let mediaType of R.keys(companyInfo)) {
            cell[mediaType] = R.path([mediaType, week], companyInfo);
            cell.company = company;
            cell.week = week;
          }
          row.push(cell);
        }
      }
      tableData.push(row);
    }

    return { leftData, topData, tableData };
  }, [calendarData, masterCompanyInfo, filter, filterBarLines]);

  const cornerRenderer = corner => {
    if (corner !== "nw") {
      return null;
    }
    return (
      <div className="calendarCornerHeader">
        <div>Week</div>
      </div>
    );
  };

  // When dates load, it scrolls to the current week
  const stickyTableScrollToRef = useRef();
  const [scrolled, setScrolled] = useState(false);
  useLayoutEffect(() => {
    if (R.length(leftData) > 0 && tableData && !scrolled && stickyTableScrollToRef.current) {
      for (let i = 0; i < leftData.length; ++i) {
        if (getIsCurrentWeek(leftData[i])) {
          setScrolled(true);
          stickyTableScrollToRef.current(i, 0);
          break;
        }
      }
    }
  }, [leftData, tableData, scrolled]);

  const resetExpectedBookings = useCallback(async () => {
    try {
      await ToolsLambdaFetch("/reset_expected_bookings", { method: "post" });
      window.location.reload();
    } catch (e) {
      setError({
        reportError: e,
        message: e.message,
      });
    }
  }, [setError]);

  return (
    <Page
      title="Calendar"
      pageType="Calendar"
      actions={
        <div className="bpmCalendarPageActions">
          <InfoTooltip size="reg">
            <strong>Booked: </strong>The total amount of media we've booked. For linear, this is in
            gross.
            <br />
            <br />
            <strong>Target: </strong>The total amount of media we should have booked in order to
            spend to expectation. For linear media, this incorporates our clearance forecast.
            <br />
            <br />
            <strong>Expected: </strong>Client's desired spend level.
          </InfoTooltip>
          <div className="calendarLegend">Booked / Target (Expected)</div>
          <Button size="sm" variant="link" onClick={resetExpectedBookings}>
            Reset expected bookings
          </Button>
        </div>
      }
    >
      <div className="bpmCalendarPage">
        <div className="calendarFilterBar">
          <OldFilterBar options={filterOptions} lines={filterBarLines} onFilter={setFilter} />
        </div>
        <div className="calendarTable">
          {tableData && leftData ? (
            <AutoSizer>
              {({ width, height }) => (
                <StickyTable
                  scrollToRef={stickyTableScrollToRef}
                  alternateColors={false}
                  width={width}
                  height={height}
                  leftWidth={LEFT_WIDTH}
                  rowHeight={ROW_HEIGHT}
                  columnWidth={COLUMN_WIDTH}
                  topHeight={TOP_HEIGHT}
                  data={tableData}
                  topData={topData}
                  leftData={leftData}
                  cornerRenderer={cornerRenderer}
                  topRenderer={({ data, style, classes }) => {
                    return (
                      <OverlayTrigger
                        placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                        overlay={<Tooltip>{data}</Tooltip>}
                      >
                        <div className={[...classes, "topDataCell"].join(" ")} style={style}>
                          <div className="logo">
                            <Img
                              src={`https://cdn.blisspointmedia.com/companies/${data}/logo.png`}
                            />
                          </div>
                        </div>
                      </OverlayTrigger>
                    );
                  }}
                  leftRenderer={({ data, style, classes }) => {
                    let props = { style };
                    classes.push("leftWeek");

                    if (data === startOfThisWeek) {
                      classes.push("currentWeek currentWeekDateStyle");
                    }

                    let isHolidayWeek = false;
                    let holidayInfo;
                    for (let holiday of holidays) {
                      let holidayWeek = R.pipe(
                        Dfns.parseISO,
                        Dfns.startOfISOWeek,
                        Dfns.format(DATE_FORMAT)
                      )(holiday.date);
                      if (holidayWeek === data) {
                        isHolidayWeek = true;
                        holidayInfo = holiday;
                      }
                    }

                    props.className = classes.join(" ");

                    return (
                      <div {...props}>
                        <div>{R.pipe(Dfns.parseISO, Dfns.format(SHORT_DATE_FORMAT))(data)}</div>
                        {isHolidayWeek && (
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                            overlay={
                              <Tooltip>{`${holidayInfo.summary} ${holidayInfo.date}`}</Tooltip>
                            }
                          >
                            <Img
                              className="calendarHolidayLogo"
                              src={`https://cdn.blisspointmedia.com/assets/img/holidays/${holidayInfo.url}`}
                            />
                          </OverlayTrigger>
                        )}
                      </div>
                    );
                  }}
                  cellRenderer={({ data, style, classes }) => {
                    if (data.week === startOfThisWeek) {
                      classes.push("currentWeek");
                    }

                    let hasAnything = false;
                    let values = {};
                    for (let mediaType of R.path([data.company, "mediaTypes"], masterCompanyInfo) ||
                      []) {
                      let ourData = data[mediaType];

                      let noBookedValue = true;
                      let noExpectedValue = true;

                      if (!R.isNil(ourData)) {
                        noBookedValue =
                          R.isNil(ourData.booked) ||
                          R.isEmpty(ourData.booked) ||
                          ourData.booked === 0;
                        noExpectedValue =
                          R.isNil(ourData.expected) ||
                          R.isEmpty(ourData.expected) ||
                          ourData.expected === 0;
                      }

                      if (!noBookedValue || !noExpectedValue) {
                        hasAnything = true;
                        values[mediaType] = {
                          booked: ourData.booked,
                          expected: ourData.expected,
                          target: ourData.target,
                        };
                      }
                    }

                    if (!hasAnything) {
                      return <div className={classes.join(" ")} style={style} />;
                    }

                    return (
                      <div className={[...classes, "contentCell"].join(" ")} style={style}>
                        <div
                          className="contentBox"
                          style={{
                            color: computeTextColor(
                              R.path([data.company, "color"], masterCompanyInfo)
                            ),
                            borderColor: R.path([data.company, "color"], masterCompanyInfo),
                          }}
                        >
                          {R.pipe(
                            R.keys,
                            R.sortBy(R.identity),
                            R.map(mediaType => {
                              let { booked, expected, target } = values[mediaType];

                              let warning = false;
                              if (
                                Math.abs((booked - target) / target) > 0.2 &&
                                inWarningRange(data.week)
                              ) {
                                warning = true;
                              }

                              let mediaTypeCapitalized =
                                mediaType.charAt(0).toUpperCase() + mediaType.slice(1);

                              return (
                                <div className="contentBoxRow" key={mediaType}>
                                  <OverlayTrigger
                                    placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                                    overlay={
                                      <Tooltip>
                                        {mediaTypeCapitalized === "Tv"
                                          ? "Linear"
                                          : mediaTypeCapitalized}
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      {mediaTypeCapitalized === "Tv" ? (
                                        <MdTv />
                                      ) : mediaTypeCapitalized === "Streaming" ? (
                                        <StreamingIcon
                                          style={{
                                            fill: computeTextColor(
                                              R.path([data.company, "color"], masterCompanyInfo)
                                            ),
                                          }}
                                        />
                                      ) : mediaTypeCapitalized === "Audio" ? (
                                        <MdHeadset />
                                      ) : mediaTypeCapitalized === "Radio" ? (
                                        <MdRadio />
                                      ) : (
                                        <MdImage />
                                      )}
                                    </div>
                                  </OverlayTrigger>
                                  <div>
                                    <strong>
                                      {normalizeSpend(booked)} / {normalizeSpend(target)}
                                    </strong>
                                    <small>{` (${normalizeSpend(expected)})`}</small>
                                    {warning && <MdError className="calendarWarning" />}
                                  </div>
                                </div>
                              );
                            })
                          )(values)}
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          ) : (
            <Skeleton>
              <TableSkeleton rowHeight={ROW_HEIGHT} headerHeight={HEADER_HEIGHT} />
            </Skeleton>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Calendar;
