import { useMemo, useCallback } from "react";

import * as R from "ramda";

import useLocation from "./useLocation";

export const useTabbedNav = ({ navigate, baseURL, defaultKey }) => {
  const { location } = useLocation();

  const [tab, isRoot] = useMemo(() => {
    const { pathname } = location;
    const match = pathname.match(
      new RegExp(`^\\/[^/]+\\/${baseURL.replace(/\\/g, "\\/")}\\/?(.*)$`)
    );
    const tab = match[1];
    if (!tab || tab === "/") {
      return [defaultKey, true];
    }
    return [tab, false];
  }, [location, baseURL, defaultKey]);

  const goToTab = useCallback(
    newTab => {
      navigate(`${isRoot ? "" : "./"}${newTab === defaultKey ? "" : newTab}`);
    },
    [isRoot, defaultKey, navigate]
  );
  return { tab, goToTab };
};

const findPath = (route, pathParts) => {
  if (!(pathParts.length && R.path(["subRoutes", "length"], route))) {
    return [];
  }

  for (let subRoute of route.subRoutes) {
    if (subRoute.uri === "*" || subRoute.uri === pathParts[0]) {
      let res = findPath(subRoute, pathParts.slice(1 + (subRoute.depth || 0)));
      if (res) {
        let elem = R.omit(["subRoutes"], subRoute);
        if (subRoute.uri === "*") {
          elem.resolvedPath = pathParts[0];
        }
        if (subRoute.label === "*") {
          elem.label = pathParts[0];
        }
        if (subRoute.depth) {
          elem.resolvedPath = pathParts.slice(0, subRoute.depth + 1).join("/");
        }
        return [elem, ...res];
      } else {
        return false;
      }
    }
  }
  return false;
};

// Expected to be used with BreadcrumbTitle.jsx
export const useNestedNav = ({ baseURL, routes }) => {
  const { location } = useLocation();

  return useMemo(() => {
    const { pathname } = location;
    const match = pathname.match(
      new RegExp(`^\\/[^/]+\\/${baseURL.replace(/\\/g, "\\/")}\\/?(.*)$`)
    );
    const pathParts = match[1].split("/");
    return (pathParts.length && findPath({ subRoutes: routes }, pathParts)) || [];
  }, [location, baseURL, routes]);
};

export const RedirectOnDefault = ({ rootNavigate }) => {
  rootNavigate("./");
  return null;
};
