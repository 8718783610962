// Stage Two MM
import { engineeringUsers, productUsers, econometricsUsers } from "./experiment-utils";
// If opted into this experiment, you'll see the "Effective or Raw" at the top of MMM

export const shouldEnablestageTwoMMM = (user: string): boolean => {
  const stageTwoMMMUsers: string[] = [
    ...engineeringUsers,
    ...productUsers,
    ...econometricsUsers,
    "sean.odlum@tinuiti.com",
    // Add users here!
  ];
  return stageTwoMMMUsers.includes(user);
};
