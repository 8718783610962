export const tmrRetailerFilterOptions: {
  value: string;
  label: string;
}[] = [
  { value: "null", label: "All Retailers" },
  { value: "AholdDelhaizebyCitrusAd", label: "AholdDelhaize" },
  { value: "Albertsons", label: "Albertsons" },
  { value: "Amazon", label: "Amazon" },
  { value: "AmazonDSP", label: "AmazonDSP" },
  { value: "Amazon Fresh", label: "Amazon Fresh" },
  { value: "Best Buy", label: "Best Buy" },
  { value: "BJs", label: "BJs" },
  { value: "BJs Display", label: "BJs Display" },
  { value: "Chewy", label: "Chewy" },
  { value: "Costco", label: "Costco" },
  { value: "Costco Business", label: "Costco Business" },
  { value: "Criteo", label: "Criteo" },
  { value: "CVS", label: "CVS" },
  { value: "FreshDirect", label: "FreshDirect" },
  { value: "Giant Eagle", label: "Giant Eagle" },
  { value: "GoPuff", label: "GoPuff" },
  { value: "Harris Teeter", label: "Harris Teeter" },
  { value: "Instacart", label: "Instacart" },
  { value: "Kohl's", label: "Kohl's" },
  { value: "Kroger", label: "Kroger" },
  { value: "Macys", label: "Macys" },
  { value: "Meijer", label: "Meijer" },
  { value: "Nordstrom", label: "Nordstrom" },
  { value: "SamsClub", label: "SamsClub" },
  { value: "Shipt", label: "Shipt" },
  { value: "SamsClub", label: "SamsClub" },
  { value: "Shipt", label: "Shipt" },
  { value: "Target", label: "Target" },
  { value: "TheTradeDesk", label: "TheTradeDesk" },
  { value: "Uber", label: "Uber" },
  { value: "Uber Eats", label: "Uber Eats" },
  { value: "Unassigned", label: "Unassigned" },
  { value: "Walmart", label: "Walmart" },
  { value: "Walmart 1P", label: "Walmart 1P" },
  { value: "Walmart 3P", label: "Walmart 3P" },
  { value: "WalmartDSP", label: "WalmartDSP" },
  { value: "Wayfair", label: "Wayfair" },
  { value: "Whole Foods", label: "Whole Foods" },
];

export const TMR_COLORS = {
  PURPLE: "#6600cc",
  BLUE: "#4178cf",
  LIGHT_GREEN: "#c2d500",
  PINK: "#ff3366",
  GREEN: "#2ad03d",
  ORANGE: "#ff6633",
  YELLOW: "#fbd54f",
};

export const CHART_FILL_COLORS = [
  "#4178cf",
  "#c2d500",
  "#ff3366",
  "#2ad03d",
  "#6600cc",
  "#ff6633",
  "#fbd54f",
  "#0000ff",
  "#ff00ff",
  "#00ffff",
  "#ffff00",
  "#000000",
  "#ffffff",
];

export const tmrSortByOptions: {
  value: string;
  label: string;
}[] = [
  { value: "spend", label: "Spend" },
  { value: "sales", label: "Sales" },
];

export const tmrKpiPull: {
  value: string;
  label: string;
}[] = [
  { value: "acos", label: "ACOS" },
  { value: "roas", label: "ROAS" },
];

export const UNASSIGNED_FREEFORM = {
  GRAY: "#959198",
};

export const PROFILE_FILTER_MESSAGING = "filter profiles separate with commas";
