import "./MMM.scss";
import React from "react";

import FAQ from "./FAQ/FAQ";
import { Page } from "../Components";

const BasicFaq: React.FC = () => {
  return (
    <Page app2Redesign pageType="MMM FAQ" title="MMM FAQ">
      <FAQ />
    </Page>
  );
};

export default BasicFaq;
