import * as R from "ramda";
import * as Dfns from "date-fns/fp";

/**
 * Convert 24hr time format to 12hr format.
 * e.g. 17:59 -> 5:59pm
 * @param {String} daypart
 * @returns {String}
 */
export const convert24hrTo12hr = (daypart: string): string => {
  if (!daypart) {
    return "";
  }
  return R.pipe(Dfns.parse(new Date(), "H:mm"), Dfns.format("h:mma"))(daypart).toLowerCase();
};

/**
 * Convert 12hr time format to 24hr format.
 * e.g. 5:59pm -> 17:59
 * @param {String} daypart
 * @returns {String}
 */
export const convert12hrTo24hr = (daypart: string): string => {
  if (!daypart) {
    return "";
  }
  return R.pipe(Dfns.parse(new Date(), "h:mma"), Dfns.format("HH:mm"))(daypart).toLowerCase();
};
