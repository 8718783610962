import React, { useEffect, useCallback, useMemo, useState } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Page,
  Spinner,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  BPMDateRange,
  BPMButton,
  Card,
  BPMTable,
} from "../Components";
import useLocation from "../utils/hooks/useLocation";
import "./ExpectedBookings.scss";
import {
  ExpectedBooking,
  UpdateExpectedBookingsParams,
  ExpectedBookingsForWeek,
} from "@blisspointmedia/bpm-types/dist/ExpectedBookings";
import { MdFilterList, MdSave, MdArrowRightAlt, MdAdd } from "react-icons/md";
import * as Dfns from "date-fns/fp";
import { Button, Form, Modal } from "react-bootstrap";
import { DateRange } from "../utils/types";
import { getWeeksFromRange } from "../utils/date-utils";
import { downloadJSONToCSV } from "../utils/download-utils";
import Select from "react-select";

interface rawRowData extends ExpectedBooking {
  lastmodified?: string;
}
interface pendingChangesRow extends rawRowData {
  save_type?: string;
  old_booking?: number;
}
interface pendingChangesData {
  insert: pendingChangesRow[];
  update: pendingChangesRow[];
  delete: pendingChangesRow[];
}

interface selectHeader {
  label: string;
  value: string;
}

const ExpectedBookings: React.FC = () => {
  const { company } = useLocation();
  const setError = useSetError();
  const [rawData, setRawData] = useState<ExpectedBooking[]>([]);
  const [mediaTypes, setMediaTypes] = useState<string[]>([]);
  const [deletedRows, setDeletedRows] = useState<ExpectedBookingsForWeek[]>([]);
  const [tableData, setTableData] = useState<ExpectedBookingsForWeek[]>();
  const [originalTableData, setOriginalTableData] = useState<ExpectedBookingsForWeek[]>();
  const [saving, setSaving] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [dates, setDates] = useState<DateRange | undefined>();
  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [showMediaTypeModal, setShowMediaTypeModal] = useState(false);
  const [bulkEditMediaType, setBulkEditMediaType] = useState<selectHeader>();
  const [bulkEditBooking, setBulkEditBooking] = useState<string | null>(null);
  const [pendingChanges, setPendingChanges] = useState<pendingChangesData>();
  const [invalidText, setInvalidText] = useState<string>();
  const [newMediaType, setNewMediaType] = useState<selectHeader>();
  const [filteredTableData, setFilteredTableData] = useState<ExpectedBookingsForWeek[]>();

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let res = await ToolsLambdaFetch("/get_expected_bookings", {
            params: {
              company,
            },
          });
          let bookingsData = await awaitJSON<ExpectedBooking[]>(res);
          setRawData(bookingsData);
          const rawMediaTypes: string[] = Array.from(
            new Set(bookingsData.map((obj: ExpectedBooking) => obj.media))
          );
          setMediaTypes(rawMediaTypes.sort());
          const formattedBookingsData = formattedData(bookingsData);
          setTableData(formattedBookingsData);
          setOriginalTableData(formattedBookingsData);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get expected bookings assignments data ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [setError, company, tableData]);

  const formattedData = (rawData: ExpectedBooking[] | rawRowData[]) => {
    if (rawData) {
      const weeks: string[] = Array.from(new Set(rawData.map((obj: ExpectedBooking) => obj.week)));
      let formatted: ExpectedBookingsForWeek[] = [];
      weeks.forEach(week => formatted.push({ week: week }));
      rawData.forEach(rawObj =>
        formatted.forEach(obj => {
          if (rawObj.week === obj.week) {
            obj[rawObj.media] = rawObj.booking;
            obj[`${rawObj.media}_id`] = rawObj.id;
          }
        })
      );
      return formatted;
    }
  };

  const revertedData = useCallback(
    (data: ExpectedBookingsForWeek) => {
      if (data) {
        const modified = {};
        mediaTypes.forEach(mediaType => {
          if (!data[`${mediaType}_id`]) {
            modified[mediaType] = data.lastmodified;
          } else {
            rawData.forEach(row => {
              if (data[`${mediaType}_id`] === row.id && data[mediaType] !== row.booking) {
                modified[mediaType] = data.lastmodified;
              }
            });
          }
        });
        const revertedData: rawRowData[] = [];
        mediaTypes.forEach(
          mediaType =>
            !(R.isNil(data[`${mediaType}_id`]) && R.isNil(data[mediaType])) &&
            revertedData.push({
              id: data[`${mediaType}_id`],
              week: data.week,
              company: company,
              media: mediaType,
              booking: (data[mediaType] as number) || 0,
              lastmodified: modified[mediaType],
            })
        );
        return revertedData;
      }
      return [];
    },
    [company, mediaTypes, rawData]
  );

  const formattedLabel = (mediaType: string) => {
    let labelFormatting: string[] = mediaType.split("_");
    labelFormatting = labelFormatting.map(word =>
      word.length > 2 ? word[0].toUpperCase() + word.substring(1) : word.toUpperCase()
    );
    return labelFormatting.join(" ");
  };

  useEffect(() => {
    let revertedTableData: ExpectedBooking[] = [];
    tableData &&
      tableData.forEach(obj => {
        revertedTableData = revertedTableData.concat(revertedData(obj));
      });

    const rowSetValuesChanged = ({
      week,
      lastmodified,
      company,
      media,
      booking,
    }: pendingChangesRow) =>
      !R.isNil(lastmodified) &&
      !R.isNil(week) &&
      !R.isNil(company) &&
      !R.isNil(media) &&
      !R.isNil(booking);

    const idSet = ({ id }: pendingChangesRow) => !R.isNil(id);
    if (tableData) {
      // Only want to save rows that have been modified in the current session (and therefore have a 'lastmodified' value)
      // and have all required values
      let modifiedRows = R.filter(rowSetValuesChanged, revertedTableData);
      // Rows that have an id value were created before the current session
      let updateRows = R.filter(idSet, modifiedRows);
      let insertRows = R.without(updateRows, modifiedRows);
      // Only want to delete rows that existed before the current session (because only those 'deletedRows' are in the database)
      let revertedDeletedRows: ExpectedBooking[] = [];
      deletedRows.forEach(obj => {
        revertedDeletedRows = revertedDeletedRows.concat(revertedData(obj));
      });
      let validDeleteRows = R.filter(idSet, revertedDeletedRows);
      insertRows.forEach(obj => {
        obj.save_type = "insert";
      });
      updateRows.forEach(obj =>
        rawData.forEach(rawObj => {
          if (obj.id === rawObj.id) {
            obj.old_booking = rawObj.booking;
            obj.save_type = "update";
          }
        })
      );
      validDeleteRows.forEach(obj =>
        rawData.forEach(rawObj => {
          if (obj.id === rawObj.id) {
            obj.save_type = "delete";
          }
        })
      );
      if (modifiedRows.length > 0 || validDeleteRows.length > 0) {
        setPendingChanges({
          insert: insertRows,
          update: updateRows,
          delete: validDeleteRows,
        });
      } else {
        setPendingChanges(undefined);
      }
    }
  }, [deletedRows, tableData, revertedData, rawData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await ToolsLambdaFetch<UpdateExpectedBookingsParams>("/update_expected_bookings", {
        method: "POST",
        body: pendingChanges,
      });
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      let userMessage = `Failed to set expected bookings data ${reportError.message}`;
      if (
        reportError.message ===
        'duplicate key value violates unique constraint "expected_bookings_week_company_media_key"'
      ) {
        userMessage =
          "At least some of the bookings you entered are already in the database (week, company, mediatype)";
      }
      setError({
        message: userMessage,
        reportError,
      });
    }
  }, [pendingChanges, setError]);

  const addNewMediaType = useCallback(() => {
    try {
      if (newMediaType) {
        setMediaTypes(mediaTypes.concat([newMediaType.value]).sort());
        setNewMediaType(undefined);
      }
    } catch (e) {
      const reportError = e as Error;
      let userMessage = `Failed to add new media type for ${company} ${reportError.message}`;
      setError({
        message: userMessage,
        reportError,
      });
    }
  }, [company, mediaTypes, newMediaType, setError]);

  const selectorOptions = useMemo(() => ({}), []);

  const bookingsHeaders = [
    {
      label: "Week",
      field: "week",
      type: "week",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
      uneditable: false,
    },
  ];
  mediaTypes.forEach(mediaType => {
    bookingsHeaders.push({
      label: formattedLabel(mediaType),
      field: mediaType,
      type: "currency",
      flex: 1,
      modalRow: Math.floor(bookingsHeaders.length % 2) + 1,
      modalFlex: 1,
      uneditable: false,
    });
  });
  bookingsHeaders.push({
    label: "Weekly Totals",
    field: "total",
    type: "currency",
    flex: 1,
    modalRow: 0,
    modalFlex: 0,
    uneditable: true,
  });

  const pendingChangesHeaders = useMemo(() => {
    const deletedWeeks: string[] = [];
    pendingChanges?.delete.forEach(obj => deletedWeeks.push(obj.week));
    const pendingChangesHeaders: {}[] = [];
    pendingChangesHeaders.push(
      {
        label: "Week",
        name: "week",
        flex: 1,
      },
      { label: "Media", name: "media", flex: 1 }
    );
    pendingChangesHeaders.push({
      label: "Amount",
      name: "booking",
      flex: 1,
      renderer: row => {
        if (row.save_type === "delete") {
          return (
            <div className="deleted">{`${row.booking.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</div>
          );
        } else if (row.save_type === "update") {
          return (
            <div className="updatedBookings">
              <div className="deleted">{`${row.old_booking.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</div>
              <div>{<MdArrowRightAlt />}</div>
              <div className="updated">{`${row.booking.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</div>
            </div>
          );
        } else {
          return (
            <div className="updated">{`${row.booking.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</div>
          );
        }
      },
    });
    return pendingChangesHeaders;
  }, [pendingChanges?.delete]);

  const mediaTypeOptions = useMemo(() => {
    const possibleMediaTypes = [
      "tv",
      "audio",
      "streaming",
      "audio_secured",
      "tv_secured",
      "streaming_secured",
      "radio",
      "display",
    ];
    const inactiveMediaType = string => !mediaTypes.includes(string);
    const filteredMediaTypes = R.filter(inactiveMediaType, possibleMediaTypes);
    return R.map(e => ({ label: formattedLabel(e), value: e }), filteredMediaTypes);
  }, [mediaTypes]);

  const addBulkDataToTable = useCallback(() => {
    if (dates && bulkEditMediaType && tableData) {
      let updatedTableData = R.clone(tableData);
      let weeks = getWeeksFromRange(dates);
      updatedTableData.forEach(obj => {
        const isNewWeek = week => week !== obj.week;
        if (weeks.includes(obj.week)) {
          obj[`${bulkEditMediaType.value}`] = parseInt(bulkEditBooking || "0");
          obj.lastmodified = Dfns.formatISO(new Date());
          weeks = R.filter(isNewWeek, weeks);
        }
      });
      weeks.forEach(week => {
        const obj: ExpectedBookingsForWeek = { week };
        obj[`${bulkEditMediaType.value}`] = parseInt(bulkEditBooking || "0");
        obj.lastmodified = Dfns.formatISO(new Date());
        updatedTableData.push(obj);
      });
      updatedTableData.sort((a, b) => (a.week > b.week ? -1 : 1));
      setShowBulkEditModal(false);
      setTableData(updatedTableData);
    }
  }, [dates, bulkEditBooking, bulkEditMediaType, tableData, setTableData]);

  const bulkEditMediaTypeOptions: { label?: string; value: string }[] = [];
  mediaTypes.forEach(str =>
    bulkEditMediaTypeOptions.push({ label: formattedLabel(str), value: str })
  );

  const totals = useMemo(() => {
    if (mediaTypes && tableData && filteredTableData) {
      tableData.forEach(row => {
        row.total = 0;
        mediaTypes.forEach(
          mediaType =>
            row[mediaType] &&
            (row.total = row.total > 0 ? row.total + row[mediaType] : row[mediaType])
        );
      });
      const finalRowDataFormatted = { week: "Totals", total: "" };
      const finalRowDataRaw = { week: "Totals", total: 0 };
      mediaTypes.forEach(
        mediaType =>
          (finalRowDataFormatted[mediaType] = R.sum(
            R.filter(value => !R.isNil(value), R.pluck(mediaType, filteredTableData))
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })) &&
          (finalRowDataRaw[mediaType] = R.sum(
            R.filter(value => !R.isNil(value), R.pluck(mediaType, filteredTableData))
          ))
      );
      finalRowDataFormatted.total = R.sum(
        R.filter(value => !R.isNil(value), R.pluck("total", filteredTableData))
      ).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      finalRowDataRaw.total = R.sum(
        R.filter(value => !R.isNil(value), R.pluck("total", filteredTableData))
      );
      return { formatted: finalRowDataFormatted, raw: finalRowDataRaw };
    }
  }, [mediaTypes, tableData, filteredTableData]);

  const csvData = useMemo(() => {
    if (tableData) {
      let csvData: ExpectedBookingsForWeek[] = [];
      tableData.forEach(obj => {
        let newWeek: ExpectedBookingsForWeek = { week: obj.week };
        mediaTypes.forEach(mediaType => {
          if (!obj[mediaType]) {
            newWeek[mediaType] = undefined;
          } else {
            newWeek[mediaType] = obj[mediaType];
          }
        });
        newWeek.total = obj.total;
        csvData.push(newWeek);
      });
      totals && csvData.push(totals.raw);
      return csvData;
    }
  }, [tableData, mediaTypes, totals]);

  const checkIsValid = (data: ExpectedBookingsForWeek) => {
    const { week } = data;
    if (!week) {
      setInvalidText("Must specify week");
      return false;
    }
    for (const row of tableData || []) {
      if (row.week === data.week && row.index !== data.index) {
        setInvalidText(`Row for ${data.week} already exists.`);
        return false;
      }
    }
    return true;
  };

  return (
    <Page
      title="Expected Bookings"
      pageType="Expected Bookings"
      minHeight="600px"
      actions={
        <div className="expectedBookingsActions">
          <BPMButton
            size="sm"
            variant="primary"
            className="addNewMediaTypeButton"
            onClick={() => setShowMediaTypeModal(true)}
          >
            {<MdAdd />} New Media Type
          </BPMButton>
          <BPMButton
            size="sm"
            variant="primary"
            className="addNewMediaTypeButton"
            onClick={() => downloadJSONToCSV(csvData || [], `${company} Expected Bookings`)}
          >
            Export
          </BPMButton>
          <BPMButton
            size="sm"
            variant="primary"
            onClick={() => setShowBulkEditModal(!showBulkEditModal)}
            className="bulkEditButton"
          >
            {"Bulk Edit"}
          </BPMButton>
          {pendingChanges && (
            <div className="buttonsContainer">
              <BPMButton
                className="showPendingChangesButton"
                size="sm"
                variant={showPendingChanges ? "primary" : "outline-primary"}
                icon={<MdFilterList />}
                onClick={() => {
                  setShowPendingChanges(!showPendingChanges);
                }}
              >
                Pending Changes
              </BPMButton>
              <BPMButton
                size="sm"
                variant="danger"
                className="reloadButton"
                onClick={() => {
                  setTableData(originalTableData);
                  setDeletedRows([]);
                }}
              >
                Clear All Changes
              </BPMButton>
              <BPMButton
                size="sm"
                variant="success"
                className="saveButton"
                onClick={() => save()}
                disabled={
                  R.isNil(pendingChanges) ||
                  (!pendingChanges.insert.length &&
                    !pendingChanges.update.length &&
                    !pendingChanges.delete.length) ||
                  saving
                }
              >
                {saving ? <Spinner /> : <MdSave />}
              </BPMButton>
            </div>
          )}
        </div>
      }
    >
      <div className="expectedBookingsPageContainer">
        {tableData ? (
          <ModalEditTable
            className="expectedBookingsTable"
            name=""
            headers={bookingsHeaders}
            tableData={tableData}
            setTableData={setTableData}
            selectorOptions={selectorOptions}
            filterBar
            showModalOnAdd={true}
            checkIsValid={checkIsValid}
            invalidText={invalidText}
            deletedRows={deletedRows}
            /// @ts-ignore - Can delete when ModalEditTable is TypeScripted
            setDeletedRows={setDeletedRows}
            totals={totals?.formatted}
            onFilteredDataChange={setFilteredTableData}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && pendingChanges && (
          <div className="pendingChangesContainer">
            <Card className="leftSideContainer">
              <BPMTable
                alternateColors={false}
                headers={pendingChangesHeaders}
                data={pendingChanges?.insert
                  .concat(pendingChanges.update)
                  .concat(pendingChanges.delete)}
                headerHeight={25}
                rowHeight={35}
                filterBar={false}
              />
            </Card>
          </div>
        )}
      </div>
      <Modal
        size="lg"
        onClose={() => setShowMediaTypeModal(false)}
        show={showMediaTypeModal}
        onHide={() => setShowMediaTypeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Media Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            classNamePrefix="mediaTypeOptions"
            className="mediaTypeOptions"
            placeholder="Select Media Type..."
            menuPlacement="bottom"
            isClearable={false}
            value={newMediaType}
            options={mediaTypeOptions}
            onChange={value => setNewMediaType(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <BPMButton
            variant="primary"
            onClick={() => {
              addNewMediaType();
              setShowMediaTypeModal(false);
            }}
          >
            Save
          </BPMButton>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        onClose={() => setShowBulkEditModal(false)}
        show={showBulkEditModal}
        onHide={() => setShowBulkEditModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BPMDateRange
            bordered
            range={dates}
            onChange={dates => setDates(dates)}
            isDayBlocked={date => !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)}
            fullWeeksOnly
          />
          <Select
            classNamePrefix="mediaTypeOptions"
            className="mediaTypeOptions"
            placeholder="Select Media Type..."
            menuPlacement="bottom"
            isClearable={false}
            value={bulkEditMediaType}
            options={bulkEditMediaTypeOptions}
            onChange={value => setBulkEditMediaType(value)}
          />
          <Form.Control
            placeholder="Booking Amount"
            value={
              bulkEditBooking
                ? parseFloat(bulkEditBooking).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : ""
            }
            onChange={event => {
              let newVal: number = parseFloat(event.target.value.replace(/[^0-9]/g, ""));
              setBulkEditBooking(isNaN(newVal) ? null : newVal.toString());
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addBulkDataToTable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default ExpectedBookings;
