import React from "react";

import { Tooltip } from "react-bootstrap";

import { Setter } from "../../utils/types";
import { CheckBox, OverlayTrigger } from "../../Components";

interface ConfigToggleProps {
  toggledProperty: boolean;
  setToggledProperty: Setter<boolean>;
  toggleText: string;
  tooltipText: string;
  disabled?: boolean;
}

const ConfigToggle: React.FC<ConfigToggleProps> = ({
  toggledProperty,
  setToggledProperty,
  toggleText,
  tooltipText,
  disabled,
}) => {
  return (
    <OverlayTrigger
      placement="bottom center"
      overlay={<Tooltip id="configToggleTooltip">{tooltipText}</Tooltip>}
    >
      <div className="checkboxBox">
        <CheckBox
          checked={toggledProperty}
          disabled={disabled}
          size="sm"
          onCheck={setToggledProperty}
        />
        <div>{toggleText}</div>
      </div>
    </OverlayTrigger>
  );
};

export default ConfigToggle;
