// Basically a ripoff of https://github.com/mbrevda/react-image/blob/master/src/index.js. They aren't updating to remove
// deprecated React functions, so I'm taking matters into my own hands.
import React, { useRef, useEffect, useState } from "react";

import { Spinner } from "./";

interface ImgProps {
  src: string;
  unloader?: React.ReactNode;
  spinnerSize?: number;
  className?: string;
  alt?: string;
  [passthrough: string]: any;
}

export const Img = ({
  src,
  unloader = <span />,
  spinnerSize = 20,
  className = "",
  alt = "",
  ...props
}: ImgProps): JSX.Element => {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (!imgRef.current) {
      imgRef.current = new Image();
    }
  }, [imgRef]);
  useEffect(() => {
    let stale = false;
    if (imgRef.current) {
      imgRef.current.src = src;
      imgRef.current.onload = () => {
        if (!stale) {
          setLoadingState("loaded");
        }
      };
      imgRef.current.onerror = () => {
        if (!stale) {
          setLoadingState("error");
        }
      };
    }
    return () => {
      stale = true;
    };
  }, [imgRef, src, setLoadingState]);

  switch (loadingState) {
    case "loaded":
      return <img {...props} alt={alt} src={src} className={className} />;
    case "loading":
      return <Spinner size={spinnerSize} />;
    case "error":
      return <>{unloader}</>;
    default:
      return <span />;
  }
};

export default Img;
