import { GROSS_LABEL } from "../LinearPacing/LinearPacingUtils";
import { Img, NumberFormatter } from "../Components";
import { BottomDataRow } from "./LinearPacingDetail2";

export const calculateAdjustedSpend = (
  spend: number,
  commission: number,
  spendDimension: string
): number => {
  return spendDimension === GROSS_LABEL ? spend : spend * (0.85 + (commission || 0.08));
};

export const makeNetworkLabel = ({ network }: { network: string }): JSX.Element => {
  return (
    <div className="pacingBarLabel pacingNetworkBarLabel">
      <Img
        className="logo network"
        src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
        title={network}
        unloader={<span className="logo network">{network}</span>}
      />
    </div>
  );
};

export const makeCompanyLabel = ({
  company,
  campaign,
}: {
  company: string;
  campaign: string | null;
}): JSX.Element => {
  return (
    <div className="pacingBarLabel pacingCompanyBarLabelLinear">
      <div className="logo company">
        <Img
          src={`https://cdn.blisspointmedia.com/companies/${company}/logo.png`}
          title={company}
          unloader={<span className="noImg">{company}</span>}
        />
      </div>
      {campaign && <span className="subLabel">{campaign}</span>}
    </div>
  );
};

export const createLogsHeaderRow = (dow: string, index: number): Record<string, any> => ({
  label: dow,
  name: "spots[0]",
  renderer: (row: BottomDataRow) => (
    <div className={`${row.spend_override ? "pacingSpendOverride" : ""}`}>
      <NumberFormatter value={row.spots[index].cost} type={"$"} decimals={0} />
    </div>
  ),
  width: 70,
});

export const BOTTOM_SUPER_HEADERS = [
  { span: 7, data: "Summary" },
  { span: 3, data: "Ordered" },
  { span: 9, data: "Logs" },
];

export const CSV_HEADER = [
  "Date",
  "Company",
  "Network",
  "Avail",
  "Booked",
  "Logs",
  "Estimated",
  "Has BVS",
  "Has Prelogs",
  "Last Prelog Day",
];
