import React, { useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Modal, Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

import { getNextWeek } from "../linearBuyingUtils";
import { PlanRow } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { DATE_FORMAT } from "../linearBuyingConstants";
import { BPMButton, BPMDateRange, OverlayTrigger, Spinner } from "../../Components";
import { DateRange } from "../../utils/types";

import "./ExtendSelectedModal.scss";

interface ExtendSelectedModalProps {
  onClose: () => void;
  rowsToExtend: Record<string, PlanRow>;
  week: string;
  extendFunction: (rowsToExtend: Record<string, PlanRow>, week: string) => Promise<void>;
}

const ExtendSelectedModal: React.FC<ExtendSelectedModalProps> = ({
  onClose,
  rowsToExtend,
  week,
  extendFunction,
}) => {
  const nextWeek = getNextWeek(week);
  const DEFAULT_DATE_RANGE = { start: nextWeek, end: nextWeek };
  const [dates, setDates] = useState<DateRange>(DEFAULT_DATE_RANGE);
  const [extending, setExtending] = useState(false);

  const addSelected = async () => {
    setExtending(true);
    // Create array of weeks to loop over.
    let weeks: string[] = [];
    let currentWeek = dates.start;
    while (currentWeek <= dates.end) {
      weeks.push(currentWeek);
      currentWeek = R.pipe(Dfns.parseISO, Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(currentWeek);
    }

    // For each week, call extendFunction
    await Promise.all(
      weeks.map(week =>
        (async () => {
          await extendFunction(rowsToExtend, week);
        })()
      )
    );
    setExtending(false);
    onClose();
  };

  return (
    <Modal show onHide={onClose} className="extendSelectedModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">
          <div className="title">Extend Selected Rows</div>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
            overlay={
              <Tooltip id="tooltip">
                <p>
                  Copy and extend the selected rows into a new date range. The weeks in the date
                  picker are inclusive.
                </p>
              </Tooltip>
            }
          >
            <MdInfoOutline />
          </OverlayTrigger>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BPMDateRange
          bordered
          range={dates}
          onChange={dates => setDates(dates)}
          isDayBlocked={date => date <= week || !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)}
          fullWeeksOnly
        />
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="primary" onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton variant="primary" onClick={() => addSelected()}>
          {extending ? <Spinner /> : "Add Selected"}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtendSelectedModal;
