import "./StackedTextToggle.scss";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { ButtonFramework, ButtonFrameworkVariant } from "../ButtonFramework";
import { typedReactMemo } from "../../utils/types";

interface Option<T> {
  key: T;
  label?: string;
}

interface Props<T> {
  options: readonly (Option<T> | T)[];
  selectedOption: T;
  onChange: (key: T) => void;
  className?: string;
}

export const StackedTextToggle = typedReactMemo(
  <T extends string>({
    className = "",
    options,
    selectedOption,
    onChange,
  }: Props<T>): JSX.Element => {
    const [animateOnClick, setAnimateOnClick] = useState(false);

    useEffect(() => {
      const parentContainers = document.getElementsByClassName("chartContainer");

      const handleParentMouseLeave = () => {
        setAnimateOnClick(false); // Reset animateOnClick when chartContainer is hovered out
      };

      for (let i = 0; i < parentContainers.length; i++) {
        parentContainers[i]?.addEventListener("mouseleave", handleParentMouseLeave);
      }

      return () => {
        for (let i = 0; i < parentContainers.length; i++) {
          const parentContainer = parentContainers[i];
          parentContainer?.removeEventListener("mouseleave", handleParentMouseLeave);
        }
      };
    }, []); // Run this effect only once on component mount

    const buttonGroupClassName = useMemo(() => {
      let classes = ["stacked-toggle-button"];

      if (className) {
        classes.push(className);
      }

      return classes.join(" ");
    }, [className]);

    return (
      <ButtonGroup toggle className={buttonGroupClassName}>
        {options.map(option => {
          let key: T;
          let label: string | undefined;
          if (typeof option === "string") {
            key = option;
            label = option;
          } else {
            ({ key } = option);
            label = option.label || option.key;
          }
          let active = selectedOption === key;

          return (
            <ButtonFramework
              className={`stacked-toggle-item ${active ? "selected" : "unselected"}${
                animateOnClick ? " animated" : ""
              }`}
              key={key}
              variant={ButtonFrameworkVariant.NO_ICON}
              size="sm"
              onClick={() => {
                if (!active) {
                  onChange(key);
                  setAnimateOnClick(true); // Set animateOnClick to true when button is clicked
                }
              }}
              onAnimationEnd={() => {
                setAnimateOnClick(false); // Reset animateOnClick when animation ends
              }}
            >
              {label}
            </ButtonFramework>
          );
        })}
      </ButtonGroup>
    );
  }
);
