import "./InvestToIncrease.scss";
import Papa from "papaparse";
import { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import MoreInfo from "../../MMM/MoreInfo";
import { SnapshotChart, IconToggleButton, DownloadDropdown } from "../../Components";
import MetricsTable from "../../SingleChannel/MetricsTable/MetricsTable";
import { Column, DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as R from "ramda";
import { roasDataFormatter } from "../../MMM/ModelResults/DataFormatters";
import { standardizeMetricName, snapShotDataSort } from "./BrandImpactUtils";
import { Neutral400 } from "../../utils/colors";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { downloadPNG } from "../../utils/download-utils";

interface InvestToIncreaseProps {
  company: string;
  groupByMetric: string;
  spendAndEffectData: any[];
  nextBestData: any[];
}

const ROW_HEIGHT = 50;
const TABLE_HEIGHT = 320;

const SPEND_AND_EFFECT_SORT_OPTIONS = [
  { value: "Alphabetical" },
  { value: "Spend to effect ratio" },
  { value: "Spend: High to low" },
  { value: "Spend: Low to high" },
  { value: "Effect: High to low" },
  { value: "Effect: Low to high" },
] as const;

const dataColumns: Column[] = [
  { label: "Ad Awareness", dataVarName: "adAwareness", id: "column-1" },
  { label: "Awareness", dataVarName: "awareness", id: "column-2" },
  { label: "Consideration", dataVarName: "consideration", id: "column-3" },
  { label: "Buzz", dataVarName: "buzz", id: "column-4" },
];

const DimensionColumns: DimensionColumn[] = [
  {
    id: "name",
    dimensionVarName: "Channel",
    label:
      "Paid Display, Paid Search Nb, Paid Shopping Nb, Meta, Tik Tok, Streaming Video, Linear Television, Affiliate",
    dimensionTypeName: "Channel",
    divider: false,
  },
];

export const GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC = [
  { label: "Ad Awareness", value: "ad_awareness" },
  { label: "Awareness", value: "awareness" },
  { label: "Buzz", value: "buzz" },
  { label: "Consideration", value: "consideration" },
];

const fixData = (data: any[]) => {
  const adAwarenessData: any[] = [];
  const awarenessData: any[] = [];
  const buzzData: any[] = [];
  const considerationData: any[] = [];

  data.forEach(item => {
    const formattedValue = {
      name: item.name,
      value: (parseFloat(item.value) * 10000000).toFixed(2),
      label: (parseFloat(item.value) * 10000000).toFixed(2),
    };

    switch (item.label.brandHealthMetricValue) {
      case "ad_awareness":
        adAwarenessData.push(formattedValue);
        break;
      case "awareness":
        awarenessData.push(formattedValue);
        break;
      case "buzz":
        buzzData.push(formattedValue);
        break;
      case "consideration":
        considerationData.push(formattedValue);
        break;
      default:
        break;
    }
  });
  const platforms = [
    "Paid Display",
    "Paid Search - Non-brand",
    "Paid Shopping - Non-brand",
    "Meta",
    "TikTok",
    "Streaming Video",
    "Linear TV",
    "Affiliate",
  ];

  const tableData = platforms.map(platform => {
    const row = [
      adAwarenessData.find(d => d.name === platform) || { value: 0, label: "--" },
      awarenessData.find(d => d.name === platform) || { value: 0, label: "--" },
      buzzData.find(d => d.name === platform) || { value: 0, label: "--" },
      considerationData.find(d => d.name === platform) || { value: 0, label: "--" },
    ];
    return row;
  });
  return tableData;
};

const InvestToIncrease: React.FC<InvestToIncreaseProps> = ({
  company,
  groupByMetric,
  nextBestData,
  spendAndEffectData,
}) => {
  const [spendAndEffectDataSorted, setSpendAndEffectDataSorted] = useState<any[]>([]);
  const [nextBestDataSorted, setNextBestDataSorted] = useState<any[]>([]);
  const [snapChartSortValueSpendEffect, setSnapChartSortValueSpendEffect] = useState(
    "Spend: High to low"
  );
  const [snapChartSortValueNextBest, setSnapChartSortValueNextBest] = useState("Highest to lowest");
  const [graphView, setGraphView] = useState(true);

  const [unformattedTableData, setUnformattedTableData] = useState<any[]>([]);
  const [metricsTableData, setMetricsTableData] = useState<any[]>([]);
  const [metricDimensionsData, setMetricDimensionsData] = useState<any[]>([]);
  const [tableWidth, setTableWidth] = useState(500);
  const containerRef = useRef(null);
  const [rerenderTable, setRerenderTable] = useState(false);

  useEffect(() => {
    if (!R.isEmpty(nextBestData)) {
      const sortedNextBestData = snapShotDataSort(nextBestData, snapChartSortValueNextBest);
      setNextBestDataSorted(sortedNextBestData);
    }
  }, [nextBestData, snapChartSortValueNextBest]);

  useEffect(() => {
    if (!R.isEmpty(spendAndEffectData)) {
      const sortedSpendAndEffect = snapShotDataSort(
        spendAndEffectData,
        snapChartSortValueSpendEffect
      );
      setSpendAndEffectDataSorted(sortedSpendAndEffect);
    }
  }, [spendAndEffectData, snapChartSortValueSpendEffect]);

  useLayoutEffect(() => {
    const newTableWidth = containerRef.current
      ? (containerRef.current as any).offsetWidth
      : undefined;
    if (newTableWidth) {
      setTableWidth((containerRef.current as any).offsetWidth - 5);
    }
    if (rerenderTable) {
      setRerenderTable(false);
    }
  }, [rerenderTable]);

  useEffect(() => {
    const groupData = async () => {
      try {
        let allData: any[] = [];
        for (let brandHealthMetric of GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC) {
          let brandHealthMetricValue = brandHealthMetric.value;
          let tableDataResponse = await S3SignedUrlFetch(
            `bpm-miguel-test/bh_robyn_b1_main_demo/out/${brandHealthMetricValue}_p/next_best_dollar.csv`
          );
          const tableDataText = await tableDataResponse.text();
          const { data: tableDataParsed }: Papa.ParseResult<any> = Papa.parse(tableDataText, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          });
          const cleanTableData: any[] = tableDataParsed.map((item: any) => ({
            label: { brandHealthMetricValue },
            name: item.dX
              .split("_")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
              .replace("Paid Search Nb", "Paid Search - Non-brand")
              .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
              .replace("Tik Tok", "TikTok")
              .replace("Linear Television", "Linear TV"),
            value: item.dY,
          }));
          allData = allData.concat(cleanTableData);
        }
        setUnformattedTableData(allData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };
    groupData();
  }, [groupByMetric]);

  useEffect(() => {
    const tableData = fixData(unformattedTableData);
    setMetricsTableData(tableData);
  }, [unformattedTableData]);

  useEffect(() => {
    const formattedData = roasDataFormatter({
      data: unformattedTableData,
      tableHeaders: DimensionColumns,
      disabledPlatform: false,
    });
    setMetricDimensionsData(formattedData);
  }, [unformattedTableData]);

  const pngDownload = useCallback(async () => {
    await downloadPNG("metricsTable", "next_best_$1000");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header={`Which channels should we invest in to increase ${standardizeMetricName(
        groupByMetric
      )}?`}
      subHeader={
        <>
          {
            "Below is the marginal impact of each media dollar, given the existing allocation of resources."
          }
          <MoreInfo rightLabel="More info" size="sm">
            {
              "The marginal impact of a media dollar is based on where one is on the channel saturation curve (see section below). Next-dollar effectiveness is the slope of the line tangent to the saturation curve at the current level of investment. Intuitively, you want to invest where the slope of the curve is steepest."
            }
          </MoreInfo>
        </>
      }
    >
      <div className="investMain">
        <div className="investLeft">
          <SnapshotChart
            data={spendAndEffectDataSorted}
            dropdownOptions={SPEND_AND_EFFECT_SORT_OPTIONS}
            dropdownValue={snapChartSortValueSpendEffect}
            setDropdownValue={setSnapChartSortValueSpendEffect}
            doubleBarChart={true}
            header={undefined}
            title={"Spend and Effect"}
            valueFormatter={value => (value === null ? "--" : `${(value * 100).toFixed(0)}%`)}
          />
        </div>
        <div className="investRight">
          {graphView && (
            <SnapshotChart
              title={"Next Best $1,000"}
              data={nextBestDataSorted}
              tooltipItems={{
                tooltipText: "One basis point is equal to 1/100th of 1 percent",
                beforeTooltipText: "Basis Points",
                color: Neutral400,
              }}
              dropdownOptions={[
                { value: "Alphabetical" },
                { value: "Highest to lowest" },
                { value: "Lowest to highest" },
              ]}
              dropdownValue={snapChartSortValueNextBest}
              setDropdownValue={setSnapChartSortValueNextBest}
              doubleBarChart={false}
              header={
                "Marginal returns reflect the steepness of the saturation curve at current investment levels."
              }
              additionalRightActions={
                <div>
                  <IconToggleButton
                    options={[
                      { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                      { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                    ]}
                    size="sm"
                    selectedOption={graphView ? "showGraph" : "showTable"}
                    onChange={() => setGraphView(prev => !prev)}
                  />
                </div>
              }
              valueFormatter={value => (value === null ? "--" : `${value.toFixed(2)}`)}
            />
          )}
          {!graphView && !R.isEmpty(metricsTableData) && !R.isEmpty(metricDimensionsData) && (
            <ChartContainer
              enableHoverDesign
              height={405}
              title="Next Best $1000"
              rightActions={
                <div className="investToIncreaseRightActions">
                  <IconToggleButton
                    options={[
                      { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                      { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                    ]}
                    size="sm"
                    selectedOption={graphView ? "showGraph" : "showTable"}
                    onChange={() => setGraphView(prev => !prev)}
                  />
                  <DownloadDropdown size="sm" onClickOptions={[pngDownload]} />
                </div>
              }
            >
              <MetricsTable
                aggregateData={[]}
                dataColumns={dataColumns}
                dimensionColumns={DimensionColumns}
                dimensionData={metricDimensionsData}
                tableData={metricsTableData}
                width={tableWidth}
                overrideParams={{
                  maxColWidth: 130,
                  rowHeight: ROW_HEIGHT,
                }}
                height={TABLE_HEIGHT}
              />
            </ChartContainer>
          )}
        </div>
      </div>
    </WidgetContainer>
  );
};

export default InvestToIncrease;
