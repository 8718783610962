import React, { useMemo, useState } from "react";
import "./2x2Table.scss";
import { ListGroup } from "react-bootstrap";
import { SimpleTooltip, SimpleTooltipArgs } from "../SimpleTooltip";

interface DataListProps {
  orientation: "Horizontal" | "Vertical";
  data: {
    date: string;
    row1DataEntry: string;
    row2DataEntry: string;
    row3DataEntry: string;
    row4DataEntry: string;
  }[];
}

const DataList: React.FC<DataListProps> = React.memo(
  ({ orientation, data }: DataListProps): JSX.Element => {
    const DataList: JSX.Element[] = useMemo(() => {
      const lastIndex = data.length - 1;
      return data.map((entry, index) => {
        const { date, row1DataEntry, row2DataEntry, row3DataEntry, row4DataEntry } = entry;
        return (
          <ListGroup.Item className={`tbtt${orientation}Item`} key={date}>
            <div className={`tbtt${orientation}DateCell`}>{date}</div>
            <div className={`tbtt${orientation}DataCells ${index === lastIndex ? "last" : ""}`}>
              <div
                className={`tbtt${orientation}Cell1 ${
                  orientation === "Horizontal"
                    ? `${index === lastIndex ? "top last" : "top"}`
                    : "left"
                }`}
              >
                {row1DataEntry}
              </div>
              <div
                className={`tbtt${orientation}Cell2 ${
                  orientation === "Horizontal"
                    ? `${index === lastIndex ? "top last" : "top"}`
                    : "left"
                }`}
              >
                {row2DataEntry}
              </div>
              <div
                className={`tbtt${orientation}Cell1 ${
                  orientation === "Horizontal"
                    ? `${index === lastIndex ? "bottom last" : "bottom"}`
                    : "right"
                }`}
              >
                {row3DataEntry}
              </div>
              <div
                className={`tbtt${orientation}Cell2 ${
                  orientation === "Horizontal"
                    ? `${index === lastIndex ? "bottom last" : "bottom"}`
                    : "right"
                }`}
              >
                {row4DataEntry}
              </div>
            </div>
          </ListGroup.Item>
        );
        // }
      });
    }, [data, orientation]);

    return <ListGroup className={`tbtt${orientation}DataList`}>{DataList}</ListGroup>;
  }
);

interface TwoxTwoTableProps {
  orientation: "Horizontal" | "Vertical";
  mainHeader1: string;
  mainHeader2: string;
  secondaryHeader1: string;
  secondaryHeader2: string;
  data: {
    date: string;
    row1DataEntry: string;
    row2DataEntry: string;
    row3DataEntry: string;
    row4DataEntry: string;
  }[];
  secondaryHeader1TooltipArgs?: SimpleTooltipArgs;
  secondaryHeader2TooltipArgs?: SimpleTooltipArgs;
}

export const TwoxTwoTable: React.FC<TwoxTwoTableProps> = React.memo(
  ({
    orientation,
    mainHeader1,
    mainHeader2,
    secondaryHeader1,
    secondaryHeader2,
    secondaryHeader1TooltipArgs,
    secondaryHeader2TooltipArgs,
    data,
  }: TwoxTwoTableProps): JSX.Element => {
    const [showSecondaryHeader1Tooltip, setShowSecondaryHeader1Tooltip] = useState<boolean>(false);
    const [showSecondaryHeader2Tooltip, setShowSecondaryHeader2Tooltip] = useState<boolean>(false);

    return (
      <div className={`tbtt${orientation}TableContainer`}>
        <div className={`tbtt${orientation}TableBorder`}>
          <div className={`tbtt${orientation}EmptySpace`}></div>
          <div
            className={`tbtt${orientation}EmptyRectangle ${
              orientation === "Horizontal" ? "left" : "top"
            }`}
          ></div>
        </div>
        <div className={`tbtt${orientation}TableView`}>
          <div className={`tbtt${orientation}HeaderContainer`}>
            <div className={`tbtt${orientation}EmptyRow`}></div>
            <div className={`tbtt${orientation}HeaderSection`}>
              <div className={`tbtt${orientation}MainHeader`}>
                <div className={`tbtt${orientation}MainHeader1`}>{mainHeader1}</div>
                <div className={`tbtt${orientation}MainHeader2`}>{mainHeader2}</div>
              </div>
              <div className={`tbtt${orientation}SecondaryHeader`}>
                <div
                  className={`tbtt${orientation}SecondaryHeader1 ${
                    orientation === "Horizontal" ? "top" : "left"
                  }`}
                >
                  {secondaryHeader1TooltipArgs ? (
                    <SimpleTooltip
                      text={secondaryHeader1TooltipArgs.text}
                      showTooltip={showSecondaryHeader1Tooltip}
                      verticalShift={secondaryHeader1TooltipArgs.verticalShift}
                      horizontalShift={secondaryHeader1TooltipArgs.horizontalShift}
                      direction={secondaryHeader1TooltipArgs.direction}
                      onMouseEnter={() => setShowSecondaryHeader1Tooltip(true)}
                      onMouseLeave={() => setShowSecondaryHeader1Tooltip(false)}
                      maxWidth={secondaryHeader1TooltipArgs.maxWidth}
                    >
                      {secondaryHeader1}
                    </SimpleTooltip>
                  ) : (
                    secondaryHeader1
                  )}
                </div>
                <div
                  className={`tbtt${orientation}SecondaryHeader2 ${
                    orientation === "Horizontal" ? "top" : "left"
                  }`}
                >
                  {secondaryHeader2TooltipArgs ? (
                    <SimpleTooltip
                      text={secondaryHeader2TooltipArgs.text}
                      showTooltip={showSecondaryHeader2Tooltip}
                      verticalShift={secondaryHeader2TooltipArgs.verticalShift}
                      horizontalShift={secondaryHeader2TooltipArgs.horizontalShift}
                      direction={secondaryHeader2TooltipArgs.direction}
                      onMouseEnter={() => setShowSecondaryHeader2Tooltip(true)}
                      onMouseLeave={() => setShowSecondaryHeader2Tooltip(false)}
                      maxWidth={secondaryHeader2TooltipArgs.maxWidth}
                    >
                      {secondaryHeader2}
                    </SimpleTooltip>
                  ) : (
                    secondaryHeader2
                  )}
                </div>
                <div
                  className={`tbtt${orientation}SecondaryHeader1 ${
                    orientation === "Horizontal" ? "bottom" : "right"
                  }`}
                >
                  <div
                    onMouseEnter={() =>
                      secondaryHeader1TooltipArgs
                        ? setShowSecondaryHeader1Tooltip(true)
                        : setShowSecondaryHeader1Tooltip(false)
                    }
                    onMouseLeave={() => setShowSecondaryHeader1Tooltip(false)}
                  >
                    {secondaryHeader1}
                  </div>
                </div>
                <div
                  className={`tbtt${orientation}SecondaryHeader2 ${
                    orientation === "Horizontal" ? "bottom" : "right"
                  }`}
                >
                  <div
                    onMouseEnter={() =>
                      secondaryHeader2TooltipArgs
                        ? setShowSecondaryHeader2Tooltip(true)
                        : setShowSecondaryHeader2Tooltip(false)
                    }
                    onMouseLeave={() => setShowSecondaryHeader2Tooltip(false)}
                  >
                    {secondaryHeader2}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`tbtt${orientation}DataSection`}>
            <DataList orientation={orientation} data={data}></DataList>
          </div>
        </div>
        <div className={`tbtt${orientation}TableBorder`}>
          <div className={`tbtt${orientation}EmptySpace`}></div>
          <div
            className={`tbtt${orientation}EmptyRectangle ${
              orientation === "Horizontal" ? "right" : "bottom"
            }`}
          ></div>
        </div>
      </div>
    );
  }
);
