// Experiment for enabling Master Targeting page.
const masterTargetingUsers = [
  "berto.garciacarrillo@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "michelle.ricciuti@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "alisa.knight@tinuiti.com",
  "jack.zahn@tinuiti.com",
  "matthew.bilyak@tinuiti.com",
  "molly.naudi-popick@tinuiti.com",
  // Add users here!
];

export const shouldEnableMasterTargeting = (user: string): boolean => {
  return masterTargetingUsers.includes(user);
};
