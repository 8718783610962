import React, { useMemo, useState, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { useSetError } from "../../redux/modals";
import { MdCallMade, MdCallReceived } from "react-icons/md";
import { Tooltip, ButtonGroup, Button } from "react-bootstrap";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import { BPMTable, OverlayTrigger } from "../../Components";
import { getLocalChangesForCompany } from "../../StreamingBuying/StreamingBuying";
import { formatMoney } from "../../utils/format-utils";
import { makeMemoizedGetter } from "../../utils/data";
import useLocation from "../../utils/hooks/useLocation";
import { download } from "../../utils/download-utils";

import "./OrderView.scss";

const DATE_FORMAT = "M/d/yyyy";
const toPrettyDate = makeMemoizedGetter({
  calculate: R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT)),
});

const parseAndFormatSpend = makeMemoizedGetter({
  calculate: num => formatMoney(parseFloat(num || 0)),
});

const PDF_MIME_TYPE = "application/pdf";
const UNSAVED = "Unsaved";
const UNSENT = "Unsent";
const UNCONFIRMED = "Unconfirmed";

const statusToLabel = status => {
  let label;
  switch (status) {
    case 20:
      label = "Confirmed";
      break;
    case 11:
      label = "Sent";
      break;
    default:
      label = "Pending";
  }
  return `${label} (${status})`;
};

const ListCell = ({ children, placement = OverlayTrigger.PLACEMENTS.TOP.LEFT }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{children}</Tooltip>}>
      <div className="listCell">{children}</div>
    </OverlayTrigger>
  );
};

const OrderView = ({ orderInfo, pdfInfo, forceCommit }) => {
  const setError = useSetError();
  const { company } = useLocation();

  const [orders, unsavedCount, unsentCount, unconfirmedCount] = useMemo(() => {
    const unsavedMap = getLocalChangesForCompany(company);
    let unsent = 0;
    let unconfirmed = 0;
    let orders = orderInfo.map(order => {
      let problem = "";
      if (unsavedMap[order.id]) {
        problem = UNSAVED;
      } else if (order.status === 0) {
        problem = UNSENT;
        unsent++;
      } else if (order.status < 20) {
        problem = UNCONFIRMED;
        unconfirmed++;
      }
      return { ...order, problem };
    });
    return [orders, R.keys(unsavedMap).length, unsent, unconfirmed];
  }, [orderInfo, company]);

  let downloadPDF = useCallback(
    async filename => {
      try {
        let response = await S3SignedUrlFetch(`bpm-streaming-orders/${filename}`);
        if (response.status !== 200) {
          console.error(response);
          throw new Error(response.statusText);
        }
        let blob = await response.blob();
        download(blob, filename, PDF_MIME_TYPE);
      } catch (e) {
        setError({
          message: "Could not find the file.",
          reportError: e,
        });
      }
    },
    [setError]
  );
  const [buttonFilter, setButtonFilter] = useState();
  const filteredOrders = useMemo(
    () => (buttonFilter ? R.filter(order => order.problem === buttonFilter, orders) : orders),
    [orders, buttonFilter]
  );

  const headers = useMemo(
    () => [
      {
        label: "ID",
        name: "id",
        width: 100,
        renderer: data => (
          <div>
            <a href={`/${company}/streaming/buying/${data.network}/${data.id}`}>
              {data.id}.{data.revision}
            </a>
          </div>
        ),
      },
      {
        label: "Problem",
        name: "problem",
        width: 120,
      },
      {
        label: "Network",
        name: "network",
        width: 100,
        renderer: data => <ListCell>{data.network}</ListCell>,
      },
      {
        label: "Platforms",
        name: "platforms",
        flex: 1,
        minFlexWidth: 120,
        renderer: data => (
          <ListCell>{R.pipe(R.prop("platforms"), R.defaultTo([]), R.join(", "))(data)}</ListCell>
        ),
      },
      {
        label: "Descriptions",
        name: "descriptions",
        flex: 2,
        minFlexWidth: 150,
        renderer: data => (
          <ListCell>
            {R.pipe(
              R.prop("descriptions"),
              R.defaultTo([]),
              R.map(description => description || "(none)"),
              R.join(", ")
            )(data)}
          </ListCell>
        ),
      },
      {
        label: "Start",
        name: "start",
        width: 110,
        renderer: R.pipe(R.prop("start"), toPrettyDate),
      },
      {
        label: "End",
        name: "end",
        width: 110,
        renderer: R.pipe(R.prop("end"), toPrettyDate),
      },
      {
        label: "Spend",
        name: "spend",
        width: 120,
        renderer: R.pipe(R.prop("spend"), parseAndFormatSpend),
      },
      {
        label: "Status",
        name: "status",
        width: 120,
        renderer: data => {
          let label = statusToLabel(data.status);
          return data.status < 20 ? (
            <Button variant="link" size="sm" onClick={() => forceCommit(data.id)}>
              {label}
            </Button>
          ) : (
            label
          );
        },
      },
      {
        label: "Last User",
        name: "lastuser",
        width: 80,
        renderer: data => (
          <ListCell placement={OverlayTrigger.PLACEMENTS.TOP.RIGHT}>
            {(data.lastuser || "").split("@")[0]}
          </ListCell>
        ),
      },
      {
        label: "PDFs",
        name: "",
        width: 70,
        nonInteractive: true,
        renderer: data => {
          if (pdfInfo) {
            const pdfs = pdfInfo[data.id];

            let sent = R.path(["sent"], pdfs);
            let approved = R.path(["approved"], pdfs);
            return (
              <div className="pdfButtons">
                {!!sent && (
                  <Button variant="outline-primary" size="sm" onClick={() => downloadPDF(sent)}>
                    <MdCallMade />
                  </Button>
                )}
                {!!approved && (
                  <Button variant="outline-primary" size="sm" onClick={() => downloadPDF(approved)}>
                    <MdCallReceived />
                  </Button>
                )}
              </div>
            );
          } else {
            return <div></div>;
          }
        },
      },
    ],
    [company, downloadPDF, forceCommit, pdfInfo]
  );

  return (
    <div className="orderView">
      <div className="filterButtons">
        <ButtonGroup>
          {[
            [UNSAVED, unsavedCount],
            [UNSENT, unsentCount],
            [UNCONFIRMED, unconfirmedCount],
          ].map(([key, count]) => {
            const isSelected = key === buttonFilter;
            return (
              <Button
                key={key}
                variant={`${isSelected ? "" : "outline-"}primary`}
                onClick={() => setButtonFilter(isSelected ? "" : key)}
              >
                {key} {count ? `(${count})` : ""}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
      <div className="orderTable">
        <BPMTable
          noRowsRenderer={() => <div className="noOrders">No orders to show.</div>}
          data={filteredOrders}
          rowHeight={30}
          headers={headers}
        />
      </div>
    </div>
  );
};

export default OrderView;
