import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import {
  MdSend,
  MdCheck,
  MdCheckCircle,
  MdCancel,
  MdLoop,
  MdRefresh,
  MdHistory,
} from "react-icons/md";
import { Dropdown, Button, Form } from "react-bootstrap";

import { LinearBuyingLambdaFetch, awaitJSON, pollS3, S3Put } from "../utils/fetch-utils";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { TYPES_TO_NAMES, OrderStatus } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { CURRENT_WEEK_START} from "../LinearBuying/linearBuyingConstants";
import {
  Page,
  FullPageSpinner,
  Spinner,
  BPMTable,
  CheckBox,
  ThreeDotsButton,
  BPMButton,
  SingleDatePicker,
  Header,
} from "../Components";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import OrderHistoryModal from "./OrderHistoryModal";
import "./LinearOrders.scss";

const MONTH_DAY_TIME_DATE_FORMAT = "MM/dd p";
const MONTH_DAY_DATE_FORMAT = "MM/dd";

const S3_LINEAR_ORDERS = "bpm-linear-orders";

const PRETTY_ORDER_STATUS_NAMES = {
  0: "Pending",
  10: "Sending",
  11: "Sent",
  12: "Marked as Sent",
  13: "Sent Cancellation",
  20: "Confirmed",
  21: "Confirmed Cancellation",
  30: "Canceled",
} as const;

const PDF_STATUS_MAP = {
  pending: <MdLoop size={25} />,
  success: <MdCheckCircle size={25} />,
  failed: <MdCancel size={25} />,
};

interface OrderRow {
  id: number;
  company: string;
  network: string;
  avail: string;
  start_week: string;
  end_week: string;
  type: number;
  status: number;
  revision: number;
  pdf_status: string;
  notes: string | null;
  lastmodified: string;
  lastuser: string;
  is_traffic: boolean;
  orderVersion: string;
  s3Path: string;
  campaign: string | null;
  isV1?: boolean;
  order_id?: number; // for V1 orders
}

export interface FormattedOrderRow extends OrderRow {
  formattedWeeks: string;
  formattedOrder: string;
  formattedOrderStatus: string;
  formattedOrderType: string;
}

interface SelectedOrder {
  id: number;
  isTraffic: boolean;
}

interface SelectedOrdersMap {
  [id: string]: SelectedOrder;
}

interface OrderNote {
  notes: string;
  isTraffic: boolean;
}

interface OrderNotesMap {
  [id: string]: OrderNote;
}

interface FileInfo {
  s3Key: string;
  file: File | undefined;
}

/**
 * Determines if an order should be allowed to be selected to be sent.
 */
const shouldAllowSending = (status: number) =>
  status === OrderStatus.PENDING || status === OrderStatus.CANCELED;

const LinearOrders = (): JSX.Element => {
  const companyInfo = useCompanyInfo();
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  let company = companyInfo.cid;
  const enableCampaigns = useExperimentFlag("enableLinearCampaigns");
  const enableRadioBuying = useExperimentFlag("enableRadioBuying");

  const [week, setWeek] = useState(CURRENT_WEEK_START);
  const [orders, setOrders] = useState<OrderRow[]>();
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});

  const [orderNotes, setOrderNotes] = useState<OrderNotesMap>({});
  const [saving, setSaving] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState<SelectedOrdersMap>({});
  const [selectAll, setSelectAll] = useState(false);
  const [sending, setSending] = useState(false);

  const [fileData, setFileData] = useState<Record<string, FileInfo>>({});

  const [orderModalData, setOrderModalData] = useState<FormattedOrderRow>();

  useEffect(() => {
    if (orders) {
      return;
    }
    (async () => {
      let res = await LinearBuyingLambdaFetch("/getOrdersAndTraffic", {
        params: {
          company,
          week,
        },
      });
      let data = await awaitJSON<OrderRow[]>(res);

      setOrders(data);
    })();
  }, [company, week, orders]);

  const downloadPDF = useCallback(
    async (id, path, isV1) => {
      setDownloading(current => ({ ...current, [id]: true }));
      const bucket = isV1 ? "bpm-orders" : "bpm-linear-orders";
      try {
        await pollS3({
          bucket: bucket,
          mimeType: "application/pdf",
          filename: path,
          autoDownload: true,
          timeout: 10,
        });
        setDownloading(current => ({ ...current, [id]: false }));
      } catch (e) {
        const reportError = e as Error;
        setDownloading(current => ({ ...current, [id]: false }));
        setError({ message: `Failed to download PDF ${id}. Error: ${reportError}`, reportError });
      }
    },
    [setError]
  );

  // Upload confirmation to S3.
  const uploadConfirmationToS3 = (key, file) => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      let data = event?.target?.result as any;

      S3Put(S3_LINEAR_ORDERS, key, data, {
        bufferEncoding: "binary",
        contentType: file.type,
      });
    };

    fileReader.readAsBinaryString(file);
  };

  // Upload all order confirmations to S3, and then confirm them in the DB.
  const uploadAllConfirmations = async () => {
    // Upload each file to S3
    try {
      for (let id of R.keys(fileData)) {
        const fileInfo = fileData[id];
        const { s3Key, file } = fileInfo;
        uploadConfirmationToS3(s3Key, file);
      }

      // Confirm orders in DB
      await LinearBuyingLambdaFetch("/markOrdersAsConfirmed", {
        method: "POST",
        body: { orders: [...R.keys(fileData)] },
      });
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to upload confirmations. Error: ${reportError.message}`,
        reportError,
      });
    }
  };

  // Add formatted values of Order, Status, and Type columns so that the values are more
  // clear in the filter bar.
  const formattedRows: FormattedOrderRow[] = useMemo(
    () =>
      (orders || []).map((orderRow: OrderRow) => {
        const { id, status, type, revision, start_week, end_week } = orderRow;
        const start = R.pipe(Dfns.parseISO, Dfns.format(MONTH_DAY_DATE_FORMAT))(start_week);
        const end = R.pipe(Dfns.parseISO, Dfns.format(MONTH_DAY_DATE_FORMAT))(end_week);
        const formattedWeeks = `${start} - ${end}`;
        const formattedOrder = `${id}-${revision}`;
        const formattedOrderStatus: string = PRETTY_ORDER_STATUS_NAMES[status];
        const formattedOrderType: string = TYPES_TO_NAMES[`${type}`];

        return {
          ...orderRow,
          formattedWeeks,
          formattedOrder,
          formattedOrderStatus,
          formattedOrderType,
        };
      }),
    [orders]
  );

  // Loop through this when "select all" checkbox is clicked. We only look at rows that are currently being filtered.
  const [filteredData, setFilteredData] = useState(formattedRows);

  let tableHeaders: Header[] = [
    {
      label: "",
      name: "id",
      width: 63,
      nonInteractive: true,
      renderer: (row: FormattedOrderRow) => {
        const { id, is_traffic } = row;
        const idString = id.toString();
        return (
          <CheckBox
            checked={R.has(idString, selectedOrders)}
            onCheck={() => {
              if (selectedOrders[idString]) {
                setSelectedOrders(current => R.omit([idString], current));
              } else {
                setSelectedOrders(current => ({
                  ...current,
                  [idString]: { id, isTraffic: is_traffic },
                }));
              }
            }}
          />
        );
      },
    },
    {
      label: "Weeks",
      name: "formattedWeeks",
      renderer: (row: FormattedOrderRow) => row.formattedWeeks,
    },
    {
      label: "Order",
      name: "formattedOrder",
      width: 140,
      renderer: (row: FormattedOrderRow) => {
        const { id, s3Path, formattedOrder, isV1, revision } = row;
        return downloading[id] ? (
          <Spinner />
        ) : (
          <>
            <Button className="pdfLink" onClick={() => downloadPDF(id, s3Path, isV1)}>
              {formattedOrder}
            </Button>
            {(revision > 1 || (!row.is_traffic && row.status === OrderStatus.CONFIRMED)) && (
              <Button size="sm" variant="outline-primary" onClick={() => setOrderModalData(row)}>
                <MdHistory />
              </Button>
            )}
          </>
        );
      },
    },
    { label: "Network", name: "network", flex: 1 },
    { label: "Avail", name: "avail", width: 85 },
    {
      label: "Version",
      name: "orderVersion",
      flex: 1,
      renderer: (row: FormattedOrderRow) => row.orderVersion,
    },
    {
      label: "Type",
      name: "formattedOrderType",
      width: 100,
      renderer: (row: FormattedOrderRow) => row.formattedOrderType,
    },
    {
      label: "Campaign",
      name: "campaign",
      flex: 1,
      renderer: (row: FormattedOrderRow) => row.campaign,
    },
    {
      label: "Order Notes",
      name: "notes",
      flex: 1,
      renderer: (row: FormattedOrderRow) => {
        return (
          <Form.Control
            className="notes"
            disabled={row.end_week < CURRENT_WEEK_START}
            as="textarea"
            maxLength={450}
            value={R.path(["notes"], orderNotes[row.id]) ?? (row.notes || "")}
            onChange={e => {
              const val = e.target.value;
              const isTraffic = row.is_traffic;
              setOrderNotes(current => ({ ...current, [row.id]: { notes: val, isTraffic } }));
            }}
          />
        );
      },
    },
    {
      label: "Status",
      name: "formattedOrderStatus",
      flex: 1,
      renderer: (row: FormattedOrderRow) => {
        const { id, s3Path, formattedOrderStatus } = row;
        return (
          <div className={`orderstatus ${formattedOrderStatus}`}>
            {formattedOrderStatus}
            {row.status === 11 && (
              <input
                className="fileInput"
                type="file"
                onChange={e => {
                  const uploadedFile = e.target.files;
                  const file = uploadedFile?.[0];
                  const approvedPath = s3Path.replace(".pdf", "_Approved.pdf");
                  setFileData(current => ({ ...current, [id]: { s3Key: approvedPath, file } }));
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      label: "Last Modified",
      name: "lastmodified",
      width: 150,
      renderer: (row: FormattedOrderRow) => {
        const { lastmodified, lastuser } = row;
        let formattedDate = R.pipe(
          Dfns.parseISO,
          Dfns.format(MONTH_DAY_TIME_DATE_FORMAT)
        )(lastmodified);
        let name = lastuser && lastuser.split("@")[0];
        return <div>{`${name} at ${formattedDate}`}</div>;
      },
    },
    {
      label: "PDF Status",
      name: "pdf_status",
      width: 90,
      renderer: (row: FormattedOrderRow) => (
        <div className={`pdfstatus ${row.pdf_status}`}>{PDF_STATUS_MAP[row.pdf_status]}</div>
      ),
    },
  ];

  if (!enableCampaigns) {
    tableHeaders = tableHeaders.filter(header => {
      return header.name !== "campaign";
    });
  }

  const headersRenderer = useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <CheckBox
              checked={selectAll}
              onCheck={() => {
                if (selectAll) {
                  setSelectedOrders({});
                } else {
                  let selectAllMap = {};
                  if (filteredData) {
                    for (const { id, is_traffic } of filteredData) {
                      const idString = id.toString();
                      selectAllMap[idString] = { id, isTraffic: is_traffic };
                    }
                  }

                  setSelectedOrders(current => ({ ...current, ...selectAllMap }));
                }

                setSelectAll(!selectAll);
              }}
            />
          );
        default:
          return data;
      }
    },
    [filteredData, selectAll]
  );

  const sendSelected = async (sendEmail: boolean) => {
    let allowSendingAll = true;
    for (let row of formattedRows) {
      const { id, status } = row;
      let allowSending = shouldAllowSending(status);
      if (!allowSending && selectedOrders[id]) {
        allowSendingAll = false;
        break;
      }
    }

    try {
      if (sendEmail && !allowSendingAll) {
        await setAreYouSure({
          title: "Resend orders to networks?",
          message:
            "You have orders selected that have already been sent. This would resend them. You probably don't want to do this. Do you wish to continue?",
          cancelText: "Never mind",
          okayText: "Okay",
          variant: "danger",
        });
      }
    } catch (e) {
      return;
    }

    try {
      if (sendEmail) {
        await setAreYouSure({
          title: "Send orders to networks?",
          message:
            "You are about to send the selected order PDFs to their associated networks.  Do you wish to continue?",
          cancelText: "Never mind",
          okayText: "Okay",
        });
      }
    } catch (e) {
      return;
    }

    try {
      setSending(true);
      await LinearBuyingLambdaFetch("/sendOrdersAndTraffic", {
        method: "POST",
        body: { company, orders: R.values(selectedOrders), sendEmail },
      });
      setOrders(undefined);
      setSelectedOrders({});
      setSelectAll(false);
      setSending(false);
    } catch (e) {
      const reportError = e as Error;
      setSending(false);
      setError({ message: reportError.message, reportError });
    }
  };

  const regenerateSelected = useCallback(async () => {
    try {
      await setAreYouSure({
        title: "Regenerate PDFs?",
        message: "You are about to regenerate the selected order PDFs.  Do you wish to continue?",
        cancelText: "Never mind",
        okayText: "Okay",
      });
    } catch (e) {
      return;
    }

    try {
      setSending(true);
      await LinearBuyingLambdaFetch("/regeneratePDFs", {
        method: "POST",
        body: { orders: R.values(selectedOrders), useExperiment: enableRadioBuying },
      });
      setOrders(undefined);
      setSelectedOrders({});
      setSelectAll(false);
      setSending(false);
    } catch (e) {
      const reportError = e as Error;
      setSending(false);
      setError({ message: reportError.message, reportError });
    }
  }, [enableRadioBuying, selectedOrders, setAreYouSure, setError]);

  const cancelSelected = useCallback(async () => {
    try {
      await setAreYouSure({
        title: "Cancel Orders?",
        message: `You are about to cancel the selected orders. This will zero-out rows for future weeks, but leave current week rows alone.
        If this order is pending and has never been sent to a network, it will just be deleted.
        Do you wish to continue?`,
        cancelText: "Never mind",
        okayText: "Okay",
      });
    } catch (e) {
      return;
    }

    try {
      setSending(true);
      await LinearBuyingLambdaFetch("/cancelOrders", {
        method: "POST",
        body: { orders: R.values(selectedOrders), useExperiment: enableRadioBuying },
      });
      setOrders(undefined);
      setSelectedOrders({});
      setSelectAll(false);
      setSending(false);
    } catch (e) {
      const reportError = e as Error;
      setSending(false);
      setError({ message: reportError.message, reportError });
    }
  }, [enableRadioBuying, selectedOrders, setAreYouSure, setError]);

  const saveChanges = async () => {
    try {
      setSaving(true);
      if (!R.isEmpty(orderNotes)) {
        let processedOrderNotes = R.keys(orderNotes).map(id => {
          const { notes, isTraffic } = orderNotes[id];
          return { id: parseInt(id), notes, isTraffic };
        });

        await LinearBuyingLambdaFetch("/saveOrderNotes", {
          method: "POST",
          body: { orders: processedOrderNotes, useExperiment: enableRadioBuying },
        });
      }

      if (!R.isEmpty(fileData)) {
        await uploadAllConfirmations();
      }
      setOrders(undefined);
      setOrderNotes({});
      setFileData({});
      setSelectedOrders({});
      setSelectAll(false);
      setSaving(false);
    } catch (e) {
      const reportError = e as Error;
      setSaving(false);
      setError({ message: reportError.message, reportError });
    }
  };

  return (
    <Page
      title="Linear Orders"
      pageType="Linear Orders"
      actions={
        <div className="linearOrdersActions">
          {(!R.isEmpty(orderNotes) || !R.isEmpty(fileData)) && (
            <>
              <BPMButton
                size="sm"
                variant="danger"
                onClick={() => {
                  setOrderNotes({});
                  setFileData({});
                }}
              >
                Clear Changes
              </BPMButton>
              <BPMButton size="sm" variant="success" onClick={() => saveChanges()}>
                {saving ? <Spinner /> : "Save Changes"}
              </BPMButton>
            </>
          )}
          {sending && <Spinner />}
          <Dropdown className="optionsDropdown">
            <Dropdown.Toggle as={ThreeDotsButton} />
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdownItem"
                disabled={R.isEmpty(selectedOrders)}
                onClick={() => sendSelected(true)}
              >
                <MdSend />
                <span className="label"> Send Selected</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdownItem"
                disabled={R.isEmpty(selectedOrders)}
                onClick={() => sendSelected(false)}
              >
                <MdCheck />
                <span className="label"> Mark Selected as Sent</span>
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdownItem"
                disabled={R.isEmpty(selectedOrders)}
                onClick={regenerateSelected}
              >
                <MdRefresh />
                <span className="label">Regenerate Selected</span>
              </Dropdown.Item>
              {/* <Dropdown.Item className="dropdownItem" onClick={() => {}}>
                <MdClose />
                <span className="label">Cancel Scheduled</span>
              </Dropdown.Item> */}
              <Dropdown.Item
                className="dropdownItem"
                disabled={R.isEmpty(selectedOrders)}
                onClick={cancelSelected}
              >
                <MdCancel />
                <span className="label">Cancel Selected</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <SingleDatePicker
            mondayOnly
            date={week}
            onChange={date => {
              setWeek(date);
              setOrders(undefined);
              setSelectAll(false);
              setSelectedOrders({});
            }}
          />
        </div>
      }
    >
      <div className="linearOrders">
        {orders ? (
          R.isEmpty(orders) ? (
            <div className="noOrders">No orders for the selected week.</div>
          ) : (
            <BPMTable
              data={formattedRows}
              headers={tableHeaders}
              headersRenderer={headersRenderer}
              onFilteredDataChange={setFilteredData}
            />
          )
        ) : (
          <FullPageSpinner />
        )}
        {orderModalData && (
          <OrderHistoryModal
            orderInfo={orderModalData}
            downloadPDF={downloadPDF}
            onClose={() => setOrderModalData(undefined)}
          />
        )}
      </div>
    </Page>
  );
};

export default LinearOrders;
