import React, { useState, useEffect } from "react";
import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import QuestionCard, { QuestionCardProps } from "../Components/QuestionCard/QuestionCard";
import "./Onboarding.scss";

interface QuestionOnlyFormProps {
  responses: Response[];
  questions: QuestionCardProps[];
  onChange: (updatedResponses: Response[]) => void;
}

const QuestionOnlyForm: React.FC<QuestionOnlyFormProps> = ({ responses, questions, onChange }) => {
  const [currentResponses, setCurrentResponses] = useState<Response[]>(responses || []);

  useEffect(() => {
    setCurrentResponses(responses);
  }, [responses]);

  const handleChange = (updatedResponse: Response) => {
    const existingIndex = currentResponses.findIndex(
      response => response.questionNumber === updatedResponse.questionNumber
    );

    let updatedResponses: Response[];

    if (existingIndex !== -1) {
      updatedResponses = currentResponses.map((response, index) =>
        index === existingIndex ? updatedResponse : response
      );
    } else {
      updatedResponses = [...currentResponses, updatedResponse];
    }

    setCurrentResponses(updatedResponses);
    onChange(updatedResponses);
  };

  const initialValue = (questionNumber: string) => {
    if (!currentResponses || currentResponses.length === 0) {
      return {
        questionNumber,
        selections: [],
        additionalInfo: "",
      };
    }
    return (
      currentResponses.find(response => response.questionNumber === questionNumber) || {
        questionNumber,
        selections: [],
        additionalInfo: "",
      }
    );
  };

  return (
    <div className="questionRows">
      {questions.map(question => (
        <QuestionCard
          id={question.id}
          key={question.questionNumber}
          title={question.title}
          subtitle={question.subtitle}
          questionNumber={question.questionNumber}
          type={question.type}
          textLabel={question.textLabel}
          options={question.options}
          initialValue={initialValue(question.questionNumber)}
          placeholder={question.placeholder}
          nestedOptionsConfig={question.nestedOptionsConfig}
          onChange={updatedValue => handleChange(updatedValue)}
        />
      ))}
    </div>
  );
};

export default QuestionOnlyForm;
