import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";

import * as R from "ramda";

import { useSelector } from "react-redux";

import { InputGroup, Form, Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

import {
  StreamingV2LambdaFetch,
  awaitJSON,
  JavaLambdaFetch,
  GetS3SignedUrl,
  getCleanEmail,
} from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";

import { useCompanyInfo } from "../redux/company";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import * as UserRedux from "../redux/user";

import { Skeleton, RectSkeleton, ListSkeleton } from "../Components";

import { OrderViewContext } from "./OrderView";

const PPM_ADDENDUM =
  "\n\nProspect Point Media (PPM) is a sister agency to Bliss Point Media (BPM), functioning as a wholly owned subsidiary. Media spend should be applied toward BPM's total overall commitments, using the same media rates previously negotiated in BPM contracts.\n\nBilling Information:\nTo ensure prompt reconciliation of bills and payment, please address bills as follows:\nPrimary AP Contact: accounting@prospectpointmedia.com\n\nMailing Address:\nProspect Point Media\n1507 7th St. Suite 132\nSanta Monica, CA 90401";

const TINUITI_ADDENDUM =
  "\n\nTinuiti is a sister agency to Bliss Point Media (BPM). Media spend should be applied toward BPM's total overall commitments, using the same media rates previously negotiated in BPM contracts.";

const makeDefaultEmailBodyText = ({ name, company, agency }) =>
  `Hi ${name},\n\nAttached is our streaming/FEP order for ${company}. Please review and send back a signed confirmation. *Existing tags will be extended for the duration of this flight*.\n\nThanks!${
    agency === "ppm" ? PPM_ADDENDUM : agency === "tinuiti" ? TINUITI_ADDENDUM : ""
  }`;

const SKELETON_LINE_HEIGHT = 30;
const SKELETON_PADDING = 10;
const SKELETON_BORDER_RADIUS = 5;

const MEDIA_OPS_EMAIL = "bpm-media-buying@tinuiti.com";

const MailerSkeleton = () => {
  return (
    <Skeleton>
      <ListSkeleton
        alignTop
        lineHeight={SKELETON_LINE_HEIGHT}
        lineSpacing={SKELETON_PADDING / 2}
        horizontalPadding={SKELETON_PADDING}
        verticalPadding={SKELETON_PADDING}
        height={height => height / 2}
        borderRadius={SKELETON_BORDER_RADIUS}
      />
      <RectSkeleton
        // We want padding on x/y/width, so we supply it as a prop. But it will also deduct from
        // height, so add it back in.
        height={SKELETON_LINE_HEIGHT + 2 * SKELETON_PADDING}
        y={height => height / 2}
        padding={SKELETON_PADDING}
      />
      <RectSkeleton
        height={height => height / 2 - SKELETON_LINE_HEIGHT - SKELETON_PADDING}
        y={height => height / 2 + SKELETON_LINE_HEIGHT + SKELETON_PADDING}
        padding={SKELETON_PADDING}
      />
    </Skeleton>
  );
};

const SendOrderView = () => {
  const getIsMounted = useIsMounted();

  const { company } = useLocation();
  const companyInfo = useCompanyInfo();

  const sendingEmail = useSelector(UserRedux.sendingEmailSelector);

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const {
    network,
    order,
    isNewOrder,
    flightChangeMap,
    generatePDF,
    setShowSendView,
    setSaving,
    refetchOrder,
  } = useContext(OrderViewContext);

  useEffect(() => {
    if (order && order.status !== 0) {
      (async () => {
        try {
          await setAreYouSure({
            title: "IO Already Sent",
            message: "This IO has already been sent. Are you sure you want to resend it?",
            okayText: "Yes, I want to send",
            cancelText: "I don't want to send",
          });
        } catch (e) {
          setShowSendView(false);
        }
      })();
    }
  }, [order, setAreYouSure, setShowSendView]);

  const [generating, setGenerating] = useState(false);
  const [filename, setFilename] = useState();
  const [signedURL, setSignedURL] = useState();
  useEffect(() => {
    if (!generating && !filename && !signedURL) {
      setGenerating(true);
      (async () => {
        let newFilename = await generatePDF(false);
        let signedURL = await GetS3SignedUrl(`bpm-streaming-orders/${newFilename}`);
        if (getIsMounted()) {
          setFilename(newFilename);
          setSignedURL(signedURL);
          setGenerating(false);
        }
      })();
    }
  }, [generatePDF, filename, generating, getIsMounted, signedURL]);
  const allPlatforms = useMemo(() => {
    const { flights } = order;
    const platformMap = {};
    for (let flight of flights) {
      let combo = { ...flight, ...(flightChangeMap[flight.id] || {}) };
      platformMap[combo.platform] = true;
    }
    return R.pipe(
      R.values,
      R.reduce(
        (map, flight) => ({
          ...map,
          [flight.platform]: true,
        }),
        platformMap
      ),
      R.keys
    )(flightChangeMap);
  }, [order, flightChangeMap]);

  const neverSent = useMemo(() => isNewOrder || (order.status === 0 && order.revision === 1), [
    isNewOrder,
    order,
  ]);

  const [to, setTo] = useState();
  const [cc, setCC] = useState([]);

  const [bodyText, setBodyText] = useState("");

  const [mailerInfo, setMailerInfo] = useState();
  useEffect(() => {
    if (company && network && R.length(allPlatforms) && companyInfo.cid && !mailerInfo) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/mailer", {
            params: {
              company,
              network,
              platforms: allPlatforms.join(","),
            },
          });
          let info = await awaitJSON(res);
          if (getIsMounted()) {
            if (!(R.pipe(R.prop("networkContacts"), R.length)(info) || R.prop("primary", info))) {
              await setError({
                title: "No Contacts",
                message: (
                  <p>
                    There are no contacts for {network} on any of the properties:{" "}
                    {allPlatforms.join(", ")}. You cannot send until there are some contacts.{" "}
                    <a href={`/streaming/networks/${network}`}>You can set contacts here.</a>
                  </p>
                ),
              });
              setShowSendView(false);
              return;
            }
            setTo(info.primary.email);
            setCC([sendingEmail, ...info.networkContacts, ...info.internalContacts]);
            setBodyText(
              makeDefaultEmailBodyText({
                name: info.primary.firstName,
                company: companyInfo.name,
                agency: companyInfo.agency,
              })
            );
            setMailerInfo(info);
          }
        } catch (e) {
          setError({
            message: `Failed to fetch mailer info. ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [
    company,
    network,
    allPlatforms,
    sendingEmail,
    mailerInfo,
    setError,
    companyInfo,
    setShowSendView,
    getIsMounted,
  ]);

  const [subject, setSubject] = useState(() => {
    if (neverSent) {
      return `New Streaming/FEP Order for ${companyInfo.name} - ${network}`;
    }
    let version = order.revision;
    return `Streaming/FEP Order Revision (#${order.id}, version ${version}) for ${companyInfo.name} - ${network}`;
  });

  const onSend = useCallback(async () => {
    setSaving(true);
    let cleanTo = getCleanEmail(to);
    if (!cleanTo) {
      setError({
        title: "Invalid Email",
        message: `"${to}" is not a valid email address`,
      });
      setSaving(false);
      return;
    }
    let newCC = [];
    for (let email of cc) {
      if (!email) {
        continue;
      }
      // remove all whitespace
      let cleanEmail = getCleanEmail(email);
      if (!cleanEmail) {
        setError({
          title: "Invalid Email",
          message: `"${email}" is not a valid email address`,
        });
        setSaving(false);
        return;
      }
      newCC.push(cleanEmail);
    }
    newCC.push(MEDIA_OPS_EMAIL);

    let body = bodyText
      .replace(/\n/g, "<br />")
      .replace(/\*(.+?)\*/g, "<strong>$1</strong>")
      .replace(/_(.+?)_/g, "<em>$1</em>");

    try {
      await JavaLambdaFetch("/send_mail", {
        method: "POST",
        body: {
          to: cleanTo,
          agency: companyInfo.agency,
          cc: newCC.join(", "),
          subject,
          body,
          attachment: `s3://bpm-streaming-orders/${filename}`,
        },
      });
      if (order.status < 11) {
        await StreamingV2LambdaFetch("/orders/status", {
          method: "POST",
          body: {
            id: order.id,
            status: 11,
          },
        });
        refetchOrder();
      }
      setSaving(false);
      setShowSendView(false);
    } catch (e) {
      setError({ message: `Failed to send PDF. ${e.message}`, reportError: e });
      setSaving(false);
    }
  }, [
    setSaving,
    setError,
    cc,
    bodyText,
    to,
    filename,
    refetchOrder,
    setShowSendView,
    companyInfo,
    order,
    subject,
  ]);

  return (
    <div className="sendView">
      <div className="mailPane">
        {mailerInfo ? (
          <>
            <div className="emails">
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text className="inputLabel">To</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control value={to} onChange={e => setTo(e.target.value)} />
              </InputGroup>
              <div className="ccs">
                {cc.map((email, i) => (
                  <InputGroup key={`${i}_${cc.length}`} size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="inputLabel">CC</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      value={email}
                      onChange={e => setCC(R.update(i, e.target.value))}
                    />
                    <InputGroup.Append>
                      <Button variant="outline-danger" onClick={() => setCC(R.remove(i, 1))}>
                        <MdDelete />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                ))}
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="inputLabel">CC</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control readOnly value={MEDIA_OPS_EMAIL} />
                </InputGroup>
              </div>
              <Button block size="sm" onClick={() => setCC(cc => [...cc, ""])}>
                Add CC
              </Button>
            </div>
            <div className="content">
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text className="">Subject</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control value={subject} onChange={e => setSubject(e.target.value)} />
              </InputGroup>
              <Form.Control
                as="textarea"
                className="bodyTextArea"
                value={bodyText}
                onChange={e => setBodyText(e.target.value)}
              />
              <div className="hint">
                You can use *<strong>Bold</strong>* and _<em>Italics</em>_, which will appear in the
                email.
              </div>
            </div>
            <div className="buttonRow">
              <Button size="sm" variant="dark" onClick={() => setShowSendView(false)}>
                Cancel
              </Button>
              <Button disabled={!filename} size="sm" variant="primary" onClick={onSend}>
                Send
              </Button>
            </div>
          </>
        ) : (
          <MailerSkeleton />
        )}
      </div>
      <div className="pdfViewer">
        {signedURL ? (
          <iframe src={signedURL} title="pdfViewer" />
        ) : (
          <Skeleton>
            <RectSkeleton padding={SKELETON_PADDING} />
          </Skeleton>
        )}
      </div>
    </div>
  );
};

export default SendOrderView;
