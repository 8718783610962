import React, { useMemo } from "react";

import { Router } from "@reach/router";

import { MdMenu, MdLibraryAdd, MdSettings } from "react-icons/md";

import { useTabbedNav } from "../utils/hooks/useNav";

import { useCompanyInfo } from "../redux/company";

import { Page, ToggleNav, ToggleNavButton } from "../Components";

import Menu from "./Menu";
import BulkAddPage from "./BulkAddPage";
import CreativeOptions from "./CreativeOptions";

import "./CreativeMap.scss";

export const MENU_KEY = "menu";
export const BULK_ADD_KEY = "add";
export const CREATIVE_OPTIONS_KEY = "options";

export const useReportingCreative = () => {
  const companyInfo = useCompanyInfo();

  const usesReportingCreative = useMemo(() => {
    return companyInfo.parent_company === "techstyle";
  }, [companyInfo]);
  return usesReportingCreative;
};

export const CreativeMapContext = React.createContext();

export default React.memo(({ navigate }) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "creativemap",
    defaultKey: MENU_KEY,
  });

  return (
    <Page
      title="Creative Map"
      pageType="Creative Map"
      minWidth={900}
      actions={
        <div className="creativeMapActions">
          <ToggleNav activeKey={tab} onChange={goToTab}>
            <ToggleNavButton value={MENU_KEY}>
              <MdMenu />
            </ToggleNavButton>
            <ToggleNavButton value={BULK_ADD_KEY}>
              <MdLibraryAdd />
            </ToggleNavButton>
            <ToggleNavButton value={CREATIVE_OPTIONS_KEY}>
              <MdSettings />
            </ToggleNavButton>
          </ToggleNav>
        </div>
      }
    >
      <div className="creativeMapPage">
        <CreativeMapContext.Provider
          value={{
            goToTab,
          }}
        >
          <Router className="fullPageRouter">
            <Menu path={MENU_KEY} />
            <BulkAddPage path={BULK_ADD_KEY} />
            <CreativeOptions path={CREATIVE_OPTIONS_KEY} />
            <Menu default />
          </Router>
        </CreativeMapContext.Provider>
      </div>
    </Page>
  );
});
