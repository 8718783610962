import { awaitJSON, FiltersLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { DIMENSIONS, fetchDailyDelivery } from "../YouTubeDelivery/YouTubeDelivery";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { Form } from "react-bootstrap";
import { GetFilterPresetsParams } from "@blisspointmedia/bpm-types/dist/FilterPresets";
import { OldDropdown as Dropdown, RelativeDatePicker, BPMToggleButton } from "../Components";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { SlideDeliveryChart } from "./StreamingDelivery";
import * as R from "ramda";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useCompanyInfo } from "../redux/company";

interface LegendItem {
  key: string;
  color: string;
  overrideText?: string | undefined;
}

export interface YoutubeDeliverySlideState {
  agency: string;
  absolute: boolean;
  bySpend: boolean;
  daily: boolean;
  dates: RelativeDateRange;
  dimension: string;
  filterPreset: FilterPreset | null;
  title: string;
}

interface YoutubeDeliverySlideData {
  title: string;
  chartURL: string;
  legend: LegendItem[];
}

export const youtubeDelivSharedStateKey = "youtubeDelivery" as const;
export interface YoutubeDeliverySharedState {
  filterPresets: FilterPreset[];
}
export const youtubeDelivSharedFetcher: SharedStateFetcher<YoutubeDeliverySharedState> = async (
  key: string,
  setError: SetError
) => {
  const [company] = key.split("_");
  let res: YoutubeDeliverySharedState = {
    filterPresets: [],
  };
  try {
    const params = {
      company,
      platform: "youtube",
    };
    const filterPresetRes = await FiltersLambdaFetch<GetFilterPresetsParams>("/getFilters", {
      params,
    });
    const parsedFilterPresets = await awaitJSON(filterPresetRes);
    res.filterPresets = parsedFilterPresets;
  } catch (e) {
    const error: Error = e as Error;
    setError({
      message: `Could not get valid youtube filter presets. Error: ${error.message}`,
      reportError: error,
    });
  }
  return res;
};

class YoutubeDeliverySlide extends SlideType {
  static typeKey = "youtubeDelivery";
  static displayKey = "Youtube Delivery";
  static defaultState: YoutubeDeliverySlideState = {
    absolute: false,
    agency: "bpm",
    bySpend: true,
    daily: true,
    dates: {
      start: { pivotDate: "monday", adjustment: 6, adjustmentType: "week" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    dimension: "Ad",
    filterPreset: null,
    title: "Youtube Delivery",
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<YoutubeDeliverySlideState>
  > = React.memo(({ state, setState, slideContext, sharedState, sharedFetch }) => {
    const { company } = slideContext;
    const { agency: companyInfoAgency } = useCompanyInfo();
    const { absolute, agency, bySpend, daily, dates, dimension, filterPreset, title } = state;
    const extremaKey = company;
    const { filterPresets } =
      R.isNil(sharedState) ||
      R.isNil(sharedState[youtubeDelivSharedStateKey]) ||
      R.isNil(sharedState[youtubeDelivSharedStateKey][extremaKey])
        ? { filterPresets: [] }
        : sharedState[youtubeDelivSharedStateKey][extremaKey];

    useEffect(() => {
      if (!R.path([youtubeDelivSharedStateKey, extremaKey], sharedState)) {
        (async () => {
          await sharedFetch(youtubeDelivSharedStateKey, extremaKey);
        })();
      }
    }, [sharedState, sharedFetch, extremaKey]);

    useEffect(() => {
      if (agency !== companyInfoAgency) {
        setState({ agency: companyInfoAgency });
      }
    }, [sharedState, sharedFetch, extremaKey, agency, companyInfoAgency, setState]);

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="wrappingColumn">
          <Dropdown
            label={"Filter"}
            value={filterPreset ? filterPreset.id.toString() : "undefined"}
            options={R.map(filterPreset => {
              return { label: filterPreset.name, value: filterPreset.id.toString() };
            }, R.defaultTo([], filterPresets) as FilterPreset[])}
            onChange={filterID => {
              let filterPreset: FilterPreset | null = null;
              for (let preset of R.defaultTo([], filterPresets)) {
                if (filterID === preset.id.toString()) {
                  filterPreset = preset;
                }
              }
              setState({ filterPreset });
            }}
          />
          <Dropdown
            label={"Dimension"}
            value={dimension}
            options={R.keys(DIMENSIONS).map(key => ({ label: DIMENSIONS[key], value: key }))}
            onChange={dimension => setState({ dimension })}
          />
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Relative",
                },
                {
                  key: "Absolute",
                },
              ]}
              selectedOption={absolute ? "Absolute" : "Relative"}
              onChange={key => setState({ absolute: key === "Absolute" })}
            />
          </Form.Group>
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Daily",
                },
                {
                  key: "Weekly",
                },
              ]}
              selectedOption={daily ? "Daily" : "Weekly"}
              onChange={key => setState({ daily: key === "Daily" })}
            />
          </Form.Group>
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "spend",
                  label: "By Spend",
                },
                {
                  key: "imps",
                  label: "By Impressions",
                },
              ]}
              selectedOption={bySpend ? "spend" : "imps"}
              onChange={key => setState({ bySpend: key === "spend" })}
            />
          </Form.Group>
        </div>
        <div className="wrappingColumn">
          <Form.Group>
            <Form.Label>Start</Form.Label>
            <RelativeDatePicker
              state={dates.start}
              onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End</Form.Label>
            <RelativeDatePicker
              state={dates.end}
              onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
            />
          </Form.Group>
        </div>
        <div>
          <small>Note: Dates will be clipped based on the available data.</small>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<YoutubeDeliverySlideData> => {
    const { company } = context;
    const {
      agency,
      absolute,
      bySpend,
      daily,
      dates,
      dimension,
      filterPreset,
      title,
    } = state as YoutubeDeliverySlideState;
    const data = await fetchDailyDelivery({
      agency,
      dates: {
        start: computeResolvedDate(dates.start),
        end: computeResolvedDate(dates.end),
      },
      company,
      dimension,
      filterID: filterPreset ? filterPreset.id : undefined,
      filterState: filterPreset
        ? filterPreset.filter_state
        : { advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false },
    });

    if (R.isEmpty(data.keys) || R.isEmpty(data[daily ? "daily" : "weekly"])) {
      throw new Error(
        `We are missing data for the date range: ${computeResolvedDate(
          dates.start
        )}-${computeResolvedDate(dates.end)} with the following settings:
        company: ${company}
        dimension: ${dimension}
        ${filterPreset ? `filter: ${filterPreset.name}` : ""}
        Please verify on the Youtube Delivery Page that there is data for these settings or reach out to the engineering team!`
      );
    }

    const sandbox = await claimSandbox();
    const { svgString, legend }: { svgString: string; legend: LegendItem[] } = await new Promise(
      resolve => {
        const load = () => {
          ReactDOM.render(
            <SlideDeliveryChart
              daily={daily}
              absolute={absolute}
              data={data}
              dimension={dimension}
              bySpend={bySpend}
              useLogo={false}
              onLoad={({ elem, legend }) => {
                if (elem) {
                  const svg = elem.container.getElementsByTagName("svg")[0];
                  const boundingBox = svg.getBoundingClientRect();
                  const svgString = convertSVGStringToPNGURI(
                    svg.outerHTML,
                    boundingBox.width,
                    boundingBox.height
                  );
                  resolve({ svgString, legend });
                } else {
                  setTimeout(load, 100);
                }
              }}
            />,
            sandbox.element
          );
        };
        setTimeout(load, 100);
      }
    );

    releaseSandbox(sandbox);
    const chartURL = await addS3Image(svgString);
    return { chartURL, legend, title };
  };
}

export default YoutubeDeliverySlide;
