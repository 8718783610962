import "./DataCard.scss";
import React from "react";

interface DataCardProps {
  label: string;
  value: string | number;
  width?: string;
}

const DataCard: React.FC<DataCardProps> = ({ label, value, width }) => {
  return (
    <div className="data-card" style={{ width }}>
      <div className="capitalize-first data-card-title">{label}</div>
      <div className="data-card-value">{value}</div>
    </div>
  );
};

export default DataCard;
