import React, { CSSProperties, useMemo, useState } from "react";

import * as R from "ramda";
import * as uuid from "uuid";

import { Dropdown as BSDropdown, Button, Form } from "react-bootstrap";

import { Setter, StateSetter, typedReactMemo } from "../utils/types";

import { Spinner } from ".";

import "./OldDropdown.scss";
export type DropdownOption<T extends string = string> =
  | T
  | {
      label?: string;
      value: T;
    };

interface SearchableMenuProps {
  filterTerm: string;
  setFilterTerm: StateSetter<string>;
  style?: CSSProperties;
  className?: string;
}

const SearchableMenu = React.forwardRef<HTMLDivElement, SearchableMenuProps>(
  ({ filterTerm, setFilterTerm, style, className, children }, ref) => {
    return (
      <div ref={ref} style={style} className={className}>
        <Form.Control
          size="sm"
          className="searchBar"
          placeholder="Search"
          value={filterTerm}
          onChange={e => setFilterTerm(e.target.value)}
        />
        {children}
      </div>
    );
  }
);

interface DropdownProps<T extends string> {
  label?: string;
  value: T;
  options: readonly DropdownOption<T>[];
  onChange: Setter<T>;
  size?: "sm" | "lg";
  className?: string;
  disabled?: boolean;
  spinner?: boolean;
  outline?: boolean;
  frozen?: boolean;
  bold?: boolean;
  wrapping?: boolean;
  searchable?: boolean;
  placeholder?: string;
  normalTitle?: boolean;
}

export const OldDropdown = typedReactMemo(
  <T extends string>({
    label,
    value,
    options,
    onChange,
    size,
    disabled,
    className,
    spinner,
    frozen,
    bold,
    wrapping,
    searchable,
    placeholder,
    normalTitle,
  }: DropdownProps<T>): JSX.Element => {
    const id = useMemo(() => uuid.v4(), []);
    const selectedOption = useMemo(
      () =>
        R.find(option => (typeof option === "string" ? option : option.value) === value, options),
      [value, options]
    );
    const title = useMemo(
      () =>
        spinner ? (
          <Spinner />
        ) : (
          <span>
            {label && (
              <span
                className={normalTitle ? "dropdownLabelNormal" : "dropdownLabel"}
              >{`${label}: `}</span>
            )}
            <span className="value">{`${
              typeof selectedOption === "string"
                ? selectedOption
                : selectedOption?.label || selectedOption?.value || placeholder || ""
            }`}</span>
          </span>
        ),
      [label, selectedOption, spinner, placeholder, normalTitle]
    );

    const ourClassName = useMemo(() => {
      let ourClasses = ["BPMDropdownButton"];
      if (size === "sm") {
        ourClasses.push("sm");
      }
      if (frozen) {
        ourClasses.push("frozen");
      }
      if (options.length < 1) {
        ourClasses.push("noDrop");
      }
      if (bold) {
        ourClasses.push("bold");
      }
      if (wrapping) {
        ourClasses.push("wrapping");
      }
      if (!selectedOption && placeholder) {
        ourClasses.push("placeholder");
      }
      if (className) {
        ourClasses.push(className);
      }

      return ourClasses.join(" ");
    }, [className, size, frozen, options, bold, wrapping, placeholder, selectedOption]);

    const [filterTerm, setFilterTerm] = useState("");

    const filteredOptions = useMemo(() => {
      let lcFilterTerm = filterTerm.toLowerCase();
      return options.filter(option =>
        typeof option === "string"
          ? option.toLowerCase().includes(lcFilterTerm)
          : option.value.toLowerCase().includes(lcFilterTerm) ||
            (option.label ? option.label.toLowerCase().includes(lcFilterTerm) : true)
      );
    }, [options, filterTerm]);

    let items = useMemo(
      () =>
        filteredOptions.map(option => {
          let label: string, value: T;
          if (typeof option === "string") {
            label = option;
            value = option;
          } else {
            label = option.label || option.value;
            ({ value } = option);
          }
          return (
            <BSDropdown.Item key={value} onClick={() => onChange(value)}>
              {label || value}
            </BSDropdown.Item>
          );
        }),
      [filteredOptions, onChange]
    );

    let menu = useMemo(() => {
      if (!searchable) {
        return <BSDropdown.Menu>{items}</BSDropdown.Menu>;
      }
      return (
        <BSDropdown.Menu as={SearchableMenu} filterTerm={filterTerm} setFilterTerm={setFilterTerm}>
          {items}
        </BSDropdown.Menu>
      );
    }, [searchable, items, filterTerm]);

    if (options.length < 1) {
      return (
        <div className={ourClassName}>
          <Button size={size} variant="outline-primary" disabled={disabled}>
            {title}
          </Button>
        </div>
      );
    }

    return (
      <BSDropdown className={ourClassName}>
        <BSDropdown.Toggle
          id={`${id}_dropdown`}
          size={size}
          variant="outline-primary"
          disabled={disabled}
        >
          {title}
        </BSDropdown.Toggle>
        {menu}
      </BSDropdown>
    );
  }
);
