import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import {
  LinearLambdaFetch,
  SheetsLambdaFetch,
  UsersLambdaFetch,
  awaitJSON,
  pollS3,
  LinearBuyingLambdaFetch,
} from "../utils/fetch-utils";
import * as Dfns from "date-fns/fp";
import { downloadJSONToCSV } from "../utils/download-utils";
import {
  Page,
  Spinner,
  FilterTableContainer,
  NumberFormatter,
  DateRangePicker,
} from "../Components";
import { TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import "./Admin.scss";
import { CURRENT_WEEK_START } from "../LinearBuying/linearBuyingConstants";

export const DATE_FORMAT = "yyyy-MM-dd";
export const CUTOFF_DATE_PAST = R.pipe(
  Dfns.parseISO,
  Dfns.subMonths(6),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);
export const CUTOFF_DATE_FUTURE = R.pipe(
  Dfns.parseISO,
  Dfns.subDays(7),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);

const Admin = () => {
  const setError = useSetError();
  const [start, setStart] = useState(
    R.pipe(Dfns.parseISO, Dfns.subMonths(6), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [end, setEnd] = useState(
    R.pipe(Dfns.parseISO, Dfns.subDays(7), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [unfilteredLogSummaries, setUnfilteredLogSummaries] = useState();
  const [showUpdateTraffic, setShowUpdateTraffic] = useState(false);
  const [working, setWorking] = useState();
  const [logSummaries, setLogSummaries] = useState();

  const addConfirmedDNRs = useMemo(() => {
    if (!unfilteredLogSummaries) {
      return [];
    }

    return unfilteredLogSummaries.linearReconciliationStatus
      .filter(row => row.status === "DNR")
      .map(row => ({
        week: row.week,
        company: row.company,
        network: row.network,
        avail: row.avail,
      }));
  }, [unfilteredLogSummaries]);

  //lambda pull
  useEffect(() => {
    if (!unfilteredLogSummaries) {
      (async () => {
        let result = await LinearLambdaFetch("/log_summaries");
        result = await awaitJSON(result);
        setUnfilteredLogSummaries(result);
      })();
    }
  }, [unfilteredLogSummaries]);

  useEffect(() => {
    if (start && end && unfilteredLogSummaries) {
      unfilteredLogSummaries.confirmedDNRs = addConfirmedDNRs;
      const filtered = R.map(
        R.filter(table => {
          let tableDate = new Date(table.week);
          return tableDate <= new Date(end) && new Date(table.week) >= new Date(start);
        })
      );
      setLogSummaries(filtered(unfilteredLogSummaries));
    }
  }, [start, end, unfilteredLogSummaries, addConfirmedDNRs]);
  const updateTraffic = useCallback(async () => {
    try {
      await LinearBuyingLambdaFetch("/update_isci_destinations", {
        method: "POST",
        body: {},
      });
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
  }, [setError]);

  const clearSheets = useCallback(async () => {
    setWorking(true);
    try {
      await SheetsLambdaFetch("/clear", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {},
      });
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setWorking();
  }, [setError]);

  const updateTypes = table => {
    Object.keys(table).forEach(key => {
      const row = table[key];
      if (row.type && TYPES_TO_NAMES[row.type]) {
        row.type = TYPES_TO_NAMES[`${row.type}`];
      }
    });
  };
  const downloadLinearTapesReport = useCallback(async () => {
    setWorking(true);
    try {
      const result = await LinearBuyingLambdaFetch("/missing_linear_traffic");
      const { uuid } = await awaitJSON(result);
      await pollS3({
        bucket: "bpm-cache",
        mimeType: "text/csv",
        filename: `${uuid}.csv`,
        overloadFilename: "linear_traffic.csv",
      });
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setWorking();
  }, [setError]);

  const clearCompaniesAndUsers = useCallback(async () => {
    setWorking(true);
    try {
      await UsersLambdaFetch("/clear", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {},
      });
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setWorking();
  }, [setError]);

  const UpdateTrafficConfirmation = ({ show }) => {
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowUpdateTraffic(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Extreme Reach Traffic information?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will take several minutes to complete.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={async () => {
              setShowUpdateTraffic(false);
              await updateTraffic();
            }}
          >
            Start
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const downloadAllTables = (data, fileName) => {
    if (!(data && fileName)) {
      console.error("Must provide data and fileName to download JSON to CSV");
      return;
    }
    Object.keys(data).forEach(table => {
      updateTypes(Object.values(data[table]));
      downloadJSONToCSV(data[table], `${table}`);
    });
  };

  const updateReconcileStatus = useCallback(
    async row => {
      setLogSummaries({
        ...logSummaries,
        potentiallyMissing: R.map(missingRow => {
          if (
            row.week === missingRow.week &&
            row.company === missingRow.company &&
            row.network === missingRow.network &&
            row.avail === missingRow.avail
          ) {
            return row;
          } else {
            return missingRow;
          }
        }, logSummaries.potentiallyMissing),
      });
      await LinearLambdaFetch("/linear_reconciliation", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: [row],
      });
    },
    [logSummaries]
  );

  return (
    <Page
      title="Admin Functions"
      pageType="Admin Functions"
      minHeight={600}
      actions={
        <div className="adminActions">
          <Form.Group>
            <DateRangePicker
              isOutsideRange={date => date < CUTOFF_DATE_PAST || date > CUTOFF_DATE_FUTURE}
              startDate={start}
              endDate={end}
              startDateId="tableDataStartDate"
              endDateId="tableDataStartDate"
              mondayOnly={true}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStart(startDate);
                  setEnd(endDate);
                }
              }}
            />
          </Form.Group>
        </div>
      }
    >
      <div className="adminPageContainer">
        <div className="adminPageButtons">
          <Button onClick={() => setShowUpdateTraffic(true)}>
            {working ? <Spinner /> : <span>Update Traffic from Extreme Reach</span>}
          </Button>
          <Button onClick={() => downloadLinearTapesReport()}>
            {working ? <Spinner /> : <span>Download Linear Tapes Report</span>}
          </Button>
          <Button onClick={() => clearSheets()}>
            {working ? <Spinner /> : <span>Clear Sheets</span>}
          </Button>
          <Button onClick={() => clearCompaniesAndUsers()}>
            {working ? <Spinner /> : <span>Clear Companies and Users</span>}
          </Button>
          <Button onClick={() => downloadAllTables(logSummaries, "allTables")}>
            {working ? <Spinner /> : <span>Download All Reports</span>}
          </Button>
          <UpdateTrafficConfirmation
            show={showUpdateTraffic}
            setShowUpdateTraffic={setShowUpdateTraffic}
          />
        </div>
        <div className="adminPageTables">
          {logSummaries ? (
            <div>
              <div className="adminPageTableRow">
                <FilterTableContainer
                  smallRows
                  data={logSummaries.duplicates}
                  headers={[
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 0,
                      sortAscending: true,
                      flex: 1,
                    },
                    { label: "Week", name: "week", sortPriority: 1, sortAscending: true, flex: 1 },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Avail",
                      name: "avail",
                      sortPriority: 3,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Type",
                      name: "type",
                      sortPriority: 4,
                      sortAscending: true,
                      flex: 1,
                      renderer: row => TYPES_TO_NAMES[`${row.type}`],
                    },
                    { label: "Count", name: "count" },
                  ]}
                  title="Duplicate post-logs"
                />
                <FilterTableContainer
                  smallRows
                  data={logSummaries.unaccepted}
                  headers={[
                    {
                      label: "Week",
                      name: "week",
                      sortPriority: 0,
                      sortAscending: false,
                      flex: 1,
                    },
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 1,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Avail",
                      name: "avail",
                      sortPriority: 3,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Type",
                      name: "type",
                      sortPriority: 4,
                      sortAscending: true,
                      flex: 1,
                      renderer: row => TYPES_TO_NAMES[`${row.type}`],
                    },
                    { label: "Unaccepted", name: "unaccepted" },
                  ]}
                  title="Unaccepted linear spots"
                />
              </div>
              <div className="adminPageTableRow">
                <FilterTableContainer
                  smallRows
                  data={logSummaries.potentiallyMissing}
                  headers={[
                    { label: "Week", name: "week", sortPriority: 0, sortAscending: false, flex: 1 },
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 1,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: true,
                      width: 80,
                    },
                    {
                      label: "Avail",
                      name: "avail",
                      sortPriority: 3,
                      sortAscending: true,
                      width: 60,
                    },
                    {
                      label: "Type",
                      name: "type",
                      sortPriority: 4,
                      sortAscending: true,
                      flex: 1,
                      renderer: row => TYPES_TO_NAMES[`${row.type}`],
                    },
                    {
                      label: "Ordered $",
                      name: "plans_cost",
                      renderer: row => <NumberFormatter value={row.plans_cost} type={"$"} />,
                      flex: 1,
                    },
                    {
                      label: "Post $",
                      name: "postlogs_spend",
                      renderer: row => <NumberFormatter value={row.postlogs_spend} type={"$"} />,
                      flex: 1,
                    },
                    {
                      label: "BVS $",
                      name: "bvs_spend",
                      renderer: row => <NumberFormatter value={row.bvs_spend} type={"$"} />,
                      flex: 1,
                    },
                    {
                      label: "Pre $",
                      name: "prelog_spend",
                      renderer: row => <NumberFormatter value={row.prelog_spend} type={"$"} />,
                      flex: 1,
                    },
                    {
                      label: "Status",
                      name: "status",
                      minFlexWidth: 150,
                      renderer: row => {
                        if (row.status === "DNR") {
                          return (
                            <span>
                              <span>
                                DNR (
                                {row.reconciler
                                  .replace("@blisspointmedia.com", "")
                                  .replace("@old.blisspointmedia.com", "")
                                  .replace("@prospectpointmedia.com", "")
                                  .replace("@old.prospectpointmedia.com", "")
                                  .replace("@tinuiti.com", "")}
                                )
                              </span>
                              <Button
                                variant="link"
                                className="paddedButton"
                                onClick={() =>
                                  updateReconcileStatus({ ...row, status: "", reconciler: "me" })
                                }
                              >
                                Undo
                              </Button>
                            </span>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() =>
                                updateReconcileStatus({ ...row, status: "DNR", reconciler: "me" })
                              }
                            >
                              Mark DNR
                            </Button>
                          );
                        }
                      },
                      flex: 1,
                    },
                  ]}
                  title="Possibly Missing Logs (order with no post-logs)"
                />
                <FilterTableContainer
                  smallRows
                  data={logSummaries.overspend}
                  headers={[
                    { label: "Week", name: "week", sortPriority: 0, sortAscending: false, flex: 1 },
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 1,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: true,
                      width: 80,
                    },
                    {
                      label: "Avail",
                      name: "avail",
                      sortPriority: 3,
                      sortAscending: true,
                      width: 60,
                    },
                    {
                      label: "Type",
                      name: "type",
                      sortPriority: 4,
                      sortAscending: true,
                      flex: 1,
                      renderer: row => TYPES_TO_NAMES[`${row.type}`],
                    },
                    {
                      label: "Ordered $",
                      name: "plans_cost",
                      renderer: row => <NumberFormatter value={row.plans_cost} type={"$"} />,
                      flex: 1,
                    },
                    {
                      label: "Post $",
                      name: "postlogs_spend",
                      renderer: row => <NumberFormatter value={row.postlogs_spend} type={"$"} />,
                      flex: 1,
                    },
                  ]}
                  title="Overspend (post-log spend > ordered spend)"
                />
                <FilterTableContainer
                  smallRows
                  data={logSummaries.confirmedDNRs}
                  headers={[
                    {
                      label: "Week",
                      name: "week",
                      sortPriority: 0,
                      sortAscending: false,
                      flex: 1,
                    },
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 1,
                      sortAscending: true,
                      flex: 1,
                    },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: true,
                      width: 80,
                    },
                    {
                      label: "Avail",
                      name: "avail",
                      sortPriority: 3,
                      sortAscending: true,
                      width: 60,
                    },
                  ]}
                  title="Confirmed DNRs Grouped by Week"
                />
              </div>
              <div className="adminPageTableRow">
                <FilterTableContainer
                  smallRows
                  data={logSummaries.prelogsOnly}
                  headers={[
                    { label: "Week", name: "week", sortPriority: 0, sortAscending: false, flex: 1 },
                    {
                      label: "Company",
                      name: "company",
                      sortPriority: 1,
                      sortAscending: false,
                      flex: 1,
                    },
                    {
                      label: "Network",
                      name: "network",
                      sortPriority: 2,
                      sortAscending: false,
                      flex: 1,
                    },
                    {
                      label: "Type",
                      name: "type",
                      sortPriority: 3,
                      sortAscending: true,
                      flex: 1,
                      renderer: row => TYPES_TO_NAMES[`${row.type}`],
                    },
                  ]}
                  title="BVS only for logs (no post-logs)"
                />
              </div>
            </div>
          ) : (
            <Spinner size={100} />
          )}
        </div>
      </div>
    </Page>
  );
};

export default Admin;
