import gql from "graphql-tag";

export const GET_SUMMARY_SLIDE_DATA = gql`
  query GetSummarySlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $dimension: TmrDimensionOptions
    $dateDimension: TmrDimensionOptions
    $retailer: String
  ) {
    tmr {
      byChannel: summary(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          dimension: $dimension
          retailer: $retailer
        }
      ) {
        totalAdvertising {
          spend
          spendChange
          adSales
          adSalesChange
          roas
          roasChange
          acos
          acosChange
          impressions
          impressionsChange
          clicks
          clicksChange
          orders
          ordersChange
          aov
          aovChange
        }
        tableData: totalAdvertisingByDimension {
          dimension {
            ... on TmrChannel {
              channel
            }
          }
          data {
            spend
            clicks
            impressions
            costPerClick
            orders
            adSales
            roas
            acos
            ntbPercent
          }
        }
      }
      byDate: summary(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          dimension: $dateDimension
          retailer: $retailer
        }
      ) {
        lineGraphData: totalAdvertisingByDimension {
          dimension {
            ... on TmrDate {
              date
            }
          }
          data {
            adSales
            acos
            roas
            aov
            orders
          }
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_SLIDE_DATA = gql`
  query GetCampaignSlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
    $sort: TmrMetricField
  ) {
    tmr {
      campaign(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
          sort: $sort
        }
      ) {
        changeData {
          campaign
          spend
          spendChange
          sales
          salesChange
          roas
          roasChange
          acos
          acosChange
        }
        spendVsSales {
          branded {
            name
            spend
            sales
          }
          campaign {
            name
            spend
            sales
          }
          placement {
            name
            spend
            sales
          }
          targeting {
            name
            spend
            sales
          }
        }
      }
    }
  }
`;

export const GET_KEYWORD_SLIDE_DATA = gql`
  query GetKeywordSlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
    $sort: TmrMetricField
  ) {
    tmr {
      keyword(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
          sort: $sort
        }
      ) {
        overview {
          keyword
          impressions
          clicks
          sales
          spend
          convRate
          avgCpc
          roas
          acos
        }
        matchType {
          matchType
          avgCpc
          sales
          spend
          acos
          roas
        }
      }
    }
  }
`;

export const GET_DSP_SLIDE_DATA = gql`
  query GetDspSlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
  ) {
    tmr {
      dsp(input: { companyId: $companyId, profileNames: $profileNames, dateRange: $dateRange }) {
        summary {
          impressions
          impressionChange
          clicks
          clickChange
          dpvRate
          dpvRateChange
          ctr
          ctrChange
          ecpm
          ecpmChange
          roas
          roasChange
          acos
          acosChange
          sales
          saleChange
          spend
          spendChange
        }
        dataByWeek {
          week
          impressions
          clicks
          dpv
          dpvRate
          spend
          sales
          roas
          acos
          ecpm
          ctr
        }
      }
    }
  }
`;

export const GET_PRODUCT_SLIDE_DATA = gql`
  query GetProductSlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
    $sort: TmrMetricField
  ) {
    tmr {
      product(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
          sort: $sort
        }
      ) {
        brandSales {
          brand
          roas
          acos
          sales
          spend
        }
        categorySales {
          productCategory
          sales
        }
        categorySpend {
          productCategory
          spend
        }
        id {
          clicks
          clicksChange
          convRate
          convRateChange
          orders
          ordersChange
          productId
          roas
          roasChange
          acos
          acosChange
          sales
          salesChange
          spend
          spendChange
        }
      }
    }
  }
`;

export const GET_BIGGEST_MOVER_DATA = gql`
  query GetBiggestMoversData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $filterField: TmrMetricField
    $dollarMin: Int
    $retailer: String
  ) {
    tmr {
      biggestMovers(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          filterField: $filterField
          dollarMin: $dollarMin
          retailer: $retailer
        }
      ) {
        bestPerformers {
          campaignName
          cpc
          orders
          ntbSales
          spend
          spendChange
          roas
          roasChange
          sales
          salesChange
          acos
          acosChange
        }
        worstPerformers {
          campaignName
          cpc
          orders
          ntbSales
          spend
          spendChange
          roas
          roasChange
          sales
          salesChange
          acos
          acosChange
        }
      }
    }
  }
`;

export const GET_FREE_FORM_DATA = gql`
  query GetFreeFormData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
    $sort: TmrMetricField
  ) {
    tmr {
      freeForm(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
          sort: $sort
        }
      ) {
        dataByLabel {
          label
          sales
          spend
          roas
          acos
          impressions
          cpc
          convRate
          clicks
        }
        dataByWeek {
          reportWeek
          roas
          acos
          sales {
            label
            value
          }
        }
      }
    }
  }
`;

export const GET_SEASONALITY_DATA = gql`
  query GetSeasonalitySlideData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
  ) {
    tmr {
      seasonality(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
        }
      ) {
        salesByQuarter {
          quarter
          reportDate
          sales
          spend
        }
      }
    }
  }
`;

export const GET_CATEGORY_DATA = gql`
  query GetTmrCategoryData(
    $companyId: String!
    $dateRange: DateRangeInput!
    $profileNames: [String!]
    $retailer: String
    $sort: TmrMetricField
  ) {
    tmr {
      category(
        input: {
          companyId: $companyId
          dateRange: $dateRange
          profileNames: $profileNames
          retailer: $retailer
          sort: $sort
        }
      ) {
        dataByCategory {
          category
          sales
          spend
          roas
          impressions
          cpc
          convRate
          clicks
          acos
        }
        dataByWeek {
          reportWeek
          roas
          acos
          sales {
            label
            value
          }
        }
      }
    }
  }
`;
