import { engineeringUsers } from "./experiment-utils";

export const shouldEnableFlashtalkingAPI = (userEmail: string): boolean => {
  return [
    ...engineeringUsers,
    "alicia.jewell@tinuiti.com>",
    "alisa.knight@tinuiti.com",
    "evan.okeeffe@tinuiti.com",
    "grace.langmuir@tinuiti.com",
    "kevin.kiehm@tinuiti.com",
    "matthew.bilyak@tinuiti.com",
    "melinda.lee@tinuiti.com",
    "nina.grill@tinuiti.com",
    "sarah.butts@tinuiti.com",
  ].includes(userEmail);
};
