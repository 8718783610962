// Going to keep page hidden until we transition to new
// method of creating derived Ids and have list of people
// to review derived ids.
const streamingMonitoringUsers = [
  "berto.garciacarrillo@tinuiti.com",
  "carling.sugarman@tinuiti.com",
];

export const shouldEnableStreamingMonitoring = (userEmail: string): boolean => {
  return streamingMonitoringUsers.includes(userEmail);
};
