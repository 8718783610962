import React, { useEffect, useCallback } from "react";
import "./CustomDatePresets.scss";
import { DateRange, Setter } from "../../../utils/types";
import { SingleDatePreset } from "../SingleDatePrest";
import * as Dfns from "date-fns/fp";
import { DATE_FORMAT } from "../../../utils/data";
import {
  CUSTOM_PRESET_INTERVAL,
  CUSTOM_PRESET_REFERENCE,
  INIT_CUSTOM_PRESET_STATE,
} from "../DatePickerButton/DatePickerConstants";

export interface CustomPresetState {
  number: number | undefined;
  interval: typeof CUSTOM_PRESET_INTERVAL[number];
  before: boolean;
  referenceDate: typeof CUSTOM_PRESET_REFERENCE[number];
}

interface CustomDatePresetsProps {
  startCustomPreset: CustomPresetState;
  setStartCustomPreset: Setter<CustomPresetState | ((c: CustomPresetState) => CustomPresetState)>;
  endCustomPreset: CustomPresetState;
  setEndCustomPreset: Setter<CustomPresetState | ((c: CustomPresetState) => CustomPresetState)>;
  rangeError: "start" | "end" | null;
  setRangeError: Setter<"start" | "end" | null>;
  disabled?: boolean;
  weekDefinition: number;
  isValidInRange: (date: string) => boolean;
  setDateInputs: Setter<Partial<DateRange>>;
}

export const CustomDatePresets: React.FC<CustomDatePresetsProps> = ({
  startCustomPreset,
  setStartCustomPreset,
  endCustomPreset,
  setEndCustomPreset,
  disabled,
  rangeError,
  weekDefinition,
  isValidInRange,
  setDateInputs,
  setRangeError,
}) => {
  const onPresetSelect = useCallback(
    (
      state: CustomPresetState,
      setParentDateInput,
      isStartDate: boolean,
      rangerErrorSetter,
      companionSetter
    ) => {
      if (state.number === undefined || state.number < 0) {
        rangerErrorSetter(null);
        return;
      }
      const now = new Date();
      const yesterdayDate = Dfns.subDays(1, now);

      let dateOfReference;
      switch (state.referenceDate) {
        case "Yesterday":
          dateOfReference = yesterdayDate;
          break;
        case "Today":
          dateOfReference = now;
          break;
        case "Start of This Week":
          const startOfWeek = Dfns.startOfWeekWithOptions({ weekStartsOn: weekDefinition });
          dateOfReference = startOfWeek(now);
          break;
        case "End of This Week":
          const endOfWeek = Dfns.endOfWeekWithOptions({ weekStartsOn: weekDefinition });
          dateOfReference = endOfWeek(now);
          break;
      }

      let intervalFunc;
      switch (state.interval) {
        case "Days":
          intervalFunc = state.before ? Dfns.subDays : Dfns.addDays;
          break;
        case "Weeks":
          intervalFunc = state.before ? Dfns.subWeeks : Dfns.addWeeks;
          break;
        case "Months":
          intervalFunc = state.before ? Dfns.subMonths : Dfns.addMonths;
      }

      if (intervalFunc && dateOfReference) {
        const calculatedTargetDate = Dfns.format(
          DATE_FORMAT,
          intervalFunc(state.number, dateOfReference)
        );

        if (isValidInRange(calculatedTargetDate)) {
          rangerErrorSetter(isStartDate ? "start" : "end");
        } else {
          rangerErrorSetter(null);
          setParentDateInput(p => {
            if (isStartDate) {
              if (calculatedTargetDate > p.end) {
                companionSetter({ ...INIT_CUSTOM_PRESET_STATE });
                return { start: calculatedTargetDate };
              } else {
                return { ...p, start: calculatedTargetDate };
              }
            } else if (calculatedTargetDate < p.start) {
              companionSetter({ ...INIT_CUSTOM_PRESET_STATE });
              return { end: calculatedTargetDate };
            } else {
              return { ...p, end: calculatedTargetDate };
            }
          });
        }
      }
    },
    [weekDefinition, isValidInRange]
  );

  useEffect(() => {
    onPresetSelect(startCustomPreset, setDateInputs, true, setRangeError, setEndCustomPreset);
  }, [startCustomPreset, onPresetSelect, setDateInputs, setEndCustomPreset, setRangeError]);

  useEffect(() => {
    onPresetSelect(endCustomPreset, setDateInputs, false, setRangeError, setStartCustomPreset);
  }, [endCustomPreset, setDateInputs, setRangeError, setStartCustomPreset, onPresetSelect]);

  return (
    <div className={`DPCustomDatePresets ${disabled ? "disabled" : ""}`}>
      <SingleDatePreset
        key={"singleDatePresetCustomStart"}
        label="Start"
        customPresetState={startCustomPreset}
        setCustomPresetState={setStartCustomPreset}
        disabled={disabled}
        rangeError={rangeError === "start"}
      />
      <SingleDatePreset
        key={"singleDatePresetCustomEnd"}
        label="End"
        customPresetState={endCustomPreset}
        setCustomPresetState={setEndCustomPreset}
        disabled={disabled}
        rangeError={rangeError === "end"}
      />
    </div>
  );
};
