import { awaitJSON, SlidesLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { ExecutiveSummaryChart } from "../Components/ExecutiveSummaryChart";
import { Form } from "react-bootstrap";
import { GOOGLE_SLIDE_IMAGE_SCALING_FACTOR } from "./slideFormatConstants";
import { KpiInfo } from "@blisspointmedia/bpm-types/dist/Kpis";
import { KpiPicker, OldDropdown, RelativeDatePicker, BPMToggleButton } from "../Components";
import { Provider } from "react-redux";
import { reduxStore } from "../redux";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as R from "ramda";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

interface SlideExecutiveSummaryChartResult {
  elem: SVGSVGElement | null;
  audioTotal?: number;
  linearTotal?: number;
  streamingTotal?: number;
}

interface SlideExecutiveSummaryChartOnLoad {
  (results: SlideExecutiveSummaryChartResult): void;
}

interface ExecutiveData {
  date: string;
  audioSpend: number;
  linearSpend: number;
  streamingSpend: number;
  audioVolume: number;
  linearVolume: number;
  streamingVolume: number;
  audioCPX: number;
  linearCPX: number;
  streamingCPX: number;
  audioROAS: number;
  linearROAS: number;
  streamingROAS: number;
}

interface SlideExecutiveSummaryChartProps {
  executiveSummaryChartType: string;
  executiveSummaryData: ExecutiveData[];
  executiveSummaryDataKeys: string[];
  onLoad: SlideExecutiveSummaryChartOnLoad;
}

const CHART_WIDTH = 197.6 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // Scaling factor used to make a
const CHART_HEIGHT = 108.9 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // 197.6px by 108.9px sized image
const DEFAULT_LINEAR_COLOR = "#737AFF";
const DEFAULT_STREAMING_COLOR = "#4CCE99";
const DEFAULT_AUDIO_COLOR = "#009FDF";
const CHARTS = ["SPEND", "KPI", "CPX", "ROAS"];
const impCountTypes = ["Imp's Served Between", "Response Between"] as const;
const includeROASTypes = ["Include", "Exclude"] as const;
export const executiveSummarySharedStateKey = "executiveSummary" as const;
export const CHART_ASPECT_RATIO = CHART_WIDTH / CHART_HEIGHT;

const SlideExecutiveSummaryChart: React.FC<SlideExecutiveSummaryChartProps> = ({
  executiveSummaryChartType,
  executiveSummaryData,
  executiveSummaryDataKeys,
  onLoad,
}) => {
  const [pathLoaded, setPathLoaded] = useState(false);
  const [clear, setClear] = useState(false);
  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    if (clear) {
      mapSvgRef.current = null;
      setClear(false);
    } else if (executiveSummaryData.length > 1 && pathLoaded) {
      const data = executiveSummaryData.slice(0, -1);
      let onLoadInput = {
        elem: mapSvgRef.current,
      };
      for (let key of executiveSummaryDataKeys) {
        if (key.includes("audio")) {
          onLoadInput = R.mergeLeft(onLoadInput, {
            audioTotal: data[data.length - 1][key],
          });
        } else if (key.includes("linear")) {
          onLoadInput = R.mergeLeft(onLoadInput, {
            linearTotal: data[data.length - 1][key],
          });
        } else if (key.includes("streaming")) {
          onLoadInput = R.mergeLeft(onLoadInput, {
            streamingTotal: data[data.length - 1][key],
          });
        }
      }
      onLoad(onLoadInput);
      setClear(true);
    }
  }, [clear, executiveSummaryData, executiveSummaryDataKeys, onLoad, pathLoaded]);

  if (executiveSummaryData === null) {
    return null;
  } else {
    const colors: string[] = [];
    for (let key of executiveSummaryDataKeys) {
      if (key.includes("audio")) {
        colors.push(DEFAULT_AUDIO_COLOR);
      } else if (key.includes("linear")) {
        colors.push(DEFAULT_LINEAR_COLOR);
      } else if (key.includes("streaming")) {
        colors.push(DEFAULT_STREAMING_COLOR);
      }
    }
    return (
      <ExecutiveSummaryChart
        chartData={executiveSummaryData.slice(0, -1)}
        chartType={executiveSummaryChartType}
        color={colors}
        dataKeys={executiveSummaryDataKeys}
        height={CHART_WIDTH / CHART_ASPECT_RATIO}
        onPathLoad={setPathLoaded}
        ref={mapSvgRef}
        width={CHART_WIDTH}
      />
    );
  }
};

interface ColorMap {
  audio?: string;
  linear?: string;
  streaming?: string;
}

export interface ExecutiveChartLambdaInputProperties {
  audioTotal: number;
  linearTotal: number;
  streamingTotal: number;
  colorMap: ColorMap;
  chartURL: string;
}

interface ExecutiveSummarySlideData {
  start?: string;
  end?: string;
  executiveSummaryMap: Record<string, ExecutiveChartLambdaInputProperties>;
  kpi: string;
}

export interface ExecutiveSummarySlideState extends ExecutiveSummarySlideData {
  dates: RelativeDateRange;
  kpis: KpiInfo[];
  impressionCountSelector: string;
  lag: string;
  includeROAS: boolean;
}

export type ExecutiveSummarySharedState = string[];
export const executiveSummarySharedFetcher: SharedStateFetcher<ExecutiveSummarySharedState> = async (
  company: string,
  setError: SetError
) => {
  try {
    const res = await SlidesLambdaFetch("/getExecutiveSummaryCompanies", {
      params: { company },
    });
    const executiveSummaryCompanies: ExecutiveSummarySharedState = await awaitJSON(res);
    return executiveSummaryCompanies;
  } catch (e) {
    const error: Error = e as Error;
    setError({
      message: `Could not get valid executiveSummary companies. Error: ${error.message}`,
      reportError: error,
    });
  }
  return [];
};

class ExecutiveSummarySlide extends SlideType {
  static typeKey = "executiveSummary";
  static displayKey = "Executive Summary";
  static defaultState: ExecutiveSummarySlideState = {
    executiveSummaryMap: {},
    kpis: [],
    dates: {
      start: { pivotDate: "monday", adjustment: 90, adjustmentType: "day" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    kpi: "",
    impressionCountSelector: "Imp's Served Between",
    lag: "",
    includeROAS: false,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<ExecutiveSummarySlideState>
  > = React.memo(({ state, setState }) => {
    const { dates, impressionCountSelector, kpi, lag, includeROAS } = state;
    const {
      streaming_performance_default_kpi,
      streaming_performance_default_lag,
      kpis,
    } = useCompanyInfo();

    useEffect(() => {
      if (!kpi) {
        setState({ kpi: streaming_performance_default_kpi });
      }
    }, [kpi, streaming_performance_default_kpi, setState]);

    useEffect(() => {
      if (!lag) {
        setState({ lag: streaming_performance_default_lag });
      }
    }, [lag, streaming_performance_default_lag, setState]);

    useEffect(() => {
      setState({ kpis });
    }, [kpis, setState]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <div className="kpiSelector">
            <KpiPicker kpi={kpi} onChange={kpi => setState({ kpi })} />
          </div>
          <div>
            <BPMToggleButton
              size="sm"
              block
              bordered
              options={impCountTypes}
              selectedOption={impressionCountSelector}
              onChange={impressionCountSelector => setState({ impressionCountSelector })}
            />
          </div>
          <Form.Group>
            <div className="lagSelector">
              Lag
              <OldDropdown
                value={lag}
                options={[
                  { label: "1d", value: "1d" },
                  { label: "3d", value: "3d" },
                  { label: "7d", value: "7d" },
                  { label: "14d", value: "14d" },
                  { label: "30d", value: "30d" },
                ]}
                onChange={lag => setState({ lag })}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Start</Form.Label>
            <RelativeDatePicker
              state={dates.start}
              onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
            />
            <Form.Label>End</Form.Label>
            <RelativeDatePicker
              state={dates.end}
              onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
            />
          </Form.Group>
          <Form.Group>
            <div>
              Include ROAS Chart?
              <BPMToggleButton
                size="sm"
                block
                bordered
                options={includeROASTypes}
                selectedOption={includeROAS ? includeROASTypes[0] : includeROASTypes[1]}
                onChange={includeROASType =>
                  setState({ includeROAS: includeROASType === "Include" })
                }
              />
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<ExecutiveSummarySlideData> => {
    const {
      kpi,
      dates,
      kpis,
      impressionCountSelector,
      lag,
      includeROAS,
    } = state as ExecutiveSummarySlideState;
    const start = computeResolvedDate(dates.start);
    const end = computeResolvedDate(dates.end);
    let executiveSummaryMap = {};
    let kpiString;
    let charts;
    if (!includeROAS) {
      charts = R.filter(elem => elem !== "ROAS", CHARTS);
    } else {
      charts = CHARTS;
    }
    let execData;
    if (kpi) {
      try {
        const res = await SlidesLambdaFetch("/getExecutiveSummaryData", {
          params: {
            kpi,
            start,
            end,
            lag,
            impressionCountSelector,
          },
        });
        execData = await awaitJSON(res);
      } catch (e) {
        const error = e as Error;
        throw new Error(`Failed to fetch info for kpi "${kpi}". ${error.message}`);
      }
    } else {
      throw new Error("No kpi selected for Executive Summary Slide.");
    }

    if (execData.length < 2) {
      throw new Error(
        `Not enough data for Executive Summary Slide for dates selected: start=${start}, end=${end}.`
      );
    }

    for (const chart of charts) {
      let dataKeysMap: Record<string, boolean> = {};
      let dataKeys: string[] = [];
      for (let elem of execData) {
        // If we have seen a value for every data key, we can stop looking
        if (R.keys(dataKeysMap).length === 3) {
          break;
        }
        if (chart === "SPEND") {
          if (elem.audioSpend > 0) {
            dataKeysMap.audioSpend = true;
          }
          if (elem.linearSpend > 0) {
            dataKeysMap.linearSpend = true;
          }
          if (elem.streamingSpend > 0) {
            dataKeysMap.streamingSpend = true;
          }
        } else if (chart === "KPI") {
          if (elem.audioVolume > 0) {
            dataKeysMap.audioVolume = true;
          }
          if (elem.linearVolume > 0) {
            dataKeysMap.linearVolume = true;
          }
          if (elem.streamingVolume > 0) {
            dataKeysMap.streamingVolume = true;
          }
        } else if (chart === "CPX") {
          if (elem.audioCPX > 0) {
            dataKeysMap.audioCPX = true;
          }
          if (elem.linearCPX > 0) {
            dataKeysMap.linearCPX = true;
          }
          if (elem.streamingCPX > 0) {
            dataKeysMap.streamingCPX = true;
          }
        } else if (chart === "ROAS") {
          if (elem.audioROAS > 0) {
            dataKeysMap.audioROAS = true;
          }
          if (elem.linearROAS > 0) {
            dataKeysMap.linearROAS = true;
          }
          if (elem.streamingROAS > 0) {
            dataKeysMap.streamingROAS = true;
          }
        }
      }
      dataKeys = R.keys(dataKeysMap);

      const sandbox = await claimSandbox();
      const {
        svgString,
        audioTotal,
        linearTotal,
        streamingTotal,
      }: Omit<SlideExecutiveSummaryChartResult, "elem"> & { svgString: string } = await new Promise(
        (resolve, reject) => {
          try {
            const load = () => {
              ReactDOM.render(
                <Provider store={reduxStore}>
                  <SlideExecutiveSummaryChart
                    executiveSummaryChartType={chart}
                    executiveSummaryData={execData}
                    executiveSummaryDataKeys={dataKeys}
                    onLoad={({ elem, audioTotal, linearTotal, streamingTotal }) => {
                      if (elem) {
                        const boundingBox = elem.getBoundingClientRect();
                        const svgString = convertSVGStringToPNGURI(
                          elem.outerHTML,
                          boundingBox.width,
                          boundingBox.height
                        );
                        if (sandbox.element) {
                          ReactDOM.unmountComponentAtNode(sandbox.element);
                        }
                        resolve({
                          svgString,
                          audioTotal,
                          linearTotal,
                          streamingTotal,
                        });
                      }
                    }}
                  />
                </Provider>,
                sandbox.element
              );
            };
            load();
          } catch (e) {
            reject(e);
          }
        }
      );

      releaseSandbox(sandbox);

      let colorMap: ColorMap = {};
      if (typeof audioTotal !== "undefined") {
        colorMap.audio = DEFAULT_AUDIO_COLOR;
      }
      if (typeof linearTotal !== "undefined") {
        colorMap.linear = DEFAULT_LINEAR_COLOR;
      }
      if (typeof streamingTotal !== "undefined") {
        colorMap.streaming = DEFAULT_STREAMING_COLOR;
      }

      const chartURL = await addS3Image(svgString);
      if (R.keys(colorMap).length > 0) {
        executiveSummaryMap[chart] = {
          audioTotal,
          linearTotal,
          streamingTotal,
          colorMap,
          chartURL,
        };
      }
    }

    for (const kpiInfo of kpis) {
      if (kpiInfo.id === kpi) {
        kpiString = kpiInfo.name;
      }
    }

    return {
      start,
      end,
      executiveSummaryMap,
      kpi: kpiString,
    };
  };
}

export default ExecutiveSummarySlide;
