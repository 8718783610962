import React from "react";

import "./Tooltip.scss";

interface TooltipProps {
  children: React.ReactNode;
  coords?: {
    x: number;
    y: number;
  };
  horizontalShift?: boolean;
  verticalShift?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  coords,
  horizontalShift,
  verticalShift,
  children,
}) => {
  return (
    <div
      className="bpmTooltip"
      style={{
        position: coords ? "fixed" : "static",
        left: coords ? `${coords.x}px` : "auto",
        top: coords ? `${coords.y}px` : "auto",
        transform: `translateX(${horizontalShift ? -100 : 0}%) translateY(${
          verticalShift ? -100 : 0
        }%)`,
      }}
    >
      {children}
    </div>
  );
};
