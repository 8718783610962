import React, { useState, useContext, useEffect } from "react";
import { Button, ButtonType, OverlayTrigger, ToggleSwitch, FullPageSpinner } from "../Components";
import WtoRuleCard from "./WtoRuleCard";
import WtoRulesFormModal from "./WtoRulesFormModal";
import { Tooltip } from "react-bootstrap";
import { MdContentCopy } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import { FormContext, defaultRule, formObjDefault } from "./WtoFormContext";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { formValidator } from "./WtoFormValidator";
import WtoValidationErrors from "./WtoValidationErrors";
import { WtoLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import WtoDuplicateRuleModal from "./WtoDuplicateRuleModal";
import WtoCancelModal from "./WtoCancelModal";
import {
  isSavedDraft,
  buildPloverUrl,
  trimBackslashes,
  formatRules,
  formatDefaultId,
} from "./WtoUtils";
import * as R from "ramda";
import "./WtoRules.scss";

export interface WtoRulesProps {
  useCopyPlover: () => { tooltipText; setTooltipText; copyPloverUrl };
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
  readOnly: boolean;
  setReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
  formEdited: boolean;
  showCancelConfirmation: boolean;
  setShowCancelConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  userEmail: string;
  fetchPlovers: () => void;
  companyId: string;
  setModalData: (modalType: string, data: Record<string, any>) => void;
  plovers: Record<string, any>[];
}

export const WtoRules: React.FC<WtoRulesProps> = ({
  useCopyPlover,
  setBreadcrumbPath,
  readOnly,
  setReadOnly,
  formEdited,
  showCancelConfirmation,
  setShowCancelConfirmation,
  userEmail,
  fetchPlovers,
  companyId,
  setModalData,
  plovers,
}) => {
  const [showRulesModal, setShowRulesModal] = useState<boolean>(false);
  const [reorderEnabled, setreorderEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const { wtoFormObj, setWtoFormObj, setNewRule } = useContext(FormContext);
  const { tooltipText, setTooltipText, copyPloverUrl } = useCopyPlover();
  const [duplicateRuleIndex, setDuplicateRuleIndex] = useState<number>(-1);
  const [showRuleCards, setShowRuleCards] = useState<boolean[]>(
    wtoFormObj.rules ? wtoFormObj.rules.map(() => true) : []
  );
  const [newRuleIndex, setNewRuleIndex] = useState<number | null>(null);
  const setError = useSetError();

  useEffect(() => {
    if (R.isEmpty(wtoFormObj.default_id) || R.isEmpty(wtoFormObj.default_url)) {
      setBreadcrumbPath(["Route Details"]);
    }
  }, [wtoFormObj, setBreadcrumbPath]);

  useEffect(() => {
    if (wtoFormObj.status.toLocaleLowerCase() === "draft" || wtoFormObj.status === "") {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [wtoFormObj, setReadOnly]);

  useEffect(() => {
    if (!showRulesModal) {
      setNewRuleIndex(null);
    }
  }, [showRulesModal]);

  let ploverUrl = buildPloverUrl(wtoFormObj.domain, wtoFormObj.folder_path, wtoFormObj.plover_url);

  const confirmCancel = () => {
    setWtoFormObj({ ...formObjDefault });
    setShowCancelConfirmation(false);
    setBreadcrumbPath([]);
  };

  const onCancelClick = () => {
    if ((!readOnly && formEdited) || (readOnly && splitsEdited())) {
      setShowCancelConfirmation(true);
    } else {
      confirmCancel();
    }
  };

  // determine if the variant splits have been edited for a live route
  const splitsEdited = () => {
    let edited = false;
    const savedPlover = R.find(R.propEq("id", wtoFormObj.id), plovers);
    if (savedPlover && savedPlover.status.toLocaleLowerCase() === "production") {
      wtoFormObj.rules.forEach((rule, ruleIndex) => {
        const oldRule = savedPlover.rules[ruleIndex];
        rule.variant_splits.forEach((variant, variantIndex) => {
          const oldSplit = oldRule.variant_splits[variantIndex].split;
          if (oldSplit !== variant.split) {
            edited = true;
          }
        });
      });
    }
    return edited;
  };

  const ruleModal = (index?: number) => {
    if (R.isNil(index)) {
      setNewRule({
        ...R.clone(defaultRule),
        rule_order: wtoFormObj.rules.length.toString(),
      });
    } else {
      setNewRuleIndex(index);
      setNewRule(wtoFormObj.rules[index]);
    }
    setShowRulesModal(true);
  };

  const hideCard = index => {
    let cardsShown = [...showRuleCards];
    cardsShown[index] = !cardsShown[index];
    setShowRuleCards(cardsShown);
  };

  const saveAsDraft = async () => {
    setLoading(true);
    try {
      if (isSavedDraft(wtoFormObj)) {
        // update route draft if it already exists
        await WtoLambdaFetch(`/plover/${wtoFormObj.id}`, {
          method: "PATCH",
          params: {
            email: userEmail,
          },
          body: {
            company: companyId,
            test_name: wtoFormObj.test_name,
            utm_test_id: wtoFormObj.utm_test_id,
            default_url: wtoFormObj.default_url,
            default_id: formatDefaultId(wtoFormObj.default_id),
            plover_url: wtoFormObj.plover_url,
            folder_path: trimBackslashes(wtoFormObj.folder_path),
            notes: wtoFormObj.notes,
            variants: wtoFormObj.variants,
            domain: wtoFormObj.domain,
            rules: formatRules(wtoFormObj.rules),
            status: "draft",
          },
        });
      } else {
        // route draft does not exist, so create it
        await WtoLambdaFetch("/plover", {
          method: "POST",
          params: {
            email: userEmail,
          },
          body: {
            company: companyId,
            test_name: wtoFormObj.test_name,
            utm_test_id: wtoFormObj.utm_test_id,
            default_url: wtoFormObj.default_url,
            default_id: formatDefaultId(wtoFormObj.default_id),
            plover_url: wtoFormObj.plover_url,
            folder_path: trimBackslashes(wtoFormObj.folder_path),
            notes: wtoFormObj.notes,
            variants: wtoFormObj.variants,
            domain: wtoFormObj.domain,
            rules: formatRules(wtoFormObj.rules),
            status: "draft",
          },
        });
      }
      fetchPlovers();
      setBreadcrumbPath([]);
      setShowCancelConfirmation(false);
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to submit route. Error: ${error.message}`,
        reportError: error,
      });
    }
    setLoading(false);
  };

  const validateForm = (isDraft?: boolean) => {
    const errors = formValidator(
      wtoFormObj,
      [
        "test_name",
        "utm_test_id",
        "variants",
        "default_id",
        "default_url",
        "plover_url",
        "folder_path",
        "domain",
        "rules-maximum",
        "rules-minimum",
      ],
      {
        isBulkPlover: false,
      }
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
    } else if (isDraft) {
      saveAsDraft();
    } else {
      formatRules(wtoFormObj.rules);
      setModalData(splitsEdited() ? "Update Route" : "Create Route", wtoFormObj);
    }
  };

  return (
    <div className="wtoRulesContainer">
      <div className="wtoRules">
        <div className="description">
          <p>
            Set up rules below to direct traffic to your variants. Rules will be executed in
            top-down order, first rule that matches a condition will redirect the user. If no rules
            were matched, then user will be redirected to the fallback variant. To change Rule
            Execution Order, toggle the "Reorder" switch below.
          </p>
          <div className="ploverUrlWrapper">
            <p>
              Route URL: <span className="ploverUrl">{ploverUrl}</span>
            </p>
            <OverlayTrigger
              placement="bottom center"
              onExit={() => setTooltipText("Copy URL to Clipboard")}
              overlay={<Tooltip id="id:copy plover">{tooltipText}</Tooltip>}
            >
              <div className="copyUrlIcon" onClick={e => copyPloverUrl(e, ploverUrl)}>
                <MdContentCopy />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        {loading ? (
          <FullPageSpinner />
        ) : (
          <div className="rulesEditor">
            <div className="rulesHeader">
              <h3>Rules</h3>
              {!R.isEmpty(wtoFormObj.rules) && (
                <div className="rulesToolbar">
                  <ToggleSwitch
                    className="reorderToggle"
                    label="Reorder"
                    checked={reorderEnabled}
                    onChange={() => setreorderEnabled(!reorderEnabled)}
                    disabled={readOnly}
                  />
                  <Button
                    type={ButtonType.FILLED}
                    label="Add Rule"
                    icon={<HiOutlinePlus />}
                    onClick={() => {
                      setValidationErrors([]);
                      ruleModal();
                    }}
                    variant={ButtonFrameworkVariant.LEADING_ICON}
                    disabled={readOnly}
                  >
                    Add Rule
                  </Button>
                </div>
              )}
            </div>
            <WtoValidationErrors validationErrors={validationErrors} />
            {R.isEmpty(wtoFormObj.rules) ? (
              <div className="rulePrompt">
                Get started by adding your first rule!
                <Button
                  type={ButtonType.FILLED}
                  label="Add Rule"
                  icon={<HiOutlinePlus />}
                  onClick={() => {
                    setValidationErrors([]);
                    ruleModal();
                  }}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  disabled={readOnly}
                >
                  Add Rule
                </Button>
              </div>
            ) : (
              wtoFormObj.rules.map((data, index) => {
                return (
                  <WtoRuleCard
                    key={index}
                    data={data}
                    index={index}
                    show={showRuleCards[index]}
                    hideCard={hideCard}
                    ruleModal={ruleModal}
                    reorderEnabled={reorderEnabled}
                    setDuplicateRuleIndex={setDuplicateRuleIndex}
                    showRuleCards={showRuleCards}
                    setShowRuleCards={setShowRuleCards}
                  />
                );
              })
            )}
            <div className="ruleCard default">
              <span className="ruleName">Default Rule</span>
              <hr />
              <div className="ruleSpecBody ruleSpecRow plover">
                <div className="ruleSpecCol plover">
                  {wtoFormObj.default_id}
                  <span className="lightText">{wtoFormObj.default_url}</span>
                </div>
                <div className="ploverPercentage">100%</div>
              </div>
            </div>
          </div>
        )}
        {showRulesModal && (
          <WtoRulesFormModal
            show={showRulesModal}
            closeModal={() => setShowRulesModal(false)}
            readOnly={readOnly}
            setBreadcrumbPath={setBreadcrumbPath}
            ruleIndex={newRuleIndex}
            showRuleCards={showRuleCards}
            setShowRuleCards={setShowRuleCards}
          />
        )}
        <div className="rulesButtons">
          <Button type={ButtonType.FILLED} onClick={onCancelClick}>
            Cancel
          </Button>
          <Button
            type={ButtonType.FILLED}
            onClick={(_: any) => validateForm(true)}
            disabled={readOnly}
          >
            Save as Draft
          </Button>
          <Button
            type={ButtonType.FILLED}
            onClick={(_: any) => validateForm(false)}
            disabled={readOnly && !splitsEdited()}
          >
            {wtoFormObj.status.toLocaleLowerCase() !== "draft" ? "Update Route" : "Create Route"}
          </Button>
        </div>
      </div>
      <WtoDuplicateRuleModal
        show={duplicateRuleIndex > -1}
        closeModal={() => setDuplicateRuleIndex(-1)}
        index={duplicateRuleIndex}
        showRuleCards={showRuleCards}
        setShowRuleCards={setShowRuleCards}
      />
      <WtoCancelModal
        show={showCancelConfirmation}
        closeModal={() => setShowCancelConfirmation(false)}
        confirmCancel={confirmCancel}
      />
    </div>
  );
};

export default WtoRules;
