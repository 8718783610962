import { CellData, DimensionMap } from "../SingleChannel/MetricsTable/metricsTableUtils";
import { Column, DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { CompanyInfo } from "../redux/company";
import { CreativeMap, CreativeMapItem } from "../redux/creative";
import { DerivedNetworkMap } from "../redux/networks";
import { MetricsPagePreset } from "@blisspointmedia/bpm-types/dist/MetricsPage";
import { MiscLambdaFetch, SingleChannelLambdaFetch, awaitJSON, pollS3 } from "../utils/fetch-utils";
import { overrideDateRange } from "../utils/test-account-utils";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import {
  columnMetaDataMap as STR_COLUMN_METADATA_MAP,
  dimensionColumnMetaDataMap as STR_DIMENSION_COLUMN_METADATA_MAP,
  getDimensionCell as STR_GET_DIMENSION_CELL,
} from "../SingleChannel/Channels/streamingPerformanceUtils";
import {
  columnMetaDataMap as LIN_COLUMN_METADATA_MAP,
  dimensionColumnMetaDataMap as LIN_DIMENSION_COLUMN_METADATA_MAP,
  getDimensionCell as LIN_GET_DIMENSION_CELL,
} from "../SingleChannel/Channels/linearPerformanceUtils";
import {
  columnMetaDataMap as YT_COLUMN_METADATA_MAP,
  dimensionColumnMetaDataMap as YT_DIMENSION_COLUMN_METADATA_MAP,
  getDimensionCell as YT_GET_DIMENSION_CELL,
} from "../SingleChannel/Channels/youtubePerformanceUtils";

import {
  columnMetaDataMap as SO_COLUMN_METADATA_MAP,
  dimensionColumnMetaDataMap as SO_DIMENSION_COLUMN_METADATA_MAP,
  getDimensionCell as SO_GET_DIMENSION_CELL,
} from "../SingleChannel/Channels/socialPerformanceUtils";

const getCometLoadedMetricsTableData = async (params: any, lambdaName: string) => {
  const result = await MiscLambdaFetch("/kickOffLambda", {
    method: "POST",
    body: {
      fileType: "txt",
      lambdaArgs: { ...params },
      lambdaName,
    },
  });
  const uuid = await awaitJSON(result);
  const content = await pollS3({
    autoDownload: false,
    bucket: "bpm-cache",
    filename: `${uuid}.txt`,
    mimeType: "text/plain",
    period: 3000,
  });
  const textContent = await content.text();
  return JSON.parse(textContent) as S.GetPageDataResponse;
};
const getSocialMetricsTableData = async (params: any) => {
  const dateRangeToUse = overrideDateRange(params.company, params.dates);
  const res = await await SingleChannelLambdaFetch<S.GetPageDataParams>(
    "/getChannelMetricsTableData",
    {
      params: {
        ...params,
        ...(dateRangeToUse || {}),
        channel: "social",
      },
    }
  );
  return await awaitJSON<S.GetPageDataResponse>(res);
};

export const getSingleChannelMetricsTableData: Record<string, (params: any) => Promise<any>> = {
  audio: params => getCometLoadedMetricsTableData(params, "streamingPerformance-getPageData"),
  display: params => getCometLoadedMetricsTableData(params, "streamingPerformance-getPageData"),
  linear: params => getCometLoadedMetricsTableData(params, "linearPerformance-getPageData"),
  streaming: (params: any) =>
    getCometLoadedMetricsTableData(params, "streamingPerformance-getPageData"),
  social: (params: any) => getSocialMetricsTableData(params),
  youtube: params => getCometLoadedMetricsTableData(params, "youtubePerformance-getPageData"),
};

export const getSingleChannelMetricsTableColumnMetaDataMap = {
  audio: {
    ...STR_DIMENSION_COLUMN_METADATA_MAP,
    ...STR_COLUMN_METADATA_MAP,
  },
  linear: {
    ...LIN_DIMENSION_COLUMN_METADATA_MAP,
    ...LIN_COLUMN_METADATA_MAP,
  },
  display: {
    ...STR_DIMENSION_COLUMN_METADATA_MAP,
    ...STR_COLUMN_METADATA_MAP,
  },
  social: {
    ...SO_DIMENSION_COLUMN_METADATA_MAP,
    ...SO_COLUMN_METADATA_MAP,
  },
  streaming: {
    ...STR_DIMENSION_COLUMN_METADATA_MAP,
    ...STR_COLUMN_METADATA_MAP,
  },
  youtube: {
    ...YT_DIMENSION_COLUMN_METADATA_MAP,
    ...YT_COLUMN_METADATA_MAP,
  },
};

export const getSingleChannelMetricsTableDimensionCellMap = (
  creativeMap: CreativeMap,
  creativeNameMap: Record<string, CreativeMapItem>,
  derivedNetworkMap: DerivedNetworkMap
): Record<
  string,
  (
    dimensionData: DimensionMap,
    dimensionHeader: DimensionColumn,
    ...args // Can pass in creative map, network map etc.
  ) => CellData
> => ({
  audio: (dimensionData, dimensionHeader) =>
    STR_GET_DIMENSION_CELL(
      dimensionData,
      dimensionHeader,
      { ...creativeNameMap, ...creativeMap },
      derivedNetworkMap
    ),
  display: (dimensionData, dimensionHeader) =>
    STR_GET_DIMENSION_CELL(
      dimensionData,
      dimensionHeader,
      { ...creativeNameMap, ...creativeMap },
      derivedNetworkMap
    ),
  linear: (dimensionData, dimensionHeader) =>
    LIN_GET_DIMENSION_CELL(dimensionData, dimensionHeader, {
      ...creativeNameMap,
      ...creativeMap,
    }),
  social: (dimensionData, dimensionHeader) =>
    SO_GET_DIMENSION_CELL(dimensionData, dimensionHeader, {}),
  streaming: (dimensionData, dimensionHeader) =>
    STR_GET_DIMENSION_CELL(
      dimensionData,
      dimensionHeader,
      { ...creativeNameMap, ...creativeMap },
      derivedNetworkMap
    ),
  youtube: (dimensionData, dimensionHeader) =>
    YT_GET_DIMENSION_CELL(dimensionData, dimensionHeader, {}),
});

export const getSingleChannelMetricsTableDataFetchKey = {
  audio: S.getFetchKey,
  display: S.getFetchKey,
  linear: L.getFetchKey,
  social: (header: Column): string => `${header.kpi}_${header.lag}`,
  streaming: S.getFetchKey,
  youtube: (header: Column): string => `${header.kpi}`,
};

export const validateSingleChannelMetricsTableDataColumns = (
  companyInfo: CompanyInfo,
  dataColumns: Column[],
  selectedPagePreset: MetricsPagePreset
): Column[] =>
  R.map(col => {
    let resolvedKPI = R.defaultTo(
      (selectedPagePreset as MetricsPagePreset).globalOptions.kpi,
      col.kpi
    );
    let resolvedLag = R.defaultTo(
      (selectedPagePreset as MetricsPagePreset).globalOptions.lag,
      col.lag
    );
    // DEFAULT KPI LOGIC
    if (!resolvedKPI) {
      if (["audio", "display", "streaming"].includes(selectedPagePreset.mediatype)) {
        resolvedKPI = companyInfo.streaming_performance_default_kpi;
      } else if (selectedPagePreset.mediatype === "linear") {
        resolvedKPI = companyInfo.initial_kpi;
      } else if (selectedPagePreset.mediatype === "social") {
        resolvedKPI = companyInfo.cross_channel_kpis[0];
      } else if (selectedPagePreset.mediatype === "youtube") {
        // We don't currently have any default column here so reuse the streaming one.
        resolvedKPI = companyInfo.streaming_performance_default_kpi;
      }
    }
    // DEFAULT LAG LOGIC
    if (!resolvedLag) {
      if (["audio", "display", "streaming"].includes(selectedPagePreset.mediatype)) {
        resolvedLag = companyInfo.streaming_performance_default_lag;
      } else if (selectedPagePreset.mediatype === "social") {
        // We don't currently have any default column here so reuse the streaming one.
        resolvedLag = companyInfo.streaming_performance_default_lag;
      }
    }
    return {
      ...col,
      audience: R.defaultTo(
        "HH",
        R.defaultTo((selectedPagePreset as MetricsPagePreset).globalOptions.audience, col.audience)
      ),
      kpi: resolvedKPI,
      lag: resolvedLag,
    };
  }, dataColumns);
