import "./ABCD.scss";
import { Button, ButtonType, Dropdown, ImportModal, Page } from "../Components";
import { MdCheckCircle } from "react-icons/md";
import { MediaPlayer } from "../CreativeView/CreativeView";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import React, { useState, useEffect, useCallback, useMemo } from "react";

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const timestampButton = (timestamp: number, onClick: () => void) => (
  <Button type={ButtonType.FILLED} onClick={() => onClick()}>
    {formatTime(timestamp)}
  </Button>
);

interface Creative {
  file: string;
  media_types: string[];
}

interface ModelData {
  [isci: string]: any;
}

const ABCD: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<number | undefined>();
  const [file, setFile] = useState<any>();
  const [showImportChangesModal, setShowImportChangesModal] = useState<boolean>(false);
  const [modelData, setModelData] = useState<ModelData>();
  const setError = useSetError();
  const [selectedCreative, setSelectedCreative] = useState<Creative>();

  const isciOptions = R.keys(R.defaultTo({}, modelData));

  const actionsContainer = (
    <div className="abcdActionsContainer">
      {!R.isNil(modelData) && (
        <>
          <div className="label">Selected Creative: </div>
          <Dropdown
            value={R.defaultTo("", selectedCreative?.file)}
            options={R.map(isci => ({ label: isci, value: isci }), isciOptions)}
            onChange={file => {
              if (file === "Import Creative Data") {
                setShowImportChangesModal(true);
                setSelectedCreative(undefined);
              } else {
                setSelectedCreative({
                  file,
                  media_types: ["streaming"],
                });
              }
            }}
          ></Dropdown>
        </>
      )}
      <Button type={ButtonType.FILLED} onClick={() => setShowImportChangesModal(true)}>
        Import Creative Data
      </Button>
    </div>
  );

  const importData = useCallback(() => {
    try {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = event => {
          if (event.target) {
            const data = event.target.result;
            const parsedData = JSON.parse(data as string);
            setModelData(parsedData);
          }
        };
        fileReader.readAsText(file);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to import: ${reportError.message}`,
        reportError,
      });
    }
  }, [file, setError]);

  const importModal = (
    <ImportModal
      importFunction={importData}
      file={file}
      setFile={file => {
        if (R.isNil(file) || !file.name.includes(".json") || !file.type.includes("json")) {
          setError({
            message: "File must be a .json",
            reportError: new Error("File must be a .json"),
          });
        } else {
          setFile(file);
        }
      }}
      onClose={() => setShowImportChangesModal(false)}
    />
  );

  const mediaPlayer = selectedCreative && (
    <MediaPlayer
      selectedCreative={selectedCreative}
      key={selectedCreative.file}
      currentTime={currentTime}
    />
  );

  const videoPlayerContainer = <div className="videoPlayerContainer">{mediaPlayer}</div>;

  const creativeInfoContainer = useMemo(() => {
    const isci = selectedCreative && selectedCreative.file ? selectedCreative.file : undefined;
    if (R.isNil(modelData) || R.isNil(isci)) {
      return undefined;
    }
    const featureMap = modelData[isci];
    const featureItems = R.map(featureKey => {
      const tags = R.filter(
        tagInfo => tagInfo.feature_detected === "True",
        R.defaultTo([], featureMap[featureKey]) as any[]
      );

      const isPresent = R.find(
        tag => R.toLower(tag.feature_name).replace(/ /g, "") !== "notpresent",
        tags
      );
      console.log("isPresent", isPresent);

      const featureContainer = !R.isNil(tags) && tags.length > 0 && (
        <div className="featureContainer">
          <div className="featureLabelContainer">
            <div className="label">{featureKey}</div>
            {isPresent && <MdCheckCircle className="checkIcon" />}
          </div>
          <div className="tagsContainer">
            {R.map(
              tag => (
                <>
                  <div className="tagExplanationContainer">
                    <div className="tag">{tag.feature_name}:</div>
                    <div className="explanation">{tag.llm_explanation}</div>
                  </div>
                  {R.toLower(tag.feature_name) !== "not present" && (
                    <div className="timestampsContainer">
                      {R.map(range => {
                        const start = range[0];
                        const end = range[1] ? range[1] : start;
                        return (
                          <div className="timestamp">
                            {timestampButton(start, () => setCurrentTime(start))}
                            <div>{" - "}</div>
                            {timestampButton(end, () => setCurrentTime(end))}
                          </div>
                        );
                      }, tag.timestamps)}
                    </div>
                  )}
                </>
              ),
              tags
            )}
          </div>
        </div>
      );
      return featureContainer;
    }, R.keys(featureMap));
    return modelData && <div className="creativeInfoContainer">{featureItems}</div>;
  }, [modelData, selectedCreative]);

  useEffect(() => {
    if (!R.isNil(currentTime)) {
      setCurrentTime(undefined);
    }
  }, [currentTime]);

  return (
    <Page
      title="AI-Powered Tagging"
      pageType="AI-Powered Tagging"
      minHeight="600px"
      actions={actionsContainer}
    >
      {showImportChangesModal && importModal}
      <div className="abcdPageContainer">
        {videoPlayerContainer}
        {creativeInfoContainer}
      </div>
    </Page>
  );
};

export default ABCD;
