// Experiment for Media Update Generator page .

const MediaUpdateGeneratorUsers: string[] = [
  "sean.odlum@tinuiti.com",
  "miguel.garcia@tinuiti.com",
  "nicole.yu@tinuiti.com",

  // Add users here!
];

export const shouldEnableMediaUpdateGenerator = (userEmail: string): boolean => {
  return MediaUpdateGeneratorUsers.includes(userEmail);
};
