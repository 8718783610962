import "../IncrementalityPlaybook.scss";
import { IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { setupHeaders, testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "The Trade Desk";

const TradeDesk = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              The Trade Desk is a comprehensive programmatic advertising platform that marketers can
              buy advertising space on websites, mobile apps, connected TV platforms, and other
              digital platforms. This includes display, streaming video and audio ads. The platform
              provides marketers with sophisticated targeting capabilities, real-time bidding, and
              advanced analytics to optimize campaign performance.
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Setup Options & Considerations",
                "Test Design",
                "Signal Enrichment",
                "Test Outputs",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              On The Trade Desk (TTD), we currently have only one incrementality testing option:
              conversion lift, a standard testing option where conversion rates between the
              treatment and control groups are compared using a hypothesis test to determine if
              there is a significant difference. The Trade Desk users are randomized into treatment
              and control to ensure comparability of both groups.
            </div>
            <br />
            {playbookTable(
              [
                {
                  Method: "Conversion Lift in Platform",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                    { img: "Icon_ServiceModel_ManagedService.png", label: "Managed Service" },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        CL is a user based testing option in which treatment users are served ads
                        and control users are withheld from client ads consistent with a Ghost
                        Bidding methodology.
                      </li>
                      <li>
                        They withhold the control users from the auction and log whether we would
                        have bid on the user.
                      </li>
                      <li>
                        Calculations are then used to determine how many users we would have won
                        from that pool of control bids to get the final number of control users that
                        we will compare the treatment group against.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        The managed service ensures a quick and effortless setup process. Their team
                        can setup tests, typically within about a week.
                      </li>
                      <li>Available across their inventory types.</li>
                      <li>
                        Since the ad is not actually served to users in the control group, there are
                        no additional ad-serving costs.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Limited control over measurement and custom analysis due to reliance on The
                        Trade Desk for handling measurement and data.
                      </li>
                      <li>
                        No user level data but experimental results on the campaign for different
                        KPIs will be provided in CSV format including significance,
                        control/treatment group sizes, etc.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              450
            )}
          </div>
        }
        id="incrementality-testing-approaches"
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Considerations: "Setup Steps",
                  Details: (
                    <ol className="basicList">
                      <li>
                        Collect all the test details and fill out feasibility calculator to ensure
                        that the The Trade Desk team has all the information they need to get the
                        test set up.{" "}
                        <a href="https://view-su2.highspot.com/viewer/649de5c912e267cd26a5e0ee">
                          Link to feasibility calculator
                        </a>{" "}
                        and details to fill out have been provided below.
                      </li>
                      <li>
                        Send an email to our contact at The Trade Desk: Charlotte Kleiman,{" "}
                        <a href="mailto:charlotte.kleiman@thetradedesk.com">
                          charlotte.kleiman@thetradedesk.com
                        </a>{" "}
                        with all the information and filled out calculator.
                      </li>
                      <li>
                        Follow up for results a week after the test has concluded or in accordance
                        with the lag windows of the client.
                      </li>
                    </ol>
                  ),
                },
                {
                  Considerations: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>
                        It is recommended that requests to setup a test are received 1 week prior to
                        the start date to ensure the campaign launches on time.
                      </li>
                      <li>
                        Tests typically run for 1-3 months depending on the KPI, spend levels and
                        other inputs into the test. Deeper funnel KPIs will typically need longer
                        test durations.
                      </li>
                    </ul>
                  ),
                },
                {
                  Considerations: "Sample Size Recommendation",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Conversion lift will be set up and run entirely by the Trade Desk team
                        including randomization, sample size calculation, exposure, and measurement
                        of outcomes. Sample size recommendations for your test will be provided by
                        their team.
                      </li>
                    </ul>
                  ),
                },
              ],
              setupHeaders,
              index => (index === 0 ? 250 : 100)
            )}
            <br />
            <div className="basicText">
              To help make the process smooth, The Trade Desk asks us to share the following
              information:
            </div>
            <div className="basicText">
              Fill in the data for this feasibility calculator, if possible:{" "}
              <a href="https://view-su2.highspot.com/viewer/649de5c912e267cd26a5e0ee">
                Home | Highspot{" "}
              </a>
            </div>
            <ul className="basicList">
              <li>Experiment Name</li>
              <li>Advertiser ID</li>
              <li>Randomization option (Adbrain household graph or Adbrain person graph)</li>
              <li>Experiment type: (Campaign, Advertiser, Ad Group)</li>
              <li>Advertiser/Campaign/Ad Group IDs to be included</li>
              <li>Duration of the experiment (max 3 months)</li>
              <li>Holdout %</li>
              <li>Primary pixel for this experiment</li>
              <li>
                Number of Cells
                <ul>
                  <li>Size Per Cell (If muli-cell)</li>
                  <li>Campaigns In Each Cell (If muli-cell)</li>
                </ul>
              </li>
            </ul>
            <br />
            <div className="basicText">Example Form:</div>
            <ul className="basicList">
              Experiment Name: Wisp Ghost Bidding Test 2023
              <li>Advertiser ID: pq39oxw</li>
              <li>Randomization option: Adbrain household graph</li>
              <li>Experiment type: Campaign</li>
              <li>
                Advertiser, Campaign, Ad Group IDs to be included:
                <ul>
                  <li>
                    Campaigns:
                    <ul>
                      <li>Wisp_2023_Display_Prospecting_The Trade Desk_Banners (id: jt613yz)</li>
                      <li>Wisp_2023_Display_Prospecting_The Trade Desk_Native (id: mgbicrd)</li>
                      <li>Wisp_2023_Display_Retargeting_The Trade Desk_Banners (id: 0xju93b)</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>Duration of the experiment: ASAP-9/20</li>
              <li>Holdout %: 50%</li>
              <li>Primary pixel for this experiment: Wisp - Purchase Confirmation (id: 1skbmsr)</li>
              <li>
                Number of Cells: Three
                <ul>
                  <li>Size Per Cell: 33%</li>
                  <li>
                    Cells:
                    <ul>
                      <li>Wisp_2023_Display_Prospecting_The Trade Desk_Banners (id: jt613yz)</li>
                      <li>Wisp_2023_Display_Prospecting_The Trade Desk_Native (id: mgbicrd)</li>
                      <li>Wisp_2023_Display_Retargeting_The Trade Desk_Banners (id: 0xju93b)</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        }
        id={"setup-options-&-considerations"}
        pageTitle={PAGE_TITLE}
        title={"Setup Options & Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Randomization",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Users can be randomized into treatment and control using the TDID or
                        Person/Household graph and is available at the campaign or
                        placement/ad_group level. After one of these options is chosen, users will
                        be randomized into either group using hashing functions.
                      </li>
                      <li>
                        It is generally recommended that you use Person/HH randomization, since that
                        will allow you to use cross device targeting and track conversions across
                        platforms.
                      </li>
                      <li>
                        The one instance where TDID randomization could make sense is if most of
                        your conversions occur on the same device as the device where the impression
                        was served. For example, if you are running an in-app campaign to drive
                        users to install an app, TDID might make sense since conversions are less
                        likely to occur across device (and this can help open up scale a bit more
                        than using a graph).
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Bidding Proccess",
                  Details: (
                    <ul className="basicList">
                      <li>
                        For the test group, the bidding process will happen as it normally does, the
                        clients ad is sent to the SSP (supply-side platform) and if the ad wins it
                        is logged. For the control group, we withhold the ad and log if we would
                        have bid on the ad, instead the 2nd ranked ad within The Trade Desk’s
                        internal auction is sent to the SSP.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Identification of Winning Bids",
                  Details: (
                    <ul className="basicList">
                      <li>
                        The Trade Desk uses two assumptions to back into how many bids we would have
                        won. First, they assume that the unexposed conversion rate is the same
                        across both the treatment and control and second, assume that the exposed to
                        unexposed ratio is the same across both groups. These assumptions make it
                        possible to identify the total control exposed and these numbers are then
                        returned back to us.
                      </li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              index => (index === 0 ? 300 : 100)
            )}
          </div>
        }
        id={"test-design"}
        pageTitle={PAGE_TITLE}
        title={"Test Design"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              For Trade Desk (The Trade Desk) incrementality conversion lift, signal enrichment is a
              crucial step to enhance the accuracy and depth of conversion measurement. Signal
              enrichment is achieved through the{" "}
              <a href="https://partner.thetradedesk.com/v3/portal/data/doc/DataConversionEventsApi">
                Trade Desk Real-time Conversion Events API
              </a>
              , which empowers clients to gather and leverage user events for retargeting,
              attribution, and in-depth analysis. This API operates via HTTPS requests using the
              JSON format, ensuring seamless connectivity and efficient data exchange with the
              platform.
            </div>
            <br />
            <div className="basicText">
              Key Features of the Trade Desk Real-Time Conversion Events API:
            </div>
            <ul className="basicList">
              <li>
                Real-Time Conversion Events: The API facilitates the tracking of real-time
                conversion events. This means that as users engage with the platform, their actions
                are immediately recorded and made available for analysis. Real-time data collection
                enables timely insights into the effectiveness of advertising efforts.
              </li>
              <li>
                URL Mapping: URL mapping is a pivotal aspect of the API's capabilities. By
                associating specific URLs with conversion events, contextual information is added to
                the collected data. This association provides a deeper understanding of user
                interactions and helps in attributing conversions accurately.
              </li>
              <li>
                Support for Multiple Items: The API allows the inclusion of multiple items within a
                single event, such as cart-level data or other granular user actions. This feature
                enables the capture of detailed user engagement, contributing to a comprehensive
                analysis of user behavior.
              </li>
              <li>
                Wide Range of ID Types: The API supports various identification types, including
                TDID, RampID, Mobile Advertising IDs (IDFA, AAID, NAID, DAID), and Unified IDs such
                as UID2 and EUID. These IDs enable cross-device and cross-platform tracking,
                enriching the data with insights into user journeys across channels.
              </li>
              <li>
                Custom Fields for Metadata: Custom fields (TD1-TD10) offer the flexibility to
                include additional metadata with conversion events. This metadata can be tailored to
                specific campaign attributes or promotional details, enriching the collected data
                with valuable context.
              </li>
            </ul>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      Typically provided a week after the test has concluded. Can sometimes support
                      weekly reporting during the test if they detect feasibility.
                    </ul>
                  ),
                },
                {
                  Element: "Conversion Window",
                  Details: (
                    <ul className="basicList">
                      The total number of conversions within the experiment window are measured
                      (there is no look back window) which is used to calculate the conversion rate
                      in both the treatment and control group.
                    </ul>
                  ),
                },
                {
                  Element: "Granularity of Results",
                  Details: (
                    <ul className="basicList">
                      Results can be broken out by device, conversion/converters/sales, and KPI.
                    </ul>
                  ),
                },
                {
                  Element: "Output Format",
                  Details: (
                    <ul className="basicList">
                      Provided in a CSV/EXCEL sheet rather than in the platform or app.
                    </ul>
                  ),
                },
                {
                  Element: "Statistical Analysis",
                  Details: (
                    <ul className="basicList">
                      A one-sided t-test with 90% confidence is used to compare/analyze the
                      conversion rates between the groups.
                    </ul>
                  ),
                },
              ],
              testHeaders
            )}
            <br />
            <div className="basicText">
              Example output can be found attached below as well as a glossary/definition of each
              column.
            </div>
            <br />
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1EoKxTj58NaYZgzCVK8FEKUZtYKq-a4Lc/preview?authuser=0"
              title="Example Output"
            />
            <br />
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1HOXQp16w4S-sW3mz0kBYQ1-VNRSVoAnY/preview?authuser=0"
              title="Example Output"
            />
            <br />
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1gdOw7AUFVdfRhF4IrQAOPzn7Qlj1R9gV/preview?authuser=0"
              title="Example Output"
            />
          </div>
        }
        id={"test-outputs"}
        pageTitle={PAGE_TITLE}
        title={"Test Outputs"}
      />
    </div>
  );
});

export default TradeDesk;
