import "./StreamingCreatives.scss";
import { ApprovalStages } from "@blisspointmedia/bpm-types/dist/CreativeApprovals";
import { MdPieChart, MdMenu, MdShoppingCart, MdCloudUpload, MdCheckCircle } from "react-icons/md";
import { Router } from "@reach/router";
import { Tooltip } from "react-bootstrap";
import { useCreativeMap } from "../redux/creative";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { useMap, useList } from "../utils/hooks/useData";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import { useTabbedNav } from "../utils/hooks/useNav";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import Approvals from "./Approvals";
import Cart from "./Cart";
import Generate from "./Generate";
import NonCart from "./NonCart";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import useLocation from "../utils/hooks/useLocation";
import {
  BPMButton,
  FullPageSpinner,
  ImportModal,
  OverlayTrigger,
  Page,
  SingleDatePicker,
  ToggleNav,
  ToggleNavButton,
} from "../Components";
import {
  CreativeLambdaFetch,
  FlashtalkingLambdaFetch,
  StreamingLambdaFetch,
  awaitJSON,
} from "../utils/fetch-utils";

export const AD_SERVER_MAP = {
  ft: "Flashtalking",
  xr: "Extreme Reach",
};

export const StreamingCreativesContext = React.createContext();

export const MENU_KEY = "menu";
export const WORKSPACE_KEY = "workspace";
export const CART_KEY = "cart";
export const APPROVALS_KEY = "approvals";
export const GENERATE_KEY = "cloud";

export const NON_LIVE_COLOR = "#666666";

export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_FORMAT_MOMENT = "YYYY-MM-DD";

export const makeNetPlatKey = ({ network, platform }) => `${network}-${platform}`;

const TODAY = Dfns.format(DATE_FORMAT, new Date());

export const useWorkspaceOptions = (date, length, platform, adServer) => {
  const { company } = useLocation();
  const { creativeMap, colorMap, isciLiveOnDate } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const checkPlatformMatch = useCallback((creative, platform) => {
    let matchesPlatform = true;
    if (platform.includes("Streaming") && !creative.media_types?.includes("streaming")) {
      matchesPlatform = false;
    }
    if (platform.includes("Audio") && !creative.media_types?.includes("audio")) {
      matchesPlatform = false;
    }
    return matchesPlatform;
  }, []);

  // Check if we have a flashtalking id for the creative in the creative map.
  const checkIfAvailable = useCallback((creative, adServer) => {
    if ((adServer === "Flashtalking" || adServer === "ft") && R.isNil(creative.flashtalking_id)) {
      return false;
    }
    return true;
  }, []);

  const workspaceOptions = useMemo(() => {
    if (!creativeMap) {
      return null;
    }
    const iscis = R.keys(creativeMap);
    if (iscis.length) {
      const options = [];
      for (let isci of iscis) {
        const creative = creativeMap[isci];
        const matchesLength = length ? creative.length.toString() === length : true;
        const matchesPlatform = platform ? checkPlatformMatch(creative, platform) : true;
        const available = checkIfAvailable(creative, adServer);
        if (isciLiveOnDate({ isci, date }) && matchesLength && matchesPlatform && available) {
          options.push({
            ...creative,
            name: isci,
            creative: creative.name,
            color: colorMap[isci] || "white",
          });
        }
      }
      return R.sortBy(R.prop("isci"), options);
    }

    return null;
  }, [
    creativeMap,
    length,
    platform,
    checkPlatformMatch,
    checkIfAvailable,
    adServer,
    isciLiveOnDate,
    date,
    colorMap,
  ]);

  return workspaceOptions;
};

export const useAudioCompanionOptions = () => {
  const { company } = useLocation();
  const setError = useSetError();
  const [audioCompanionOptions, setAudioCompanionOptions] = useState([]);

  const fetchAudioCompanions = useCallback(async () => {
    const companionOptions = [];
    try {
      try {
        const res = await CreativeLambdaFetch("/fetchCompanions", {
          params: {
            company,
          },
        });
        const xrCompanionOptions = await awaitJSON(res);
        if (xrCompanionOptions && R.keys(xrCompanionOptions).length) {
          for (const adid of R.keys(xrCompanionOptions)) {
            companionOptions.push({ ...xrCompanionOptions[adid], adServer: "xr" });
          }
        }
      } catch (e) {
        throw new Error(`Couldn't fetch extreme reach audio companion options. ${e.message}`);
      }
      try {
        const res = await FlashtalkingLambdaFetch("/fetchStoredFlashtalkingAudioCompanions", {
          params: {
            company,
          },
        });
        const companionMap = await awaitJSON(res);
        if (companionMap) {
          // The ID for audio companions in flashtalking is the placement ID
          for (const placementID of R.keys(companionMap)) {
            const row = companionMap[placementID];
            companionOptions.push({
              adid: placementID,
              assetid: placementID,
              title: row.companion_placement_name,
              adServer: "ft",
            });
          }
        }
      } catch (e) {
        throw new Error(`Couldn't fetch flashtalking audio companion options. ${e.message}`);
      }
    } catch (e) {
      setError({
        message: `${e.message}`,
        reportError: e,
      });
    } finally {
      setAudioCompanionOptions(companionOptions);
    }
  }, [company, setError]);

  useEffect(() => {
    if (company) {
      fetchAudioCompanions();
    }
  }, [company, fetchAudioCompanions]);
  return audioCompanionOptions;
};

const useShopData = () => {
  const { company } = useLocation();
  const setError = useSetError();

  const [shopData, setShopData] = useState();

  const fetchShop = useCallback(async () => {
    try {
      let res = await StreamingLambdaFetch("/creative/shop", {
        params: {
          company,
        },
      });
      let { placements } = await awaitJSON(res);
      let placementMap = {};
      for (let placement of placements) {
        placementMap[placement.id] = placement;
      }
      setShopData({ placements, placementMap });
    } catch (e) {
      setError({
        message: `Couldn't fetch shop data. ${e.message}`,
        reportError: e,
      });
    }
  }, [company, setError]);

  useEffect(() => {
    if (company) {
      fetchShop();
    }
  }, [company, fetchShop]);

  return [shopData, fetchShop];
};

export const StreamingCreatives = ({ navigate }) => {
  const enableImporter = useExperimentFlag("enableStreamingCreativesImporter");
  const { company } = useLocation();
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure();
  const [showImportChangesModal, setShowImportChangesModal] = useState(false);
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "streaming/creatives",
    defaultKey: MENU_KEY,
  });

  const { creativeMap, isciLiveOnDate } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const [platform, setPlatform] = useState("");
  const [adServer, setAdServer] = useState("");

  const [date, setDate] = useState(TODAY);

  const [shopData, refetchShopData] = useShopData();

  // Map of id to boolean of which placements are selected
  const [selectedMap, setSelectedValue, setSelectedMap] = useMap();
  // Valid iscis for workspace
  const workspaceOptions = useWorkspaceOptions(date, null /* =length*/, platform, adServer);
  // Map of isci to value
  const [workspaceMap, setWorkspaceValue, setWorkspaceMap] = useMap();

  // // Imported File Data
  const [file, setFile] = useState();

  const audioCompanionOptions = useAudioCompanionOptions();

  const [cart, setCart, addToCart] = useList();

  const inCart = useMemo(
    () =>
      R.reduce(
        (inCartMap, cartItem) => {
          for (let id of cartItem.placements) {
            inCartMap[id] = true;
          }
          return inCartMap;
        },
        {},
        cart
      ),
    [cart]
  );

  const [localLoaded, setLocalLoaded] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const localStorageKey = `BPMStreamingCreatives${company}`;

  useEffect(() => {
    if (company && shopData && creativeMap && !localLoaded && !localLoading) {
      setLocalLoading(true);
      (async () => {
        let localData = JSON.parse(window.localStorage.getItem(localStorageKey)) || {};

        const isEmpty = data =>
          !(
            R.pipe(R.prop("workspaceMap"), R.defaultTo({}), R.keys, R.length)(data) ||
            R.pipe(R.prop("selectedMap"), R.defaultTo({}), R.keys, R.length)(data) ||
            R.pipe(R.prop("cart"), R.defaultTo([]), R.length)(data)
          );
        // If their local storage is empty, don't do anything
        if (isEmpty(localData)) {
          setLocalLoaded(true);
          return;
        }

        let localDate = localData.date || date;

        if (localDate < TODAY) {
          localDate = TODAY;
          await setError({
            title: "Date Was Reset",
            variant: "warning",
            message:
              "You had items in your cart or workspace, but the rotation date you had is now in the past. The date has been updated to today. Make sure the items in your cart and workspace are still valid.",
          });
        }

        let badIscis = [];
        let badPlacements = [];

        let newWorkspaceMap = {};
        // Only take things from local storage that are still valid
        let oldWorkspaceMap = R.prop("workspaceMap", localData) || {};
        for (let isci of R.keys(oldWorkspaceMap)) {
          if (isciLiveOnDate({ isci, date: localDate })) {
            newWorkspaceMap[isci] = oldWorkspaceMap[isci];
          } else {
            badIscis.push(isci);
          }
        }

        let newSelectedMap = {};
        let oldSelectedMap = R.prop("selectedMap", localData) || {};

        for (let placementId of R.keys(oldSelectedMap)) {
          if (!oldSelectedMap[placementId]) {
            continue;
          }
          if (shopData.placementMap[placementId]) {
            if (selectedMap.length) {
              if (
                newSelectedMap[placementId].platform === platform &&
                newSelectedMap[placementId].adServer === adServer
              ) {
                newSelectedMap[placementId] = true;
              }
            } else {
              newSelectedMap[placementId] = true;
              setPlatform(shopData.placementMap[placementId].platform.split(" ")[0]);
              setAdServer(shopData.placementMap[placementId].adServer);
            }
          } else {
            badPlacements.push(placementId);
          }
        }

        let newCart = [];

        for (let item of localData.cart || []) {
          let { placements, rotation } = item;
          let goodPlacements = true;
          for (let placement of placements) {
            if (!R.path(["placementMap", placement], shopData)) {
              goodPlacements = false;
              badPlacements.push(placement);
            }
          }
          let goodRotation = true;
          for (let creative of rotation) {
            if (!isciLiveOnDate({ isci: creative.isci, date: localDate })) {
              goodRotation = false;
              badIscis.push(creative.isci);
            }
          }
          if (goodRotation && goodPlacements) {
            newCart.push(item);
          }
        }

        if (badPlacements.length || badIscis.length) {
          let lines = [];
          if (badPlacements.length) {
            lines.push(
              `The following tags are no longer active:\n${R.pipe(
                R.uniq,
                R.join(", ")
              )(badPlacements)}`
            );
          }

          if (badIscis.length) {
            if (lines.length) {
              lines.push("\n");
            }
            lines.push(
              `The following ISCIs are no longer live:\n${R.pipe(R.uniq, R.join(", "))(badIscis)}`
            );
          }
          lines.push(
            "\nSome items in your cart had this ISCI and have been removed. Double check before committing your changes."
          );
          setError({ message: lines.join("\n") });
        }

        setCart(newCart);
        setLocalLoaded(true);
        setLocalLoading(false);
        setSelectedMap(newSelectedMap);
        setWorkspaceMap(newWorkspaceMap);
        if (
          !isEmpty({
            cart: newCart,
            selectedMap: newSelectedMap,
            workspaceMap: newWorkspaceMap,
          })
        ) {
          setDate(localDate);
        }
      })();
    }
  }, [
    company,
    shopData,
    workspaceOptions,
    localLoaded,
    localLoading,
    localStorageKey,
    setCart,
    setError,
    setSelectedMap,
    setWorkspaceMap,
    date,
    creativeMap,
    isciLiveOnDate,
    adServer,
    platform,
    selectedMap.length,
  ]);

  const importCSVRotations = useCallback(() => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event.target.result;
        const rows = data.split("\n");
        // Check if the data is empty (need more than just a header)
        if (R.isNil(rows) || rows.length < 2) {
          setError({
            message: "There is no data in the file provided, please check the csv uploaded.",
            title: "Error: No Data",
          });
          return;
        }
        if (R.isNil(shopData) || R.isEmpty(shopData.placementMap)) {
          setError({
            message:
              "There is no placement data available yet, please wait. If this issue is persisting please contact the engineering team.",
            title: "Error: No Placement Data",
          });
        }

        let workspaceOptionsMap = {};
        for (const option of workspaceOptions) {
          workspaceOptionsMap[option.name] = true;
        }

        if (R.isNil(workspaceOptionsMap) && R.isEmpty(workspaceOptionsMap)) {
          setError({
            message:
              "There is no creative data available yet, please wait. If this issue is persisting please contact the engineering team.",
            title: "Error: No Creative Data",
          });
        }
        const newCart = [];
        let excelRowIndex = 2;
        for (const rowString of rows.slice(1)) {
          setSelectedMap({});
          setWorkspaceMap({});
          if (R.isNil(rowString) || R.isEmpty(rowString)) {
            setError({
              message: `There is no data in row: ${excelRowIndex}.
              Please check your csv file.`,
              title: "Error: No Row Data",
            });
          }

          let rotation = [];
          let placements = [];
          const row = rowString.split(",");
          const placementIDs = row[0].split(",");
          const date = row[1];
          setDate(date);
          let creative = null;

          // Verify the placement id's used
          for (const placementID of placementIDs) {
            if (shopData && shopData.placementMap && shopData.placementMap[placementID]) {
              placements.push(placementID);
            } else {
              setError({
                message: `The following placement ID is invalid: ${placementID}. Skipping this id.
                Please check csv row: ${excelRowIndex}.`,
                title: "Error: Placement ID",
              });
            }
          }

          // Extract and verify the creative rotation for a given set of placements
          let runningTotal = 0;
          for (let i = 2; i < row.length; i++) {
            if (i % 2 && creative) {
              rotation.push({ isci: creative, value: row[i] });
              runningTotal += parseInt(row[i]);
            } else if (i % 2 === 0) {
              creative = row[i] && workspaceOptionsMap[row[i]] ? row[i] : null;
              if (!(R.isNil(row[i]) || R.isEmpty(row[i])) && R.isNil(workspaceOptionsMap[row[i]])) {
                setError({
                  message: `The following isci: ${row[i]} is not valid. 
                  Please check csv row: ${excelRowIndex}.`,
                  title: "Error: ISCI",
                });
                return;
              }
            }
          }

          // Verify the creative allocation
          if (runningTotal !== 100 && Math.round(runningTotal) !== 100) {
            setError({
              message: `Please check csv row: ${excelRowIndex}. The creative allocation for this row
              doesn't add up to 100. Getting: ${runningTotal}`,
              title: "Error: Creative Allocation",
            });
          }

          newCart.push({ id: Date.now(), placements: R.uniq(placements), rotation });
          excelRowIndex++;
        }
        setCart(newCart);
        goToTab(CART_KEY);
      };
      fileReader.readAsText(file);
    }
  }, [
    file,
    goToTab,
    setCart,
    setError,
    setSelectedMap,
    setWorkspaceMap,
    shopData,
    workspaceOptions,
  ]);

  useEffect(() => {
    if (localLoaded) {
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          selectedMap,
          workspaceMap,
          cart,
          date,
        })
      );
    }
  }, [selectedMap, workspaceMap, cart, date, localLoaded, localStorageKey]);

  const [committing, setCommitting] = useState(false);

  const [approvalStageData, setApprovalStageData] = useState();

  useEffect(() => {
    (async () => {
      if (company && !committing) {
        try {
          const res = await StreamingLambdaFetch("/getCreativeApprovalStage", {
            params: {
              company,
            },
          });
          const data = await awaitJSON(res);
          setApprovalStageData(data);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      }
    })();
  }, [company, setError, committing, tab]);

  const updateApprovalStage = useCallback(
    (
      company,
      newApprovalStage,
      selectedReviewers,
      currentRequester,
      currentReviewer,
      commentBoxText
    ) => {
      (async () => {
        try {
          const body = {
            company,
            stage: newApprovalStage,
            reviewers: selectedReviewers,
            requester: currentRequester,
            currentReviewer,
            commentBoxText: commentBoxText,
          };
          const res = await StreamingLambdaFetch("/updateCreativeApprovalStage", {
            method: "POST",
            body,
          });
          const data = await awaitJSON(res);
          setApprovalStageData(data);
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    },
    [setError]
  );

  const commit = useCallback(async () => {
    setCommitting(true);
    try {
      const placements = [];
      for (let { placements: cartPlacements, rotation } of cart) {
        const creatives = [];
        for (const creative of rotation) {
          creatives.push({
            isci: creative.isci,
            pct: creative.value,
          });
        }
        for (const placementID of cartPlacements) {
          placements.push({
            placementID,
            creatives,
            // TODO: Once we have a UI for clickthrough URLs, audio companions,
            // and tracking pixels in the /streaming/creatives page, fill these out!
            clickthroughUrlMap: {},
            audioCompanionMap: {},
            extraTrackingPixels: [],
          });
        }
      }

      const res = await CreativeLambdaFetch("/commitCreatives", {
        method: "POST",
        body: { date, company, placements },
      });

      await awaitJSON(res);

      await refetchShopData();

      if (
        approvalStageData.stage !== ApprovalStages.CHANGES_REQUESTED &&
        approvalStageData.stage !== ApprovalStages.PRE_REVIEW
      ) {
        setApprovalStageData(null);
        //passing in reviewers and requester so they will be sent a slack saying the process was canceled (if they exist).
        await updateApprovalStage(
          company,
          ApprovalStages.PRE_REVIEW,
          approvalStageData.reviewers || [],
          approvalStageData.requester || []
        );
      }

      goToTab(APPROVALS_KEY);
      setSelectedMap({});
      setCart([]);
      setWorkspaceMap({});
    } catch (e) {
      setError({
        message: `Failed to commit changes. Error: ${e.message}`,
        reportError: e,
      });
    }
    setCommitting(false);
    setPlatform("");
    setAdServer("");
  }, [
    refetchShopData,
    approvalStageData,
    goToTab,
    setSelectedMap,
    setCart,
    setWorkspaceMap,
    cart,
    date,
    company,
    updateApprovalStage,
    setError,
  ]);

  const setDatePicker = useCallback(
    newDate => {
      let nonEmptyCart = !!cart.length;
      let nonEmptyWorkspace = R.pipe(
        R.values,
        R.any(val => !!val)
      )(workspaceMap);
      if (nonEmptyCart || nonEmptyWorkspace) {
        let whatYouHave = [];
        if (nonEmptyCart) {
          whatYouHave.push("items in your cart");
        }
        if (nonEmptyWorkspace) {
          whatYouHave.push("values in your workspace");
        }

        setAreYouSure({
          title: "Changing dates with non-empty cart",
          message: `You have ${whatYouHave.join(
            " and "
          )}. Changing the date will clear these. Are you sure you want to continue?`,
          okayText: "Yes, change the date",
          onOkay: () => {
            setCart([]);
            setWorkspaceMap({});
            setDate(newDate);
          },
        });
      } else {
        setDate(newDate);
      }
    },
    [cart, workspaceMap, setAreYouSure, setCart, setWorkspaceMap]
  );

  const loaded = shopData && localLoaded && R.pipe(R.keys, R.length)(creativeMap);
  return (
    <StreamingCreativesContext.Provider
      value={{
        tab,
        goToTab,
        date,
        shopData,
        cart,
        selectedMap,
        inCart,
        setSelectedValue,
        setSelectedMap,
        workspaceOptions,
        workspaceMap,
        setWorkspaceValue,
        setWorkspaceMap,
        addToCart,
        committing,
        commit,
        setCart,
        audioCompanionOptions,
        approvalStageData,
        updateApprovalStage,
        platform,
        setPlatform,
        adServer,
        setAdServer,
      }}
    >
      <Page
        title="Streaming Creatives"
        pageType="Streaming Creatives"
        minHeight={700}
        actions={
          <div className="streamingCreativeActions">
            <div className="importButtons">
              {enableImporter && (
                <BPMButton
                  className="importCSVButton"
                  onClick={() => setShowImportChangesModal(true)}
                >
                  Import Creative Allocations
                </BPMButton>
              )}
            </div>
            {enableImporter && showImportChangesModal && (
              <ImportModal
                importFunction={importCSVRotations}
                file={file}
                setFile={setFile}
                onClose={() => setShowImportChangesModal(false)}
              />
            )}
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                <Tooltip>
                  Any rotations on this page will be what the rotations will be on this date. Any
                  rotations you set here will start on this date.
                </Tooltip>
              }
            >
              <div className="datePickerLabel">Rotation Date:</div>
            </OverlayTrigger>
            <SingleDatePicker
              showDefaultInputIcon
              block={false}
              id="streamingCreativesDate"
              date={date}
              onChange={date => setDatePicker(date)}
              isOutsideRange={day => day < TODAY}
            />
            <ToggleNav className="viewButtons" activeKey={tab} onChange={goToTab}>
              <ToggleNavButton value={MENU_KEY}>
                <MdMenu />
              </ToggleNavButton>
              <ToggleNavButton value={WORKSPACE_KEY}>
                <MdPieChart />
              </ToggleNavButton>
              <ToggleNavButton value={CART_KEY} className="cartToggle">
                <MdShoppingCart />
                {cart.length ? <span className="count">({cart.length})</span> : null}
              </ToggleNavButton>
              <ToggleNavButton value={APPROVALS_KEY}>
                <MdCheckCircle />
              </ToggleNavButton>
              <ToggleNavButton value={GENERATE_KEY}>
                <MdCloudUpload />
              </ToggleNavButton>
            </ToggleNav>
          </div>
        }
      >
        <div className="streamingCreatives">
          {loaded ? (
            <>
              <Router className="fullPageRouter">
                <Cart path={CART_KEY} />
                <Approvals path={APPROVALS_KEY} />
                <Generate path={GENERATE_KEY} />
                <NonCart default />
              </Router>
            </>
          ) : (
            <FullPageSpinner />
          )}
        </div>
      </Page>
    </StreamingCreativesContext.Provider>
  );
};
