import React from "react";
import "./Key.scss";

interface KeyProps {
  focalAdvertiser: string;
  nonFocalAdvertisers: string[];
  type: string;
}

const Key: React.FC<KeyProps> = React.memo(
  ({ focalAdvertiser, nonFocalAdvertisers, type }: KeyProps): JSX.Element => {
    const resolvedFocalAdvertiser = focalAdvertiser === "Joann" ? "JOANN" : focalAdvertiser;
    return (
      <div className="keyContainer">
        <div className="focalAdvertiser">
          {nonFocalAdvertisers.length > 0 ? resolvedFocalAdvertiser : type}
          <div className="line"></div>
        </div>
        {nonFocalAdvertisers.length > 0 && (
          <div className="competitorSection">
            <div className="competitorLabel">
              {nonFocalAdvertisers.length <= 1 ? "Competitor" : "Competitors"}
              <div className="line"></div>
            </div>
            <div className="competitors">{nonFocalAdvertisers.join(", ")}</div>
          </div>
        )}
      </div>
    );
  }
);

export default Key;
