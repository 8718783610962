import React, { useEffect, useState } from "react";

import {
  HistogramRow,
  GetHistogramParams,
  PerformancePreset as Preset,
  Prefix as StreamingPrefix,
} from "@blisspointmedia/bpm-types/dist/StreamingPerformance";

import { Histogram } from "./Histogram";
import { awaitJSON, StreamingPerformanceLambdaFetch } from "../../utils/fetch-utils";
import { useSetError } from "../../redux/modals";
import { useIsMounted } from "../../utils/hooks/useDOMHelpers";

interface ConversionLatencyHistogramProps {
  presetID?: number;
  kpi: string;
  start: string;
  end: string;
  prefix: StreamingPrefix;
  preset?: Preset;
}

const ConversionLatencyHistogram = ({
  presetID,
  kpi,
  start,
  end,
  prefix,
  preset,
}: ConversionLatencyHistogramProps): JSX.Element => {
  const setError = useSetError();
  const getIsMounted = useIsMounted();

  const [histDataMap, setHistDataMap] = useState<Record<string, HistogramRow[] | undefined>>({});

  const histKey = `${presetID}_${kpi}_${start}_${end}_${preset?.impsBetween}`;

  useEffect(() => {
    if (preset && !histDataMap[histKey]) {
      (async () => {
        try {
          let res = await StreamingPerformanceLambdaFetch<GetHistogramParams>("/histogram", {
            params: {
              kpi,
              start,
              end,
              prefix,
              impsBetween: preset.impsBetween,
            },
          });
          let rows = await awaitJSON<HistogramRow[]>(res);
          if (getIsMounted()) {
            setHistDataMap(map => ({ ...map, [histKey]: rows }));
          }
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [preset, histDataMap, histKey, start, end, setError, kpi, getIsMounted, prefix]);

  const histData = histDataMap[histKey];

  return <Histogram data={histData} />;
};

export default ConversionLatencyHistogram;
