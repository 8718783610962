import React, { useState } from "react";
import { Tab, Tabs, Form, Card } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { Response, Selection } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { S3Put } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { BPMFileInput, Button, ButtonType } from "../Components";
import "./Onboarding.scss";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

interface OnboardingUploaderProps {
  questionTitle: string;
  response: Response
  onChange: (file: any) => void;
}

const OnboardingUploader = ({
  questionTitle,
  response,
  onChange,
}: OnboardingUploaderProps): JSX.Element => {
  const { selections, questionNumber } = response;
  const { company } = useLocation();
  const [uploadLink, setUploadLink] = useState<string>("");
  const initialSelections = selections.map((selection) => selection.label);
  const [allSelections, setAllSelections] = useState<string[]>(initialSelections);
  const [returnedSelections, setReturnedSelections] = useState<string[]>(initialSelections);

  const onNewLink = (link: string) => {
    const newSelection: Selection = { label: link };
    const updatedResponse = {
      ...response,
      selections: [...response.selections, newSelection]
    };

    setAllSelections([...allSelections, link]);
    setReturnedSelections([...returnedSelections, link]);
    setUploadLink("");

    onChange(updatedResponse);
  };

  const uploadFileToS3 = (file: File, e: any) => {
    const fileReader = new FileReader();
    fileReader.onload = e => {
      if (!e.target) {
        return;
      }
      let data = e.target.result || "";

      if (typeof data !== "string") {
        data = new TextDecoder().decode(data);
      }

      const splitString = data.split(",");
      data = splitString[1];

      S3Put("bpm-client-info", `${company}/${questionTitle}/${file.name}`, data, {
        bufferEncoding: "base64",
        contentType: file.type,
      });
    };
    fileReader.readAsDataURL(file);
  };

  const onNewFile = (e: any) => {
    const file = e.target.files[0];
    uploadFileToS3(file, e);

    const s3Path = `bpm-client-info/${company}/${questionTitle}/${file.name}`;
    const newSelection: Selection = { label: s3Path };

    const updatedResponse = {
      ...response,
      selections: [...response.selections, newSelection]
    };

    setAllSelections([...allSelections, file.name]);
    setReturnedSelections([...returnedSelections, s3Path]);

    onChange(updatedResponse);
  };

  return (
    <div>
      <Tabs defaultActiveKey="link" id="uncontrolled-tab-example">
        <Tab eventKey="link" title="Link File(s)">
          <Card className="onboardingLinkCard">
            <Form.Group>
              <Form.Label htmlFor={`${questionNumber}-textarea`}>Link your file below and grant us view access.</Form.Label>
              <Form.Control
                id={`${questionNumber}-link`}
                value={uploadLink}
                onChange={(e) => setUploadLink(e.target.value)}
                as="textarea"
                placeholder="Link file here"
              />
            </Form.Group>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdAdd />}
              onClick={() => onNewLink(uploadLink)}
            >
              Submit
            </Button>
          </Card>
        </Tab>
        <Tab eventKey="fileUpload" title="Upload File(s)">
          <BPMFileInput id={`${questionNumber}-fileUpload`} name="onboardingFileUpload" onChange={(e) => onNewFile(e)} />
        </Tab>
      </Tabs>
      <Card className="uploadedFiles">
        <h5>Linked/Uploaded File(s)</h5>
        {allSelections.length === 0 && <p>Link or upload a file above.</p>}
        {allSelections.map((file, index) => (
          <p key={index}>{file ?? ""}</p>
        ))}
      </Card>
    </div>
  );
};

export default OnboardingUploader;
