import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import {
  getCreativeInfoFromSpikeChartData,
  getSpikeChartData,
  SpikeChart,
  SpikeChartData,
} from "../Components/SpikeChart";
import { dateSort } from "./slideUtils";
import { Form } from "react-bootstrap";
import { GOOGLE_SLIDE_IMAGE_SCALING_FACTOR } from "./slideFormatConstants";
import { KpiInfo } from "@blisspointmedia/bpm-types/dist/Kpis";
import { KpiPicker, RelativeDatePicker } from "../Components";
import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { parseInputToInt } from "../utils/data";
import { Provider } from "react-redux";
import { reduxStore } from "../redux";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { useCompanyInfo } from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const AREA_FILL = "#000000";
const CHART_HEIGHT = 283.3 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // Scale by a factor of 1.335 so the size of the picture
const CHART_WIDTH = 850.7 * GOOGLE_SLIDE_IMAGE_SCALING_FACTOR; // is 238.3px by 850.7px
const DEFAULT_MAX_LOGOS = 10;

interface SlideNotableAiringsChartResult {
  elem: SVGSVGElement | null;
}

interface SlideNotableAiringsChartOnLoad {
  (results: SlideNotableAiringsChartResult): void;
}

interface SlideNotableAiringsChartProps {
  color: string;
  kpi: string;
  spikeChartData: SpikeChartData[];
  onLoad: SlideNotableAiringsChartOnLoad;
}

const SlideNotableAiringsChart: React.FC<SlideNotableAiringsChartProps> = ({
  color,
  kpi,
  spikeChartData,
  onLoad,
}) => {
  const [clear, setClear] = useState<boolean>(false);
  const [pathLoaded, setPathLoaded] = useState(false);
  const mapSvgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    if (clear) {
      mapSvgRef.current = null;
      setClear(false);
    }
    if (spikeChartData !== null && pathLoaded) {
      onLoad({
        elem: mapSvgRef.current,
      });
      setClear(true);
    }
  }, [clear, onLoad, pathLoaded, spikeChartData]);

  if (spikeChartData === null) {
    return null;
  } else {
    return (
      <SpikeChart
        chartData={spikeChartData}
        fillColor={color}
        fillOpacity={0.5}
        height={CHART_HEIGHT}
        kpi={kpi}
        margin={{ top: 10, bottom: 0, left: -20, right: 0 }}
        noXAxisTitle={true}
        noYAxisTitle={true}
        onPathLoad={setPathLoaded}
        ref={mapSvgRef}
        strokeColor={color}
        strokeWidth={0.5}
        top={Math.max(...R.map(elem => elem.kpiCount, spikeChartData))}
        width={CHART_WIDTH}
      />
    );
  }
};
export interface NotableAiring {
  dayOfWeek: string;
  spikeTime: string;
  station: string;
  program: string;
  creative: string;
  kpiCount: number;
  date: Date;
}
export interface NotableAiringLogo {
  network: string;
  // These are relative to the top left corner of the image
  x: number;
  y: number;
  // If image isn't present in our database override with text
  overrideImage?: boolean;
}

interface NotableAiringsSlideData {
  companyColor: string;
  end: string;
  kpi: string;
  notableAiringsLogos: NotableAiringLogo[];
  notableAiringsRows: NotableAiring[];
  spikeChartURL: string;
  start: string;
}

export interface NotableAiringsSlideState extends NotableAiringsSlideData {
  dates: RelativeDateRange;
  kpis: KpiInfo[];
  maxLogos: number;
}

export type NotableAiringsSharedState = string[];

class NotableAiringsSlide extends SlideType {
  static typeKey = "notableAirings";
  static displayKey = "Notable Airings";
  static defaultState: NotableAiringsSlideState = {
    companyColor: AREA_FILL,
    dates: {
      start: { pivotDate: "monday", adjustment: 7, adjustmentType: "day" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    end: "string",
    kpi: "",
    kpis: [],
    maxLogos: DEFAULT_MAX_LOGOS,
    notableAiringsLogos: [],
    notableAiringsRows: [],
    spikeChartURL: "",
    start: "",
  };

  static SettingsComponent: React.FC<SettingsComponentProps<NotableAiringsSlideState>> = React.memo(
    ({ state, setState }) => {
      const { dates, kpi, maxLogos } = state;
      const { streaming_performance_default_kpi, kpis, color } = useCompanyInfo();

      useEffect(() => {
        if (!kpi) {
          setState({ kpi: streaming_performance_default_kpi });
        }
      }, [kpi, streaming_performance_default_kpi, setState]);

      useEffect(() => {
        setState({ kpis });
      }, [kpis, setState]);

      useEffect(() => {
        setState({ companyColor: color });
      }, [color, setState]);

      return (
        <div className="settingsBox">
          <div className="wrappingColumn">
            <Form.Group>
              <Form.Label>Number of Network Logos</Form.Label>
              <Form.Control
                as="input"
                type="number"
                value={`${maxLogos}`}
                onChange={e =>
                  setState({
                    maxLogos: parseInputToInt(e.currentTarget.value),
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Week Of</Form.Label>
              <RelativeDatePicker
                state={dates.start}
                onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
              />
            </Form.Group>
            <div className="kpiSelector">
              <KpiPicker kpi={kpi} onChange={kpi => setState({ kpi })} />
            </div>
          </div>
        </div>
      );
    }
  );

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<NotableAiringsSlideData> => {
    const { companyColor, dates, kpi, kpis, maxLogos } = state as NotableAiringsSlideState;
    const week = Dfns.format(
      DATE_FORMAT,
      Dfns.startOfISOWeek(Dfns.addDays(1, new Date(computeResolvedDate(dates.start))))
    );
    const start = week;
    const end = Dfns.format(
      DATE_FORMAT,
      Dfns.endOfISOWeek(Dfns.addDays(1, new Date(computeResolvedDate(dates.start))))
    );
    const sandbox = await claimSandbox();
    let spikeChartData: SpikeChartData[] = [];
    let spikeChartURL;

    try {
      spikeChartData = R.defaultTo([], await getSpikeChartData(kpi, week));
    } catch (e) {
      const error = e as Error;
      throw new Error(`Failed to get spike data: ${error.message}`);
    }
    if (spikeChartData && spikeChartData.length) {
      try {
        const networkQuery = await LinearBuyingLambdaFetch("/linear_canonical_networks");
        console.log("networkQuery", networkQuery);
        const networkRes = await awaitJSON(networkQuery);
        spikeChartData = R.map(entry => {
          if (entry.creativeInfo) {
            for (let info of entry.creativeInfo) {
              info.overrideImage = true;
              for (let linearNetwork of networkRes) {
                if (info.network === linearNetwork["Short Code"]) {
                  info.networkName = linearNetwork["Station Name"];
                  info.overrideImage = false;
                  break;
                }
              }
            }
          }
          return entry;
        }, spikeChartData);
      } catch (e) {
        let error = e as Error;
        throw new Error(`Failed to get networks for Notable Airings Slide: ${error.message}`);
      }
    } else {
      throw new Error(`No data available for spike chart: kpi=${kpi}, week=${week}`);
    }

    const {
      svgString,
    }: Omit<SlideNotableAiringsChartResult, "elem"> & {
      svgString: string;
    } = await new Promise((resolve, reject) => {
      try {
        const load = () => {
          ReactDOM.render(
            <Provider store={reduxStore}>
              <SlideNotableAiringsChart
                color={companyColor}
                kpi={kpi}
                spikeChartData={spikeChartData}
                onLoad={({ elem }) => {
                  if (elem) {
                    let boundingBox = elem.getBoundingClientRect();
                    let svgString = convertSVGStringToPNGURI(
                      elem.outerHTML,
                      boundingBox.width,
                      boundingBox.height
                    );
                    if (sandbox.element) {
                      ReactDOM.unmountComponentAtNode(sandbox.element);
                    }
                    resolve({
                      svgString,
                    });
                  }
                }}
              />
            </Provider>,
            sandbox.element
          );
        };
        load();
      } catch (e) {
        reject(e);
      }
    });
    releaseSandbox(sandbox);

    const creativeInfo = spikeChartData ? getCreativeInfoFromSpikeChartData(spikeChartData) : null;
    if (R.isNil(creativeInfo) || R.isEmpty(creativeInfo)) {
      throw new Error(
        `Error: No table data (Notable Airings Slide). Please check the linear spike page with the following settings to confirm: KPI=${kpi}, Week=${week}`
      );
    }

    spikeChartURL = await addS3Image(svgString);

    let kpiString;
    for (let kpiInfo of kpis) {
      if (kpiInfo.id === kpi) {
        kpiString = kpiInfo.name;
      }
    }

    let notableAiringsRows: NotableAiring[] = [];
    let notableAiringsLogos: NotableAiringLogo[] = [];
    let numLogos = maxLogos < creativeInfo.length ? maxLogos : creativeInfo.length;
    let dayCount = Math.floor(numLogos / 7);
    let dayMap = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    };
    for (let i = 0; i < creativeInfo.length; i++) {
      let elem = creativeInfo[i];
      if (elem) {
        let date = Dfns.isValid(new Date(elem.time))
          ? new Date(elem.time)
          : new Date(elem.timeslot);
        let dayOfWeek = Dfns.format("eeee", date);
        // Get the top airing of each day
        if (dayMap[dayOfWeek] < dayCount && elem.program && elem.program !== "ROS") {
          let spikeTime = Dfns.isValid(new Date(elem.time)) ? Dfns.format("hh:mm:ss a", date) : "";
          let notableAiring: NotableAiring = {
            dayOfWeek,
            spikeTime,
            station: elem.networkName ? elem.networkName : elem.network,
            program: elem.program,
            creative: `${elem.creative} (${elem.length})`,
            kpiCount: elem.kpi,
            date,
          };
          let notableAiringLogo: NotableAiringLogo = {
            network: elem.network,
            x: R.defaultTo(1, elem.xPercentage),
            y: R.defaultTo(1, elem.yPercentage),
            overrideImage: elem.overrideImage,
          };
          notableAiringsRows.push(notableAiring);
          notableAiringsLogos.push(notableAiringLogo);

          // Remove after inserted into lists
          creativeInfo.splice(i, 1);
          dayMap[dayOfWeek] += 1;
        }
      }
    }

    let extraAirings = numLogos - R.sum(R.values(dayMap));
    // Fill the remaining logos and rows with remaining top kpi counts
    for (let i = 0; i < extraAirings; i++) {
      let elem = creativeInfo[i];
      if (elem) {
        let date = Dfns.isValid(new Date(elem.time))
          ? new Date(elem.time)
          : new Date(elem.timeslot);
        let dayOfWeek = Dfns.format("eeee", date);
        let spikeTime = Dfns.isValid(new Date(elem.time)) ? Dfns.format("hh:mm:ss a", date) : "";
        let notableAiring: NotableAiring = {
          dayOfWeek,
          spikeTime,
          station: elem.networkName ? elem.networkName : elem.network,
          program: elem.program,
          creative: `${elem.creative} (${elem.length}s)`,
          kpiCount: elem.kpi,
          date,
        };
        let notableAiringLogo: NotableAiringLogo = {
          network: elem.network,
          x: R.defaultTo(1, elem.xPercentage),
          y: R.defaultTo(1, elem.yPercentage),
          overrideImage: elem.overrideImage,
        };
        notableAiringsRows.push(notableAiring);
        notableAiringsLogos.push(notableAiringLogo);
      }
    }

    notableAiringsRows = dateSort(notableAiringsRows);

    return {
      companyColor,
      end,
      kpi: kpiString,
      notableAiringsLogos,
      notableAiringsRows,
      spikeChartURL,
      start,
    };
  };
}

export default NotableAiringsSlide;
