import React, { useState, useCallback } from "react";

import { InputGroup, Form, Button, Spinner } from "react-bootstrap";
import { InfoTooltip, Page } from "../Components";
import { awaitJSON, MetricsLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import "./MediaUpdateGenerator.scss";

const MediaUpdateGenerator = (): JSX.Element => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState("");
  const [max_tokens, setMaxTokens] = useState<number | null>(null);
  const [temperature, setTemperature] = useState<number>(1);
  const setError = useSetError();

  const mediaUpdateGenerator = useCallback(async () => {
    try {
      setActiveAPICall(true);
      const res = await MetricsLambdaFetch("/mediaUpdateGenerator", {
        method: "POST",
        body: JSON.stringify({
          prompt,
          max_tokens,
          temperature,
        }),
      });
      let data = await awaitJSON(res);
      setResult(data);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [prompt, max_tokens, temperature, setError]);

  return (
    <Page title="Media Update Generator" pageType="Media Update Generator">
      <div className="mediaUpdateContainer">
        <label className="mediaUpdateContainerHeader">Prompt</label>
        <textarea
          className="mediaUpdateRow"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
        />

        <label className="mediaUpdateContainerHeader">
          {" "}
          Result
          <InfoTooltip size="reg">
            {`The API is non-determinsistic so different results will appear everytime you submit, 
          even if the prompt stays the same`}
          </InfoTooltip>
        </label>
        <div className="mediaUpdateRow"> {result}</div>

        <InputGroup size="sm" className="mediaUpdateContainerInput">
          <InputGroup.Prepend>
            <InputGroup.Text className="inputLabel">Max Number of Tokens</InputGroup.Text>
          </InputGroup.Prepend>
          <InfoTooltip size="reg">
            {`The maximum number of tokens to generate in the completion. 1 token is approximately 4 characters or 0.75 words for English text. Please note the number of tokens
        for prompt plus number of tokens for completion must not exceed 2049 tokens ~ 1536 words`}
          </InfoTooltip>
          <Form.Control
            value={max_tokens || ""}
            onChange={e => setMaxTokens(e.target.value ? parseInt(e.target.value) : null)}
          />
        </InputGroup>
        <InputGroup size="sm" className="mediaUpdateContainerInput">
          <InputGroup.Prepend>
            <InputGroup.Text className="inputLabel">Temperature</InputGroup.Text>
          </InputGroup.Prepend>
          <InfoTooltip size="reg">
            {`What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the
        output more random, while lower values like 0.2 will make it more focused and deterministic.
        Please do not leave empty. (Default is 1)`}
          </InfoTooltip>
          <Form.Control
            type="number"
            value={temperature}
            onChange={e => {
              const value = parseFloat(e.target.value);
              if (value !== 0 && (R.isNil(value) || value > 2 || value < -2)) {
                setTemperature(1);
              } else {
                setTemperature(value);
              }
            }}
          />
        </InputGroup>
        <Button className="mediaUpdateContainerInput" onClick={mediaUpdateGenerator}>
          {activeAPICall ? <Spinner animation={"border"} /> : "Submit"}
        </Button>
      </div>
    </Page>
  );
};
export default MediaUpdateGenerator;
