import "./MetadataForm.scss";
import { Alert, Button, Modal } from "react-bootstrap";
import { AudioCompanion } from "@blisspointmedia/bpm-types/dist/Creative";
import { CreativeMetadataInputCard } from "../../StreamingCreatives/CreativeMetadataInputCard";
import { StagedTagData } from "./utils";
import { StateSetter } from "../../utils/types";
import { TagCreationContext } from "./GenerateView";
import { useCreativeMap } from "../../redux/creative";
import { useMap } from "../../utils/hooks/useData";
import * as R from "ramda";
import React, { useMemo, useState, useEffect, useCallback, useContext } from "react";

interface AudioCompanionDropdownOption {
  label: string;
  value: string;
}

interface MetadataFormProps {
  tags: StagedTagData[];
  setTags: StateSetter<StagedTagData[]>;
  onSubmit: any;
  company: string;
  isEditMode: boolean;
  isPlacementLevelModeInitial?: boolean;
}

export const MetadataForm = ({
  tags,
  setTags,
  onSubmit,
  company,
  isEditMode,
  isPlacementLevelModeInitial = false,
}: MetadataFormProps): JSX.Element => {
  // When in placement-level mode we take a single input for a clickthrough URL
  // to set for the entire placement, rather than individual creative-level URLs.
  const initialPlacementLevelClickthroughUrl = isEditMode ? tags[0].clickthroughUrl : undefined;
  const [isPlacementLevelMode, setIsPlacementLevelMode] = useState(
    isPlacementLevelModeInitial || !!initialPlacementLevelClickthroughUrl
  );
  const [placementLevelClickthroughUrl, setPlacementLevelClickthroughUrl] = useState<
    string | undefined
  >(initialPlacementLevelClickthroughUrl);

  const [formFailMessage, setFormFailMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditingClickthroughs, setIsEditingClickthroughs] = useState(isEditMode);

  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const iscis: string[] = useMemo(() => {
    // Get unique ISCIs from the creative allocations of the staged tags.
    let uniqueIscis: string[] = [];
    for (let tag of tags) {
      for (let isci of Object.keys(tag.creativeAllocationMap || {})) {
        if (uniqueIscis.indexOf(isci) === -1) {
          uniqueIscis.push(isci);
        }
      }
    }
    return uniqueIscis;
  }, [tags]);

  const adServer = tags[0]?.adServer;

  const isAudio = tags[0]?.platform.includes("Audio");
  const { audioCompanionOptions: allCompanions } = useContext(TagCreationContext);
  const audioCompanionOptions = useMemo(() => {
    const audioCompanionOptions: any[] = [];
    for (const companion of allCompanions) {
      if (companion.adServer === "xr" && adServer === "Extreme Reach") {
        audioCompanionOptions.push(companion);
      } else if (companion.adServer === "ft" && adServer === "Flashtalking") {
        audioCompanionOptions.push(companion);
      }
    }

    return audioCompanionOptions;
  }, [adServer, allCompanions]);

  // Format the audio companion options into a form the Dropdown will understand.
  const audioCompanionOptionsList = useMemo(() => {
    let optionsList: AudioCompanionDropdownOption[] = [];
    if (isAudio && audioCompanionOptions) {
      // Add an option for "No Companion"
      optionsList.push({
        label: "No Companion",
        value: "",
      });

      Object.values(audioCompanionOptions).forEach(option => {
        const { adid, title } = option as AudioCompanion;
        optionsList.push({
          label: `${adid} (${title})`,
          value: adid,
        });
      });
    }
    return optionsList;
  }, [isAudio, audioCompanionOptions]);

  // Get the default clickthrough URLs for each isci based on what's in the creative map, or
  // based on what's already been set if we're editing an already staged tag.
  const defaultClickthroughUrlMap = useMemo(() => {
    let defaultValues = {};

    if (isEditMode) {
      // Clickthrough map will be the same on all tags, so take it from the first.
      defaultValues = tags[0].clickthroughUrlMap || {};
    }

    for (let isci of iscis) {
      // Don't override set values if we're in edit mode.
      if (!isEditMode) {
        const creative = creativeMap ? creativeMap[isci] : undefined;
        defaultValues[isci] = creative?.clickthroughUrl || "";
      }
    }

    return defaultValues;
  }, [isEditMode, tags, iscis, creativeMap]);

  const [clickthroughUrlMap, setClickthroughUrlValue, setClickthroughUrlMap] = useMap<
    string,
    string
  >(defaultClickthroughUrlMap);

  const [audioCompanionMap, setAudioCompanionValue, setAudioCompanionMap] = useMap<
    string,
    AudioCompanion
  >(
    {} // TODO: Get default values from creative map once audio companions are in there.
  );

  // Note: This function will trigger the below useEffect once setTags completes,
  // which will submit the form and stage the tags.
  const addMetadataToTags = useCallback(() => {
    let foundInvalidClickthrough = false;
    let tagsWithMetadata = tags.map(tag => {
      if (isEditMode) {
        delete tag.clickthroughUrl;
        delete tag.clickthroughUrlMap;
        delete tag.audioCompanionMap;
      }

      if (placementLevelClickthroughUrl) {
        if (placementLevelClickthroughUrl && !placementLevelClickthroughUrl.includes("https")) {
          foundInvalidClickthrough = true;
          return tag;
        }

        return {
          ...tag,
          clickthroughUrl: placementLevelClickthroughUrl,
        };
      } else {
        for (let clickthrough of Object.values(clickthroughUrlMap)) {
          if (clickthrough && !clickthrough.includes("https")) {
            foundInvalidClickthrough = true;
            return tag;
          }
        }

        return {
          ...tag,
          clickthroughUrlMap: clickthroughUrlMap as Record<string, string>,
          audioCompanionMap: audioCompanionMap as Record<string, AudioCompanion>,
        };
      }
    });

    if (foundInvalidClickthrough) {
      setFormFailMessage("Clickthrough URLs must be secure (https).");
      return;
    }

    setTags(tagsWithMetadata);
    setClickthroughUrlMap({});
    setAudioCompanionMap({});
    setIsEditingClickthroughs(false);
  }, [
    clickthroughUrlMap,
    audioCompanionMap,
    isEditMode,
    placementLevelClickthroughUrl,
    setClickthroughUrlMap,
    setAudioCompanionMap,
    setTags,
    tags,
  ]);

  useEffect(() => {
    // Wait until metadata is set on all tags before submitting.
    const tagsHaveMetadata = tags.reduce(
      (prev, curr) => prev && (!!curr.clickthroughUrlMap || !!curr.clickthroughUrl),
      true
    );
    if (tagsHaveMetadata && !isSubmitted && !isEditingClickthroughs) {
      onSubmit();
      setIsSubmitted(true);
    }

    // Reset isSubmitted when we get a new tag.  We can tell because it will have
    // no clickthrough URLs set.
    if (!tagsHaveMetadata) {
      setIsSubmitted(false);
    }
  }, [onSubmit, tags, isSubmitted, isEditingClickthroughs]);

  const togglePlacementLevelCheckbox = useCallback(() => {
    if (isPlacementLevelMode) {
      setIsPlacementLevelMode(false);
      setPlacementLevelClickthroughUrl(undefined);
    } else {
      setIsPlacementLevelMode(true);
    }
  }, [isPlacementLevelMode]);

  const onClickthroughUrlInput = useCallback(
    (isci, value) => {
      setClickthroughUrlValue(isci, value);
    },
    [setClickthroughUrlValue]
  );

  const onAudioCompanionSelect = useCallback(
    (isci, value) => {
      if (audioCompanionOptions) {
        const audioCompanion = audioCompanionOptions[value]
          ? (audioCompanionOptions[value] as AudioCompanion)
          : (R.find(R.propEq("adid", value), audioCompanionOptions) as AudioCompanion);
        setAudioCompanionValue(isci, audioCompanion);
      }
    },
    [setAudioCompanionValue, audioCompanionOptions]
  );

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <div>{"Set Metadata"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Don't allow the switch to a placement-level clickthrough if there are also companion banners to be set. */}
        {/* We might want to handle this differently in the future. */}
        {!audioCompanionOptionsList.length && (
          <div className="clickthroughTypeCheckboxContainer">
            <input
              className="clickthroughTypeCheckbox"
              type="checkbox"
              checked={isPlacementLevelMode}
              onChange={togglePlacementLevelCheckbox}
            />
            <div className="clickthroughTypeCheckboxLabel">Set a single placement-level URL</div>
          </div>
        )}
        <div className="metadataForm">
          {isPlacementLevelMode ? (
            <div className="placementLevelInputContainer">
              <div className="inputBoxLabel">Placement-Level Click-Through URL:</div>
              <textarea
                className="placementLevelInputBox"
                value={placementLevelClickthroughUrl}
                onChange={e => setPlacementLevelClickthroughUrl(e.target.value)}
              ></textarea>
            </div>
          ) : (
            <div>
              {iscis.map(isci => {
                const creative = creativeMap ? creativeMap[isci] : undefined;
                return creative ? (
                  <CreativeMetadataInputCard
                    company={company}
                    key={isci}
                    creative={creative}
                    clickthroughUrl={clickthroughUrlMap[creative.isci] || ""}
                    onClickthroughUrlInput={onClickthroughUrlInput}
                    audioCompanionOptionsList={audioCompanionOptionsList}
                    audioCompanion={audioCompanionMap[creative.isci]?.adid || ""}
                    onAudioCompanionSelect={onAudioCompanionSelect}
                  />
                ) : (
                  <div></div>
                );
              })}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {formFailMessage && (
          <Alert variant="danger" className="alert">
            {formFailMessage}
          </Alert>
        )}
        <Button onClick={addMetadataToTags}>Stage</Button>
      </Modal.Footer>
    </div>
  );
};
