import * as R from "ramda";

const BOOLS = ["and", "or"];
const OPS = ["is", "is not", "is like", "is not like"];

const defaultOptionGetter = key => line => [`${line[key]}`];

const makeOptionGetters = categories => {
  let map = {};
  for (let { name, optionsGetter } of categories) {
    if (optionsGetter) {
      map[name] = optionsGetter;
    }
  }
  return key => map[key] || defaultOptionGetter(key);
};

export const getNextOptions = (tokens, categories, values) => {
  const optionGetters = makeOptionGetters(categories);
  let lastCat;
  let parenCount = 0;
  for (let token of tokens) {
    switch (token.type) {
      case "cat":
        lastCat = token;
        break;
      case "openParen":
        parenCount += 1;
        break;
      case "closeParen":
        parenCount -= 1;
        break;
      default:
        break;
    }
  }

  const lastToken = tokens[tokens.length - 1];
  if (lastToken && lastToken.type === "op" && lastToken.label.includes("like")) {
    return [];
  }
  // We do ~length because if we have already said "and" and we have another option with value: "and", the select will
  // see that it's already been selected and filter it from the list. So they need to have unique values.
  const getOpts = (list, type) => {
    return R.pipe(
      R.map(elem => {
        let label;
        let value;
        let key;
        if (type === "cat" && typeof elem !== "string") {
          ({ label } = elem);
          value = elem.name;
          key = elem.name;
        } else {
          label = elem;
          value = elem;
          key = elem;
        }
        return {
          label,
          type,
          key,
          value: `${value}~${tokens.length}`,
        };
      }),
      R.sortBy(R.prop("label"))
    )(list);
  };

  if (!tokens.length || lastToken.type === "bool" || lastToken.type === "openParen") {
    return [
      {
        label: "(",
        type: "openParen",
        value: `(~${tokens.length}`,
      },
      ...getOpts(categories, "cat"),
    ];
  }
  if (lastToken.type === "cat") {
    return getOpts(OPS, "op");
  }
  if (lastToken.type === "op") {
    let options = R.pipe(
      R.map(optionGetters(lastCat.key)),
      R.flatten,
      R.uniq,
      R.sortBy(R.identity)
    )(values);
    return getOpts(options, "val");
  }

  if (lastToken.type === "val" || lastToken.type === "closeParen") {
    let opts = getOpts(BOOLS, "bool");
    if (parenCount > 0) {
      return [
        {
          label: ")",
          type: "closeParen",
          value: `)~${tokens.length}`,
        },
        ...opts,
      ];
    }
    return opts;
  }
  return [];
};

// Converts numbers/booleans to strings, plus lowercases everything
let normalizeString = str => `${str}`.toLowerCase();
let is = R.uncurryN(2, a => R.pipe(normalizeString, R.pipe(normalizeString, R.equals)(a)));
let isLike = (val, like) => normalizeString(val).includes(normalizeString(like));

const EVALUATORS = {
  is,
  "is not": R.complement(is),
  "is like": isLike,
  "is not like": R.complement(isLike),
  and: R.both,
  or: R.either,
};

export const makeFilter = (tokens, categories) => {
  if (!tokens.length) {
    return () => true;
  }
  const optionGetters = makeOptionGetters(categories);

  // Stack of nodes that will be progressively be merged into a single function
  let stack = [];
  // Previous token
  let prev;
  const assertPrevTokenType = desiredType => {
    let prevType = R.prop("type", prev) || "none";
    if (prevType !== desiredType) {
      throw new Error(`Expected previous token type to be "${desiredType}", but got "${prevType}"`);
    }
  };
  const assertTopStackType = desiredType => {
    let top = stack.pop();
    let prevType = R.prop("type", top) || "none";
    if (prevType !== desiredType) {
      throw new Error(`Expected type "${desiredType}". Got "${prevType}"`);
    }
    stack.push(top);
  };

  // Given a function, recurse over the stack looking for boolean operators. If we see one, pop it off, top off the node
  // before it (should be another function), then push a new function that's the two functions and/or'd. Does it
  // recursively to get all chained boolean expressions. If it hits the end of the stack or an open paren it stops.
  const mergeStackFns = fn => {
    if (stack.length) {
      let top = stack.pop();
      if (top.type === "bool") {
        assertTopStackType("fn");
        let left = stack.pop();
        return EVALUATORS[top.op](left.fn, fn);
      } else {
        // put it back
        stack.push(top);
        return fn;
      }
    }
    return fn;
  };

  for (let token of tokens) {
    const { type, key } = token;

    switch (type) {
      case "cat":
        stack.push({
          type: "exp",
          cat: key,
        });
        break;
      case "op":
        assertPrevTokenType("cat");
        if (!OPS.includes(key)) {
          throw new Error(`"${key}" is not a valid operator`);
        }
        // Why the brackets? Because es6 lets you do this to enforce scoping. Switch statements are really weird about
        // scoping, so this is the best way to get local variables inside a case block
        {
          let topOp = stack.pop();
          topOp.op = key;
          stack.push(topOp);
        }
        break;
      case "val":
        assertPrevTokenType("op");
        {
          let topVal = stack.pop();
          // If we have a full expression, make a comp function that does left [op] right.
          let fn = line => {
            let options = optionGetters(topVal.cat)(line);
            return R.any(a => EVALUATORS[topVal.op](a, key), options);
          };
          stack.push({
            type: "fn",
            fn: mergeStackFns(fn),
          });
        }

        break;
      case "bool":
        if (!BOOLS.includes(key)) {
          throw new Error(`"${key}" is not a valid boolean operator`);
        }
        stack.push({
          type: "bool",
          op: key,
        });
        break;
      case "openParen":
        stack.push({
          type: "open paren",
        });
        break;
      case "closeParen":
        // This will only work if the last thing we have is a function
        assertTopStackType("fn");
        {
          let topClose = stack.pop();
          // Everything inside the parens should be boiled down into a single operation at this point.
          assertTopStackType("open paren");
          // Get rid of that open paren
          stack.pop();
          stack.push({
            type: "fn",
            fn: mergeStackFns(topClose.fn),
          });
        }
        break;
      default:
        break;
    }
    prev = token;
  }

  // At this point our stack should just be one thing: a single function
  let top = stack.pop();

  // If we have something followed by an open paren, that means they left out a close paren
  if (stack.length && stack[stack.length - 1].type === "open paren") {
    throw new Error(`We were looking for a closing parenthesis, but we got "${prev.key}".`);
  }
  // If they ended on an open paren, that's no good
  if (top.type === "open paren") {
    throw new Error("You can't end with an open parenthesis.");
  }
  // If they ended with a boolean, that's no good
  if (top.type === "bool") {
    throw new Error(`You can't end with "${top.op}".`);
  }
  // If the top of the stack is an exp, that means they never put in the final value, because otherwise it would have
  // become an "fn"
  if (top.type === "exp") {
    if (prev.type === "op") {
      throw new Error(`You can't end with an operation ("${prev.key}" in this case).`);
    }
    if (prev.type === "cat") {
      throw new Error(`You can't end with a category ("${prev.key}" in this case).`);
    }
  }
  // If there are any items left in the stack (since we popped off what should be the only element: the final function)
  // then we messed up.
  if (stack.length) {
    throw new Error(
      `Failed to make filter function. Ended up with a stack of size ${stack.length + 1}.`
    );
  }
  // If the last element isn't a function, we have some kind of problem.
  if (top.type !== "fn") {
    throw new Error(`Expected to end with filter function. Got "${top.type}".`);
  }
  return top.fn;
};
