import React, { useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { MdMoreVert, MdDone } from "react-icons/md";
import { StateSetter } from "../utils/types";
import { MEDIA_TYPE_TO_PRETTY_NAME } from "./homePageConstants";
import { SimpleTooltip, SimpleTooltipDirection } from "../Components";
import "./ChannelChip.scss";

interface ChannelChipProps {
  mediaType: string;
  isSelected: boolean | undefined;
  lag: "1d" | "3d" | "7d" | "14d" | "30d";
  setLags: StateSetter<
    Record<"streaming" | "audio" | "display", "1d" | "3d" | "7d" | "14d" | "30d">
  >;
  setToggledMediaTypes: StateSetter<Record<string, boolean>>;
  isLive: boolean;
}

interface ChannelChipDropdownProps {
  mediaType: string;
  lag: "1d" | "3d" | "7d" | "14d" | "30d";
  setLags: StateSetter<
    Record<"streaming" | "audio" | "display", "1d" | "3d" | "7d" | "14d" | "30d">
  >;
}

const LAG_OPTIONS = ["1d", "3d", "7d", "14d", "30d"] as const;

const CHANNELS_WITH_LAG = ["streaming", "audio", "display"];

const makeTooltipLabel = (mediaType: string, isSelected: boolean | undefined, isLive: boolean) => {
  if (!isLive) {
    return "Not active";
  } else {
    return `${isSelected ? "Hide" : "Show"} ${mediaType}`;
  }
};

/**
 * Only for channels with lag.
 */
const ChannelChipDropdown: React.FC<ChannelChipDropdownProps> = ({ mediaType, lag, setLags }) => {
  return (
    <div className="channelChipDropdown">
      <div className="lagSelector">
        <div className="lagSelectorTitle">Lag</div>
        {LAG_OPTIONS.map(option => (
          <div
            className={`option ${lag === option ? "selected" : ""}`}
            onClick={() => setLags(current => ({ ...current, [mediaType]: option }))}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

const ChannelChip: React.FC<ChannelChipProps> = React.memo(
  ({ mediaType, isSelected, lag, setLags, setToggledMediaTypes, isLive }) => {
    const [showTooltipMediaType, setShowTooltipMediaType] = useState(false);
    const [showTooltipMoreOptions, setShowTooltipMoreOptions] = useState(false);
    return (
      <Dropdown
        as={ButtonGroup}
        className={`channelChip ${mediaType} ${isSelected ? "" : "unselected"} ${
          isLive ? "" : "notLive"
        }`}
      >
        <SimpleTooltip
          text={makeTooltipLabel(MEDIA_TYPE_TO_PRETTY_NAME[mediaType], isSelected, isLive)}
          horizontalShift={isSelected ? "15px" : "-15px"}
          verticalShift="-250%"
          onMouseEnter={() => setShowTooltipMediaType(true)}
          onMouseLeave={() => setShowTooltipMediaType(false)}
          showTooltip={showTooltipMediaType}
          direction={SimpleTooltipDirection.BOTTOM}
          maxWidth="200px"
        >
          <div
            className={`channelChipTitle ${mediaType}`}
            onClick={() => {
              if (isLive) {
                setToggledMediaTypes(current => ({ ...current, [mediaType]: !current[mediaType] }));
              }
            }}
          >
            {isSelected && <MdDone className="channelChipTitleIcon" />}
            {MEDIA_TYPE_TO_PRETTY_NAME[mediaType]}
          </div>
        </SimpleTooltip>
        {CHANNELS_WITH_LAG.includes(mediaType) && isSelected && isLive && (
          <>
            <SimpleTooltip
              text="More options"
              horizontalShift="-30px"
              verticalShift="-150%"
              onMouseEnter={() => setShowTooltipMoreOptions(true)}
              onMouseLeave={() => setShowTooltipMoreOptions(false)}
              showTooltip={showTooltipMoreOptions}
              direction={SimpleTooltipDirection.BOTTOM}
              maxWidth="200px"
            >
              <Dropdown.Toggle className={`channelChipToggle ${mediaType}`} size="sm">
                <MdMoreVert />
              </Dropdown.Toggle>
            </SimpleTooltip>
            <Dropdown.Menu>
              <ChannelChipDropdown mediaType={mediaType} lag={lag} setLags={setLags} />
            </Dropdown.Menu>
          </>
        )}
      </Dropdown>
    );
  }
);

interface ChannelChipRedesignProps {
  label: string;
  isSelected: boolean | undefined;
  setState: StateSetter<Record<string, boolean>>;
  isDisabled?: boolean;
}

export const ChannelChipRedesign: React.FC<ChannelChipRedesignProps> = React.memo(
  ({ label, isSelected, setState, isDisabled = false }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <Dropdown
        as={ButtonGroup}
        className={`channelChipRedesign ${isSelected ? "" : "unselected"}`}
      >
        {isDisabled ? (
          <div
            className={`channelChipRedesignTitle ${label}`}
            onClick={() => {
              if (!isDisabled) {
                setState(current => ({ ...current, [label]: !current[label] }));
              }
            }}
          >
            {label}
          </div>
        ) : (
          <SimpleTooltip
            text={`${isSelected ? "Hide" : "Show"} ${label}`}
            horizontalShift="-20px"
            verticalShift="-250%"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            showTooltip={showTooltip}
            direction={SimpleTooltipDirection.BOTTOM}
            maxWidth="200px"
          >
            <div
              className={`channelChipRedesignTitle ${label}`}
              onClick={() => setState(current => ({ ...current, [label]: !current[label] }))}
            >
              {label}
            </div>
          </SimpleTooltip>
        )}
      </Dropdown>
    );
  }
);

export default ChannelChip;
