import React, { useCallback, useState } from "react";
import * as R from "ramda";
import { Dropdown as BSDropdown, Form, Modal, Toast } from "react-bootstrap";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";

import {
  MdLink,
  MdFileDownload,
  MdRestore,
  MdAdd,
  MdCompareArrows,
  MdDeleteForever,
  MdBrush,
} from "react-icons/md";
import { ReactComponent as Rename } from "../icons/Rename.svg";
import { DateRange, StateSetter } from "../../utils/types";
import { navigate, WindowLocation } from "@reach/router";
import { encodePrettyUrl } from "../performanceUtils";
import { SetError, useSetAreYouSure } from "../../redux/modals";
import {
  StreamingPerformanceLambdaFetch,
  LinearPerformanceLambdaFetch,
  YoutubePerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import { BPMButton, ThreeDotsButton } from "../../Components";
import PresetShop from "./PresetShop";
import { MatchedResponsesModal } from "../MatchedResponsesModal";
import { useExperimentFlag } from "../../utils/experiments/experiment-utils";

interface OptionsDropdownProps<PresetType extends P.Preset> {
  location: WindowLocation<unknown> | undefined;
  isInternal: boolean;
  preset: PresetType;
  presetID: P.PresetIDGroups | undefined;
  baseUrl: string;
  presetName: string;
  timeTravelOpen?: boolean;
  presetOptions: string[];
  groupOptions: string[];
  hasExportCSVData?: boolean;
  tempBranchBuild?: string;
  datePickerDates: DateRange;
  isLinear?: boolean;
  isYoutube?: boolean;
  exportCSV?: () => void;
  setNewName: StateSetter<string>;
  setPresetChanges: StateSetter<P.PresetChanges<PresetType>>;
  setEditMode: (val: boolean | "new" | "quick") => void;
  setSaving: StateSetter<boolean>;
  setError: SetError;
  setTimeTravelOpen?: (open: boolean) => void;
  setTempBranchBuild?: StateSetter<string>;
}

const OptionsDropdown = <PresetType extends P.Preset>({
  location,
  isInternal,
  preset,
  presetID,
  baseUrl,
  presetName,
  timeTravelOpen,
  presetOptions,
  groupOptions,
  hasExportCSVData,
  tempBranchBuild,
  datePickerDates,
  isLinear,
  isYoutube,
  exportCSV,
  setNewName,
  setPresetChanges,
  setEditMode,
  setSaving,
  setError,
  setTimeTravelOpen,
  setTempBranchBuild,
}: OptionsDropdownProps<PresetType>): JSX.Element => {
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [showCreateView, setShowCreateView] = useState(false);
  const [showMatchedResponses, setShowMatchedResponses] = useState(false);
  const setAreYouSure = useSetAreYouSure();
  const enableTimeTravel = useExperimentFlag("enableTimeTravel");

  const deleteView = useCallback(async () => {
    if (!presetID) {
      return;
    }
    try {
      await setAreYouSure({
        title: "Delete View",
        message: "Are you sure you want to delete this view?",
        okayText: "Delete",
        cancelText: "Never mind.",
      });
    } catch (e) {
      return;
    }
    try {
      setSaving(true);
      if (isYoutube) {
        await YoutubePerformanceLambdaFetch<P.DeletePresetParams>("/preset", {
          method: "DELETE",
          params: {
            id: presetID.id,
          },
        });
      } else if (isLinear) {
        await LinearPerformanceLambdaFetch<P.DeletePresetParams>("/deletePreset", {
          method: "DELETE",
          params: {
            id: presetID.id,
          },
        });
      } else {
        await StreamingPerformanceLambdaFetch<P.DeletePresetParams>("/preset", {
          method: "DELETE",
          params: {
            id: presetID.id,
          },
        });
      }
    } catch (e) {
      const error = e as Error;
      setError({
        reportError: error,
        message: error.message,
      });
      setSaving(false);
      return;
    }
    navigate(baseUrl);
    window.location.reload();
  }, [presetID, baseUrl, setAreYouSure, setSaving, isYoutube, isLinear, setError]);

  const editView = useCallback(
    (quick?: boolean) => {
      if (isInternal) {
        setNewName(presetID?.name || "");
        if (preset) {
          setPresetChanges(
            R.omit(["filterState", "filterTokens"], preset) as P.PresetChanges<PresetType>
          );
        }
        setEditMode(quick ? "quick" : true);
      }
    },
    [presetID, setEditMode, preset, isInternal, setNewName, setPresetChanges]
  );

  return (
    <BSDropdown className="optionsDropdown">
      <BSDropdown.Toggle as={ThreeDotsButton} />
      <BSDropdown.Menu>
        {isInternal && (
          <BSDropdown.Item onClick={() => editView(true)}>
            <Rename />
            <span className="label">Quick Edit</span>
          </BSDropdown.Item>
        )}
        <BSDropdown.Item
          onClick={() => {
            /// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
            navigator.clipboard.writeText(
              `${location?.origin}${baseUrl}${
                presetID?.id === 0 ? "" : `/${encodePrettyUrl(presetName)}`
              }`
            );
            setShowCopiedToast(true);
          }}
        >
          <MdLink />
          <span className="label">Copy link</span>
        </BSDropdown.Item>
        {isInternal && !presetID?.temporary && (
          <BSDropdown.Item onClick={() => editView()}>
            <MdBrush />
            <span className="label">Customize view</span>
          </BSDropdown.Item>
        )}
        {isInternal && presetID && (
          <BSDropdown.Item onClick={() => setShowCreateView(R.not)}>
            <MdAdd />
            <span className="label">Create view</span>
          </BSDropdown.Item>
        )}
        {isInternal && (
          <BSDropdown.Item onClick={deleteView}>
            <MdDeleteForever />
            <span className="label">Delete view</span>
          </BSDropdown.Item>
        )}

        {(isInternal || enableTimeTravel) && setTimeTravelOpen && (
          <BSDropdown.Item onClick={() => setTimeTravelOpen(true)}>
            <MdRestore />
            <span className="label">Time Travel</span>
          </BSDropdown.Item>
        )}
        {!isLinear && preset && (
          <BSDropdown.Item onClick={() => setShowMatchedResponses(true)}>
            <MdCompareArrows />
            <span className="label">Matched Responses</span>
          </BSDropdown.Item>
        )}
        {preset && hasExportCSVData && (
          <BSDropdown.Item onClick={exportCSV}>
            <MdFileDownload />
            <span className="label">Export view</span>
          </BSDropdown.Item>
        )}
      </BSDropdown.Menu>
      <Toast
        className="copiedToast"
        onClose={() => setShowCopiedToast(false)}
        show={showCopiedToast}
        delay={1000}
        autohide
      >
        <Toast.Body>Copied!</Toast.Body>
      </Toast>
      {timeTravelOpen && setTimeTravelOpen && setTempBranchBuild && (
        <Modal show size="sm" onHide={() => setTimeTravelOpen(false)}>
          <Modal.Header closeButton>Time Travel</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Control
                value={tempBranchBuild}
                onChange={e => setTempBranchBuild(e.currentTarget.value)}
              />
            </Form.Group>
            <BPMButton onClick={() => setTimeTravelOpen(false)}>Save</BPMButton>
          </Modal.Body>
        </Modal>
      )}
      {showCreateView && presetID && (
        <PresetShop
          onClose={() => setShowCreateView(false)}
          currentID={presetID.id}
          currentName={presetID.name}
          presetNames={presetOptions}
          groupOptions={groupOptions}
          baseUrl={baseUrl}
          isLinear={isLinear}
          isYoutube={isYoutube}
        />
      )}
      {showMatchedResponses && (
        <MatchedResponsesModal
          onClose={() => setShowMatchedResponses(false)}
          start={datePickerDates.start}
          end={datePickerDates.end}
        />
      )}
    </BSDropdown>
  );
};

export default OptionsDropdown;
