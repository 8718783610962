import React, { useCallback, useEffect, useState } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";

import { download } from "../utils/download-utils";

import { Modal, Table, Popover, Form, Tooltip } from "react-bootstrap";

import {
  MdCheck,
  MdWarning,
  MdClear,
  MdRefresh,
  MdTv,
  MdSearch,
  MdHeadphones,
} from "react-icons/md";
import { ReactComponent as DisplayIcon } from "../NavBar/Icons/Display Icon.svg";
import { ReactComponent as StreamingIcon } from "../NavBar/Icons/Streaming TV Icon.svg";

import {
  Page,
  DateRangePicker,
  BPMTable,
  FullPageSpinner,
  Img,
  Spinner,
  BPMButton,
  OverlayTrigger,
} from "../Components";
import { useSetError } from "../redux/modals";

import {
  MiscLambdaFetch,
  AdminLambdaFetch,
  LinearLambdaFetch,
  StreamingV2LambdaFetch,
  awaitJSON,
  pollS3,
} from "../utils/fetch-utils";

import "./Invoicing.scss";
import { staggeredAll } from "../utils/async";

const DATE_FORMAT = "yyyy-MM-dd";
const SPEND_DELTA_THRESHOLD = 100;

const defaultDates = () => {
  let start = R.pipe(Dfns.subMonths(1), Dfns.startOfMonth, Dfns.format(DATE_FORMAT))(new Date());
  let end = R.pipe(Dfns.subMonths(1), Dfns.endOfMonth, Dfns.format(DATE_FORMAT))(new Date());
  return { start, end };
};

const toCost = num => `$${(Math.round(num * 100) / 100).toLocaleString()}`;

const Check = () => (
  <div className="result pass">
    <MdCheck />
  </div>
);
const X = () => (
  <div className="result fail">
    <MdClear />
  </div>
);
const Warning = () => (
  <div className="result warning">
    <MdWarning />
  </div>
);

const Invoicing = () => {
  const setError = useSetError();
  const [invoicingDates, setInvoicingDates] = useState(defaultDates);
  const [companyList, setCompanyList] = useState();
  const [checkerMap, setCheckerMap] = useState({});
  const [loadingModal, setLoadingModal] = useState({});
  const [modalComparison, setModalComparison] = useState();
  const [generatingCSV, setGeneratingCSV] = useState({});
  const [fetchingAll, setFetchingAll] = useState(false);
  const [fetchingSummary, setFetchingSummary] = useState(false);
  const [companySpendTotals, setCompanySpendTotals] = useState();

  useEffect(() => {
    (async () => {
      try {
        let res = await MiscLambdaFetch("/invoicing_companies", {
          params: { start: invoicingDates.start, end: invoicingDates.end },
        });
        let data = await awaitJSON(res);
        setCompanyList(data);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [setError, invoicingDates.start, invoicingDates.end]);

  const fetchCheckerResults = useCallback(
    async (cid, start, end, mediaTypes) => {
      try {
        let streamingDeliveryMap = {};
        let invoicingInfo;

        let promises = [
          ...mediaTypes
            .filter(type => R.contains(type, ["streaming", "audio", "display"]))
            .map(async type => {
              let res = await StreamingV2LambdaFetch("/delivery_v2", {
                params: {
                  dimension: "network",
                  company: cid,
                  include_vod: false,
                  streaming_type: type,
                  start: start,
                  end: end,
                },
              });
              streamingDeliveryMap[type] = await awaitJSON(res);
            }),
          (async () => {
            let res = await AdminLambdaFetch(`/invoicing?start=${start}&end=${end}&company=${cid}`);
            invoicingInfo = await awaitJSON(res);
          })(),
        ];

        if (!companySpendTotals) {
          promises.push(
            (async () => {
              let res = await MiscLambdaFetch("/getESpend", {
                params: {
                  start,
                  end,
                },
              });
              let spendInfo = await awaitJSON(res);
              let map = {};
              for (let row of spendInfo) {
                map[row.company] = Math.round(parseFloat(row.sum) || 0);
              }
              setCompanySpendTotals(map);
            })()
          );
        }

        await Promise.all(promises);

        let deliveryTotals = {};
        if (!R.isEmpty(streamingDeliveryMap)) {
          for (let key of R.keys(streamingDeliveryMap)) {
            for (let network of streamingDeliveryMap[key]) {
              deliveryTotals[key] = (deliveryTotals[key] || 0) + network.cost;
            }
          }
        }

        setCheckerMap(current => ({
          ...current,
          [cid]: {
            needsWizard: invoicingInfo.needsWizard,
            linearSpotDetailCount: invoicingInfo.linearSpotDetailCount,
            invoiceSpend: Math.round(invoicingInfo.invoiceSpend),
            strDeliv: Math.round(deliveryTotals.streaming) || 0,
            audioDeliv: Math.round(deliveryTotals.audio) || 0,
            olvDeliv: Math.round(deliveryTotals.olv) || 0,
          },
        }));
      } catch (e) {
        setCheckerMap(current => {
          return { ...current, [cid]: {} };
        });
        setError({ message: e.message, reportError: e });
      }
    },
    [setError, companySpendTotals]
  );

  const [speed, setSpeed] = useState("3");

  const fetchAll = useCallback(async () => {
    setFetchingAll(true);

    for (let company of companyList) {
      setCheckerMap(current => {
        return { ...current, [company.cid]: { checking: true } };
      });
    }
    let promises = [];
    for (let company of companyList) {
      let { cid, MediaTypes } = company;
      promises.push(() =>
        fetchCheckerResults(cid, invoicingDates.start, invoicingDates.end, MediaTypes)
      );
    }
    await staggeredAll(promises, parseInt(speed) || 3);
    setFetchingAll(false);
  }, [fetchCheckerResults, companyList, invoicingDates.start, invoicingDates.end, speed]);

  const CHANNELS = ["Streaming", "Audio", "Display"];
  const HEADER = ["Company", "Linear", ...CHANNELS];
  const fetchSummary = async () => {
    setFetchingSummary(true);
    let res = await MiscLambdaFetch("/invoicing_summary", {
      params: { start: invoicingDates.start, end: invoicingDates.end },
    });
    let data = await awaitJSON(res);

    let summary = data.map(company => {
      const channelSummaries = R.fromPairs(
        R.map(channel => {
          let channelImpressions = company[`${channel.toLowerCase()}ImpressionsTotal`];
          if (channelImpressions) {
            if (channelImpressions > 1000) {
              return [channel, "T"];
            } else {
              return [channel, "F"];
            }
          } else {
            return [channel, "F"];
          }
        }, CHANNELS)
      );
      const rowObj = R.mergeLeft(
        { Company: company.company, Linear: company.linearSpendTotal ? "T" : "F" },
        channelSummaries
      );
      return R.map(header => rowObj[header], HEADER);
    });
    summary.unshift(HEADER);

    download(
      Papa.unparse(summary),
      `Invoicing_Summary_${invoicingDates.start}_${invoicingDates.end}.csv`,
      "text/csv"
    );
    setFetchingSummary(false);
  };

  return (
    <Page
      title="Invoicing"
      pageType="Invoicing"
      actions={
        <div className="invoicingPageActions">
          <BPMButton className="fetchAllButton" onClick={fetchAll} disabled={fetchingAll}>
            Fetch All
          </BPMButton>
          <OverlayTrigger
            placement="bottom right"
            overlay={
              <Tooltip>
                Increase to fetch all faster. Decrease if it makes your computer sad.
              </Tooltip>
            }
          >
            <Form.Control
              className="speed"
              placeholder="speed"
              type="number"
              value={speed}
              onChange={e => setSpeed(e.currentTarget.value)}
            />
          </OverlayTrigger>
          <BPMButton
            className="fetchSummaryButton"
            onClick={fetchSummary}
            disabled={fetchingSummary}
          >
            Summary
          </BPMButton>
          <DateRangePicker
            startDate={invoicingDates.start}
            endDate={invoicingDates.end}
            startDateId="invoicingStartDate"
            endDateId="invoicingEndDate"
            onChange={({ startDate, endDate }) =>
              setInvoicingDates({
                start: startDate,
                end: endDate,
              })
            }
          />
        </div>
      }
    >
      <div className="invoicingPageContainer">
        {companyList ? (
          <div className="invoicingTable">
            <BPMTable
              data={companyList}
              rowHeight={75}
              headers={[
                {
                  label: "Company",
                  name: "name",
                  width: 200,
                  renderer: data => {
                    return (
                      <div className="companyColumn">
                        <div className="companyLogo">
                          <Img
                            src={`https://cdn.blisspointmedia.com/companies/${data.cid}/logo.png`}
                          />
                        </div>
                        <div className="companyName">{data.name}</div>
                      </div>
                    );
                  },
                },
                {
                  label: "Checker Results",
                  name: "checker_results",
                  nonInteractive: true,
                  flex: 1,
                  minFlexWidth: 275,
                  renderer: data => {
                    let checkerInfo = checkerMap[data.cid];
                    let hasLinear = R.includes("tv", data.MediaTypes);
                    let hasStreaming = R.includes("streaming", data.MediaTypes);

                    let checkerColumnBody;
                    let checkerResultIcon;

                    if (!checkerInfo) {
                      checkerColumnBody = <div className="readyToCheck">Ready to Check</div>;
                    } else if ((checkerInfo || {}).checking) {
                      checkerColumnBody = <div className="readyToCheck">Checking...</div>;
                    } else if (checkerInfo.needsWizard) {
                      checkerColumnBody = <div className="readyToCheck">Wizarding: Incomplete</div>;
                      checkerResultIcon = <Warning />;
                    } else {
                      checkerResultIcon = <Check />;
                      let {
                        linearSpotDetailCount,
                        strDeliv,
                        audioDeliv,
                        olvDeliv,
                        invoiceSpend,
                      } = checkerInfo;

                      let linearBody;
                      let linearGood = !hasLinear || linearSpotDetailCount === 0;

                      if (!hasLinear) {
                        linearBody = (
                          <div className="pass logs">
                            <span>Unresolved Post Log </span>
                            <span>Spots: Unapplicable</span>
                          </div>
                        );
                      } else if (linearGood) {
                        linearBody = (
                          <div className="pass logs">
                            <span>Unresolved Post Log </span>
                            <span>Spots: None</span>
                          </div>
                        );
                      } else {
                        linearBody = (
                          <div className="fail logs">
                            <span>Unresolved Post Log </span>
                            <span>Spots: {linearSpotDetailCount}</span>
                          </div>
                        );
                      }

                      let spendTotal = Math.round(companySpendTotals[data.cid] || 0);

                      let spendDelta = Math.abs(invoiceSpend - spendTotal);
                      let streamingGood = spendDelta === 0;
                      let streamingOK = spendDelta < SPEND_DELTA_THRESHOLD;
                      let deliveryTotal = strDeliv + audioDeliv + olvDeliv;
                      let delivGood = deliveryTotal === invoiceSpend;

                      let streamingBody;

                      if (!hasStreaming) {
                        streamingBody = (
                          <div className="streaming pass">Streaming Spend: Unapplicable</div>
                        );
                      } else {
                        let color;
                        if (streamingGood) {
                          color = "pass";
                        } else if (streamingOK) {
                          checkerResultIcon = <Warning />;
                          color = "warning";
                        } else {
                          checkerResultIcon = <X />;
                          color = "fail";
                        }

                        streamingBody = (
                          <div className={`streaming ${color}`}>
                            <div className="streamingSpend">
                              {`Streaming Spend: Invoice: ${toCost(
                                invoiceSpend
                              )}, Spend Table: ${toCost(spendTotal)}, Δ: ${toCost(spendDelta)}`}
                            </div>
                            <div className={`deliverySpend ${delivGood ? "pass" : "warning"}`}>
                              {`Delivery: ${toCost(strDeliv)} (Str) + ${toCost(
                                audioDeliv
                              )} (Aud) + ${toCost(olvDeliv)} (OLV) = ${toCost(deliveryTotal)}`}
                              {!delivGood && (
                                <span
                                  className="modalButton"
                                  onClick={async () => {
                                    setLoadingModal(current => {
                                      return { ...current, [data.cid]: { checking: true } };
                                    });
                                    let invoicingRes = await AdminLambdaFetch(
                                      `/invoicing/check_delivery?start=${invoicingDates.start}&end=${invoicingDates.end}&company=${data.cid}`
                                    );
                                    let compareData = await awaitJSON(invoicingRes);
                                    setLoadingModal({});
                                    setModalComparison(compareData);
                                  }}
                                >
                                  {(loadingModal[data.cid] || {}).checking ? (
                                    <Spinner />
                                  ) : (
                                    <MdSearch size={20} />
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      }
                      checkerColumnBody = (
                        <>
                          <div className="pass">Wizarding: Complete</div>
                          {linearBody}
                          {streamingBody}
                        </>
                      );
                    }

                    return (
                      <div className="checkerResultsColumn">
                        {checkerColumnBody}
                        {checkerResultIcon}
                        <BPMButton
                          className="recheck"
                          disabled={(checkerInfo || {}).checking}
                          onClick={() => {
                            setCheckerMap(current => {
                              return { ...current, [data.cid]: { checking: true } };
                            });
                            fetchCheckerResults(
                              data.cid,
                              invoicingDates.start,
                              invoicingDates.end,
                              data.MediaTypes
                            );
                          }}
                        >
                          <MdRefresh />
                        </BPMButton>
                      </div>
                    );
                  },
                },
                {
                  label: "Download",
                  name: "MediaTypes",
                  width: 235,
                  nonInteractive: true,
                  renderer: data => {
                    let hasLinear = R.includes("tv", data.MediaTypes);
                    let { commission } = data;
                    return (
                      <div className="downloadColumn">
                        <div className="downloadButton">
                          {hasLinear && (
                            <OverlayTrigger
                              placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                              overlay={<Tooltip>linear</Tooltip>}
                            >
                              <BPMButton
                                onClick={() => {
                                  setGeneratingCSV({ [data.cid]: { downloadingLinear: true } });
                                  (async () => {
                                    // End date is exclusive
                                    let outerEnd = R.pipe(
                                      Dfns.parseISO,
                                      Dfns.addDays(1),
                                      Dfns.format(DATE_FORMAT)
                                    )(invoicingDates.end);

                                    let response = await LinearLambdaFetch("/network_summary", {
                                      params: {
                                        company: data.cid,
                                        start: invoicingDates.start,
                                        end: outerEnd,
                                        calendar: "true",
                                        commission,
                                      },
                                    });
                                    let networkSummary = await awaitJSON(response);
                                    download(
                                      networkSummary.csv,
                                      `${data.cid}-linear-${invoicingDates.start}_${invoicingDates.end}.csv`,
                                      "text/csv"
                                    );
                                    setGeneratingCSV({});
                                  })();
                                }}
                              >
                                {generatingCSV[data.cid] &&
                                generatingCSV[data.cid].downloadingLinear ? (
                                  <Spinner color="white" />
                                ) : (
                                  <MdTv />
                                )}
                              </BPMButton>
                            </OverlayTrigger>
                          )}
                        </div>
                        {R.map(
                          mediaType =>
                            R.includes(mediaType, data.MediaTypes) && (
                              <div className="downloadButton">
                                <OverlayTrigger
                                  placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                                  overlay={<Tooltip>{mediaType}</Tooltip>}
                                >
                                  <BPMButton
                                    onClick={() => {
                                      setGeneratingCSV({
                                        [data.cid]: { downloadingStreaming: true },
                                      });
                                      (async () => {
                                        const result = await MiscLambdaFetch("/kickOffLambda", {
                                          method: "POST",
                                          body: {
                                            fileType: "txt",
                                            lambdaArgs: {
                                              company: data.cid,
                                              start: invoicingDates.start,
                                              end: invoicingDates.end,
                                              commission,
                                              platform: mediaType,
                                            },
                                            lambdaName: "admin-generateInvoice",
                                          },
                                        });
                                        const cometUuid = await awaitJSON(result);
                                        const content = await pollS3({
                                          autoDownload: false,
                                          bucket: "bpm-cache",
                                          filename: `${cometUuid}.txt`,
                                          mimeType: "text/plain",
                                        });
                                        const textContent = await content.text();
                                        const lambdaResult = JSON.parse(textContent);
                                        await pollS3({
                                          bucket: "bpm-invoicing",
                                          mimetype:
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                          filename: `${lambdaResult.filename}.xlsx`,
                                        });
                                        setGeneratingCSV({});
                                      })();
                                    }}
                                  >
                                    {generatingCSV[data.cid] &&
                                    generatingCSV[data.cid].downloadingStreaming ? (
                                      <Spinner color="white" />
                                    ) : (
                                      (mediaType === "streaming" && <StreamingIcon />) ||
                                      (mediaType === "audio" && <MdHeadphones />) ||
                                      (mediaType === "display" && <DisplayIcon />)
                                    )}
                                  </BPMButton>
                                </OverlayTrigger>
                              </div>
                            ),
                          ["streaming", "audio", "display"]
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
            <Modal
              show={modalComparison && modalComparison.length}
              onHide={() => setModalComparison([])}
            >
              <Modal.Body>
                <div className="invoiceModal">
                  {modalComparison &&
                    modalComparison.length &&
                    R.map(
                      ({
                        network,
                        deliveryCost,
                        deliveryCount,
                        invoiceCost,
                        invoiceCount,
                        imps,
                        matched,
                      }) => {
                        return (
                          <div className="networkBreakdown">
                            <div className="networkName">{network}</div>
                            <div className="detail">
                              <div className="invoiceModalLabel">Invoice:</div>
                              <div className="cost">{toCost(invoiceCost)}</div>
                              <div className="count">
                                ({invoiceCount.toLocaleString()} impressions)
                              </div>
                            </div>
                            {matched ? (
                              <>
                                <div className="detail">
                                  <div className="invoiceModalLabel">Daily Delivery:</div>
                                  <div className="cost">{toCost(deliveryCost)}</div>
                                  <div className="count">
                                    ({deliveryCount.toLocaleString()} impressions)
                                  </div>
                                </div>
                                <div className="detail">
                                  <div className="invoiceModalLabel">Δ:</div>
                                  <div className="cost">{toCost(invoiceCost - deliveryCost)}</div>
                                  <div className="count">
                                    ({(invoiceCount - deliveryCount).toLocaleString()} impressions)
                                  </div>
                                </div>
                                <div className="impsHeader">Daily discrepancies:</div>

                                {R.prop("length", imps) ? (
                                  <Table bordered striped hover size="sm">
                                    <thead>
                                      <tr>
                                        {imps[0].property && <th>Property</th>}
                                        <th>Platform</th>
                                        <th>Date</th>
                                        <th>Invoice</th>
                                        <th>Delivery</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {R.map(
                                        ({
                                          invoiceCount,
                                          deliveryCount,
                                          notes,
                                          platform,
                                          date,
                                          property,
                                        }) => {
                                          let key = `${network}${platform}${
                                            property || "na"
                                          }${date}notes`;
                                          let content = (
                                            <tr key={key}>
                                              {property && <td>{property}</td>}
                                              <td>{platform}</td>
                                              <td>{date}</td>
                                              <td>
                                                {invoiceCount
                                                  ? invoiceCount.toLocaleString()
                                                  : "no match"}
                                              </td>
                                              <td>
                                                {deliveryCount
                                                  ? deliveryCount.toLocaleString()
                                                  : "no match"}
                                              </td>
                                            </tr>
                                          );

                                          if (notes) {
                                            content = (
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Popover id={key} title="Notes">
                                                    {notes}
                                                  </Popover>
                                                }
                                              >
                                                {content}
                                              </OverlayTrigger>
                                            );
                                          }

                                          return content;
                                        },
                                        imps
                                      )}
                                    </tbody>
                                  </Table>
                                ) : (
                                  "No daily impression discrepancies"
                                )}
                              </>
                            ) : (
                              <div className="detail">
                                <div className="invoiceModalLabel">Daily Delivery:</div>
                                <div className="cost">none</div>
                              </div>
                            )}
                          </div>
                        );
                      },
                      modalComparison
                    )}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <FullPageSpinner />
        )}
      </div>
    </Page>
  );
};

export default Invoicing;
