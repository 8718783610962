import React, { useEffect, useMemo, useState } from "react";

import moment from "moment-timezone";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Select from "react-select";

import { awaitJSON, S3SignedUrlFetch, LinearBuyingLambdaFetch } from "../utils/fetch-utils";

import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { Img, Page, FullPageSpinner, Spinner, DateRangePicker, BPMTable } from "../Components";

import "./CompetitiveSpots.scss";
import { formatMoneyAsInt } from "../utils/format-utils";

const DATE_FORMAT = "yyyy-MM-dd";

const DEFAULT_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(1),
  Dfns.format(DATE_FORMAT)
)(new Date());

const CUTOFF_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const INNER_CELL_HEIGHT = 35;

const FormattedHeaderCell = ({ headerData }) => (
  <div className="spots-table-header-cell">{headerData}</div>
);

const FormattedInnerCell = ({ data }) => <div className="spots-table-inner-cell">{data}</div>;

const headersRenderer = data => <FormattedHeaderCell headerData={data.data} />;

const totalsRenderer = ({ data, style = {}, classes = [] }) => (
  <div style={style} className={[...classes, "spots-table-totals-cell"].join(" ")}>
    {data}
  </div>
);

const colHeaders = [
  {
    label: "Network",
    name: "network",
    flex: 2,
    renderer: data => <FormattedInnerCell data={data.network} />,
  },
  {
    label: "Avail",
    name: "avail",
    width: 100,
    renderer: data => <FormattedInnerCell data={data.avail} />,
  },
  {
    label: "Creative",
    name: "creative",
    flex: 3,
    renderer: data => <FormattedInnerCell data={data.creative} />,
  },
  {
    label: "Length",
    name: "length",
    width: 100,
    renderer: data => <FormattedInnerCell data={data.length} />,
  },
  {
    label: "Broadcast Time (ET)",
    name: "broadcastTimeText",
    flex: 3,
    minFlexWidth: 200,
    renderer: data => <FormattedInnerCell data={data.broadcastTime} />,
  },
  {
    label: "Selling Rotation",
    name: "sellingRotation",
    flex: 2,
    minFlexWidth: 140,
    renderer: data => <FormattedInnerCell data={data.sellingRotation} />,
  },
  {
    label: "Est Cost",
    name: "netRate",
    flex: 1,
    renderer: data => <FormattedInnerCell data={formatMoneyAsInt(data.netRate)} />,
  },
  {
    label: "Program",
    name: "program",
    flex: 3,
    renderer: data => <FormattedInnerCell data={data.program} />,
  },
];

const SpotsTableContainer = ({ tableData, csvName }) => {
  // set the filteredData to the original data in the beginning because we are not applying any filters by default
  const [filteredData, setFilteredData] = useState(tableData);

  /*
    This is the totalsRow that will be displayed at the bottom of the table. We have to recompute the total cost and the
    total spots count anytime the *filtered* data changes (either because the original data changed, or because the
    applied filter(s) changed).
  */
  const finalRow = useMemo(() => {
    let totalSpotsCount = R.length(filteredData);
    let costTotal;
    if (!totalSpotsCount) {
      totalSpotsCount = 0;
      costTotal = 0;
    } else {
      costTotal = R.pipe(R.pluck("netRate"), R.sum)(filteredData);
    }
    return {
      network: "Total",
      creative: `Count: ${totalSpotsCount}`,
      netRate: formatMoneyAsInt(costTotal),
    };
  }, [filteredData]);

  return (
    <BPMTable
      alternateColors
      headers={colHeaders}
      data={tableData}
      totals={finalRow}
      headersRenderer={headersRenderer}
      totalsRenderer={totalsRenderer}
      csvDownload={csvName}
      rowHeight={INNER_CELL_HEIGHT}
      onFilteredDataChange={setFilteredData}
    />
  );
};

const CompetitiveSpots = () => {
  const setError = useSetError();

  const companyInfo = useCompanyInfo();

  const [selectedCompetitor, setSelectedCompetitor] = useState();

  const [competitorOptions, setCompetitorOptions] = useState();

  // start and end dates should both default to the Monday of the last full week
  const [weeks, setWeeks] = useState({
    start: DEFAULT_DATE,
    end: DEFAULT_DATE,
  });

  useEffect(() => {
    if (companyInfo.cid) {
      setSelectedCompetitor(companyInfo.default_competitor || companyInfo.competitors[0]);
      let { competitors } = companyInfo;
      let options = R.map(item => ({
        label: item,
        value: item,
      }))(competitors || []);
      setCompetitorOptions(options);
    }
  }, [companyInfo]);

  const [competitorSpotsDataMap, setCompetitorSpotsDataMap] = useState({});

  useEffect(() => {
    if (!selectedCompetitor || competitorSpotsDataMap[selectedCompetitor]) {
      return;
    }

    (async () => {
      try {
        let rawSpotsData = await S3SignedUrlFetch(
          `bpm-ml-data/competitive/latest/${selectedCompetitor}_spots.json.gz`
        );
        let spotsJson = await awaitJSON(rawSpotsData);

        setCompetitorSpotsDataMap(spotsData => ({
          ...spotsData,
          [selectedCompetitor]: spotsJson,
        }));
      } catch (e) {
        setError({
          message: `Failed to load spots data for ${selectedCompetitor}. Error: ${e.message}`,
          // This is a frequently failing lambda because competitors often have missing data. For now, just not
          // reporting the failure
          // reportError: e
        });
      }
    })();
  }, [selectedCompetitor, competitorSpotsDataMap, setError]);

  const competitorSpotsData = competitorSpotsDataMap[selectedCompetitor];

  const validDays = useMemo(() => {
    if (!weeks) {
      return [];
    }
    return Dfns.eachDayOfInterval({
      start: Dfns.parse(new Date(), DATE_FORMAT, weeks.start),
      end: Dfns.parse(new Date(), DATE_FORMAT, weeks.end),
    }).map(Dfns.format(DATE_FORMAT));
  }, [weeks]);

  // TODO: should move this to redux
  const [stationMap, setStationMap] = useState();

  useEffect(() => {
    if (stationMap) {
      return;
    }
    (async () => {
      try {
        let res = await LinearBuyingLambdaFetch("/networks");
        let map = await awaitJSON(res);
        let stationMap = {};
        Object.keys(map).forEach(network => {
          stationMap[network] = map[network].stationName;
        });

        setStationMap(stationMap);
      } catch (e) {
        setError({
          message: `Could not fetch station map. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [setError, stationMap]);

  /*
    Filter and transform the data so that it's in the correct format for passing it into the table.
  */
  const filteredWeeksData = useMemo(() => {
    if (!(validDays && competitorSpotsData && stationMap)) {
      return null;
    }

    let keys = R.filter(key => R.includes(key, validDays), R.keys(competitorSpotsData));
    let grouped = [];

    for (let week of keys) {
      let weekData = competitorSpotsData[week];

      for (let {
        stationName,
        avail,
        creative,
        spotLength,
        easternSpotTime,
        sellingRotation,
        netRate,
        program,
      } of weekData) {
        let tableRow = {
          network: stationMap[stationName] || stationName,
          avail,
          creative,
          length: spotLength,
          broadcastTime: moment(easternSpotTime)
            .tz("America/New_York")
            .format("ddd MMM D, h:mm:ss a"),
          broadcastTimeText: moment(easternSpotTime)
            .tz("America/New_York")
            .format("YYYY-MM-DD HH:mm:ss"),
          sellingRotation,
          netRate,
          program,
        };

        grouped.push(tableRow);
      }
    }
    return grouped;
  }, [validDays, competitorSpotsData, stationMap]);

  return (
    <Page
      title="Competitive Spots"
      pageType="Competitive Spots"
      minHeight={600}
      actions={
        <div className="competitiveSpotsActions">
          {selectedCompetitor && (
            <Img
              src={`https://cdn.blisspointmedia.com/competitors/${selectedCompetitor}.png`}
              className="companyLogo"
              title={selectedCompetitor}
              loader={
                <div className="logoNoImgBox">
                  <Spinner />
                </div>
              }
              unloader={
                <div className="logoNoImgBox logo404">
                  <span>{selectedCompetitor}</span>
                </div>
              }
            />
          )}

          <div className="competitorSelect">
            {selectedCompetitor && R.length(companyInfo.competitors) > 1 && (
              <Select
                onChange={value => {
                  setSelectedCompetitor(value.label);
                }}
                value={{ label: selectedCompetitor, value: selectedCompetitor }}
                options={competitorOptions}
              />
            )}
          </div>
          <DateRangePicker
            mondayOnly
            startDate={weeks.start}
            endDate={weeks.end}
            startDateId="competitiveSpotsStartDate"
            endDateId="competitiveSpotsEndDate"
            isOutsideRange={date => date > CUTOFF_DATE}
            onChange={({ startDate, endDate }) =>
              setWeeks({
                start: startDate,
                end: endDate,
              })
            }
          />
        </div>
      }
    >
      <div className="competitiveSpotsBody">
        {filteredWeeksData && selectedCompetitor ? (
          <SpotsTableContainer
            tableData={filteredWeeksData}
            csvName={`Competitive_${selectedCompetitor}_${weeks.start}-${weeks.end}.csv`}
          />
        ) : (
          <FullPageSpinner />
        )}
      </div>
    </Page>
  );
};

export default CompetitiveSpots;
