import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as R from "ramda";

import { Form, Button } from "react-bootstrap";
import { MdDeleteForever, MdSkipPrevious, MdSkipNext } from "react-icons/md";

import { ColumnHeader } from "@blisspointmedia/bpm-types/dist/Performance";

import { StateSetter } from "../../utils/types";

import { HeaderInfo } from "./configUtils";

interface SuperHeaderProps {
  info: Record<string, HeaderInfo | true | undefined>;
  onHeaderChange: StateSetter<ColumnHeader[]>;
  headers: ColumnHeader[];
  columnI: number;
  colCount: number;
}

const SuperHeader: React.FC<SuperHeaderProps> = ({
  info,
  onHeaderChange,
  headers,
  columnI,
  colCount,
}) => {
  const ourInfo = info[columnI];

  const onDelete = useCallback(() => {
    if (ourInfo && typeof ourInfo !== "boolean") {
      onHeaderChange(R.remove(ourInfo.headerIndex, 1));
    }
  }, [onHeaderChange, ourInfo]);

  const onChange = useCallback(
    <T extends keyof ColumnHeader>(key: T, value: ColumnHeader[T]) => {
      if (ourInfo && typeof ourInfo !== "boolean") {
        onHeaderChange(current =>
          R.update(
            ourInfo.headerIndex,
            {
              ...current[ourInfo.headerIndex],
              [key]: value,
            },
            current
          )
        );
      }
    },
    [onHeaderChange, ourInfo]
  );

  const { left, right, size, canLeft, canRight } = useMemo(() => {
    if (ourInfo && typeof ourInfo !== "boolean") {
      let [lastHeader, header, nextHeader] = [
        headers[ourInfo.headerIndex - 1],
        headers[ourInfo.headerIndex],
        headers[ourInfo.headerIndex + 1],
      ];
      return {
        canLeft: header.start > 0 && (!lastHeader || lastHeader.end < header.start - 1),
        canRight: header.end < colCount - 1 && (!nextHeader || nextHeader.start > header.end + 1),
        left: header.start,
        right: header.end,
        size: header.end - header.start + 1,
      };
    }
    return {};
  }, [ourInfo, headers, colCount]);

  const [localText, setLocalText] = useState(
    ourInfo && typeof ourInfo !== "boolean" ? ourInfo.text : ""
  );

  useEffect(() => {
    setLocalText(ourInfo && typeof ourInfo !== "boolean" ? ourInfo.text : "");
  }, [ourInfo]);

  return ourInfo ? (
    typeof ourInfo !== "boolean" ? (
      <div className="superHeaderContainer">
        <div style={{ width: `${ourInfo.width}px` }}>
          <div className="sizerBox">
            {canLeft && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => onChange("start", (left || 0) - 1)}
              >
                <MdSkipPrevious />
              </Button>
            )}
            {(size || 0) > 1 && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => onChange("start", (left || 0) + 1)}
              >
                <MdSkipNext />
              </Button>
            )}
          </div>
          <Form.Control
            size="sm"
            value={localText}
            onBlur={() => onChange("text", localText)}
            onChange={e => setLocalText(e.currentTarget.value)}
          />
          <Button size="sm" variant="danger" onClick={onDelete}>
            <MdDeleteForever />
          </Button>
          <div className="sizerBox">
            {(size || 0) > 1 && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => onChange("end", (right || 0) - 1)}
              >
                <MdSkipPrevious />
              </Button>
            )}
            {canRight && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => onChange("end", (right || 0) + 1)}
              >
                <MdSkipNext />
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : null
  ) : null;
};

export default SuperHeader;
