import React, { useState, useCallback, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { MdAdd, MdOutlineAdd, MdOutlineDeleteOutline } from "react-icons/md";
import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import "./Onboarding.scss";
import { Button, ButtonType } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

export interface FormLayoutProps {
  columns: {
    title: string;
    type: "header" | "email" | "text" | "button" | "checkbox" | "select";
    width?: number;
    required?: boolean;
    children?: React.ReactNode;
    selectOptions?: string[];
    textPlaceholder?: string;
  }[];
  rows: {
    id: string;
    questionNumber: string;
    title: string;
    subtitle?: string;
  }[];
}

interface OnboardingFreeFormProps {
  questions: FormLayoutProps;
  responses: Response[];
  onDataChange: (responses: Response[]) => void;
  renderAddButton?: boolean;
}

const OnboardingFreeForm: React.FC<OnboardingFreeFormProps> = ({
  questions,
  responses,
  onDataChange,
  renderAddButton = false,
}) => {
  const currentResponses: Response[] = useMemo(() => responses || [], [responses]);

  const { initialFormValues, initialDynamicRows } = useMemo(() => {
    if (!responses || responses.length === 0) {
      return {
        initialFormValues: {} as { [key: string]: string | boolean },
        initialDynamicRows: {} as { [key: string]: any[] },
      };
    }
    return responses.reduce(
      (acc, response) => {
        response.selections.forEach(selection => {
          const questionRow = questions.rows.find(
            row => row.questionNumber === response.questionNumber
          );
          if (questionRow) {
            const column = questions.columns.find(col => col.title === selection.label);

            if (column) {
              switch (column.type) {
                case "checkbox":
                  acc.initialFormValues[`${questionRow.id}-${selection.label}`] =
                    typeof selection.openText === "boolean" ? selection.openText : false;
                  break;
                case "select":
                  acc.initialFormValues[`${questionRow.id}-${selection.label}`] =
                    selection.openText || "";
                  break;
                default:
                  acc.initialFormValues[`${questionRow.id}-${selection.label}`] =
                    selection.openText || "";
                  break;
              }
            }
          }
        });
        return acc;
      },
      {
        initialFormValues: {} as { [key: string]: string | boolean },
        initialDynamicRows: {} as { [key: string]: any[] },
      }
    );
  }, [responses, questions.rows, questions.columns]);

  const [formValues, setFormValues] = useState<{ [key: string]: string | boolean }>(
    initialFormValues
  );
  const [dynamicRows, setDynamicRows] = useState<{ [key: string]: any[] }>(initialDynamicRows);

  const handleInputChange = (e: any, rowId: string, columnTitle: string) => {
    const updatedFormValues = {
      ...formValues,
      [`${rowId}-${columnTitle}`]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    };
    setFormValues(updatedFormValues);

    onDataChange(updateResponseData(updatedFormValues));
  };

  const updateResponseData = useCallback(
    (updatedFormValues = formValues) => {
      const updatedResponses = [...currentResponses];

      const findResponseIndex = (questionNumber: string) => {
        return updatedResponses.findIndex(res => res.questionNumber === questionNumber);
      };

      questions.rows.forEach(row => {
        const selections = questions.columns
          .filter(column => column.type !== "button" && column.type !== "header")
          .map(column => ({
            label: column.title,
            openText: updatedFormValues[`${row.id}-${column.title}`] || "",
          }));

        const responseIndex = findResponseIndex(row.questionNumber);

        if (responseIndex !== -1) {
          updatedResponses[responseIndex] = {
            ...updatedResponses[responseIndex],
            selections: selections,
          };
        } else {
          updatedResponses.push({
            questionNumber: row.questionNumber,
            selections: selections,
          });
        }
      });

      Object.keys(dynamicRows).forEach(rowId => {
        dynamicRows[rowId].forEach(dynamicRow => {
          const selections = questions.columns
            .filter(column => column.type !== "button" && column.type !== "header")
            .map(column => ({
              label: column.title,
              openText: updatedFormValues[`${dynamicRow.id}-${column.title}`] || "",
            }));

          const responseIndex = findResponseIndex(dynamicRow.questionNumber);

          if (responseIndex !== -1) {
            updatedResponses[responseIndex] = {
              ...updatedResponses[responseIndex],
              selections: selections,
            };
          } else {
            updatedResponses.push({
              questionNumber: dynamicRow.questionNumber,
              selections: selections,
            });
          }
        });
      });

      return updatedResponses;
    },
    [formValues, dynamicRows, questions.columns, questions.rows, currentResponses]
  );

  const handleAddRow = (e: any, rowId: string) => {
    e.preventDefault();

    setDynamicRows(prevRows => {
      const currentRows = prevRows[rowId] || [];
      const totalRows = currentRows.length + 1;

      if (totalRows >= 3) {
        return prevRows;
      }

      const newRowId = `${rowId}-${currentRows.length + 1}`;
      const baseQuestionNumber = questions.rows.find(r => r.id === rowId)?.questionNumber;
      const newQuestionNumber = `${baseQuestionNumber}-${currentRows.length + 1}`;

      const updatedDynamicRows = {
        ...prevRows,
        [rowId]: [
          ...currentRows,
          {
            id: newRowId,
            questionNumber: newQuestionNumber,
          },
        ],
      };

      onDataChange(updateResponseData(formValues));
      return updatedDynamicRows;
    });
  };

  const handleDeleteRow = (rowId: string, dynamicRowId: string) => {
    setDynamicRows(prevRows => {
      const updatedDynamicRows = {
        ...prevRows,
        [rowId]: prevRows[rowId].filter(row => row.id !== dynamicRowId),
      };

      const updatedFormValues = { ...formValues };
      Object.keys(updatedFormValues).forEach(key => {
        if (key.startsWith(dynamicRowId)) {
          delete updatedFormValues[key];
        }
      });

      onDataChange(updateResponseData(updatedFormValues));

      setFormValues(updatedFormValues);
      return updatedDynamicRows;
    });
  };

  return (
    <div>
      <Form>
        <Row>
          {questions.columns.map((column, index) => (
            <Col key={index} lg={column.width}>
              <h6>{column.title}</h6>
            </Col>
          ))}
        </Row>

        {questions.rows.map(row => (
          <div key={row.id} className="onboardingFreeForm">
            <Row>
              {questions.columns.map((column, colIndex) => {
                if (column.type === "header") {
                  return (
                    <Col key={colIndex} lg={column.width}>
                      <h5>{row.title}</h5>
                      <span>{row.subtitle}</span>
                    </Col>
                  );
                }

                switch (column.type) {
                  case "email":
                  case "text":
                    return (
                      <Col key={colIndex} lg={column.width}>
                        <Form.Group controlId={`${row.id}-${column.title}`}>
                          <Form.Control
                            type={column.type}
                            value={String(formValues[`${row.id}-${column.title}`] ?? "")}
                            onChange={e => handleInputChange(e, row.id, column.title)}
                            placeholder={column.textPlaceholder || ""}
                          />
                        </Form.Group>
                      </Col>
                    );

                  case "checkbox":
                    return (
                      <Col key={colIndex} lg={column.width}>
                        <Form.Check
                          type="checkbox"
                          checked={formValues[`${row.id}-${column.title}`] === true}
                          onChange={e => handleInputChange(e, row.id, column.title)}
                          id={`${row.id}-${column.title}`}
                        />
                      </Col>
                    );

                  case "select":
                    return (
                      <Col key={colIndex} lg={column.width}>
                        <Form.Control
                          as="select"
                          value={String(formValues[`${row.id}-${column.title}`]) || ""}
                          onChange={e => handleInputChange(e, row.id, column.title)}
                        >
                          <option value="">Select one</option>
                          {column.selectOptions?.map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    );

                  case "button":
                    return (
                      <Col key={colIndex} lg={column.width}>
                        <Button
                          variant={ButtonFrameworkVariant.ICON_ONLY}
                          type={ButtonType.EMPTY}
                          icon={<MdOutlineAdd />}
                          onClick={e => handleAddRow(e, row.id)}
                          disabled={dynamicRows[row.id]?.length >= 2}
                        />
                      </Col>
                    );
                  default:
                    return null;
                }
              })}
            </Row>

            {dynamicRows[row.id] &&
              dynamicRows[row.id].map(dynamicRow => (
                <Row key={dynamicRow.id}>
                  {questions.columns.map((column, colIndex) => {
                    if (column.type === "header") {
                      return <Col key={colIndex} lg={column.width} />;
                    }
                    switch (column.type) {
                      case "email":
                      case "text":
                        return (
                          <Col key={colIndex} lg={column.width}>
                            <Form.Group controlId={`${dynamicRow.id}-${column.title}`}>
                              <Form.Control
                                type={column.type}
                                value={String(formValues[`${dynamicRow.id}-${column.title}`] ?? "")}
                                onChange={e => handleInputChange(e, dynamicRow.id, column.title)}
                                placeholder={column.textPlaceholder || ""}
                              />
                            </Form.Group>
                          </Col>
                        );
                      case "checkbox":
                        return (
                          <Col key={colIndex} lg={column.width}>
                            <Form.Check
                              type="checkbox"
                              checked={formValues[`${row.id}-${column.title}`] === true}
                              onChange={e => handleInputChange(e, row.id, column.title)}
                              id={`${row.id}-${column.title}`}
                            />
                          </Col>
                        );

                      case "select":
                        return (
                          <Col key={colIndex} lg={column.width}>
                            <Form.Control
                              as="select"
                              value={String(formValues[`${row.id}-${column.title}`]) || ""}
                              onChange={e => handleInputChange(e, row.id, column.title)}
                            >
                              <option value="">Select one</option>
                              {column.selectOptions?.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        );
                      case "button":
                        return (
                          <Col key={colIndex} lg={column.width}>
                            <Button
                              variant={ButtonFrameworkVariant.ICON_ONLY}
                              type={ButtonType.EMPTY}
                              icon={<MdOutlineDeleteOutline />}
                              onClick={() => handleDeleteRow(row.id, dynamicRow.id)}
                            />
                          </Col>
                        );
                      default:
                        return null;
                    }
                  })}
                </Row>
              ))}
          </div>
        ))}
        {renderAddButton && (
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<MdAdd />}
            onClick={e => {
              const newRowId = `custom-${Date.now()}`;
              handleAddRow(e, newRowId);
            }}
          >
            Add Custom Platform / Source
          </Button>
        )}
      </Form>
    </div>
  );
};

export default OnboardingFreeForm;
