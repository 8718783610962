import React, { useEffect, useState, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MdCheckBox } from "react-icons/md";

import { Modal, Form, Table } from "react-bootstrap";

import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useStateFunction } from "../utils/hooks/useData";
import { useSetError } from "../redux/modals";

import {
  Page,
  FullPageSpinner,
  Spinner,
  SingleDatePicker,
  CheckBox,
  BPMButton,
  OldFilterBar,
} from "../Components";

import { NetworkMap, NetworkMetadata } from "@blisspointmedia/bpm-types/dist/LogsTracker";
import { StateSetter } from "../utils/types";

import "./LogsTracker.scss";
import { downloadJSONToCSV } from "../utils/download-utils";
import { HiDownload } from "react-icons/hi";

const DATE_FORMAT = "yyyy-MM-dd";

const FILTER_BAR_OPTIONS = [
  { name: "network", label: "Network" },
  { name: "company", label: "Company" },
];

interface ColumnComponentProps {
  filteredData: NetworkMetadata[];
  setSelectedNetwork: StateSetter<string>;
}

const NotStarted: React.FC<ColumnComponentProps> = ({ filteredData, setSelectedNetwork }) => {
  return (
    <div className="column">
      <div className="title">Not Started</div>
      <div className="boxesList">
        {filteredData
          .filter(network => {
            // To be in "Not Started", nothing is checked off and there are no notes (network or company)
            const { networkNotes, companies } = network;

            let notStarted = true;

            if (networkNotes) {
              notStarted = false;
              return notStarted;
            }

            for (let company of R.values(companies)) {
              if (company.companyNotes) {
                notStarted = false;
                break;
              }
              if (company.checked) {
                notStarted = false;
                break;
              }
              if (company.didNotRun) {
                notStarted = false;
                break;
              }
            }
            return notStarted;
          })
          .map(network => {
            return (
              <div
                key={network.network}
                className="box"
                onClick={() => setSelectedNetwork(network.network)}
              >
                {network.network}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const InProgress: React.FC<ColumnComponentProps> = ({ filteredData, setSelectedNetwork }) => {
  return (
    <div className="column">
      <div className="title">In Progress</div>
      <div className="boxesList">
        {filteredData
          .filter(network => {
            // To be in "In Progress", there are some amount of companies checked off or notes added,
            // but every company can't be checked off (that would mean it's completed).
            const { networkNotes, companies } = network;

            let inProgress = false;
            let allCompleted = true;

            if (networkNotes) {
              inProgress = true;
            }

            for (let company of R.values(companies)) {
              if (company.companyNotes) {
                inProgress = true;
              }
              if (company.checked) {
                inProgress = true;
              }
              if (company.didNotRun) {
                inProgress = true;
              }
              if (!company.checked && !company.didNotRun) {
                allCompleted = false;
              }
            }

            return allCompleted ? false : inProgress;
          })
          .map(network => {
            // See what companies still need to be checked off. We display this in the box.
            let stillMissing = R.values(network.companies)
              .filter(company => !company.checked && !company.didNotRun)
              .map(company => company.company);
            return (
              <div
                key={network.network}
                className="box"
                onClick={() => setSelectedNetwork(network.network)}
              >
                <div>{network.network}</div>
                <div className="missingCompanies">
                  <span className="label">Missing: </span>
                  {stillMissing.join(", ")}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Completed: React.FC<ColumnComponentProps> = ({ filteredData, setSelectedNetwork }) => {
  return (
    <div className="column">
      <div className="title">Completed</div>
      <div className="boxesList">
        {filteredData
          .filter(network => {
            // To be in "Completed", all companies are checked off (either "Log In" or "DNR")
            const { companies } = network;
            let completed = true;

            for (let company of R.values(companies)) {
              if (!company.checked && !company.didNotRun) {
                completed = false;
                break;
              }
            }
            return completed;
          })
          .map(network => {
            // Check if all companies Did Not Run for this network. If so, add CSS class.
            let allDNR = true;
            for (let company of R.values(network.companies)) {
              if (!company.didNotRun) {
                allDNR = false;
              }
            }

            return (
              <div
                key={network.network}
                className={`box${allDNR ? " allDNR" : ""}`}
                onClick={() => setSelectedNetwork(network.network)}
              >
                {network.network}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const LogsTracker = ({ "*": path }: { "*": string }): JSX.Element => {
  // Pre Logs date defaults to this week, Post Logs defaults to last week.
  const CURRENT_WEEK =
    path === "pre"
      ? R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date())
      : R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(1), Dfns.format(DATE_FORMAT))(new Date());

  // This is in case someone puts a random word in the URL path. We only want the type options to
  // be "pre" or "post" because we pass this to the APIs.
  const type = path === "pre" ? "pre" : "post";

  const setError = useSetError();
  const [week, setWeek] = useState(CURRENT_WEEK);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const [rawData, setRawData] = useState([]);
  const [networkMap, setNetworkMap] = useState<NetworkMap>();
  const [localChanges, setLocalChanges] = useState<NetworkMap>({});

  // If the path changes via the navbar (i.e. they switch from Pre to Post) we want to reset networkMap
  // so that we refetch the data.
  useEffect(() => setNetworkMap(undefined), [path]);

  const combinedData = useMemo(
    () => (networkMap ? R.mergeDeepRight(networkMap, localChanges) : {}) as NetworkMap,
    [networkMap, localChanges]
  );

  const combinedDataArray = useMemo(
    () =>
      R.values(combinedData).map(row => ({
        ...row,
        // Adding an additional property, "company", that is just a joined string of all the companies for that network.
        // This is so we can filter on company using the filter bar.
        company: R.keys(row.companies).join(","),
      })),
    [combinedData]
  );

  const [filter, setFilter] = useStateFunction<(line: typeof combinedDataArray[number]) => boolean>(
    () => true
  );

  const filteredData = useMemo(() => {
    if (combinedDataArray) {
      let filtered = combinedDataArray.filter(filter);
      let sorted = R.sortBy(row => row.network, filtered);
      return sorted;
    } else {
      return [];
    }
  }, [combinedDataArray, filter]);

  useEffect(() => {
    if (networkMap) {
      return;
    }
    (async () => {
      try {
        let res = await LinearBuyingLambdaFetch("/logs_tracker", {
          params: {
            week,
            type,
          },
        });
        let data = await awaitJSON(res);

        setRawData(data);

        let networkMap: NetworkMap = {};

        for (let row of data) {
          const { network, company, network_notes, company_notes, checked, didNotRun } = row;

          networkMap = R.mergeDeepRight(networkMap, {
            [network]: {
              network,
              networkNotes: network_notes,
              companies: {
                [company]: {
                  company,
                  companyNotes: company_notes,
                  checked,
                  didNotRun,
                },
              },
            },
          });
        }

        setNetworkMap(networkMap);
      } catch (e) {
        setError({ message: `Failed to fetch ${e.message}`, reportError: e });
      }
    })();
  }, [week, networkMap, setError, type]);

  const checkAll = (selectedNetwork: string) => {
    if (networkMap) {
      let allChecked = {};

      for (let company of R.values(networkMap[selectedNetwork].companies)) {
        allChecked[company.company] = { checked: true };
      }

      setLocalChanges(current => {
        return R.mergeDeepRight(current, {
          [selectedNetwork]: {
            companies: allChecked,
          },
        }) as NetworkMap;
      });
    }
  };

  const saveChanges = async () => {
    try {
      setSaving(true);
      await LinearBuyingLambdaFetch("/update_logs_tracker", {
        method: "POST",
        body: { edits: localChanges, week, type },
      });
      setLocalChanges({});
      setNetworkMap(undefined);
      setSaving(false);
    } catch (e) {
      setError({ message: `Failed to save changes ${e.message}`, reportError: e });
      setSaving(false);
    }
  };

  const downloadAsCSV = () => {
    downloadJSONToCSV(rawData, `${path === "pre" ? "Pre" : "Post"} Logs Tracker - ${week}`);
  };

  return (
    <Page
      title={`${path === "pre" ? "Pre" : "Post"} Logs Tracker`}
      pageType={`${path === "pre" ? "Pre" : "Post"} Logs Tracker`}
      actions={
        <div className="linearOrdersActions">
          {!R.isEmpty(localChanges) && (
            <BPMButton
              onClick={() => {
                setLocalChanges({});
              }}
              variant="danger"
            >
              Clear Changes
            </BPMButton>
          )}
          {!R.isEmpty(localChanges) && (
            <BPMButton onClick={() => saveChanges()} variant="success">
              {saving ? <Spinner /> : "Save Changes"}
            </BPMButton>
          )}
          <BPMButton icon={<HiDownload />} onClick={downloadAsCSV}>
            Download
          </BPMButton>
          <SingleDatePicker
            mondayOnly
            date={week}
            onChange={date => {
              setWeek(date);
              setNetworkMap(undefined);
              setLocalChanges({});
            }}
          />
        </div>
      }
    >
      {networkMap ? (
        <div className="logsTrackerPage">
          <OldFilterBar
            options={FILTER_BAR_OPTIONS}
            lines={combinedDataArray}
            onFilter={setFilter}
          />
          <div className="columnsContainer">
            <NotStarted filteredData={filteredData} setSelectedNetwork={setSelectedNetwork} />
            <InProgress filteredData={filteredData} setSelectedNetwork={setSelectedNetwork} />
            <Completed filteredData={filteredData} setSelectedNetwork={setSelectedNetwork} />
          </div>
        </div>
      ) : (
        <FullPageSpinner />
      )}
      {selectedNetwork && combinedData && (
        <Modal
          show
          size="lg"
          onHide={() => {
            setSelectedNetwork("");
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedNetwork}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              as="textarea"
              placeholder="Network notes"
              rows={4}
              value={R.path([selectedNetwork, "networkNotes"], combinedData) || ""}
              onChange={e => {
                let note = e.target.value;
                setLocalChanges(current => {
                  return R.mergeDeepRight(current, {
                    [selectedNetwork]: {
                      networkNotes: note,
                    },
                  }) as NetworkMap;
                });
              }}
            />
            <BPMButton className="checkAllButton" onClick={() => checkAll(selectedNetwork)}>
              <MdCheckBox />
              Check All
            </BPMButton>
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="in">Log In?</th>
                  {type === "post" && <th className="in">DNR</th>}
                  <th className="company">Company</th>
                  <th className="notes">Notes</th>
                </tr>
              </thead>
              <tbody>
                {combinedData &&
                  R.values((combinedData[selectedNetwork] || {}).companies).map(company => {
                    return (
                      <tr key={company.company}>
                        <td>
                          <CheckBox
                            checked={company.checked}
                            onCheck={check =>
                              setLocalChanges(current => {
                                return R.mergeDeepRight(current, {
                                  [selectedNetwork]: {
                                    companies: {
                                      [company.company]: {
                                        checked: check,
                                      },
                                    },
                                  },
                                }) as NetworkMap;
                              })
                            }
                          />
                        </td>
                        {type === "post" && (
                          <td>
                            <CheckBox
                              checked={company.didNotRun}
                              onCheck={check =>
                                setLocalChanges(current => {
                                  return R.mergeDeepRight(current, {
                                    [selectedNetwork]: {
                                      companies: {
                                        [company.company]: {
                                          didNotRun: check,
                                        },
                                      },
                                    },
                                  }) as NetworkMap;
                                })
                              }
                            />
                          </td>
                        )}
                        <td>{company.company}</td>
                        <td>
                          <Form.Control
                            as="textarea"
                            value={company.companyNotes || ""}
                            onChange={e => {
                              let note = e.target.value;
                              setLocalChanges(current => {
                                return R.mergeDeepRight(current, {
                                  [selectedNetwork]: {
                                    companies: {
                                      [company.company]: {
                                        companyNotes: note,
                                      },
                                    },
                                  },
                                }) as NetworkMap;
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <BPMButton variant="primary" onClick={() => setSelectedNetwork("")}>
              Okay
            </BPMButton>
          </Modal.Footer>
        </Modal>
      )}
    </Page>
  );
};

export default LogsTracker;
