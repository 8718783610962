import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import * as UserRedux from "../../redux/user";
import { useSetError } from "../../redux/modals";
import OnboardingFormPage from "../OnboardingFormPage";
import OnboardingFreeForm from "../OnboardingFreeForm";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { primaryContacts, budgets, calendar, metrics } from "./CoreReportingQuestions";
import { saveClientInfo, getCompletedPercentage } from "../OnboardingUtils";

enum CoreReportingTabs {
  CONTACT_INFO = "contact-info",
  BUDGETS = "budgets",
  CALENDAR = "calendar",
  METRICS = "metrics",
}

interface CoreReportingProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
}

const CoreReporting: React.FC<CoreReportingProps> = ({ clientInfo, onPercentageChange }) => {
  const [initialResponses, setInitialResponses] = useState<Response[]>(clientInfo.responses);
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [saveSubmitting, setSaveSubmitting] = useState<boolean>(false);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);

  useEffect(() => {
    setSaveSubmitting(initialResponses !== allResponses.responses);
  }, [initialResponses, allResponses]);

  const contactInfoQuestionsAnswered = useCallback((): Response[] => {
    const questions = allResponses.responses.filter(response =>
      response.questionNumber.startsWith("3A")
    );
    return questions.filter(
      question => question.selections.map(selection => selection.openText !== "").length > 0
    );
  }, [allResponses.responses]);

  const [contactInfoCompleted, setContactInfoCompleted] = useState<number>(
    getCompletedPercentage(contactInfoQuestionsAnswered(), primaryContacts.rows.length, "3A")
  );
  const [budgetsCompleted, setBudgetCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgets.length, "3B")
  );
  const [calendarCompleted, setCalendarCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, calendar.length, "3C")
  );
  const [metricsCompleted, setMetricsCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, metrics.length, "3D")
  );

  const handleCoreReportingChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
  };

  useEffect(() => {
    const totalPercentage = getCompletedPercentage(
      allResponses.responses,
      primaryContacts.rows.length + calendar.length + budgets.length + metrics.length,
      "3"
    );
    setContactInfoCompleted(
      getCompletedPercentage(contactInfoQuestionsAnswered(), primaryContacts.rows.length, "3A")
    );
    setBudgetCompleted(getCompletedPercentage(allResponses.responses, budgets.length, "3B"));
    setCalendarCompleted(getCompletedPercentage(allResponses.responses, calendar.length, "3C"));
    setMetricsCompleted(getCompletedPercentage(allResponses.responses, metrics.length, "3D"));
    onPercentageChange(totalPercentage);
  }, [allResponses, onPercentageChange, contactInfoQuestionsAnswered]);

  const renderTabs = [
    {
      label: "Primary Contacts for:",
      headerLabel: "A. Contact Info",
      key: CoreReportingTabs.CONTACT_INFO,
      percentage: contactInfoCompleted,
      children: (
        <OnboardingFreeForm
          responses={allResponses.responses}
          onDataChange={handleCoreReportingChange}
          questions={primaryContacts}
        />
      ),
    },
    {
      label: "Budgets",
      headerLabel: "B. Budgets",
      key: CoreReportingTabs.BUDGETS,
      percentage: budgetsCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgets}
          onChange={handleCoreReportingChange}
        />
      ),
    },
    {
      label: "Calendar",
      headerLabel: "C. Calendar",
      key: CoreReportingTabs.CALENDAR,
      percentage: calendarCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={calendar}
          onChange={handleCoreReportingChange}
        />
      ),
    },
    {
      label: "Metrics",
      headerLabel: "D. Metrics",
      key: CoreReportingTabs.METRICS,
      percentage: metricsCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={metrics}
          onChange={handleCoreReportingChange}
        />
      ),
    },
  ];

  const handleSave = async () => {
    try {
      await saveClientInfo({
        company: allResponses.company,
        responses: allResponses.responses,
        user,
      });
      setInitialResponses(allResponses.responses);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={CoreReportingTabs.CONTACT_INFO}
      tabs={Object.values(CoreReportingTabs)}
      saveSubmitting={saveSubmitting}
      onSave={handleSave}
    />
  );
};

export default CoreReporting;
