import React from "react";

import { RowType } from "./pendingChangesUtils";
import { MdArrowDownward } from "react-icons/md";
import { Header } from "../StickyTable";

import "./PendingChangesPanel.scss";

interface PendingEditedRowProps<R> {
  oldRow: R;
  newRow: R;
  columns: Header[];
}

const PendingEditedRow = <R extends RowType>({
  oldRow,
  newRow,
  columns,
}: PendingEditedRowProps<R>): JSX.Element => {
  return (
    <div className="pendingChangesRowContainer pendingEditRowContainer">
      {columns.map(col => {
        const { label, field, renderer } = col;
        const oldVal = oldRow[field || ""] || "null";
        const newVal = newRow[field || ""] || "null";
        return (
          <div key={label}>
            {oldVal !== newVal ? (
              <div className="pendingChangesRow">
                <b className="pendingChangesRowLabel">{label}:</b>
                <div className="pendingChangesRowVal">
                  <div className="oldVal">{renderer ? renderer(oldRow) : oldVal}</div>
                  <div className="pendingChangesArrow">
                    <MdArrowDownward />
                  </div>
                  <div className="newVal">{renderer ? renderer(newRow) : newVal}</div>
                </div>
              </div>
            ) : (
              <div className="pendingChangesRow">
                <b className="pendingChangesRowLabel">{label}:</b>
                <div>{renderer ? renderer(newRow) : newVal}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PendingEditedRow;
