import React, { CSSProperties, useMemo } from "react";
import { Popover } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

interface BPMPopoverProps {
  title?: string;
  className?: string;
  clickable?: boolean;
  style?: CSSProperties;
  [passthroughProp: string]: any;
}

export const BPMPopover: React.FC<BPMPopoverProps> = ({
  title,
  className: providedClassName,
  style: providedStyle = {},
  clickable,
  children,
  ...passthrough
}) => {
  const id = useMemo(() => uuidv4(), []);
  const className = useMemo(() => {
    let classes = ["BPMPopover"];

    if (providedClassName) {
      classes.push(providedClassName);
    }
    return classes.join(" ");
  }, [providedClassName]);
  const style = useMemo(() => {
    const style = {
      ...providedStyle,
    };
    if (!clickable) {
      style.pointerEvents = "none";
    }
    return style;
  }, [providedStyle, clickable]);
  return (
    <Popover id={id} className={className} style={style} {...passthrough}>
      {title && <Popover.Title>{title}</Popover.Title>}
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
};
