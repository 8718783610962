import { Spinner } from "./Spinner";
import * as R from "ramda";
import BarChart from "./BarChart/BarChart";
import InnerLineChartBox from "./InnerLineChartBox";
import React, { useEffect, useMemo } from "react";

export interface FunnelChartData {
  count: number;
  fill: string;
  name: string;
}

interface FunnelDynamicsChartProps {
  chartData: any[];
  chartType: string;
  color: string;
  height?: number;
  width?: number;
  onPathLoad?: (loaded: boolean) => void;
}

export const FunnelDynamicsChart = React.forwardRef<SVGSVGElement | null, FunnelDynamicsChartProps>(
  ({ chartData, chartType, color, height, width, onPathLoad }, ref) => {
    useEffect(() => {
      if (chartData.length > 1) {
        if (onPathLoad) {
          onPathLoad(true);
        }
      }
    }, [onPathLoad, chartData.length]);

    const graph = useMemo(() => {
      let index = 0;
      let step = chartData.length < 10 ? 1 : Math.round(chartData.length / 10);
      let fill = "#000000";
      let fontSize = 16;
      let position = "top" as const;
      const revDataLabel = {
        fill,
        fontSize,
        position,
        formatter: elem => {
          return `$${Math.round(elem)}`;
        },
      };
      const conversionDataLabel = {
        fill,
        fontSize,
        position,
        formatter: elem => {
          let label = "";
          if (elem && index % step === 0) {
            label = `${Math.round(elem * 100)}%`;
          }
          index += 1;
          return label;
        },
      };

      if (chartData.length > 1) {
        if (chartType === "revenue") {
          return (
            <BarChart
              chartData={chartData}
              chartMargins={{ top: 0, right: 15, left: 10, bottom: 0 }}
              fill={[color]}
              height={height}
              isAnimationActive={false}
              isSpend={true}
              label={revDataLabel}
              ref={ref}
              width={width}
              xAxisTickCount={chartData.length}
              xAxisTickFontSize={fontSize}
              yAxisDomain={[0, Math.max(...R.map(elem => elem.count, chartData)) * 1.2]}
              leftYAxisLabel={{ value: "Avg Weekly Revenue", angle: -90, fontSize: fontSize }}
              yAxisLine={false}
              yAxisTickFontSize={0}
            />
          );
        } else {
          return (
            <InnerLineChartBox
              chartData={chartData}
              chartMargins={{ top: 20, right: 25, left: -35, bottom: -12 }}
              height={height}
              isAnimationActive={false}
              isSpend={false}
              label={conversionDataLabel}
              ref={ref}
              strokeColor={[color]}
              strokeWidth={[4]}
              width={width}
              xAxisFontSize={0}
              xAxisLine={false}
              xAxisTickCount={0}
              xAxisTicks={[]}
              yAxisLine={false}
              yAxisTickCount={0}
              yAxisTicks={[]}
              yAxisDomain={[
                Math.min(...R.map(elem => elem.count, chartData)),
                Math.max(...R.map(elem => elem.count, chartData)),
              ]}
            />
          );
        }
      } else {
        return null;
      }
    }, [chartData, chartType, color, height, ref, width]);

    return (
      <div className="funnelDynamicsChart" style={{ width, height }} key={`${chartType}Chart`}>
        {graph ? (
          <svg ref={ref} viewBox={`0 0 ${width} ${height}`}>
            {graph}
          </svg>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
);
