import "./AdPathFilters.scss";
import React from "react";
import { Dropdown, DropdownToggleType } from "../../../Components";

const FILTER_VALUES = ["include", "exclude", "require"].map(filter => ({ value: filter }));

interface AdPathSelectionTypes {
  value: string;
  onSelect: (value: string) => void;
  label?: string;
  width?: string | number;
  index?: number;
}

interface AdPathFilterMenuTypes {
  filtersRowData: {
    label: string;
    columnWidth?: string | number;
    data: {
      label?: string;
      value: string;
    }[];
  }[];
  onSelect: (value: string, rowIndex: number, filterIndex: number) => void;
}

export const AdPathSelection: React.FC<AdPathSelectionTypes> = ({
  value,
  label,
  width,
  onSelect,
}) => (
  <div className="ad-path-dropdown-wrapper" style={width ? { width: width } : {}}>
    <Dropdown
      type={DropdownToggleType.OUTLINED}
      design="secondary"
      onChange={option => onSelect(option)}
      options={FILTER_VALUES}
      value={value}
      label={label}
    />
  </div>
);

export const AdPathFilterMenu: React.FC<AdPathFilterMenuTypes> = ({ filtersRowData, onSelect }) => (
  <div className="ad-path-filters-menu">
    {filtersRowData.map((row, rowIndex) => (
      <div className="ad-path-filters-row" key={rowIndex}>
        <div className="ad-path-filters-row-label">{row.label}</div>
        <div className="ad-path-filters-wrapper">
          {row.data.map((filter, filterIndex) => (
            <AdPathSelection
              key={filter.label}
              value={filter.value}
              label={filter.label}
              width={row.columnWidth}
              onSelect={value => onSelect(value, rowIndex, filterIndex)}
              index={filterIndex}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);
