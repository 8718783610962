import React from "react";

import { formatMoney, formatMoneyAsInt } from "../utils/format-utils";
import { OverlayTrigger } from "../Components";
import { Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

import "./SpendBreakdown.scss";

const SpendBreakdown = ({ data }) => {
  // Show a full cost breakdown when any fees are included in the spend.
  const showBreakdown = data.spend > 0 && (data.includeFees || data.includeCommission);

  const hasAdServingFee = data.adServingFee > 0;
  const hasCommission = data.commission > 0;
  const hasDspCpmFee = data.dspCpmFee > 0;
  const hasDspCommissionFee = data.dspCommissionFee > 0;

  // Subtract out the included fees/commission to get the media spend amount.
  const calculateMediaSpend = () => {
    let mediaSpend = data.spend;
    if (data.includeFees) {
      mediaSpend -= data.adServingFee + data.dspCpmFee;
    }
    if (data.includeCommission) {
      mediaSpend -= data.commission;
    }
    if (hasDspCommissionFee) {
      mediaSpend -= data.dspCommissionFee;
    }
    return mediaSpend;
  };
  return (
    <div className="activeOttSpendBreakdown">
      <div className="spendTotals">
        <div className="spendBreakdownLabel" hidden={!showBreakdown}>
          <span>Media Spend:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(calculateMediaSpend())}</span>
          {/* Show info icon next to media spend when there's a dsp commission-type fee to explain
              that the media spend being shown is lower than what was actually booked, since dsp
              commission is baked into the budget. */}
          <span className="dspInfoIcon" hidden={!data.includeFees || !hasDspCommissionFee}>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
              overlay={
                <Tooltip className="dspInfoTooltip">
                  <p className="dspInfoQuestion">
                    Why is the media spend less than what was booked?
                  </p>
                  <p>
                    When the DSP uses a commission-type fee, this fee is baked into media spend we
                    order, so the actual media value is less than the booked spend.
                  </p>
                </Tooltip>
              }
            >
              <MdInfoOutline />
            </OverlayTrigger>
          </span>
        </div>
        <div className="spendBreakdownLabel" hidden={!data.includeFees || !hasAdServingFee}>
          <span>Ad Serving Fee:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(data.adServingFee)}</span>
        </div>
        <div className="spendBreakdownLabel" hidden={!data.includeFees || !hasDspCpmFee}>
          <span>Dsp Fee:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(data.dspCpmFee)}</span>
        </div>
        <div className="spendBreakdownLabel" hidden={!data.includeFees || !hasDspCommissionFee}>
          <span>Dsp Fee:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(data.dspCommissionFee)}</span>
        </div>
        <div className="spendBreakdownLabel" hidden={!data.includeCommission || !hasCommission}>
          <span>Commission:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(data.commission)}</span>
        </div>
        <hr className="spendBreakdownDivider" hidden={!showBreakdown} />
        <div className="spendBreakdownLabel">
          <span>Spend:</span>
          <span className="spendBreakdownAmount">{formatMoneyAsInt(data.spend)}</span>
        </div>
      </div>
      <div className="cpmSpacer" hidden={!showBreakdown}></div>
      <div className="spendBreakdownLabel">
        <span>CPM:</span>
        <span className="spendBreakdownAmount">{data.cpm ? formatMoney(data.cpm) : "$0"}</span>
      </div>
    </div>
  );
};

export default SpendBreakdown;
