// Experiment for sending flights to Beeswax automatically.

const beeswaxIntegrationCompaniesAndDates: Record<string, string> = {
  adopstest: "2023-05-23",
  albert: "2023-02-13",
  allbirds: "2023-02-09",
  atlassian: "2023-02-13",
  away: "2023-01-05",
  backcountry: "2023-02-13",
  blacktux: "2023-02-13",
  blinkist: "2023-02-13",
  blisspoint: "2022-11-08",
  blockfi: "2023-02-13",
  bloomscape: "2023-02-13",
  bolt: "2023-02-13",
  bookofthemonth: "2023-02-13",
  bottlekeeper: "2023-02-13",
  bruntworkwear: "2023-02-13",
  butcherbox: "2023-01-05",
  californiaclosets: "2023-02-13",
  caraway: "2023-02-13",
  cashapp: "2023-02-13",
  casper: "2023-02-17",
  clutter: "2023-02-13",
  cookunity: "2023-02-13",
  condenast: "2023-02-13",
  credible: "2023-02-13",
  curology: "2023-02-13",
  customink: "2023-02-13",
  dame: "2023-02-13",
  depop: "2023-02-13",
  doordash: "2023-01-17",
  drsquatch: "2023-02-13",
  elf: "2023-02-13",
  ergatta: "2023-02-13",
  ethos: "2023-02-13",
  everlane: "2023-02-13",
  experian: "2023-01-13",
  extra: "2023-02-13",
  fiton: "2023-02-13",
  flamingo: "2023-02-13",
  flipp: "2023-02-13",
  framebridge: "2023-02-13",
  gabi: "2023-02-13",
  goodrx: "2023-02-13",
  gopuff: "2023-02-13",
  halocollar: "2023-02-13",
  hannaandersson: "2023-02-13",
  homer: "2023-02-13",
  honest: "2023-02-13",
  honeylove: "2023-02-13",
  hopper: "2023-02-13",
  huckberry: "2023-01-17",
  hum: "2023-02-13",
  hydrow: "2023-02-13",
  imperfectfoods: "2023-02-13",
  imvu: "2023-02-13",
  instacart: "2023-06-05",
  jira: "2023-02-13",
  justfab: "2023-02-13",
  knock: "2023-02-13",
  kopari: "2023-02-13",
  lampsplus: "2023-02-13",
  lemonade: "2023-02-13",
  life360: "2023-02-13",
  lightricks: "2023-02-13",
  lola: "2023-02-13",
  lume: "2023-01-05",
  makespace: "2023-02-13",
  marinelayer: "2023-02-13",
  meandem: "2023-02-13",
  mercury: "2023-02-13",
  metacore: "2023-02-13",
  mindbloom: "2023-02-13",
  misfitsmarket: "2023-02-13",
  mizzenandmain: "2023-02-13",
  molekule: "2023-02-13",
  musely: "2023-02-13",
  nastygal: "2023-02-13",
  nerdwallet: "2023-02-13",
  noom: "2023-02-13",
  noomca: "2023-02-13",
  ollie: "2023-02-13",
  omaze: "2023-02-13",
  ondeck: "2023-02-13",
  onrunning: "2023-02-13",
  orbwellness: "2023-02-13",
  originalgrain: "2023-02-13",
  oura: "2023-01-13",
  ourplace: "2023-02-13",
  pendulum: "2023-02-13",
  personalcapital: "2023-02-13",
  petal: "2023-02-13",
  prettylitter: "2023-02-13",
  primary: "2023-02-13",
  prizepicks: "2023-02-13",
  qmixers: "2023-02-13",
  rhone: "2023-02-13",
  rocketmoney: "2023-01-05",
  saatchiart: "2023-02-13",
  savagex: "2023-02-13",
  sesamecare: "2023-02-13",
  shipstation: "2023-02-13",
  shoedazzle: "2023-02-13",
  simplehealth: "2023-02-13",
  smartasset: "2023-02-13",
  society6: "2023-01-05",
  sorare: "2023-02-13",
  stockx: "2023-02-13",
  summersalt: "2023-02-13",
  sunday: "2023-02-13",
  sweatybetty: "2023-02-13",
  talkiatry: "2023-02-13",
  talkspace: "2023-02-13",
  tally: "2023-02-13",
  theathletic: "2023-02-13",
  thrivemarket: "2023-02-13",
  thumbtack: "2023-02-13",
  tile: "2023-02-13",
  tinuiti: "2023-02-13",
  torrid: "2023-02-13",
  tractiveca: "2023-02-13",
  tradecoffee: "2023-02-13",
  trello: "2023-02-13",
  truebill: "2023-01-05",
  trustedhealth: "2023-02-13",
  quay: "2023-02-13",
  quince: "2023-02-13",
  quip: "2023-02-13",
  wac: "2023-02-13",
  wag: "2023-02-13",
  wonolo: "2023-02-13",
  yoursuper: "2023-02-13",
  zocdoc: "2023-02-13",
  // Add companies and flip dates here!
};

export const getBeeswaxIntegrationFlipDate = (company: string): string => {
  const isInstacart = company === "instacart";
  const hasExplicitCutover = Object.keys(beeswaxIntegrationCompaniesAndDates).includes(company);

  // Any companies that haven't been specified (new companies, etc.) are cut over on 2/20.
  if (!hasExplicitCutover) {
    beeswaxIntegrationCompaniesAndDates[company] = "2023-02-20";
  }

  // Forward fix for instacart offshoots, since they were accidentally turned off.
  if (!isInstacart && company.includes("instacart")) {
    beeswaxIntegrationCompaniesAndDates[company] = "2023-05-22";
  }

  return beeswaxIntegrationCompaniesAndDates[company];
};
