import "./BPMFileInput.scss";
import { BPMButton } from "../BPMButton/BPMButton";
import { BPMButtonProps } from "../BPMButtonUtils";
import React from "react";

interface FileInputProps extends BPMButtonProps {
  buttonText?: string;
  id: string;
  name: string;
  onChange: any;
}

export const BPMFileInput: React.FC<FileInputProps> = React.memo(
  ({
    // File Input Props
    buttonText = "Add File",
    className,
    id,
    name,
    onChange,

    // Button Props
    bordered,
    icon,
    size,
    variant = "primary",
    ...passthrough
  }) => {
    return (
      <div className={`BPMFileInput ${className}`}>
        <BPMButton
          {...passthrough}
          bordered={bordered}
          className={`BPMFileInputInnerButton ${className}`}
          icon={icon}
          size={size}
          variant={variant}
        >
          <label htmlFor={id} className={"BPMFileInputInnerLabel"}>
            {`${buttonText}`}
          </label>
        </BPMButton>
        <input
          className={`BPMFileInputInnerInputElement ${className}`}
          type="file"
          name={name}
          id={id}
          onChange={onChange}
        />
      </div>
    );
  }
);
