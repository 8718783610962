import React, { useState, useEffect } from "react";

import { useSetError } from "../redux/modals";

import { awaitJSON, DagLambdaFetch } from "../utils/fetch-utils";

import { Page, Spinner, BPMTable } from "../Components";

import "./DagGraphs.scss";

const DagGraphsTable = ({ data, headers }) => {
  if (!data) {
    return <Spinner size={75} />;
  }

  return (
    <BPMTable
      headers={headers}
      data={data}
      noRowsRenderer={() => <div>No rows to show.</div>}
      rowHeight={45}
      headerHeight={40}
      defaultTokens={{
        advanced: ["Name", "is like", "mobius"],
      }}
      defaultAdvancedFilter
    />
  );
};

const DagGraphs = () => {
  const setError = useSetError();
  const [graphs, setGraphs] = useState();

  useEffect(() => {
    (async () => {
      try {
        let allGraphs = await DagLambdaFetch("/dag_graphs");
        let parsedResult = await awaitJSON(allGraphs);
        setGraphs(parsedResult);
      } catch (e) {
        setError({ message: "Failed to fetch dag graphs", reportError: e });
        setGraphs([]);
      }
    })();
  }, [setError]);

  return (
    <Page title="Dag Graphs" pageType="Dag Graphs" minHeight={600}>
      <div className="bpm-dag-graphs">
        {graphs ? (
          <DagGraphsTable
            data={graphs}
            headers={[
              {
                sortPriority: 1,
                sortAscending: true,
                flex: 2,
                label: "Name",
                name: "name",
                renderer: data => data.name,
              },
              {
                flex: 2,
                label: "Cron",
                name: "cron",
                renderer: data => data.cron,
              },
              {
                flex: 2,
                label: "Time Zone",
                name: "timezone",
                renderer: data => data.tz,
              },
            ]}
          />
        ) : (
          <Spinner size={100} />
        )}
      </div>
    </Page>
  );
};

export default DagGraphs;
