import React from "react";
import { SlideState, SharedState } from "../slideTemplateConstants";
import {
  SlideType,
  SlideContext,
  SettingsComponentProps,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { Form } from "react-bootstrap";
import { DateRange } from "../../utils/types";
import { BPMDateRange, Dropdown, DropdownToggleType, LineChart } from "../../Components";
import moment from "moment";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { fetchSeasonalitySlideData } from "./QueryServices";
import { PROFILE_FILTER_MESSAGING, tmrRetailerFilterOptions, TMR_COLORS } from "./utils";
import * as Dfns from "date-fns/fp";
import { BarChart, XAxis, YAxis, Bar } from "recharts";
import { abbreviateNumberAMC } from "../../AMC/AMCFormatters";

export interface MarketplaceReportSeasonalitySlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  retailer: string;
  companyProfile: string;
}

interface MarketplaceReportSeasonalitySlideData {
  title: string;
  subTitle: string;
}

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

class MarketplaceReportSeasonalitySlide extends SlideType {
  static typeKey = "TmrSeasonalitySlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportSeasonalitySlideState = {
    title: "TMR Quarterly Seasonality",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    retailer: "null",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportSeasonalitySlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, dates, companyProfile } = state;
    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
            <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profiles (Contains)</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
     </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportSeasonalitySlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      dates,
      retailer,
      companyProfile,
    } = state as MarketplaceReportSeasonalitySlideState;
    const height = 231.36;
    const width = 1176.96;
    let newQuarterStart = Dfns.startOfQuarter(Dfns.subDays(365, Dfns.parseISO(dates.end)));
    let isoStartDate = Dfns.format("yyyy-MM-dd", newQuarterStart);
    let newEndDate = Dfns.startOfQuarter(Dfns.parseISO(dates.end));
    let isoEndDate = Dfns.format("yyyy-MM-dd", newEndDate);
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    let data = await fetchSeasonalitySlideData(
      company,
      isoStartDate,
      isoEndDate,
      retailer,
      profileNames
    );
    let referenceLines: { x: string; label: string; stroke: string }[] = [];
    let months = 0;
    let days = 45;

    for (let i = 0; i < 8; i++) {
      if (i % 2 === 0) {
        let newRef = {
          x: Dfns.format("yyyy-MM-dd", Dfns.addMonths(months, newQuarterStart)),
          label: "",
          stroke: TMR_COLORS.PINK,
        };
        months += 3;
        referenceLines.push(newRef);
      } else {
        let newLabel = {
          x: Dfns.format("yyyy-MM-dd", Dfns.addDays(days, newQuarterStart)),
          label: `Q${Dfns.getQuarter(Dfns.addQuarters(i / 2, newQuarterStart))}`,
          stroke: "",
        };
        days += 90;
        referenceLines.push(newLabel);
      }
    }

    //if client missing data
    let startDate = newQuarterStart;
    let endDate = newEndDate;

    let dataAppended = JSON.parse(JSON.stringify(data.salesByQuarter));

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      let match = dataAppended.find(item => item.reportDate === Dfns.format("yyyy-MM-dd", date));
      if (match === undefined) {
        let dateAdd = {
          __typename: "SeasonalitySalesByQuarter",
          quarter: `Q${Dfns.getQuarter(date)} ${Dfns.getYear(date)}`,
          reportDate: Dfns.format("yyyy-MM-dd", date),
          sales: 0,
          spend: 0,
        };
        dataAppended.push(dateAdd);
      }
    }

    //sort array in date order
    let sortByDate = function (arr, dateProp) {
      return arr.sort((a, b) => new Date(a[dateProp]).valueOf() - new Date(b[dateProp]).valueOf());
    };

    const sortedData = sortByDate(dataAppended, "reportDate");

    //line chart
    const byDayLineChartComponent = (
      <LineChart
        data={sortedData}
        lineDataKeys={[
          { dataKey: "spend", yAxisId: 1 },
          { dataKey: "sales", yAxisId: 0 },
        ]} // yAxisId: 0 = right, 1 = left
        dateXAxis={true}
        xAxisDataKey="reportDate"
        leftYAxisDataKey="spend"
        rightYAxisDataKey="sales"
        isAnimationActive={false}
        leftYAxisLabel="Total Ad Spend"
        rightYAxisLabel="Total Ad Sales"
        legendLocation="bottom"
        height={height}
        width={width}
        refLineKeys={referenceLines}
        lineChartColors={[TMR_COLORS.GREEN, TMR_COLORS.PURPLE]}
      />
    );

    //transform data for sales by quarter

    const quarterOnly = data.salesByQuarter.reduce((acc, obj) => {
      const { sales, spend, quarter } = obj;
      const key = `${quarter}`; // Create a unique key based on common data
      if (!acc[key]) {
        acc[key] = { sales, spend, quarter };
      } else if (acc[key] !== null) {
        acc[key].sales += obj.sales;
        acc[key].spend += obj.spend;
      }
      return acc;
    }, {});

    const arrayQuarterOnly = Object.values(quarterOnly);

    const byQuarterLineBarComponent = (
      <BarChart data={arrayQuarterOnly} height={height} width={width}>
        <Bar
          dataKey="sales"
          fill={TMR_COLORS.GREEN}
          isAnimationActive={false}
          label="Total Ad Sales"
        ></Bar>
        <Bar
          dataKey="spend"
          fill={TMR_COLORS.PURPLE}
          isAnimationActive={false}
          label="Total Ad Spend"
        ></Bar>
        <XAxis dataKey="quarter" tick={{ fontSize: 10 }} tickCount={5} />
        <YAxis
          domain={[0, "auto"]}
          orientation="left"
          tickFormatter={tick => `${abbreviateNumberAMC(tick)}`}
          type="number"
        />
      </BarChart>
    );
    //line chart
    let byDayChartUrl = await convertComponentToImageUrl(
      byDayLineChartComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );
    //bar chart
    let byQuarterChartUrl = await convertComponentToImageUrl(
      byQuarterLineBarComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      data,
      retailer,
      byDayChartUrl,
      byQuarterChartUrl,
    });
  };
}
export default MarketplaceReportSeasonalitySlide;
