import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";

import { Card, BPMDateRange, OldDropdown } from "../../Components";
import { InputGroup, Form, Button, Spinner } from "react-bootstrap";
import {
  awaitJSON,
  SheetsLambdaFetch,
  StreamingUtilsLambdaFetch,
  StreamingV2LambdaFetch,
} from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

import { DateRange } from "../../utils/types";
import { DerivedNetwork } from "../../redux/networks";
import { useSelector } from "react-redux";
import { emailSelector } from "../../redux/user";

export enum TransferMethod {
  EVENLY = "Evenly",
  ALLOCATION = "Allocation",
  ZERO = "Zero",
}

const TransferByMethod: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [dates, setDates] = useState<DateRange>();
  const [dateList, setDateList] = useState<string[]>([]);

  const [notes, setNotes] = useState("");

  const email = useSelector(emailSelector);
  const [transferMethod, setTransferMethod] = useState("");
  const transferMethodOptions = [
    TransferMethod.EVENLY,
    TransferMethod.ALLOCATION,
    TransferMethod.ZERO,
  ];

  const [newCost, setNewCost] = useState<number | null>(null);
  const [newCount, setNewCount] = useState<number | null>(null);
  const [isImpressionChange, setIsImpressionChange] = useState<boolean>(false);

  const [company, setCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState<string[]>();

  const [network, setNetwork] = useState("");
  const [derivedNetworkMap, setDerivedNetworkMap] = useState({});

  useEffect(() => {
    if (!companyOptions) {
      (async () => {
        let res = await SheetsLambdaFetch("/get_company_ids");
        let companies = await awaitJSON<{ ids: string[] }>(res);
        setCompanyOptions(companies.ids);
      })();
    }
  });

  const companyOptionList = useMemo(
    () => (companyOptions || []).map(value => ({ value, label: value })),
    [companyOptions]
  );

  useEffect(() => {
    (async () => {
      let res = await StreamingV2LambdaFetch("/derived", { params: { company } });
      let derivedNetworks = await awaitJSON<Record<string, DerivedNetwork>>(res);
      setDerivedNetworkMap(derivedNetworks);
    })();
  }, [company]);

  const derivedNetworkOptionList = useMemo(
    () => Object.keys(derivedNetworkMap || {}).map(value => ({ value, label: value })),
    [derivedNetworkMap]
  );

  const transferEvenlyOrAllocation = useCallback(async () => {
    try {
      setActiveAPICall(true);

      await StreamingUtilsLambdaFetch("/transferEvenlyOrAllocation", {
        method: "POST",
        body: JSON.stringify({
          dateList,
          company,
          network,
          notes,
          email,
          newCount,
          newCost,
          transferMethod,
          isImpressionChange,
        }),
      });

      setCompany("");
      setNotes("");
      setDates(undefined);
      setDateList([]);
      setTransferMethod("");
      setNetwork("");
      setNewCost(null);
      setNewCount(null);
      setIsImpressionChange(false);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [
    isImpressionChange,
    dateList,
    company,
    network,
    notes,
    email,
    newCount,
    transferMethod,
    newCost,
    setError,
  ]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Transfer by Method</div>
      <BPMDateRange
        range={dates}
        onChange={option => {
          setDates(option);
          let datesList: string[] = [];
          let tempDate = new Date(option.start);
          tempDate.setDate(tempDate.getDate() + 1);
          tempDate.setHours(0, 0, 0, 0);
          let endDate = new Date(option.end);
          endDate.setDate(endDate.getDate() + 1);
          endDate.setHours(0, 0, 0, 0);
          while (tempDate <= endDate) {
            let dateToPush = new Date(tempDate);
            datesList.push(dateToPush.toISOString().slice(0, 10));
            tempDate.setDate(tempDate.getDate() + 1);
          }
          setDateList(datesList);
        }}
      />
      <Select
        className="select adOpsElem"
        placeholder="Select from existing companies"
        isSearchable
        options={companyOptionList}
        onChange={option => {
          setCompany(option.value);
        }}
      />
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Notes</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={notes} onChange={e => setNotes(e.target.value)} />
      </InputGroup>
      {company && (
        <OldDropdown
          placeholder="Select transfer method"
          value={transferMethod || ""}
          options={transferMethodOptions}
          onChange={option => {
            setTransferMethod(option);
            if (isImpressionChange && option === TransferMethod.ZERO) {
              setNewCount(0);
            } else if (!isImpressionChange && option === TransferMethod.ZERO) {
              setNewCost(0);
            }
          }}
        />
      )}
      {transferMethod && (
        <Select
          className="select adOpsElem"
          placeholder="Select from Derived IDs"
          isSearchable
          isClearable
          options={derivedNetworkOptionList}
          onChange={option => {
            setNetwork(option?.value);
          }}
        />
      )}
      <div className="adOpsElem">
        <Form.Check
          className="adOpsFormCheck"
          inline
          label="Total Impression Change"
          type="checkbox"
          onChange={() => {
            setIsImpressionChange(!isImpressionChange);
            !isImpressionChange ? setNewCost(null) : setNewCount(null);
            if (!isImpressionChange && transferMethod === TransferMethod.ZERO) {
              setNewCount(0);
            } else if (isImpressionChange && transferMethod === TransferMethod.ZERO) {
              setNewCost(0);
            }
          }}
        ></Form.Check>
      </div>
      {!isImpressionChange && transferMethod !== TransferMethod.ZERO && (
        <InputGroup size="sm" className="adOpsElem">
          <InputGroup.Prepend>
            <InputGroup.Text className="inputLabel">New Cost</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            value={newCost !== null ? newCost : ""}
            onChange={e => setNewCost(e.target.value !== "" ? parseFloat(e.target.value) : null)}
          />
        </InputGroup>
      )}
      {isImpressionChange && transferMethod !== TransferMethod.ZERO && (
        <InputGroup size="sm" className="adOpsElem">
          <InputGroup.Prepend>
            <InputGroup.Text className="inputLabel">New Count</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            value={newCount !== null ? newCount : ""}
            onChange={e => setNewCount(e.target.value !== "" ? parseInt(e.target.value) : null)}
          />
        </InputGroup>
      )}
      <Button className="adOpsElem" onClick={transferEvenlyOrAllocation}>
        {activeAPICall ? <Spinner animation={"border"} /> : "Submit"}
      </Button>
    </Card>
  );
};

export default TransferByMethod;
