import "./FetchFilterBar.scss";
import { Category, FilterPaneState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import { COMMON_CATEGORY_LABELS } from "./FilterPaneBasic";
import { Dropdown, OverlayTrigger, Tooltip } from "../../Components";
import { MdEdit, MdMoreVert } from "react-icons/md";
import { StateSetter } from "../../utils/types";
import * as R from "ramda";
import React from "react";

export interface FetchFilterBarChipProps {
  advanced?: string;
  category?: Category;
  disabled?: boolean;
  expanded?: boolean;
  filterState?: FilterPaneState;
  setInitialOpenSection?: StateSetter<string>;
  setShowEditFilterModal?: StateSetter<boolean>;
}

export const FetchFilterBarChip: React.FC<FetchFilterBarChipProps> = React.memo(
  ({
    advanced,
    category,
    expanded,
    filterState,
    setInitialOpenSection = () => {},
    setShowEditFilterModal = () => {},
  }) => {
    const disabled =
      !R.isNil(category) && !R.isNil(category.key) && R.toLower(category.key) === "disabled";
    if (advanced) {
      const tokenCategory = <div className="tokenCategory">Advanced:</div>;
      const advancedContent = (
        <div className="tokenItemsContent">{advanced.length ? advanced : "All"}</div>
      );
      const tokenExpandContainer = (
        <div className="tokenExpandContainer">
          <MdMoreVert />
        </div>
      );
      const tokenContent = (
        <div className="tokenContent">
          {tokenCategory}
          {advancedContent}
          {tokenExpandContainer}
        </div>
      );
      return (
        <Dropdown
          applyContent={"Edit"}
          applyIcon={<MdEdit />}
          applyOnClick={() => {
            setInitialOpenSection("Advanced");
            setShowEditFilterModal(true);
          }}
          cancelContent={"Close"}
          cancelOnClick={() => {}}
          className={`${expanded ? "allowWrap" : ""} ${disabled ? "chipDisabled" : ""}`}
          hasFilterBar={false}
          hasTicks={false}
          label={tokenContent}
          onChange={() => {}}
          options={advanced.length ? [advanced] : ["All"]}
          value={"" as string}
        />
      );
    } else if (category) {
      const tokenCategory = <div className="tokenCategory">{category.label}:</div>;
      const not =
        filterState && filterState.basic && filterState.basic.notMap
          ? filterState.basic.notMap[category.key]
          : false;
      const selectedTokenItems: string[] = [];
      if (filterState && filterState.basic && filterState.basic.selectedMap) {
        const { selectedMap } = filterState.basic;
        for (const selectedKey of R.keys(R.defaultTo({}, selectedMap[category.key]))) {
          if (selectedMap[category.key][selectedKey]) {
            if (
              COMMON_CATEGORY_LABELS[category.key] &&
              COMMON_CATEGORY_LABELS[category.key][selectedKey]
            ) {
              selectedTokenItems.push(COMMON_CATEGORY_LABELS[category.key][selectedKey]);
            } else {
              selectedTokenItems.push(selectedKey);
            }
          }
        }
      }
      const tokenItems = [...selectedTokenItems];
      const tokenItemsContent = (
        <div className="tokenItemsContent">
          {tokenItems.length
            ? expanded || tokenItems.length < 3
              ? `${not ? "Exclude" : ""} ${tokenItems.join(", ")}`
              : `${not ? "Exclude" : ""} ${tokenItems.length} ${category.label.toLowerCase()}s`
            : "All"}
        </div>
      );
      const tokenExpandContainer = (
        <div className="tokenExpandContainer">
          <MdMoreVert />
        </div>
      );
      const tokenContent = (
        <div className="tokenContent">
          {tokenCategory}
          {tokenItemsContent}
          {tokenExpandContainer}
        </div>
      );
      const chipDropdown = (
        <div>
          <Dropdown
            applyContent={"Edit"}
            applyIcon={<MdEdit />}
            applyOnClick={() => {
              setInitialOpenSection(category.key);
              setShowEditFilterModal(true);
            }}
            cancelContent={"Close"}
            cancelOnClick={() => {}}
            className={`${tokenItems.length ? "hasItems" : ""}  ${
              expanded && tokenItems.length > 6 ? "allowWrap" : ""
            } ${disabled ? "chipDisabled" : ""}`}
            hasFilterBar={false}
            hasTicks={false}
            label={tokenContent}
            onChange={() => {}}
            options={tokenItems.length ? tokenItems : ["All"]}
            subTitles={not ? "Exclude" : ""}
            value={"" as string}
          />
        </div>
      );
      return disabled ? (
        <OverlayTrigger
          overlay={
            <Tooltip>
              This filter is currently disabled with the current dimension settings.
            </Tooltip>
          }
        >
          {chipDropdown}
        </OverlayTrigger>
      ) : (
        chipDropdown
      );
    }
    return <div></div>;
  }
);

export default FetchFilterBarChip;
