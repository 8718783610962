import "../IncrementalityPlaybook.scss";
import { CommonShoppingSections } from "./Shopping";
import { IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";

const PAGE_TITLE = "Discovery";

const Discovery = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Discovery Ads - help you reach people who are ready to discover and engage with your
              brand.
            </div>
            <br />
            <ul className="basicList">
              <li>
                Discovery ads are displayed automatically on YouTube Home and Watch Next feeds,
                Discover, and the Gmail Promotions and Social tabs using a single campaign.
              </li>
              <li>
                You can use Discovery ads in a{" "}
                <a href="https://support.google.com/google-ads/answer/13695777?visit_id=638539003473120418-1525429713&rd=1">
                  Discovery campaign.
                </a>
              </li>
            </ul>
            <br />
            <div className="basicText">
              Of note, methods outlined below are the same across google platforms for Search,
              Shopping, Display (DV360) & Discovery.
            </div>
            <br />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <CommonShoppingSections pageTitle={PAGE_TITLE} />
    </div>
  );
});

export default Discovery;
