import React, { useMemo } from "react";
import * as R from "ramda";
import {
  MEDIA_TYPE_TO_PRETTY_NAME,
  MEDIA_TYPE_TO_STROKE_COLOR,
  MEDIA_TYPE_TO_TITLE_COLOR,
} from "./homePageConstants";
import "./BreakdownLegend.scss";

interface BreakdownLegendProps {
  dimensionType: string;
  mediaTypes: string[];
  data: any;
}

const ColoredLine = ({ type }) => (
  <hr
    style={{
      color: MEDIA_TYPE_TO_STROKE_COLOR[type],
      backgroundColor: MEDIA_TYPE_TO_STROKE_COLOR[type],
      height: 2,
      width: 20,
    }}
  />
);

const BreakdownLegend: React.FC<BreakdownLegendProps> = ({ dimensionType, mediaTypes, data }) => {
  const mediaTypesToShow = useMemo(() => {
    const totals = {};
    for (let row of data) {
      for (let key of R.keys(row)) {
        if (key !== "date") {
          totals[key] = (totals[key] || 0) + R.sum(R.values(row[key]));
        }
      }
    }
    return R.keys(R.pickBy(v => v > 0, totals));
  }, [data]);

  return (
    <div className="breakdownLegend">
      {mediaTypesToShow.sort().map(type => {
        if (mediaTypes.includes(type)) {
          return (
            <div className="legendItem" key={type}>
              <div className="title" style={{ color: MEDIA_TYPE_TO_TITLE_COLOR[type] }}>
                {MEDIA_TYPE_TO_PRETTY_NAME[type]}
              </div>
              <span className="key">
                {dimensionType} <ColoredLine type={type} />
              </span>
            </div>
          );
        } else {
          return <React.Fragment key={type}></React.Fragment>;
        }
      })}
    </div>
  );
};

export default BreakdownLegend;
