import "./ReturnOnAdSpend.scss";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import useLocation from "../../utils/hooks/useLocation";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { Column, DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { snapShotDataSort, CONVERSION_FORMATTER_TYPE } from "../MMMUtils";
import { DownloadDropdown } from "../../Components/DownloadDropdown";
import { roasDataFormatter, roasSnapshotData } from "./DataFormatters";
import { SnapshotChart } from "../../Components";
import * as R from "ramda";
import MetricsTable from "../../SingleChannel/MetricsTable/MetricsTable";
import MoreInfo from "../MoreInfo";
import { exportToExcel } from "../../utils/download-utils";
import { MdOutlineWarningAmber } from "react-icons/md";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { RoasData } from "@blisspointmedia/bpm-types/dist/MMM";

const DimensionColumns: DimensionColumn[] = [
  {
    id: "name",
    dimensionVarName: "Channel",
    label: "Channel, Platform, Tactic, Brand/Non-Brand",
    dimensionTypeName: "Channel",
    divider: false,
  },
];

const COLUMNS_TO_EXPORT_XLSX = [
  "client",
  "kpi",
  "refresh_date",
  "name",
  "previous_qtr_median_spend",
  "next_dollar_effectiveness",
];

const TABLE_HEADERS: Column[] = [
  {
    id: "previous_qtr_roas",
    dataVarName: "roas",
    label: "Previous quarter ROAS",
  },
  {
    id: "previous_qtr_cpa",
    dataVarName: "roas",
    label: "Previous quarter CPA",
  },
  {
    id: "all_time_roas",
    dataVarName: "roas",
    label: "Entire time period ROAS",
  },
  {
    id: "all_time_cpa",
    dataVarName: "roas",
    label: "Entire time period CPA",
  },
  {
    id: "previous_qtr_median_spend",
    dataVarName: "roas",
    label: "Previous quarter median spend",
  },
  {
    id: "response_previous_qtr_median_spend",
    dataVarName: "roas",
    label: "Predicted response to median spend",
  },
  {
    id: "next_dollar_effectiveness",
    dataVarName: "spend",
    label: "Next Dollar Effectiveness",
    heatMapping: {
      colorScheme: "sequential",
    } as any,
  },
];

const ROW_HEIGHT = 50;
const TABLE_HEIGHT = 320;
interface ReturnOnAdSpendProps {
  disabledPlatform: boolean | undefined;
  kpiType: string;
  data: RoasData[];
}

const ReturnOnAdSpend: React.FC<ReturnOnAdSpendProps> = ({ disabledPlatform, kpiType, data }) => {
  let { company } = useLocation();

  const [roasData, setRoasData] = useState([] as any[]);
  const [dimensionData, setDimensionData] = useState([] as any[]);
  const [snapChartData, setSnapChartData] = useState([] as any[]);
  const [snapChartSortValue, setSnapChartSortValue] = useState("Highest to lowest");
  const [tableWidth, setTableWidth] = useState(500);
  const [rerenderTable, setRerenderTable] = useState(false);
  const [tableHeaders, setTableHeaders] = useState<Column[]>([]);
  const containerRef = useRef(null);
  const companyEqualsTest = TEST_COMPANIES.includes(company);
  if (companyEqualsTest) {
    company = "test";
  }
  const windowResizeFunction = () => setRerenderTable(true);
  if (window && window.onresize !== windowResizeFunction) {
    window.onresize = windowResizeFunction;
  }

  useLayoutEffect(() => {
    const newTableWidth = containerRef.current
      ? (containerRef.current as any).offsetWidth
      : undefined;
    if (newTableWidth) {
      setTableWidth((containerRef.current as any).offsetWidth - 5);
    }
    if (rerenderTable) {
      setRerenderTable(false);
    }
  }, [rerenderTable]);

  useEffect(() => {
    if (disabledPlatform !== undefined) {
      const tableHeaders = TABLE_HEADERS.filter(item => {
        return kpiType === "revenue" ? !item.id.includes("cpa") : !item.id.includes("roas");
      }).map(item =>
        kpiType !== "revenue" && item.label === "Predicted response to median spend"
          ? { ...item, label: "Predicted conversions for median spend" }
          : item
      );
      const formattedRoasData = roasDataFormatter({
        data: data,
        tableHeaders,
        disabledPlatform,
        companyEqualsTest,
        kpiType,
      });
      const formattedDimensionData = roasDataFormatter({
        data: data,
        tableHeaders: DimensionColumns,
        disabledPlatform,
        companyEqualsTest,
        kpiType,
      });
      const snapData = roasSnapshotData(data, disabledPlatform, companyEqualsTest);

      setTableHeaders(tableHeaders);
      setSnapChartData(snapShotDataSort(snapData, snapChartSortValue));
      setRoasData(formattedRoasData);
      setDimensionData(formattedDimensionData);
    }
  }, [company, companyEqualsTest, kpiType, disabledPlatform, snapChartSortValue, data]);

  const excelDownloadROAS = useCallback(() => {
    exportToExcel(data, `${company}_${kpiType === "revenue" ? "ROAS" : "CPA"}`);
  }, [company, data, kpiType]);

  return (
    <WidgetContainer
      bottom={
        <div className="mmmWidgetBottomWrapper">
          <MdOutlineWarningAmber style={{ fontSize: 22, marginRight: 14 }} />
          <div>
            Channels marked with an alert icon lack incrementality testing, and results should be
            interpreted cautiously. <br />
            Incrementality testing is strongly recommended, and we encourage you to contact your
            team to review options.
          </div>
        </div>
      }
      collapsible
      header="Where should we allocate our next media dollar?"
      subHeader={
        <>
          Below is the <i style={{ whiteSpace: "pre-wrap" }}> marginal </i> impact of each media
          dollar, given the existing allocation of resources.
          <MoreInfo rightLabel="More info" size="sm">
            The marginal impact of a media dollar is based on where one is on the channel saturation
            curve (see section below). Next dollar effectiveness is the slope of the line tangent to
            the saturation curve at the current level of investment. Intuitively, you want to invest
            where the slope of the curve is steepest, i.e. offers the highest return.
          </MoreInfo>
        </>
      }
    >
      {
        <div className="roasSection" style={{ display: "flex" }}>
          <div className="left" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {!R.isEmpty(snapChartData) && (
              <SnapshotChart
                title="Estimated Return on Next Invested Dollar"
                data={snapChartData}
                dropdownOptions={[
                  { value: "Alphabetical" },
                  { value: "Highest to lowest" },
                  { value: "Lowest to highest" },
                ]}
                dropdownValue={snapChartSortValue}
                setDropdownValue={setSnapChartSortValue}
                valueFormatter={value => {
                  return value ? CONVERSION_FORMATTER_TYPE[kpiType].format(value) : "--";
                }}
                exportFormattedData={R.map(R.pick(COLUMNS_TO_EXPORT_XLSX), data)}
              />
            )}
          </div>
          <div className="right" style={{ flex: 2 }}>
            <ChartContainer
              enableHoverDesign
              height={405}
              title={`${kpiType === "revenue" ? "ROAS" : "CPA"} and Next Best Dollar`}
              rightActions={
                <DownloadDropdown
                  menuOptions={["XLSX"]}
                  size="sm"
                  onClickOptions={[excelDownloadROAS]}
                />
              }
            >
              <div ref={containerRef} style={{ width: "100%" }}>
                {!R.isEmpty(roasData) &&
                  !R.isEmpty(dimensionData) &&
                  !R.isEmpty(tableHeaders) &&
                  !rerenderTable && (
                    <MetricsTable
                      aggregateData={[]}
                      dimensionColumns={DimensionColumns}
                      dimensionData={dimensionData}
                      tableData={roasData}
                      dataColumns={tableHeaders}
                      overrideParams={{
                        maxColWidth: 130,
                        rowHeight: ROW_HEIGHT,
                        headerTextHeight: 53
                      }}
                      width={tableWidth}
                      height={TABLE_HEIGHT}
                      columnMetaDataMap={{
                        spend: {
                          displayName: "Next Dollar Effectiveness",
                          overlayText:
                            "Formally, this is the slope of the line tangent to the response\nsaturation curve at the current level of investment for the channel.",
                          overlayPlacement: "left center",
                        },
                      }}
                    />
                  )}
              </div>
            </ChartContainer>
          </div>
        </div>
      }
    </WidgetContainer>
  );
};

export default ReturnOnAdSpend;
