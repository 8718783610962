import "../IncrementalityPlaybook.scss";
import { CommonShoppingSections } from "./Shopping";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";

const PAGE_TITLE = "Google (DV360)";

const GoogleDV360 = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Google Display is a type of online advertisement that combines text, images, and a URL
              that links to a website where a customer can learn more about or buy products. Display
              ad formats can be static with an image or animated with multiple images, video, or
              changing text (also called rich media ads).
            </div>
            <br />
            <div className="basicText">
              Of note, methods outlined below are the same across google platforms for Search,
              Shopping, Display (DV360) & Discovery.
            </div>
            <br />
            <Img style={{ height: 393, width: 700 }} src={`${INC_CDN}/Google_Display.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <CommonShoppingSections
        pageTitle={PAGE_TITLE}
        sections={[
          "Incrementality Testing Approaches",
          "Media Options & Considerations",
          "Sample Output",
          "Signal Enrichment",
          "Google Power Calculation",
        ]}
      />
    </div>
  );
});

export default GoogleDV360;
