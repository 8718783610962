import "./Performance.scss";
import { awaitJSON, YoutubePerformanceLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  COLUMN_METADATA_MAP,
  performanceRowToTableRow,
  RowWithTabularData,
} from "../Performance/YouTubePerformance/youtubePerformanceUtils";
import {
  DimensionData,
  DimensionValue,
  GetPresetsPreset,
  PerformanceTableData as YoutubePerformanceTableData,
} from "@blisspointmedia/bpm-types/dist/PerformanceSlide";
import { DateRange } from "../utils/types";
import {
  DEFAULT_DATE_SETTING,
  emptyYoutubePreset,
  getHeatMapColor,
  pixelsToInches,
  WHITE,
} from "./slideUtils";
import { CheckBox, OldDropdown, RelativeDatePicker } from "../Components";
import { Form } from "react-bootstrap";
import { getGlobalBrand } from "../Performance/performanceUtils";
import {
  GetKpiMetaDataParams,
  GetKpiMetaDataResponse,
} from "@blisspointmedia/bpm-types/dist/Performance";
import { Provider, useSelector } from "react-redux";
import { reduxStore } from "../redux";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { CompanyInfo, useCompanyInfo } from "../redux/company";
import { SetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import * as UserRedux from "../redux/user";
import PerformanceGrid from "../Performance/PerformanceGrid";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

export const makeFetchKey = (kpi: string): string => `${kpi}`;
const MAX_DATES = 4;
const MAX_FONT_SIZE = 20;
const EMPTY_ROW_DATA = {
  color: WHITE,
  content: "-",
  divider: false,
  value: -1,
};

export const makeDimKey = (dimensionData: DimensionData): string => {
  let dimKey = "";
  for (let key of R.keys(dimensionData)) {
    // Don't want to add type of dimensions just their values
    if (key !== "dimensions") {
      dimKey = `${dimKey}_${dimensionData[key]}`;
    }
  }
  return dimKey;
};

interface SlideYoutubePerformanceChartResult {
  clear: boolean;
  youtubePerformanceTableData: YoutubePerformanceTableData | null;
}

interface SlideYoutubePerformanceChartOnLoad {
  (results: SlideYoutubePerformanceChartResult): void;
}

interface SlideYoutubePerformanceChartProps {
  clear: boolean;
  company: string;
  data: any;
  dataKey: string;
  defaultKPI: string;
  end: string;
  endLabel: string;
  fetchedPreset: S.PresetRow;
  kpiMetaData: GetKpiMetaDataResponse;
  presetID: number;
  prevKey: string;
  start: string;
  startLabel: string;
  onLoad: SlideYoutubePerformanceChartOnLoad;
}

interface OnPathLoadInterface {
  bottomData: any;
  heatmapInfo: any;
  leftData: any;
  tableData: any;
  topData: any;
}

const SlideYoutubePerformanceChart: React.FC<SlideYoutubePerformanceChartProps> = ({
  clear,
  data,
  dataKey,
  defaultKPI,
  endLabel,
  fetchedPreset,
  kpiMetaData,
  prevKey,
  startLabel,
  onLoad,
}) => {
  const { preset } = fetchedPreset;
  const kpi = preset && preset.globalKpi ? preset.globalKpi : defaultKPI;
  const [pathLoaded, setPathLoaded] = useState<OnPathLoadInterface | null>(null);
  const [otherDates, setOtherDates] = useState<DateRange>();
  const [filterTerm, setFilterTerm] = useState("");
  const [dataMap, setDataMap] = useState<Record<string, YoutubePerformanceTableData>>({});
  const wait =
    !R.isEmpty(dataKey) &&
    !R.isEmpty(prevKey) &&
    !R.isNil(dataMap[prevKey]) &&
    !R.isNil(dataMap[dataKey]) &&
    dataMap[dataKey] === dataMap[prevKey] &&
    prevKey !== dataKey;
  useLayoutEffect(() => {
    if (!dataKey || wait || (pathLoaded === null && clear)) {
      onLoad({
        youtubePerformanceTableData: {
          headerData: [],
          rowData: [],
          aggregateData: [],
          dimensionData: [],
          start: "",
          end: "",
        },
        clear,
      });
    }
    if (pathLoaded !== null && data.length === 0) {
      onLoad({
        youtubePerformanceTableData: null,
        clear: false,
      });
    } else if (!clear && pathLoaded !== null && !R.isNil(data)) {
      let { topData, tableData, bottomData, heatmapInfo, leftData } = pathLoaded;
      let leftDataCheck = !R.isEmpty(leftData);
      for (let row of leftData) {
        let dims = R.keys(row);
        // Check if the correct type of data has properly loaded
        for (let dimCol of preset.performanceDimensionColumns) {
          if (!dims.includes(dimCol.dimension)) {
            leftDataCheck = false;
            break;
          }
        }
      }
      if (
        !R.isEmpty(topData) &&
        !R.isEmpty(tableData) &&
        !R.isEmpty(bottomData) &&
        !R.isEmpty(heatmapInfo) &&
        leftDataCheck
      ) {
        let rowIndex = 0;
        for (let row of tableData) {
          let colIndex = 0;
          for (let col of heatmapInfo) {
            if (col) {
              let color = getHeatMapColor(
                colIndex,
                row[colIndex].value,
                heatmapInfo,
                topData,
                COLUMN_METADATA_MAP
              );
              tableData[rowIndex][colIndex].color = color;
            }
            colIndex++;
          }
          rowIndex++;
        }
        if (preset && preset.performanceDimensionColumns) {
          for (let elem of leftData) {
            let dimensions: DimensionValue[] = [];
            for (let dimCol of preset.performanceDimensionColumns) {
              let dimensionValue: DimensionValue = {
                headerLabel: R.defaultTo(dimCol.type, dimCol.label),
                type: dimCol.type,
              };
              if (dimCol.type === "Ad Name") {
                dimensionValue.label = elem.Ad;
              } else if (dimCol.type === "Ad Group Name") {
                dimensionValue.label = elem["Ad Group"];
              } else if (dimCol.type === "Campaign Name") {
                dimensionValue.label = elem.Campaign
                  ? elem.Campaign.includes("|") &&
                    elem.Campaign.split("|") &&
                    elem.Campaign.split("|").length > 1
                    ? elem.Campaign.split("|")[1]
                    : elem.Campaign
                  : "";
              } else if (["Age", "Device", "Gender"].includes(dimCol.type)) {
                dimensionValue.label = elem[dimCol.type];
              }
              dimensions.push(dimensionValue);
            }
            elem.dimensions = dimensions;
          }
        }
        let youtubePerformanceTableData: YoutubePerformanceTableData | null =
          !!topData && !!tableData && !!bottomData && !!leftData
            ? {
                headerData: topData,
                rowData: tableData,
                aggregateData: bottomData,
                dimensionData: leftData,
                start: startLabel,
                end: endLabel,
              }
            : null;
        if (
          (prevKey !== dataKey && dataMap[prevKey] !== youtubePerformanceTableData) ||
          (prevKey === dataKey && dataMap[prevKey] === youtubePerformanceTableData)
        ) {
          setTimeout(() => {
            onLoad({
              youtubePerformanceTableData,
              clear: false,
            });
          }, 500);
          if (youtubePerformanceTableData !== null) {
            setDataMap(map => {
              map[dataKey] = youtubePerformanceTableData as YoutubePerformanceTableData;
              return map;
            });
          }
        }
      }
    }
  }, [
    clear,
    data,
    dataKey,
    dataMap,
    endLabel,
    onLoad,
    pathLoaded,
    preset,
    prevKey,
    startLabel,
    wait,
  ]);
  const blankCreativeMap = useMemo(() => {
    return {};
  }, []);
  if (!data || !preset || !kpiMetaData || clear || wait) {
    return (
      <PerformanceGrid
        creativeMap={blankCreativeMap}
        data={[]}
        filterTerm={filterTerm}
        isSlides={true}
        isYoutube={true}
        onPathLoad={setPathLoaded}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        preset={emptyYoutubePreset}
        setFilterTerm={setFilterTerm}
        setOtherDates={setOtherDates}
        slidesCompany={kpi?.split("_")[0]}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData}
      />
    );
  } else {
    return (
      <PerformanceGrid
        creativeMap={blankCreativeMap}
        data={data}
        filterTerm={filterTerm}
        isSlides={true}
        isYoutube={true}
        onPathLoad={setPathLoaded}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        preset={preset}
        setFilterTerm={setFilterTerm}
        setOtherDates={setOtherDates}
        slidesCompany={kpi?.split("_")[0]}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData}
      />
    );
  }
};

interface YoutubePerformanceSlideData {
  companyColor: string | null;
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  footnote: string;
  youtubePerformanceTableData: YoutubePerformanceTableData[] | null;
}

export interface YoutubePerformanceSlideState {
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  dates: RelativeDateRange[];
  datesSort: boolean[];
  footnote: string;
  isInternal: boolean;
  numDates: number;
  numRows: number;
  selectedPreset: GetPresetsPreset | null;
}
export const youtubePerformanceSharedStateKey = "youtubePerformance" as const;

export interface YoutubePerformanceSharedState {
  companyInfo: CompanyInfo | undefined;
  globalBrand: string | undefined;
  kpiMetaData: GetKpiMetaDataResponse | undefined;
  presets: GetPresetsPreset[];
  presetMap: Record<string, S.PresetRow>;
}

export interface YoutubePerformanceSharedStateContext {
  presetID: number;
  state: YoutubePerformanceSharedState;
}

export const youtubePerformanceSharedFetcher: SharedStateFetcher<YoutubePerformanceSharedState> = async (
  key: string,
  setError: SetError,
  context: YoutubePerformanceSharedStateContext
) => {
  const [company] = key.split("_");
  let res: YoutubePerformanceSharedState = {
    companyInfo: undefined,
    globalBrand: undefined,
    kpiMetaData: undefined,
    presetMap: {},
    presets: [],
  };
  if (R.isNil(context)) {
    try {
      let params = { company };
      let presetRes = await YoutubePerformanceLambdaFetch("/presets", {
        params,
      });
      let presets: GetPresetsPreset[] = await awaitJSON(presetRes);
      res.presets = R.filter(preset => !preset.temporary, presets);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: `Could not get valid youtube performance presets. Error: ${error.message}`,
        reportError: error,
      });
    }
    try {
      const kpiRes = await YoutubePerformanceLambdaFetch<GetKpiMetaDataParams>("/kpis", {
        params: {
          company,
        },
      });
      const kpiMetaData = await awaitJSON<GetKpiMetaDataResponse>(kpiRes);
      res.kpiMetaData = kpiMetaData;
    } catch (e) {
      let error = e as Error;
      setError({
        message: `Failed to get youtube performance preset data: ${error.message}`,
        reportError: error,
      });
    }
  } else {
    if (!R.isNil(context.presetID) && R.isNil(context.state.presetMap[context.presetID])) {
      try {
        const fields = {
          company,
          id: context.presetID,
        };
        const res = await YoutubePerformanceLambdaFetch("/preset", {
          params: { ...fields },
        });
        const fetchedPreset = await awaitJSON(res);
        context.state.presetMap[context.presetID] = fetchedPreset;
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to get youtube performance preset data: ${error.message}`,
          reportError: error,
        });
      }
    }
    res = context.state;
  }
  return res;
};

class YoutubePerformanceSlide extends SlideType {
  static typeKey = "youtubePerformance";
  static displayKey = "Youtube Performance";
  static defaultState: YoutubePerformanceSlideState = {
    customSlideHeader: "",
    customTableColumnWidth: 32,
    customTableFontSize: 9,
    customTableRowHeight: 30,
    dates: [DEFAULT_DATE_SETTING],
    datesSort: [true, false, false, false],
    footnote: "",
    isInternal: false,
    numDates: 1,
    numRows: 20,
    selectedPreset: null,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<YoutubePerformanceSlideState>
  > = React.memo(({ slideContext, state, setState, sharedFetch, sharedState }) => {
    const {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      isInternal,
      numDates,
      numRows,
      selectedPreset,
    } = state;
    const [isInternalReady, setIsInternalReady] = useState<boolean>();
    const fetchedIsInternal = useSelector(UserRedux.isInternalSelector);
    const { company } = slideContext;
    const extremaKey = `${company}`;
    const { companyInfo, globalBrand, presetMap, presets } =
      R.isNil(sharedState) ||
      R.isNil(sharedState[youtubePerformanceSharedStateKey]) ||
      R.isNil(sharedState[youtubePerformanceSharedStateKey][extremaKey])
        ? {
            companyInfo: undefined,
            globalBrand: undefined,
            presetMap: {},
            presets: [],
          }
        : sharedState[youtubePerformanceSharedStateKey][extremaKey];
    const fetchedCompanyInfo = useCompanyInfo();
    const fetchedGlobalBrand = companyInfo
      ? getGlobalBrand(companyInfo, companyInfo.streaming_performance_default_kpi)
      : undefined;

    useEffect(() => {
      if (!R.path([youtubePerformanceSharedStateKey, extremaKey], sharedState)) {
        (async () => {
          await sharedFetch(youtubePerformanceSharedStateKey, extremaKey, null);
        })();
      }
    }, [sharedState, sharedFetch, extremaKey]);

    useEffect(() => {
      if (!isInternalReady && !R.isNil(fetchedIsInternal)) {
        setState({ isInternal: fetchedIsInternal });
        setIsInternalReady(true);
      }
    }, [isInternal, fetchedIsInternal, setState, isInternalReady]);

    useEffect(() => {
      if (
        sharedState &&
        sharedState[youtubePerformanceSharedStateKey] &&
        sharedState[youtubePerformanceSharedStateKey][extremaKey]
      ) {
        (async () => {
          if (fetchedGlobalBrand && R.isNil(globalBrand)) {
            await sharedFetch(youtubePerformanceSharedStateKey, extremaKey, {
              state: {
                ...sharedState[youtubePerformanceSharedStateKey][extremaKey],
                companyInfo: fetchedCompanyInfo,
                globalBrand: fetchedGlobalBrand,
              },
              updateState: true,
            });
          }
          if (!R.isNil(presets) && !R.isEmpty(presets) && !R.isNil(selectedPreset)) {
            if (!R.map(elem => elem.id, presets).includes(selectedPreset.id)) {
              setState({ selectedPreset: null });
            }
          }
          if (selectedPreset && R.isNil(presetMap[selectedPreset.id])) {
            try {
              await sharedFetch(youtubePerformanceSharedStateKey, extremaKey, {
                state: {
                  ...sharedState[youtubePerformanceSharedStateKey][extremaKey],
                  companyInfo: fetchedCompanyInfo,
                  globalBrand: fetchedGlobalBrand,
                },
                presetID: selectedPreset.id,
                updateState: true,
              });
            } catch (e) {
              setState({ selectedPreset: null });
            }
          }
        })();
      }
    }, [
      companyInfo,
      extremaKey,
      fetchedCompanyInfo,
      fetchedGlobalBrand,
      globalBrand,
      presetMap,
      presets,
      selectedPreset,
      setState,
      sharedFetch,
      sharedState,
    ]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group className="options">
            <div className="presetSelector">
              <OldDropdown
                label={"Preset"}
                value={selectedPreset ? selectedPreset.id.toString() : "undefined"}
                options={R.map(preset => {
                  return { label: preset.name, value: preset.id.toString() };
                }, presets)}
                onChange={presetID => {
                  let selectedPreset: GetPresetsPreset | null = null;
                  for (let preset of presets) {
                    if (presetID === preset.id.toString()) {
                      selectedPreset = preset;
                    }
                  }
                  setState({ selectedPreset });
                }}
              />
            </div>
            <div>
              <Form.Label>Font Size</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_FONT_SIZE}
                value={customTableFontSize}
                onChange={e => {
                  const size = R.defaultTo(1, parseInt(e.target.value));
                  const sizeInPxs = Math.ceil(size * (1 + 1 / 3));
                  if (customTableColumnWidth < sizeInPxs) {
                    setState({ customTableColumnWidth: sizeInPxs });
                  }
                  if (customTableRowHeight < sizeInPxs) {
                    setState({ customTableRowHeight: sizeInPxs });
                  }
                  setState({ customTableFontSize: size });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Date Ranges in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_DATES}
                value={numDates}
                onChange={e => {
                  let newDatesSort = [false, false, false, false];
                  let sortByIndex = 0;
                  const numDates = R.defaultTo(1, parseInt(e.target.value));
                  let newDates = Array(numDates);
                  for (let i = 0; i < numDates; i++) {
                    newDates[i] = dates[i] && i < dates.length ? dates[i] : DEFAULT_DATE_SETTING;
                    if (datesSort[i]) {
                      sortByIndex = i;
                    }
                  }
                  newDatesSort[sortByIndex] = true;
                  setState({ dates: newDates, datesSort: newDatesSort, numDates: numDates });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Rows in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={numRows}
                onChange={e => {
                  setState({ numRows: R.defaultTo(1, parseInt(e.target.value)) });
                }}
              />
            </div>
            <Form.Label className="note">
              NOTE: Remember to sort the page by the relevant column if the number of rows exceeds
              20 and to specify the global kpi being used in the table and if any columns have a
              custom kpi set in the optional footnote!
            </Form.Label>
            <div>
              <Form.Label>
                Custom Table Column Width in Pixels(
                {pixelsToInches(customTableColumnWidth).toFixed(2)} in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={32}
                value={customTableColumnWidth}
                onChange={e => {
                  let newWidth;
                  try {
                    newWidth = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newWidth = customTableColumnWidth;
                  }
                  setState({ customTableColumnWidth: newWidth });
                }}
              />
            </div>
            <div>
              <Form.Label>
                Custom Table Row Height in Pixels({pixelsToInches(customTableRowHeight).toFixed(2)}{" "}
                in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={10}
                value={customTableRowHeight}
                onChange={e => {
                  let newHeight;
                  try {
                    newHeight = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newHeight = customTableRowHeight;
                  }
                  setState({ customTableRowHeight: newHeight });
                }}
              />
            </div>
            <div className="footnoteDiv">
              Optional Footnote:
              <Form.Control
                className="footnoteTextEntry"
                value={footnote}
                onChange={e => {
                  setState({ footnote: e.target.value });
                }}
              />
            </div>
            <div>
              {`${footnote.length ? "Optional Footnote on Youtube Performance Slide:" : ""}`}
            </div>
            <Form.Label className="note">{`${
              footnote.length ? "Note: " : ""
            }${footnote}`}</Form.Label>
          </Form.Group>
          <Form.Group className="datePickers">
            <div className="customSlideHeaderText">
              Optional Custom Slide Header:
              <Form.Control
                className="customSlideHeader"
                value={customSlideHeader ? customSlideHeader : ""}
                onChange={e => {
                  setState({ customSlideHeader: e.target.value });
                }}
              />
            </div>
            <div className="dp">
              {dates.length > 0 &&
                dates.map((date: RelativeDateRange, index: number) => {
                  if (date && date.start && date.end) {
                    return (
                      <div key={`Dates Pickers ${index + 1}`}>
                        <div className="dateRangeLabelAndSortBy">
                          <Form.Label className="dateRangeText">{`Start Date ${
                            index + 1
                          }`}</Form.Label>
                          <div className="dateRangeSortBy">
                            <Form.Label className="dateRangeSortByText">
                              Sort By Date Range?
                            </Form.Label>
                            <CheckBox
                              checked={datesSort[index]}
                              onCheck={e => {
                                let newDatesSort = [false, false, false, false];
                                newDatesSort[index] = e;
                                setState({ datesSort: newDatesSort });
                              }}
                              size="md"
                            />
                          </div>
                        </div>
                        <RelativeDatePicker
                          state={date.start}
                          onChange={start => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start, end: datesUpdate[index].end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                        <Form.Label className="dateRangeText">{`End Date ${index + 1}`}</Form.Label>
                        <RelativeDatePicker
                          state={date.end}
                          onChange={end => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start: datesUpdate[index].start, end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<YoutubePerformanceSlideData> => {
    let { company } = context;
    let {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      isInternal,
      numDates,
      numRows,
      selectedPreset,
    } = state as YoutubePerformanceSlideState;
    const extremaKey = `${company}`;
    const { companyInfo, kpiMetaData, presetMap } = _.youtubePerformance[extremaKey];
    if (R.isNil(companyInfo)) {
      throw new Error(`No default kpi found for: ${company}`);
    }
    const {
      agency,
      color: companyColor = null,
      streaming_performance_default_kpi: defaultKPI,
    } = companyInfo;
    const mediatypeLabel = "Youtube";
    if (R.isNil(selectedPreset)) {
      throw new Error(`No preset selected. (${mediatypeLabel} Performance, ${customSlideHeader}).`);
    }
    const fetchedPreset = presetMap[selectedPreset.id];
    if (R.isNil(fetchedPreset)) {
      throw new Error(
        `We are still loading your youtube performance presets, please wait. (${mediatypeLabel} Performance, ${customSlideHeader}`
      );
    }
    let youtubePerformanceTableData: YoutubePerformanceTableData[] = [];
    let prevKey = "";
    for (let i = 0; i < numDates; i++) {
      let data: YoutubePerformanceTableData;
      const dateObj = dates[i];
      const start = computeResolvedDate(dateObj.start);
      const end = Dfns.format(
        DATE_FORMAT,
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      const startLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.start)))
      );
      const endLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      if (fetchedPreset === null || kpiMetaData === null) {
        throw new Error(
          `No preset data (${mediatypeLabel} Performance, ${customSlideHeader}, ${startLabel}-${endLabel}).`
        );
      }
      const dataKey = `${fetchedPreset.id}_${start}_${end}`;
      const slideName = `${customSlideHeader ? `${customSlideHeader}, ` : ""}Preset: ${
        fetchedPreset.name
      }`;
      let sandbox = await claimSandbox();
      if (
        fetchedPreset === null ||
        !fetchedPreset.preset ||
        !fetchedPreset.preset.performanceDimensionColumns ||
        R.isEmpty(fetchedPreset.preset.performanceDimensionColumns)
      ) {
        throw new Error(
          `No preset data (${mediatypeLabel} Performance, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (kpiMetaData === null) {
        throw new Error(
          `No kpi meta data (${mediatypeLabel} Performance, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else {
        const { preset } = fetchedPreset;
        const fields = {
          agency,
          company,
          end,
          id: selectedPreset ? selectedPreset.id : 0,
          kpi: preset.globalKpi ? preset.globalKpi : defaultKPI,
          start,
        };
        let fetchedPerformanceData;
        try {
          let res = await YoutubePerformanceLambdaFetch("/", {
            params: { ...fields },
          });
          fetchedPerformanceData = await awaitJSON(res);
        } catch (e) {
          throw new Error(
            `No data available for this date range (${mediatypeLabel} Performance, ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
          );
        }
        if (
          !fetchedPerformanceData ||
          !fetchedPerformanceData.data ||
          R.isEmpty(fetchedPerformanceData.data)
        ) {
          throw new Error(
            `No data available for this date range (${mediatypeLabel} Performance, ${slideName}, ${startLabel}-${endLabel}).
            If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
          );
        }
        let rows: RowWithTabularData[] = [];
        try {
          let { data } = fetchedPerformanceData;
          let tabularData: RowWithTabularData[] = [];
          for (let row of data) {
            tabularData.push({
              ...row,
              tabularRow: performanceRowToTableRow(
                row,
                preset.columns,
                {},
                company,
                preset.globalKpi ? preset.globalKpi : defaultKPI,
                isInternal
              ),
            });
          }

          for (let row of tabularData) {
            for (let dimCol of preset.performanceDimensionColumns) {
              let check: (row: RowWithTabularData) => boolean;
              check = row => `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes("");
              if (check(row)) {
                rows.push(row);
                break;
              }
            }
          }
        } catch (e) {
          let error = e as Error;
          throw error;
        }
        if (R.isEmpty(rows)) {
          throw new Error(
            `No data available for this date range (${mediatypeLabel} Performance, ${slideName}, ${startLabel}-${endLabel}).
            If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
          );
        }
        let prevKeyChart = prevKey;
        data = await new Promise((resolve, reject) => {
          try {
            //At the start of each loop clear the performance grid
            const clear = true;
            const load = (clear: boolean) => {
              ReactDOM.render(
                <Provider store={reduxStore}>
                  <SlideYoutubePerformanceChart
                    clear={clear}
                    company={company}
                    data={rows}
                    defaultKPI={defaultKPI}
                    end={end}
                    endLabel={endLabel}
                    fetchedPreset={fetchedPreset as S.PresetRow}
                    dataKey={dataKey}
                    kpiMetaData={kpiMetaData as GetKpiMetaDataResponse}
                    presetID={selectedPreset ? selectedPreset.id : 0}
                    prevKey={prevKeyChart}
                    start={start}
                    startLabel={startLabel}
                    onLoad={({ youtubePerformanceTableData, clear }) => {
                      if (clear) {
                        load(false);
                      }
                      if (
                        youtubePerformanceTableData !== null &&
                        R.defaultTo([], youtubePerformanceTableData.headerData).length > 0 &&
                        R.defaultTo([], youtubePerformanceTableData.rowData).length > 0 &&
                        R.defaultTo([], youtubePerformanceTableData.aggregateData).length > 0
                      ) {
                        resolve(youtubePerformanceTableData);
                      } else if (youtubePerformanceTableData === null) {
                        reject(
                          new Error(
                            `No data available for this date range (${mediatypeLabel} Performance, ${slideName})`
                          )
                        );
                      }
                    }}
                  />
                </Provider>,
                sandbox.element
              );
            };
            setTimeout(() => {
              load(clear);
            }, 500);
          } catch (e) {
            reject(e);
          }
        });
      }
      releaseSandbox(sandbox);
      youtubePerformanceTableData.push(data);
    }
    let sortIndex = 0;
    for (let i = 0; i < datesSort.length; i++) {
      if (datesSort[i]) {
        sortIndex = i;
      }
    }

    let dimensionKeys: Record<string, number> = {};
    let numRowsAdjusted = numRows;
    if (
      youtubePerformanceTableData[sortIndex].dimensionData &&
      youtubePerformanceTableData[sortIndex].dimensionData.length < numRowsAdjusted
    ) {
      numRowsAdjusted = youtubePerformanceTableData[sortIndex].dimensionData.length;
    } else if (
      !youtubePerformanceTableData[sortIndex].dimensionData ||
      !youtubePerformanceTableData[sortIndex].rowData
    ) {
      throw new Error(`Failed to get youtube performance data (${mediatypeLabel} Performance)`);
    }

    // Store indexes of dimension data in case subsequent date ranges are not in the same order
    for (let j = 0; j < numRowsAdjusted; j++) {
      let { dimensionData } = youtubePerformanceTableData[sortIndex];
      let dimKey = makeDimKey(dimensionData[j]);
      dimensionKeys[dimKey] = j;
    }

    for (let i = 0; i < numDates; i++) {
      // If data is missing, enter zeros
      let { dimensionData, rowData, headerData } = youtubePerformanceTableData[i];
      let sortedRowData = Array(numRowsAdjusted).fill(
        Array(R.defaultTo([], headerData).length).fill(EMPTY_ROW_DATA)
      );
      if (dimensionData && rowData) {
        if (i === sortIndex) {
          sortedRowData = rowData.slice(0, numRowsAdjusted);
        } else {
          for (let k = 0; k < R.defaultTo(0, rowData.length); k++) {
            let dimKey = makeDimKey(dimensionData[k]);
            // Must check for zero or else this index will be ignored
            if (dimensionKeys[dimKey] || dimensionKeys[dimKey] === 0) {
              sortedRowData[dimensionKeys[dimKey]] = rowData ? rowData[k] : EMPTY_ROW_DATA;
            }
          }
        }
      }
      // Sort data using first date range
      let dimData = youtubePerformanceTableData[sortIndex].dimensionData;
      youtubePerformanceTableData[i].dimensionData = dimData
        ? dimData.slice(0, numRowsAdjusted)
        : dimData;
      youtubePerformanceTableData[i].rowData = sortedRowData;
    }
    footnote = footnote.trim();
    if (footnote) {
      footnote = `Note: ${footnote}`;
    }

    return {
      companyColor,
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      footnote,
      youtubePerformanceTableData,
    };
  };
}

export default YoutubePerformanceSlide;
