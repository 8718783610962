import { engineeringUsers } from "./experiment-utils";

export const shouldEnableNewFetchFilterBar = (userEmail: string): boolean => {
  const users: string[] = [
    "micah.kilimann@tinuiti.com",
    "automation@tinuiti.com",
    ...engineeringUsers,
  ];
  return users.includes(userEmail);
};
