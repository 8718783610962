import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";

import * as R from "ramda";

import CreatableSelect from "react-select/creatable";

import "./SuggestionInput.scss";

export const SuggestionInput = ({
  value,
  onChange,
  options,
  blankLabel = "",
  className = "",
  nonEmpty,
  ...passthrough
}) => {
  const [prevValue, setPrevValue] = useState(value);
  const [input, setInputRaw] = useState(value);

  useEffect(() => {
    if (R.isNil(input)) {
      setInputRaw(value);
    }
  }, [input, value]);
  useEffect(() => {
    if (value !== prevValue) {
      setInputRaw(value);
      setPrevValue(value);
    }
  }, [value, prevValue]);

  const setInput = useCallback((input, info) => {
    if (R.prop("action", info) === "input-change") {
      setInputRaw(input);
    }
  }, []);

  const nonOption = useMemo(
    () => ({ value: "", label: blankLabel || passthrough.placeholder }),
    [blankLabel, passthrough]
  );

  const blurBlockRef = useRef(false);
  const ourOnChange = useCallback(
    (newValue, info) => {
      blurBlockRef.current = true;
      let actualValue = R.prop("value", newValue);

      if (R.prop("action", info) === "clear") {
        actualValue = "";
      }
      setInputRaw(null);
      onChange(actualValue);
    },
    [onChange]
  );

  const onBlur = useCallback(
    e => {
      // Blur always happens after an option is selected. When an option is selected, we don't want
      // the blur effect to happen. So the on change sets this switch that blocks the blur event.
      // The blur event resets it for next time.
      if (!blurBlockRef.current) {
        setInputRaw(null);
        onChange(e.target.value);
      }
      blurBlockRef.current = false;
    },
    [onChange]
  );

  const ourOptions = useMemo(
    () => (nonEmpty ? options : [nonOption, ...options]),
    [nonEmpty, nonOption, options]
  );

  const selectRef = useRef();
  return (
    <CreatableSelect
      ref={selectRef}
      isClearable
      blurInputOnSelect
      createOptionPosition="first"
      backspaceRemovesValue={false}
      {...passthrough}
      className={`suggestionInput${className ? ` ${className}` : ""}`}
      inputValue={input || ""}
      onInputChange={setInput}
      options={ourOptions}
      onChange={ourOnChange}
      onBlur={onBlur}
      onMenuClose={() => {
        // The menu close happens before the on change. It needs to happen after
        setTimeout(() => {
          if (selectRef.current) {
            selectRef.current.blur();
          }
        }, 0);
      }}
    />
  );
};
