import React, { useEffect, useState } from "react";
import PaidMediaImpact from "./PaidMediaImpact";
import ModelInsights from "./ModelInsights";
import InvestToIncrease from "./InvestToIncrease";
import { RouteComponentProps } from "@reach/router";
import { Img } from "../../Components";
import Overview from "./Overview";
import "./BrandImpact.scss";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import Papa from "papaparse";

interface BrandImpactProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

const BrandImpact = ({
  company,
  groupBy,
  setGroupByOptions,
  groupByMetric,
}: BrandImpactProps & RouteComponentProps): JSX.Element => {
  const [spendAndEffectData, setSpendAndEffectData] = useState<any[]>([]);
  const [nextBestData, setNextBestData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let resSpendEffectResponse = await S3SignedUrlFetch(
          `bpm-miguel-test/bh_robyn_b1_main_demo/out/${groupByMetric}_p/spend_v_effect.csv`
        );
        const resSpendEffectText = await resSpendEffectResponse.text();
        const { data: resSpendEffectParsed }: Papa.ParseResult<any> = Papa.parse(
          resSpendEffectText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );
        const dataFiltered: any[] = resSpendEffectParsed.filter(
          (item: any) =>
            item.clean_channel !== "Intercept" &&
            item.clean_channel !== "Season" &&
            item.clean_channel !== "Holiday" &&
            item.clean_channel !== "Trend" &&
            item.clean_channel != null
        );

        const spendEffectDataFixed: any[] = dataFiltered.map((item: any) => ({
          label: "Spend",
          label2: "Effect",
          name: item.clean_channel
            .replace("Paid Search Nb", "Paid Search - Non-brand")
            .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
            .replace("Tik Tok", "TikTok")
            .replace("Linear Television", "Linear TV"),
          value: item.spend_share === "NA" ? 0 : item.spend_share,
          value2: item.effect_share === "NA" ? 0 : item.effect_share,
        }));
        setSpendAndEffectData(spendEffectDataFixed);

        let nextBestDataResponse = await S3SignedUrlFetch(
          `bpm-miguel-test/bh_robyn_b1_main_demo/out/${groupByMetric}_p/next_best_dollar.csv`
        );
        const nextBestDataText = await nextBestDataResponse.text();

        const { data: nextBestDataParsed }: Papa.ParseResult<any> = Papa.parse(nextBestDataText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });

        const nextBestDataCleaned: any[] = nextBestDataParsed.map((item: any) => ({
          label: "Spend",
          name: item.dX
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
            .replace("Paid Search Nb", "Paid Search - Non-brand")
            .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
            .replace("Tik Tok", "TikTok")
            .replace("Linear Television", "Linear TV"),
          value: parseFloat(item.dY) * 10000000,
        }));
        setNextBestData(nextBestDataCleaned);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };
    fetchData();
  }, [groupByMetric]);

  return (
    <div>
      <div className="brandEquityHeader">
        <div className="brandEquityTextHeader">
          <div className="brandEquityTextHeaderTitle">
            A strong brand drives business outcomes and creates a tailwind for performance marketing
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Performance marketing generates results over days to weeks. Brand marketing operates
            over months to years, making unified assessment of the funnel challenging.
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Bliss Point Brand Health quantifies brand equity's impact on the business for a holistic
            understanding of ROAS.
          </div>
        </div>
        <div className="brandEquityHeaderImageContainer">
          <Img
            src="https://cdn.blisspointmedia.com/assets/img/BusinessImpactDemo/BrandEquity_Infographic.png"
            alt="brand health header"
          />
        </div>
      </div>
      <Overview
        company={company}
        setGroupByOptions={setGroupByOptions}
        groupBy={groupBy}
        groupByMetric={groupByMetric}
      />
      <PaidMediaImpact
        company={company}
        setGroupByOptions={setGroupByOptions}
        groupBy={groupBy}
        groupByMetric={groupByMetric}
      />
      <InvestToIncrease
        company={company}
        groupByMetric={groupByMetric}
        nextBestData={nextBestData}
        spendAndEffectData={spendAndEffectData}
      />
      <ModelInsights company={company} groupByMetric={groupByMetric} />
    </div>
  );
};

export default BrandImpact;
