import React from "react";

import * as uuid from "uuid";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { SharedStateFetch } from "./Slides";

export interface SlideContext {
  company: string;
  week: string;
  today: string;
}

export interface SandboxObject {
  element: HTMLDivElement | null;
  id: string;
}

export interface ClaimSandboxFunction {
  (): Promise<SandboxObject>;
}
export interface ReleaseSandboxFunction {
  (sandboxObject: SandboxObject): void;
}

export interface S3PromiseFunction {
  (pngString: string): Promise<string>;
}
export interface SharedStateFetcher<T> {
  (key: string, setError: SetError, context?: any): Promise<T>;
}
export interface SettingsComponentProps<T> {
  state: T;
  setState: (newState: ((prevState: T) => T) | Partial<T>) => void;
  slideContext: SlideContext;
  sharedState: SharedState;
  sharedFetch: SharedStateFetch;
  setLoading: (loading: boolean) => void;
  id: string;
}

export abstract class SlideType {
  static typeKey = "abstract";
  static displayKey = "abstract";
  static SettingsComponent: React.FC<any> = () => <div className="emptySettings" />;
  static defaultState = {};

  // Note: I'm trying to allow an instance of an extending class of SlideType to get its static
  // keys. Typescript doesn't let non-static objects access static members of their classes. You can
  // get a reference to the static class of an instance with this.constructor, but if you use dot
  // notation to get the actual properties out, typescript complains that type Function doesn't have
  // that key. This, however, works.
  // eslint-disable-next-line dot-notation
  type = this.constructor["typeKey"];
  // eslint-disable-next-line dot-notation
  displayName = this.constructor["displayKey"];
  // eslint-disable-next-line dot-notation
  Settings = this.constructor["SettingsComponent"];

  id = uuid.v4();

  constructor(public name: string, id?: string) {
    if (id) {
      this.id = id;
    }
  }

  abstract generate: (
    context: SlideContext,
    state: SlideState,
    sharedState: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ) => Promise<object>;
}
