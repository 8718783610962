import React, { useContext, useMemo, useCallback } from "react";

import * as R from "ramda";

import { ListGroup, Button, Collapse } from "react-bootstrap";

import { MdClose, MdPieChart, MdClearAll } from "react-icons/md";

import { Card } from "../Components";

import { useMap } from "../utils/hooks/useData";

import {
  StreamingCreativesContext,
  makeNetPlatKey,
  CART_KEY,
  MENU_KEY,
} from "./StreamingCreatives";

import { NonCartContext } from "./NonCart";

const SideCart = () => {
  const {
    selectedMap,
    shopData,
    workspaceOptions,
    workspaceMap,
    setSelectedValue,
    setSelectedMap,
    goToTab,
    cart,
    addToCart,
    setWorkspaceMap,
    setPlatform,
  } = useContext(StreamingCreativesContext);

  const { setViewPlacementId } = useContext(NonCartContext);
  const [expandMap, setExpandValue] = useMap();

  const rotation = useMemo(() => {
    let rotation = [];
    for (let { isci, color } of workspaceOptions) {
      let value = workspaceMap[isci];
      if (value) {
        rotation.push({ isci, value, color });
      }
    }
    return rotation;
  }, [workspaceOptions, workspaceMap]);

  const items = useMemo(() => {
    let items = [];
    for (let id of R.keys(selectedMap)) {
      if (selectedMap[id]) {
        items.push(shopData.placementMap[id]);
      }
    }
    return R.pipe(
      R.groupBy(makeNetPlatKey),
      R.values,
      R.sortBy(placementList => makeNetPlatKey(placementList[0]))
    )(items);
  }, [selectedMap, shopData]);

  const [sumTo100, buttonText] = useMemo(() => {
    let pctSum = R.pipe(R.pluck("value"), R.sum)(rotation);
    let sumTo100 = pctSum === 100;
    let buttonText = "Add to Cart";
    if (pctSum > 100) {
      buttonText = `Remove ${pctSum - 100}% from your rotation.`;
    }
    if (pctSum < 100) {
      buttonText = `Add ${100 - pctSum}% to your rotation.`;
    }
    return [sumTo100, buttonText];
  }, [rotation]);

  const ourAddToCart = useCallback(() => {
    addToCart({
      id: Date.now(),
      placements: R.pipe(R.flatten, R.sortBy(R.prop("name")), R.pluck("id"))(items),
      rotation: R.project(["isci", "value"], rotation),
    });
    setSelectedMap({});
    setWorkspaceMap({});
    goToTab(MENU_KEY);
  }, [items, addToCart, rotation, setSelectedMap, setWorkspaceMap, goToTab]);

  return (
    <div className="streamingCreativesCardContainer sideCart">
      <Card className="streamingCreativesCard">
        <div className="selectList">
          {!items.length && (
            <div className="noTags">No tags selected. Add them from the tag menu.</div>
          )}
          <ListGroup>
            {items.map(placementList => {
              let key = makeNetPlatKey(placementList[0]);
              const { network, platform } = placementList[0];
              return (
                <ListGroup.Item
                  key={key}
                  className="cartListItem"
                  onClick={() => setExpandValue(key, !expandMap[key])}
                >
                  {network} {platform}
                  <Button
                    variant="light"
                    size="sm"
                    className="removeItem deleteButton"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      let newMap = { ...selectedMap };
                      for (let { id } of placementList) {
                        newMap[id] = false;
                      }
                      setSelectedMap(newMap);
                      if (!Object.values(newMap).includes(true)) {
                        setPlatform("");
                      }
                    }}
                  >
                    <MdClose />
                  </Button>
                  <Collapse in={expandMap[key]}>
                    <ListGroup variant="flush" className="placementList">
                      {placementList.map(({ name, id }) => (
                        <ListGroup.Item key={name} className="placementItem">
                          {name}
                          <Button
                            variant="light"
                            size="sm"
                            className="viewPlacementButton"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setViewPlacementId(id);
                            }}
                          >
                            <MdPieChart />
                          </Button>
                          <Button
                            variant="light"
                            size="sm"
                            className="removeItem deleteButton"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSelectedValue(id, false);
                            }}
                          >
                            <MdClose />
                          </Button>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Collapse>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
        <div className="buttonsContainer">
          {items.length ? (
            <>
              <Button
                variant="outline-danger"
                className="clearAll"
                size="lg"
                onClick={() => setSelectedMap({})}
                title="Clear all selected"
              >
                <MdClearAll />
              </Button>
              <Button disabled={!sumTo100} variant="primary" size="lg" onClick={ourAddToCart}>
                {buttonText}
              </Button>
            </>
          ) : (
            <Button
              disabled={!cart.length}
              variant="primary"
              size="lg"
              onClick={() => goToTab(CART_KEY)}
            >
              View Cart ({cart.length})
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default SideCart;
