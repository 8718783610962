import { useEffect } from "react";

import * as R from "ramda";

import { useDispatch, useSelector } from "react-redux";

import { dispatchError, useSetError } from "./modals";

import {
  UsersLambdaFetch,
  awaitJSON,
  ToolsLambdaFetch,
  YoutubePerformanceLambdaFetch,
} from "../utils/fetch-utils";

import { makeReducer } from "./utils";
import useLocation from "../utils/hooks/useLocation";
import { KpiInfo } from "@blisspointmedia/bpm-types/dist/Kpis";
import { LagOption } from "@blisspointmedia/bpm-types/dist/StreamingPerformance";

const ROOT = "company";

export interface MobiusViewInfo {
  company: string;
  key: string;
  name: string;
  url: string;
}

export interface CustomDashboardInfo {
  name: string,
  url: string
}

export interface CompanyInfo {
  agency: string;
  all_mobius_views?: MobiusViewInfo[];
  beeswax_advertiser_domain?: string;
  beeswax_default_click?: string;
  beeswax_iab_category?: string;
  cid: string;
  color?: string;
  commission?: number;
  competitors?: string[];
  default_competitor?: string;
  disable_pages?: string[];
  groups: string[];
  initial_kpi: string;
  kpi_filter_order?: string[];
  kpis: KpiInfo[];
  media_types?: string[];
  min_impressions_dates?: string;
  mobiusx_account_id?: string;
  amc_instance_id?: string;
  name_abbrev?: string;
  name: string;
  optimization_cid?: string;
  parent_company?: string;
  spike_kpis?: string[];
  streaming_performance_default_kpi: string;
  streaming_performance_default_lag: LagOption;
  sub_companies?: { name: string; cid: string }[];
  cross_channel_kpis: string[];
  custom_dashboards: CustomDashboardInfo[];
}

const DUMMY_COMPANY_INFO: CompanyInfo = {
  agency: "",
  cid: "",
  groups: [],
  initial_kpi: "",
  kpi_filter_order: [],
  kpis: [],
  name: "",
  streaming_performance_default_kpi: "",
  streaming_performance_default_lag: "1d",
  cross_channel_kpis: [],
  custom_dashboards: [],
};

interface CompanyReduxState {
  companies: Record<string, CompanyInfo | undefined>;
  current: string;
  filteredKpis: Record<string, "fetching" | Record<string, boolean>>;
}

interface CompanyReduxRootState {
  [ROOT]: CompanyReduxState;
}

const SET_CURRENT_COMPANY = `${ROOT}/SET_CURRENT_COMPANY`;

interface SetCurrentCompanyActionType {
  type: string;
  company: string;
}

export const SetCompany = (company: string): SetCurrentCompanyActionType => ({
  type: SET_CURRENT_COMPANY,
  company,
});

const SET_COMPANY_INFO = `${ROOT}/SET_COMPANY_INFO`;
interface SetCompanyInfoActionType {
  type: string;
  company: string;
  info: Omit<CompanyInfo, "cid">;
}

export const SetCompanyInfo = (company: string, info: CompanyInfo): SetCompanyInfoActionType => ({
  type: SET_COMPANY_INFO,
  company,
  info,
});

const SET_FILTERED_KPIS = `${ROOT}/SET_FILTERED_KPIS`;
interface SetFilteredKpisActionType {
  type: string;
  company: string;
  filteredKpis: "fetching" | Record<string, boolean>;
}

export const fetchStreamingKpis = (
  company: string
): ((
  dispatch: ReturnType<typeof useDispatch>,
  getState: () => CompanyReduxRootState
) => Promise<void>) => async (dispatch, getState) => {
  if (!filteredKpisSelector(getState())) {
    dispatch({
      type: SET_FILTERED_KPIS,
      company,
      filteredKpis: "fetching",
    });
    try {
      const validKpis = await ToolsLambdaFetch("/geo_kpis", {
        params: { company },
      });
      const res = await awaitJSON(validKpis);
      dispatch({
        type: SET_FILTERED_KPIS,
        company,
        filteredKpis: res,
      });
    } catch (e) {
      const reportError = e as Error;
      dispatchError(dispatch, {
        message: `Failed to fetch streaming kpi info. Error: ${reportError.message}`,
        reportError,
      });
    }
  }
};

export const fetchYoutubeKpis = (
  company: string
): ((
  dispatch: ReturnType<typeof useDispatch>,
  getState: () => CompanyReduxRootState
) => Promise<void>) => async (dispatch, getState) => {
  if (!filteredKpisSelector(getState())) {
    dispatch({
      type: SET_FILTERED_KPIS,
      company,
      filteredKpis: "fetching",
    });
    try {
      const validKpis = await YoutubePerformanceLambdaFetch("/kpis", {
        params: { company },
      });
      const res = await awaitJSON(validKpis);
      dispatch({
        type: SET_FILTERED_KPIS,
        company,
        filteredKpis: res,
      });
    } catch (e) {
      const reportError = e as Error;
      dispatchError(dispatch, {
        message: `Failed to fetch youtube kpi info. Error: ${reportError.message}`,
        reportError,
      });
    }
  }
};

export const currentCompanySelector = (state: CompanyReduxRootState): string => state[ROOT].current;
export const companyInfoSelector = (company: string) => (
  state: CompanyReduxRootState
): CompanyInfo | undefined => state[ROOT].companies[company];

export const mediaTypesSelector = (company: string) => (state: CompanyReduxRootState): string[] =>
  state[ROOT].companies[company]?.media_types || [];
export const initialKpiSelector = (company: string) => (
  state: CompanyReduxRootState
): string | undefined => state[ROOT].companies[company]?.initial_kpi;

export const competitorsSelector = (company: string) => (state: CompanyReduxRootState): string[] =>
  state[ROOT].companies[company]?.competitors || [];

export const disablePagesSelector = (company: string) => (state: CompanyReduxRootState): string[] =>
  state[ROOT].companies[company]?.disable_pages || [];

export const filteredKpisSelector = (
  state: CompanyReduxRootState
): "fetching" | Record<string, boolean> => state[ROOT].filteredKpis[currentCompanySelector(state)];

export const useCompanyInfo: (inputCompany?: string) => CompanyInfo = (inputCompany?: string) => {
  const locationCompany = useSelector(currentCompanySelector);
  const company = R.defaultTo(locationCompany, inputCompany);
  const companyInfo = useSelector(companyInfoSelector(company || ""));
  return companyInfo || DUMMY_COMPANY_INFO;
};

export const useCompanyInfoFetch: (inputCompany?: string) => void = (inputCompany?: string) => {
  const dispatch = useDispatch();
  const locationCompany = useSelector(currentCompanySelector);
  const company = R.defaultTo(locationCompany, inputCompany);
  const companyInfo = useSelector(companyInfoSelector(company || ""));
  const setError = useSetError();
  const { location } = useLocation();

  useEffect(() => {
    if (R.isNil(companyInfo) && company) {
      (async () => {
        try {
          let res = await UsersLambdaFetch("/company", { params: { company } });
          let info = await awaitJSON(res);

          dispatch(SetCompanyInfo(company, info));
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to fetch info for company "${company}". ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [companyInfo, company, location, dispatch, setError]);
};

const setCompanyInfo = (
  state: CompanyReduxState,
  { company, info }: SetCompanyInfoActionType
): CompanyReduxState => ({
  ...state,
  companies: {
    ...state.companies,
    [company]: {
      cid: company,
      ...info,
    },
  },
});

const setCurrentCompany = (
  state: CompanyReduxState,
  { company }: SetCurrentCompanyActionType
): CompanyReduxState => ({
  ...state,
  current: company,
});

const setFilteredKpis = (
  state: CompanyReduxState,
  { company, filteredKpis }: SetFilteredKpisActionType
): CompanyReduxState => ({
  ...state,
  filteredKpis: {
    ...state.filteredKpis,
    [company]: filteredKpis,
  },
});

export default makeReducer<CompanyReduxState>(
  {
    current: "",
    companies: {},
    filteredKpis: {},
  },
  {
    [SET_COMPANY_INFO]: setCompanyInfo,
    [SET_CURRENT_COMPANY]: setCurrentCompany,
    [SET_FILTERED_KPIS]: setFilteredKpis,
  }
);
