import React, { useEffect, useState, useCallback } from "react";
import { Page, FullPageSpinner } from "../Components";
import WtoTable from "./WtoTable";
import WtoDetailsForm from "./WtoDetailsForm";
import WtoRules from "./WtoRules";
import { WtoFormProvider, formObjDefault } from "./WtoFormContext";
import { WtoBulkPloverModal } from "./WtoBulkPloverModal";
import WtoArchiveModal from "./WtoArchiveModal";
import WtoDuplicateModal from "./WtoDuplicateModal";
import WtoActivateModal from "./WtoActivateModal";
import { WtoLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { emailSelector } from "../redux/user";
import { useSelector } from "react-redux";
import MenubarWTO from "./MenubarWTO";
import * as R from "ramda";
import "./WebTrafficOptimization.scss";
import "../Components/BreadcrumbTitle.scss";
import { Plover } from "@blisspointmedia/bpm-types/dist/webTrafficOptimization";

const WebTrafficOptimization = (): JSX.Element => {
  const setError = useSetError();
  const { cid } = useCompanyInfo();
  const userEmail = useSelector(emailSelector);

  const [loading, setLoading] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [breadcrumbPath, setBreadcrumbPath] = useState<string[]>([]);
  const [modal, setModal] = useState<string>("");
  const [modalRowData, setModalRowData] = useState<Plover>(formObjDefault);
  const [formEdited, setFormEdited] = useState(false);
  const [domains, setDomains] = useState<Record<string, any>[]>([]);
  const [plovers, setPlovers] = useState<Record<string, any>[]>([]);

  const fetchPlovers = useCallback(async () => {
    setLoading(true);
    try {
      const ploverRes = await WtoLambdaFetch("/plovers", {
        params: { company: cid },
      });
      const ploverData = await awaitJSON(ploverRes);
      R.filter((plover: Record<string, any>) => plover.rules.length > 0, ploverData.data).forEach(
        plover => {
          plover.rules.forEach(rule => {
            const formattedConditions: {
              trigger: string;
              rule: string;
              value: string;
              operator: string;
            }[] = [];
            rule.conditions.forEach(condition => {
              if (
                R.type(condition) === "Array" &&
                condition.length === 4 &&
                !R.isNil(condition[0]) &&
                !R.isNil(condition[1]) &&
                !R.isNil(condition[2]) &&
                !R.isNil(condition[3])
              ) {
                formattedConditions.push({
                  trigger: condition[0],
                  rule: condition[1],
                  value: condition[2],
                  operator: formattedConditions.length === 0 ? "WHEN" : "AND",
                });
              } else if (
                R.type(condition) === "Object" &&
                condition.trigger &&
                condition.rule &&
                condition.value &&
                condition.operator
              ) {
                const newCondition = R.clone(condition);
                formattedConditions.push({
                  ...newCondition,
                  operator: formattedConditions.length === 0 ? "WHEN" : "AND",
                });
              }
            });
            rule.conditions = formattedConditions;
          });
        }
      );
      setPlovers(ploverData.data);
    } catch (e) {
      const error = e as Error;
      setError({
        message: `Failed to fetch routes. Error: ${error.message}`,
        reportError: error,
      });
      setPlovers([]);
    }
    setLoading(false);
  }, [cid, setError]);

  useEffect(() => {
    (async () => {
      try {
        const domainRes = await WtoLambdaFetch("/domains", {
          params: { company: cid },
        });
        const domainData = await awaitJSON(domainRes);
        setDomains(domainData.data);
        fetchPlovers();
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [cid, fetchPlovers, setError]);

  const setModalData = (modalType, data) => {
    setModal(modalType);
    setModalRowData(data);
  };

  const useCopyPlover = () => {
    const [tooltipText, setTooltipText] = useState("");

    const copyPloverUrl = (e: any, copyText: string) => {
      e.preventDefault();
      navigator.clipboard.writeText(copyText);
      setTooltipText("Copied!");
    };

    return { tooltipText, setTooltipText, copyPloverUrl };
  };

  const isBreadcrumbPath = crumbs => {
    if (crumbs.length !== breadcrumbPath.length) {
      return false;
    }
    crumbs.forEach(crumb => {
      if (!breadcrumbPath.includes(crumb)) {
        return false;
      }
    });
    return true;
  };

  const breadCrumbsRenderer = () => {
    return (
      <div className="breadcrumbTitle">
        <div
          className="mainTitle"
          onClick={() =>
            formEdited && !readOnly ? setShowCancelConfirmation(true) : setBreadcrumbPath([])
          }
        >
          Web Traffic Optimization
        </div>
        {breadcrumbPath.map((crumb, index) => (
          <div
            key={index}
            className="crumb"
            onClick={() => setBreadcrumbPath(breadcrumbPath.slice(0, index + 1))}
          >
            {crumb}
          </div>
        ))}
      </div>
    );
  };

  return (
    <WtoFormProvider>
      <Page
        title={breadCrumbsRenderer()}
        pageType="Admin Functions"
        actions={
          domains.length > 0 && (
            <MenubarWTO
              breadcrumbPath={breadcrumbPath}
              setBreadcrumbPath={setBreadcrumbPath}
              loading={loading}
              setModal={setModal}
            />
          )
        }
      >
        <div className="WebTrafficOptimization">
          {loading ? (
            <FullPageSpinner />
          ) : (
            <>
              {isBreadcrumbPath(["Route Details"]) && (
                <WtoDetailsForm
                  useCopyPlover={useCopyPlover}
                  domains={domains}
                  setBreadcrumbPath={setBreadcrumbPath}
                  readOnly={readOnly}
                  setReadOnly={setReadOnly}
                  formEdited={formEdited}
                  setFormEdited={setFormEdited}
                  showCancelConfirmation={showCancelConfirmation}
                  setShowCancelConfirmation={setShowCancelConfirmation}
                  userEmail={userEmail}
                  fetchPlovers={fetchPlovers}
                  companyId={cid}
                />
              )}
              {isBreadcrumbPath(["Route Details", "Rules"]) && (
                <WtoRules
                  useCopyPlover={useCopyPlover}
                  setBreadcrumbPath={setBreadcrumbPath}
                  readOnly={readOnly}
                  setReadOnly={setReadOnly}
                  formEdited={formEdited}
                  showCancelConfirmation={showCancelConfirmation}
                  setShowCancelConfirmation={setShowCancelConfirmation}
                  userEmail={userEmail}
                  fetchPlovers={fetchPlovers}
                  companyId={cid}
                  setModalData={setModalData}
                  plovers={plovers}
                />
              )}
              {R.isEmpty(breadcrumbPath) && (
                <WtoTable
                  data={plovers}
                  useCopyPlover={useCopyPlover}
                  setModalData={setModalData}
                  setBreadcrumbPath={setBreadcrumbPath}
                  loading={loading}
                  domains={domains}
                />
              )}
            </>
          )}
          {modal === "Bulk Plover Upload" && (
            <WtoBulkPloverModal
              show={modal === "Bulk Plover Upload"}
              closeModal={() => setModal("")}
              userEmail={userEmail}
              domains={domains}
              companyId={cid}
              fetchPlovers={fetchPlovers}
            />
          )}
          <WtoDuplicateModal
            show={modal === "Duplicate Route"}
            closeModal={() => setModal("")}
            data={modalRowData}
            userEmail={userEmail}
            companyId={cid}
            fetchPlovers={fetchPlovers}
          />
          <WtoArchiveModal
            show={modal === "Archive Route"}
            closeModal={() => setModal("")}
            data={modalRowData}
            userEmail={userEmail}
            fetchPlovers={fetchPlovers}
          />
          <WtoActivateModal
            show={
              modal === "Activate Route" || modal === "Create Route" || modal === "Update Route"
            }
            closeModal={() => setModal("")}
            data={modalRowData}
            userEmail={userEmail}
            fetchPlovers={fetchPlovers}
            action={modal}
            setBreadcrumbPath={setBreadcrumbPath}
          />
        </div>
      </Page>
    </WtoFormProvider>
  );
};

export default WebTrafficOptimization;
