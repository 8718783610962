import React, { useMemo } from "react";

import "./BreadcrumbTitle.scss";

export const BreadcrumbTitle = React.memo(({ navigate, path, title }) => {
  let nodes = useMemo(() => {
    let crumbs = [];
    let nodes = [];
    for (let { uri, label, key, resolvedPath } of path) {
      crumbs.push(resolvedPath || uri);
      if (label) {
        let crumbPath = crumbs.join("/");
        nodes.push(
          <div key={key} className="crumb" onClick={() => navigate(crumbPath)}>
            {label}
          </div>
        );
      }
    }

    return nodes;
  }, [path, navigate]);
  return (
    <div className="breadcrumbTitle">
      <div className="mainTitle" onClick={() => navigate("./")}>
        {title}
      </div>
      {nodes}
    </div>
  );
});
