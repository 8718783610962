import React, { useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import moment from "moment";
import { Tooltip } from "react-bootstrap";
import {
  XAxis,
  YAxis,
  Line,
  Bar,
  ReferenceArea,
  ComposedChart,
  Tooltip as RechartsTooltips,
} from "recharts";

import { Img, Spinner, OverlayTrigger } from "../Components";
import { useSetError } from "../redux/modals";

import AutoSizer from "react-virtualized-auto-sizer";

import { awaitJSON, LinearLambdaFetch } from "../utils/fetch-utils";
import { moneyColor, calendarColors, gray } from "../utils/colors";

import { createSeriesData, DATE_FORMAT } from "./LinearPacingUtils";

import "./LinearPacing.scss";

const getQuarterInfo = dateStr => {
  const dateParts = dateStr.match(/(\d\d\d\d-\d\d-\d\d)$/);
  // Broadcast quarters are calculated as follows:
  //    for a given week (that starts on Monday), find the month of the Sunday of that week
  //    then, divide by 3 to figure out the quarter
  if (!dateParts) {
    throw new Error("Date wasn't parseable");
  }

  let weekStart = moment(dateParts[1], "YYYY-MM-DD");
  let weekEnd = weekStart.clone().add(1, "week").subtract(1, "day");
  let calendarQuarter = Math.floor(weekEnd.get("month") / 3);
  return calendarQuarter;
};

const LinearPacingForecast = ({ startDate, endDate }) => {
  const [clearanceData, setClearanceData] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  const dateInfo = useMemo(() => {
    if (!startDate || !endDate) {
      return {};
    }

    const dfnsStartDate = Dfns.parseISO(startDate);
    const dfnsEndDate = Dfns.parseISO(endDate);
    let range = [Dfns.format(DATE_FORMAT, dfnsStartDate)];
    let tempDate = Dfns.addWeeks(0, dfnsStartDate);
    while (!Dfns.isEqual(tempDate, dfnsEndDate)) {
      tempDate = Dfns.addWeeks(1, tempDate);
      range.push(Dfns.format(DATE_FORMAT, tempDate));
    }

    return {
      start: Dfns.format(DATE_FORMAT, dfnsStartDate),
      end: Dfns.format(DATE_FORMAT, dfnsEndDate),
      range,
    };
  }, [startDate, endDate]);

  const { start, end, range } = dateInfo;
  const setError = useSetError();

  useEffect(() => {
    if (start && end) {
      setForecastData(null);
      setClearanceData(null);

      (async () => {
        try {
          let forecastData = await LinearLambdaFetch("/clearance/forecast", {
            params: {
              start,
              end,
            },
          });
          let historicalData = await LinearLambdaFetch("/clearance", {
            params: {
              group: "company",
              start,
              end,
            },
          });
          forecastData = await awaitJSON(forecastData);
          historicalData = await awaitJSON(historicalData);
          setForecastData(forecastData);
          setClearanceData(historicalData);
        } catch (e) {
          setError({ message: "Could not fetch data for forecast page", reportError: e });
        }
      })();
    }
  }, [start, end, setError]);

  const historicalSeriesData = useMemo(() => {
    if (!clearanceData) {
      return null;
    }

    const series = createSeriesData("company", clearanceData, range);
    return series;
  }, [clearanceData, range]);

  const forecastSeriesData = useMemo(() => {
    if (!forecastData) {
      return null;
    }

    const series = createSeriesData("company", forecastData, range);

    return series;
  }, [forecastData, range]);

  const RectangleBar = props => {
    const { fill, x, y, width, height, payload } = props;

    if (!payload.Actual) {
      return null;
    }

    return <path d={`M${x} ${y} h ${width} v ${height} h ${-width} Z`} stroke="none" fill={fill} />;
  };

  const CustomizedDot = props => {
    const { cx, cy, payload } = props;

    if (!payload.Forecast) {
      return null;
    }

    if (payload.dark) {
      return (
        <svg x={cx - 5} y={cy - 5} width={10} height={10} fill={gray} viewBox="0 0 1024 1024">
          <line x1="0" y1="0" x2="1024" y2="1024" stroke={gray} strokeWidth="256" />
          <line x1="1024" y1="0" x2="0" y2="1024" stroke={gray} strokeWidth="256" />
        </svg>
      );
    } else {
      return (
        <svg x={cx - 5} y={cy - 5} width={10} height={10} fill={moneyColor} viewBox="0 0 1024 1024">
          <path
            d="M 512, 512
                  m -512, 0
                  a 512,512 0 1,0 1024,0
                  a 512,512 0 1,0 -1024,0"
          />
        </svg>
      );
    }
  };

  return (
    <div className="pacingDashboard linearPacingDashboard">
      <div className="chartPanes">
        <div className="leftPane">
          <div className="pacingPane">
            <div className="pacingLines">
              {forecastSeriesData && historicalSeriesData ? (
                (() => {
                  const { groupedByKey: forecastByCompany = {} } = forecastSeriesData;
                  const { groupedByKey: historicalByCompany = {} } = historicalSeriesData;
                  const sharedKeys =
                    R.intersection(R.keys(historicalByCompany), R.keys(forecastByCompany)).sort() ||
                    [];

                  let mergedSeries = R.pipe(
                    R.defaultTo([]),
                    R.map(company => {
                      let forecastCompanyData = forecastByCompany[company];
                      let historicalCompanyData = historicalByCompany[company];
                      let merged = forecastCompanyData.map((point, idx) => {
                        const historicalPoint = R.path([idx], historicalCompanyData) || {};
                        const actual = R.prop("clearance_rate", historicalPoint);

                        return {
                          Forecast: R.prop("rate", point),
                          Actual: actual,
                          week: R.prop("week", point),
                          company: R.prop("company", point),
                          quarter: getQuarterInfo(R.prop("week", point)),
                          dark: R.defaultTo(0, R.prop("spend_expected", historicalPoint)) === 0,
                        };
                      });

                      return [company, merged];
                    }),
                    R.fromPairs
                  )(sharedKeys);

                  return sharedKeys.map(company => {
                    let minY = R.min(
                      R.pipe(
                        R.pluck("Forecast"),
                        R.filter(Number.isFinite),
                        R.sortBy(R.identity),
                        R.head()
                      )(mergedSeries[company]),
                      R.pipe(
                        R.pluck("Actual"),
                        R.filter(Number.isFinite),
                        R.sortBy(R.identity),
                        R.head()
                      )(mergedSeries[company])
                    );
                    if (minY % 10 === 0) {
                      minY -= 10;
                    } else {
                      minY -= minY % 10;
                    }

                    minY = Math.max(0, minY);

                    let quarters = [];
                    for (let elem of mergedSeries[company]) {
                      if (quarters.length === 0) {
                        quarters.push({ start: elem.week, quarter: elem.quarter });
                      } else if (quarters[quarters.length - 1].quarter !== elem.quarter) {
                        quarters[quarters.length - 1].end = elem.week;
                        quarters.push({ start: elem.week, quarter: elem.quarter });
                      }
                    }
                    let avgForecast = Math.round(
                      R.pipe(
                        R.pluck("Forecast"),
                        R.filter(Number.isFinite),
                        R.sum
                      )(mergedSeries[company]) /
                        R.pipe(
                          R.pluck("Forecast"),
                          R.filter(Number.isFinite)
                        )(mergedSeries[company]).length
                    );
                    let avgClearance = Math.round(
                      R.pipe(
                        R.pluck("Actual"),
                        R.filter(Number.isFinite),
                        R.sum
                      )(mergedSeries[company]) /
                        R.pipe(R.pluck("Actual"), R.filter(Number.isFinite))(mergedSeries[company])
                          .length
                    );

                    return (
                      <div
                        key={company}
                        className="pacingRow"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "15px",
                          cursor: "pointer",
                          minHeight: "185px",
                        }}
                      >
                        <Img
                          className="logo"
                          src={`https://cdn.blisspointmedia.com/companies/${company}/logo.png`}
                          title={company}
                          style={{
                            maxHeight: "50%",
                          }}
                          loader={
                            <span className="logo">
                              {company}
                              <Spinner size={25} />
                            </span>
                          }
                          unloader={<span className="logo">{company}</span>}
                        />
                        <div style={{ height: 150, width: "70%" }}>
                          <AutoSizer>
                            {({ width, height }) => (
                              <ComposedChart
                                width={width}
                                height={height}
                                data={mergedSeries[company]}
                                margin={{ top: 5, right: 30, left: 10, bottom: 5 }}
                              >
                                <XAxis
                                  dataKey="week"
                                  ticks={range}
                                  tickCount={range.length}
                                  axisLine={false}
                                  tickLine={false}
                                  padding={{ left: 25 }}
                                  domain={[range[0], range[range.length - 1]]}
                                />
                                <YAxis
                                  axisLine={false}
                                  tickLine={false}
                                  tickFormatter={val => `${val}%`}
                                  domain={[minY, 100]}
                                />
                                <Bar
                                  dataKey="Actual"
                                  barSize={20}
                                  isAnimationActive={false}
                                  fill="#413ea0"
                                  shape={<RectangleBar />}
                                />
                                <Line
                                  type="monotone"
                                  dataKey="Forecast"
                                  isAnimationActive={false}
                                  stroke={moneyColor}
                                  tickFormatter={val => `${val}%`}
                                  dot={<CustomizedDot />}
                                />
                                <RechartsTooltips
                                  isAnimationActive={false}
                                  formatter={val => `${val}%`}
                                />
                                {quarters.map(q => (
                                  <ReferenceArea
                                    key={q.start}
                                    x1={q.start}
                                    x2={q.end}
                                    y1={minY}
                                    y2={100}
                                    fill={calendarColors[q.quarter]}
                                    opacity={0.25}
                                  />
                                ))}
                              </ComposedChart>
                            )}
                          </AutoSizer>
                        </div>
                        <OverlayTrigger
                          placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                          overlay={
                            <Tooltip id={`summary_${company}`}>
                              {"Clearance % / Forecast %"}
                            </Tooltip>
                          }
                        >
                          <div className="avail-title">
                            <span style={{ color: "#413ea0" }}>{avgClearance}%</span> /{" "}
                            <span style={{ color: moneyColor }}>{avgForecast}%</span>
                          </div>
                        </OverlayTrigger>
                      </div>
                    );
                  });
                })()
              ) : (
                <Spinner size={100} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinearPacingForecast;
