import React from "react";
import { InfoTooltip } from "../Components";
import { Channel } from "./MarketingPortfolio";
import "./MarketingPortfolioLegend.scss";

interface MarketingPortfolioLegendProps {
  channels: Channel[];
  channelClassification: Record<string, string>;
}

const ColoredSquare = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 10,
      width: 10,
      borderColor: color,
      borderRadius: "50%",
    }}
  />
);

const MarketingPortfolioLegend: React.FC<MarketingPortfolioLegendProps> = ({
  channels,
  channelClassification,
}) => {
  return (
    <div className="marketingPortfolioLegend">
      {channels.map(channelInfo => {
        const { channel, color } = channelInfo;
        return (
          <div className="legendItem" key={channel}>
            <span className="key">
              <ColoredSquare color={color} />
            </span>
            <div className={`title ${channelClassification[channel]}`}>{channel}</div>
          </div>
        );
      })}
      <InfoTooltip>
        <div>
          Italicized channels are shown in 100's of impressions. All other channels are shown in
          spend dollars.
        </div>
      </InfoTooltip>
    </div>
  );
};

export default MarketingPortfolioLegend;
