import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetAreYouSure, useSetError } from "../redux/modals";

import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";
import { downloadJSONToCSV } from "../utils/download-utils";

import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  OverlayTrigger,
  Tooltip,
  SelectorOption,
  PendingChangesPanel,
  PendingChangesControls,
  Button,
  ButtonType,
} from "../Components";
import "./AccountPlanning.scss";

import {
  AccountPlanningRowInternal,
  AccountPlanningRowDisplay,
} from "@blisspointmedia/bpm-types/dist/pgTables/AccountPlanning";

import {
  UpdateAccountPlanningRowsParams,
  GetAccountPlanningRowsResponse,
  UserAccessContact,
} from "@blisspointmedia/bpm-types/dist/AccountPlanning";

import { HiDownload } from "react-icons/hi";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

const AGENCY_WIDTH_MODAL = 150;
const MAIN_MODAL_WIDTH = 200;
const UNVIEWABLE_WIDTH = 0;

const WIDTHS = {
  COMPANY: 200,
  TIER: 75,
  SUBGROUP: 100,
  PRIMARY_CONTACT: 150,
  SECONDARY_CONTACTS: 150,
  MEDIA_OPS_CONTACT: 150,
  MEDIA_OPS_SECONDARY: 210,
  MEDIA_LINEAR_SECONDARY: 210,
  MEDIA_ADDITIONAL: 210,
  INVESTMENT_LEAD: 150,
  MEDIA_LINEAR: 150,
  ECONOMETRICS: 150,
  SLACK_CHANNEL: 150,
  NOTES: 250,
  RELAUNCH: 150,
  GODARK: 150,
  AGENCY: 100,
  GROWTH: 80,
};
const MODAL_ROWS = {
  COMPANY: 0,
  TIER: 0,
  SUBGROUP: 0,
  PRIMARY_CONTACT: 1,
  SECONDARY_CONTACTS: 1,
  MEDIA_OPS_CONTACT: 2,
  MEDIA_OPS_SECONDARY: 2,
  INVESTMENT_LEAD: 4,
  MEDIA_LINEAR: 3,
  MEDIA_LINEAR_SECONDARY: 3,
  ECONOMETRICS: 5,
  EXECUTIVE_SPONSOR: 5,
  MEDIA_ADDITIONAL: 4,
  SLACK_CHANNEL: 5,
  NOTES: 6,
  RELAUNCH: 6,
  GODARK: 6,
  GROWTH: 6,
  AGENCY: 0,
  LAST_MODIFIED: 7,
  LAST_USER: 7,
};

const DEFAULT_NEW_ROW = {
  secondary_contacts: [],
  media_ops_secondary: [],
  senior_media_secondary: [],
};

const TIER_OPTIONS = [0, 1, 2, 3, 4, 5];

const AGENCY_OPTIONS = ["BPM", "PPM", "Tinuiti"];

const AccountPlanning: React.FC = () => {
  const [tableData, setTableData] = useState<AccountPlanningRowDisplay[]>();
  const [originalTableData, setOriginalTableData] = useState<AccountPlanningRowDisplay[]>();
  const [originalTableDataMap, setOriginalTableDataMap] = useState<
    Record<string, AccountPlanningRowDisplay>
  >();
  const [companies, setCompanies] = useState<string[]>();
  const [updatedRows, setUpdatedRows] = useState<AccountPlanningRowDisplay[]>([]);
  const [userAcessContacts, setUserAccessContacts] = useState<UserAccessContact[]>();
  const [contactNames, setContactNames] = useState<string[]>();

  const [accountPlanningData, setaccountPlanningData] = useState<GetAccountPlanningRowsResponse>();

  const [saving, setSaving] = useState(false);
  const [invalidText, setInvalidText] = useState<string>();
  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [showTableData, setShowTableData] = useState(false);
  const [liveLinearCompanies, setLiveLinearCompanies] = useState<string[]>();
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const getFreshAccountPlanningData = useCallback(async () => {
    try {
      setShowTableData(false);
      let res = await MiscLambdaFetch("/getAccountPlanningData");
      let jsonResponse = await awaitJSON<GetAccountPlanningRowsResponse>(res);
      setaccountPlanningData(jsonResponse);
      setCompanies(jsonResponse.companies);
      setUserAccessContacts(jsonResponse.userAccessContacts);
      setContactNames(jsonResponse.contactNames);
      setTableData(jsonResponse.tableData);
      setOriginalTableData(jsonResponse.tableData);
      setLiveLinearCompanies(jsonResponse.liveLinearCompanies);

      let originalTableDataMap: Record<string, AccountPlanningRowDisplay> = {};
      for (let row of jsonResponse.tableData) {
        originalTableDataMap[row.id] = row;
      }
      setOriginalTableDataMap(originalTableDataMap);
      setShowTableData(true);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get account planning data. ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    if (!tableData) {
      (async () => {
        await getFreshAccountPlanningData();
      })();
    }
  }, [setError, getFreshAccountPlanningData, tableData]);

  const downloadAsCSV = () => {
    let date = new Date();
    let csvData = R.map(
      row => ({
        company: row.company,
        tier: row.tier,
        subgroup: row.company_subgroup,
        primary: row.primary_contact,
        secondary: row.secondary_contacts,
        media_ops: row.media_ops_contact,
        media_ops_secondary: row.media_ops_secondary,
        investment_lead_contact: row.investment_lead_contact,
        investment_lead_secondary: row.investment_lead_secondary,
        senior_media_contact: row.senior_media_contact,
        senior_media_secondary: row.senior_media_secondary,
        executive_sponsor: row.executive_sponsor,
        econometrics: row.econometrics_contact,
        media_additional: row.media_additional,
        notes: row.notes,
        slack_channel: row.slack_channel,
        agency: row.agency,
        relaunch: row.relaunch_date,
        godark: row.godark_date,
        growth: row.growth,
      }),
      tableData || []
    );

    downloadJSONToCSV(csvData, `AccountPlanning ${date.toUTCString()}`);
  };

  useEffect(() => {
    if (tableData && originalTableDataMap) {
      let isUpdated = (row: AccountPlanningRowDisplay) => {
        if (originalTableDataMap[row.id]) {
          let originalRow = originalTableDataMap[row.id];
          let arrayEquals = (a, b) => {
            let setA = new Set(a);
            let setB = new Set(b);
            let setEquals = (x, y) => x.size === y.size && [...x].every(value => y.has(value));
            return setEquals(setA, setB);
          };
          return (
            row.id !== null &&
            (row.company !== originalRow.company ||
              row.tier !== originalRow.tier ||
              row.company_subgroup !== originalRow.company_subgroup ||
              row.primary_contact !== originalRow.primary_contact ||
              !arrayEquals(row.secondary_contacts, originalRow.secondary_contacts) ||
              row.media_ops_contact !== originalRow.media_ops_contact ||
              !arrayEquals(row.media_ops_secondary, originalRow.media_ops_secondary) ||
              row.investment_lead_contact !== originalRow.investment_lead_contact ||
              row.investment_lead_secondary !== originalRow.investment_lead_secondary ||
              row.econometrics_contact !== originalRow.econometrics_contact ||
              row.executive_sponsor !== originalRow.executive_sponsor ||
              row.senior_media_contact !== originalRow.senior_media_contact ||
              !arrayEquals(row.senior_media_secondary, originalRow.senior_media_secondary) ||
              !arrayEquals(row.media_additional, originalRow.media_additional) ||
              row.notes !== originalRow.notes ||
              row.slack_channel !== originalRow.slack_channel ||
              row.relaunch_date !== originalRow.relaunch_date ||
              row.godark_date !== originalRow.godark_date ||
              row.agency !== originalRow.agency ||
              row.growth !== originalRow.growth)
          );
        }
        return false;
      };
      let updatedRows = R.filter(isUpdated, tableData);
      setUpdatedRows(updatedRows);
    }
  }, [originalTableDataMap, tableData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const isChanged = !R.isEmpty(updatedRows);

    if (!isChanged) {
      // if there are no pending changes force the pending pane to
      // close
      setShowPendingChanges(false);
    }

    return isChanged;
  }, [updatedRows]);

  const clearAllChanges = useCallback(() => {
    setTableData(originalTableData);
    setUpdatedRows([]);
  }, [originalTableData]);

  const selectorOptions = useMemo(() => {
    if (accountPlanningData) {
      return {
        agency: R.map(e => ({ label: e, value: e.toLowerCase() }), AGENCY_OPTIONS),
        company: R.map(e => ({ label: e, value: e }), companies || []),
        allContacts: R.map(contact => ({ label: contact, value: contact }), contactNames || []),
        tierOptions: R.map(e => ({ label: e, value: e }), TIER_OPTIONS),
      } as Record<string, SelectorOption[]>;
    }
  }, [accountPlanningData, companies, contactNames]);

  const getUserID = useCallback(
    (fullName: string | null, agency: string) => {
      for (let user of userAcessContacts || []) {
        if (user.name === fullName) {
          return user.userid;
        }
      }
      return null;
    },
    [userAcessContacts]
  );

  const save = useCallback(async () => {
    const getInternalData = (
      displayData: AccountPlanningRowDisplay[]
    ): AccountPlanningRowInternal[] => {
      return R.map(
        row => ({
          id: row.id,
          company: row.company,
          tier: row.tier,
          company_subgroup: row.company_subgroup,
          primary_contact: getUserID(row.primary_contact, row.agency) || -1,
          secondary_contacts: row.secondary_contacts
            .map(x => getUserID(x, row.agency))
            .filter(x => !R.isNil(x)) as number[],
          media_ops_contact: getUserID(row.media_ops_contact, row.agency),
          media_ops_secondary: row.media_ops_secondary
            .map(x => getUserID(x, row.agency))
            .filter(x => !R.isNil(x)) as number[],
          investment_lead_contact: getUserID(row.investment_lead_contact, row.agency),
          investment_lead_secondary: getUserID(row.investment_lead_secondary, row.agency),
          senior_media_contact: getUserID(row.senior_media_contact, row.agency),
          senior_media_secondary: row.senior_media_secondary
            .map(x => getUserID(x, row.agency))
            .filter(x => !R.isNil(x)) as number[],
          econometrics_contact: getUserID(row.econometrics_contact, row.agency),
          media_additional: row.media_additional
            .map(x => getUserID(x, row.agency))
            .filter(x => !R.isNil(x)) as number[],
          executive_sponsor: getUserID(row.executive_sponsor, row.agency),
          notes: row.notes,
          slack_channel: row.slack_channel,
          agency: row.agency,
          relaunch_date: row.relaunch_date,
          godark_date: row.godark_date,
          lastuser: row.lastuser,
          created: row.created,
          lastmodified: row.lastmodified,
          growth: row.growth,
        }),
        displayData
      );
    };
    const internalBody: UpdateAccountPlanningRowsParams = {
      insert: [],
      update: getInternalData(updatedRows || []),
      delete: [],
    };

    // If tier or go-dark/relaunch date changes, make sure we warn people to update the Relationship Status of the client.
    let shouldShowRelationshipChangeWarning = false;
    for (const row of updatedRows) {
      if (originalTableDataMap) {
        const originalRow = originalTableDataMap[row.id];
        if (
          row.id !== null &&
          (row.tier !== originalRow.tier ||
            row.relaunch_date !== originalRow.relaunch_date ||
            row.godark_date !== originalRow.godark_date)
        ) {
          shouldShowRelationshipChangeWarning = true;
        }
      }
    }

    if (shouldShowRelationshipChangeWarning) {
      await setAreYouSure({
        title: "Relationship Status Change",
        message: (
          <div>
            Client relationship churned, going dark, or relaunching? Please also complete the{" "}
            <a
              href="https://www.wrike.com/frontend/requestforms/index.html?token=eyJhY2NvdW50SWQiOjE4ODY1NCwidGFza0Zvcm1JZCI6NDY4NzA2fQk0Nzc0NTM0OTQxNzk3CTVhMmIyOTljYTViNTM5ZjUyNTYzNjhlZmU4Njc1ZmUxYzYxOWFlZTY1NzhkNzJjNmU4M2I3MTk0NWEwYTQ1ZWE="
              target="_blank"
              rel="noopener noreferrer"
            >
              Relationship Status Change form (RSCF)
            </a>{" "}
            to update our internal systems.
          </div>
        ),
        okayText: "I understand",
      });
    }

    try {
      setSaving(true);
      await MiscLambdaFetch<UpdateAccountPlanningRowsParams>("/setAccountPlanningData", {
        method: "POST",
        body: internalBody,
      });

      await getFreshAccountPlanningData();
      setSaving(false);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to set Accout Planning data. \n ERROR: ${reportError.message}`,
        reportError,
      });
    }
  }, [
    updatedRows,
    getUserID,
    originalTableDataMap,
    getFreshAccountPlanningData,
    setAreYouSure,
    setError,
  ]);

  const checkIsValid = (data: AccountPlanningRowDisplay) => {
    const { company, tier, company_subgroup, agency, slack_channel } = data;
    if (!company) {
      setInvalidText("Company field cannot be empty");
      return false;
    }
    const isEmpty = a => {
      if (a === null || a === undefined) {
        return true;
      }
    };

    for (const row of tableData || []) {
      if (row.id !== data.id && row.company === company) {
        const cur = row.company_subgroup;
        const other = company_subgroup;
        if (row.company_subgroup === company_subgroup || (isEmpty(cur) && isEmpty(other))) {
          setInvalidText(
            `Company ${company} ${
              isEmpty(cur) ? "" : `with subgroup ${company_subgroup}`
            } already exists.`
          );
          return false;
        }
      }
    }
    if (!(tier in TIER_OPTIONS)) {
      setInvalidText("Company must be assigned a tier value");
      return false;
    }
    if (!agency) {
      setInvalidText("Company must be assigned to an agency");
      return false;
    }
    if (slack_channel) {
      let doesntStartWithHashtag = !(slack_channel.charAt(0) === "#");
      let isNotOneWord = slack_channel.includes(" ");
      if (doesntStartWithHashtag) {
        setInvalidText("Slack Channel must begin with '#'");
        return false;
      }
      if (isNotOneWord) {
        setInvalidText("Slack Channel cannot contain spaces.");
        return false;
      }
    }

    return true;
  };

  const tierCellRenderer = data => {
    const tierValue = data.tier;
    return (
      <div className="labeledBox">
        <div className={`tier${tierValue}`}>{tierValue}</div>
      </div>
    );
  };

  const arrayCellRenderer = (data, field) => {
    const array = data[field];
    if (!array.length) {
      return <>-</>;
    }
    if (array.length >= 1 && array.length <= 2) {
      return (
        <div className="secondariesCell">
          {array.map(el => {
            return (
              <div key={el} className="secondaryName">{`${
                array.length === 2 ? "•" : ""
              } ${el}`}</div>
            );
          })}
        </div>
      );
    } else {
      let displayValue = (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={<Tooltip>{array.join(", ")}</Tooltip>}
        >
          <span>{array.length} selected</span>
        </OverlayTrigger>
      );
      return displayValue;
    }
  };

  const secondariesRenderer = data => {
    return arrayCellRenderer(data, "secondary_contacts");
  };
  const mediaOpsSecondariesRenderer = data => {
    return arrayCellRenderer(data, "media_ops_secondary");
  };
  const mediaLinearSecondariesRenderer = data => {
    return arrayCellRenderer(data, "senior_media_secondary");
  };
  const mediaAdditionalRenderer = data => {
    return arrayCellRenderer(data, "media_additional");
  };
  const investmentRenderer = useCallback(
    (data, field) => {
      const cellValue = data[field];
      if (!cellValue) {
        return <>-</>;
      }

      const className = R.includes(data.company, liveLinearCompanies || []) ? "" : "disabledCell";
      return <div className={className}>{cellValue}</div>;
    },
    [liveLinearCompanies]
  );
  const agencyLabelRenderer = data => {
    const agencyValue = data.agency;
    const agencyLabelMap = {
      bpm: "BPM",
      ppm: "PPM",
      tinuiti: "Tinuiti",
    };
    return (
      <div className="labeledBox">
        <div className={`agency${agencyValue}`}>{agencyLabelMap[agencyValue]}</div>
      </div>
    );
  };

  const contactsHeader = [
    {
      label: "Company",
      field: "company",
      type: "text",
      readOnly: true,
      width: WIDTHS.COMPANY,
      modalRow: MODAL_ROWS.COMPANY,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Tier",
      field: "tier",
      type: "select",
      options: "tierOptions",
      width: WIDTHS.TIER,
      modalRow: MODAL_ROWS.TIER,
      modalFlex: 1,
      renderer: tierCellRenderer,
    },
    {
      label: "Primary Contact",
      field: "primary_contact",
      type: "select",
      options: "allContacts",
      width: WIDTHS.PRIMARY_CONTACT,
      modalRow: MODAL_ROWS.PRIMARY_CONTACT,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Secondaries",
      field: "secondary_contacts",
      type: "select",
      options: "allContacts",
      width: WIDTHS.SECONDARY_CONTACTS,
      modalRow: MODAL_ROWS.SECONDARY_CONTACTS,
      modalFlex: 1,
      renderer: secondariesRenderer,
      isMulti: true,
    },
    {
      label: "Media Ops Primary",
      field: "media_ops_contact",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_OPS_CONTACT,
      modalRow: MODAL_ROWS.MEDIA_OPS_CONTACT,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Media Ops Secondaries",
      field: "media_ops_secondary",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_OPS_SECONDARY,
      modalRow: MODAL_ROWS.MEDIA_OPS_SECONDARY,
      modalFlex: 1,
      renderer: mediaOpsSecondariesRenderer,
      isMulti: true,
    },
    {
      label: "Investment Lead Primary",
      field: "investment_lead_contact",
      type: "select",
      options: "allContacts",
      renderer: investmentRenderer,
      width: WIDTHS.INVESTMENT_LEAD,
      modalRow: MODAL_ROWS.INVESTMENT_LEAD,
      modalFlex: 1,
    },
    {
      label: "Investment Lead Secondary",
      field: "investment_lead_secondary",
      type: "select",
      options: "allContacts",
      renderer: investmentRenderer,
      width: WIDTHS.INVESTMENT_LEAD,
      modalRow: MODAL_ROWS.INVESTMENT_LEAD,
      modalFlex: 1,
    },
    {
      label: "Media Strategy Lead",
      field: "senior_media_contact",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_LINEAR,
      modalRow: MODAL_ROWS.MEDIA_LINEAR,
      modalWidth: MAIN_MODAL_WIDTH,
    },
    {
      label: "Media Strategy Lead Secondary",
      field: "senior_media_secondary",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_LINEAR_SECONDARY,
      modalRow: MODAL_ROWS.MEDIA_LINEAR_SECONDARY,
      modalFlex: 1,
      isMulti: true,
      renderer: mediaLinearSecondariesRenderer,
    },
    {
      label: "Econometrics Contact",
      field: "econometrics_contact",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_LINEAR,
      modalRow: MODAL_ROWS.ECONOMETRICS,
      modalFlex: 1,
    },
    {
      label: "Media Additional",
      field: "media_additional",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_ADDITIONAL,
      modalRow: MODAL_ROWS.MEDIA_ADDITIONAL,
      modalFlex: 1,
      isMulti: true,
      renderer: mediaAdditionalRenderer,
    },
    {
      label: "Executive Media Lead",
      field: "executive_sponsor",
      type: "select",
      options: "allContacts",
      width: WIDTHS.MEDIA_LINEAR,
      modalRow: MODAL_ROWS.EXECUTIVE_SPONSOR,
      modalFlex: 1,
    },
    {
      label: "Slack Channel",
      field: "slack_channel",
      type: "text",
      width: WIDTHS.SLACK_CHANNEL,
      modalRow: MODAL_ROWS.SLACK_CHANNEL,
      modalFlex: 1,
    },
    {
      label: "Notes",
      field: "notes",
      type: "text",
      width: WIDTHS.NOTES,
      modalRow: MODAL_ROWS.NOTES,
      modalFlex: 1,
    },
    {
      label: "Re-Launch Date",
      field: "relaunch_date",
      type: "day",
      width: WIDTHS.RELAUNCH,
      modalRow: MODAL_ROWS.RELAUNCH,
      modalWidth: 130,
    },
    {
      label: "Go-Dark Date",
      field: "godark_date",
      type: "day",
      width: WIDTHS.GODARK,
      modalRow: MODAL_ROWS.GODARK,
      modalWidth: 130,
    },
    {
      label: "Growth",
      field: "growth",
      type: "text",
      width: WIDTHS.GROWTH,
      modalRow: MODAL_ROWS.GROWTH,
      modalWidth: 80,
      renderer: row => {
        return row.growth === "true" || row.growth === true ? "true" : "false";
      },
    },
    {
      label: "Subgroup",
      field: "company_subgroup",
      type: "text",
      width: WIDTHS.SUBGROUP,
      modalRow: MODAL_ROWS.SUBGROUP,
      modalFlex: 1,
    },
    {
      label: "Agency",
      field: "agency",
      options: "agency",
      type: "select",
      width: WIDTHS.AGENCY,
      modalRow: MODAL_ROWS.AGENCY,
      modalWidth: AGENCY_WIDTH_MODAL,
      renderer: agencyLabelRenderer,
    },
    {
      label: "Last Modified",
      field: "lastmodified",
      type: "text",
      readOnly: true,
      modalRow: MODAL_ROWS.LAST_MODIFIED,
      modalFlex: 1,
      width: UNVIEWABLE_WIDTH,
      nonInteractive: true,
    },
    {
      label: "Last User",
      field: "lastuser",
      type: "text",
      readOnly: true,
      modalRow: MODAL_ROWS.LAST_USER,
      modalFlex: 1,
      width: UNVIEWABLE_WIDTH,
      nonInteractive: true,
    },
  ];

  return (
    <Page
      title="Account Planning"
      pageType="Account Planning"
      minHeight="600px"
      actions={
        <div className="accountPlanningActions">
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<HiDownload />}
            onClick={downloadAsCSV}
          >
            Download
          </Button>
          <PendingChangesControls
            hasPendingChanges={hasPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            saveChanges={save}
            isSaving={saving}
            clearAllChanges={clearAllChanges}
          />
        </div>
      }
    >
      <div className="accountPlanningPageContainer">
        {showTableData && tableData && selectorOptions ? (
          <ModalEditTable<AccountPlanningRowDisplay>
            className="accountPlanningTable"
            headers={contactsHeader}
            tableData={tableData}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            filterBar
            enableDelete={false}
            enableAdd={false}
            checkIsValid={checkIsValid}
            invalidText={invalidText}
            rowHeight={45}
            showModalOnAdd={true}
            defaultNewRow={DEFAULT_NEW_ROW}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            originalData={originalTableDataMap || {}}
            pendingChanges={{
              editedRows: updatedRows,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={contactsHeader}
          />
        )}
      </div>
    </Page>
  );
};

export default AccountPlanning;
