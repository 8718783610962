import React, { useCallback, useMemo, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { BsSquareFill } from "react-icons/bs";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Importance } from "@blisspointmedia/bpm-types/dist/Creative";

import "./AttributesChart.scss";
import ChartContainer from "../Components/ChartContainer";
import { Button, ButtonType, Spinner, InfoTooltip } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { MdDownload } from "react-icons/md";
import * as XLSX from "xlsx";
import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";

interface AttributesChartProps {
  selectedAttribute: string;
  handleAttributeClick: (attributeName: string) => void;
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>;
  fillColors: Map<string, string>;
  attributeImportanceData: Importance[];
  suppressedAttributes: Set<string>;
  kpi: string;
  showDownloadButton: boolean;
}

const AttributesChart = ({
  selectedAttribute,
  handleAttributeClick,
  fillColors,
  attributeImportanceData,
  suppressedAttributes,
  kpi,
  showDownloadButton,
}: AttributesChartProps): JSX.Element => {
  // Exclude suppressed attributes and normalize data so they add to 100
  const setError = useSetError();
  const data: Importance[] = useMemo(() => {
    let output: Importance[] = [];
    let temp: Importance[] = [];
    let sum = 0;
    if (suppressedAttributes) {
      attributeImportanceData.forEach(attribute => {
        if (!suppressedAttributes.has(attribute.name)) {
          let { val } = attribute;
          sum += val;
          temp.push({ name: attribute.name, val });
        }
      });

      temp.forEach(attribute => {
        let updatedVal = (attribute.val / sum) * 100;
        output.push({ name: attribute.name, val: Math.round(updatedVal * 10) / 10 });
      });
    }

    return output;
  }, [attributeImportanceData, suppressedAttributes]);

  const [hoveredAttribute, setHoveredAttribute] = useState<string>("");

  const attributeNamesList: JSX.Element[] = useMemo(() => {
    return data?.map((attribute, index) => {
      return (
        <ListGroup.Item
          className="attribute"
          key={attribute.name}
          onClick={() => handleAttributeClick(attribute.name)}
          active={selectedAttribute === attribute.name}
          variant={"Light"}
          action
        >
          <div className="color">
            <BsSquareFill fill={fillColors.get(attribute.name)} />
          </div>
          <div className="nameAndValue">
            <b className="name">{attribute.name}</b>
            {` ${"\u2014"} ${attribute.val}%`}
          </div>
        </ListGroup.Item>
      );
    });
  }, [data, fillColors, handleAttributeClick, selectedAttribute]);

  let userRole = useSelector(UserRedux.roleSelector);
  const [downloading, setDownloading] = useState<boolean>(false);
  const handleDownload = useCallback(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/fetchRawCreativeInsightsData", {
          params: {
            dataType: "attribute",
            kpi,
          },
        });
        let parsedRes = await awaitJSON<string[][]>(res);
        let fileName = "linear_creative_importances.xlsx";
        let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
        let worksheet = XLSX.utils.json_to_sheet(
          parsedRes.map((row: string[]) => {
            return {
              Attribute: row[0],
              Importance: +row[1],
            };
          })
        );

        parsedRes.forEach((row: string[], index: number) => {
          worksheet[`B${index + 2}`].z = "0.0#########";
        });

        let sheetName = "linear creative importances";

        workbook.SheetNames.push(sheetName);
        workbook.Sheets[sheetName] = worksheet;

        XLSX.writeFile(workbook, fileName);
        setDownloading(false);
      } catch (e) {
        setError({
          message: `Couldn't download attribute importances. Error: ${e.message}.`,
          reportError: e,
        });
      }
    })();
  }, [kpi, setError]);

  return (
    <ChartContainer
      title="Attributes"
      leftActions={
        <InfoTooltip size="reg">
          {`The attribute importance scores reflect the fraction of total creative-driven variation in
  KPI outcomes accounted for by a given attribute. Creative elements will drive some portion
  of total KPI variation – media would also drive a substantial amount – and the attribute
  importances tell us how much of that variation is driven by a given creative element.`}
        </InfoTooltip>
      }
      rightActions={
        userRole <= 1 &&
        showDownloadButton && (
          <div style={{ width: "34px" }}>
            {!downloading && (
              <Button
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.ICON_ONLY}
                icon={<MdDownload />}
                size="sm"
                onClick={() => {
                  setDownloading(true);
                  handleDownload();
                }}
              />
            )}
            {downloading && <Spinner />}
          </div>
        )
      }
    >
      <div className="attributesContainer">
        <div className="attributesContent">
          <div className="attributeNames">
            <ListGroup className="attributeNamesList">{attributeNamesList}</ListGroup>
          </div>
          <div className="attributesChart">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Tooltip
                  key="name"
                  content={() => {
                    return (
                      <div className="attributesChartTooltip">
                        {`Attribute: ${hoveredAttribute}`}
                      </div>
                    );
                  }}
                  cursor={true}
                />
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  fill="#8884d8"
                  dataKey="val"
                  onClick={field => {
                    const name = "name";
                    handleAttributeClick(field[name]);
                  }}
                  onMouseEnter={slice => {
                    let name = "name";
                    setHoveredAttribute(slice[name]);
                  }}
                  cursor="pointer"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={fillColors.get(entry.name)} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </ChartContainer>
  );
};

export default AttributesChart;
