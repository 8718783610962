import React, { useState } from "react";
import * as R from "ramda";
import Select from "react-select";
import { MdInfoOutline } from "react-icons/md";
import { Modal, Tooltip } from "react-bootstrap";

import { BPMButton, CheckBox, OverlayTrigger } from "../../Components";
import { RotationsAndPricing } from "../linearBuyingUtils";
import "./AddNewNetworkModal.scss";

interface AddNewNetworkModalProps {
  rotationsAndPricing: RotationsAndPricing;
  addNewNetwork: (selectedNetwork: string) => void;
  onClose: () => void;
}

const AddNewNetworkModal: React.FC<AddNewNetworkModalProps> = ({
  rotationsAndPricing,
  addNewNetwork,
  onClose,
}) => {
  const [selectedNetwork, setSelectedNetwork] = useState("");
  // These include Ampersand, Wide Orbit, and American Target Networks.
  // There are a lot of them and they aren't relevant to most clients' media plans, so we hide them by default.
  const [showLocalAggregators, setShowLocalAggregators] = useState(false);

  const networkOptions = R.keys(rotationsAndPricing)
    .filter(network =>
      showLocalAggregators
        ? true
        : !network.startsWith("AMP") && !network.startsWith("WO") && !network.startsWith("ATN")
    )
    .map(network => ({
      label: network,
      value: network,
    }));

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select a new network to add</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          className="newNetworkSelect"
          onChange={value => setSelectedNetwork(value.label)}
          options={networkOptions}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="checkbox">
          <div className="label">
            <div className="text">Show Local Aggregators</div>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
              overlay={
                <Tooltip id="addNetworkTooltip">
                  <>These include Ampersand, Wide Orbit, and American Target Networks</>
                </Tooltip>
              }
            >
              <MdInfoOutline />
            </OverlayTrigger>
          </div>
          <CheckBox
            checked={showLocalAggregators}
            onClick={() => setShowLocalAggregators(!showLocalAggregators)}
          />
        </div>

        <BPMButton variant="outline-primary" onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton
          variant="primary"
          disabled={!selectedNetwork}
          onClick={() => addNewNetwork(selectedNetwork)}
        >
          Add
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewNetworkModal;
