import React, { useContext, useMemo, useState } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Modal, Button, ListGroup, Row, Col } from "react-bootstrap";

import { Img, DateBox } from "../Components";

import { ActiveOTTContext, getPrettyWeek, makeCPM } from "./ActiveOTT";

import { formatMoney, formatMoneyAsInt, formatNumber } from "../utils/format-utils";

import SpendBreakdown from "./SpendBreakdown";

import "./DetailModal.scss";

const CANCELED_TIMESTAMP_FORMAT = "MM/dd/yyy @ h:mm a";
const makeCanceledTimestamp = R.pipe(Dfns.parseISO, Dfns.format(CANCELED_TIMESTAMP_FORMAT));

const DetailModal = ({ data, onClose }) => {
  const { flightMap, byNetwork } = useContext(ActiveOTTContext);
  const header = useMemo(() => {
    if (!R.path(["flights", "length"], data)) {
      return null;
    }
    let firstFlight = flightMap[data.flights[0]];
    if (!firstFlight) {
      return null;
    }
    let headerInfo = {
      date: getPrettyWeek(data.week),
    };
    if (byNetwork) {
      headerInfo.cid = firstFlight.key;
      return headerInfo;
    } else {
      let platformBits = firstFlight.platform.split(" ");
      let platform = "";
      if (platformBits.length > 1 && platformBits[0] === "Streaming") {
        platform = platformBits.slice(1).join(" ");
      } else {
        ({ platform } = firstFlight);
      }
      return {
        ...headerInfo,
        ...R.pick(["shortCode", "description"], firstFlight),
        platform,
      };
    }
  }, [data, flightMap, byNetwork]);

  const [properties, flights] = useMemo(() => {
    if (!R.path(["flights", "length"], data)) {
      return [];
    }
    let properties = [];
    let flights = [];
    for (let flightID of data.flights) {
      let flight = flightMap[flightID];
      if (flight.properties) {
        properties.push(flight.properties);
      }
      flights.push(flight);
    }
    return [R.pipe(R.flatten, R.uniq)(properties), flights];
  }, [data, flightMap]);

  const [expandedFlightMap, setExpandedFlightMap] = useState({});
  return header ? (
    <Modal show onHide={onClose} dialogClassName="activeOTTDetailModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <Img
            src={`https://cdn.blisspointmedia.com/${
              byNetwork ? `companies/${header.cid}/square_logo` : `networks/${header.shortCode}`
            }.png`}
            unloader={header.shortCode}
          />
          <div className="headerName">
            {byNetwork
              ? header.cid
              : `${header.shortCode}${header.description ? ` ${header.description}` : ""} ${
                  header.platform
                }`}
          </div>
          <div className="week">{header.date}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12} md>
            <div className="dataSection">
              <div className="dataLabel">Bookings</div>
              <div className="valRow">
                <SpendBreakdown data={data} />
              </div>
            </div>
          </Col>
          {properties.length > 0 && (
            <Col>
              <div className="dataSection">
                <div className="dataLabel">Properties</div>
                <div className="valRow">
                  <div className="unlabeled">{properties.join(", ")}</div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div className="dataSection">
          <div className="dataLabel">Flights</div>
          <div className="valRow">
            <ListGroup className="flightList">
              {flights.map(flight => {
                let expanded = expandedFlightMap[flight.id];
                return (
                  <ListGroup.Item
                    key={flight.id}
                    action
                    onClick={() =>
                      setExpandedFlightMap(map => ({
                        ...map,
                        [flight.id]: !expanded,
                      }))
                    }
                  >
                    <div className="mainFlightRow">
                      <div>
                        <div className="flightIDs">
                          Flight #{flight.id}
                          <small>(Order #{flight.orderID})</small>
                        </div>
                        <DateBox start={flight.startDate} end={flight.endDate} />
                      </div>
                      <div>
                        <div className="labeled">
                          <span>Spend:</span>
                          {formatMoneyAsInt(flight.spend)}
                        </div>
                        <div className="labeled">
                          <span>CPM:</span>
                          {flight.cpm ? formatMoney(flight.cpm) : "$0"}
                        </div>
                        <div className="labeled">
                          <span>Impressions:</span>
                          {formatNumber(flight.impressions)}
                        </div>
                      </div>
                    </div>
                    {expanded && (
                      <div className="expandedSection">
                        {byNetwork && (
                          <>
                            <div className="labeled">
                              <span>Platform:</span>
                              {flight.platform || "No Description"}
                            </div>
                            <div className="labeled">
                              <span>Description:</span>
                              {flight.description || "No Description"}
                            </div>
                          </>
                        )}
                        {R.path(["properties", "length"], flight) ? (
                          <div className="labeled">
                            <span>Properties:</span>
                            {flight.properties.join(", ")}
                          </div>
                        ) : null}
                        {flight.canceled && (
                          <div className="labeled">
                            <span>Canceled:</span>
                            {makeCanceledTimestamp(flight.canceled)}
                          </div>
                        )}
                        {flight.overrides && (
                          <div className="expandedRow">
                            <div className="labeled">
                              <span>
                                Overrides <small>(spend/cpm/imps)</small>:
                              </span>
                              {formatMoneyAsInt(flight.overrides.spend)} /{" "}
                              {formatMoney(
                                makeCPM(flight.overrides.spend, flight.overrides.impressions)
                              )}{" "}
                              / {formatNumber(flight.overrides.impressions)}
                            </div>
                          </div>
                        )}
                        {flight.derivedNetwork && (
                          <div className="labeled">
                            <span>Derived Network:</span>
                            {flight.derivedNetwork}
                          </div>
                        )}
                        <div className="labeled">
                          <span>PDF Text</span>
                        </div>
                        <div className="pdfText">
                          {flight.pdfText
                            ? flight.pdfText
                                .split("\n")
                                .map((text, i) => <p key={`${text}_${i}`}>{text || "\u00a0"}</p>)
                            : "None"}
                        </div>
                      </div>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default DetailModal;
