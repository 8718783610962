import React from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";

import AutoSizer from "react-virtualized-auto-sizer";

import "./CompetitiveChartBox.scss";
import { formatMoney, formatMoneyAsInt, formatNumber } from "../utils/format-utils";
import { useSetError } from "../redux/modals";
import { AxisDomain } from "recharts/types/util/types";

interface Label {
  value?: string;
  angle?: number;
  fill?: string;
  fontSize?: number;
  position?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "inside"
    | "outside"
    | "insideLeft"
    | "insideRight"
    | "insideTop"
    | "insideBottom"
    | "insideTopLeft"
    | "insideBottomLeft"
    | "insideTopRight"
    | "insideBottomRight"
    | "insideStart"
    | "insideEnd"
    | "end"
    | "center"
    | "centerTop"
    | "centerBottom"
    | "middle"
    | {
        x?: number;
        y?: number;
      };
  formatter?: (elem) => number | string | null | undefined;
}

interface InnerLineChartBoxProps {
  chartData: any[];
  chartMargins?: {};
  dataKeys?: string[];
  hasDot?: boolean;
  height?: number;
  isAnimationActive?: boolean;
  isSpend: boolean;
  label?: Label;
  strokeColor: string[];
  strokeWidth?: number[];
  width?: number;
  xAxisFontSize?: number;
  xAxisLine?: boolean;
  xAxisTickCount?: number;
  xAxisTicks?: string[] | number[];
  yAxisDomain?: string[] | number[];
  yAxisFontSize?: number;
  yAxisLine?: boolean;
  yAxisTickCount?: number;
  yAxisTicks?: string[] | number[];
  ref: any;
}

export default React.memo<InnerLineChartBoxProps>(
  React.forwardRef(
    (
      {
        chartData,
        chartMargins = { top: 20, right: 30, left: 10 },
        dataKeys = ["count"],
        hasDot = false,
        height,
        isAnimationActive = true,
        isSpend,
        label,
        strokeColor,
        strokeWidth,
        width,
        xAxisFontSize = 10,
        xAxisLine = true,
        xAxisTickCount = 5,
        xAxisTicks,
        yAxisDomain,
        yAxisFontSize = 12,
        yAxisLine = true,
        yAxisTickCount = 5,
        yAxisTicks,
      },
      ref: any
    ) => {
      const setError = useSetError();
      if (dataKeys.length !== strokeColor.length) {
        let error = new Error("Must have the same amount of lines as stroke colors");
        setError({
          message: `Error: ${error.message}`,
          reportError: error,
        });
      }

      const lineChartToolTip = (
        <Tooltip
          isAnimationActive={false}
          formatter={(value: string) =>
            isSpend
              ? `${formatMoneyAsInt(parseFloat(value) / 1000)}k`
              : formatNumber(parseFloat(value))
          }
        />
      );
      const lineChartXAxis = (
        <XAxis
          dataKey="date"
          tickFormatter={R.pipe(Dfns.parseISO, Dfns.format("MMM yyyy"))}
          tick={{ fontSize: xAxisFontSize }}
          tickCount={xAxisTickCount}
          ticks={xAxisTicks}
          axisLine={xAxisLine}
          tickLine={xAxisLine}
        />
      );
      const lineChartYAxis = (
        <YAxis
          tickFormatter={tick => {
            if (isSpend) {
              let base = tick >= 1000 ? "k" : "";
              let tickValue = tick >= 1000 ? tick / 1000 : tick;
              if (tick < 1 && tick !== 0) {
                return `${formatMoney(tickValue)}${base}`;
              } else {
                return `${formatMoneyAsInt(tickValue)}${base}`;
              }
            } else {
              return formatNumber(tick);
            }
          }}
          tick={{ fontSize: yAxisFontSize }}
          tickCount={yAxisTickCount}
          ticks={yAxisTicks}
          axisLine={yAxisLine}
          tickLine={yAxisLine}
          domain={yAxisDomain as AxisDomain}
        />
      );

      const lineChartLines = R.map(index => {
        let width = 2;
        if (strokeWidth && strokeWidth[index]) {
          width = strokeWidth[index];
        }
        return (
          <Line
            key={`Line ${index}`}
            type="monotone"
            strokeWidth={width}
            dataKey={dataKeys[index]}
            dot={hasDot}
            stroke={strokeColor[index]}
            isAnimationActive={isAnimationActive}
            {...{ label }}
          ></Line>
        );
      }, Array.from(Array(dataKeys.length).keys()));

      if (height && width) {
        return (
          <div className="chart-inner-box">
            {chartData && (
              <LineChart
                ref={ref}
                height={height}
                width={width}
                margin={chartMargins}
                data={chartData}
              >
                {lineChartToolTip} {lineChartXAxis} {lineChartYAxis}
                {R.map(elem => elem, lineChartLines)}
              </LineChart>
            )}
          </div>
        );
      } else {
        return (
          <div className="chart-inner-box">
            <AutoSizer>
              {({ width, height }) =>
                chartData && (
                  <LineChart
                    ref={ref}
                    height={height}
                    width={width}
                    margin={chartMargins}
                    data={chartData}
                  >
                    {lineChartToolTip} {lineChartXAxis} {lineChartYAxis}
                    {R.map(elem => elem, lineChartLines)}
                  </LineChart>
                )
              }
            </AutoSizer>
          </div>
        );
      }
    }
  )
);
