import { BsPlayBtn } from "react-icons/bs";

interface CreativeListPlaceHolderProps {
  selectedAttribute: string;
  selectedTag: string;
}

const CreativeListPlaceHolder = ({
  selectedAttribute,
  selectedTag,
}: CreativeListPlaceHolderProps): JSX.Element => {
  if (selectedAttribute && !selectedTag) {
    return (
      <div className="creativeListPlaceholder">
        <div>
          <BsPlayBtn size={100} color={"lightgray"} />
        </div>
        <div>Select a tactic to view creatives.</div>
      </div>
    );
  } else if (selectedAttribute && selectedTag) {
    return (
      <div className="creativeListPlaceholder">
        <div>
          <BsPlayBtn size={100} color={"lightgray"} />
        </div>
        <div>No creatives available for the selected attribute and tactic.</div>
      </div>
    );
  } else {
    return (
      <div className="creativeListPlaceholder">
        <div>
          <BsPlayBtn size={100} color={"lightgray"} />
        </div>
        <div>First select an attribute, then select a tactic to view creatives.</div>
      </div>
    );
  }
};

export default CreativeListPlaceHolder;
