import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Meta";

const Meta = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Meta is the parent company for a suite of social media platforms made up of Facebook
              and Instagram. With the two platforms carrying such a high daily active user count the
              audience pool is large making incrementality testing a very viable option for the
              majority of clients.
            </div>
            <br />
            <Img
              src={`${INC_CDN}/Meta_IncrementalConversions.png`}
              style={{ height: 500, width: 898 }}
            />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Outputs",
                "Signal Enrichment",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift in Platform",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>The Conversion Lift from Meta is a user-segmented test.</li>
                      <li>Meta randomizes the audience into treatment and control groups.</li>
                      <li>
                        Advertisements get delivered to the treatment group, and the control group
                        does not see any of the brand's ads.
                      </li>
                      <li>
                        The difference in conversions between the treatment and control shows the
                        incremental effect of advertising.
                      </li>
                      <li>
                        To kick off the conversion lift test reach out to your Meta rep and they
                        will help get the process started.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Managed service reduces the lift on internal teams to set up analysis.
                      </li>
                      <li>Available across Facebook, Instagram and Audience Network.</li>
                      <li>
                        Multi-cell tests are available to compare incrementality across different
                        ads in one test.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Less granular results can make it difficult to independently validate the
                        experiment.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Conversion Lift API",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        The Conversion Lift from Meta is a user-segmented test that is set up
                        through the Meta API.
                      </li>
                      <li>
                        Meta randomizes the audience into treatment and control groups based on
                        percentages of the audience defined through the API.
                      </li>
                      <li>
                        Advertisements get delivered to the treatment group, and the control group
                        does not see any meta ads.
                      </li>
                      <li>
                        The difference in conversions between the treatment and control shows the
                        incremental effect of advertising.
                      </li>
                      <li>
                        For inquiries on setting up a test contact, please message the{" "}
                        <a href="https://tinuiti.slack.com/archives/C05HHT6JETE">
                          #incrementality-master-playbook slack.channel.
                        </a>
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Through the{" "}
                        <a href="https://developers.facebook.com/docs/marketing-api/guides/lift-studies">
                          API
                        </a>{" "}
                        test setup and results retrieval can all be done programmatically.
                      </li>
                      <li>
                        Results from the API are more granular than the readout given via the
                        managed service version.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>Higher engineering lift to set up test via API.</li>
                    </ul>
                  ),
                },
                {
                  Method: "Meta GeoLift",
                  "Method Type": [
                    {
                      img: "Icon_ServiceModel_ManagedService.png",
                      label: "Managed Service",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        <a href="https://facebookincubator.github.io/GeoLift/">GeoLift</a> from Meta
                        is an open-source geo-segmented incrementality approach.
                      </li>
                      <li>
                        Using the power analysis, duration, number of test markets, investment, and
                        power of the test is determined through multiple iterations of random
                        sampling to determine the best combination.
                      </li>
                      <li>
                        Ads are then withheld in the Geos selected as control markets, with the test
                        markets using the average investment from the power analysis as their
                        budget.
                      </li>
                      <li>
                        In the analysis, the difference between the test and control counterfactual
                        is the incremental result from advertising.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>Can be used to analyze any platform that has geo-level data.</li>
                      <li>
                        Gives incremental impact on the full business and any measurable
                        outcome/KPI.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        The test is an open-source R script. Consultation with the Marketing
                        Science/Econometrics team is needed.
                      </li>
                      <li>Needs daily geo-segmented data and ad targeting.</li>
                      <li>Loss of revenue from turning off ads in Geos.</li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              500
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Engineering Lift",
                  Details: (
                    <ul className="basicList">
                      <li>
                        In order to run the lift test through the API there needs to be engineering
                        support.
                      </li>
                      <li>
                        If there is no engineering support using conversion lift in platform is
                        recommended.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Available Outcomes to Measure",
                  Details: (
                    <ul className="basicList">
                      <li>Facebook Pixel for online actions</li>
                      <li>Facebook SDK for in-app events</li>
                      <li>Facebook Offline Events Manager for offline actions</li>
                    </ul>
                  ),
                },
                {
                  Element: "Geo-targeting Availability",
                  Details: (
                    <ul className="basicList">
                      <li>
                        When using the Meta GeoLift it is necessary to target and run ads at the geo
                        level.
                      </li>
                      <li>
                        If geo-targeting is not a viable option, using the conversion lift test is
                        the recommended option.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Conversion API",
                  Details: (
                    <ul className="basicList">
                      <li>
                        In order to run conversion lift it is necessary to have the Meta Conversion
                        API activated.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Lift Test vs A/B Test",
                  Details: (
                    <ul className="basicList">
                      <li>
                        A lift test implements a holdout group to compare conversions for users who
                        see ads vs those who don’t get exposed
                      </li>
                      <li>
                        An A/B test on Meta does not include a holdout group. It compares the
                        performance of the two different campaigns.
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Competing Ads",
                  Details: (
                    <ul className="basicList">
                      <li>
                        If users in the control group are exposed to other ads from the advertiser,
                        the results of the test may be skewed.
                      </li>
                      <li>
                        Meta can help ensure non-overlapping audiences between the test audience and
                        the audiences in other normal marketing initiatives.
                      </li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              100
            )}
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Results are available in the Facebook Ads Manager experiments UI (standard offering),
              as well as{" "}
              <a href="https://developers.facebook.com/docs/marketing-api/guides/lift-studies#">
                through the meta API
              </a>
              . API results are more granular. For a multi-cell test, the winning cell can be
              determined by cost per conversion lift or ROAS lift. Cost per conversion lift is a
              measure of how expensive incremental conversions were in each cell. ROAS lift compares
              the test cells based on the return on ad spend to determine a winning cell.
            </div>
            <br />
            <div className="basicHeader">Single Cell Results</div>
            <Img src={`${INC_CDN}/Meta_SingleCellResults.png`} />
            <br />
            <div className="basicHeader">Multi-Cell Results</div>
            <Img src={`${INC_CDN}/Meta_MultiCellResults.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Test Outputs"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              For all Meta incrementality products that rely on a user-based protocol – thus
              excluding geo experiments – it is strongly recommended that{" "}
              <a href="https://developers.facebook.com/docs/marketing-api/conversions-api/">
                Conversions API (CAPI)
              </a>{" "}
              be implemented in order to strengthen conversion modeling and increase the overall
              fidelity of measurement. This is especially important for tests of lower-funnel
              outcomes.
            </div>
            <br />
            <div className="basicText">
              CAPI is designed to create a direct and more reliable connection between marketing
              data (from a brand’s server, website platform, app or CRM) and Meta. Examples of
              marketing data include{" "}
              <a href="https://www.facebook.com/business/help/964258670337005?id=1205376682832142">
                website events
              </a>
              ,{" "}
              <a href="https://www.facebook.com/business/help/235457266642587?id=1716638325041491">
                app events
              </a>{" "}
              and{" "}
              <a href="https://www.facebook.com/business/help/1142103235885551?id=565900110447546">
                offline conversions
              </a>
              . This marketing data helps enable ad personalization, optimization and measurement
              across Meta technologies so that ads are shown to{" "}
              <a href="https://www.facebook.com/business/help/283579896000936">people</a> who are
              more likely to find them relevant.
            </div>
            <br />
            <div className="basicText">
              If a client does not already have CAPI implemented, we can help them via Tinuiti’s{" "}
              <a href="https://www.facebook.com/business/help/746511563926080?id=818859032317965">
                CAPI GMA
              </a>{" "}
              infrastructure.
            </div>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
    </div>
  );
});

export default Meta;
