import "./Pacing.scss";
import { awaitJSON, CrossChannelLambdaFetch } from "../utils/fetch-utils";
import {
  BPMDateRange,
  Dropdown,
  Page,
  TextToggleButton,
  DropdownToggleType,
  DownloadDropdown,
  FilterBar,
} from "../Components";
import { MdAdd, MdHelp, MdSort } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { DateRange } from "../utils/types";
import useLocation from "../utils/hooks/useLocation";
import PacingBarsDashboard from "./PacingBarsDashboard";
import { downloadPNG, exportToExcel } from "../utils/download-utils";
import { pacingSortByOptions, unnestPacingData } from "./pacingUtils";
import { measureText } from "./PacingBar";
import { useStateFunction } from "../utils/hooks/useData";
import { PacingDataInfo } from "@blisspointmedia/bpm-types/dist/Pacing";

const DATE_FORMAT = "yyyy-MM-dd";

const usePacingInfo = (dateRange: DateRange, setError) => {
  const [dataPacing, setDataPacing] = useState<PacingDataInfo[]>([]);
  const [unfilteredDataPacing, setunfilteredDataPacing] = useState<PacingDataInfo[]>([]);
  const [dataReady, setDataReady] = useState(false);
  const [uniqueSegments, setUniqueSegments] = useState<string[]>([]);

  const { company } = useLocation();
  const [lastConfig, setLastConfig] = useState<string | null>(null);
  const [key, setKey] = useState<string | undefined>();
  const { start, end } = dateRange;
  const config = useMemo(() => `${start}_${end}_${key || "none"}`, [start, end, key]);

  const fetchPacingInfo = useCallback(async () => {
    try {
      setDataReady(false);
      const response = await CrossChannelLambdaFetch("/getCrossChannelPacingDashboardInfo", {
        params: {
          start,
          end,
          company,
        },
      });
      const data = await awaitJSON(response);
      setDataPacing(data[0]);
      setunfilteredDataPacing(data[1]);
      setUniqueSegments(data[2]);
      setDataReady(true);
    } catch (e) {
      setError({
        message: e.message,
        title: e.message,
      });
    }
  }, [company, end, start, setError]);

  useEffect(() => {
    if (start && end && lastConfig !== config) {
      fetchPacingInfo();
      setLastConfig(config);
    }
  }, [config, fetchPacingInfo, lastConfig, start, end]);

  return {
    pacingData: dataReady ? dataPacing : undefined,
    unfilteredDataPacing: dataReady ? unfilteredDataPacing : undefined,
    uniqueSegments,
    key,
    setKey,
  };
};

const Pacing: React.FC = () => {
  const setError = useSetError();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date())),
    end: Dfns.format(DATE_FORMAT, Dfns.addDays(6, Dfns.startOfISOWeek(new Date()))),
  });
  const { pacingData, unfilteredDataPacing, uniqueSegments } = usePacingInfo(dateRange, setError);
  const [dataMode, setDataMode] = useState("Actual");
  const [simplicity, setSimplicity] = useState("Simple");
  const [goalTypeValue, setGoalTypeValue] = useState("Spend");
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [activeDimensions, setActiveDimensions] = useState<string[]>(["Channel"]);
  const [segmentVisibility, setSegmentVisibility] = useState({});
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [longestText, setLongestText] = useState(0);
  const [sort, setSort] = useState("Abc");

  // will eventually use this for budget context
  console.log("unfilteredDataPacing", unfilteredDataPacing?.length);

  const filterBarOptions = useMemo(() => {
    const uniqueSegmentOptions =
      uniqueSegments?.map(segment => ({
        name: segment,
        label: segment,
      })) ?? [];

    const options = [...uniqueSegmentOptions];

    return options;
  }, [uniqueSegments]);

  const filteredData = useMemo(() => {
    return pacingData?.filter(filter) || [];
  }, [filter, pacingData]);

  useEffect(() => {
    let longest = 0;
    uniqueSegments.forEach(segment => {
      const currentKey: string = segment;
      const segmentWordsSet = new Set(pacingData?.map(item => item[currentKey]));
      const uniqueSegmentWords = Array.from(segmentWordsSet);
      longest = R.max(
        uniqueSegmentWords.reduce(
          (accumulator, currentValue) =>
            (accumulator = R.max(accumulator, measureText(currentValue))),
          0
        ),
        longest
      );
    });
    setLongestText(longest);
  }, [uniqueSegments, pacingData]);

  const toggleSegmentVisibility = index => {
    setSegmentVisibility(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const availableDimensions = useMemo(() => {
    const nullSegments = uniqueSegments.filter(segment =>
      pacingData?.every(obj => obj[segment] === null)
    );
    const uniqueNonNullSegments = uniqueSegments.filter(segment => !nullSegments.includes(segment));

    return uniqueNonNullSegments.map(segment => ({
      label: String(segment),
      value: String(segment),
    }));
  }, [uniqueSegments, pacingData]);

  const handleDimensionChange = (index: number, newDimension: string) => {
    setActiveDimensions(prevDimensions => {
      const newDimensions = [...prevDimensions];
      newDimensions[index] = newDimension;
      return newDimensions;
    });
  };

  const handleRemoveDimension = (index: number) => {
    setActiveDimensions(prevDimensions => prevDimensions.filter((_, i) => i !== index));
  };

  const excelDownloadPacingData = useCallback(() => {
    exportToExcel(
      unnestPacingData(pacingData || []) || [],
      `pacing_${dateRange.start}_${dateRange.end}`
    );
  }, [pacingData, dateRange.end, dateRange.start]);

  const pngDownloadPacingData = useCallback(async () => {
    await downloadPNG(".bpmPage .body .crossChannelPacingDashboard", "pacingBars");
  }, []);

  return (
    <Page
      app2Redesign
      title="Pacing"
      pageType="Pacing"
      actions={
        <div className="pacingDashboardActions">
          <MdHelp onClick={() => setShowHelpModal(!showHelpModal)} className="optionsHelp" />
          <TextToggleButton
            design="primary-dark"
            options={["Relative", "Actual"]}
            selectedOption={dataMode}
            onChange={value => {
              setDataMode(value);
            }}
          />
          <TextToggleButton
            design="primary-dark"
            options={["Simple", "Adjusted"]}
            selectedOption={simplicity}
            onChange={value => {
              setSimplicity(value);
            }}
          />
          <TextToggleButton
            design="primary-dark"
            options={["Revenue", "Spend"]}
            selectedOption={goalTypeValue}
            onChange={value => {
              setGoalTypeValue(value);
            }}
          />

          <BPMDateRange range={dateRange} onChange={setDateRange} />
        </div>
      }
    >
      <div className="crossChannelPacingDashboard">
        <div className="dashboardTitleContainer">
          <div className="dashboardTitleContainerTopHalf">
            <div className="dashboardTitleLeftHalf">
              <div className="dashboardTitleElements">Pacing by</div>
              {activeDimensions.map((dim, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <Dropdown
                      type={DropdownToggleType.WIDGET_TITLE}
                      value={activeDimensions[index]}
                      options={availableDimensions.filter(
                        dimnesion => !activeDimensions.includes(dimnesion.value)
                      )}
                      onChange={newDimension => handleDimensionChange(index, newDimension)}
                    />
                  ) : (
                    <>
                      <div className="dashboardTitleElements">
                        <div> then by </div>
                        <Dropdown
                          type={DropdownToggleType.WIDGET_TITLE}
                          value={activeDimensions[index]}
                          options={availableDimensions.filter(
                            dimnesion => !activeDimensions.includes(dimnesion.value)
                          )}
                          onChange={newDimension => handleDimensionChange(index, newDimension)}
                        />
                        <button
                          className="removeDimension"
                          onClick={() => handleRemoveDimension(index)}
                        >
                          x
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
              <div className="dashboardTitleElements">
                {activeDimensions.length > 0 && activeDimensions.length < 4 && (
                  <Dropdown
                    background="dark"
                    value=""
                    options={availableDimensions.filter(
                      dimension => !activeDimensions.includes(dimension.value)
                    )}
                    onChange={newDimension =>
                      handleDimensionChange(activeDimensions.length, newDimension)
                    }
                    placeholder="Add dimension"
                    leadingIcon={<MdAdd />}
                  />
                )}
              </div>
            </div>
            <div className="dashboardTitleButtons">
              <Dropdown
                label="Sort by"
                value={sort}
                options={pacingSortByOptions}
                onChange={change => setSort(change)}
                leadingIcon={<MdSort />}
              ></Dropdown>
            </div>
            <div className="dashboardTitleButtons">
              <DownloadDropdown
                size="lg"
                onClickOptions={[excelDownloadPacingData, pngDownloadPacingData]}
              />
            </div>
          </div>
          <div className="dashboardTitleContainerBottomHalf">
            <FilterBar
              hasAdvanced={true}
              onFilter={setFilter}
              lines={pacingData || []}
              options={filterBarOptions}
            ></FilterBar>
            <div className="pacingLegend">
              <div className="pacingLegendContainer">
                <div className="pacingLegendHead">Legend:</div>
                <div className="pacingBoxLineText">
                  <div className="pacingBox delivered" />
                  <div className="legendLabel">Delivered</div>
                </div>
                <div className="pacingBoxLineText">
                  <div className="pacingBox projected" />
                  <div className="legendLabel">Projected</div>
                </div>
                <div className="pacingBoxLineText">
                  <div className="pacingBox remainder" />
                  <div className="legendLabel">Remainder</div>
                </div>
                <div className="pacingBoxLineText">
                  <div className="pacingLine budget" />
                  <div className="legendLabel legendBudgetText">Budget</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PacingBarsDashboard
          data={pacingData || []}
          keys={["goalName"]}
          simplicity={simplicity.toLocaleLowerCase()}
          goalTypeValue={goalTypeValue.toLocaleLowerCase()}
          marginLeft={1}
          segmentVisibility={segmentVisibility}
          toggleSegmentVisibility={toggleSegmentVisibility}
          longestText={longestText}
          sortOption={sort}
          total={true}
          dateEnd={dateRange.end}
        />

        <PacingBarsDashboard
          data={filteredData || []}
          keys={activeDimensions}
          simplicity={simplicity.toLocaleLowerCase()}
          goalTypeValue={goalTypeValue.toLocaleLowerCase()}
          marginLeft={1}
          segmentVisibility={segmentVisibility}
          toggleSegmentVisibility={toggleSegmentVisibility}
          longestText={longestText}
          sortOption={sort}
          dateEnd={dateRange.end}
        />

        {showHelpModal && (
          <Modal show size="lg" onHide={() => setShowHelpModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>What Do These Options Mean?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                In general, "Simple" will figure out what the daily values for a flight should be,
                and compute booked and projected values from that. "Adjusted" will do various things
                such as taking overdelivery into account and focus more on what's left than what's
                already ran.
              </p>
              <h2>Booked</h2>
              <p>
                In "Simple" mode, the amount booked will be the the total booked for the flight,
                divided by the number of days in the flight, times the number of days in the date
                range. If the flight is from Monday through Sunday, and the date range is from
                Monday through Sunday, the booked amount will be exactly what's on the IO. If the
                flight is two weeks long, and the date range is only one week long, the amount
                booked will be half of what's on the IO. If the flight ends before the date range
                ends, it will not multiply by the full date range but only up to when the flight
                ends, so if the date range is two weeks, and the flight is one week, the booked will
                be the total booked on the IO.
              </p>
              <p>
                In "Adjusted" mode, it will first deduct any impressions served for that flight
                before the selected date range. It will then divide the remaining booked impressions
                by the number of days remaining in the flight, then multiply by the length of the
                date range. If the flight is contained entirely within the date range, this number
                should be the same as the simple version.
              </p>
              <p>
                Suppose there was a flight that was 10 days long and had a spend of $1,000. For 5
                days it paced perfectly ($100 a day) and delivered a total of $500. Then we double
                the spend, making it $2,000. If our date range is the full ten days, both the Simple
                and Adjusted booked numbers will be $2,000. However, suppose the date range only
                covered the final 5 days. In Simple mode it would be the full flight spend ($2,000)
                divided by the number of days in the flight (10), times the number of days in our
                range (5), which would be $1,000 (or half the total spend). In Adjusted mode, it
                would be the full flight spend ($2,000) minus what was delivered outside of the date
                range ($500, for a remaining total of $1,500), divided by the remaining days in the
                flight (5), times the days in our date range (5), for a total of $1,500.
              </p>
              <h2>Projected</h2>
              <p>
                In "Simple" mode, we take the number of impressions served in the date range, divide
                by the number of days so far, then multiplied by the full date range. In "Adjusted"
                mode, we take that number, but cut it off at the booked amount, assuming that once
                the network hits the IO amount, they'll stop delivering.
              </p>
              <h2>Actual Delivery</h2>
              <p>
                The "Simple" and "Adjusted" delivered impressions will always be the same, because
                these are the number of impressions we got. The "Simple" spend will be that numbers
                times the cpm divided by 1000. The "Adjusted" spend will be that number, but with
                overdelivery taken into account.
              </p>
              <h2>Ideal Delivery</h2>
              <p>
                "Ideal Delivery" refers to the "Ideal Daily Delivery" and "Ideal Delivery" lines in
                the charts that appear when selecting an item in the right pane. In "Simple" mode,
                the ideal daily delivery will be the total spend of the flight divided by the number
                of days in the flight. The Ideal total delivery will be that number times the number
                of days. In other words, the ideal daily delivery line will be flat, and the ideal
                delivery line will be a straight increasing line. In "Adjusted" mode, instead of
                looking at what the pacing should be for the whole flight, it looks at what the
                pacing should be based on the remaining impresisons. For each day it takes the
                number of remaining impressions, then divides by the number of remaining days. Thus,
                if a flight is under-delivering, the ideal line will get higher, and if it's
                over-delivering, it will get lower.
              </p>
              <p>
                Consider the above example with the 10 day flight at $1,000 that doubles in spend
                half way through. Looking at the last five days, "Simple" mode will compute the
                ideal daily spend as the total spend ($2,000) divided by the total days in the
                flight (10), to get the ideal daily spend ($200). So it will show a horizontal line
                at $200 and a straight line that starts at $0 with a slope of $200 per day. In
                "Adjusted" mode, we'll take the full booked amount ($2,000), subtract the already
                delivered impressions ($500, for a new total of $1,500), then divide that by the
                total number of remaining days (5) to get a ideal daily spend of $300. If we were to
                look at it for the entire 10 day range, on day one the ideal daily spend will be
                $200. After the first day (where we deliver $100) the remaining spend will be
                $1,900. With 9 days left the ideal daily spend will be $211. After we deliver
                another $100 the total will be $1,800, and with 8 days left that makes the ideal
                daily spend $225. Every day the ideal spend increases because we're under-delivering
                when looking at the entire range. Once we get to day 5, the ideal daily spend will
                be $300. If they deliver $300 a day then the ideal will stay at $300 a day. If on
                the first day they deliver $400, then the new total will be $2,000 - $500 - $400 =
                $1,100, which divided by the remaining days (4) is $275.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowHelpModal(false)}>
                Got It
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </Page>
  );
};

export default Pacing;
