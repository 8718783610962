import React, { useState, useEffect, useMemo, useRef, useLayoutEffect, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import AutoSizer from "react-virtualized-auto-sizer";
import { Tooltip, Button, ButtonGroup } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { downloadJSONToCSV } from "../utils/download-utils";
import { useStateFunction } from "../utils/hooks/useData";
import { makeMemoizedGetter } from "../utils/data";

import {
  Page,
  StickyTable,
  Img,
  Skeleton,
  TableSkeleton,
  OldFilterBar,
  DateRangePicker,
  OverlayTrigger,
} from "../Components";

import CompanyDropdown from "./CompanyDropdown";
import DetailModal from "./DetailModal";
import NetworkDropdown from "./NetworkDropdown";

import "./ActiveOTT.scss";
import { formatMoney, formatMoneyAsInt } from "../utils/format-utils";

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "M/d";

const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 120;
const COLUMN_WIDTH = 110;
const COLUMN_SPACER_WIDTH = 2;
const RIGHT_HEADER_WIDTH = 120;

const COMPANY_FILTER_OPTIONS = [
  {
    name: "shortCode",
    label: "Network",
  },
  {
    name: "platform",
    label: "Platform",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "audioBuyType",
    label: "Audio Buy Type",
  },
];

const NETWORK_FILTER_OPTIONS = [
  {
    name: "cid",
    label: "Company",
  },
  {
    name: "platform",
    label: "Platform",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "audioBuyType",
    label: "Audio Buy Type",
  },
];

export const ActiveOTTContext = React.createContext({});

const getIsCurrentWeek = makeMemoizedGetter({
  calculate: date => {
    let nextWeek = R.pipe(Dfns.parseISO, Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(date);
    let today = Dfns.format(DATE_FORMAT, new Date());
    return date <= today && nextWeek > today;
  },
});
export const getPrettyWeek = makeMemoizedGetter({
  calculate: R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT)),
});

export const makeCPM = (spend, impressions) =>
  impressions ? Math.round((spend / impressions) * 10000) / 10 : 0;

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    spend: true,
    cpm: false,
  });

  const toggleDimension = useCallback(
    type => {
      const otherType = type === "cpm" ? "spend" : "cpm";
      setDimensions({
        // If the button they pressed is set, it should become unset. If the button they pressed is unset, it should
        // become set. No matter what, the button they click on should toggle.
        [type]: !dimensions[type],
        // If only the pressed button was on, then we swap and make the other one turn on (so this will be true). If the
        // other button was already on, clicking this one has no effect: either you turn it on and you have two things on,
        // or you turn it off and you only have the other one on. In this case it stays true. So no matter what the
        // orientation is, pressing a button makes the other one true
        [otherType]: true,
      });
    },
    [dimensions, setDimensions]
  );
  return [dimensions, toggleDimension];
};

const useNetworkOptions = dates => {
  const setError = useSetError();

  const dataKey = useMemo(() => JSON.stringify(dates), [dates]);

  const [options, setOptions] = useState({});
  useEffect(() => {
    if (!(dates.start && dates.end)) {
      return;
    }
    if (options[dataKey]) {
      return;
    }
    (async () => {
      try {
        let res = await ToolsLambdaFetch("/activeott_network_options", {
          params: dates,
        });
        let options = await awaitJSON(res);
        setOptions(existing => ({ ...existing, [dataKey]: options }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [dataKey, options, dates, setOptions, setError]);

  return options[dataKey];
};

const useCompanyOptions = () => {
  const setError = useSetError();

  const [options, setOptions] = useState();
  useEffect(() => {
    if (!options) {
      (async () => {
        try {
          let res = await ToolsLambdaFetch("/activeott_company_options");
          let options = await awaitJSON(res);
          setOptions(options);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [options, setOptions, setError]);

  return options;
};

const useExpectedBookings = (dates, company) => {
  const setError = useSetError();

  let dataKey = useMemo(() => `${JSON.stringify(dates)}_${company}`, [dates, company]);

  const [expected, setExpected] = useState({});
  useEffect(() => {
    if (!(dates.start && dates.end && company)) {
      return;
    }
    if (R.prop(dataKey, expected)) {
      return;
    }
    (async () => {
      try {
        let res = await ToolsLambdaFetch("/get_expected_bookings_range", {
          params: {
            ...dates,
            company,
            types: "streaming,audio",
          },
        });
        let expected = await awaitJSON(res);
        setExpected(existing => ({ ...existing, [dataKey]: expected }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [expected, dates, company, setExpected, setError, dataKey]);

  return R.prop(dataKey, expected);
};

const useData = ({ dates, selectedGroup, byNetwork }) => {
  const setError = useSetError();

  const [dataMap, setDataMap] = useState({});
  const dataKey = useMemo(
    () => `${JSON.stringify(selectedGroup)}_${JSON.stringify(dates)}_${byNetwork}`,
    [selectedGroup, dates, byNetwork]
  );
  const data = dataMap[dataKey];

  // Fetch data
  useEffect(() => {
    if (selectedGroup) {
      // If we've already fetched data with these params, back out
      if (data) {
        return;
      }
      (async () => {
        try {
          let params = {
            ...dates,
          };
          if (byNetwork) {
            params = {
              ...params,
              network: selectedGroup,
            };
          } else {
            params = {
              ...params,
              company: R.prop("cid", selectedGroup),
            };
          }
          let res = await ToolsLambdaFetch("/activeott", {
            params,
          });
          let resData = await awaitJSON(res);
          setDataMap(existing => ({
            ...existing,
            [dataKey]: resData,
          }));
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [data, setDataMap, selectedGroup, byNetwork, dates, dataKey, setError]);

  return data || {};
};

const initDates = () => {
  let currentMonday = Dfns.startOfISOWeek(new Date());
  let start = R.pipe(Dfns.subWeeks(4), Dfns.format(DATE_FORMAT))(currentMonday);
  let end = R.pipe(Dfns.addWeeks(5), Dfns.format(DATE_FORMAT))(currentMonday);
  return { start, end };
};

const transformAndDownloadDataToCSV = ({
  rows,
  filteredHeaders,
  rightTotals,
  byNetwork,
  isCpm,
  isSpend,
  fileName,
}) => {
  let csvRows = [];
  let headerRow = ["Week"];
  for (let header of filteredHeaders) {
    let label;
    if (byNetwork) {
      label = header.cid;
    } else {
      label = `${header.shortCode} ${header.platform}`;
      if (header.description) {
        label = `${label} ${header.description}`;
      }
    }
    if (isSpend) {
      headerRow.push(`${label} Spend`);
    }
    if (isCpm) {
      headerRow.push(`${label} CPM`);
    }
  }
  if (!byNetwork) {
    headerRow.push("Expected");
  }
  headerRow.push("Total");
  csvRows.push(headerRow);

  for (let i = 0; i < rows.length; ++i) {
    let csvRow = [];
    let row = rows[i];
    let totals = rightTotals[i];
    csvRow.push(R.path([0, "week"], row));
    for (let col of row) {
      for (let [enabled, key] of [
        [isSpend, "spend"],
        [isCpm, "cpm"],
      ]) {
        if (enabled) {
          let vals = col;
          csvRow.push(R.prop(key, vals) || "");
        }
      }
    }
    if (!byNetwork) {
      csvRow.push(totals.expected);
    }
    csvRow.push(totals.spend);
    csvRows.push(csvRow);
  }

  downloadJSONToCSV(csvRows, fileName);
};

const bottomRenderer = ({ data, style, classes }) => (
  <div className={classes.join(" ")} style={style}>
    {data === "-" ? data : formatMoneyAsInt(data)}
  </div>
);

const usePageState = ({ networkOptions, companyOptions, path, navigate, uri }) => {
  const [byNetwork, urlSelection] = useMemo(() => {
    let pathParts = (path || "").split("/");
    if (!pathParts.length) {
      return [false];
    }
    if (pathParts[0] === "networks") {
      let selection = pathParts[1];
      return [true, selection];
    }
    return [false, pathParts[0]];
  }, [path]);

  const selectedGroup = useMemo(() => {
    let options = byNetwork ? networkOptions : companyOptions;
    if (!options) {
      return;
    }
    if (byNetwork) {
      let networks = R.keys(networkOptions);
      for (let network of networks) {
        if (urlSelection === network) {
          return network;
        }
      }
      return networks[0];
    } else {
      let company = R.find(R.propEq("cid", urlSelection), companyOptions);
      if (company) {
        return company;
      }
      return companyOptions[0];
    }
  }, [byNetwork, urlSelection, companyOptions, networkOptions]);

  const setByNetwork = useCallback(
    byNetwork => {
      navigate(byNetwork ? `${uri}/networks` : uri);
    },
    [navigate, uri]
  );
  const setSelectedGroup = useCallback(
    key => {
      let url = uri;
      if (byNetwork) {
        url = `${url}/networks`;
      }
      navigate(`${url}/${key}`);
    },
    [byNetwork, uri, navigate]
  );

  return {
    byNetwork,
    selectedGroup,
    setByNetwork,
    setSelectedGroup,
  };
};

const ActiveOTT = ({ "*": path, uri, navigate }) => {
  const [dimensions, toggleDimension] = useDimensions();
  const [dates, setDates] = useState(initDates);

  const [filter, setFilter] = useStateFunction(() => true);

  const networkOptions = useNetworkOptions(dates);
  const companyOptions = useCompanyOptions();

  const { byNetwork, selectedGroup, setByNetwork, setSelectedGroup } = usePageState({
    networkOptions,
    companyOptions,
    path,
    navigate,
    uri,
  });

  const [useOverrides, setUseOverrides] = useState(false);

  const expectedBookings = useExpectedBookings(
    dates,
    byNetwork ? false : R.prop("cid", selectedGroup)
  );

  const [includeCommission, setIncludeCommission] = useState(false);
  const [includeFees, setIncludeFees] = useState(false);
  const [includeAudio, setIncludeAudio] = useState(false);
  const [includeOLV, setIncludeOLV] = useState(false);

  const hasOptions = useMemo(() => !!(byNetwork ? networkOptions : companyOptions), [
    byNetwork,
    networkOptions,
    companyOptions,
  ]);

  const { headers, weeks, flightMap } = useData({
    dates,
    selectedGroup,
    byNetwork,
  });

  const networkHeaderLines = useMemo(() => {
    if (!byNetwork || !weeks) {
      return [];
    }
    let headerLineMap = {};
    for (let { data } of weeks) {
      for (let { cid } of headers) {
        let cellLines = data[cid];
        if (!cellLines) {
          continue;
        }
        if (!headerLineMap[cid]) {
          headerLineMap[cid] = {};
        }
        for (let { metaData } of cellLines) {
          let obj = { ...metaData, cid };
          let lineKey = JSON.stringify(obj);
          headerLineMap[cid][lineKey] = obj;
        }
      }
    }
    return R.map(R.values, headerLineMap);
  }, [weeks, byNetwork, headers]);

  // Get the lines for the filter bar, and a special filter function. For company mode, the lines
  // are just the headers. For network mode, the filter bar not only filters the headers, but the
  // data (by platform and description). Since one filter bar filters two data sets, we need to
  // construct "lines" to give the filter bar so it knows what the valid options are for each field.
  // Since the valid options only need to appear once for each field, we can just zip the two lists
  // together arbitrarily, duplicating the last element for the remaining values of the longer list.
  // Additionally, if you enter a filter for one field and not the other, every element from the
  // other data set will be filtered out, since none of them have the properties from the other data
  // set (and, thus, won't match). So we provide a custom filter function. We can use a
  // previously-constructed map (networkHeaderLines), which maps a company to all the
  // platform/descriptions it contains. Thus, we can filter on that.
  const [filterLines, modifiedFilter] = useMemo(() => {
    if (!headers) {
      return [[], () => [true, false]];
    }
    let filterLines = [];
    if (!byNetwork) {
      filterLines = headers;
    } else {
      let networkLines = R.pipe(R.prop(selectedGroup), R.values, R.flatten)(networkOptions);
      for (let i = 0; i < R.max(networkLines.length, headers.length); ++i) {
        filterLines.push({
          ...(i >= headers.length ? headers[headers.length - 1] : headers[i]),
          ...(i >= networkLines.length ? networkLines[networkLines.length - 1] : networkLines[i]),
        });
      }
    }
    return [
      filterLines,
      el => {
        if (!byNetwork) {
          if (!includeAudio && el.platform.includes("Audio")) {
            return [false, false];
          }
          if (!includeOLV && el.platform.includes("OLV")) {
            return [false, false];
          }
        }
        let isFiltered = false;
        let include = false;
        if (byNetwork) {
          let filtered = R.none(filter, networkHeaderLines[el.cid] || []);
          if (filtered) {
            isFiltered = true;
            include = false;
          } else {
            include = true;
          }
        } else {
          include = filter(el);
          if (!include) {
            isFiltered = true;
          }
        }

        return [include, isFiltered];
      },
    ];
  }, [
    headers,
    selectedGroup,
    byNetwork,
    networkOptions,
    filter,
    includeAudio,
    includeOLV,
    networkHeaderLines,
  ]);

  const [filteredHeaders, filterOptions, isFiltered] = useMemo(() => {
    if (!headers) {
      return [];
    }

    let options = byNetwork ? NETWORK_FILTER_OPTIONS : COMPANY_FILTER_OPTIONS;

    let isFiltered = false;
    let filteredHeaders = R.pipe(
      R.filter(el => {
        let [include, ourIsFiltered] = modifiedFilter(el);
        isFiltered = isFiltered || ourIsFiltered;
        return include;
      }),
      R.sortWith(options.map(({ name }) => R.ascend(R.prop(name))))
    )(headers);
    return [filteredHeaders, options, isFiltered];
  }, [headers, byNetwork, modifiedFilter]);

  const [rows, weekVals] = useMemo(() => {
    if (!(filteredHeaders && weeks)) {
      return [];
    }

    let weekVals = [];
    let rows = [];
    for (let { week, data } of weeks) {
      weekVals.push(week);
      let row = [];
      for (let header of filteredHeaders) {
        if (!byNetwork) {
          if (!includeAudio && header.platform.includes("Audio")) {
            continue;
          }
          if (!includeOLV && header.platform.includes("OLV")) {
            continue;
          }
        }
        let dataRows = data[header.key];
        if (!dataRows) {
          row.push({
            week,
          });
          continue;
        }
        let cellData = {
          spend: 0,
          adServingFee: 0,
          dspCpmFee: 0,
          dspCommissionFee: 0,
          commission: 0,
          impressions: 0,
          flights: [],
        };
        for (let dataRow of dataRows) {
          let { metaData } = dataRow;
          if (byNetwork) {
            metaData.cid = header.cid;
          }
          if (!filter(metaData)) {
            continue;
          }

          cellData.flights.push(dataRow.flights);
          let ourData = dataRow;
          if (useOverrides) {
            ourData = dataRow.overrides;
          }
          cellData.spend += ourData.media;
          cellData.adServingFee += ourData.adServingFee;
          cellData.dspCpmFee += ourData.dspCpmFee;
          cellData.dspCommissionFee += ourData.dspCommissionFee;
          cellData.commission += ourData.agencyCommission;
          cellData.impressions += ourData.impressions;
          if (includeCommission) {
            cellData.spend += ourData.agencyCommission;
          }
          if (includeFees) {
            // Only add cpm-based dsp fees, since commission-based dsp fees are
            // already baked into the media spend.
            cellData.spend += ourData.adServingFee + ourData.dspCpmFee;
          }
        }

        row.push({
          week,
          flights: R.pipe(R.flatten, R.uniq, R.sortBy(R.identity))(cellData.flights),
          spend: Math.round(cellData.spend),
          adServingFee: Math.round(cellData.adServingFee),
          dspCpmFee: Math.round(cellData.dspCpmFee),
          dspCommissionFee: Math.round(cellData.dspCommissionFee),
          commission: Math.round(cellData.commission),
          includeFees,
          includeCommission,
          cpm: makeCPM(cellData.spend, cellData.impressions),
        });
      }
      if (row.length) {
        rows.push(row);
      }
    }
    return [rows, weekVals];
  }, [
    weeks,
    filteredHeaders,
    includeAudio,
    includeOLV,
    byNetwork,
    useOverrides,
    includeCommission,
    includeFees,
    filter,
  ]);

  const bottomTotals = useMemo(() => {
    if (!R.length(rows || [])) {
      return [];
    }
    let colCount = R.pipe(R.prop(0), R.length)(rows);
    let totals = new Array(colCount).fill(0);
    for (let row of rows) {
      for (let i = 0; i < colCount; ++i) {
        totals[i] += R.path([i, "spend"], row) || 0;
      }
    }
    return totals;
  }, [rows]);

  const stickyTableScrollToRef = useRef();
  // When data changes, it scrolls to the current week
  useLayoutEffect(() => {
    if (rows && stickyTableScrollToRef.current) {
      for (let i = 0; i < rows.length; ++i) {
        if (getIsCurrentWeek(R.path([i, 0, "week"], rows))) {
          stickyTableScrollToRef.current(i, 0);
          break;
        }
      }
    }
  }, [rows]);

  const bothDimensions = dimensions.cpm && dimensions.spend;

  const [modalData, setModalData] = useState();

  const cellRenderer = useCallback(
    ({ data, style, classes }) => {
      classes.push("activeOTTCell");
      if (getIsCurrentWeek(data.week)) {
        classes.push("currentWeek");
      }
      const hasVals = R.pipe(R.keys, R.length)(data) > 1;
      let onClick;
      if (hasVals) {
        classes.push("hasVals");
        onClick = () => setModalData(data);
      }
      const [spend, cpm] = ["spend", "cpm"].map(key => {
        if (!hasVals) {
          return "-";
        }
        let val;
        if (dimensions[key]) {
          val = data[key];
          if (R.isNil(val)) {
            val = "-";
          } else {
            val = key === "spend" ? formatMoneyAsInt(val) : formatMoney(val);
          }
        }
        return val;
      });

      return (
        <div className={classes.join(" ")} style={style} onClick={onClick}>
          {dimensions.spend && <div>{spend}</div>}
          {bothDimensions && <div className="spacer" style={{ minWidth: COLUMN_SPACER_WIDTH }} />}
          {dimensions.cpm && <div>{cpm}</div>}
        </div>
      );
    },
    [dimensions, bothDimensions]
  );

  const rightTotals = useMemo(() => {
    if (!weekVals) {
      return [];
    }
    let totals = [];

    for (let weekIndex = 0; weekIndex < weekVals.length; ++weekIndex) {
      let week = weekVals[weekIndex];
      let elem = {
        week,
        spend: 0,
        expected: 0,
      };
      if (R.prop(week, expectedBookings)) {
        let expectedVal = 0;
        let mediaTypes = ["streaming"];
        if (includeAudio) {
          mediaTypes.push("audio");
        }
        for (let type of mediaTypes) {
          if (R.path([week, type], expectedBookings)) {
            let { media, adServingFee, dspCpmFee, agencyCommission } = R.path(
              [week, type],
              expectedBookings
            );
            expectedVal += media;
            if (includeFees) {
              // Only add cpm-based dsp fees, since commission-based dsp fees are
              // already baked into the media spend.
              expectedVal += adServingFee + dspCpmFee;
            }
            if (includeCommission) {
              expectedVal += agencyCommission;
            }
          }
        }
        elem.expected = Math.round(expectedVal);
      }
      for (let headerIndex = 0; headerIndex < filteredHeaders.length; ++headerIndex) {
        elem.spend += R.path([weekIndex, headerIndex, "spend"], rows) || 0;
      }
      totals.push(elem);
    }
    return totals;
  }, [
    weekVals,
    rows,
    expectedBookings,
    includeFees,
    includeCommission,
    includeAudio,
    filteredHeaders,
  ]);

  const showExpectedBookings = useMemo(() => expectedBookings && !byNetwork && !isFiltered, [
    expectedBookings,
    byNetwork,
    isFiltered,
  ]);
  const rightWidth = useMemo(
    () => (showExpectedBookings ? RIGHT_HEADER_WIDTH * 2 : RIGHT_HEADER_WIDTH),
    [showExpectedBookings]
  );
  const columnWidth = useMemo(
    () => (bothDimensions ? COLUMN_WIDTH * 2 + COLUMN_SPACER_WIDTH : COLUMN_WIDTH),
    [bothDimensions]
  );
  const cornerRenderer = useCallback(
    corner => {
      if (corner !== "ne") {
        return null;
      }
      return (
        <div className="activeOTTRightHeaders">
          {showExpectedBookings && <div>Expected</div>}
          <div>Total</div>
        </div>
      );
    },
    [showExpectedBookings]
  );

  const leftRenderer = useCallback(({ data, classes, style, rowIndex }) => {
    let props = {
      style,
    };
    if (rowIndex % 2) {
      classes.push("odd");
    }
    if (getIsCurrentWeek(data)) {
      classes.push("currentWeek");
    }
    props.className = classes.join(" ");

    return <div {...props}>{getPrettyWeek(data)}</div>;
  }, []);

  const rightRenderer = useCallback(
    ({ data, style, classes, rowIndex }) => {
      classes.push("activeOTTRightColumn");
      if (rowIndex % 2) {
        classes.push("odd");
      }
      if (getIsCurrentWeek(data.week)) {
        classes.push("currentWeek");
      }

      return (
        <div className={classes.join(" ")} style={style}>
          {showExpectedBookings && (
            <div>{data.expected ? formatMoneyAsInt(data.expected) : "-"}</div>
          )}
          <div>{data.spend ? formatMoneyAsInt(data.spend) : "-"}</div>
        </div>
      );
    },
    [showExpectedBookings]
  );

  const topRenderer = useCallback(
    ({ data, style, classes }) => (
      <OverlayTrigger
        placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
        overlay={
          <Tooltip>
            {byNetwork
              ? data.cid
              : `${data.shortCode}${data.description ? ` ${data.description}` : ""} ${
                  data.platform
                }`}
          </Tooltip>
        }
      >
        <div
          className={[...classes, "activeOTTHeader", "companyLogoHeader"].join(" ")}
          style={style}
        >
          <div className="logo">
            <Img
              src={`https://cdn.blisspointmedia.com/${
                byNetwork
                  ? `companies/${data.cid}/${bothDimensions ? "logo" : "square_logo"}`
                  : `networks/${data.shortCode}`
              }.png`}
              unloader={byNetwork ? data.cid : data.shortCode}
            />
          </div>
          {!byNetwork && <div className="platform">{data.platform.replace("Streaming ", "")}</div>}
          {!byNetwork && data.description && <div className="description">{data.description}</div>}
        </div>
      </OverlayTrigger>
    ),
    [byNetwork, bothDimensions]
  );

  return (
    <ActiveOTTContext.Provider
      value={{
        byNetwork,
        selectedGroup,
        setSelectedGroup,
        networkOptions,
        companyOptions,
        flightMap,
      }}
    >
      <Page
        title="Active OTT"
        pageType="Active OTT"
        minHeight={600}
        minWidth={1240}
        actions={
          <div className="activeottActions">
            <div className={`dropdown${byNetwork ? " byNetwork" : ""}`}>
              {selectedGroup &&
                hasOptions &&
                (byNetwork ? <NetworkDropdown /> : <CompanyDropdown />)}
            </div>
            <div className="configButtons">
              {!byNetwork && (
                <>
                  <Button
                    variant={`${includeAudio ? "" : "outline-"}primary`}
                    onClick={() => setIncludeAudio(R.not)}
                  >
                    Audio
                  </Button>
                  <Button
                    variant={`${includeOLV ? "" : "outline-"}primary`}
                    onClick={() => setIncludeOLV(R.not)}
                  >
                    OLV
                  </Button>
                </>
              )}
              <ButtonGroup toggle>
                <Button active={dimensions.spend} onClick={() => toggleDimension("spend")}>
                  Spend
                </Button>
                <Button active={dimensions.cpm} onClick={() => toggleDimension("cpm")}>
                  CPM
                </Button>
              </ButtonGroup>
              <ButtonGroup toggle>
                <Button active={!byNetwork} onClick={() => setByNetwork(!byNetwork)}>
                  Company
                </Button>
                <Button active={byNetwork} onClick={() => setByNetwork(!byNetwork)}>
                  Network
                </Button>
              </ButtonGroup>
              <DateRangePicker
                startDate={dates.start}
                endDate={dates.end}
                startDateId="activeOTTStartDate"
                endDateId="activeOTTEndDate"
                onChange={({ startDate, endDate }) =>
                  setDates({
                    start: startDate,
                    end: endDate,
                  })
                }
              />
              {rows && (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    transformAndDownloadDataToCSV({
                      rows,
                      filteredHeaders,
                      rightTotals,
                      byNetwork,
                      isCpm: dimensions.cpm,
                      isSpend: dimensions.spend,
                      useOverrides,
                      includeFees,
                      includeCommission,
                      fileName: `${byNetwork ? selectedGroup : selectedGroup.cid} Active OTT`,
                    });
                  }}
                >
                  <MdSave />
                </Button>
              )}
            </div>
          </div>
        }
      >
        <div className="activeott">
          {rows ? (
            <>
              <div className="filterBarArea">
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
                  delay={500}
                  overlay={
                    <Tooltip>
                      When enabled, this uses $0 for the spend when the flight is canceled, or any
                      wrenched PDF overrides, if present.
                    </Tooltip>
                  }
                >
                  <Button
                    variant={`${useOverrides ? "" : "outline-"}primary`}
                    onClick={() => setUseOverrides(R.not)}
                  >
                    PDF Numbers
                  </Button>
                </OverlayTrigger>
                <Button
                  variant={`${includeFees ? "" : "outline-"}primary`}
                  onClick={() => setIncludeFees(R.not)}
                >
                  Include Fees
                </Button>
                <Button
                  variant={`${includeCommission ? "" : "outline-"}primary`}
                  onClick={() => setIncludeCommission(R.not)}
                >
                  Include Commission
                </Button>
                <OldFilterBar options={filterOptions} lines={filterLines} onFilter={setFilter} />
              </div>
              {rows.length ? (
                <div className="table">
                  <AutoSizer>
                    {({ width, height }) => (
                      <StickyTable
                        scrollToRef={stickyTableScrollToRef}
                        width={width}
                        height={height}
                        data={rows}
                        topData={filteredHeaders}
                        leftData={weekVals}
                        rightData={rightTotals}
                        bottomData={bottomTotals}
                        leftWidth={75}
                        rowHeight={ROW_HEIGHT}
                        topHeight={HEADER_HEIGHT}
                        rightWidth={rightWidth}
                        columnWidth={columnWidth}
                        cornerRenderer={cornerRenderer}
                        cellRenderer={cellRenderer}
                        leftRenderer={leftRenderer}
                        rightRenderer={rightRenderer}
                        topRenderer={topRenderer}
                        bottomRenderer={bottomRenderer}
                      />
                    )}
                  </AutoSizer>
                  {modalData && <DetailModal data={modalData} onClose={() => setModalData()} />}
                </div>
              ) : (
                <div className="noData">
                  This client has no spend data with this filter in this date range.
                </div>
              )}
            </>
          ) : (
            <Skeleton>
              <TableSkeleton rowHeight={ROW_HEIGHT} headerHeight={HEADER_HEIGHT} />
            </Skeleton>
          )}
        </div>
      </Page>
    </ActiveOTTContext.Provider>
  );
};

export default ActiveOTT;
