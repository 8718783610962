// enables GUI access to mmm page for external clients
const MMMExternalCompanies: string[] = ["saxx", "tinuititest", "freedomcare"];

export const shouldEnableMMMNavbarGui = (company: string): boolean => {
  if (MMMExternalCompanies.includes(company)) {
    return true;
  } else {
    return false;
  }
};
