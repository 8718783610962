import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useLocation from "../utils/hooks/useLocation";
import { download } from "../utils/download-utils";

import * as R from "ramda";

import { Page, KpiPickerLegacy, FullPageSpinner, OverlayTrigger } from "../Components";
import { useSetError } from "../redux/modals";
import { ToolsLambdaFetch, S3SignedUrlFetch } from "../utils/fetch-utils";
import { artificialWait } from "../utils/async";

import * as UserRedux from "../redux/user";
import * as CompanyRedux from "../redux/company";

import { Form, Container, Row, Col, Button, Popover } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import {
  INVESTMENT_AUDIENCE_KEY,
  LINEAR_DELIVERY_KEY,
  PERFORMANCE_OUTCOMES_KEY,
  CLIENT_SPECIFIC_KEY,
} from "./constants";

import DashboardSparkChart from "./components/DashboardSparkChart";
import DashboardStackedSparkChart from "./components/DashboardStackedSparkChart";
import DashboardTable from "./components/DashboardTable";

import "./Dashboard.scss";

const DashboardWidget = ({ widgetData, onSetField }) => {
  if (
    widgetData.data &&
    !((widgetData.data.data /*charts*/ || widgetData.data.transformedData) /*tables*/)
  ) {
    return null;
  }

  let toShow;
  if (widgetData.type === "table") {
    toShow = (
      <DashboardTable
        data={widgetData.data}
        details={{
          ...widgetData.details,
          csvName: `${widgetData.details.title}.csv`,
        }}
        onSetField={onSetField}
      />
    );
  } else if (widgetData.type === "chart") {
    toShow = <DashboardSparkChart chartData={widgetData.data} />;
  } else if (widgetData.type === "stackedChart") {
    toShow = <DashboardStackedSparkChart chartData={widgetData.data} />;
  }

  return (
    <Col
      className="dashboardCol"
      sm={widgetData.size.sm}
      md={widgetData.size.md}
      lg={widgetData.size.lg}
      xl={widgetData.size.xl}
    >
      {toShow}
    </Col>
  );
};

const Dashboard = () => {
  const { company } = useLocation();
  const mediaTypes = useSelector(CompanyRedux.mediaTypesSelector(company));

  const [branchBuild, setBranchBuild] = useState("v4/latest");
  const [selectedView, setSelectedView] = useState(INVESTMENT_AUDIENCE_KEY);
  const [data, setData] = useState({});
  const [kpi, setKpi] = useState(useSelector(CompanyRedux.initialKpiSelector(company)));
  const [fields, setFields] = useState({
    audience: "HH",
  });
  const [queryKey, setQueryKey] = useState();
  const [polling, setPolling] = useState(0);
  const [userIdTags, setUserIdTags] = useState();
  const setError = useSetError();
  let isInternal = useSelector(UserRedux.isInternalSelector);
  const companyInfo = CompanyRedux.useCompanyInfo();

  useEffect(() => {
    setPolling(0);
    setQueryKey();
  }, [kpi]);

  useEffect(() => {
    if (kpi) {
      (async () => {
        try {
          setData({});
          let res = await ToolsLambdaFetch("/dashboard_async", {
            params: { ...fields, kpi, key: queryKey },
          });
          const text = await res.text();
          if (text === "{}") {
            //in progress
            await artificialWait(5000);
            setPolling(polling + 1);
          }
          let info = JSON.parse(text);
          if (!queryKey) {
            //first time, now we need to start polling with the key
            await artificialWait(5000);
            setQueryKey(info.key);
          } else {
            setData(info);
          }
        } catch (e) {
          setError({
            message: `Failed to fetch info for kpi "${kpi}". ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [kpi, fields, queryKey, polling, setError]);

  useEffect(() => {
    if (kpi) {
      (async () => {
        try {
          let response = await S3SignedUrlFetch(`bpm-ml-data/v4/${kpi}/latest/userIdTags.csv.gz`);
          if (response.status !== 200) {
            setUserIdTags();
          }
          let blob = await response.blob();
          if (blob.size < 30) {
            setUserIdTags();
          } else {
            setUserIdTags(blob);
          }
        } catch (e) {
          setUserIdTags();
        }
      })();
    }
  }, [setError, kpi]);

  const renderSelectedView = useCallback(
    view => {
      if (R.prop("isEmpty", data)) {
        return null;
      }
      if (!(data && data[view])) {
        return <FullPageSpinner size={100} />;
      }

      return (
        <Container fluid>
          {R.pipe(
            R.prop(view),
            R.filter(R.path(["elements", "length"])),
            R.map(section => {
              R.forEach(elem => {
                elem.size = {
                  sm: 12,
                  md: 12,
                  // on larger screen sizes, allow the charts/tables to shrink dynamically if
                  // they are not explicitly set to always be full width
                  lg: elem.details.alwaysFullWidth ? 12 : 10,
                  xl: elem.details.alwaysFullWidth ? 12 : elem.type === "table" ? 8 : 4,
                };
              }, section.elements);
              if (
                section.elements.length > 2 &&
                section.elements[section.elements.length - 1].type === "chart" &&
                section.elements[section.elements.length - 2].type === "chart"
              ) {
                section.elements[section.elements.length - 1].size.xl += 2;
                section.elements[section.elements.length - 2].size.xl += 2;
              }

              if (
                section.elements[0].type === "table" &&
                section.elements[0].details.alwaysFullWidth
              ) {
                section.elements[0].size.xl = 12;
              }

              return (
                <div key={`container_${section.title}`}>
                  <Row key={`title_${section.title}`} className="justify-content-md-left">
                    <div className="dashboardSectionTitle">{section.title}</div>
                  </Row>
                  <Row key={`body_${section.title}`} className="justify-content-md-center">
                    {section.elements.map(widgetData => (
                      <DashboardWidget
                        key={`${widgetData.type}_${widgetData.details.title}`}
                        widgetData={widgetData}
                        onSetField={({ field, value }) => {
                          setFields({ ...fields, [field]: value });
                        }}
                      />
                    ))}
                  </Row>
                </div>
              );
            })
          )(data)}
        </Container>
      );
    },
    [data, fields, setFields]
  );

  const navs = useMemo(() => {
    let navs = [
      {
        key: INVESTMENT_AUDIENCE_KEY,
        label: "Investment & Audience",
      },
      {
        key: PERFORMANCE_OUTCOMES_KEY,
        label: "Performance Outcomes",
      },
    ];

    if (isInternal && R.contains("tv", mediaTypes)) {
      navs.push({
        key: LINEAR_DELIVERY_KEY,
        label: "Linear Delivery",
      });
    }

    if (R.length(data[CLIENT_SPECIFIC_KEY]) > 0) {
      navs.push({
        key: CLIENT_SPECIFIC_KEY,
        label: "Custom",
      });
    }

    return navs;
  }, [isInternal, mediaTypes, data]);

  let downloadTaggedUserIDs = useCallback(() => {
    download(userIdTags, `${kpi} User ID Tags.csv`, "application/gzip");
  }, [userIdTags, kpi]);

  return (
    <Page
      title="Dashboard"
      pageType="Dashboard"
      navs={navs}
      selectedNav={selectedView}
      onNav={setSelectedView}
      minWidth={900}
      actions={
        <div className="dashboardPageActions">
          {selectedView === INVESTMENT_AUDIENCE_KEY && !R.isNil(companyInfo.sub_companies) && (
            <div className="kpiPickerContainer">
              <KpiPickerLegacy
                selectedDimensions={["Brand"]}
                onChange={newKpi => {
                  setKpi(newKpi);
                }}
              />
            </div>
          )}
          {selectedView !== INVESTMENT_AUDIENCE_KEY && (
            <div className="kpiPickerContainer">
              <KpiPickerLegacy
                onChange={newKpi => {
                  setKpi(newKpi);
                }}
              />
            </div>
          )}
          <Form.Control
            required
            className="branchBuildBox"
            size="sm"
            type="text"
            defaultValue={branchBuild}
            onKeyDown={event => (event.key === "Enter" ? setBranchBuild(event.target.value) : null)}
          />
          {userIdTags && (
            <Button size="sm" variant="outline-secondary" onClick={downloadTaggedUserIDs}>
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.LEFT.TOP}
                trigger={OverlayTrigger.TRIGGERS.HOVER}
                overlay={
                  <Popover>
                    <Popover.Content>Download Tagged User IDs</Popover.Content>
                  </Popover>
                }
              >
                <MdSave />
              </OverlayTrigger>
            </Button>
          )}
        </div>
      }
    >
      <div className="dashboardPage">
        <div className="dashboardPageBody">{renderSelectedView(selectedView)}</div>
      </div>
    </Page>
  );
};

export default Dashboard;
