import React, { useMemo } from "react";
import "./1x2Table.scss";
import { ListGroup } from "react-bootstrap";

interface DataListProps {
  orientation: "Horizontal" | "Vertical";
  data: {
    date: string;
    row1DataEntry: string;
    row2DataEntry: string;
  }[];
}

const DataList: React.FC<DataListProps> = React.memo(
  ({ orientation, data }: DataListProps): JSX.Element => {
    const DataList: JSX.Element[] = useMemo(() => {
      const lastIndex = data.length - 1;
      return data.map((entry, index) => {
        const { date, row1DataEntry, row2DataEntry } = entry;
        return (
          <ListGroup.Item className={`obtt${orientation}Item`} key={date}>
            <div className={`obtt${orientation}DateCell`}>{date}</div>
            <div className={`obtt${orientation}DataCells ${index === lastIndex ? "last" : ""}`}>
              <div className={`obtt${orientation}Cell1 ${index === lastIndex ? "last" : ""}`}>
                {row1DataEntry}
              </div>
              <div className={`obtt${orientation}Cell2 ${index === lastIndex ? "last" : ""}`}>
                {row2DataEntry}
              </div>
            </div>
          </ListGroup.Item>
        );
        // }
      });
    }, [data, orientation]);

    return <ListGroup className={`obtt${orientation}DataList`}>{DataList}</ListGroup>;
  }
);

interface OnexTwoTableProps {
  orientation: "Horizontal" | "Vertical";
  header1: string;
  header2: string;
  data: {
    date: string;
    row1DataEntry: string;
    row2DataEntry: string;
  }[];
}

export const OnexTwoTable: React.FC<OnexTwoTableProps> = React.memo(
  ({ orientation, header1, header2, data }: OnexTwoTableProps): JSX.Element => {
    return (
      <div className={`obtt${orientation}TableContainer`}>
        <div className={`obtt${orientation}TableBorder`}>
          <div className={`obtt${orientation}EmptySpace`}></div>
          <div
            className={`obtt${orientation}EmptyRectangle ${
              orientation === "Horizontal" ? "left" : "top"
            }`}
          ></div>
        </div>
        <div className={`obtt${orientation}TableView`}>
          <div className={`obtt${orientation}HeaderContainer`}>
            <div className={`obtt${orientation}EmptyRow`}></div>
            <div className={`obtt${orientation}HeaderSection`}>
              <div className={`obtt${orientation}Header`}>
                <div
                  className={`obtt${orientation}Header1 ${
                    orientation === "Horizontal" ? "top" : "left"
                  }`}
                >
                  {header1}
                </div>
                <div
                  className={`obtt${orientation}Header2 ${
                    orientation === "Horizontal" ? "top" : "left"
                  }`}
                >
                  {header2}
                </div>
              </div>
            </div>
          </div>
          <div className={`obtt${orientation}DataSection`}>
            <DataList orientation={orientation} data={data}></DataList>
          </div>
        </div>
        <div className={`obtt${orientation}TableBorder`}>
          <div className={`obtt${orientation}EmptySpace`}></div>
          <div
            className={`obtt${orientation}EmptyRectangle ${
              orientation === "Horizontal" ? "right" : "bottom"
            }`}
          ></div>
        </div>
      </div>
    );
  }
);
