import React, { useState, useCallback, useMemo, useEffect } from "react";

import * as R from "ramda";

import * as uuid from "uuid";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip, Toast } from "react-bootstrap";
import { MdBugReport } from "react-icons/md";

import cn from "classnames";

import { KpiInfo } from "@blisspointmedia/bpm-types/dist/Kpis";
import {
  fetchStreamingKpis,
  fetchYoutubeKpis,
  filteredKpisSelector,
  useCompanyInfo,
} from "../redux/company";
import * as UserRedux from "../redux/user";
import { useSetError } from "../redux/modals";

import { OverlayTrigger, OldDropdown, DropdownOption } from ".";

import "./KpiPicker.scss";

interface KpiPickerLegacyProps {
  defaultKpi?: string;
  disableType?: boolean;
  filteredDimensions?: string[];
  selectedDimensions?: string[];
  customFilter?: (kpis: KpiInfo[]) => KpiInfo[];
  onChange?: (kpiID: string) => void;
}

export const KpiPickerLegacy = React.memo<KpiPickerLegacyProps>(
  ({
    defaultKpi,
    disableType,
    filteredDimensions = [],
    selectedDimensions = [],
    customFilter = R.identity,
    onChange = () => {},
  }) => {
    const id = useMemo(() => uuid.v4(), []);
    const companyInfo = useCompanyInfo();
    const isInternal = useSelector(UserRedux.isInternalSelector);
    const [showCopiedToast, setShowCopiedToast] = useState(false);

    const { kpis, defaultKpiIndex, kpiFilterOrder } = useMemo(() => {
      let { kpis, kpi_filter_order } = companyInfo;
      kpis = customFilter(kpis);

      let ourKpi = defaultKpi || companyInfo.initial_kpi;
      let kpiIndex = kpis.findIndex(kpi => kpi.id === ourKpi);

      // If for some reason the InitialKPI is undefined, just pick the first one in the list.
      if (kpiIndex === -1) {
        kpiIndex = 0;
      }

      return {
        kpis,
        kpiFilterOrder: kpi_filter_order,
        defaultKpiIndex: kpiIndex,
      };
    }, [companyInfo, customFilter, defaultKpi]);

    const [selectedKpi, setSelectedKpi] = useState(kpis[defaultKpiIndex]);
    useEffect(() => {
      onChange(selectedKpi.id || "");
    }, [selectedKpi, onChange]);

    const { kpiDimensionNames, kpiDimensions, kpiDimensionKeys } = useMemo(() => {
      if (!kpis.length || !selectedKpi) {
        return {};
      }
      const filteredKpis = kpis.filter(kpi => !kpi.hidden);
      // Get the kpi properties that we actually want to select on
      let kpiDimensionNames = R.flatten([
        "name",
        R.uniq(
          R.flatten(
            R.map(kpi => {
              return R.without(["id", "name", "linearHidden"], R.keys(kpi));
            }, filteredKpis)
          )
        ).sort(),
      ]);

      // Is there a filter order? If so, organize them based on that.
      if (kpiFilterOrder) {
        kpiDimensionNames = R.sortBy(name => R.indexOf(name, kpiFilterOrder), kpiDimensionNames);
      }

      let kpiDimensions: Record<string, KpiInfo[]>[] = [];
      // For our currently selected kpi, go through each dimension, in order, and split our kpis
      // based on that property. This gives us the different options for that dimension and also
      // filters out kpis as we dig deeper.
      if (kpiDimensionNames.length > 0) {
        // We start off looking at all our kpis
        let remainingKpis = filteredKpis;
        for (let name of kpiDimensionNames) {
          // Split them based on this prop. Save this in our array for when we construct that picker.
          let kpiList = R.groupBy(R.pipe(R.prop(name), R.defaultTo("")), remainingKpis || []);
          kpiDimensions.push(kpiList);
          // Take only the kpis in this dimension
          remainingKpis = kpiList[selectedKpi[name] || ""];
        }
      }
      let kpiDimensionKeys: string[][] = R.map(
        list => R.without(["undefined"], R.keys(list)),
        kpiDimensions
      );
      return {
        kpiDimensionNames,
        kpiDimensions,
        kpiDimensionKeys,
      };
    }, [kpis, kpiFilterOrder, selectedKpi]);

    const shouldShowDimension = useCallback(
      index => {
        // Our selector that constructs our dimensions list will make a dimension with the key
        // "undefined" when one of our dimensions isn't specified in that kpi
        const valsAtDim = (kpiDimensions || [])[index];
        return valsAtDim && !valsAtDim.undefined;
      },
      [kpiDimensions]
    );
    const changeKpiDimension = useCallback(
      (name, value) => {
        // Construct a kpi that only has the keys we care about
        let current = {
          name: selectedKpi.name,
          ...(kpiDimensionNames || []).reduce((obj, key) => {
            obj[key] = selectedKpi[key];
            return obj;
          }, {}),
          [name]: value,
        };
        // Look through all our kpis and find one where all the keys from current are the same. This
        // ignores names that aren't in kpiDimensionNames, because those don't have to be equal.
        let newKpiIndex = kpis.findIndex(kpi => {
          // For each name we care about
          for (let name of kpiDimensionNames || []) {
            // Once we find one that's not the same, it's over
            if (kpi[name] !== current[name]) {
              return false;
            }
          }
          // Didn't find any that are different? Bingo
          return true;
        });

        // We didn't find one?
        if (newKpiIndex === -1) {
          // If we didn't find one, then the exact combo we made doesn't work. This could happen, for
          // instance, if we switch the kpi name, but that particular one doesn't have all the same
          // filters (like if we have traffic-specialurl, and change traffic to clicks, but clicks
          // doesn't have a specialurl filter). In this case, let's see if we can find any kpi with the
          // same name as ours that has the value set from the picker
          newKpiIndex = kpis.findIndex(
            kpi =>
              // Make sure this guy has the value selected from the picker
              kpi[name] === value &&
              // Only count it if the name is the same. However, if the name is that which changed, then
              // the name will not be the same so don't do the check.
              (name !== "name" || kpi.name === selectedKpi.name)
          );
          // Can we STILL not find one?
          if (newKpiIndex === -1) {
            // We were trying to keep it inside the kpi name, but perhaps they changes a higher-level
            // filter somehow, such as type. In that case, just try to find ANY kpi in our list that has
            // the value set from the picker.
            newKpiIndex = kpis.findIndex(kpi => kpi[name] === value);
            // It doesn't seem likely/possible to have NO kpi that's valid, since the picker values are
            // derived from our kpi list, but if it happens then we're out of luck
            if (newKpiIndex === -1) {
              return;
            }
          }
        }

        let kpiIndex = R.indexOf(selectedKpi, kpis);
        if (kpiIndex !== newKpiIndex) {
          setSelectedKpi(kpis[newKpiIndex]);
        }
      },
      [kpis, selectedKpi, kpiDimensionNames]
    );

    if (!kpis.length || !kpiDimensionNames) {
      return (
        <div className="kpiPicker">
          <OldDropdown
            size="sm"
            className="kpiPill"
            value=""
            options={[]}
            label=""
            spinner
            onChange={() => {}}
            disabled
          />
        </div>
      );
    }

    return (
      <div className="kpiPicker">
        {kpiDimensionNames.map((name, nameIndex) => {
          if (filteredDimensions.includes(name)) {
            return null;
          }
          if (selectedDimensions.length && !selectedDimensions.includes(name)) {
            return null;
          }

          if (shouldShowDimension(nameIndex)) {
            let label = name === "name" ? "KPI" : name;
            let options = R.pipe<string[][], string[] | undefined, string[], DropdownOption[]>(
              R.nth(nameIndex),
              R.defaultTo([]),
              R.map(value => ({ value, label: value }))
            )(kpiDimensionKeys || []);

            return (
              <OldDropdown
                searchable
                key={name}
                size="sm"
                className="kpiPill"
                value={selectedKpi[name] || ""}
                options={options}
                label={label}
                onChange={newVal => changeKpiDimension(name, newVal)}
                disabled={disableType && name === "Type"}
              />
            );
          } else {
            return null;
          }
        })}
        {isInternal &&
          companyInfo.cid !== "test" &&
          companyInfo.cid !== "prospect" &&
          companyInfo.cid !== "interview" && (
            <>
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.BOTTOM.RIGHT}
                overlay={
                  <Tooltip id={`${id}_kpiid_tooltip`} className="kpiPickerKpiIDTooltip">
                    {selectedKpi.id}
                  </Tooltip>
                }
              >
                <div
                  className="kpiIdIcon"
                  onClick={() => {
                    /// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
                    navigator.clipboard.writeText(selectedKpi.id || "");
                    setShowCopiedToast(true);
                  }}
                >
                  <MdBugReport />
                </div>
              </OverlayTrigger>
              <Toast
                className="copiedToast"
                onClose={() => setShowCopiedToast(false)}
                show={showCopiedToast}
                delay={1000}
                autohide
              >
                <Toast.Body>Copied!</Toast.Body>
              </Toast>
            </>
          )}
      </div>
    );
  }
);

export const useKpiPickerData = ({
  company,
  mediaType,
  filter,
  useCrossChannelKpis = false,
}: {
  company?: string;
  mediaType?: string;
  filter?: (kpi: string) => boolean;
  useCrossChannelKpis?: boolean;
}): { kpiMap: Record<string, KpiInfo>; defaultKpi: string } => {
  const {
    cid,
    kpis,
    initial_kpi,
    streaming_performance_default_kpi,
    cross_channel_kpis,
  } = useCompanyInfo(company);

  const streamingOnly = R.defaultTo("", mediaType).toLowerCase() === "streaming";
  const youtubeOnly = R.defaultTo("", mediaType).toLowerCase() === "youtube";
  const filteredKpis = useSelector(filteredKpisSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (streamingOnly && !filteredKpis) {
      dispatch(fetchStreamingKpis(cid));
    }
  }, [cid, dispatch, filteredKpis, streamingOnly]);

  useEffect(() => {
    if (youtubeOnly && !filteredKpis) {
      dispatch(fetchYoutubeKpis(cid));
    }
  }, [cid, dispatch, filteredKpis, youtubeOnly]);

  const [processedKpiMap, firstKpi]: [Record<string, KpiInfo>, string] = useMemo(() => {
    if (!kpis || (streamingOnly && (!filteredKpis || filteredKpis === "fetching"))) {
      return [{}, ""];
    }

    let map: Record<string, KpiInfo> = {};
    let firstKpi: KpiInfo | null = null;
    for (let kpi of kpis) {
      const { id, hidden } = kpi;
      if ((streamingOnly || youtubeOnly) && filteredKpis && !filteredKpis[id]) {
        continue;
      }
      if (hidden) {
        continue;
      }
      let pass = true;
      if (filter) {
        pass = filter(id);
      }
      if (pass) {
        map[id] = kpi;
        if (!firstKpi) {
          firstKpi = kpi;
        }
      }
    }
    return [map, firstKpi?.id || ""];
  }, [kpis, streamingOnly, filteredKpis, youtubeOnly, filter]);

  const defaultKpi = useMemo(() => {
    if (!R.keys(processedKpiMap).length) {
      return "";
    }
    let defaultKpi = streamingOnly ? streaming_performance_default_kpi : initial_kpi;
    if (!processedKpiMap[defaultKpi]) {
      defaultKpi = firstKpi;
    }
    return defaultKpi;
  }, [processedKpiMap, initial_kpi, firstKpi, streamingOnly, streaming_performance_default_kpi]);

  if (useCrossChannelKpis) {
    return {
      kpiMap: R.fromPairs(
        cross_channel_kpis.map(kpi => {
          return [kpi, { id: kpi, name: kpi, Type: "", Version: "" }];
        })
      ),
      defaultKpi: cross_channel_kpis[0] || "",
    };
  } else if (mediaType === "commerce") {
    const kpiMap = {
      orders: { id: "orders", type: "filtered", name: "Orders", Type: "Filtered" },
      units: { id: "units", type: "filtered", name: "Units", Type: "Filtered" },
      new_to_brand_orders: {
        id: "new_to_brand_orders",
        name: "New to Brand Orders",
        Type: "Filtered",
      },
    };
    return {
      kpiMap,
      defaultKpi: R.keys(kpiMap)[0] || "",
    };
  }

  return {
    kpiMap: processedKpiMap,
    defaultKpi,
  };
};

interface KpiPickerProps {
  company?: string;
  bold?: boolean;
  defaultKpi?: string;
  disableTypeSelector?: boolean; // Fully hides the Type selector.
  filter?: (kpi: string) => boolean;
  kpi?: string;
  kpiMap?: Record<string, KpiInfo>;
  mediaType?: string;
  noBreak?: boolean;
  normalTitle?: boolean;
  onChange: (kpi: string) => void;
}

export const KpiPicker: React.FC<KpiPickerProps> = React.memo(
  ({
    company,
    bold,
    defaultKpi: providedDefaultKpi,
    disableTypeSelector,
    filter,
    kpi: providedKpi,
    kpiMap: providedKpiMap,
    mediaType,
    noBreak,
    normalTitle,
    onChange,
  }) => {
    const setError = useSetError();

    const { cid, kpi_filter_order } = useCompanyInfo(company);

    const [internalKpi, setInternalKpi] = useState<string>();
    const kpi = useMemo(() => providedKpi || internalKpi, [providedKpi, internalKpi]);

    useEffect(() => {
      if (providedKpi && providedKpi !== internalKpi) {
        setInternalKpi(providedKpi);
      }
    }, [providedKpi, internalKpi]);
    const kpiPickerData = useKpiPickerData({ company: cid, mediaType, filter });

    const { kpiMap, defaultKpi } = useMemo(
      () => ({
        kpiMap: providedKpiMap ?? kpiPickerData.kpiMap,
        defaultKpi: providedDefaultKpi ?? kpiPickerData.defaultKpi,
      }),
      [providedKpiMap, providedDefaultKpi, kpiPickerData]
    );

    useEffect(() => {
      if (!kpi && defaultKpi) {
        setInternalKpi(defaultKpi);
      }
    }, [kpi, defaultKpi, onChange]);

    // If they provide a kpi that doesn't match our list (they select "streamingOnly" but provide a
    // non-streaming Kpi, or they previously chose and saved a KPI somewhere that has since been
    // disabled/deleted), we "reset to a valid Kpi" by calling onChange.
    useEffect(() => {
      if (
        kpi &&
        !kpiMap[kpi] &&
        defaultKpi &&
        R.keys(kpiMap).length &&
        !kpiMap[providedKpi || ""]
      ) {
        (async () => {
          let msg = `No info for KPI: "${kpi}". Falling back to "${defaultKpi}"`;
          await setError({
            message: msg,
            reportError: new Error(msg),
          });
          onChange(defaultKpi);
        })();
      }
    }, [kpiMap, kpi, setError, defaultKpi, providedKpi, onChange]);

    // If the provided kpi is undefined, then we should set it (they want the default KPI).
    useEffect(() => {
      if (R.isNil(providedKpi) && kpi && R.keys(kpiMap).length && kpiMap[kpi]) {
        onChange(kpi);
      }
    }, [kpiMap, kpi, providedKpi, onChange]);

    const dropdownOptions = useMemo(() => {
      if (!(R.keys(kpiMap).length && kpi)) {
        return;
      }
      // Get the info of our selected KPI. We're going to use this to find matching KPIs for the
      // various dimensions.
      let kpiInfo = kpiMap[kpi];
      if (!kpiInfo) {
        return;
      }
      // Get the dimension options for this KPI's info
      let keys = R.keys(R.omit(["id", "linearHidden"], kpiInfo));
      // If there's a filter order, that determines the order the dimension pickers are in. However,
      // there is no guarantee that the filter order includes all the possible dimensions, nor that
      // our selected KPI has all the defined dimensions. We will take all the defined dimensions,
      // plus any dimensions in our object that aren't in the list, using the order they come in as
      // the order, concatenated at the end. Uniq will help us remove the common keys. Also, we
      // always start with "name" as the first option.
      let dimensions = R.uniq(["name", ...(kpi_filter_order || []), ...keys]);
      // This will the data for our rendered picker
      let dropdownOptions: { dimension: string; value: string; options: string[] }[] = [];
      let matches: KpiInfo[] = R.values(kpiMap);
      // Loop over each of these dimensions, collecting all the KPIs that have a matching value. We
      // collect those and iterate on the smaller list with the next value.
      for (let dimension of dimensions) {
        let ourDimensionValue = kpiInfo[dimension];
        // We may have a situation where kpi_filter_order included a dimension that isn't in this
        // KPI. This will just skip that dimension and move on.
        if (!ourDimensionValue) {
          continue;
        }
        // We'll make sure the dropdown has our value.
        let options: string[] = [ourDimensionValue];

        let newMatches: KpiInfo[] = [];
        for (let kpiInfo of matches) {
          let option = kpiInfo[dimension];
          if (option) {
            // If this kpi has this dimension, we can add its value to the dropdown.
            options.push(option);
            if (option === ourDimensionValue) {
              // If it matches, then we can use it for the next dimension.
              newMatches.push(kpiInfo);
            }
          }
        }
        matches = newMatches;
        dropdownOptions.push({
          dimension: dimension === "name" ? "KPI" : dimension,
          value: ourDimensionValue,
          options: R.uniq(options),
        });
      }
      return dropdownOptions;
    }, [kpi, kpiMap, kpi_filter_order]);

    const onDropdownSelect = useCallback(
      (dimension: string, value: string) => {
        // We need to find a KPI that matches what's currently selected in the picker. The trick,
        // however, is there may not be a KPI that has all the same dimensions. We want to first find
        // only the Kpis that match the specific value they just selected, then from that match, left
        // to right, the currently-selected dropdown values, paring down the list each time. If we
        // find a dimension that doesn't have a single available KPI, we skip it and go to the next
        // dimension without filtering the list. If we get to the end and there are multiple KPIs that
        // fit, we'll pick the first one. If none fit, we'll just use the current value, because it
        // shouldn't be possible to find NO match since the dropdown options are derived from the
        // list.
        let ourOptions = [
          { dimension, value },
          ...(dropdownOptions || []).map(R.pick(["dimension", "value"])),
        ];
        let kpis: KpiInfo[] = R.values(kpiMap);
        for (let { dimension, value } of ourOptions) {
          if (dimension === "KPI") {
            dimension = "name";
          }

          let nextKpis: KpiInfo[] = [];
          for (let kpi of kpis) {
            if (kpi[dimension] === value) {
              nextKpis.push(kpi);
            }
          }
          if (nextKpis.length) {
            kpis = nextKpis;
          }
        }
        let newKpi = kpis[0]?.id || kpi || "";
        onChange(newKpi);
        setInternalKpi(newKpi);
      },
      [dropdownOptions, kpiMap, onChange, kpi]
    );

    const isInternal = useSelector(UserRedux.isInternalSelector);
    const [showCopiedToast, setShowCopiedToast] = useState(false);
    const id = useMemo(() => uuid.v4(), []);

    if (!dropdownOptions) {
      return (
        <div className="kpiPicker">
          <OldDropdown
            size="sm"
            className="kpiPill"
            value=""
            options={[]}
            label=""
            spinner
            onChange={() => {}}
            disabled
            normalTitle={normalTitle}
            bold={bold}
          />
        </div>
      );
    }
    return (
      <div className={cn("kpiPicker", { noBreak })}>
        {dropdownOptions.map(({ dimension, value, options }) => {
          return !disableTypeSelector || dimension !== "Type" ? (
            <OldDropdown
              searchable
              key={dimension}
              size="sm"
              className="kpiPill"
              value={value}
              options={options}
              label={dimension}
              onChange={value => onDropdownSelect(dimension, value)}
              normalTitle={normalTitle}
              bold={bold}
            />
          ) : (
            <div key={dimension}></div>
          );
        })}
        {isInternal && cid !== "test" && cid !== "prospect" && cid !== "interview" && (
          <>
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.RIGHT}
              overlay={
                <Tooltip id={`${id}_kpiid_tooltip`} className="kpiPickerKpiIDTooltip">
                  {kpi}
                </Tooltip>
              }
            >
              <div
                className="kpiIdIcon"
                onClick={() => {
                  /// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
                  navigator.clipboard.writeText(kpi || "");
                  setShowCopiedToast(true);
                }}
              >
                <MdBugReport />
              </div>
            </OverlayTrigger>
            <Toast
              className="copiedToast"
              onClose={() => setShowCopiedToast(false)}
              show={showCopiedToast}
              delay={1000}
              autohide
            >
              <Toast.Body>Copied!</Toast.Body>
            </Toast>
          </>
        )}
      </div>
    );
  }
);
