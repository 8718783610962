import React, { useState, useMemo, useEffect, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { MdSave } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { emailSelector } from "../redux/user";
import {
  LinearLambdaFetch,
  StreamingV2LambdaFetch,
  awaitJSON,
  LinearBuyingLambdaFetch,
} from "../utils/fetch-utils";
import {
  Page,
  FullPageSpinner,
  Spinner,
  ModalEditTable,
  SelectorOption,
  NumberFormatter,
  TextToggleButton,
  Button,
  ButtonType,
} from "../Components";
import "./LinearRates.scss";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

const EQUALITY_PROPS: string[] = [
  "network",
  "avail",
  "status",
  "day_set",
  "daypart_begin",
  "daypart_end",
  "program_name",
  "rotation_name",
  "company",
  "market",
];

const textCellRenderer = field => data => {
  const value = data[field];
  if (value && value !== "") {
    return (
      <div className="modalEditTableCell" key={field}>
        {value}
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={field}>
        -
      </div>
    );
  }
};

const isDaySetValid = value => {
  let valid = true;

  if (R.isNil(value)) {
    valid = false;
  } else if (R.indexOf(value, DAYS) === -1) {
    const days = R.split(" - ", value);

    if (days.length !== 2) {
      valid = false;
    } else {
      const startDayInd = R.indexOf(days[0], DAYS);
      const endDayInd = R.indexOf(days[1], DAYS);

      if (startDayInd === -1 || endDayInd === -1 || startDayInd >= endDayInd) {
        valid = false;
      }
    }
  }
  return valid;
};

const daysCellRenderer = data => {
  if (!data.day_set || data.day_set === "") {
    return (
      <div className="modalEditTableCell invalidCell" key={"day_set"}>
        -
      </div>
    );
  } else if (isDaySetValid(data.day_set)) {
    return (
      <div className="modalEditTableCell" key={"day_set"}>
        {data.day_set}
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={"day_set"}>
        {data.day_set}
      </div>
    );
  }
};

const isTimeValid = value =>
  value && value !== "" && R.test(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, value);

const isTextValid = value => value && value !== "";

const timeCellRenderer = field => data => {
  const value = data[field];
  if (!value || value === "") {
    return (
      <div className="modalEditTableCell invalidCell" key={field}>
        -
      </div>
    );
  } else if (isTimeValid(value)) {
    return (
      <div className="modalEditTableCell" key={field}>
        {value}
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={field}>
        {value}
      </div>
    );
  }
};

const costRenderer = data => {
  const value = data.cost_30s;
  const parsedValue = Number.parseFloat(value);
  if (!value || value === "") {
    return (
      <div className="modalEditTableCell" key={"cost"}>
        -
      </div>
    );
  } else if (!isNaN(parsedValue)) {
    return (
      <div className="modalEditTableCell" key={"cost"}>
        <NumberFormatter value={value} type={"$"} />
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={"cost"}>
        <NumberFormatter value={value} type={"$"} />
      </div>
    );
  }
};

const DAYS = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];

const LinearRates = (): JSX.Element => {
  const setError = useSetError();
  const email = useSelector(emailSelector);
  const [linearRatesData, setLinearRatesData] = useState<any[]>();
  const [originalLinearRatesData, setOriginalLinearRatesData] = useState<any[]>();
  const [radioRatesData, setRadioRatesData] = useState<any[]>();
  const [originalRadioRatesData, setOriginalRadioRatesData] = useState<any[]>();
  const [saving, setSaving] = useState(false);
  const [networksBuyType, setNetworksBuyType] = useState<string>("linear");
  const enableMarketChannelColumns = useExperimentFlag("enableMarketChannelColumns");

  const ratesHeaders = useMemo(() => {
    let headers = [
      {
        label: "Network",
        field: "network",
        type: "text",
        modalRow: 0,
        modalWidth: 165,
        width: 165,
        isClearable: false,
        renderer: textCellRenderer("network"),
      },
      {
        label: "Full Network",
        field: "full_network",
        type: "text",
        flex: 1,
        modalRow: 0,
        modalWidth: 300,
        width: 300,
        isClearable: false,
        renderer: textCellRenderer("full_network"),
      },
      {
        label: "Avail",
        field: "avail",
        type: "select",
        options: "availOptions",
        modalRow: 0,
        modalWidth: 60,
        width: 60,
        isClearable: false,
        renderer: textCellRenderer("avail"),
      },
      {
        label: "Status",
        field: "status",
        type: "select",
        options: "statusOptions",
        modalRow: 0,
        modalWidth: 150,
        width: 120,
        isClearable: false,
        renderer: textCellRenderer("status"),
      },
      {
        label: "Program Name",
        field: "program_name",
        type: "text",
        flex: 1,
        modalRow: 1,
        modalWidth: 200,
        width: 200,
        isClearable: false,
      },
      {
        label: "Rotation Name",
        field: "rotation_name",
        type: "text",
        modalRow: 1,
        modalWidth: 200,
        width: 200,
        isClearable: false,
        renderer: textCellRenderer("rotation_name"),
      },
      {
        label: "Market",
        field: "market",
        type: "select",
        options: "marketOptions",
        modalRow: 1,
        modalWidth: 200,
        width: 120,
        isClearable: true,
      },
      {
        label: "Days",
        field: "day_set",
        type: "text",
        modalRow: 2,
        modalWidth: 100,
        width: 100,
        isClearable: false,
        renderer: daysCellRenderer,
      },
      {
        label: "Start",
        field: "daypart_begin",
        type: "text",
        modalRow: 2,
        modalWidth: 100,
        width: 100,
        isClearable: false,
        renderer: timeCellRenderer("daypart_begin"),
      },
      {
        label: "End",
        field: "daypart_end",
        type: "text",
        modalRow: 2,
        modalWidth: 100,
        width: 100,
        isClearable: false,
        renderer: timeCellRenderer("daypart_end"),
      },
      {
        label: "Cost 30s",
        field: "cost_30s",
        type: "currency",
        modalRow: 2,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer,
      },
      {
        label: "Company",
        field: "company",
        type: "text",
        modalRow: 2,
        modalWidth: 200,
        width: 100,
      },
      { label: "Valid", field: "valid", type: "text" },
    ];

    return enableMarketChannelColumns
      ? headers
      : headers.filter(header => {
          return header.label !== "Market";
        });
  }, [enableMarketChannelColumns]);

  const makeOptions = optionsList => {
    return R.map(e => ({ label: e, value: e }), optionsList);
  };

  const [marketOptions, setMarketOptions] = useState<string[]>();

  useEffect(() => {
    if (!marketOptions) {
      (async () => {
        let dmas: string[] = [];

        try {
          let res = await StreamingV2LambdaFetch("/getDMAMap");
          res = await awaitJSON(res);
          Object.keys(res).forEach(dmaCode => {
            dmas.push(res[dmaCode]);
          });
          dmas.sort();
        } catch (e) {
          setError({ message: `Failed to get linear rates data ${e.message}`, reportError: e });
        }

        setMarketOptions(["NATIONAL"].concat(dmas));
      })();
    }
  }, [marketOptions, setError]);

  const selectorOptions = useMemo(() => {
    let availOptions = networksBuyType === "radio" ? ["L"] : ["N", "L"];
    return {
      availOptions: makeOptions(availOptions),
      statusOptions: makeOptions([
        "buyable",
        "defunct",
        "inactive",
        "mirror",
        "paid programming",
        "secured",
        "general",
        "remnant",
        "upfront",
      ]),
      marketOptions: marketOptions ? makeOptions(marketOptions) : [],
    } as Record<string, SelectorOption[]>;
  }, [marketOptions, networksBuyType]);

  const [networksWithChannel, setNetworksWithChannel] = useState<{ [network: string]: string }>({});
  useEffect(() => {
    if (Object.keys(networksWithChannel).length === 0) {
      (async () => {
        try {
          let res = await LinearBuyingLambdaFetch("/networks");
          const networksMap = await awaitJSON(res);
          let networksWithChannel: { [network: string]: string } = {};
          Object.keys(networksMap).forEach(network => {
            networksWithChannel[network] = networksMap[network].channel;
          });

          setNetworksWithChannel(networksWithChannel);
        } catch (e) {
          setError({
            message: `Failed to get network channel info. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [networksWithChannel, setError]);

  useEffect(() => {
    if (!linearRatesData && !radioRatesData && Object.keys(networksWithChannel).length > 0) {
      (async () => {
        let ratesData;

        try {
          ratesData = await LinearLambdaFetch("/get_rates");
          ratesData = await awaitJSON(ratesData);
        } catch (e) {
          setError({ message: `Failed to get linear rates data ${e.message}`, reportError: e });
        }

        let linearRatesData =
          ratesData?.filter(entry => networksWithChannel[entry.network] === "Linear") || [];
        setLinearRatesData(linearRatesData);
        setOriginalLinearRatesData(linearRatesData);
        let radioRatesData =
          ratesData?.filter(entry => networksWithChannel[entry.network] === "Radio") || [];
        setRadioRatesData(radioRatesData);
        setOriginalRadioRatesData(radioRatesData);
      })();
    }
  }, [setError, linearRatesData, radioRatesData, networksWithChannel]);

  const modifiedRows = useMemo(() => {
    if (linearRatesData && originalLinearRatesData && radioRatesData && originalRadioRatesData) {
      const linearAdded = R.filter(row => !row.id || row.id < 0, linearRatesData);

      const linearCurrentById = R.fromPairs(R.map(row => [row.id, row], linearRatesData));
      const linearRemoved = R.filter(
        row => row.id >= 0 && !linearCurrentById[row.id],
        originalLinearRatesData
      );
      const linearUpdated = R.filter(row => row.id >= 0 && row.dirty, linearRatesData);

      for (let i = 0; i < linearAdded.length; i++) {
        linearAdded[i].id = -(i + 1);
      }

      const radioAdded = R.filter(row => !row.id || row.id < 0, radioRatesData);

      const radioCurrentById = R.fromPairs(R.map(row => [row.id, row], radioRatesData));
      const radioRemoved = R.filter(
        row => row.id >= 0 && !radioCurrentById[row.id],
        originalRadioRatesData
      );
      const radioUpdated = R.filter(row => row.id >= 0 && row.dirty, radioRatesData);

      for (let i = 0; i < radioAdded.length; i++) {
        radioAdded[i].id = -(i + 1);
      }

      return {
        added: {
          ...R.fromPairs(R.map(row => [row.id, row], linearAdded)),
          ...R.fromPairs(R.map(row => [row.id, row], radioAdded)),
        },
        removed: {
          ...R.fromPairs(R.map(row => [row.id, row], linearRemoved)),
          ...R.fromPairs(R.map(row => [row.id, row], radioRemoved)),
        },
        updated: {
          ...R.fromPairs(R.map(row => [row.id, row], linearUpdated)),
          ...R.fromPairs(R.map(row => [row.id, row], radioUpdated)),
        },
      };
    } else {
      return { added: {}, removed: {}, updated: {} };
    }
  }, [linearRatesData, originalLinearRatesData, radioRatesData, originalRadioRatesData]);

  const hasModifications = useMemo(() => {
    return (
      R.keys(modifiedRows.added).length > 0 ||
      R.keys(modifiedRows.removed).length > 0 ||
      R.keys(modifiedRows.updated).length > 0
    );
  }, [modifiedRows]);

  const onSubmit = useCallback(async () => {
    setSaving(true);

    try {
      await LinearLambdaFetch("/set_rates", {
        method: "POST",
        body: { changes: modifiedRows, email },
      });
    } catch (e) {
      setError({ message: `Failed to set linear rates data ${e.message}`, reportError: e });
    }

    let ratesData;
    try {
      ratesData = await LinearLambdaFetch("/get_rates");
      ratesData = await awaitJSON(ratesData);
      let linearRatesData =
        ratesData?.filter(entry => networksWithChannel[entry.network] === "Linear") || [];
      setLinearRatesData(linearRatesData);
      setOriginalLinearRatesData(linearRatesData);
      let radioRatesData =
        ratesData?.filter(entry => networksWithChannel[entry.network] === "Radio") || [];
      setRadioRatesData(radioRatesData);
      setOriginalRadioRatesData(radioRatesData);
    } catch (e) {
      setError({ message: `Failed to get linear rates data ${e.message}`, reportError: e });
    }

    setSaving(false);
  }, [modifiedRows, email, setError, networksWithChannel]);

  const invalidRows = useMemo(() => {
    return R.filter(row => row.valid === "invalid", linearRatesData || []).concat(
      R.filter(rows => rows.valid === "invalid", radioRatesData || [])
    );
  }, [radioRatesData, linearRatesData]);

  const validateRows = rows => {
    const duplicateRowCounts = {};

    for (let row of rows) {
      if (
        !isDaySetValid(row.day_set) ||
        !isTimeValid(row.daypart_begin) ||
        !isTimeValid(row.daypart_end) ||
        !isTextValid(row.network) ||
        !isTextValid(row.full_network) ||
        !isTextValid(row.avail) ||
        !isTextValid(row.status) ||
        !isTextValid(row.rotation_name)
      ) {
        row.valid = "invalid";
      } else {
        delete row.valid;
      }

      const key = R.map(p => row[p], EQUALITY_PROPS).join("_");
      duplicateRowCounts[key] = R.defaultTo(0, duplicateRowCounts[key]) + 1;
    }

    for (let row of rows) {
      const key = R.map(p => row[p], EQUALITY_PROPS).join("_");
      if (duplicateRowCounts[key] > 1) {
        row.valid = "duplicate";
      }
    }
  };

  const linearRatesTable: JSX.Element = useMemo(() => {
    if (networksBuyType === "radio") {
      return radioRatesData ? (
        <ModalEditTable
          className="linearRatesTable"
          name="Linear Rates"
          selectorOptions={selectorOptions}
          headers={ratesHeaders}
          tableData={radioRatesData}
          setTableData={newRatesData => {
            validateRows(newRatesData);
            setRadioRatesData(newRatesData);
          }}
          filterBar={true}
          defaultAdvancedFilter={true}
          checkIsValid={elem => {
            elem.dirty = true;
            return true;
          }}
        />
      ) : (
        <FullPageSpinner />
      );
    } else {
      return linearRatesData ? (
        <ModalEditTable
          className="linearRatesTable"
          name="Linear Rates"
          selectorOptions={selectorOptions}
          headers={ratesHeaders}
          tableData={linearRatesData}
          setTableData={newRatesData => {
            validateRows(newRatesData);
            setLinearRatesData(newRatesData);
          }}
          filterBar={true}
          defaultAdvancedFilter={true}
          checkIsValid={elem => {
            elem.dirty = true;
            return true;
          }}
        />
      ) : (
        <FullPageSpinner />
      );
    }
  }, [networksBuyType, radioRatesData, ratesHeaders, selectorOptions, linearRatesData]);

  return (
    <Page
      title="Linear Rates"
      pageType="Linear Rates"
      actions={
        <div className="bpmLinearRatesActions">
          {enableMarketChannelColumns && (
            <TextToggleButton
              options={[
                { key: "linear", label: "Linear" },
                { key: "radio", label: "Radio" },
              ]}
              selectedOption={networksBuyType}
              onChange={key => setNetworksBuyType(key)}
            />
          )}
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.ICON_ONLY}
            disabled={!hasModifications || !R.isEmpty(invalidRows)}
            onClick={() => onSubmit()}
            icon={saving ? <Spinner /> : <MdSave />}
          />
        </div>
      }
    >
      <div className="bpmLinearRates">{linearRatesTable}</div>
    </Page>
  );
};

export default LinearRates;
