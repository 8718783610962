import "./TableInputField.scss";
import React, { ClipboardEventHandler } from "react";
import { Form } from "react-bootstrap";

interface TableInputFieldProps {
  value: number;
  onChange: any;
  onPaste: ClipboardEventHandler<HTMLInputElement>;
}

const TableInputField: React.FC<TableInputFieldProps> = ({ value, onChange, onPaste }) => {
  return (
    <Form.Group className="tableInputFieldGroup">
      <Form.Control type="number" value={value ?? ""} onChange={onChange} onPaste={onPaste} />
    </Form.Group>
  );
};

export default TableInputField;
