import { Button } from "react-bootstrap";
import { DIMENSION_COLUMN_METADATA_MAP as LINEAR_DIMENSION_COLUMN_METADATA_MAP } from "../LinearPerformance/linearPerformanceUtils";
import { DIMENSION_COLUMN_METADATA_MAP as STREAMING_DIMENSION_COLUMN_METADATA_MAP } from "../StreamingPerformance/streamingPerformanceUtils";
import { DIMENSION_COLUMN_METADATA_MAP as YOUTUBE_DIMENSION_COLUMN_METADATA_MAP } from "../YouTubePerformance/youtubePerformanceUtils";
import { DimensionColumnMetaData } from "../performanceUtils";
import { MoveColumn, DeleteColumn, DIMENSION_COLUMN_DND_TYPE } from "./configUtils";
import { OldDropdown, CheckBox, OnBlurInput } from "../../Components";
import { StateSetter } from "../../utils/types";
import { useDrag, useDrop } from "react-dnd";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import cn from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import ColumnTop from "./ColumnTop";
import {
  PerformanceDimensionColumn as DimensionColumn,
  Preset,
  PresetChanges,
} from "@blisspointmedia/bpm-types/dist/Performance";

import "./ConfigColumn.scss";

interface ConfigDimensionColumnProps<DC extends DimensionColumn, P extends Preset> {
  i: number;
  column: DC;
  setPresetChanges: StateSetter<PresetChanges<P>>;
  moveColumn: MoveColumn;
  deleteColumn: DeleteColumn;
  isLast: boolean;
  addColumn: (i: number) => void;
  color?: string;
  hoverItem?: string;
  isLinear?: boolean;
  isYoutube?: boolean;
}

const ConfigDimensionColumn = <DC extends DimensionColumn, P extends Preset>({
  column,
  i,
  setPresetChanges,
  moveColumn,
  deleteColumn,
  isLast,
  addColumn,
  color,
  hoverItem,
  isLinear,
  isYoutube,
}: ConfigDimensionColumnProps<DC, P>): JSX.Element => {
  const { id, label, minWidth, type, adminOnly } = column;

  const dimensionColumnMetaDataMap: Record<string, DimensionColumnMetaData> = useMemo(() => {
    return isYoutube
      ? YOUTUBE_DIMENSION_COLUMN_METADATA_MAP
      : isLinear
      ? LINEAR_DIMENSION_COLUMN_METADATA_MAP
      : STREAMING_DIMENSION_COLUMN_METADATA_MAP;
  }, [isLinear, isYoutube]);

  let metadata = dimensionColumnMetaDataMap[type];

  const columnRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DIMENSION_COLUMN_DND_TYPE, id, i },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [{ isOver, dropI }, drop] = useDrop({
    accept: DIMENSION_COLUMN_DND_TYPE,
    canDrop: item => item.id !== id,
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
      dropI: monitor.getItem()?.i,
    }),
    drop: (item: { type: typeof DIMENSION_COLUMN_DND_TYPE; id: string }) => {
      moveColumn(item.id, column.id);
    },
  });

  drop(preview(columnRef));
  drag(handleRef);

  const onDimensionChange = useCallback(
    (i, key, value) => {
      setPresetChanges(changes => {
        let newChanges = {
          ...changes,
          performanceDimensionColumns: R.adjust(
            i,
            column => ({
              ...column,
              [key]: value,
            }),
            changes.performanceDimensionColumns
          ),
        };
        if (key === "type") {
          let metadata = dimensionColumnMetaDataMap[value];
          newChanges.performanceDimensionColumns[i].dimension = metadata.dimension;
          if (metadata.defaultAdmin) {
            newChanges.performanceDimensionColumns[i].adminOnly = true;
          }
        }

        return newChanges;
      });
    },
    [setPresetChanges, dimensionColumnMetaDataMap]
  );

  // TODO: what if they delete the last column and try to save?
  return (
    <div className="columnConfigOuter" ref={columnRef}>
      <div
        className={cn("columnConfig", {
          isOver,
          left: isOver && dropI > i,
          right: isOver && dropI <= i,
          dragging: isDragging,
          isHoverItem: hoverItem === id,
        })}
        key={i}
      >
        <ColumnTop noHeaders color={color} handleRef={handleRef} addColumn={addColumn} i={i} />
        <div className="basicSection">
          <OldDropdown
            wrapping
            size="sm"
            label="Type"
            value={type}
            options={
              isYoutube
                ? Y.DIMENSION_COLUMN_TYPES
                : isLinear
                ? L.DIMENSION_COLUMN_TYPES
                : S.PERFORMANCE_DIMENSION_COLUMN_TYPES
            }
            onChange={val => onDimensionChange(i, "type", val)}
          />
        </div>
        <div className="formSection">
          <div className="label">Label</div>
          <OnBlurInput
            size="sm"
            value={label || ""}
            placeholder={`${type} (default)`}
            onChange={val => onDimensionChange(i, "label", val)}
          />
        </div>
        <div className="formSection">
          <div className="label">Min width:</div>
          <OnBlurInput
            size="sm"
            type="number"
            value={minWidth || metadata.minWidth}
            onChange={minWidth =>
              onDimensionChange(i, "minWidth", parseFloat(minWidth) || undefined)
            }
            placeholder={`${metadata.minWidth} (default)`}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Admin Only?:</div>
          <CheckBox
            checked={!!adminOnly}
            size="sm"
            onCheck={newVal => onDimensionChange(i, "adminOnly", newVal)}
          />
        </div>
        {!isLast && (
          <Button
            size="sm"
            variant="outline-danger"
            className="deleteButton"
            onClick={() => deleteColumn(i)}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConfigDimensionColumn;
