import React, { useContext, useState } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import { Button, ButtonType } from "../Components";
import { MdWarning } from "react-icons/md";
import { FormContext } from "./WtoFormContext";
import WtoValidationErrors from "./WtoValidationErrors";
import "./WtoDuplicateModal.scss";
import * as R from "ramda";

export interface WtoDuplicateRuleModalProps {
  show: boolean;
  closeModal: () => void;
  index: number;
  showRuleCards: boolean[];
  setShowRuleCards: React.Dispatch<React.SetStateAction<boolean[]>>;
}

export const WtoDuplicateRuleModal: React.FC<WtoDuplicateRuleModalProps> = ({
  show,
  closeModal,
  index,
  showRuleCards,
  setShowRuleCards,
}) => {
  const [duplicateRuleName, setDuplicateRuleName] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const { wtoFormObj, setWtoFormObj } = useContext(FormContext);

  const confirmDuplication = () => {
    if (duplicateRuleName.length === 0) {
      setValidationErrors(["Rule Name cannot be left blank"]);
    } else {
      const newWtoFormObj = R.clone(wtoFormObj);
      let clonedRule = R.clone(wtoFormObj.rules[index]);
      clonedRule.rule_order = `${index + 1}`;
      clonedRule.rule_name = duplicateRuleName;
      newWtoFormObj.rules = R.insert(index + 1, clonedRule, newWtoFormObj.rules);
      setWtoFormObj(newWtoFormObj);
      setShowRuleCards(R.insert(index + 1, true, R.clone(showRuleCards)));
      closeModal();
    }
  };

  return (
    <Modal show={show} onHide={closeModal} className="wtoDuplicateModal">
      <Modal.Header closeButton>
        <Modal.Title>Duplicate Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WtoValidationErrors validationErrors={validationErrors} />
        <div className="infoBox warning">
          <div className="icon">
            <MdWarning color="orange" className="alertIcon" />
          </div>
          <p className="message">
            Before duplicating this rule, please enter unique values for the following fields:
          </p>
        </div>
        <Form className="form">
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Rule Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Rule Name"
                onChange={e => setDuplicateRuleName(e.target.value)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type={ButtonType.FILLED} onClick={closeModal}>
          Cancel
        </Button>
        <Button type={ButtonType.FILLED} onClick={confirmDuplication}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WtoDuplicateRuleModal;
