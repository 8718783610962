import { BPMButton, Page, Spinner } from "../Components";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdDragHandle } from "react-icons/md";
import "./KpiSetup.scss";
import { awaitJSON, SheetsLambdaFetch } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import * as R from "ramda";

interface companyLabel {
  value: string;
  label: string;
}

interface KpiRow {
  name: string;
  kpi: string;
  kpi_order: number;
}

const KpiSetup = (): JSX.Element => {
  const setError = useSetError();
  const [company, setCompany] = useState<companyLabel>();
  const [saving, setSaving] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>();
  const [fetch, setFetching] = useState(false);
  const [originalKpiItems, setOriginalKpiItems] = useState<KpiRow[]>([]);
  const [undefinedKpiItems, setundefinedKpiItems] = useState<string[]>([]);
  const defaultKpis = [
    { name: "kpi1", kpi: "kpi1_filtered", kpi_order: 1 },
    { name: "kpi2", kpi: "kpi2_filtered", kpi_order: 2 },
    { name: "kpi3", kpi: "kpi3_filtered", kpi_order: 3 },
  ];
  const [kpiItems, setKpiItems] = useState<KpiRow[]>(defaultKpis);
  const [kpisPulled, setPulledKpis] = useState<boolean>(false);

  const useCompanyOptions = (): string[] => {
    useEffect(() => {
      if (!options || !options.length) {
        (async () => {
          try {
            let res = await SheetsLambdaFetch("/get_company_ids");
            let jsonResponse = await awaitJSON<{ ids: string[] }>(res);
            setOptions(jsonResponse.ids);
            setPulledKpis(false);
          } catch {
            setError({ title: "Internal Error", message: "Could not fetch existing company ids" });
            setOptions([]);
            return options;
          }
        })();
      }
    });
    return options || [];
  };

  const getKpiItems = async () => {
    setFetching(true);
    if (company === undefined) {
      setError({
        message: "Please select a valid company",
        title: "Error: Company Name",
      });
      setFetching(false);
      return;
    }
    try {
      let res = await SheetsLambdaFetch("/get_company_kpis", {
        params: {
          company: company?.value,
        },
      });
      let jsonResponse = await awaitJSON<{ kpis: KpiRow[] }>(res);
      setOriginalKpiItems(jsonResponse.kpis);
      let undefinedKpis = R.map(
        kpi => kpi.kpi,
        R.filter(kpi => kpi.kpi_order === null, jsonResponse.kpis) as KpiRow[]
      );
      setundefinedKpiItems(undefinedKpis);
      setKpiItems(jsonResponse.kpis);
      setFetching(false);
      setPulledKpis(true);
    } catch {
      setError({ title: "Internal Error", message: "Could not fetch kpis for company" });
      setKpiItems([]);
      setFetching(false);
    }
  };

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const handleSort = () => {
    //duplicate items
    let items = [...kpiItems];

    //remove and save the dragged item content
    const draggedItemContent = items.splice(dragItem.current, 1)[0];

    //switch the position
    items.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update kpi_order
    items.forEach((kpi, index) => {
      kpi.kpi_order = index + 1;
    });

    //update the actual array
    setKpiItems(items);
  };
  // const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setKpiItems([e.target.value]);
  // };

  const saveChanges = async () => {
    if (company === undefined) {
      setError({
        message: "Please select a valid company",
        title: "Error: Company Name",
      });
      return;
    }

    if (!kpisPulled) {
      setError({
        message: "Please pull valid KPIs",
        title: "Error: KPIs do not match selected company",
      });
      return;
    }

    try {
      let kpisToSend: KpiRow[] = [];
      kpiItems.forEach((kpi, index) => {
        let originalKpi = originalKpiItems[index];
        if (kpi.kpi !== originalKpi.kpi) {
          kpisToSend.push(kpi);
        } else if (undefinedKpiItems.includes(kpi.kpi)) {
          kpi.kpi_order = index + 1;
          kpisToSend.push(kpi);
        }
      });

      await SheetsLambdaFetch<{ company: string; kpis: KpiRow[] }>("/set_company_kpis_ordering", {
        method: "POST",
        body: { company: company.value, kpis: kpisToSend },
      });
      await setError({
        variant: "success",
        title: "Success!",
        message: "Successfully reorder KPIs",
      });
      setSaving(false);
      window.location.reload();
    } catch {
      setSaving(false);
      setError({ title: "Internal Error", message: "Could not update kpi ordering" });
    }
  };

  const companyOptions = useCompanyOptions();
  const optionList = useMemo(() => companyOptions.map(value => ({ value, label: value })), [
    companyOptions,
  ]);

  return (
    <Page
      title="KPI Setup"
      pageType="KPI Setup"
      actions={
        <div className="saveChanges">
          <BPMButton variant="success" onClick={() => saveChanges()}>
            {saving ? <Spinner /> : "Save Changes"}
          </BPMButton>
        </div>
      }
    >
      <div className="kpiSetupPage">
        <div className="companyPicker">
          <Select
            className="select"
            placeholder="Select from existing companies..."
            isSearchable
            value={company}
            options={optionList}
            onChange={value => setCompany(value)}
          />
          <BPMButton className="kpiButton" variant="primary" onClick={() => getKpiItems()}>
            {fetch ? <Spinner /> : "Get KPIs"}
          </BPMButton>
        </div>

        <div className="app">
          {/** List container //TODO break into component */}
          <div className="list-container">
            {kpiItems.map((item, index) => (
              <div
                key={index}
                className="list-item"
                draggable
                onDragStart={e => (dragItem.current = index)}
                onDragEnter={e => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={e => e.preventDefault()}
              >
                <MdDragHandle />
                <i className="fa-solid fa-bars"></i>
                <span className="kpiItem">{`${item.kpi} | ${item.name}`}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};
export default KpiSetup;
