import { CrossChannelContext } from "../CrossChannel";
import { DEFAULT_SPARKCHARTS_MAP } from "../../SingleChannel/MetricsTableConfig/EditPagePresetModal";
import { METRIC_OPTIONS } from "../crossChannelConstants";
import { Metric, MetricTotalsByDate, KpiInfo } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import CompareMetrics from "../Components/CompareMetrics";
import FunnelDynamics from "../Components/FunnelDynamics";
import KpiVolume, { Filtering } from "../Components/KpiVolume";
import MarketingInputs from "../Components/MarketingInputs";
import React, { useState, useContext } from "react";
import SparkChart from "../../Components/SparkChart/SparkChart";

interface OverviewProps {
  kpis: KpiInfo;
  data: MetricTotalsByDate[];
  otherData: MetricTotalsByDate[];
  marketingInputs: any[];
  channelClassification: Record<string, string>;
  defaultCompareMetrics: Metric[];
  defaultCompareMetricsIncludeOtherDates;
  defaultFunnelDynamicsPreset?: string;
  defaultKpiFiltering?: Filtering;
  defaultKpiFilteringFunnel?: string;
  defaultSparkCharts?: Metric[];
  defaultTotal?: string[];
}

export const DEFAULT_METRICS = DEFAULT_SPARKCHARTS_MAP["cross-channel"];

const Overview: React.FC<OverviewProps> = ({
  kpis,
  data,
  otherData,
  marketingInputs,
  channelClassification,
  defaultCompareMetrics,
  defaultCompareMetricsIncludeOtherDates = false,
  defaultFunnelDynamicsPreset,
  defaultKpiFiltering,
  defaultKpiFilteringFunnel,
  defaultSparkCharts = DEFAULT_METRICS,
  defaultTotal = [false, false, true],
}) => {
  const [showMarketingInputs, setShowMarketingInputs] = useState(false);
  const { kpiData, funnelPresets } = kpis;
  const { kpi } = useContext(CrossChannelContext);

  return (
    <div className="ccOverview">
      <div className="ccTopSection">
        <div className="ccTopSectionLeftSide">
          <div className="ccDailyKpiVolume">
            <KpiVolume
              data={kpiData}
              funnelPresets={funnelPresets}
              showMarketingInputs={showMarketingInputs}
              setShowMarketingInputs={setShowMarketingInputs}
              defaultFiltering={defaultKpiFiltering}
              defaultFunnel={defaultKpiFilteringFunnel}
              footer={
                <>
                  <span>KPI picker doesn't apply</span>
                </>
              }
            />
          </div>
          {showMarketingInputs && (
            <div className="ccMarketingInputs">
              <MarketingInputs
                data={marketingInputs}
                channelClassification={channelClassification}
              />
            </div>
          )}
          {!showMarketingInputs && (
            <div className="ccSparkCharts">
              {defaultSparkCharts.slice(0, DEFAULT_METRICS.length).map((metric, index) => (
                <SparkChart
                  pngLabel={`KPI: ${kpi}`}
                  pngFileIdentifiers={["CrossChannel"]}
                  data={data}
                  otherData={otherData}
                  defaultMetric={metric}
                  key={metric}
                  defaultTotal={defaultTotal[index] ? defaultTotal[index] === "Total" : false}
                  metricOptions={METRIC_OPTIONS.filter(option => option.value !== Metric.VOLUME)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="ccTopSectionRightSide">
          <div className="ccKpiFunnel">
            <FunnelDynamics
              data={kpiData}
              funnelPresets={funnelPresets}
              defaultPreset={defaultFunnelDynamicsPreset}
            />
          </div>
        </div>
      </div>
      <div className="ccRow">
        <div className="ccAgencyNews" style={{ margin: "8px" }}>
          <a href="https://www.tinuiti.com" target="_blank" rel="noopener noreferrer">
            <img
              src={"https://cdn.blisspointmedia.com/agencies/tinuiti/Tinuiti_Agency_News.png"} // This is the placeholder image for now.
              alt="Agency News"
              width={500}
              height={500}
            />
          </a>
        </div>
        <div className="ccMetricComparisonChart">
          <CompareMetrics
            pngDownloadLabel={`KPI: ${kpi}`}
            pngFileIdentifiers={["CrossChannel"]}
            data={data}
            otherData={otherData}
            defaultMetrics={defaultCompareMetrics}
            defaultIncludeOtherDates={defaultCompareMetricsIncludeOtherDates}
            includeFooter={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
