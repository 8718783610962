import React, { useMemo } from "react";
import { BPMTable, NumberFormatter } from "../Components";

interface ModalCumulativeAndDailyTableProps {
  data: any[];
  showCumulative: boolean;
  budget: number;
}

const ModalCumulativeAndDailyTable: React.FC<ModalCumulativeAndDailyTableProps> = ({
  data,
  showCumulative,
  budget,
}) => {
  const dataAdjusted = useMemo(() => {
    const todayFormatted = new Date().toISOString().split("T")[0];
    let remainingBudget = budget;
    const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return sortedData.map(item => {
      const formattedCurrent = new Date(item.date).toISOString().split("T")[0];
      const spend =
        formattedCurrent < todayFormatted
          ? item.cleared.simple.spend
          : item.projected.adjusted.spend;

      remainingBudget -= spend;

      return {
        ...item,
        remainingBudget,
      };
    });
  }, [data, budget]);

  const headers = [
    {
      label: "Date",
      name: "date",
      flex: 1,
      minFlexWidth: 150,
    },
    {
      label: "Daily Delivery",
      name: "cleared",
      flex: 1,
      minFlexWidth: 150,
      renderer: row => {
        return <NumberFormatter value={row.cleared.simple.spend} type={"$"} decimals={0} />;
      },
    },
    {
      label: "Expected Delivery",
      name: "booked",
      flex: 1,
      minFlexWidth: 150,
      renderer: row => {
        return <NumberFormatter value={row.booked.simple.spend} type={"$"} decimals={0} />;
      },
    },
    {
      label: "Ideal Delivery",
      name: "cleared",
      flex: 1,
      minFlexWidth: 150,
      renderer: row => {
        return <NumberFormatter value={row.projected.adjusted.spend} type={"$"} decimals={0} />;
      },
    },
    {
      label: "Remaining Budget",
      name: "booked",
      flex: 1,
      minFlexWidth: 150,
      renderer: row => {
        return <NumberFormatter value={row.remainingBudget} type={"$"} decimals={0} />;
      },
    },
  ];

  return showCumulative ? (
    <BPMTable data={dataAdjusted} headers={headers} filterBar={false}></BPMTable>
  ) : (
    <BPMTable data={dataAdjusted} headers={headers} filterBar={false}></BPMTable>
  );
};

export default ModalCumulativeAndDailyTable;
