import React, { useState, useEffect, useMemo, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { ListGroup, Button, Alert, Form, Modal } from "react-bootstrap";

import { useSetError, useSetAreYouSure } from "../redux/modals";
import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import useLocation from "../utils/hooks/useLocation";
import { useStateFunction } from "../utils/hooks/useData";
import { makeMemoizedGetter } from "../utils/data";

import { FullPageSpinner, OldFilterBar, Img, DateBox } from "../Components";

import "./InvalidRotationsReport.scss";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

const PRETTY_DATE_FORMAT = "M/dd/yyyy";

const toPrettyDate = R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT));

const BAD_DAY_COUNT = 4;

const TODAY = Dfns.format("yyyy-MM-dd", new Date());

const getIsImminent = makeMemoizedGetter({
  calculate: date =>
    Dfns.differenceInCalendarDays(new Date(), Dfns.parseISO(date)) <= BAD_DAY_COUNT,
});

const FILTER_BAR_OPTIONS = [
  { name: "date", label: "Bad Day" },
  { name: "isci", label: "ISCI" },
  { name: "network", label: "Network" },
  { name: "platform", label: "Platform" },
  { name: "placementName", label: "Placement Name" },
  { name: "placementID", label: "Placement ID" },
  { name: "suppressed", label: "Is Suppressed" },
  { name: "suppressedUntil", label: "Suppressed Until" },
  { name: "suppressedBy", label: "Suppressed By" },
  { name: "rotationStart", label: "Rotation Start Date" },
  { name: "rotationEnd", label: "Rotation End Date" },
  { name: "flightStart", label: "Flight Start Date" },
  { name: "flightEnd", label: "Flight End Date" },
  { name: "lastLiveStart", label: "Last Live Start Date" },
  { name: "lastLiveEnd", label: "Last Live End Date" },
  { name: "flightID", label: "Flight ID" },
  { name: "orderID", label: "Order ID" },
];

const DateSet = React.memo(({ start, end }) => {
  const [prettyStart, prettyEnd] = useMemo(
    () => [start && toPrettyDate(start), end ? toPrettyDate(end) : "Forever"],
    [start, end]
  );
  return prettyStart ? (
    <DateBox noParse className="dateSet" start={prettyStart} end={prettyEnd} />
  ) : (
    <div>None</div>
  );
});

const ProblemPane = React.memo(({ data, resetData }) => {
  const {
    network,
    platform,
    placementName,
    placementID,
    date,
    isci,
    rotationDates,
    flight,
    lastLiveDates = {},
    suppression,
  } = data;
  const { company } = useLocation();

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const [suppressDays, setSuppressDays] = useState(1);
  const [because, setBecause] = useState("");

  const imminentDanger = useMemo(
    () => Dfns.differenceInCalendarDays(new Date(), Dfns.parseISO(date)) <= BAD_DAY_COUNT,
    [date]
  );

  const [suppressing, setSuppressing] = useState(false);
  const suppress = useCallback(async () => {
    setSuppressing(true);
    try {
      await setAreYouSure({
        title: "Suppressing Slack Alert",
        message: `You're about to suppress slack alerts for isci ${isci} on placement ${placementID} for ${suppressDays} day${
          `${suppressDays}` === "1" ? "" : "s"
        }. Are you sure you want to do this?`,
        okayText: "Suppress Alerts",
      });
    } catch (e) {
      setSuppressing(false);
      return;
    }
    if (!because) {
      try {
        await setAreYouSure({
          title: "Suppressing Without Explanation",
          message:
            "You are about to suppress a slack alert without giving an explanation. Explanations are important for knowing why an alert was suppressed. Are you sure you want to continue?",
          okayText: "Suppress Without Explanation",
        });
      } catch (e) {
        setSuppressing(false);
        return;
      }
    }
    if (imminentDanger) {
      try {
        await setAreYouSure({
          variant: "danger",
          title: "Are you Sure?",
          message: `This ISCI will run ${
            date === TODAY ? "TODAY" : "very soon"
          }. Unless you are certain that this isn't an actual issue, you should either get the correct rotations into the system or update the creative map. Are you sure you want to suppress the alert?`,
          okayText: "Suppress Anyway",
        });
      } catch (e) {
        setSuppressing(false);
        return;
      }
    }

    try {
      await CreativeLambdaFetch("/suppress_rotation_warning", {
        method: "post",
        body: {
          placementID,
          isci,
          days: suppressDays,
          because,
        },
      });
      resetData();
    } catch (e) {
      let message = `Failed to suppress slack alert. Error: ${e.message}`;
      await setError({
        message,
        reportError: e,
      });
    }
  }, [
    setAreYouSure,
    setError,
    suppressDays,
    isci,
    placementID,
    because,
    resetData,
    imminentDanger,
    date,
  ]);

  const suppressionSubmitDate = useMemo(
    () =>
      suppression &&
      R.pipe(R.prop("lastmodified"), Dfns.parseISO, Dfns.format("M/dd/yyyy @ h:mma"))(suppression),
    [suppression]
  );

  return (
    <div className="problemView">
      <div className="infoSection">
        <div>
          <div>Network:</div>
          <div>
            {network} {platform}
          </div>
        </div>
        <div>
          <div>Placement:</div>
          <div>
            {placementName} (#{placementID})
          </div>
        </div>
        <div>
          <div>ISCI:</div>
          <div>{isci}</div>
        </div>
      </div>
      <Alert variant={imminentDanger ? "danger" : "warning"}>
        This ISCI{" "}
        {date === TODAY ? (
          <strong>IS RUNNING TODAY</strong>
        ) : (
          <div>
            will run on <strong>{toPrettyDate(date)}</strong>
          </div>
        )}
        . It is not marked as live in the creative map
        {date === TODAY ? "" : " on that date"}.
      </Alert>
      <div className="creativeRow">
        <div className="imgBox">
          <Img
            title={isci}
            src={getCreativeThumbnail(company, isci)}
            className="thumbnail"
            unloader={<div className="thumbnail" />}
          />
        </div>
        <div className="dateBox">
          <div className="sectionHeader">Last Live Dates</div>
          {lastLiveDates.start_date ? (
            <DateSet start={lastLiveDates.start_date} end={lastLiveDates.end_date} />
          ) : (
            <div>Never Live</div>
          )}
          <Button size="sm" variant="link" as="a" target="_blank" href={`/${company}/creativemap`}>
            Fix Creative Map
          </Button>
          <div className="sectionHeader">Rotation Run Dates</div>
          <DateSet start={rotationDates.start_date} end={rotationDates.end_date} />
          <Button
            size="sm"
            variant="link"
            as="a"
            target="_blank"
            href={`/${company}/streaming/creatives`}
          >
            Fix Streaming Creative Rotations
          </Button>
        </div>
      </div>
      <div className="flightRow">
        <div className="sectionHeader">Next Flight</div>
        <div className="flightInfo">
          <div>Flight ID #{flight.id}</div>
          <div>Order ID #{flight.order_id}</div>
          <DateSet start={flight.start_date} end={flight.end_date} />
        </div>
      </div>
      <hr />
      {suppression ? (
        <div>
          <Alert variant="info">
            This alert is suppressed until {toPrettyDate(suppression.suppress_until)}
          </Alert>
          <div>
            This alert was suppressed by <code>{suppression.lastuser}</code> on{" "}
            <code>{suppressionSubmitDate}</code>. Reason:
          </div>
          <div className="becauseBox">{suppression.because}</div>
        </div>
      ) : (
        <div>
          <div className="suppressDays">
            Suppress slack alert for{" "}
            <Form.Control
              className="suppressDaysControl"
              size="sm"
              type="number"
              min={0}
              value={suppressDays}
              onChange={e => setSuppressDays(e.target.value)}
            />{" "}
            day{`${suppressDays}` === "1" ? "" : "s"} (until{" "}
            {R.pipe(Dfns.addDays(suppressDays), Dfns.format(PRETTY_DATE_FORMAT))(new Date())})
            because
          </div>
          <Form.Control
            className="becauseInput"
            as="textarea"
            rows="3"
            value={because}
            onChange={e => setBecause(e.target.value)}
          />
          <Button
            block
            variant={imminentDanger ? "danger" : "warning"}
            disabled={suppressing}
            onClick={suppress}
          >
            Suppress Slack Alerts
          </Button>
        </div>
      )}
    </div>
  );
});

export default React.memo(() => {
  const setError = useSetError();
  const { company } = useLocation();

  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/check_invalid", {
            params: {
              company,
            },
          });
          setData(await awaitJSON(res));
        } catch (e) {
          let message = `Could not fetch invalid rotations report. Error: ${e.message}.`;
          setError({ message, reportError: e });
        }
      })();
    }
  }, [data, company, setError]);

  const [filter, setFilter] = useStateFunction(() => true);
  const { problems, suppressions, rotationMap, filterBarLines } = useMemo(() => {
    let rotationMap = R.pipe(
      R.values,
      R.flatten,
      R.reduce((agg, elem) => {
        agg[`${elem.placementID}_${elem.isci}`] = elem;
        return agg;
      }, {})
    )(data);
    let filterBarLines = [];
    // Using R.map because data will be two arrays: "problems" and "suppressions", and we want to do the same
    // thing to both.
    let { problems, suppressions } = R.map(
      R.pipe(
        R.filter(({ rotationDates, flight, lastLiveDates, suppression, date, ...restLine }) => {
          let filterableLine = {
            ...restLine,
            date: date === TODAY ? "Today" : date,
            flightID: R.prop("id", flight),
            orderID: R.prop("order_id", flight),
            flightStart: R.prop("start_date", flight),
            flightEnd: R.prop("end_date", flight),
            rotationStart: R.prop("start_date", rotationDates),
            rotationEnd: R.prop("end_date", rotationDates),
            lastLiveStart: R.prop("start_date", lastLiveDates),
            lastLiveEnd: R.prop("end_date", lastLiveDates),
            suppressed: !!suppression,
            suppressedUntil: R.prop("suppress_until", suppression),
            suppressedBy: R.prop("lastuser", suppression),
          };
          filterBarLines.push(filterableLine);
          return filter(filterableLine);
        }),
        R.groupBy(line => {
          if (line.date === TODAY) {
            return "today";
          }
          if (getIsImminent(line.date)) {
            return "imminent";
          }
          return "ok";
        }),
        R.map(
          R.pipe(
            R.groupBy(R.prop("placementID")),
            R.values,
            R.map(lines => {
              const { network, platform, placementName, placementID } = lines[0];
              return {
                network,
                platform,
                placementName,
                placementID,
                lines: R.pipe(
                  R.map(
                    R.pick(["isci", "date", "pct", "rotationDates", "flight", "lastLiveDates"])
                  ),
                  R.sortBy(R.prop("isci"))
                )(lines),
              };
            }),
            R.sortBy(R.pipe(R.props(["network", "platform", "placementName"]), R.join("_")))
          )
        )
      ),
      data || {}
    );

    return {
      problems: problems || {},
      suppressions: R.pipe(
        R.defaultTo({}),
        R.values,
        R.flatten,
        R.sortBy(R.pipe(R.props(["network", "platform", "placementName"]), R.join("_")))
      )(suppressions),
      rotationMap,
      filterBarLines,
    };
  }, [data, filter]);

  const [selectedRotation, setSelectedRotation] = useState();

  const selectedRotationData = useMemo(
    () => R.pipe(R.prop(selectedRotation), R.defaultTo({}))(rotationMap),
    [rotationMap, selectedRotation]
  );

  const [showExplainerModal, setShowExplainerModal] = useState(false);
  const [showAllFilteredModal, setShowAllFilteredModal] = useState(false);

  const allFilteredProblems = useMemo(() => {
    if (!problems) {
      return [];
    }
    let filteredProblems = [];
    for (let sectionArray of R.values(problems)) {
      for (let { placementID, lines } of sectionArray || []) {
        for (let { isci } of lines || []) {
          filteredProblems.push({
            placementID,
            isci,
          });
        }
      }
    }
    return filteredProblems;
  }, [problems]);
  const isFiltered = useMemo(() => {
    let dataLength =
      (R.path(["problems", "length"], data) || 0) + (R.path(["suppressions", "length"], data) || 0);
    return allFilteredProblems.length + suppressions.length < dataLength;
  }, [data, allFilteredProblems, suppressions]);

  return (
    <div className="invalidRotationsReport">
      {data ? (
        <div>
          <div className="rotationListView">
            <div>
              <Button variant="link" size="sm" onClick={() => setShowExplainerModal(true)}>
                What is this report about?
              </Button>
            </div>
            <div className="filterBarSection">
              <OldFilterBar
                lines={filterBarLines}
                onFilter={setFilter}
                options={FILTER_BAR_OPTIONS}
              />
              <Button
                variant="outline-primary"
                disabled={!allFilteredProblems.length}
                onClick={() => setShowAllFilteredModal(true)}
              >
                Suppress All Filtered
              </Button>
            </div>
            <div className="rotationList">
              {[
                {
                  title: "Invalid Rotations",
                  sections: [
                    {
                      subtitle: "Today",
                      data: problems.today,
                    },
                    {
                      subtitle: "Imminent",
                      data: problems.imminent,
                    },
                    {
                      subtitle: "Upcoming",
                      data: problems.ok,
                    },
                  ],
                },
                {
                  title: "Suppressed Alerts",
                  sections: [
                    {
                      data: suppressions,
                    },
                  ],
                },
              ].map(({ title, sections }) => (
                <div key={title}>
                  <div className="sectionHeader">{title}</div>
                  {R.pipe(
                    R.map(({ data }) => data || []),
                    R.flatten,
                    R.length
                  )(sections) ? (
                    sections.map(({ subtitle, data }) => (
                      <React.Fragment key={subtitle || "None"}>
                        {(data || []).length ? (
                          <div className="subsection">
                            {subtitle && <div className="subSectionHeader">{subtitle}</div>}
                            {data.map(
                              ({ placementName, placementID, network, platform, lines }) => {
                                return (
                                  <div key={placementID} className="placementSection">
                                    <div className="placementHeader">
                                      {network} {platform} <small>({placementName})</small>
                                    </div>
                                    <ListGroup className="rotationListGroup">
                                      {lines.map(line => {
                                        const { date, isci, pct, rotationDates } = line;
                                        let rotationKey = `${placementID}_${isci}`;
                                        const imminentDanger = getIsImminent(date);
                                        return (
                                          <ListGroup.Item
                                            action
                                            active={rotationKey === selectedRotation}
                                            key={isci}
                                            className="rotationListLine"
                                            onClick={() =>
                                              setSelectedRotation(key =>
                                                rotationKey === key ? null : rotationKey
                                              )
                                            }
                                          >
                                            <div className="invalidStarting">
                                              Bad Day:{" "}
                                              <span
                                                className={`badDate ${
                                                  imminentDanger ? "imminent" : ""
                                                }`}
                                              >
                                                {date === TODAY ? "TODAY" : toPrettyDate(date)}
                                              </span>
                                            </div>
                                            <div className="metaData">
                                              <div>
                                                <div>{isci}</div>
                                                <div className="pct">{pct}%</div>
                                              </div>
                                              <DateSet
                                                start={rotationDates.start_date}
                                                end={rotationDates.end_date}
                                              />
                                            </div>
                                          </ListGroup.Item>
                                        );
                                      })}
                                    </ListGroup>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))
                  ) : (
                    <div>No rotations.</div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {selectedRotation && (
            <ProblemPane data={selectedRotationData} resetData={() => setData()} />
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
      {showExplainerModal && (
        <Modal size="lg" show={!!showExplainerModal} onHide={() => setShowExplainerModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>The Invalid Rotation Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This report surfaces situations either today or in the future where an ISCI will air
              that is not marked as live in the creative map.
            </p>
            <h3>How To Use This Report</h3>
            <ul>
              <li>Each row represents an ISCI that is going to run when it isn't supposed to.</li>
              <li>Each row is a specific ISCI on a specific Extreme Reach Placement.</li>
              <li>
                The "Bad Day" is the first day when the ISCI is going to run when it isn't supposed
                to.
              </li>
              <li>
                Click on a row to get more specific information about when the ISCI is going to run
                in Extreme Reach, when the relevant flight is going to be live, and when the ISCI is
                (and isn't) set to be live in the creative map.
              </li>
              <li>
                The fix for each row will depend on the row, but in general you should go into the
                streaming creative platform and fix the rotation so the non-live ISCI doesn't run.
              </li>
            </ul>
            <h3>Background</h3>
            <p>
              We know an ISCI will air if there is an Extreme Reach rotation for that ISCI on that
              tag, and there is a flight that uses that tag. We can match all ISCIs set to run in
              Extreme Reach with all current and future flights, we can then see if there are live
              date ranges in the creative map that match that time frame. If there isn't, then we
              will have a situation where an ISCI will run that won't be live.
            </p>
            <p>
              This situation is mitigated partially because the streaming creative platform prevents
              the user from setting rotations for non-live creatives. However, a creative might be
              set to run and <em>later</em> become marked as non-live. This situation can happen a
              few ways. Some notable examples:
            </p>
            <ul>
              <li>
                We ran on a network for a week six months ago. We are going back online with that
                network, so we make a flight. Extreme Reach still has the six-month-old creative
                rotation for that tag set to run. If those ISCIs are no longer live, then we'll run
                invalid ISCIs.
              </li>
              <li>
                A media specialist enters a creative rotation that will begin two weeks from now.
                The client orders us to halt that creative. The media specialist might remove that
                ISCI from the current rotation, but there was a rotation set to start two weeks from
                now that was put in the system a week ago that won't be updated. Alternatively, the
                client manager might set the ISCI as non-live in the creative map without notifying
                their media specialist. In either of these cases,
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowExplainerModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showAllFilteredModal && (
        <SuppressAllFilteredModal
          onClose={() => setShowAllFilteredModal(false)}
          lines={allFilteredProblems}
          isFiltered={isFiltered}
          resetData={() => setData()}
        />
      )}
    </div>
  );
});

const SuppressAllFilteredModal = ({ lines, onClose, isFiltered, resetData }) => {
  const setError = useSetError();
  const [suppressDays, setSuppressDays] = useState(1);
  const [because, setBecause] = useState("");
  const [confirmingSuppress, setConfirmingSuppress] = useState(false);
  const [suppressing, setSuppressing] = useState(false);
  const suppress = useCallback(async () => {
    setSuppressing(true);
    try {
      await CreativeLambdaFetch("/bulk_suppress_rotation_warnings", {
        method: "post",
        body: {
          lines,
          days: suppressDays,
          because,
        },
      });
      resetData();
      onClose();
    } catch (e) {
      let message = `Failed to suppress slack alert. Error: ${e.message}`;
      await setError({
        message,
        reportError: e,
      });
    }
  }, [lines, setError, suppressDays, because, resetData, onClose]);
  const ourOnClose = useCallback(() => {
    if (!suppressing) {
      onClose();
    }
  }, [onClose, suppressing]);
  return (
    <Modal show onHide={ourOnClose} className="invalidRotatationReportBulkSuppressModal">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Suppress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You're about to suppress warnings for all ISCIs currently listed on this page.{" "}
          {isFiltered ? (
            "Since you have used the filter bar, this will only apply to the lines that haven't been filtered out."
          ) : (
            <strong>
              You have not filtered any lines. Using the filter bar is <em>strongly</em> encouraged.
            </strong>
          )}
        </p>
        <p>
          Please be careful when using this feature. You do not want to accidentally suppress
          warnings for actually problematic rotations.
        </p>
        <div className="suppressBox">
          <div className="suppressDays">
            Suppress {lines.length} slack alert{lines.length === 1 ? "" : "s"} for{" "}
            <Form.Control
              className="suppressDaysControl"
              size="sm"
              type="number"
              min={0}
              value={suppressDays}
              onChange={e => setSuppressDays(e.target.value)}
            />{" "}
            day{`${suppressDays}` === "1" ? "" : "s"} (until{" "}
            {R.pipe(Dfns.addDays(suppressDays), Dfns.format(PRETTY_DATE_FORMAT))(new Date())})
            because
          </div>
          <Form.Control
            className="becauseInput"
            as="textarea"
            rows="3"
            value={because}
            onChange={e => setBecause(e.target.value)}
          />
          <Button
            block
            variant="warning"
            disabled={suppressing}
            onClick={() => {
              if (confirmingSuppress) {
                suppress();
              } else {
                setConfirmingSuppress(true);
              }
            }}
          >
            {confirmingSuppress ? "Yes, I'm Sure" : "Suppress Slack Alerts"}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ourOnClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
