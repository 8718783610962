import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import * as R from "ramda";

import { navigate } from "@reach/router";

import { useStateFunction } from "../utils/hooks/useData";
import {
  OldFilterBar,
  Img,
  FullPageSpinner,
  CheckBox,
  ColorPicker,
  BPMButton,
  BPMTable,
  Spinner,
  InfoTooltip,
} from "../Components";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { Setter, StateSetter } from "../utils/types";
import { Form, Col, ListGroup, Table, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { MdAdd, MdDeleteForever, MdSave } from "react-icons/md";
import AddNewNetwork from "./AddNewNetwork";
import "./StreamingNetworks.scss";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";
import { StreamingNetworksContext } from "./StreamingNetworks";
import {
  ContentMetaData,
  NetworkInfo,
  OldNetworkInfo,
  Contact,
  AttributeInfo,
} from "@blisspointmedia/bpm-types/dist/StreamingNetworks";

const DEFAULT_TERMS = `Pre-Roll and Mid-Roll only for delivery.
Post Roll approved for bonus only, no impressions counted.
Impressions to run evenly over flight period.
BPM will only pay for video impressions in player sizes 400x300 pixels or greater (this is the minimum in-stream player size as indicated by the IAB).
Viewability will be a metric that will be monitored closely by Extreme Reach (3rd Party Verification).
ER's definition of viewability is in line with the industry standard of two consecutive seconds, with at least 50% of the player's pixels in view of the consumer's browser.
We expect the content following your ad to be user-initiated or the main focus of the consumer visiting the page.
Failure to properly implement tags/pixels as requested may result in significant delays in reconciliation and remittance of payment.)
Billing will be based on Extreme Reach numbers.
All purchased media is governed by the IAB Standard Terms and Conditions 3.0 outlined in Section III (c).
Excludes all YouTube inventory whether direct or cross sell.
Bliss Point will not be billed for GIVT impressions above 5%.
If there is under-delivery or over-delivery in one flight, it will not automatically be carried over to the next flight.
New: Bliss Point Media will not be held financially responsible for any delivery that has occurred that does not include the correct and mandatory implementation of all required advertising assets including any creatives, tags, or pixels.`;

const INFO_KEY = "INFO_KEY" as const;
const CONTACTS_KEY = "CONTACTS_KEY" as const;
const GLOBAL_CONTENT_KEY = "GLOBAL_CONTENT_KEY" as const;
const CONTENT_DETAILS_KEY = "CONTENT_DETAILS_KEY" as const;
const GLOBAL_DESCRIPTIONS_KEY = "GLOBAL_DESCRIPTIONS_KEY" as const;
const PROPERTIES_KEY = "PROPERTIES_KEY" as const;
const ATTRIBUTES_KEY = "METADATA_KEY" as const;

const FILTER_BAR_OPTIONS = [
  { name: "shortCode", label: "Short Code" },
  { name: "name", label: "Name" },
  { name: "networkGroup", label: "Network Group" },
];

interface SelectObject {
  label: string;
  value: string;
}

const MULTI_SELECT_ATTRIBUTE = "multi_select";
const BOOLEAN_ATTRIBUTE = "boolean";

const toPrettySpend = num =>
  parseFloat(num || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
const remainingDspOptions = (selectedDSPs, globalOptions: Record<string, string>[]) => {
  let remainingOptions = R.filter(option => !R.includes(option.label, selectedDSPs), globalOptions);

  return remainingOptions;
};

const SanitizingTextArea = ({ onChange, value, ...props }) => (
  <Form.Control
    as="textarea"
    {...props}
    value={value}
    onBlur={() => {
      onChange((value || "").replace(/[^\S\r\n]/g, " "));
    }}
    onChange={e => onChange(e.target.value)}
  />
);

type Tab =
  | typeof INFO_KEY
  | typeof CONTACTS_KEY
  | typeof GLOBAL_CONTENT_KEY
  | typeof CONTENT_DETAILS_KEY
  | typeof GLOBAL_DESCRIPTIONS_KEY
  | typeof PROPERTIES_KEY
  | typeof ATTRIBUTES_KEY;

interface NetworkViewProps {
  networkInfo: NetworkInfo;
  setNetworkList: Setter<NetworkInfo[] | undefined>;
  currentTab: Tab;
  setCurrentTab: Setter<Tab>;
  dspOptions: Record<string, string>[];
  shouldEnableStreamingNetworksUpdates: boolean;
}
// Side Pane Container
const NetworkView: React.FC<NetworkViewProps> = ({
  networkInfo,
  setNetworkList,
  currentTab,
  setCurrentTab,
  dspOptions,
  shouldEnableStreamingNetworksUpdates,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const [editing, setEditing] = useState(false);
  const [localChanges, setLocalChanges] = useState<Partial<NetworkInfo>>({});
  const [saving, setSaving] = useState(false);

  const discardChanges = useCallback(() => setLocalChanges({}), []);
  const onSave = useCallback(
    async (combinedData: Partial<NetworkInfo>) => {
      for (let property of combinedData.properties || []) {
        if (property.isNew && property.propertyShortCode.length > 10) {
          try {
            await setAreYouSure({
              title: "Long Short Code",
              message: `The short code "${property.propertyShortCode}" is very long. You should consider using an abbreviation or some other way of shortening it (unless any other name would be overly confusing). Are you sure you want to create this network with this short code?`,
              okayText: "Create network",
              cancelText: "Change the short code",
            });
          } catch (e) {
            return;
          }
        }
      }
      try {
        setSaving(true);
        await StreamingV2LambdaFetch("/update_streaming_networks", {
          method: "POST",
          body: {
            combinedData,
          },
        });
        setSaving(false);
        setEditing(false);
        discardChanges();
        setNetworkList(undefined);
      } catch (e) {
        const reportError = e as Error;
        setSaving(false);
        setError({ message: reportError.message, reportError });
      }
    },
    [setError, discardChanges, setNetworkList, setAreYouSure]
  );

  const combinedData = useMemo(() => ({ ...networkInfo, ...localChanges }), [
    networkInfo,
    localChanges,
  ]);

  let tab: React.ReactNode;
  switch (currentTab) {
    case CONTACTS_KEY:
      tab = (
        <ContactsTab
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    case GLOBAL_CONTENT_KEY:
      tab = (
        <GlobalContentTab
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    case CONTENT_DETAILS_KEY:
      tab = <ContentDetailsTab networkInfo={networkInfo} />;
      break;
    case PROPERTIES_KEY:
      tab = (
        <PropertiesTab
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    default:
      tab = (
        <InfoTab
          editing={editing}
          localChanges={localChanges}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
          dspOptions={dspOptions}
          shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
        />
      );
      break;
  }

  return (
    <div className={`streamingNetworkView${saving ? " saving" : ""}`}>
      <div className="topHeader">
        <div className="menuButtons">
          <BPMButton
            variant={currentTab === INFO_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(INFO_KEY)}
            size="sm"
          >
            General
          </BPMButton>
          <BPMButton
            variant={currentTab === CONTACTS_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(CONTACTS_KEY)}
            size="sm"
          >
            Contacts
          </BPMButton>
          <BPMButton
            variant={currentTab === GLOBAL_CONTENT_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(GLOBAL_CONTENT_KEY)}
            size="sm"
          >
            Content
          </BPMButton>
          <BPMButton
            variant={currentTab === CONTENT_DETAILS_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(CONTENT_DETAILS_KEY)}
            size="sm"
          >
            Content Details
          </BPMButton>
          <BPMButton
            variant={currentTab === PROPERTIES_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(PROPERTIES_KEY)}
            size="sm"
          >
            Properties
          </BPMButton>
        </div>

        {currentTab !== CONTENT_DETAILS_KEY && (
          <div className="editButtons">
            <Button
              variant={editing ? "dark" : "primary"}
              onClick={() => {
                if (editing) {
                  discardChanges();
                }
                setEditing(!editing);
              }}
            >
              {editing ? "Cancel" : "Edit"}
            </Button>
            <div>
              {editing && (
                <div>
                  <Button onClick={() => onSave(combinedData)} disabled={saving}>
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="networkContainer">{tab}</div>
      <div className="stripe" style={{ backgroundColor: networkInfo.color }} />
    </div>
  );
};

interface OldNetworkViewProps {
  networkInfo: OldNetworkInfo;
  setNetworkList: Setter<OldNetworkInfo[] | undefined>;
  currentTab: Tab;
  setCurrentTab: Setter<Tab>;
  dspOptions: Record<string, string>[];
  shouldEnableStreamingNetworksUpdates: boolean;
}
// Side Pane Container
const OldNetworkView: React.FC<OldNetworkViewProps> = ({
  networkInfo,
  setNetworkList,
  currentTab,
  setCurrentTab,
  dspOptions,
  shouldEnableStreamingNetworksUpdates,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const [editing, setEditing] = useState(false);
  const [localChanges, setLocalChanges] = useState<Partial<OldNetworkInfo>>({});
  const [saving, setSaving] = useState(false);
  const discardChanges = useCallback(() => setLocalChanges({}), []);

  const onSave = useCallback(
    async (combinedData: Partial<OldNetworkInfo>) => {
      for (let property of combinedData.properties || []) {
        if (property.isNew && property.propertyShortCode.length > 10) {
          try {
            await setAreYouSure({
              title: "Long Short Code",
              message: `The short code "${property.propertyShortCode}" is very long. You should consider using an abbreviation or some other way of shortening it (unless any other name would be overly confusing). Are you sure you want to create this network with this short code?`,
              okayText: "Create network",
              cancelText: "Change the short code",
            });
          } catch (e) {
            return;
          }
        }
      }
      try {
        setSaving(true);
        await StreamingV2LambdaFetch("/old_update_streaming_networks", {
          method: "POST",
          body: combinedData,
        });
        setSaving(false);
        setEditing(false);
        discardChanges();
        setNetworkList(undefined);
      } catch (e) {
        const reportError = e as Error;
        setSaving(false);
        setError({ message: reportError.message, reportError });
      }
    },
    [setError, discardChanges, setNetworkList, setAreYouSure]
  );

  const combinedData = useMemo(() => ({ ...networkInfo, ...localChanges }), [
    networkInfo,
    localChanges,
  ]);

  let tab: React.ReactNode;
  switch (currentTab) {
    case ATTRIBUTES_KEY:
      tab = <AttributesTab networkInfo={networkInfo} editing={editing} />;
      break;
    case CONTACTS_KEY:
      tab = (
        <ContactsTab
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    case GLOBAL_DESCRIPTIONS_KEY:
      tab = (
        <GlobalDescriptions
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    case PROPERTIES_KEY:
      tab = (
        <PropertiesTab
          editing={editing}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
        />
      );
      break;
    default:
      tab = (
        <InfoTab
          editing={editing}
          localChanges={localChanges}
          setLocalChanges={setLocalChanges}
          combinedData={combinedData}
          dspOptions={dspOptions}
          shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
        />
      );
      break;
  }

  return (
    <div className={`streamingNetworkView${saving ? " saving" : ""}`}>
      <div className="topHeader">
        <div className="menuButtons">
          <BPMButton
            variant={currentTab === INFO_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(INFO_KEY)}
            size="sm"
          >
            General
          </BPMButton>
          <BPMButton
            variant={currentTab === CONTACTS_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(CONTACTS_KEY)}
            size="sm"
          >
            Contacts
          </BPMButton>
          <BPMButton
            variant={currentTab === GLOBAL_DESCRIPTIONS_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(GLOBAL_DESCRIPTIONS_KEY)}
            size="sm"
          >
            Descriptions
          </BPMButton>
          <BPMButton
            variant={currentTab === PROPERTIES_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(PROPERTIES_KEY)}
            size="sm"
          >
            Properties
          </BPMButton>

          <BPMButton
            variant={currentTab === ATTRIBUTES_KEY ? "primary" : "outline-primary"}
            onClick={() => setCurrentTab(ATTRIBUTES_KEY)}
            size="sm"
          >
            Attributes
          </BPMButton>
        </div>

        {currentTab !== ATTRIBUTES_KEY && (
          <div className="editButtons">
            <Button
              variant={editing ? "dark" : "primary"}
              onClick={() => {
                if (editing) {
                  discardChanges();
                }
                setEditing(!editing);
              }}
            >
              {editing ? "Cancel" : "Edit"}
            </Button>
            <div>
              {editing && (
                <div>
                  <Button onClick={() => onSave(combinedData)} disabled={saving}>
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="networkContainer">{tab}</div>
      <div className="stripe" style={{ backgroundColor: networkInfo.color }} />
    </div>
  );
};

interface AttributesTabProps {
  networkInfo: OldNetworkInfo;
  editing: boolean;
}
// Attributes Tab
const AttributesTab: React.FC<AttributesTabProps> = ({ networkInfo, editing }) => {
  const { attributes } = networkInfo;
  const [selectedRow, setSelectedRow] = useState<string>();
  const [localChanges, setLocalChanges] = useState<Record<string, AttributeInfo>>({});
  const [dropdownSelect, setDropdownSelect] = useState<Record<string, string>>();
  const [globalAttributeOptions, setGlobalAttributeOptions] = useState<
    Record<string, AttributeInfo>
  >();
  const [newRow, setNewRow] = useState<TableRow>();
  const [saving, setSaving] = useState(false);
  const setError = useSetError();
  // Get all attribute options
  useEffect(() => {
    if (!globalAttributeOptions) {
      (async () => {
        let res = await StreamingV2LambdaFetch("/get_global_streaming_network_properties");
        let data = await awaitJSON(res);
        setGlobalAttributeOptions(data);
      })();
    }
  }, [globalAttributeOptions]);
  // Attribute data for selected Description/Platform combo
  const selectedRowData = useMemo(() => {
    if (attributes && selectedRow && attributes[selectedRow]) {
      return attributes[selectedRow];
    }
  }, [attributes, selectedRow]);
  const combinedData = useMemo(() => ({ ...selectedRowData, ...localChanges }), [
    selectedRowData,
    localChanges,
  ]);
  // The only dropdown options that should show are the ones that aren't already applied
  const newAttributeOptions = R.keys(globalAttributeOptions)
    .filter((key: string) => {
      return !(combinedData || {})[key];
    })
    .map((key: string) => {
      return { label: key, value: key };
    });

  interface TableRow {
    platform: string;
    description: string;
    property?: string;
    isNew?: boolean;
  }
  const tableRows = useMemo(() => {
    if (!R.isEmpty(networkInfo.properties)) {
      let tempArr: TableRow[] = [];

      for (let key of R.keys(attributes)) {
        let [platform, description, property] = key.split("_");

        tempArr.push({
          platform,
          description,
          property,
        });
      }
      tempArr = R.uniq(tempArr);
      if (newRow) {
        tempArr.push(newRow);
      }
      return tempArr;
    } else {
      let tempArr: TableRow[] = [{ platform: "", description: "" }];

      for (let key of R.keys(attributes)) {
        let [platform, description] = key.split("_");
        tempArr.push({
          platform,
          description,
        });
      }
      tempArr = R.uniq(tempArr);
      if (newRow) {
        tempArr.push(newRow);
      }
      return tempArr;
    }
  }, [attributes, newRow, networkInfo.properties]);
  const addNewAttribute = selectedValue => {
    let name = selectedValue.label;
    if (globalAttributeOptions) {
      if ((globalAttributeOptions || {})[name].type === MULTI_SELECT_ATTRIBUTE) {
        let obj = { ...(globalAttributeOptions || {})[name] };
        setLocalChanges(current => {
          return { ...current, [name]: obj };
        });
      } else if ((globalAttributeOptions || {})[name].type === BOOLEAN_ATTRIBUTE) {
        let obj = { ...(globalAttributeOptions || {})[name], value: true };
        setLocalChanges(current => {
          return { ...current, [name]: obj };
        });
      } else {
        setLocalChanges(current => {
          return { ...current, [name]: (globalAttributeOptions || {})[name] };
        });
      }
    }
  };
  const saveChanges = async () => {
    setSaving(true);

    let [platform, description, property] = (selectedRow || "").split("_");

    let network = networkInfo.shortCode;

    if (property) {
      network = property;
    }

    if (newRow) {
      ({ platform, description } = newRow);
    }

    if (newRow && newRow.property) {
      network = newRow.property;
    }
    let networkMetadata = {
      network,
      platform,
      description,
    };

    try {
      await StreamingV2LambdaFetch("/save_network_attributes", {
        method: "post",
        body: { networkInfo: networkMetadata, edits: localChanges },
      });
      setSaving(false);
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      setSaving(false);
      setError({ message: reportError.message, reportError });
    }
  };
  const tableHeaders = [
    {
      name: "platform",
      label: "Platform",
      flex: 1,
      nonInteractive: true,
      renderer: data => {
        let classes: string[] = [];

        let rowKey = data.isNew ? "new" : `${data.platform}_${data.description}`;

        if (!R.isEmpty(networkInfo.properties)) {
          rowKey = data.isNew ? "new" : `${data.platform}_${data.description}_${data.property}`;
        }

        if (rowKey === selectedRow) {
          classes.push("selectedRow");
        }

        let content: React.ReactNode = data.platform === "" ? "(default)" : data.platform;

        let dropdownOptions = networkInfo.platforms.map(platform => ({
          label: platform.platform,
          value: platform.platform,
        }));

        if (data.isNew) {
          content = (
            <Select
              isSearchable
              isClearable
              value={{ label: data.platform, value: data.platform }}
              options={dropdownOptions}
              onChange={selection =>
                setNewRow(current => ({
                  ...(current as TableRow),
                  platform: (selection || {}).value || "",
                }))
              }
              menuPortalTarget={document.body}
            />
          );
        }

        return (
          <div className={classes.join(" ")} onClick={() => setSelectedRow(rowKey)}>
            {content}
          </div>
        );
      },
    },
    {
      name: "description",
      label: "Description",
      flex: 1,
      nonInteractive: true,
      renderer: data => {
        let classes: string[] = [];

        let rowKey = data.isNew ? "new" : `${data.platform}_${data.description}`;

        if (!R.isEmpty(networkInfo.properties)) {
          rowKey = data.isNew ? "new" : `${data.platform}_${data.description}_${data.property}`;
        }

        if (rowKey === selectedRow) {
          classes.push("selectedRow");
        }
        if (rowKey === selectedRow) {
          classes.push("selectedRow");
        }

        let content: React.ReactNode = data.description === "" ? "(default)" : data.description;

        let dropdownOptions = networkInfo.globalDescriptions.map(description => ({
          label: description.descriptionName,
          value: description.descriptionName,
        }));

        if (data.isNew) {
          content = (
            <Select
              isSearchable
              isClearable
              value={{ label: data.description, value: data.description }}
              options={dropdownOptions}
              onChange={selection =>
                setNewRow(current => ({
                  ...(current as TableRow),
                  description: selection?.value || "",
                }))
              }
              menuPortalTarget={document.body}
            />
          );
        }
        return (
          <div
            className={classes.join(" ")}
            onClick={() => {
              setSelectedRow(rowKey);
              setLocalChanges({});
            }}
          >
            {content}
          </div>
        );
      },
    },
  ];
  if (!R.isEmpty(networkInfo.properties)) {
    tableHeaders.unshift({
      name: "property",
      label: "Property",
      flex: 1,
      nonInteractive: true,
      renderer: data => {
        let classes: string[] = [];

        let rowKey = data.isNew ? "new" : `${data.platform}_${data.description}_${data.property}`;

        if (rowKey === selectedRow) {
          classes.push("selectedRow");
        }

        let content: React.ReactNode = data.platform === "" ? "(default)" : data.property;

        let dropdownOptions = networkInfo.properties.map(property => ({
          label: property.propertyShortCode,
          value: property.propertyShortCode,
        }));

        if (data.isNew) {
          content = (
            <Select
              isSearchable
              isClearable
              value={{ label: data.property, value: data.property }}
              options={dropdownOptions}
              onChange={selection =>
                setNewRow(current => ({
                  ...(current as TableRow),
                  property: selection.value,
                }))
              }
              menuPortalTarget={document.body}
            />
          );
        }

        return (
          <div className={classes.join(" ")} onClick={() => setSelectedRow(rowKey)}>
            {content}
          </div>
        );
      },
    });
  }
  return (
    <div className="attributesTab">
      <div className="leftSide">
        <div className="table">
          <BPMTable data={tableRows} headerHeight={30} headers={tableHeaders} filterBar={false} />
        </div>
        {!newRow && (
          <BPMButton
            onClick={() => {
              setNewRow({ platform: "", description: "", isNew: true });
              setSelectedRow("new");
            }}
          >
            <MdAdd />
          </BPMButton>
        )}
      </div>
      <div className="rightSide">
        {globalAttributeOptions ? (
          <div className="attributesList">
            {selectedRow ? (
              R.keys(combinedData).map(attribute => {
                if (combinedData[attribute]?.type === "multi_select") {
                  let value = (combinedData[attribute]?.value as string[]).map((str: string) => {
                    return { label: str, value: str };
                  });
                  let options = (globalAttributeOptions[attribute].value as string[]).map(str => ({
                    label: str,
                    value: str,
                  }));
                  return (
                    <div className="attribute select" key={attribute}>
                      <Form.Group>
                        <Form.Label>{attribute}</Form.Label>
                        <Select
                          isMulti
                          placeholder="Select Options"
                          value={value}
                          onChange={(selection: SelectObject[] | null) => {
                            setLocalChanges(current => {
                              return {
                                ...current,
                                [attribute]: {
                                  ...(globalAttributeOptions || {})[attribute],
                                  value: R.pluck("value", selection || []),
                                },
                              };
                            });
                          }}
                          options={options}
                        />
                      </Form.Group>
                    </div>
                  );
                } else if (combinedData[attribute]?.type === "boolean") {
                  return (
                    <div className="attribute" key={attribute}>
                      <Form.Group>
                        <Form.Label>{attribute}</Form.Label>
                        <CheckBox
                          checked={
                            ((localChanges[attribute] || {}).value as boolean) ??
                            (combinedData[attribute]?.value as boolean)
                          }
                          onCheck={val => {
                            setLocalChanges(current => {
                              return {
                                ...current,
                                [attribute]: {
                                  ...localChanges[attribute],
                                  ...(globalAttributeOptions || {})[attribute],
                                  value: val,
                                },
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  );
                } else if (combinedData[attribute]?.type === "text") {
                  return (
                    <div className="attribute" key={attribute}>
                      <Form.Group>
                        <Form.Label>{attribute}</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={
                            ((localChanges[attribute] || {}).value as string) ??
                            (combinedData[attribute]?.value as string)
                          }
                          onChange={e => {
                            let val = e.target.value;
                            setLocalChanges(current => {
                              return {
                                ...current,
                                [attribute]: {
                                  ...localChanges[attribute],
                                  ...(globalAttributeOptions || {})[attribute],
                                  value: val,
                                },
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  );
                } else {
                  return (
                    <div className="attribute" key={attribute}>
                      <Form.Group>
                        <Form.Label>{attribute}</Form.Label>
                        <Form.Control
                          type="text"
                          value={
                            ((localChanges[attribute] || {}).value as string) ??
                            (combinedData[attribute]?.value as string)
                          }
                          onChange={e => {
                            let val = e.target.value;
                            setLocalChanges(current => {
                              return {
                                ...current,
                                [attribute]: {
                                  ...localChanges[attribute],
                                  ...(globalAttributeOptions || {})[attribute],
                                  value: val,
                                },
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  );
                }
              })
            ) : (
              <div className="defaultText">Select a row on the left to edit metadata</div>
            )}
          </div>
        ) : (
          <Spinner />
        )}
        {selectedRow && (
          <div className="addNewAttribute">
            <Select
              className="dropdown"
              menuPlacement="top"
              placeholder="Select Options"
              value={dropdownSelect}
              onChange={selection => setDropdownSelect(selection)}
              options={newAttributeOptions}
            />
            <BPMButton disabled={!dropdownSelect} onClick={() => addNewAttribute(dropdownSelect)}>
              <MdAdd />
            </BPMButton>
            {(!R.isEmpty(localChanges) || newRow) && (
              <>
                <BPMButton
                  onClick={() => {
                    setLocalChanges({});
                    setNewRow(undefined);
                  }}
                  variant="danger"
                  size="sm"
                >
                  <MdDeleteForever />
                </BPMButton>
                <BPMButton onClick={() => saveChanges()} variant="success" size="sm">
                  {saving ? <Spinner /> : <MdSave />}
                </BPMButton>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

interface InfoTabProps {
  editing: boolean;
  localChanges: Partial<NetworkInfo | OldNetworkInfo>;
  setLocalChanges: StateSetter<Partial<NetworkInfo | OldNetworkInfo>>;
  combinedData: NetworkInfo | OldNetworkInfo;
  dspOptions: Record<string, string>[];
  shouldEnableStreamingNetworksUpdates: boolean;
}
// General Network Info
const InfoTab: React.FC<InfoTabProps> = ({
  editing,
  localChanges,
  setLocalChanges,
  combinedData,
  dspOptions,
  shouldEnableStreamingNetworksUpdates,
}) => {
  const setError = useSetError();

  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  interface Platform {
    platform: string;
    buyable: boolean;
    originalIndex: number;
    deleted?: boolean;
    isNew?: boolean;
  }

  const indexedPlatforms = useMemo(() => {
    let platforms: Platform[] = [];

    for (let i = 0; i < (combinedData.platforms || []).length; ++i) {
      let platform = combinedData.platforms[i];
      if (!platform.deleted) {
        platforms.push({ ...platform, originalIndex: i });
      }
    }

    return platforms;
  }, [combinedData]);

  return (
    <div className="networkInfo">
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            {editing ? (
              <Form.Control
                readOnly={!editing}
                type="text"
                size="sm"
                value={combinedData.name}
                onChange={e => {
                  changeFormVal("name", e.target.value);
                }}
              />
            ) : (
              <div>{combinedData.name}</div>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Short Code</Form.Label>
            <div>{combinedData.shortCode}</div>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Network Group</Form.Label>
            {editing ? (
              <Form.Control
                readOnly={!editing}
                type="text"
                size="sm"
                value={combinedData.networkGroup}
                onChange={e => {
                  changeFormVal("networkGroup", e.target.value);
                }}
              />
            ) : (
              <div>{combinedData.networkGroup}</div>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Default CPM</Form.Label>
              {editing ? (
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="None"
                  readOnly={!editing}
                  value={combinedData.cpm || ""}
                  onChange={e => {
                    changeFormVal("cpm", e.target.value);
                  }}
                />
              ) : (
                <div>{combinedData.cpm || "None"}</div>
              )}
            </Form.Group>
            {!shouldEnableStreamingNetworksUpdates && (
              <Form.Group className="checkboxGroup">
                <div className="checkboxLabel">Requires Descriptions</div>
                <CheckBox
                  disabled={!editing}
                  checked={(combinedData as OldNetworkInfo).requiresDescriptions}
                  onCheck={() => {
                    changeFormVal(
                      "requiresDescriptions",
                      !(combinedData as OldNetworkInfo).requiresDescriptions
                    );
                  }}
                />
              </Form.Group>
            )}
            <Form.Group className="checkboxGroup">
              <div className="checkboxLabel">Buyable</div>
              <CheckBox
                disabled={!editing}
                checked={combinedData.buyable}
                onCheck={() => {
                  changeFormVal("buyable", !combinedData.buyable);
                }}
              />
            </Form.Group>
            <Form.Group className="checkboxGroup">
              <div className="checkboxLabel">Split Length</div>
              <CheckBox
                disabled={!editing}
                checked={combinedData.splitLength === null ? false : combinedData.splitLength}
                onCheck={() => {
                  changeFormVal("splitLength", !combinedData.splitLength);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Color</Form.Label>
              <ColorPicker
                type="color"
                disabled={!editing}
                color={combinedData.color}
                onChange={color => {
                  changeFormVal("color", color);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>DSPs</Form.Label>
              <div className="dspList">
                {!R.isEmpty(combinedData.dsp)
                  ? R.map(dsp => <span key={dsp}>{dsp}</span>, combinedData.dsp)
                  : "No DSPs"}
              </div>
              {editing && (
                <CreatableSelect
                  placeholder="New DSP"
                  value={null}
                  onChange={selection => {
                    changeFormVal("dsp", [...combinedData.dsp, selection.value]);
                  }}
                  options={remainingDspOptions(combinedData.dsp, dspOptions)}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>IO Minimum</Form.Label>
              {editing ? (
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="None"
                  readOnly={!editing}
                  value={combinedData.ioMinimum || ""}
                  onChange={e => {
                    changeFormVal("ioMinimum", e.target.value);
                  }}
                />
              ) : (
                <div>
                  {R.isNil(combinedData.ioMinimum) ? "None" : toPrettySpend(combinedData.ioMinimum)}
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Line Item Minimum</Form.Label>
              {editing ? (
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="None"
                  readOnly={!editing}
                  value={combinedData.lineItemMinimum || ""}
                  onChange={e => {
                    changeFormVal("lineItemMinimum", e.target.value);
                  }}
                />
              ) : (
                <div>
                  {R.isNil(combinedData.lineItemMinimum)
                    ? "None"
                    : toPrettySpend(combinedData.lineItemMinimum)}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={8}>
            <Form.Group>
              <Form.Label>Platforms</Form.Label>
              <ListGroup className="platformList">
                {indexedPlatforms
                  .filter((platform: Platform) => platform.deleted !== true)
                  .map(platform => {
                    return (
                      <ListGroup.Item className="platformListRow" key={platform.originalIndex}>
                        {platform.isNew === true ? (
                          <div className="platformListItemNew">
                            <Form.Control
                              size="sm"
                              value={platform.platform}
                              onBlur={e => {
                                const { value } = e.currentTarget;
                                if (value.toLowerCase().includes("bonus")) {
                                  setError({
                                    title: "No Bonus Platform",
                                    message:
                                      "You cannot add a 'Bonus' platform. They are added automatically.",
                                  });
                                  changeFormVal(
                                    "platforms",
                                    R.remove(platform.originalIndex, 1, combinedData.platforms)
                                  );
                                }
                              }}
                              onChange={e =>
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    platform.originalIndex,
                                    { ...platform, platform: e.target.value },
                                    combinedData.platforms
                                  )
                                )
                              }
                            />
                            <CheckBox
                              disabled={!editing}
                              checked={platform.buyable}
                              onCheck={() => {
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    platform.originalIndex,
                                    { ...platform, buyable: !platform.buyable },
                                    combinedData.platforms
                                  )
                                );
                              }}
                            />
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    platform.originalIndex,
                                    { ...platform, deleted: !platform.deleted },
                                    combinedData.platforms
                                  )
                                );
                              }}
                            >
                              <MdDeleteForever />
                            </Button>
                          </div>
                        ) : (
                          <div className="platformListItem">
                            {platform.platform}
                            <CheckBox
                              disabled={!editing}
                              checked={platform.buyable}
                              onCheck={() => {
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    platform.originalIndex,
                                    { ...platform, buyable: !platform.buyable },
                                    combinedData.platforms
                                  )
                                );
                              }}
                            />
                          </div>
                        )}
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
              {editing && (
                <Button
                  block
                  onClick={() =>
                    changeFormVal("platforms", [
                      ...combinedData.platforms,
                      { platform: "", buyable: true, isNew: true, deleted: false },
                    ])
                  }
                >
                  <MdAdd />
                </Button>
              )}
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Group>
          <Form.Label>Default PDF Text</Form.Label>
          <InfoTooltip size="sm">
            The pre-populated text in the text box when you make an IO. Typically to list things
            like frequency caps, etc.
          </InfoTooltip>
          <SanitizingTextArea
            rows="4"
            readOnly={!editing}
            value={combinedData.defaultIoPdfText || ""}
            onChange={value => {
              changeFormVal("defaultIoPdfText", value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Terms</Form.Label>
          <InfoTooltip size="sm">
            The bulleted list of terms at the end of the IO. Typically addressing higher-level items
            like billing nuances, etc.
          </InfoTooltip>
          {editing && (
            <Button
              size="sm"
              variant="link"
              className="helperButton"
              onClick={() => changeFormVal("pdfText", DEFAULT_TERMS)}
            >
              Fill with default
            </Button>
          )}
          <SanitizingTextArea
            rows="4"
            readOnly={!editing}
            value={combinedData.pdfText || ""}
            onChange={value => {
              changeFormVal("pdfText", value);
            }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

interface ContactsTabProps {
  editing: boolean;
  setLocalChanges: StateSetter<Partial<NetworkInfo>>;
  combinedData: NetworkInfo | OldNetworkInfo;
}
// Network Contacts
const ContactsTab: React.FC<ContactsTabProps> = ({ editing, setLocalChanges, combinedData }) => {
  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  let indexedContacts = R.addIndex<Contact, Contact>(R.map)(
    (obj: Contact, ungroupedIndex: number) => ({
      ...obj,
      ungroupedIndex,
    }),
    combinedData.contacts
  );
  let groupedContacts = R.groupBy(R.prop("platform"), indexedContacts);

  const groupedArray = useMemo(() => {
    let arr: { platform: string; contacts: Contact[] | [] }[] = [];

    for (let platform of combinedData.platforms) {
      if (!R.isEmpty(groupedContacts)) {
        arr.push({
          platform: platform.platform,
          contacts: groupedContacts[platform.platform] || [],
        });
      } else {
        arr.push({
          platform: platform.platform,
          contacts: [],
        });
      }
    }
    return arr;
  }, [combinedData.platforms, groupedContacts]);

  interface GroupedPlatform {
    platform: string;
    contacts: Contact[];
  }

  return (
    <div className="contactsInfo">
      {groupedArray.map((platform: GroupedPlatform) => {
        return (
          <React.Fragment key={platform.platform}>
            <div className="platformName">{platform.platform}</div>
            <Table size="sm" striped bordered key={platform.platform} className="contactsTable">
              <thead>
                <tr>
                  <th className="firstName">First</th>
                  <th className="lastName">Last</th>
                  <th className="email">Email</th>
                  <th className="primary">Primary</th>
                  {editing && <th className="delete">Delete</th>}
                </tr>
              </thead>
              <tbody>
                {platform.contacts
                  .filter(contact => contact.deleted === false)
                  .map((person, index) => {
                    return (
                      <tr key={index}>
                        <td className="firstName">
                          {editing ? (
                            <Form.Control
                              size="sm"
                              value={person.first || ""}
                              onChange={e =>
                                changeFormVal(
                                  "contacts",
                                  R.update(
                                    person.ungroupedIndex || 0,
                                    {
                                      ...person,
                                      first: e.target.value,
                                    },
                                    combinedData.contacts
                                  )
                                )
                              }
                            />
                          ) : (
                            person.first
                          )}
                        </td>
                        <td className="lastName">
                          {editing ? (
                            <Form.Control
                              size="sm"
                              value={person.last || ""}
                              onChange={e =>
                                changeFormVal(
                                  "contacts",
                                  R.update(
                                    person.ungroupedIndex || 0,
                                    { ...person, last: e.target.value },
                                    combinedData.contacts
                                  )
                                )
                              }
                            />
                          ) : (
                            person.last
                          )}
                        </td>
                        <td className="email">
                          {editing ? (
                            <Form.Control
                              size="sm"
                              value={person.email || ""}
                              onChange={e =>
                                changeFormVal(
                                  "contacts",
                                  R.update(
                                    person.ungroupedIndex || 0,
                                    {
                                      originalEmail: person.email,
                                      ...person,
                                      email: e.target.value,
                                    },
                                    combinedData.contacts
                                  )
                                )
                              }
                            />
                          ) : (
                            person.email
                          )}
                        </td>
                        <td className="primary">
                          <CheckBox
                            disabled={!editing}
                            checked={person.isPrimary}
                            onCheck={() => {
                              changeFormVal(
                                "contacts",
                                R.map(innerPerson => {
                                  if (innerPerson.email === person.email) {
                                    return { ...innerPerson, isPrimary: !innerPerson.isPrimary };
                                  } else if (
                                    innerPerson.email !== person.email &&
                                    innerPerson.platform === person.platform
                                  ) {
                                    return { ...innerPerson, isPrimary: false };
                                  } else {
                                    return innerPerson;
                                  }
                                }, combinedData.contacts)
                              );
                            }}
                          />
                        </td>
                        {editing && (
                          <td className="delete">
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                changeFormVal(
                                  "contacts",
                                  R.update(
                                    person.ungroupedIndex || 0,
                                    { ...person, deleted: !person.deleted },
                                    combinedData.contacts
                                  )
                                );
                              }}
                            >
                              <MdDeleteForever />
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {editing && (
              <Button
                block
                onClick={() =>
                  changeFormVal("contacts", [
                    ...combinedData.contacts,
                    {
                      first: "",
                      last: "",
                      email: "",
                      phone: "",
                      isPrimary: false,
                      platform: platform.platform,
                      deleted: false,
                    },
                  ])
                }
              >
                <MdAdd />
              </Button>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

// Global Content Tab
const GlobalContentTab = ({ combinedData, setLocalChanges, editing }) => {
  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  const indexedContent = useMemo(() => {
    let content: { contentValue: string; deleted: boolean; originalIndex: number }[] = [];

    for (let i = 0; i < (combinedData.globalContent || []).length; ++i) {
      let contentValue = combinedData.globalContent[i];
      if (!contentValue.deleted) {
        content.push({ ...contentValue, originalIndex: i });
      }
    }

    return content;
  }, [combinedData]);

  return (
    <Form.Group>
      <ListGroup className="globalDescriptionsList">
        {R.isEmpty(combinedData.globalContent) ? (
          <div>No Global Content</div>
        ) : (
          indexedContent.map(content => {
            return (
              <ListGroup.Item key={content.originalIndex}>
                {editing ? (
                  <div className="globalDescriptionRow">
                    <Form.Control
                      size="sm"
                      disabled={!editing}
                      value={content.contentValue}
                      onChange={e =>
                        changeFormVal(
                          "globalContent",
                          R.update(
                            content.originalIndex,
                            {
                              originalContentValue: content.contentValue,
                              ...content,
                              contentValue: e.target.value,
                            },
                            combinedData.globalContent
                          )
                        )
                      }
                    />
                    <div className="deleteDescription">
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          changeFormVal(
                            "globalContent",
                            R.update(
                              content.originalIndex,
                              { ...content, deleted: !content.deleted },
                              combinedData.globalContent
                            )
                          );
                        }}
                      >
                        <MdDeleteForever />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>{content.contentValue}</div>
                )}
              </ListGroup.Item>
            );
          })
        )}
      </ListGroup>
      {editing && (
        <Button
          block
          onClick={() =>
            changeFormVal("globalContent", [
              ...combinedData.globalContent,
              { contentValue: "", deleted: false },
            ])
          }
        >
          <MdAdd />
        </Button>
      )}
    </Form.Group>
  );
};

interface ContentDetailsTabProps {
  networkInfo: NetworkInfo;
}
// Content Details Tab
const ContentDetailsTab: React.FC<ContentDetailsTabProps> = ({ networkInfo }) => {
  const FORMAT_MAP = {
    olv: "OLV",
    fep: "FEP",
    music: "Music",
    none: "None",
  };
  const FORMAT_OPTIONS = Object.entries(FORMAT_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const GENRE_MAP = {
    none: "None",
  };
  const GENRE_OPTIONS = Object.entries(GENRE_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const PUBLISHER_GENRE_MAP = {
    audience: "Audience",
    gaming: "Gaming",
    openmarket: "Openmarket",
    talk: "Talk",
    documentary: "Documentary",
    business_finance: "Business/Finance",
    family_kids: "Family/Kids",
    hispanic: "Hispanic",
    generic_audio: "Generic Audio",
    news: "News",
    music: "Music",
    sports: "Sports",
    entertainment: "Entertainment",
    generic_video: "Generic Video",
    lifestyle_food: "Lifestyle Food",
    crime: "Crime",
    other: "Other",
    technology: "Technology",
    none: "None",
  };
  const PUBLISHER_GENRE_OPTIONS = Object.entries(PUBLISHER_GENRE_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const PLACEMENT_GENRE_MAP = {
    animation: "Animation",
    health_wellness: "HealthWellness",
    technology: "Technology",
    comedy: "Comedy",
    documentary: "Documentary",
    pop_culture: "PopCulture",
    crime: "Crime",
    audience: "Audience",
    sports: "Sports",
    entertainment: "Entertainment",
    lifestyle_food: "Lifestyle/Food",
    reality: "Reality",
    drama: "Drama",
    business_finance: "Business/Finance",
    family_kids: "Family/Kids",
    action: "Action",
    music: "Music",
    none: "None",
  };
  const PLACEMENT_GENRE_OPTIONS = Object.entries(PLACEMENT_GENRE_MAP).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  const FEP_OLV_OPTIONS = [
    { label: "SFV", value: "SFV" },
    { label: "FEP", value: "FEP" },
    { label: "AUD", value: "AUD" },
    { label: "OLV", value: "OLV" },
    { label: "NONE", value: "NONE" },
  ];

  const { contentMetaDataMap } = networkInfo;
  const [selectedRow, setSelectedRow] = useState<string>();
  const [localChanges, setLocalChanges] = useState<Partial<ContentMetaData>>({});
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  const setError = useSetError();

  const selectedRowData = useMemo(() => {
    if (contentMetaDataMap && selectedRow && contentMetaDataMap[selectedRow]) {
      return contentMetaDataMap[selectedRow];
    }
  }, [contentMetaDataMap, selectedRow]);

  const combinedData = useMemo(() => ({ ...selectedRowData, ...localChanges }), [
    selectedRowData,
    localChanges,
  ]);

  const tableRows = useMemo(() => {
    let tempArr: {
      content: string;
    }[] = [];

    for (let key of R.keys(contentMetaDataMap)) {
      tempArr.push({
        content: key as string,
      });
    }
    tempArr = R.uniq(tempArr);
    return tempArr;
  }, [contentMetaDataMap]);

  const saveChanges = async () => {
    setSaving(true);

    try {
      await StreamingV2LambdaFetch("/update_network_content_meta_data", {
        method: "post",
        body: {
          network: networkInfo.shortCode,
          content: selectedRow,
          combinedData: {
            network: networkInfo.shortCode,
            content: selectedRow,
            genre: combinedData.genre,
            publisherGenre: combinedData.publisherGenre,
            placementGenre: combinedData.placementGenre,
            format: combinedData.format,
            pmp: combinedData.pmp,
            guaranteed: combinedData.guaranteed,
            fepOlv: combinedData.fepOlv,
            enabled: combinedData.enabled,
          },
        },
      });
      setSaving(false);
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      setSaving(false);
      setError({ message: reportError.message, reportError });
    }
  };

  const tableHeaders = [
    {
      name: "content",
      label: "Content",
      flex: 1,
      nonInteractive: true,
      renderer: data => {
        let classes: string[] = [];

        let rowKey = `${data.content}`;

        if (rowKey === selectedRow) {
          classes.push("selectedRow");
        }

        let contentReactNode: React.ReactNode = data.content;

        return (
          <div
            className={classes.join(" ")}
            onClick={() => {
              setSelectedRow(rowKey);
              setEditing(false);
              setLocalChanges({});
            }}
          >
            {contentReactNode}
          </div>
        );
      },
    },
  ];

  return (
    <div className="contentDetailsTab">
      <div className="leftSide">
        <div className="table">
          <BPMTable data={tableRows} headerHeight={30} headers={tableHeaders} filterBar={false} />
        </div>
      </div>
      <div className="rightSide">
        <div className="attributesList">
          {selectedRowData ? (
            <div>
              <Form.Group>
                <Form.Label>Enabled</Form.Label>
                <Select
                  value={
                    combinedData.enabled
                      ? { label: "True", value: true }
                      : { label: "False", value: false }
                  }
                  options={[
                    { label: "True", value: true },
                    { label: "False", value: false },
                  ]}
                  onChange={selection => {
                    if (selection === null && selectedRowData.enabled !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        enabled: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.enabled === null) ||
                      selection.value === selectedRowData.enabled
                    ) {
                      const { enabled, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.enabled) {
                      setLocalChanges(existing => ({
                        ...existing,
                        enabled: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Genre</Form.Label>
                <Select
                  value={
                    combinedData.genre && combinedData.genre !== null
                      ? {
                          label: GENRE_MAP[combinedData.genre],
                          value: combinedData.genre,
                        }
                      : null
                  }
                  options={GENRE_OPTIONS}
                  onChange={selection => {
                    if (selection === null && selectedRowData.genre !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        genre: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.genre === null) ||
                      selection.value === selectedRowData.genre
                    ) {
                      const { genre, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.genre) {
                      setLocalChanges(existing => ({
                        ...existing,
                        genre: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Publisher Genre</Form.Label>
                <Select
                  isMulti={true}
                  value={
                    combinedData.publisherGenre && combinedData.publisherGenre !== null
                      ? combinedData.publisherGenre
                          .split(";")
                          .filter(item => item)
                          .map(item => {
                            return { label: PUBLISHER_GENRE_MAP[item], value: item };
                          })
                      : null
                  }
                  options={PUBLISHER_GENRE_OPTIONS}
                  onChange={selection => {
                    if (selection !== null) {
                      if (selection.length > 0) {
                        let newValue = "";
                        for (let i = 0; i < selection.length; i++) {
                          if (i !== 0) {
                            newValue = newValue.concat(";");
                          }
                          newValue = newValue.concat(selection[i].value);
                        }
                        newValue = newValue.split(";").sort().join(";");

                        if (newValue !== selectedRowData.publisherGenre) {
                          setLocalChanges(existing => ({
                            ...existing,
                            publisherGenre: newValue,
                          }));
                        }
                      } else if (
                        selection.length === 0 &&
                        selectedRowData.publisherGenre !== null
                      ) {
                        setLocalChanges(existing => ({
                          ...existing,
                          publisherGenre: null,
                        }));
                      } else if (
                        selection.length === 0 &&
                        selectedRowData.publisherGenre === null
                      ) {
                        const { publisherGenre, ...rest } = localChanges;
                        setLocalChanges(rest);
                      }
                    } else if (selectedRowData.publisherGenre !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        publisherGenre: null,
                      }));
                    } else {
                      const { publisherGenre, ...rest } = localChanges;
                      setLocalChanges(rest);
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Placement Genre</Form.Label>
                <Select
                  value={
                    combinedData.placementGenre && combinedData.placementGenre !== null
                      ? {
                          label: PLACEMENT_GENRE_MAP[combinedData.placementGenre],
                          value: combinedData.placementGenre,
                        }
                      : null
                  }
                  options={PLACEMENT_GENRE_OPTIONS}
                  onChange={selection => {
                    if (selection === null && selectedRowData.placementGenre !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        placementGenre: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.placementGenre === null) ||
                      selection.value === selectedRowData.placementGenre
                    ) {
                      const { placementGenre, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.placementGenre) {
                      setLocalChanges(existing => ({
                        ...existing,
                        placementGenre: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Format</Form.Label>
                <Select
                  value={
                    combinedData.format && combinedData.format !== null
                      ? {
                          label: FORMAT_MAP[combinedData.format],
                          value: combinedData.format,
                        }
                      : null
                  }
                  options={FORMAT_OPTIONS}
                  onChange={selection => {
                    if (selection === null && selectedRowData.format !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        format: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.format === null) ||
                      selection.value === selectedRowData.format
                    ) {
                      const { format, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.format) {
                      setLocalChanges(existing => ({
                        ...existing,
                        format: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>PMP</Form.Label>
                <Select
                  value={
                    combinedData.pmp !== null
                      ? {
                          label: combinedData.pmp ? "True" : "False",
                          value: combinedData.pmp,
                        }
                      : null
                  }
                  options={[
                    { label: "True", value: true },
                    { label: "False", value: false },
                  ]}
                  onChange={selection => {
                    if (selection === null && selectedRowData.pmp !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        pmp: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.pmp === null) ||
                      selection.value === selectedRowData.pmp
                    ) {
                      const { pmp, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.pmp) {
                      setLocalChanges(existing => ({
                        ...existing,
                        pmp: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Guaranteed</Form.Label>
                <Select
                  value={
                    combinedData.guaranteed !== null
                      ? {
                          label: combinedData.guaranteed ? "True" : "False",
                          value: combinedData.guaranteed,
                        }
                      : null
                  }
                  options={[
                    { label: "True", value: true },
                    { label: "False", value: false },
                  ]}
                  onChange={selection => {
                    if (selection === null && selectedRowData.guaranteed !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        guaranteed: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.guaranteed === null) ||
                      selection.value === selectedRowData.guaranteed
                    ) {
                      const { guaranteed, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.guaranteed) {
                      setLocalChanges(existing => ({
                        ...existing,
                        guaranteed: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>FEP/OLV</Form.Label>
                <Select
                  value={
                    combinedData.fepOlv !== null
                      ? { label: combinedData.fepOlv, value: combinedData.fepOlv }
                      : null
                  }
                  options={FEP_OLV_OPTIONS}
                  onChange={selection => {
                    if (selection === null && selectedRowData.fepOlv !== null) {
                      setLocalChanges(existing => ({
                        ...existing,
                        fepOlv: null,
                      }));
                    } else if (
                      (selection === null && selectedRowData.fepOlv === null) ||
                      selection.value === selectedRowData.fepOlv
                    ) {
                      const { fepOlv, ...rest } = localChanges;
                      setLocalChanges(rest);
                    } else if (selection.value !== selectedRowData.fepOlv) {
                      setLocalChanges(existing => ({
                        ...existing,
                        fepOlv: selection.value,
                      }));
                    }
                  }}
                  isDisabled={!editing}
                  isClearable
                  placeholder="Not set"
                />
              </Form.Group>
              <div className="editButtons">
                <Button
                  variant={editing ? "dark" : "primary"}
                  onClick={() => {
                    if (editing) {
                      setLocalChanges({});
                    }
                    setEditing(!editing);
                  }}
                >
                  {editing ? "Cancel" : "Edit"}
                </Button>
                <div>
                  {editing && (
                    <div>
                      <Button
                        onClick={() => saveChanges()}
                        disabled={saving || Object.keys(localChanges).length === 0}
                      >
                        {saving ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="defaultText">Select a row on the left to edit metadata</div>
          )}
        </div>
      </div>
    </div>
  );
};

// Global Descriptions
const GlobalDescriptions = ({ combinedData, setLocalChanges, editing }) => {
  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  const indexedDescriptions = useMemo(() => {
    let descriptions: { descriptionName: string; deleted: boolean; originalIndex: number }[] = [];

    for (let i = 0; i < (combinedData.globalDescriptions || []).length; ++i) {
      let description = combinedData.globalDescriptions[i];
      if (!description.deleted) {
        descriptions.push({ ...description, originalIndex: i });
      }
    }

    return descriptions;
  }, [combinedData]);

  return (
    <Form.Group>
      <ListGroup className="globalDescriptionsList">
        {R.isEmpty(combinedData.globalDescriptions) ? (
          <div>No Global Descriptions</div>
        ) : (
          indexedDescriptions.map(description => {
            return (
              <ListGroup.Item key={description.originalIndex}>
                {editing ? (
                  <div className="globalDescriptionRow">
                    <Form.Control
                      size="sm"
                      disabled={!editing}
                      value={description.descriptionName}
                      onChange={e =>
                        changeFormVal(
                          "globalDescriptions",
                          R.update(
                            description.originalIndex,
                            {
                              originalDescription: description.descriptionName,
                              ...description,
                              descriptionName: e.target.value,
                            },
                            combinedData.globalDescriptions
                          )
                        )
                      }
                    />
                    <div className="deleteDescription">
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          changeFormVal(
                            "globalDescriptions",
                            R.update(
                              description.originalIndex,
                              { ...description, deleted: !description.deleted },
                              combinedData.globalDescriptions
                            )
                          );
                        }}
                      >
                        <MdDeleteForever />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>{description.descriptionName}</div>
                )}
              </ListGroup.Item>
            );
          })
        )}
      </ListGroup>
      {editing && (
        <Button
          block
          onClick={() =>
            changeFormVal("globalDescriptions", [
              ...combinedData.globalDescriptions,
              { descriptionName: "", deleted: false },
            ])
          }
        >
          <MdAdd />
        </Button>
      )}
    </Form.Group>
  );
};

// Sub-Properties
const PropertiesTab = ({ combinedData, setLocalChanges, editing }) => {
  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  return (
    <div className="propertiesInfo">
      {R.isEmpty(combinedData.properties) ? (
        <div>
          There are currently no sub-properties for this network. <br />
          By adding one here, you're going to make this network work like Discovery.
        </div>
      ) : (
        <Table size="sm" striped bordered className="propertiesTable">
          <thead>
            <tr>
              <th>Short Code</th>
              <th>Name</th>
              <th>Buyable</th>
            </tr>
          </thead>
          <tbody>
            {combinedData.properties.map((property, i) => {
              return (
                <tr key={i}>
                  <td className="propertyShortCode">
                    {property.isNew ? (
                      <Form.Control
                        size="sm"
                        value={property.propertyShortCode}
                        onChange={e =>
                          changeFormVal(
                            "properties",
                            R.update(
                              i,
                              { ...property, propertyShortCode: e.target.value },
                              combinedData.properties
                            )
                          )
                        }
                        onBlur={() =>
                          changeFormVal(
                            "properties",
                            R.update(
                              i,
                              {
                                ...property,
                                propertyShortCode: property.propertyShortCode.toUpperCase(),
                              },
                              combinedData.properties
                            )
                          )
                        }
                      />
                    ) : (
                      property.propertyShortCode
                    )}
                  </td>
                  <td className="propertyName">
                    {property.isNew ? (
                      <Form.Control
                        size="sm"
                        value={property.propertyName}
                        onChange={e =>
                          changeFormVal(
                            "properties",
                            R.update(
                              i,
                              { ...property, propertyName: e.target.value },
                              combinedData.properties
                            )
                          )
                        }
                      />
                    ) : (
                      property.propertyName
                    )}
                  </td>
                  <td className="propertyBuyable">
                    <CheckBox
                      disabled={!editing}
                      checked={property.buyable}
                      onCheck={() => {
                        changeFormVal(
                          "properties",
                          R.update(
                            i,
                            { ...property, buyable: !property.buyable },
                            combinedData.properties
                          )
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      {editing && (
        <Button
          block
          onClick={() =>
            changeFormVal("properties", [
              ...combinedData.properties,
              {
                propertyShortCode: "",
                propertyName: "",
                propertyNetworkGroup: combinedData.networkGroup,
                buyable: true,
                isNew: true,
              },
            ])
          }
        >
          <MdAdd />
        </Button>
      )}
    </div>
  );
};

interface StreamingNetworkMenuProps {
  network: string;
  navigate: typeof navigate;
}

const StreamingNetworkMenu = ({ network, navigate }: StreamingNetworkMenuProps): JSX.Element => {
  const getIsMounted = useIsMounted();

  const { shouldEnableStreamingNetworksUpdates } = useContext(StreamingNetworksContext);
  const setError = useSetError();
  const [networkList, setNetworkList] = useState<NetworkInfo[]>();
  const [oldNetworkList, setOldNetworkList] = useState<OldNetworkInfo[]>();
  const [currentTab, setCurrentTab] = useState<Tab>(INFO_KEY);
  const [addNetworkModal, setAddNetworkModal] = useState(false);

  useEffect(() => {
    if (!networkList && shouldEnableStreamingNetworksUpdates) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/streaming_networks");
          let data = await awaitJSON(res);
          if (getIsMounted()) {
            setNetworkList(data);
          }
        } catch (e) {
          const reportError = e as Error;
          setError({ message: reportError.message, reportError });
        }
      })();
    }
  }, [networkList, setError, getIsMounted, shouldEnableStreamingNetworksUpdates]);

  useEffect(() => {
    if (!oldNetworkList && !shouldEnableStreamingNetworksUpdates) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/old_streaming_networks");
          let data = await awaitJSON(res);
          if (getIsMounted()) {
            setOldNetworkList(data);
          }
        } catch (e) {
          const reportError = e as Error;
          setError({ message: reportError.message, reportError });
        }
      })();
    }
  }, [getIsMounted, oldNetworkList, setError, shouldEnableStreamingNetworksUpdates]);

  // Filter Bar
  const filterBarLines = useMemo(() => {
    return shouldEnableStreamingNetworksUpdates
      ? (networkList || []).map(network => ({
          name: network.shortCode,
          shortCode: network.shortCode,
          networkGroup: network.networkGroup,
        }))
      : (oldNetworkList || []).map(network => ({
          name: network.shortCode,
          shortCode: network.shortCode,
          networkGroup: network.networkGroup,
        }));
  }, [networkList, oldNetworkList, shouldEnableStreamingNetworksUpdates]);
  const [filter, setFilter] = useStateFunction<(line: typeof filterBarLines[number]) => boolean>(
    () => true
  );

  const filteredNetworkList = useMemo(() => {
    if (networkList && shouldEnableStreamingNetworksUpdates) {
      return R.pipe<NetworkInfo[], NetworkInfo[], NetworkInfo[]>(
        R.filter(filter),
        R.sortBy(R.prop("shortCode"))
      )(networkList);
    } else if (oldNetworkList && !shouldEnableStreamingNetworksUpdates) {
      return R.pipe<OldNetworkInfo[], OldNetworkInfo[], OldNetworkInfo[]>(
        R.filter(filter),
        R.sortBy(R.prop("shortCode"))
      )(oldNetworkList);
    } else {
      return [];
    }
  }, [networkList, shouldEnableStreamingNetworksUpdates, oldNetworkList, filter]);

  const networkInfo = useMemo(() => {
    if (networkList && shouldEnableStreamingNetworksUpdates) {
      for (let item of networkList) {
        if (item.shortCode === network.toUpperCase()) {
          return item;
        }
      }
    } else if (oldNetworkList && !shouldEnableStreamingNetworksUpdates) {
      for (let item of oldNetworkList) {
        if (item.shortCode === network.toUpperCase()) {
          return item;
        }
      }
    }
  }, [shouldEnableStreamingNetworksUpdates, networkList, network, oldNetworkList]);

  const [dspOptions, setDspOptions] = useState([]);

  useEffect(() => {
    if (!dspOptions.length) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/getDspOptions");
          let options = await awaitJSON(res);
          setDspOptions(
            options.map(dsp => {
              return { label: dsp, value: dsp };
            })
          );
        } catch (e) {
          const reportError = e as Error;
          setError({ message: reportError.message, reportError });
        }
      })();
    }
  }, [dspOptions, setDspOptions, setError]);

  const DisplayedNetworkView = useMemo(() => {
    if (networkInfo && networkList && shouldEnableStreamingNetworksUpdates) {
      return (
        <NetworkView
          networkInfo={networkInfo as NetworkInfo}
          key={network}
          setNetworkList={setNetworkList}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          dspOptions={dspOptions}
          shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
        />
      );
    } else if (networkInfo && oldNetworkList && !shouldEnableStreamingNetworksUpdates) {
      return (
        <OldNetworkView
          networkInfo={networkInfo as OldNetworkInfo}
          key={network}
          setNetworkList={setOldNetworkList}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          dspOptions={dspOptions}
          shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
        />
      );
    } else {
      return <></>;
    }
  }, [
    currentTab,
    dspOptions,
    network,
    networkInfo,
    networkList,
    oldNetworkList,
    shouldEnableStreamingNetworksUpdates,
  ]);

  return networkList || oldNetworkList ? (
    <div className="streamingNetworkListContainer">
      <div className="filterBarContainer">
        <div className="addNetworkButton">
          <Button onClick={() => setAddNetworkModal(true)}>
            <MdAdd />
          </Button>
        </div>
        <OldFilterBar options={FILTER_BAR_OPTIONS} lines={filterBarLines} onFilter={setFilter} />
      </div>

      <div className="contentContainer">
        <div className={`streamingNetworkList${networkInfo ? " expanded" : ""}`}>
          {filteredNetworkList.map(({ shortCode, name, color }) => (
            <div
              key={shortCode}
              style={{ borderColor: color }}
              className={`networkRow${shortCode === network ? " expanded" : ""}`}
              onClick={() => {
                if (!networkInfo) {
                  navigate(`./${shortCode}`);
                } else if (network === shortCode) {
                  navigate("../");
                } else {
                  navigate(`../${shortCode}`);
                  setCurrentTab(INFO_KEY);
                }
              }}
            >
              <div className="networkLogo">
                <Img
                  alt={shortCode}
                  src={`https://cdn.blisspointmedia.com/networks/${shortCode}.png`}
                />
              </div>

              {!networkInfo && (
                <div className="networkName">
                  {name} <span className="networkShortCode">{shortCode}</span>
                </div>
              )}

              <div className="stripe" style={{ backgroundColor: color }} />
            </div>
          ))}
        </div>
        <div className={`networkViewContainer${networkInfo ? " expanded" : ""}`}>
          {DisplayedNetworkView}
        </div>
      </div>
      {addNetworkModal && (
        <Modal size="lg" show onHide={() => setAddNetworkModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Streaming Network</Modal.Title>
          </Modal.Header>
          <AddNewNetwork
            setNetworkList={
              shouldEnableStreamingNetworksUpdates ? setNetworkList : setOldNetworkList
            }
            setAddNetworkModal={setAddNetworkModal}
            dspOptions={dspOptions}
            shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
          />
        </Modal>
      )}
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default StreamingNetworkMenu;
