import React, { useState, useEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { useSetError } from "../redux/modals";
import { StreamingLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { Page, FullPageSpinner } from "../Components";

import { Table } from "react-bootstrap";

import "./NonLiveReport.scss";

import useLocation from "../utils/hooks/useLocation";

const isYellow = (creativeObj, totalCount) => {
  return !creativeObj.live && (creativeObj.count / totalCount < 0.05 || creativeObj.count < 100);
};

export const NonLiveReport = () => {
  const setError = useSetError();
  const [creativeInfo, setCreativeInfo] = useState();
  const { company } = useLocation();

  useEffect(() => {
    if (company) {
      let bail = false;
      (async () => {
        try {
          let res = await StreamingLambdaFetch(`/check_creatives/${company}`);
          let data = await awaitJSON(res);

          if (bail) {
            return;
          }
          if (!data[company]) {
            setCreativeInfo([]);
            return;
          }

          let red = [];
          let yellow = [];
          let white = [];

          for (let placement of data[company]) {
            let bucket = "white";
            for (let creative of placement.iscis) {
              if (isYellow(creative, placement.count)) {
                bucket = "yellow";
              } else if (!creative.live) {
                bucket = "red";
                break;
              }
            }
            if (bucket === "red") {
              red.push(placement);
            } else if (bucket === "white") {
              white.push(placement);
            } else {
              yellow.push(placement);
            }
          }

          let newList = R.pipe(
            R.map(R.sortBy(el => el.network.toUpperCase())),
            R.flatten
          )([red, yellow, white]);

          setCreativeInfo(newList);
        } catch (e) {
          setError({
            message: `Couldn't run non-live creative check. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [company, setError]);

  return (
    <div className="nonLiveReportPage">
      <h3>
        Creative Report ({R.pipe(Dfns.subDays(1), Dfns.format("EEEE, LLLL do y"))(new Date())})
      </h3>
      {creativeInfo ? (
        creativeInfo.length ? (
          creativeInfo.map(({ network, platform, count: groupCount, iscis }) => (
            <div key={`${network} ${platform}`} className="nonLiveReportPage">
              <h3>{`${network} ${platform} (${groupCount.toLocaleString()} impressions received)`}</h3>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th>ISCI</th>
                    <th>Count</th>
                    <th>%</th>
                    <th>Live?</th>
                  </tr>
                </thead>
                <tbody>
                  {iscis.map(creative => {
                    const { isci, count, live } = creative;
                    let className = "";
                    let liveText = "";
                    if (isYellow(creative, groupCount)) {
                      className = "notLiveMinimal";
                      liveText = "no, but insignificant";
                    } else if (!live) {
                      className = "notLive";
                      liveText = "no!";
                    } else {
                      liveText = "yes";
                    }

                    return (
                      <tr key={isci} className={className}>
                        <td>{isci}</td>
                        <td>{count.toLocaleString()}</td>
                        <td>{Math.floor((count / groupCount) * 10000) / 100}%</td>
                        <td>{liveText}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ))
        ) : (
          <div>No impressions ran yesterday</div>
        )
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

const NonLiveReportWithPage = () => (
  <Page title="Non-Live Creatives" pageType="Non-Live Creatives">
    <NonLiveReport />
  </Page>
);

export default NonLiveReportWithPage;
