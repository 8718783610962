import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  GlossaryItem,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as C from "@blisspointmedia/bpm-types/dist/CommercePerformance";
import * as R from "ramda";

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const { dimensionVarName, dimensionTypeName } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<C.Dimension, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    value: dimensionValue,
    label: dimensionValue,
  };
  if (dimensionTypeName === "Platform") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = `${CDN}/assets/img/platforms/${parsedDimensionValue}.png`;
    return {
      label: dimensionValue,
      overlayText: dimensionValue,
      value: dimensionValue,
      url,
    };
  }
  return defaultCell;
};

export const dimensionColumnMetaDataMap: Partial<Record<C.DimensionColumnType, ColumnMetaData>> = {
  "Ad Group": {
    contentType: "text",
    dimensionVarName: "ad_group_name" as any,
    displayName: "Ad Group Name",
  },
  "Campaign Type": {
    contentType: "text",
    dimensionVarName: "campaign_type",
    displayName: "Campaign Type",
  },
  Campaign: {
    contentType: "text",
    dimensionVarName: "campaign_name",
    displayName: "Campaign",
  },
  Keyword: {
    contentType: "text",
    dimensionVarName: "keyword",
    displayName: "Keyword",
  },
  "Match Type": {
    contentType: "text",
    dimensionVarName: "match_type",
    displayName: "Match Type",
  },
  Placement: {
    contentType: "text",
    dimensionVarName: "placement",
    displayName: "Placement",
  },
  Platform: {
    contentType: "text",
    dimensionVarName: "platform",
    displayName: "Platform",
    iconStyle: "logo",
  },
  "Product Ad": {
    contentType: "text",
    dimensionVarName: "ad_name",
    displayName: "Product Ad",
  },
  "Product Category": {
    contentType: "text",
    dimensionVarName: "tinuiti_product_category",
    displayName: "Product Category",
  },
  "Product ID": {
    contentType: "text",
    dimensionVarName: "product_id",
    displayName: "Product ID",
  },
  Retailer: {
    contentType: "text",
    dimensionVarName: "retailer",
    displayName: "Retailer",
  },
} as any;

export const columnMetaDataMap: Partial<Record<C.ColumnType, ColumnMetaData>> = {
  acos: {
    aggregator: agg =>
      agg.spend && (agg.spend as number) > 0 && agg.revenue
        ? ((agg.spend as number) * 1.0) / R.defaultTo(0.0, agg.revenue as number)
        : "--",
    decimals: 2,
    displayName: "ACOS",
    fetchGetter: fetch =>
      fetch.spend && (fetch.spend as number) > 0 && fetch.revenue
        ? ((fetch.spend as number) * 1.0) / R.defaultTo(0.0, fetch.revenue as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["revenue", "spend"],
  },
  aov: {
    aggregator: agg =>
      agg.volume && (agg.volume as number) > 0 && agg.revenue
        ? (agg.revenue as number) / R.defaultTo(0.0, agg.volume as number)
        : "--",
    displayName: "AOV",
    fetchGetter: fetch =>
      fetch.volume && (fetch.volume as number) > 0 && fetch.revenue
        ? (fetch.revenue as number) / R.defaultTo(0.0, fetch.volume as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["revenue", "volume"],
  },
  clicks: {
    displayName: "Clicks",
    formatValue: toPrettyNumber,
  },
  conversion_rate: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.volume
        ? ((agg.volume as number) * 1.0) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    displayName: "Conversion Rate",
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.volume
        ? ((fetch.volume as number) * 1.0) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["clicks", "volume"],
  },
  cpc: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.spend
        ? (agg.spend as number) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    displayName: "CPC",
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.spend
        ? (fetch.spend as number) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyCPX,
    requiredTotalsColumns: ["clicks", "spend"],
  },
  ctr: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.clicks
        ? ((agg.clicks as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "CTR",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.clicks
        ? ((fetch.clicks as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "clicks"],
  },
  impressions: {
    displayName: "Impressions",
    formatValue: toPretty1000sInteger,
  },
  revenue: {
    displayName: "Revenue",
    formatValue: toPrettySpend,
  },
  roas: {
    aggregator: agg =>
      agg.spend && (agg.spend as number) > 0 && agg.revenue
        ? ((agg.revenue as number) * 1.0) / R.defaultTo(0.0, agg.spend as number)
        : "--",
    displayName: "ROAS",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.spend && (fetch.spend as number) > 0 && fetch.revenue
        ? ((fetch.revenue as number) * 1.0) / R.defaultTo(0.0, fetch.spend as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["revenue", "spend"],
  },
  spend: {
    displayName: "Spend",
    formatValue: toPrettySpend,
  },
  total_new_to_brand_sales_merge: {
    displayName: "New to Brand Sales",
    formatValue: toPrettySpend,
  },
  volume: {
    displayName: "KPI Volume",
    formatValue: toPrettyNumber,
  },
};

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "Orders",
    definition:
      "The number of unique orders contributed to the ad on the platform it is served on. In general, we default to using 14 day attribution across the board but it is possible for some to have custom setups.",
  },
  {
    term: "Revenue",
    definition:
      "The number of dollars generated in return. Typically commerce uses 14d attribution for attributing revenue to an ad.",
  },
  {
    term: "Units",
    definition:
      "The number of units purchased in the orders contributed to the ad. This should always be equal to or greater than the number of orders.",
  },
  {
    term: "Conversion Rate",
    definition: "Conversion Rate = KPI volume / clicks",
  },
  {
    term: "ROAS",
    definition: "Return on Ad Spend = revenue / spend",
  },
  {
    term: "ACOS",
    definition:
      "Advertising Cost of Sales. This is the inverse of ROAS and aims to show how much you need to spend in order to generate one dollar of revenue. Formula is spend/revenue. Anything under 1 means that you are generating more revenue than you are spending.",
  },
  {
    term: "NTB Orders",
    definition:
      "New to Brand Orders. The amount of orders that were attributed to your ad where the consumer has not purchased from that brand within the past year.",
  },
  {
    term: "NTB Sales",
    definition:
      "New to Brand Sales. The amount of sales revenue generated by the New to Brand Orders.",
  },
  {
    term: "Spend",
    definition: "The number of dollars invested.",
  },
  {
    term: "Impressions",
    definition: "The number of times the ad(s) are delivered.",
  },
  {
    term: "Clicks",
    definition: "The number of times the ad is clicked.",
  },
  {
    term: "CPM",
    definition: "Cost per thousand impressions = (spend x 1,000) / impressions",
  },
  {
    term: "CPC",
    definition: "Cost per Click = spend / clicks",
  },
];
