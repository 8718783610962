import React, { useState, useEffect, useCallback } from "react";
import * as uuid from "uuid";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { BPMButton, FullPageSpinner, Spinner, InfoTooltip } from "../Components";
import { awaitJSON, CreativeLambdaFetch } from "../utils/fetch-utils";
import { MdAdd, MdSave, MdCancel, MdDelete, MdEdit } from "react-icons/md";
import * as R from "ramda";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

interface CategoryInfo {
  name: string;
  options: string[];
  new?: boolean;
}

interface NewCategoryInfo {
  name: string;
  options: string[];
  new?: boolean;
  id: string;
}

interface ConceptInfo {
  concept: string;
  categories: Record<string, boolean>;
  new?: boolean;
  company?: string;
}

interface InsightsCategoryInfo {
  category: string;
  new?: boolean;
  company?: string;
}

const CreativeOptions = (): JSX.Element => {
  const [data, setData] = useState<CategoryInfo[]>();
  const [conceptData, setConceptData] = useState<ConceptInfo[]>();
  const [insightsCategoryData, setInsightsCategoryData] = useState<string[]>();
  const [newCategories, setNewCategories] = useState<NewCategoryInfo[]>([]);
  const [newOptions, setNewOptions] = useState<Record<string, string[]>>({});
  const [newConcepts, setNewConcepts] = useState<ConceptInfo[]>([]);
  const [newInsightsCategories, setNewInsightsCategories] = useState<InsightsCategoryInfo[]>([]);
  const [existingConceptsEdits, setExistingConceptsEdits] = useState({});
  const [conceptToIdMap, setConceptToIdMap] = useState({});
  const [saving, setSaving] = useState(false);

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure();
  const { cid } = useCompanyInfo();

  const shouldEnableInsightsCategories = useExperimentFlag("enableInsightsCategories");

  useEffect(() => {
    if (!data) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/get_creative_options", {
            params: { cid },
          });

          let formattedRes = await awaitJSON(res);

          let idMap = {};
          for (let concept of formattedRes.conceptData) {
            idMap[concept.concept] = concept.id;
          }

          setData(formattedRes.categoryData);
          setConceptData(formattedRes.conceptData);
          setConceptToIdMap(idMap);
          setInsightsCategoryData(formattedRes.insightsCategoryData);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [cid, data, setError]);

  // Adds a new category
  const addNewCategory = () => {
    let id = uuid.v4();
    let emptyCategory: NewCategoryInfo = { name: "", options: [], new: true, id };
    setNewCategories(current => {
      return [...current, emptyCategory];
    });
  };

  // Adds an option to a category
  const addOption = name => {
    setNewOptions(current => {
      return { ...current, [name]: [...(current[name] || []), ""] };
    });
  };

  // Changes names of the options
  const changeOptionVal = (categoryName, i, value) => {
    setNewOptions(current => {
      return { ...current, [categoryName]: R.update(i, value, newOptions[categoryName]) };
    });
  };

  // Discard all unsaved changes
  const discardChanges = () => {
    setNewCategories([]);
    setNewOptions({});
    setExistingConceptsEdits({});
    setNewConcepts([]);
    setUnsavedSelectOptions({});
    setNewInsightsCategories([]);
  };

  // Save Changes
  const saveChanges = async () => {
    setSaving(true);
    let attributesData: CategoryInfo[] = [];

    for (let key of R.keys(newOptions)) {
      let actualName: string = key;
      for (let category of newCategories) {
        if (category.id === key) {
          actualName = category.name;
        }
      }

      let filteredOptions = newOptions[key].filter(option => option.trim() !== "");

      let data = { name: actualName, options: filteredOptions, company: cid };
      attributesData.push(data);
    }

    let conceptData: ConceptInfo[] = [];

    if (newConcepts.length > 0) {
      for (let obj of newConcepts) {
        if (obj.concept.trim() !== "") {
          conceptData.push({ ...obj, new: true, company: cid });
        }
      }
    }

    let insightsCategoryData: InsightsCategoryInfo[] = [];

    if (newInsightsCategories.length > 0) {
      for (let obj of newInsightsCategories) {
        if (obj.category.trim() !== "") {
          insightsCategoryData.push({ ...obj, new: true, company: cid });
        }
      }
    }

    if (!R.isEmpty(existingConceptsEdits)) {
      for (let key of R.keys(existingConceptsEdits)) {
        let obj = {
          concept: key,
          categories: existingConceptsEdits[key],
          company: cid,
          id: conceptToIdMap[key],
        };
        conceptData.push(obj);
      }
    }

    try {
      await CreativeLambdaFetch("/add_creative_options", {
        method: "post",
        body: { attributesData, conceptData, insightsCategoryData },
      });
      setData(undefined);
      setNewCategories([]);
      setNewOptions({});
      setExistingConceptsEdits({});
      setNewConcepts([]);
      setNewInsightsCategories([]);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      setError({ message: e.message, reportError: e });
    }
  };

  // Add new concept
  const addConcept = () => {
    let attributesList = {};
    if (data) {
      for (let attribute of data) {
        attributesList[attribute.name] = true;
      }
    }

    let newConcept = { concept: "", categories: attributesList };
    setNewConcepts(current => {
      return [...current, newConcept];
    });
  };

  // Adds a new insights category
  const addInsightsCategory = () => {
    let newInsightsCategory = { category: "" };
    setNewInsightsCategories(current => {
      return [...current, newInsightsCategory];
    });
  };

  // Category Options for Multi Select
  let attributeDropdownOptions: Record<string, string>[] = [];
  if (data && data.length > 0) {
    for (let attribute of data) {
      attributeDropdownOptions.push({
        value: attribute.name,
        label: attribute.name,
      });
    }
  }

  const [defaultNaValues, setDefaultNaValues] = useState<
    Record<string, Record<string, boolean>[]>
  >();

  if (conceptData && conceptData.length && !defaultNaValues) {
    let object = {};
    for (let concept of conceptData) {
      for (let key of R.keys(concept.categories)) {
        if (concept.categories[key] === false) {
          object[concept.concept] = [
            ...(object[concept.concept] || []),
            { value: key, label: key },
          ];
        }
      }
    }
    setDefaultNaValues(object);
  }

  const [unsavedSelectOptions, setUnsavedSelectOptions] = useState({});

  const editConcept = (select, concept) => {
    if (select === null) {
      unsavedSelectOptions[concept] = [];
    } else {
      setUnsavedSelectOptions(current => {
        return { ...current, [concept]: select };
      });
    }

    let edits = {};
    if (!R.isNil(select)) {
      for (let selection of select) {
        edits[concept] = { ...(edits[concept] || {}), [selection.value]: false };
      }

      let selectArray: string[] = [];
      for (let item of select) {
        selectArray.push(item.value);
      }

      for (let option of attributeDropdownOptions) {
        if (!R.includes(option.value, selectArray)) {
          edits[concept] = { ...(edits[concept] || {}), [option.value]: true };
        }
      }
    } else {
      for (let option of attributeDropdownOptions) {
        edits[concept] = { ...(edits[concept] || {}), [option.value]: true };
      }
    }
    setExistingConceptsEdits(current => {
      return {
        ...current,
        ...edits,
      };
    });
  };

  const [conceptInEditMode, setConceptInEditMode] = useState("");
  const [newConceptName, setNewConceptName] = useState("");
  const [attributeInEditMode, setAttributeInEditMode] = useState("");
  const [newAttributeName, setNewAttributeName] = useState("");

  const saveConceptName = useCallback(async () => {
    try {
      await setAreYouSure({
        title: "Update concept name?",
        message:
          "You are about to immediately update the name of this concept.  Are you sure you want to proceed?",
        okayText: "Yes, update name",
        cancelText: "Never mind",
      });

      await CreativeLambdaFetch("/saveConceptName", {
        method: "POST",
        body: { company: cid, oldName: conceptInEditMode, newName: newConceptName },
      });

      window.location.reload();
    } catch (e) {
      setError({
        message: e.message,
      });
    }
  }, [setAreYouSure, cid, conceptInEditMode, newConceptName, setError]);

  const deleteConcept = useCallback(
    async (conceptName: string) => {
      try {
        await setAreYouSure({
          title: "Delete concept?",
          message: `You are about to immediately delete this concept, removing it from any ISCIs on which it is set.  You will need to give those ISCIs new concepts manually.
            
            Are you sure you no longer need this concept in the creative map?`,
          okayText: "Yes, delete",
          cancelText: "Never mind",
          variant: "danger",
        });

        await CreativeLambdaFetch("/removeConcept", {
          method: "POST",
          body: { company: cid, conceptName },
        });

        window.location.reload();
      } catch (e) {
        setError({
          message: e.message,
        });
      }
    },
    [setAreYouSure, setError, cid]
  );

  const saveAttributeName = useCallback(async () => {
    try {
      await setAreYouSure({
        title: "Update attribute name?",
        message:
          "You are about to immediately update the name of this attribute.  Are you sure you want to proceed?",
        okayText: "Yes, update name",
        cancelText: "Never mind",
      });

      await CreativeLambdaFetch("/saveCreativeAttributeName", {
        method: "POST",
        body: { company: cid, oldName: attributeInEditMode, newName: newAttributeName },
      });

      window.location.reload();
    } catch (e) {
      setError({
        message: e.message,
      });
    }
  }, [setAreYouSure, cid, attributeInEditMode, newAttributeName, setError]);

  const deleteAttribute = useCallback(
    async (attributeName: string) => {
      try {
        await setAreYouSure({
          title: "Delete attribute and tactics?",
          message: `You are about to immediately delete this attribute and all of its associated tactics, removing them from any ISCIs on which they are set.
            
            Are you sure you no longer need this attribute in the creative map?`,
          okayText: "Yes, delete",
          cancelText: "Never mind",
          variant: "danger",
        });

        await CreativeLambdaFetch("/removeCreativeAttribute", {
          method: "POST",
          body: { company: cid, attributeName },
        });

        window.location.reload();
      } catch (e) {
        setError({
          message: e.message,
        });
      }
    },
    [setAreYouSure, setError, cid]
  );

  return data ? (
    <div className="creativeOptionsPage">
      {conceptData && (
        <div className="conceptContainer">
          <div className="conceptTitleAndButtons">
            <div className="title">Concepts</div>
          </div>
          <div className="conceptRowsContainer">
            <div className="headers">
              <span className="name">Name</span>
              <span className="applicableCategories">Disabled Categories</span>
              <InfoTooltip size="reg">
                This shows what Creative Attributes are disabled for each concept. Selecting one
                means that the creative attribute is not applicable (N/A) for its respective
                concept.
              </InfoTooltip>
            </div>
            {conceptData.map(conceptObj => {
              let { concept } = conceptObj;
              return (
                <div className="row" key={concept}>
                  <div className="conceptNameAndEditTools">
                    <div className="conceptName">
                      {conceptInEditMode === concept ? (
                        <input
                          className="attributeNameInput"
                          value={newConceptName}
                          onChange={e => setNewConceptName(e.target.value)}
                        />
                      ) : (
                        <div>{concept}</div>
                      )}
                    </div>
                    <div className="categoryControls">
                      {conceptInEditMode === concept ? (
                        <div className="saveAndCancelButtons">
                          <BPMButton size="sm" variant="outline-danger" onClick={saveConceptName}>
                            <MdSave />
                          </BPMButton>
                          <BPMButton
                            size="sm"
                            variant="outline-danger"
                            onClick={() => {
                              setConceptInEditMode("");
                              setNewConceptName("");
                            }}
                          >
                            <MdCancel />
                          </BPMButton>
                        </div>
                      ) : (
                        <div>
                          <BPMButton
                            size="sm"
                            variant="outline-danger"
                            onClick={() => {
                              setConceptInEditMode(concept);
                              setNewConceptName(concept);
                            }}
                          >
                            <MdEdit />
                          </BPMButton>
                          <BPMButton
                            size="sm"
                            variant="outline-danger"
                            onClick={() => deleteConcept(concept)}
                          >
                            <MdDelete />
                          </BPMButton>
                        </div>
                      )}
                    </div>
                  </div>

                  <Select
                    isMulti
                    isClearable={false}
                    value={
                      unsavedSelectOptions[concept] ?? ((defaultNaValues || {})[concept] || null)
                    }
                    className="naCategoriesSelect"
                    options={attributeDropdownOptions}
                    onChange={select => {
                      editConcept(select, concept);
                    }}
                  />
                </div>
              );
            })}
            {newConcepts.map((conceptObj, i) => {
              let { concept } = conceptObj;
              return (
                <div className="row" key={i}>
                  <Form.Control
                    className="newConceptForm"
                    placeholder="Concept Name"
                    type="text"
                    value={concept}
                    onChange={e =>
                      setNewConcepts(
                        R.update(i, { ...conceptObj, concept: e.currentTarget.value }, newConcepts)
                      )
                    }
                  />
                </div>
              );
            })}
            <BPMButton onClick={addConcept}>
              <MdAdd />
            </BPMButton>
          </div>
        </div>
      )}

      {shouldEnableInsightsCategories && insightsCategoryData && (
        <div className="conceptContainer">
          <div className="conceptTitleAndButtons">
            <div className="title">Insights Categories</div>
          </div>
          <div className="conceptRowsContainer">
            <div className="headers">
              <span className="name">Name</span>
            </div>
            {insightsCategoryData.map(category => {
              return (
                <div className="row" key={category}>
                  <div className="conceptNameAndEditTools">{category}</div>
                </div>
              );
            })}
            {newInsightsCategories.map((categoryObj, i) => {
              let { category } = categoryObj;
              return (
                <div className="row" key={i}>
                  <Form.Control
                    className="newConceptForm"
                    placeholder="Insights Category Name"
                    type="text"
                    value={category}
                    onChange={e =>
                      setNewInsightsCategories(
                        R.update(
                          i,
                          { ...categoryObj, category: e.currentTarget.value },
                          newInsightsCategories
                        )
                      )
                    }
                  />
                </div>
              );
            })}
            <BPMButton onClick={addInsightsCategory}>
              <MdAdd />
            </BPMButton>
          </div>
        </div>
      )}

      <div className="creativeAttributesTitle">Creative Attributes</div>

      <div className="categoryList">
        {data.map((category, i) => {
          return (
            <div className="categoryBox" key={category.name}>
              <div className="categoryHeader">
                <div className="categoryName">
                  {attributeInEditMode === category.name ? (
                    <input
                      className="attributeNameInput"
                      value={newAttributeName}
                      onChange={e => setNewAttributeName(e.target.value)}
                    />
                  ) : (
                    <div>{category.name}</div>
                  )}
                </div>
                <div className="categoryControls">
                  {attributeInEditMode === category.name ? (
                    <div className="saveAndCancelButtons">
                      <BPMButton size="sm" variant="outline-danger" onClick={saveAttributeName}>
                        <MdSave />
                      </BPMButton>
                      <BPMButton
                        size="sm"
                        variant="outline-danger"
                        onClick={() => {
                          setAttributeInEditMode("");
                          setNewAttributeName("");
                        }}
                      >
                        <MdCancel />
                      </BPMButton>
                    </div>
                  ) : (
                    <div>
                      <BPMButton
                        size="sm"
                        variant="outline-danger"
                        onClick={() => {
                          setAttributeInEditMode(category.name);
                          setNewAttributeName(category.name);
                        }}
                      >
                        <MdEdit />
                      </BPMButton>
                      <BPMButton
                        size="sm"
                        variant="outline-danger"
                        onClick={() => deleteAttribute(category.name)}
                      >
                        <MdDelete />
                      </BPMButton>
                    </div>
                  )}
                </div>
              </div>
              <div className="categoryOptions">
                {category.options.map(option => {
                  return (
                    <div className="option" key={option}>
                      {option}
                    </div>
                  );
                })}
                {newOptions[category.name] &&
                  newOptions[category.name].map((option, i) => {
                    return (
                      <Form.Control
                        size="sm"
                        placeholder="New Option"
                        type="text"
                        value={option}
                        key={i}
                        onChange={e => {
                          changeOptionVal(category.name, i, e.currentTarget.value);
                        }}
                      />
                    );
                  })}
                <BPMButton size="sm" onClick={() => addOption(category.name)}>
                  <MdAdd />
                </BPMButton>
              </div>
            </div>
          );
        })}
        {newCategories &&
          newCategories.map((category, i) => {
            return (
              <div className="categoryBox" key={i}>
                <div className="categoryHeader">
                  <div className="categoryName">
                    <Form.Control
                      placeholder="Category Name"
                      type="text"
                      value={category.name}
                      onChange={e =>
                        setNewCategories(
                          R.update(i, { ...category, name: e.currentTarget.value }, newCategories)
                        )
                      }
                    />
                  </div>
                </div>
                <div className="categoryOptions">
                  {newOptions[category.id] &&
                    newOptions[category.id].map((option, i) => {
                      return (
                        <Form.Control
                          size="sm"
                          placeholder="Option"
                          type="text"
                          value={option}
                          key={i}
                          onChange={e => {
                            changeOptionVal(category.id, i, e.currentTarget.value);
                          }}
                        />
                      );
                    })}
                  <BPMButton size="sm" onClick={() => addOption(category.id)}>
                    <MdAdd />
                  </BPMButton>
                </div>
              </div>
            );
          })}
        <BPMButton className="newCategoryButton" onClick={addNewCategory} variant="outline-primary">
          <MdAdd />
        </BPMButton>
      </div>

      {!(
        R.isEmpty(newCategories) &&
        R.isEmpty(newOptions) &&
        R.isEmpty(existingConceptsEdits) &&
        R.isEmpty(newConcepts) &&
        R.isEmpty(newInsightsCategories)
      ) && (
        <div className="floatingControls">
          <BPMButton onClick={discardChanges} variant="danger">
            <MdCancel />
          </BPMButton>

          <BPMButton onClick={saveChanges} variant="success">
            {saving ? <Spinner /> : <MdSave />}
          </BPMButton>
        </div>
      )}
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default CreativeOptions;
