import React from "react";
import * as R from "ramda";
import useLocation from "../utils/hooks/useLocation";
import { FullPageSpinner } from "../Components";

import "./MobiusView.scss";
import { useCompanyInfo } from "../redux/company";

const MobiusView = (): JSX.Element => {
  const companyInfo = useCompanyInfo();
  const { location } = useLocation();

  const company = companyInfo.cid;
  const viewKey = location.pathname.replace(`/${company}/mobius/`, "");
  const viewInfo = R.find(row => row.key === viewKey, companyInfo.all_mobius_views || []);
  if (!companyInfo.all_mobius_views || companyInfo.all_mobius_views.length === 0) {
    window.location.href = `/${company}`;
  }

  return viewInfo ? (
    <iframe className="dashboardIframe" title={viewInfo.name} src={viewInfo.url}></iframe>
  ) : (
    <FullPageSpinner />
  );
};

export default MobiusView;
