import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./FiltersPanel.scss";
import CheckBox from "./CheckBox";
import { Button, ButtonType, SimpleTooltip } from "../Components";

interface FiltersPanelProps {
  ageMap: Partial<Record<string, boolean>>;
  setAgeMap: (key: string, value: boolean) => void;
  genderMap: Partial<Record<string, boolean>>;
  setGenderMap: (key: string, value: boolean) => void;
  regionMap: Partial<Record<string, boolean>>;
  setRegionMap: (key: string, value: boolean) => void;
  incomeMap: Partial<Record<string, boolean>>;
  setIncomeMap: (key: string, value: boolean) => void;
  setFetchingData: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  appliedFilters: {
    [key: string]: boolean | undefined;
  };
  inLoadingState: boolean;
}

const FiltersPanel: React.FC<FiltersPanelProps> = React.memo(
  ({
    ageMap,
    setAgeMap,
    genderMap,
    setGenderMap,
    regionMap,
    setRegionMap,
    incomeMap,
    setIncomeMap,
    setFetchingData,
    setData,
    appliedFilters,
    inLoadingState,
  }: FiltersPanelProps): JSX.Element => {
    const resetFilters = useCallback(() => {
      setAgeMap("Age-All", true);
      setAgeMap("18-34", false);
      setAgeMap("35-49", false);
      setAgeMap("50+", false);
      setGenderMap("Gender-All", true);
      setGenderMap("Male", false);
      setGenderMap("Female", false);
      setRegionMap("Region-All", true);
      setRegionMap("Northeast", false);
      setRegionMap("South", false);
      setRegionMap("Midwest", false);
      setRegionMap("West", false);
      setIncomeMap("All", true);
      setIncomeMap("Under 40K", false);
      setIncomeMap("40K - 80K", false);
      setIncomeMap("80K - 120K", false);
      setIncomeMap("Over 120K", false);
    }, [setAgeMap, setGenderMap, setIncomeMap, setRegionMap]);

    const inDefaultState = useMemo(() => {
      return (
        ageMap["Age-All"] && genderMap["Gender-All"] && regionMap["Region-All"] && incomeMap.All
      );
    }, [ageMap, genderMap, incomeMap, regionMap]);

    const selectedFilters = useMemo(() => {
      return { ...ageMap, ...genderMap, ...regionMap, ...incomeMap };
    }, [ageMap, genderMap, incomeMap, regionMap]);

    const areFiltersApplied = useMemo(() => {
      if (Object.keys(appliedFilters).length !== Object.keys(selectedFilters).length) {
        return false;
      }

      let output = true;
      Object.keys(appliedFilters).forEach(key => {
        if (selectedFilters[key] !== appliedFilters[key]) {
          output = false;
        }
      });
      return output;
    }, [appliedFilters, selectedFilters]);

    const allowRefresh = useMemo(() => {
      let output = false;
      Object.keys(appliedFilters).forEach(key => {
        if (
          key !== "Age-All" &&
          key !== "Gender-All" &&
          key !== "Region-All" &&
          key !== "All" &&
          appliedFilters[key] === true
        ) {
          output = true;
        }
      });

      return output;
    }, [appliedFilters]);

    const [showResetTooltip, setShowResetTooltip] = useState<boolean>(false);
    const [showApplyTooltip, setShowApplyTooltip] = useState<boolean>(false);

    const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
    useEffect(() => {
      window.addEventListener("resize", () => setInnerHeight(window.innerHeight));

      return () => {
        window.removeEventListener("resize", () => setInnerHeight(window.innerHeight));
      };
    }, [innerHeight]);

    const stylingSuffix = useMemo(() => {
      if (innerHeight < 972 && innerHeight >= 888) {
        return " reduce-spacing-1";
      } else if (innerHeight < 888 && innerHeight >= 804) {
        return " reduce-spacing-2";
      } else if (innerHeight < 804 && innerHeight >= 720) {
        return " reduce-spacing-3";
      } else if (innerHeight < 720) {
        return " wrap";
      } else {
        return "";
      }
    }, [innerHeight]);

    return (
      <div className="filtersPanel">
        <div className="header">
          <div className="title">Filters</div>
        </div>
        <div className={`filters${stylingSuffix}`}>
          <div className={`filterSection${stylingSuffix}`}>
            <div className="filterName">Age</div>
            <div className={`filterOptions${stylingSuffix}`}>
              <CheckBox
                className="checkBox"
                checked={ageMap["Age-All"] || false}
                label="All"
                onCheck={() => {
                  setAgeMap("Age-All", !ageMap["Age-All"]);
                  setAgeMap("18-34", false);
                  setAgeMap("35-49", false);
                  setAgeMap("50+", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={ageMap["18-34"] || false}
                label={`18${"\u2013"}34`}
                onCheck={() => {
                  setAgeMap("18-34", !ageMap["18-34"]);
                  setAgeMap("Age-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={ageMap["35-49"] || false}
                label={`35${"\u2013"}49`}
                onCheck={() => {
                  setAgeMap("35-49", !ageMap["35-49"]);
                  setAgeMap("Age-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={ageMap["50+"] || false}
                label="50+"
                onCheck={() => {
                  setAgeMap("50+", !ageMap["50+"]);
                  setAgeMap("Age-All", false);
                }}
              ></CheckBox>
            </div>
          </div>
          <div className={`filterSection${stylingSuffix}`}>
            <div className="filterName">Gender</div>
            <div className={`filterOptions${stylingSuffix}`}>
              <CheckBox
                className="checkBox"
                checked={genderMap["Gender-All"] || false}
                label="All"
                onCheck={() => {
                  setGenderMap("Gender-All", !genderMap["Gender-All"]);
                  setGenderMap("Male", false);
                  setGenderMap("Female", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={genderMap.Male || false}
                label="Male"
                onCheck={() => {
                  setGenderMap("Male", !genderMap.Male);
                  setGenderMap("Gender-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={genderMap.Female || false}
                label="Female"
                onCheck={() => {
                  setGenderMap("Female", !genderMap.Female);
                  setGenderMap("Gender-All", false);
                }}
              ></CheckBox>
            </div>
          </div>
          <div className={`filterSection${stylingSuffix}`}>
            <div className="filterName">Region</div>
            <div className={`filterOptions${stylingSuffix}`}>
              <CheckBox
                className="checkBox"
                checked={regionMap["Region-All"] || false}
                label="All"
                onCheck={() => {
                  setRegionMap("Region-All", !regionMap["Region-All"]);
                  setRegionMap("Northeast", false);
                  setRegionMap("South", false);
                  setRegionMap("Midwest", false);
                  setRegionMap("West", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.Northeast || false}
                label="Northeast"
                onCheck={() => {
                  setRegionMap("Northeast", !regionMap.Northeast);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.South || false}
                label="South"
                onCheck={() => {
                  setRegionMap("South", !regionMap.South);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.Midwest || false}
                label="Midwest"
                onCheck={() => {
                  setRegionMap("Midwest", !regionMap.Midwest);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={regionMap.West || false}
                label="West"
                onCheck={() => {
                  setRegionMap("West", !regionMap.West);
                  setRegionMap("Region-All", false);
                }}
              ></CheckBox>
            </div>
          </div>
          <div className={`filterSection${stylingSuffix}`}>
            <div className="filterName">Household Income</div>
            <div className={`filterOptions${stylingSuffix}`}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CheckBox
                  className="checkBox"
                  checked={incomeMap.All || false}
                  label="All"
                  onCheck={() => {
                    setIncomeMap("All", !incomeMap.All);
                    setIncomeMap("Under 40K", false);
                    setIncomeMap("40K - 80K", false);
                    setIncomeMap("80K - 120K", false);
                    setIncomeMap("Over 120K", false);
                  }}
                ></CheckBox>
                <span
                  style={{
                    paddingLeft: "7px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    height: "14px",
                  }}
                >
                  {"(includes participants who prefer not to say)"}
                </span>
              </div>
              <CheckBox
                className="checkBox"
                checked={incomeMap["Under 40K"] || false}
                label="<$40k"
                onCheck={() => {
                  setIncomeMap("Under 40K", !incomeMap["Under 40K"]);
                  setIncomeMap("All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={incomeMap["40K - 80K"] || false}
                label={`$40${"\u2013"}$80k`}
                onCheck={() => {
                  setIncomeMap("40K - 80K", !incomeMap["40K - 80K"]);
                  setIncomeMap("All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={incomeMap["80K - 120K"] || false}
                label={`$80${"\u2013"}$120k`}
                onCheck={() => {
                  setIncomeMap("80K - 120K", !incomeMap["80K - 120K"]);
                  setIncomeMap("All", false);
                }}
              ></CheckBox>
              <CheckBox
                className="checkBox"
                checked={incomeMap["Over 120K"] || false}
                label=">$120k"
                onCheck={() => {
                  setIncomeMap("Over 120K", !incomeMap["Over 120K"]);
                  setIncomeMap("All", false);
                }}
              ></CheckBox>
            </div>
          </div>
        </div>
        <div className="actions">
          <SimpleTooltip
            text="The filter selection is already set to the defualt."
            horizontalShift="8.73px"
            verticalShift="150%"
            onMouseEnter={() => {
              if (inDefaultState && !inLoadingState) {
                setShowResetTooltip(true);
              }
            }}
            onMouseLeave={() => setShowResetTooltip(false)}
            showTooltip={showResetTooltip}
          >
            <Button
              type={ButtonType.FILLED}
              design="secondary"
              disabled={(inDefaultState && !inLoadingState) || inLoadingState}
              onClick={() => {
                const allowDataRefresh = allowRefresh;
                resetFilters();
                if (allowDataRefresh) {
                  setData([]);
                  setFetchingData(true);
                }
              }}
            >
              Reset Filters
            </Button>
          </SimpleTooltip>
          <SimpleTooltip
            text="The selected filters are already applied."
            horizontalShift="8.5px"
            verticalShift="150%"
            onMouseEnter={() => {
              if (areFiltersApplied && !inLoadingState) {
                setShowApplyTooltip(true);
              }
            }}
            onMouseLeave={() => setShowApplyTooltip(false)}
            showTooltip={showApplyTooltip}
          >
            <Button
              type={ButtonType.FILLED}
              onClick={() => {
                setData([]);
                setFetchingData(true);
              }}
              disabled={(areFiltersApplied && !inLoadingState) || inLoadingState}
            >
              Apply Filters
            </Button>
          </SimpleTooltip>
        </div>
      </div>
    );
  }
);

export default FiltersPanel;
