import "./MTModalEditTable.scss";
import {
  CheckBox,
  Header,
  NumberFormatter,
  BPMTable,
  Button,
  TableDataType,
  ButtonType,
} from "../Components";
import { MdAdd } from "react-icons/md";
import * as R from "ramda";
import cn from "classnames";
import React, { Dispatch, useCallback, useLayoutEffect, useState, useMemo } from "react";
import MTModalRowEditor from "./MTModalRowEditor";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

const ROW_HEIGHT = 35;

const SUPER_HEADERS = [
  { span: 18, data: "" },
  { span: 3, data: "Demo Targeting" },
  { span: 9, data: "Device Targeting" },
  { span: 2, data: "Geo Targeting" },
  { span: 5, data: "Advanced Targeting" },
  { span: 4, data: "Media Type" },
  { span: 8, data: "DSPs" },
];

const renderForType = (type: string, value: string | boolean | JSX.Element) => {
  switch (type) {
    case "currency":
      return <NumberFormatter value={parseFloat(value as string)} type={"$"} />;
    case "decimal":
    case "real":
      return <NumberFormatter value={parseFloat(value as string)} type={"#"} />;
    case "checkbox":
      return <CheckBox checked={value as boolean} />;
    default:
      return value;
  }
};

interface MTModalEditTableProps<T> {
  className: string;
  deletedRows?: T[];
  allHeaders: Header[];
  viewableHeaders: Header[];
  readOnly?: boolean;
  setTableData: Dispatch<any[]>;
  tableData: T[];
  showDemoTargeting: boolean;
  setShowDemoTargeting: React.Dispatch<React.SetStateAction<boolean>>;
  showDeviceTargeting: boolean;
  setShowDeviceTargeting: React.Dispatch<React.SetStateAction<boolean>>;
  showGeoTargeting: boolean;
  setShowGeoTargeting: React.Dispatch<React.SetStateAction<boolean>>;
  showAdvancedTargeting: boolean;
  setShowAdvancedTargeting: React.Dispatch<React.SetStateAction<boolean>>;
  showMediaType: boolean;
  setShowMediaType: React.Dispatch<React.SetStateAction<boolean>>;
  showDSPs: boolean;
  setShowDSPs: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MTModalEditTable = <T extends TableDataType>(
  props: MTModalEditTableProps<T>
): JSX.Element => {
  const {
    className,
    deletedRows = [],
    allHeaders,
    viewableHeaders,
    readOnly = false,
    setTableData,
    tableData,
    showDemoTargeting,
    setShowDemoTargeting,
    showDeviceTargeting,
    setShowDeviceTargeting,
    showGeoTargeting,
    setShowGeoTargeting,
    showAdvancedTargeting,
    setShowAdvancedTargeting,
    showMediaType,
    setShowMediaType,
    showDSPs,
    setShowDSPs,
  } = props;

  const [showEditRow, setShowEditRow] = useState<Partial<T>>();
  const [isAddingNewRow, setIsAddingNewRow] = useState<boolean>(false);
  const defaultNewRow = {} as T;

  // Since BPMTable does not consistently give us back the row index of the data being manipulated, an index variable is instead
  // added to each row in the below useLayoutEffect and is automatically refreshed on any modification to tableData. As a result,
  // modifications to the table do not need to maintain index manually.
  useLayoutEffect(() => {
    const indexesAreContiguous = R.addIndex(R.map)(
      (row: any, index: number) => !R.isNil(row.index) && index === row.index,
      tableData
    );

    if (indexesAreContiguous.includes(false)) {
      const newTableData: T[] = R.addIndex(R.map)(
        (row: any, index: number) => ({ ...row, index }),
        tableData
      );
      setTableData(newTableData);
    }
  }, [tableData, setTableData]);

  const setRowData = useCallback(
    (rowData, oldIndex, newIndex) => {
      for (let header of allHeaders) {
        if (
          !(rowData[R.defaultTo("", header.field)] === 0) &&
          !rowData[R.defaultTo("", header.field)] &&
          typeof header.default !== "undefined"
        ) {
          rowData[R.defaultTo("", header.field)] = header.default;
        }
      }

      let newTableData;

      oldIndex = R.isNil(oldIndex) ? -1 : oldIndex;
      newIndex = R.isNil(newIndex) ? -1 : newIndex;

      if (oldIndex === -1 && newIndex === -1) {
        newTableData = tableData;
      } else if (oldIndex === -1) {
        const clampedNewIndex = R.clamp(0, tableData.length, newIndex);
        newTableData = R.insert(clampedNewIndex, rowData, tableData);
      } else if (newIndex === -1) {
        const clampedOldIndex = R.clamp(0, tableData.length - 1, oldIndex);
        newTableData = clampedOldIndex === oldIndex ? R.remove(oldIndex, 1, tableData) : tableData;
      } else {
        const clampedOldIndex = R.clamp(0, tableData.length - 1, oldIndex);
        const clampedNewIndex = R.clamp(0, tableData.length - 1, newIndex);
        newTableData =
          clampedOldIndex === oldIndex
            ? R.pipe(R.update(oldIndex, rowData), R.move(oldIndex, clampedNewIndex))(tableData)
            : tableData;
      }
      setTableData(newTableData);
    },
    [tableData, setTableData, allHeaders]
  );

  const tableHeaders = useMemo(
    () =>
      R.filter(
        elem => !R.isNil(elem),
        R.map(header => {
          return {
            ...header,
            width: R.defaultTo(120, header.width),
            minFlexWidth: R.defaultTo(200, header.minFlexWidth),
            name: header.field,
            renderer: data => {
              if (header.renderer) {
                <div
                  className={"modalEditTableCell modalEditTableCustomRendererCell"}
                  onClick={() => {
                    if (!readOnly) {
                      setShowEditRow(data);
                    }
                  }}
                >
                  {header.renderer(data, header.field)}
                </div>;
              }

              let displayValue: boolean | string | JSX.Element = "-";

              displayValue = renderForType(
                header.type as string,
                data[R.defaultTo("", header.field)]
              );

              return (
                <div
                  className={"modalEditTableCell"}
                  onClick={() => {
                    if (!readOnly) {
                      setShowEditRow(data);
                    }
                  }}
                >
                  {displayValue}
                </div>
              );
            },
          };
        }, viewableHeaders) as Header[]
      ),
    [viewableHeaders, readOnly]
  );

  const superHeaders = useMemo(() => {
    return SUPER_HEADERS.filter(element => {
      if (!showDemoTargeting && element.data === "Demo Targeting") {
        return false;
      }
      if (!showDeviceTargeting && element.data === "Device Targeting") {
        return false;
      }
      if (!showGeoTargeting && element.data === "Geo Targeting") {
        return false;
      }
      if (!showAdvancedTargeting && element.data === "Advanced Targeting") {
        return false;
      }
      if (!showMediaType && element.data === "Media Type") {
        return false;
      }
      if (!showDSPs && element.data === "DSPs") {
        return false;
      }

      return true;
    });
  }, [
    showAdvancedTargeting,
    showDSPs,
    showDemoTargeting,
    showDeviceTargeting,
    showGeoTargeting,
    showMediaType,
  ]);

  return (
    allHeaders && (
      <div className={cn("mtModalEditTable", className)}>
        <div className="mtTableHeader">
          <div className="mtTableActions">
            <Button
              type={ButtonType.FILLED}
              className="demoTargetingButton"
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowDemoTargeting(!showDemoTargeting)}
            >
              {showDemoTargeting ? "Hide Demo Targeting" : "Show Demo Targeting"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowDeviceTargeting(!showDeviceTargeting)}
            >
              {showDeviceTargeting ? "Hide Devices" : "Show Devices"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowGeoTargeting(!showGeoTargeting)}
            >
              {showGeoTargeting ? "Hide Geo Targeting" : "Show Geo Targeting"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowAdvancedTargeting(!showAdvancedTargeting)}
            >
              {showAdvancedTargeting ? "Hide Advanced Targeting" : "Show Advanced Targeting"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowMediaType(!showMediaType)}
            >
              {showMediaType ? "Hide Media Type" : "Show Media Type"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.NO_ICON}
              onClick={() => setShowDSPs(!showDSPs)}
            >
              {showDSPs ? "Hide DSPs" : "Show DSPs"}
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              icon={<MdAdd />}
              onClick={() => {
                const newTableData = R.concat([defaultNewRow], tableData);
                setTableData(newTableData);
              }}
            />
          </div>
        </div>
        <div className="mtOuterTableContainer">
          <BPMTable
            data={tableData}
            filterBar={true}
            filterBar2={false}
            defaultAdvancedFilter={true}
            headerHeight={50}
            headers={tableHeaders}
            superHeaders={superHeaders}
            minColumnWidth={20}
            noRowsRenderer={() => <div className="noRowsRenderer">No rows to show</div>}
            rowHeight={ROW_HEIGHT}
            rowHoverClass="hovered"
          />
        </div>
        {showEditRow && (
          <MTModalRowEditor<T>
            deletedRows={deletedRows}
            headers={allHeaders}
            invalidText={""}
            isAddingNewRow={isAddingNewRow}
            name=""
            onHide={() => setShowEditRow(undefined)}
            rowData={showEditRow as T}
            setDeletedRows={() => []}
            setIsAddingNewRow={setIsAddingNewRow}
            setRowData={(rowData, oldIndex, newIndex) => {
              setRowData(rowData, oldIndex, newIndex);
              setShowEditRow(undefined);
            }}
          />
        )}
      </div>
    )
  );
};
