import "./SnapshotChart.scss";
import React, { useMemo, useCallback } from "react";
import * as R from "ramda";
import { XAxis, YAxis, BarChart, Bar, Cell, LabelList, ResponsiveContainer } from "recharts";
import { DownloadDropdown, Dropdown, DropdownToggleType } from "../../Components";
import ChartContainer from "../../Components/ChartContainer";
import { colorMap as colors } from "../../utils/colors";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import { formatNumberAsInt } from "../../utils/format-utils";
import { ToggleSwitch } from "../ToggleSwitch";
import { StateSetter } from "../../utils/types";
import { MdOutlineWarningAmber } from "react-icons/md";
import { InfoTooltip } from "../InfoTooltip";

const BAR_CATEGORY_GAP = 6;
const BAR_CHART_CONTAINER_HEIGHT = 57;

const DEFAULT_FORMATTER = (value: number): string => formatNumberAsInt(value);

interface TooltipItems {
  beforeTooltipText: string;
  tooltipText: string;
  color?: string;
}

interface DataItem {
  label: string | { top: string; bottom: string };
  label2?: string | { top: string; bottom: string };
  name: string;
  value: number;
  value2?: number;
  incrementality?: boolean;
  added?: boolean;
}

interface DropdownOption {
  value: string;
}

interface SnapshotChartProps {
  colorOverrides?: { [key: string]: string };
  data: DataItem[];
  doubleBarChart?: boolean;
  dropdownOptions?: readonly DropdownOption[];
  dropdownValue?: string;
  header?: string;
  setDropdownValue?: (value: string) => void;
  setToggleLabel?: StateSetter<boolean>;
  title: string;
  tooltipItems?: TooltipItems;
  toggleLabel?: boolean;
  toggleSwitchLabel?: string;
  valueFormatter?: (value: number) => string;
  additionalRightActions?: React.ReactNode;
  exportFormattedData?: any[];
}

interface LegendProps {
  name: string;
  value: number;
  value2: number | undefined;
  formatter: (value: number) => string;
  incrementality?: boolean;
  expanded?: boolean;
  label?: number | string;
  label2?: number | string;
  actualValues?: boolean;
  onClick?: () => void;
}

export const Legend: React.FC<LegendProps> = ({
  name,
  value,
  value2,
  label2,
  label,
  formatter,
  incrementality,
  expanded,
  actualValues = false,
  onClick,
}) => {
  return (
    <div className="legend" onClick={onClick ? () => onClick() : undefined}>
      <div className="itemLabel">
        <div>
          {name}
          {incrementality && (
            <MdOutlineWarningAmber style={{ color: "orange", marginLeft: "5px" }} />
          )}
        </div>
      </div>
      {expanded ? (
        <div className="itemValueWrapper">
          <div className="itemValue">
            <div className="itemNameAndPercent">
              <span>Spend:</span>
              <span className="legendPercentSpan">{formatter(value)}</span>
            </div>
            {actualValues && <span className="actualValuesSpan">{label}</span>}
          </div>
          {value2 !== undefined && (
            <div className="itemValue">
              <div className="itemNameAndPercent">
                <span>Effect:</span>
                <span className="legendPercentSpan">{formatter(value2)}</span>
              </div>
              {actualValues && <span className="actualValuesSpan">{label2}</span>}
            </div>
          )}
        </div>
      ) : (
        <div className="itemValueWrapper">
          <div className="itemValue">{formatter(value)}</div>
          {value2 !== undefined && <div className="itemValue">{formatter(value2)}</div>}
        </div>
      )}
    </div>
  );
};

export const SnapshotChart: React.FC<SnapshotChartProps> = React.memo(
  ({
    colorOverrides,
    data,
    doubleBarChart,
    dropdownOptions,
    dropdownValue,
    header,
    setDropdownValue,
    setToggleLabel,
    title,
    tooltipItems,
    toggleLabel,
    toggleSwitchLabel,
    valueFormatter = DEFAULT_FORMATTER,
    additionalRightActions,
    exportFormattedData,
  }) => {
    const excelDownload = useCallback(() => {
      const dataToExport = exportFormattedData ? exportFormattedData : data;
      const resolvedTitle = title ? title.slice(0, 30) : "snapshot";
      exportToExcel(dataToExport, resolvedTitle);
    }, [title, data, exportFormattedData]);

    const pngDownload = useCallback(async () => {
      await downloadPNG(".snapshotGraphContents", title);
    }, [title]);

    const colorMap = useMemo(() => {
      if (!R.isEmpty(data)) {
        const colorsOriginal = colors(data);

        return { ...colorsOriginal, ...colorOverrides };
      }
    }, [colorOverrides, data]);

    const renderCustomizedLabel = useCallback(
      props => {
        const { x, y, width, value } = props;
        const newWidth = isNaN(width) ? 0 : width;
        const splitValue = value?.toString().split(" ");
        if (!splitValue) {
          return null;
        }
        let val1: string;
        let val2: string;
        if (typeof value === "string") {
          val1 = `${splitValue[0]}${splitValue[1] === "x" ? " x" : ""}`;
          val2 = `${
            splitValue[1] === "x" ? splitValue.slice(2).join(" ") : splitValue.slice(1).join(" ")
          }`;
        } else {
          val1 = value.top;
          val2 = value.bottom;
        }
        const showSecondLabel = !doubleBarChart || toggleLabel;

        return (
          <g>
            <text
              className="snapshotBarLabel"
              x={x + newWidth + 4}
              y={showSecondLabel ? y + 5 : y + 11}
              fill="#1f003f"
              stroke="#1f003f"
              strokeWidth={0}
              textAnchor="start"
              fontWeight={400}
              width={20}
              dominantBaseline="middle"
            >
              {val1}
            </text>
            {showSecondLabel && (
              <text
                className="snapshotBarLabel"
                x={x + newWidth + 4}
                y={y + 17}
                fill="#1f003f"
                stroke="#1f003f"
                strokeWidth={0}
                textAnchor="start"
                fontWeight={400}
                width={20}
                dominantBaseline="middle"
              >
                {val2}
              </text>
            )}
          </g>
        );
      },
      [toggleLabel, doubleBarChart]
    );

    return (
      <>
        <div className="snapshotChartContainer">
          <ChartContainer
            enableHoverDesign
            title={title}
            rightActions={
              <>
                {additionalRightActions}
                {!R.isNil(toggleLabel) && setToggleLabel && toggleSwitchLabel && (
                  <ToggleSwitch
                    label={toggleSwitchLabel}
                    checked={toggleLabel}
                    onChange={setToggleLabel}
                  />
                )}
                {dropdownOptions && dropdownValue && setDropdownValue && (
                  <Dropdown
                    type={DropdownToggleType.OUTLINED}
                    design="secondary"
                    size="sm"
                    value={dropdownValue}
                    options={dropdownOptions}
                    onChange={setDropdownValue}
                  />
                )}
                <DownloadDropdown size="sm" onClickOptions={[excelDownload, pngDownload]} />
              </>
            }
          >
            <div id="snapshotGraphContents" className="snapshotGraphContents">
              {header && !tooltipItems && <div className="snapshotGraphHeader">{header}</div>}
              {header && tooltipItems && (
                <div className="snapshotGraphHeaderWithToolTip">
                  <div>{header}</div>
                  <div className="snapShotGraphTooltipBeforeText">
                    {tooltipItems.beforeTooltipText}
                    <InfoTooltip color={tooltipItems.color}>{tooltipItems.tooltipText}</InfoTooltip>
                  </div>
                </div>
              )}
              <div
                className="barChartsContainer"
                style={{ height: data.length * BAR_CHART_CONTAINER_HEIGHT }}
              >
                <div className="leftOfChart">
                  {data.map(item => (
                    <Legend
                      name={item.name}
                      value={item.value}
                      value2={item.value2}
                      formatter={valueFormatter}
                      key={item.name}
                      incrementality={item.incrementality}
                    />
                  ))}
                </div>
                <div
                  className="snapshotBarContainer"
                  style={{ height: data.length * BAR_CHART_CONTAINER_HEIGHT }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      barCategoryGap={BAR_CATEGORY_GAP}
                      data={data}
                      layout="vertical"
                      margin={{ top: 0, right: 50, bottom: 0, left: 0 }}
                    >
                      <XAxis type="number" hide />
                      <YAxis type="category" dataKey="value" hide />
                      <Bar
                        dataKey="value"
                        isAnimationActive={false}
                        opacity={doubleBarChart ? 0.5 : 1}
                        stackId="spend"
                        strokeWidth={2}
                        radius={title === "Spend and Effect Share" ? 0 : 2}
                        strokeOpacity={1}
                      >
                        {data.map(item => (
                          <Cell key={`${item.name}value1`} fill={colorMap[item.name]} />
                        ))}
                        {(toggleLabel || doubleBarChart) && (
                          <LabelList dataKey="label" content={renderCustomizedLabel} />
                        )}
                      </Bar>
                      {doubleBarChart && (
                        <>
                          <Bar dataKey="value2" isAnimationActive={false} stackId="revenue">
                            {data.map(item => (
                              <Cell key={`${item.name}value2`} fill={colorMap[item.name]} />
                            ))}
                            {(toggleLabel || doubleBarChart) && (
                              <LabelList dataKey="label2" content={renderCustomizedLabel} />
                            )}
                          </Bar>
                        </>
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </>
    );
  }
);
