import React, { useState, useEffect, useMemo } from "react";

import * as Dfns from "date-fns/fp";

import * as R from "ramda";

import { LineChart, Line, XAxis, YAxis, Tooltip, ReferenceLine } from "recharts";

import AutoSizer from "react-virtualized-auto-sizer";

import { useSetError } from "../redux/modals";

import {
  LinearOptimizationsLambdaFetch,
  pollS3,
  S3SignedUrlFetch,
  awaitJSON,
} from "../utils/fetch-utils";

import { FullPageSpinner } from "../Components";

import "./StageOneDiagnostics.scss";

const TICK_DATE_FORMAT = "M/yyyy";
const LONG_DATE_FORMAT = "M/dd/yyyy h:mm:ss a";
const TICK_COUNT = 8;

const StageOneDiagnostics = ({ kpi, branch }) => {
  const setError = useSetError();
  const [dataMap, setDataMap] = useState({});

  let data = dataMap[`${kpi}_${branch}`];

  useEffect(() => {
    if (dataMap[`${kpi}_${branch}`]) {
      return;
    }
    let bail = false;
    (async () => {
      try {
        let res = await LinearOptimizationsLambdaFetch("/get_stage_one", {
          params: {
            kpi,
            branch,
          },
        });

        const { id } = await awaitJSON(res);

        let dataRes = await pollS3({
          bucket: "processed-stage-one-diagnostics",
          mimeType: "application/json",
          filename: `${id}.json`,
          autoDownload: false,
          noTimeout: true,
        });

        const dataText = await dataRes.text();

        const data = JSON.parse(dataText);

        let pdfRes = await S3SignedUrlFetch(
          `bpm-ml-data/${branch}/${kpi}/latest/smoothed_impulse_response_curve.pdf`
        );

        if (bail) {
          return;
        }
        setDataMap(current => ({
          ...current,
          [`${kpi}_${branch}`]: { ...data, pdfURL: pdfRes.url },
        }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
    return () => {
      bail = true;
    };
  }, [dataMap, kpi, branch, setError]);

  const ticks = useMemo(() => {
    if (!data || !data.allValues) {
      return [];
    }
    if (R.isEmpty(data)) {
      return;
    }
    let ticks = [];
    let min = data.allValues[0].time;
    let max = data.allValues[data.allValues.length - 1].time;
    // We do TICK_COUNT - 1 so we can always make the final value the max.
    let step = Math.round((max - min) / (TICK_COUNT - 1));
    // Skip the last tick because we're adding it manually after.
    for (let i = 0; i < TICK_COUNT - 1; ++i) {
      ticks.push(min + step * i);
    }
    ticks.push(max);
    return ticks;
  }, [data]);

  return (
    <div className="stageOneDiagnostics">
      {data ? (
        !R.isEmpty(data) && data.allValues ? (
          <>
            <div className="chartsContainer">
              <div className="bigChart">
                <AutoSizer>
                  {({ width, height }) => (
                    <LineChart
                      width={width}
                      height={height}
                      data={data.allValues}
                      margin={{ right: 50 }}
                    >
                      <Line
                        dataKey="moving_average"
                        type="monotone"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={false}
                        isAnimationActive={false}
                      />
                      <XAxis
                        padding={{ left: 50, right: 50 }}
                        dataKey="time"
                        type="number"
                        allowDecimals={false}
                        domain={["dataMin", "dataMax"]}
                        ticks={ticks}
                        tickFormatter={time => Dfns.format(TICK_DATE_FORMAT, new Date(time))}
                      />
                      <YAxis />
                      <Tooltip
                        isAnimationActive={false}
                        labelFormatter={time => Dfns.format(LONG_DATE_FORMAT, new Date(time))}
                        formatter={value => {
                          let formattedValue = value.toLocaleString();

                          return [formattedValue, "Moving Average"];
                        }}
                      />
                      <ReferenceLine
                        label={{
                          position: "insideBottomRight",
                          value: "99th Percentile",
                        }}
                        y={data.percentile99}
                        stroke="red"
                        strokeDasharray="10 20"
                        strokeWidth={1}
                      />
                      <ReferenceLine
                        label={{
                          position: "insideBottomRight",
                          value: "1st Percentile",
                        }}
                        y={data.percentile1}
                        stroke="red"
                        strokeDasharray="10 20"
                        strokeWidth={1}
                      />
                    </LineChart>
                  )}
                </AutoSizer>
              </div>
              <div className="impulseResponseCurve">
                <embed
                  src={`${data.pdfURL}#toolbar=0&navpanes=0&scrollbar=0`}
                  height="100%"
                  width="100%"
                />
              </div>
            </div>

            <div className="stageOneDiagnosticsSummary">
              <h3 className="summaryTitle">AdRawEffect Summary</h3>
              <div className="summaryStatistics">
                <div>
                  <span className="summaryLabel">Min:</span>
                  {`${parseFloat(data.adRawEffectStatistics.min).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">Max:</span>
                  {`${parseFloat(data.adRawEffectStatistics.max).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">Mean:</span>
                  {`${parseFloat(data.adRawEffectStatistics.mean).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">Median:</span>
                  {`${parseFloat(data.adRawEffectStatistics.median).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">Standard Deviation:</span>
                  {`${parseFloat(data.adRawEffectStatistics.stdev).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">Kurtosis:</span>
                  {`${parseFloat(data.adRawEffectStatistics.kurtosis).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">STDEV to Min:</span>
                  {`${parseFloat(data.adRawEffectStatistics.minMedianStDev).toFixed(2)}`}
                </div>
                <div>
                  <span className="summaryLabel">STDEV to Max:</span>
                  {`${parseFloat(data.adRawEffectStatistics.maxMedianStDev).toFixed()}`}
                </div>
                <div>
                  <span className="summaryLabel">Start:</span>
                  {data.adRawEffectStatistics.startDate}
                </div>
                <div>
                  <span className="summaryLabel">End:</span>
                  {data.adRawEffectStatistics.endDate}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="noStageOneData">No Stage One Data for this KPI</div>
        )
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

export default StageOneDiagnostics;
