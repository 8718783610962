import { QuestionCardProps } from "../../Components/QuestionCard/QuestionCard";

export const budgetStrategy = [
  {
    "id": "ce6efce1-31f0-4c2a-8b7c-6d9a52e70639",
    "questionNumber": "4A1",
    "title": "How much authority does Tinuiti have to re-allocate budgets?",
    "type": "radio",
    "options": ["None", "Some. Tinuiti can adjust budget:", "Complete authority"],
    "nestedOptionsConfig": {
      "Some. Tinuiti can adjust budget:": [
        {
          "inputType": "checkbox",
          "options": ["Between channels", "Between platforms (within a channel)", "Between campaigns (within a platform)", "Between time periods", "Up to reach performance goals", "Down to reach performance goals"]
        }
      ]
    },
  },
  {
    "id": "2861d069-2d0c-4890-b221-d21b57e1e089",
    "questionNumber": "4A2",
    "title": "Do you require POs on your Tinuiti invoices?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": [
        {
          "inputType": "textarea",
          "label": "In what capacity?",
          "placeholder": "Example Text"
        },
        {
          "inputType": "textarea",
          "label": "How do you need them broken out?",
          "placeholder": "Example Text"
        },
        {
          "inputType": "textarea",
          "label": "When can we expect to receive these?",
          "placeholder": "Example Text"
        }
      ]
    }
  },
  {
    "id": "afdb30c3-88e6-4ca9-831e-c79c4d271242",
    "questionNumber": "4A3",
    "title": "Does your billing team need to speak with us prior to engagement?",
    "type": "radio",
    "options": ["Yes", "No"]
  },
  {
    "id": "78a99abe-2e11-4645-8e6b-f9e928fac5c1",
    "questionNumber": "4A4",
    "title": "Are there any billing or invoicing nuances not captured here?",
    "type": "radio",
    "options": ["Yes", "No"]
  }
] as QuestionCardProps[];

export const budgetDetails = [
  {
    "id": "7bfb0f90-c57f-41e9-bf1f-e2bc8200fab3",
    "questionNumber": "4B1",
    "title": "Are you expecting a large shift in budgets the month Tinuiti takes over?",
    "type": "radio",
    "options": ["Yes", "No", "Unsure"],
  },
  {
    "id": "b0d11c34-c957-4ecd-ae12-a735332cde17",
    "questionNumber": "4B2",
    "title": "Is there a specific campaign, effort, or platform that requires special attention as soon as Tinuiti takes over?",
    "subtitle": "Ex: time-sensitive, new product launch, large promotion, etc.",
    "type": "radio",
    "options": ["Yes", "No"],
  },
  {
    "id": "c9a8c39e-9bc7-452c-9f83-566b6cb4b9d1",
    "questionNumber": "4B3",
    "title": "Please upload any upcoming initiatives that we need to be aware of prior to engagement.",
    "subtitle": "Ex: promotional, product, or media plans",
    "type": "upload",
    "uploadQuestionTitle": "upcoming-initiatives"
  }
] as QuestionCardProps[];
