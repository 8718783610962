import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import {
  INC_CDN,
  IncPlaybookSectionBox,
  PAGE_PREFIX,
  navigateToSection,
} from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Geo Testing";

const GeoTesting = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Specific geographic regions are randomly assigned to the treatment or control group.
              By comparing the outcome time trends in the treatment regions to the control regions,
              the causal impact of the campaign can be assessed at a broad level.
            </div>
            <br />
            <div className="basicText">
              This approach has a highly flexible setup and can be applied to any channel in a
              client's portfolio (Display, Streaming, Linear, Paid Social, etc.).
            </div>
            <br />
            <div className="basicText">
              *See <a href={`${PAGE_PREFIX}/Incrementality101`}>Incrementality 101</a> for more
              information on the key differences between user and geo level splits.
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Test Execution & Setup",
                "Test Considerations",
                "Power Analysis",
                "Post-Test Incrementality",
                "Sample Output",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Our Client Strategy and Media Team partner with the client to run media in the
              specified live markets at the spend and test duration levels chosen based upon the
              power analysis run by the Econometrics Team.
            </div>
            <br />
            <div className="basicHeader">Steps</div>
            <ol className="basicList">
              <li>
                Pass along requested test specifications (feasible spend, number of treatment cells,
                any markets to exclude, etc.) to your Marketing Science/Econometrics contact to run
                a power simulation.
              </li>
              <li>
                Once the power simulation is complete, pass along the selected dark/live DMA list to
                your media contact to begin sourcing geo-targeted media in the live DMA's for the
                chosen duration of the test.
              </li>
            </ol>
            <br />
            <Img src={`${INC_CDN}/GeoTesting_Diagram.png`} />
          </div>
        }
        id={"test-execution-&-setup"}
        pageTitle={PAGE_TITLE}
        title={"Test Execution & Setup"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader">Reasons for Including Top DMAs:</div>
            <ol className="basicList">
              <li>
                Statistical Validity: Ensuring a large enough sample size is crucial for detecting
                meaningful differences in results. This is especially important in tests aimed at
                achieving high statistical power.
              </li>
              <li>
                Market Representation: Including top DMAs may better represent the overall
                population or the specific target market, providing insights that are more
                applicable across broader scopes.
              </li>
              <li>
                Revenue Impact: Focusing on areas that drive significant sales or traffic can
                translate into more substantial and tangible revenue impacts, making the results
                more actionable.
              </li>
            </ol>
            <br />
            <div className="basicHeader">Reasons for Excluding Top DMAs:</div>
            <ol className="basicList">
              <li>
                Risk Management: If there is concern that the test could negatively impact sales,
                excluding top DMAs can be a safer approach, helping to minimize the risk to
                top-performing markets.
              </li>
              <li>
                Market Saturation: Top DMAs may experience market saturation, making them less
                sensitive to incremental changes. Excluding these DMAs can yield clearer insights
                into areas with more growth potential.
              </li>
              <li>
                Data Variance Reduction: Excluding top DMAs might reduce variance in the data,
                facilitating the detection of effects not driven solely by volume, traffic, or
                sales.
              </li>
            </ol>
            <br />
            <div className="basicHeader">Best Practices for DMA Inclusion in Test Groups:</div>
            <ul className="basicList">
              <li>
                Balanced Test Design: Include a similar number of high-performing DMAs in both the
                treated and control groups to avoid upward bias in the estimates.
              </li>
              <li>
                Segmentation: Consider segmenting DMAs based on criteria such as population size,
                demographics, etc. This ensures that each market segment is represented in the test,
                allowing for a more nuanced understanding of how different types of markets respond
                to changes.
              </li>
            </ul>
          </div>
        }
        id={"test-considerations"}
        pageTitle={PAGE_TITLE}
        title={"Test Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Before the geo-test is executed, Econometrics will spec out a{" "}
              <a href="https://tinuiti.atlassian.net/wiki/spaces/MARKETING/pages/2459303983/Geo-Test+Power+Simulation">
                Power Analysis
              </a>
              .
            </div>
            <br />
            <div className="basicText">
              In the process of calculating these probabilities, our power analysis routine uses
              randomized market splits engineered to yield balanced test groups for the experiment.
              These splits are outputted in the analysis to be used for the execution of the test.
              The client ultimately decides which random split to use based on the number of markets
              they would like to go dark in.
            </div>
            <br />
            <div className="basicHeader">Power Analysis Determines</div>
            <ul className="basicList">
              <li>Weeks the test will run…</li>
              <li>Weekly media spend in live markets…</li>
              <li>Which markets will go dark or remain live…</li>
              To hit statistical significance with high probability.
            </ul>
            <br />
            <div className="basicHeader">Others (e.g. Hulu, Tubi, etc.)</div>
            <ul className="basicList">
              <li>Media spend levels</li>
              <li>Test lengths</li>
              <li>Proportion of markets to go dark</li>
              <li>CPMs</li>
              <li>Assumed incrementality levels</li>
            </ul>
          </div>
        }
        id={"power-analysis"}
        pageTitle={PAGE_TITLE}
        title={"Power Analysis"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              Econometrics typically provides incrementality results within 1-2 weeks after the
              completion of the test. The lead time allows for data maturation and for the Econ team
              member to put together the analysis and deliverable.
            </div>
            <br />
            <div className="basicText">
              The Econometrics team has the capability of providing mid-point read-outs during the
              test, but typically doesn’t advise this as results are not likely stable or
              informative until the test is complete to the specifications of the power analysis.
            </div>
          </div>
        }
        id={"post-test-incrementality"}
        pageTitle={PAGE_TITLE}
        title={"Post-Test Incrementality"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <iframe
              allowFullScreen
              src={
                "https://drive.google.com/file/d/1C-KuNwEQ82h7TK9TeGQwJFb4iggT_J_A/preview?authuser=0"
              }
              title={"Sample Output"}
            />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
    </div>
  );
});

export default GeoTesting;
