import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from "react-select";

import { Card, BPMDateRange } from "../../Components";
import { InputGroup, Form, Button, Spinner, Modal } from "react-bootstrap";
import {
  awaitJSON,
  CreativeLambdaFetch,
  SheetsLambdaFetch,
  StreamingV2LambdaFetch,
} from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

import { DateRange } from "../../utils/types";
import { DerivedNetwork } from "../../redux/networks";
import { useSelector } from "react-redux";
import { emailSelector } from "../../redux/user";
import * as R from "ramda";

export enum Device {
  DESKTOP = "desktop",
  PHONE = "phone",
  SPEAKER = "speaker",
  TABLET = "tablet",
  TV = "tv",
}

export enum OS {
  IOS = "ios",
  LINUX = "linux",
  SONOS = "sonos",
  ANDROID = "android",
  FIRETV = "firetv",
  ROKU = "roku",
}

const Bonuser: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [oses, setOses] = useState<string[]>();
  const [devices, setDevices] = useState<string[]>();
  const [iscis, setIscis] = useState<string[]>();

  const deviceOptions = [Device.DESKTOP, Device.PHONE, Device.SPEAKER, Device.TABLET, Device.TV];
  const osOptions = [OS.IOS, OS.LINUX, OS.SONOS, OS.ANDROID, OS.FIRETV, OS.ROKU];

  const email = useSelector(emailSelector);

  const [dates, setDates] = useState<DateRange>();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [jira, setJira] = useState("");

  const [company, setCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState<string[]>();

  const [dryRun, setDryRun] = useState<boolean>(false);
  const [overrideRS, setOverrideRS] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [count, setCount] = useState<number | null>(null);

  const [derivedID, setDerivedID] = useState("");
  const [derivedNetworkMap, setDerivedNetworkMap] = useState({});

  const [isciOptions, setIsciOptions] = useState({});

  useEffect(() => {
    if (!companyOptions) {
      (async () => {
        let res = await SheetsLambdaFetch("/get_company_ids");
        let companies = await awaitJSON<{ ids: string[] }>(res);
        setCompanyOptions(companies.ids);
      })();
    }
  });

  const companyOptionList = useMemo(
    () => (companyOptions || []).map(value => ({ value, label: value })),
    [companyOptions]
  );

  useEffect(() => {
    (async () => {
      let res = await StreamingV2LambdaFetch("/derived", { params: { company } });
      let derivedNetworks = await awaitJSON<Record<string, DerivedNetwork>>(res);
      setDerivedNetworkMap(derivedNetworks);
    })();
  }, [company]);

  const derivedNetworkOptionList = useMemo(
    () => Object.keys(derivedNetworkMap || {}).map(value => ({ value, label: value })),
    [derivedNetworkMap]
  );

  useEffect(() => {
    if (company) {
      (async () => {
        let res = await CreativeLambdaFetch("/", {
          params: {
            company,
            timeline: 1,
            include_retired: true,
          },
        });
        const isciOptions = await awaitJSON(res);
        setIsciOptions(isciOptions);
      })();
    }
  }, [company]);

  const isciOptionList = useMemo(
    () => Object.keys(isciOptions || {}).map(value => ({ value, label: value })),
    [isciOptions]
  );

  const automaticBonus = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await StreamingV2LambdaFetch("/bonus", {
        method: "POST",
        body: JSON.stringify({
          start,
          end,
          company,
          derivedID,
          count,
          devices,
          oses,
          jira,
          iscis,
          dryRun,
          overrideRS,
          email,
        }),
      });
      setDates(undefined);
      setCompany("");
      setStart("");
      setEnd("");
      setDerivedID("");
      setCount(null);
      setJira("");
      setOverrideRS(false);
      setDryRun(false);
      setDevices([]);
      setOses([]);
      setIscis([]);
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [
    start,
    end,
    company,
    derivedID,
    count,
    devices,
    oses,
    jira,
    iscis,
    dryRun,
    overrideRS,
    email,
    setError,
  ]);
  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Bonuser</div>
      <BPMDateRange
        range={dates}
        onChange={option => {
          setDates(option);
          setStart(option.start);
          let tempDate = new Date(option.end);
          tempDate.setDate(tempDate.getDate() + 2);
          tempDate.setHours(0, 0, 0, 0);
          const newEnd = tempDate.toISOString().slice(0, 10);
          setEnd(newEnd);
        }}
      />
      <Select
        className="select adOpsElem"
        placeholder="Select from existing companies"
        isSearchable
        isClearable
        options={companyOptionList}
        value={company ? { label: company, value: company } : null}
        onChange={option => {
          setCompany(option?.value);
        }}
      />
      {company && (
        <Select
          className="select adOpsElem"
          placeholder="Select old Derived ID"
          isSearchable
          isClearable
          options={derivedNetworkOptionList}
          onChange={option => {
            setDerivedID(option?.value);
          }}
        />
      )}
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Count</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={count || ""}
          onChange={e => setCount(e.target.value ? parseInt(e.target.value) : null)}
        />
      </InputGroup>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Jira</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={jira} onChange={e => setJira(e.target.value)} />
      </InputGroup>
      <div className="adOpsElem">
        <Form.Check
          className="adOpsFormCheck"
          inline
          label="dryRun"
          type="checkbox"
          checked={dryRun}
          onChange={() => {
            setDryRun(!dryRun);
          }}
        ></Form.Check>
        <Form.Check
          className="adOpsFormCheck"
          inline
          label="overrideRS"
          type="checkbox"
          checked={overrideRS}
          onChange={() => {
            !overrideRS ? setShowConfirmation(true) : setShowConfirmation(false);
            setOverrideRS(false);
          }}
        ></Form.Check>
      </div>
      <Modal
        show={showConfirmation}
        onHide={() => {
          setShowConfirmation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to override RS?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmation(false);
              setOverrideRS(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowConfirmation(false);
              setOverrideRS(true);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Select
        className="select adOpsElem"
        placeholder="Select relevant devices"
        isClearable
        isMulti
        options={R.map(deviceOptions => {
          return { label: deviceOptions, value: deviceOptions };
        }, deviceOptions)}
        value={
          devices
            ? devices.map(device => {
                return { label: device, value: device };
              })
            : null
        }
        onChange={selections => {
          let arr: string[] = [];
          selections?.forEach(element => {
            arr.push(element.value);
          });
          setDevices(arr);
        }}
      />
      <Select
        className="select adOpsElem"
        placeholder="Select relevant OS"
        isClearable
        isMulti
        options={R.map(osOptions => {
          return { label: osOptions, value: osOptions };
        }, osOptions)}
        value={
          oses
            ? oses.map(os => {
                return { label: os, value: os };
              })
            : null
        }
        onChange={selections => {
          let arr: string[] = [];
          selections?.forEach(element => {
            arr.push(element.value);
          });
          setOses(arr);
        }}
      />
      {company && (
        <Select
          className="select adOpsElem"
          placeholder="Select relevant isci"
          isClearable
          isMulti
          options={isciOptionList}
          onChange={selections => {
            let arr: string[] = [];
            selections?.forEach(element => {
              arr.push(element.value);
            });
            setIscis(arr);
          }}
        />
      )}
      <Button className="adOpsElem" onClick={automaticBonus}>
        {activeAPICall ? <Spinner animation={"border"} /> : "Submit"}
      </Button>
    </Card>
  );
};

export default Bonuser;
