import React from "react";
import { VOLUME_FORMATTER } from "./homePageUtils";
import "./ChartTooltip.scss";

interface PayloadItem {
  label: string | number | undefined;
  value: number | undefined;
  color: string | undefined;
  styles?: Record<string, any>;
}
interface ChartTooltipProps {
  headerLabel: string;
  subHeaderLabel?: string;
  subHeaderValue?: number; // If not provided, we will sum item values by default.
  subHeaderValueFormatter?: (value: number) => string;
  items: PayloadItem[];
  itemFormatter?: (value: number) => string;
  itemKeyShape?: "circle" | "square" | "line" | "dottedLine";
  otherSubHeaderLabel?: string;
  otherSubHeaderValue?: number; // If not provided, we will sum other item values by default.
  otherSubHeaderValueFormatter?: (value: number) => string;
  otherItems?: PayloadItem[];
  otherItemFormatter?: (value: number) => string;
  otherItemKeyShape?: "circle" | "square" | "line" | "dottedLine";
  comparisonHeaderLabel?: string;
  comparisonItems?: PayloadItem[];
  comparisonItemKeyShape?: "circle" | "square" | "line" | "dottedLine";
  footer?: string;
}
export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  headerLabel,
  subHeaderLabel,
  subHeaderValue,
  subHeaderValueFormatter = VOLUME_FORMATTER,
  items,
  itemFormatter = VOLUME_FORMATTER,
  itemKeyShape = "circle",
  otherSubHeaderLabel,
  otherSubHeaderValue,
  otherSubHeaderValueFormatter = VOLUME_FORMATTER,
  otherItems,
  otherItemFormatter = VOLUME_FORMATTER,
  otherItemKeyShape = "square",
  comparisonHeaderLabel,
  comparisonItems,
  comparisonItemKeyShape = "circle",
  footer,
}) => {
  let ourSubHeaderValue = subHeaderValue;
  let ourOtherSubHeaderValue = otherSubHeaderValue;

  // If a sub header value isn't provided, we sum the numbers by default.
  // But if no sub header label is provided, we ignore the entire sub header and don't render it.
  if (subHeaderLabel && !subHeaderValue) {
    ourSubHeaderValue = items.reduce((prev, curr) => prev + (curr.value || 0), 0);
  }
  if (otherSubHeaderLabel && !otherSubHeaderValue && otherItems) {
    ourOtherSubHeaderValue = otherItems.reduce((prev, curr) => prev + (curr.value || 0), 0);
  }
  return (
    <div className="chartTooltip">
      <div className="headerLabel">{headerLabel}</div>
      {subHeaderLabel && (
        <div className="subHeader">
          <span className="label">{subHeaderLabel}: </span>
          <span className="value">{subHeaderValueFormatter(ourSubHeaderValue || 0)}</span>
        </div>
      )}
      <div className="itemList">
        {items.map((item, i) => {
          const { label, value, color, styles } = item;
          if (!value) {
            return <></>;
          }
          return (
            <div className="itemRow" key={i}>
              <ColorKey shape={itemKeyShape} color={color} />
              <div className="name" style={{ ...styles }}>{`${label}: `}</div>
              <div className="value">{itemFormatter(value)}</div>
            </div>
          );
        })}
      </div>
      {otherItems && (
        <>
          <div className="subHeader other">
            <span className="label">{otherSubHeaderLabel}: </span>
            <span className="value">
              {otherSubHeaderValueFormatter(ourOtherSubHeaderValue || 0)}
            </span>
          </div>
          <div className="itemList">
            {otherItems.map((item, i) => {
              const { label, value, color } = item;
              if (!value) {
                return <></>;
              }
              return (
                <div className="itemRow" key={i}>
                  <ColorKey shape={otherItemKeyShape} color={color} />
                  <div className="name">{`${label}: `}</div>
                  <div className="value">{otherItemFormatter(value)}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="headerLabel">{comparisonHeaderLabel}</div>
      <div className="itemList">
        {(comparisonItems || []).map((item, i) => {
          const { label, value, color } = item;
          if (!value) {
            return <></>;
          }
          return (
            <div className="itemRow" key={i}>
              <ColorKey shape={comparisonItemKeyShape} color={color} />
              <div className="name">{`${label}: `}</div>
              <div className="value">{itemFormatter(value)}</div>
            </div>
          );
        })}
      </div>
      {footer && <div className="footer">{footer}</div>}
    </div>
  );
};

interface ColorKeyProps {
  shape: "circle" | "square" | "line" | "dottedLine";
  color: string | undefined;
}

const ColorKey: React.FC<ColorKeyProps> = React.memo(({ shape, color }) => {
  return (
    <>
      {shape === "dottedLine" ? (
        <hr className={`${shape}`} style={{ borderTop: `dotted 3px ${color}` }} />
      ) : (
        <div className={`${shape}`} style={{ backgroundColor: color }} />
      )}
    </>
  );
});

export default ChartTooltip;
