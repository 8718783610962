import { OOHBuy } from "@blisspointmedia/bpm-types/dist/OutOfHomeBuys";

const companyLabel = {
  label: "Company",
  field: "company",
  type: "string",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};

const campaignLabel = {
  label: "Campaign",
  field: "campaign",
  type: "string",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};

const startDateLabel = {
  label: "Start Date",
  field: "start_date",
  type: "day",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};
const endDateLabel = {
  label: "End Date",
  field: "end_date",
  type: "day",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};

const marketLabel = {
  label: "Market",
  field: "market",
  type: "string",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};

const formatLabel = {
  label: "Format",
  field: "format",
  type: "string",
  flex: 1,
  modalRow: 0,
  modalFlex: 1,
};

const descriptionLabel = {
  label: "Description",
  field: "description",
  type: "string",
  flex: 3,
  modalRow: 1,
  modalFlex: 3,
};

const unitNumberLabel = {
  label: "Unit Number",
  field: "unit_number",
  type: "string",
  flex: 1,
  modalRow: 2,
  modalFlex: 1,
};

const mediaCostLabel = {
  label: "Media Cost",
  field: "media_cost",
  type: "decimal",
  flex: 1,
  modalRow: 2,
  modalFlex: 1,
};

const productionTotalLabel = {
  label: "Production Total",
  field: "production_total",
  type: "number",
  flex: 1,
  modalRow: 2,
  modalFlex: 1,
};

const installationTotalLabel = {
  label: "Installation Total",
  field: "installation_total",
  type: "number",
  flex: 1,
  modalRow: 2,
  modalFlex: 1,
};

const totalCostLabel = {
  label: "Total Cost",
  field: "total_cost",
  type: "decimal",
  flex: 1,
  modalRow: 3,
  modalFlex: 1,
};

const totalImpressionsLabel = {
  label: "Total Impressions",
  field: "total_impressions",
  type: "decimal",
  flex: 1,
  modalRow: 3,
  modalFlex: 1,
};

const cpmLabel = {
  label: "CPM",
  field: "cpm",
  type: "decimal",
  flex: 1,
  modalRow: 3,
  modalFlex: 1,
};

const pdf = {
  label: "Contract PDF",
  field: "pdf",
  type: "file",
  flex: 10,
  modalRow: 4,
  modalFlex: 10,
  renderer: (data: OOHBuy): any => {
    if (data.pdf && data.pdf !== "") {
      return (
        <a
          href={`https://cdn.blisspointmedia.com/${data.pdf}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View PDF
        </a>
      );
    }
    return "No Contract Uploaded";
  },
};

const headers = [
  companyLabel,
  campaignLabel,
  startDateLabel,
  endDateLabel,
  marketLabel,
  formatLabel,
  descriptionLabel,
  unitNumberLabel,
  mediaCostLabel,
  productionTotalLabel,
  installationTotalLabel,
  totalCostLabel,
  totalImpressionsLabel,
  cpmLabel,
  pdf,
];

export default headers;
