import { AD_SERVER_MAP, StreamingCreativesContext } from "./StreamingCreatives";
import { BPMTable } from "../Components";
import { Button } from "react-bootstrap";
import { MdAddToPhotos, MdAdd } from "react-icons/md";
import { NonCartContext } from "./NonCart";
import { useCreativeMap } from "../redux/creative";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import React, { useContext, useCallback, useMemo } from "react";
import useLocation from "../utils/hooks/useLocation";

const Menu = () => {
  const { company } = useLocation();
  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });
  const {
    shopData,
    selectedMap,
    setSelectedMap,
    inCart,
    setSelectedValue,
    platform,
    setPlatform,
    adServer,
    setAdServer,
  } = useContext(StreamingCreativesContext);
  const { setViewPlacementId, filteredPlacements, setFilteredPlacements } = useContext(
    NonCartContext
  );

  const filteredLines = useMemo(
    () =>
      R.pipe(
        R.prop("placements"),
        R.filter(({ id }) => !(selectedMap[id] || inCart[id]))
      )(shopData),
    [shopData, selectedMap, inCart]
  );

  const setError = useSetError();

  const headers = useMemo(
    () => [
      {
        label: "",
        name: "id",
        width: 63,
        nonInteractive: true,
        renderer: ({ id }) => (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              if (adServer && platform && Object.values(selectedMap)) {
                if (
                  shopData.placementMap[id].platform.split(" ")[0] === platform &&
                  shopData.placementMap[id].adServer === adServer
                ) {
                  setSelectedValue(id, true);
                } else if (adServer !== shopData.placementMap[id].adServer) {
                  setError({
                    message: `You are currently editing ${AD_SERVER_MAP[adServer]} placements. Please commit these changes before selecting placements for ${shopData.placementMap[id].adServer}.`,
                  });
                } else {
                  setError({
                    message: `You are currently editing ${platform} placements. Please commit these changes before selecting placements for other platforms.`,
                  });
                }
              } else {
                setPlatform(shopData.placementMap[id].platform.split(" ")[0]);
                setAdServer(shopData.placementMap[id].adServer);
                setSelectedValue(id, true);
              }
            }}
          >
            <MdAdd />
          </Button>
        ),
      },
      {
        label: "Network",
        name: "network",
        flex: 1,
      },
      {
        label: "Network Group",
        name: "network_group",
        flex: 1,
        minFlexWidth: 140,
      },
      {
        label: "Platform",
        name: "platform",
        flex: 1,
        minFlexWidth: 140,
      },
      {
        label: "Tag Name",
        name: "name",
        flex: 3,
      },
      {
        label: "Campaign",
        name: "campaign",
        flex: 1,
        renderer: row => (row.campaign ? row.campaign : "N/A"),
      },

      {
        label: "Ad Server",
        name: "adServer",
        flex: 1,
        renderer: ({ adServer }) =>
          adServer ? (adServer === "xr" ? "Extreme Reach" : "Flashtalking") : "N/A",
      },
      {
        label: "",
        name: "id",
        width: 160,
        nonInteractive: true,
        renderer: ({ id }) => (
          <Button variant="link" size="sm" onClick={() => setViewPlacementId(id)}>
            View Placement
          </Button>
        ),
      },
    ],
    [
      adServer,
      platform,
      selectedMap,
      shopData.placementMap,
      setSelectedValue,
      setError,
      setPlatform,
      setAdServer,
      setViewPlacementId,
    ]
  );

  const additionalFilterOptions = useMemo(
    () => [
      {
        label: "Start Date",
        name: "placement_start",
      },
      {
        label: "End Date",
        name: "placement_end",
      },
      {
        label: "ISCI",
        name: "isci",
        optionsGetter: placement => {
          let options = [];
          if (R.path(["creatives", "length"], placement)) {
            for (let { isci } of placement.creatives) {
              options.push(isci);
            }
          }
          return options;
        },
      },
      {
        label: "Creative",
        name: "creativeName",
        optionsGetter: placement => {
          let options = [];
          if (R.path(["creatives", "length"], placement)) {
            for (let { isci } of placement.creatives) {
              options.push(R.path([isci, "name"], creativeMap));
            }
          }
          return options;
        },
      },
    ],
    [creativeMap]
  );

  const headersRenderer = useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                let newMap = { ...selectedMap };

                for (let id of filteredPlacements) {
                  newMap[id] = true;
                }
                setSelectedMap(newMap);
              }}
            >
              <MdAddToPhotos />
            </Button>
          );
        default:
          return data;
      }
    },
    [filteredPlacements, selectedMap, setSelectedMap]
  );

  return (
    <>
      <BPMTable
        data={R.filter(line => line.adServer === "xr" || line.adServer === "ft", filteredLines)}
        headers={headers}
        cellClassName="placementTableCell"
        headerHeight={50}
        rowHeight={40}
        headersRenderer={headersRenderer}
        additionalFilterOptions={additionalFilterOptions}
        noRowsRenderer={() => <div>No Placements</div>}
        onFilteredDataChange={setFilteredPlacements}
      />
    </>
  );
};

export default Menu;
