import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "TikTok";

const TikTok = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              TikTok is a short-form video-native social media feed. TikTok is mostly mobile-based
              but does have web capabilities. TikTok's platform native incrementality approach is a
              user segmented approach.
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Sample Output",
                "Signal Enrichment",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift Study",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>To kick off the experiment contact your TikTok rep.</li>
                      <li>
                        The TikTok Conversion Lift Study is a user-segmented incrementality test.
                      </li>
                      <li>
                        The users are split into a treatment and a control group. campaign targeting
                        is then applied to create even groups.
                      </li>
                      <li>
                        Advertisements are served to the treatment group while the control group are
                        withheld from seeing ads.
                      </li>
                      <li>
                        Looking at the difference in conversions between the treatment and control
                        groups the full impact of the ads can be understood.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Establishes a baseline of advertisement effectiveness through causal
                        inference.
                      </li>
                      <li>
                        Includes a power analysis to ensure that statistical significance is met.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>App events are limited to Android only.</li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              500
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Ad Selection",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Using a mix of brand videos and creator made videos can help to make sure
                        ads for the test feel native to the platform.
                      </li>
                      <li>Lean into current trends.</li>
                    </ul>
                  ),
                },
                {
                  Element: "Targets",
                  Details: (
                    <ul className="basicList">
                      <li>Web events</li>
                      <li>App events (Android only)</li>
                    </ul>
                  ),
                },
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Reach Out to your TikTok reps with the start and end dates for the study.
                      </li>
                      <li>The TikTok team will set up the study.</li>
                      <li>Studies are typically 30 days in length.</li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              100
            )}
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <Img src={`${INC_CDN}/TikTok_Sample.png`} />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              To enhance our marketing strategies on TikTok, implementing the Events API is crucial.
              This tool establishes a robust connection between TikTok and Tinuiti's clients
              marketing data. By incorporating Events API, Tinuiti clients can gain improved ad
              delivery and targeting through the capture of previously missed conversions, which is
              especially beneficial given the potential for connectivity issues and browser
              inconsistencies that can affect conversions reported via Pixel.
            </div>
            <br />
            <div className="basicText">
              The Events API offers granular control over the data shared with TikTok, ensuring only
              necessary information is transmitted to meet marketing goals. This is increasingly
              important as the advertising landscape evolves, necessitating resilient solutions.
              When used alongside TikTok Pixel, the Events API enriches the conversion data,
              enhancing full-funnel measurement, ad delivery, and audience creation, making it the
              recommended setup.
            </div>
            <br />
            <div className="basicText">
              To maximize campaign performance, TikTok advises sharing consistent events,
              parameters, and match keys across both Pixel and Events API setups. This approach
              ensures accurate deduplication and improved campaign measurement and performance.
              Consult TikTok's documentation on standard and custom events, match keys, and privacy
              and data usage to fully leverage the Events API's capabilities{" "}
              <a href="https://ads.tiktok.com/help/article/events-api?lang=en">here</a>.
            </div>
            <br />
            <div className="basicText">
              There are 3 ways to set up Web site conversions on TikTok. See below for benefits of
              each of these set up scenarios:
            </div>
            <br />
            {playbookTable(
              [
                {
                  Impact: "Benefits",
                  "Events API": (
                    <ul className="basicList">
                      <li>
                        Enables more sustainable event sharing between your business and TikTok.
                      </li>
                      <li>Improves ad delivery and targeting by capturing missed conversions.</li>
                      <li>More control over what data your business shares with TikTok.</li>
                    </ul>
                  ),
                  Pixel: (
                    <ul className="basicList">
                      <li>Lightweight implementation.</li>
                      <li>Easy customer event capture and reporting.</li>
                      <li>Automatic updates to pixel performance included.</li>
                    </ul>
                  ),
                  "Pixel + Events": (
                    <ul className="basicList">
                      <li>Events API enriches conversions shared by Pixel.</li>
                      <li>
                        Enriched conversions enhances full-funnel measurement, ad delivery, and
                        audience creation.
                      </li>
                      <li>Enables sustainable transition to ad industry changes.</li>
                    </ul>
                  ),
                },
                {
                  Impact: "Time to Implement",
                  "Events API": (
                    <ul className="basicList">
                      <li>Direct API implementation can take between 1-4 weeks.</li>
                      <li>
                        Commerce & Data Partner integrations will vary by partner. It can be less
                        than 1 hour.
                      </li>
                    </ul>
                  ),
                  Pixel: (
                    <ul className="basicList">
                      <li>Manual Pixel code implementation often takes a few minutes</li>
                      <li>
                        Integration with 3rd party Pixel partners may vary. Often can take less than
                        a few minutes.
                      </li>
                    </ul>
                  ),
                  "Pixel + Events": (
                    <ul className="basicList">
                      <li>
                        Similar to the Events API implementation, with additional time to set up
                        event deduplication.
                      </li>
                    </ul>
                  ),
                },
              ],
              [
                {
                  flex: 2,
                  label: "Impact",
                  name: "Impact",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row.Impact}</div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  flex: 5,
                  label: "Events API",
                  name: "Events API",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["Events API"]}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Pixel",
                  name: "Pixel",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row.Pixel}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Pixel + Events",
                  name: "Pixel + Events",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["Pixel + Events"]}</div>;
                  },
                },
              ],
              200
            )}
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
    </div>
  );
});

export default TikTok;
