import { DateRange, typedReactMemo } from "../../utils/types";
import { DimensionMap } from "../MetricsTable/metricsTableUtils";
import { FullPageSpinner } from "../../Components";

import { overrideDateRange } from "../../utils/test-account-utils";
import { RouteComponentProps } from "@reach/router";
import { useSetError } from "../../redux/modals";
import * as C from "@blisspointmedia/bpm-types/dist/CommercePerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import SingleChannelPage from "../SingleChannelPage";
import useLocation from "../../utils/hooks/useLocation";
import {
  awaitJSON,
  SingleChannelLambdaFetch,
  StreamingPerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import {
  columnMetaDataMap,
  dimensionColumnMetaDataMap,
  getDimensionCell as SOCIAL_GET_DIMENSION_CELL,
  GLOSSARY,
} from "./commercePerformanceUtils";
import {
  DeletePresetParams as DeletePagePresetParams,
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  SavePresetParams as SaveTablePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

interface CommerceSingleChannelProps extends RouteComponentProps {
  urlSettings: string;
}

const CommerceSingleChannel = typedReactMemo<React.FC<CommerceSingleChannelProps>>(
  ({ location, urlSettings }) => {
    const [dates, setDates] = useState<DateRange>();
    const [fetchingPagePresets, setFetchingPagePresets] = useState(false);
    const [fetchingTablePresets, setFetchingTablePresets] = useState(false);
    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();
    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();
    const [pagePresets, setPagePresets] = useState<MetricsPagePreset[]>();
    const [tablePresets, setTablePresets] = useState<MetricsTablePreset[]>();
    const { company } = useLocation();
    const setError = useSetError();

    const getPagePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_page_presets",
            {
              params: {
                company,
                mediatype: "commerce",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setPagePresets(parsedPresets as MetricsPagePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch page presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingPagePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_table_presets",
            {
              params: {
                company,
                mediatype: "commerce",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setTablePresets(parsedPresets as MetricsTablePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch table presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingTablePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            const res = await SingleChannelLambdaFetch<GetTablePresetParams>(
              "/metrics_table_preset",
              {
                params,
              }
            );
            return await awaitJSON<MetricsTablePreset>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to fetch table preset. Error: ${error.message}`,
              reportError: error,
            });
          }
        })();
      },
      [setError]
    );

    const savePagePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<SavePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save page preset. Error: ${error.message}`,
              reportError: error,
            });
            presetResponse = { message: `Failed to save page preset. Error: ${error.message}` };
          } finally {
            getPagePresets();
            return presetResponse;
          }
        })();
      },
      [getPagePresets, setError]
    );

    const saveTablePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<SaveTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
            return presetResponse;
          }
        })();
      },
      [getTablePresets, setError]
    );

    const deletePagePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<DeletePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete page preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getPagePresets();
          }
        })();
      },

      [getPagePresets, setError]
    );

    const deleteTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<DeleteTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
          }
        })();
      },
      [getTablePresets, setError]
    );

    const getDeliveryData = useCallback(params => {
      return (async () => {
        const res = await SingleChannelLambdaFetch("/getChannelDelivery", {
          params: {
            ...params,
            filterState: params.filterState ? JSON.stringify(params.filterState) : undefined,
          },
        });
        return awaitJSON(res);
      })();
    }, []);

    const getPerformanceData = useCallback(
      params => {
        return (async () => {
          const dateRangeToUse = overrideDateRange(company, params.dates);
          const res = await await SingleChannelLambdaFetch<C.GetPageDataParams>(
            "/getChannelMetricsTableData",
            {
              params: {
                ...params,
                ...(dateRangeToUse || {}),
                channel: "commerce",
              },
            }
          );
          return await awaitJSON<C.GetPageDataResponse>(res);
        })();
      },
      [company]
    );

    const getDimensionCell = useCallback(
      (dimensionData: DimensionMap, dimensionHeader: DimensionColumn) =>
        SOCIAL_GET_DIMENSION_CELL(dimensionData, dimensionHeader),
      []
    );

    const filterDataProcessor = useCallback(
      (filterData, tablePreset, deliveryDimension, selectedTab) => {
        if (tablePreset) {
          const dimensions = R.map(
            R.prop("dimensionVarName"),
            R.defaultTo([], tablePreset.dimensionColumns)
          ) as string[];
          return R.map(filterRow => {
            const disabledRow = { ...filterRow, key: "disabled" };
            const DELIVERY = "delivery";
            if (
              (selectedTab === DELIVERY
                ? deliveryDimension === "Placement"
                : dimensions.includes("placement")) &&
              ["campaign_type", "match_type", "keyword", "product_id", "ad_name"].includes(
                (filterRow as any).key
              )
            ) {
              return disabledRow;
            }
            if (
              (selectedTab === DELIVERY
                ? deliveryDimension === "Keyword"
                : dimensions.includes("keyword")) &&
              ["campaign_type", "match_type", "keyword", "product_id", "ad_name"].includes(
                (filterRow as any).key
              )
            ) {
              return disabledRow;
            }
            if (
              (selectedTab === DELIVERY
                ? deliveryDimension === "Product Ad"
                : dimensions.includes("ad_name")) &&
              ["placement", "match_type", "keyword"].includes((filterRow as any).key)
            ) {
              return disabledRow;
            }
            return filterRow;
          }, filterData);
        }
        return filterData;
      },
      []
    );

    useEffect(() => {
      if (company && !pagePresets) {
        getPagePresets();
      }
    }, [company, getPagePresets, pagePresets, setError, urlSettings]);

    useEffect(() => {
      if (company && !tablePresets) {
        getTablePresets();
      }
    }, [company, getTablePresets, pagePresets, setError, tablePresets, urlSettings]);

    useEffect(() => {
      if (company && !filterData) {
        (async () => {
          try {
            const res = await SingleChannelLambdaFetch<C.GetFilterOptionsParams>(
              "/getChannelFilterOptions",
              {
                params: {
                  company,
                  channel: "commerce",
                } as any,
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, filterData, setError]);

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<P.GetKpiMetaDataParams>("/kpis", {
              params: {
                company,
              },
            });
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [kpiMetaData, company, setError]);

    return pagePresets && tablePresets ? (
      <SingleChannelPage
        columnMetaDataMap={columnMetaDataMap}
        dataFetchKey={header => `${header.kpi}_${header.lag}`}
        dates={dates as any}
        defaultKPI={"orders"}
        defaultLag={"14d"}
        defaultSparkChartMetrics={["cpc", "spend", "roas", "volume"]}
        deletePagePreset={deletePagePreset}
        deleteTablePreset={deleteTablePreset}
        deliveryDimensionMap={{
          Retailer: "retailer",
          Platform: "platform",
          "Campaign Type": "campaign_type",
          Campaign: "campaign_name",
          "Product Category": "tinuiti_product_category",
          Placement: "placement",
          "Ad Group": "ad_group_name",
          Keyword: "keyword",
          "Match Type": "match_type",
          "Product Ad": "ad_name",
          "Product ID": "product_id",
        }}
        deliveryMetricOptions={[
          {
            label: "Impressions",
            value: "impressions",
          },
          {
            label: "Spend",
            isSpend: true,
            value: "spend",
          },
          {
            label: "Clicks",
            value: "clicks",
          },
        ]}
        dimensionColumnMetaDataMap={dimensionColumnMetaDataMap}
        fetchingPagePresets={fetchingPagePresets}
        fetchingTablePresets={fetchingTablePresets}
        filterData={R.defaultTo([], filterData)}
        filterDataProcessor={filterDataProcessor}
        getDeliveryData={getDeliveryData}
        getDimensionCell={getDimensionCell}
        getPerformanceData={getPerformanceData}
        getTablePreset={getTablePreset}
        glossary={GLOSSARY}
        kpiMetaData={R.defaultTo({}, kpiMetaData)}
        location={location}
        pagePresets={R.defaultTo([], pagePresets)}
        prefix={"commerce"}
        savePagePreset={savePagePreset}
        saveTablePreset={saveTablePreset}
        setDates={setDates as any}
        setPagePresets={setPagePresets}
        setTablePresets={setTablePresets}
        sparkChartMetricsMap={{
          acos: "acos",
          aov: "aov",
          clicks: "clicks",
          "conversion rate": "conversion rate",
          cpc: "cpc",
          ctr: "ctr",
          impressions: "impressions",
          "new to brand sales": "new to brand sales",
          revenue: "revenue",
          roas: "roas",
          spend: "spend",
          volume: "volume",
        }}
        sparkChartMetricPrettyNameMap={{
          acos: "ACOS",
          aov: "AOV",
          clicks: "Clicks",
          "conversion rate": "Conversion Rate",
          cpc: "CPC",
          ctr: "CTR",
          impressions: "Impressions",
          "new to brand sales": "New to Brand Sales",
          revenue: "Revenue",
          roas: "ROAS",
          spend: "Spend",
          volume: "KPI Volume",
        }}
        tablePresets={R.defaultTo([], tablePresets)}
        urlSettings={urlSettings}
        useCrossChannelKpis={false}
      />
    ) : (
      <FullPageSpinner />
    );
  }
);

export default CommerceSingleChannel;
