// Experiment for linear delivery page.

const linearDeliveryUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "alex.echeverria@tinuiti.com",
  "justin.manus@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  "kyle.chan@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "benjamin.contesse@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  "philip.rundall@tinuiti.com",
  "galen.murray@tinuiti.com",
  "paul.sims@tinuiti.com",
  "taymour.siddiqui@tinuiti.com",
  "matthew.black@tinuiti.com",
  "nina.grill@tinuiti.com",
  "garrett.cheadle@tinuiti.com",
  "lauren.woflen@tinuiti.com",
  // Add users here!
];

export const shouldEnableLinearDelivery = (userEmail: string): boolean => {
  return linearDeliveryUsers.includes(userEmail);
};
