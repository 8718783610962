import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import Papa from "papaparse";

import { ReactComponent as Save } from "../icons/Save.svg";

import { CostPerPathRow } from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line, Tooltip } from "recharts";
import { AxesSkeleton, Skeleton, PathSkeleton } from "../../Components";
import { seriesColors } from "../../utils/colors";
import { useCallback } from "react";
import { download } from "../../utils/download-utils";
import { formatMoney } from "../../utils/format-utils";

interface CostPerPathChartProps {
  data?: CostPerPathRow[];
  label: string;
  decimals?: number;
}

const TICK_DATE_FORMAT = "MMM d";

export const CostPerPathChart: React.FC<CostPerPathChartProps> = ({ data, label, decimals }) => {
  const downloadData = useCallback(() => {
    if (data) {
      let csv = Papa.unparse(R.project(["date", "val"], data));
      download(csv, `${label}.csv`, "text/csv");
    }
  }, [data, label]);

  const tickFormatter = useCallback(
    val => {
      return (val as number).toLocaleString("en-us", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: decimals || 0,
      });
    },
    [decimals]
  );
  const labelFormatter = useCallback((value: string) => formatMoney(parseFloat(value), decimals), [
    decimals,
  ]);

  return (
    <div className="cppChart section overviewChart">
      <div className="sectionHeader cppHeader">
        <div>{label}</div>
        <div className="saveButton" onClick={downloadData}>
          <Save />
        </div>
      </div>
      <div className="chart">
        {data ? (
          data.length ? (
            <ResponsiveContainer>
              <LineChart data={data}>
                <YAxis tickFormatter={tickFormatter} />
                <XAxis
                  dataKey="date"
                  height={15}
                  tickFormatter={R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))}
                />

                <Line
                  dataKey="val"
                  type="monotone"
                  stroke={seriesColors[0]}
                  dot={false}
                  strokeWidth={2}
                />

                <Tooltip
                  labelFormatter={R.pipe(Dfns.parseISO, Dfns.format(TICK_DATE_FORMAT))}
                  formatter={labelFormatter}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="noData">No Data</div>
          )
        ) : (
          <Skeleton>
            <PathSkeleton
              points={[
                [0, 0.3],
                [0.25, 0.7],
                [0.5, 0.2],
                [0.75, 0.9],
                [1, 0.5],
              ]}
              thickness={10}
            />

            <AxesSkeleton size={5} />
          </Skeleton>
        )}
      </div>
    </div>
  );
};
