import React, { useState, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";

import { download } from "../utils/download-utils";

import { MdSave } from "react-icons/md";

import useLocation from "../utils/hooks/useLocation";

import { Page, BPMButton } from "../Components";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";

import StreamingStageTwoDiagnostics from "./StreamingStageTwoDiagnostics";

import "./StreamingMLDiagnostics.scss";

const DATE_FORMAT = "M/dd/yyyy h:mm a";

const CSV_MIME_TYPE = "text/csv";

const PLACEMENT_ONLY = "Placement only";
const PLACEMENT_DEVOSCAT = "Placement + DevOSCat";
const PLATFORM_VIDEO = "Video";
const PLATFORM_AUDIO = "Audio";
const SHOW_SEEN_ONLY = "Seen only";
const SHOW_UNSEEN = "Seen + Unseen";

export default React.memo(() => {
  const { company } = useLocation();
  const [timestamp, setTimestamp] = useState();
  const [fetchingCSV, setFetchingCSV] = useState(false);
  const [showDevOSCat, setShowDevOSCat] = useState(false);
  const [showUnseen, setShowUnseen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [kpi, setKpi] = useState();
  const [platform, setPlatform] = useState(PLATFORM_VIDEO);
  const branch = "v4";

  let downloadCSV = useCallback(() => {
    setFetchingCSV(true);
    const header = showDevOSCat
      ? [
          "placement",
          "devcatos",
          "pred_cr_per_m",
          "cpm",
          "pred_cost_per_conversion",
          "pred_cr_per_dollar",
        ]
      : ["placement", "pred_cr_per_m", "cpm", "pred_cost_per_conversion", "pred_cr_per_dollar"];
    const csvRows = R.map(row => R.map(h => row[h], header), filteredData);
    const csv = Papa.unparse([header, ...csvRows]);
    download(csv, "export.csv", CSV_MIME_TYPE);
    setFetchingCSV(false);
  }, [filteredData, showDevOSCat]);

  return (
    <Page
      title="Streaming ML Diagnostics"
      pageType="Streaming ML Diagnostics"
      actions={
        <div className="mlDiagnosticsActions">
          {timestamp ? (
            <div className="lastCompletionDate">{`Last Completion: ${R.pipe(
              Dfns.parseISO,
              Dfns.format(DATE_FORMAT)
            )(timestamp)} Pacific`}</div>
          ) : (
            ""
          )}
          {kpi ? <div className="currentKpi">{kpi}</div> : ""}
          <Form.Group className="configuration">
            <DropdownButton drop="down" title={showDevOSCat ? PLACEMENT_DEVOSCAT : PLACEMENT_ONLY}>
              {[PLACEMENT_ONLY, PLACEMENT_DEVOSCAT].map(category => (
                <Dropdown.Item
                  key={category}
                  eventKey={category}
                  onSelect={category => {
                    setShowDevOSCat(category === PLACEMENT_DEVOSCAT);
                  }}
                >
                  {category}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
          <Form.Group className="configuration">
            <DropdownButton drop="down" title={platform}>
              {[PLATFORM_VIDEO, PLATFORM_AUDIO].map(p => (
                <Dropdown.Item
                  key={p}
                  eventKey={p}
                  onSelect={selected => {
                    if (selected !== null) {
                      setPlatform(selected);
                    }
                  }}
                >
                  {p}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
          <Form.Group className="configuration">
            <DropdownButton drop="down" title={showUnseen ? SHOW_UNSEEN : SHOW_SEEN_ONLY}>
              {[SHOW_SEEN_ONLY, SHOW_UNSEEN].map(p => (
                <Dropdown.Item
                  key={p}
                  eventKey={p}
                  onSelect={selected => {
                    if (selected !== null) {
                      setShowUnseen(selected === SHOW_UNSEEN);
                    }
                  }}
                >
                  {p}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
          <BPMButton variant="outline-secondary" disabled={fetchingCSV} onClick={downloadCSV}>
            <MdSave />
          </BPMButton>
        </div>
      }
    >
      <div className="mlDiagnostics">
        <StreamingStageTwoDiagnostics
          company={company}
          branch={branch}
          platform={platform}
          setTimestamp={setTimestamp}
          showDevOSCat={showDevOSCat}
          setFilteredData={setFilteredData}
          setKpi={setKpi}
          showUnseen={showUnseen}
        />
      </div>
    </Page>
  );
});
