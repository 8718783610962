import React, { useMemo, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import { NumberFormatter } from "../../Components";

import * as Colors from "../../utils/colors";
import "../Dashboard.scss";

const TrendLabelContainer = ({ title, value, color }) => (
  <div className="trendLabelContainer">
    {title && <div className="trendTitle">{title}</div>}
    <div className="trendValue" style={{ color }}>
      {value > 0 ? "+" : ""}
      <NumberFormatter value={value} type="%" />
    </div>
  </div>
);

const PRETTY_DATE_FORMAT = "MMM-dd";
const DATE_FORMAT = "yyyy-MM-dd";
const COLOR_FOR_SERIES = {
  TV: Colors.primary,
  "TV Video": Colors.primary,
  "TV Video (A18+)": Colors.primary,
  Streaming: Colors.secondary,
  "Streaming Video": Colors.secondary,
  "Streaming Audio": Colors.tertiary,
};

const getColorForSeries = series => {
  if (COLOR_FOR_SERIES[series]) {
    return COLOR_FOR_SERIES[series];
  } else if (series.indexOf(" CP") >= 0 || series.indexOf("CP") === 0 || series === "CAC") {
    return Colors.primary;
  } else {
    return Colors.secondary;
  }
};

const DashboardSparkChart = ({ chartData }) => {
  const reformattedData = useMemo(() => {
    if (!R.pipe(R.prop("data"), R.length)(chartData)) {
      return null;
    }

    let transformed = R.map(origData => {
      return R.mergeRight(
        { week: origData.week },
        R.fromPairs(
          R.map(key => {
            const value =
              chartData.series && origData.values ? origData.values[key] : origData.value;
            return [key, Number.isFinite(value) && value > 0 ? value : null];
          }, R.defaultTo(["TV"], R.concat(chartData.series, R.defaultTo([], chartData.altSeries))))
        )
      );
    }, chartData.data);
    return R.sortBy(R.prop("week"), transformed);
  }, [chartData]);

  const tooltipFormatter = useCallback(
    (value, name) => {
      let formatType = "";
      if (chartData.series && R.contains(name, chartData.series)) {
        formatType = chartData.type;
      }
      if (chartData.altSeries && R.contains(name, chartData.altSeries)) {
        formatType = chartData.altType;
      }
      return `${formatType === "$" ? "$" : ""}${value.toLocaleString("en-US", {
        maximumFractionDigits: value > 100 ? 0 : 2,
      })}`;
    },
    [chartData]
  );

  if (!chartData || !chartData.data || chartData.data.length === 0) {
    return null;
  }

  const header = chartData.title;
  const numericData = R.sortBy(R.prop("week"), chartData.data);
  const mostRecentValue = numericData[numericData.length - 1].value;
  return (
    reformattedData && (
      <div className="dashboardWidget">
        <div className="sparkChartHeadersContainer">
          <div className="dashboardWidgetHeader">{header}</div>
          {chartData.prevWeekPrimary ? (
            <div className="trendsContainer">
              <div className="trendLabelContainer">
                {chartData.prevWeek}:&nbsp;
                <NumberFormatter
                  value={chartData.prevWeekPrimary}
                  type={chartData.type}
                  decimals={header.includes("CP") || header === "CAC" ? 2 : 0}
                />
                {chartData.prevWeekSecondary && (
                  <>
                    &nbsp;/&nbsp;
                    <NumberFormatter value={chartData.prevWeekSecondary} type={"$"} decimals={2} />
                  </>
                )}
              </div>
              <div className="trendLabelContainer">
                {Number.isFinite(chartData.prevWeekPrimaryTrend) && (
                  <TrendLabelContainer
                    title={"∆ vs. Prev Week"}
                    value={chartData.prevWeekPrimaryTrend}
                    color={chartData.prevWeekPrimaryTrendColor}
                  />
                )}
                {Number.isFinite(chartData.prevWeekSecondaryTrend) && (
                  <>
                    &nbsp;/&nbsp;
                    <TrendLabelContainer
                      value={chartData.prevWeekSecondaryTrend}
                      color={chartData.prevWeekSecondaryTrendColor}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="trendsContainer">
              <NumberFormatter
                prefix={`${
                  chartData.prevWeek ? chartData.prevWeek : numericData[numericData.length - 1].week
                }: `}
                value={mostRecentValue}
                type={chartData.type}
                decimals={header.includes("CP") || header === "CAC" ? 2 : 0}
              />
              {Number.isFinite(chartData.prevWeekTrend) && (
                <TrendLabelContainer
                  title={chartData.prevWeekSubtitle}
                  value={chartData.prevWeekTrend}
                  color={chartData.prevWeekTrendColor}
                />
              )}
            </div>
          )}
        </div>
        <div className="sparkChartInnerBox">
          <AutoSizer>
            {({ width, height }) => (
              <LineChart
                height={height}
                width={width}
                margin={{
                  top: 20,
                  right: 30,
                }}
                data={reformattedData}
              >
                <Tooltip
                  cursor={{ strokeWidth: 2 }}
                  isAnimationActive={false}
                  formatter={tooltipFormatter}
                />
                <XAxis
                  dataKey="week"
                  tickFormatter={tick =>
                    R.pipe(
                      Dfns.parse(new Date(), DATE_FORMAT),
                      Dfns.format(PRETTY_DATE_FORMAT)
                    )(tick)
                  }
                  tick={{ fontSize: 10 }}
                />
                <YAxis
                  yAxisId="left"
                  tickFormatter={tick =>
                    `${chartData.type === "$" ? "$" : ""}${tick.toLocaleString()}`
                  }
                  tick={{ fontSize: 10 }}
                />
                {chartData.altSeries && (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={tick =>
                      `${chartData.altType === "$" ? "$" : ""}${tick.toLocaleString()}`
                    }
                    tick={{ fontSize: 10 }}
                  />
                )}
                {R.map(
                  series => (
                    <Line
                      yAxisId="left"
                      type="monotone"
                      strokeWidth={2.5}
                      dataKey={series}
                      key={series}
                      name={series}
                      dot={false}
                      stroke={getColorForSeries(series)}
                    />
                  ),
                  R.defaultTo(["TV"], chartData.series)
                )}
                {chartData.altSeries &&
                  R.map(
                    series => (
                      <Line
                        yAxisId="right"
                        type="monotone"
                        strokeWidth={2.5}
                        dataKey={series}
                        key={series}
                        name={series}
                        dot={false}
                        stroke={getColorForSeries(series)}
                      />
                    ),
                    chartData.altSeries
                  )}
                <Legend verticalAlign="bottom" height={36} />
              </LineChart>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  );
};

export default DashboardSparkChart;
