import React, { useMemo, useCallback, useContext } from "react";

import * as R from "ramda";

import Select from "react-select";

import { Img } from "../Components";

import { ActiveOTTContext } from "../ActiveOTT/ActiveOTT";

import "./NetworkDropdown.scss";

const NetworkDropdown = () => {
  const { selectedGroup, setSelectedGroup, networkOptions } = useContext(ActiveOTTContext);

  const onNetworkChange = useCallback(
    ({ value }) => {
      setSelectedGroup(value);
    },
    [setSelectedGroup]
  );

  const networkValue = useMemo(
    () => ({
      label: selectedGroup,
      value: selectedGroup,
    }),
    [selectedGroup]
  );

  const networkSelectOptions = useMemo(
    () =>
      R.pipe(
        R.keys,
        R.map(value => ({ label: value, value }))
      )(networkOptions),
    [networkOptions]
  );

  return (
    <div className="activeOTTNetworkPicker">
      <div className="logo">
        <Img src={`https://cdn.blisspointmedia.com/networks/${selectedGroup}.png`} />
      </div>
      <Select
        className="networkSelect"
        value={networkValue}
        options={networkSelectOptions}
        onChange={onNetworkChange}
      />
    </div>
  );
};

export default NetworkDropdown;
