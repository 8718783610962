import { useState } from "react";

import * as R from "ramda";

import { BarChart, ResponsiveContainer, YAxis, Bar, Tooltip } from "recharts";

import { Button } from "react-bootstrap";

import { HistogramRow } from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import { AxesSkeleton, BarGraphSkeleton, Skeleton } from "../../Components";
import { primary } from "../../utils/colors";
interface HistogramProps {
  data?: HistogramRow[];
}

const toPrettyPct = (num: number): string => `${Math.round(num * 100)}%`;

const BAR_WIDTH = 14;
const BAR_SPACE = 3;

export const Histogram: React.FC<HistogramProps> = ({ data }) => {
  const [discrete, setDiscrete] = useState(true);

  return (
    <div className="streamingPerformanceHistogram section overviewChart">
      <div className="sectionHeader">
        Conversion Latency{" "}
        <Button
          className="basicToggleButton"
          variant="link"
          size="sm"
          onClick={() => setDiscrete(R.not)}
        >
          ({discrete ? "Discrete" : "Cumulative"})
        </Button>
      </div>
      <div className="chart">
        {data ? (
          data.length ? (
            <ResponsiveContainer>
              <BarChart data={data}>
                <YAxis
                  width={30}
                  dataKey={discrete ? "pct" : "cumulative"}
                  tickFormatter={val => toPrettyPct(val as number)}
                  interval={0}
                />
                <Bar dataKey={discrete ? "pct" : "cumulative"} fill={primary} />
                <Tooltip
                  labelFormatter={label => `${label} day${label === 1 ? "" : "s"} later`}
                  formatter={val => toPrettyPct(val as number)}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div className="noData">No Data</div>
          )
        ) : (
          <Skeleton>
            <BarGraphSkeleton barWidth={BAR_WIDTH} gutter={BAR_SPACE} verticalPadding={5} />
            <AxesSkeleton size={3} />
          </Skeleton>
        )}
      </div>
    </div>
  );
};
