import "./Pagination.scss";
import React, { Dispatch, SetStateAction } from "react";

import { IconButton } from "../IconButton";
import { MdFirstPage, MdLastPage, MdOutlineSkipNext, MdOutlineSkipPrevious } from "react-icons/md";

interface PaginationProps {
  limit?: number;
  offset: number;
  totalData: number;
  updateOffset: Dispatch<SetStateAction<number>>;
}

const Pagination: React.FC<PaginationProps> = ({ limit = 10, offset, totalData, updateOffset }) => {
  return (
    <div className="pagination-row">
      <IconButton
        disable={!(offset > 0)}
        variant="outline-primary"
        className="first-page-button"
        onClick={() => {
          updateOffset(0);
        }}
        icon={<MdFirstPage />}
      >
        {""}
      </IconButton>
      <IconButton
        disable={!(offset > 0)}
        variant="outline-primary"
        className="previous-button"
        onClick={() => {
          offset - limit > 0 ? updateOffset(offset - limit) : updateOffset(0);
        }}
        icon={<MdOutlineSkipPrevious />}
      >
        {""}
      </IconButton>
      <div className="pagination-data-shown">
        <div>{totalData === 0 ? 0 : offset + 1} to </div>
        <div>{offset + limit <= totalData ? offset + limit : totalData} of </div>
        <div>{totalData}</div>
      </div>
      <IconButton
        disable={offset + limit >= totalData}
        variant="outline-primary"
        className="next-button"
        onClick={() => {
          updateOffset(offset + limit);
        }}
        icon={<MdOutlineSkipNext />}
      >
        {""}
      </IconButton>
      <IconButton
        disable={offset + limit >= totalData}
        variant="outline-primary"
        className="next-button"
        onClick={() => {
          updateOffset(totalData - limit);
        }}
        icon={<MdLastPage />}
      >
        {""}
      </IconButton>
    </div>
  );
};

export default Pagination;
