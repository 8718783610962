import React, { useMemo, useCallback, useState } from "react";
import * as R from "ramda";
import { MdWarning, MdCheckCircle } from "react-icons/md";

import { Alert, Card, Modal, Tooltip, Tab, Tabs } from "react-bootstrap";
import { BPMButton, PieSetter, OverlayTrigger } from "../../Components";
import { EditableCard } from "../../StreamingCreatives/Cards";
import {
  getCreativeOptions,
  EditsMap,
  weekFormatter,
  transformToHash,
  getPlanRow,
} from "../linearBuyingUtils";
import { ISCI, PlanRow } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { CreativeMap } from "../../redux/creative";
import { StateSetter } from "../../utils/types";

import "./CreativeAllocationModal.scss";

interface CreativeAllocationModalV2Props {
  planRows: PlanRow[];
  creativeMap: CreativeMap;
  colorMap: Record<string, string>;
  setEditsMap: StateSetter<EditsMap>;
  setNewRows: StateSetter<Record<string, PlanRow>>;
  onClose: () => void;
}

export interface CreativePercent {
  week: string;
  isci: string;
  percent: number;
}

export interface CreativePercentMap {
  [key: string]: CreativePercent;
}

export interface RotationToUse {
  [week: string]: Creative[];
}

interface Creative {
  plan_id: number;
  isci: string;
  percent: number;
  isPending: boolean;
}

export const CreativeAllocationModalV2: React.FC<CreativeAllocationModalV2Props> = ({
  planRows,
  creativeMap,
  colorMap,
  setEditsMap,
  setNewRows,
  onClose,
}) => {
  const creativeOptions = useMemo(() => {
    return R.fromPairs(
      R.map(planRow => {
        return [planRow.week, getCreativeOptions(planRow, creativeMap, colorMap)];
      }, planRows)
    );
  }, [colorMap, creativeMap, planRows]);

  const [week, setWeek] = useState<string>("");

  const [iscisToPercentages, setIsciToPercentage] = useState<CreativePercentMap>({});
  // If there's an edited rotation, use that. Otherwise, use the original.
  const rotationToUse = useMemo(() => {
    let rotationToUse: RotationToUse = {};
    for (let planRow of planRows) {
      if (planRow.edits && R.has("creatives", planRow.edits)) {
        rotationToUse[planRow.week] = R.path(["edits", "creatives"], planRow) as Creative[];
      } else if (R.has("creatives", planRow)) {
        rotationToUse[planRow.week] = planRow.creatives as Creative[];
      }
    }
    return rotationToUse;
  }, [planRows]);

  const defaultWeek = useMemo(() => {
    let planRow = planRows[0];
    const { week } = planRow;
    setWeek(week);
  }, [planRows]);

  const currentPercentageMap = useMemo(() => {
    let creativePercentMap: CreativePercentMap = {};

    if (!R.isEmpty(rotationToUse)) {
      for (let key of Object.keys(rotationToUse)) {
        for (let value of rotationToUse[key]) {
          creativePercentMap = {
            ...creativePercentMap,
            ...transformToHash(key, value.isci, value.percent),
          };
        }
      }
      return creativePercentMap;
    }

    return creativePercentMap;
  }, [rotationToUse]);

  // Combine existing rotation with changes and remove allocations that aren't valid.
  const sanitizedAllocations = useMemo(() => {
    let updatedMap: CreativePercentMap = {};
    updatedMap = R.clone(currentPercentageMap);
    for (let key of Object.keys(iscisToPercentages)) {
      let value = iscisToPercentages[key].percent;
      if (R.has(key, updatedMap)) {
        updatedMap[key] = R.assoc("percent", value, updatedMap[key]);
      }
    }
    return updatedMap;
  }, [iscisToPercentages, currentPercentageMap]);

  // week isci percent
  //update the percents with the changed percent in the updates array
  //consolidate the percents by week
  //make sure they add up to 100

  const allocationSum = useMemo(() => {
    let percentSumMap = Object.values(sanitizedAllocations);
    return percentSumMap.reduce((acc, row) => {
      const { week, percent } = row;
      if (!acc[week]) {
        acc[week] = { percent: 0, count: 0 };
      }
      acc[week].percent += percent || 0;
      acc[week].count += 1;
      return acc;
    }, {});
  }, [sanitizedAllocations]);
  // Checks if the rotation equals 100%

  const isValidTotalPercentage = useMemo(() => {
    if (!week) {
      return true;
    }
    if (R.prop("percent", allocationSum[week]) !== 100) {
      return false;
    }
    return true;
  }, [allocationSum, week]);

  //Checks if there are more creatives than unit count. i.e. don't allow 50/50 rotation for 1 spot
  const isValidCreativeCount = useMemo(() => {
    const findRow = getPlanRow(planRows, week);
    let count = R.defaultTo(0, findRow?.count);
    if (R.prop("count", allocationSum[week]) > count) {
      return false;
    }
    return true;
  }, [allocationSum, planRows, week]);

  const isValidAllocation = useMemo(() => {
    return isValidTotalPercentage && isValidCreativeCount;
  }, [isValidTotalPercentage, isValidCreativeCount]);

  const onSubmit = useCallback(() => {
    // If there are no changes, do nothing.
    if (R.isEmpty(iscisToPercentages)) {
      onClose();
      return;
    }

    // Combine existing rotation with changes and remove allocations that aren't valid.
    let updatedMap = { ...currentPercentageMap };
    const updateSanitizedAllocations = () => {
      for (let key of Object.keys(iscisToPercentages)) {
        let value = iscisToPercentages[key].percent;
        if (R.has(key, updatedMap)) {
          updatedMap[key] = R.assoc("percent", value, updatedMap[key]);
        }
      }
      return updatedMap;
    }; //here make sure right object is getting changes
    let sanitizedAllocations = updateSanitizedAllocations();
    // Construct metadata using new rotation.
    // If edited an existing ISCI, just change the percent. If new, create new metadata for it.
    let updatedRotation: ISCI[] = [];
    let index = 0;
    let keysLen = Object.keys(sanitizedAllocations).length;
    //need to only loop through each set of creations for each key
    for (let weekIsci of Object.keys(sanitizedAllocations)) {
      let findRow = getPlanRow(planRows, sanitizedAllocations[weekIsci].week) as PlanRow;
      let { key, isNewRow, creatives, plan_id } = findRow;
      let existingMetadata = R.find(
        row => row.isci === sanitizedAllocations[weekIsci].isci,
        creatives || []
      );
      if (existingMetadata && existingMetadata.percent !== sanitizedAllocations[weekIsci].percent) {
        let updated: ISCI = {
          ...existingMetadata,
          percent: sanitizedAllocations[weekIsci].percent,
        };
        updatedRotation.push(updated);
      } else if (!existingMetadata) {
        let newIsci: ISCI = {
          plan_id: plan_id || null,
          isci: sanitizedAllocations[weekIsci].isci,
          percent: sanitizedAllocations[weekIsci].percent,
          isPending: true,
        };
        updatedRotation.push(newIsci);
      }
      //expected -- if rotation is the same as creatives continue or exit
      //roation should not be updated if the percents are the same
      // If the updated rotation is the same as the original, do nothing.
      if (R.equals(updatedRotation, creatives) || updatedRotation.length === 0) {
        if (!(index === keysLen - 1)) {
          index++;
          continue;
        } else {
          updatedRotation = [];
          onClose();
          return;
        }
      }
      index++;
      let edit = { creatives: updatedRotation };
      if (isNewRow) {
        setNewRows(current => ({ ...current, [key]: { ...current[key], ...edit } }));
      } else {
        setEditsMap(current => ({
          ...current,
          [key]: { ...findRow, edits: { ...R.path([key, "edits"], current), ...edit } },
        }));
      }
    }
    onClose();
  }, [currentPercentageMap, iscisToPercentages, planRows, setEditsMap, setNewRows, onClose]);

  const makeTabTitle = (week: string): string => {
    let planRow = getPlanRow(planRows, week);
    let title = `${planRow?.count} ${weekFormatter(week)}`;
    return title;
  };

  const cardTitle = () => {
    let planRow = planRows[0];
    const { network, avail, rotation, length } = planRow;
    let title = `${network} - ${avail} - ${rotation} - ${length}s`;
    return title;
  };

  const hasNonLiveCreative = useMemo(() => {
    if (!creativeOptions[week]) {
      return false;
    }
    const nonLive = creativeOptions[week].map(creative => creative.notLive || false);
    return R.any(R.identity, nonLive);
  }, [creativeOptions, week]);

  const weeklyCreativeOptions = (week: string) => {
    if (creativeOptions[week]) {
      let creatives = creativeOptions[week];
      let creativeList = R.map(creative => {
        const { isci } = creative;
        let mapId = R.concat(week, isci);
        const newValue =
          iscisToPercentages[mapId]?.percent ?? (currentPercentageMap[mapId]?.percent || 0);
        return { ...creative, key: isci, value: newValue };
      }, creatives);
      return creativeList;
    }
  };

  return (
    <Modal show size="xl" onHide={onClose} className="creativeAllocationModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <div className="title">{`${cardTitle()}`}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="workspace">
          <Tabs
            defaultActiveKey={defaultWeek}
            onSelect={week => {
              setWeek(week as string);
            }}
          >
            {Object.keys(creativeOptions).map(week => {
              const tabTitle = makeTabTitle(week);
              return (
                <Tab
                  className="tab"
                  key={week}
                  eventKey={week}
                  title={
                    <div className="tabTitle">
                      {tabTitle}
                      {isValidAllocation ? (
                        <div className="validIndicator">
                          <MdCheckCircle />
                        </div>
                      ) : null}
                    </div>
                  }
                >
                  <Card className="workspaceCard">
                    <div className="workspaceCardInner">
                      <PieSetter
                        pieFlex={0.5}
                        percentage
                        data={weeklyCreativeOptions(week)}
                        renderItem={elem => (
                          <EditableCard
                            {...elem}
                            onChange={(isci, value) => {
                              // If you type 0, you get 0 as the value. But if you delete the value, it becomes
                              // NaN. We want it to be 0 so that it doesn't get filtered out in the onSubmit.
                              let convertedValue = value || 0;
                              if (convertedValue < 0) {
                                convertedValue = Math.abs(convertedValue);
                              }
                              setIsciToPercentage({
                                //find the hash then update
                                ...iscisToPercentages,
                                ...transformToHash(week, isci, convertedValue),
                              });
                            }}
                          />
                        )}
                      />
                    </div>
                    {!isValidTotalPercentage && (
                      <Alert variant="danger">{`Your rotation is off by ${Math.abs(
                        100 - R.prop("percent", allocationSum[week])
                      )}%`}</Alert>
                    )}
                    {!isValidCreativeCount && (
                      <Alert variant="danger">
                        The rotation doesn't make sense with the current unit count
                      </Alert>
                    )}
                    <Card.Footer>
                      <BPMButton onClick={onClose}>Cancel</BPMButton>
                      <BPMButton disabled={!isValidAllocation} onClick={onSubmit}>
                        Okay
                      </BPMButton>
                      {hasNonLiveCreative && (
                        <OverlayTrigger
                          placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                          overlay={
                            <Tooltip id={`Error: ${week}_${getPlanRow(planRows, week)?.network}`}>
                              <>
                                There is at least one creative in this rotation that is not live on
                                linear during the currently selected week. You should probably
                                remove it from the rotation.
                              </>
                            </Tooltip>
                          }
                        >
                          <MdWarning color="orange" />
                        </OverlayTrigger>
                      )}
                    </Card.Footer>
                  </Card>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CreativeAllocationModalV2;
