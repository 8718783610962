import React, { useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { MdArrowForward } from "react-icons/md";

import "./DateBox.scss";

const PRETTY_DATE_FORMAT = "M/dd/yyyy";

export const DateBox = React.memo(
  ({ start, end, dateFormat = PRETTY_DATE_FORMAT, noParse = false, className }) => {
    const [prettyStart, prettyEnd] = useMemo(
      () =>
        noParse ? [start, end] : [start, end].map(R.pipe(Dfns.parseISO, Dfns.format(dateFormat))),
      [noParse, start, end, dateFormat]
    );
    const resolvedClassName = useMemo(() => {
      let classes = ["BPMDateBox"];
      if (className) {
        classes.push(className);
      }
      return classes.join(" ");
    }, [className]);
    return (
      <div className={resolvedClassName}>
        <div>{prettyStart}</div>
        <MdArrowForward className="arrow" />
        <div>{prettyEnd}</div>
      </div>
    );
  }
);
