import React from "react";
import { amcClient } from "../../Cache";
import { useQuery } from "@apollo/client";
import TableProgressBar from "../../TableProgressBar";
import { formatMoneyAsInt } from "../../../utils/format-utils";
import { abbreviateNumberAMC } from "../../AMCFormatters";

import {
  maxProPurchaseRate,
  maxProPurchased,
  maxProOrderSize,
  maxNTBPurchaseRate,
  maxNTBPurchased,
  maxNTBOrderSize,
  maxReach,
  GET_MAX_MEDIA_MIX,
} from "../../ReactiveVariables";

const ProgressBarRenderer = (
  value: number,
  maxCompleted: number,
  type: string,
  mediaType: string | null = null
): JSX.Element => {
  useQuery(GET_MAX_MEDIA_MIX, { client: amcClient });
  let formatted;

  switch (type) {
    case "purchaseRate":
      formatted = `${abbreviateNumberAMC(value)}%`;
      break;
    case "avgOrderSize":
      formatted = `${formatMoneyAsInt(value)}`;
      break;
    default:
      formatted = `${abbreviateNumberAMC(value)}`;
  }

  return mediaType === "Grand Total" ? (
    <div>{formatted}</div>
  ) : (
    <TableProgressBar label={formatted} maxCompleted={maxCompleted} value={value} />
  );
};

interface TableData {
  avgOrderSize: number;
  mediaType: string;
  purchased: number;
  purchaseRate: number;
  reach: number;
}

export const prospectiveTableHeaders = [
  {
    label: "Media Type",
    name: "mediaType",
    flex: 1,
    minFlexWidth: 150,
    renderer: (data: TableData): string => {
      return data.mediaType;
    },
  },
  {
    label: "Shoppers Reached",
    name: "reach",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.reach, maxReach(), "reach", data.mediaType),
  },
  {
    label: "Prospective Purchased",
    name: "purchased",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.purchased, maxProPurchased(), "purchased", data.mediaType),
  },
  {
    label: "Prospective Purchase Rate",
    name: "purchaseRate",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.purchaseRate, maxProPurchaseRate(), "purchaseRate", data.mediaType),
  },
  {
    label: "Avg Order Value",
    name: "avgOrderSize",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.avgOrderSize, maxProOrderSize(), "avgOrderSize", data.mediaType),
  },
];

export const ntbTableHeaders = [
  {
    label: "Media Type",
    name: "mediaType",
    flex: 1,
    minFlexWidth: 150,
    renderer: (data: TableData): string => {
      return data.mediaType;
    },
  },
  {
    label: "Shoppers Reached",
    name: "reach",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.reach, maxReach(), "reach", data.mediaType),
  },
  {
    label: "NTB Purchased",
    name: "purchased",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.purchased, maxNTBPurchased(), "purchased", data.mediaType),
  },
  {
    label: "NTB Purchase Rate",
    name: "purchaseRate",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.purchaseRate, maxNTBPurchaseRate(), "purchaseRate", data.mediaType),
  },
  {
    label: "Avg Order Value",
    name: "avgOrderSize",
    flex: 1,
    minFlexWidth: 105,
    renderer: (data: TableData): JSX.Element =>
      ProgressBarRenderer(data.avgOrderSize, maxNTBOrderSize(), "avgOrderSize", data.mediaType),
  },
];
