import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { formatMoney } from "../utils/format-utils";
import {
  HOME_PAGE_MEDIA_TYPES,
  MEDIA_TYPE_TO_PRETTY_NAME,
  MEDIA_TYPE_TO_TITLE_COLOR,
} from "./homePageConstants";
import "./BudgetSnapshotLegend.scss";

interface BudgetSnapshotLegendProps {
  mediaTypes: string[];
  data: any;
  budgetComparator: string;
}

const BudgetSnapshotLegend: React.FC<BudgetSnapshotLegendProps> = ({
  mediaTypes,
  data,
  budgetComparator,
}) => {
  const dataMap = (data || []).reduce((acc, row) => {
    acc[row.mediaType] = row;
    return acc;
  }, {});

  const overLabel = budgetComparator === "spend" ? "over budget" : "increase";
  const underLabel = budgetComparator === "spend" ? "under budget" : "decrease";

  return (
    <div className="budgetSnapshotLegend">
      {HOME_PAGE_MEDIA_TYPES.map(type => {
        const ourData = dataMap[type];
        if (mediaTypes.includes(type) && ourData) {
          const comparator =
            budgetComparator === "spend" ? ourData.spendLastWeek : ourData.budgetThisWeek;
          const dollarDiff = comparator - ourData.budgetLastWeek;
          const percentDiff = Math.round((dollarDiff / ourData.budgetLastWeek) * 100);
          return (
            <div className="legendItem" key={type}>
              <div className="title" style={{ color: MEDIA_TYPE_TO_TITLE_COLOR[type] }}>
                {MEDIA_TYPE_TO_PRETTY_NAME[type]}
              </div>
              <div className="info">
                {dollarDiff === 0 ? (
                  <span className="label">no change</span>
                ) : (
                  <>
                    <div className="label">
                      <span>{dollarDiff < 0 ? <MdArrowDownward /> : <MdArrowUpward />}</span>
                      <span>{dollarDiff < 0 ? underLabel : overLabel}</span>
                    </div>
                    <div className="difference">
                      <span>{`${dollarDiff > 0 ? "+" : ""}${formatMoney(dollarDiff, 0)}`}</span>
                      <span> {percentDiff === Infinity ? "" : `(${percentDiff}%)`}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        } else {
          return <React.Fragment key={type}></React.Fragment>;
        }
      })}
    </div>
  );
};

export default BudgetSnapshotLegend;
