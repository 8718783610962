import { ApolloClient, InMemoryCache } from "@apollo/client";
import * as Dfns from "date-fns/fp";
import {
  maxProPurchaseRate,
  maxProPurchased,
  maxProOrderSize,
  maxNTBPurchaseRate,
  maxNTBPurchased,
  maxNTBOrderSize,
  maxReach,
  maxNtbRate,
  maxNtbRepeatRate,
  maxDPVRate,
  maxConversionRate,
  maxGeoNtbRepeatRate,
  maxPurchaseRate,
} from "./ReactiveVariables";

export const amcCache = new InMemoryCache({
  typePolicies: {
    MediaMixTableData: {
      fields: {
        maxNTBOrderSize: {
          read() {
            return maxNTBOrderSize();
          },
        },
        maxNTBPurchased: {
          read() {
            return maxNTBPurchased();
          },
        },
        maxNTBPurchaseRate: {
          read() {
            return maxNTBPurchaseRate();
          },
        },
        maxProOrderSize: {
          read() {
            return maxProOrderSize();
          },
        },
        maxProPurchased: {
          read() {
            return maxProPurchased();
          },
        },
        maxProPurchaseRate: {
          read() {
            return maxProPurchaseRate();
          },
        },
        maxReach: {
          read() {
            return maxReach();
          },
        },
      },
    },
    MediaMixGraphData: {
      fields: {
        date: {
          read(date) {
            return Dfns.format("MMM dd, yyyy", new Date(`${date} 00:00:00`));
          },
        },
      },
    },
    NtbBehaviorByGroup: {
      fields: {
        maxNtbRate: {
          read() {
            return maxNtbRate();
          },
        },
        maxNtbRepeatRate: {
          read() {
            return maxNtbRepeatRate();
          },
        },
      },
    },
    GeoReport: {
      fields: {
        maxDPVRate: {
          read() {
            return maxDPVRate();
          },
        },
        maxConversionRate: {
          read() {
            return maxConversionRate();
          },
        },
        maxGeoNtbRepeatRate: {
          read() {
            return maxGeoNtbRepeatRate();
          },
        },
        maxPurchaseRate: {
          read() {
            return maxPurchaseRate();
          },
        },
      },
    },
  },
});

export const amcClient = new ApolloClient({
  cache: amcCache,
  uri: process.env.REACT_APP_MOBIUSX_DATA_API,
  headers: {
    "x-api-key": process.env.REACT_APP_MOBIUSX_DATA_API_KEY,
  },
});
