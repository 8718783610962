import React, { useMemo, useCallback } from "react";

import * as R from "ramda";

import { Form } from "react-bootstrap";

import {
  computeResolvedDate,
  defaultState,
  DEFAULT_PIVOT_DATES,
  PivotDate,
  RelativeDatePickerFullState,
  RelativeDatePickerState,
  TODAY,
  AdjustmentType,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";

import { Setter } from "../utils/types";
import { parseInputToInt } from "../utils/data";
import { OldDropdown, SingleDatePicker } from ".";
import "./RelativeDatePicker.scss";

interface RelativeDatePickerProps {
  state: RelativeDatePickerState;
  onChange: Setter<RelativeDatePickerState>;
  customPivotDateMap?: Record<string, PivotDate>;
  className?: string;
  noDefaultPivotDates?: boolean;
  white?: boolean;
}

export const RelativeDatePicker: React.FC<RelativeDatePickerProps> = ({
  state,
  onChange,
  customPivotDateMap,
  className,
  noDefaultPivotDates,
  white,
}) => {
  const fullState: RelativeDatePickerFullState = useMemo(
    () => ({
      ...defaultState,
      ...state,
    }),
    [state]
  );

  const resolvedDate = useMemo(
    () => computeResolvedDate(fullState, customPivotDateMap, "M/d/yyyy"),
    [fullState, customPivotDateMap]
  );

  const pivotDateOptions = useMemo(() => {
    let customMap = customPivotDateMap || {};
    let map = noDefaultPivotDates ? customMap : { ...DEFAULT_PIVOT_DATES, ...customMap };
    return R.keys(map).map(value => ({ label: map[value].label, value }));
  }, [noDefaultPivotDates, customPivotDateMap]);

  const ourOnChange = useCallback(
    (partialState: RelativeDatePickerState) => {
      let newState = { ...fullState, ...partialState };

      // If they are changing to exact mode, reset the exact date (since they state they provided
      // may have a stale one).
      if (!R.isNil(partialState.exact)) {
        if (partialState.exact) {
          newState.exactDate = TODAY;
        } else {
          newState.pivotDate = pivotDateOptions[0].value;
        }
      }
      onChange(newState);
    },
    [fullState, onChange, pivotDateOptions]
  );

  const ourClassName = useMemo(() => {
    let classes = ["BPMRelativeDatePicker"];
    if (white) {
      classes.push("white");
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(" ");
  }, [white, className]);

  const { exact, exactDate, adjustment, adjustmentType, before, pivotDate } = fullState;

  return (
    <div className={ourClassName}>
      <OldDropdown
        size="sm"
        value={exact ? "exact" : "relative"}
        onChange={value => ourOnChange({ exact: value === "exact" })}
        options={[
          { label: "Exact", value: "exact" },
          { label: "Relative", value: "relative" },
        ]}
      />
      {exact ? (
        <SingleDatePicker date={exactDate} onChange={exactDate => ourOnChange({ exactDate })} />
      ) : (
        <>
          <Form.Control
            size="sm"
            className="daysInput"
            as="input"
            type="number"
            min={0}
            value={`${adjustment}`}
            onChange={e => ourOnChange({ adjustment: parseInputToInt(e.currentTarget.value) })}
          />
          <OldDropdown
            size="sm"
            value={adjustmentType}
            onChange={adjustmentType => ourOnChange({ adjustmentType })}
            options={(["day", "week", "month"] as AdjustmentType[]).map(value => ({
              value,
              label: `${value}${adjustment === 1 ? "" : "s"}`,
            }))}
          />

          <OldDropdown
            size="sm"
            value={before ? "before" : "after"}
            onChange={value => ourOnChange({ before: value === "before" })}
            options={["before", "after"]}
          />
          <OldDropdown
            size="sm"
            value={pivotDate}
            onChange={pivotDate => ourOnChange({ pivotDate })}
            options={pivotDateOptions}
          />
          <div className="resolvedDate">({resolvedDate})</div>
        </>
      )}
    </div>
  );
};
