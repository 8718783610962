import React, { useMemo, useState, useCallback, useEffect } from "react";

import { Router } from "@reach/router";

import { useSetError, useSetAreYouSure } from "../redux/modals";

import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useTabbedNav } from "../utils/hooks/useNav";
import useLocation from "../utils/hooks/useLocation";
import { useIsMounted } from "../utils/hooks/useDOMHelpers";

import { Page, FullPageSpinner } from "../Components";

import OrderView from "./OrderView/OrderView";
import GenerateView from "./GenerateView/GenerateView";

import "./StreamingOrderStatus.scss";

const ORDERS_KEY = "orders";
const GENERATE_KEY = "generate";

const StreamingOrderStatus = ({ navigate }) => {
  const { company } = useLocation();
  const getIsMounted = useIsMounted();

  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "streaming/status",
    defaultKey: ORDERS_KEY,
  });

  const [orderInfo, setOrderInfo] = useState();
  const [pdfInfo, setPdfInfo] = useState();
  const [tagInfo, setTagInfo] = useState();
  const [shouldRefreshData, setShouldRefreshData] = useState(false); // Used to trigger a data refresh from the Generate view.

  useEffect(() => {
    if (!orderInfo || shouldRefreshData) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/order_info", {
            params: { company, includePdfs: true },
          });
          let data = await awaitJSON(res);
          if (getIsMounted()) {
            setOrderInfo(data);
            setShouldRefreshData(false);
          }
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [orderInfo, company, setError, getIsMounted, shouldRefreshData]);

  useEffect(() => {
    if (!pdfInfo || shouldRefreshData) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/pdf_info", { params: { company } });
          let data = await awaitJSON(res);
          if (getIsMounted()) {
            setPdfInfo(data);
            setShouldRefreshData(false);
          }
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [pdfInfo, company, setError, getIsMounted, shouldRefreshData]);

  useEffect(() => {
    if (!tagInfo || shouldRefreshData) {
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/tag_info", { params: { company } });
          let data = await awaitJSON(res);
          if (getIsMounted()) {
            setTagInfo(data);
            setShouldRefreshData(false);
          }
        } catch (e) {
          setError({
            message: e.message,
            reportError: e,
          });
        }
      })();
    }
  }, [tagInfo, company, setError, getIsMounted, shouldRefreshData]);

  const [saving, setSaving] = useState(false);

  const forceCommit = useCallback(
    async id => {
      try {
        await setAreYouSure({
          title: "Force-Commit This Order?",
          message: `You are about to force commit order ${id}. Are you sure?`,
          okayText: "Force Commit",
        });
      } catch (e) {
        return;
      }
      setSaving(true);
      try {
        await StreamingV2LambdaFetch("/orders/status", {
          method: "POST",
          body: {
            id,
            status: 20,
          },
        });
        setOrderInfo();
        setTagInfo();
        setSaving(false);
      } catch (e) {
        setError({
          message: `Failed to commit order. Error: ${e.message}.`,
          reportError: e,
        });
        setSaving(false);
      }
    },
    [setError, setAreYouSure]
  );

  const [suppressedMap, setSuppressedMap] = useState({});

  const generateLabel = useMemo(() => {
    if (!tagInfo) {
      return "Generate";
    }
    let missingCount = 0;
    for (let line of tagInfo) {
      if (!(line.hasPlacement || line.hasOverride || suppressedMap[line.derivedID])) {
        missingCount++;
      }
    }
    return `Generate Tags (${missingCount})`;
  }, [tagInfo, suppressedMap]);

  return (
    <Page
      title="Streaming Order Status"
      pageType="Streaming Order Status"
      navs={[
        {
          label: "Orders",
          key: ORDERS_KEY,
        },
        {
          label: generateLabel,
          key: GENERATE_KEY,
        },
      ]}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="streamingOrderStatusV2">
        {orderInfo && tagInfo ? (
          <Router className="fullPageRouter">
            <OrderView
              path={ORDERS_KEY}
              orderInfo={orderInfo}
              pdfInfo={pdfInfo}
              forceCommit={forceCommit}
            />
            <GenerateView
              path={GENERATE_KEY}
              tagInfo={tagInfo}
              suppressedMap={suppressedMap}
              setSuppressedMap={setSuppressedMap}
              shouldRefreshData={shouldRefreshData}
              setShouldRefreshData={setShouldRefreshData}
            />
            <OrderView default orderInfo={orderInfo} pdfInfo={pdfInfo} forceCommit={forceCommit} />
          </Router>
        ) : (
          <FullPageSpinner />
        )}
        {saving && (
          <div className="saving">
            <FullPageSpinner />
          </div>
        )}
      </div>
    </Page>
  );
};

export default StreamingOrderStatus;
