import React, { Fragment, useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Tooltip } from "react-bootstrap";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ReferenceLine,
  Tooltip as RechartsTooltips,
} from "recharts";

import { Img, Spinner, OldFilterBar, OverlayTrigger } from "../Components";
import { useSetError } from "../redux/modals";

import AutoSizer from "react-virtualized-auto-sizer";

import { awaitJSON, LinearLambdaFetch } from "../utils/fetch-utils";
import { moneyColor } from "../utils/colors";

import { createSeriesData, DATE_FORMAT } from "./LinearPacingUtils";

import "./LinearPacing.scss";

const CLEARANCE_LOWER_BOUND = 65;
const CLEARANCE_UPPER_BOUND = 85;

const dateToUse = new Date();

const formatToMonth = date =>
  R.pipe(Dfns.parse(dateToUse, DATE_FORMAT), Dfns.format("MM/dd"))(date);

const LinearPacingClearance = ({ startDate, endDate }) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedAvail, setSelectedAvail] = useState(null);
  const [networkFilter, setNetworkFilter] = useState(() => () => true);

  const [data, setData] = useState(null);
  const [availInfo, setAvailInfo] = useState(null);
  const [rotationInfo, setRotationInfo] = useState(null);

  const dateInfo = useMemo(() => {
    if (!startDate || !endDate) {
      return {};
    }

    let dfnsStartDate = Dfns.parse(new Date(), DATE_FORMAT, startDate);
    let dfnsEndDate = Dfns.parse(new Date(), DATE_FORMAT, endDate);
    let range = [Dfns.format(DATE_FORMAT, dfnsStartDate)];
    let tempDate = Dfns.addWeeks(0, dfnsStartDate);
    while (!Dfns.isEqual(tempDate, dfnsEndDate)) {
      tempDate = Dfns.addWeeks(1, tempDate);
      range.push(Dfns.format(DATE_FORMAT, tempDate));
    }

    return {
      start: Dfns.format(DATE_FORMAT, dfnsStartDate),
      end: Dfns.format(DATE_FORMAT, dfnsEndDate),
      range,
    };
  }, [startDate, endDate]);

  const { start, end, range } = dateInfo;

  const monthMap = useMemo(() => {
    if (!range) {
      return {};
    }

    return R.pipe(
      R.map(date => [date, formatToMonth(date)]),
      R.fromPairs
    )(range);
  }, [range]);

  useEffect(() => {
    setData(null);
    setAvailInfo(null);
    setRotationInfo(null);
  }, [startDate, endDate]);

  const setError = useSetError();
  useEffect(() => {
    if (start && end) {
      (async () => {
        try {
          let group;
          if (selectedNetwork && selectedAvail) {
            group = ["network", "avail", "rotation"];
          } else if (selectedNetwork) {
            group = ["network", "avail"];
          } else {
            group = ["network"];
          }

          let res = await LinearLambdaFetch("/clearance", {
            params: {
              group,
              start,
              end,
              network: selectedNetwork && selectedNetwork,
              avail: selectedAvail && selectedAvail,
            },
          });

          res = await awaitJSON(res);

          if (selectedNetwork && selectedAvail) {
            setRotationInfo(res);
          } else if (selectedNetwork) {
            setAvailInfo(res);
          } else {
            setData(res);
          }
        } catch (e) {
          setError({ message: "Could not fetch data for clearance page", reportError: e });
        }
      })();
    }
  }, [start, end, selectedNetwork, selectedAvail, setError]);

  const [leftFilterTokens, setLeftFilterTokens] = useState({});
  const [leftFilterAdvanced, setLeftFilterAdvanced] = useState(false);

  const seriesData = useMemo(() => {
    if (!data) {
      return null;
    }

    let series = createSeriesData("network", data, range);
    return series;
  }, [data, range]);

  const availSeriesData = useMemo(() => {
    if (!availInfo) {
      return null;
    }

    let series = createSeriesData("avail", availInfo, range);
    return series;
  }, [availInfo, range]);

  const rotationSeriesData = useMemo(() => {
    if (!rotationInfo) {
      return null;
    }

    let series = createSeriesData("rotation", rotationInfo, range);

    return series;
  }, [rotationInfo, range]);

  const filteredNetworks = useMemo(() => {
    if (!seriesData) {
      return [];
    }

    const filteredNetworks = R.pipe(
      R.prop("keyOrder"),
      R.defaultTo([]),
      R.map(x => ({ network: x })),
      R.filter(networkFilter),
      R.pluck("network")
    )(seriesData);

    // See if the currently selected network is in the newly filtered set. IF it is not, set the selected network/avail to null.
    const inSet = R.contains(selectedNetwork, filteredNetworks);
    if (!inSet) {
      setSelectedNetwork(null);
      setSelectedAvail(null);
    }

    return filteredNetworks;
  }, [networkFilter, seriesData, selectedNetwork]);

  return (
    <div className="pacingDashboard linearPacingDashboard">
      <div className="controls">
        <OldFilterBar
          options={["network"]}
          lines={R.pipe(
            R.defaultTo({}),
            R.prop("keyOrder"),
            R.defaultTo([]),
            R.uniq,
            R.map(x => ({
              network: x,
            }))
          )(seriesData)}
          onFilter={filter => {
            setNetworkFilter(() => filter);
          }}
          tokens={leftFilterTokens}
          onChange={tokens => setLeftFilterTokens(tokens)}
          advanced={leftFilterAdvanced}
          onSetAdvanced={leftFilterAdvanced => setLeftFilterAdvanced(leftFilterAdvanced)}
        />
      </div>
      <div className="chartPanes">
        <div className="leftPane">
          {seriesData ? (
            (() => {
              const { groupedByKey, spendMap, clearanceMap } = seriesData;

              return (
                <div>
                  <div className="pane-title">Networks</div>
                  {filteredNetworks.map((network, idx) => {
                    let series = groupedByKey[network];
                    const isSelected = selectedNetwork === network;

                    let minY = R.min(
                      CLEARANCE_LOWER_BOUND,
                      R.pipe(
                        R.pluck("clearance_rate"),
                        R.filter(Number.isFinite),
                        R.sortBy(R.identity),
                        R.head()
                      )(series)
                    );
                    if (minY % 10 === 0) {
                      minY -= 10;
                    } else {
                      minY -= minY % 10;
                    }

                    return (
                      <div
                        key={`clearance_row_${idx}`}
                        onClick={() => setSelectedNetwork(isSelected ? null : network)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "15px",
                          cursor: "pointer",
                          minHeight: "160px",
                          border: isSelected ? "1px solid black" : "",
                        }}
                      >
                        <Img
                          style={{
                            maxHeight: "50%",
                          }}
                          src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
                          title={network}
                          loader={
                            <span className="logo">
                              {network}
                              <Spinner size={25} />
                            </span>
                          }
                          unloader={<span className="logo">{network}</span>}
                        />
                        <div style={{ height: 125, width: "70%" }}>
                          <AutoSizer>
                            {({ width, height }) => (
                              <LineChart
                                height={height}
                                width={width}
                                data={series}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 10,
                                  bottom: 5,
                                }}
                              >
                                {/* Only render the x-axis for the last series, so all charts share the same x-axis */}
                                <XAxis
                                  dataKey="week"
                                  ticks={range}
                                  tickCount={range.length}
                                  axisLine={false}
                                  tickLine={false}
                                  padding={{ left: 25 }}
                                  tickFormatter={val => monthMap[val]}
                                  domain={[range[0], range[range.length - 1]]}
                                />
                                <YAxis
                                  axisLine={false}
                                  tickLine={false}
                                  tickFormatter={val => `${val}%`}
                                  domain={[minY, 100]}
                                />
                                <Line
                                  type="monotone"
                                  dataKey="clearance_rate"
                                  isAnimationActive={false}
                                  stroke={moneyColor}
                                />
                                <RechartsTooltips
                                  isAnimationActive={false}
                                  formatter={val => [`${val}%`, "Clearance Rate"]}
                                />
                                <ReferenceLine
                                  y={CLEARANCE_LOWER_BOUND}
                                  stroke="blue"
                                  strokeDasharray="3 3"
                                />
                                <ReferenceLine
                                  y={CLEARANCE_UPPER_BOUND}
                                  stroke="red"
                                  strokeDasharray="3 3"
                                />
                              </LineChart>
                            )}
                          </AutoSizer>
                        </div>
                        <OverlayTrigger
                          placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                          overlay={
                            <Tooltip id={`summary_${network}`}>
                              {"Gross Booked / Cumulative Clearance"}
                            </Tooltip>
                          }
                        >
                          <div className="avail-title">
                            {spendMap[network]} / {clearanceMap[network]}
                          </div>
                        </OverlayTrigger>
                      </div>
                    );
                  })}
                </div>
              );
            })()
          ) : (
            <Spinner size={100} />
          )}
        </div>
        {!selectedNetwork
          ? null
          : (() => {
              return (
                <div className="rightPane">
                  <div className="pane-title">Avails for {selectedNetwork}</div>
                  {availInfo && availSeriesData
                    ? (() => {
                        const { keyOrder, groupedByKey, spendMap, clearanceMap } = availSeriesData;
                        return keyOrder.map((avail, idx) => {
                          const availSelected = avail === selectedAvail;
                          let series = groupedByKey[avail];
                          let minY = R.min(
                            CLEARANCE_LOWER_BOUND,
                            R.pipe(
                              R.pluck("clearance_rate"),
                              R.filter(Number.isFinite),
                              R.sortBy(R.identity),
                              R.head()
                            )(series)
                          );
                          if (minY % 10 === 0) {
                            minY -= 10;
                          } else {
                            minY -= minY % 10;
                          }

                          return (
                            <Fragment key={`clearance_row_avail_${idx}`}>
                              <div
                                style={{
                                  display: "flex",
                                  border: avail === selectedAvail ? "1px solid black" : "",
                                  alignItems: "center",
                                  padding: "15px",
                                  cursor: "pointer",
                                  height: "160px",
                                }}
                                onClick={() => setSelectedAvail(availSelected ? null : avail)}
                              >
                                <Img
                                  style={{
                                    maxHeight: "50%",
                                  }}
                                  src={`https://cdn.blisspointmedia.com/networks/${selectedNetwork}.png`}
                                  title={selectedNetwork}
                                  loader={
                                    <span className="logo">
                                      {selectedNetwork} ({avail})
                                      <Spinner size={25} />
                                    </span>
                                  }
                                  unloader={<span className="logo">{selectedNetwork}</span>}
                                />
                                <div className="avail-title">({avail})</div>
                                <div style={{ height: 125, width: "70%" }}>
                                  <AutoSizer>
                                    {({ width, height }) => (
                                      <LineChart
                                        width={width}
                                        height={height}
                                        data={series}
                                        margin={{
                                          top: 5,
                                          right: 30,
                                          left: 10,
                                          bottom: 5,
                                        }}
                                      >
                                        {/* Only render the x-axis for the last series, so all charts share the same x-axis */}
                                        <XAxis
                                          dataKey="week"
                                          ticks={range}
                                          tickCount={range.length}
                                          axisLine={false}
                                          tickLine={false}
                                          padding={{ left: 25 }}
                                          tickFormatter={val => monthMap[val]}
                                          domain={[range[0], range[range.length - 1]]}
                                        />
                                        <YAxis
                                          axisLine={false}
                                          tickLine={false}
                                          scale="linear"
                                          tickFormatter={val => `${val}%`}
                                          domain={[minY, 100]}
                                        />
                                        <Line
                                          type="monotone"
                                          dataKey="clearance_rate"
                                          isAnimationActive={false}
                                          stroke={moneyColor}
                                        />
                                        <RechartsTooltips
                                          isAnimationActive={false}
                                          formatter={val => [`${val}%`, "Clearance Rate"]}
                                        />
                                        <ReferenceLine
                                          y={CLEARANCE_LOWER_BOUND}
                                          stroke="blue"
                                          strokeDasharray="3 3"
                                        />
                                        <ReferenceLine
                                          y={CLEARANCE_UPPER_BOUND}
                                          stroke="red"
                                          strokeDasharray="3 3"
                                        />
                                      </LineChart>
                                    )}
                                  </AutoSizer>
                                </div>
                                <OverlayTrigger
                                  placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                                  overlay={
                                    <Tooltip id={`summary_${avail}`}>
                                      {"Gross Booked / Cumulative Clearance"}
                                    </Tooltip>
                                  }
                                >
                                  <div className="avail-title">
                                    {spendMap[avail]} / {clearanceMap[avail]}
                                  </div>
                                </OverlayTrigger>
                              </div>
                              {availSelected
                                ? (() => {
                                    if (!rotationSeriesData) {
                                      return (
                                        <div>
                                          <Spinner size={100} />
                                        </div>
                                      );
                                    }

                                    const {
                                      groupedByKey,
                                      keyOrder,
                                      spendMap,
                                      clearanceMap,
                                    } = rotationSeriesData;

                                    return (
                                      <div
                                        style={{
                                          backgroundColor: "#DBE4E7",
                                        }}
                                      >
                                        {keyOrder.map((rotation, idx) => {
                                          let series = groupedByKey[rotation];
                                          let minY = R.min(
                                            CLEARANCE_LOWER_BOUND,
                                            R.pipe(
                                              R.pluck("clearance_rate"),
                                              R.filter(Number.isFinite),
                                              R.sortBy(R.identity),
                                              R.head()
                                            )(series)
                                          );
                                          if (minY % 10 === 0) {
                                            minY -= 10;
                                          } else {
                                            minY -= minY % 10;
                                          }

                                          return (
                                            <div
                                              key={`clearance_row_rotation_${idx}`}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "15px",
                                                cursor: "pointer",
                                                height: "160px",
                                              }}
                                            >
                                              <div className="avail-title"> {rotation}: </div>
                                              <div
                                                style={{
                                                  height: 125,
                                                  width: "70%",
                                                }}
                                              >
                                                <AutoSizer>
                                                  {({ width, height }) => (
                                                    <LineChart
                                                      width={width}
                                                      height={height}
                                                      data={series}
                                                      margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 10,
                                                        bottom: 5,
                                                      }}
                                                    >
                                                      {/* Only render the x-axis for the last series, so all charts share the same x-axis */}
                                                      <XAxis
                                                        dataKey="week"
                                                        ticks={range}
                                                        tickCount={range.length}
                                                        axisLine={false}
                                                        tickLine={false}
                                                        padding={{ left: 25 }}
                                                        tickFormatter={val => monthMap[val]}
                                                        domain={[range[0], range[range.length - 1]]}
                                                      />
                                                      <YAxis
                                                        axisLine={false}
                                                        tickLine={false}
                                                        scale="linear"
                                                        tickFormatter={val => `${val}%`}
                                                        domain={[minY, 100]}
                                                      />
                                                      <Line
                                                        type="monotone"
                                                        dataKey="clearance_rate"
                                                        isAnimationActive={false}
                                                        stroke={moneyColor}
                                                      />
                                                      <RechartsTooltips
                                                        isAnimationActive={false}
                                                        formatter={val => [
                                                          `${val}%`,
                                                          "Clearance Rate",
                                                        ]}
                                                      />
                                                      <ReferenceLine
                                                        y={CLEARANCE_LOWER_BOUND}
                                                        stroke="blue"
                                                        strokeDasharray="3 3"
                                                      />
                                                      <ReferenceLine
                                                        y={CLEARANCE_UPPER_BOUND}
                                                        stroke="red"
                                                        strokeDasharray="3 3"
                                                      />
                                                    </LineChart>
                                                  )}
                                                </AutoSizer>
                                              </div>
                                              <OverlayTrigger
                                                placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                                                overlay={
                                                  <Tooltip id={`summary_${rotation}`}>
                                                    {"Gross Booked / Cumulative Clearance"}
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="avail-title">
                                                  {spendMap[rotation]} / {clearanceMap[rotation]}
                                                </div>
                                              </OverlayTrigger>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })()
                                : null}
                            </Fragment>
                          );
                        });
                      })()
                    : (() => {
                        return (
                          <div>
                            <Spinner size={100} />
                          </div>
                        );
                      })()}
                </div>
              );
            })()}
      </div>
    </div>
  );
};

export default LinearPacingClearance;
