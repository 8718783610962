import React, { useCallback, useMemo, useRef, useState } from "react";
import "./BrandMetricsContent.scss";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { DateRange } from "../utils/types";
import {
  InfoTooltip,
  Dropdown,
  Spinner,
  TwoxTwoTable,
  OnexTwoTable,
  DownloadDropdown,
  DropdownToggleType,
  Button,
  ButtonType,
  IconToggleButton,
} from "../Components";
import Key from "./Key";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import * as XLSX from "xlsx";
import { BrandHealthEntry } from "@blisspointmedia/bpm-types/dist/BrandHealthMetrics";
import { useCompanyInfo } from "../redux/company";
import ChartContainer from "../Components/ChartContainer";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

export const formatDate: (date: any) => string = date => {
  let splitDate = date.split("-");
  let year = splitDate[0];
  let month = parseInt(splitDate[1]);
  let day = parseInt(splitDate[2]);

  return [month, day, year.substring(2)].join("/");
};

interface BrandMetricsContentProps {
  focalAdvertiser: string;
  nonFocalAdvertisers: string[];
  type: "Awareness" | "Consideration";
  infoBarText: string;
  toolTipText: string;
  data: BrandHealthEntry[];
  dateRange: DateRange;
}
const BrandMetricsContent: React.FC<BrandMetricsContentProps> = React.memo(
  ({
    focalAdvertiser,
    nonFocalAdvertisers,
    type,
    infoBarText,
    toolTipText,
    data,
    dateRange,
  }: BrandMetricsContentProps): JSX.Element => {
    const { cid } = useCompanyInfo();
    let isInternal = useSelector(UserRedux.isInternalSelector);
    let ref: React.MutableRefObject<any> = useRef(null);
    const [graphType, setGraphType] = useState<string>("Line Graph");
    const graphTypeDropdownOptions: {
      value: string;
      label: string;
    }[] = [
      { value: "Line Graph", label: "Line Graph" },
      { value: "Bar Graph", label: "Bar Graph" },
    ];

    const [selectedToggleOption, setSelectedToggleOption] = useState<string>("showGraph");

    const filteredData: BrandHealthEntry[] = useMemo(() => {
      return data.filter(entry => {
        return (
          Date.parse(entry.date) >= Date.parse(dateRange.start) &&
          Date.parse(entry.date) <= Date.parse(dateRange.end) &&
          entry.metric === type
        );
      });
    }, [data, dateRange.end, dateRange.start, type]);

    const [dataMin, setDataMin] = useState<number>(100);
    const [dataMax, setDataMax] = useState<number>(0);

    const tableFormattedData: {
      date: string;
      row1DataEntry: string;
      row2DataEntry: string;
      row3DataEntry: string;
      row4DataEntry: string;
    }[] = useMemo(() => {
      let minVal = 100;
      let maxVal = 0;
      let output = filteredData.map(entry => {
        let focalMaNum = entry.focal_ma as number;
        let nonFocalMaNum = entry.non_focal_ma as number;
        minVal = Math.min(minVal, focalMaNum, nonFocalMaNum);
        maxVal = Math.max(maxVal, focalMaNum, nonFocalMaNum);

        let updatedFocalMa = (entry.focal_ma as string).concat("%");
        let updatedNonFocalMa = (entry.non_focal_ma as string).concat("%");
        return {
          date: formatDate(entry.date),
          row1DataEntry: updatedFocalMa,
          row2DataEntry: entry.focal_sample_size,
          row3DataEntry: updatedNonFocalMa,
          row4DataEntry: entry.non_focal_sample_size,
        };
      });

      setDataMin(minVal);
      setDataMax(maxVal);

      return output;
    }, [filteredData]);

    const CustomTooltip: (params: any) => JSX.Element = useCallback(
      params => {
        let hasCompetitors = nonFocalAdvertisers.length > 0;
        const { payload } = params;
        if (payload && payload[0]) {
          let {
            date,
            focal_ma,
            focal_sample_size,
            non_focal_ma,
            non_focal_sample_size,
          } = payload[0].payload;
          return (
            <div className="bmcGraphTooltip">
              <div className="bmcDate">{formatDate(date)}</div>
              {hasCompetitors && <div className="bmcFocalAdvertiser">{focalAdvertiser}</div>}
              <div className="bmcFocalMa">
                <div className="label">{`${type}:`}&nbsp;</div>
                <div className="value">{`${focal_ma}%`}</div>
              </div>
              <div className="bmcFocalSampleSize">
                <div className="label">Sample Size:&nbsp;</div>
                <div className="value">{focal_sample_size}</div>
              </div>
              {hasCompetitors && (
                <div className="bmcCompetitor">
                  {nonFocalAdvertisers.length === 1 ? "Competitor" : "Competitors"}
                </div>
              )}
              {hasCompetitors && (
                <div className="bmcNonFocalMa">
                  <div className="label">{`${type}:`}&nbsp;</div>
                  <div className="value">{`${non_focal_ma}%`}</div>
                </div>
              )}
              {hasCompetitors && (
                <div className="bmcNonFocalSampleSize">
                  <div className="label">Sample Size:&nbsp;</div>
                  <div className="value">{`${non_focal_sample_size}`}</div>
                </div>
              )}
            </div>
          );
        }

        return <></>;
      },
      [focalAdvertiser, nonFocalAdvertisers.length, type]
    );

    const MetricsGraph: JSX.Element = useMemo(() => {
      if (graphType === "Line Graph") {
        return (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={filteredData}
              margin={{
                top: 5,
                right: 5,
                bottom: -12,
                left: -25,
              }}
              ref={ref}
            >
              <CartesianGrid strokeWidth="1px" stroke="#e6eaee" vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={{ stroke: "#4e4e50", strokeWidth: "1px" }}
                tickFormatter={value => formatDate(value)}
                tick={{ stroke: "#130d2d", strokeWidth: "0.15px" }}
              />
              <YAxis
                tickFormatter={value => {
                  return `${value}%`;
                }}
                axisLine={{ stroke: "#4e4e50", strokeWidth: "1px" }}
                tick={{ stroke: "#130d2d", strokeWidth: "0.15px" }}
                domain={[Math.floor(dataMin), Math.ceil(dataMax)]}
              />
              <Tooltip content={CustomTooltip} cursor={{ stroke: "#e6eaee" }} />
              <Line
                type="monotone"
                dataKey="focal_ma"
                stroke="#7756c9"
                dot={false}
                strokeWidth="2px"
              />
              {nonFocalAdvertisers.length > 0 && (
                <Line
                  type="monotone"
                  dataKey="non_focal_ma"
                  stroke="#717171"
                  dot={false}
                  strokeWidth="2px"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        );
      } else {
        return (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={filteredData}
              margin={{
                top: 5,
                right: 5,
                bottom: -12,
                left: -25,
              }}
              ref={ref}
            >
              <CartesianGrid strokeWidth="1px" stroke="#e6eaee" vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={{ stroke: "#4e4e50", strokeWidth: "1px" }}
                tickFormatter={value => formatDate(value)}
                tick={{ stroke: "#130d2d", strokeWidth: "0.15px" }}
              />
              <YAxis
                tickFormatter={value => {
                  return `${value}%`;
                }}
                axisLine={{ stroke: "#4e4e50", strokeWidth: "1px" }}
                tick={{ stroke: "#130d2d", strokeWidth: "0.15px" }}
              />
              <Tooltip content={CustomTooltip} cursor={{ fill: "#e6eaee" }} />
              <Bar dataKey="focal_ma" fill="#7756c9" />
              <Bar dataKey="non_focal_ma" fill="#717171" />
            </BarChart>
          </ResponsiveContainer>
        );
      }
    }, [CustomTooltip, dataMax, dataMin, filteredData, graphType, nonFocalAdvertisers.length]);

    const [tableOrientation, setTableOrientation] = useState<"Horizontal" | "Vertical">(
      "Horizontal"
    );

    const exportToExcel = useCallback(() => {
      let fileName = `${cid}_BrandHealth_${type}.xlsx`;

      let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
      let worksheet = XLSX.utils.json_to_sheet(
        filteredData.map(entry => {
          const { date, focal_ma, focal_sample_size, non_focal_ma, non_focal_sample_size } = entry;
          const dateHeader = "Date";
          const focalMaHeader = `${focalAdvertiser} ${type}`;
          const focalSampleSizeHeader = `${focalAdvertiser} Sample Size`;
          const nonFocalMaHeader = `Competitor ${type}`;
          const nonFocalSampleSizeHeader = "Competitor Sample Size";

          let updatedEntry = {};
          updatedEntry[dateHeader] = formatDate(date);
          updatedEntry[focalMaHeader] = parseFloat(focal_ma as string) / 100;
          updatedEntry[focalSampleSizeHeader] = +focal_sample_size.replace(",", "");
          if (nonFocalAdvertisers.length > 0) {
            updatedEntry[nonFocalMaHeader] = parseFloat(non_focal_ma as string) / 100;
            updatedEntry[nonFocalSampleSizeHeader] = +non_focal_sample_size.replace(",", "");
          }

          return updatedEntry;
        })
      );

      filteredData.forEach((entry, index) => {
        worksheet[`A${index + 2}`].z = "m/d/yy";
        worksheet[`B${index + 2}`].z = "0.000";
        worksheet[`C${index + 2}`].z = "#,###";
        if (nonFocalAdvertisers.length > 0) {
          worksheet[`D${index + 2}`].z = "0.000";
          worksheet[`E${index + 2}`].z = "#,###";
        }
      });

      let sheetName = `${cid}_${type}`;

      workbook.SheetNames.push(sheetName);
      workbook.Sheets[sheetName] = worksheet;

      XLSX.writeFile(workbook, fileName);
    }, [cid, filteredData, focalAdvertiser, nonFocalAdvertisers.length, type]);

    const downloadPNG = useCallback(async () => {
      const contents = document.querySelector<HTMLElement>(`.showGraph-${type}`);
      if (!contents) {
        return;
      }
      const canvas = await html2canvas(contents);
      const dataURL = canvas.toDataURL("image/png");
      downloadjs(dataURL, `${cid}_BrandHealth_${type}.png`, "image/png");
    }, [cid, type]);

    return (
      <ChartContainer
        title={`Brand ${type}`}
        rightActions={
          filteredData.length > 0 && (
            <>
              {selectedToggleOption === "showGraph" && (
                <Dropdown
                  type={DropdownToggleType.FILLED}
                  design="secondary"
                  size="sm"
                  value={graphType}
                  options={graphTypeDropdownOptions}
                  onChange={setGraphType}
                />
              )}
              {selectedToggleOption === "showTable" && (
                <Button
                  type={ButtonType.FILLED}
                  design="secondary"
                  size="sm"
                  onClick={() => {
                    tableOrientation === "Horizontal"
                      ? setTableOrientation("Vertical")
                      : setTableOrientation("Horizontal");
                  }}
                >
                  Swap Table Axes
                </Button>
              )}
              <IconToggleButton
                size="sm"
                options={[
                  { key: "showTable", label: "table view", icon: <MdOutlineTableRows /> },
                  { key: "showGraph", label: "graph view", icon: <MdShowChart /> },
                ]}
                selectedOption={selectedToggleOption}
                onChange={setSelectedToggleOption}
              />
              {isInternal && (
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[exportToExcel, downloadPNG]}
                  disabledMenuOptions={
                    selectedToggleOption === "showGraph"
                      ? { XLSX: false, PNG: false }
                      : { XLSX: false, PNG: true }
                  }
                />
              )}
            </>
          )
        }
      >
        <div className={`bmcContentContainer ${selectedToggleOption}-${type}`}>
          <div
            className={`bmcContent ${selectedToggleOption === "showTable" ? tableOrientation : ""}`}
          >
            <div
              className={`bmcInfoSection ${
                selectedToggleOption === "showTable" ? tableOrientation : ""
              }`}
            >
              <div className="bmcLabel">{type}</div>
              <div
                className={`bmcInfoBar ${
                  selectedToggleOption === "showTable" ? tableOrientation : ""
                }`}
              >
                <div className="bmcText">{infoBarText}</div>
                <div
                  className={`bmcTooltipIcon ${type} ${
                    selectedToggleOption === "showTable" ? tableOrientation : ""
                  }`}
                >
                  <InfoTooltip size="sm">{toolTipText}</InfoTooltip>
                </div>
              </div>
            </div>
            {filteredData.length > 0 && selectedToggleOption === "showGraph" && (
              <div className="bmcData">
                <div className="bmcGraphSection">{MetricsGraph}</div>
                <div className="bmcLegend">
                  <Key
                    focalAdvertiser={focalAdvertiser}
                    nonFocalAdvertisers={nonFocalAdvertisers}
                    type={type}
                  ></Key>
                </div>
              </div>
            )}
            {filteredData.length > 0 &&
              nonFocalAdvertisers.length > 0 &&
              selectedToggleOption === "showTable" && (
                <div className={`bmcData showTable ${tableOrientation}`}>
                  <TwoxTwoTable
                    orientation={tableOrientation}
                    mainHeader1={focalAdvertiser}
                    mainHeader2="Competitor"
                    secondaryHeader1={type}
                    secondaryHeader2="Sample Size"
                    data={tableFormattedData}
                  ></TwoxTwoTable>
                </div>
              )}
            {filteredData.length > 0 &&
              nonFocalAdvertisers.length === 0 &&
              selectedToggleOption === "showTable" && (
                <div className={`bmcData showTable ${tableOrientation}`}>
                  <OnexTwoTable
                    orientation={tableOrientation}
                    header1={type}
                    header2="Sample Size"
                    data={tableFormattedData}
                  ></OnexTwoTable>
                </div>
              )}
            {filteredData.length === 0 && <Spinner />}
          </div>
        </div>
      </ChartContainer>
    );
  }
);

export default BrandMetricsContent;
