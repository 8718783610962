// Experiment for Streaming Plan Builder Page.

const streamingPlanBuilderUsers: string[] = [
  "carling.sugarman@tinuiti.com",
  "alex.echeverria@tinuiti.com",
  "justin.manus@tinuiti.com",
  "rochell.rotenberg@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "automation@tinuiti.com",
  "sarah.butts@tinuiti.com",
  "michael.librie@tinuiti.com",
  // Add users here!
];

export const shouldEnableStreamingPlanBuilder = (userEmail: string): boolean => {
  return streamingPlanBuilderUsers.includes(userEmail);
};
