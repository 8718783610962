import React from "react";

import { Form } from "react-bootstrap";

import { parseInputToInt } from "../utils/data";
import { SlideState } from "./slideTemplateConstants";

import { SlideType, SettingsComponentProps, SlideContext } from "./slidesTypes";

const DEFAULT_PLANS_LINES_PER_SLIDE = 11;
const DEFAULT_PLANS_NUM_SLIDES = 1;
const MAX_TABLE_SIZE = 420;

export interface LinearPlansSlideState {
  lineHeight: number;
  numSlides: number;
  linesPerSlide: number;
}

interface LinearPlansSlideData {
  slides: number;
  lineHeight: number;
  linesPerSlide: number;
  week: string;
}

class LinearPlansSlide extends SlideType {
  static typeKey = "linearPlans";
  static displayKey = "Linear Plans";
  static defaultState: LinearPlansSlideState = {
    numSlides: DEFAULT_PLANS_NUM_SLIDES,
    lineHeight: 30,
    linesPerSlide: DEFAULT_PLANS_LINES_PER_SLIDE,
  };

  static SettingsComponent: React.FC<SettingsComponentProps<LinearPlansSlideState>> = React.memo(
    ({ state, setState }) => {
      const { numSlides, lineHeight, linesPerSlide } = state;

      return (
        <div className="settingsBox">
          <div>
            <Form.Group>
              <Form.Label>Lines per Slide</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${linesPerSlide}`}
                onChange={e => setState({ linesPerSlide: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Line Height</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={20}
                max={MAX_TABLE_SIZE / linesPerSlide}
                value={`${lineHeight}`}
                onChange={e => setState({ lineHeight: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Maximum Number of Slides</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={`${numSlides}`}
                onChange={e => setState({ numSlides: parseInputToInt(e.currentTarget.value) })}
              />
            </Form.Group>
          </div>
        </div>
      );
    }
  );

  generate = (context: SlideContext, state: SlideState): Promise<LinearPlansSlideData> => {
    let ourState = state as LinearPlansSlideState;
    return Promise.resolve({
      slides: ourState.numSlides,
      lineHeight: ourState.lineHeight,
      linesPerSlide: ourState.linesPerSlide,
      week: context.week,
    });
  };
}

export default LinearPlansSlide;
