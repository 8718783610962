import React from "react";
import { ComposedChart, CartesianGrid, Line, Bar, XAxis, YAxis, Legend } from "recharts";

import { abbreviateNumberAMC, capitalizeFirstLetter } from "../../AMC/AMCFormatters";
import { AREA_LINE_CHART_COLOR } from "../../utils/colors";

interface StackedBarAndLineChartProps {
  lineDataKey: string;
  barDataKeys: string[];
  data: any;
  legendLocation?: "top" | "bottom";
  xAxisDataKey: string;
  xAxisLabel: string;
  barYAxisDataKey: string;
  barYAxisLabel: string;
  lineYAxisDataKey: string;
  lineYAxisLabel: string;
  fillColors: string[];
  isAnimationActive?: boolean;
  height?: number;
  width?: number;
  lineChartColor?: string;
}

const StackedBarAndLineChart: React.FC<StackedBarAndLineChartProps> = ({
  lineDataKey,
  barDataKeys,
  data,
  legendLocation = "top",
  xAxisDataKey,
  xAxisLabel,
  barYAxisDataKey,
  barYAxisLabel,
  lineYAxisDataKey,
  lineYAxisLabel,
  isAnimationActive = false,
  height,
  width,
  lineChartColor = AREA_LINE_CHART_COLOR,
  fillColors,
}) => {
  const xAxis = <XAxis dataKey={xAxisDataKey} label={xAxisLabel} />;
  const barYAxis = (
    <YAxis
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "left",
        value: barYAxisLabel,
      }}
      orientation="left"
      tickFormatter={tick => `${abbreviateNumberAMC(tick)}`}
      type="number"
      yAxisId="0"
    />
  );
  const lineYAxis = (
    <YAxis
      dataKey={lineYAxisDataKey}
      domain={[0, "auto"]}
      label={{
        angle: -90,
        position: "right",
        value: lineYAxisLabel,
      }}
      orientation="right"
      tickFormatter={tick => `${abbreviateNumberAMC(tick)}`}
      type="number"
      yAxisId="1"
    />
  );
  const legend = (
    <Legend formatter={item => capitalizeFirstLetter(item)} verticalAlign={legendLocation} />
  );
  const line = (
    <Line
      yAxisId="1"
      stroke={lineChartColor}
      dataKey={lineDataKey}
      type="linear"
      isAnimationActive={isAnimationActive}
      strokeWidth={3}
      dot={false}
    />
  );

  return (
    <ComposedChart width={width} height={height} data={data}>
      <CartesianGrid />
      {legend}
      {xAxis}
      {lineYAxis}
      {barYAxis}
      {data &&
        barDataKeys.map((key, index) => {
          let dataKey = `${barYAxisDataKey}["${key}"]`;
          return (
            <Bar
              className="graph-bar"
              barSize={20}
              dataKey={dataKey}
              fill={fillColors[index]}
              stackId="a"
              isAnimationActive={isAnimationActive}
            />
          );
        })}
      {line}
    </ComposedChart>
  );
};

export default StackedBarAndLineChart;
