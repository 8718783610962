import React, { useLayoutEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { convertSVGStringToPNGURI } from "./download-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../Slides/slidesTypes";

interface WrappedComponentProps {
  component: any;
  height: number;
  width: number;
  onLoad: (WrappedComponentRefCurrent: { elem: any }) => void;
}

const WrappedComponent: React.FC<WrappedComponentProps> = ({
  component,
  height,
  width,
  onLoad,
}) => {
  const [clear, setClear] = useState<boolean>(false);
  const exportRef = useRef<SVGSVGElement | null>(null);
  useLayoutEffect(() => {
    if (clear) {
      exportRef.current = null;
      setClear(false);
    }
    if (exportRef.current) {
      onLoad({ elem: exportRef.current });
      setClear(true);
    }
  }, [onLoad, clear]);
  return (
    <svg ref={exportRef} height={height} width={width}>
      {component}
    </svg>
  );
};

interface WrappedComponentResult {
  elem: SVGSVGElement | null;
}

// Converts a component to image Url
// Verify that the component being passed in has a height and width, otherwise a blank image may be captured
export const convertComponentToImageUrl = async (
  component: JSX.Element,
  height: number,
  width: number,
  claimSandbox: ClaimSandboxFunction,
  releaseSandbox: ReleaseSandboxFunction,
  addS3Image: S3PromiseFunction
): Promise<string> => {
  let sandbox = await claimSandbox();
  const {
    svgString,
  }: Omit<WrappedComponentResult, "elem"> & {
    svgString: string;
  } = await new Promise((resolve, reject) => {
    try {
      const load = () => {
        ReactDOM.render(
          <WrappedComponent
            component={component}
            height={height}
            width={width}
            onLoad={({ elem }) => {
              if (elem) {
                let boundingBox = elem.getBoundingClientRect();
                let svgString = convertSVGStringToPNGURI(
                  elem.outerHTML,
                  boundingBox.width,
                  boundingBox.height
                );
                if (sandbox.element) {
                  ReactDOM.unmountComponentAtNode(sandbox.element);
                }
                resolve({ svgString });
              }
            }}
          />,
          sandbox.element
        );
      };
      load();
    } catch (e) {
      reject(e);
    }
  });
  releaseSandbox(sandbox);
  let componentUrl = await addS3Image(svgString);
  return componentUrl;
};
