import React from "react";
import moment from "moment";
import { Form } from "react-bootstrap";
import { BPMDateRange, Dropdown, PieChart, DropdownToggleType } from "../../Components";
import { DateRange } from "../../utils/types";
import {
  tmrKpiPull,
  tmrRetailerFilterOptions,
  tmrSortByOptions,
  CHART_FILL_COLORS,
  TMR_COLORS,
  PROFILE_FILTER_MESSAGING,
} from "./utils";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { SharedState, SlideState } from "../slideTemplateConstants";
import StackedBarAndLineChart from "./StackedBarAndLineChart";
import {
  SettingsComponentProps,
  SlideContext,
  SlideType,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { fetchCategorySlideData } from "./QueryServices";

export interface MarketplaceReportCategorySlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  sort: string;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface PieData {
  name: string;
  value: number;
  color?: string;
}

interface MarketplaceReportCategorySlideData {
  title: string;
  subTitle: string;
}

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

const formatPieData = (data: any): PieData[] => {
  return data.map((item, index) => ({
    name: item.category,
    value: item.sales === 0 ? 0.001 : item.sales,
    color: CHART_FILL_COLORS[index] || TMR_COLORS,
  }));
};

const pieComponent = (height: number, width: number, data: {}[]) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    {/* This is because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
    {/*// @ts-ignore */}
    <PieChart data={data} size={300} />
  </div>
);

class MarketplaceReportCategorySlide extends SlideType {
  static typeKey = "TmrCategorySlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportCategorySlideState = {
    title: "Product Category Label Reporting",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    sort: "sales",
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportCategorySlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, sort, dates, kpi, companyProfile } = state;

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label> Sort by</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={sort}
              options={tmrSortByOptions}
              onChange={change => setState({ sort: change })}
            />
          </div>
         <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
            <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportCategorySlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      retailer,
      dates,
      sort,
      companyProfile,
      kpi,
    } = state as MarketplaceReportCategorySlideState;

    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }

    const height = 500;
    const width = 1000;

    let data = await fetchCategorySlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      sort,
      profileNames
    );

    const uniqueLabels = new Set<string>();

    data.dataByWeek.forEach(item => {
      item.sales.forEach(sale => {
        uniqueLabels.add(sale.label);
      });
    });

    const dataKeys = Array.from(uniqueLabels);

    const transformedArray = data.dataByWeek.map(item => {
      const transformedSales = item.sales.reduce((acc, sale) => {
        acc[sale.label] = sale.value;
        return acc;
      }, {});

      return {
        ...item,
        sales: transformedSales,
      };
    });

    const barAndLineChart = (
      <StackedBarAndLineChart
        data={transformedArray}
        barDataKeys={dataKeys}
        lineDataKey={kpi}
        lineYAxisDataKey={kpi}
        lineYAxisLabel={kpi.toUpperCase()}
        fillColors={CHART_FILL_COLORS}
        legendLocation="top"
        xAxisDataKey="reportWeek"
        barYAxisLabel="Sales"
        barYAxisDataKey="sales"
        xAxisLabel="Report Week"
        height={height}
        width={width}
        isAnimationActive={false}
      />
    );

    let barAndLineChartUrl = await convertComponentToImageUrl(
      barAndLineChart,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    let pieData = formatPieData(data.dataByCategory);

    // passing same value for height and width to make it a square
    const pieChart = pieComponent(height, height, pieData);

    let pieChartUrl = await convertComponentToImageUrl(
      pieChart,
      height,
      height,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      data,
      retailer,
      barAndLineChartUrl,
      pieChartUrl,
      pieData,
      dataKeys,
      kpi,
    });
  };
}
export default MarketplaceReportCategorySlide;
