import "../OptimizationsUtils/Optimizations.scss";
import {
  awaitJSON,
  JavaLambdaFetch,
  pollS3,
  StreamingOptimizationsLambdaFetch,
} from "../utils/fetch-utils";
import { BPMTable, NumberFormatter, FullPageSpinner, Spinner, CellRenderer } from "../Components";
import { Button } from "react-bootstrap";
import {
  MdAllOut,
  MdAttachMoney,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdNotInterested,
} from "react-icons/md";
import { RunResult, StreamingOptimizationsContext } from "./StreamingOptimizations";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import { download } from "../utils/download-utils";
import Papa from "papaparse";
import React, { useMemo, useContext, useCallback, useState } from "react";

const RunView = React.memo(() => {
  const {
    runResult,
    company,
    runId,
    optimizations,
    downloadMediaPlan,
    selectedView,
    RUNS_SUMMARY_KEY,
    RUNS_CONSTRAINTS_KEY,
  } = useContext(StreamingOptimizationsContext);

  const [constraintRequestStatus, setConstraintRequestStatus] = useState(0);
  const [isDownloadConstraintRequestData, setIsDownloadConstraintRequestData] = useState(false);
  const [constraintsData, setConstraintsData] = useState([]);
  const [dynamicTokens, setDynamicTokens] = useState<string[]>();

  const runInfo = useMemo(() => {
    if (!runId || !optimizations) {
      return;
    }
    return R.filter(row => `${row.build_number}` === runId, optimizations)[0];
  }, [runId, optimizations]);

  const totalSpend = useMemo(() => {
    if (!runResult) {
      return;
    }

    return R.sum(
      R.map(
        row => parseFloat(row.Cost),
        R.filter(row => row["Placement Code"] !== "", runResult) as RunResult[]
      )
    );
  }, [runResult]);

  const networkPivot = useMemo(() => {
    if (!runResult || !totalSpend) {
      return;
    }

    const grouped = R.groupBy(
      row => row["Placement ID"],
      R.filter(row => row["Placement Code"] !== "", runResult) as RunResult[]
    );

    return R.sortBy(
      (row: any) => -row.spend,
      R.values(
        R.map(key => {
          const spend = R.sum(R.map(row => parseFloat(row.Cost), grouped[key]) as number[]);
          return {
            network: grouped[key][0]["Placement ID"],
            spend,
            pct: spend / totalSpend,
          };
        }, R.keys(grouped))
      )
    );
  }, [runResult, totalSpend]);

  console.log(networkPivot);

  const overviewData = useMemo(() => {
    if (!runId || !runInfo) {
      return [];
    }

    return [
      { key: "#", value: runId },
      { key: "Status", value: runInfo.status },
      { key: "Date", value: runInfo.date },
      { key: "Constraint", value: runInfo.constraint_name },
      { key: "OF", value: runInfo.objective_value },
      { key: "Cost", value: runInfo.cost },
      { key: "Budget", value: runInfo.budget },
      { key: "Logs", value: runInfo.log_url },
      { key: "Excel", value: "" },
    ];
  }, [runId, runInfo]);

  const networkPivotHeader = [
    {
      label: "Placement Code",
      name: "network",
      minFlexWidth: 500,
      flex: 1,
    },
    {
      label: "Cost",
      name: "spend",
      renderer: row => <NumberFormatter value={row.spend} type={"$"} decimals={0} />,
    },
    {
      label: "% of Spend",
      name: "pct",
      renderer: row => <NumberFormatter value={row.pct} type={"%"} decimals={1} />,
    },
  ];

  const overviewHeader = useMemo(
    () => [
      {
        label: "Key",
        name: "key",
        nonInteractive: true,
      },
      {
        label: "Value",
        name: "value",
        nonInteractive: true,
        width: 200,
        renderer: (row, rowIndex) => {
          if (R.contains(overviewData[rowIndex].key, ["OF"])) {
            return <NumberFormatter value={row.value} type={""} decimals={1} />;
          } else if (R.contains(overviewData[rowIndex].key, ["Cost", "Budget"])) {
            return <NumberFormatter value={row.value} type={"$"} decimals={0} />;
          } else if (R.contains(overviewData[rowIndex].key, ["Date"])) {
            return R.pipe(Dfns.parseISO, Dfns.format("yyyy-MM-dd hh:mma"))(row.value);
          } else if (R.contains(overviewData[rowIndex].key, ["Logs"])) {
            return (
              <a href={row.value} target="_blank" rel="noopener noreferrer">
                Logs
              </a>
            );
          } else if (R.contains(overviewData[rowIndex].key, ["Excel"])) {
            return (
              <Button variant="link" onClick={() => downloadMediaPlan()}>
                Excel
              </Button>
            );
          } else {
            return row.value;
          }
        },
      },
    ],
    [overviewData, downloadMediaPlan]
  );

  const constraintsHeader = [
    {
      label: "Type",
      name: "constraintType",
      width: 150,
    },
    {
      label: "Name",
      name: "Name",
      flex: true,
      minFlexWidth: 200,
    },
    {
      label: "Min Constraint",
      name: "MinConstraint",
      width: 150,
    },
    {
      label: "Max Constraint",
      name: "MaxConstraint",
      width: 150,
    },
    {
      label: "Final Value",
      name: "FinalValue",
      flex: true,
      minFlexWidth: 200,
    },
    {
      label: "Final Value (Rounded)",
      name: "FinalValueNonFractional",
      width: 150,
    },
    {
      label: "Binding",
      name: "isBinding",
      width: 150,
    },
    {
      label: "Constraint Violation Surplus",
      name: "NonFractionalSurplus",
      width: 150,
    },
    {
      label: "Constraint Violation Deficit",
      name: "NonFractionalDeficit",
      width: 150,
    },
  ];

  const totalsRenderer: CellRenderer<Element | number | string | undefined> = ({
    data,
    style = {},
    classes = [],
  }) => {
    let output;
    if (Number.isFinite(data) && (data as number) <= 1) {
      output = <NumberFormatter value={data} type={"%"} decimals={1} />;
    } else if (Number.isFinite(data) && (data as number) > 1) {
      output = <NumberFormatter value={data} type={"$"} decimals={0} />;
    }
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {output}
      </div>
    );
  };

  const networkTotalsRow = useMemo(() => ({ network: "Grand Total", spend: totalSpend, pct: 1 }), [
    totalSpend,
  ]);

  const downloadCSV = useCallback(
    async rows => {
      const csv = Papa.unparse(rows);
      const excelResult = await JavaLambdaFetch("/csv_to_excel", {
        method: "POST",
        body: {
          csv,
        },
      });
      const excelJson = await awaitJSON(excelResult);
      await pollS3({
        bucket: "bpm-cache",
        mimeType: "application/vnd.ms-excel",
        filename: excelJson.s3Out,
        overloadFilename: `Optimization_${company}_${runId}.xlsx`,
        autoDownload: true,
      });
    },
    [company, runId]
  );

  const requestConstraintData = async runInfo => {
    const { build_number, company_id, branch } = runInfo;
    setConstraintRequestStatus(1);
    const response = await StreamingOptimizationsLambdaFetch("/constraintsDebugger", {
      params: {
        branch,
        kpi: company_id,
        runId: build_number,
      },
    });
    let res = await awaitJSON(response);
    if (isDownloadConstraintRequestData) {
      download(res.csv, `Constraints: ${"branch"}/${"kpi"}/${"runId"}.csv`, "text/csv");
    }

    // Transform data for table
    setConstraintsData(
      res.data.map(row => {
        // Parse Type
        let parsed = row.Name.split(":");
        if (parsed.length !== 1) {
          row.Name = parsed.slice(1).join("");
          row.constraintType = parsed[0];
        } else {
          row.constraintType = "Unknown";
        }
        // isBinding
        if (row.BindingMin === "1") {
          row.isBinding = "At Minimum";
        } else if (row.BindingMax === "1") {
          row.isBinding = "At Maximum";
        } else {
          row.isBinding = "Not binding";
        }
        // String -> Number
        row.FinalValue = parseFloat(row.FinalValue);
        row.FinalValueNonFractional = row.FinalValueNonFractional
          ? parseInt(row.FinalValueNonFractional)
          : "-";
        row.MinConstraint = parseInt(row.MinConstraint);
        row.MaxConstraint = parseInt(row.MaxConstraint);
        // Surplus / Deficit
        row.NonFractionalSurplus = "None";
        row.NonFractionalDeficit = "None";
        if (row.FinalValueNonFractional !== "-" && !isNaN(row.FinalValue)) {
          if (row.FinalValueNonFractional > row.MaxConstraint) {
            row.NonFractionalSurplus = row.FinalValueNonFractional - row.MaxConstraint;
          } else if (row.FinalValueNonFractional < row.MinConstraint) {
            row.NonFractionalDeficit = row.MinConstraint - row.FinalValueNonFractional;
          }
        }
        return row;
      })
    );

    setConstraintRequestStatus(2);
  };

  if (!runResult) {
    return <FullPageSpinner />;
  }

  const PresetFilters = () => {
    return (
      <>
        <div className="presetFilterBar">
          <div className="presetFilterBarTitle">Constraint Types</div>
          <div>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Budget"])}
              className="filterButton"
            >
              <MdAttachMoney />
              &nbsp;Budget
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Avoid"])}
              className="filterButton"
            >
              <MdNotInterested />
              &nbsp;Avoid
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Global"])}
              className="filterButton"
            >
              <MdAllOut />
              &nbsp;Global
            </Button>
          </div>
        </div>
        <div className="presetFilterBar">
          <div className="presetFilterBarTitle">Preset Filters</div>
          <div>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Binding", "is", "At Minimum"])}
              className="filterButton"
            >
              Binding at minimum
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Binding", "is", "At Maximum"])}
              className="filterButton"
            >
              Binding at maximum
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Final Value", "is not", "0"])}
              className="filterButton"
            >
              Non-zero
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Final Value (Rounded)", "is not", "0"])}
              className="filterButton"
            >
              Non-zero rounded
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() =>
                setDynamicTokens([
                  "Constraint Violation Surplus",
                  "is not",
                  "None",
                  "or",
                  "Constraint Violation Deficit",
                  "is not",
                  "None",
                ])
              }
              className="filterButton"
            >
              Rounded value violates constraint
            </Button>
          </div>
        </div>
      </>
    );
  };

  if (selectedView === RUNS_SUMMARY_KEY) {
    return (
      <div className="resultPivot">
        <div className="resultPivotColumn middle">
          <BPMTable
            headerHeight={40}
            headers={networkPivotHeader}
            data={networkPivot}
            filterBar={false}
            totals={networkTotalsRow}
            totalsRenderer={totalsRenderer}
          />
        </div>
        <div className="resultPivotColumn right">
          <BPMTable
            headerHeight={40}
            headers={overviewHeader}
            data={overviewData}
            filterBar={false}
          />
          <Button onClick={async () => await downloadCSV(runResult)}>Export</Button>
        </div>
      </div>
    );
  } else if (selectedView === RUNS_CONSTRAINTS_KEY) {
    return (
      <div className="resultConstraints">
        {constraintRequestStatus === 0 ? (
          <div className="beforeFetch">
            <Button
              className="downloadButton"
              variant="outline-secondary"
              onClick={() => requestConstraintData(runInfo)}
              disabled={!runInfo}
            >
              Download constraint data {runInfo ? false : <Spinner />}
            </Button>
            <div className="checkboxRow">
              <Button className="acceptedButton" variant="link" disabled>
                Display in table <MdCheckBox />
              </Button>
              <Button
                className="acceptedButton"
                variant="link"
                onClick={() => setIsDownloadConstraintRequestData(!isDownloadConstraintRequestData)}
              >
                Save file locally{" "}
                {isDownloadConstraintRequestData ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
              </Button>
            </div>
          </div>
        ) : constraintRequestStatus === 1 ? (
          <div>
            <Spinner
              size={50}
              /// @ts-ignore
              style={{ marginTop: "35px" }}
            />
          </div>
        ) : (
          <>
            <PresetFilters />
            <BPMTable
              headerHeight={40}
              defaultAdvancedFilter
              dynamicTokens={dynamicTokens}
              setDynamicTokens={setDynamicTokens}
              headers={constraintsHeader}
              data={constraintsData}
            />
          </>
        )}
      </div>
    );
  } else {
    return <Spinner></Spinner>;
  }
});

export default RunView;
