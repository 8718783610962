import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import * as R from "ramda";

import { Overlay, Tooltip } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

import { makeFilter, getNextOptions } from "../../utils/boolean-logic-parser-creator";

import { TextToggleButton, Button, ButtonType } from "..";

import "./OldFilterBar2.scss";
import { MdSearch } from "react-icons/md";

const isLike = tokens => {
  let lastElem = R.last(tokens);

  return R.prop("type", lastElem) === "op" && R.prop("value", lastElem).includes("like");
};

const isValidNewOption = (elem, tokens) => {
  if (!elem) {
    return false;
  }
  return isLike(tokens);
};

const makeNewOption = (text, length) => ({
  label: text,
  key: text,
  value: `${text}~${length}`,
  type: "val",
});

const basicToAdvanced = (tokens, options) => {
  let fakeTokens = [];
  for (let token of tokens) {
    fakeTokens.push({
      label: "(",
      type: "openParen",
    });
    for (let option of options) {
      let label, name;
      if (typeof option === "string") {
        label = option;
        name = option;
      } else {
        ({ label, name } = option);
      }

      if (option.boolean) {
        let tokenLC = token.value.toLowerCase().replace("!", "");
        if (tokenLC === option.name.toLowerCase() || tokenLC === option.label.toLowerCase()) {
          fakeTokens.push({
            label,
            key: name,
            type: "cat",
          });
          fakeTokens.push({
            label: "is",
            type: "op",
            key: "is",
          });
          let isNot = token.value.charAt(0) === "!";
          fakeTokens.push({
            label: isNot ? "false" : "true",
            type: "val",
            key: isNot ? "false" : "true",
          });
          fakeTokens.push({
            label: "or",
            type: "bool",
            key: "or",
          });
        }
      } else {
        fakeTokens.push({
          label,
          key: name,
          type: "cat",
        });
        fakeTokens.push({
          label: "is like",
          type: "op",
          key: "is like",
        });
        fakeTokens.push({
          label: token.label,
          type: "val",
          key: token.value,
        });
        fakeTokens.push({
          label: "or",
          type: "bool",
          key: "or",
        });
      }
    }
    // We have an extra "or"
    fakeTokens.pop();
    fakeTokens.push({
      label: ")",
      type: "closeParen",
    });
    fakeTokens.push({
      label: "and",
      type: "bool",
      key: "and",
    });
  }
  // We have an extra "and"
  fakeTokens.pop();
  return fakeTokens;
};

// Given an array of strings representing tokens, a list of categories, and a list of filterable lines, construct a
// token object. This is meant to be used to convert a list of tokens taken as a parameter into the internal token
// representation.
export const parseRawTokenArray = (rawTokens, options, lines) => {
  let tokens = [];
  // Go over the token strings we got
  for (let rawToken of rawTokens) {
    // Look at the options we have available to us with the tokens we've added thus-far
    let ourOptions = getNextOptions(tokens, options, lines);
    // Does the next raw token match one of the options?
    let ourToken = R.find(
      R.pipe(R.prop("label"), label => label === rawToken),
      ourOptions
    );
    // If so, use the valid token. If not, we might be allowed to make a "custom option", which is the free-text option
    // (generally for "is like" filters). If we can do that, do that. Otherwise, we have an invalid token so we'll just
    // back out.
    if (ourToken) {
      tokens.push({
        ...ourToken,
        isFixed: true,
      });
    } else if (isValidNewOption(rawToken, tokens)) {
      tokens.push({
        ...makeNewOption(rawToken, tokens.length),
        isFixed: true,
      });
    } else {
      console.error(
        `Unable to parse default filter bar tokens. Un-matchable token was: "${rawToken}"`
      );
      break;
    }
  }
  if (tokens.length) {
    tokens[tokens.length - 1].isFixed = false;
  }
  return tokens;
};

// lines: [{ key1: val, key2: val, ...}]
// options: [key1, key2] or [{ name: key1, label: label1, type: string}, { name: key2, label: label2, type: boolean}]
export const OldAdvancedFilterBar2 = React.memo(
  ({
    lines,
    options,
    onFilter = () => {},
    onChange = () => {},
    tokens,
    defaultTokens = [],
    dynamicTokens = [],
    setDynamicTokens = () => {},
    setMalformed = () => {},
    dismissMalformed = () => {},
    disableAutoFilter,
  }) => {
    // Checks if there's saved tokens for this page in the Session Storage. If there are, sets the default tokens to them
    const savedTokens = window.sessionStorage.getItem(
      `advanced_filter_${window.location.pathname}`
    );
    const parsedSavedTokens = JSON.parse(savedTokens);

    const [menuOpen, setMenuOpen] = useState(false);
    const [input, setInput] = useState("");
    const [localTokens, setLocalTokens] = useState(
      parsedSavedTokens && parsedSavedTokens.length > 0 ? parsedSavedTokens : defaultTokens
    );

    const ourTokens = tokens || localTokens;

    // This takes our tokens and creates a filter. If the makeFilter function fails, we don't set a filter and display
    // an error message (unless displayMalformed is false).
    let submitFilter = useCallback(
      (tokens = [], displayMalformed = true) => {
        let ourFilter;
        if (!tokens.length) {
          ourFilter = () => true;
        } else {
          try {
            ourFilter = makeFilter(tokens, options);
          } catch (e) {
            if (displayMalformed) {
              setMalformed(e.message || e);
            }
            return;
          }
        }
        dismissMalformed();
        onFilter(ourFilter, tokens);
      },
      [setMalformed, dismissMalformed, onFilter, options]
    );

    // We only want this to run on mount, so we don't want it to rerun whenever the tokens or anything else changes
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      submitFilter(ourTokens);
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    // Since `displayMalformed` is false, then this will submit the filter if it's valid, and do nothing otherwise.
    let submitFilterIfValid = useCallback(tokens => submitFilter(tokens, false), [submitFilter]);

    const setTokens = useCallback(
      newTokens => {
        setLocalTokens(newTokens);
        onChange(newTokens);
        if (!disableAutoFilter) {
          submitFilterIfValid(newTokens);
        }

        // When the tokens change, they're saved in session storage so that the filter query isn't lost on page reload
        window.sessionStorage.setItem(
          `advanced_filter_${window.location.pathname}`,
          JSON.stringify(newTokens)
        );
      },
      [setLocalTokens, onChange, disableAutoFilter, submitFilterIfValid]
    );

    // Turns a selected option ('elem') into a filter token
    const addOption = useCallback(
      elem => {
        setInput("");
        let newTokens = [...ourTokens];
        // Setting 'isFixed' ensures that only the last token in the query can be deleted
        if (newTokens.length) {
          newTokens[newTokens.length - 1].isFixed = true;
        }
        newTokens.push({
          ...elem,
          isFixed: false,
        });
        setTokens(newTokens);
      },
      [setInput, setTokens, ourTokens]
    );

    // Removes the last token / selected option in the filter
    const popOption = useCallback(() => {
      setInput("");
      let newTokens = [...ourTokens];
      newTokens.pop();
      if (newTokens.length) {
        newTokens[newTokens.length - 1].isFixed = false;
      }
      setTokens(newTokens);
    }, [setInput, setTokens, ourTokens]);

    const clearFilter = useCallback(() => {
      setTokens([]);
      submitFilter();
      dismissMalformed();
    }, [setTokens, submitFilter, dismissMalformed]);

    // Force set tokens if dynamic tokens has been passed (tokens have been set programmatically in a parent)
    useEffect(() => {
      if (dynamicTokens.length > 0) {
        // Clear dynamically set token so it's not set again every rerender
        setDynamicTokens(); // App completely bricks if we remove this ?
        // Update filter bar
        setTokens(dynamicTokens);
      }
    }, [dynamicTokens, setDynamicTokens, setTokens]);

    return (
      <>
        <div className="filterBarComponent">
          <CreatableSelect
            classNamePrefix="filterBarSelect"
            isMulti
            placeholder="Search"
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                minHeight: "none",
              }),
              multiValueRemove: (base, state) =>
                state.data.isFixed ? { ...base, display: "none" } : base,
            }}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            formatCreateLabel={label => `Search for "${label}"`}
            isValidNewOption={elem => isValidNewOption(elem, ourTokens)}
            inputValue={input}
            value={ourTokens}
            options={getNextOptions(ourTokens, options, lines)}
            getOptionLabel={option => option.label || "\u00a0"}
            noOptionsMessage={() =>
              isLike(ourTokens) ? "Start typing what you want to filter for" : "No options"
            }
            onMenuClose={() => setMenuOpen(false)}
            onMenuOpen={() => {
              dismissMalformed();
              setMenuOpen(true);
            }}
            onKeyDown={e => {
              if (menuOpen) {
                return;
              }
              if (e.key === "Enter") {
                submitFilter(ourTokens);
                e.preventDefault();
              }
            }}
            onInputChange={input => setInput(input)}
            // See https://react-select.com/props
            onChange={(elems, { action, removedValue }) => {
              switch (action) {
                case "remove-value":
                case "pop-value":
                  if (removedValue && !removedValue.isFixed) {
                    popOption();
                  }
                  return;
                case "create-option":
                  {
                    let last = elems.pop();
                    addOption(makeNewOption(last.label, elems.length));
                  }
                  return;
                case "clear":
                  clearFilter();
                  return;
                default:
                  break;
              }
              if (!R.prop("length", elems)) {
                setInput("");
                setTokens([]);
                return;
              }
              let last = elems.pop();
              addOption(last);
            }}
          />
        </div>
        <Button
          type={ButtonType.FILLED}
          icon={<MdSearch />}
          className="filterSubmit"
          onClick={() => {
            submitFilter(ourTokens);
          }}
        />
      </>
    );
  }
);

export const OldBasicFilterBar2 = React.memo(
  ({
    options,
    onFilter = () => {},
    onChange = () => {},
    tokens,
    defaultTokens = [],
    disableAutoFilter,
  }) => {
    // Checks if there's saved tokens for this page in the Session Storage. If there are, sets the default tokens to them
    const savedTokens = window.sessionStorage.getItem(`basic_filter_${window.location.pathname}`);
    const parsedSavedTokens = JSON.parse(savedTokens);

    const [input, setInput] = useState("");
    const [localTokens, setLocalTokens] = useState(parsedSavedTokens || defaultTokens);

    const ourTokens = tokens || localTokens;

    const triggerFilter = useCallback(
      tokens => {
        let fakeTokens = basicToAdvanced(tokens, options);
        let filter = makeFilter(fakeTokens, options);
        onFilter(filter, fakeTokens);
      },
      [options, onFilter]
    );

    // On mount, do it! We only want this to run on mount, so we don't want it to rerun whenever the tokens or anything else changes
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      triggerFilter(ourTokens);
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    const setTokens = useCallback(
      newTokens => {
        setLocalTokens(newTokens);
        onChange(newTokens);
        if (!disableAutoFilter) {
          triggerFilter(newTokens);
        }

        // When the tokens change, they're saved in session storage so that the filter query isn't lost on page reload
        window.sessionStorage.setItem(
          `basic_filter_${window.location.pathname}`,
          JSON.stringify(newTokens)
        );
      },
      [setLocalTokens, onChange, disableAutoFilter, triggerFilter]
    );

    // Turns a selected option ('value') into a filter token
    const addOption = useCallback(
      value => {
        let newTokens = [
          ...ourTokens,
          {
            value,
            label: value,
          },
        ];
        setInput("");
        setTokens(newTokens);
      },
      [ourTokens, setInput, setTokens]
    );

    // Removes the specified selected option / token ('value')
    const removeOption = useCallback(
      value => {
        let i;
        if (!value) {
          i = ourTokens.length - 1;
        } else {
          i = R.findIndex(elem => elem.value === value, ourTokens);
        }
        if (R.isNil(i)) {
          return;
        }
        setInput("");
        setTokens(R.remove(i, 1, ourTokens));
      },
      [ourTokens, setInput, setTokens]
    );

    const clearFilter = useCallback(() => {
      setTokens([]);
      setInput("");
    }, [setTokens, setInput]);

    return (
      <>
        <div className="filterBarComponent">
          <CreatableSelect
            classNamePrefix="filterBarSelect"
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                minHeight: "none",
              }),
            }}
            isMulti
            placeholder="Search"
            components={{
              DropdownIndicator: null,
            }}
            menuIsOpen={false}
            inputValue={input}
            value={ourTokens}
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === "Tab") {
                if (input !== "") {
                  addOption(input);
                } else {
                  triggerFilter(ourTokens);
                }
                e.preventDefault();
              }
            }}
            onInputChange={(input, { action }) => {
              if (action === "input-change") {
                setInput(input);
                if (!disableAutoFilter) {
                  triggerFilter([...ourTokens, { value: input, label: input }]);
                }
              }
            }}
            onChange={(elems, { action, removedValue }) => {
              switch (action) {
                case "remove-value":
                  if (removedValue) {
                    removeOption(removedValue.value);
                  }
                  return;
                case "pop-value":
                  if (ourTokens.length) {
                    removeOption();
                  }
                  return;
                case "clear":
                  clearFilter();
                  return;
                default:
                  break;
              }
              let last = elems.pop();
              addOption(last);
            }}
          />
        </div>
        <Button
          type={ButtonType.FILLED}
          icon={<MdSearch />}
          className="filterSubmit"
          onClick={() => {
            if (input === "") {
              triggerFilter(ourTokens);
            } else {
              addOption(input);
            }
          }}
        />
      </>
    );
  }
);

const useMalformed = () => {
  const [malformedObject, setMalformedObject] = useState("");
  const [showMalformed, setShowMalformed] = useState(false);

  const exposedSetMalformed = useCallback(
    error => {
      if (error) {
        setMalformedObject({ error });
        setShowMalformed(true);
        let stillUndoing = true;
        setTimeout(() => {
          if (stillUndoing) {
            setShowMalformed(false);
          }
        }, 2000);
        return () => {
          stillUndoing = false;
        };
      } else {
        setShowMalformed(false);
      }
    },
    [setShowMalformed, setMalformedObject]
  );
  const dismissMalformed = useCallback(() => exposedSetMalformed(false), [exposedSetMalformed]);
  return {
    malformedObject,
    showMalformed,
    setShowMalformed,
    setMalformed: exposedSetMalformed,
    dismissMalformed,
  };
};

export const OldFilterBar2 = React.memo(
  ({
    options,
    lines,
    onFilter,
    onChange = () => {},
    tokens = {},
    defaultTokens = {},
    defaultAdvanced = false,
    disableAutoFilter = false,
    dynamicTokens = [],
    setDynamicTokens,
    advanced,
    onSetAdvanced = () => {},
  }) => {
    const barRef = useRef();
    const transformedDefaultAdvancedTokens = useMemo(() => {
      if (defaultTokens.advanced) {
        return parseRawTokenArray(defaultTokens.advanced, options, lines);
      }
    }, [defaultTokens.advanced, options, lines]);

    const transformedDynamicAdvancedTokens = useMemo(() => {
      if (dynamicTokens) {
        return parseRawTokenArray(dynamicTokens, options, lines);
      }
    }, [dynamicTokens, options, lines]);

    const [localAdvancedMode, setLocalAdvancedMode] = useState(
      R.isNil(window.sessionStorage.getItem(`filter_state_${window.location.pathname}`))
        ? defaultAdvanced
        : JSON.parse(window.sessionStorage.getItem(`filter_state_${window.location.pathname}`))
    );

    const {
      malformedObject,
      showMalformed,
      setShowMalformed,
      setMalformed,
      dismissMalformed,
    } = useMalformed();

    const advancedMode = R.isNil(advanced) ? localAdvancedMode : advanced;
    const setAdvancedMode = useCallback(
      setting => {
        onSetAdvanced(setting);
        setLocalAdvancedMode(setting);
        window.sessionStorage.setItem(
          `filter_state_${window.location.pathname}`,
          JSON.stringify(setting)
        );
      },
      [setLocalAdvancedMode, onSetAdvanced]
    );

    const onBasicChange = useCallback(
      basic => {
        onChange({
          ...tokens,
          basic,
        });
      },
      [onChange, tokens]
    );

    const onAdvancedChange = useCallback(
      advanced => {
        onChange({
          ...tokens,
          advanced,
        });
      },
      [onChange, tokens]
    );

    return (
      <div className="BPMFilterBar2" ref={barRef}>
        <TextToggleButton
          className="basicAdvancedToggle"
          options={["Advanced", "Basic"]}
          selectedOption={advancedMode ? "Advanced" : "Basic"}
          onChange={key => setAdvancedMode(key === "Advanced")}
        />
        {advancedMode ? (
          <OldAdvancedFilterBar2
            disableAutoFilter={disableAutoFilter}
            lines={lines}
            options={options}
            onFilter={onFilter}
            onChange={onAdvancedChange}
            tokens={tokens.advanced}
            defaultTokens={transformedDefaultAdvancedTokens}
            dynamicTokens={transformedDynamicAdvancedTokens}
            setDynamicTokens={setDynamicTokens}
            setMalformed={setMalformed}
            dismissMalformed={dismissMalformed}
          />
        ) : (
          <OldBasicFilterBar2
            disableAutoFilter={disableAutoFilter}
            options={options}
            onFilter={onFilter}
            onChange={onBasicChange}
            tokens={tokens.basic}
            defaultTokens={defaultTokens.basic}
          />
        )}
        <Overlay target={barRef.current} show={showMalformed} placement="bottom-start">
          {props => (
            <Tooltip
              {...props}
              style={{
                ...props.style,
                cursor: "pointer",
              }}
              // This is a dumb bootstrap bug/idiosyncrasy
              show={props.show.toString()}
              onClick={() => {
                setShowMalformed(false);
              }}
            >
              {malformedObject.error}
            </Tooltip>
          )}
        </Overlay>
      </div>
    );
  }
);
