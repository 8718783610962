import React from "react";
import { MdZoomOutMap } from "react-icons/md";
import { Setter } from "../../utils/types";
import "./ExpandModalButton.scss";


interface ExpandModalButtonProps {
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  size?: "sm" | "lg";
  className?: string;
  setShowModal: Setter<boolean | ((s: boolean) => boolean)>;
}

const ExpandModalButton: React.FC<ExpandModalButtonProps> = ({
  className,
  design = "primary",
  background = "light",
  size = "lg",
  setShowModal,
}) => {
  const resolvedClassName = ["effectiveModalButton", design, background, size, className].join(" ");

  return (
    <div className={resolvedClassName} onClick={() => setShowModal((s) => !s) }>
      <div className="expand-icon">
        <MdZoomOutMap />
      </div>
    </div>
  );
};

export default ExpandModalButton;
