import "./GeoDataCard.scss";
import React from "react";
import { abbreviateNumberAMC } from "../../AMCFormatters";
import DataCard from "../../../Components/DataCard/DataCard";

interface GeoCardData {
  __typename: string;
  state: string;
  impressions: number;
  reach: number;
  dpv: number;
  customersConverted: number;
  orders: number;
  revenue: number;
  ntbCustomers: number;
  dpvRate: number;
  conversionRate: number;
  ntbRepeatRate: number;
  ntbPurchaseRate: number;
}

interface GeoDataCardProps {
  geoCardData: GeoCardData;
  title: string;
}

const GeoDataCards: React.FC<GeoDataCardProps> = ({ geoCardData, title }) => {
  const buildCards = (data, item) => {
    const formattedItem = item
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/ntb/i, "NTB")
      .replace(/cpa/i, "DPV");

    let formattedValue;

    switch (item) {
      case "dpvRate":
      case "conversionRate":
      case "ntbRepeatRate":
        formattedValue = `${data[item]}%`;
        break;
      default:
        formattedValue = `${abbreviateNumberAMC(data[item])}`;
    }

    return item !== "__typename" ? (
      <DataCard key={formattedItem} label={formattedItem} value={formattedValue} width={"105px"} />
    ) : null;
  };
  return (
    <div className="geo-data-card-container">
      <div className="geo-state-selection-header">{title}</div>
      <div className="geo-data-card-wrapper">
        {geoCardData &&
          Object.keys(geoCardData).map(item => {
            const createCard = () => {
              if (item !== "__typename" && item !== "state" && item !== "ntbPurchaseRate") {
                return buildCards(geoCardData, item);
              }
            };
            return createCard();
          })}
      </div>
    </div>
  );
};

export default GeoDataCards;
