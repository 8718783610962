import "./AMCGraphs.scss";
import React from "react";
import {
  Bar,
  BarChart as BarGraph,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { abbreviateNumberAMC } from "./AMCFormatters";

interface BarChartProps {
  data: {}[];
  dataKeys: string[];
  dataLabel: any;
  fill: any;
  percentKeys: string[];
}

// This is a stacked bar chart shown in a %. percentKey strings should equal dataKey string plus the word Percent
// This naming convention allows the graph to display data form of percent and the label to show the actual data number
const BarChart: React.FC<BarChartProps> = ({ data, dataKeys, fill, dataLabel, percentKeys }) => {
  let tooltip: string;
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      for (const item of payload) {
        if (item.dataKey === `${tooltip}Percent`) {
          return (
            <div className="custom-tooltip">
              <div className="label" style={{ backgroundColor: fill[tooltip] }}>
                {dataLabel[item.dataKey]}
              </div>
              <div className="description">{`${item.payload.month}: ${abbreviateNumberAMC(
                item.payload[tooltip]
              )}`}</div>
            </div>
          );
        }
      }
    }

    return null;
  };

  return (
    <ResponsiveContainer height={400} width="100%">
      <BarGraph
        className="amc-stacked-bar-chart"
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="month" tickMargin={5} />
        <YAxis
          domain={[0, 100]}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          type="number"
          unit="%"
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
        <Legend formatter={item => dataLabel[item]} iconSize={20} />
        {data &&
          percentKeys.map((key, index) => {
            return (
              <Bar
                className="graph-bar"
                dataKey={key}
                fill={fill[dataKeys[index]]}
                key={index}
                // set tooltip var to bar's key; This lets the tooltip show on hover
                // of a single bar instead of both bar data
                onMouseOver={() => (tooltip = dataKeys[index])}
                stackId="a"
              />
            );
          })}
      </BarGraph>
    </ResponsiveContainer>
  );
};

export default BarChart;
