import React from "react";

import { StateSetter } from "../../utils/types";
import { StagedTagData } from "./utils";
import StagedTag from "./StagedTag";
import { Spinner } from "../../Components";

import "./TagStagingArea.scss";

interface TagStagingAreaProps {
  stagedTags: StagedTagData[];
  setStagedTags: StateSetter<StagedTagData[]>;
  isSending: boolean;
  openTagCreationModal: any;
}

export const TagStagingArea = ({
  stagedTags,
  setStagedTags,
  isSending,
  openTagCreationModal,
}: TagStagingAreaProps): JSX.Element => {
  return (
    <div className="tagStagingArea">
      {isSending ? (
        <Spinner />
      ) : (
        <div className="tagList">
          {stagedTags.map((tag, i) => (
            <StagedTag
              key={i}
              tag={tag}
              tagIndex={i}
              openTagCreationModal={openTagCreationModal}
              setStagedTags={setStagedTags}
            />
          ))}
        </div>
      )}
    </div>
  );
};
