import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./MasterTargeting.scss";
import {
  Button,
  ButtonType,
  FullPageSpinner,
  NumberFormatter,
  Page,
  SimpleTooltip,
  SimpleTooltipDirection,
  Spinner,
} from "../Components";
import { useSetError } from "../redux/modals";
import { useSelector } from "react-redux";
import { emailSelector } from "../redux/user";
import { MdClear, MdClose, MdDone, MdSave } from "react-icons/md";
import { MTModalEditTable } from "./MTModalEditTable";
import * as R from "ramda";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { MasterTargetingRow } from "@blisspointmedia/bpm-types/dist/MasterTargeting";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

const textCellRenderer = field => data => {
  const value = data[field];
  if (value && value !== "") {
    return (
      <div className="modalEditTableCell" key={field}>
        {value}
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={field}>
        -
      </div>
    );
  }
};

const costRenderer = field => data => {
  const value = data[field];
  const parsedValue = Number.parseFloat(value);
  if (!value || value === "") {
    return (
      <div className="modalEditTableCell" key={"cost"}>
        -
      </div>
    );
  } else if (!isNaN(parsedValue)) {
    return (
      <div className="modalEditTableCell" key={"cost"}>
        <NumberFormatter value={value} type={"$"} />
      </div>
    );
  } else {
    return (
      <div className="modalEditTableCell invalidCell" key={"cost"}>
        <NumberFormatter value={value} type={"$"} />
      </div>
    );
  }
};

const targetingCheckBoxRenderer = field => data => {
  const value = data[field];
  return (
    <div className="modalEditTableCell">
      <div className={`targetingCheckBox ${value}`}>
        {(value === "green-check" || value === "yellow-check") && <MdDone />}
        {value === "red-x" && <MdClose />}
      </div>
    </div>
  );
};

const DEMO_TARGETING = new Set<string>(["m_f", "median_age", "hhi"]);
const DEVICE_TARGETING = new Set<string>([
  "desktop",
  "ctv",
  "roku",
  "apple_tv",
  "fire_tv",
  "mobile",
  "iphone",
  "android",
  "tablet",
]);
const GEO_TARGETING = new Set<string>(["dma", "zip_code"]);
const ADVANCED_TARGETING = new Set<string>([
  "advertiser_first_party_data",
  "publisher_behavioral_targeting",
  "publisher_contextual_targeting",
  "retargeting",
  "lal_modeling",
]);
const MEDIA_TYPE = new Set<string>(["ott", "olv", "audio", "display"]);
const DSPs = new Set<string>([
  "beeswax",
  "nexxen",
  "roku_oneview",
  "amazon",
  "the_trade_desk",
  "xandr",
  "yahoo",
  "youtube",
]);

const MasterTargeting = (): JSX.Element => {
  const setError = useSetError();
  const email = useSelector(emailSelector);
  const [originalMasterTargetingData, setOriginalMasterTargetingData] = useState<
    MasterTargetingRow[]
  >();
  const [masterTargetingData, setMasterTargetingData] = useState<MasterTargetingRow[]>();
  const [saving, setSaving] = useState(false);

  const [showDemoTargeting, setShowDemoTargeting] = useState<boolean>(false);
  const [showDeviceTargeting, setShowDeviceTargeting] = useState<boolean>(false);
  const [showGeoTargeting, setShowGeoTargeting] = useState<boolean>(false);
  const [showAdvancedTargeting, setShowAdvancedTargeting] = useState<boolean>(false);
  const [showMediaType, setShowMediaType] = useState<boolean>(false);
  const [showDSPs, setShowDSPs] = useState<boolean>(false);

  const allMTHeaders = useMemo(() => {
    return [
      {
        label: "Platform",
        field: "platform",
        type: "text",
        modalRow: 0,
        modalWidth: 150,
        width: 150,
        isClearable: false,
        renderer: textCellRenderer("platform"),
      },
      {
        label: "Short Code",
        field: "short_code",
        type: "text",
        flex: 1,
        modalRow: 0,
        modalWidth: 100,
        width: 100,
        isClearable: false,
        renderer: textCellRenderer("short_code"),
      },
      {
        label: "Network Name",
        field: "network_name",
        type: "text",
        flex: 1,
        modalRow: 0,
        modalWidth: 250,
        width: 250,
        isClearable: false,
        renderer: textCellRenderer("network_name"),
      },
      {
        label: "Description/Property",
        field: "description_or_property",
        type: "text",
        modalRow: 1,
        modalWidth: 300,
        width: 300,
        isClearable: false,
        renderer: textCellRenderer("description_or_property"),
      },
      {
        label: "Description/Property Short Code",
        field: "description_or_property_short_code",
        type: "text",
        modalRow: 1,
        modalWidth: 300,
        width: 300,
        isClearable: false,
        renderer: textCellRenderer("description_or_property_short_code"),
      },
      {
        label: "Property Summary",
        field: "property_summary",
        type: "text",
        modalRow: 2,
        modalWidth: 724,
        isClearable: false,
        renderer: textCellRenderer("property_summary"),
      },
      {
        label: "January",
        field: "january",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("january"),
      },
      {
        label: "February",
        field: "february",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("february"),
      },
      {
        label: "March",
        field: "march",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("march"),
      },
      {
        label: "April",
        field: "april",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("april"),
      },
      {
        label: "May",
        field: "may",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("may"),
      },
      {
        label: "June",
        field: "june",
        type: "currency",
        modalRow: 3,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("june"),
      },
      {
        label: "July",
        field: "july",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("july"),
      },
      {
        label: "August",
        field: "august",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("august"),
      },
      {
        label: "September",
        field: "september",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("september"),
      },
      {
        label: "October",
        field: "october",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("october"),
      },
      {
        label: "November",
        field: "november",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("november"),
      },
      {
        label: "December",
        field: "december",
        type: "currency",
        modalRow: 4,
        modalWidth: 100,
        width: 100,
        renderer: costRenderer("december"),
      },
      {
        label: "Non-PE CPM",
        field: "non_pe_cpm",
        type: "text",
        modalRow: 5,
        modalWidth: 100,
        width: 100,
        renderer: textCellRenderer("non_pe_cpm"),
      },
      {
        label: "Priority CPM",
        field: "priority_cpm",
        type: "text",
        modalRow: 5,
        modalWidth: 100,
        width: 100,
        renderer: textCellRenderer("priority_cpm"),
      },
      {
        label: "M/F",
        field: "m_f",
        type: "text",
        modalRow: 6,
        modalWidth: 100,
        width: 100,
        renderer: textCellRenderer("m_f"),
      },
      {
        label: "Median Age",
        field: "median_age",
        type: "text",
        modalRow: 6,
        modalWidth: 100,
        width: 100,
        renderer: textCellRenderer("median_age"),
      },
      {
        label: "HHI",
        field: "hhi",
        type: "text",
        modalRow: 6,
        modalWidth: 100,
        width: 100,
        renderer: textCellRenderer("hhi"),
      },
      {
        label: "Desktop",
        field: "desktop",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("desktop"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "CTV",
        field: "ctv",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("ctv"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Roku",
        field: "roku",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("roku"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Apple TV",
        field: "apple_tv",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("apple_tv"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Fire TV",
        field: "fire_tv",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("fire_tv"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Mobile",
        field: "mobile",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("mobile"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "iPhone",
        field: "iphone",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("iphone"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Android",
        field: "android",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("android"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Tablet",
        field: "tablet",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("tablet"),

        modalRow: 7,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Dma",
        field: "dma",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("dma"),

        modalRow: 8,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Zip Code",
        field: "zip_code",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("zip_code"),

        modalRow: 8,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Advertiser 1st Party Data",
        field: "advertiser_first_party_data",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("advertiser_first_party_data"),

        modalRow: 9,
        modalWidth: 233,
        width: 233,
      },
      {
        label: "Publisher Behavioral Targeting",
        field: "publisher_behavioral_targeting",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("publisher_behavioral_targeting"),

        modalRow: 9,
        modalWidth: 233,
        width: 233,
      },
      {
        label: "Publisher Contextual Targeting",
        field: "publisher_contextual_targeting",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("publisher_contextual_targeting"),

        modalRow: 9,
        modalWidth: 233,
        width: 233,
      },
      {
        label: "Retargeting",
        field: "retargeting",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("retargeting"),

        modalRow: 10,
        modalWidth: 233,
        width: 233,
      },
      {
        label: "LAL Modeling",
        field: "lal_modeling",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("lal_modeling"),

        modalRow: 10,
        modalWidth: 233,
        width: 233,
      },
      {
        label: "OTT",
        field: "ott",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("ott"),

        modalRow: 11,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "OLV",
        field: "olv",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("olv"),

        modalRow: 11,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Audio",
        field: "audio",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("audio"),

        modalRow: 11,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Display",
        field: "display",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("display"),

        modalRow: 11,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Beeswax",
        field: "beeswax",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("beeswax"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Nexxen",
        field: "nexxen",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("nexxen"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Roku OneView",
        field: "roku_oneview",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("roku_oneview"),

        modalRow: 12,
        modalWidth: 112,
        width: 112,
      },
      {
        label: "Amazon",
        field: "amazon",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("amazon"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "The Trade Desk",
        field: "the_trade_desk",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("the_trade_desk"),

        modalRow: 12,
        modalWidth: 112,
        width: 112,
      },
      {
        label: "Xandr",
        field: "xandr",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("xandr"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Yahoo",
        field: "yahoo",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("yahoo"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
      {
        label: "Youtube",
        field: "youtube",
        type: "targetingCheckBox",
        renderer: targetingCheckBoxRenderer("youtube"),

        modalRow: 12,
        modalWidth: 71,
        width: 71,
      },
    ];
  }, []);

  const viewableMTHeaders = useMemo(() => {
    return allMTHeaders.filter(element => {
      if (
        element.field === "description_or_property_short_code" ||
        element.field === "property_summary"
      ) {
        return false;
      } else if (!showDemoTargeting && DEMO_TARGETING.has(element.field)) {
        return false;
      } else if (!showDeviceTargeting && DEVICE_TARGETING.has(element.field)) {
        return false;
      } else if (!showGeoTargeting && GEO_TARGETING.has(element.field)) {
        return false;
      } else if (!showAdvancedTargeting && ADVANCED_TARGETING.has(element.field)) {
        return false;
      } else if (!showMediaType && MEDIA_TYPE.has(element.field)) {
        return false;
      } else if (!showDSPs && DSPs.has(element.field)) {
        return false;
      } else {
        return true;
      }
    });
  }, [
    allMTHeaders,
    showAdvancedTargeting,
    showDSPs,
    showDemoTargeting,
    showDeviceTargeting,
    showGeoTargeting,
    showMediaType,
  ]);

  useEffect(() => {
    if (!masterTargetingData) {
      (async () => {
        let masterTargetingData;

        try {
          masterTargetingData = await StreamingV2LambdaFetch("/getMasterTargeting");
          masterTargetingData = await awaitJSON(masterTargetingData);
        } catch (e) {
          setError({ message: `Failed to get master targeting data ${e.message}`, reportError: e });
        }

        setMasterTargetingData(masterTargetingData);
        setOriginalMasterTargetingData(masterTargetingData);
      })();
    }
  }, [setError, masterTargetingData]);

  const checkIfUpdatedRow = useCallback(
    row => {
      let originalRow = originalMasterTargetingData?.find(originalRow => originalRow.id === row.id);
      if (originalRow) {
        let keys = Object.keys(row);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (key !== "index" && row[key] !== originalRow[key]) {
            return true;
          }
        }
      }
      return false;
    },
    [originalMasterTargetingData]
  );

  const modifiedRows = useMemo(() => {
    if (masterTargetingData && originalMasterTargetingData) {
      const added = R.filter(row => !row.id || row.id < 0, masterTargetingData);

      const currentById = R.fromPairs(R.map(row => [row.id, row], masterTargetingData));
      const removed = R.filter(
        row => row.id >= 0 && !currentById[row.id],
        originalMasterTargetingData
      );

      const updated =
        originalMasterTargetingData.length > 0
          ? R.filter(row => row.id >= 0 && checkIfUpdatedRow(row), masterTargetingData)
          : [];

      for (let i = 0; i < added.length; i++) {
        added[i].id = -(i + 1);
      }

      return {
        added: {
          ...R.fromPairs(R.map(row => [row.id, row], added)),
        },
        removed: {
          ...R.fromPairs(R.map(row => [row.id, row], removed)),
        },
        updated: {
          ...R.fromPairs(R.map(row => [row.id, row], updated)),
        },
      };
    } else {
      return { added: {}, removed: {}, updated: {} };
    }
  }, [checkIfUpdatedRow, masterTargetingData, originalMasterTargetingData]);

  const hasModifications = useMemo(() => {
    return (
      R.keys(modifiedRows.added).length > 0 ||
      R.keys(modifiedRows.removed).length > 0 ||
      R.keys(modifiedRows.updated).length > 0
    );
  }, [modifiedRows]);

  const hasMissingFields = useMemo(() => {
    const { added, updated } = modifiedRows;
    for (let row of Object.values(added)) {
      if (
        !row.platform ||
        !row.short_code ||
        !row.network_name ||
        !row.january ||
        !row.february ||
        !row.march ||
        !row.april ||
        !row.may ||
        !row.june ||
        !row.july ||
        !row.august ||
        !row.september ||
        !row.october ||
        !row.november ||
        !row.december
      ) {
        return true;
      }
    }
    for (let row of Object.values(updated)) {
      if (
        !row.platform ||
        !row.short_code ||
        !row.network_name ||
        !row.january ||
        !row.february ||
        !row.march ||
        !row.april ||
        !row.may ||
        !row.june ||
        !row.july ||
        !row.august ||
        !row.september ||
        !row.october ||
        !row.november ||
        !row.december
      ) {
        return true;
      }
    }

    return false;
  }, [modifiedRows]);

  const onSubmit = useCallback(async () => {
    setSaving(true);

    if (hasMissingFields) {
      setError({
        title: "Missing Fields",
        message:
          "Please make sure platform, short code, network name, and cpms for each month are set for new/updates rows.",
      });
      setSaving(false);
      return;
    }

    try {
      await StreamingV2LambdaFetch("/setMasterTargeting", {
        method: "POST",
        body: { changes: modifiedRows, email },
      });
    } catch (e) {
      setError({ message: `Failed to set master targeting data ${e.message}`, reportError: e });
    }

    let targetingData;
    try {
      targetingData = await StreamingV2LambdaFetch("/getMasterTargeting");
      targetingData = await awaitJSON(targetingData);
      setMasterTargetingData(targetingData);
      setOriginalMasterTargetingData(targetingData);
    } catch (e) {
      setError({ message: `Failed to get master targeting data ${e.message}`, reportError: e });
    }

    setSaving(false);
  }, [email, hasMissingFields, modifiedRows, setError]);

  // TODO: add validation and add checks for invalid rows
  const masterTargetingTable: JSX.Element = useMemo(() => {
    return masterTargetingData ? (
      <MTModalEditTable
        className="masterTargetingTable"
        allHeaders={allMTHeaders}
        viewableHeaders={viewableMTHeaders}
        tableData={masterTargetingData}
        setTableData={newRatesData => {
          setMasterTargetingData(newRatesData);
        }}
        showDemoTargeting={showDemoTargeting}
        setShowDemoTargeting={setShowDemoTargeting}
        showDeviceTargeting={showDeviceTargeting}
        setShowDeviceTargeting={setShowDeviceTargeting}
        showGeoTargeting={showGeoTargeting}
        setShowGeoTargeting={setShowGeoTargeting}
        showAdvancedTargeting={showAdvancedTargeting}
        setShowAdvancedTargeting={setShowAdvancedTargeting}
        showMediaType={showMediaType}
        setShowMediaType={setShowMediaType}
        showDSPs={showDSPs}
        setShowDSPs={setShowDSPs}
      />
    ) : (
      <FullPageSpinner />
    );
  }, [
    allMTHeaders,
    masterTargetingData,
    showAdvancedTargeting,
    showDSPs,
    showDemoTargeting,
    showDeviceTargeting,
    showGeoTargeting,
    showMediaType,
    viewableMTHeaders,
  ]);

  const [showSaveButtonTooltip, setShowSaveButtonTooltip] = useState(false);
  const [showClearButtonTooltip, setShowClearButtonTooltip] = useState(false);

  return (
    <Page
      title="Master Targeting"
      pageType="Master Targeting"
      actions={
        <div className="masterTargetingActions">
          <SimpleTooltip
            text="Save Changes"
            showTooltip={showSaveButtonTooltip}
            direction={SimpleTooltipDirection.BOTTOM}
            horizontalShift="-30px"
            verticalShift="-40px"
            onMouseEnter={() => setShowSaveButtonTooltip(true)}
            onMouseLeave={() => setShowSaveButtonTooltip(false)}
          >
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              onClick={() => onSubmit()}
              disabled={!hasModifications || saving}
              icon={saving ? <Spinner /> : <MdSave />}
            />
          </SimpleTooltip>
          <SimpleTooltip
            text="Clear Changes"
            showTooltip={showClearButtonTooltip}
            direction={SimpleTooltipDirection.BOTTOM}
            horizontalShift="-32px"
            verticalShift="-40px"
            onMouseEnter={() => setShowClearButtonTooltip(true)}
            onMouseLeave={() => setShowClearButtonTooltip(false)}
          >
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              onClick={() => setMasterTargetingData(originalMasterTargetingData)}
              disabled={!hasModifications || saving}
              icon={<MdClear />}
            />
          </SimpleTooltip>
          <div className="mtTargetingKey">
            <div className="mtKeySection">
              <div className={"mtTargetingCheckBox mtGreenCheck"}>
                <MdDone />
              </div>
              <div className="mtKeyText">Can target AND exclude</div>
            </div>
            <div className="mtKeySection">
              <div className={"mtTargetingCheckBox mtYellowCheck"}>
                <MdDone />
              </div>
              <div className="mtKeyText">Can target OR exclude, not both</div>
            </div>
            <div className="mtKeySection">
              <div className={"mtTargetingCheckBox mtRedX"}>
                <MdClose />
              </div>
              <div className="mtKeyText">Cannot target or exclude</div>
            </div>
          </div>
        </div>
      }
    >
      <div className="bpmMasterTargeting">{masterTargetingTable}</div>
    </Page>
  );
};

export default MasterTargeting;
