import React from "react";

import { RectSkeleton, Skeleton, ListSkeleton } from "../Components";
import { ROW_HEIGHT } from "./performanceUtils";
import { DIMENSION_COLUMN_METADATA_MAP as STREAMING_DIMENSION_COLUMN_METADATA_MAP } from "./StreamingPerformance/streamingPerformanceUtils";

const SKELETON_LINE_PADDING = 5;
const SKELETON_DIMENSION_WIDTH =
  STREAMING_DIMENSION_COLUMN_METADATA_MAP["Network Logo"].minWidth +
  STREAMING_DIMENSION_COLUMN_METADATA_MAP.Description.minWidth;

const PerformanceGridSkeleton: React.FC = () => (
  <div className="gridBox">
    <Skeleton redesign>
      <RectSkeleton height={ROW_HEIGHT} />
      <ListSkeleton
        lineHeight={ROW_HEIGHT - SKELETON_LINE_PADDING * 2}
        lineSpacing={SKELETON_LINE_PADDING}
        y={ROW_HEIGHT}
        width={SKELETON_DIMENSION_WIDTH}
        height={height => height - ROW_HEIGHT * 2 - SKELETON_LINE_PADDING * 2}
        borderRadius={0}
      />
      <ListSkeleton
        lineHeight={ROW_HEIGHT - SKELETON_LINE_PADDING * 2}
        lineSpacing={SKELETON_LINE_PADDING}
        y={ROW_HEIGHT}
        x={SKELETON_DIMENSION_WIDTH + SKELETON_LINE_PADDING}
        width={width => width - SKELETON_DIMENSION_WIDTH - SKELETON_LINE_PADDING * 2}
        height={height => height - ROW_HEIGHT * 2 - SKELETON_LINE_PADDING * 2}
        borderRadius={0}
      />
      <RectSkeleton height={ROW_HEIGHT} y={height => height - ROW_HEIGHT} />
    </Skeleton>
  </div>
);

export default PerformanceGridSkeleton;
