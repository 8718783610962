import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import {
  INC_CDN,
  IncPlaybookSectionBox,
  PAGE_PREFIX,
  navigateToSection,
} from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";

import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Streaming";

const Streaming = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Streaming TV and audio delivers on-demand television shows, movies, and music content
              over the internet. The shift towards streaming services reflects changing consumer
              behavior, with more people opting for on-demand entertainment options.
            </div>
            <br />
            <div className="basicText">
              By leveraging this growing trend, marketers can tap into a highly engaged audience and
              deliver their messages in a contextually relevant and impactful way.
            </div>
            <br />
            <div className="basicText">
              When purchasing streaming media from platforms and networks, we receive user-level
              data, which provides us with significantly more detailed insights compared to other
              channels. This abundance of granular data opens up a wide range of possibilities for
              measurement, analysis, and customized solutions for our clients.
            </div>
            <br />
            <div className="basicText">
              It's important to note that all the mentioned incrementality methods are conducted
              out-of-platform and are executed and analyzed within Tinuiti. This allows us to
              leverage our comprehensive access to data. These methods may require more internal
              resources to implement, however, they provide us with greater control and visibility
              into the final results.
            </div>
            <br />
            <Img src={`${INC_CDN}/Streaming_Tests.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              ["Incrementality Testing Approaches", "Setup and Test Design", "Test Outputs"]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "PSA Testing",
                  Description: (
                    <ul className="basicList">
                      <li>
                        A user based experiment in which the exposed group receives client ads and
                        the control group that receives control ads or a public service
                        announcement.
                      </li>
                      <li>
                        Ghost bidding is currently available through Nexxen one of our DSP partners
                        that we buy OLV media from. This is essentially a free way to do a PSA test
                        as instead of serving an actual ad, the users are just logged instead when
                        they would have been served a PSA/ad.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Cheap and quick compared to other incrementality testing options, it’s
                        low-cost, fast to implement, and has a short run time.
                      </li>
                      <li>
                        Clean measurement of incremental lift as there is a clean, randomized
                        comparison of exposed vs control groups isolated to a single network without
                        geographic effects.
                      </li>
                      <li>
                        User-level data allow for analytical flexibility different cuts of
                        performance outputs (e.g., comparing lag windows or traffic types, or
                        scrubbing specific viewers for prior exposures).
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Cross-contamination possible since randomization is done at the network
                        level and networks don’t coordinate, testing multiple networks at once will
                        lead to cross-contamination. So, only one or a few networks can be tested at
                        a time.
                      </li>
                      <li>
                        Less external validity since we’re limited to testing fewer networks, the
                        results may not perfectly generalize to the entire campaign.
                      </li>
                      <li>
                        Only certain networks have the capability to segregate treatment and control
                        groups without leakage (such as Hulu or IMG). Some networks charge a small
                        CPM premium for this service
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Geo Testing",
                  Description: (
                    <ul className="basicList">
                      <li>
                        A geo-based experiment in which we serve ads in a set of DMAs (test group)
                        across a portfolio of publishers and hold out ads in all other geographies
                        (control group).
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Minimal cross-contamination since treatment and control users are separated
                        by geography. So, we can test across multiple networks or the full portfolio
                        (if the networks allow geo targeting)
                      </li>
                      <li>
                        Measurement of “fully-loaded” impact by testing the full slate of a
                        campaign’s networks and its full audience without need for user scrubbing.
                      </li>
                      <li>No wasted money on PSAs or proxy PSAs.</li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        Longer and more expensive because the number of DMAs constrains the sample
                        size. Geo-targeting premiums also increase cost.
                      </li>
                      <li>
                        Higher opportunity costs because of a longer test and having to go dark in
                        all the control DMAs.
                      </li>
                      <li>
                        While we can choose similar geographies to assign to the exposed and holdout
                        groups, there may still be some unaccounted trends or differences.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Geo PSA Testing",
                  Description: (
                    <ul className="basicList">
                      <li>
                        An experiment that is a hybrid between a Geo and PSA test. (control group).
                      </li>
                      <li>
                        Similar to a geo test, we select a set of markets to serve as the control
                        group and the other markets as the test group.
                      </li>
                      <li>
                        We serve real ads in treatment DMA’s as usual but also will serve PSA ads in
                        control DMA’s.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        Minimal cross-contamination because treatment and PSA ads are served in
                        different DMAs.
                      </li>
                      <li>
                        Lower cost and less time to measure the full streaming campaign. Able to
                        measure incrementality on more streaming networks but without the higher
                        cost and time needed to reach statistical significance for a traditional geo
                        test.
                      </li>
                      <li>
                        Allows for user-level data and standard matched responses (real ads and
                        PSA’s) can be passed back to the client.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        As per our standard attribution, we use matching to determine who has
                        responded to advertising. This provides us with an estimate for
                        incrementality among residential users, but may not perfectly generalize to
                        non-residential users.
                      </li>
                      <li>
                        Geo targeting premiums plus higher opportunity costs due to needing to limit
                        marketing activity in control DMAs.
                      </li>
                      <li>
                        While we can choose similar geographies to assign to the exposed and holdout
                        groups, there may still be some unaccounted trends or differences.
                      </li>
                    </ul>
                  ),
                },
                {
                  Method: "Always On Incrementality",
                  Description: (
                    <ul className="basicList">
                      <li>
                        Proxies PSA test using behaviorally matched segments from other Tinuiti
                        advertisers, is a quasi-experiment not a true experiment.
                      </li>
                      <li>
                        The control group consists of users who were exposed to other Tinuiti
                        client’s ads on the same network and granularity.
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>
                        AOI is available to every client running on streaming free-of-charge ,
                        making it a preferred initial look at incrementality
                      </li>
                      <li>
                        Unlike traditional tests which require network cooperation, AOI relies on
                        already-run impressions from other advertisers, meaning its available across
                        the streaming portfolio.
                      </li>
                      <li>
                        AOI figures are updated daily at the Network – Device – KPI – Lag – Day
                        level, allowing advertisers to see how the incrementality of their campaign
                        evolves over time across many cross-sections.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        To create an accurate control group, we use ads run on the same network
                        segment, including any demographic targeting but there may be limitations on
                        behavioral matching . For networks with particularly unique targeting,
                        creating a control group may not be feasible, in which case we roll up to
                        look at the full network portfolio.
                      </li>
                      <li>
                        May have limitations on statistical significance , for example, small
                        networks will have lower spend and impressions, this can mean that results
                        are more subject to fluctuation and may not be statistically significant
                        unless looked at over a longer period.
                      </li>
                      <li>
                        Since AOI leverages other advertisers’ impressions, control group allocation
                        is not randomized , meaning there may still be some unaccounted for
                        differences between exposed and control groups.
                      </li>
                    </ul>
                  ),
                },
              ],
              R.filter(header => header.name !== "Method Type", methodHeaders),
              500
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  "PSA Testing": (
                    <ul className="basicList">
                      <li>2-3 week setup</li>
                      <li>2-4 week test length</li>
                    </ul>
                  ),
                  "Geo Testing": (
                    <ul className="basicList">
                      <li>4 week setup</li>
                      <li>8-10 week test length</li>
                    </ul>
                  ),
                  "Geo-PSA Testing": (
                    <ul className="basicList">
                      <li>3-4 week setup</li>
                      <li>2-4 week test length</li>
                    </ul>
                  ),
                  "Always On Incrementality": (
                    <ul className="basicList">
                      <li>Campaign launchable immediately</li>
                      <li>Results begin populating after 2-3 weeks</li>
                    </ul>
                  ),
                },
                {
                  Element: "Cost",
                  "PSA Testing": (
                    <ul className="basicList">
                      <li>10-60k+</li>
                    </ul>
                  ),
                  "Geo Testing": (
                    <ul className="basicList">
                      <li>400k-1M</li>
                    </ul>
                  ),
                  "Geo-PSA Testing": (
                    <ul className="basicList">
                      <li>15-80k+</li>
                    </ul>
                  ),
                  "Always On Incrementality": (
                    <ul className="basicList">
                      <li>No cost</li>
                    </ul>
                  ),
                },
                {
                  Element: "Randomization",
                  "PSA Testing": (
                    <ul className="basicList">
                      <li>User-level</li>
                    </ul>
                  ),
                  "Geo Testing": (
                    <ul className="basicList">
                      <li>DMA-level</li>
                    </ul>
                  ),
                  "Geo-PSA Testing": (
                    <ul className="basicList">
                      <li>DMA-level</li>
                    </ul>
                  ),
                  "Always On Incrementality": (
                    <ul className="basicList">
                      <li>Behaviorally-matched users from other clients</li>
                    </ul>
                  ),
                },
                {
                  Element: "Sample Size Recommendation",
                  "PSA Testing": (
                    <ul className="basicList">
                      <li>Sample Size Calculator filled out by the client team.</li>
                    </ul>
                  ),
                  "Geo Testing": (
                    <ul className="basicList">
                      <li>
                        Full spec out including the DMA randomization and sample size calculations
                        done by Econ/Marketing Science.
                      </li>
                    </ul>
                  ),
                  "Geo-PSA Testing": (
                    <ul className="basicList">
                      <li>DMA Randomization done by Econ/Marketing Science.</li>
                    </ul>
                  ),
                  "Always On Incrementality": (
                    <ul className="basicList">
                      <li>Sample Size Calculator filled out by the client team.</li>
                    </ul>
                  ),
                },
                {
                  Element: "Analysis Methodology",
                  "PSA Testing": (
                    <ul className="basicList">
                      <li>
                        User-level analysis comparing response rates between test and control
                        groups.
                      </li>
                    </ul>
                  ),
                  "Geo Testing": (
                    <ul className="basicList">
                      <li>
                        DMA-level analysis comparing pre-test KPI volume to post-test KPI volume for
                        both groups
                      </li>
                    </ul>
                  ),
                  "Geo-PSA Testing": (
                    <ul className="basicList">
                      <li>
                        User-level analysis comparing response rates between test and control
                        groups.
                      </li>
                    </ul>
                  ),
                  "Always On Incrementality": (
                    <ul className="basicList">
                      <li>
                        User-level analysis comparing response rates between test and control
                        groups.
                      </li>
                    </ul>
                  ),
                },
              ],
              [
                {
                  flex: 5,
                  label: "",
                  name: "Element",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return (
                      //Reuse the channel class from the Playbook.scss
                      <div className="channelNames">
                        <div className="channelName single">
                          <div className="label">{row.Element}</div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  flex: 5,
                  label: "PSA Testing",
                  name: "PSA Testing",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["PSA Testing"]}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Geo Testing",
                  name: "Geo Testing",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["Geo Testing"]}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Geo-PSA Testing",
                  name: "Geo-PSA Testing",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["Geo-PSA Testing"]}</div>;
                  },
                },
                {
                  flex: 5,
                  label: "Always On Incrementality",
                  name: "Always On Incrementality",
                  nonInteractive: true,
                  renderer: (row: Record<string, any>): JSX.Element => {
                    return <div className="bulletedList">{row["Always On Incrementality"]}</div>;
                  },
                },
              ],
              150
            )}
            <br />
            <div className="basicText">
              Note: These are just general guidelines, and lengths and costs may vary based off of
              client.
            </div>
            <br />
            <div className="basicText">For more detailed setup steps, see below.</div>
            <br />
            <div className="basicHeader">Setup Process</div>
            <ol className="basicList">
              <li>
                Confirm test details with client:
                <ul>
                  <li>
                    Goal of the test, target date, kpi and lag they are interested in, spend/time
                    and other constraints, any questions the client has,etc
                  </li>
                  <li>
                    If the client is interested in a geo test then we will need additional details
                    and questions to clarify with the client:
                    <ol>
                      <li>
                        Is there a certain spend or impression level that the client team wants to
                        measure incrementality at? For example at campaign BAU (business-as-usual)
                        levels?
                      </li>
                      <li>Are there any DMA’s that the Client wants excluded from the test?</li>
                      <li>
                        Are there any other DMA-level covariates that should be taken into account
                        for the randomization process? Typically, we balance on DMA level covariates
                        such as size, prior response rates, and socioeconomic indicators. We can
                        include any other DMA-level covariates that the client feels are predictive
                        of the kpi response
                      </li>
                      <li>
                        When making the assumption about the test response rate, is there a certain
                        level of incrementality or a certain response rate that the client wants to
                        be able to detect with this test? If so, we can power the test to be able to
                        detect an effect of the desired size.
                      </li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>Fill out Sample Size Calculator (PSA Testing) if running a user-level test.</li>
              <li>
                Create JIRA with all the details, planned test, and filled out calculator if
                applicable and then pass to econometrics/marketing science. The team will then check
                over to make sure everything looks good from a test design perspective.
                <ul>
                  <li>
                    If it is a geo-test, the econ or marketing science team will randomize DMAs into
                    treatment and control and will do the power calculations to back out to a
                    recommended spend and test length and return all that information to you.
                  </li>
                  <li>
                    Typically you can assign the jira to the econometrics team member embedded to
                    the client account on{" "}
                    <a href="https://blisspoint.tinuiti.com/accountplanning">this page</a>.
                  </li>
                  <li>
                    After the spec out is complete, the Jira needs to be passed to the media team to
                    put in the media buys and set up the test. The embedded media ops team member
                    that you can reach out to will also be found on{" "}
                    <a href="https://blisspoint.tinuiti.com/accountplanning">this page</a>.
                  </li>
                  <li>
                    Once the test is complete, we will need to collect the results and analyze them.
                    More info on the steps for test readouts can be found below.
                  </li>
                  <li>Present results to clients!</li>
                </ul>
              </li>
            </ol>
          </div>
        }
        id={"setup-and-test-design"}
        pageTitle={PAGE_TITLE}
        title={"Setup and Test Design"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader">Analysis</div>
            <br />
            <div className="basicText">
              There are two levels/types of analysis that we conduct after the test: user-level and
              geo-level. Which one we do depends on the type of test we ran and can be found in the
              table above.
            </div>
            <br />
            <div className="basicHeader">User-Level Analysis</div>
            <br />
            <div className="basicText">
              To get results we run a hypothesis test comparing the response rates between the
              treatment and control to detect if there is a statistically significant difference
              between them. More info about the query that pulls the results and does this analysis
              can be found <a href={`${PAGE_PREFIX}/SampleSizeCalculator`}>here</a>. Getting results
              typically do not require support from the econometrics or marketing science team and
              results can be received by following the steps in the link above.
            </div>
            <br />
            <div className="basicHeader">Geo-levelAnalysis</div>
            <br />
            <div className="basicText">
              We will make use of the pre-test data and compare the change in KPI volume between the
              exposed and control DMA's to detect the impact of our treatment. We typically use a
              standard{" "}
              <a href="https://ds4ps.org/PROG-EVAL-III/DiffInDiff.html">
                Difference-in-Differences
              </a>{" "}
              model to measure results.
            </div>
            <br />
            <div className="basicHeader">Sample Outputs</div>
            <br />
            <div className="basicText">User-Level Analysis</div>
            <iframe
              allowFullScreen
              src="https://docs.google.com/spreadsheets/d/1iUrjc_nnNlvKSfFQAoBdUlgo53HEyzlJ2P0vqJVe3ss/preview?authuser=0"
              title="User-Level Analysis"
            />
            <br />
            <div className="basicText">Geo-Level Analysis</div>
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1C-KuNwEQ82h7TK9TeGQwJFb4iggT_J_A/preview?authuser=0"
              title="Geo-Level Analysis"
            />
            <br />
            <div className="basicText">Client Facing Results Example</div>
            <iframe
              allowFullScreen
              src="https://drive.google.com/file/d/1WH8uB85cGPiPcNgXOVEcJCj2JpB6X0SC/preview?authuser=0"
              title="Client Facing Results"
            />
          </div>
        }
        id={"test-outputs"}
        pageTitle={PAGE_TITLE}
        title={"Test Outputs"}
      />
    </div>
  );
});

export default Streaming;
