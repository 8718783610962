import { Button, Tooltip, Modal } from "react-bootstrap";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { download } from "../utils/download-utils";
import Papa from "papaparse";
import * as Dfns from "date-fns/fp";
import { useStateFunction } from "../utils/hooks/useData";
import { useSetError } from "../redux/modals";
import {
  Page,
  Spinner,
  OldFilterBar,
  Skeleton,
  TableSkeleton,
  DateRangePicker,
  BPMTable,
  NumberFormatter,
  OverlayTrigger,
} from "../Components";
import {
  MdFileDownload,
  MdDeleteForever,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";
import { LinearLambdaFetch, awaitJSON, pollS3 } from "../utils/fetch-utils";
import { CreditMemoDataRow } from "@blisspointmedia/bpm-types/dist/CreditMemos";
import { CreditMemoModal } from "./CreditMemoModal";
import "./CreditMemos.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const REFERENCE_DATE_FORMAT = "yyyy-MMM-dd";

interface DeleteCreditInfo {
  row: any;
  index: number;
}

interface DeleteCreditMemoModalProps {
  selectedRow: any;
  selectedRowIndex: number;
  show: boolean;
  setShow: Function;
}

const CreditMemo = (): JSX.Element => {
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [startWeek, setStartWeek] = useState(
    Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date()))
  );
  const [endWeek, setEndWeek] = useState(
    Dfns.format(DATE_FORMAT, Dfns.addDays(6, Dfns.startOfISOWeek(new Date())))
  );
  const [creditMemoFilter, setCreditMemoFilter] = useStateFunction<(line) => boolean>(() => true);
  const [creditMemoFilterAdvanced, setCreditMemoFilterAdvanced] = useState(false);
  const setError = useSetError();
  const [showCreditMemoRows, setShowCreditMemoRows] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPatching, setIsPatching] = useState(false);
  const [deleteCreditInfo, setDeleteCreditInfo] = useState<DeleteCreditInfo>();
  const [fetchingPDFForId, setFetchingPDFForId] = useState<any[]>([]);

  const updateCreditMemo = useCallback(
    (rowToUpdate: any, setValueObject: any): void => {
      // get row
      const { id } = rowToUpdate;
      const newData = data.map(row => {
        if (row.id === id) {
          for (let propToUpdate of Object.entries(setValueObject)) {
            row[propToUpdate[0]] = propToUpdate[1];
          }
        }
        return row;
      });
      setData(newData);
    },
    [data]
  );

  const referenceNumberRenderer = row =>
    `CM${row.company.toUpperCase()}_${row.network}_${row.avail}_${Dfns.format(
      REFERENCE_DATE_FORMAT,
      new Date(row.week.split("-")[0], parseInt(row.week.split("-")[1]) - 1, row.week.split("-")[2])
    ).toUpperCase()}`;

  const downloadPDF = useCallback(
    async (row: any) => {
      const { company, week, network, avail, id } = row;
      const oldId = [company, week, network, avail, id].join("___"); // 3 underscores
      setFetchingPDFForId([...fetchingPDFForId, id]);
      try {
        // in development try the dev URL (so production S3 files aren't stepped on)
        if (process.env.REACT_APP_STAGE === "production") {
          try {
            // First try the new bucket
            await pollS3({
              bucket: "credit-memo-pdfs",
              mimeType: "application/pdf",
              filename: `${id}.pdf`,
              maxAttempts: 2,
            });
          } catch (err) {
            // Try the old bucket if it's not in the new bucket
            await pollS3({
              bucket: "bpm-cache",
              mimeType: "application/pdf",
              filename: `${oldId}.pdf`,
            });
          }
        } else {
          await pollS3({
            bucket: "credit-memo-pdfs",
            mimeType: "application/pdf",
            filename: `${id}-dev.pdf`,
          });
        }
        setFetchingPDFForId(fetchingPDFForId.filter(currId => currId !== id));
      } catch (err) {
        setFetchingPDFForId(fetchingPDFForId.filter(currId => currId !== id));
        throw err;
      }
    },
    [fetchingPDFForId]
  );

  const selectedRows = useMemo(() => data.filter(row => row.selected), [data]);
  const selectedRowsIndex = useMemo(() => {
    return data
      .map((row, i) => ({ ...row, index: i }))
      .filter(row => row.selected)
      .map(row => row.index);
  }, [data]);

  const isOneRowSelected = useMemo(() => data.filter(row => row.selected).length === 1, [data]);

  const creditMemoHeaders = useMemo(() => {
    return [
      {
        label: "",
        name: "selected",
        width: 40,
        nonInteractive: true,
        renderer: row => {
          return (
            <Button
              variant="link"
              onClick={() => updateCreditMemo(row, { selected: !row.selected })}
            >
              {row.selected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            </Button>
          );
        },
      },
      {
        label: "Reference",
        name: "reference",
        flex: true,
        minFlexWidth: 360,
        renderer: referenceNumberRenderer,
      },
      {
        label: "Creation Time (Eastern)",
        name: "creationDatetime",
        flex: true,
        minFlexWidth: 200,
      },
      { label: "Week", name: "week", sortPriority: 1, sortAscending: true, flex: true },
      {
        label: "Network",
        name: "network",
        flex: true,
        minFlexWidth: 160,
        sortPriority: 2,
        sortAscending: true,
      },
      {
        label: "Avail",
        name: "avail",
        sortPriority: 3,
        sortAscending: true,
        flex: true,
        minFlexWidth: 60,
      },
      { label: "Company", name: "company", sortPriority: 4, sortAscending: true, flex: true },
      {
        label: "Approved Credit Amount",
        name: "approvedCreditAmount",
        sortPriority: 5,
        sortAscending: true,
        flex: true,
        renderer: row => {
          return <NumberFormatter value={row.approvedCreditAmount} type={"$"} decimals={0} />;
        },
      },
      {
        label: "Outstanding Credit Amount",
        name: "creditAmount",
        sortPriority: 6,
        sortAscending: true,
        flex: true,
        renderer: row => {
          return <NumberFormatter value={row.creditAmount} type={"$"} decimals={0} />;
        },
      },
      {
        label: "Number of Spots",
        name: "nSpots",
        sortPriority: 7,
        sortAscending: true,
        flex: true,
      },
      { label: "Status", name: "status", sortPriority: 7, sortAscending: true, flex: true },
      {
        label: "PDF",
        name: "pdf",
        nonInteractive: true,
        width: 50,
        renderer: row => {
          return fetchingPDFForId.includes(row.id) ? (
            <Spinner color="#7e57c2" />
          ) : (
            <MdFileDownload
              style={{ cursor: "pointer", padding: "4px", height: "26px", width: "26px" }}
              fill="#7e57c2"
              onClick={() => downloadPDF(row)}
            />
          );
        },
      },
    ];
  }, [updateCreditMemo, downloadPDF, fetchingPDFForId]);

  const DeleteConfirmationModal = ({
    selectedRow,
    selectedRowIndex,
    show,
    setShow,
  }: // data,
  // setData,
  DeleteCreditMemoModalProps): JSX.Element => {
    return (
      <Modal show onHide={() => setDeleteCreditInfo(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Credit Memo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the credit memo for {selectedRow.company},{" "}
          {selectedRow.network}, {selectedRow.avail}, {selectedRow.week}?{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              deleteCreditMemo(selectedRow, selectedRowIndex);
              setDeleteCreditInfo(undefined);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    API Requests (besdies initial GET)
  */
  const deleteCreditMemo = (row, index) => {
    (async () => {
      try {
        setIsDeleting(true);
        const { id } = row;
        await LinearLambdaFetch("/credit_memo", {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: { id },
        });
        // remove deleted row on client
        const newData = Array.from(data);
        newData.splice(index, 1);
        setData(newData);
        setIsDeleting(false);
      } catch (err: any) {
        setIsDeleting(false);
        console.log(err);
        setError({
          message: err.message,
          reportError: err,
        });
      }
    })();
  };

  const patchCreditMemo = useCallback(
    async (row, index, status) => {
      try {
        setIsPatching(true);
        const { id } = row;
        await LinearLambdaFetch("/credit_memo", {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: { id, status },
        });
        // update row status locally
        const newData = Array.from(data);
        newData[index].status = status;
        // move outstanding credit to approved credit on approval
        if (status === "Accepted") {
          newData[index].approvedCreditAmount = newData[index].creditAmount;
          newData[index].creditAmount = 0;
        }
        setData(newData);
        setIsPatching(false);
      } catch (err: any) {
        setIsPatching(false);
        console.log(err);
        setError({
          message: err.message,
          reportError: err,
        });
      }
    },
    [data, setError]
  );

  const filterableCreditMemoHeaders = creditMemoHeaders.filter(
    header => !["week"].includes(header.name)
  );

  // Same as creditMemoData but with no filters applied (all rows)
  const unfilteredCreditMemoData = useMemo(() => {
    return data ? data : [];
  }, [data]);

  const creditMemoData = useMemo(() => {
    return data ? data.filter(creditMemoFilter) : [];
  }, [data, creditMemoFilter]);

  const creditMemoCsv = useMemo(() => {
    let rows: any = [];
    let headers = creditMemoHeaders.filter(header => !["pdf", "selected"].includes(header.name));

    if (creditMemoData) {
      rows = creditMemoData.map(row =>
        headers.map(header =>
          header.name === "reference" ? referenceNumberRenderer(row) : row[header.name]
        )
      );
    }

    rows.unshift(headers.map(header => header.label));
    return Papa.unparse(rows);
  }, [creditMemoData, creditMemoHeaders]);

  // API fetch
  useEffect(() => {
    (async () => {
      try {
        setData([]);
        setIsFetching(true);
        let res = await LinearLambdaFetch("/credit_memo", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            startWeek,
            endWeek,
          },
        });
        let lambdaData = await awaitJSON<CreditMemoDataRow[]>(res);
        // add selected checkbox property
        setData(
          lambdaData.map(row => {
            return {
              ...row,
              selected: false,
            };
          })
        );
        setIsFetching(false);
      } catch (err: any) {
        setIsFetching(false);
        console.log(err);
        setError({
          message: err.message,
          reportError: err,
        });
      }
    })();
  }, [setError, endWeek, startWeek]);

  const actions = useMemo(() => {
    return (
      <>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={
            isOneRowSelected ? (
              ["Accepted", "Rejected"].includes(selectedRows[0]?.status) ? (
                <Tooltip id={"1"}>Already {selectedRows[0]?.status}</Tooltip>
              ) : (
                <></>
              )
            ) : (
              <Tooltip id={"1"}>Select one row to continue</Tooltip>
            )
          }
        >
          <div
            style={{
              width: "198px",
              display: "flex",
              flexDirection: "row",
              marginRight: "15px",
              position: "relative",
            }}
          >
            {!isOneRowSelected || ["Accepted", "Rejected"].includes(selectedRows[0]?.status) ? (
              <div
                style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
              ></div>
            ) : (
              false
            )}
            <Button
              disabled={
                !isOneRowSelected ||
                isPatching ||
                ["Accepted", "Rejected"].includes(selectedRows[0]?.status)
              }
              variant="outline-success"
              style={{ marginRight: "10px", width: "99px" }}
              onClick={() => patchCreditMemo(selectedRows[0], selectedRowsIndex[0], "Accepted")}
            >
              {isPatching ? <Spinner /> : "Accepted"}
            </Button>
            <Button
              disabled={
                !isOneRowSelected ||
                isPatching ||
                ["Accepted", "Rejected"].includes(selectedRows[0]?.status)
              }
              variant="outline-danger"
              style={{ width: "99px" }}
              onClick={() => patchCreditMemo(selectedRows[0], selectedRowsIndex[0], "Rejected")}
            >
              {isPatching ? <Spinner /> : "Rejected"}
            </Button>
          </div>
        </OverlayTrigger>
        <DateRangePicker
          mondayOnly
          startDate={startWeek}
          endDate={endWeek}
          startDateId="pacingStartDate"
          endDateId="pacingEndDate"
          onChange={({ startDate, endDate }) => {
            if (startDate && endDate) {
              setStartWeek(startDate);
              setEndWeek(endDate);
            }
          }}
        />
      </>
    );
  }, [
    endWeek,
    startWeek,
    isOneRowSelected,
    isPatching,
    patchCreditMemo,
    selectedRows,
    selectedRowsIndex,
  ]);

  return (
    <Page title="Credit Memos" pageType="Credit Memos" actions={actions}>
      <div className="allPanes">
        <div className="topPane">
          <div className="filterBarContainer">
            <OldFilterBar
              options={filterableCreditMemoHeaders}
              lines={unfilteredCreditMemoData}
              onFilter={setCreditMemoFilter}
              advanced={creditMemoFilterAdvanced}
              onSetAdvanced={advanced => setCreditMemoFilterAdvanced(advanced)}
            />
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
              overlay={
                isOneRowSelected ? (
                  ["Accepted", "Rejected"].includes(selectedRows[0]?.status) ? (
                    <Tooltip id={"1"}>Already {selectedRows[0]?.status}</Tooltip>
                  ) : (
                    <></>
                  )
                ) : (
                  <Tooltip id={"1"}>Select one row to continue</Tooltip>
                )
              }
            >
              {!isOneRowSelected || ["Accepted", "Rejected"].includes(selectedRows[0]?.status) ? (
                <div style={{ position: "relative", width: "168.27px" }}>
                  <div
                    style={{ position: "absolute", zIndex: 10, width: "100%", height: "100%" }}
                  ></div>
                  <Button disabled={true} style={{ position: "absolute", width: "100%" }}>
                    Email Credit Memo
                  </Button>
                </div>
              ) : (
                <Button onClick={() => setShowCreditMemoRows(true)}>Email Credit Memo</Button>
              )}
            </OverlayTrigger>
            <Button
              disabled={!(data.length > 0)}
              onClick={() =>
                download(creditMemoCsv, `Credit Memos ${startWeek} - ${endWeek}.csv`, "text/csv")
              }
            >
              <MdFileDownload />
            </Button>
            <Button
              disabled={!isOneRowSelected || isDeleting}
              onClick={() =>
                setDeleteCreditInfo({ row: selectedRows[0], index: selectedRowsIndex[0] })
              }
            >
              {isDeleting ? <Spinner /> : <MdDeleteForever />}
            </Button>
          </div>
          <BPMTable
            alternateColors
            headers={creditMemoHeaders}
            data={creditMemoData}
            rowHeight={26}
            headerHeight={40}
            filterBar={false}
            noRowsRenderer={() =>
              isFetching ? (
                <Skeleton>
                  <TableSkeleton />
                </Skeleton>
              ) : (
                <div>No rows to show in specified date range.</div>
              )
            }
          />
        </div>
      </div>
      {showCreditMemoRows && (
        <CreditMemoModal
          selectedRows={selectedRows}
          selectedRowsIndex={selectedRowsIndex}
          patchCreditMemo={patchCreditMemo}
          show={showCreditMemoRows}
          setShow={setShowCreditMemoRows}
        />
      )}
      {deleteCreditInfo && (
        <DeleteConfirmationModal
          selectedRow={deleteCreditInfo?.row}
          selectedRowIndex={deleteCreditInfo?.index}
          show={!!deleteCreditInfo}
          setShow={setDeleteCreditInfo}
        />
      )}
    </Page>
  );
};

export default CreditMemo;
