import React from "react";

import { OldFilterBar2 as FilterBarNonTS2 } from "./OldFilterBar2";

interface Option {
  name: string;
  label: string;
  type?: "string" | "boolean";
}

interface DefaultTokens {
  advanced?: string[];
  basic?: string[];
}

type ValidValue = string | number | boolean;
interface OldFilterBarProps<T = Record<string, ValidValue>> {
  options: (string | Option)[];
  lines: T[];
  onFilter: (filter: (line: T) => boolean) => void;
  advanced?: boolean;
  onSetAdvanced?: (boolean) => void;
  defaultTokens?: DefaultTokens;
  defaultAdvanced?: boolean;
  dynamicTokens?: string[];
  setDynamicTokens?: (dynamicTokens: string[]) => void;
}

export const OldFilterBar2 = <T,>(props: OldFilterBarProps<T>): JSX.Element => (
  <FilterBarNonTS2 {...props} />
);
