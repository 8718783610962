import "./StreamingConstraints.scss";
import { awaitJSON, StreamingOptimizationsLambdaFetch } from "../utils/fetch-utils";
import { Button } from "react-bootstrap";
import {
  ConstraintData,
  StreamingPlacementAndDerivedNetwork,
} from "../StreamingOptimizations/StreamingOptimizations";
import { MdSave } from "react-icons/md";
import { Page, FullPageSpinner, Spinner, ModalEditTable, SelectorOption } from "../Components";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useState, useEffect, useCallback, useMemo } from "react";

const PRETTY_DATE_FORMAT = "yyyy-MM-dd hh:mma";

const StreamingConstraints = (): JSX.Element => {
  const streamingType = window.location.href.split("/")[1] || "streaming";
  const setError = useSetError();
  const [dirty, setDirty] = useState<boolean>(false);
  const [data, setData] = useState<ConstraintData>();

  useEffect(() => {
    if (!data) {
      (async () => {
        try {
          const getGlobalConstraintResponse = await StreamingOptimizationsLambdaFetch(
            "/constraints",
            {
              params: {
                company: "global",
                name: "global",
                streamingType,
              },
            }
          );
          let globalConstraints = await awaitJSON(getGlobalConstraintResponse);
          for (let table of ["maximum", "avoid"]) {
            for (let i = 0; i < globalConstraints.constraints[table].length; i++) {
              if (!globalConstraints.constraints[table][i].lastmodified) {
                globalConstraints.constraints[table][i].lastmodified = "2020-01-01T00:00:00-08:00";
              }
            }
            globalConstraints.constraints[table] = R.sortBy(
              R.prop("lastmodified"),
              globalConstraints.constraints[table] as any[]
            );
            for (let i = 0; i < globalConstraints.constraints[table].length; i++) {
              globalConstraints.constraints[table][i].index = i;
            }
          }
          setData(globalConstraints);
        } catch (e) {
          let reportError = e as Error;
          setError({
            message: `Failed to get constraint data. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [data, streamingType, setError]);

  const [saving, setSaving] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);
    try {
      const saveConstraintResponse = await StreamingOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      });
      const saveConstraintData = await awaitJSON(saveConstraintResponse);
      setData(saveConstraintData);
      setDirty(false);
    } catch (e) {
      let reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
    setSaving(false);
  }, [data, setError]);

  const maximumHeaders = [
    {
      type: "select",
      field: "network",
      label: "Networks",
      isMulti: false,
      options: "networks",
      clearKeys: [],
      modalRow: 0,
      modalFlex: 2,
    },
    {
      type: "select",
      field: "description",
      label: "Descriptions",
      isMulti: true,
      options: "descriptions",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "derived_id",
      label: "Derived IDs",
      isMulti: true,
      options: "derivedIDs",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "placement",
      label: "Placements",
      isMulti: true,
      options: "placements",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "currency",
      field: "spend",
      label: "Spend",
      width: 120,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "text",
      field: "notes",
      label: "Notes",
      flex: 1,
      modalRow: 4,
      modalFlex: 1,
    },
    {
      type: "timestamp",
      field: "lastmodified",
      label: "Last Modified",
      width: 200,
      modalRow: 5,
      modalFlex: 1,
      readOnly: true,
    },
  ];

  const avoidHeaders = [
    {
      type: "select",
      field: "network",
      label: "Network",
      isMulti: false,
      options: "network",
      clearKeys: [],
      modalRow: 0,
      modalFlex: 2,
    },
    {
      type: "select",
      field: "description",
      label: "Descriptions",
      isMulti: true,
      options: "descriptions",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "derived_id",
      label: "Derived IDs",
      isMulti: true,
      options: "derivedIDs",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "placement",
      label: "Placements",
      isMulti: true,
      options: "placements",
      clearKeys: [],
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "length",
      label: "Length",
      options: "length",
      modalRow: 1,
      modalWidth: 100,
    },
    {
      type: "text",
      field: "notes",
      label: "Notes",
      flex: 1,
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "timestamp",
      field: "lastmodified",
      label: "Last Modified",
      width: 200,
      modalRow: 3,
      modalFlex: 1,
      readOnly: true,
    },
  ];

  const lastMod = useMemo(
    () =>
      data
        ? `Last Modified: ${R.defaultTo(
            "",
            R.replace(/@.*/, "", R.prop("lastuser", data))
          )} at ${Dfns.format(PRETTY_DATE_FORMAT)(Dfns.parseISO(data.lastmodified))}`
        : "",
    [data]
  );

  const [placements, setPlacements] = useState<StreamingPlacementAndDerivedNetwork[]>([]);
  const [placementsDownloaded, setPlacementsDownloaded] = useState(false);

  useEffect(() => {
    if (R.isEmpty(placements) && !placementsDownloaded) {
      (async () => {
        try {
          const getDerivedNetworksResponse = await StreamingOptimizationsLambdaFetch(
            "/getPlacementsAndDerivedNetworks",
            {
              params: { mediaType: streamingType },
            }
          );
          const placements: StreamingPlacementAndDerivedNetwork[] = await awaitJSON(
            getDerivedNetworksResponse
          );
          setPlacements(placements);
          setPlacementsDownloaded(true);
        } catch (e) {
          let reportError = e as Error;
          setError({
            message: `Failed to network data. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [setError, placements, streamingType, placementsDownloaded]);

  const publisherOptions: SelectorOption[] = useMemo(
    () =>
      placements
        ? R.map(
            e => ({ label: e, value: e }),
            R.sortBy(
              R.identity,
              R.uniq(
                R.pluck(
                  "description",
                  R.filter(placement => !R.isNil(placement.publisher), placements) as any[]
                )
              )
            )
          )
        : [],

    [placements]
  );

  const placementOptions: SelectorOption[] = useMemo(
    () =>
      placements
        ? R.map(
            e => ({ label: e, value: e }),
            R.sortBy(
              R.identity,
              R.uniq(
                R.pluck(
                  "placement_name",
                  R.filter(placement => !R.isNil(placement.placement), placements) as any[]
                )
              )
            )
          )
        : [],
    [placements]
  );

  const derivedIDOptions: SelectorOption[] = useMemo(
    () =>
      placements
        ? R.map(
            e => ({ label: e, value: e }),
            R.sortBy(
              R.identity,
              R.uniq(
                R.pluck(
                  "derived_id",
                  R.filter(placement => !R.isNil(placement.derived_id), placements) as any[]
                )
              )
            )
          )
        : [],
    [placements]
  );

  const selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    return {
      length: R.map(e => ({ label: e, value: e }), ["15", "30", "60"]),
      placements: placementOptions,
      publishers: publisherOptions,
      derivedIDs: derivedIDOptions,
    };
  }, [derivedIDOptions, publisherOptions, placementOptions]);

  return (
    <Page
      title="Streaming Global Constraints"
      minHeight={"600"}
      actions={
        <div className={`streamingConstraintActions${saving ? " saving" : ""}`}>
          {data && <div>{lastMod}</div>}
          <Button variant="primary" onClick={save} disabled={!dirty} className="saveButton">
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
      pageType={"Streaming Global Constraints"}
    >
      {data && R.prop("constraints", data) ? (
        <div className={`streamingConstraints${saving ? " saving" : ""}`}>
          <ModalEditTable
            headers={maximumHeaders}
            name="Maximum constraints"
            tableData={data.constraints.maximum}
            filterBar={true}
            setTableData={tableData => {
              setData({ ...data, constraints: { ...data.constraints, maximum: tableData } });
              setDirty(true);
            }}
            selectorOptions={selectorOptions}
            className={""}
          />
          <ModalEditTable
            headers={avoidHeaders}
            name="Avoid constraints"
            tableData={data.constraints.avoid}
            filterBar={true}
            setTableData={tableData => {
              setData({ ...data, constraints: { ...data.constraints, avoid: tableData } });
              setDirty(true);
            }}
            selectorOptions={selectorOptions}
            className={""}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default StreamingConstraints;
