import React from "react";

import { RowType } from "./pendingChangesUtils";
import { Header } from "../StickyTable";

import "./PendingChangesPanel.scss";

interface PendingNewRowProps<R> {
  newRow: R;
  columns: Header[];
}

const PendingNewRow = <R extends RowType>({
  newRow,
  columns,
}: PendingNewRowProps<R>): JSX.Element => {
  return (
    <div className="pendingChangesRowContainer pendingNewRowContainer">
      {columns.map(col => {
        const { label, field, renderer } = col;
        const newVal = newRow[field || ""] || "null";
        return (
          <div key={label}>
            <div className="pendingChangesRow">
              <b className="pendingChangesRowLabel">{label}:</b>
              <div>{renderer ? renderer(newRow) : newVal}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PendingNewRow;
