// Experiment for display ISCIs.
const displayIscisCompanies: string[] = [
  "blisspoint",
  "condenast",
  "instacart",
  "instacartca",
  "petmeds",
  "wisp",
  "theathletic",
  "butcherbox",
  "trugreen",
  "prizepicks",
  "bassettfurniture",
  "olly",
  "liquidiv",
  "alltrails",
  "adopstest"
  // Add companies here!
];

export const shouldEnableDisplayIscis = (company: string): boolean => {
  return displayIscisCompanies.includes(company);
};
