import React from "react";

// TODO: Probably use a pure-render if this proves to be an issue.

export const getFormattedValue = ({
  currency = "USD",
  locale = "en-US",
  value,
  type,
  decimals = 0,
}) => {
  if (!Number.isFinite(value)) {
    return "";
  }
  let opts = {};
  if (type === "%") {
    opts.style = "percent";
    opts.decimals = decimals != null ? decimals : 1;
  } else if (type === "$") {
    opts.style = "currency";
    opts.decimals = decimals != null ? decimals : null;
  } else if (type === "$0") {
    opts.style = "currency";
    opts.decimals = 0;
  } else if (type === "$K") {
    value *= 1000;
    opts.style = "currency";
    opts.decimals = 0;
  } else if (type === "#") {
    opts.style = "decimal";
  } else if (type.indexOf("#") === 0) {
    opts.style = "decimal";
    opts.decimals = parseInt(type.replace("#", ""));
  } else {
    // defaults to displaying as decimal
    opts.style = "decimal";
  }

  let options = {
    style: opts.style,
    minimumFractionDigits: opts.decimals,
    maximumFractionDigits: opts.decimals,
    currency,
  };

  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(value);
};

export const NumberFormatter = ({
  prefix = "",
  currency = "USD",
  locale = "en-US",
  value,
  type,
  decimals = 0,
}) => {
  if (value === "NaN" || value === "Infinity" || value == null) {
    return <span className="number-formatter" />;
  }

  let formattedValue = getFormattedValue({
    currency,
    locale,
    value,
    type,
    decimals,
  });

  return <span className="number-formatter">{`${prefix}${formattedValue}`}</span>;
};
