import "./OfflineInputs.scss";
import React, { useMemo } from "react";
import cn from "classnames";
import { MdDelete, MdEdit, MdSave } from "react-icons/md";
import { Button, ButtonType, FilterBar, Spinner } from "../../Components";
import { DownloadToggle } from "../../Components/DownloadDropdown/DownloadToggle";
import { HiOutlinePlus } from "react-icons/hi";
import { EmptyInputs } from "./EmptyInputs";
import { OfflineInputTabs } from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { format } from "date-fns";
import { SetError } from "../../redux/modals";

interface InputContainerProps {
  dialogs: React.ReactNode;
  tables: React.ReactNode;
  inputType: keyof typeof OfflineInputTabs;
  updated: { by?: string; at?: string };
  isEmpty: boolean;
  fetching: boolean;
  submitting: boolean;
  editingUnlocked: boolean;
  setEditingUnlocked: React.Dispatch<React.SetStateAction<boolean>>;
  onFilter: (filter: any) => void;
  validateAndUpdateTables: () => void;
  setShowAddInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDiscardDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setError: SetError;
}

const InputContainer: React.FC<InputContainerProps> = ({
  dialogs,
  tables,
  inputType,
  updated,
  isEmpty,
  fetching,
  submitting,
  editingUnlocked,
  setEditingUnlocked,
  onFilter,
  validateAndUpdateTables,
  setShowAddInputModal,
  setShowDiscardDialog,
  setError,
}) => {
  const updatedLabel: React.ReactNode = useMemo(() => {
    const { by, at } = updated;

    try {
      const atDate = new Date(at ?? "");
      const atStrFormatted = format(atDate, "MM/dd/yyyy");

      return `Last updated on ${atStrFormatted} by ${by}`;
    } catch (e) {
      // If "at" cannot be parsed as a date, display nothing.
      return null;
    }
  }, [updated]);

  const addMediaLabel: string = useMemo(() => {
    switch (inputType) {
      case OfflineInputTabs.PAID_MEDIA_INPUTS:
        return "Paid Media Input";
      case OfflineInputTabs.NON_PAID_MEDIA_INPUTS:
        return "Non-Paid Media Input";
      case OfflineInputTabs.OUTCOME_DATA:
        return "Outcome Data";
      default:
        return "";
    }
  }, [inputType]);

  return (
    <>
      {dialogs}
      <div className="offlineInputsGroup">
        {fetching && <Spinner size={100} />}
        {!fetching && isEmpty && (
          <EmptyInputs
            inputType={addMediaLabel}
            showAddMediaInputModal={() => setShowAddInputModal(true)}
          />
        )}
        {!fetching && !isEmpty && (
          <>
            <div className="tablesHeader">
              <span className="tablesHeaderUpdatedBy">{updatedLabel}</span>
              <span className="tablesActions">
                {editingUnlocked && (
                  <FilterBar hasAdvanced={false} onFilter={onFilter} options={["date"]} />
                )}
                {editingUnlocked ? (
                  <Button
                    type={ButtonType.FILLED}
                    design="secondary"
                    disabled={submitting}
                    onClick={() => setShowAddInputModal(true)}
                  >
                    <HiOutlinePlus />
                    Add {addMediaLabel}
                  </Button>
                ) : (
                  <Button type={ButtonType.FILLED} onClick={() => setEditingUnlocked(true)}>
                    <MdEdit />
                    Edit
                  </Button>
                )}
                <div
                  // TODO: Implement download: https://tinuiti.atlassian.net/browse/STREAM-118
                  onClick={() =>
                    setError({ title: "Cannot download", message: "Download not yet implemented" })
                  }
                >
                  <DownloadToggle design={editingUnlocked ? "secondary" : "primary"} />
                </div>
              </span>
            </div>
            <div className={cn("tablesContainer", { locked: !editingUnlocked })}>{tables}</div>
            {editingUnlocked && (
              <div className="tablesFooter">
                <div className="tablesActions">
                  <Button
                    type={ButtonType.FILLED}
                    design="secondary"
                    disabled={submitting}
                    onClick={() => setShowDiscardDialog(true)}
                  >
                    <MdDelete />
                    Discard Edits
                  </Button>
                  <Button
                    type={ButtonType.FILLED}
                    disabled={submitting}
                    onClick={validateAndUpdateTables}
                  >
                    {submitting ? (
                      <Spinner />
                    ) : (
                      <>
                        <MdSave />
                        Commit
                      </>
                    )}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InputContainer;
