import "./DeliverySnapshot.scss";
import { CrossChannelMetrics, BudgetsData } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import React, { useState, useMemo, useContext, useCallback } from "react";
import * as R from "ramda";
import { Dropdown, DropdownToggleType, DownloadDropdown } from "../../Components";
import ChartContainer from "../../Components/ChartContainer";
import { metricFormatter, SPEND_FORMATTER } from "../../TVADCrossChannel/homePageUtils";
import { CrossChannelContext } from "../CrossChannel";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import SnapshotChart, { BarPart, BarType, DataItem } from "./SnapshotChart";

enum TopDropdownValues {
  SPEND = "spend",
  OTHER_BUDGET = "otherBudget",
}

enum BottomDropdownValues {
  BUDGET = "budget",
  OTHER_SPEND = "otherSpend",
}

interface DeliverySnapshotProps {
  data: CrossChannelMetrics[];
  otherData: CrossChannelMetrics[];
  budgetsData: BudgetsData[];
  defaultTop?: string;
  defaultBottom?: string;
}

interface ChannelTotals {
  primarySpend: Record<string, number>;
  comparisonSpend: Record<string, number>;
  primaryBudgets: Record<string, number>;
  comparisonBudgets: Record<string, number>;
}

const getChannelTotals = (
  data: CrossChannelMetrics[],
  otherData: CrossChannelMetrics[],
  budgetsData: BudgetsData[],
  toggledChannels: Record<string, boolean>
): ChannelTotals => {
  let primarySpendByChannel: Record<string, number> = {};
  let comparisonSpendByChannel: Record<string, number> = {};
  let primaryBudgetByChannel: Record<string, number> = {};
  let comparisonBudgetByChannel: Record<string, number> = {};

  for (let item of data) {
    const { channel, metrics } = item;
    if (!toggledChannels[channel]) {
      continue;
    }

    const { spend } = metrics;

    primarySpendByChannel = R.mergeDeepRight(primarySpendByChannel, {
      [channel]: R.pathOr(0, [channel], primarySpendByChannel) + spend,
    });
  }

  for (let item of otherData) {
    const { channel, metrics } = item;
    if (!toggledChannels[channel]) {
      continue;
    }

    const { spend } = metrics;

    comparisonSpendByChannel = R.mergeDeepRight(comparisonSpendByChannel, {
      [channel]: R.pathOr(0, [channel], comparisonSpendByChannel) + spend,
    });
  }

  for (let item of budgetsData) {
    const { channel, budget, dateRange } = item;
    if (!toggledChannels[channel]) {
      continue;
    }

    if (dateRange === "primary") {
      primaryBudgetByChannel = R.mergeDeepRight(primaryBudgetByChannel, {
        [channel]: R.pathOr(0, [channel], primaryBudgetByChannel) + budget,
      });
    } else {
      comparisonBudgetByChannel = R.mergeDeepRight(comparisonBudgetByChannel, {
        [channel]: R.pathOr(0, [channel], comparisonBudgetByChannel) + budget,
      });
    }
  }

  return {
    primarySpend: primarySpendByChannel,
    comparisonSpend: comparisonSpendByChannel,
    primaryBudgets: primaryBudgetByChannel,
    comparisonBudgets: comparisonBudgetByChannel,
  };
};

/**
 * Choose which numbers to use for each channel depending on what comparison dropdowns are selected.
 */
const processSnapshotData = (
  channelTotals: ChannelTotals,
  toggledChannels: Record<string, boolean>,
  topPathKey: string,
  bottomPathKey: string,
  isDoubleBar: boolean,
  channelColors: Record<string, string>,
  availableChannels: Record<string, boolean>
): DataItem[] => {
  const { primarySpend, comparisonSpend, primaryBudgets, comparisonBudgets } = channelTotals;

  let processedData: DataItem[] = [];

  if (isDoubleBar) {
    for (let channel of R.keys(availableChannels).sort()) {
      if (!toggledChannels[channel]) {
        continue;
      }

      const topValue =
        topPathKey === TopDropdownValues.SPEND
          ? primarySpend[channel] || 0
          : comparisonBudgets[channel] || 0;

      const bottomValue =
        bottomPathKey === BottomDropdownValues.OTHER_SPEND
          ? comparisonSpend[channel] || 0
          : primaryBudgets[channel] || 0;

      const parts = [
        { val: topValue, type: BarType.HORIZONTAL },
        { val: bottomValue, type: BarType.HORIZONTAL_GREY },
      ];

      const numericalDifference = topValue - bottomValue;
      const percentDifference = (numericalDifference / bottomValue) * 100;

      const formattedNumericalDifference = metricFormatter("spend")(numericalDifference);
      const formattedPercentDifference = isFinite(percentDifference)
        ? `(${Math.round(percentDifference)}%)`
        : "";

      const subLabel = `${formattedNumericalDifference} ${formattedPercentDifference}`;

      processedData.push({
        label: channel,
        subLabel,
        topValue,
        bottomValue,
        parts,
        color: channelColors[channel],
      });
    }
  } else {
    for (let channel of R.keys(availableChannels).sort()) {
      if (!toggledChannels[channel]) {
        continue;
      }

      // always spend
      const topValue =
        topPathKey === TopDropdownValues.SPEND
          ? primarySpend[channel] || 0
          : comparisonSpend[channel] || 0;

      // always budget
      const bottomValue =
        bottomPathKey === BottomDropdownValues.BUDGET
          ? primaryBudgets[channel] || 0
          : comparisonBudgets[channel] || 0;

      const parts: BarPart[] = [
        { val: topValue, type: BarType.HORIZONTAL, className: "spend" },
        { val: bottomValue, type: BarType.VERTICAL, className: "budget" },
      ];

      const difference = bottomValue - topValue;

      if (difference > 0) {
        parts.push({ val: difference, type: BarType.HORIZONTAL_GREY, className: "remainder" });
      }

      const numericalDifference = topValue - bottomValue;
      const percentDifference = (numericalDifference / bottomValue) * 100;

      const formattedNumericalDifference = metricFormatter("spend")(numericalDifference);
      const formattedPercentDifference = isFinite(percentDifference)
        ? `(${Math.round(percentDifference)}%)`
        : "";

      const subLabel = `${formattedNumericalDifference} ${formattedPercentDifference}`;

      processedData.push({
        label: channel,
        subLabel,
        topValue,
        bottomValue,
        parts,
        color: channelColors[channel],
      });
    }
  }

  return processedData;
};

const getTotals = (processedData: DataItem[]) => {
  const topTotal = processedData.reduce((acc, item) => acc + R.pathOr(0, ["topValue"], item), 0);
  const bottomTotal = processedData.reduce(
    (acc, item) => acc + R.pathOr(0, ["bottomValue"], item),
    0
  );

  return { topTotal, bottomTotal };
};

export const makeDropdownOptions = (): { topDropdownOptions; bottomDropdownOptions } => {
  const topDropdownOptions = [
    { label: "Comparison's Budget", value: TopDropdownValues.OTHER_BUDGET },
    { label: "Primary's Spend", value: TopDropdownValues.SPEND },
  ];
  const bottomDropdownOptions = [
    { label: "Primary's Budget", value: BottomDropdownValues.BUDGET },
    { label: "Comparison's Spend", value: BottomDropdownValues.OTHER_SPEND },
  ];

  return { topDropdownOptions, bottomDropdownOptions };
};

const DeliverySnapshot: React.FC<DeliverySnapshotProps> = React.memo(
  ({
    data,
    otherData,
    budgetsData,
    defaultTop = TopDropdownValues.SPEND,
    defaultBottom = BottomDropdownValues.BUDGET,
  }) => {
    const [topPathKey, setTopPathKey] = useState<string>(defaultTop);
    const [bottomPathKey, setBottomPathKey] = useState<string>(defaultBottom);

    const {
      company,
      dates,
      selectedChannels,
      deliveryAndPerformanceChannels,
      channelColors,
      kpi,
      otherDates,
    } = useContext(CrossChannelContext);

    const channelTotals = useMemo(
      () => getChannelTotals(data, otherData, budgetsData, selectedChannels),
      [data, otherData, budgetsData, selectedChannels]
    );

    // If comparing spend/spend or budget/budget, we display the data differently.
    const isDoubleBar =
      (topPathKey === TopDropdownValues.SPEND &&
        bottomPathKey === BottomDropdownValues.OTHER_SPEND) ||
      (topPathKey === TopDropdownValues.OTHER_BUDGET &&
        bottomPathKey === BottomDropdownValues.BUDGET);

    const processedData = useMemo(
      () =>
        processSnapshotData(
          channelTotals,
          selectedChannels,
          topPathKey,
          bottomPathKey,
          isDoubleBar,
          channelColors,
          deliveryAndPerformanceChannels
        ),
      [
        channelTotals,
        selectedChannels,
        topPathKey,
        bottomPathKey,
        isDoubleBar,
        channelColors,
        deliveryAndPerformanceChannels,
      ]
    );

    const { topDropdownOptions, bottomDropdownOptions } = useMemo(() => makeDropdownOptions(), []);

    const excelDownload = useCallback(() => {
      const exportData = processedData.map(item => {
        const { label, topValue, bottomValue } = item;
        return {
          label,
          [`${dates.start} ${topPathKey}`]: topValue,
          [`${dates.end} ${bottomPathKey}`]: bottomValue,
        };
      });
      exportToExcel(exportData, `${company}_DeliverySnapshot`);
    }, [bottomPathKey, company, dates.end, dates.start, processedData, topPathKey]);

    const pngDownload = useCallback(async () => {
      const label = `KPI: ${kpi}`;
      const topDropdown =
        topDropdownOptions.find(item => item.value === topPathKey)?.label.split(" ")[1] ||
        topPathKey;
      const bottomDropdown =
        bottomDropdownOptions.find(item => item.value === bottomPathKey)?.label.split(" ")[1] ||
        bottomPathKey;

      const fileNameIdentifiers = ["CrossChannel", `${dates.start}–${dates.end}`, topDropdown];
      if (isDoubleBar) {
        fileNameIdentifiers.push(`vs_${otherDates.start}–${otherDates.end}`, bottomDropdown);
      }
      await downloadPNG(".deliverySnapshotContainer", fileNameIdentifiers, label, [".rightSide"]);
    }, [
      kpi,
      topDropdownOptions,
      topPathKey,
      bottomDropdownOptions,
      bottomPathKey,
      otherDates,
      dates,
      isDoubleBar,
    ]);

    const { topTotal, bottomTotal } = useMemo(() => getTotals(processedData), [processedData]);

    // If the top dropdown is Spend and the bottom dropdown is Budget, we want to flip the colors
    // because we want the budget dropdown to be darker color to match the budget bars. In all other
    // instances, we have the top dropdown as the darker color.
    const flipDropdownColors = useMemo(
      () => topPathKey === TopDropdownValues.SPEND && bottomPathKey === BottomDropdownValues.BUDGET,
      [topPathKey, bottomPathKey]
    );

    return (
      <div className="deliverySnapshotContainer">
        <ChartContainer
          enableHoverDesign
          title={
            <>
              <Dropdown
                className={`topDeliverySnapshotDropdown ${flipDropdownColors ? "flipColor" : ""}`}
                type={DropdownToggleType.WIDGET_TITLE}
                value={topPathKey}
                options={topDropdownOptions}
                onChange={option => setTopPathKey(option)}
              />
            </>
          }
          leftActions={
            <>
              <div
                style={{
                  fontSize: "24px",
                  color: flipDropdownColors ? "#1f003f" : "#94a0b8",
                  fontWeight: 600,
                  marginRight: "6px",
                }}
              >
                vs
              </div>
              <Dropdown
                className={`bottomDeliverySnapshotDropdown ${
                  flipDropdownColors ? "flipColor" : ""
                }`}
                type={DropdownToggleType.WIDGET_TITLE}
                value={bottomPathKey}
                options={bottomDropdownOptions}
                onChange={option => setBottomPathKey(option)}
                background="dark"
              />
            </>
          }
          rightActions={
            <DownloadDropdown
              size="sm"
              onClickOptions={[excelDownload, pngDownload]}
            ></DownloadDropdown>
          }
          footerContent={
            <>
              <span>{"KPI doesn't apply"}</span>
              <span>{"Source = Platform"}</span>
            </>
          }
        >
          {R.isEmpty(processedData) ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "26px",
              }}
            >
              No data to show
            </div>
          ) : (
            <div id="deliverySnapshotGraphContents" className="deliverySnapshotContents">
              <Totals
                topTotal={topTotal}
                bottomTotal={bottomTotal}
                topPathKey={topPathKey}
                bottomPathKey={bottomPathKey}
              />
              <SnapshotChart
                data={processedData}
                doubleBarChart={isDoubleBar}
                valueFormatter={SPEND_FORMATTER}
              />
            </div>
          )}
        </ChartContainer>
      </div>
    );
  }
);

interface TotalsProps {
  topTotal: number;
  bottomTotal: number;
  topPathKey: string;
  bottomPathKey: string;
}

const Totals: React.FC<TotalsProps> = ({ topTotal, bottomTotal, topPathKey, bottomPathKey }) => {
  const numericalDifference =
    // If Top is Budget and Bottom is Spend, we want to base the increase based on the bottom because
    // we want to compare Comparison Spend on bottom to Comparison Budget on top
    topPathKey.toLowerCase().includes("budget") && bottomPathKey.toLowerCase().includes("spend")
      ? bottomTotal - topTotal
      : topTotal - bottomTotal || 0;
  const percentDifference = (numericalDifference / bottomTotal) * 100 || 0;
  let differenceLabel = "";

  // If both keys have spend in name or both keys have budget, Total: Increase/Decrease $X (Y%)
  // Otherwise, Total: Over/Under budget $X (Y%)
  if (
    (topPathKey.toLowerCase().includes("spend") && bottomPathKey.toLowerCase().includes("spend")) ||
    (topPathKey.toLowerCase().includes("budget") && bottomPathKey.toLowerCase().includes("budget"))
  ) {
    differenceLabel = numericalDifference >= 0 ? "Increase" : "Decrease";
  } else {
    differenceLabel = numericalDifference >= 0 ? "Over budget" : "Under budget";
  }

  return (
    <div className="totalsContainer">
      <div className="totalsLabel">Total: {differenceLabel}</div>
      <div className="totalsDiff">{metricFormatter("spend")(Math.abs(numericalDifference))}</div>
      <div className="totalsPercentDiff">
        ({`${Math.round(Math.abs(percentDifference)).toLocaleString()}%`})
      </div>
    </div>
  );
};

export default DeliverySnapshot;
