import React, { useState, useRef, useCallback, useMemo, useEffect, SyntheticEvent } from "react";
import ReactDOM from "react-dom";

import * as R from "ramda";

import { useIsMounted } from "../utils/hooks/useDOMHelpers";

import "./OverlayTrigger.scss";
import { typedReactMemo } from "../utils/types";
import { BPMPopover } from "./BPMPopover";

const TOOLTIP_OFFSET = 2;
const POPOVER_OFFSET = 8;

// https://github.com/react-bootstrap/react-bootstrap/blob/master/src/OverlayTrigger.js
class RefHolder extends React.Component {
  render() {
    return this.props.children;
  }
}

interface OverlayContainerProps {
  top?: number | "unset";
  right?: number | "unset";
  bottom?: number | "unset";
  left?: number | "unset";
  transform?: string;
  exitOnBackgroundClick?: boolean;
  onBackgroundClick?: () => void;
}

const OverlayContainer = typedReactMemo<React.FC<OverlayContainerProps>>(
  ({ children, top, right, bottom, left, transform, exitOnBackgroundClick, onBackgroundClick }) => {
    let content = (
      <div
        className="overlayTriggerContainer"
        style={{
          top,
          right,
          bottom,
          left,
          transform,
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </div>
    );
    if (exitOnBackgroundClick) {
      content = (
        <div className="overlayTriggerBackground" onClick={onBackgroundClick}>
          {content}
        </div>
      );
    }
    return ReactDOM.createPortal(content, document.getElementsByTagName("body")[0]);
  }
);

// Legacy
const PLACEMENT_KEYWORDS = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
  CENTER: "center",
} as const;

const PLACEMENTS = {
  TOP: {
    LEFT: `${PLACEMENT_KEYWORDS.TOP} ${PLACEMENT_KEYWORDS.LEFT}`,
    CENTER: `${PLACEMENT_KEYWORDS.TOP} ${PLACEMENT_KEYWORDS.CENTER}`,
    RIGHT: `${PLACEMENT_KEYWORDS.TOP} ${PLACEMENT_KEYWORDS.RIGHT}`,
  },
  RIGHT: {
    TOP: `${PLACEMENT_KEYWORDS.RIGHT} ${PLACEMENT_KEYWORDS.TOP}`,
    CENTER: `${PLACEMENT_KEYWORDS.RIGHT} ${PLACEMENT_KEYWORDS.CENTER}`,
    BOTTOM: `${PLACEMENT_KEYWORDS.RIGHT} ${PLACEMENT_KEYWORDS.BOTTOM}`,
  },
  BOTTOM: {
    LEFT: `${PLACEMENT_KEYWORDS.BOTTOM} ${PLACEMENT_KEYWORDS.LEFT}`,
    CENTER: `${PLACEMENT_KEYWORDS.BOTTOM} ${PLACEMENT_KEYWORDS.CENTER}`,
    RIGHT: `${PLACEMENT_KEYWORDS.BOTTOM} ${PLACEMENT_KEYWORDS.RIGHT}`,
  },
  LEFT: {
    TOP: `${PLACEMENT_KEYWORDS.LEFT} ${PLACEMENT_KEYWORDS.TOP}`,
    CENTER: `${PLACEMENT_KEYWORDS.LEFT} ${PLACEMENT_KEYWORDS.CENTER}`,
    BOTTOM: `${PLACEMENT_KEYWORDS.LEFT} ${PLACEMENT_KEYWORDS.BOTTOM}`,
  },
} as const;

// Legacy
const TRIGGERS = {
  CLICK: "click",
  HOVER: "hover",
} as const;

interface SyntheticHandler {
  (e: SyntheticEvent): void;
}

type VERTICAL_WORD = "top" | "bottom";
type HORIZONTAL_WORD = "left" | "right";
type CENTER_WORD = "center";

export type Placement =
  | `${VERTICAL_WORD} ${HORIZONTAL_WORD | CENTER_WORD}`
  | `${HORIZONTAL_WORD} ${VERTICAL_WORD | CENTER_WORD}`;

export type Trigger = "click" | "hover";
interface OverlayTriggerProps {
  children: JSX.Element;
  overlay: JSX.Element;
  trigger?: Trigger;
  placement?: Placement;
  delay?: number;
  exitOnBackgroundClick?: boolean;
  loseFocusOnScroll?: boolean;
  onExit?: () => void;
  customTrigger?: boolean | undefined | null;
  yoffset?: number;
  xoffset?: number;
}
// NOTE: this needs exactly one child, no more, no less.
// NOTE: this is unlikely to work unless a React Bootstrap Tooltip or Popover is used as the "overlay"
export const OverlayTrigger = ({
  children,
  overlay,
  trigger = "hover",
  placement = "top center",
  delay = 0,
  exitOnBackgroundClick = false,
  loseFocusOnScroll = true,
  onExit,
  customTrigger,
  yoffset = 0,
  xoffset = 0,
}: OverlayTriggerProps): JSX.Element => {
  const child = React.Children.only(children);
  const getIsMounted = useIsMounted();

  const ref = useRef<RefHolder>(null);
  const [rect, setRect] = useState<DOMRect>();
  const [opening, setOpening] = useState(false);

  const close = useCallback(() => {
    if (onExit) {
      onExit();
    }
    setOpening(false);
    setRect(undefined);
  }, [onExit]);

  const getRect = useCallback(() => {
    if (!opening && ref.current && ReactDOM.findDOMNode(ref.current)) {
      const open = () => {
        let rect = (ReactDOM.findDOMNode(ref.current) as Element).getBoundingClientRect();
        setRect(rect);
      };
      if (delay) {
        setOpening(true);
        setTimeout(() => {
          if (getIsMounted()) {
            setOpening(opening => {
              if (opening) {
                open();
                return false;
              }
              return opening;
            });
          }
        }, delay);
      } else {
        open();
      }
    }
  }, [delay, opening, getIsMounted]);

  useEffect(() => {
    if (!R.isNil(customTrigger)) {
      if (customTrigger) {
        getRect();
      } else {
        close();
      }
    }
  }, [customTrigger, close, getRect]);

  useEffect(() => {
    if (rect) {
      let bail = false;
      let listener = () => {
        if (!bail) {
          setRect(undefined);
          if (loseFocusOnScroll) {
            window.removeEventListener("scroll", listener);
          }
        }
      };
      if (loseFocusOnScroll) {
        window.addEventListener("scroll", listener, true);
      }
      return () => {
        bail = true;
        if (loseFocusOnScroll) {
          window.removeEventListener("scroll", listener);
        }
      };
    }
  }, [rect, getRect, loseFocusOnScroll]);

  let triggerProps = useMemo(() => {
    let triggerProps: Record<string, SyntheticHandler> = {};
    if (R.isNil(customTrigger)) {
      switch (trigger) {
        case "click":
          triggerProps.onClick = e => {
            if (rect) {
              close();
            } else {
              getRect();
            }
            if (R.path(["props", "onClick"], child)) {
              child.props.onClick(e);
            }
          };
          break;
        case "hover":
          triggerProps.onFocus = e => {
            getRect();
            if (R.path(["props", "onFocus"], child)) {
              child.props.onFocus(e);
            }
          };
          triggerProps.onBlur = e => {
            close();
            if (R.path(["props", "onBlur"], child)) {
              child.props.onBlur(e);
            }
          };
          triggerProps.onMouseOver = e => {
            getRect();
            if (R.path(["props", "onMouseOver"], child)) {
              child.props.onMouseOver(e);
            }
          };
          triggerProps.onMouseOut = e => {
            close();
            if (R.path(["props", "onMouseOut"], child)) {
              child.props.onMouseOut(e);
            }
          };
          // NOTE: when they scroll, the tooltip is removed. However, this means if they move their cursor around inside
          // the element, the tooltip won't show up. They have to exit the element and re-hover. This would change that.
          // However, it has a sort of laggy feel because if you scroll without moving your mouse, or scroll and then
          // slightly move your mouse it kind of flickers.
          // triggerProps.onMouseMove = e => {if (!rect) {getRect();
          //   }
          //   if (R.path(["props", "onMouseMove"], child)) {child.props.onMouseMove(e);
          //   }
          // };
          break;
        default:
          console.error(`${trigger} is not a valid trigger`);
      }
    }
    return triggerProps;
    // I'm skeptical that in all cases this will trigger if the child's onClick/onHover props change.
  }, [customTrigger, trigger, rect, child, close, getRect]);

  const { arrowProps, tooltipPlacement, ...overlayProps } = useMemo(() => {
    const parts = placement.split(" ") as [
      VERTICAL_WORD | HORIZONTAL_WORD | CENTER_WORD,
      VERTICAL_WORD | HORIZONTAL_WORD | CENTER_WORD
    ];
    if (parts.length !== 2) {
      console.error(`${placement} is not a valid placement`);
    }
    let offset = overlay.type === BPMPopover ? POPOVER_OFFSET : TOOLTIP_OFFSET;

    if (!rect) {
      return {};
    }
    let top: number | "unset" = "unset";
    let right: number | "unset" = "unset";
    let bottom: number | "unset" = "unset";
    let left: number | "unset" = "unset";
    let transformX = "0";
    let transformY = "0";
    let arrowProps: { style: React.CSSProperties } = {
      style: {},
    };
    let tooltipPlacement = "";
    switch (parts[0]) {
      case "top":
        top = rect.top - offset;
        transformY = "-100%";
        tooltipPlacement = "top";
        break;
      case "right":
        left = rect.right + offset;
        tooltipPlacement = "right";
        break;
      case "bottom":
        top = rect.bottom + offset;
        tooltipPlacement = "bottom";
        break;
      case "left":
        left = rect.left - offset;
        transformX = "-100%";
        tooltipPlacement = "left";
        break;
      default:
        console.error(`${placement} is not a valid placement`);
    }
    switch (parts[1]) {
      case "top":
        ({ top } = rect);
        arrowProps.style.top = 3;
        break;
      case "right":
        left = rect.right;
        transformX = "-100%";
        arrowProps.style.right = 3;
        break;
      case "bottom":
        top = rect.bottom;
        transformY = "-100%";
        arrowProps.style.bottom = 3;

        break;
      case "left":
        ({ left } = rect);
        arrowProps.style.left = 3;
        break;
      case "center":
        if (parts[0] === "top" || parts[0] === "bottom") {
          left = rect.left + rect.width / 2;
          transformX = "-50%";
          arrowProps.style.left = "50%";
          arrowProps.style.transform = "translate(-50%, 0)";
        } else {
          top = rect.top + rect.height / 2;
          transformY = "-50%";
          arrowProps.style.top = "50%";
          arrowProps.style.transform = "translate(0, -50%)";
        }
        break;
      default:
        console.error(`${placement} is not a valid placement`);
    }

    return {
      top: typeof top === "number" ? top - yoffset : top,
      right: typeof right === "number" ? right + xoffset : right,
      bottom: typeof bottom === "number" ? bottom + yoffset : bottom,
      left: typeof left === "number" ? left + xoffset : left,
      transform: `translate(${transformX}, ${transformY})`,
      arrowProps,
      tooltipPlacement,
      exitOnBackgroundClick,
      onBackgroundClick: close,
    };
  }, [placement, overlay.type, rect, yoffset, xoffset, exitOnBackgroundClick, close]);

  return (
    <RefHolder ref={ref}>
      {React.cloneElement(child, triggerProps)}
      {rect && (
        <OverlayContainer {...overlayProps}>
          {React.cloneElement(overlay, {
            arrowProps,
            placement: tooltipPlacement,
          })}
        </OverlayContainer>
      )}
    </RefHolder>
  );
};

OverlayTrigger.PLACEMENTS = PLACEMENTS;
OverlayTrigger.TRIGGERS = TRIGGERS;
