import React, { useState, useCallback } from "react";

import { Card, Spinner } from "../../Components";
import { Button, Form, InputGroup } from "react-bootstrap";
import { LinearBuyingLambdaFetch } from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

const LogUpdates: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError, setModalMessage, setShowModalConfirmation } = utils;

  const [reconcileCompany, setReconcileCompany] = useState("");
  const [reconcileNetwork, setReconcileNetwork] = useState("");
  const [reconcileWeek, setReconcileWeek] = useState("");

  const redoReconcile = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await LinearBuyingLambdaFetch("/resolveSpotsWithReset", {
        method: "POST",
        body: JSON.stringify({
          week: reconcileWeek,
          network: reconcileNetwork,
          company: reconcileCompany,
        }),
      });
      setModalMessage(`Re-reconciled ${reconcileCompany}, ${reconcileNetwork}, ${reconcileWeek}`);
      setShowModalConfirmation(true);
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setActiveAPICall(false);
  }, [
    reconcileWeek,
    reconcileNetwork,
    reconcileCompany,
    setModalMessage,
    setShowModalConfirmation,
    setError,
  ]);

  const addHTSSpend = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await LinearBuyingLambdaFetch("/update_hts_costs", {
        method: "POST",
        body: {},
      });
      setModalMessage("HTS Costs Updated");
      setShowModalConfirmation(true);
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setActiveAPICall(false);
  }, [setError, setModalMessage, setShowModalConfirmation]);

  const addVWSESpend = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await LinearBuyingLambdaFetch("/update_vwse_costs", {
        method: "POST",
        body: {},
      });
      setModalMessage("VWSE Costs Updated");
      setShowModalConfirmation(true);
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setActiveAPICall(false);
  }, [setError, setModalMessage, setShowModalConfirmation]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Log Updates</div>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={reconcileCompany}
          onChange={e => setReconcileCompany(e.target.value)}
        />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Network</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={reconcileNetwork}
          onChange={e => setReconcileNetwork(e.target.value)}
        />
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Week</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={reconcileWeek} onChange={e => setReconcileWeek(e.target.value)} />
      </InputGroup>
      <div className="adOpsElem">
        <Button className="adOpsElem" onClick={redoReconcile}>
          {activeAPICall ? <Spinner /> : "Redo Logs Reconcile"}
        </Button>
      </div>
      <div className="adOpsElem">
        <Button className="adOpsElem" onClick={addHTSSpend}>
          {activeAPICall ? <Spinner /> : "Add HTS Spend"}
        </Button>
      </div>
      <div className="adOpsElem">
        <Button className="adOpsElem" onClick={addVWSESpend}>
          {activeAPICall ? <Spinner /> : "Add VWSE Spend"}
        </Button>
      </div>
    </Card>
  );
};

export default LogUpdates;
