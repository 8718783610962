import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { XAxis, YAxis, Tooltip, CartesianGrid, Area, AreaChart, BarChart, Bar } from "recharts";
import {
  Skeleton,
  PathSkeleton,
  BPMTable,
  DropdownToggleType,
  Dropdown,
  IconToggleButton,
  DownloadDropdown,
} from "../Components";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";
import { abbreviateNumber } from "../utils/data";
import ChartContainer from "../Components/ChartContainer";
import LinkSelector from "./LinkSelector";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  X_AXIS_PADDING,
  DateGroupingKey,
  DATE_GROUPING_OPTIONS,
} from "./homePageConstants";
import { getSeriesColor } from "../utils/colors";
import MarketingPortfolioLegend from "./MarketingPortfolioLegend";
import { formatNumberAsInt } from "../utils/format-utils";
import {
  formatDateLabel,
  getDateGroupingOptions,
  getGroupingValue,
  RELATIVE_FORMATTER,
  VOLUME_FORMATTER,
} from "./homePageUtils";
import ChartTooltip from "./ChartTooltip";
import "./MarketingPortfolio.scss";

interface MarketingPortfolioProps {
  company: string;
  start: string;
  end: string;
  kpi: string;
}

interface GradientRange {
  start: number;
  end: number;
  active: boolean;
}

export interface Channel {
  channel: string;
  color: string;
}

const CHART_VIEW_OPTIONS = [{ value: "Absolute" }, { value: "Relative" }];
const X_AXIS_DOMAIN = ["dataMin", "dataMax"];
const CHART_TYPE_OPTIONS = [{ value: "Area Graph" }, { value: "Stacked Bar Graph" }];

const groupDataByDate = (data: any, groupingKey: DateGroupingKey): any => {
  if (!data) {
    return [];
  }

  // By default, the data is on the day level, so don't do anything to it.
  if (groupingKey === "Day") {
    return data;
  }

  const keys = Object.keys(data[0] || {}).filter(key => key !== "date");

  let grouped: Record<string, Record<string, any>> = {};
  for (const item of data) {
    const groupingValue = getGroupingValue(item.date, groupingKey);

    let prev = { ...(grouped[groupingValue] || {}) };

    let incremented: Record<string, any> = {};

    for (let key of keys) {
      if (prev.hasOwnProperty(key)) {
        incremented[key] = prev[key] + item[key];
      } else {
        incremented[key] = item[key];
      }
    }

    grouped[groupingValue] = incremented;
  }
  return Object.entries(grouped).map(([date, totals]) => ({
    date,
    ...totals,
  })) as any;
};

const processGraphData = (data: any, chartView: string, groupingKey: DateGroupingKey) => {
  if (!data) {
    return [];
  }

  // Group data by selected date interval (Day, Week, Month, etc.)
  const grouped = groupDataByDate(data, groupingKey);

  // If applicable, convert data to relative percentages.
  // But only if looking at Spend view because it doesn't make sense with CPM.
  if (chartView === "Relative") {
    let relativeSpend: any[] = [];
    for (let item of grouped) {
      const { date, ...rest } = item;
      const total = R.sum(R.values(rest));
      let converted: Record<string, number> = {};
      for (let channel of R.keys(rest)) {
        converted[channel] = Math.round((1000 * item[channel]) / total) / 10;
      }
      relativeSpend.push({ date, ...converted });
    }
    return relativeSpend;
  }

  return grouped;
};

const processTableData = (data: any[], groupingKey: DateGroupingKey): any[] => {
  if (!data) {
    return [];
  }
  let groupedDataByDate: Record<string, Record<string, number>> = {};
  for (let row of data) {
    const channels = R.keys(row).filter(key => key !== "date");
    const dateToUse = getGroupingValue(row.date, groupingKey as DateGroupingKey);
    for (let channel of channels) {
      const value = row[channel];

      groupedDataByDate = R.mergeDeepRight(groupedDataByDate, {
        [dateToUse]: {
          [channel]: R.pathOr(0, [dateToUse, channel], groupedDataByDate) + (value || 0),
        },
      });
    }
  }

  return Object.entries(groupedDataByDate).map(([date, totals]) => ({ date, ...totals }));
};

const MarketingPortfolio: React.FC<MarketingPortfolioProps> = ({ company, start, end, kpi }) => {
  const [dataMap, setDataMap] = useState({});
  const [chartView, setChartView] = useState(CHART_VIEW_OPTIONS[0].value);
  const [chartType, setChartType] = useState(CHART_TYPE_OPTIONS[0].value);
  const [selectedToggleOption, setSelectedToggleOption] = useState<string>("showGraph");
  const [groupingKey, setGroupingKey] = useState(DATE_GROUPING_OPTIONS[1].value);
  const [channelClassification, setChannelClassification] = useState({});
  const dataKey = `${start}_${end}_${kpi}`;

  const linkMetadata = {
    title: "delivery page",
    links: [
      { name: "Streaming", href: `/${company}/streaming/delivery` },
      { name: "Audio", href: `/${company}/audio/delivery` },
      { name: "Linear", href: `/${company}/linear/delivery` },
      { name: "Display", href: `/${company}/display/delivery` },
    ],
  };

  useEffect(() => {
    (async () => {
      const res = await ToolsLambdaFetch("/getMarketingPortfolio", {
        params: { company, start, end, kpi },
      });
      const { channelSpend, channelClassification } = await awaitJSON(res);
      setDataMap(current => ({ ...current, [dataKey]: channelSpend }));
      setChannelClassification(channelClassification);
    })();
  }, [company, end, start, kpi, dataKey]);

  const data = dataMap[dataKey];

  const processedGraphData = useMemo(() => processGraphData(data, chartView, groupingKey), [
    data,
    chartView,
    groupingKey,
  ]);

  const processedTableData = useMemo(() => processTableData(data, groupingKey), [
    data,
    groupingKey,
  ]);

  const channels = useMemo(() => {
    let marketingPortfolioChannels: Channel[] = [];
    if (data && data.length) {
      const keys = R.keys(data[0]);
      for (let i = 0; i < keys.length; i++) {
        const value = keys[i];
        if (value !== "date" && value !== "Interpolated") {
          marketingPortfolioChannels.push({ channel: value, color: getSeriesColor(i) });
        }
      }
    }
    return marketingPortfolioChannels;
  }, [data]);

  const gradientRanges = useMemo(() => {
    let marketingPortfolioGradientRanges: GradientRange[] = [];
    if (data && !R.isEmpty(data)) {
      let globalCount = 0;
      let thisCount = 0;
      let active = data[0].Interpolated;
      let arrayLength = data.length;
      for (let date of data) {
        if (date.Interpolated === active) {
          thisCount++;
        } else {
          marketingPortfolioGradientRanges.push({
            start: (globalCount / arrayLength) * 100,
            end: ((globalCount + thisCount) / arrayLength) * 100,
            active,
          });
          active = date.Interpolated;
          globalCount += thisCount;
          thisCount = 1;
        }
      }
      marketingPortfolioGradientRanges.push({
        start: (globalCount / arrayLength) * 100,
        end: ((globalCount + thisCount) / arrayLength) * 100,
        active,
      });
    }
    return marketingPortfolioGradientRanges;
  }, [data]);

  const headers = useMemo(() => {
    if (!processedTableData || R.isEmpty(processedTableData)) {
      return [];
    }
    let headers: any[] = [
      {
        label: "Date",
        name: "date",
        nonInteractive: true,
        renderer: item => formatDateLabel(item.date, groupingKey),
      },
    ];
    for (let key of R.keys(processedTableData[0])) {
      if (key !== "date" && key !== "Interpolated") {
        // For each media type, add a super header plus a header for Spend and CPM.
        headers.push(
          ...[
            {
              label: key,
              name: key,
              nonInteractive: true,
              flex: 1,
              renderer: item => formatNumberAsInt(item[key]),
            },
          ]
        );
      }
    }

    return headers;
  }, [processedTableData, groupingKey]);

  const headersRenderer = useCallback(
    ({ data }) => {
      const styles: any = {};
      if (channelClassification[data] === "Impressions") {
        styles.fontStyle = "italic";
      }
      return <div style={styles}>{data}</div>;
    },
    [channelClassification]
  );

  const totals = useMemo(() => {
    if (!processedTableData || R.isEmpty(processedTableData)) {
      return {};
    }
    let totals = processedTableData.reduce((acc, row) => {
      for (let key of R.keys(row)) {
        if (key !== "date") {
          acc[key] = (acc[key] || 0) + row[key];
        }
      }
      return acc;
    }, {});

    return {
      date: "Totals",
      ...totals,
    };
  }, [processedTableData]);

  const totalsRenderer = ({ data, style = {}, classes = [] as string[] }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {formatNumberAsInt(data)}
      </div>
    );
  };

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_MarketingPortfolio.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet(data);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, data]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".marketingPortfolioChart");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_MarketingPortfolio.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Marketing Portfolio"
      leftActions={<LinkSelector title={linkMetadata.title} links={linkMetadata.links} />}
      rightActions={
        <>
          {selectedToggleOption === "showGraph" && (
            <>
              <Dropdown
                type={DropdownToggleType.FILLED}
                design="secondary"
                size="sm"
                value={chartType}
                options={CHART_TYPE_OPTIONS}
                onChange={option => setChartType(option)}
              />
              <Dropdown
                type={DropdownToggleType.FILLED}
                design="secondary"
                size="sm"
                value={chartView}
                options={CHART_VIEW_OPTIONS}
                onChange={option => setChartView(option)}
              />
            </>
          )}
          <IconToggleButton
            size="sm"
            options={[
              { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
              { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
            ]}
            selectedOption={selectedToggleOption}
            onChange={setSelectedToggleOption}
          />
          <DownloadDropdown
            size="sm"
            onClickOptions={[exportToExcel, downloadPNG]}
            disabledMenuOptions={
              selectedToggleOption === "showGraph"
                ? { XLSX: false, PNG: false }
                : { XLSX: false, PNG: true }
            }
            disabled={!data}
          />
        </>
      }
    >
      {data ? (
        R.isEmpty(data) ? (
          <div className="marketingPortfolioNoData">No marketing portfolio data</div>
        ) : selectedToggleOption === "showTable" ? (
          <BPMTable
            data={processedTableData}
            headers={headers}
            headersRenderer={headersRenderer}
            filterBar={false}
            totals={totals}
            totalsRenderer={totalsRenderer}
          />
        ) : (
          <div className="marketingPortfolio">
            <div className="aboveChart">Spend/Impressions</div>
            <div className="chartContents">
              <div id="marketingPortfolioChart" className="marketingPortfolioChart">
                <AutoSizer>
                  {({ width, height }) =>
                    chartType === "Area Graph" ? (
                      <AreaChart
                        width={width}
                        height={height}
                        data={processedGraphData}
                        stackOffset={chartView === "Relative" ? "expand" : "none"}
                      >
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <defs>
                          {channels.map((channelInfo, i) => {
                            const { channel, color } = channelInfo;
                            return (
                              <React.Fragment key={channel}>
                                <linearGradient
                                  id={`marketingPortfolioFillGradient_${channel}`}
                                  x1="0%"
                                  x2="100%"
                                  y1="0%"
                                  y2="0%"
                                >
                                  {gradientRanges.map(range => {
                                    const { start, end, active } = range;
                                    let opacity = active ? 0.5 : 1;
                                    return [
                                      <stop
                                        key={`${start}_${end}_start`}
                                        offset={`${start}%`}
                                        stopColor={color}
                                        stopOpacity={opacity}
                                      />,
                                      <stop
                                        key={`${start}_${end}_end`}
                                        offset={`${end}%`}
                                        stopColor={color}
                                        stopOpacity={opacity}
                                      />,
                                    ];
                                  })}
                                </linearGradient>
                              </React.Fragment>
                            );
                          })}
                        </defs>
                        {channels.map((channelInfo, i) => {
                          const { channel, color } = channelInfo;
                          return (
                            <Area
                              type="monotone"
                              dataKey={channel}
                              stackId="1"
                              key={i}
                              activeDot={false}
                              fillOpacity={1}
                              strokeWidth={0}
                              fill={color}
                              isAnimationActive={false}
                              stroke={color}
                            />
                          );
                        })}
                        <XAxis
                          dataKey="date"
                          domain={X_AXIS_DOMAIN}
                          padding={X_AXIS_PADDING}
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number * 100)}%`
                              : `${abbreviateNumber(number)}`
                          }
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: item.value as number,
                                color: item.stroke,
                              })) || []
                            );
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                items={items}
                                itemFormatter={
                                  chartView === "Relative" ? RELATIVE_FORMATTER : VOLUME_FORMATTER
                                }
                                footer="Italicized channels are shown in 100's of impressions. All other channels are shown in spend
                              dollars."
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                      </AreaChart>
                    ) : (
                      <BarChart
                        width={width}
                        height={height}
                        data={processedGraphData}
                        stackOffset={chartView === "Relative" ? "expand" : "none"}
                      >
                        <CartesianGrid
                          vertical={false}
                          stroke={CARTESIAN_GRID_STROKE}
                          strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                        />
                        <Tooltip
                          content={({ label, payload }) => {
                            const formattedHeaderLabel = formatDateLabel(label, groupingKey, true);
                            let items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: item.value as number,
                                color: item.stroke,
                              })) || []
                            );
                            return (
                              <ChartTooltip
                                headerLabel={formattedHeaderLabel}
                                subHeaderLabel="Total"
                                subHeaderValueFormatter={
                                  chartView === "Relative" ? RELATIVE_FORMATTER : VOLUME_FORMATTER
                                }
                                items={items}
                                itemFormatter={
                                  chartView === "Relative" ? RELATIVE_FORMATTER : VOLUME_FORMATTER
                                }
                                footer="Italicized channels are shown in 100's of impressions. All other channels are shown in spend
                              dollars."
                              />
                            );
                          }}
                          isAnimationActive={false}
                        />
                        <XAxis
                          dataKey="date"
                          domain={X_AXIS_DOMAIN}
                          padding={X_AXIS_PADDING}
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={date => formatDateLabel(date, groupingKey)}
                        />
                        <YAxis
                          tickLine={false}
                          tick={TICK_STYLE}
                          tickFormatter={number =>
                            chartView === "Relative"
                              ? `${abbreviateNumber(number * 100)}%`
                              : `${abbreviateNumber(number)}`
                          }
                        />

                        {channels.map((channelInfo, i) => {
                          const { channel, color } = channelInfo;
                          return (
                            <Bar
                              dataKey={channel}
                              key={i}
                              fill={color}
                              stroke={color}
                              stackId={"a"}
                            />
                          );
                        })}
                      </BarChart>
                    )
                  }
                </AutoSizer>
              </div>
              <div className="rightOfChart">
                <MarketingPortfolioLegend
                  channels={channels}
                  channelClassification={channelClassification}
                />
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  design="secondary"
                  size="sm"
                  value={groupingKey}
                  options={getDateGroupingOptions(start, end)}
                  onChange={option => setGroupingKey(option as DateGroupingKey)}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <Skeleton>
          <PathSkeleton
            points={[
              [0, 0.3],
              [0.25, 0.7],
              [0.5, 0.2],
              [0.75, 0.9],
              [1, 0.5],
            ]}
          />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default MarketingPortfolio;
