import "./ToggleSwitchWithIcon.scss";
import React from "react";
import { MdOutlineToggleOff, MdOutlineToggleOn } from "react-icons/md";

interface ToggleSwitchWithIconProps {
  checked: boolean;
  leftLabel?: string | React.ReactNode;
  onChange: (checked: boolean) => void;
  rightLabel?: string | React.ReactNode;
  rightIcon?: boolean;
  toggleSize?: number;
}

const ToggleSwitchWithIcon: React.FC<ToggleSwitchWithIconProps> = ({
  rightLabel,
  rightIcon,
  leftLabel,
  checked,
  onChange,
  toggleSize = 20,
}) => {
  return (
    <div className="toggleWrapper" onClick={() => onChange(!checked)}>
      <div className="leftLabel">{leftLabel}</div>
      {!checked ? (
        <MdOutlineToggleOff className="toggleIcon off" size={toggleSize} />
      ) : (
        <div className="toggleOnWrap">
          <div className="toggleBorderHide"></div>
          <MdOutlineToggleOn className="toggleIcon on" size={toggleSize} />
        </div>
      )}
      <div className={`rightLabel ${rightIcon ? "icon" : ""}`}>{rightLabel}</div>
    </div>
  );
};

export default ToggleSwitchWithIcon;
