import { engineeringUsers } from "./experiment-utils";

export const shouldEnableMobiusSlides = (user: string): boolean => {
  const mobiusSlidesUsers: string[] = [
    "lauren.wolfen@tinuiti.com",
    "melissa.wang@tinuiti.com",
    "dean.lee@tinuiti.com",
    "michael.ward@tinuiti.com",
    "melissa.wang@tinuiti.com",
    "dean.lee@tinuiti.com",
    // Add users here!
  ].concat(engineeringUsers);
  return mobiusSlidesUsers.includes(user);
};

const MOBIUS_COMPANIES = [
  "aeropostale",
  "ancestry",
  "appfolio",
  "aviron",
  "away",
  "beautycounter",
  "beyondproper",
  "big5",
  "birdies",
  "birkenstock",
  "blueair",
  "bonobos",
  "bonterra",
  "brooksbrothers",
  "brooksrunning",
  "californiaclosets",
  "condenast",
  "converse",
  "dickies",
  "dishsling",
  "dollartree",
  "eddiebauer",
  "ehealth",
  "elf",
  "eloquii",
  "equifax",
  "ethanallen",
  "etsy",
  "figure",
  "fleurdumal",
  "frye",
  "gap",
  "hansons",
  "honest",
  "horizon",
  "joann",
  "katesomerville",
  "keyssoulcare",
  "leafguard",
  "leejeans",
  "liquiddeath",
  "liquidiv",
  "lucky",
  "m1finance",
  "marketingsci",
  "mathnasium",
  "maurices",
  "meyer",
  "nautica",
  "northerntrust",
  "olly",
  "onnit",
  "pacsun",
  "partstown",
  "poppi",
  "redbubble",
  "reebok",
  "revlon",
  "samsung",
  "scentair",
  "scojo",
  "shopbop",
  "singer_raw",
  "smartypants",
  "solarwinds",
  "sony",
  "sylvan",
  "tatcha",
  "thinx",
  "tiaa",
  "tinuiti",
  "truewerk",
  "trugreen",
  "truist",
  "welly",
  "wildplanet",
  "wrangler",
  "yohana",
];

export const shouldEnableMobiusSlidesCompany = (company: string): boolean => {
  return MOBIUS_COMPANIES.includes(company);
};
