import React, { useMemo, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";

import * as Colors from "../../utils/colors";
import "../Dashboard.scss";

const PRETTY_DATE_FORMAT = "MMM-dd";
const DATE_FORMAT = "yyyy-MM-dd";

const DashboardStackedSparkChart = ({ chartData }) => {
  const reformattedData = useMemo(() => {
    if (!R.pipe(R.prop("data"), R.length)(chartData)) {
      return null;
    }

    let transformed = R.map(origData => {
      return R.mergeRight(
        { week: origData.week },
        R.fromPairs(
          R.map(key => {
            const value =
              chartData.series && origData.values ? origData.values[key] : origData.value;
            return [key, Number.isFinite(value) && value > 0 ? value : null];
          }, R.defaultTo(["TV"], R.concat(chartData.series, R.isNil(chartData.altSeriesBar) || !chartData.altSeriesBar ? [] : chartData.altSeriesBar)))
        )
      );
    }, chartData.data);
    return R.sortBy(R.prop("week"), transformed);
  }, [chartData]);

  const tooltipFormatter = useCallback(
    (value, name) => {
      let formatType = "";
      if (chartData.series && R.contains(name, chartData.series)) {
        formatType = chartData.type;
      }
      if (chartData.altSeriesBar && R.contains(name, chartData.altSeriesBar)) {
        formatType = chartData.altType;
      }

      if (formatType === "$") {
        return `$${value.toLocaleString("en-US", {
          maximumFractionDigits: value > 100 ? 0 : 2,
        })}`;
      } else if (formatType === "%") {
        value = value * 100;
        return `${value.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })}%`;
      } else {
        return value.toLocaleString("en-US", {
          maximumFractionDigits: value > 100 ? 0 : 2,
        });
      }
    },
    [chartData]
  );

  const labelFormatter = useCallback(label => chartData.data[label].week, [chartData]);

  if (!chartData || !chartData.data || chartData.data.length === 0) {
    return null;
  }

  const numCharts =
    chartData.series.length + (chartData.altSeriesBar ? chartData.altSeriesBar.length : 0);

  return (
    reformattedData && (
      <div className="dashboardWidget" style={{ paddingTop: "80px", paddingBottom: "50px" }}>
        <div className="sparkChartInnerBox">
          <AutoSizer>
            {({ width, height }) => (
              <div>
                {R.map(
                  series => (
                    <LineChart
                      key={series}
                      height={height / numCharts}
                      width={width}
                      data={reformattedData}
                    >
                      <Tooltip
                        cursor={{ strokeWidth: 2 }}
                        isAnimationActive={false}
                        formatter={tooltipFormatter}
                        labelFormatter={labelFormatter}
                      />
                      <YAxis
                        tickFormatter={tick => `${(100 * tick).toLocaleString()}%`}
                        tick={{ fontSize: 10 }}
                        domain={[0, 1]}
                      />
                      <Line
                        type="monotone"
                        strokeWidth={2.5}
                        dataKey={series}
                        key={series}
                        name={series}
                        dot={false}
                        stroke={
                          R.indexOf(series, chartData.series) === 0
                            ? Colors.primary
                            : Colors.secondary
                        }
                      />
                    </LineChart>
                  ),
                  chartData.series
                )}
                {chartData.altSeriesBar && (
                  <BarChart height={height / numCharts} width={width} data={reformattedData}>
                    <Tooltip
                      cursor={{ strokeWidth: 2 }}
                      isAnimationActive={false}
                      formatter={tooltipFormatter}
                    />
                    <XAxis
                      dataKey="week"
                      tickFormatter={tick =>
                        R.pipe(
                          Dfns.parse(new Date(), DATE_FORMAT),
                          Dfns.format(PRETTY_DATE_FORMAT)
                        )(tick)
                      }
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis
                      tickFormatter={tick => `$${tick.toLocaleString()}`}
                      tick={{ fontSize: 10 }}
                    />
                    <Bar
                      type="monotone"
                      dataKey={chartData.altSeriesBar[0]}
                      key={chartData.altSeriesBar[0]}
                      name={chartData.altSeriesBar[0]}
                      stroke={Colors.tertiary}
                      fill={Colors.tertiary}
                    />
                  </BarChart>
                )}
                <LineChart height={50} width={width} data={reformattedData}>
                  <Line
                    type="monotone"
                    strokeWidth={0}
                    dataKey={chartData.series[0]}
                    key={chartData.series[0]}
                    name={chartData.series[0]}
                    dot={false}
                    stroke={Colors.primary}
                  />
                  <Line
                    type="monotone"
                    strokeWidth={0}
                    dataKey={chartData.series[1]}
                    key={chartData.series[1]}
                    name={chartData.series[1]}
                    dot={false}
                    stroke={Colors.secondary}
                  />
                  <Line
                    type="monotone"
                    strokeWidth={0}
                    dataKey={chartData.altSeriesBar[0]}
                    key={chartData.altSeriesBar[0]}
                    name={chartData.altSeriesBar[0]}
                    dot={false}
                    stroke={Colors.tertiary}
                  />
                  <Legend verticalAlign="bottom" height={36} />
                </LineChart>
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  );
};

export default DashboardStackedSparkChart;
