import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import cn from "classnames";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import { useRenderedPosition } from "../../utils/hooks/useDOMHelpers";
import { DateRange } from "../../utils/types";
import { DATE_FORMAT, makeMemoizedGetter } from "../../utils/data";

import { ReactComponent as CalendarIcon } from "./Calendar.svg";

import { BPMButton } from "../BPMButton/BPMButton";
import "./BPMDate.scss";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "../OverlayTrigger";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

const DEFAULT_MIN_YEAR = 2017 as const;
const DEFAULT_MAX_YEAR = new Date().getFullYear() + 2;

// Gotten from inspecting the date picker. Note: the height is the maximum height for when the
// calendar has 6 rows.
const CALENDAR_WIDTH = 537;
const CALENDAR_HEIGHT = 303;

const CALENDAR_SPACE = 6;

const FOCUS_OPTIONS = ["START", "END"] as const;

const PRETTY_DATE_FORMAT = "M/d/yy";

// TODO: memoize?
const format = Dfns.format(DATE_FORMAT);

interface DateInfo {
  date: string;
  display: string;
  span?: boolean;
  selectedLeft?: boolean;
  selectedRight?: boolean;
  lastWasFirst?: boolean;
  nextIsLast?: boolean;
  invalid?: boolean;
}
interface MonthInfo {
  year: number;
  date: Date;
  dates: (DateInfo | null)[];
}

const getMonthInfo = makeMemoizedGetter({
  calculate: (parsedDate: Date): MonthInfo => {
    const startOfMonth = Dfns.startOfMonth(parsedDate);
    const endOfMonth = Dfns.endOfMonth(parsedDate);
    const dowStart = Dfns.getDay(startOfMonth);
    const dowEnd = Dfns.getDay(endOfMonth);
    const dates = Dfns.eachDayOfInterval({
      start: startOfMonth,
      end: endOfMonth,
    }).map(rawDate => {
      const date = format(rawDate);
      return { date, display: Dfns.format("d", rawDate) };
    });
    // Fill in days from other months with nulls
    dates.unshift(...new Array(dowStart).fill(null));
    dates.push(...new Array(6 - dowEnd).fill(null));
    return {
      year: parseInt(Dfns.format("yyyy", parsedDate)) || 2020,
      dates,
      date: startOfMonth,
    };
  },
  makeKey: Dfns.format("yyyy-MM"),
});

const makePrettyDate = (date?: string) =>
  date ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(date) : "___";

interface BPMDateRangeProps {
  range?: DateRange;
  onChange: (newDates: DateRange) => void;
  minYear?: number;
  maxYear?: number;
  isOutsideRange?: (date: string) => boolean;
  isDayBlocked?: (date: string) => boolean;
  bordered?: boolean;
  currentMonthSecond?: boolean;
  fullWeeksOnly?: boolean; // Used for linear pages in which we only allow selection of entire weeks starting on Mondays.
  maxDate?: string; // Used for brand health page to explain why certain dates are greyed out.
  // [passthroughProp: string]: any;
}

export const BPMDateRange: React.FC<BPMDateRangeProps> = ({
  range,
  onChange,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  isOutsideRange = () => false,
  isDayBlocked = () => false,
  bordered,
  currentMonthSecond,
  fullWeeksOnly,
  maxDate,
}) => {
  // Years for the year picker
  const years = useMemo(() => R.range(minYear, maxYear + 1), [minYear, maxYear]);

  const { start, end } = range || {};

  const ref = useRef<HTMLDivElement>(null);

  const [position, resetPosition] = useRenderedPosition(ref);

  const resolvedPosition = useMemo(() => {
    // Start off anchored below the input aligned on the right
    let top = position.bottom + CALENDAR_SPACE;
    let left = position.right - CALENDAR_WIDTH;
    // If it spills off the bottom of the page, open up
    if (top + CALENDAR_HEIGHT > window.innerHeight) {
      top = position.top - CALENDAR_HEIGHT - CALENDAR_SPACE;
    }
    // if it spill over the left side of the screen, anchor left
    if (left < 0) {
      ({ left } = position);
    }
    return { top, left };
  }, [position]);

  const [show, setShowRaw] = useState(false);

  const [dateInputs, setDateInputs] = useState<Partial<DateRange>>({
    start,
    end,
  });
  const [focus, setFocus] = useState<typeof FOCUS_OPTIONS[number]>("START");

  const setShow = useCallback(
    (show: boolean, save?: boolean) => {
      resetPosition();
      setShowRaw(show);
      if (!show && dateInputs.start && dateInputs.end) {
        setFocus("START");
        setDateInputs({ start, end });
        if (save && (dateInputs.start !== start || dateInputs.end !== end)) {
          onChange(dateInputs as DateRange);
        }
      }
    },
    [onChange, dateInputs, resetPosition, start, end]
  );

  useEffect(() => {
    setDateInputs(dateInputs => {
      let newDateInputs = { ...dateInputs };
      let changed = false;
      if (dateInputs.start !== start) {
        newDateInputs.start = start;
        changed = true;
      }
      if (dateInputs.end !== end) {
        newDateInputs.end = end;
        changed = true;
      }
      if (changed) {
        return newDateInputs;
      }
      return dateInputs;
    });
  }, [start, end]);

  const onChangeInternal = useCallback(
    (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => {
      setDateInputs(dateRange);
      setFocus(focus);
    },
    []
  );

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [setShow]);

  const dateRangeDisplay = useMemo(
    () => (
      <>
        <span className="singleDate">{`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(
          start
        )}`}</span>{" "}
        –{" "}
        <span className="singleDate" onClick={() => setFocus("END")}>
          {`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(end)}`}
        </span>
      </>
    ),
    [start, end, fullWeeksOnly]
  );

  const className = useMemo(() => {
    let classes = ["BPMDate"];
    if (bordered) {
      classes.push("bordered");
    }
    if (fullWeeksOnly) {
      classes.push("fullWeeksOnly");
    }
    return classes.join(" ");
  }, [bordered, fullWeeksOnly]);
  return (
    <>
      <div ref={ref} className={className} onClick={() => setShow(true)}>
        <div className="icon">
          <CalendarIcon />
        </div>
        <div className="dateRange">{dateRangeDisplay}</div>
      </div>
      {show &&
        ReactDOM.createPortal(
          <div className="BPMDateContainer">
            <div className="overlay" onClick={() => setShow(false)} />
            <div className="calendarBox" style={resolvedPosition}>
              <BPMCalendar
                start={dateInputs.start}
                end={dateInputs.end}
                focus={focus}
                onChange={onChangeInternal}
                isOutsideRange={isOutsideRange}
                isDayBlocked={isDayBlocked}
                years={years}
                currentMonthSecond={currentMonthSecond}
                maxDate={maxDate}
              />
              <div className="controls">
                <div className="subControls">
                  <div className="dateDisplay">
                    <span
                      className={cn("singleDate", { focused: focus === "START" })}
                      onClick={() => setFocus("START")}
                    >
                      {makePrettyDate(dateInputs.start)}
                    </span>{" "}
                    –{" "}
                    <span
                      className={cn("singleDate", { focused: focus === "END" })}
                      onClick={() => setFocus("END")}
                    >
                      {makePrettyDate(dateInputs.end)}
                    </span>
                  </div>
                  <BPMButton
                    size="sm"
                    variant="outline-primary"
                    onClick={() => setDateInputs(range || {})}
                  >
                    Reset
                  </BPMButton>
                </div>
                <div className="subControls">
                  <BPMButton size="sm" variant="outline-primary" onClick={() => setShow(false)}>
                    Cancel
                  </BPMButton>
                  <BPMButton size="sm" onClick={() => setShow(false, true)}>
                    Save
                  </BPMButton>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

interface BPMCalendarProps {
  start?: string;
  end?: string;
  focus?: typeof FOCUS_OPTIONS[number];
  onChange: (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => void;
  isOutsideRange: (date: string) => boolean;
  isDayBlocked: (date: string) => boolean;
  years: number[];
  currentMonthSecond?: boolean;
  maxDate?: string;
}

const BPMCalendar: React.FC<BPMCalendarProps> = ({
  start,
  end,
  focus,
  onChange,
  isOutsideRange,
  isDayBlocked,
  years,
  currentMonthSecond,
  maxDate,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(start);
  const monthInfo = useMemo(() => {
    let parsedStart = Dfns.parseISO(selectedMonth || TODAY);
    let nextMonth = Dfns.addMonths(1, parsedStart);
    if (currentMonthSecond) {
      nextMonth = parsedStart;
      parsedStart = Dfns.subMonths(1, parsedStart);
    }
    return [getMonthInfo(parsedStart), getMonthInfo(nextMonth)].map((info: MonthInfo) => ({
      ...info,
      dates: info.dates.map(date => {
        if (!date) {
          return date;
        }
        return {
          ...date,
          invalid: isOutsideRange(date.date) || isDayBlocked(date.date),
        };
      }),
    }));
  }, [selectedMonth, isOutsideRange, isDayBlocked, currentMonthSecond]);
  const adjustSelectedMonth = useCallback(
    (adjustment: number, date = Dfns.parseISO(selectedMonth || TODAY)) => {
      setSelectedMonth(R.pipe(Dfns.addMonths(adjustment), format)(date));
    },
    [selectedMonth]
  );
  const onSelect = useCallback(
    (date: string) => {
      if (focus === "END") {
        let newRange: Partial<DateRange> = {
          end: date,
        };
        if (!start || start <= date) {
          newRange.start = start;
        }
        onChange(newRange, "START");
      } else {
        let newRange: Partial<DateRange> = {
          start: date,
        };
        if (!end || end >= date) {
          newRange.end = end;
        }
        onChange(newRange, "END");
      }
    },
    [start, end, focus, onChange]
  );
  return (
    <div className="BPMCalendar">
      {/* TODO: we could probably take a flag and do n months tbh */}
      <IndividualMonth
        monthInfo={monthInfo[0]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(0, month)}
        years={years}
        maxDate={maxDate}
      />
      <IndividualMonth
        monthInfo={monthInfo[1]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(-1, month)}
        years={years}
        maxDate={maxDate}
      />
      <div className="navArrow left" onClick={() => adjustSelectedMonth(-1)}>
        <MdChevronLeft />
      </div>
      <div className="navArrow right" onClick={() => adjustSelectedMonth(1)}>
        <MdChevronRight />
      </div>
    </div>
  );
};

interface IndividualMonthProps {
  monthInfo: MonthInfo;
  onSelect: (date: string) => void;
  range?: Partial<DateRange>;
  focus?: typeof FOCUS_OPTIONS[number];
  setSelectedMonth: (selectedMonth: Date) => void;
  years: number[];
  maxDate?: string;
}

const IndividualMonth: React.FC<IndividualMonthProps> = ({
  monthInfo,
  range,
  onSelect,
  focus,
  setSelectedMonth,
  years,
  maxDate,
}) => {
  const dayGrid = useMemo(() => {
    const rows: (DateInfo | null)[][] = [];
    let row: (DateInfo | null)[] = [];
    let lastWasFirst = false;
    for (let dateInfo of monthInfo.dates) {
      if (dateInfo) {
        let info = { ...dateInfo };
        if (lastWasFirst) {
          // This is only necessary when we're on the same row as the start. Thus, if the row has no
          // length, meaning we're the first element, we don't need to set.
          if (row.length) {
            info.lastWasFirst = true;
          }
          lastWasFirst = false;
        }
        if (range) {
          if (info.date === range.start) {
            info.selectedLeft = true;
            lastWasFirst = true;
          } else if (info.date === range.end) {
            info.selectedRight = true;
            const last = row[row.length - 1];
            if (last) {
              last.nextIsLast = true;
            }
          } else if (range.start && range.end && info.date > range.start && info.date < range.end) {
            info.span = true;
          }
        }
        row.push(info);
      } else {
        row.push(null);
      }

      if (row.length === 7) {
        rows.push(row);
        row = [];
      }
    }
    return rows;
  }, [monthInfo, range]);
  const monthValue = useMemo(() => Dfns.getMonth(monthInfo.date), [monthInfo]);
  const onMonthChange = useCallback(
    (monthStr: string) => {
      // The months are 0-delimited
      const monthNum = parseInt(monthStr) + 1;
      const paddedMonthString = `${monthNum}`.padStart(2, "0");
      setSelectedMonth(Dfns.parseISO(`${monthInfo.year}-${paddedMonthString}-01`));
    },
    [setSelectedMonth, monthInfo]
  );
  const onYearChange = useCallback(
    (year: string) => {
      R.pipe(Dfns.setYear(parseInt(year)), setSelectedMonth)(monthInfo.date);
    },
    [setSelectedMonth, monthInfo]
  );
  return (
    <div className="BPMMonth">
      <div className="monthHeader">
        <select value={monthValue} onChange={e => onMonthChange(e.target.value)}>
          {MONTHS.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select value={monthInfo.year} onChange={e => onYearChange(e.target.value)}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="daysOfWeek">
        <div>Su</div>
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
      </div>
      <div className="dayGrid">
        {dayGrid.map((row, i) => (
          <div key={i}>
            {row.map((day, i) => {
              let classes: string[] = [];
              let display = "";
              let key = `${i}`;
              if (day) {
                ({ display } = day);
                key = day.date;
                if (day.invalid) {
                  classes.push("invalid");
                }
                if (focus) {
                  classes.push(focus === "START" ? "focusStart" : "focusEnd");
                }
                if (day.selectedLeft || day.selectedRight) {
                  classes.push("selected");
                  if (day.selectedLeft) {
                    classes.push("selectedLeft");
                  } else {
                    classes.push("selectedRight");
                  }
                } else if (day.span) {
                  classes.push("span");
                }
                if (day.lastWasFirst) {
                  classes.push("lastWasFirst");
                }
                if (day.nextIsLast) {
                  classes.push("nextIsLast");
                }
              } else {
                classes.push("notCurrentMonth");
              }

              return day && day.invalid && maxDate && day.date > maxDate ? (
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                  overlay={<Tooltip id={day.date}>Data not yet available for these dates.</Tooltip>}
                  key={key}
                >
                  <div
                    key={key}
                    className={classes.join(" ")}
                    onClick={() => day && !day.invalid && onSelect(day.date)}
                  >
                    {display}
                  </div>
                </OverlayTrigger>
              ) : (
                <div
                  key={key}
                  className={classes.join(" ")}
                  onClick={() => day && !day.invalid && onSelect(day.date)}
                >
                  {display}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
