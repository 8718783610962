import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, ButtonType } from "../Components";
import { HiDownload } from "react-icons/hi";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { CSV_FILE_BASE64, EXCEL_FILE_BASE64, formatPloverObj } from "./WtoUtils";
import _ from "lodash";
import * as XLSX from "xlsx";
import "./WtoBulkPloverModal.scss";
import { WtoLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import * as R from "ramda";

export interface WtoBulkPloverModalProps {
  show: boolean;
  closeModal: () => void;
  userEmail: string;
  domains: any;
  companyId: string;
  fetchPlovers: () => void;
}

export const WtoBulkPloverModal: React.FC<WtoBulkPloverModalProps> = ({
  show,
  closeModal,
  userEmail,
  domains,
  companyId,
  fetchPlovers,
}) => {
  const setError = useSetError();
  const [fileType, setFileType] = useState<any>(null);
  const [filePath, setFilePath] = useState<any>(null);
  const [excelFile, setExcelFile] = useState(null);
  const [enableUploadBtn, setEnableUploadBtn] = useState(true);

  const handleFile = (e: any) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setFileType(selectedFile.type);
        setFilePath(e.target.value);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e: any) => {
          setExcelFile(e.target.result);
        };
      } else {
        setError({
          title: "Bulk Plover Upload Error",
          message: "Please select only excel/CSV file types",
        });
        setExcelFile(null);
        setFilePath("");
        setFileType("");
      }
    } else {
      setExcelFile(null);
      setFilePath("");
      setFileType("");
    }
  };

  const getDataRange = (data: any) => {
    const dataWithValues = _.pickBy(data, (value, key) => !!value.v);
    const cellNamesWithValues = _.keys(dataWithValues);
    const cellsWithValues = cellNamesWithValues.map(cell => XLSX.utils.decode_cell(cell));
    const maxRow: any = _.max(cellsWithValues.map(cell => cell.r));
    const maxColumn: any = _.max(cellsWithValues.map(cell => cell.c));
    const lastCellName = XLSX.utils.encode_cell({ c: maxColumn, r: maxRow });
    return `A1:${lastCellName}`;
  };

  const HEADERS = [
    "route_name",
    "plover_url",
    "domain",
    "default_url",
    "folder_path",
    "default_id",
    "utm_exp",
    "exp_name",
    "version/experiment number",
    "Variant Name (Please input each value on a separate line)",
    "Variant URL/Destination URL(Please input each value on a separate line)",
    "How Many Variants in this route?",
  ];

  const validateMissingHeaders = (sheet: any, errors: any) => {
    let missingHeaders: any[] = [];
    HEADERS.forEach((value: any) => {
      if (sheet?.indexOf(value) === -1) {
        missingHeaders.push(value);
      }
    });
    if (missingHeaders?.length !== 0) {
      errors.push(missingHeaders);
    }
  };

  const handleFileSubmit = async (e: any) => {
    try {
      setEnableUploadBtn(false);
      e.preventDefault();
      if (excelFile !== null) {
        let data: any;
        let workbook: any;
        if (fileType === "text/csv") {
          workbook = XLSX.read(excelFile, { type: "buffer", raw: true });
        } else {
          workbook = XLSX.read(excelFile, { type: "buffer" });
        }
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        worksheet["!ref"] = getDataRange(worksheet);
        data = XLSX.utils.sheet_to_json(worksheet, { range: 1, blankrows: false });

        if (fileType === "text/csv" && data?.length > 0) {
          const updatedData = data.map((row: any) => {
            Object.keys(row).forEach(key => {
              if (row[key] === "") {
                delete row[key];
              }
            });
            return row;
          });
          data = updatedData;
        }

        const rowObject: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
          range: 1,
        });
        const headers = rowObject[0];

        let errors: any[] = [];

        validateMissingHeaders(headers, errors);
        if (errors?.length > 0) {
          setError({
            title: "Bulk Plover Upload Error",
            message: `Missing or incorrect headers. Please download the template for reference, Missing or Incorrect header is : ${errors?.toString()}`,
          });
          setExcelFile(null);
          setFilePath("");
          setEnableUploadBtn(true);
          setFileType("");
        } else if (data?.length > 0 && data?.length <= 1000) {
          const { bulkPloverPayload, frontEndValidationsArray } = formatPloverObj(
            data,
            domains,
            companyId,
            userEmail
          );
          if (frontEndValidationsArray.length === 0) {
            await (async () => {
              try {
                const payloadWithStatus = bulkPloverPayload.map(plover => ({
                  ...plover,
                  status: "draft",
                }));
                const bulkPloverReq = await WtoLambdaFetch("/bulkplover", {
                  method: "POST",
                  body: payloadWithStatus,
                  params: {
                    email: userEmail,
                    company: companyId,
                  },
                });
                const bulkPloverResponse = await awaitJSON(bulkPloverReq);

                if (bulkPloverResponse) {
                  fetchPlovers();
                }
                closeModal();
              } catch (e) {
                const error = e as Error;
                setError({
                  message: `Failed to upload bulk plovers. Error: ${error.message}`,
                  reportError: error,
                });
                closeModal();
              }
            })();
          } else {
            setError({
              title: "Bulk Plover Validation Error(s)",
              message: `• ${R.join("\n• ", frontEndValidationsArray)}`,
            });
            setExcelFile(null);
            setFilePath("");
            setEnableUploadBtn(true);
            setFileType("");
            closeModal();
          }
        } else if (data?.length > 1000) {
          setError({
            title: "Bulk Plover Upload Error",
            message:
              "The maximum number of records cannot exceed 1000. Please upload a sheet in a set of 1000 records.",
          });
          setExcelFile(null);
          setFilePath("");
          setEnableUploadBtn(true);
          setFileType("");
          closeModal();
        } else {
          setError({
            title: "Bulk Plover Upload Error",
            message: "No data was found on the sheet.",
          });
          setExcelFile(null);
          setFilePath("");
          setEnableUploadBtn(true);
          setFileType("");
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
      setError({
        title: "Bulk Plover Upload Error",
        message: "Something went wrong while uploading.",
      });
      setExcelFile(null);
      setFilePath("");
      setEnableUploadBtn(true);
      setFileType("");
      closeModal();
    }
  };

  const handleDownload = (fileType: string) => {
    let fileBase64 = fileType === "excel" ? EXCEL_FILE_BASE64 : CSV_FILE_BASE64;
    let sliceSize = 1024;
    let byteCharacters = atob(fileBase64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const link = document.createElement("a");
    const fileName =
      fileType === "excel" ? "bulk-plover-template.xlsx" : "bulk-plover-template.csv";
    link.setAttribute("download", fileName);
    link.href = URL.createObjectURL(new Blob(byteArrays, { type: "application/vnd.ms-excel" }));
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    setEnableUploadBtn(true);
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => (enableUploadBtn ? closeModal() : null)}
      className="wtoBulkPloverModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Bulk Plover Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Don't have the template?
        <div className="downloadTemplateWrapper">
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<HiDownload />}
            onClick={() => handleDownload("excel")}
          >
            Download Excel
          </Button>
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<HiDownload />}
            onClick={() => handleDownload("csv")}
          >
            Download CSV
          </Button>
        </div>
        <input type="file" onChange={handleFile} />
        <p className="helperText">Note: The maximum number of records cannot exceed 1000.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!enableUploadBtn} type={ButtonType.FILLED} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!filePath || !enableUploadBtn}
          type={ButtonType.FILLED}
          onClick={handleFileSubmit}
        >
          {enableUploadBtn ? "Upload" : "Uploading..."}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
