import "./Ebay.scss";
import { awaitJSON, EbayLambdaFetch } from "../utils/fetch-utils";
import {
  BPMButton,
  FullPageSpinner,
  Header,
  ImportModal,
  ModalEditTable,
  Page,
  Spinner,
} from "../Components";
import { DATE_FORMAT } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { downloadJSONToCSV } from "../utils/download-utils";
import {
  EbayAdGroup as EbayAdGroupWOKeywordNegativeKeyword,
  EbayCampaign as EbayCampaignWOAdGroups,
  EbayCurrency,
  EbayFundingModel,
  EbayKeyword,
  EbayMarketplaceId,
  EbayNegativeKeyword,
} from "@blisspointmedia/bpm-types/dist/Ebay";
import { MdDelete } from "react-icons/md";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocation from "../utils/hooks/useLocation";

type EbayEntity = "Campaign" | "Ad Group" | "Keyword" | "Negative Keyword";
type EbayImportAction = "Create" | "Update";
type EbayAdGroupStatus = "ACTIVE" | "PAUSED" | "ARCHIVED";
type EbayKeywordStatus = EbayAdGroupStatus;
type EbayNegativeKeywordStatus = "ACTIVE" | "ARCHIVED";
type EbayAdGroup = EbayAdGroupWOKeywordNegativeKeyword & {
  keywords: Record<string, EbayKeyword>;
  negativeKeywords: Record<string, EbayNegativeKeyword>;
};
type EbayCampaign = EbayCampaignWOAdGroups & { adGroups: Record<string, EbayAdGroup> };

interface ImportDataCSVRow {
  Entity: EbayEntity;
  Action: EbayImportAction;
  "Campaign ID": string;
  "Campaign Name": string;
  "Campaign Budget Daily Amount Value": string;
  "Campaign Budget Daily Amount Currency": EbayCurrency;
  "Start Date": string;
  "End Date": string;
  "Marketplace ID"?: EbayMarketplaceId;
  "Campaign Funding Strategy Funding Model"?: EbayFundingModel;
  "Ad Group Name": string;
  "Ad Group Default Bid Currency": EbayCurrency;
  "AdGroup Default Bid Value": string;
  "Ad Group ID": string;
  "Ad Group Status": EbayAdGroupStatus;
  "Keyword Bid Currency": EbayCurrency;
  "Keyword Bid Value": string;
  "Keyword Text": string;
  "Keyword Match Type": string;
  "Keyword ID": string;
  "Keyword Status": EbayKeywordStatus;
  "Negative Keyword Text": string;
  "Negative Keyword Match Type": string;
  "Negative Keyword ID": string;
  "Negative Keyword Status": EbayNegativeKeywordStatus;
}

const ACCESS_TOKEN_LIFE = 1000 * 45;

const Ebay: React.FC = () => {
  const [accessCode, setAccessCode] = useState<string | undefined>();
  const [accessToken, setAccessToken] = useState("");
  const [accessTokenExp, setAccessTokenExp] = useState<Date>();
  const [currentCampaigns, setCurrentCampaigns] = useState<Record<string, EbayCampaign>>();
  const [file, setFile] = useState<Blob>();
  const [loadingAdGroups, setLoadingAdGroups] = useState<boolean>(false);
  const [loadingAllData, setLoadingAllData] = useState<boolean>(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);
  const [loadingKeywords, setLoadingKeywords] = useState<boolean>(false);
  const [loadingNegativeKeywords, setLoadingNegativeKeywords] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [selectedAdGroupID, setSelectedAdGroupID] = useState<string>();
  const [selectedCampaignID, setSelectedCampaignID] = useState<string>();
  const [showImportChangesModal, setShowImportChangesModal] = useState(false);
  const { company } = useLocation();
  const setError = useSetError();
  const url = window.location.href;

  // checking for access code in the URL
  useEffect(() => {
    (() => {
      try {
        const code = url.includes("code=")
          ? url.slice(url.indexOf("code=") + 5, url.indexOf("&expires"))
          : "";
        setAccessCode(code);
        // figure out how to get rid of the code in the URL so the URL doesn't keep the code in it forever
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get check URL for authorization code for eBay: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [setError, url]);

  useEffect(() => {
    (async () => {
      try {
        if (!accessToken) {
          const params: { company: string; accessCode?: string } = {
            company,
          };
          if (accessCode) {
            params.accessCode = accessCode;
          }
          let eBayResponse = await EbayLambdaFetch("/ebayAuthorization", {
            params,
          });
          let authorization = await awaitJSON<string>(eBayResponse);
          // If this lambda returns a link, there is no refreshToken stored in Secrets Manager,
          // and the user is redirected to login to eBay and consent to Tinuiti accessing their eBay account.
          // Once user has consented, cookie will be used to redirect back to this company's eBay page in our app.
          if (authorization.substring(0, 5) === "https") {
            window.localStorage.setItem("eBay_company", company);
            setRedirectUrl(authorization);
          } else {
            setAccessToken(authorization);
            setAccessTokenExp(new Date(Date.now() + ACCESS_TOKEN_LIFE));
          }
        }
        window.history.pushState({}, "Ebay", "ebay");
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get eBay authorization: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [setError, accessCode, accessToken, company]);

  // Every 45 minutes clear out the access token so that we can trigger an access token refresh.
  useEffect(() => {
    if (accessToken && accessTokenExp && accessTokenExp < new Date(Date.now())) {
      setAccessToken("");
    }
  }, [accessToken, accessTokenExp, setError]);

  const getAuthorization = useCallback(() => {
    (() => {
      try {
        if (redirectUrl && !accessToken) {
          window.location.href = redirectUrl;
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed redirect to eBay: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [accessToken, redirectUrl, setError]);

  const currentAdGroups = useMemo(
    () =>
      currentCampaigns && selectedCampaignID && currentCampaigns[selectedCampaignID]
        ? currentCampaigns[selectedCampaignID].adGroups
        : undefined,

    [currentCampaigns, selectedCampaignID]
  );

  const currentKeywords = useMemo(
    () =>
      currentAdGroups && selectedAdGroupID && currentAdGroups[selectedAdGroupID] && !loadingKeywords
        ? currentAdGroups[selectedAdGroupID].keywords
        : undefined,
    [currentAdGroups, loadingKeywords, selectedAdGroupID]
  );
  const currentNegativeKeywords = useMemo(
    () =>
      currentAdGroups &&
      selectedAdGroupID &&
      currentAdGroups[selectedAdGroupID] &&
      !loadingNegativeKeywords
        ? currentAdGroups[selectedAdGroupID].negativeKeywords
        : undefined,
    [currentAdGroups, loadingNegativeKeywords, selectedAdGroupID]
  );

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && R.isNil(currentCampaigns)) {
          setLoadingCampaigns(true);
          const eBayCampaignResponse = await EbayLambdaFetch("/getEbayCampaigns", {
            params: {
              accessToken,
              company,
            },
          });
          let campaigns = await awaitJSON<EbayCampaign[]>(eBayCampaignResponse);
          let fetchedCampaigns = {};
          for (let campaign of campaigns) {
            fetchedCampaigns[campaign.campaignId] = campaign;
          }
          setCurrentCampaigns(fetchedCampaigns as any);
          setLoadingCampaigns(false);
        }
      } catch (e) {
        setLoadingCampaigns(false);
        const reportError = e as Error;
        setError({
          message: `Failed to get campaigns: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [accessToken, company, currentCampaigns, setError]);

  const deleteCampaign = useCallback(
    async campaignId => {
      setLoadingCampaigns(true);
      try {
        await EbayLambdaFetch("/deleteEbayCampaign", {
          method: "POST",
          body: {
            accessToken,
            campaignId,
            company,
          },
        });
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to delete campaign with ID: ${campaignId} Error: ${reportError.message}`,
          reportError,
        });
      } finally {
        setLoadingCampaigns(false);
        setCurrentCampaigns(undefined);
        setSelectedCampaignID(undefined);
        setSelectedAdGroupID(undefined);
      }
    },
    [accessToken, company, setError]
  );

  const getCampaignAdGroups = useCallback(
    async campaignId => {
      if (currentCampaigns && currentCampaigns[campaignId]) {
        setLoadingAdGroups(true);
        try {
          const eBayAdGroupsRes = await EbayLambdaFetch("/getEbayAdGroups", {
            params: {
              accessToken,
              company,
              campaignId,
            },
          });
          const ebayAdGroupsResponse = await awaitJSON(eBayAdGroupsRes);
          let adGroups = {};
          if (ebayAdGroupsResponse) {
            for (let adGroup of ebayAdGroupsResponse) {
              adGroups[adGroup.adGroupId] = adGroup;
            }
          }
          setCurrentCampaigns(campaigns => {
            if (campaigns) {
              campaigns[campaignId].adGroups = adGroups;
              return campaigns;
            }
          });
          setSelectedCampaignID(campaignId);
          setLoadingAdGroups(false);
        } catch (e) {
          setLoadingAdGroups(false);
          const reportError = e as Error;
          setError({
            message: `Failed to get Ad Groups eBay: ${reportError.message}`,
            reportError,
          });
        }
      }
    },
    [accessToken, company, currentCampaigns, setError]
  );

  const getAdGroupKeywords = useCallback(
    async (campaignId, adGroupId) => {
      if (
        currentCampaigns &&
        R.defaultTo({}, currentCampaigns)[campaignId] &&
        R.defaultTo({}, currentAdGroups)[adGroupId]
      ) {
        try {
          setLoadingKeywords(true);
          const eBayKeywordRes = await EbayLambdaFetch("/getEbayKeywords", {
            params: {
              accessToken,
              company,
              campaignId,
              adGroupIds: adGroupId,
            },
          });
          const eBayKeywordsResponse = await awaitJSON(eBayKeywordRes);
          const keywords = {};
          if (eBayKeywordsResponse) {
            for (let keyword of eBayKeywordsResponse) {
              keywords[keyword.keywordId] = keyword;
            }
          }
          setCurrentCampaigns(campaigns => {
            if (campaigns) {
              campaigns[campaignId].adGroups[adGroupId].keywords = keywords;
              return campaigns;
            }
          });
          setSelectedAdGroupID(adGroupId);
          setLoadingKeywords(false);
        } catch (e) {
          setLoadingKeywords(false);
          const reportError = e as Error;
          setError({
            message: `Failed to get Keywords eBay: ${reportError.message}`,
            reportError,
          });
        }
      }
    },
    [accessToken, company, currentAdGroups, currentCampaigns, setError]
  );

  const getAdGroupNegativeKeywords = useCallback(
    async (campaignId, adGroupId) => {
      if (
        currentCampaigns &&
        R.defaultTo({}, currentCampaigns)[campaignId] &&
        R.defaultTo({}, currentAdGroups)[adGroupId]
      ) {
        try {
          setLoadingNegativeKeywords(true);
          const eBayNegativeKeywordRes = await EbayLambdaFetch("/getEbayNegativeKeywords", {
            params: {
              accessToken,
              company,
              campaignIds: campaignId,
              adGroupIds: adGroupId,
            },
          });
          const eBayNegativeKeywordsResponse = await awaitJSON(eBayNegativeKeywordRes);
          const negativeKeywords = {};
          if (eBayNegativeKeywordsResponse) {
            for (let negativeKeyword of eBayNegativeKeywordsResponse) {
              negativeKeywords[negativeKeyword.negativeKeywordId] = negativeKeyword;
            }
          }
          setCurrentCampaigns(campaigns => {
            if (campaigns) {
              campaigns[campaignId].adGroups[adGroupId].negativeKeywords = negativeKeywords;
              return campaigns;
            }
          });
          setLoadingNegativeKeywords(false);
          setSelectedAdGroupID(adGroupId);
        } catch (e) {
          setLoadingNegativeKeywords(false);
          const reportError = e as Error;
          setError({
            message: `Failed to get Negative Keywords eBay: ${reportError.message}`,
            reportError,
          });
        }
      }
    },
    [accessToken, company, currentAdGroups, currentCampaigns, setError]
  );

  const importData = useCallback(() => {
    try {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = async event => {
          if (event.target) {
            const data = event.target.result;
            if (typeof data == "string") {
              const importedCSVRows: ImportDataCSVRow[] = [];
              const rows = data.split("\r\n");
              const keys = rows[0].split(",");
              for (const rowString of rows.slice(1)) {
                const rowObj = {};
                const rowValues = rowString.split(",");
                for (let i = 0; i < rowValues.length; ++i) {
                  rowObj[keys[i]] = rowValues[i] && rowValues[i].length ? rowValues[i] : undefined;
                }
                importedCSVRows.push(rowObj as ImportDataCSVRow);
              }
              let rowNumber = 2; // Keep track of the row number for error logging
              try {
                for (let row of importedCSVRows) {
                  const rowNumberMessage = `Please check CSV Row ${rowNumber}.`;
                  const { Action, Entity } = row;
                  if (Action && Entity) {
                    const {
                      "Campaign ID": campaignId,
                      "Campaign Name": campaignName,
                      "Start Date": csvStartDate,
                      "End Date": csvEndDate,
                      "Marketplace ID": marketplaceId,
                      "Campaign Funding Strategy Funding Model": fundingModel,
                      "Ad Group Name": adGroupName,
                      "Ad Group ID": adGroupId,
                      "Ad Group Status": adGroupStatus,
                    } = row;
                    // The eBay API requires us to include the time zone.
                    const startDate =
                      csvStartDate && csvStartDate.length > 10
                        ? csvStartDate
                        : `${csvStartDate}T23:59:00Z`;
                    const endDate =
                      csvEndDate && csvEndDate.length > 10 ? csvEndDate : `${csvEndDate}T23:59:00Z`;
                    // Update Ebay Campaign
                    if (Entity === "Campaign") {
                      const {
                        "Campaign Budget Daily Amount Currency": currency,
                        "Campaign Budget Daily Amount Value": value,
                      } = row;
                      if (Action === "Create") {
                        const newEbayCampaign = {
                          campaignName,
                          startDate,
                          endDate,
                          marketplaceId,
                          fundingStrategy: {
                            fundingModel,
                          },
                          budget: {
                            daily: {
                              amount: {
                                currency,
                                value,
                              },
                            },
                          },
                          adGroups: [],
                        };
                        const body = {
                          ...newEbayCampaign,
                          company,
                          accessToken,
                        };
                        await EbayLambdaFetch("/createEbayCampaign", {
                          method: "POST",
                          body,
                        });
                      } else if (Action === "Update") {
                        if (R.isNil(campaignId)) {
                          throw new Error(
                            `Ad Campaign ID is required for an campaign update. ${rowNumberMessage}`
                          );
                        }

                        let body: Record<string, any> = { company, accessToken, campaignId };
                        if (campaignName) {
                          body.campaignName = campaignName;
                        }
                        if (startDate) {
                          body.startDate = startDate;
                        }
                        if (endDate) {
                          body.endDate = endDate;
                        }
                        if (currency && value) {
                          body.budget = {
                            daily: {
                              amount: {
                                currency,
                                value,
                              },
                            },
                          };
                        }
                        await EbayLambdaFetch("/updateEbayCampaign", {
                          method: "POST",
                          body,
                        });
                      } else if (Action === "Delete") {
                        await deleteCampaign(campaignId);
                      }
                    }
                    if (Entity === "Ad Group") {
                      const {
                        "Ad Group Default Bid Currency": currency,
                        "AdGroup Default Bid Value": value,
                      } = row;
                      if (Action === "Create") {
                        // Lambda Update
                        const body = {
                          company,
                          accessToken,
                          currency,
                          value,
                          campaignId,
                          adGroupName,
                        };
                        await EbayLambdaFetch("/createEbayAdGroup", {
                          method: "POST",
                          body,
                        });
                      } else if (Action === "Update") {
                        if (R.isNil(adGroupId)) {
                          throw new Error(
                            `Ad Group ID is required for an ad group update. ${rowNumberMessage}`
                          );
                        }
                        let body: Record<string, any> = {
                          company,
                          accessToken,
                          campaignId,
                          adGroupId,
                          request: {},
                        };
                        if (adGroupName) {
                          body.request.adGroupName = adGroupName;
                        }
                        if (adGroupStatus) {
                          body.request.adGroupStatus = adGroupStatus;
                        }
                        if (currency && value) {
                          body.request.defaultBid = {
                            currency,
                            value,
                          };
                        }
                        await EbayLambdaFetch("/updateEbayAdGroup", {
                          method: "POST",
                          body,
                        });
                      }
                    }
                    if (Entity === "Keyword") {
                      const {
                        "Keyword Match Type": matchType,
                        "Keyword Bid Currency": currency,
                        "Keyword Bid Value": value,
                        "Keyword ID": keywordId,
                        "Keyword Status": keywordStatus,
                        "Keyword Text": keywordText,
                      } = row;
                      if (Action === "Create") {
                        // Lambda Update
                        const body = {
                          company,
                          accessToken,
                          campaignId,
                          request: {
                            bid: {
                              currency,
                              value,
                            },
                            keywordText,
                            matchType,
                            adGroupId,
                          },
                        };
                        await EbayLambdaFetch("/createEbayKeyword", {
                          method: "POST",
                          body,
                        });
                      } else if (Action === "Update") {
                        if (R.isNil(keywordId)) {
                          throw new Error(
                            `Keyword ID is required for keyword update. ${rowNumberMessage}`
                          );
                        }

                        let body: Record<string, any> = {
                          company,
                          accessToken,
                          campaignId,
                          keywordId,
                          request: {
                            adGroupId,
                          },
                        };
                        if (keywordStatus) {
                          body.request.keywordStatus = keywordStatus;
                        }
                        if (currency && value) {
                          body.request.bid = {
                            currency,
                            value,
                          };
                        }
                        await EbayLambdaFetch("/updateEbayKeyword", {
                          method: "POST",
                          body,
                        });
                      }
                    }
                    if (Entity === "Negative Keyword") {
                      const {
                        "Negative Keyword Match Type": negativeKeywordMatchType,
                        "Negative Keyword ID": negativeKeywordId,
                        "Negative Keyword Status": negativeKeywordStatus,
                        "Negative Keyword Text": negativeKeywordText,
                      } = row;
                      if (Action === "Create") {
                        // Lambda Update
                        const body = {
                          company,
                          accessToken,
                          request: {
                            campaignId,
                            negativeKeywordText,
                            negativeKeywordMatchType,
                            adGroupId,
                          },
                        };
                        await EbayLambdaFetch("/createEbayNegativeKeyword", {
                          method: "POST",
                          body,
                        });
                      } else if (Action === "Update") {
                        if (R.isNil(negativeKeywordId)) {
                          throw new Error(
                            `Negative Keyword ID is required for an negative keyword update. ${rowNumberMessage}`
                          );
                        }
                        if (negativeKeywordStatus) {
                          // Lambda Update
                          let body = {
                            company,
                            accessToken,
                            campaignId,
                            adGroupId,
                            negativeKeywordId,
                            request: {
                              negativeKeywordStatus,
                            },
                          };
                          await EbayLambdaFetch("/updateEbayNegativeKeyword", {
                            method: "POST",
                            body,
                          });
                        } else {
                          throw new Error(
                            `A status is required to update negative keyword with an id of: ${negativeKeywordId} for ad group ${adGroupId} for campaign: ${campaignId}. ${rowNumberMessage}. Please note that you will need to create an ad group before you are able to update it with negative keywords.`
                          );
                        }
                      }
                    }
                  } else {
                    throw new Error(`Action and Entity are required. ${rowNumberMessage}`);
                  }
                  rowNumber++;
                }
              } catch (e) {
                let reportError = e as Error;
                setError({
                  message: `Failed to import CSV data: ${reportError.message}. Please check CSV Row ${rowNumber}.`,
                  reportError,
                });
              } finally {
                setCurrentCampaigns(undefined);
                setSelectedCampaignID(undefined);
                setSelectedAdGroupID(undefined);
              }
            }
          }
        };
        fileReader.readAsText(file);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to import eBay campaign changes: ${reportError.message}`,
        reportError,
      });
    }
  }, [accessToken, company, deleteCampaign, file, setError]);

  const pullAllCampaignData = useCallback(async () => {
    try {
      setSelectedCampaignID(undefined);
      setSelectedAdGroupID(undefined);
      setLoadingAllData(true);
      if (currentCampaigns) {
        let newCurrentCampaigns = currentCampaigns;
        // Cycle through and pull all ad group data
        for (const campaignKey of R.keys(newCurrentCampaigns)) {
          const campaign = newCurrentCampaigns[campaignKey];
          const campaignID = campaign.campaignId;
          if (campaignID) {
            if (R.isNil(campaign.adGroups)) {
              const eBayAdGroupsRes = await EbayLambdaFetch("/getEbayAdGroups", {
                params: {
                  accessToken,
                  company,
                  campaignId: campaignID,
                },
              });
              const ebayAdGroupsResponse = await awaitJSON(eBayAdGroupsRes);
              let adGroups = {};
              if (ebayAdGroupsResponse) {
                for (let adGroup of ebayAdGroupsResponse) {
                  adGroups[adGroup.adGroupId] = adGroup;
                }
              }
              newCurrentCampaigns[campaignKey].adGroups = adGroups;
            }
          }
        }
        // Cycle through and pull all keyword data
        for (const campaignKey of R.keys(currentCampaigns)) {
          const campaign = currentCampaigns[campaignKey];
          const campaignID = campaign.campaignId;
          if (campaignID) {
            for (const adGroupKey of R.keys(campaign.adGroups)) {
              const adGroup = campaign.adGroups[adGroupKey];
              const adGroupID = adGroup.adGroupId;
              if (R.isNil(adGroup.keywords)) {
                const eBayKeywordRes = await EbayLambdaFetch("/getEbayKeywords", {
                  params: {
                    accessToken,
                    company,
                    campaignId: campaignID,
                    adGroupIds: adGroupID,
                  },
                });
                const eBayKeywordsResponse = await awaitJSON(eBayKeywordRes);
                const keywords = {};
                if (eBayKeywordsResponse) {
                  for (let keyword of eBayKeywordsResponse) {
                    keywords[keyword.keywordId] = keyword;
                  }
                }
                newCurrentCampaigns[campaignKey].adGroups[adGroupKey].keywords = keywords;
              }
              if (R.isNil(adGroup.negativeKeywords)) {
                const eBayNegativeKeywordRes = await EbayLambdaFetch("/getEbayNegativeKeywords", {
                  params: {
                    accessToken,
                    company,
                    campaignIds: campaignID,
                    adGroupIds: adGroupID,
                  },
                });
                const eBayNegativeKeywordsResponse = await awaitJSON(eBayNegativeKeywordRes);
                const negativeKeywords = {};
                if (eBayNegativeKeywordsResponse) {
                  for (let negativeKeyword of eBayNegativeKeywordsResponse) {
                    negativeKeywords[negativeKeyword.negativeKeywordId] = negativeKeyword;
                  }
                }
                newCurrentCampaigns[campaignKey].adGroups[
                  adGroupKey
                ].negativeKeywords = negativeKeywords;
              }
            }
          }
        }
        setCurrentCampaigns(newCurrentCampaigns);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to pull all data: ${reportError.message}`,
        reportError,
      });
    } finally {
      setSelectedCampaignID(undefined);
      setSelectedAdGroupID(undefined);
      setLoadingAllData(false);
    }
  }, [accessToken, company, currentCampaigns, setError]);

  const isAllDataLoaded = useMemo(() => {
    if (currentCampaigns && !loadingAllData) {
      for (const campaignKey of R.keys(currentCampaigns)) {
        const campaign = currentCampaigns[campaignKey];
        const campaignID = campaign.campaignId;
        if (campaignID) {
          if (R.isNil(campaign.adGroups)) {
            return false;
          } else {
            for (const adGroupKey of R.keys(campaign.adGroups)) {
              const adGroup = campaign.adGroups[adGroupKey];
              if (R.isNil(adGroup.keywords)) {
                return false;
              } else if (R.isNil(adGroup.negativeKeywords)) {
                return false;
              } else {
                continue;
              }
            }
          }
        }
      }
      return true;
    } else {
      return false;
    }
  }, [currentCampaigns, loadingAllData]);

  const exportData = useCallback(
    entity => {
      try {
        if (currentCampaigns) {
          let fileName = "";
          let csvRows: Partial<ImportDataCSVRow>[] = [];
          if (entity === "ALL") {
            const keywordCSVRows: Partial<ImportDataCSVRow>[] = [];
            const negativeKeywordCSVRows: Partial<ImportDataCSVRow>[] = [];
            for (const campaignKey of R.keys(currentCampaigns)) {
              const campaign = currentCampaigns[campaignKey];
              const adGroups = campaign && campaign.adGroups ? campaign.adGroups : {};
              for (const adGroupKey of R.keys(adGroups)) {
                const adGroup = adGroups[adGroupKey];
                const template = {
                  "Campaign ID": campaign.campaignId,
                  "Campaign Name": campaign.campaignName,
                  "Campaign Budget Daily Amount Value":
                    campaign.budget && campaign.budget.daily && campaign.budget.daily.amount
                      ? campaign.budget.daily.amount.value
                      : "",
                  "Campaign Budget Daily Amount Currency":
                    campaign.budget && campaign.budget.daily && campaign.budget.daily.amount
                      ? campaign.budget.daily.amount.currency
                      : ("" as any),
                  "Start Date": campaign.startDate,
                  "End Date": campaign.endDate,
                  "Marketplace ID": campaign.marketplaceId,
                  "Campaign Funding Strategy Funding Model":
                    campaign && campaign.fundingStrategy
                      ? campaign.fundingStrategy.fundingModel
                      : ("" as any),
                  "Ad Group Name": adGroup.name,
                  "Ad Group Default Bid Currency":
                    adGroup && adGroup.defaultBid ? adGroup.defaultBid.currency : ("" as any),
                  "AdGroup Default Bid Value":
                    adGroup && adGroup.defaultBid ? adGroup.defaultBid.value : ("" as any),
                  "Ad Group ID": adGroup.adGroupId,
                  "Ad Group Status": adGroup.adGroupStatus,
                };
                for (const keywordKey of R.keys(adGroup.keywords)) {
                  const keyword = adGroup.keywords[keywordKey];
                  keywordCSVRows.push({
                    ...template,
                    "Keyword Bid Currency": keyword.bid.currency as EbayCurrency,
                    "Keyword Bid Value": keyword.bid.value,
                    "Keyword Text": keyword.keywordText,
                    "Keyword Match Type": keyword.matchType,
                    "Keyword ID": keyword.keywordId,
                    "Keyword Status": keyword.keywordStatus,
                    "Negative Keyword Text": "",
                    "Negative Keyword Match Type": "",
                    "Negative Keyword ID": "",
                    "Negative Keyword Status": "" as any,
                  });
                }
                for (const negativeKeywordKey of R.keys(adGroup.negativeKeywords)) {
                  const negativeKeyword = adGroup.negativeKeywords[negativeKeywordKey];
                  negativeKeywordCSVRows.push({
                    ...template,
                    "Keyword Bid Currency": "" as any,
                    "Keyword Bid Value": "",
                    "Keyword Text": "",
                    "Keyword Match Type": "",
                    "Keyword ID": "",
                    "Keyword Status": "" as any,
                    "Negative Keyword Text": negativeKeyword.negativeKeywordText,
                    "Negative Keyword Match Type": negativeKeyword.negativeKeywordMatchType,
                    "Negative Keyword ID": negativeKeyword.negativeKeywordId,
                    "Negative Keyword Status": negativeKeyword.negativeKeywordStatus,
                  });
                }
              }
            }
            csvRows = R.flatten(keywordCSVRows.concat(negativeKeywordCSVRows));
            fileName = `${company} eBay All Data`;
          }
          if (entity === "CAMPAIGN") {
            csvRows = R.map(
              campaign => ({
                "Campaign ID": campaign.campaignId,
                "Campaign Name": campaign.campaignName,
                "Campaign Budget Daily Amount Value":
                  campaign.budget && campaign.budget.daily && campaign.budget.daily.amount
                    ? campaign.budget.daily.amount.value
                    : "",
                "Campaign Budget Daily Amount Currency":
                  campaign.budget && campaign.budget.daily && campaign.budget.daily.amount
                    ? campaign.budget.daily.amount.currency
                    : ("" as any),
                "Start Date": campaign.startDate,
                "End Date": campaign.endDate,
                "Marketplace ID": campaign.marketplaceId,
                "Campaign Funding Strategy Funding Model":
                  campaign && campaign.fundingStrategy
                    ? campaign.fundingStrategy.fundingModel
                    : ("" as any),
              }),
              R.values(currentCampaigns)
            );
            fileName = `${company} eBay Campaigns`;
          } else if (currentAdGroups && selectedCampaignID && entity === "AD GROUP") {
            csvRows = R.map(
              adGroup => ({
                "Ad Group Name": adGroup.name,
                "Ad Group Default Bid Currency":
                  adGroup && adGroup.defaultBid ? adGroup.defaultBid.currency : ("" as any),
                "AdGroup Default Bid Value":
                  adGroup && adGroup.defaultBid ? adGroup.defaultBid.value : ("" as any),
                "Ad Group ID": adGroup.adGroupId,
                "Ad Group Status": adGroup.adGroupStatus,
              }),
              R.values(currentAdGroups)
            );
            fileName = `${company} eBay ${currentCampaigns[selectedCampaignID].campaignName} Ad Groups`;
          } else if (
            currentAdGroups &&
            selectedAdGroupID &&
            currentKeywords &&
            entity === "KEYWORD"
          ) {
            csvRows = R.map(
              keyword => ({
                "Keyword Bid Currency": keyword.bid.currency as EbayCurrency,
                "Keyword Bid Value": keyword.bid.value,
                "Keyword Text": keyword.keywordText,
                "Keyword Match Type": keyword.matchType,
                "Keyword ID": keyword.keywordId,
                "Keyword Status": keyword.keywordStatus,
              }),
              R.values(currentKeywords)
            );
            fileName = `${company} eBay ${currentAdGroups[selectedAdGroupID].name} Keywords`;
          } else if (
            currentAdGroups &&
            selectedAdGroupID &&
            currentNegativeKeywords &&
            entity === "NEGATIVE KEYWORD"
          ) {
            csvRows = R.map(
              negativeKeyword => ({
                "Negative Keyword Text": negativeKeyword.negativeKeywordText,
                "Negative Keyword Match Type": negativeKeyword.negativeKeywordMatchType,
                "Negative Keyword ID": negativeKeyword.negativeKeywordId,
                "Negative Keyword Status": negativeKeyword.negativeKeywordStatus,
              }),
              R.values(currentNegativeKeywords)
            );
            fileName = `${company} eBay ${currentAdGroups[selectedAdGroupID].name} Negative Keywords`;
          }
          downloadJSONToCSV(csvRows, fileName);
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed export eBay ${entity}'s: ${reportError.message}`,
          reportError,
        });
      }
    },
    [
      company,
      currentAdGroups,
      currentCampaigns,
      currentKeywords,
      currentNegativeKeywords,
      selectedAdGroupID,
      selectedCampaignID,
      setError,
    ]
  );

  const createSampleImportDocument = useCallback(() => {
    const genericTemplate = {
      Entity: "",
      Action: "",
      "Campaign ID": "",
      "Campaign Name": "",
      "Campaign Budget Daily Amount Value": "",
      "Campaign Budget Daily Amount Currency": "",
      "Start Date": "",
      "End Date": "",
      "Marketplace ID": "",
      "Campaign Funding Strategy Funding Model": "",
      "Ad Group Name": "",
      "Ad Group Default Bid Currency": "",
      "AdGroup Default Bid Value": "",
      "Ad Group ID": "",
      "Ad Group Status": "",
      "Keyword Bid Currency": "",
      "Keyword Bid Value": "",
      "Keyword Text": "",
      "Keyword Match Type": "",
      "Keyword ID": "",
      "Keyword Status": "",
      "Negative Keyword Text": "",
      "Negative Keyword Match Type": "",
      "Negative Keyword ID": "",
      "Negative Keyword Status": "",
    };
    const createCampaignTemplate = {
      Entity: "Campaign",
      Action: "Create",
      "Campaign Name": "NEW CAMPAIGN",
      "Campaign Budget Daily Amount Value": "10",
      "Campaign Budget Daily Amount Currency": "USD",
      "Start Date": R.pipe(Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(new Date()),
      "End Date": R.pipe(Dfns.addWeeks(4), Dfns.format(DATE_FORMAT))(new Date()),
      "Marketplace ID": "EBAY_US",
      "Campaign Funding Strategy Funding Model": "COST_PER_CLICK",
    };
    const updateCampaignTemplate = {
      Entity: "Campaign",
      Action: "Update",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Campaign Name": "NEW CAMPAIGN NAME",
      "Campaign Budget Daily Amount Value": "10",
      "Campaign Budget Daily Amount Currency": "USD",
      "Start Date": R.pipe(Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(new Date()),
      "End Date": R.pipe(Dfns.addWeeks(4), Dfns.format(DATE_FORMAT))(new Date()),
      "Marketplace ID": "EBAY_US",
      "Campaign Funding Strategy Funding Model": "COST_PER_CLICK",
    };
    const deleteCampaignTemplate = {
      Entity: "Campaign",
      Action: "Delete",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
    };
    const createAdGroupTemplate = {
      Entity: "Ad Group",
      Action: "Create",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group Name": "NEW AD GROUP",
      "Ad Group Default Bid Currency": "USD",
      "AdGroup Default Bid Value": "0.5",
    };
    const updateAdGroupTemplate = {
      Entity: "Ad Group",
      Action: "Update",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group Name": "NEW AD GROUP NAME",
      "Ad Group Default Bid Currency": "USD",
      "Ad Group Default Bid Value": "0.5",
      "Ad Group ID": "12345 (MUST ALREADY EXIST)",
      "Ad Group Status": "ACTIVE",
    };
    const createKeywordTemplate = {
      Entity: "Keyword",
      Action: "Create",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group ID": "12345 (MUST ALREADY EXIST)",
      "Keyword Bid Currency": "USD",
      "Keyword Bid Value": "0.1",
      "Keyword Text": "shoes",
      "Keyword Match Type": "PHRASE",
    };
    const updateKeywordTemplate = {
      Entity: "Keyword",
      Action: "Update",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group ID": "12345 (MUST ALREADY EXIST)",
      "Keyword Bid Currency": "USD",
      "Keyword Bid Value": "0.1",
      "Keyword ID": "123456 (MUST ALREADY EXIST)",
      "Keyword Status": "ACTIVE",
    };
    const createNegativeKeywordTemplate = {
      Entity: "Negative Keyword",
      Action: "Create",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group ID": "12345 (MUST ALREADY EXIST)",
      "Negative Keyword Text": "string",
      "Negative Keyword Match Type": "EXACT",
    };
    const updateNegativeKeywordTemplate = {
      Entity: "Negative Keyword",
      Action: "Update",
      "Campaign ID": "1234 (MUST ALREADY EXIST)",
      "Ad Group ID": "12345 (MUST ALREADY EXIST)",
      "Negative Keyword ID": "1234567 (MUST ALREADY EXIST)",
      "Negative Keyword Status": "ACTIVE",
    };
    const templates = R.map(
      template => ({
        ...genericTemplate,
        ...template,
      }),
      [
        createCampaignTemplate,
        updateCampaignTemplate,
        deleteCampaignTemplate,
        createAdGroupTemplate,
        updateAdGroupTemplate,
        createKeywordTemplate,
        updateKeywordTemplate,
        createNegativeKeywordTemplate,
        updateNegativeKeywordTemplate,
      ]
    );

    downloadJSONToCSV(templates, "eBay Import Template");
  }, []);

  const campaignTableHeaders: Header[] = [
    {
      label: "Campaign Name",
      field: "campaignName",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Campaign ID",
      field: "campaignId",
      type: "number",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      label: "Start Date",
      field: "startDate",
      type: "day",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
      contentGetter: row => (row.startDate ? new Date(row.startDate).toDateString() : "-"),
      optionsGetter: row => (row.startDate ? new Date(row.startDate).toDateString() : "-"),
      renderer: row => (row.startDate ? new Date(row.startDate).toDateString() : "-"),
    },
    {
      label: "End Date",
      field: "endDate",
      type: "day",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
      contentGetter: row => (row.endDate ? new Date(row.endDate).toDateString() : "-"),
      optionsGetter: row => (row.endDate ? new Date(row.endDate).toDateString() : "-"),
      renderer: row => (row.endDate ? new Date(row.endDate).toDateString() : "-"),
    },
    {
      label: "Daily Budget",
      field: "budget_daily_amount_value",
      contentGetter: row =>
        row && row.budget && row.budget.daily && row.budget.daily.amount
          ? row.budget.daily.amount.value
          : "-",
      optionsGetter: row =>
        row && row.budget && row.budget.daily && row.budget.daily.amount
          ? row.budget.daily.amount.value
          : "-",
      renderer: row =>
        row && row.budget && row.budget.daily && row.budget.daily.amount
          ? row.budget.daily.amount.value
          : "-",
      type: "decimal",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Campaign Status",
      field: "campaignStatus",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Funding Model",
      field: "fundingStrategy_fundingModel",
      contentGetter: row => (row && row.fundingStrategy ? row.fundingStrategy.fundingModel : "-"),
      optionsGetter: row => (row && row.fundingStrategy ? row.fundingStrategy.fundingModel : "-"),
      renderer: row => (row && row.fundingStrategy ? row.fundingStrategy.fundingModel : "-"),
      type: "select",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "View Ad Groups",
      nonFilterable: true,
      renderer: row =>
        !R.isNil(row) ? (
          <BPMButton
            className="tableButton"
            onClick={() => {
              if (currentCampaigns && R.isNil(currentCampaigns[row.campaignId].adGroups)) {
                getCampaignAdGroups(row.campaignId);
              } else {
                setSelectedCampaignID(row.campaignId);
              }
              setSelectedAdGroupID(undefined);
            }}
          >
            View Ad Groups
          </BPMButton>
        ) : (
          <div />
        ),
    },
    {
      label: "Delete Campaign",
      nonFilterable: true,
      renderer: row =>
        !R.isNil(row) &&
        row.campaignId &&
        ["ENDED", "PAUSED", "RUNNING"].includes(row.campaignStatus) ? (
          <MdDelete
            onClick={() => {
              deleteCampaign(row.campaignId);
            }}
          />
        ) : (
          <div></div>
        ),
    },
  ];

  const campaignSelectorOptions = {
    fundingStrategy_fundingModel: [
      { label: "Cost Per Click", value: "COST_PER_CLICK" },
      { label: "Cost Per Sale", value: "COST_PER_SALE" },
    ],
  };

  const adGroupTableHeaders: Header[] = [
    {
      label: "Ad Group Name",
      field: "name",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Ad Group ID",
      field: "adGroupId",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Default Bid Budget",
      renderer: row => (row && row.defaultBid ? row.defaultBid.value : "-"),
      type: "decimal",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Ad Group Status",
      field: "adGroupStatus",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "View Keywords",
      renderer: row =>
        !R.isNil(row) ? (
          <BPMButton
            className="tableButton"
            onClick={() => {
              if (
                currentAdGroups &&
                currentAdGroups[row.adGroupId] &&
                R.isNil(currentAdGroups[row.adGroupId].keywords)
              ) {
                getAdGroupKeywords(selectedCampaignID, row.adGroupId);
              } else {
                setSelectedAdGroupID(row.adGroupId);
              }
            }}
          >
            View Keywords
          </BPMButton>
        ) : (
          <div />
        ),
    },
    {
      label: "View Negative Keywords",
      renderer: row =>
        !R.isNil(row) ? (
          <BPMButton
            className="tableButton"
            onClick={() => {
              if (
                currentAdGroups &&
                currentAdGroups[row.adGroupId] &&
                R.isNil(currentAdGroups[row.adGroupId].negativeKeywords)
              ) {
                getAdGroupNegativeKeywords(selectedCampaignID, row.adGroupId);
              } else {
                setSelectedAdGroupID(row.adGroupId);
              }
            }}
          >
            View Negative Keywords
          </BPMButton>
        ) : (
          <div />
        ),
    },
  ];

  const keywordTableHeaders: Header[] = [
    {
      label: "Keyword Text",
      field: "keywordText",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Keyword ID",
      field: "keywordId",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Default Bid Budget",
      renderer: row => (row && row.bid ? row.bid.value : "-"),
      type: "decimal",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Keyword Status",
      field: "keywordStatus",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Keyword Match Type",
      field: "matchType",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
  ];

  const negativeKeywordTableHeaders: Header[] = [
    {
      label: "Negative Keyword Text",
      field: "negativeKeywordText",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Negative Keyword ID",
      field: "negativeKeywordId",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Negative Keyword Status",
      field: "negativeKeywordStatus",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Negative Keyword Match Type",
      field: "negativeKeywordMatchType",
      type: "text",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
  ];

  return (
    <Page
      title="eBay"
      pageType="eBay"
      minHeight="600px"
      actions={
        redirectUrl || accessToken ? (
          <div className="eBayExportImportButtons">
            <BPMButton onClick={() => setShowImportChangesModal(true)}>
              Import eBay Campaigns
            </BPMButton>
            <BPMButton onClick={() => createSampleImportDocument()}>
              Download Sample Import Sheet
            </BPMButton>
            {redirectUrl && <BPMButton onClick={getAuthorization}>eBay Sign-in</BPMButton>}
            {currentCampaigns &&
              (loadingAllData ? (
                <Spinner />
              ) : (
                <BPMButton
                  onClick={() => {
                    if (isAllDataLoaded) {
                      exportData("ALL");
                    } else {
                      pullAllCampaignData();
                    }
                  }}
                >
                  {isAllDataLoaded ? "Export All" : "Load All eBay Data"}
                </BPMButton>
              ))}
            {currentCampaigns && !loadingAllData && (
              <BPMButton
                onClick={() => {
                  exportData("CAMPAIGN");
                }}
              >
                Export eBay Campaigns
              </BPMButton>
            )}
            {currentAdGroups && !loadingAllData && (
              <BPMButton
                onClick={() => {
                  exportData("AD GROUP");
                }}
              >
                Export eBay Ad Groups
              </BPMButton>
            )}
            {currentKeywords && !loadingAllData && (
              <BPMButton
                onClick={() => {
                  exportData("KEYWORD");
                }}
              >
                Export eBay Keywords
              </BPMButton>
            )}
            {currentNegativeKeywords && !loadingAllData && (
              <BPMButton
                onClick={() => {
                  exportData("NEGATIVE KEYWORD");
                }}
              >
                Export eBay Negative Keywords
              </BPMButton>
            )}
            {showImportChangesModal && (
              <ImportModal
                importFunction={importData}
                file={file}
                setFile={file => {
                  if (R.isNil(file) || !file.name.includes(".csv") || !file.type.includes("csv")) {
                    setError({
                      message: "File must be a .csv",
                      reportError: new Error("File must be a .csv"),
                    });
                  } else {
                    setFile(file);
                  }
                }}
                onClose={() => setShowImportChangesModal(false)}
              />
            )}
          </div>
        ) : (
          <Spinner />
        )
      }
    >
      <div className="ebayPageContainer">
        {loadingCampaigns || loadingAllData ? (
          <FullPageSpinner />
        ) : redirectUrl || accessToken ? (
          <ModalEditTable<EbayCampaign>
            className="eBayCampaignsTable"
            enableAdd={false}
            enableDelete={false}
            filterBar
            headers={campaignTableHeaders}
            name="eBay Campaigns"
            selectorOptions={campaignSelectorOptions}
            setTableData={() => {}} //Currently Read Only
            showModalOnAdd={true}
            readOnly={true}
            rowHeight={50}
            tableData={R.values(R.defaultTo({}, currentCampaigns) as Record<string, EbayCampaign>)}
          />
        ) : (
          <FullPageSpinner />
        )}
        {loadingAdGroups && !loadingAllData ? (
          <Spinner />
        ) : (redirectUrl || accessToken) &&
          currentCampaigns &&
          selectedCampaignID &&
          currentAdGroups &&
          !loadingAllData ? (
          <ModalEditTable<EbayAdGroup>
            className="eBayAdGroupsTable"
            enableAdd={false}
            enableDelete={false}
            filterBar
            headers={adGroupTableHeaders}
            name={`${currentCampaigns[selectedCampaignID].campaignName}'s Ad Groups, Campaign ID: ${selectedCampaignID}`}
            selectorOptions={{}}
            setTableData={() => {}} //Currently Read Only
            showModalOnAdd={true}
            readOnly={true}
            rowHeight={50}
            tableData={R.values(currentAdGroups)}
          />
        ) : (
          <div></div>
        )}
        {loadingKeywords && !loadingAllData ? (
          <Spinner />
        ) : (redirectUrl || accessToken) &&
          currentAdGroups &&
          selectedAdGroupID &&
          currentKeywords &&
          !loadingAllData ? (
          <ModalEditTable<EbayKeyword>
            className="eBayKeywordsTable"
            enableAdd={false}
            enableDelete={false}
            filterBar
            headers={keywordTableHeaders}
            name={`${currentAdGroups[selectedAdGroupID].name}'s Keywords, Ad Group ID: ${selectedAdGroupID}`}
            readOnly={true}
            selectorOptions={{}}
            setTableData={() => {}} //Currently Read Only
            showModalOnAdd={true}
            tableData={R.values(currentKeywords)}
          />
        ) : (
          <div></div>
        )}

        {loadingNegativeKeywords && !loadingAllData ? (
          <Spinner />
        ) : (redirectUrl || accessToken) &&
          currentAdGroups &&
          selectedAdGroupID &&
          currentNegativeKeywords &&
          !loadingAllData ? (
          <ModalEditTable<EbayNegativeKeyword>
            className="eBayKeywordsTable"
            enableAdd={false}
            enableDelete={false}
            filterBar
            headers={negativeKeywordTableHeaders}
            name={`${currentAdGroups[selectedAdGroupID].name}'s Negative Keywords, Ad Group ID: ${selectedAdGroupID}`}
            readOnly={true}
            selectorOptions={{}}
            setTableData={() => {}} //Currently Read Only
            showModalOnAdd={true}
            tableData={R.values(currentNegativeKeywords)}
          />
        ) : (
          <div></div>
        )}
      </div>
    </Page>
  );
};

export default Ebay;
