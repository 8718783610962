import React from "react";

import { Location } from "@reach/router";

import { Page } from "../Components";

import "./NotFound.scss";

const NotFound = ({ invalidCompany }) => {
  return (
    <Page title="Page Not Found" pageType="Page Not Found">
      <Location>
        {({ location }) => {
          let { href, pathname } = location;
          let message = "This page doesn't exist.";
          let hint = "";
          if (invalidCompany) {
            let moreThanMatch = pathname.match(/^\/[^/]+\/.+$/);
            if (moreThanMatch) {
              message = `"${invalidCompany}" is not a valid company. This was the url you used.`;
            }
          } else if (pathname !== pathname.toLowerCase()) {
            hint = "Do you have uppercase letters in your URL? Try making them lowercase.";
          }
          return (
            <div className="pageNotFound">
              <div className="message">{message}</div>
              <code className="href">{href}</code>
              {hint && <div className="hint">{hint}</div>}
            </div>
          );
        }}
      </Location>
    </Page>
  );
};

export default NotFound;
