import React, { useMemo } from "react";
import * as R from "ramda";

import { StateSetter } from "../../utils/types";
import { MdClose } from "react-icons/md";
import { PendingChanges, RowType } from "./pendingChangesUtils";
import { Header } from "../StickyTable";
import PendingEditedRow from "./PendingEditedRow";
import PendingNewRow from "./PendingNewRow";

import "./PendingChangesPanel.scss";
import PendingDeletedRow from "./PendingDeletedRow";

interface PendingChangesPanelProps<R> {
  originalData?: Record<string, R>;
  pendingChanges: PendingChanges<R>;
  showPendingChanges: boolean;
  setShowPendingChanges: StateSetter<boolean>;
  headers: Header[];
}

export const PendingChangesPanel = <R extends RowType>({
  originalData,
  pendingChanges,
  showPendingChanges,
  setShowPendingChanges,
  headers,
}: PendingChangesPanelProps<R>): JSX.Element => {
  const { editedRows, newRows, deletedRows } = pendingChanges;

  const hasPendingChanges = useMemo(
    () => !R.isEmpty(editedRows) || !R.isEmpty(newRows) || !R.isEmpty(deletedRows),
    [editedRows, newRows, deletedRows]
  );

  return showPendingChanges && hasPendingChanges ? (
    <div className="pendingChangesPanel">
      <div className="pendingChangesTop">
        Pending Changes
        <div className="closeButton" onClick={() => setShowPendingChanges(false)}>
          <MdClose />
        </div>
      </div>
      <div className="pendingChangesBody">
        {editedRows && !R.isEmpty(editedRows) && originalData && (
          <div className="body">
            <span className="editsSectionTitle">Edited Rows</span>
            <div className="editsList">
              {editedRows.map(editedRow => {
                return (
                  <PendingEditedRow
                    key={JSON.stringify(editedRow)}
                    oldRow={originalData[editedRow.id]}
                    newRow={editedRow}
                    columns={headers}
                  />
                );
              })}
            </div>
          </div>
        )}
        {newRows && !R.isEmpty(newRows) && (
          <div className="body">
            <span className="editsSectionTitle">New Rows</span>
            <div className="editsList">
              {newRows.map(newRow => {
                return (
                  <PendingNewRow key={JSON.stringify(newRow)} newRow={newRow} columns={headers} />
                );
              })}
            </div>
          </div>
        )}
        {deletedRows && !R.isEmpty(deletedRows) && (
          <div className="body">
            <span className="editsSectionTitle">Deleted Rows</span>
            <div className="editsList">
              {deletedRows.map(deletedRow => {
                return (
                  <PendingDeletedRow
                    key={JSON.stringify(deletedRow)}
                    oldRow={deletedRow}
                    columns={headers}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
};
