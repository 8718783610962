import React, { useEffect, useState, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Tooltip, Form } from "react-bootstrap";

import { useSelector } from "react-redux";

import { useSetError } from "../redux/modals";

import * as UserRedux from "../redux/user";

import { DagLambdaFetch, awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";

import { BPMButton, BPMTable, FullPageSpinner, OverlayTrigger, Page, Spinner } from "../Components";

import { useIsMounted } from "../utils/hooks/useDOMHelpers";

import "./BatchFailures.scss";

const RUNNING_STATUSES = {
  SUBMITTED: true,
  PENDING: true,
  RUNNABLE: true,
  STARTING: true,
  RUNNING: true,
};

interface FailureRow {
  company: string;
  kpi: string;
  task: string;
  job_name: string;
  job_id: string;
  log_url: string;
  status: string;
  created: string;
  lastmodified: string;
  investigator: string | null;
  investigation_time: string | null;
  investigation_notes: string | null;
  expected_job_completion: string | null;
  builds: FailureRow[];
  running: boolean;
  agency: string;
}

const BatchFailures = (): JSX.Element => {
  const setError = useSetError();
  const email = useSelector(UserRedux.emailSelector);
  const getIsMounted = useIsMounted();
  const [failures, setFailures] = useState<FailureRow[]>();
  const isAdmin = useSelector(UserRedux.isAdminSelector);
  const isNOC = useSelector(UserRedux.isNOCSelector);

  useEffect(() => {
    let fetcher = async () => {
      try {
        let res = await MiscLambdaFetch("/get_batch_failures");
        let failures = await awaitJSON(res);

        failures = R.map(row => {
          let newRow = { ...row };
          newRow.running = newRow.status in RUNNING_STATUSES;
          return newRow;
        }, failures);

        if (getIsMounted()) {
          setFailures(failures);
        }
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to fetch batch failure info. Error: ${error.message}`,
        });
      }
    };

    if (!failures) {
      fetcher();
    }
  }, [failures, setError, getIsMounted]);

  const updateFailures = useCallback(
    args => {
      const {
        id,
        investigator,
        investigation_time,
        investigation_notes,
        update,
        restarting,
        running,
      } = args;
      try {
        let indexToUpdate = R.findIndex(
          (failureRow: FailureRow) => args.id === failureRow.job_id,
          failures as FailureRow[]
        );
        let clone = R.clone(failures || []);
        clone[indexToUpdate] = R.mergeRight(
          clone[indexToUpdate],
          R.filter(R.identity, {
            investigator: investigator && investigator.split("@")[0],
            investigation_time,
            investigation_notes,
            restarting,
            running,
            id: id,
          })
        );

        if (update) {
          MiscLambdaFetch("/update_batch_failures", {
            method: "POST",
            body: clone[indexToUpdate],
          });
        }
        setFailures(clone);
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to update build info. Error: ${error.message}`,
          reportError: error,
        });
      }
    },
    [setError, failures]
  );

  const restartBuild = useCallback(
    async args => {
      const { job_id, company, job_name } = args;
      updateFailures({ id: job_id, restarting: false, running: true });
      try {
        const result = await DagLambdaFetch("/start", {
          method: "POST",
          body: JSON.stringify({
            dag: company,
            start_task_name: job_name,
            retries: 0,
          }),
        });
        let parsedResult = await awaitJSON(result);
        if (!parsedResult.success) {
          setError({
            message: parsedResult.message,
          });
        }
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to restart build. Error: ${error.message}`,
          reportError: error,
        });
        updateFailures({ id: job_id, restarting: false, running: false });
      }
    },
    [setError, updateFailures]
  );

  const headers = [
    {
      label: "Job Name",
      name: "job_name",
      flex: 1,
      minFlexWidth: 250,
      renderer: data => <div className="jobName">{data.job_name}</div>,
    },
    { label: "Last Modified", name: "lastmodified", width: 200 },
    {
      label: "Investigator",
      name: "investigator",
      width: 150,
      renderer: data => (
        <div className="investigatorClaim">
          {data.investigator && <div>{data.investigator || email.split("@")[0]}</div>}
        </div>
      ),
    },
    {
      label: "Investigation Time",
      name: "investigation_time",
      width: 200,
      renderer: data => (data.investigation_time ? <div>{data.investigation_time}</div> : ""),
    },
    {
      label: "Investigation Notes",
      name: "investigation_notes",
      width: 300,
      renderer: data => (
        <Form.Control
          value={data.investigation_notes || ""}
          onChange={e => {
            updateFailures({
              id: data.job_id,
              investigation_notes: e.target.value,
            });
          }}
          onBlur={e => {
            if (e.target.value.trim().length > 0) {
              updateFailures({
                id: data.job_id,
                investigator: email,
                investigation_time: Dfns.format("yyyy-MM-dd'T'HH:mm:ss", new Date()),
                update: true,
              });
            }
          }}
        />
      ),
    },
    {
      label: "Ignore",
      name: "ignore",
      width: 80,
      renderer: data => (
        <BPMButton
          onClick={() =>
            updateFailures({
              id: data.job_id,
              investigator: email,
              investigation_time: Dfns.format("yyyy-MM-dd'T'HH:mm:ss", new Date()),
              investigation_notes: "IGNORE",
              update: true,
            })
          }
        >
          Ignore
        </BPMButton>
      ),
    },
    {
      label: "Failure Logs",
      name: "log_url",
      width: 100,
      renderer: data => (
        <div>
          {data.log_url ? (
            <a href={data.log_url} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      label: "Previous Builds",
      name: "builds",
      flex: 1,
      minFlexWidth: 500,
      renderer: data => {
        return (
          <div>
            {R.map(build => {
              return (
                <OverlayTrigger
                  key={`${build.created}`}
                  placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                  overlay={
                    <Tooltip id={`${build.created}`}>
                      Status: {build.status}
                      <br />
                      Last Modified: {build.lastmodified}
                    </Tooltip>
                  }
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={build.log_url}
                    className="previousBuild"
                  >
                    {build.status}
                  </a>
                </OverlayTrigger>
              );
            }, data.builds)}
          </div>
        );
      },
    },
  ];

  return (
    <Page title="Batch Failures" pageType="Batch Failures">
      <div className="batchFailuresContainer">
        {failures ? (
          <BPMTable
            defaultAdvancedFilter
            defaultTokens={{
              advanced: [
                "Restartable",
                "is",
                "false",
                "and",
                "Investigation Notes",
                "is not like",
                "ignore",
              ],
            }}
            noRowsRenderer={() => <div className="noFailures">No failures to show</div>}
            data={failures}
            headers={
              isAdmin || isNOC
                ? [
                    {
                      label: "Restartable",
                      name: "running",
                      width: 80,
                      renderer: data => {
                        return data.running ? (
                          <div>
                            <Spinner />
                          </div>
                        ) : (
                          <BPMButton onClick={() => restartBuild(data)}>
                            {data.restarting ? <Spinner /> : "Restart"}
                          </BPMButton>
                        );
                      },
                    },
                    ...headers,
                  ]
                : headers
            }
          />
        ) : (
          <FullPageSpinner />
        )}
      </div>
    </Page>
  );
};

export default BatchFailures;
