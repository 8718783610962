import { Auth } from "aws-amplify";
import { UsersLambdaFetch, awaitJSON } from "./fetch-utils";
import * as R from "ramda";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const domain = process.env.REACT_APP_DOMAIN;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_TINUITI_GOOGLE_CLIENT_ID;
const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

const ACCESS_TOKEN_LIFE = 1000 * 60 * 60 * 24; // 24 hours in ms
const ACCESS_TOKEN_LIFE_DELTA = 1000 * 60 * 60 * 23 + 1000 * 60 * 30; // 23 hours and 30 minutes in ms
const REFRESH_TOKEN_LIFE = 1000 * 60 * 60 * 24 * 30; // 30 days in ms

// Checks if a user is authorized to access the app and logs them in through the Cognito user pool
export const signInAWS = async (username, password) => {
  // Check if user is authorized
  let res = await UsersLambdaFetch("/checkUserAuthorization", {
    method: "POST",
    mode: "cors", // no-cors, *cors, same-origin
    body: JSON.stringify({ email: username }),
    headers: { "Content-Type": "application/json" },
  });
  let verificationResponse = await awaitJSON(res);
  if (verificationResponse.userAuthorized) {
    // Sign in through Cognito user pool
    const user = await Auth.signIn(username, password);
    // Fails if the signed in user has a challenge, but we can ignore it because signInChangePasswordAWS
    // will be automatically called and handle it
    try {
      await refreshCognitoToken();
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return user;
  } else {
    await signOutAWS();
    throw new Error("This username doesn't exist");
  }
};

// Allows a user to sign in with a temporary password and prompts them to change to a permanent one
export const signInChangePasswordAWS = async (username, password, newPassword) => {
  const user = await Auth.signIn(username, password);
  if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    // Will error if username isn't an email
    await Auth.completeNewPassword(user, newPassword);
    await refreshCognitoToken();
  } else {
    throw new Error(user.challengeName);
  }
};

// Gets a signed in Google user, checks if they're authorized, and extracts
// an access token from them using AWS methods.
export const signInGoogleOAuth = async googleOAuthResponse => {
  clearAuthCookies();
  const { access_token, id_token, refresh_token } = googleOAuthResponse;
  // Get signed in Google User (displays pop-up)
  let googleUser;
  try {
    googleUser = jwt_decode(id_token);
  } catch (e) {
    throw new Error(e.message);
  }
  let googleEmail = googleUser.email;
  let verificationResponse;
  try {
    // Check if authorized
    let res = await UsersLambdaFetch("/checkUserAuthorization", {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify({ email: googleEmail }),
      headers: { "Content-Type": "application/json" },
    });
    verificationResponse = await awaitJSON(res);
  } catch (e) {
    throw new Error(e.message);
  }
  try {
    if (verificationResponse && verificationResponse.userAuthorized) {
      setAuthCookiesGoogle({
        access_token,
        id_token,
        login_method: "Google",
        refresh_token,
      });
      await getAWSCredentials(true, id_token);
    } else {
      await signOutAWS();
      throw new Error("This username doesn't exist");
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

// Takes a user signed in through Google and uses AWS's methods to get valid tokens
// to get valid tokens for the user. The tokens are Google tokens, not Cognito tokens
const getAWSCredentials = async (refreshWindow, googleIDToken) => {
  let googleUser;
  try {
    googleUser = jwt_decode(googleIDToken);
  } catch (e) {
    throw new Error(e.message);
  }
  let user = {
    email: googleUser.email,
    name: googleUser.name,
  };
  await Auth.signOut();
  await Auth.federatedSignIn(
    "google",
    { token: googleIDToken, expires_at: ACCESS_TOKEN_LIFE },
    user
  );
  let curAuthUser = await Auth.currentAuthenticatedUser();
  setAuthCookiesGoogle({
    id_token: curAuthUser.token,
    login_method: "Google",
  });
  if (refreshWindow) {
    // Login doesn't work correctly if the page refresh is anywhere else
    loginRedirect();
  }
};

export const loginRedirect = () => {
  if (window.location.pathname === "/login2") {
    window.location.href = window.location.origin;
  } else {
    document.location.reload();
  }
};

export const signOutAWS = () => {
  (async () => {
    try {
      if (Cookies.get("login_method") === "Cognito") {
        // Takes care of signing out users
        const currentUser = Auth.userPool.getCurrentUser();
        await currentUser.signOut();
      }
    } catch (e) {
      console.log("error signing out cognito: ", e);
    } finally {
      sessionStorage.clear();
      clearAuthCookies();
      // Clears Auth variables in local storage, doesn't work if in try block (whether or not with await)
      Auth.signOut();
      document.location.reload();
    }
  })();
};

// Google JWT Tokens are equivalent to AWS access tokens. We also need Google's access token
// to use google slides, sheets, etc.
const setAuthCookiesGoogle = ({ access_token, id_token, refresh_token }) => {
  if (id_token) {
    Cookies.set("access_token", id_token, {
      domain,
      expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
    });
    Cookies.set(
      "access_token_expiration",
      Date.now() + ACCESS_TOKEN_LIFE - ACCESS_TOKEN_LIFE_DELTA,
      {
        domain,
        expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
      }
    );
  }
  if (access_token) {
    Cookies.set("google_api_access_token", access_token, {
      domain,
      expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
    });
    Cookies.set(
      "google_api_access_token_expiration",
      Date.now() + ACCESS_TOKEN_LIFE - ACCESS_TOKEN_LIFE_DELTA,
      {
        domain,
        expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
      }
    );
  }
  if (refresh_token) {
    Cookies.set("google_refresh_token", refresh_token, {
      domain,
      expires: new Date(Date.now() + REFRESH_TOKEN_LIFE),
    });
  }
  Cookies.set("login_method", "Google", {
    domain,
    expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
  });
};

export const setAuthCookiesCognito = access_token => {
  Cookies.set("access_token", access_token, {
    domain,
    expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
  });
  Cookies.set("access_token_expiration", Date.now() + ACCESS_TOKEN_LIFE - ACCESS_TOKEN_LIFE_DELTA, {
    domain,
    expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
  });
  Cookies.set("login_method", "Cognito", {
    domain,
    expires: new Date(Date.now() + ACCESS_TOKEN_LIFE),
  });
};

export const clearAuthCookies = () => {
  Cookies.remove("access_token_expiration", { domain });
  Cookies.remove("access_token", { domain });
  Cookies.remove("google_api_access_token_expiration", { domain });
  Cookies.remove("google_api_access_token", { domain });
  Cookies.remove("login_method", { domain });
};

export const clearRefreshToken = () => {
  try {
    window.google.accounts.oauth2.revoke();
  } finally {
    Cookies.remove("google_refresh_token");
  }
};

export const refreshGoogleAccessToken = async googleIDToken => {
  const client = await window.google.accounts.oauth2.initTokenClient({
    client_id: GOOGLE_CLIENT_ID,
    scope: "profile email openid https://www.googleapis.com/auth/drive",
    auto_select: true,
    callback: async response => {
      let googleAccessToken = response.access_token;
      setAuthCookiesGoogle({
        access_token: googleAccessToken,
        id_token: googleIDToken,
      });
      if (googleIDToken) {
        await getAWSCredentials(false, googleIDToken);
      }
    },
  });
  await client.requestAccessToken();
};

export const refreshGoogleOAuthToken = async reload => {
  try {
    const refresh_token = Cookies.get("google_refresh_token");
    const code_receiver_uri = "https://www.googleapis.com/oauth2/v4/token";
    const xhr = new XMLHttpRequest();
    xhr.open("POST", code_receiver_uri, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // Set custom header for CRSF
    xhr.setRequestHeader("X-Requested-With", "XmlHttpRequest");
    xhr.onload = async () => {
      const { access_token, id_token } = JSON.parse(xhr.responseText);
      setAuthCookiesGoogle({ access_token, id_token, login_method: "Google" });
      if (id_token) {
        await getAWSCredentials(reload, id_token);
      }
      return xhr.status >= 200 && xhr.status < 300 && !R.isNil(id_token);
    };
    const postBody = `refresh_token=${refresh_token}&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&grant_type=refresh_token`;
    await xhr.send(postBody);
  } catch (e) {
    if (e.message === "google is not defined") {
      console.error(e);
    }
    throw new Error(e);
  }
};

// Doesn't work / not used currently, see periodicallyRefreshGoogleOrCognito
export const refreshCognitoToken = async () => {
  try {
    let curSession = await Auth.currentSession();
    let newAccessToken = curSession.accessToken.jwtToken;
    // Only need to reset Cookies (which updates their expiry time) if access token isn't set or has changed
    if (
      R.isNil(Cookies.get("access_token")) ||
      newAccessToken !== Cookies.get("access_token") ||
      Date.now() < Cookies.get("access_token_expiration")
    ) {
      await setAuthCookiesCognito(newAccessToken);
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const periodicallyRefreshGoogleOrCognito = async () => {
  // The Cognito periodic refresh should hypothetically work but currently does nothing (getAccessToken takes care of refresh)
  // More details: Cognito tokens are automatically refreshed by the Auth.currentSession() token, which returns
  // the current token if it's still valid, or a new token if it's expired. I think the token should be refreshed if there's less
  // than 5 minutes remaining before its expiry because "If the minimum for the access token and ID token is set to 5 minutes, and you
  // are using the SDK, the refresh token will continually refresh".
  // (https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-refresh-token.html)
  // However, I'm not seeing that functionality working so I've taken care of the refresh in getAccessToken, but left this here
  // in case it eventually does work.
  if (Cookies.get("login_method") === "Cognito") {
    try {
      await refreshCognitoToken();
    } catch (e) {
      console.error(`Failed to refresh Cognito auth. Error: ${e.message}`);
    }
  } else if (Cookies.get("login_method") === "Google" && getGoogleRefreshToken()) {
    await refreshGoogleOAuthToken(false);
  }
};

export const initPeriodicRefresh = () => {
  periodicallyRefreshGoogleOrCognito();
};

// TODO: Figure out user page
export const checkAuth = async () => {
  const res = await getAccessToken();
  if (R.isNil(res) || res === "No Login Method Found") {
    return false;
  }
  return true;
};

// Use this function (not Cookies.get("access_token")) to obtain a valid access token (if the user is logged in)
export const getAccessToken = () => {
  try {
    if (Cookies.get("login_method") === "Google" || Cookies.get("login_method") === "Cognito") {
      return Cookies.get("access_token");
    }
    return "No Login Method Found";
  } catch (e) {
    return "No Login Method Found";
  }
};

export const getGoogleRefreshToken = () => Cookies.get("google_refresh_token");

export const isRefreshNeeded = () => !R.isNil(Cookies.get("access_token_expiration"));
