import React, { useState, useMemo, useEffect } from "react";

import moment, { Moment } from "moment";

import * as R from "ramda";

import { SingleDatePicker as Single, DateRangePicker as Range } from "react-dates-temp";
import { ANCHOR_LEFT, ANCHOR_RIGHT, START_DATE } from "react-dates-temp/constants";

import "./DatePickers.scss";

const DATE_FORMAT_MOMENT = "YYYY-MM-DD";

const DEFAULT_MIN_YEAR = 2017;
const DEFAULT_MAX_YEAR = new Date().getFullYear() + 2;

interface DateRange {
  startDate: string;
  endDate: string;
}

// https://github.com/airbnb/react-dates/issues/25#issuecomment-444826302
const renderMonthPicker = years => ({ month, onMonthSelect, onYearSelect }) => (
  <div className="Datepicker_custom_month">
    <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
      {moment.months().map((label, value) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>

    <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
      {years.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </div>
);

interface SingleDatePickerProps {
  date: string;
  onChange: (date: string) => void;
  id?: string;
  mondayOnly?: boolean;
  minYear?: number;
  maxYear?: number;
  isOutsideRange?: (date: string) => boolean;
  isDayBlocked?: (date: string) => boolean;
  [passthroughProp: string]: any;
}

export const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  date,
  onChange,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  isOutsideRange = () => false,
  id = `datePicker_${Math.random()}`,
  isDayBlocked,
  mondayOnly,
  ...passthrough
}) => {
  const [focused, setFocused] = useState();
  const years = useMemo(() => R.range(minYear, maxYear + 1), [minYear, maxYear]);
  return (
    <Single
      id={id}
      small
      block
      noBorder
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      {...passthrough}
      date={date && moment(date, DATE_FORMAT_MOMENT)}
      isOutsideRange={(date: Moment) => isOutsideRange(date.format(DATE_FORMAT_MOMENT))}
      onDateChange={date => {
        onChange(date && date.format(DATE_FORMAT_MOMENT));
      }}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      renderMonthElement={renderMonthPicker(years)}
      isDayBlocked={date => {
        if (isDayBlocked) {
          return isDayBlocked(date.format(DATE_FORMAT_MOMENT));
        }
        if (mondayOnly) {
          return date.isoWeekday() !== 1;
        }
        return false;
      }}
    />
  );
};

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onChange: (newDates: DateRange) => void;
  mondayOnly?: boolean;
  minYear?: number;
  maxYear?: number;
  isOutsideRange?: (date: string) => boolean;
  isDayBlocked?: (date: string) => boolean;
  anchorRight?: boolean;
  [passthroughProp: string]: any;
}

// The trick with this picker is there are two states: the state of the inputs and the state of the
// actually selected date range. When the user is mid-selection, the date range hasn't REALLY
// changed, and thus the onChange shouldn't be called. Since the react-dates picker is controlled,
// we need to control the state of the inputs, but since we're also controlled, we need to take in
// the state of our date range. We know the user is "done picking" when focusedDate is null. When
// that happens, we need to check that we have both a start and end date (they could have made a
// selection that sets either to null), signifying that the date range is valid. We also need to
// check that these new dates do not equal the props we've been given, meaning our internal input
// state is different from the date range state we're getting in (necessitating a call to onChange).
// On top of that, since we're controlled, we need to change our internal input state if the date
// props change. We use a common hooks pattern where we save "prevProps" in a state variable. That
// way, we can check if the specific props have changed. If so, that means we're being controlled.
// However, there's a nuance: when we call our onChange, that's going to change the date props we're
// getting in. Thus, we cheat a little and when we call onChange, we also force prevDates to be our
// new dates, which will make it look on the next render like they didn't actually change. Having
// accounted for that "we're changing the props ourselves" issue, we can first check if the props
// changed, meaning they've changed us. If the props haven't changed, then we check if the inputs
// have changed (and we're also not mid-select). If that's the case, we trigger the onChange.
export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
  mondayOnly = false,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  isOutsideRange = () => false,
  isDayBlocked,
  anchorRight = false,
  ...passthrough
}) => {
  // Saving the previous date props so we can check if we're being controlled and need to change our
  // internal state based on our props.
  const [prevDates, setPrevDates] = useState({
    startDate,
    endDate,
  });

  // State of our inputs. Using moments (even though I hate moment) because these are almost
  // entirely used directly with the react-dates picker, which is all in moments. We'll format them
  // when we send them back to the parent.
  const [dateInputs, setDateInputs] = useState({
    startDate: startDate && moment(startDate, DATE_FORMAT_MOMENT),
    endDate: endDate && moment(endDate, DATE_FORMAT_MOMENT),
  });
  // State of which of the two pickers is set. This is required for react-dates, and mostly
  // meaningless to us. However, when it's null, that means they aren't interacting with the picker.
  const [focusedDate, setFocusedDate] = useState();

  // Years for the year picker
  const years = useMemo(() => R.range(minYear, maxYear + 1), [minYear, maxYear]);

  // Here's where we sync all the states. First, we check if the props changed. If so, we change
  // ourselves. If the props are the same, then we check if we're not focused (editing), our
  // internal range is valid, and our internal range doesn't match our props range. If so, that
  // means our range has changed and we need to call onChange. While we do that, we cheat and set
  // prevDates, since we know calling onChange will cause us to rerender with new props.
  useEffect(() => {
    let formattedStart = dateInputs.startDate && dateInputs.startDate.format(DATE_FORMAT_MOMENT);
    let formattedEnd = dateInputs.endDate && dateInputs.endDate.format(DATE_FORMAT_MOMENT);

    if (prevDates.startDate !== startDate || prevDates.endDate !== endDate) {
      setDateInputs({
        startDate: startDate && moment(startDate, DATE_FORMAT_MOMENT),
        endDate: endDate && moment(endDate, DATE_FORMAT_MOMENT),
      });
      setPrevDates({
        startDate,
        endDate,
      });
    } else if (
      !focusedDate &&
      formattedStart &&
      formattedEnd &&
      (formattedStart !== startDate || formattedEnd !== endDate)
    ) {
      let newDates = {
        startDate: formattedStart,
        endDate: formattedEnd,
      };
      setPrevDates(newDates);
      onChange(newDates);
    }
  }, [startDate, endDate, focusedDate, dateInputs, onChange, prevDates]);
  return (
    <Range
      small
      noBorder
      hideKeyboardShortcutsPanel
      numberOfMonths={2}
      minimumNights={0}
      {...passthrough}
      anchorDirection={anchorRight ? ANCHOR_RIGHT : ANCHOR_LEFT}
      isOutsideRange={(date: Moment) => isOutsideRange(date.format(DATE_FORMAT_MOMENT))}
      startDate={dateInputs.startDate}
      endDate={dateInputs.endDate}
      initialVisibleMonth={() =>
        focusedDate === START_DATE ? moment(startDate) : moment(endDate).subtract(1, "month")
      }
      isDayBlocked={date => {
        if (isDayBlocked) {
          return isDayBlocked(date.format(DATE_FORMAT_MOMENT));
        }
        if (mondayOnly) {
          return date.isoWeekday() !== 1;
        }
        return false;
      }}
      onDatesChange={(dates: { startDate: Moment; endDate: Moment }) => setDateInputs(dates)}
      focusedInput={focusedDate}
      onFocusChange={focusedDate => setFocusedDate(focusedDate)}
      renderMonthElement={renderMonthPicker(years)}
    />
  );
};
