import React from "react";

import "./PercentAOIPlaceholder.scss";

interface PercentAOIPlaceholderProps {
  percentage: number;
}

const PercentAOIPlaceholder: React.FC<PercentAOIPlaceholderProps> = ({ percentage }) => {
  const roundedPercent = Math.round(percentage * 100);
  document.documentElement.style.setProperty("--percentage", `${roundedPercent}%`);
  document.documentElement.style.setProperty("--percentage", `${roundedPercent}%`);

  return (
    <div className="percentAOIPlaceholder">
      <div className="greenPercentBar"></div>
    </div>
  );
};

export default PercentAOIPlaceholder;
