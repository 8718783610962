// Experiment for updates to streaming optimization page

const streamingOptimizationCompanies: string[] = [
  "justin.manus@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "wilson.reyhan@tinuiti.com",
  "benjamin.contesse@tinuiti.com",
  "alex.echeverria@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
];

export const shouldEnableStreamingOptimizationUpdates = (userEmail: string): boolean => {
  return streamingOptimizationCompanies.includes(userEmail);
};
