import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";

import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { LinearLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { Page, Spinner, Skeleton, TableSkeleton, ModalEditTable } from "../Components";

import "./LinearClearanceAssumptions.scss";

const LinearClearanceAssumptions = () => {
  const setError = useSetError();

  const headers = [
    {
      type: "select",
      field: "company",
      label: "Company",
      options: "companies",
      flex: 1,
      modalRow: 0,
      modalWidth: 200,
    },
    {
      type: "week",
      field: "week",
      label: "Week",
      flex: 1,
      modalRow: 0,
      modalWidth: 200,
    },
    {
      type: "percent",
      field: "rate",
      label: "Rate",
      flex: 1,
      modalRow: 0,
      modalWidth: 200,
    },
  ];
  const [tableData, setTableData] = useState();
  const [saving, setSaving] = useState();
  const [selectorOptions, setSelectorOptions] = useState({});

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let result = await LinearLambdaFetch("/assumed_clearance");
          result = await awaitJSON(result);
          setTableData(result.clearanceAssumptions);
          setSelectorOptions({
            companies: R.map(company => ({ label: company, value: company }), result.companies),
          });
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [setError, tableData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);

      // Filter out only rows from the table data that have been changed.
      const changedRows = tableData.filter(row => row.lastmodified);

      await LinearLambdaFetch("/assumed_clearance", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: changedRows,
      });
      setSaving();
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
  }, [setError, tableData]);

  return (
    <Page
      title="Linear Clearance Assumptions"
      pageType="Linear Clearance Assumptions"
      minHeight={600}
      actions={
        <div className={"linearClearanceActions"}>
          <Button variant="primary" onClick={save} className="saveButton">
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      <div className="linearClearanceAssumptionsPageContainer">
        {tableData ? (
          <ModalEditTable
            name="Clearance Assumptions"
            headers={headers}
            tableData={tableData || []}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            filterBar={true}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default LinearClearanceAssumptions;
