// Experiment for the companies that should not have Home Page V2 as their default.
const shouldNotDefaultHome2Companies: string[] = [
  "babylist",
  "blacktux",
  "blinkist",
  "butcherbox",
  "cookunity",
  "fabkids",
  "fabletics",
  "fableticsca",
  "fableticsmen",
  "figure",
  "homechef",
  "huckberry",
  "instacart",
  "instacartca",
  "instacartkroger",
  "instacartqfc",
  "instacartralphs",
  "instacartfredmeyer",
  "justfab",
  "lemonade",
  "lume",
  "pendulum",
  "savagex",
  "shoedazzle",
  "thumbtack",
  "tonal",
  "zocdoc",
  // Add companies here!
];

export const shouldNotDefaultHomeV2 = (company: string): boolean => {
  return shouldNotDefaultHome2Companies.includes(company);
};
