import { engineeringUsers } from "./experiment-utils";

export const shouldEnableFilterPresetsCompanies = (company: string): boolean => {
  const companies: string[] = ["instacart"];
  return companies.includes(company);
};

export const shouldEnableFilterPresets = (userEmail: string): boolean => {
  const users: string[] = [
    "lauren.wolfen@tinuiti.com",
    "micah.kilimann@tinuiti.com",
    "phil.pengiel@tinuiti.com",
    ...engineeringUsers,
  ];
  return users.includes(userEmail);
};
