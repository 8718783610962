import React from "react";

import { Form } from "react-bootstrap";

import { Card, Img } from "../Components";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

export const EditableCard = ({ name, isci, creative, length, value, color, onChange, notLive }) => {
  const CARD_WIDTH = 190;
  const STRIPE_WIDTH = 5;
  const TEXT_HEIGHT = 60;
  const FORM_HEIGHT = 55;

  const thumbHeight = ((CARD_WIDTH - STRIPE_WIDTH) * 9) / 16;

  return (
    <Card
      key={name}
      className={`creativeCardContainer ${notLive ? "notLive" : ""}`}
      style={{
        height: thumbHeight + TEXT_HEIGHT + FORM_HEIGHT,
        width: CARD_WIDTH,
        opacity: value === 0 ? 0.5 : 1,
      }}
    >
      <div className="stripe" style={{ width: STRIPE_WIDTH, backgroundColor: color }} />
      <div className="creativeCard">
        <div className="creativeContent">
          <div
            className="creativeImgBox"
            style={{
              height: thumbHeight,
              minHeight: thumbHeight,
              maxHeight: thumbHeight,
            }}
          >
            <Img
              src={getCreativeThumbnail(creative.company, isci)}
              className="creativeImg"
              unloader={<div className="noImg">Thumbnail not found</div>}
            />
          </div>
          <div className="isci">{name}</div>
          <div className="subtitle" title={`${creative} (${length}s)`}>
            {creative} <span className="length">({length}s)</span>
          </div>
        </div>
        <div className="allocInput">
          <Form.Control
            type="number"
            value={`${value}`}
            onChange={e => onChange(name, parseInt(e.target.value))}
          />
        </div>
      </div>
    </Card>
  );
};

export const StaticCard = ({ name, isci, creative, length, value, color, isLive }) => {
  const CARD_WIDTH = 170;
  const STRIPE_WIDTH = 5;
  const TEXT_HEIGHT = 70;
  const FORM_HEIGHT = 40;

  const thumbHeight = ((CARD_WIDTH - STRIPE_WIDTH) * 9) / 16;
  return (
    <Card
      key={name}
      className="staticCardContainer"
      style={{
        height: thumbHeight + TEXT_HEIGHT + FORM_HEIGHT,
        width: CARD_WIDTH,
        opacity: isLive ? 1 : 0.5,
      }}
    >
      <div className="stripe" style={{ width: STRIPE_WIDTH, backgroundColor: color }} />
      <div className="creativeCard">
        <div className="creativeContent">
          <div
            className="creativeImgBox"
            style={{
              height: thumbHeight,
              minHeight: thumbHeight,
              maxHeight: thumbHeight,
            }}
          >
            <Img
              src={getCreativeThumbnail(creative.company, isci)}
              className="creativeImg"
              unloader={<div className="noImg">Thumbnail not found</div>}
            />
          </div>
          <div className="isci">{name}</div>
          <div className="subtitle" title={`${creative} (${length}s)`}>
            {creative} <span className="length">({length}s)</span>
          </div>
        </div>
        <div className="alloc">{value}%</div>
      </div>
    </Card>
  );
};
