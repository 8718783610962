import React from "react";

import { ButtonGroup } from "react-bootstrap";

import { BPMButton } from ".";
import { typedReactMemo } from "../utils/types";

import "./BPMToggleButton.scss";

interface Option<T> {
  key: T;
  label?: string;
}

interface BPMToggleButtonProps<T> {
  options: readonly (Option<T> | T)[];
  selectedOption: T;
  onChange: (key: T) => void;
  size?: "sm" | "lg";
  className?: string;
  block?: boolean;
  bordered?: boolean;
}

export const BPMToggleButton = typedReactMemo(
  <T extends string>({
    size,
    className = "",
    options,
    selectedOption,
    onChange,
    block,
    bordered,
  }: BPMToggleButtonProps<T>): JSX.Element => {
    let classes = ["bpmToggleButtons"];
    if (block) {
      classes.push("block");
    }
    if (className) {
      classes.push(className);
    }
    return (
      <ButtonGroup toggle size={size} className={classes.join(" ")}>
        {options.map(option => {
          let key: T;
          let label: string | undefined;
          if (typeof option === "string") {
            key = option;
            label = option;
          } else {
            ({ key } = option);
            label = option.label || option.key;
          }
          let active = selectedOption === key;
          return (
            <BPMButton
              key={key}
              active={active}
              bordered={bordered}
              variant={active ? "primary" : "outline-primary"}
              onClick={() => {
                if (!active) {
                  onChange(key);
                }
              }}
            >
              {label}
            </BPMButton>
          );
        })}
      </ButtonGroup>
    );
  }
);
