import "./PendingSegmentationChanges.scss";
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { BPMButton } from "../Components";
import { SegmentEditsMap } from "./SegmentationLabeling";
import PendingSegmentationEdits from "./PendingSegmentationEdits";
import { CustomSegmentsData } from "./SegmentationMapping";

interface IncompleteUpdatesModalProps {
  show: boolean;
  handleClose: () => void;
  saveChanges: () => void;
  incompleteEditsMap: SegmentEditsMap;
  customSegments: CustomSegmentsData[] | undefined;
  dataGranularity: "ad" | "ad_group" | "campaign";
  validChangesCount: number;
}

const IncompleteUpdatesModal: React.FC<IncompleteUpdatesModalProps> = ({
  show,
  handleClose,
  saveChanges,
  incompleteEditsMap,
  customSegments,
  dataGranularity,
  validChangesCount,
}) => {
  const editTypes = customSegments?.map(segment => segment.segmentName) ?? [];

  const incompleteEditsCount = useMemo(() => {
    return Object.keys(incompleteEditsMap).length;
  }, [incompleteEditsMap]);

  const incompleteDataGranularityText = useMemo(() => {
    return `${dataGranularity.replace("_", " ")}${incompleteEditsCount > 1 ? "s" : ""}`;
  }, [dataGranularity, incompleteEditsCount]);

  const completeDataGranularityText = useMemo(() => {
    return `${dataGranularity.replace("_", " ")}${validChangesCount > 1 ? "s" : ""}`;
  }, [dataGranularity, validChangesCount]);

  return (
    <Modal show={show} onHide={handleClose} contentClassName="incompleteUpdatesModal">
      <Modal.Header>
        The following {incompleteDataGranularityText} {incompleteEditsCount > 1 ? "have" : "has"}{" "}
        incomplete info and will not be saved:
      </Modal.Header>
      <Modal.Body className="pendingSegmentationChangesBody">
        <div className="unlabeledSection">
          {Object.keys(incompleteEditsMap).map(key => {
            return (
              <PendingSegmentationEdits
                key={key}
                campaign_key={key}
                editsMap={incompleteEditsMap}
                editTypes={editTypes}
                onlyShowUnset
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="secondary-outline" onClick={handleClose}>
          Back
        </BPMButton>
        {validChangesCount > 0 && (
          <BPMButton
            variant="warning"
            onClick={() => {
              handleClose();
              saveChanges();
            }}
          >
            Save {validChangesCount} complete {completeDataGranularityText}
          </BPMButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default IncompleteUpdatesModal;
