import React, { useMemo } from "react";
import "./DatePickerButton.scss";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DateRange } from "../../../utils/types";
import { MdCalendarToday } from "react-icons/md";
import { BiCaretDown } from "react-icons/bi";
import { Button, ButtonType } from "../../Button";
import { ButtonFrameworkVariant } from "../../ButtonFramework";

const PRETTY_DATE_FORMAT = "M/d/yy";

const makePrettyDate = (date?: string) =>
  date ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(date) : "___";

interface DatePickerButtonProps {
  reference?: React.RefObject<HTMLDivElement>;
  setShow?: (show: boolean, save?: boolean) => void;
  range?: DateRange;
  fullWeeksOnly?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<"START" | "END">>;
  className?: string;
  comparison?: boolean;
  comparisonRange?: DateRange;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = React.memo(
  ({
    reference,
    setShow,
    fullWeeksOnly,
    range,
    setFocus,
    className,
    comparison = false,
    comparisonRange,
  }) => {
    const { start, end } = range || {};
    const { start: comparisonStart, end: comparisonEnd } = comparisonRange || {};

    const resolvedClassName = useMemo(() => {
      let classes = ["DatePickerButton"];
      if (className) {
        classes.push(className);
      }
      if (fullWeeksOnly) {
        classes.push("fullWeeksOnly");
      }
      return classes.join(" ");
    }, [className, fullWeeksOnly]);

    const dateRangeDisplay = useMemo(
      () => (
        <div>
          <span className="singleDate">{`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(
            start
          )}`}</span>{" "}
          –{" "}
          <span className="singleDate" onClick={() => (setFocus ? setFocus("END") : null)}>
            {`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(end)}`}
          </span>
          {comparison && (
            <span className="comparisonPeriod">
              <span> vs </span>
              <span className="singleDate">{`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(
                comparisonStart
              )}`}</span>{" "}
              –{" "}
              <span className="singleDate" onClick={() => (setFocus ? setFocus("END") : null)}>
                {`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(comparisonEnd)}`}
              </span>
            </span>
          )}
          {/* <BiCaretDown /> */}
        </div>
      ),
      [fullWeeksOnly, start, end, setFocus, comparison, comparisonEnd, comparisonStart]
    );

    return (
      <Button
        type={ButtonType.FILLED}
        background="dark"
        ref={reference}
        className={resolvedClassName}
        onClick={() => (setShow ? setShow(true) : null)}
        icon={<MdCalendarToday />}
        variant={ButtonFrameworkVariant.LEADING_ICON}
      >
        <div className="dpRangeAndCaret">
          <div className="dpbRange">{dateRangeDisplay}</div>
          <BiCaretDown />
        </div>
      </Button>
    );
  }
);

export default DatePickerButton;
