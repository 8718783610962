import React, { useMemo, useState, useCallback, useEffect } from "react";

import { Router } from "@reach/router";

import * as R from "ramda";

import { ListGroup, Button, Tooltip } from "react-bootstrap";

import { useNetworkMap } from "../redux/networks";
import { useSetError } from "../redux/modals";

import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useStateFunction } from "../utils/hooks/useData";
import useLocation from "../utils/hooks/useLocation";
import { useNestedNav } from "../utils/hooks/useNav";

import {
  Page,
  OldFilterBar,
  FullPageSpinner,
  Img,
  Skeleton,
  FilterBarSkeleton,
  FILTER_BAR_SKELETON_HEIGHT,
  BreadcrumbTitle,
  ListGroupSkeleton,
  DateBox,
  OverlayTrigger,
} from "../Components";

import OrderView from "./OrderView";

import "./StreamingBuying.scss";

const localStoragePrefix = "streamingV2OrderView";
export const makeStrBuyingLocalStorageKey = (company, network, id) =>
  `${localStoragePrefix}Company:${company}Network:${network}OrderID:${id}`;

export const getLocalChangesForCompany = company => {
  let keyCount = window.localStorage.length;
  let changed = {};
  for (let i = 0; i < keyCount; ++i) {
    let key = window.localStorage.key(i);
    let matches = key.match(
      new RegExp(`^${localStoragePrefix}Company:${company}Network:.+?OrderID:(\\d+)$`)
    );
    if (matches) {
      let [key, id] = matches;
      let val = JSON.parse(window.localStorage.getItem(key));
      let changes = R.prop("flightChangeMap", val);
      if (changes && !R.isEmpty(changes)) {
        changed[id] = true;
      }
    }
  }
  return changed;
};

const ROUTES = [
  {
    key: "network",
    label: "*",
    uri: "*",
    subRoutes: [
      {
        key: "order",
        label: "*",
        uri: "*",
      },
    ],
  },
];

const FILTER_BAR_OPTIONS = [
  { name: "shortCode", label: "Short Code" },
  { name: "name", label: "Name" },
  { name: "networkGroup", label: "Network Group" },
];

export const toStatus = statusCode => {
  switch (statusCode) {
    case 0:
      return "Pending";
    case 11:
      return "Sent";
    case 20:
      return "Confirmed";
    default:
      return "Unknown";
  }
};

export const useBuyingInfo = () => {
  const [orderInfo, setOrderInfo] = useState();
  const [tagInfo, setTagInfo] = useState();
  const { company } = useLocation();
  const setError = useSetError();

  useEffect(() => {
    if (!orderInfo) {
      let bail = false;
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/order_info", {
            params: { company },
          });
          let rawData = await awaitJSON(res);
          if (bail) {
            return;
          }
          setOrderInfo(rawData);
        } catch (e) {
          setError({
            message: `Failed to fetch order info. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [orderInfo, company, setError]);

  useEffect(() => {
    if (!tagInfo) {
      let bail = false;
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/tag_info", {
            params: {
              company,
            },
          });
          let rawData = await awaitJSON(res);
          if (bail) {
            return;
          }
          setTagInfo(rawData);
        } catch (e) {
          setError({
            message: `Failed to fetch tag info. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [tagInfo, company, setError]);

  return useMemo(() => {
    if (!orderInfo || !tagInfo) {
      return [];
    }
    let networkOrderMap = {};
    const unsavedMap = getLocalChangesForCompany(company);
    let unsent = 0;
    let unconfirmed = 0;
    for (let orderLine of orderInfo) {
      let networkOrders = networkOrderMap[orderLine.network] || [];
      networkOrders.push(orderLine);
      networkOrderMap[orderLine.network] = networkOrders;
      if (orderLine.status === 0) {
        unsent++;
      } else if (orderLine.status < 20) {
        unconfirmed++;
      }
    }

    networkOrderMap = R.map(
      R.sortWith([R.descend(R.prop("start")), R.descend(R.prop("end")), R.ascend(R.prop("id"))]),
      networkOrderMap
    );

    let missingTags = 0;
    for (let tag of tagInfo) {
      if (!(tag.hasPlacement || tag.hasOverride)) {
        missingTags++;
      }
    }
    return [
      networkOrderMap,
      {
        unsaved: R.pipe(R.keys, R.length)(unsavedMap),
        unsent,
        unconfirmed,
        missingTags,
      },
    ];
  }, [orderInfo, tagInfo, company]);
};

const OrderList = React.memo(({ orders, network, navigate }) => {
  if (orders.length) {
    return (
      <ListGroup>
        {orders.map(({ id, start, end, status, descriptions }) => {
          let detailsList = R.pipe(
            R.map(details => details || "(none)"),
            R.join(", ")
          )(descriptions);
          return (
            <ListGroup.Item
              key={id}
              action
              className="orderRow"
              onClick={() => {
                navigate(`./${network}/${id}`);
              }}
            >
              <div className="id">{id}</div>
              <DateBox className="date" start={start} end={end} />
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
                delay={500}
                overlay={<Tooltip>{detailsList}</Tooltip>}
              >
                <div className="detailsList">{detailsList}</div>
              </OverlayTrigger>
              <div className="status">
                {toStatus(status)} ({status})
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
  return <div>No Orders</div>;
});

const NetworkView = ({ navigate, network, orders }) => {
  const networkMap = useNetworkMap();
  const networkInfo = networkMap[network];
  if (!networkMap) {
    return null;
  }
  if (!networkInfo) {
    // Network is not defined. If it isn't in all caps, try that first.
    if (network !== network.toUpperCase()) {
      navigate(`./${network.toUpperCase()}`, { replace: true });
    } else {
      navigate("./", { replace: true });
    }
    return null;
  }
  const { color } = networkInfo;

  return (
    <div className="networkView">
      <div className="buttonRow">
        <Button
          variant="primary"
          onClick={() => {
            navigate(`./${network}/new`);
          }}
        >
          Create Order
        </Button>
      </div>
      <div className="orderList">
        <OrderList orders={orders} network={network} navigate={navigate} />
      </div>
      <div className="stripe" style={{ backgroundColor: color }} />
    </div>
  );
};

const NetworkList = ({ navigate, network, setProblemCounts }) => {
  const [filter, setFilterRaw] = useStateFunction(() => true);
  const networkMap = useNetworkMap();
  const networks = useMemo(() => {
    if (networkMap) {
      return R.pipe(
        R.values,
        R.filter(network => network.buyable && !network.parent),
        R.sortBy(R.prop("shortCode"))
      )(networkMap);
    }
  }, [networkMap]);

  const filteredNetworks = useMemo(() => {
    if (networks) {
      return R.filter(filter, networks);
    }
  }, [networks, filter]);

  const setFilter = useCallback(
    filter => {
      setFilterRaw(filter);
    },
    [setFilterRaw]
  );

  const [networkOrderMap, problemCounts] = useBuyingInfo();
  useEffect(() => {
    setProblemCounts(problemCounts);
  }, [problemCounts, setProblemCounts]);

  const [bookedOnly, setBookedOnly] = useState(false);

  return networkOrderMap ? (
    <>
      <div className="filterContainer">
        <Button
          variant={`${bookedOnly ? "" : "outline-"}primary`}
          onClick={() => setBookedOnly(R.not)}
        >
          Booked Only
        </Button>
        <OldFilterBar options={FILTER_BAR_OPTIONS} onFilter={setFilter} lines={networks} />
      </div>
      <div className="networkListContainer">
        <div className="networkList">
          {filteredNetworks.reduce((rows, { shortCode, color, name }) => {
            if (!bookedOnly || networkOrderMap[shortCode]) {
              return [
                ...rows,
                <div
                  key={shortCode}
                  style={{ borderColor: color }}
                  className={`networkRow${shortCode === network ? " expanded" : ""}`}
                  onClick={() => {
                    if (network === shortCode) {
                      navigate("./");
                    } else {
                      navigate(`./${shortCode}`);
                    }
                  }}
                >
                  <div className="logo">
                    <Img
                      alt={shortCode}
                      src={`https://cdn.blisspointmedia.com/networks/${shortCode}.png`}
                    />
                  </div>
                  <div className="name">
                    {name} <span className="shortCode">({shortCode})</span>
                  </div>
                </div>,
              ];
            }
            return rows;
          }, [])}
        </div>
        {network && (
          <NetworkView
            navigate={navigate}
            network={network}
            orders={networkOrderMap[network] || []}
          />
        )}
      </div>
    </>
  ) : (
    <Skeleton>
      {/* TODO: might be nice to detect if network is selected (we know from URL) and show right
          pane skeleton as well */}
      <FilterBarSkeleton />
      <ListGroupSkeleton
        y={FILTER_BAR_SKELETON_HEIGHT + 24}
        lineHeight={70}
        height={R.subtract(R.__, FILTER_BAR_SKELETON_HEIGHT + 24)}
      />
    </Skeleton>
  );
};

export const StreamingBuying = ({ navigate }) => {
  const networkMap = useNetworkMap();
  const path = useNestedNav({
    baseURL: "streaming/buying",
    routes: ROUTES,
  });
  const { company } = useLocation();

  const [problemCounts, setProblemCounts] = useState();

  const hasProblems = useMemo(() => R.pipe(R.values, R.any(R.gt(R.__, 0)))(problemCounts), [
    problemCounts,
  ]);

  return (
    <Page
      title={<BreadcrumbTitle path={path} title="Streaming Buying" navigate={navigate} />}
      pageType="Streaming Buying"
      minHeight={600}
      minWidth={1000}
      actions={
        path.length === 1 &&
        hasProblems && (
          <div className="streamingBuyingProblemPane">
            {problemCounts.unsaved > 0 && (
              <Button variant="link" onClick={() => navigate(`/${company}/streaming/status`)}>
                Unsaved: {problemCounts.unsaved}
              </Button>
            )}
            {problemCounts.unsent > 0 && (
              <Button variant="link" onClick={() => navigate(`/${company}/streaming/status`)}>
                Unsent: {problemCounts.unsent}
              </Button>
            )}
            {problemCounts.unconfirmed > 0 && (
              <Button variant="link" onClick={() => navigate(`/${company}/streaming/status`)}>
                Unconfirmed: {problemCounts.unconfirmed}
              </Button>
            )}
            {problemCounts.missingTags > 0 && (
              <Button
                variant="link"
                onClick={() => navigate(`/${company}/streaming/status/generate`)}
              >
                Missing Tags: {problemCounts.missingTags}
              </Button>
            )}
          </div>
        )
      }
    >
      <div className="streamingBuying">
        {networkMap ? (
          <Router>
            <OrderView path=":network/:orderID" />
            <NetworkList path="/*network" setProblemCounts={setProblemCounts} />
          </Router>
        ) : (
          <FullPageSpinner />
        )}
      </div>
    </Page>
  );
};

export default StreamingBuying;
