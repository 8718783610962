import { useState, useEffect, useMemo } from "react";

import * as R from "ramda";

import { useDispatch, useSelector } from "react-redux";

import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { getSeriesColor } from "../utils/colors";

import { makeReducer } from "./utils";
import { useSetError } from "./modals";

const ROOT = "creative" as const;
const SET_CREATIVE_MAP = `${ROOT}/SET_CREATIVE_MAP`;
const SET_CREATIVE_MAP_IF_UNSET = `${ROOT}/SET_CREATIVE_MAP_IF_UNSET`;
interface CreativeTimelineDateRange {
  startDate: string;
  endDate: string;
}
interface CreativeTimeline {
  current: Partial<CreativeTimelineDateRange>;
  future: Partial<CreativeTimelineDateRange>;
  past: Partial<CreativeTimelineDateRange>;
  ranges: CreativeTimelineDateRange[];
}

type TimelineKey = "streaming" | "linear";
export type MediaType = "streaming" | "audio" | "linear" | "display";
interface CreativeDataItem {
  isci: string;
  liveLinear: boolean;
  liveStreaming: boolean;
  name: string;
  modelEntry?: number;
  // TODO: parent and variant should be deprecated soon
  parent: string;
  variant: string;
  concept: string;
  length: number;
  language: "English" | "Spanish" | "French";
  avail: "N" | "L";
  // "hd: string;"" is technically a thing but it's useless
  file: string;
  clickthroughUrl?: string;
  media_types: MediaType[];
  retired: boolean;
  creativeTags: Record<string, string[]>;
  timeline: Record<TimelineKey, CreativeTimeline>;
  mostRecentDateRange?: { startDate: string; endDate: string };
  flashtalking_id?: number;
}

interface CreativeData {
  [isci: string]: CreativeDataItem;
}

interface CreativeReduxState {
  [company: string]: CreativeData;
}

interface CreativeReduxRootState {
  [ROOT]: CreativeReduxState;
}

const creativeMapSelector = (company: string) => (
  state: CreativeReduxRootState
): CreativeData | undefined => state[ROOT][company];

export type CreativeMapItem = Omit<CreativeDataItem, "timeline"> & {
  ranges: CreativeTimelineDateRange[];
};
export interface CreativeMap {
  [isci: string]: CreativeMapItem;
}

interface IsciLiveOnDate {
  (args: { isci: string; date: string }): boolean;
}

export const isciLiveOnDate = (creativeMap: CreativeMap): IsciLiveOnDate => ({ isci, date }) =>
  R.any(
    range => range.startDate <= date && (!range.endDate || range.endDate >= date),
    creativeMap[isci]?.ranges || []
  );

interface UseCreativeMapProps {
  company: string;
  streaming?: boolean;
  mediaTypes: MediaType[];
}

interface UseCreativeMapPayload {
  creativeMap?: CreativeMap;
  colorMap: Record<string, string>;
  isciLiveOnDate: IsciLiveOnDate;
}

export const useCreativeMap = ({
  company,
  streaming = true,
  mediaTypes,
}: UseCreativeMapProps): UseCreativeMapPayload => {
  const dispatch = useDispatch();
  const setError = useSetError();
  const creativeData = useSelector(creativeMapSelector(company));

  const [creativeMap, colorMap] = useMemo(() => {
    // There are two steps to this process. First we need to turn the payload inside-out a bit to
    // group by streaming vs linear. Second, we need to sort the output in order to get the colors
    // in a consistent order. The trick here is realizing that there may be many non-live creatives
    // that are not likely to be touched. When we run out of colors it starts randomly generating
    // them, which usually produces ugly colors. So we want the most-likely-to-be-used creatives to
    // get the first pick of colors. The user will likely be editing rotations on creatives that are
    // currently live. They might look into the future and set rotations on creatives that haven't
    // started. They likely won't be editing creatives that are not live and aren't set to become
    // live, but they might have a rotation that currently runs a now-non-live creative, so it needs
    // a color. So group them by current, future, and past. Within that order, sort by ISCI. Then
    // merge the lists and allocate the colors in order. We can also do this separately for
    // linear/streaming
    let groupingMap: Record<"future" | "current" | "past" | "never", string[]> = {
      future: [],
      current: [],
      past: [],
      never: [],
    };

    let creativeMap: CreativeMap = {};

    let type: TimelineKey = streaming ? "streaming" : "linear";

    for (let { timeline, isci, ...rest } of R.values(creativeData || {})) {
      if (R.pipe(R.intersection(mediaTypes), R.length, R.not)(rest.media_types)) {
        continue;
      }
      let { ranges } = timeline[type];
      creativeMap[isci] = {
        ...rest,
        isci,
        ranges,
      };
      let bucket = "never";
      for (let ourBucket of ["current", "future", "past"]) {
        if (R.path([type, ourBucket, "startDate"], timeline)) {
          bucket = ourBucket;
          break;
        }
      }
      groupingMap[bucket].push(isci);
    }

    let colorMap: Record<string, string> = {};

    let colorI = 0;
    colorMap = R.pipe<typeof groupingMap, string[][], string[], Record<string, string>>(
      R.values,
      R.flatten,
      R.reduce((colorMap, isci) => {
        colorMap[isci] = getSeriesColor(colorI++);
        return colorMap;
      }, colorMap)
    )(groupingMap);

    return [creativeMap, colorMap];
  }, [creativeData, streaming, mediaTypes]);

  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (company && !(creativeData || fetching)) {
      setFetching(true);
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/", {
            params: {
              company,
              timeline: 1,
              include_retired: 1,
            },
          });
          let data = await awaitJSON(res);

          dispatch({
            type: SET_CREATIVE_MAP_IF_UNSET,
            company,
            data,
          });
          setFetching(false);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Couldn't fetch creative map. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [creativeData, company, dispatch, setError, mediaTypes, fetching]);

  return {
    creativeMap,
    colorMap,
    isciLiveOnDate: isciLiveOnDate(creativeMap),
  };
};

const setCreativeMap = (
  state: CreativeReduxState,
  { data, company }: { data: CreativeData; company: string }
) => ({
  ...state,
  [company]: data,
});

const setCreativeMapIfUnset = (
  state: CreativeReduxState,
  { data, company }: { data: CreativeData; company: string }
) => ({
  [company]: data,
  ...state,
});

export default makeReducer<CreativeReduxState>(
  {},
  {
    [SET_CREATIVE_MAP]: setCreativeMap,
    [SET_CREATIVE_MAP_IF_UNSET]: setCreativeMapIfUnset,
  }
);
