import React, { useState, useEffect } from "react";
import { MdLock } from "react-icons/md";
import * as R from "ramda";
import { ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../utils/hooks/useLocation";
import { Spinner } from "../Components/Spinner";
import { Page } from "../Components";
import CircularProgressBar from "../Components/CircularProgressBar/CircularProgressBar";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps } from "@reach/router";
import BudgetsBilling from "./BudgetsBilling/BudgetsBilling";
import CoreReporting from "./CoreReporting/CoreReporting";
import Data from "./Data/Data";
import PlatformAccess from "./PlatformAccess/PlatformAccess";
import PlatformBudgets from "./PlatformBudgets/PlatformBudgets";
import PlatformChecklist from "./PlatformChecklist/PlatformChecklist";
import { getClientInfo } from "./OnboardingUtils";
import "./Onboarding.scss";

const enum TabKey {
  INTRO = "intro",
  PLATFORM_CHECKLIST = "platform-checklist",
  PLATFORM_ACCESS = "platform-access",
  CORE_REPORTING = "core-reporting",
  BUDGETS_BILLING = "budgets-billing",
  PLATFORM_BUDGETS = "platform-budgets",
  DATA = "data",
}

const Onboarding: React.FC = ({ navigate }: RouteComponentProps) => {
  const { company } = useLocation();
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [dataPercentage, setDataPercentage] = useState<number>(0);
  const [budgetPercentage, setBudgetPercentage] = useState<number>(0);
  const [coreReportingPercentage, setCoreReportingPercentage] = useState<number>(0);
  const [platformChecklistPercentage, setPlatformChecklistPercentage] = useState<number>(0);

  const platformLocked = platformChecklistPercentage === 0;

  const NAVS = [
    { label: "Intro", key: TabKey.INTRO, percentage: 100 },
    {
      label: "1. Platform Checklist",
      key: TabKey.PLATFORM_CHECKLIST,
      percentage: platformChecklistPercentage,
    },
    {
      label: "2. Platform Access",
      key: TabKey.PLATFORM_ACCESS,
      percentage: 0,
      isLocked: platformLocked,
    },
    { label: "3. Core Reporting", key: TabKey.CORE_REPORTING, percentage: coreReportingPercentage },
    { label: "4. Budgets/Billing", key: TabKey.BUDGETS_BILLING, percentage: budgetPercentage },
    {
      label: "5. Platform Budgets",
      key: TabKey.PLATFORM_BUDGETS,
      percentage: 0,
      isLocked: platformLocked,
    },
    { label: "6. Data", key: TabKey.DATA, percentage: dataPercentage },
  ];

  useEffect(() => {
    const fetchClientInfo = async () => {
      const info = await getClientInfo(company);
      if (R.isEmpty(info)) {
        setClientInfo({ company, responses: [], changeLog: [] });
      }
      setClientInfo(info);
    };

    fetchClientInfo();
  }, [company]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "client-info-page-dev",
    defaultKey: TabKey.INTRO,
  });

  if (!clientInfo) {
    return <Spinner size={2} />;
  }

  const handleNavClick = (navKey: string) => {
    const selectedNav = NAVS.find(nav => nav.key === navKey);

    if (selectedNav?.isLocked) {
      console.warn(`Cannot navigate to ${selectedNav.label}. The tab is locked.`);
      return;
    }

    goToTab(navKey);
  };

  const handlePlatformChecklistPercentageChange = (percentage: number) => {
    setPlatformChecklistPercentage(percentage);
  };

  const handleDataPercentageChange = (percentage: number) => {
    setDataPercentage(percentage);
  };

  const handleBudgetsPercentageChange = (percentage: number) => {
    setBudgetPercentage(percentage);
  };

  const handleCoreReportingPercentageChange = (percentage: number) => {
    setCoreReportingPercentage(percentage);
  };

  return (
    <span className="onboardingPageContainer">
      <Page
        title="Onboarding"
        pageType="Onboarding"
        app2Redesign
        navs={NAVS}
        onNav={handleNavClick}
        selectedNav={tab}
        navRenderer={label => {
          const navItem = NAVS.find(nav => nav.label === label);
          if (!navItem) {
            return <span>{label}</span>;
          }

          return (<span key={navItem.key} className="onboardingProgress">
            <CircularProgressBar
              className="onboardingProgressBar"
              percentage={navItem.percentage}
              size={60}
              icon={navItem.isLocked ? <MdLock size={30} />: undefined}
            />
              {label}
            </span>
          );
        }}
      >
        <div>
          {tab === TabKey.INTRO && (
            <div>
              <h1>Intro</h1>
            </div>
          )}
          {tab === TabKey.PLATFORM_CHECKLIST && (
            <PlatformChecklist
              clientInfo={clientInfo}
              onPercentageChange={handlePlatformChecklistPercentageChange}
            />
          )}
          {tab === TabKey.PLATFORM_ACCESS && <PlatformAccess />}
          {tab === TabKey.CORE_REPORTING && (
            <CoreReporting
              clientInfo={clientInfo}
              onPercentageChange={handleCoreReportingPercentageChange}
            />
          )}
          {tab === TabKey.BUDGETS_BILLING && (
            <BudgetsBilling
              clientInfo={clientInfo}
              onPercentageChange={handleBudgetsPercentageChange}
            />
          )}
          {tab === TabKey.PLATFORM_BUDGETS && <PlatformBudgets />}
          {tab === TabKey.DATA && (
            <Data clientInfo={clientInfo} onPercentageChange={handleDataPercentageChange} />
          )}
        </div>
      </Page>
    </span>
  );
};

export default Onboarding;
