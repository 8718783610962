import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Snapchat";

const Snapchat = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Snapchat is a social media platform that consists of sending timed images to people or
              groups. For Snapchat, the only available in-platform incrementality approach is a
              user-segmented incrementality test.
            </div>
            <br />
            <Img src={`${INC_CDN}/Snapchat_ConversionLift.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Sample Output",
                "Signal Enrichment",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Conversion Lift",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        User level lift test where users are randomly assigned to either be served
                        the focal ad or held out from ad serving (ghost bidding/ghost ads type
                        setup)
                      </li>
                      <li>
                        Randomization is conducted by using opportunity logging data to ensure that
                        both groups have the same opportunities to view each type of snap ad based
                        on their user behavior
                      </li>
                      <li>
                        Lift is calculated by comparing KPI responses in the assigned test and
                        holdout groups
                      </li>
                      <li>
                        Incremental conversions are defined as the number of conversions driven by
                        Snap advertising that wasn’t already going to occur
                      </li>
                      <li>Minimum 2MM unique audience reach over 3-6 weeks to run the test</li>
                      <li>To begin a test email the Snapchat marketing science team.</li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>Can provide offline events as well for a holistic measurement.</li>
                      <li>
                        Opportunity logging compensates for the differences in user behavior to
                        ensure equal opportunity to see ads between the treatment and control
                        groups.
                      </li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        The use of an{" "}
                        <a href="https://www.remerge.io/findings/blog-post/incrementality-tests-101-intent-to-treat-psa-ghost-ads-and-ghost-bids">
                          intent-to-treat (ITT)
                        </a>{" "}
                        approach may underestimate the effect of the treatment.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              800
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>Testing typically lasts 3-6 weeks</li>
                    </ul>
                  ),
                },
                {
                  Element: "Method",
                  Details: (
                    <ul className="basicList">
                      <li>Focusing on Snap Ads/Collection Ads (70%+ of budget)</li>
                      <li>Avoid more expensive Dynamic Product Ads, MFD, Lens, etc.</li>
                    </ul>
                  ),
                },
                {
                  Element: "Audience",
                  Details: (
                    <ul className="basicList">
                      <li>Audience size around 2M unique audiences.</li>
                      <li>
                        Starting with a broad audience rather than highly targeted if you are newer
                        to testing/advertising on Snap.
                      </li>
                      <li>This can help the ad auction mechanics optimize.</li>
                    </ul>
                  ),
                },
                {
                  Element: "Holdout Size",
                  Details: (
                    <ul className="basicList">
                      <li>5%-10% holdout group typically recommended.</li>
                    </ul>
                  ),
                },
                {
                  Element: "Target Details",
                  Details: (
                    <ul className="basicList">
                      <li>
                        Holistic Coverage: Providing online and offline KPIs for Snap to run the
                        test on.
                      </li>
                      <li>
                        Having an Event Quality Score of 5 or more ensures that the data the client
                        is sending to Snap is clean.
                      </li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              100
            )}
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <ul className="basicList">
              <li>Statistical significance/detection of lift during the study</li>
              <li>View of results for each KPI the client provided to snap during the test</li>
              <li>Snap can provide results for both online and offline KPIs</li>
            </ul>
            <br />
            <iframe
              allowFullScreen
              title="Sample Output"
              src="https://drive.google.com/file/d/1I8fH_kVHulgisv4s3MqUBx_m05vP69tO/preview?authuser=0"
            />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              For Snap Conversion Lift, it is strongly recommended that the{" "}
              <a href="https://businesshelp.snapchat.com/s/article/conversions-api?language=en_US">
                Snap Conversions API (CAPI)
              </a>{" "}
              be implemented in order to strengthen conversion modeling and increase the overall
              fidelity of measurement. This is especially important for tests of lower-funnel
              outcomes.
            </div>
            <br />
            <div className="basicText">
              Snap CAPI is a privacy-centric protocol that allows a brand to directly pass web, app,
              and offline events to Snap via a server-to-server (S2S) integration. This helps Snap’s
              systems to optimize ad campaigns, improve targeting, and measure the conversions that
              result from Snap campaigns.
            </div>
            <br />
            <div className="basicText">
              If a brand wants to include mobile conversions as an outcome in a Conversion Lift
              study, then an MMP integration is required. The details will differ somewhat depending
              on the MMP:
            </div>
            <ul className="basicList">
              <li>
                <a href="https://help.adjust.com/en/article/snapchat">Adjust</a>
              </li>
              <li>
                <a href="https://support.appsflyer.com/hc/en-us/articles/211011983-Snapchat-Advanced-SRN-integration-setup">
                  AppsFlyer
                </a>
              </li>
              <li>
                <a href="https://help.branch.io/">Branch</a>
              </li>
              <li>
                <a href="https://support.kochava.com/campaign-management/create-a-snapchat-campaign/">
                  Kochava
                </a>
              </li>
              <li>
                <a href="https://docs.mparticle.com/integrations/snapchat/event/">mParticle</a>
              </li>
              <li>
                <a href="https://support.singular.net/hc/en-us/articles/115003234523-Snapchat-Ads-Attribution-Integration">
                  Singular
                </a>
              </li>
              <li>
                <a href="https://docs.tenjin.com/docs/snapchat#snap">Tenjin</a>
              </li>
            </ul>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
    </div>
  );
});

export default Snapchat;
