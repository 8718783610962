import React, { useState, useEffect, useMemo } from "react";

import * as R from "ramda";

import { Button, Tooltip } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { makeHeatMap } from "../utils/colors";

import { LinearOptimizationsLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { BPMTable, FullPageSpinner, Img, OverlayTrigger } from "../Components";

import "./StageTwoDiagnostics.scss";

const StageTwoDiagnostics = ({ kpi, branch, setTimestamp }) => {
  const setError = useSetError();
  const [dataMap, setDataMap] = useState({});
  const [showCreatives, setShowCreatives] = useState(false);

  let data = dataMap[`${kpi}_${branch}`];

  useEffect(() => {
    return () => {
      setTimestamp();
    };
  }, [setTimestamp]);

  useEffect(() => {
    if (dataMap[`${kpi}_${branch}`]) {
      setTimestamp(dataMap[`${kpi}_${branch}`].stageTwoFileTimeStamp);
      return;
    }
    let bail = false;
    (async () => {
      try {
        let res = await LinearOptimizationsLambdaFetch("/stage_two_diagnostics", {
          params: {
            kpi,
            branch,
          },
        });
        let data = await awaitJSON(res);
        if (bail) {
          return;
        }
        setDataMap(current => ({ ...current, [`${kpi}_${branch}`]: data }));
        setTimestamp(data.stageTwoFileTimeStamp);
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
    return () => {
      bail = true;
    };
  }, [dataMap, kpi, branch, setError, setTimestamp]);

  // Create Headers and Super Headers
  const [headers, superHeaders] = useMemo(() => {
    if (!data || R.isEmpty(data)) {
      return [];
    }

    let superHeaders = [{ span: 4 }];
    let headers = [
      {
        label: "Network",
        name: "network",
        width: 110,
        sortPriority: 1,
        sortAscending: true,
        renderer: data => {
          return (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.CENTER}
              overlay={<Tooltip>{data.network}</Tooltip>}
            >
              <div className="stageTwoNetworkLogos">
                <Img src={`https://cdn.blisspointmedia.com/networks/${data.network}.png`} />
              </div>
            </OverlayTrigger>
          );
        },
      },
      { label: "Avail", name: "avail", width: 80, sortPriority: 2 },
      {
        label: "Rotation ID",
        name: "rotation_id",
        width: 115,
        sortPriority: 3,
        sortAscending: true,
      },
      {
        label: "Rotation Name",
        name: "rotation_name",
        flex: 2,
        minFlexWidth: 140,
      },
    ];
    let heatMapMap = {};

    let headerConfig = [
      { path: ["ad_raw_effect"], superLabel: "Stage One AdRawEffect" },
      {
        path: ["adeffect_per_dollar"],
        superLabel: "Stage One AdEffect Per Dollar",
      },
      { path: ["stageTwoAvg"], superLabel: "Stage Two AdEffect Per Dollar" },
    ];

    if (showCreatives) {
      for (let creative of data.creatives) {
        headerConfig.push({
          path: ["stageTwoByCreative", creative],
          superLabel: creative,
        });
      }
    }

    for (let { path, superLabel } of headerConfig) {
      superHeaders.push({
        span: 2,
        data: superLabel,
      });
      for (let length of ["15", "30"]) {
        let heatMapKey = `${path.join("-")}_${length}`;
        let sorted = [];
        for (let row of data.rows) {
          let val = R.path([length, ...path], row);
          if (!R.isNil(val)) {
            sorted.push(val);
          }
        }
        sorted = R.sortBy(R.identity, sorted);

        let lowerPercentile = 0.05;
        let upperPercentile = 0.95;

        let min = sorted[Math.round((sorted.length - 1) * lowerPercentile)];
        let max = sorted[Math.round((sorted.length - 1) * upperPercentile)];

        let median = sorted[Math.floor(sorted.length / 2)];
        if (path[0] === "ad_raw_effect") {
          heatMapMap[heatMapKey] = makeHeatMap({
            min,
            max,
            midpoint: median,
            lowColor: "#FFFFFF",
            midColor: "#B3FFD9",
          });
        } else {
          heatMapMap[heatMapKey] = makeHeatMap({
            min,
            max,
            midpoint: median,
          });
        }

        headers.push({
          label: `:${length}`,
          name: `${superLabel} ${length}`,
          flex: 1,
          minFlexWidth: superLabel === "Stage Two AdEffect Per Dollar" ? 200 : 140,
          nonFilterable: true,
          contentGetter: data => R.path([length, ...path], data) || 0,
          renderer: data => {
            let val = R.path([length, ...path], data);
            let hasValue = !R.isNil(val);
            let classes = ["heatMapCell"];

            if (
              path[0] === "stageTwoAvg" &&
              hasValue &&
              R.pipe(R.path([length, "ad_raw_effect"]), R.isNil)(data)
            ) {
              classes.push("unseenCombo");
            }
            if (length === "15") {
              classes.push("leftCell");
            }
            if (length === "30") {
              classes.push("rightCell");
            }
            return (
              <div
                className={classes.join(" ")}
                style={
                  hasValue
                    ? {
                        backgroundColor: heatMapMap[heatMapKey](val),
                      }
                    : {}
                }
              >
                {hasValue
                  ? val.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })
                  : "-"}
              </div>
            );
          },
        });
      }
    }

    return [headers, superHeaders];
  }, [data, showCreatives]);

  return (
    <div className="stageTwoDiagnostics">
      {data ? (
        !R.isEmpty(data) ? (
          <BPMTable
            alternateColors={false}
            noRowsRenderer={() => <div className="noFailures">No data to show</div>}
            data={data.rows}
            superHeaders={superHeaders}
            superHeadersRenderer={({ data, style, classes }) => {
              classes.push("superHeader");
              // If it's an empty super header, don't add the underline
              if (data) {
                classes.push("superHeaderWithData");
              }

              if (
                data !== "Stage One AdRawEffect" &&
                data !== "Stage One AdEffect Per Dollar" &&
                data !== "Stage Two AdEffect Per Dollar"
              ) {
                classes.push("creativeSuperHeaders");
              }

              if (data === "Stage Two AdEffect Per Dollar") {
                return (
                  <div style={style} className={classes.join(" ")}>
                    {data}
                    {
                      <Button
                        className="expandButton"
                        variant="outline-secondary"
                        onClick={() => setShowCreatives(!showCreatives)}
                      >
                        {showCreatives ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />}
                      </Button>
                    }
                  </div>
                );
              } else {
                return (
                  <div style={style} className={classes.join(" ")}>
                    {data}
                  </div>
                );
              }
            }}
            headers={headers}
          />
        ) : (
          <div className="noStageTwoData">No Stage Two Data for this KPI</div>
        )
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};

export default StageTwoDiagnostics;
