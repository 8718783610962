import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { MiscLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  SelectorOption,
  PendingChangesPanel,
  PendingChangesControls,
} from "../Components";
import "./OutOfHomeBuys.scss";
import { OOHBuy, UpdateOOHBuysBody } from "@blisspointmedia/bpm-types/dist/OutOfHomeBuys";
import headers from "./OutOfHomeBuysTableHeaders";

const OutOfHomeBuys: React.FC = () => {
  const setError = useSetError();
  const [saving, setSaving] = useState(false);
  const [tableData, setTableData] = useState<OOHBuy[]>();
  const [newOOHBuysRows, setNewOOHBuysRows] = useState<OOHBuy[]>([]);
  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const [oohBuysModalInputErrorMessage, setOOHBuysModalInputErrorMessage] = useState<string>("");
  const [originalOOHBuysData, setOriginalOOHBuysData] = useState<Record<string, OOHBuy>>();
  const [updatedOOHBuysRows, setUpdatedOOHBuysRows] = useState<OOHBuy[]>([]);
  const [filteredData, setFilteredData] = useState<OOHBuy[]>([]);

  const getOOHBuysData = useCallback(async () => {
    try {
      const res = await MiscLambdaFetch("/getOOHBuys");
      const oohBuysLambdaResult = await awaitJSON<OOHBuy[]>(res);
      setTableData(oohBuysLambdaResult);

      let originalOOHBuysData: Record<string, OOHBuy> = {};
      for (const buy of oohBuysLambdaResult) {
        originalOOHBuysData[buy.id] = buy;
      }
      setOriginalOOHBuysData(originalOOHBuysData);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: error.message,
        reportError: error,
      });
    }
  }, [setError, setTableData]);

  const clearAllChanges = useCallback(() => {
    setNewOOHBuysRows([]);
    setTableData(Object.values(originalOOHBuysData || {}));
  }, [setNewOOHBuysRows, setTableData, originalOOHBuysData]);

  const hasPendingChanges: boolean = useMemo(() => {
    const hasPendingChangesResult = !!newOOHBuysRows.length || !!updatedOOHBuysRows.length;

    if (!hasPendingChangesResult) {
      setShowPendingChanges(false);
    }

    return hasPendingChangesResult;
  }, [newOOHBuysRows, updatedOOHBuysRows]);

  const save = useCallback(async () => {
    try {
      setSaving(true);

      await MiscLambdaFetch<UpdateOOHBuysBody>("/updateOOHBuys", {
        method: "POST",
        body: {
          insert: newOOHBuysRows,
          update: updatedOOHBuysRows,
        },
      });

      await getOOHBuysData();

      setSaving(false);
    } catch (e) {
      setSaving(false);

      let error: Error = e as Error;
      setError({
        message: `Failed to insert out of home buys data: ${error.message}`,
        reportError: error,
      });
    }
  }, [newOOHBuysRows, getOOHBuysData, setError, updatedOOHBuysRows]);

  const validateModalUserInput: (row) => boolean = row => {
    let validUnitNumberCheck = true;
    if (row.unit_number) {
      if (row.unit_number < 0) {
        validUnitNumberCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid unit number.`);
      }
    }

    let validMediaCostCheck = true;
    if (row.media_cost) {
      if (row.media_cost < 0) {
        validMediaCostCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid media cost.`);
      }
    }

    let validProductionTotalCheck = true;
    if (row.production_total) {
      if (row.production_total < 0) {
        validProductionTotalCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid production total.`);
      }
    }

    let validInstallationTotalCheck = true;
    if (row.installation_total) {
      if (row.installation_total < 0) {
        validInstallationTotalCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid installation total.`);
      }
    }

    let validTotalCostCheck = true;
    if (row.total_cost) {
      if (row.total_cost < 0) {
        validTotalCostCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid total cost.`);
      }
    }

    let validTotalImpressionsCheck = true;
    if (row.total_impressions) {
      if (row.total_impressions < 0) {
        validTotalImpressionsCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid total impressions.`);
      }
    }

    let validCPMCheck = true;
    if (row.cpm) {
      if (row.cpm < 0) {
        validCPMCheck = false;
        setOOHBuysModalInputErrorMessage(`Invalid input: 
        Please enter a valid cpm.`);
      }
    }

    return (
      validInstallationTotalCheck &&
      validMediaCostCheck &&
      validProductionTotalCheck &&
      validTotalCostCheck &&
      validTotalImpressionsCheck &&
      validUnitNumberCheck &&
      validCPMCheck
    );
  };

  const selectorOptions = { campaign: [] } as Record<string, SelectorOption[]>;

  const finalRow = useMemo(() => {
    return {
      company: "Totals",
      media_cost: R.sum(R.pluck("media_cost", filteredData)),
      production_total: R.sum(R.pluck("production_total", filteredData)),
      installation_total: R.sum(R.pluck("installation_total", filteredData)),
      total_cost: R.sum(R.pluck("total_cost", filteredData)),
      total_impressions: R.sum(R.pluck("total_impressions", filteredData)),
    };
  }, [filteredData]);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      let error: Error = {
        name: "500",
        message: "You need to have cookies enabled to use this page!",
      };
      setError({
        message: error.message,
        reportError: error,
      });
    }

    if (!tableData) {
      (async () => {
        await getOOHBuysData();
      })();
    }
  }, [setError, tableData, getOOHBuysData]);

  useEffect(() => {
    if (tableData && originalOOHBuysData) {
      let newOOHBuysRowsHolder = R.filter(row => {
        if (row.id) {
          return false;
        }
        return true;
      }, tableData);
      setNewOOHBuysRows(newOOHBuysRowsHolder);

      let updatedOOHBuysRowsHolder = R.filter(row => {
        if (originalOOHBuysData[row.id]) {
          return (
            row.company !== originalOOHBuysData[row.id].company ||
            row.campaign !== originalOOHBuysData[row.id].campaign ||
            row.start_date !== originalOOHBuysData[row.id].start_date ||
            row.end_date !== originalOOHBuysData[row.id].end_date ||
            row.market !== originalOOHBuysData[row.id].market ||
            row.format !== originalOOHBuysData[row.id].format ||
            row.description !== originalOOHBuysData[row.id].description ||
            row.unit_number !== originalOOHBuysData[row.id].unit_number ||
            row.media_cost !== originalOOHBuysData[row.id].media_cost ||
            row.production_total !== originalOOHBuysData[row.id].production_total ||
            row.installation_total !== originalOOHBuysData[row.id].installation_total ||
            row.total_cost !== originalOOHBuysData[row.id].total_cost ||
            row.total_impressions !== originalOOHBuysData[row.id].total_impressions ||
            row.cpm !== originalOOHBuysData[row.id].cpm ||
            row.pdf !== originalOOHBuysData[row.id].pdf
          );
        }
        return false;
      }, tableData);
      setUpdatedOOHBuysRows(updatedOOHBuysRowsHolder);
    }
  }, [tableData, setNewOOHBuysRows, originalOOHBuysData, setUpdatedOOHBuysRows]);

  return (
    <Page
      title="Out Of Home Buys"
      pageType="Out Of Home Buys"
      minHeight="600px"
      actions={
        <PendingChangesControls
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={save}
          isSaving={saving}
          clearAllChanges={clearAllChanges}
        />
      }
    >
      <div className="oohBuysPageContainer">
        {tableData ? (
          <ModalEditTable<OOHBuy>
            className="oohBuysTable"
            headers={headers}
            tableData={tableData}
            setTableData={setTableData}
            selectorOptions={selectorOptions}
            filterBar
            showModalOnAdd={true}
            invalidText={oohBuysModalInputErrorMessage}
            enableDelete={false}
            checkIsValid={validateModalUserInput}
            onFilteredDataChange={setFilteredData}
            totals={finalRow}
            rowHeight={75}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            originalData={originalOOHBuysData || {}}
            pendingChanges={{
              editedRows: updatedOOHBuysRows,
              newRows: newOOHBuysRows,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={headers}
          />
        )}
      </div>
    </Page>
  );
};

export default OutOfHomeBuys;
