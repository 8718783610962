import React, { useState, useCallback } from "react";
import * as R from "ramda";
import { CheckBox, ColorPicker, InfoTooltip } from "../Components";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import { StreamingV2LambdaFetch } from "../utils/fetch-utils";
import { Form, Col, ListGroup, Button } from "react-bootstrap";
import Select from "react-select";
import { MdAdd } from "react-icons/md";
import "./AddNewNetwork.scss";

const remainingDspOptions = (selectedDSPs, globalOptions) => {
  let remainingOptions = R.filter(option => !R.includes(option.label, selectedDSPs), globalOptions);

  return remainingOptions;
};

const defaultLocalChanges = {
  shortCode: "",
  networkGroup: "",
  name: "",
  color: "#ffffff",
  buyable: true,
  cpm: null,
  defaultIoPdfText: "",
  pdfText: "",
  platforms: [{ platform: "", buyable: true }],
  dsp: [],
  requiresDescriptions: false,
};

const AddNewNetwork = ({
  setNetworkList,
  setAddNetworkModal,
  dspOptions,
  shouldEnableStreamingNetworksUpdates,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const [localChanges, setLocalChanges] = useState(defaultLocalChanges);
  const [saving, setSaving] = useState(false);

  const enableSave =
    localChanges.shortCode.trim().length > 0 &&
    localChanges.networkGroup.trim().length > 0 &&
    localChanges.name.trim().length > 0 &&
    R.map(platformObj => platformObj.platform.trim() !== "", localChanges.platforms);

  const onSave = useCallback(
    async localChanges => {
      if (localChanges.shortCode.length > 10) {
        try {
          await setAreYouSure({
            title: "Long Short Code",
            message:
              "Your short code is very long. You should consider using an abbreviation or some other way of shortening it (unless any other name would be overly confusing). Are you sure you want to create this network with this short code?",
            okayText: "Create network",
            cancelText: "Change the short code",
          });
        } catch (e) {
          return;
        }
      }

      try {
        setSaving(true);
        await StreamingV2LambdaFetch("/add_streaming_network", {
          method: "POST",
          body: {
            shortCode: localChanges.shortCode,
            networkGroup: localChanges.networkGroup,
            name: localChanges.name,
            color: localChanges.color,
            buyable: localChanges.buyable,
            cpm: localChanges.cpm,
            defaultIoPdfText: localChanges.defaultIoPdfText,
            pdfText: localChanges.pdfText,
            requiresDescriptions: localChanges.requiresDescriptions,
            platforms: localChanges.platforms,
            dsp: localChanges.dsp,
          },
        });
        setSaving(false);
        setAddNetworkModal(false);
        setNetworkList();
      } catch (e) {
        setError({ message: e.message, reportError: e });
        setSaving(false);
      }
    },
    [setError, setNetworkList, setAddNetworkModal, setAreYouSure]
  );

  return (
    <div className={`addNetworkView${saving ? " saving" : ""}`}>
      <div className="networkContainer">
        <AddInfoTab
          localChanges={localChanges}
          setLocalChanges={setLocalChanges}
          dspOptions={dspOptions}
          shouldEnableStreamingNetworksUpdates={shouldEnableStreamingNetworksUpdates}
        />
      </div>
      <div className="editButtons">
        <Button onClick={() => onSave(localChanges)} disabled={!enableSave[0]}>
          {saving ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  );
};

// Add New Network Info
const AddInfoTab = ({
  localChanges,
  setLocalChanges,
  dspOptions,
  shouldEnableStreamingNetworksUpdates,
}) => {
  const setError = useSetError();

  const changeFormVal = useCallback(
    (key, value) => setLocalChanges(existing => ({ ...existing, [key]: value })),
    [setLocalChanges]
  );

  return (
    <>
      <div className="networkInfo">
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value={localChanges.name || ""}
                onChange={e => {
                  changeFormVal("name", e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Short Code</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value={localChanges.shortCode || ""}
                onChange={e => {
                  changeFormVal("shortCode", e.target.value);
                }}
                onBlur={() =>
                  changeFormVal("shortCode", (localChanges.shortCode || "").toUpperCase())
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Network Group</Form.Label>

              <Form.Control
                type="text"
                size="sm"
                value={localChanges.networkGroup || ""}
                onChange={e => {
                  changeFormVal("networkGroup", e.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Default CPM</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  value={localChanges.cpm || ""}
                  onChange={e => {
                    changeFormVal("cpm", e.target.value);
                  }}
                />
              </Form.Group>
              {!shouldEnableStreamingNetworksUpdates && (
                <Form.Group className="checkboxGroup">
                  <div className="checkboxLabel">Requires Descriptions</div>
                  <CheckBox
                    checked={localChanges.requiresDescriptions}
                    onCheck={() => {
                      changeFormVal("requiresDescriptions", !localChanges.requiresDescriptions);
                    }}
                  />
                </Form.Group>
              )}
              <Form.Group className="checkboxGroup">
                <div className="checkboxLabel">Buyable</div>
                <CheckBox
                  checked={localChanges.buyable}
                  onCheck={() => {
                    changeFormVal("buyable", !localChanges.buyable);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Color</Form.Label>
                <ColorPicker
                  type="color"
                  color={localChanges.color}
                  onChange={color => {
                    changeFormVal("color", color);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>DSPs</Form.Label>
                <div className="newDspList">
                  {!R.isEmpty(localChanges.dsp)
                    ? R.pipe(
                        R.sortBy(R.identity),
                        R.map(dsp => <span key={dsp}>{dsp}</span>)
                      )(localChanges.dsp)
                    : "No DSPs"}
                </div>

                <Select
                  placeholder="New DSP"
                  value={null}
                  onChange={selection => {
                    changeFormVal("dsp", [...localChanges.dsp, selection.value]);
                    R.remove(null, localChanges.dsp);
                  }}
                  options={remainingDspOptions(localChanges.dsp, dspOptions)}
                />
              </Form.Group>
            </Col>
            <Col xs={8}>
              <Form.Group>
                <Form.Label>Platforms</Form.Label>
                <ListGroup className="newPlatformList">
                  {R.pipe(
                    R.sortBy(R.identity),
                    R.addIndex(R.map)((platform, i) => {
                      return (
                        <ListGroup.Item className="newPlatformListRow" key={i}>
                          <div className="newPlatformListItem">
                            <Form.Control
                              size="sm"
                              value={platform.platform}
                              onBlur={e => {
                                let { value } = e.currentTarget;
                                if (value.toLowerCase().includes("bonus")) {
                                  setError({
                                    title: "No Bonus Platform",
                                    message:
                                      "You cannot add a 'Bonus' platform. They are added automatically.",
                                  });
                                  changeFormVal(
                                    "platforms",
                                    R.remove(i, 1, localChanges.platforms)
                                  );
                                }
                              }}
                              onChange={e =>
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    i,
                                    { ...platform, platform: e.target.value },
                                    localChanges.platforms
                                  )
                                )
                              }
                            />
                            <CheckBox
                              checked={platform.buyable}
                              onCheck={() => {
                                changeFormVal(
                                  "platforms",
                                  R.update(
                                    i,
                                    { ...platform, buyable: !platform.buyable },
                                    localChanges.platforms
                                  )
                                );
                              }}
                            />
                          </div>
                        </ListGroup.Item>
                      );
                    })
                  )(localChanges.platforms)}
                </ListGroup>

                <Button
                  block
                  onClick={() =>
                    changeFormVal("platforms", [
                      ...localChanges.platforms,
                      { platform: "", buyable: true, isNew: true },
                    ])
                  }
                >
                  <MdAdd />
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Group>
            <Form.Label>Default PDF Text</Form.Label>
            <InfoTooltip size="sm">
              The pre-populated text in the text box when you make an IO. Typically to list things
              like frequency caps, etc.
            </InfoTooltip>
            <Form.Control
              as="textarea"
              rows="4"
              value={localChanges.defaultIoPdfText || ""}
              onChange={e => {
                changeFormVal("defaultIoPdfText", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Terms</Form.Label>
            <InfoTooltip size="sm">
              The bulleted list of terms at the end of the IO. Typically addressing higher-level
              items like billing for certain types of impressions, etc.
            </InfoTooltip>
            <Form.Control
              as="textarea"
              rows="4"
              value={localChanges.pdfText || ""}
              onChange={e => {
                changeFormVal("pdfText", e.target.value);
              }}
            />
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default AddNewNetwork;
