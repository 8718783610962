import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { BPMDateRange, Dropdown, PieChart, DropdownToggleType } from "../../Components";
import { DateRange } from "../../utils/types";
import { PROFILE_FILTER_MESSAGING, tmrKpiPull, tmrRetailerFilterOptions, TMR_COLORS, tmrSortByOptions } from "./utils";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { SharedState, SlideState } from "../slideTemplateConstants";
import {
  SettingsComponentProps,
  SlideContext,
  SlideType,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { fetchKeywordSlideData } from "./QueryServices";

export interface MarketplaceReportKeywordsSlideState {
  title: string;
  subTitle: string;
  sort: string;
  dates: DateRange;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportKeywordsSlideData {
  title: string;
  subTitle: string;
}

interface PieData {
  name: string;
  value: number;
  color?: string;
}

const PIE_COLOR_MAP = {
  EXACT: TMR_COLORS.PURPLE,
  BROAD: TMR_COLORS.BLUE,
  PHRASE: TMR_COLORS.PINK,
};

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

const formatPieData = (data: any): PieData[] => {
  return data.map(item => ({
    name: item.matchType,
    value: item.sales === 0 ? 0.001 : item.sales,
    color: PIE_COLOR_MAP[item.matchType] || TMR_COLORS,
  }));
};

const pieComponent = (height: number, width: number, data: {}[]) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    {/* This is because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
    {/*// @ts-ignore */}
    <PieChart data={data} size={300} />
  </div>
);

class MarketplaceReportKeywordsSlide extends SlideType {
  static typeKey = "TmrKeywordsSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportKeywordsSlideState = {
    title: "Keywords: Search Advertising",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    sort: "sales",
    dates: { start: DATE_START, end: DATE_END },
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportKeywordsSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, sort, dates, kpi, companyProfile } = state;

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label> Sort by</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={sort}
              options={tmrSortByOptions}
              onChange={change => setState({ sort: change })}
            />
          </div>
         <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
     </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportKeywordsSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      retailer,
      sort,
      dates,
      companyProfile,
      kpi,
    } = state as MarketplaceReportKeywordsSlideState;
    const height = 300;
    const width = 300;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    let data = await fetchKeywordSlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      sort,
      profileNames
    );

    let pieData = formatPieData(data.matchType);

    const pieChart = pieComponent(height, width, pieData);

    let pieChartUrl = await convertComponentToImageUrl(
      pieChart,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      data,
      pieData,
      retailer,
      pieChartUrl,
      kpi,
    });
  };
}
export default MarketplaceReportKeywordsSlide;
