import React, { useCallback, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  LabelList,
  Label,
  Rectangle,
  XAxis,
} from "recharts";

import { CloseButton as ClearButton } from "react-bootstrap";

import { BsBarChartLine } from "react-icons/bs";

import { Importance, CreativeInfoItem } from "@blisspointmedia/bpm-types/dist/Creative";

import "./TagsChart.scss";
import ChartContainer from "../Components/ChartContainer";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  Spinner,
  InfoTooltip,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { MdDownload } from "react-icons/md";
import * as XLSX from "xlsx";
import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";
import * as UserRedux from "../redux/user";
import { useSelector } from "react-redux";
import { StackedTextToggle } from "../Components/StackedTextToggle/StackedTextToggle";

interface TagsChartProps {
  selectedAttribute: string;
  setSelectedAttribute: React.Dispatch<React.SetStateAction<string>>;
  handleAttributeClick: (attributeName: string) => void;
  selectedTag: string;
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>;
  tagFillColor: string;
  attributeImportanceData: Importance[];
  tagImportanceData: { [concept: string]: Importance[] };
  setSelectedCreative: React.Dispatch<React.SetStateAction<CreativeInfoItem | undefined>>;
  creativeInfoList: CreativeInfoItem[];
  attributeTypeBinomial: { [attribute: string]: boolean };
  suppressedAttributes: Set<string>;
  kpi: string;
}

const TagsChart = ({
  selectedAttribute,
  setSelectedAttribute,
  handleAttributeClick,
  selectedTag,
  setSelectedTag,
  tagFillColor,
  attributeImportanceData,
  tagImportanceData,
  setSelectedCreative,
  creativeInfoList,
  attributeTypeBinomial,
  suppressedAttributes,
  kpi,
}: TagsChartProps): JSX.Element => {
  const creativeQuantities = useMemo(() => {
    let output: { name: string; airings: number; spend: number }[] = [];
    tagImportanceData[selectedAttribute]
      ?.map(value => value.name)
      .forEach(tag => {
        let filteredCreativeList: CreativeInfoItem[] = [];
        if (selectedAttribute === "Concept") {
          // This is the simplest case where we just check if the tag is the same as the creative's concept
          filteredCreativeList = creativeInfoList.filter(creative => tag === creative.concept);
          // Another simple case where we just return creatives with tag as the creative's language
        } else if (selectedAttribute === "Language") {
          filteredCreativeList = creativeInfoList.filter(creative => creative.language === tag);
        } else if (attributeTypeBinomial[selectedAttribute]) {
          // In this isBinary case we just want to check if the selectedAttribute is or isn't in the creative map
          filteredCreativeList = creativeInfoList.filter(creative => {
            if (tag === selectedAttribute) {
              return creative.creativeTags[selectedAttribute];
            } else if (tag === `No ${selectedAttribute}`) {
              return !creative.creativeTags[selectedAttribute];
            } else {
              return false;
            }
          });
        } else {
          // Multinomial case
          filteredCreativeList = creativeInfoList.filter(creative => {
            // If BLANK then show all creatives where selectedAttribute does not appear in creative map
            if (tag === "Not Present") {
              return !creative.creativeTags[selectedAttribute];
              // Else we want to show creatives where both the selectedAttribute and tag appear in the creative map
            } else {
              return creative.creativeTags[selectedAttribute]?.includes(tag);
            }
          });
        }

        // Iterate through filteredCreativeList to calculate total airings(spots) for tag
        let totalAirings = 0;
        let totalSpend = 0;
        filteredCreativeList.forEach(creative => {
          totalAirings += creative.numSpots;
          totalSpend += creative.spend;
        });
        output.push({ name: tag, airings: totalAirings, spend: totalSpend });
      });

    return output;
  }, [attributeTypeBinomial, creativeInfoList, selectedAttribute, tagImportanceData]);

  const data: { name: string; val: number; quantity: number; spend: number }[] = useMemo(() => {
    let output: { name: string; val: number; quantity: number; spend: number }[] = [];
    let tagImportances = tagImportanceData[selectedAttribute];
    for (let i = 0; i < tagImportances?.length; i++) {
      if (creativeQuantities[i].airings > 0) {
        output.push({
          name: tagImportances[i].name,
          val: tagImportances[i].val,
          quantity: creativeQuantities[i].airings,
          spend: creativeQuantities[i].spend,
        });
      }
    }

    return output;
  }, [tagImportanceData, creativeQuantities, selectedAttribute]);

  const maxQuantity: number = useMemo(() => {
    let output = 0;
    if (data) {
      data.forEach(tag => {
        if (tag.quantity > output) {
          output = tag.quantity;
        }
      });
    }

    return output;
  }, [data]);

  const dropDownOptions: { value: string }[] = useMemo(() => {
    return attributeImportanceData
      ? Object.values(attributeImportanceData)
          .filter(attribute => !suppressedAttributes.has(attribute.name))
          .map(attribute => {
            return { value: attribute.name };
          })
      : [];
  }, [attributeImportanceData, suppressedAttributes]);

  const dropDownValue: string = useMemo(() => {
    return selectedAttribute;
  }, [selectedAttribute]);

  const handleTagsChartClick: (field: any) => void = useCallback(
    field => {
      if (field.name === selectedTag) {
        setSelectedTag("");
      } else {
        setSelectedTag(field.name);
      }
      setSelectedCreative(undefined);
    },
    [selectedTag, setSelectedTag, setSelectedCreative]
  );

  // Want the aggregate spend font size to scale the same amount as the bar percentage labels
  const [spendFontSize, setSpendFontSize] = useState<number>(12);
  const renderCustomizedLabel: (props: any) => JSX.Element = useCallback(props => {
    const { x, y, width, value } = props;

    let xVal = x + width / 2;
    let yVal = value >= 0 ? y - 10 : y + 20;
    let val = `${value}%`;
    let fontSize = 15 - 100 / width;
    setSpendFontSize(fontSize);
    if (width < 25) {
      return (
        <text
          x={xVal}
          y={yVal}
          textAnchor="middle"
          fontSize={fontSize}
          lengthAdjust="spacingAndGlyphs"
        >
          {val}
        </text>
      );
    }
    return (
      <text x={xVal} y={yVal} textAnchor="middle" fontSize={fontSize}>
        {val}
      </text>
    );
  }, []);

  const renderReferenceLineLabel: (props: any) => JSX.Element = useCallback(props => {
    const { viewBox } = props;
    let yVal = viewBox.y;
    return (
      <text className="referenceLineLabel" y={yVal - 15}>
        <tspan x="-9px" dy="1.2em">
          Baseline
        </tspan>
        <tspan x="-30px" dy="1.2em">
          Performance
        </tspan>
      </text>
    );
  }, []);

  const [showAiringsBar, setShowAiringsBar] = useState<boolean>(true);

  const renderStackedToggle: (props: any) => JSX.Element = useCallback(
    props => {
      const { x, width } = props.viewBox;
      return (
        <foreignObject x={x - width / 3} y={-14} width={73.28} height={56}>
          <StackedTextToggle
            className="stackedToggle"
            options={[
              { label: "Airings", key: "airings" },
              { label: "Spend", key: "spend" },
            ]}
            selectedOption={showAiringsBar ? "airings" : "spend"}
            onChange={option =>
              option === "airings" ? setShowAiringsBar(true) : setShowAiringsBar(false)
            }
          />
        </foreignObject>
      );
    },
    [showAiringsBar, setShowAiringsBar]
  );

  const CustomCursor: (props: any) => JSX.Element = useCallback(props => {
    const { x, y, width, height } = props;
    return <Rectangle fill="#f8f9fa" x={x} y={y} width={width} height={height * 1.5} />;
  }, []);

  const AiringsBar: (props: any) => JSX.Element = useCallback(
    props => {
      const { x, width, quantity } = props;
      const barColors = [
        "#00ff00",
        "#00ed00",
        "#00db00",
        "#00c700",
        "#00b500",
        "#00a100",
        "#018c01",
        "#007800",
        "#006300",
      ];
      let fillArr: string[] = [];
      for (let i = 0; i < 9; i++) {
        if (i < Math.round((quantity / maxQuantity) * 9)) {
          fillArr.push(barColors[i]);
        } else {
          fillArr.push("lightgray");
        }
      }

      // Height of bar is 34 so dividing bar into 17 sections, each with height of 2
      let rectangleArr: JSX.Element[] = [...Array(17)].map((value: undefined, index: number) => {
        let i = 16 - index;
        if (i % 2 === 1) {
          return <Rectangle key={i} fill="white" x={x} y={2 * (i + 1)} width={width} height={2} />;
        } else {
          return (
            <Rectangle
              key={i}
              fill={fillArr[index / 2]}
              x={x}
              y={2 * (i + 1)}
              width={width}
              height={2}
            />
          );
        }
      });

      return <g>{rectangleArr}</g>;
    },
    [maxQuantity]
  );

  const formatCurrency = (value: number): string => {
    if (value >= 1_000_000) {
      return `$${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `$${(value / 1_000).toFixed(1)}K`;
    } else {
      return `$${value.toFixed(1)}`;
    }
  };

  const AggregateSpend: (props: any) => JSX.Element = useCallback(
    props => {
      const { x, spend, width } = props;
      let xVal = x + width / 2;

      return (
        <text
          x={xVal}
          y={25}
          textAnchor="middle"
          fontSize={spendFontSize}
          lengthAdjust="spacingAndGlyphs"
          transform={`rotate(45, ${xVal}, 25)`}
        >
          {`${formatCurrency(Math.round(spend))}`}
        </text>
      );
    },
    [spendFontSize]
  );

  let userRole = useSelector(UserRedux.roleSelector);
  const [downloading, setDownloading] = useState<boolean>(false);
  const setError = useSetError();
  const handleDownload = useCallback(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/fetchRawCreativeInsightsData", {
          params: {
            dataType: "tag",
            kpi,
          },
        });
        let parsedRes = await awaitJSON<string[][]>(res);
        let fileName = "linear_creative_betas.xlsx";
        let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
        let worksheet = XLSX.utils.json_to_sheet(
          parsedRes.map((row: string[]) => {
            return {
              Tag: row[0],
              Beta: +row[1],
            };
          })
        );

        parsedRes.forEach((row: string[], index: number) => {
          worksheet[`B${index + 2}`].z = "0.0#########";
        });

        let sheetName = "linear creative betas";

        workbook.SheetNames.push(sheetName);
        workbook.Sheets[sheetName] = worksheet;

        XLSX.writeFile(workbook, fileName);
        setDownloading(false);
      } catch (e) {
        setError({
          message: `Couldn't download attribute importances. Error: ${e.message}.`,
          reportError: e,
        });
      }
    })();
  }, [kpi, setError]);

  return (
    <ChartContainer
      title="Tags"
      leftActions={
        <>
          <InfoTooltip size="reg">
            {`All tactics are baselined against the average effect across a particular attribute's
tactics, which is leveled at zero. The magnitude attached to a given tactic can be
interpreted as the percentage difference in KPI response to be expected from adopting that
tactic as compared to the average effect of the tactic.`}
          </InfoTooltip>
        </>
      }
      rightActions={
        <>
          <div className="attributePicker">
            <Dropdown
              type={DropdownToggleType.FILLED}
              design="secondary"
              size="sm"
              value={dropDownValue}
              options={dropDownOptions}
              onChange={option => handleAttributeClick(option)}
              label="Attribute"
              placeholder={"Select Attribute"}
            />
          </div>
          {selectedAttribute && (
            <div className="clearButton">
              <ClearButton
                onClick={() => {
                  setSelectedAttribute("");
                  setSelectedTag("");
                }}
              />
            </div>
          )}
          {userRole <= 1 && selectedAttribute && !downloading && (
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.ICON_ONLY}
              icon={<MdDownload />}
              size="sm"
              onClick={() => {
                setDownloading(true);
                handleDownload();
              }}
            />
          )}
          {userRole <= 1 && selectedAttribute && downloading && (
            <div style={{ width: "34px" }}>
              <Spinner />
            </div>
          )}
        </>
      }
    >
      <div className="tagsContainer">
        {selectedAttribute ? (
          <div className="tagsContent">
            <div className="tagsChart">
              <div style={{ height: "85%" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={100}
                    syncId="sync"
                  >
                    <XAxis dataKey="name" axisLine={false} tick={false} />
                    <YAxis
                      tickFormatter={tick => `${tick}%`}
                      domain={[dataMin => dataMin - 30, dataMax => dataMax + 30]}
                    />
                    <Tooltip
                      key="name"
                      content={params => {
                        if (params?.label) {
                          let tag;
                          tag = params.label;
                          return tag ? (
                            <div className="tagsChartTooltip">{`Tactic: ${tag}`}</div>
                          ) : (
                            <div></div>
                          );
                        } else {
                          return <div></div>;
                        }
                      }}
                      cursor={<CustomCursor />}
                    />
                    <ReferenceLine y={0} stroke="#000">
                      <Label content={renderReferenceLineLabel}></Label>
                    </ReferenceLine>
                    <Bar
                      dataKey="val"
                      fill={tagFillColor}
                      onClick={handleTagsChartClick}
                      isAnimationActive={false}
                      background={{ fill: "transparent", cursor: "pointer" }}
                      cursor="pointer"
                      className="betaBar"
                    >
                      {<LabelList data={[]} content={renderCustomizedLabel} />}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div style={{ height: "15%" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                    syncId="sync"
                    className="quantityChart"
                  >
                    <YAxis
                      domain={[0, "dataMax"]}
                      axisLine={false}
                      tickLine={false}
                      label={renderStackedToggle}
                    />
                    <XAxis dataKey="name" hide={true}></XAxis>
                    <Bar
                      dataKey="quantity"
                      fill={tagFillColor}
                      isAnimationActive={false}
                      shape={showAiringsBar ? <AiringsBar /> : <AggregateSpend />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        ) : (
          <div className="tagsContentPlaceholder">
            <div>
              <BsBarChartLine size={100} color={"lightgray"} />
            </div>
            <div>Select an attribute to view tactics for that attribute.</div>
          </div>
        )}
      </div>
    </ChartContainer>
  );
};

export default TagsChart;
