import "./Delivery.scss";
import { DailyDeliveryData } from "../../StreamingDelivery/StreamingDelivery";
import { DateGroupingKey } from "../../TVADCrossChannel/homePageConstants";
import { DateRange, StateSetter } from "../../utils/types";
import { Spinner } from "../../Components";
import { toPrettyCPX, toPrettyNumber, toPrettySpend } from "../MetricsTable/metricsTableUtils";
import * as R from "ramda";
import PercentChange from "../PercentChange";
import React, { useMemo } from "react";
import SingleChannelDeliveryChart, {
  DEFAULT_DELIVERY_METRICS_OPTIONS,
  DeliveryMetricOption,
} from "./SingleChannelDeliveryChart";

const OVERVIEW_KEYS = ["Spend", "Impressions", "CPM"];

interface DeliveryProps {
  company: string;
  data: DailyDeliveryData;
  dates: DateRange;
  defaultDateGroupingKey?: DateGroupingKey;
  defaultMetric?: string;
  deliveryMetricOptions?: DeliveryMetricOption[];
  dimension: string;
  dimensionOptions: string[];
  otherData: DailyDeliveryData;
  otherDates?: DateRange;
  setDimension: StateSetter<string>;
}

const Delivery = ({
  company,
  data,
  dates,
  defaultDateGroupingKey,
  defaultMetric,
  deliveryMetricOptions = DEFAULT_DELIVERY_METRICS_OPTIONS,
  dimension,
  dimensionOptions,
  otherData,
  otherDates,
  setDimension,
}: DeliveryProps): JSX.Element => {
  const overview = useMemo(() => {
    const overviewData: Record<string, { primary: number; comparison: number }> = {
      CPM: {
        primary: 0,
        comparison: 0,
      },
    };
    for (const option of deliveryMetricOptions) {
      overviewData[option.label] = { primary: 0, comparison: 0 };
    }
    const primaryData = !R.isNil(data)
      ? !R.isNil(data.daily) && R.isEmpty(data.daily) && !R.isEmpty(data.weekly)
        ? data.weekly
        : data.daily
      : {};
    if (primaryData) {
      for (const date of R.keys(primaryData)) {
        if (primaryData[date]) {
          for (const dimension of R.keys(primaryData[date])) {
            if (primaryData[date][dimension]) {
              for (const option of deliveryMetricOptions) {
                overviewData[option.label].primary =
                  R.defaultTo(0, overviewData[option.label].primary) +
                  R.defaultTo(0, primaryData[date][dimension][option.value]);
              }
            }
          }
        }
      }
    }
    const comparisonData = !R.isNil(otherData)
      ? !R.isNil(otherData.daily) && R.isEmpty(otherData.daily) && !R.isEmpty(otherData.weekly)
        ? otherData.weekly
        : otherData.daily
      : {};
    if (comparisonData) {
      for (const date of R.keys(comparisonData)) {
        if (comparisonData[date]) {
          for (const dimension of R.keys(comparisonData[date])) {
            if (comparisonData[date][dimension]) {
              for (const option of deliveryMetricOptions) {
                overviewData[option.label].comparison =
                  R.defaultTo(0, overviewData[option.label].comparison) +
                  R.defaultTo(0, comparisonData[date][dimension][option.value]);
              }
            }
          }
        }
      }
    }
    if (overviewData.Impressions.primary > 0) {
      overviewData.CPM.primary =
        (overviewData.Spend.primary * 1000) / overviewData.Impressions.primary;
    }
    if (overviewData.Impressions.comparison > 0) {
      overviewData.CPM.comparison =
        (overviewData.Spend.comparison * 1000) / overviewData.Impressions.comparison;
    }
    if (data && (otherData || R.isNil(otherDates))) {
      return (
        <div className="deliveryOverview">
          {R.map(key => {
            const percentChange =
              overviewData[key].primary && overviewData[key].comparison
                ? ((overviewData[key].primary - overviewData[key].comparison) /
                    overviewData[key].comparison) *
                  100
                : 0;
            const value =
              key === "CPM"
                ? toPrettyCPX(overviewData[key].primary)
                : key === "Spend"
                ? toPrettySpend(overviewData[key].primary)
                : toPrettyNumber(overviewData[key].primary);
            return (
              <div className="deliveryOverviewItem">
                <div className="overviewValueKeyContainer">
                  <div className="overviewValue">{value}</div>
                  <div className="overviewKey">{key}</div>
                </div>
                {key !== "CPM" && <div className="overviewType">Total</div>}
                <div className="percentChangeContainer">
                  <PercentChange percentChange={percentChange} size={"lg"} />
                </div>
              </div>
            );
          }, OVERVIEW_KEYS)}
        </div>
      );
    }
  }, [data, deliveryMetricOptions, otherData, otherDates]);

  return R.isNil(overview) || R.isNil(data) || R.isNil(otherData && otherDates) ? (
    <Spinner size={100} />
  ) : (
    <div className="deliveryTab">
      {overview}
      <SingleChannelDeliveryChart
        company={company}
        data={data}
        dates={dates}
        defaultDateGroupingKey={defaultDateGroupingKey}
        defaultMetric={defaultMetric}
        deliveryMetricOptions={deliveryMetricOptions}
        dimension={dimension}
        dimensionOptions={dimensionOptions}
        otherData={otherData}
        otherDates={otherDates}
        setDimension={setDimension}
      />
    </div>
  );
};

export default Delivery;
