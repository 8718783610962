import { Spinner } from "./Spinner";
import InnerLineChartBox from "./InnerLineChartBox";
import React, { useEffect, useMemo } from "react";

export type SpendDate = string;

interface ExecutiveData {
  date: string;
  audioSpend: number;
  linearSpend: number;
  streamingSpend: number;
  audioVolume: number;
  linearVolume: number;
  streamingVolume: number;
  audioCPX: number;
  linearCPX: number;
  streamingCPX: number;
  audioROAS: number;
  linearROAS: number;
  streamingROAS: number;
}

export interface ExecutiveSummaryChartData {
  data: ExecutiveData[];
  dataKeys: string[];
}

interface ExecutiveSummaryChartProps {
  chartData: any[];
  chartType: string;
  color: string[];
  dataKeys: string[];
  height?: number;
  width?: number;
  onPathLoad?: (loaded: boolean) => void;
}

export const ExecutiveSummaryChart = React.forwardRef<
  SVGSVGElement | null,
  ExecutiveSummaryChartProps
>(({ chartData, chartType, color, dataKeys, height, width, onPathLoad }, ref) => {
  useEffect(() => {
    if (chartData.length > 1) {
      if (onPathLoad) {
        onPathLoad(true);
      }
    }
  }, [onPathLoad, chartData.length]);

  const graph = useMemo(() => {
    return chartData.length > 1 ? (
      <InnerLineChartBox
        chartData={chartData}
        dataKeys={dataKeys}
        height={height}
        isAnimationActive={false}
        isSpend={chartType === "SPEND" || chartType === "CPX"}
        ref={ref}
        strokeColor={color}
        width={width}
        xAxisFontSize={12}
        xAxisLine={false}
        xAxisTickCount={2}
        xAxisTicks={[chartData[0].date, chartData[chartData.length - 1].date]}
        yAxisFontSize={12}
        yAxisLine={false}
        yAxisTickCount={5}
        chartMargins={{
          top: 0,
          right: 0,
          left: 10,
          bottom: -12,
        }}
      />
    ) : null;
  }, [chartType, chartData, color, dataKeys, height, ref, width]);

  return (
    <div className="executiveSummaryChart" style={{ width, height }} key={`${chartType}Chart`}>
      {graph ? (
        <svg ref={ref} viewBox={`0 0 ${width} ${height}`}>
          {graph}
        </svg>
      ) : (
        <Spinner />
      )}
    </div>
  );
});
