import React from "react";

import { Button } from "react-bootstrap";

import "./IconButton.scss";

export const IconButton = ({ children, icon, className, disable = false, ...rest }) => (
  <Button className={`bpmIconButton ${className || ""}`} disabled={disable} {...rest}>
    {icon}
    <span>{children}</span>
  </Button>
);
