import React, { useEffect, useState } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { useSetError } from "../redux/modals";

import { PieChart, FullPageSpinner, Spinner, Img } from "../Components";

import { StreamingLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import { useCreativeMap } from "../redux/creative";
import useLocation from "../utils/hooks/useLocation";

import "./ExtremeReachReport.scss";

const PRETTY_DATE_FORMAT = "M/d/yy";

export const ExtremeReachReport = () => {
  const { company } = useLocation();

  const { colorMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const setError = useSetError();

  const [reportInfo, setReportInfo] = useState();

  useEffect(() => {
    if (company && !reportInfo) {
      let bail = false;
      (async () => {
        try {
          let res = await StreamingLambdaFetch("/creative/check", {
            params: {
              company,
            },
          });
          let info = await awaitJSON(res);
          if (bail) {
            return;
          }
          setReportInfo(info);
        } catch (e) {
          setError({
            message: `Failed to compare our creative data with Extreme Reach. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [company, reportInfo, setError]);

  const { campaign, id, placementMap, mismatches } = reportInfo || {};

  return (
    <div className="extremeReachReport">
      {reportInfo ? (
        <>
          <div className="reportHeaderRow">
            <h2>
              Extreme Reach vs BPM Sync Report:{" "}
              <strong>
                {campaign} ({id})
              </strong>
            </h2>
          </div>

          <div className="reportBody">
            <div className="xrBPMHeaders compareBox">
              <h2>Extreme Reach</h2>
              <h2>Bliss Point</h2>
            </div>

            <div className="reportContent">
              {!mismatches.length ? (
                <div>Creative rotations are synchronized</div>
              ) : (
                <>
                  {R.pipe(
                    R.sortBy(R.pipe(R.props(["placementID", "start", "end"]), R.join("_"))),
                    R.map(({ placementID, start, end, xr, bpm }) => {
                      const { name, network } = placementMap[placementID];
                      return (
                        <div key={`${placementID}${start}${end}`} className="mismatchedPlacement">
                          <h1 className="placementName">
                            {bpm ? (
                              <Img
                                src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
                                className="networkLogo"
                                title={network}
                                loader={
                                  <div className="logoNoImgBox">
                                    <Spinner size={25} />
                                  </div>
                                }
                                unloader={
                                  <div className="logoNoImgBox">
                                    <span>{network}</span>
                                  </div>
                                }
                              />
                            ) : (
                              <div className="logoNoImgBox">
                                <span>N/A</span>
                              </div>
                            )}
                            {name}{" "}
                            <small>
                              (
                              {start
                                ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(start)
                                : "Now"}{" "}
                              -{" "}
                              {end
                                ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(end)
                                : "Forever"}
                              )
                            </small>
                          </h1>
                          <div className="compareBox">
                            {[xr, bpm].map((rotation, i) => {
                              return (
                                <div key={i}>
                                  {rotation.length ? (
                                    <h3 className="rotationHeader">
                                      <PieChart
                                        data={rotation.map(({ isci, pct }) => ({
                                          name: isci,
                                          value: pct,
                                          color: colorMap[isci],
                                        }))}
                                        size={75}
                                      />
                                      <div className="isciHeader">
                                        {rotation.map(({ isci, pct }) => (
                                          <div key={isci + pct}>
                                            <div
                                              className="swatch"
                                              style={{
                                                backgroundColor: colorMap[isci],
                                              }}
                                            />
                                            <div>{isci}</div>
                                            <div>{pct}%</div>
                                          </div>
                                        ))}
                                      </div>
                                    </h3>
                                  ) : (
                                    <h4>No Rotation</h4>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  )(mismatches)}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  );
};
