import React from "react";
import moment from "moment";
import { Form } from "react-bootstrap";
import { BPMDateRange, Dropdown, DropdownToggleType } from "../../Components";
import { DateRange } from "../../utils/types";
import { tmrRetailerFilterOptions, tmrSortByOptions, PROFILE_FILTER_MESSAGING, CHART_FILL_COLORS, tmrKpiPull } from "./utils";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { SharedState, SlideState } from "../slideTemplateConstants";
import StackedBarAndLineChart from "./StackedBarAndLineChart";
import {
  SettingsComponentProps,
  SlideContext,
  SlideType,
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
} from "../slidesTypes";
import { fetchFreeFormSlideData } from "./QueryServices";

export interface MarketplaceReportFreeFormSlideState {
  title: string;
  subTitle: string;
  dates: DateRange;
  sort: string;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportFreeFormSlideData {
  title: string;
  subTitle: string;
}

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

class MarketplaceReportFreeFormSlide extends SlideType {
  static typeKey = "TmrFreeFormSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportFreeFormSlideState = {
    title: "Free Form Label Reporting",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    sort: "sales",
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportFreeFormSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, sort, dates, kpi, companyProfile } = state;

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label> Sort by</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={sort}
              options={tmrSortByOptions}
              onChange={change => setState({ sort: change })}
            />
          </div>
          <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
          <div>
            <Form.Label>Retailer Filter</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={retailer}
              options={tmrRetailerFilterOptions}
              onChange={change => setState({ retailer: change })}
            />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportFreeFormSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      retailer,
      dates,
      sort,
      companyProfile,
      kpi,
    } = state as MarketplaceReportFreeFormSlideState;

    const height = 500;
    const width = 1000;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }

    let data = await fetchFreeFormSlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      sort,
      profileNames
    );

    const uniqueLabels = new Set<string>();

    data.dataByWeek.forEach(item => {
      item.sales.forEach(sale => {
        uniqueLabels.add(sale.label);
      });
    });

    const dataKeys = Array.from(uniqueLabels);

    const transformedArray = data.dataByWeek.map(item => {
      const transformedSales = item.sales.reduce((acc, sale) => {
        acc[sale.label] = sale.value;
        return acc;
      }, {});

      return {
        ...item,
        sales: transformedSales,
      };
    });

    const barAndLineChart = (
      <StackedBarAndLineChart
        data={transformedArray}
        barDataKeys={dataKeys}
        lineDataKey={kpi}
        lineYAxisDataKey={kpi}
        lineYAxisLabel={kpi.toUpperCase()}
        fillColors={CHART_FILL_COLORS}
        legendLocation="top"
        xAxisDataKey="reportWeek"
        barYAxisLabel="Sales"
        barYAxisDataKey="sales"
        xAxisLabel="Report Week"
        height={height}
        width={width}
        isAnimationActive={false}
      />
    );

    let barAndLineChartUrl = await convertComponentToImageUrl(
      barAndLineChart,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      data,
      retailer,
      barAndLineChartUrl,
      dataKeys,
      kpi,
    });
  };
}
export default MarketplaceReportFreeFormSlide;
