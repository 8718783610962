import "./CustomSegments.scss";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { MdSave, MdCancel, MdAdd, MdEdit } from "react-icons/md";
import { BPMButton, FullPageSpinner, Spinner } from "../Components";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { SegmentationMappingLambdaFetch } from "../utils/fetch-utils";
import { RouteComponentProps } from "@reach/router";
import { CustomSegmentsData } from "./SegmentationMapping";
import { StateSetter } from "../utils/types";
import { useMap } from "../utils/hooks/useData";

interface NewValue {
  name: string;
  segmentId: number | string;
  existingSegment?: boolean;
}

interface CombinedNewData {
  [segmentId: string]: {
    name: string;
    values: string[];
  };
}

export enum RequiredSegmentNames {
  PLATFORM = "Platform",
  CHANNEL = "Channel",
  CAMPAIGN_OWNERSHIP = "Campaign Ownership",
  INCLUDE_IN_FEE_CALC = "Include in Fee Calc",
}

enum PresetSegmentNames {
  TACTIC = "Tactic",
  BRAND_VS_NONBRAND = "Brand vs Nonbrand",
  FUNNEL_TIER = "Funnel Tier",
  PROMO = "Promo",
  OBJECTIVE = "Objective",
}

const LOCKED_SEGMENT_NAMES: string[] = [
  ...(Object.values(RequiredSegmentNames) as string[]),
  ...(Object.values(PresetSegmentNames) as string[]),
];

const LOCKED_PHRASES_MAP: {
  [segmentName in PresetSegmentNames]: string[];
} = {
  [PresetSegmentNames.TACTIC]: ["prospect", "retarget", "winback", "retention"],
  [PresetSegmentNames.BRAND_VS_NONBRAND]: ["brand", "conquesting", "dsa", "blend"],
  [PresetSegmentNames.FUNNEL_TIER]: ["funnel", "upper", "mid", "lower"],
  [PresetSegmentNames.PROMO]: ["evergreen", "sale"],
  [PresetSegmentNames.OBJECTIVE]: [
    "conversions",
    "sales",
    "traffic",
    "awareness",
    "leads",
    "video views",
    "reach",
  ],
};

const PRESET_VALUES_MAP: {
  [segmentName in PresetSegmentNames]: string[];
} = {
  [PresetSegmentNames.TACTIC]: [
    "ASC+",
    "Auto",
    "Conquesting",
    "Demand Gen",
    "Direct",
    "Keyword Targeting",
    "Multi",
    "Product Targeting",
    "Prospecting",
    "Reach",
    "Retargeting",
    "Test",
    "Traffic",
    "Brand",
    "Nonbrand",
  ],
  [PresetSegmentNames.BRAND_VS_NONBRAND]: ["Brand", "Nonbrand", "Conquesting", "DSA", "Blended"],
  [PresetSegmentNames.FUNNEL_TIER]: ["Upper Funnel", "Mid Funnel", "Lower Funnel"],
  [PresetSegmentNames.PROMO]: ["Evergreen", "Sale", "N/A"],
  [PresetSegmentNames.OBJECTIVE]: [
    "Conversions",
    "Sales",
    "Traffic",
    "Awareness",
    "Leads",
    "Video Views",
    "Reach",
    "N/A",
  ],
};

const CustomSegments = ({
  data,
  setData,
  dataGranularity,
  setDataGranularity,
}: {
  data: CustomSegmentsData[] | undefined;
  setData: StateSetter<CustomSegmentsData[] | undefined>;
  dataGranularity: "ad" | "ad_group" | "campaign";
  setDataGranularity: React.Dispatch<
    React.SetStateAction<"ad" | "ad_group" | "campaign" | undefined>
  >;
} & RouteComponentProps): JSX.Element => {
  const setError = useSetError();
  const { cid } = useCompanyInfo();
  const [newSegments, setNewSegments] = useState<Record<string, string>>({});
  const [newSegmentValues, setNewSegmentValues] = useState<Record<string, NewValue>>({});
  const [editedSegmentNamesMap, setEditedSegmentName, setEditedSegmentNameMap] = useMap<
    string,
    string | undefined
  >({});
  const [
    editedSegmentValueNamesMap,
    setEditedSegmentValueName,
    setEditedSegmentValueNameMap,
  ] = useMap<string, string | undefined>({});
  const [segmentsInEditModeMap, setSegmentsInEditModeValue, setSegmentsInEditModeMap] = useMap<
    string,
    boolean
  >({});
  const [segments, setSegments] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [selectedGranularity, setSelectedGranularity] = useState<"ad" | "ad_group" | "campaign">(
    dataGranularity
  );
  const [inputContainsLockedValue, setInputContainsLockedValue] = useState(false);

  const [unEditableSegmentIdsAll, setUnEditableSegmentIdsAll] = useState<number[]>([]);
  const [currentSegmentList, setCurrentSegmentList] = useState<string[]>([]);
  const UNEDITABLE_SEGMENT_IDS = [1, 2, 3, 4]; // Channel, Platform, Campaign Ownership and Include in Fee Calc values should not be editable

  useEffect(() => {
    if (data) {
      const unEditableIds = data
        .filter(segment => LOCKED_SEGMENT_NAMES.includes(segment.segmentName))
        .map(segment => segment.segmentId);
      const currentSegments = data.map(segment => segment.segmentName);

      setCurrentSegmentList(currentSegments);
      setUnEditableSegmentIdsAll(unEditableIds);
    }
  }, [setError, cid, data]);

  const checkIfLockedValue = useCallback(
    (value: string, segmentName: string) => {
      const checkIfInList = (arr, str) => {
        return arr.reduce((acc, val) => {
          return str.toLocaleLowerCase().includes(val) ? val : acc;
        }, "");
      };

      let invalidSegment = "";
      let bannedValue = "";
      Object.keys(LOCKED_PHRASES_MAP).forEach(lockedSegName => {
        const lockedValues = LOCKED_PHRASES_MAP[lockedSegName];
        const foundBannedValue = checkIfInList(lockedValues, value);
        if (value.length > 0 && foundBannedValue && segmentName !== lockedSegName) {
          invalidSegment = lockedSegName;
          bannedValue = foundBannedValue;
        }
      });

      if (invalidSegment) {
        setError({
          message: `Please add the preset ${invalidSegment} segment to label campaigns with "${bannedValue}".`,
        });
        setInputContainsLockedValue(true);
      } else {
        setInputContainsLockedValue(false);
      }
    },
    [setError]
  );

  // Adds a new custom segment
  const addNewSegment = useCallback((option: PresetSegmentNames | string, index: number) => {
    const segmentNameId = uuid.v4();
    const segmentValueId = uuid.v4();
    switch (option) {
      case PresetSegmentNames.TACTIC:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: PresetSegmentNames.TACTIC,
        }));
        setNewSegmentValues(current => ({
          ...current,
          ...PRESET_VALUES_MAP[PresetSegmentNames.TACTIC].reduce((acc, valueName) => {
            acc[uuid.v4()] = {
              name: valueName,
              segmentId: segmentNameId,
              existingSegment: false,
            };
            return acc;
          }, {}),
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case PresetSegmentNames.BRAND_VS_NONBRAND:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: PresetSegmentNames.BRAND_VS_NONBRAND,
        }));
        setNewSegmentValues(current => ({
          ...current,
          ...PRESET_VALUES_MAP[PresetSegmentNames.BRAND_VS_NONBRAND].reduce((acc, valueName) => {
            acc[uuid.v4()] = {
              name: valueName,
              segmentId: segmentNameId,
              existingSegment: false,
            };
            return acc;
          }, {}),
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case PresetSegmentNames.FUNNEL_TIER:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: PresetSegmentNames.FUNNEL_TIER,
        }));
        setNewSegmentValues(current => ({
          ...current,
          ...PRESET_VALUES_MAP[PresetSegmentNames.FUNNEL_TIER].reduce((acc, valueName) => {
            acc[uuid.v4()] = {
              name: valueName,
              segmentId: segmentNameId,
              existingSegment: false,
            };
            return acc;
          }, {}),
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case PresetSegmentNames.PROMO:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: PresetSegmentNames.PROMO,
        }));
        setNewSegmentValues(current => ({
          ...current,
          ...PRESET_VALUES_MAP[PresetSegmentNames.PROMO].reduce((acc, valueName) => {
            acc[uuid.v4()] = {
              name: valueName,
              segmentId: segmentNameId,
              existingSegment: false,
            };
            return acc;
          }, {}),
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case PresetSegmentNames.OBJECTIVE:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: PresetSegmentNames.OBJECTIVE,
        }));
        setNewSegmentValues(current => ({
          ...current,
          ...PRESET_VALUES_MAP[PresetSegmentNames.OBJECTIVE].reduce((acc, valueName) => {
            acc[uuid.v4()] = {
              name: valueName,
              segmentId: segmentNameId,
              existingSegment: false,
            };
            return acc;
          }, {}),
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case "":
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: "",
        }));
        setNewSegmentValues(current => ({
          ...current,
          [segmentValueId]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
    }
  }, []);

  // Adds a new value to a segment
  const addNewSegmentValue = useCallback((name, segmentId, existingSegment = false) => {
    const id = uuid.v4();
    setNewSegmentValues(current => ({ ...current, [id]: { name, segmentId, existingSegment } }));
  }, []);

  // Discard all unsaved changes
  const discardChanges = useCallback(() => {
    setSegments([]);
    setNewSegments({});
    setNewSegmentValues({});
    setEditedSegmentNameMap({});
    setEditedSegmentValueNameMap({});
    setSegmentsInEditModeMap({});
    setSelectedGranularity(dataGranularity);
  }, [
    dataGranularity,
    setEditedSegmentNameMap,
    setEditedSegmentValueNameMap,
    setSegmentsInEditModeMap,
  ]);

  const hasSegmentEdits = useMemo(() => {
    return !(
      R.isEmpty(newSegments) &&
      R.isEmpty(newSegmentValues) &&
      R.isEmpty(Object.values(editedSegmentNamesMap).filter(val => !!val)) &&
      R.isEmpty(Object.values(editedSegmentValueNamesMap).filter(val => !!val))
    );
  }, [editedSegmentNamesMap, editedSegmentValueNamesMap, newSegmentValues, newSegments]);

  const hasGranularityEdits = useMemo(() => {
    return dataGranularity !== selectedGranularity;
  }, [dataGranularity, selectedGranularity]);

  // Save Changes
  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);

      if (hasSegmentEdits) {
        let combinedNewData: CombinedNewData = {};

        for (let [segmentId, segmentName] of Object.entries(newSegments)) {
          combinedNewData[segmentId] = {
            name: segmentName,
            values: [],
          };
        }

        const newValuesForExistingSegments: any[] = [];

        for (let value of Object.values(newSegmentValues)) {
          const { segmentId, name, existingSegment } = value;
          if (existingSegment) {
            newValuesForExistingSegments.push({ name, segmentId });
          } else {
            combinedNewData[segmentId].values.push(name);
          }
        }

        const newSegmentNameToValuesMap = {};
        for (let obj of Object.values(combinedNewData)) {
          newSegmentNameToValuesMap[obj.name] = obj.values;
        }

        let editedSegmentNames = {};
        Object.keys(editedSegmentNamesMap).forEach(key => {
          const value = editedSegmentNamesMap[key];
          if (!!value) {
            editedSegmentNames[key] = value;
          }
        });

        let editedSegmentValueNames = {};
        Object.keys(editedSegmentValueNamesMap).forEach(key => {
          const value = editedSegmentValueNamesMap[key];
          if (!!value) {
            editedSegmentValueNames[key] = value;
          }
        });

        await SegmentationMappingLambdaFetch("/updateCustomSegments", {
          method: "POST",
          body: {
            company: cid,
            newSegments: newSegmentNameToValuesMap,
            newValuesForExistingSegments,
            editedSegmentNames,
            editedSegmentValueNames,
          },
        });
      }

      if (hasGranularityEdits) {
        await SegmentationMappingLambdaFetch("/updateGranularity", {
          method: "POST",
          body: {
            company: cid,
            granularity: selectedGranularity,
          },
        });
        setDataGranularity(selectedGranularity);
      }

      setData(undefined);
      setSegments([]);
      setNewSegments({});
      setNewSegmentValues({});
      setEditedSegmentNameMap({});
      setEditedSegmentValueNameMap({});
      setSegmentsInEditModeMap({});
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: `Failed to save changes: ${reportError.message}`, reportError });
    }
  }, [
    cid,
    editedSegmentNamesMap,
    editedSegmentValueNamesMap,
    hasGranularityEdits,
    hasSegmentEdits,
    newSegmentValues,
    newSegments,
    selectedGranularity,
    setData,
    setDataGranularity,
    setEditedSegmentNameMap,
    setEditedSegmentValueNameMap,
    setError,
    setSegmentsInEditModeMap,
  ]);

  const handleSegmentNameOnChange = useCallback(({ segment, value, shouldTrim }) => {
    const newVal = shouldTrim ? value.trim() : value;
    setNewSegments(current => ({
      ...current,
      [segment]: newVal,
    }));
  }, []);

  const handleSegmentValueOnChange = useCallback(
    ({ segment, value, valueId, segmentId, shouldTrim }) => {
      let newVal = shouldTrim ? value.trim() : value;
      checkIfLockedValue(newVal, segment);
      setNewSegmentValues(current => ({
        ...current,
        [valueId]: { name: newVal, segmentId },
      }));
    },
    [checkIfLockedValue]
  );

  return data ? (
    <div className="customSegmentsPage">
      <div className="segmentList">
        <div className="segmentContainer" key="granularity">
          <div className="segmentHeader">
            <div className="segmentName">Granularity</div>
          </div>
          <div className="granularityExplanation">
            <div>
              Set the lowest level of granularity to segment by. We recommend choosing a value when
              you first set up segments for your client, before any labeling.
            </div>
          </div>
          <Form.Check
            type="radio"
            label="Campaign (default)"
            checked={selectedGranularity === "campaign"}
            id="campaign"
            onChange={() => setSelectedGranularity("campaign")}
          />
          <Form.Check
            type="radio"
            label="Ad Group"
            checked={selectedGranularity === "ad_group"}
            id="ad_group"
            onChange={() => setSelectedGranularity("ad_group")}
          />
          <Form.Check
            type="radio"
            label="Ad"
            checked={selectedGranularity === "ad"}
            id="ad"
            onChange={() => setSelectedGranularity("ad")}
          />
        </div>
        {data.map(segmentObj => {
          const { segmentId, segmentName, values } = segmentObj;
          return (
            <div className="segmentContainer" key={segmentId}>
              <div className="segmentHeader">
                {segmentsInEditModeMap[segmentId] && !UNEDITABLE_SEGMENT_IDS.includes(segmentId) ? (
                  <Form.Control
                    size="sm"
                    placeholder="New Value"
                    type="text"
                    value={editedSegmentNamesMap[segmentId] || segmentName}
                    key={segmentId}
                    onChange={e => {
                      checkIfLockedValue(e.currentTarget.value, segmentName);
                      setEditedSegmentName(segmentId.toString(), e.currentTarget.value);
                    }}
                  />
                ) : (
                  <div className="segmentName">{segmentName}</div>
                )}
                {segmentsInEditModeMap[segmentId] && !unEditableSegmentIdsAll.includes(segmentId) && (
                  <BPMButton
                    className="editSegmentButton"
                    size="sm"
                    onClick={() => {
                      setEditedSegmentName(segmentId.toString(), undefined);
                      values.forEach(val =>
                        setEditedSegmentValueName(val.valueId.toString(), undefined)
                      );
                      setSegmentsInEditModeValue(segmentId.toString(), false);
                    }}
                  >
                    <MdCancel />
                  </BPMButton>
                )}
                {!segmentsInEditModeMap[segmentId] && !unEditableSegmentIdsAll.includes(segmentId) && (
                  <BPMButton
                    className="editSegmentButton"
                    size="sm"
                    onClick={() => setSegmentsInEditModeValue(segmentId.toString(), true)}
                  >
                    <MdEdit />
                  </BPMButton>
                )}
              </div>
              <div className="segmentValues">
                {!LOCKED_SEGMENT_NAMES.includes(segmentName) && (
                  <BPMButton
                    className="addSegmentValueButton"
                    size="sm"
                    onClick={e => addNewSegmentValue("", segmentId, true)}
                  >
                    <MdAdd />
                  </BPMButton>
                )}
                {Object.entries(newSegmentValues)
                  .filter(([valueId, value]) => value.segmentId === segmentId)
                  .map(([valueId, value]) => {
                    return (
                      <Form.Control
                        size="sm"
                        placeholder="New Value"
                        type="text"
                        value={value.name}
                        key={valueId}
                        onChange={e => {
                          const newVal = e.currentTarget.value;
                          checkIfLockedValue(e.currentTarget.value, segmentName);
                          setNewSegmentValues(current => ({
                            ...current,
                            [valueId]: {
                              name: newVal,
                              segmentId: value.segmentId,
                              existingSegment: true,
                            },
                          }));
                        }}
                      />
                    );
                  })}
                {values.map(value => {
                  const { valueId, valueName } = value;
                  return segmentsInEditModeMap[segmentId] &&
                    !UNEDITABLE_SEGMENT_IDS.includes(segmentId) ? (
                    <Form.Control
                      size="sm"
                      placeholder="New Value"
                      type="text"
                      value={editedSegmentValueNamesMap[valueId] || valueName}
                      key={valueId}
                      onChange={e => {
                        setEditedSegmentValueName(valueId.toString(), e.currentTarget.value);
                      }}
                    />
                  ) : (
                    <div className="option" key={valueId}>
                      {valueName}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {segments.map((segment, index) =>
          segment === "a" ? (
            <div key={index} className="segmentContainer">
              <div className="segmentHeader">
                <div className="segmentName">Choose Segment type</div>
              </div>
              <div className="segmentValues">
                <div className="presetOptionTitle">Presets</div>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(PresetSegmentNames.TACTIC)}
                  onClick={() => addNewSegment(PresetSegmentNames.TACTIC, index)}
                >
                  <MdAdd /> Tactic
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(PresetSegmentNames.BRAND_VS_NONBRAND)}
                  onClick={() => addNewSegment(PresetSegmentNames.BRAND_VS_NONBRAND, index)}
                >
                  <MdAdd /> Brand vs Nonbrand
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(PresetSegmentNames.FUNNEL_TIER)}
                  onClick={() => addNewSegment(PresetSegmentNames.FUNNEL_TIER, index)}
                >
                  <MdAdd /> Funnel Tier
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(PresetSegmentNames.PROMO)}
                  onClick={() => addNewSegment(PresetSegmentNames.PROMO, index)}
                >
                  <MdAdd /> Promo
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(PresetSegmentNames.OBJECTIVE)}
                  onClick={() => addNewSegment(PresetSegmentNames.OBJECTIVE, index)}
                >
                  <MdAdd /> Objective
                </BPMButton>
                <div className="customOptionTitle">Custom</div>
                <BPMButton size="sm" onClick={() => addNewSegment("", index)}>
                  <MdAdd /> Custom Segment Type
                </BPMButton>
              </div>
            </div>
          ) : (
            <div className="segmentContainer" key={segment}>
              <div className="segmentHeader">
                <div className="segmentName">
                  <Form.Control
                    placeholder="Segment Name"
                    type="text"
                    value={newSegments[segment] || ""}
                    disabled={Object.values(LOCKED_SEGMENT_NAMES).includes(newSegments[segment])}
                    onChange={e =>
                      handleSegmentNameOnChange({ segment, value: e.currentTarget.value })
                    }
                    onBlur={e =>
                      handleSegmentNameOnChange({
                        segment,
                        value: e.currentTarget.value,
                        shouldTrim: true,
                      })
                    }
                  />
                </div>
              </div>
              <div className="segmentValues">
                {!LOCKED_SEGMENT_NAMES.includes(newSegments[segment]) && (
                  <BPMButton size="sm" onClick={() => addNewSegmentValue("", segment)}>
                    <MdAdd />
                  </BPMButton>
                )}
                {Object.entries(newSegmentValues)
                  .filter(([valueId, value]) => value.segmentId === segment)
                  .map(([valueId, value]) => {
                    return (
                      <Form.Control
                        size="sm"
                        placeholder="Segment Value"
                        type="text"
                        value={value.name}
                        key={valueId}
                        onChange={e =>
                          handleSegmentValueOnChange({
                            segment,
                            valueId,
                            value: e.currentTarget.value,
                            segmentId: value.segmentId,
                          })
                        }
                        onBlur={e =>
                          handleSegmentValueOnChange({
                            segment,
                            valueId,
                            value: e.currentTarget.value,
                            segmentId: value.segmentId,
                            shouldTrim: true,
                          })
                        }
                      />
                    );
                  })}
              </div>
            </div>
          )
        )}
        <BPMButton
          className="addNewSegmentButton"
          onClick={() => {
            setSegments([...segments, "a"]);
          }}
          variant="outline-primary"
        >
          <MdAdd />
        </BPMButton>
      </div>
      {(hasSegmentEdits || hasGranularityEdits) && (
        <div className="floatingControls">
          <BPMButton onClick={discardChanges} variant="danger">
            <MdCancel />
          </BPMButton>

          <BPMButton
            disabled={
              Object.values(newSegments).some(value => value === "") || inputContainsLockedValue
            }
            onClick={saveChanges}
            variant="success"
          >
            {saving ? <Spinner /> : <MdSave />}
          </BPMButton>
        </div>
      )}
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default CustomSegments;
