import * as R from "ramda";
import { MdClose } from "react-icons/md";
import { PlanRow } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { StateSetter } from "../../utils/types";
import { EditsMap, ExtendEditModalData } from "../linearBuyingUtils";
import PendingEdit from "./PendingEdit";
import PendingNewRow from "./PendingNewRow";
import "./PendingChanges.scss";

interface NewRowsGrouped {
  [network: string]: {
    [key: string]: PlanRow[];
  };
}

/**
 * Creates a key to be able to compare exact copies of the same plan rows (including creatives).
 * This way, the same plan row across different weeks can have the same key to group together in Pending Changes.
 */
export const makeEntireRowKey = (row: PlanRow): string => {
  const {
    avail,
    length,
    daypart_start,
    daypart_end,
    dow,
    type,
    count,
    cost,
    notes,
    network,
    creatives,
  } = row;
  const creativesStr = JSON.stringify(creatives);

  const key = `${network}_${avail}_${daypart_start}_${daypart_end}_${dow}_${type}_${length}_${count}_${cost}_${
    notes || ""
  }_${creativesStr}`;
  return key;
};

interface PendingChangesProps {
  editsMap: EditsMap;
  newRows: Record<string, PlanRow>;
  setEditsMap: StateSetter<EditsMap>;
  setNewRows: StateSetter<Record<string, PlanRow>>;
  setExtendEditModalData: StateSetter<ExtendEditModalData | undefined>;
  setShowPendingChanges: StateSetter<boolean>;
}

const PendingChanges: React.FC<PendingChangesProps> = ({
  editsMap,
  newRows,
  setEditsMap,
  setNewRows,
  setExtendEditModalData,
  setShowPendingChanges,
}) => {
  let editsByWeek: Record<string, ExtendEditModalData[]> = {};

  for (let row of R.values(editsMap)) {
    const { week } = row;
    editsByWeek = R.mergeRight(editsByWeek, {
      [week]: [
        ...((R.path([week], editsByWeek) as ExtendEditModalData[]) || []),
        {
          row,
          edits: row.edits!,
          selectedEditToExtend: null,
        },
      ],
    });
  }

  let newsRowsGroupedAcrossWeeks: NewRowsGrouped = {};
  for (let row of R.values(newRows)) {
    const { network } = row;
    const key = makeEntireRowKey(row);

    newsRowsGroupedAcrossWeeks = R.mergeDeepRight(newsRowsGroupedAcrossWeeks, {
      [network]: {
        [key]: [...((R.path([network, key], newsRowsGroupedAcrossWeeks) as PlanRow[]) || []), row],
      },
    }) as NewRowsGrouped;
  }

  const deleteEdit = (key: string, editType: string) => {
    // Create a new row that has the edit removed.
    let removedEdit = {
      ...editsMap[key],
      edits: R.omit([editType], editsMap[key].edits),
    } as PlanRow;

    // If after removing the edit, the "edits" field is empty, delete the whole row from editsMap
    // because there are no edits left for this row.
    if (R.isEmpty(removedEdit.edits)) {
      let removedRow = R.omit([key], editsMap);
      setEditsMap(removedRow);
    } else {
      // Otherwise, there are still other edits remaining for this row so just update editsMap with
      // the removedEdit row.
      setEditsMap(current => ({ ...current, [key]: removedEdit }));
    }
  };

  const deleteNewRow = (key: string) => {
    setNewRows(current => R.omit([key], current));
  };

  return (
    <div className="pendingChangesContainer">
      <div className="pendingChangesTop">
        Pending Changes
        <div className="closeButton" onClick={() => setShowPendingChanges(false)}>
          <MdClose />
        </div>
      </div>
      <div className="pendingChangesBody">
        {!R.isEmpty(editsByWeek) && (
          <div className="body">
            {R.keys(editsByWeek).map(week => {
              return (
                <div className="weekContainer" key={week}>
                  <div>
                    <span className="weekTitle">Edits: </span>
                    {week}
                  </div>
                  <div className="editsList">
                    {editsByWeek[week].map(editData => {
                      return (
                        <PendingEdit
                          key={JSON.stringify(editData)}
                          editData={editData}
                          deleteEdit={deleteEdit}
                          setExtendEditModalData={setExtendEditModalData}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!R.isEmpty(newsRowsGroupedAcrossWeeks) && (
          <div className="body">
            {R.keys(newsRowsGroupedAcrossWeeks).map(network => {
              return (
                <div className="weekContainer" key={network}>
                  <div>
                    <span className="weekTitle">{network}</span>
                  </div>
                  <div className="newRowsList">
                    {R.keys(newsRowsGroupedAcrossWeeks[network]).map(rowKey => {
                      const rows = newsRowsGroupedAcrossWeeks[network][rowKey];
                      return (
                        <div key={rowKey}>
                          <PendingNewRow rows={rows} deleteNewRow={deleteNewRow} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingChanges;
