import React, { useState, useEffect } from "react";

import { useSetError } from "../redux/modals";
import { awaitJSON, ToolsLambdaFetch } from "../utils/fetch-utils";

import { Page, Skeleton, TableSkeleton, BPMTable, NumberFormatter } from "../Components";

import "./StreamingDiscrepancies.scss";

const StreamingDiscrepancies = () => {
  const setError = useSetError();

  const headers = [
    {
      name: "day",
      label: "Day",
    },
    {
      name: "company",
      label: "Company",
      width: 150,
    },
    {
      name: "description",
      label: "Description",
      width: 150,
    },
    {
      name: "network",
      label: "Network",
      width: 200,
    },
    {
      name: "platform",
      label: "Platform",
      width: 200,
    },
    {
      name: "details",
      label: "Details",
    },
    {
      name: "yesterday",
      label: "Yesterday",
      flex: 1,
      renderer: row => {
        if (row.description === "ip frequency") {
          return <NumberFormatter value={row.yesterday_ratio} type={"#2"} decimals={2} />;
        } else if (row.yesterday_ratio) {
          return (
            <div>
              <NumberFormatter value={row.yesterday_ratio / 100} type={"%"} decimals={1} /> (
              <NumberFormatter
                value={parseInt(row.yesterday_monitored)}
                type={"#"}
                decimals={0}
              />{" "}
              / <NumberFormatter value={parseInt(row.yesterday_total)} type={"#"} decimals={0} />)
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "last_week",
      label: "Last Week",
      flex: 2,
      renderer: row => {
        if (row.description === "ip frequency") {
          return <NumberFormatter value={row.last_week_ratio} type={"#2"} decimals={2} />;
        } else if (row.last_week_ratio) {
          return (
            <div>
              <NumberFormatter value={row.last_week_ratio / 100.0} type={"%"} decimals={1} /> (
              <NumberFormatter
                value={parseInt(row.last_week_monitored)}
                type={"#"}
                decimals={0}
              />{" "}
              / <NumberFormatter value={parseInt(row.last_week_total)} type={"#"} decimals={0} />)
            </div>
          );
        } else {
          return "-";
        }
      },
    },
  ];
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      (async () => {
        try {
          let result = await ToolsLambdaFetch("/streaming_discrepancies");
          result = await awaitJSON(result);
          setData(result);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [setError, data]);

  return (
    <Page title="Streaming Discrepancies" pageType="Streaming Discrepancies" minHeight={600}>
      <div className="streamingDiscrepanciesPageContainer">
        {data ? (
          <BPMTable
            headers={headers}
            data={data}
            noRowsRenderer={() => <div>No rows to show.</div>}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default StreamingDiscrepancies;
