import React, { useContext } from "react";

import { Button, ButtonGroup } from "react-bootstrap";

import "./ToggleNav.scss";

const ToggleNavContext = React.createContext();

export const ToggleNavButton = ({ children, className = "", value }) => {
  const { activeKey, onChange } = useContext(ToggleNavContext);

  return (
    <Button
      active={value === activeKey}
      onClick={() => onChange(value)}
      className={`noOutline ${className}`}
    >
      {children}
    </Button>
  );
};

export const ToggleNav = ({ activeKey, onChange, className = "", children, ...passthrough }) => {
  return (
    <ButtonGroup toggle className={`toggleNav ${className}`} {...passthrough}>
      <ToggleNavContext.Provider value={{ activeKey, onChange }}>
        {children}
      </ToggleNavContext.Provider>
    </ButtonGroup>
  );
};
