import React from "react";

export interface WtoStatusProps {
  color: string[];
  status: string[];
  textColor?: string;
}

export const WtoStatus: React.FC<WtoStatusProps> = ({ color, status, textColor }) => {
  let isTextColor = Boolean(textColor);
  return (
    <div className="wtoStatus">
      <div className="statusWrapper">
        <span
          className="statusWrapperText"
          style={{ backgroundColor: color[0], color: isTextColor ? textColor : "#fff" }}
        >
          {status[0]}
        </span>
      </div>
      {status[1] && color[1] && (
        <div className="statusWrapper">
          <span
            className="statusWrapperText"
            style={{ backgroundColor: color[1], color: isTextColor ? textColor : "#fff" }}
          >
            {status[1]}
          </span>
        </div>
      )}
    </div>
  );
};

export default WtoStatus;
