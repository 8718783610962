import "./StreamingPacing.scss";
import { AdminLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  DateRangePicker,
  OldFilterBar,
  FullPageSpinner,
  Img,
  OldDropdown,
  OverlayTrigger,
  Page,
  Spinner,
} from "../Components";
import { download } from "../utils/download-utils";
import { formatPacingNumber } from "../utils/format-utils";
import { MdHelp, MdSave, MdWarning } from "react-icons/md";
import { ToggleButtonGroup, ToggleButton, Button, Tooltip, Modal } from "react-bootstrap";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as uuid from "uuid";
import AutoSizer from "react-virtualized-auto-sizer";
import Papa from "papaparse";
import React, { useState, useEffect, useCallback, useMemo } from "react";

interface Adjusted {
  impressions: number;
  netSpend: number;
  spend: number;
}
type Simple = Adjusted;

interface Booked {
  simple: Simple;
  adjusted: Adjusted;
}
type Cleared = Booked;
type Projection = Cleared;
type Ideal = Adjusted;

interface SimpleWithIdeal extends Simple {
  ideal: Ideal;
}

interface AdjustedWithIdeal extends Adjusted {
  ideal: Ideal;
}

interface ChartData {
  date: string;
  ideal: number;
  value: number;
  sum?: number;
  idealSum?: number;
}

interface Alert {
  simple: { impressions: true; spend: true };
  adjusted: { impressions: true; spend: true };
}

interface Day {
  adjusted: AdjustedWithIdeal;
  date: string;
  simple: SimpleWithIdeal;
}

interface SummaryData {
  booked: Booked;
  cleared: Cleared;
  end: string;
  projection: Projection;
  start: string;
}

type PacingSubSubData = Record<string, Day>;

interface PacingSubData {
  alert: Alert;
  booked: Booked;
  cleared: Cleared;
  days: Record<string, Day>;
  derived_network: string;
  description: string;
  key: string;
  network: string;
  platform: string;
  projection: Projection;
}

interface PacingData {
  booked: Booked;
  cleared: Cleared;
  company: string;
  key: string;
  platform: string;
  projection: Projection;
  subData?: PacingSubData[];
}

interface PacingFetchLambdaParams {
  company?: string;
  description?: string;
  end: string;
  globalPlatform?: string;
  groupByNetwork?: number;
  network?: string;
  platform?: string;
  start: string;
  useNetworkGroup?: number;
}

interface FilterBarPart {
  className?: string;
  label?: string | JSX.Element;
  leftVal?: number;
  type: PacingBarTypes;
  val?: number;
}

export enum PacingBarTypes {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
  LABEL = "LABEL",
  ALERT = "ALERT",
}

const DATE_FORMAT = "yyyy-MM-dd";

const IMPRESSIONS_LABEL = "Imps";
const SPEND_LABEL = "Spend";

const SIMPLE_LABEL = "Simple";
const ADJUSTED_LABEL = "Adjusted";

const USE_NETWORK_LABEL = "Network";
const USE_NETWORK_GROUP_LABEL = "Network Group";
const USE_NETWORK_KEY = "network";
const USE_NETWORK_GROUP_KEY = "network group";

const BY_COMPANY_LABEL = "Company";
const BY_COMPANY_KEY = "companies";
const BY_NETWORK_KEY = "networks";

const mergeItems = items =>
  items && R.length(items)
    ? R.pipe(
        R.tail,
        R.reduce(
          (agg, elem) =>
            R.mergeDeepWith(
              (a, b) => a + b,
              agg,
              R.pick(["booked", "cleared", "projection"], elem)
            ),
          R.pick(["booked", "cleared", "projection"], items[0])
        )
      )(items)
    : null;

export const pacingDataReducer = (
  data: PacingData[],
  key: string,
  subData: PacingSubData[],
  subKey?: string,
  subSubData?: PacingSubSubData
): PacingData[] => {
  if (subData && subSubData) {
    let outerI = R.findIndex(elem => elem.key === key, R.defaultTo([], data) as PacingData[]);
    if (outerI > -1 && !R.isNil(data)) {
      let outerObj = data[outerI];
      if (outerObj.subData) {
        let innerI = R.findIndex(elem => elem.key === subKey, outerObj.subData);
        if (innerI > -1) {
          return R.adjust(
            outerI,
            elem => ({
              ...elem,
              subData: R.adjust(
                innerI,
                elem => ({ ...elem, days: subSubData }),
                R.path([outerI, "subData"], data) as PacingSubData[]
              ),
            }),
            data
          );
        }
      }
    }
  } else if (subData) {
    const i = R.findIndex(
      (elem: PacingData) => elem.key === key,
      R.defaultTo([], data as PacingData[])
    );
    if (i > -1 && !R.isNil(data)) {
      return R.adjust(i, elem => ({ ...elem, subData }), R.defaultTo([], data) as PacingData[]);
    }
  }

  return data;
};

const usePacingInfo = (globalPlatform, setError) => {
  const [dataMap, setDataMap] = useState<Record<string, PacingData[]>>({});
  const [dataReady, setDataReady] = useState(false);
  const [dates, setDates] = useState(
    globalPlatform === "Display"
      ? {
          start: Dfns.format(DATE_FORMAT, Dfns.startOfMonth(new Date())),
          end: Dfns.format(DATE_FORMAT, Dfns.endOfMonth(new Date())),
        }
      : {
          start: Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(new Date())),
          end: Dfns.format(DATE_FORMAT, Dfns.addDays(6, Dfns.startOfISOWeek(new Date()))),
        }
  );
  const [grouping, setGrouping] = useState<string>(BY_COMPANY_KEY);
  const [key, setKey] = useState<string | undefined>();
  const [lastConfig, setLastConfig] = useState<string | undefined | null>();
  const [subKey, setSubKey] = useState<string | undefined>();
  const { start, end } = dates;
  const [useNetworkGroup, setUseNetworkGroup] = useState<string>(
    globalPlatform === "Display" ? USE_NETWORK_GROUP_KEY : USE_NETWORK_KEY
  );
  const config = `${globalPlatform}_${useNetworkGroup}_${grouping}_${start}_${end}_${
    key ? key : "none"
  }_${subKey ? subKey : "none"}`;
  const dataMapKey = config.split("_").slice(0, -2).join("_");

  const fetchPacingInfo = useCallback(() => {
    (async () => {
      setDataReady(false);
      // Leave this definition here so that we can keep config in the dependency array
      const dataMapKey = config.split("_").slice(0, -2).join("_");
      let data = dataMap[dataMapKey];
      let subData;
      let subSubData;
      let foundPacingData: PacingData | undefined;
      let foundPacingSubData: PacingSubData | undefined;
      if (R.isNil(data)) {
        const response = await AdminLambdaFetch("/pacing", {
          params: {
            ...dates,
            groupByNetwork: grouping === BY_NETWORK_KEY ? 1 : 0,
            globalPlatform,
            useNetworkGroup: useNetworkGroup === USE_NETWORK_GROUP_KEY ? 1 : 0,
          },
        });
        data = await awaitJSON(response);
      }
      if (data && key) {
        foundPacingData = R.find(elem => elem.key === key, data as PacingData[]);
        if (R.isNil(foundPacingData)) {
          if (!R.isNil(setError)) {
            setError({
              message: `We don't have any data for key: ${key}. Please select another option!`,
              title: "No Data found",
            });
          }
          setKey(undefined);
          return;
        } else {
          ({ subData } = foundPacingData);
        }
        if (R.isNil(subData)) {
          let params: PacingFetchLambdaParams = {
            ...dates,
            globalPlatform,
            ...R.pick(
              ["company", "network", "description", "platform", "audio_buy_type"],
              foundPacingData
            ),
            useNetworkGroup: useNetworkGroup === USE_NETWORK_GROUP_KEY ? 1 : 0,
          };

          if (grouping === BY_COMPANY_KEY) {
            params.company = key;
          } else {
            params.groupByNetwork = 1;
          }
          if (useNetworkGroup === USE_NETWORK_GROUP_KEY) {
            delete params.description;
          }
          const response = await AdminLambdaFetch("/pacing", {
            params,
          });
          subData = await awaitJSON(response);
          data = pacingDataReducer(data, key, subData);
        }
      }
      if (data && subData && key && subKey) {
        foundPacingSubData = R.find(
          (elem: PacingSubData) => elem.key === subKey,
          subData as PacingSubData[]
        );
        if (R.isNil(foundPacingSubData)) {
          if (!R.isNil(setError)) {
            setError({
              message: `We don't have any data for key: ${key} and subKey: ${subKey}. Please select another option!`,
              title: "No Data found",
            });
          }
          setSubKey(undefined);
          return;
        } else {
          subSubData = foundPacingSubData.days;
        }
        if (R.isNil(subSubData)) {
          let params: PacingFetchLambdaParams = {
            ...dates,
            globalPlatform,
            ...R.pick(["company", "network", "description", "platform"], foundPacingData),
            ...R.pick(["company", "network", "description", "platform"], foundPacingSubData),
            useNetworkGroup: useNetworkGroup === USE_NETWORK_GROUP_KEY ? 1 : 0,
          };
          if (useNetworkGroup === USE_NETWORK_GROUP_KEY) {
            delete params.description;
          }
          const response = await AdminLambdaFetch("/pacing", {
            params,
          });
          subSubData = await awaitJSON(response);
          data = pacingDataReducer(data, key, subData, subKey, subSubData);
        }
      }
      setDataMap(map => ({
        ...map,
        [dataMapKey]: data,
      }));
      setDataReady(true);
    })();
  }, [config, dataMap, dates, globalPlatform, grouping, key, setError, subKey, useNetworkGroup]);

  useEffect(() => {
    // Only fetch if both dates are set and if we have a new config
    if (start && end && lastConfig !== config && !R.isNil(globalPlatform)) {
      try {
        fetchPacingInfo();
        setLastConfig(config);
      } catch (e) {
        let error = e as Error;
        if (!R.isNil(setError)) {
          setError({
            message: error.message,
            title: error.message,
          });
        }
      }
    }
  }, [config, end, fetchPacingInfo, globalPlatform, lastConfig, setError, start]);

  return {
    data: dataReady && dataMap[dataMapKey] ? dataMap[dataMapKey] : undefined,
    dates,
    grouping,
    key,
    setDates,
    setGrouping,
    setKey,
    setSubKey,
    subKey,
    useNetworkGroup,
    setUseNetworkGroup,
  };
};

interface StreamingPacingProps {
  platform: string;
}

const StreamingPacing: React.FC<StreamingPacingProps> = ({ platform: pagePlatform }) => {
  const setError = useSetError();
  const [platform, setPlatform] = useState<string>(
    pagePlatform === "Display" ? pagePlatform : "Streaming_Audio"
  );
  const {
    data,
    dates,
    key: selectedLeft,
    subKey: selectedRight,
    grouping,
    setDates,
    setGrouping,
    setKey,
    setSubKey,
    useNetworkGroup,
    setUseNetworkGroup,
  } = usePacingInfo(platform, setError);
  const groupByCompany = grouping === BY_COMPANY_KEY;
  const { start, end } = dates;
  const [dimension, setDimension] = useState(
    pagePlatform === "Display" ? SPEND_LABEL : IMPRESSIONS_LABEL
  );
  const [simplicity, setSimplicity] = useState(ADJUSTED_LABEL);
  const [leftFilterTokens, setLeftFilterTokens] = useState({});
  const [leftFilterAdvanced, setLeftFilterAdvanced] = useState(false);
  const [rightFilterTokens, setRightFilterTokens] = useState({});
  const [rightFilterAdvanced, setRightFilterAdvanced] = useState(false);
  let dimensionKey;
  let numberPrefix = "";
  switch (dimension) {
    case SPEND_LABEL:
      dimensionKey = "spend";
      numberPrefix = "$";
      break;
    default:
      dimensionKey = "impressions";
      break;
  }

  let simplicityKey = simplicity === SIMPLE_LABEL ? "simple" : "adjusted";

  let rangeCount = useMemo(
    () => Dfns.differenceInCalendarDays(Dfns.parseISO(start), Dfns.parseISO(end)) + 1,
    [start, end]
  );

  let [filters, setFilters] = useState<Record<string, (line: any) => boolean>>({
    left: (line: any) => true,
    right: (line: any) => true,
  });

  const memoizedFormatPacingNumber = useCallback(
    number => formatPacingNumber(number, numberPrefix),
    [numberPrefix]
  );

  const PacingBarOverlay = useCallback(
    ({ booked, cleared, projection }) => {
      return (
        <div>
          Delivered: {formatPacingNumber(cleared[simplicityKey][dimensionKey], numberPrefix)} <br />{" "}
          Pacing: {formatPacingNumber(projection[simplicityKey][dimensionKey], numberPrefix)} <br />{" "}
          Booked: {formatPacingNumber(booked[simplicityKey][dimensionKey], numberPrefix)} <br /> Δ:{" "}
          {formatPacingNumber(
            booked[simplicityKey][dimensionKey] - projection[simplicityKey][dimensionKey],
            numberPrefix
          )}
        </div>
      );
    },
    [numberPrefix, simplicityKey, dimensionKey]
  );

  const [showHelpModal, setShowHelpModal] = useState(false);

  const { leftMax, leftData } = useMemo(() => {
    if (!data) {
      return {};
    }
    let leftMax = 0;

    const leftData = R.sortBy(
      ({ booked, projection }) =>
        -Math.abs(booked[simplicityKey][dimensionKey] - projection[simplicityKey][dimensionKey]),
      R.map(item => {
        const { booked: bookedObj, cleared: clearedObj, projection: projectionObj } = item;
        const booked =
          bookedObj && bookedObj[simplicityKey] && bookedObj[simplicityKey][dimensionKey]
            ? bookedObj[simplicityKey][dimensionKey]
            : 0;
        const cleared =
          clearedObj && clearedObj[simplicityKey] && clearedObj[simplicityKey][dimensionKey]
            ? clearedObj[simplicityKey][dimensionKey]
            : 0;
        const projection =
          projectionObj &&
          projectionObj[simplicityKey] &&
          projectionObj[simplicityKey][dimensionKey]
            ? projectionObj[simplicityKey][dimensionKey]
            : 0;
        leftMax = Math.max(leftMax, booked, cleared, projection);
        return item;
      }, R.filter((elem: PacingData) => (selectedLeft ? elem.key === selectedLeft : filters.left(elem)), data) as PacingData[]) as PacingData[]
    );

    return { leftMax, leftData };
  }, [data, dimensionKey, simplicityKey, filters, selectedLeft]);

  const leftFilterBarOptions = useMemo(
    () => (groupByCompany ? ["company"] : ["network", "description", "platform", "audio_buy_type"]),
    [groupByCompany]
  );

  const { rightMax, rightData, rightDataFiltered, rightDataSummary } = useMemo(() => {
    if (!(data && selectedLeft)) {
      return {};
    }
    let rightMax = 0;
    let rightData = R.find(elem => elem.key === selectedLeft, data) as any;
    if (R.isNil(rightData) || R.isNil(rightData.subData)) {
      return { rightData, rightMax };
    }
    // Doing "- Math.abs" is not a typo. Instead of doing an R.desc or R.reverse, this achieves the same
    // result
    const rightDataFiltered = R.sortBy(
      ({ booked, projection }) =>
        -Math.abs(booked[simplicityKey][dimensionKey] - projection[simplicityKey][dimensionKey]),
      R.map(item => {
        const { booked: bookedObj, cleared: clearedObj, projection: projectionObj } = item;
        const booked =
          bookedObj && bookedObj[simplicityKey] && bookedObj[simplicityKey][dimensionKey]
            ? bookedObj[simplicityKey][dimensionKey]
            : 0;
        const cleared =
          clearedObj && clearedObj[simplicityKey] && clearedObj[simplicityKey][dimensionKey]
            ? clearedObj[simplicityKey][dimensionKey]
            : 0;
        const projection =
          projectionObj &&
          projectionObj[simplicityKey] &&
          projectionObj[simplicityKey][dimensionKey]
            ? projectionObj[simplicityKey][dimensionKey]
            : 0;
        rightMax = Math.max(rightMax, booked, cleared, projection);
        return item;
      }, R.filter((elem: PacingSubData) => (selectedRight ? elem.key === selectedRight : filters.right(elem)), rightData.subData) as PacingSubData[]) as PacingSubData[]
    );

    const rightDataSummary = {
      ...{
        booked: {
          simple: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
          adjusted: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
        },
        cleared: {
          simple: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
          adjusted: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
        },
        projection: {
          simple: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
          adjusted: {
            impressions: 0,
            spend: 0,
            netSpend: 0,
          },
        },
      },
      ...(mergeItems(rightDataFiltered) as SummaryData),
      start,
      end,
    };

    return {
      rightData,
      rightMax,
      rightDataFiltered,
      rightDataSummary,
    };
  }, [data, selectedLeft, start, end, simplicityKey, dimensionKey, selectedRight, filters]);

  const rightFilterBarOptions = useMemo(
    () =>
      grouping === BY_COMPANY_KEY
        ? ["network", "description", "platform", "audio_buy_type"]
        : ["company"],
    [grouping]
  );

  const hasRightAlert = useMemo(
    () =>
      R.any(
        elem => !R.isNil(R.path(["alert", simplicityKey, dimensionKey], elem)),
        rightDataFiltered || []
      ),
    [rightDataFiltered, simplicityKey, dimensionKey]
  );

  const { chartData, chartDataBooked } = useMemo(() => {
    if (!(selectedRight && !R.isNil(rightData) && !R.isNil(rightData.subData))) {
      return {};
    }
    const group =
      rightData && rightData.subData
        ? R.find((elem: PacingSubData) => elem.key === selectedRight, rightData.subData)
        : undefined;

    if (R.isNil(group) || R.isNil(group.days)) {
      return {};
    }

    let chartDataBooked: number = group.booked[simplicityKey][dimensionKey];
    let chartData: ChartData[] = [];
    let date = Dfns.parseISO(start);
    let sum = 0;
    let idealSum = 0;
    for (let i = 0; i < rangeCount; ++i) {
      let item = R.path(
        ["days", Dfns.format(DATE_FORMAT, date), simplicityKey],
        group
      ) as SimpleWithIdeal;
      let value = 0;
      let ideal = 0;
      if (item) {
        value = item[dimensionKey];
        ideal = item.ideal[dimensionKey];
        sum += value;
        idealSum += ideal;
      }
      let elem: ChartData = {
        date: Dfns.format("M/d/yyyy", date),
        value,
        ideal,
      };
      if (!Dfns.isAfter(new Date(), date)) {
        elem.sum = sum;
        elem.idealSum = idealSum;
      }
      chartData.push(elem);
      date = Dfns.addDays(1, date);
    }
    return {
      chartDataBooked,
      chartData,
    };
  }, [rightData, selectedRight, dimensionKey, simplicityKey, rangeCount, start]);

  const networkLabel =
    useNetworkGroup === USE_NETWORK_GROUP_KEY ? USE_NETWORK_GROUP_LABEL : USE_NETWORK_LABEL;

  return (
    <Page
      title={`${pagePlatform} Pacing`}
      pageType={`${pagePlatform} Pacing`}
      actions={
        <div className="pacingDashboardActions">
          <div className="legend">
            <div className="circle delivery" />
            <div className="legendLabel">Delivery</div>
            <div className="circle pacing" />
            <div className="legendLabel">Pacing</div>
            <div className="circle booked" />
            <div className="legendLabel">Booked</div>
          </div>
          <MdHelp onClick={() => setShowHelpModal(!showHelpModal)} className="optionsHelp" />
          <ToggleButtonGroup
            className="simplicityButtons"
            type="radio"
            name="simplicityButtons"
            value={simplicity}
            onChange={elem => setSimplicity(elem)}
          >
            {pagePlatform !== "Display" &&
              [SIMPLE_LABEL, ADJUSTED_LABEL].map(label => (
                <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                  {label}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            className="dimensionButtons"
            type="radio"
            name="dimensionButtons"
            value={dimension}
            onChange={elem => setDimension(elem)}
          >
            {[IMPRESSIONS_LABEL, SPEND_LABEL].map(label => (
              <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            className="groupingButtons"
            type="radio"
            name="groupingButtons"
            value={grouping === BY_COMPANY_KEY ? BY_COMPANY_LABEL : networkLabel}
            onChange={newGrouping => {
              setGrouping(newGrouping === BY_COMPANY_LABEL ? BY_COMPANY_KEY : BY_NETWORK_KEY);
              setKey(undefined);
              setSubKey(undefined);
            }}
          >
            {[BY_COMPANY_LABEL, networkLabel].map(label => (
              <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            className="networkGroupGrouping"
            type="radio"
            name="networkGroupGroupingButtons"
            value={networkLabel}
            onChange={newGrouping => {
              setUseNetworkGroup(
                newGrouping === USE_NETWORK_LABEL ? USE_NETWORK_KEY : USE_NETWORK_GROUP_KEY
              );
            }}
          >
            {[USE_NETWORK_LABEL, USE_NETWORK_GROUP_LABEL].map(label => (
              <ToggleButton key={label} variant="primary" className="noOutline" value={label}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {pagePlatform === "Streaming" &&
            !(
              (grouping === BY_COMPANY_KEY && selectedRight) ||
              (grouping === BY_NETWORK_KEY && selectedLeft)
            ) && (
              <OldDropdown
                label={"Media Type"}
                value={platform}
                options={[
                  {
                    label: "Both",
                    value: "Streaming_Audio",
                  },
                  {
                    label: "Streaming",
                    value: "Streaming",
                  },
                  {
                    label: "Audio",
                    value: "Audio",
                  },
                ]}
                onChange={e => setPlatform(e)}
              />
            )}
          <DateRangePicker
            startDate={start}
            endDate={end}
            startDateId="pacingStartDate"
            endDateId="pacingEndDate"
            onChange={({ startDate, endDate }) => {
              setDates({
                start: startDate,
                end: endDate,
              });
            }}
          />
        </div>
      }
    >
      <div className="pacingDashboard">
        {data ? (
          <div className="chartPanes">
            <div className={`leftPane${selectedLeft ? " condensed" : ""}`}>
              <PacingPane
                simplicityKey={simplicityKey}
                items={leftData}
                selectedKey={selectedLeft}
                rowClassName={selectedLeft && "condensedRow"}
                onSelect={key => {
                  setSubKey(undefined);
                  setKey(key);
                }}
                filterRenderer={
                  !selectedLeft
                    ? () => (
                        <OldFilterBar
                          options={leftFilterBarOptions}
                          lines={R.pipe(R.project(leftFilterBarOptions), R.uniq)(data ? data : [])}
                          onFilter={filter => {
                            setFilters({
                              ...filters,
                              left: filter,
                            });
                          }}
                          /// @ts-ignore
                          tokens={leftFilterTokens}
                          onChange={leftFilterTokens => setLeftFilterTokens(leftFilterTokens)}
                          advanced={leftFilterAdvanced}
                          onSetAdvanced={advanced => setLeftFilterAdvanced(advanced)}
                        />
                      )
                    : undefined
                }
                overlayRenderer={selectedLeft ? undefined : PacingBarOverlay}
                labelRenderer={groupByCompany ? makeCompanyLabel : makeNetworkLabel}
                lineItemRenderer={
                  selectedLeft
                    ? undefined
                    : ({ key, cleared, projection, booked, alert }) => {
                        let parts: FilterBarPart[] = [
                          // type, label, color, val, leftVal = 0
                          {
                            type: PacingBarTypes.HORIZONTAL,
                            label: formatPacingNumber(
                              projection[simplicityKey][dimensionKey],
                              numberPrefix
                            ),
                            className: "pacing",
                            val: projection[simplicityKey][dimensionKey],
                            leftVal: cleared[simplicityKey][dimensionKey],
                          },
                          {
                            type: PacingBarTypes.HORIZONTAL,
                            label: formatPacingNumber(
                              cleared[simplicityKey][dimensionKey],
                              numberPrefix
                            ),
                            className: "delivery",
                            val: cleared[simplicityKey][dimensionKey],
                          },
                          {
                            type: PacingBarTypes.VERTICAL,
                            className: "booked",
                            val: booked[simplicityKey][dimensionKey],
                          },
                          {
                            type: PacingBarTypes.LABEL,
                            label: `${
                              booked[simplicityKey][dimensionKey]
                                ? Math.round(
                                    (projection[simplicityKey][dimensionKey] /
                                      booked[simplicityKey][dimensionKey]) *
                                      100
                                  )
                                : 100
                            }%`,
                            leftVal: projection[simplicityKey][dimensionKey],
                          },
                        ];
                        if (R.path([simplicityKey, dimensionKey], alert)) {
                          parts.push({
                            type: PacingBarTypes.ALERT,
                            className: "warningIcon",
                            label: <MdWarning />,
                          });
                        }
                        return <PacingBar key={key} max={leftMax} parts={parts} />;
                      }
                }
              />
            </div>
            {selectedLeft &&
              (R.isNil(rightData) || R.isNil(rightData.subData) ? (
                <div className="rightPane">
                  <div className="topPane">
                    <Spinner size={100} />
                  </div>
                </div>
              ) : (
                <div className="rightPane">
                  <div className={`topPane${selectedRight ? " condensed" : ""}`}>
                    <PacingPane
                      simplicityKey={simplicityKey}
                      items={rightDataFiltered}
                      selectedKey={selectedRight}
                      hasAlert={hasRightAlert}
                      onSelect={subKey => {
                        setSubKey(subKey);
                      }}
                      summaryData={selectedRight ? undefined : rightDataSummary}
                      filterRenderer={
                        selectedRight
                          ? undefined
                          : () => (
                              <div style={{ width: "100%", display: "flex" }}>
                                <OldFilterBar
                                  options={rightFilterBarOptions}
                                  lines={R.uniq(
                                    R.project(
                                      rightFilterBarOptions,
                                      rightData && rightData.subData ? rightData.subData : []
                                    )
                                  )}
                                  onFilter={filter => {
                                    setFilters({
                                      ...filters,
                                      right: filter,
                                    });
                                  }}
                                  /// @ts-ignore
                                  tokens={rightFilterTokens}
                                  onChange={rightFilterTokens =>
                                    setRightFilterTokens(rightFilterTokens)
                                  }
                                  advanced={rightFilterAdvanced}
                                  onSetAdvanced={advanced => setRightFilterAdvanced(advanced)}
                                />
                                <Button
                                  variant="outline-secondary"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    const csvRows = R.map(
                                      row => [
                                        row.network,
                                        row.platform,
                                        row.projection[simplicityKey][dimensionKey] /
                                          row.booked[simplicityKey][dimensionKey],
                                      ],
                                      R.defaultTo([], rightDataFiltered) as PacingSubData[]
                                    );
                                    csvRows.unshift(["Network", "Platform", "Clearance Rate"]);
                                    const csv = Papa.unparse(csvRows);
                                    download(
                                      csv,
                                      `${selectedLeft}_clearance_rates_${simplicityKey}_${dimensionKey}_${start}-${end}.csv`,
                                      "text/plain"
                                    );
                                  }}
                                >
                                  <MdSave />
                                </Button>
                              </div>
                            )
                      }
                      labelRenderer={groupByCompany ? makeNetworkLabel : makeCompanyLabel}
                      overlayRenderer={PacingBarOverlay}
                      lineItemRenderer={({
                        key,
                        cleared,
                        projection,
                        booked,
                        alert,
                        independent = false,
                      }) => {
                        const path = [simplicityKey, dimensionKey];
                        const ourProjection: number = R.defaultTo(0, R.path(path, projection));
                        const ourCleared: number = R.defaultTo(0, R.path(path, cleared));
                        const ourBooked: number = R.defaultTo(0, R.path(path, booked));
                        let parts: FilterBarPart[] = [
                          {
                            type: PacingBarTypes.HORIZONTAL,
                            label: formatPacingNumber(ourProjection, numberPrefix),
                            className: "pacing",
                            val: ourProjection,
                            leftVal: ourCleared,
                          },
                          {
                            type: PacingBarTypes.HORIZONTAL,
                            label: formatPacingNumber(ourCleared, numberPrefix),
                            className: "delivery",
                            val: ourCleared,
                          },
                          {
                            type: PacingBarTypes.VERTICAL,
                            className: "booked",
                            val: ourBooked,
                          },
                          {
                            type: PacingBarTypes.LABEL,
                            label: `${
                              ourBooked ? Math.round((ourProjection / ourBooked) * 100) : 100
                            }%`,
                            leftVal: Math.max(ourProjection, ourCleared),
                          },
                        ];
                        if (R.path([simplicityKey, dimensionKey], alert)) {
                          parts.push({
                            type: PacingBarTypes.ALERT,
                            className: "warningIcon",
                            label: <MdWarning />,
                          });
                        }
                        let max = rightMax;
                        if (independent) {
                          max = Math.max(ourProjection, ourCleared, ourBooked);
                        }
                        return <PacingBar key={key} max={max} parts={parts} />;
                      }}
                    />
                  </div>
                  {selectedRight &&
                    (chartData ? (
                      <div className="bottomPane">
                        <AutoSizer>
                          {({ width, height }) => (
                            <ComposedChart
                              data={chartData}
                              width={width}
                              height={height}
                              // Because recharts is dumb and full of bugs, resizing will make the x axis disappear.
                              // So I need to force a full rerender on resize.
                              key={`${width}_${height}`}
                            >
                              <CartesianGrid />
                              <XAxis
                                dataKey="date"
                                padding={{
                                  left: 50,
                                  right: 50,
                                }}
                              />
                              <YAxis
                                type="number"
                                domain={chartDataBooked ? [0, chartDataBooked] : undefined}
                                tickFormatter={memoizedFormatPacingNumber}
                              />
                              <Bar
                                name="Actual Daily Delivery"
                                dataKey="value"
                                barSize={20}
                                fill="#0074D9"
                              />
                              <Line
                                name="Ideal Daily Delivery"
                                strokeDasharray="5 5"
                                dataKey="ideal"
                                stroke="#001F3F"
                                strokeWidth={2}
                                dot={{ strokeDasharray: "0" }}
                              />
                              <Line
                                name="Actual Delivery"
                                type="monotoneX"
                                dataKey="sum"
                                stroke="#FF4136"
                                strokeWidth={2}
                              />
                              <Line
                                name="Ideal Delivery"
                                dataKey="idealSum"
                                stroke="#85144B"
                                strokeDasharray="5 5"
                                strokeWidth={2}
                                dot={{ strokeDasharray: "0" }}
                              />
                              <RechartsTooltip formatter={memoizedFormatPacingNumber} />
                              <Legend />
                            </ComposedChart>
                          )}
                        </AutoSizer>
                      </div>
                    ) : (
                      <div className="bottomPane loadingPane">
                        <Spinner size={100} />
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ) : (
          <FullPageSpinner />
        )}
        {showHelpModal && (
          <Modal show size="lg" onHide={() => setShowHelpModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>What Do These Options Mean?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                In general, "Simple" will figure out what the daily values for a flight should be,
                and compute booked and projected values from that. "Adjusted" will do various things
                such as taking overdelivery into account and focus more on what's left than what's
                already ran.
              </p>
              <h2>Booked</h2>
              <p>
                In "Simple" mode, the amount booked will be the the total booked for the flight,
                divided by the number of days in the flight, times the number of days in the date
                range. If the flight is from Monday through Sunday, and the date range is from
                Monday through Sunday, the booked amount will be exactly what's on the IO. If the
                flight is two weeks long, and the date range is only one week long, the amount
                booked will be half of what's on the IO. If the flight ends before the date range
                ends, it will not multiply by the full date range but only up to when the flight
                ends, so if the date range is two weeks, and the flight is one week, the booked will
                be the total booked on the IO.
              </p>
              <p>
                In "Adjusted" mode, it will first deduct any impressions served for that flight
                before the selected date range. It will then divide the remaining booked impressions
                by the number of days remaining in the flight, then multiply by the length of the
                date range. If the flight is contained entirely within the date range, this number
                should be the same as the simple version.
              </p>
              <p>
                Suppose there was a flight that was 10 days long and had a spend of $1,000. For 5
                days it paced perfectly ($100 a day) and delivered a total of $500. Then we double
                the spend, making it $2,000. If our date range is the full ten days, both the Simple
                and Adjusted booked numbers will be $2,000. However, suppose the date range only
                covered the final 5 days. In Simple mode it would be the full flight spend ($2,000)
                divided by the number of days in the flight (10), times the number of days in our
                range (5), which would be $1,000 (or half the total spend). In Adjusted mode, it
                would be the full flight spend ($2,000) minus what was delivered outside of the date
                range ($500, for a remaining total of $1,500), divided by the remaining days in the
                flight (5), times the days in our date range (5), for a total of $1,500.
              </p>
              <h2>Projected</h2>
              <p>
                In "Simple" mode, we take the number of impressions served in the date range, divide
                by the number of days so far, then multiplied by the full date range. In "Adjusted"
                mode, we take that number, but cut it off at the booked amount, assuming that once
                the network hits the IO amount, they'll stop delivering.
              </p>
              <h2>Actual Delivery</h2>
              <p>
                The "Simple" and "Adjusted" delivered impressions will always be the same, because
                these are the number of impressions we got. The "Simple" spend will be that numbers
                times the cpm divided by 1000. The "Adjusted" spend will be that number, but with
                overdelivery taken into account.
              </p>
              <h2>Ideal Delivery</h2>
              <p>
                "Ideal Delivery" refers to the "Ideal Daily Delivery" and "Ideal Delivery" lines in
                the charts that appear when selecting an item in the right pane. In "Simple" mode,
                the ideal daily delivery will be the total spend of the flight divided by the number
                of days in the flight. The Ideal total delivery will be that number times the number
                of days. In other words, the ideal daily delivery line will be flat, and the ideal
                delivery line will be a straight increasing line. In "Adjusted" mode, instead of
                looking at what the pacing should be for the whole flight, it looks at what the
                pacing should be based on the remaining impresisons. For each day it takes the
                number of remaining impressions, then divides by the number of remaining days. Thus,
                if a flight is under-delivering, the ideal line will get higher, and if it's
                over-delivering, it will get lower.
              </p>
              <p>
                Consider the above example with the 10 day flight at $1,000 that doubles in spend
                half way through. Looking at the last five days, "Simple" mode will compute the
                ideal daily spend as the total spend ($2,000) divided by the total days in the
                flight (10), to get the ideal daily spend ($200). So it will show a horizontal line
                at $200 and a straight line that starts at $0 with a slope of $200 per day. In
                "Adjusted" mode, we'll take the full booked amount ($2,000), subtract the already
                delivered impressions ($500, for a new total of $1,500), then divide that by the
                total number of remaining days (5) to get a ideal daily spend of $300. If we were to
                look at it for the entire 10 day range, on day one the ideal daily spend will be
                $200. After the first day (where we deliver $100) the remaining spend will be
                $1,900. With 9 days left the ideal daily spend will be $211. After we deliver
                another $100 the total will be $1,800, and with 8 days left that makes the ideal
                daily spend $225. Every day the ideal spend increases because we're under-delivering
                when looking at the entire range. Once we get to day 5, the ideal daily spend will
                be $300. If they deliver $300 a day then the ideal will stay at $300 a day. If on
                the first day they deliver $400, then the new total will be $2,000 - $500 - $400 =
                $1,100, which divided by the remaining days (4) is $275.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowHelpModal(false)}>
                Got It
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </Page>
  );
};

interface PacingPaneProps {
  filterRenderer?: () => JSX.Element;
  hasAlert?: boolean;
  items?: PacingData[] | PacingSubData[];
  labelRenderer: (args: any) => JSX.Element;
  lineItemRenderer?: (args: any) => JSX.Element;
  onSelect: (selection: string) => void;
  overlayRenderer?: (arg: PacingData | PacingSubData) => JSX.Element;
  rowClassName?: string;
  selectedKey?: string;
  simplicityKey?: string;
  summaryData?: SummaryData;
}

export const PacingPane: React.FC<PacingPaneProps> = ({
  filterRenderer,
  hasAlert = false,
  items = [],
  labelRenderer,
  lineItemRenderer,
  onSelect,
  overlayRenderer,
  rowClassName,
  selectedKey,
  simplicityKey,
  summaryData,
}) => {
  const {
    averageDailySpend,
    totalSpendRequiredToHitBudget,
    dailySpendRequiredToHitBudget,
  } = useMemo(() => {
    if (
      simplicityKey &&
      summaryData &&
      summaryData.start === Dfns.format(DATE_FORMAT, Dfns.startOfMonth(new Date())) &&
      summaryData.end === Dfns.format(DATE_FORMAT, Dfns.endOfMonth(new Date())) &&
      new Date().getDate() > 1
    ) {
      const today = new Date().getDate();
      const endOfMonth = Dfns.endOfMonth(new Date()).getDate();
      return {
        averageDailySpend: summaryData.cleared[simplicityKey].spend / (today - 1),
        totalSpendRequiredToHitBudget:
          summaryData.booked[simplicityKey].spend - summaryData.cleared[simplicityKey].spend,
        dailySpendRequiredToHitBudget:
          endOfMonth - today > 0
            ? (summaryData.booked[simplicityKey].spend - summaryData.cleared[simplicityKey].spend) /
              (endOfMonth - (today - 1))
            : undefined,
      };
    }
    return {};
  }, [summaryData, simplicityKey]);

  const contentRenderer = useCallback(
    (key, item) => {
      let content = lineItemRenderer && (
        <div className={`lineItemContent${hasAlert ? " withAlert" : ""}`}>
          {lineItemRenderer(item)}
        </div>
      );
      if (overlayRenderer && content) {
        let contentKey = `${key}_content`;
        content = (
          <OverlayTrigger
            key={contentKey}
            placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
            overlay={<Tooltip id={uuid.v4()}>{overlayRenderer(item)}</Tooltip>}
          >
            {content}
          </OverlayTrigger>
        );
      }
      return content;
    },
    [lineItemRenderer, hasAlert, overlayRenderer]
  );
  return (
    <div className="pacingPane">
      {averageDailySpend && totalSpendRequiredToHitBudget && dailySpendRequiredToHitBudget && (
        <div className="summaryHeader">
          <div>Average Daily Spend: {formatPacingNumber(averageDailySpend, "$")}</div>
          <div>
            Total Spend Required To Hit Budget:{" "}
            {formatPacingNumber(totalSpendRequiredToHitBudget, "$")}
          </div>
          <div>
            Daily Spend Required To Hit Budget:{" "}
            {formatPacingNumber(dailySpendRequiredToHitBudget, "$")}
          </div>
        </div>
      )}
      {summaryData && (
        <div className="summaryLine">
          {contentRenderer("summary", { ...summaryData, independent: true })}
        </div>
      )}
      {filterRenderer && <div className="controls">{filterRenderer()}</div>}
      <div className="pacingLines">
        {R.map(item => {
          let { key } = item;
          let classes = ["pacingRow"];

          if (rowClassName) {
            classes.push(rowClassName);
          }
          let content = contentRenderer(key, item);
          let labelContent;
          if (labelRenderer) {
            let labelKey = `${key}_label`;
            let label = key;

            labelContent = (
              <OverlayTrigger
                key={labelKey}
                placement={OverlayTrigger.PLACEMENTS.TOP.LEFT}
                overlay={<Tooltip id={uuid.v4()}>{label}</Tooltip>}
              >
                <div className="pacingRowLabel">{labelRenderer(item)}</div>
              </OverlayTrigger>
            );
          }
          let isSelected = selectedKey === key;
          return (
            <div
              key={key}
              className={classes.join(" ")}
              onClick={() => onSelect(isSelected ? undefined : key)}
            >
              {isSelected && <div className="selectedBorder" />}
              <div className="rowContents">
                {labelContent}
                {content}
              </div>
            </div>
          );
        }, R.defaultTo([], items) as any[])}
      </div>
    </div>
  );
};

const makeNetworkLabel = ({ network, platform, description }) => {
  let label = (platform || "").replace("Streaming ", "").replace("Display ", "");
  if (description && network !== description) {
    label = `${description} ${label}`;
  }
  return (
    <div className="pacingBarLabel pacingNetworkBarLabel">
      <div className="logo network">
        <Img
          src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
          title={network}
          unloader={<span className="noImg">{network}</span>}
        />
      </div>

      <span className="subLabel">{label}</span>
    </div>
  );
};

export const makeCompanyLabel = ({ company }: { company: string }): JSX.Element => (
  <div className="pacingBarLabel pacingCompanyBarLabel">
    <div className="logo company">
      <Img
        src={`https://cdn.blisspointmedia.com/companies/${company}/logo.png`}
        title={company}
        unloader={<span className="noImg">{company}</span>}
      />
    </div>
  </div>
);

interface PacingBarProps {
  max?: number;
  parts: FilterBarPart[];
}

const VERTICAL_BAR_WIDTH = 6;
export const PacingBar: React.FC<PacingBarProps> = ({ max = 1, parts }) => {
  let ratio = 100 / max;
  return (
    <div className="pacingItemBar">
      {parts.map(({ type, label = "", className, val = 0, leftVal = 0 }) => {
        let key = [type, label, className, val].join("_");
        let classes: string[] = [];
        if (className) {
          classes.push(className);
        }
        let style = {};
        switch (type) {
          case PacingBarTypes.HORIZONTAL:
            classes.push("horizontal");
            style = {
              width: `${val * ratio}%`,
              paddingLeft: `${leftVal * ratio}%`,
            };
            break;
          case PacingBarTypes.VERTICAL:
            classes.push("vertical");
            style = {
              width: VERTICAL_BAR_WIDTH,
              left: `calc(${val * ratio}% - ${VERTICAL_BAR_WIDTH / 2}px)`,
            };
            break;
          case PacingBarTypes.LABEL:
            classes.push("pacingBarLabel");
            style = {
              left: `${leftVal * ratio}%`,
            };
            break;
          case PacingBarTypes.ALERT:
            classes.push("pacingBarAlert");
            break;
          default:
            return undefined;
        }
        return (
          <div key={key} className={classes.join(" ")} style={style}>
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default StreamingPacing;
