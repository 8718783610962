import "./MetricsTable.scss";
import {
  CellData,
  ColumnMetaDataMap,
  constructTableData,
  DEFAULT_GET_DIMENSION_CELL,
  DimensionMap,
} from "./metricsTableUtils";
import {
  Column,
  DimensionColumn,
  PerformanceData,
  MetricsTablePreset as Preset,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { StateSetter } from "../../utils/types";
import * as R from "ramda";
import MetricsTable from "./MetricsTable";
import React, { useMemo } from "react";

interface MetricsTableWithPresetsProps {
  columnMetaDataMap?: ColumnMetaDataMap;
  data: PerformanceData[];
  dataFetchKey?: (...args) => string;
  exportedData?: (string | number)[][];
  filter?: any;
  hasAggregateData?: boolean;
  height?: number;
  getDimensionCell?: (
    dimensionData: DimensionMap,
    dimensionHeader: DimensionColumn,
    ...args
  ) => CellData;
  percentAOIPlaceholder?: number;
  preset: Preset;
  role?: number; // We don't want to use to redux here because we cannot use it in storybook testing
  setExportedData?: StateSetter<(string | number)[][]>;
  variant?: "Metrics" | "Widget" | "SparkChart";
  width?: number;
}

const DEFAULT_HEIGHT = 500;
const DEFAULT_WIDTH = 1204;

export const MetricsTableWithPresets = React.memo<MetricsTableWithPresetsProps>(
  ({
    columnMetaDataMap = {},
    data,
    dataFetchKey = () => "",
    exportedData,
    filter,
    getDimensionCell = DEFAULT_GET_DIMENSION_CELL,
    hasAggregateData = true,
    height = DEFAULT_HEIGHT,
    percentAOIPlaceholder,
    preset = { dimensionColumns: [], dataColumns: [] } as Partial<Preset>,
    role = 10,
    setExportedData = ((exportedData: (string | number)[][]) => {}) as StateSetter<(string | number)[][]>,
    variant = "Metrics",
    width = DEFAULT_WIDTH,
  }) => {
    const dataHeaders: Column[] = useMemo(
      () =>
        R.filter(
          col => (col && col.role ? col.role >= role : true),
          preset && preset.dataColumns ? preset.dataColumns : []
        ),
      [preset, role]
    );

    const dimensionDataHeaders: DimensionColumn[] = useMemo(
      () =>
        R.filter(
          col => (col && col.role ? col.role >= role : true),
          preset && preset.dimensionColumns ? preset.dimensionColumns : []
        ),
      [preset, role]
    );

    const {
      aggregateData,
      dimensionData,
      tableData,
    }: {
      aggregateData: CellData[];
      dimensionData: Record<string, CellData>[];
      tableData: CellData[][];
    } = useMemo(() => {
      const { aggregateData, dimensionData, tableData } = constructTableData(
        columnMetaDataMap,
        dataHeaders,
        dimensionDataHeaders,
        getDimensionCell,
        dataFetchKey,
        data
      );
      const filteredDimensionData: Record<string, CellData>[] = [];
      const filteredTableData: CellData[][] = [];
      if (filter && dimensionData) {
        for (let rowIndex = 0; rowIndex < dimensionData.length; rowIndex++) {
          const row = dimensionData[rowIndex];
          const dataToBeFiltered = {};
          for (const key of R.keys(row)) {
            dataToBeFiltered[key] =
              row[key].url && row[key].overlayText ? row[key].overlayText : row[key].label;
          }
          if (filter(dataToBeFiltered)) {
            filteredDimensionData.push(dimensionData[rowIndex]);
            filteredTableData.push(tableData[rowIndex]);
          }
        }
        return {
          aggregateData,
          dimensionData: filteredDimensionData,
          tableData: filteredTableData,
        };
      }
      return {
        aggregateData,
        dimensionData,
        tableData,
      };
    }, [
      columnMetaDataMap,
      data,
      dataFetchKey,
      dataHeaders,
      dimensionDataHeaders,
      filter,
      getDimensionCell,
    ]);

    return (
      <MetricsTable
        aggregateData={hasAggregateData ? aggregateData : []}
        columnMetaDataMap={columnMetaDataMap}
        dataColumns={dataHeaders}
        defaultSorting={preset.defaultSorting}
        dimensionColumns={dimensionDataHeaders}
        dimensionData={dimensionData}
        exportedData={exportedData}
        height={height}
        percentAOIPlaceholder={percentAOIPlaceholder}
        role={role}
        setExportedData={setExportedData}
        superHeaders={preset.superHeaders}
        tableData={tableData}
        variant={variant}
        width={width}
      />
    );
  }
);

export default MetricsTableWithPresets;
