import React from "react";

import * as R from "ramda";

import { ButtonGroup } from "react-bootstrap";

import { BPMButton } from ".";

import "./BPMToggleButton.scss";

interface ToggleCheckboxesProps<T> {
  options: {
    key: T;
    label?: string;
  }[];
  selectedOptions: T[];
  onChange: (key: T[]) => void;
  size?: "sm" | "lg";
  className?: string;
  block?: boolean;
  noWrap?: boolean;
}

export const ToggleCheckboxes: <T extends string>(
  props: ToggleCheckboxesProps<T>
) => JSX.Element = ({
  size,
  className = "",
  options,
  selectedOptions,
  onChange,
  block = false,
  noWrap = false,
}) => {
  let classes = ["bpmToggleButtons", "bpmToggleCheckboxes"];
  if (block) {
    classes.push("block");
  }
  if (noWrap) {
    classes.push("noWrap");
  }
  if (className) {
    classes.push(className);
  }
  return (
    <ButtonGroup toggle size={size} className={classes.join(" ")}>
      {options.map(({ key, label }) => {
        let active = selectedOptions.includes(key);
        return (
          <BPMButton
            key={key}
            active={active}
            variant={active ? "primary" : "outline-primary"}
            onClick={() => {
              if (active) {
                onChange(R.without([key], selectedOptions));
              } else {
                onChange([...selectedOptions, key]);
              }
            }}
          >
            {label || key}
          </BPMButton>
        );
      })}
    </ButtonGroup>
  );
};
