import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as uuid from "uuid";
import * as UserRedux from "../../redux/user";
import InputTable, { getTablesContainerInnerWidth } from "./InputTable";
import {
  EntryEditsMap,
  NewTableEntriesMap,
  TableEditsMap,
  prepDeleteTableReq,
  validateAndUpdateTables as validateAndUpdateTablesInternal,
  QueuedRequest,
  prepCreateTableReq,
} from "./OfflineInputsUtils";
import {
  OfflineInputTabs,
  MediaEntry,
  MediaTable,
  OfflineInputsTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { useSelector } from "react-redux";
import AddMediaInputModal from "./AddMediaInputModal";
import {
  CommitSuccessDialog,
  DiscardConfirmationDialog,
  InstructionsDialog,
  ValidationErrorDialog,
} from "./OfflineInputsDialogs";
import { SetError } from "../../redux/modals";
import InputContainer from "./InputContainer";

interface MediaInputsProps {
  inputType: keyof typeof OfflineInputTabs;
  originalTables: MediaTable[];
  updated: { by?: string; at?: string };
  fetching: boolean;
  refetch: () => void;
  editingUnlocked: boolean;
  setEditingUnlocked: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchStateReset: boolean;
  setDispatchStateReset: React.Dispatch<React.SetStateAction<boolean>>;
  company: string;
  setError: SetError;
}

const MediaInputs: React.FC<MediaInputsProps> = ({
  inputType,
  originalTables,
  updated,
  fetching,
  refetch,
  editingUnlocked,
  setEditingUnlocked,
  dispatchStateReset,
  setDispatchStateReset,
  company,
  setError,
}) => {
  const [tables, setTables] = useState<MediaTable[]>([...originalTables]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tablesInEditMode, setTablesInEditMode] = useState<string[]>([]);
  const [newTableEntries, setNewTableEntries] = useState<NewTableEntriesMap>({});
  const [validationErrors, setValidationErrors] = useState<
    { tableId: string; errorDates: string[] }[]
  >([]);
  const [queuedRequests, setQueuedRequests] = useState<QueuedRequest[]>([]);
  const [deletedTables, setDeletedTables] = useState<MediaTable[]>([]);
  const [showAddMediaInputModal, setShowAddMediaInputModal] = useState(false);
  const [showCommitSuccessDialog, setShowCommitSuccessDialog] = useState(false);
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [tableEdits, setTableEdits] = useState<TableEditsMap>({});
  const [filter, setFilter] = useState<{ filter: (line: Record<string, any>) => boolean }>();
  const userFullName: string = useSelector(UserRedux.fullNameSelector) ?? "";

  const scrollToRow = (tableId, date) => {
    const idToFind = `${tableId}_${date.split("T")[0]}`;
    const foundEl = document.getElementById(idToFind);

    if (foundEl) {
      foundEl.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setTimeout(scrollToRow, 200, tableId, date);
    }
  };

  const startEditingTable = (tableId: string, blanks: MediaEntry[]) => {
    setTablesInEditMode([...tablesInEditMode, tableId]);
    setTableEdits({ ...tableEdits, [tableId]: {} });
    newTableEntries[tableId] = blanks;
    setNewTableEntries({ ...newTableEntries });

    if (blanks.length > 0) {
      scrollToRow(tableId, blanks[blanks.length - 1].date);
    }
  };

  const resetState = useCallback(() => {
    setTables([...originalTables]);
    setSubmitting(false);
    setTablesInEditMode([]);
    setNewTableEntries({});
    setValidationErrors([]);
    setQueuedRequests([]);
    setDeletedTables([]);
    setShowAddMediaInputModal(false);
    setShowCommitSuccessDialog(false);
    setShowInstructionsDialog(false);
    setShowDiscardDialog(false);
    setTableEdits({});
  }, [originalTables]);

  useEffect(() => {
    if (dispatchStateReset) {
      resetState();
      setDispatchStateReset(false);
    }
  }, [dispatchStateReset, resetState, setDispatchStateReset]);

  useEffect(() => {
    setTables([...originalTables]);
  }, [originalTables]);

  const onCommitSuccess = () => {
    setShowCommitSuccessDialog(true);
    refetch();
    resetState();
  };

  const validateAndUpdateTables = async () => {
    setSubmitting(true);
    await validateAndUpdateTablesInternal(
      company,
      userFullName,
      inputType,
      tableEdits,
      newTableEntries,
      setValidationErrors,
      setError,
      onCommitSuccess,
      queuedRequests,
      setQueuedRequests
    );
    setSubmitting(false);
  };

  const deleteTable = (tableId: string) => {
    prepDeleteTableReq(
      tableId,
      company,
      userFullName,
      inputType,
      tables,
      setTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
      deletedTables,
      setDeletedTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
      queuedRequests,
      setQueuedRequests,
      newTableEntries,
      setNewTableEntries,
      tableEdits,
      setTableEdits
    );
  };

  const addModal = useMemo(() => {
    const prepCreateMediaTable = (input: {
      platform: string;
      tactic: string;
      channel: string;
      brand: "Brand" | "Non-Brand" | "N/A";
      hasImpressions: boolean;
      hasRedemptions: boolean;
      isPaid: boolean;
      otherUnitLabel?: string;
      startDate: Date;
    }) => {
      const {
        platform,
        tactic,
        channel,
        brand,
        hasImpressions,
        hasRedemptions,
        isPaid,
        otherUnitLabel,
        startDate,
      } = input;

      const newTable: MediaTable = {
        platform,
        tactic,
        channel,
        brand,
        hasImpressions,
        hasRedemptions,
        isPaid,
        otherUnitLabel,
        tableId: uuid.v4(),
        entries: [],
      };

      prepCreateTableReq({
        company,
        updatedBy: userFullName,
        inputType,
        startDate,
        newTable,
        queuedRequests,
        setQueuedRequests,
        tables,
        setTables: setTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
        tablesInEditMode,
        setTablesInEditMode,
        newTableEntries,
        setNewTableEntries,
        tableEdits,
        setTableEdits,
      });
    };

    return (
      <AddMediaInputModal
        show={showAddMediaInputModal}
        handleClose={() => setShowAddMediaInputModal(false)}
        createTable={input => {
          prepCreateMediaTable(input);
          setEditingUnlocked(true);
        }}
        isPaid={inputType === OfflineInputTabs.PAID_MEDIA_INPUTS}
      />
    );
  }, [
    company,
    inputType,
    newTableEntries,
    queuedRequests,
    setEditingUnlocked,
    showAddMediaInputModal,
    tableEdits,
    tables,
    tablesInEditMode,
    userFullName,
  ]);

  return (
    <InputContainer
      dialogs={
        <>
          {addModal}
          <CommitSuccessDialog
            show={showCommitSuccessDialog}
            onHide={() => setShowCommitSuccessDialog(false)}
          />
          <InstructionsDialog
            show={showInstructionsDialog}
            onHide={() => {
              setShowInstructionsDialog(false);
            }}
          />
          <DiscardConfirmationDialog
            show={showDiscardDialog}
            onDiscard={() => resetState()}
            onHide={() => setShowDiscardDialog(false)}
          />
          {validationErrors.length > 0 && (
            <ValidationErrorDialog
              errorDates={validationErrors[0].errorDates}
              onResolve={() => {
                validationErrors.shift();
                setValidationErrors([...validationErrors]);

                if (validationErrors.length === 0) {
                  validateAndUpdateTables();
                }
              }}
              onReject={() => {
                setValidationErrors([]);
                setShowInstructionsDialog(true);
                scrollToRow(validationErrors[0].tableId, validationErrors[0].errorDates[0]);
              }}
              table={tables.find(table => table.tableId === validationErrors[0].tableId)}
              tableEdits={tableEdits[validationErrors[0].tableId]}
              getInputDescription={table => {
                const mediaTable = table as MediaTable;
                return `${mediaTable.channel}
                  ${mediaTable.platform ? `, ${mediaTable.platform}` : ""}
                  ${mediaTable.tactic ? `, ${mediaTable.tactic}` : ""}
                  ${mediaTable.brand && mediaTable.brand !== "N/A" ? `, ${mediaTable.brand}` : ""}`;
              }}
              createEmptyEntry={date => {
                return {
                  date,
                  impressions: undefined,
                  otherUnit: undefined,
                  redemptions: undefined,
                  spend: undefined,
                };
              }}
              normalizeEntry={(table, entry) => {
                const mediaTable = table as MediaTable;
                const mediaEntry = entry as MediaEntry;

                if (mediaTable.hasImpressions) {
                  mediaEntry.impressions = 0;
                }

                if (mediaTable.otherUnitLabel) {
                  mediaEntry.otherUnit = 0;
                }

                if (mediaTable.hasRedemptions) {
                  mediaEntry.redemptions = 0;
                }

                if (mediaTable.isPaid) {
                  mediaEntry.spend = 0;
                }
              }}
            />
          )}
        </>
      }
      tables={
        <div
          className="tablesContainerInner"
          style={getTablesContainerInnerWidth(inputType, tables)}
        >
          {tables?.map(table => {
            const { channel, platform, tactic, brand } = table;

            let tacticBrandLine;
            if (!brand || brand === "N/A") {
              tacticBrandLine = tactic;
            } else if (!tactic) {
              return brand;
            } else {
              tacticBrandLine = `${tactic}, ${brand}`;
            }

            const headerLabels = (
              <div className="headerLabels">
                <div>
                  <strong>{channel}</strong>
                </div>
                <div>{platform}</div>
                {tacticBrandLine && <div>{tacticBrandLine}</div>}
              </div>
            );

            return (
              <InputTable
                inputType={inputType}
                table={table}
                key={table.tableId}
                deleteTable={deleteTable}
                tablesInEditMode={tablesInEditMode}
                startEditingTable={startEditingTable}
                editingUnlocked={editingUnlocked}
                edits={tableEdits[table.tableId] ?? {}}
                setEdits={(edits: EntryEditsMap) =>
                  setTableEdits({ ...tableEdits, [table.tableId]: edits })
                }
                newEntries={newTableEntries[table.tableId] ?? []}
                submitting={submitting}
                setError={setError}
                filter={filter ? filter.filter : undefined}
                headerLabels={headerLabels}
              />
            );
          })}
        </div>
      }
      inputType={inputType}
      updated={updated}
      isEmpty={tables.length + deletedTables.length === 0}
      fetching={fetching}
      submitting={submitting}
      editingUnlocked={editingUnlocked}
      setEditingUnlocked={setEditingUnlocked}
      onFilter={filter => {
        setFilter({ filter });
      }}
      validateAndUpdateTables={validateAndUpdateTables}
      setShowAddInputModal={setShowAddMediaInputModal}
      setShowDiscardDialog={setShowDiscardDialog}
      setError={setError}
    />
  );
};

export default MediaInputs;
