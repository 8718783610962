import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";

import "./LinkSelector.scss";

export interface DropdownLinkOption {
  name: string;
  href: string;
}

interface LinkSelectorProps {
  title: string;
  links: DropdownLinkOption[];
}

const LinkSelector: React.FC<LinkSelectorProps> = ({ title, links }) => {
  return (
    <Dropdown as={ButtonGroup} className="linkSelector">
      <Dropdown.Toggle className="dropdownToggle">{title}</Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">
        {links.map(link => (
          <Dropdown.Item key={link.name} href={link.href}>
            {link.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LinkSelector;
