import gql from "graphql-tag";
import { makeVar } from "@apollo/client";

// Reactive Variables
export const maxReach = makeVar();
export const maxProPurchaseRate = makeVar();
export const maxProPurchased = makeVar();
export const maxProOrderSize = makeVar();
export const maxNTBPurchaseRate = makeVar();
export const maxNTBPurchased = makeVar();
export const maxNTBOrderSize = makeVar();

export const GET_MAX_MEDIA_MIX = gql`
  query getMaxReach {
    maxReach @client
    maxProPurchaseRate @client
    maxProPurchased @client
    maxProOrderSize @client
    maxNTBPurchaseRate @client
    maxNTBPurchased @client
    maxNTBOrderSize @client
  }
`;

export const maxNtbRate = makeVar();
export const maxNtbRepeatRate = makeVar();

export const GET_MAX_NTB = gql`
  query getMaxNTB {
    maxNtbRate @client
    maxNtbRepeatRate @client
  }
`;

export const maxDPVRate = makeVar();
export const maxConversionRate = makeVar();
export const maxGeoNtbRepeatRate = makeVar();
export const maxPurchaseRate = makeVar();
export const GET_MAX_GEO = gql`
  query getMaxGEO {
    maxDPVRate @client
    maxConversionRate @client
    maxGeoNtbRepeatRate @client
    maxPurchaseRate @client
  }
`;
