import React from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Modal, Table, Tooltip } from "react-bootstrap";
import { MdArrowForward, MdCheckBox, MdInfoOutline } from "react-icons/md";

import { PlanRow, RowEdit, TYPES_TO_NAMES, ISCI } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { ExtendEditModalData, EditsMap } from "../linearBuyingUtils";
import { PRETTY_EDIT_NAMES, DATE_FORMAT } from "../linearBuyingConstants";
import { convert24hrTo12hr } from "../../utils/time-utils";
import { useMap } from "../../utils/hooks/useData";
import { BPMButton, CheckBox, OverlayTrigger } from "../../Components";
import { StateSetter } from "../../utils/types";
import { formatMoney } from "../../utils/format-utils";
import "./ExtendEditModal.scss";

const GenericEditDetail = ({ oldValue, newValue, selectedEdit }) => {
  return (
    <>
      <span className="editType">{PRETTY_EDIT_NAMES[selectedEdit]}:</span>
      <span className="oldValue">{oldValue}</span>
      <MdArrowForward />
      <span className="newValue">{newValue}</span>
    </>
  );
};

const CreativeEditDetail = ({ oldRotation, newRotation }) => {
  return (
    <>
      {R.keys(newRotation).map(isci => {
        let pct = newRotation[isci];
        let unchanged = newRotation[isci] === oldRotation[isci];
        return (
          <React.Fragment key={isci}>
            <span>{isci}</span>
            <span className={unchanged ? "unchanged" : "oldValue"}>
              {oldRotation[isci] ? `${oldRotation[isci]}%` : "0%"}
            </span>
            <MdArrowForward />
            <span className={unchanged ? "unchanged" : "newValue"}>{`${pct}%`}</span>
          </React.Fragment>
        );
      })}
    </>
  );
};

interface ExtendEditModalProps {
  onClose: () => void;
  editData: ExtendEditModalData;
  rowOptions: PlanRow[];
  setEditsMap: StateSetter<EditsMap>;
}

const ExtendEditModal: React.FC<ExtendEditModalProps> = ({
  onClose,
  editData,
  rowOptions,
  setEditsMap,
}) => {
  const [selectedRows, setSelectedRowValue] = useMap<string, boolean>({});

  const { row, edits, selectedEditToExtend } = editData;
  const { week, network, daypart_start, daypart_end, dow, avail, length, type, cost, notes } = row;

  const oldValue = row[selectedEditToExtend!];
  const newValue = edits[selectedEditToExtend!];

  let oldRotation: Record<string, number> = {};
  let newRotation: Record<string, number> = {};
  if (selectedEditToExtend === "creatives") {
    for (let creativeMetadata of edits[selectedEditToExtend]!) {
      const { isci, percent } = creativeMetadata;
      newRotation[isci] = percent;
    }
    if (row.creatives) {
      for (let creativeMetadata of row.creatives) {
        const { isci, percent } = creativeMetadata;
        oldRotation[isci] = percent;
      }
    }
  }

  const checkAll = () => {
    for (let row of rowOptions) {
      setSelectedRowValue(row.key, true);
    }
  };

  const addSelected = () => {
    setEditsMap(current => {
      let selected = {};
      for (let row of rowOptions) {
        if (selectedRows[row.key]) {
          // If we are extending an edit to creatives, we need to update the plan_id field to the id
          // that matches the row we are extending into. Otherwise, all future weeks will have the
          // original plan_id in the creatives rows
          if (selectedEditToExtend === "creatives") {
            let creativesWithCorrectPlanId: ISCI[] = [];
            for (let creative of edits.creatives!) {
              creativesWithCorrectPlanId.push({ ...creative, plan_id: row.plan_id });
            }
            selected[row.key] = {
              ...row,
              edits: {
                ...(R.path([row.key, "edits"], current) as RowEdit),
                [selectedEditToExtend!]: creativesWithCorrectPlanId,
              },
            };
          } else {
            selected[row.key] = {
              ...row,
              edits: {
                ...(R.path([row.key, "edits"], current) as RowEdit),
                [selectedEditToExtend!]: edits[selectedEditToExtend!],
              },
            };
          }
        }
      }

      return { ...current, ...selected };
    });
    onClose();
  };

  return (
    <Modal show onHide={onClose} size="lg" className="extendEditModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">
          <div className="title">Extend Selected Edit</div>
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
            overlay={
              <Tooltip id="tooltip">
                <p>
                  Displays all future weeks containing a matching plan to that of the selected edit.
                </p>
              </Tooltip>
            }
          >
            <MdInfoOutline />
          </OverlayTrigger>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="header">
          <div className="week">{R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT))(week)}</div>
          <div className="selectedRowDetails">
            <div className="detail">
              <span className="label">Network: </span>
              <span>{network}</span>
            </div>
            <div className="detail">
              <span className="label">Avail: </span>
              <span>{avail}</span>
            </div>
            <div className="detail">
              <span className="label">Start time: </span>
              <span>{convert24hrTo12hr(daypart_start)}</span>
            </div>
            <div className="detail">
              <span className="label">End time: </span>
              <span>{convert24hrTo12hr(daypart_end)}</span>
            </div>
            <div className="detail">
              <span className="label">Days: </span>
              <span>{dow.join(",")}</span>
            </div>
            <div className="detail">
              <span className="label">Type: </span>
              <span>{TYPES_TO_NAMES[`${type}`]}</span>
            </div>
            <div className="detail">
              <span className="label">Length: </span>
              <span>{length}</span>
            </div>
            <div className="detail">
              <span className="label">Cost: </span>
              <span>{formatMoney(cost, 0)}</span>
            </div>
            <div className="detail">
              <span className="label">Notes: </span>
              <span>{notes}</span>
            </div>
          </div>
          <div className="editDetails">
            {selectedEditToExtend === "creatives" ? (
              <CreativeEditDetail oldRotation={oldRotation} newRotation={newRotation} />
            ) : (
              <GenericEditDetail
                oldValue={oldValue}
                newValue={newValue}
                selectedEdit={selectedEditToExtend}
              />
            )}
          </div>
        </div>
        {rowOptions.length ? (
          <div className="futureRows">
            <BPMButton
              className="checkAllButton"
              variant="outline-primary"
              onClick={() => checkAll()}
            >
              <MdCheckBox />
              Check All
            </BPMButton>
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="select"></th>
                  <th className="company">Week</th>
                  <th className="notes">Edit</th>
                </tr>
              </thead>
              <tbody>
                {rowOptions.map(row => {
                  return (
                    <tr key={row.key}>
                      <td className="checkbox">
                        <CheckBox
                          checked={!!selectedRows[row.key]}
                          onCheck={() =>
                            setSelectedRowValue(row.key, !selectedRows[row.key] ?? true)
                          }
                        />
                      </td>
                      <td className="week">
                        {R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT))(row.week)}
                      </td>
                      <td className="edit">
                        {selectedEditToExtend === "creatives" ? (
                          <CreativeEditDetail oldRotation={oldRotation} newRotation={newRotation} />
                        ) : (
                          <GenericEditDetail
                            oldValue={oldValue}
                            newValue={newValue}
                            selectedEdit={selectedEditToExtend}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>No matching rows.</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="primary" onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton variant="primary" onClick={() => addSelected()}>
          Add Selected
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtendEditModal;
