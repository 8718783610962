import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import moment from "moment";

import { Router } from "@reach/router";
import {
  MdSave,
  MdDateRange,
  MdOutlineFormatListNumbered,
  MdOutlineMonetizationOn,
  MdOutlineBugReport,
} from "react-icons/md";
import { Button, Tooltip, Modal } from "react-bootstrap";

import useLocation from "../utils/hooks/useLocation";
import { useNestedNav, RedirectOnDefault } from "../utils/hooks/useNav";

import { useCreativeMap } from "../redux/creative";

import {
  LinearOptimizationsLambdaFetch,
  SheetsLambdaFetch,
  awaitJSON,
  pollS3,
  CreativeLambdaFetch,
  LinearBuyingLambdaFetch,
} from "../utils/fetch-utils";

import { useSetError } from "../redux/modals";

import { Page, Spinner, BreadcrumbTitle, OverlayTrigger } from "../Components";

import OptimizationHome from "./OptimizationHome";
import ConstraintViewNew from "./ConstraintViewNew";
import RunView from "./RunView";

import "./LinearOptimizations.scss";

export const LinearOptimizationsContext = React.createContext({});

const PRETTY_DATE_FORMAT = "yyyy-MM-dd hh:mma";
const RUNS_SUMMARY_KEY = "RUNS_SUMMARY_KEY";
const RUNS_BUYABLE_KEY = "RUNS_BUYABLE_KEY";
const RUNS_CONSTRAINTS_KEY = "RUNS_CONSTRAINTS_KEY";

export const ROUTES = {
  CONSTRAINTS: {
    key: "constraints",
    label: "Constraints",
    uri: "constraints",
    depth: 1,
  },
  OPTIMIZATIONS: {
    key: "optimizations",
    label: "Optimizations",
    uri: "optimizations",
  },
  RUNS: {
    key: "runs",
    label: "Runs",
    uri: "runs",
    depth: 1,
  },
};

const OPTIMIZATION_TYPE_MAP = {
  1: "buying",
  2: "traffic",
  3: "audience",
};

// This needs to be a constant object. If we calculate this inside the component, it will legit rerender in an infinite
// loop.
const ROUTE_VALUES = R.values(ROUTES);

export default React.memo(({ navigate }) => {
  const setError = useSetError();
  const { company } = useLocation();

  const [optimizations, setOptimizations] = useState();
  const [singleRunOptimization, setSingleRunOptimization] = useState();
  const [optimizationKPIs, setOptimizationKPIs] = useState();
  const [constraintsList, setConstraintsList] = useState();
  const [constraintData, setConstraintData] = useState();
  const [runResult, setRunResult] = useState();
  const [runId, setRunId] = useState();
  const [optimizationKPI, setOptimizationKPI] = useState();
  const [refreshingOptimizations, setRefreshingOptimizations] = useState(true);
  const [selectedView, setSelectedView] = useState(RUNS_SUMMARY_KEY);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [insightsFetched, setInsightsFetched] = useState(false);
  const [creativeInsightsData, setCreativeInsightsData] = useState();
  const [insightsCategoriesData, setInsightsCategoriesData] = useState();
  const [validWeeks, setValidWeeks] = useState();
  const [validWeeksFetched, setValidWeeksFetched] = useState(false);

  const path = useNestedNav({
    baseURL: "linear/optimizations",
    routes: ROUTE_VALUES,
  });
  const currentPathNode = useMemo(() => path[path.length - 1], [path]);
  const isConstraintsPage = useMemo(
    () => R.prop("key", currentPathNode) === ROUTES.CONSTRAINTS.key,
    [currentPathNode]
  );
  const isRunsPage = useMemo(() => R.prop("key", currentPathNode) === ROUTES.RUNS.key, [
    currentPathNode,
  ]);

  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["linear"],
  });

  useEffect(() => {
    if (validWeeksFetched) {
      return;
    }
    (async () => {
      let res = await LinearBuyingLambdaFetch("/get_plan_log_weeks", {
        params: { company },
      });
      // res = await awaitJSON(res);
      setValidWeeks(res);
      setValidWeeksFetched(true);
    })();
  }, [company, validWeeksFetched]);

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          let result = await LinearOptimizationsLambdaFetch("/optimization_kpis", {
            params: { company },
          });
          result = await awaitJSON(result);
          setOptimizationKPIs(result);
        } catch (e) {
          setError({
            message: `Failed to fetch optimization KPIs for company "${company}". ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, setError, setOptimizationKPIs]);

  useEffect(() => {
    if (insightsFetched) {
      return;
    }
    (async () => {
      try {
        setInsightsFetched(true);
        let res = await CreativeLambdaFetch("/get_creative_options", {
          params: { cid: company },
        });
        let formattedRes = await awaitJSON(res);
        let { insightsCategoryData } = formattedRes;
        setInsightsCategoriesData(insightsCategoryData);

        let creativeRes = await LinearOptimizationsLambdaFetch("/get_creative_insights", {
          params: { company },
        });
        let formattedCreativeRes = await awaitJSON(creativeRes);
        let creativeInsights = R.pipe(
          R.groupBy(R.prop("creative")),
          R.map(R.map(R.prop("insight_name")))
        )(formattedCreativeRes);
        setCreativeInsightsData(creativeInsights);
      } catch (e) {
        setError({
          message: `Failed to fetch constraint creative insights data for company "${company}". ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [insightsFetched, insightsCategoriesData, creativeInsightsData, company, setError]);

  const noSpacesCreativeMap = useMemo(
    () =>
      R.pipe(
        R.values,
        R.map(e => [`ad${e.modelEntry};${e.name.replace(/ /g, "")}`, e.name]),
        R.fromPairs
      )(creativeMap),
    [creativeMap]
  );

  const listUntilJobsFinish = useCallback(async () => {
    let optimizationsResult = await LinearOptimizationsLambdaFetch("/optimizations", {
      params: { company },
    });
    optimizationsResult = await awaitJSON(optimizationsResult);
    setOptimizations(optimizationsResult);

    const stillRunning = R.filter(
      row => row.status.indexOf("SUCC") < 0 && row.status.indexOf("FAIL") < 0,
      optimizationsResult
    ).length;

    if (stillRunning > 0) {
      setTimeout(async () => {
        await listUntilJobsFinish();
      }, 30000);
    } else {
      setRefreshingOptimizations(false);
    }
  }, [company, setOptimizations]);

  const getSingleRunOptimization = useCallback(async () => {
    try {
      let optimizationsResult = await LinearOptimizationsLambdaFetch("/optimizations", {
        params: {
          kpi: optimizationKPI,
          company,
          runId,
          singleRunMetadata: true,
        },
      });
      optimizationsResult = await awaitJSON(optimizationsResult);
      setSingleRunOptimization({
        kpi: optimizationKPI,
        runId,
        optimization: optimizationsResult[0],
      });
    } catch (err) {
      setError(`Error fetching optimization ${runId}`);
    }
  }, [runId, company, optimizationKPI, setError]);

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          let [constraintsResult] = await Promise.all([
            LinearOptimizationsLambdaFetch("/list_constraints", {
              params: { company },
            }),
            listUntilJobsFinish(),
          ]);
          constraintsResult = await awaitJSON(constraintsResult);
          setConstraintsList(constraintsResult);
        } catch (e) {
          setError({
            message: `Failed to fetch optimizations for company "${company}". ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, setError, listUntilJobsFinish]);

  useEffect(() => {
    if (company && isConstraintsPage) {
      (async () => {
        let constraintId = currentPathNode.resolvedPath.replace("constraints/", "");
        try {
          let constraintsResult = await LinearOptimizationsLambdaFetch("/constraints", {
            params: { company, name: constraintId },
          });
          constraintsResult = await awaitJSON(constraintsResult);
          constraintsResult.constraints.budget = constraintsResult.constraints.budget.map(
            (elem, index) => ({ ...elem, index: index })
          );
          constraintsResult.constraints.avoid = constraintsResult.constraints.avoid.map(
            (elem, index) => ({ ...elem, index: index })
          );
          constraintsResult.constraints.spot = constraintsResult.constraints.spot.map(
            (elem, index) => ({ ...elem, index: index })
          );
          constraintsResult.constraints.audience = constraintsResult.constraints.audience
            ? constraintsResult.constraints.audience.map((elem, index) => ({
                ...elem,
                index: index,
              }))
            : []; // Need to impute for old constraints with no field

          constraintsResult.constraints.creativesAllowed = constraintsResult.constraints.creativesAllowed.map(
            (elem, index) => ({
              ...elem,
              index: index,
            })
          );
          constraintsResult.constraints.networkCreativesDisallowed = constraintsResult.constraints.networkCreativesDisallowed.map(
            (elem, index) => ({
              ...elem,
              index: index,
            })
          );

          let validatedConstraints = await LinearOptimizationsLambdaFetch("/validate_constraints", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: constraintsResult,
          });
          validatedConstraints = await awaitJSON(validatedConstraints);
          validatedConstraints.optimizationType =
            OPTIMIZATION_TYPE_MAP[validatedConstraints.optimization_type_id] ?? "buying";

          setConstraintData(validatedConstraints);
        } catch (e) {
          setError({
            message: `Failed to fetch constraint ${constraintId} for company "${company}". ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, currentPathNode, setError, isConstraintsPage]);

  useEffect(() => {
    if (isRunsPage) {
      (async () => {
        let kpiWithRunId = currentPathNode.resolvedPath.replace("runs/", "");
        let splitIndex = kpiWithRunId.lastIndexOf("_");
        let kpi = kpiWithRunId.substring(0, splitIndex);
        let runId = kpiWithRunId.substring(splitIndex + 1);
        try {
          const finalPivotData = await LinearOptimizationsLambdaFetch("/optimizations", {
            params: { kpi, runId, csv: true },
          });
          let finalPivot = await awaitJSON(finalPivotData);
          finalPivot = R.pipe(
            R.map(row => {
              if (row.Network === "WNAT") {
                const wnatSpots = parseFloat(row["Number of Spots"]);
                if (wnatSpots <= 20) {
                  return null;
                } else if (wnatSpots < 34) {
                  row["Number of Spots"] = "34";
                }
              }
              return row;
            }),
            R.filter(R.identity)
          )(finalPivot);
          setRunId(runId);
          setOptimizationKPI(kpi);
          // console.log(`RUN RESULT FROM HOME ${runId}`, finalPivot);
          setRunResult(finalPivot);
        } catch (e) {
          setError({
            message: `Failed to load spots data for ${runId}. Error: ${e.message}`,
            // This is a frequently failing lambda because competitors often have missing data. For now, just not
            // reporting the failure
            // reportError: e
          });
        }
      })();
    }
  }, [currentPathNode, setError, isRunsPage]);

  const downloadMediaPlan = useCallback(async () => {
    let kpiWithRunId = currentPathNode.resolvedPath.replace("runs/", "");
    let splitIndex = kpiWithRunId.lastIndexOf("_");
    let kpi = kpiWithRunId.substring(0, splitIndex);
    let runId = kpiWithRunId.substring(splitIndex + 1);

    const xlsxData = await LinearOptimizationsLambdaFetch("/optimizations", {
      params: { kpi, runId, xlsx: true },
    });
    const xlsxFilename = await awaitJSON(xlsxData);

    await pollS3({
      bucket: "bpm-output",
      mimeType: "application/vnd.ms-excel",
      filename: xlsxFilename.filename,
      overloadFilename: `MediaPlan ${kpi} ${runId}.xlsx`,
    });
  }, [currentPathNode]);

  const lastModExperimental = useMemo(
    () =>
      constraintData ? (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={
            <Tooltip>
              {Dfns.format(PRETTY_DATE_FORMAT)(Dfns.parseISO(constraintData.lastmodified))}
            </Tooltip>
          }
        >
          <span className="lastModified">
            {`Last modified ${
              constraintData.lastuser ? `by ${constraintData.lastuser.split("@")[0]}` : ""
            } ${moment(constraintData.lastmodified).fromNow()}`}
          </span>
        </OverlayTrigger>
      ) : (
        ""
      ),
    [constraintData]
  );

  const [buyableNetworkAvailRotations, setBuyableNetworkAvailRotations] = useState();
  const [rotationsByNetwork, setRotationsByNetwork] = useState();
  useEffect(() => {
    if (!buyableNetworkAvailRotations) {
      (async () => {
        try {
          let linearRotationsAndPricing = await SheetsLambdaFetch(
            "/json?file_id=1G33ftgb81YEjVR43WfWKx46sNBulocHspMdXtvTfFKo"
          );
          linearRotationsAndPricing = await awaitJSON(linearRotationsAndPricing);

          let grouped = R.pipe(
            R.filter(row => row.status === "buyable" || row.status === "secured"),
            R.groupBy(row => row.channel),
            R.map(networkGroup =>
              R.pipe(
                R.groupBy(row => row.avail),
                R.map(networkAvailGroup =>
                  R.fromPairs(
                    R.map(
                      row => [
                        `${row.name} (${row.day_set} ${row.daypart_beg} - ${row.daypart_end})`,
                        true,
                      ],
                      networkAvailGroup
                    )
                  )
                )
              )(networkGroup)
            )
          )(linearRotationsAndPricing.json);

          setBuyableNetworkAvailRotations(grouped);
          setRotationsByNetwork(
            R.pipe(
              R.filter(
                rotation =>
                  (rotation.status === "buyable" || rotation.status === "secured") &&
                  Dfns.isValid(
                    Dfns.parseISO(
                      `2016-01-01T${rotation.daypart_beg.length === 4 ? "0" : ""}${
                        rotation.daypart_beg
                      }:00`
                    )
                  ) &&
                  Dfns.isValid(
                    Dfns.parseISO(
                      `2016-01-01T${rotation.daypart_end.length === 4 ? "0" : ""}${
                        rotation.daypart_end
                      }:00.000000`
                    )
                  )
              ),
              R.map(rotation => {
                let start = Dfns.parseISO(
                  `2016-01-01T${rotation.daypart_beg.length === 4 ? "0" : ""}${
                    rotation.daypart_beg
                  }:00`
                );
                let end = Dfns.parseISO(
                  `2016-01-01T${rotation.daypart_end.length === 4 ? "0" : ""}${
                    rotation.daypart_end
                  }:00.000000`
                );
                while (Dfns.getMinutes(end) % 30 !== 0) {
                  end = Dfns.addMinutes(1, end);
                }

                rotation.fullName = `${rotation.name} (${rotation.day_set} ${Dfns.format(
                  "h:mma",
                  start
                )}-${Dfns.format("h:mma", end)})`;
                return [rotation];
              }),
              R.unnest,
              R.groupBy(R.prop("channel"))
            )(linearRotationsAndPricing.json)
          );
        } catch (e) {
          setError({
            message: `Failed to load network rotation info: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [buyableNetworkAvailRotations, setError]);

  const [savingConstraint, setSavingConstraint] = useState(false);
  const [dirtyConstraint, setDirtyConstraint] = useState(false);
  const [invalidConstraint, setInvalidConstraint] = useState(false);

  const saveConstraint = useCallback(async () => {
    setSavingConstraint(true);
    try {
      let lambdaData = await LinearOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: constraintData,
      });
      lambdaData = await awaitJSON(lambdaData);

      let validatedConstraints = await LinearOptimizationsLambdaFetch("/validate_constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: lambdaData,
      });
      validatedConstraints = await awaitJSON(validatedConstraints);
      validatedConstraints.optimizationType =
        OPTIMIZATION_TYPE_MAP[validatedConstraints.optimization_type_id] ?? "buying";

      setConstraintData(validatedConstraints);
      setDirtyConstraint(false);
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setSavingConstraint(false);
  }, [constraintData, setError]);

  const copyConstraint = useCallback(
    async ({ from, to, optimizationType, week }) => {
      let constraintsResult = await LinearOptimizationsLambdaFetch("/constraints", {
        params: { company, name: from },
      });
      constraintsResult = await awaitJSON(constraintsResult);
      // Add an optimization type if it has been set
      const body = { ...constraintsResult, name: to };
      if (optimizationType && week) {
        body.optimizationType = optimizationType;
        body.week = week;
      }
      // Add audience constraint field if doesn't exist
      if (!("audience" in body.constraints)) {
        body.constraints.audience = [];
      }
      await LinearOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      let constraintsListResult = await LinearOptimizationsLambdaFetch("/list_constraints", {
        params: { company },
      });
      constraintsListResult = await awaitJSON(constraintsListResult);
      setConstraintsList(constraintsListResult);
    },
    [company]
  );

  const newConstraint = useCallback(
    async ({ to, optimizationType, week }) => {
      const body = {
        company,
        name: to,
        // Basic constraints included in `sample` optimizations
        constraints: {
          spot: [],
          avoid: [],
          total: 100000,
          budget: [],
          audience: [], // new audience constraint
          global: {
            totalSpots: 2000,
            minExtrapolation: 6,
            unseenMaxSpendPct: 0.25,
            networkMaxSpendPct: 0.2,
            single15sSpotMaxCost: 1800,
            single30sSpotMaxCost: 3000,
            networkDayDaypartCountPerHour: 1.5,
            networkDayDaypartMaxSpendPerHour: 2500,
            networkDayDaypartReachTypeCountPerHour: 1.5,
          },
          version: 4,
          creativesAllowed: [],
          networkCreativesDisallowed: [],
        },
      };

      // Add an optimization type if it has been set
      if (optimizationType && week) {
        body.optimizationType = optimizationType;
        body.week = week;
        body.constraints.week = week;
      }

      await LinearOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      let constraintsListResult = await LinearOptimizationsLambdaFetch("/list_constraints", {
        params: { company },
      });
      constraintsListResult = await awaitJSON(constraintsListResult);
      setConstraintsList(constraintsListResult);
    },
    [company]
  );

  const deleteConstraint = useCallback(
    async ({ deleteConstraintId }) => {
      await LinearOptimizationsLambdaFetch("/delete_constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { company, name: deleteConstraintId },
      });

      let constraintsListResult = await LinearOptimizationsLambdaFetch("/list_constraints", {
        params: { company },
      });
      constraintsListResult = await awaitJSON(constraintsListResult);
      setConstraintsList(constraintsListResult);
    },
    [company]
  );

  const runOptimization = useCallback(
    async ({ name, kpi, branch, optimizationType, week }) => {
      await LinearOptimizationsLambdaFetch("/run_batch_optimizer", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: { name, branch, kpi, optimizationType, week },
      });

      setRefreshingOptimizations(true);
      setTimeout(async () => {
        await listUntilJobsFinish();
      }, 5000);
    },
    [listUntilJobsFinish]
  );

  let actions = useMemo(() => {
    if (isConstraintsPage && constraintData) {
      return (
        <div className={`linearConstraintActions${savingConstraint ? " saving" : ""}`}>
          <>
            <div className="nameLabel">
              <span style={{ color: "#7e57c2" }}>
                {constraintData.optimizationType === "traffic" ? (
                  <span
                    style={{
                      border: "solid 1px #7e57c2",
                      borderRadius: "5px",
                      padding: "3px 6px",
                      margin: "0 10px 2px 0",
                      fontSize: "1rem",
                    }}
                  >
                    <MdDateRange style={{ margin: "0 4px 2px 0" }} />
                    {constraintData.week}
                  </span>
                ) : (
                  false
                )}
                {constraintData.optimizationType[0].toUpperCase() +
                  constraintData.optimizationType.slice(1)}{" "}
                optimization:{" "}
              </span>
              <span>{constraintData.name}</span>
            </div>
            <div>{lastModExperimental}</div>
          </>

          <Button
            variant="primary"
            onClick={saveConstraint}
            disabled={!dirtyConstraint || invalidConstraint}
            className="saveButton"
          >
            {savingConstraint ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      );
    } else if (isRunsPage) {
      return (
        <Button
          variant="outline-primary"
          onClick={() => setShowLogsModal(true)}
          className="saveButton"
        >
          Show AWS Logs
        </Button>
      );
    }
  }, [
    isConstraintsPage,
    constraintData,
    lastModExperimental,
    saveConstraint,
    dirtyConstraint,
    savingConstraint,
    invalidConstraint,
    isRunsPage,
  ]);

  const navs = useMemo(() => {
    if (!isRunsPage) {
      return;
    }

    let navs = [
      {
        key: RUNS_SUMMARY_KEY,
        label: "Summary",
      },
      {
        key: RUNS_BUYABLE_KEY,
        label: "Buyable Spots",
      },
      {
        key: RUNS_CONSTRAINTS_KEY,
        label: "Constraint Debugger",
      },
    ];

    return navs;
  }, [isRunsPage]);

  const navRenderer = label => {
    if (label === "Summary") {
      return (
        <span className="navItem">
          <MdOutlineFormatListNumbered style={{ height: "20px", width: "20px" }} /> {label}
        </span>
      );
    } else if (label === "Buyable Spots") {
      return (
        <span className="navItem">
          <MdOutlineMonetizationOn style={{ height: "20px", width: "20px" }} /> {label}
        </span>
      );
    } else if (label === "Constraint Debugger") {
      return (
        <span className="navItem">
          <MdOutlineBugReport style={{ height: "20px", width: "20px" }} /> {label}
        </span>
      );
    } else {
      return label;
    }
  };

  const LogsModal = useCallback(
    ({ show, setShowLogsModal }) => {
      if (!show) {
        return null;
      }

      // check if exists and not stale
      const isFetchingSingleRunOptimization = !(
        singleRunOptimization?.optimization && singleRunOptimization?.runId === runId
      );
      let singleRunMetadata;
      if (isFetchingSingleRunOptimization) {
        // display spinner and make API request
        getSingleRunOptimization();
      } else {
        singleRunMetadata = singleRunOptimization.optimization;
      }
      return (
        <Modal size="lg" keyboard={true} show={!!show} onHide={() => setShowLogsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Logs for build {runId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isFetchingSingleRunOptimization ? (
              <Spinner size={50} />
            ) : singleRunMetadata.log_url.indexOf("bpm-output") >= 0 ? (
              <>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnRlx-sns-prod-us-west-2;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_linear;start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>IterationConstraints</h2>
                </a>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-python-optimizer-prod-OptimizeIteration;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_linear;start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>PyIPOPT</h2>
                </a>
                <a
                  href={`https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logEventViewer:group=/aws/lambda/bpm-jv-lmbds-ptmztrtnPvt-sns-prod-us-west-2;filter=${singleRunMetadata.company_id}_${singleRunMetadata.build_number}_linear;start=${singleRunMetadata.start};end=${singleRunMetadata.end}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>IterationPivots</h2>
                </a>
              </>
            ) : (
              <a href={singleRunMetadata.log_url} target="_blank" rel="noopener noreferrer">
                <h2>External Link</h2>
              </a>
            )}
          </Modal.Body>
        </Modal>
      );
    },
    [getSingleRunOptimization, runId, singleRunOptimization]
  );

  return (
    <LinearOptimizationsContext.Provider
      value={{
        company,
        optimizationKPIs,
        optimizations,
        constraintsList,
        constraintData,
        setDirtyConstraint,
        setInvalidConstraint,
        setConstraintData,
        buyableNetworkAvailRotations,
        creativeMap,
        insightsCategoriesData,
        creativeInsightsData,
        rotationsByNetwork,
        validWeeks,
        copyConstraint,
        newConstraint,
        deleteConstraint,
        runOptimization,
        refreshingOptimizations,
        runResult,
        runId,
        selectedView,
        RUNS_SUMMARY_KEY,
        RUNS_BUYABLE_KEY,
        RUNS_CONSTRAINTS_KEY,
        downloadMediaPlan,
        noSpacesCreativeMap,
      }}
    >
      <Page
        title={<BreadcrumbTitle path={path} title="Linear Optimizations" navigate={navigate} />}
        pageType="Linear Optimizations"
        navs={navs}
        navRenderer={navRenderer}
        selectedNav={selectedView}
        onNav={setSelectedView}
        minHeight={600}
        actions={actions}
      >
        <div className="optimizationPage">
          <Router>
            <RunView path={`${ROUTES.RUNS.uri}/:optimizationId`} />
            <ConstraintViewNew path={`${ROUTES.CONSTRAINTS.uri}/:constraintId`} />
            <OptimizationHome path="/" />
            <RedirectOnDefault default rootNavigate={navigate} />
          </Router>
          <LogsModal show={showLogsModal} setShowLogsModal={setShowLogsModal} />
        </div>
      </Page>
    </LinearOptimizationsContext.Provider>
  );
});
