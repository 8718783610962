import "./DeliveryBreakdown.scss";
import {
  Metric,
  MetricsByChannel,
  CrossChannelMetrics,
} from "@blisspointmedia/bpm-types/dist/CrossChannel";
import React, { useState, useMemo, useContext, useCallback } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { MdOutlineTableRows, MdShowChart, MdClose } from "react-icons/md";
import { ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import {
  Dropdown,
  DropdownToggleType,
  IconToggleButton,
  DownloadDropdown,
  BPMTable,
} from "../../Components";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  X_AXIS_PADDING,
  DATE_GROUPING_OPTIONS,
  DateGroupingKey,
  INACTIVE_COLOR,
} from "../../TVADCrossChannel/homePageConstants";
import ChartContainer from "../../Components/ChartContainer";
import {
  formatDateLabel,
  getGroupingValue,
  metricFormatter,
} from "../../TVADCrossChannel/homePageUtils";
import { getDeliveryBreakdownCartesianComponents } from "./cartesianComponents";
import { DELIVERY_METRIC_OPTIONS } from "../crossChannelConstants";
import { abbreviateNumber } from "../../utils/data";
import { CrossChannelContext } from "../CrossChannel";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import ChartTooltipV2 from "../../Components/Charts/ChartTooltip/ChartTooltipV2";

interface MetricTotalsByDateChannel {
  date: string;
  channels: MetricsByChannel;
}

interface DeliveryBreakdownProps {
  data: CrossChannelMetrics[];
  otherData: CrossChannelMetrics[];
  defaultMetric?: Metric;
  defaultIncludeOtherDates?: boolean;
}

/**
 * Depending on the date grouping selected (Day, Week, Month, etc.), sum up the metrics for each date.
 */
const getTotalsByDateIncrementChannel = (
  data: CrossChannelMetrics[],
  dateGrouping: DateGroupingKey
): MetricTotalsByDateChannel[] => {
  let totalsByDateChannel: Record<string, MetricsByChannel> = {};

  for (let item of data) {
    const { date, channel, metrics } = item;
    const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);
    totalsByDateChannel[dateToUse] = totalsByDateChannel[dateToUse] || {};

    totalsByDateChannel[dateToUse][channel] = R.mergeWith(
      R.add,
      totalsByDateChannel[dateToUse][channel] || {},
      metrics
    );

    // Compute running average of CPM when aggregating metrics.
    const newCPM =
      (R.pathOr(0, [dateToUse, channel, Metric.SPEND], totalsByDateChannel) * 1000) /
      R.pathOr(0, [dateToUse, channel, Metric.IMPRESSIONS], totalsByDateChannel);

    //@ts-ignore
    totalsByDateChannel[dateToUse][channel][Metric.CPM] =
      isFinite(newCPM) && newCPM !== 0 ? newCPM : null;
  }

  return Object.entries(totalsByDateChannel)
    .map(([date, channels]) => ({ date, channels }))
    .sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Combine data from both date ranges into one array so that they can be visualized on the same chart, even
 * though they can be of differing lengths/date ranges.
 * Adds "_other" suffix to the keys of the comparison data to avoid conflicts.
 */
const getJoinedData = (
  data: MetricTotalsByDateChannel[],
  otherData: MetricTotalsByDateChannel[],
  includeOtherDateRange: boolean
) => {
  const maxLength = includeOtherDateRange ? Math.max(data.length, otherData.length) : data.length;
  let joined: Record<string, any>[] = [];
  for (let i = 0; i < maxLength; i++) {
    const item = data[i] || {};
    // Rename keys of comparison data
    const otherItem = R.keys(otherData[i] || {}).reduce((acc, key) => {
      acc[`${key}_other`] = (otherData[i] || {})[key];
      return acc;
    }, {});

    joined.push({
      ...item,
      ...otherItem,
    });
  }

  return joined;
};

const DeliveryBreakdown: React.FC<DeliveryBreakdownProps> = React.memo(
  ({ data, otherData, defaultMetric = Metric.SPEND, defaultIncludeOtherDates = false }) => {
    const [groupingKey, setGroupingKey] = useState(DATE_GROUPING_OPTIONS[0].value);
    const [metric, setMetric] = useState<string>(defaultMetric);
    const [selectedToggleOption, setSelectedToggleOption] = useState<string>("showGraph");
    const [includeOtherDateRange, setIncludeOtherDateRange] = useState<boolean>(
      defaultIncludeOtherDates
    );
    const [focusedChannel, setFocusedChannel] = useState("");
    const [hoverChannel, setHoverChannel] = useState("");

    const {
      company,
      dates,
      otherDates,
      selectedChannels,
      deliveryAndPerformanceChannels,
      channelColors,
      kpi,
    } = useContext(CrossChannelContext);

    const { dataGroupedByDateIncrement, otherDataGroupedByDateIncrement } = useMemo(() => {
      const dataGroupedByDateIncrement = getTotalsByDateIncrementChannel(data, groupingKey);
      const otherDataGroupedByDateIncrement = getTotalsByDateIncrementChannel(
        otherData,
        groupingKey
      );
      return { dataGroupedByDateIncrement, otherDataGroupedByDateIncrement };
    }, [data, otherData, groupingKey]);

    const joinedData = useMemo(
      () =>
        getJoinedData(
          dataGroupedByDateIncrement,
          otherDataGroupedByDateIncrement,
          includeOtherDateRange
        ),
      [dataGroupedByDateIncrement, otherDataGroupedByDateIncrement, includeOtherDateRange]
    );

    const channels = useMemo(
      () =>
        R.keys(selectedChannels)
          .filter(channel => selectedChannels[channel] && deliveryAndPerformanceChannels[channel])
          .sort(),
      [selectedChannels, deliveryAndPerformanceChannels]
    );

    const chartElements = useMemo(
      () =>
        getDeliveryBreakdownCartesianComponents(
          metric,
          channels,
          includeOtherDateRange,
          channelColors,
          hoverChannel,
          focusedChannel
        ),
      [metric, channels, includeOtherDateRange, channelColors, hoverChannel, focusedChannel]
    );

    const [headers, superHeaders] = useMemo(() => {
      let superHeaders;
      let headers: any[] = [];

      if (includeOtherDateRange) {
        superHeaders = [];
        for (let channel of channels) {
          superHeaders.push({ span: 4, data: channel });
          headers.push({
            label: "Date",
            name: "date",
            width: 60,
            nonInteractive: true,
            renderer: (item: any) => formatDateLabel(item.date, "", true) || "-",
          });
          headers.push({
            label: "",
            name: metric,
            flex: 1,
            nonInteractive: true,
            renderer: (item: any) =>
              metricFormatter(metric)(R.pathOr(0, ["channels", channel, metric], item)) || "-",
          });
          headers.push({
            label: "",
            name: metric,
            flex: 1,
            nonInteractive: true,
            renderer: (item: any) =>
              metricFormatter(metric)(R.pathOr(0, ["channels_other", channel, metric], item)) ||
              "-",
          });
          headers.push({
            label: "Other Date",
            name: "date_other",
            width: 80,
            nonInteractive: true,
            renderer: (item: any) => formatDateLabel(item.date_other, "", true) || "-",
          });
        }
      } else {
        headers.push({
          label: "Date",
          name: "date",
          width: 60,
          nonInteractive: true,
          renderer: (item: any) => formatDateLabel(item.date, "", true) || "-",
        });
        for (let channel of channels) {
          headers.push({
            label: channel,
            name: channel,
            flex: 1,
            nonInteractive: true,
            renderer: (item: any) =>
              metricFormatter(metric)(R.pathOr(0, ["channels", channel, metric], item)) || "-",
          });

          if (includeOtherDateRange) {
            headers.push({
              label: channel,
              name: `${channel}_other`,
              flex: 1,
              nonInteractive: true,
              renderer: (item: any) =>
                metricFormatter(metric)(R.pathOr(0, ["channels_other", channel, metric], item)) ||
                "-",
            });
          }
        }
      }
      return [headers, superHeaders];
    }, [metric, channels, includeOtherDateRange]);

    const excelDownload = useCallback(() => {
      let unionData: any[] = [];
      for (let item of dataGroupedByDateIncrement) {
        for (let channel of R.keys(item.channels)) {
          unionData.push({
            date: item.date,
            channel,
            spend: item.channels[channel].spend,
            impressions: item.channels[channel].impressions,
          });
        }
      }

      for (let item of otherDataGroupedByDateIncrement) {
        for (let channel of R.keys(item.channels)) {
          unionData.push({
            date: item.date,
            channel,
            spend: item.channels[channel].spend,
            impressions: item.channels[channel].impressions,
          });
        }
      }

      exportToExcel(unionData, `${company}_DeliveryBreakdown`);
    }, [company, dataGroupedByDateIncrement, otherDataGroupedByDateIncrement]);

    const pngDownload = useCallback(async () => {
      const label = `KPI: ${kpi}`;
      const date =
        DATE_GROUPING_OPTIONS.find(item => item.value === groupingKey)?.label || groupingKey;
      const prettyNameMetric =
        DELIVERY_METRIC_OPTIONS.find(item => item.value === metric)?.label || metric;

      const fileNameIdentifiers = [
        "CrossChannel",
        date,
        prettyNameMetric,
        `${dates.start}–${dates.end}`,
      ];
      if (includeOtherDateRange) {
        fileNameIdentifiers.push(`vs_${otherDates.start}–${otherDates.end}`);
      }
      await downloadPNG(".deliveryBreakdownContainer", fileNameIdentifiers, label, [".rightSide"]);
    }, [kpi, groupingKey, metric, dates, otherDates, includeOtherDateRange]);

    return (
      <div className="deliveryBreakdownContainer">
        <ChartContainer
          enableHoverDesign
          title={
            <>
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={groupingKey}
                options={DATE_GROUPING_OPTIONS} // TODO: calculate date grouping options to show
                onChange={option => setGroupingKey(option as DateGroupingKey)}
              />
              <Dropdown
                type={DropdownToggleType.WIDGET_TITLE}
                value={metric}
                options={DELIVERY_METRIC_OPTIONS}
                onChange={option => setMetric(option)}
              />
              <div className="titleDivider"></div>
              <div className="primaryDateRange">{`${formatDateLabel(
                dates.start
              )} – ${formatDateLabel(dates.end)}`}</div>
              {!includeOtherDateRange && (
                <div className="addOtherDateRange" onClick={() => setIncludeOtherDateRange(true)}>
                  +
                </div>
              )}
              {includeOtherDateRange && (
                <>
                  <div className="otherDateRange">{`vs ${formatDateLabel(
                    otherDates.start
                  )} – ${formatDateLabel(otherDates.end)}`}</div>
                  <div
                    className="removeButton"
                    onClick={() => {
                      setIncludeOtherDateRange(false);
                    }}
                  >
                    <MdClose />
                  </div>
                </>
              )}
            </>
          }
          rightActions={
            <>
              <IconToggleButton
                size="sm"
                options={[
                  { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                  { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                ]}
                selectedOption={selectedToggleOption}
                onChange={setSelectedToggleOption}
              />
              <DownloadDropdown
                size="sm"
                onClickOptions={[excelDownload, pngDownload]}
                disabledMenuOptions={
                  selectedToggleOption === "showGraph"
                    ? { XLSX: false, PNG: false }
                    : { XLSX: false, PNG: true }
                }
              />
            </>
          }
          footerContent={
            <>
              <span>{"KPI doesn't apply"}</span>
              <span>{"Source = Platform"}</span>
            </>
          }
        >
          {R.isEmpty(joinedData) || R.isEmpty(channels) ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "26px",
              }}
            >
              No data to show
            </div>
          ) : selectedToggleOption === "showGraph" ? (
            <div className="deliveryBreakdownGraphContainer">
              <div id="deliveryBreakdownGraphContents" className="deliveryBreakdownGraphContents">
                <AutoSizer>
                  {({ width, height }) => (
                    <ComposedChart
                      width={width}
                      height={height}
                      data={joinedData}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                      <CartesianGrid
                        vertical={false}
                        stroke={CARTESIAN_GRID_STROKE}
                        strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                      />
                      <XAxis
                        dataKey={
                          includeOtherDateRange
                            ? R.length(dataGroupedByDateIncrement) >=
                              R.length(otherDataGroupedByDateIncrement)
                              ? "date"
                              : "date_other"
                            : "date"
                        }
                        padding={X_AXIS_PADDING}
                        tick={TICK_STYLE}
                        tickFormatter={date => formatDateLabel(date, groupingKey)}
                        axisLine={false}
                      />
                      <YAxis
                        tickLine={false}
                        tick={TICK_STYLE}
                        axisLine={false}
                        tickFormatter={number =>
                          metric === Metric.IMPRESSIONS
                            ? `${abbreviateNumber(number)}`
                            : `$${abbreviateNumber(number)}`
                        }
                      />
                      {chartElements}
                      <Tooltip
                        content={({ label, payload, ...rest }) => {
                          const fullPayload = payload?.[0]?.payload || {};

                          let headerLabel; // Label for the date
                          let items; // Channels
                          let comparisonHeaderLabel; // Label for the comparison date
                          let comparisonItems; // Comparison channels

                          let primaryTotals;
                          let comparisonTotals;

                          // If including other date range, we have to do work to split out the
                          // payload into the two date ranges.
                          if (includeOtherDateRange) {
                            headerLabel = formatDateLabel(fullPayload.date, groupingKey, true);

                            comparisonHeaderLabel = formatDateLabel(
                              fullPayload.date_other,
                              groupingKey,
                              true
                            );

                            primaryTotals = R.sum(
                              payload
                                ?.filter(item =>
                                  R.pathOr("", ["dataKey"], item).includes("channels.")
                                )
                                .map(item => item.value as number) || []
                            );

                            comparisonTotals = R.sum(
                              payload
                                ?.filter(item =>
                                  R.pathOr("", ["dataKey"], item).includes("channels_other.")
                                )
                                .map(item => item.value as number) || []
                            );

                            items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload
                                ?.filter(item =>
                                  R.pathOr("", ["dataKey"], item).includes("channels.")
                                )
                                .map(item => ({
                                  label: item.name as string,
                                  value: metricFormatter(metric)(item.value as number),
                                  color: item.stroke,
                                })) || []
                            );

                            comparisonItems = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload
                                ?.filter(item =>
                                  R.pathOr("", ["dataKey"], item).includes("channels_other.")
                                )
                                .map(item => ({
                                  label: item.name as string,
                                  value: metricFormatter(metric)(item.value as number),
                                  color: item.stroke,
                                })) || []
                            );
                          } else {
                            headerLabel = formatDateLabel(fullPayload.date, groupingKey, true);

                            primaryTotals = R.sum(
                              payload
                                ?.filter(item =>
                                  R.pathOr("", ["dataKey"], item).includes("channels.")
                                )
                                .map(item => item.value as number) || []
                            );

                            items = R.sortBy(
                              (item: any) => -Math.abs(item.value),
                              payload?.map(item => ({
                                label: item.name as string,
                                value: metricFormatter(metric)(item.value as number),
                                color: item.stroke,
                              })) || []
                            );
                          }
                          return (
                            <ChartTooltipV2
                              headers={[headerLabel, comparisonHeaderLabel]}
                              items={items}
                              itemKeyShape={includeOtherDateRange ? "line" : "square"}
                              comparisonItems={comparisonItems}
                              footerValues={
                                focusedChannel === ""
                                  ? [
                                      metricFormatter(metric)(primaryTotals),
                                      metricFormatter(metric)(comparisonTotals),
                                    ]
                                  : undefined
                              }
                            />
                          );
                        }}
                        isAnimationActive={false}
                      />
                    </ComposedChart>
                  )}
                </AutoSizer>
              </div>
              <div className="rightOfChart">
                <div className="deliveryBreakdownLegend">
                  {channels.map((channel, i) => {
                    const resolvedColor =
                      focusedChannel === ""
                        ? hoverChannel === "" || hoverChannel === channel
                          ? channelColors[channel]
                          : INACTIVE_COLOR
                        : focusedChannel === channel
                        ? channelColors[channel]
                        : INACTIVE_COLOR;

                    return (
                      <div
                        className="legendItem"
                        key={channel}
                        onClick={() => {
                          setFocusedChannel(f => (f === channel ? "" : channel));
                          setHoverChannel("");
                        }}
                        onMouseEnter={() => {
                          if (focusedChannel === "") {
                            setHoverChannel(channel);
                          }
                        }}
                        onMouseLeave={() => {
                          if (focusedChannel === "") {
                            setHoverChannel("");
                          }
                        }}
                      >
                        <div
                          className="square"
                          style={{
                            backgroundColor: resolvedColor,
                          }}
                        />
                        <div
                          className={
                            focusedChannel === "" || focusedChannel === channel ? "" : "unselected"
                          }
                        >
                          {channel}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <BPMTable
              superHeaders={superHeaders}
              headers={headers}
              data={joinedData}
              filterBar={false}
              superHeaderHeight={30}
              headerHeight={30}
            />
          )}
        </ChartContainer>
      </div>
    );
  }
);

export default DeliveryBreakdown;
