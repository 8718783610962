import React, { useRef, useLayoutEffect } from "react";
import BarChart from "../Components/BarChart/BarChart";
import { formatMoney, formatMoneyAsInt, formatNumber } from "../utils/format-utils";

export const BPM_PURPLE = "#5917C4";
const CHART_FONT = "sans-serif";
const CHART_HEIGHT = 300;
const CHART_WIDTH = 400;

interface AppendixBarChartProps {
  chartData: any[];
  isSpend?: boolean;
  xKey: string;
  yKey: string;
  onLoad: (results: { elem: SVGSVGElement | null }) => void;
}

export const AppendixBarChart: React.FC<AppendixBarChartProps> = ({
  chartData,
  isSpend,
  xKey,
  yKey,
  onLoad,
}) => {
  const ref = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setTimeout(() => {}, 1000);
      setTimeout(() => {
        onLoad({
          elem: ref.current,
        });
      }, 100);
    }
  }, [onLoad]);

  return (
    <svg ref={ref} viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}>
      <BarChart
        chartData={chartData}
        dataKeys={[yKey]}
        fill={[BPM_PURPLE]}
        font={CHART_FONT}
        height={CHART_HEIGHT}
        isAnimationActive={false}
        isSpend={true}
        layout={"vertical"}
        width={CHART_WIDTH}
        xDataKey={xKey}
        xAxisTickFormatter={tick => {
          if (isSpend) {
            let base = tick >= 1000 ? "k" : "";
            let tickValue = tick >= 1000 ? tick / 1000 : tick;
            if (tick < 1 && tick !== 0) {
              return `${formatMoney(tickValue)}${base}`;
            } else {
              return `${formatMoneyAsInt(tickValue)}${base}`;
            }
          } else {
            return formatNumber(tick);
          }
        }}
        xAxisType={"number"}
        yAxisType={"category"}
        ref={ref}
      ></BarChart>
    </svg>
  );
};
