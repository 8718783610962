import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { TYPES_MAP } from "@blisspointmedia/bpm-types/dist/LinearBuying";

export const DATE_FORMAT = "yyyy-MM-dd";
export const DAYS_OF_WEEK = ["M", "Tu", "W", "Th", "F", "Sa", "Su"] as const;
export const CURRENT_WEEK_START = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
export const CURRENT_WEEK_END = R.pipe(Dfns.endOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
// The estimated audience size of a Local airing compared to its National counterpart.
export const LOCAL_AUDIENCE_CORRECTION_FACTOR = 0.373;

export const FILTER_BAR_OPTIONS = [
  { name: "network", label: "Network" },
  { name: "avail", label: "Avail" },
  { name: "length", label: "Length" },
  { name: "typeName", label: "Type" },
  { name: "rotation", label: "Rotation" },
  { name: "campaignName", label: "Campaign" },
  { name: "media_classification", label: "Media Classification" },
  { name: "market", label: "Market" },
];

export const AVAIL_OPTIONS = [
  { label: "N", value: "N" },
  { label: "L", value: "L" },
] as const;

export const CREATIVE_LENGTH_OPTIONS = [
  { label: 15, value: 15 },
  { label: 30, value: 30 },
  { label: 60, value: 60 },
] as const;

export const ORDER_TYPE_OPTIONS = [
  { label: "Regular", value: TYPES_MAP.Regular },
  { label: "Secured", value: TYPES_MAP.Secured },
  { label: "General", value: TYPES_MAP.General },
  { label: "Remnant", value: TYPES_MAP.Remnant },
  { label: "Upfront", value: TYPES_MAP.Upfront },
] as const;

export const MEDIA_CLASSIFICATION_OPTIONS = [
  { label: "High Profile", value: "High Profile" },
  { label: "ADU Q1", value: "ADU Q1" },
  { label: "ADU Q2", value: "ADU Q2" },
  { label: "ADU Q3", value: "ADU Q3" },
  { label: "ADU Q4", value: "ADU Q4" },
  { label: "Makegood Q1", value: "Makegood Q1" },
  { label: "Makegood Q2", value: "Makegood Q2" },
  { label: "Makegood Q3", value: "Makegood Q3" },
  { label: "Makegood Q4", value: "Makegood Q4" },
] as const;

export const DAY_OF_WEEK_MAP = {
  M: "M----------",
  Tu: "-Tu--------",
  TU: "-Tu--------",
  W: "---W-------",
  Th: "----Th-----",
  TH: "----Th-----",
  F: "------F----",
  Sa: "-------Sa--",
  SA: "-------Sa--",
  Su: "---------Su",
  SU: "---------Su",
  Weekday: "MTuWThF----",
  Weekend: "-------SaSu",
  "M - F": "MTuWThF----",
  "M - Th": "MTuWTh-----",
  "M - W": "MTuW-------",
  "M - Sa": "MTuWThFSa--",
  "M - Su": "MTuWThFSaSu",
  "Tu - W": "-TuW-------",
  "Tu - Th": "-TuWTh-----",
  "W - Th": "---WTh-----",
  "Th - F": "----ThF----",
  "F - Su": "------FSaSu",
  "Sa - Su": "-------SaSu",
} as const;

export const PRETTY_EDIT_NAMES = {
  avail: "Avail",
  cost: "Cost",
  count: "Count",
  daypart_start: "Start time",
  daypart_end: "End time",
  dow: "Days",
  length: "Length",
  notes: "Notes",
  rotation: "Rotation",
  type: "Type",
  creatives: "Creatives",
  media_classification: "Media Classification",
  impressions: "Impressions",
  orderedImpressions: "Ordered Impressions",
  orderedImpressionsDemo: "Ordered Impressions Demo",
  orderedImpressionsMeasurement: "Ordered Impressions Measurement",
  impressionOverrides: "Impression Overrides",
} as const;

// Maps PlanRow keys to what they should be named in the Excel export (to match optimizer files)
export const EXPORT_TO_EXCEL_COLUMN_NAMES = {
  plan_id: "id",
  network: "network",
  avail: "avail",
  rotation: "rotationName",
  daypart: "daypart12hr",
  dow: "dow",
  type: "type",
  length: "length",
  count: "count",
  cost: "cost",
  notes: "notes",
  campaign_id: "campaign",
  media_classification: "media_classification",
  market: "market",
  week: "week",
  impressionsMeasurement: "impressionsMeasurement",
  impressionsDemo: "impressionsDemo",
  orderedImpressionsMeasurement: "orderedImpressionsMeasurement",
  orderedImpressions: "orderedImpressions",
  orderedImpressionsDemo: "orderedImpressionsDemo",
} as const;

export const DEMO_GROUPINGS = {
  Meta: {
    HH: "hh",
    "M18+": "m18plus",
    "W18+": "w18plus",
    "A18+": "a18plus",
    "A18-34": "a18_34",
    "A18-49": "a18_49",
    "A25-54": "a25_54",
    "A35-54": "a35_54",
    "A55+": "a55plus",
  },
  Men: {
    "18 - 20": "m18_20",
    "18 - 34": "m18_34",
    "18 - 49": "m18_49",
    "21 - 24": "m21_24",
    "25 - 29": "m25_29",
    "25 - 54": "m25_54",
    "30 - 34": "m30_34",
    "35 - 39": "m35_39",
    "35 - 54": "m35_54",
    "40 - 44": "m40_44",
    "45 - 49": "m45_49",
    "50 - 54": "m50_54",
    "55 - 64": "m55_64",
    "55+": "m55plus",
    "65+": "m65plus",
  },
  Women: {
    "18 - 20": "w18_20",
    "18 - 34": "w18_34",
    "18 - 49": "w18_49",
    "21 - 24": "w21_24",
    "25 - 29": "w25_29",
    "25 - 54": "w25_54",
    "30 - 34": "w30_34",
    "35 - 39": "w35_39",
    "35 - 54": "w35_54",
    "40 - 44": "w40_44",
    "45 - 49": "w45_49",
    "50 - 54": "w50_54",
    "55 - 64": "w55_64",
    "55+": "w55plus",
    "65+": "w65plus",
  },
};

export const ORDERED_DEMO_GROUPINGS = {
  Meta: {
    HH: "hh",
    "M18+": "m18plus",
    "M25+": "m25plus",
    "W18+": "w18plus",
    "W25+": "w25plus",
    "A2+": "a2plus",
    "A18+": "a18plus",
    "A18-34": "a18_34",
    "A18-49": "a18_49",
    "A25+": "a25plus",
    "A25-54": "a25_54",
    "A35-54": "a35_54",
    "A55+": "a55plus",
  },
  Men: {
    "18 - 20": "m18_20",
    "18 - 34": "m18_34",
    "18 - 49": "m18_49",
    "21 - 24": "m21_24",
    "25 - 29": "m25_29",
    "25 - 54": "m25_54",
    "30 - 34": "m30_34",
    "35 - 39": "m35_39",
    "35 - 54": "m35_54",
    "40 - 44": "m40_44",
    "45 - 49": "m45_49",
    "50 - 54": "m50_54",
    "55 - 64": "m55_64",
    "55+": "m55plus",
    "65+": "m65plus",
  },
  Women: {
    "18 - 20": "w18_20",
    "18 - 34": "w18_34",
    "18 - 49": "w18_49",
    "21 - 24": "w21_24",
    "25 - 29": "w25_29",
    "25 - 44": "w25_44",
    "25 - 49": "w25_49",
    "25 - 54": "w25_54",
    "30 - 34": "w30_34",
    "35 - 39": "w35_39",
    "35 - 54": "w35_54",
    "40 - 44": "w40_44",
    "45 - 49": "w45_49",
    "50 - 54": "w50_54",
    "55 - 64": "w55_64",
    "55+": "w55plus",
    "65+": "w65plus",
  },
};

export const PRETTY_DEMO_NAMES = {
  hh: "HH",
  m18plus: "M18+",
  m25plus: "M25+",
  w18plus: "W18+",
  w25plus: "W25+",
  a2plus: "A2+",
  a18plus: "A18+",
  a18_34: "A18-34",
  a18_49: "A18-49",
  a25plus: "A25+",
  a25_54: "A25-54",
  a35_54: "A35-54",
  a55plus: "A55+",
  m18_20: "M18-20",
  m18_34: "M18-34",
  m18_49: "M18-49",
  m21_24: "M21-24",
  m25_29: "M25-29",
  m25_54: "M25-54",
  m30_34: "M30-34",
  m35_39: "M35-39",
  m35_54: "M35-54",
  m40_44: "M40-44",
  m45_49: "M45-49",
  m50_54: "M50-54",
  m55_64: "M55-64",
  m55plus: "M55+",
  m65plus: "M65+",
  w18_20: "W18-20",
  w18_34: "W18-34",
  w18_49: "W18-49",
  w21_24: "W21-24",
  w25_29: "W25-29",
  w25_44: "W25-44",
  w25_49: "W25-49",
  w25_54: "W25-54",
  w30_34: "W30-34",
  w35_39: "W35-39",
  w35_54: "W35-54",
  w40_44: "W40-44",
  w45_49: "W45-49",
  w50_54: "W50-54",
  w55_64: "W55-64",
  w55plus: "W55+",
  w65plus: "W65+",
};

export const ABBREVIATED_MEAUREMENT_NAMES = {
  "Live+ Same Day AA": "L+SD",
  "Live+ 3 Days AA": "L+3",
  "Live+ 7 Days AA": "L+7",
};

export const OVERLAPPING_DEMO_LOOKUP = {};

for (let [key, val] of Object.entries(DEMO_GROUPINGS.Men)) {
  PRETTY_DEMO_NAMES[val] = `M${key}`.replace(/ /g, "");
  OVERLAPPING_DEMO_LOOKUP[val] = [];
}
for (let [key, val] of Object.entries(DEMO_GROUPINGS.Women)) {
  PRETTY_DEMO_NAMES[val] = `W${key}`.replace(/ /g, "");
  OVERLAPPING_DEMO_LOOKUP[val] = [];
}

const ageParser = e => [
  e[0],
  ...[e[1] + e[2], e[4] + e[5]].map(n => (isNaN(parseInt(n)) ? 100 : parseInt(n))),
];
for (let n = 0; n < Object.keys(OVERLAPPING_DEMO_LOOKUP).length; n++) {
  const group = Object.keys(OVERLAPPING_DEMO_LOOKUP)[n];
  const [mw, start, end] = ageParser(group);
  for (let i = 0; i < Object.keys(OVERLAPPING_DEMO_LOOKUP).length; i++) {
    if (i === n) {
      continue;
    }
    const checkGroup = Object.keys(OVERLAPPING_DEMO_LOOKUP)[i];
    const [checkMw, checkStart, checkEnd] = ageParser(checkGroup);
    if (
      mw === checkMw &&
      !(
        (start >= checkStart && start >= checkEnd && end >= checkStart && end >= checkEnd) ||
        (start <= checkStart && start <= checkEnd && end <= checkStart && end <= checkEnd)
      )
    ) {
      OVERLAPPING_DEMO_LOOKUP[group].push(checkGroup);
    }
  }
}
