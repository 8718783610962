import { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import { XAxis, YAxis, BarChart, Bar, Cell, LabelList } from "recharts";
import * as XLSX from "xlsx";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import {
  Skeleton,
  BarGraphSkeleton,
  AxesSkeleton,
  DownloadDropdown,
  Dropdown,
  DropdownToggleType,
  InfoTooltip,
} from "../Components";
import {
  MEDIA_TYPE_TO_BAR_COLOR,
  GRAY_TEXT_COLOR,
  BUDGET_SNAPSHOT_GREY,
} from "./homePageConstants";
import ChartContainer from "../Components/ChartContainer";
import LinkSelector from "./LinkSelector";
import PerformanceSnapshotLegend from "./PerformanceSnapshotLegend";
import "./PerformanceSnapshot.scss";
import { METRIC_TO_FORMATTER } from "./homePageUtils";

interface PerformanceSnapshotData {
  mediaType: string;
  volumeLastWeek: number;
  volumeLastMonth: number;
  roasLastWeek: number;
  roasLastMonth: number;
  cpxLastWeek: number;
  cpxLastMonth: number;
}

interface PerformanceSnapshotProps {
  data: PerformanceSnapshotData[];
  company: string;
  toggledMediaTypes: Record<string, boolean>;
}

const PERFORMANCE_VIEW_OPTIONS = [
  { value: "volume", label: "Volume" },
  { value: "roas", label: "ROAS" },
  { value: "cpx", label: "CPX" },
];

const PerformanceSnapshot: React.FC<PerformanceSnapshotProps> = ({
  data,
  company,
  toggledMediaTypes,
}) => {
  const [selectedPerformanceView, setSelectedPerformanceView] = useState(
    PERFORMANCE_VIEW_OPTIONS[0].value
  );

  const linkMetadata = {
    title: "performance page",
    links: [
      { name: "Streaming", href: `/${company}/streaming/performance` },
      { name: "Audio", href: `/${company}/audio/performance` },
      { name: "Linear", href: `/${company}/linear/performance` },
      { name: "Display", href: `/${company}/display/performance` },
    ],
  };

  const filteredData = useMemo(
    () =>
      (data || [])
        .filter(row => toggledMediaTypes[row.mediaType])
        .sort((a, b) => a.mediaType.localeCompare(b.mediaType)),
    [data, toggledMediaTypes]
  );

  const exportToExcel = useCallback(() => {
    let fileName = `${company}_PerformanceSnapshot.xlsx`;

    let workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let worksheet = XLSX.utils.json_to_sheet(filteredData);

    let sheetName = `${company}`;

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    XLSX.writeFile(workbook, fileName);
  }, [company, filteredData]);

  const downloadPNG = useCallback(async () => {
    const contents = document.querySelector<HTMLElement>(".performanceSnapshot");
    if (!contents) {
      return;
    }
    const canvas = await html2canvas(contents);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, `${company}_PerformanceSnapshot.png`, "image/png");
  }, [company]);

  return (
    <ChartContainer
      title="Performance Snapshot"
      leftActions={<LinkSelector title={linkMetadata.title} links={linkMetadata.links} />}
      rightActions={
        <>
          {filteredData && (
            <DownloadDropdown size="sm" onClickOptions={[exportToExcel, downloadPNG]} />
          )}
        </>
      }
    >
      {data ? (
        <div className="performanceSnapshot">
          <div className="aboveChart">
            <div className="performanceSnapshotControls">
              <Dropdown
                className="performanceSnapshotControlsDropdown"
                type={DropdownToggleType.OUTLINED}
                design="secondary"
                size="sm"
                value={selectedPerformanceView}
                options={PERFORMANCE_VIEW_OPTIONS}
                onChange={(option: any) => {
                  setSelectedPerformanceView(option);
                }}
              />
              <InfoTooltip>
                <div>
                  Streaming volumes and performance metrics correspond to full funnel incremental
                  matches from Tinuiti’s device graph. <br /> Last week's linear volumes are subject
                  to change daily, as values are based on pre-log and BVS encoded timestamps prior
                  to receipt of network post-logs. Results should be interpreted directionally.
                </div>
              </InfoTooltip>
              <strong style={{ marginLeft: "8px" }}>Last week&nbsp;</strong>
              <div style={{ color: GRAY_TEXT_COLOR }}>vs previous 4 weeks avg</div>
            </div>
          </div>
          <div className="chartContents">
            <div className="leftOfChart">
              <PerformanceSnapshotLegend
                mediaTypes={R.keys(R.pickBy(v => v, toggledMediaTypes))}
                data={data}
                selectedPerformanceView={selectedPerformanceView}
              />
            </div>
            <div id="performanceSnapshotChart" className="performanceSnapshotChart">
              <AutoSizer>
                {({ width, height }) => (
                  <BarChart width={width} height={height} data={filteredData} layout="vertical">
                    <XAxis type="number" hide />
                    <YAxis type="category" dataKey="mediaType" hide />
                    <Bar dataKey={`${selectedPerformanceView}LastWeek`} isAnimationActive={false}>
                      <LabelList
                        dataKey={`${selectedPerformanceView}LastWeek`}
                        position="insideLeft"
                        fill={"#000000"}
                        formatter={value => METRIC_TO_FORMATTER[selectedPerformanceView](value)}
                      />
                      {filteredData.map(entry => {
                        return (
                          <Cell
                            fill={MEDIA_TYPE_TO_BAR_COLOR[entry.mediaType]}
                            color={GRAY_TEXT_COLOR}
                            key={entry.mediaType}
                          />
                        );
                      })}
                    </Bar>
                    <Bar
                      dataKey={`${selectedPerformanceView}LastMonth`}
                      fill={BUDGET_SNAPSHOT_GREY}
                      isAnimationActive={false}
                    >
                      <LabelList
                        dataKey={`${selectedPerformanceView}LastMonth`}
                        position="insideLeft"
                        fill={"#000000"}
                        formatter={value => METRIC_TO_FORMATTER[selectedPerformanceView](value)}
                      />
                    </Bar>
                  </BarChart>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton>
          <BarGraphSkeleton barWidth={14} gutter={3} verticalPadding={5} />
          <AxesSkeleton size={3} />
        </Skeleton>
      )}
    </ChartContainer>
  );
};

export default PerformanceSnapshot;
