import "./AreaChart.scss";
import React, { useCallback, useState, useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Area, AreaChart as AC, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { CARTESIAN_GRID_STROKE, CARTESIAN_GRID_STROKE_WIDTH, TICK_STYLE } from "../ChartConstants";
import { colorMap as colors, Neutral200 } from "../../../utils/colors";
import * as R from "ramda";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import { headerFormatter, useDynamicYAxisWidth } from "../ChartUtils";
import LegendWithGrouping from "../Legend/LegendWithGrouping";
import DefaultLegend from "../Legend/DefaultLegend/DefaultLegend";

const INACTIVE_COLOR = Neutral200;

interface AreaChartProps {
  areas: {
    name: string;
    dataKey: string;
    grouping?: { channel: string; platform: string; tactic: string; brand_nonbrand: string };
    incrementality?: boolean;
    toolTip?: string;
    toolTipColor?: string;
  }[];
  data: any[];
  dateGrouping?: string;
  colorOverrides?: { [key: string]: string };
  hardCodedColor?: string;
  hardCodedColorName?: string;
  hideLegend?: boolean;
  groupedAreas?: any[];
  incrementality?: boolean;
  syncId?: number;
  tooltipFormatter?: (value: any) => string;
  tooltipShape?: "circle" | "square" | "line";
  reverseToolTipItems?: boolean;
  reverseLegend?: boolean;
  usePercentageYAxis?: boolean;
  xAxisDataKey: string;
  xAxisTickFormatter?: (value: any) => string;
  yAxisDataKey?: string;
  yAxisTickFormatter?: (value: any) => string;
  yAxisWidth?: number;
  legendTitle?: string;
}

const AreaChart: React.FC<AreaChartProps> = ({
  areas,
  data,
  dateGrouping,
  colorOverrides,
  hardCodedColor,
  hardCodedColorName,
  hideLegend,
  groupedAreas,
  incrementality,
  syncId,
  tooltipFormatter,
  tooltipShape,
  reverseToolTipItems,
  reverseLegend,
  usePercentageYAxis = false,
  xAxisDataKey,
  xAxisTickFormatter,
  yAxisDataKey,
  yAxisTickFormatter,
  legendTitle,
}) => {
  const [activeArea, setActiveArea] = useState<string | null>(null);
  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [hoveredLegend, setHoveredLegend] = useState<string | null>(null);

  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 3,
  });

  const colorMap = useMemo(() => {
    if (!R.isEmpty(areas)) {
      const colorsOriginal = colors(areas, hardCodedColorName, hardCodedColor);

      return { ...colorsOriginal, ...colorOverrides };
    }
  }, [areas, hardCodedColorName, hardCodedColor, colorOverrides]);

  const handleLegendClick = useCallback(
    (line: string, groupChannel?: string) => {
      if (activeArea === line) {
        setActiveArea(null);
        setActiveGroup(null);
      } else {
        setActiveArea(line);
        if (groupChannel) {
          setActiveGroup(groupChannel);
        }
      }
    },
    [activeArea]
  );

  const handleLegendEnter = useCallback(
    line => {
      hoveredLegend === line ? setHoveredLegend(null) : setHoveredLegend(line);
    },
    [hoveredLegend]
  );

  const buildAreas = useCallback(
    (areas, activeArea, hoveredLegend) => {
      return areas.map((area, i) => {
        const isActiveArea = activeArea === null ? true : activeArea === area.name;
        const isHoveredLegend = hoveredLegend === null ? true : hoveredLegend === area.name;
        if (!isActiveArea && activeArea !== null) {
          return null;
        }

        const activeColor = isActiveArea && colorMap ? colorMap[area.name] : INACTIVE_COLOR;
        const hoverColor = isHoveredLegend && colorMap ? colorMap[area.name] : INACTIVE_COLOR;

        return (
          <Area
            activeDot={false}
            dataKey={area.dataKey}
            fill={activeArea ? activeColor : hoverColor}
            fillOpacity={1}
            isAnimationActive={false}
            key={i}
            name={area.name}
            stackId="1"
            stroke={activeArea ? activeColor : hoverColor}
            strokeWidth={0}
            type="monotone"
          />
        );
      });
    },
    [colorMap]
  );

  const percentageYAxis = (
    <YAxis
      dataKey={yAxisDataKey}
      domain={[0, 100]}
      orientation="left"
      tick={TICK_STYLE}
      ticks={[0, 25, 50, 75, 100]}
      type="number"
      yAxisId="0"
      unit="%"
    />
  );

  return (
    <div className="areaChart" style={{ display: "flex", flex: 1 }}>
      <div style={{ display: "flex", flex: 1 }}>
        <AutoSizer>
          {({ width, height }) => (
            <AC data={data} height={height} syncId={syncId} ref={setChartRef} width={width}>
              <CartesianGrid
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                vertical={false}
              />
              <XAxis
                axisLine={false}
                dataKey={xAxisDataKey}
                height={xAxisHeight}
                tick={TICK_STYLE}
                tickFormatter={xAxisTickFormatter}
              />
              {usePercentageYAxis && percentageYAxis}
              {!usePercentageYAxis && (
                <YAxis
                  axisLine={false}
                  dataKey={yAxisDataKey}
                  tick={TICK_STYLE}
                  tickFormatter={yAxisTickFormatter}
                  tickLine={false}
                  width={yAxisWidth}
                />
              )}
              {buildAreas(areas, activeArea, hoveredLegend)}
              <Tooltip
                content={({ label, payload }) => {
                  if (!payload || !label || !dateGrouping) {
                    return null;
                  }

                  const header = headerFormatter(dateGrouping, label);
                  let items = R.sortBy(
                    (item: any) => -Math.abs(item.value),
                    payload?.map(item => ({
                      label: item.name as string,
                      value: tooltipFormatter
                        ? tooltipFormatter(item.value)
                        : (item.value as number),
                      color: item.stroke,
                    })) || []
                  );

                  return (
                    <ChartTooltip
                      headerLabel={header}
                      items={items}
                      shape={tooltipShape}
                      reverseItems={reverseToolTipItems}
                    />
                  );
                }}
                isAnimationActive={false}
              />
            </AC>
          )}
        </AutoSizer>
      </div>
      <div className={`rightOfChart ${hideLegend ? "hide" : ""}`}>
        <div className="legendTitle">{legendTitle}</div>
        {(!groupedAreas || R.isEmpty(groupedAreas)) && (
          <DefaultLegend
            areas={areas}
            activeArea={activeArea}
            activeGroup={activeGroup}
            handleLegendClick={handleLegendClick}
            incrementality={incrementality}
            setHoveredLegend={setHoveredLegend}
            handleLegendEnter={handleLegendEnter}
            hoveredLegend={hoveredLegend}
            colorMap={colorMap}
            reverseLegend={reverseLegend}
          />
        )}
        {groupedAreas && !R.isEmpty(groupedAreas) && (
          <LegendWithGrouping
            activeArea={activeArea}
            activeGroup={activeGroup}
            handleLegendClick={handleLegendClick}
            setHoveredLegend={setHoveredLegend}
            handleLegendEnter={handleLegendEnter}
            hoveredLegend={hoveredLegend}
            incrementality={incrementality}
            colorMap={colorMap}
            groupedAreas={groupedAreas}
          />
        )}
      </div>
    </div>
  );
};

export default AreaChart;
