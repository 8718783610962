import React, { useState, useMemo, useCallback, useEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Tooltip, Button, ButtonGroup } from "react-bootstrap";
import AutoSizer from "react-virtualized-auto-sizer";
import Select from "react-select";

import { useSetError } from "../redux/modals";

import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";

import {
  Page,
  StickyTable,
  Img,
  Skeleton,
  TableSkeleton,
  DateRangePicker,
  OverlayTrigger,
} from "../Components";

import DeviceBreakoutModal from "./DeviceBreakoutModal/DeviceBreakoutModal";

import "./DeviceBreakout.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 100;
const LEFT_WIDTH = 120;
const COLUMN_WIDTH = 140;
const TOP_HEIGHT = 100;

// Active OTT flight data
const useFlightData = ({ dates, selectedGroup, byNetwork }) => {
  const setError = useSetError();

  const [dataMap, setDataMap] = useState({});
  const dataKey = useMemo(
    () => `${JSON.stringify(selectedGroup)}_${JSON.stringify(dates)}_${byNetwork}`,
    [selectedGroup, dates, byNetwork]
  );
  const data = dataMap[dataKey];

  // Fetch data
  useEffect(() => {
    if (selectedGroup) {
      // If we've already fetched data with these params, back out
      if (data) {
        return;
      }
      (async () => {
        try {
          let params = {
            ...dates,
          };
          if (byNetwork) {
            params = {
              ...params,
              network: selectedGroup,
            };
          } else {
            params = {
              ...params,
              company: R.prop("cid", selectedGroup),
            };
          }
          let res = await ToolsLambdaFetch("/activeott", {
            params,
          });
          let resData = await awaitJSON(res);
          setDataMap(existing => ({
            ...existing,
            [dataKey]: resData,
          }));
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [data, setDataMap, selectedGroup, byNetwork, dates, dataKey, setError]);

  return data || {};
};

// Set the default dates on page load
const initDates = () => {
  let currentMonday = Dfns.startOfISOWeek(new Date());
  let start = R.pipe(Dfns.subWeeks(1), Dfns.format(DATE_FORMAT))(currentMonday);
  let end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(currentMonday);
  return { start, end };
};

// Get a list of company options for dropdown
const useCompanyOptions = () => {
  const setError = useSetError();

  const [options, setOptions] = useState();
  useEffect(() => {
    if (!options) {
      (async () => {
        try {
          let res = await ToolsLambdaFetch("/activeott_company_options");
          let options = await awaitJSON(res);
          setOptions(options);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [options, setOptions, setError]);

  return options;
};

// Get a list of network options for dropdown
const useNetworkOptions = dates => {
  const setError = useSetError();

  const dataKey = useMemo(() => JSON.stringify(dates), [dates]);

  const [options, setOptions] = useState({});
  useEffect(() => {
    if (!(dates.start && dates.end)) {
      return;
    }
    if (options[dataKey]) {
      return;
    }
    (async () => {
      try {
        let res = await ToolsLambdaFetch("/device_breakout_network_options", {
          params: dates,
        });
        let options = await awaitJSON(res);
        setOptions(existing => ({ ...existing, [dataKey]: options }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [dataKey, options, dates, setOptions, setError]);
  return options[dataKey];
};

// Returns the props that are needed for the dropdown
const useDropdownProps = ({
  setSelectedGroup,
  selectedGroup,
  byNetwork,
  networkOptions,
  companyOptions,
}) =>
  useMemo(() => {
    if (!selectedGroup) {
      return {};
    }
    let options;
    let value;
    let onChange;
    if (byNetwork) {
      if (!networkOptions) {
        return {};
      }
      onChange = ({ value }) => setSelectedGroup(value);
      let label;
      if (selectedGroup.description) {
        label = `${selectedGroup.network} ${selectedGroup.description} ${selectedGroup.platform}`;
      } else {
        label = `${selectedGroup.network} ${selectedGroup.platform}`;
      }
      value = {
        value: selectedGroup,
        label,
      };
      options = networkOptions.map(value => {
        let label;
        if (value.description) {
          label = `${value.network} ${value.description} ${value.platform}`;
        } else {
          label = `${value.network} ${value.platform}`;
        }
        return {
          label,
          value,
        };
      });
    } else {
      if (!companyOptions) {
        return {};
      }
      onChange = ({ label, value }) => setSelectedGroup({ name: label, cid: value });

      value = {
        value: selectedGroup.cid,
        label: selectedGroup.name,
      };
      options = companyOptions.map(({ cid, name }) => ({
        label: name,
        value: cid,
      }));
    }
    return { options, value, onChange };
  }, [setSelectedGroup, selectedGroup, byNetwork, networkOptions, companyOptions]);

// Transform table data into format for Sticky Table
const useData = ({ byNetwork, tableData }) => {
  if (!R.keys(tableData).length) {
    return {};
  }
  const {
    deviceList,
    companyList,
    networkList,
    totalImpsByCompany,
    totalImpsByNetwork,
    companyDeviceMap,
    networkDeviceMap,
  } = tableData;
  let topData;
  let leftData = deviceList;
  let bottomData = [];
  let selectedData = [];

  // Top data
  if (byNetwork) {
    topData = companyList;
  } else {
    topData = networkList;
  }

  // Bottom data
  if (byNetwork) {
    for (let company of companyList) {
      bottomData.push(totalImpsByCompany[company].toLocaleString());
    }
  } else {
    for (let network of networkList) {
      bottomData.push(totalImpsByNetwork[network].toLocaleString());
    }
  }

  // Selected data
  if (byNetwork) {
    for (let device of deviceList) {
      let row = [];
      for (let company of companyList) {
        let count = companyDeviceMap[company][device];
        if (count) {
          row.push({
            count: count.toLocaleString(),
            percentage: `(${Math.round((count / totalImpsByCompany[company]) * 100)}%)`,
            company,
          });
        } else {
          row.push({ count: "-", percentage: "-" });
        }
      }
      selectedData.push(row);
    }
  } else {
    for (let device of deviceList) {
      let row = [];
      for (let network of networkList) {
        let count = networkDeviceMap[network][device];
        if (count) {
          row.push({
            count: count.toLocaleString(),
            percentage: `(${Math.round((count / totalImpsByNetwork[network]) * 100)}%)`,
            combinedPlacementName: network.replace(/ /g, ""),
            placement: network,
            network: network.split(" ")[0],
          });
        } else {
          row.push({ count: "-", percentage: "-" });
        }
      }
      selectedData.push(row);
    }
  }
  return {
    topData,
    leftData,
    bottomData,
    selectedData,
  };
};

const DeviceBreakout = () => {
  const setError = useSetError();

  const [dates, setDates] = useState(initDates);
  const [byNetwork, setByNetworkRaw] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [tableDataMap, setTableDataMap] = useState({});
  const companyOptions = useCompanyOptions();
  const networkOptions = useNetworkOptions(dates);

  let tableDataKey = JSON.stringify({ byNetwork, dates, selectedGroup });
  let tableData = tableDataMap[tableDataKey] || {};

  const { topData, leftData, bottomData, selectedData } = useData({
    byNetwork,
    tableData,
  });

  // When there is no selected group, set it to the default, which is the first of the available options
  useEffect(() => {
    if (!selectedGroup) {
      if (byNetwork) {
        if (networkOptions) {
          setSelectedGroup(networkOptions[0]);
        }
      } else if (companyOptions) {
        setSelectedGroup(companyOptions[0]);
      }
    }
  }, [companyOptions, networkOptions, selectedGroup, setSelectedGroup, byNetwork]);

  // When switching between Company and Network, reset the selected group to the default
  const setByNetwork = useCallback(
    byNetwork => {
      if (byNetwork) {
        setSelectedGroup(R.prop(0, networkOptions));
      } else {
        setSelectedGroup(R.prop(0, companyOptions));
      }
      setByNetworkRaw(byNetwork);
    },
    [networkOptions, companyOptions]
  );

  const dropdownProps = useDropdownProps({
    setSelectedGroup,
    selectedGroup,
    byNetwork,
    networkOptions,
    companyOptions,
  });

  // Fetch the table data
  useEffect(() => {
    if (!selectedGroup) {
      return;
    }
    if (tableDataMap[tableDataKey]) {
      return;
    }
    (async () => {
      try {
        let network, platform, company;
        if (byNetwork) {
          if (selectedGroup.derived_id) {
            network = selectedGroup.derived_id;
          } else {
            ({ network } = selectedGroup);
          }
          ({ platform } = selectedGroup);
        } else {
          company = selectedGroup.cid;
        }
        let res = await ToolsLambdaFetch("/device_breakout", {
          params: {
            ...dates,
            network,
            platform,
            company,
          },
        });
        let data = await awaitJSON(res);
        setTableDataMap(current => {
          return { ...current, [tableDataKey]: data };
        });
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [dates, byNetwork, selectedGroup, setError, tableDataKey, tableDataMap]);

  const { flightMap } = useFlightData({
    dates,
    selectedGroup,
    byNetwork,
  });

  const [modalData, setModalData] = useState();

  return (
    <Page
      title="Device Breakout"
      pageType="Device Breakout"
      actions={
        <div className="deviceBreakoutActions">
          {selectedGroup && (
            <div className="companyPicker">
              <div className="logo">
                <Img
                  src={`https://cdn.blisspointmedia.com/${
                    byNetwork
                      ? `networks/${selectedGroup.network}`
                      : `companies/${selectedGroup.cid}/logo`
                  }.png`}
                />
              </div>
              <div className="deviceBreakoutSelect">
                <Select {...dropdownProps} />
              </div>
            </div>
          )}
          <ButtonGroup toggle>
            <Button active={!byNetwork} onClick={() => setByNetwork(false)}>
              Company
            </Button>
            <Button active={byNetwork} onClick={() => setByNetwork(true)}>
              Network
            </Button>
          </ButtonGroup>
          <DateRangePicker
            startDate={dates.start}
            endDate={dates.end}
            startDateId="deviceBreakoutStartDate"
            endDateId="deviceBreakoutEndDate"
            onChange={({ startDate, endDate }) =>
              setDates({
                start: startDate,
                end: endDate,
              })
            }
          />
        </div>
      }
    >
      <div className="deviceBreakout">
        {selectedData ? (
          <>
            {selectedData.length ? (
              <div className="deviceBreakoutTable">
                <AutoSizer>
                  {({ width, height }) => (
                    <StickyTable
                      width={width}
                      height={height}
                      leftWidth={LEFT_WIDTH}
                      rowHeight={ROW_HEIGHT}
                      columnWidth={COLUMN_WIDTH}
                      topHeight={TOP_HEIGHT}
                      data={selectedData}
                      topData={topData}
                      leftData={leftData}
                      bottomData={bottomData}
                      cellRenderer={({ data, style, classes }) => {
                        if (data.count === "-") {
                          return (
                            <div
                              className={[...classes, "selectedDataCell"].join(" ")}
                              style={style}
                            >
                              <div>{data.count}</div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className={[...classes, "selectedDataCell", "withData"].join(" ")}
                              style={style}
                              onClick={() => {
                                if (byNetwork) {
                                  return;
                                }
                                let { combinedPlacementName, network, placement } = data;
                                let flights = [];

                                if (tableData.placementToFlightsMap[combinedPlacementName]) {
                                  flights = tableData.placementToFlightsMap[combinedPlacementName];
                                }

                                setModalData({ flights, network, placement });
                              }}
                            >
                              <div className="cellCount">
                                {data.count}
                                <span className="cellPercentage">{data.percentage}</span>
                              </div>
                            </div>
                          );
                        }
                      }}
                      topRenderer={({ data, style, classes }) => {
                        let label;
                        if (!byNetwork) {
                          let parts = data.split(" ");
                          // Gets rid of the short code
                          parts.shift();
                          // If it's Streaming _____, then just use _____. If it's just Streaming, leave it.
                          if (parts[0] === "Streaming" && parts.length > 1) {
                            parts.shift();
                          }
                          label = parts.join(" ");
                        }
                        return (
                          <OverlayTrigger
                            placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                            overlay={<Tooltip>{data}</Tooltip>}
                          >
                            <div className={[...classes, "topDataCell"].join(" ")} style={style}>
                              <div className="logo">
                                <Img
                                  src={`https://cdn.blisspointmedia.com/${
                                    byNetwork
                                      ? `companies/${data}/square_logo`
                                      : `networks/${data.split(" ")[0]}`
                                  }.png`}
                                />
                              </div>
                              {!byNetwork && <div className="platform">{label}</div>}
                            </div>
                          </OverlayTrigger>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              </div>
            ) : (
              <div className="noData">This client has no device data in this date range.</div>
            )}
          </>
        ) : (
          <Skeleton>
            <TableSkeleton rowHeight={ROW_HEIGHT} headerHeight={HEADER_HEIGHT} />
          </Skeleton>
        )}
      </div>
      {modalData && (
        <DeviceBreakoutModal
          data={modalData}
          flightMap={flightMap}
          byNetwork={byNetwork}
          onClose={() => setModalData()}
        />
      )}
    </Page>
  );
};

export default DeviceBreakout;
