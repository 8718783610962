import { Page } from "../Components";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import React, { useEffect } from "react";
// /ebay is given to eBay as Tinuiti's single redirectURL for all clients,
// so clients should have a cookie specifying which company they are acting on behalf of,
// and this page will redirect them straight to their company specific eBay page
const EbayRedirect: React.FC = () => {
  const companies = useSelector(UserRedux.companiesSelector);
  const company = window.localStorage.getItem("eBay_company");
  const setError = useSetError();
  useEffect(() => {
    (() => {
      try {
        const validCompany =
          !R.isNil(company) &&
          !R.isNil(companies) &&
          !R.isEmpty(companies) &&
          R.map(company => company.name, companies).includes(company, companies);
        if (company && validCompany) {
          const currentUrl = window.location.href;
          const redirectUrl = currentUrl.replace("ebay", `${company}/ebay`);
          localStorage.removeItem("eBay_company");
          window.location.href = redirectUrl;
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to redirect to ${company}'s eBay page ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [companies, company, setError]);

  return <Page title="eBayRedirect" pageType="eBayRedirect" minHeight="600px"></Page>;
};

export default EbayRedirect;
