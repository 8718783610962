import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { ListGroup, Button, Dropdown, DropdownButton, Table, Modal } from "react-bootstrap";
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  AreaChart,
  Area,
  Cell,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector } from "react-redux";
import { MdMoreVert } from "react-icons/md";

import { ReactComponent as StreamingVideoIcon } from "../HomePage/streamingVideo.svg";
import { ReactComponent as StreamingAudioIcon } from "../HomePage/streamingAudio.svg";
import { ReactComponent as LinearTvIcon } from "../HomePage/linearTv.svg";

import {
  FullPageSpinner,
  Page,
  KpiPickerLegacy,
  BPMTable,
  DateRangePicker,
  BPMToggleButton,
  Spinner,
  NumberFormatter,
  InfoTooltip,
} from "../Components";
import { getSeriesColor, primary, secondary } from "../utils/colors";
import useLocation from "../utils/hooks/useLocation";
import { useSetError } from "../redux/modals";
import * as CompanyRedux from "../redux/company";
import * as UserRedux from "../redux/user";
import { download, downloadJSONToCSV, convertSVGStringToPNGURI } from "../utils/download-utils";
import { useScrollbarSizes } from "../utils/hooks/useDOMHelpers";
import { abbreviateNumber } from "../utils/data";
import { overrideDateRange } from "../utils/test-account-utils";

import {
  ToolsLambdaFetch,
  awaitJSON,
  AdminLambdaFetch,
  LinearLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  StreamingV2LambdaFetch,
  StreamingUtilsLambdaFetch,
} from "../utils/fetch-utils";

import "./HomePage.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "MMMM dd, yyyy";
const TOOLTIP_LABEL_DATE = "MMM do, yyyy";
const DATE_PICKER_CUTOFF = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
const X_AXIS_PADDING = { left: 15, right: 15 };
const X_AXIS_DOMAIN = ["dataMin", "dataMax"];
const CHART_MARGIN_WITH_LEGEND = { top: 10, right: 25 };
const CHART_MARGIN_NO_LEGEND = { top: 20, right: 25 };
const CARTESIAN_GRID_STROKE = "#b9bed2";
const CARTESIAN_GRID_STROKE_WIDTH = 1;
const LINE_STROKE_PRIMARY = primary;
const LINE_STROKE_SECONDARY = secondary;
const LINE_STROKE_WIDTH = 2;
const TICK_STYLE = { fontSize: 12 };
const AREA_FILL = primary;
const PROCESSING = "Processing...";
const LINEAR_PROCESSING_THRESHOLD = 0.25;
const SEASONALITY_LABEL_MAP = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};
const DAY_OF_WEEK_LABEL_MAP = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
};

const TODAY = R.pipe(Dfns.format(DATE_FORMAT))(new Date());
const YESTERDAY = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());

const SUMMARY_START_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(4),
  Dfns.format(DATE_FORMAT)
)(new Date());

const SUMMARY_END_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.addWeeks(5),
  Dfns.subDays(1),
  Dfns.format(DATE_FORMAT)
)(new Date());

const TWO_WEEKS_AGO_START_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(2),
  Dfns.format(DATE_FORMAT)
)(new Date());

const TWO_WEEKS_AGO_END_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(2),
  Dfns.addDays(6),
  Dfns.format(DATE_FORMAT)
)(new Date());

const LAST_WEEK_START_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(1),
  Dfns.format(DATE_FORMAT)
)(new Date());

const LAST_WEEK_END_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(1),
  Dfns.addDays(6),
  Dfns.format(DATE_FORMAT)
)(new Date());

const POSTLOGS_END_DATE = R.pipe(
  Dfns.subWeeks(Dfns.getISODay(new Date()) >= 4 ? 0 : 1),
  Dfns.startOfISOWeek,
  Dfns.addDays(6),
  Dfns.format(DATE_FORMAT)
)(new Date());

const CURRENT_WEEK_START_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const CURRENT_WEEK_END_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.addDays(6),
  Dfns.format(DATE_FORMAT)
)(new Date());

const SUPER_HEADERS = [
  { span: 1 },
  { span: 2, data: "Linear TV" },
  { span: 2, data: "Streaming Video" },
  { span: 2, data: "Streaming Audio" },
];

const calculateChange = (val, lastWeekVal) => {
  let change = Math.round(((val - lastWeekVal) / lastWeekVal) * 100);

  if (change === Infinity) {
    change = 100;
  }
  if (isNaN(change)) {
    change = 0;
  }
  return change;
};

// Calculates percent change in Overview
const weekOverWeekChange = (currentWeek, summaryTableData, mediaType, chartLocation) => {
  let change = Math.round(
    ((R.reduce(
      R.add,
      0,
      R.map(
        item => item[mediaType],
        currentWeek ? summaryTableData.currentWeekSummary : summaryTableData.lastWeekSummary
      )
    ) -
      R.reduce(
        R.add,
        0,
        R.map(
          item => item[mediaType],
          currentWeek ? summaryTableData.lastWeekSummary : summaryTableData.twoWeeksAgoSummary
        )
      )) /
      R.reduce(
        R.add,
        0,
        R.map(
          item => item[mediaType],
          currentWeek ? summaryTableData.lastWeekSummary : summaryTableData.twoWeeksAgoSummary
        )
      )) *
      100
  );

  if (change === Infinity) {
    change = 100;
  }
  if (isNaN(change)) {
    return <div className={["mediaChange", "neutral"].join(" ")}>-</div>;
  }

  let classes = ["mediaChange"];
  if (change > 0) {
    classes.push("positive");
  } else if (change < 0) {
    classes.push("negative");
  } else {
    classes.push("neutral");
  }

  if (chartLocation === "overview") {
    return `${Math.round(change).toLocaleString()}%`;
  }
  return <div className={classes.join(" ")}>{`${change}%`}</div>;
};

const weeklySpendTotal = (currentWeek, summaryTableData, mediaType) => {
  let total = 0;
  const elems = currentWeek
    ? summaryTableData.currentWeekSummary
    : summaryTableData.lastWeekSummary;
  for (const elem of elems) {
    if (elem[mediaType] === PROCESSING) {
      total = PROCESSING;
      break;
    } else {
      total += elem[mediaType];
    }
  }

  return total;
};

const fullDateRangeSpendTotal = (data, mediaType) => {
  let total = R.reduce(
    R.add,
    0,
    R.map(item => (item[mediaType] === PROCESSING ? 0 : item[mediaType]), data)
  );

  return total;
};

const defaultSummaryExportDates = () => {
  let start = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());
  let end = R.pipe(Dfns.startOfISOWeek, Dfns.addDays(6), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end };
};

const defaultKpiChartDates = () => {
  let start = R.pipe(Dfns.subMonths(6), Dfns.format(DATE_FORMAT))(new Date());
  let end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end };
};

const isLinearProcessing = (date, linear, linearBooked) => {
  return linear < LINEAR_PROCESSING_THRESHOLD * linearBooked && date > POSTLOGS_END_DATE
    ? PROCESSING
    : `$${linear.toLocaleString()}`;
};

const HomePage = () => {
  const setError = useSetError();
  const { company } = useLocation();
  const [kpi, setKpi] = useState(useSelector(CompanyRedux.initialKpiSelector(company)));
  const [summaryTableMap, setSummaryTableMap] = useState({});
  const [currentWeek, setCurrentWeek] = useState(true);
  const companyInfo = CompanyRedux.useCompanyInfo();
  const [chartDataMap, setChartDataMap] = useState({});
  let isInternal = useSelector(UserRedux.isInternalSelector);
  const [downloadingSpendSummary, setDownloadingSpendSummary] = useState(false);
  const [downloadingPostlogs, setDownloadingPostlogs] = useState(false);
  const [downloadingKpiDebug, setDownloadingKpiDebug] = useState(false);
  const [showSpendSummaryModal, setShowSpendSummaryModal] = useState(false);
  const [showPostlogsModal, setShowPostlogsModal] = useState(false);
  const [spendSummaryDates, setSpendSummaryDates] = useState(defaultSummaryExportDates);
  const [postLogsDates, setPostLogsDates] = useState(defaultSummaryExportDates);
  const [kpiChartDates, setKpiChartDates] = useState(defaultKpiChartDates);
  const summaryTableRef = useRef();
  const scrollBarSize = useScrollbarSizes(summaryTableRef);

  let kpiCompanyName;
  if (kpi) {
    kpiCompanyName = kpi.split("_")[0];
  }

  let kpiChartKey = JSON.stringify({ kpi, kpiChartDates });

  let data = chartDataMap[kpiChartKey];
  let summaryData = summaryTableMap[kpiCompanyName];

  const downloadChartCSV = async (kpi, file) => {
    await pollS3({
      bucket: "bpm-ml-data",
      mimeType: "text/csv",
      filename: `v4/${kpi}/latest/${file}.csv.gz`,
      overloadFilename: `${kpi}.csv`,
    });
  };

  useEffect(() => {
    (async () => {
      if (downloadingKpiDebug) {
        const debugS3Response = await StreamingUtilsLambdaFetch(`/kpi_debug_zip?kpi=${kpi}`);
        const debugS3Info = await awaitJSON(debugS3Response);
        await pollS3({
          bucket: debugS3Info.Bucket,
          mimeType: "application/zip",
          filename: debugS3Info.Key,
          overloadFilename: `${kpi}.zip`,
        });
        setDownloadingKpiDebug(false);
      }
    })();
  }, [downloadingKpiDebug, kpi]);

  const exportPostlogs = async (start, end) => {
    setDownloadingPostlogs(true);
    try {
      let logsResponse = await LinearLambdaFetch(
        `/processed_post_logs?start=${start}%2005:00:00&end=${end}%2005:00:00&kpi=${kpiCompanyName}`
      );
      logsResponse = await awaitJSON(logsResponse);
      await pollS3({
        bucket: logsResponse.bucket,
        mimeType: "text/csv",
        filename: logsResponse.key,
        overloadFilename: `Post Logs ${kpiCompanyName} from ${start} to ${end}.csv`,
      });

      setShowPostlogsModal(false);
      setDownloadingPostlogs(false);
    } catch (e) {
      setError({ message: e.message, reportError: e });
      setDownloadingPostlogs(false);
    }
  };

  const exportSpendSummary = async (start, end) => {
    setDownloadingSpendSummary(true);
    try {
      const [streamingDeliveryData, audioDeliveryData, linearData] = await Promise.all([
        (async () => {
          let streamingV2Res = await StreamingV2LambdaFetch("/delivery_v2", {
            params: {
              start: start,
              end: end,
              company: kpiCompanyName,
              dimension: "network_group",
              streaming_type: "streaming",
            },
          });
          let streamingV2Result = await awaitJSON(streamingV2Res);
          return streamingV2Result;
        })(),
        (async () => {
          let audioV2Res = await StreamingV2LambdaFetch("/delivery_v2", {
            params: {
              start: start,
              end: end,
              company: kpiCompanyName,
              dimension: "network_group",
              streaming_type: "audio",
            },
          });
          let audioV2Result = await awaitJSON(audioV2Res);
          return audioV2Result;
        })(),
        (async () => {
          let linearRes = await LinearLambdaFetch("/estimates", {
            params: { company: kpiCompanyName, week: start },
          });
          let linearResult = await awaitJSON(linearRes);
          return linearResult;
        })(),
      ]);

      let streamingAndAudioSpendByDayMap = {};

      for (let row of streamingDeliveryData) {
        const { date, cost } = row;
        streamingAndAudioSpendByDayMap[date] = {
          ...streamingAndAudioSpendByDayMap[date],
          streaming: (R.path([date, "streaming"], streamingAndAudioSpendByDayMap) || 0) + cost,
        };
      }

      for (let row of audioDeliveryData) {
        const { date, cost } = row;
        streamingAndAudioSpendByDayMap[date] = {
          ...streamingAndAudioSpendByDayMap[date],
          audio: (R.path([date, "audio"], streamingAndAudioSpendByDayMap) || 0) + cost,
        };
      }

      let linearMap = R.pipe(
        R.prop("weekSummary"),
        R.groupBy(R.prop("day")),
        R.map(R.prop(0))
      )(linearData);

      let summaryArray = Dfns.eachDayOfInterval({
        start: Dfns.parseISO(start),
        end: Dfns.parseISO(end),
      }).map(dateRaw => {
        let date = Dfns.format(DATE_FORMAT, dateRaw);

        let linearSpend, linearBooked;
        if (!R.prop(date, linearMap)) {
          linearSpend = 0;
          linearBooked = 0;
        } else if (linearMap[date].actual > 0) {
          linearSpend = linearMap[date].actual;
          linearBooked = R.defaultTo(0, linearMap[date].booked);
        } else {
          linearSpend = linearMap[date].estimated + linearMap[date].electronic;
          linearBooked = R.defaultTo(0, linearMap[date].booked);
        }

        let streaming =
          Math.round(R.path([date, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
        let audio = Math.round(R.path([date, "audio"], streamingAndAudioSpendByDayMap)) || 0;
        let linear = Math.round(linearSpend) || 0;

        streaming = `$${streaming.toLocaleString()}`;
        audio = `$${audio.toLocaleString()}`;

        linear = isLinearProcessing(date, linear, linearBooked);

        return {
          Date: date,
          "Linear TV": linear,
          "Streaming Video": streaming,
          "Streaming Audio": audio,
        };
      });

      downloadJSONToCSV(summaryArray, "Spend Summary");
      setShowSpendSummaryModal(false);
      setDownloadingSpendSummary(false);
    } catch (e) {
      setError({ message: e.message, reportError: e });
      setDownloadingSpendSummary(false);
    }
  };

  const downloadSummaryTableCSV = week => {
    let csvTitle = currentWeek ? "CurrentWeekSummary" : "LastWeekSummary";

    let linearTotal = R.reduce(
      R.add,
      0,
      R.map(item => (item.linear === PROCESSING ? 0 : item.linear), week)
    );

    let streamingTotal = R.reduce(
      R.add,
      0,
      R.map(item => item.streaming, week)
    );

    let audioTotal = R.reduce(
      R.add,
      0,
      R.map(item => item.audio, week)
    );

    let enabledMediaTypesMap = {
      tv: false,
      streaming: false,
      audio: false,
    };

    for (let mediaType of ["tv", "streaming", "audio"]) {
      if (R.includes(mediaType, companyInfo.media_types)) {
        enabledMediaTypesMap[mediaType] = true;
      }
    }

    let summaryArray = R.map(day => {
      let newRow = { Date: day.prettyDate };

      if (enabledMediaTypesMap.tv) {
        newRow["Linear TV"] = day.linear;
        newRow["Linear TV Delta"] = day.linearChange;
      }

      if (enabledMediaTypesMap.streaming) {
        newRow["Streaming Video"] = day.streaming;
        newRow["Streaming Video Delta"] = day.streamingChange;
      }

      if (enabledMediaTypesMap.audio) {
        newRow["Streaming Audio"] = day.audio;
        newRow["Streaming Audio Delta"] = day.audioChange;
      }

      return newRow;
    }, week);

    let finalRow = { Date: "Summary" };

    if (enabledMediaTypesMap.tv) {
      finalRow["Linear TV"] = linearTotal;
      finalRow["Linear TV Delta"] = null;
    }

    if (enabledMediaTypesMap.streaming) {
      finalRow["Streaming Video"] = streamingTotal;
      finalRow["Streaming Video Delta"] = null;
    }

    if (enabledMediaTypesMap.audio) {
      finalRow["Streaming Audio"] = audioTotal;
      finalRow["Streaming Audio Delta"] = null;
    }

    summaryArray.push(finalRow);

    downloadJSONToCSV(summaryArray, csvTitle);
  };

  const pngUri = id => {
    let chart = document.querySelectorAll(`#${id} svg`);
    let serializer = new XMLSerializer();
    let svgData = serializer.serializeToString(chart[0]);

    return convertSVGStringToPNGURI(svgData);
  };

  // Fetch Summary Table Data
  useEffect(() => {
    if (!kpiCompanyName || summaryTableMap[kpiCompanyName]) {
      return;
    }
    let bail = false;
    (async () => {
      try {
        const [
          streamingDeliveryData,
          audioDeliveryData,
          streamingPacingData,
          linearData,
          additionalData,
        ] = await Promise.all([
          (async () => {
            let streamingV2Res = await StreamingV2LambdaFetch("/delivery_v2", {
              params: {
                start: TWO_WEEKS_AGO_START_DATE,
                end: YESTERDAY,
                company: kpiCompanyName,
                dimension: "network_group",
                streaming_type: "streaming",
              },
            });
            let streamingV2Result = await awaitJSON(streamingV2Res);
            return streamingV2Result;
          })(),
          (async () => {
            let audioV2Res = await StreamingV2LambdaFetch("/delivery_v2", {
              params: {
                start: TWO_WEEKS_AGO_START_DATE,
                end: YESTERDAY,
                company: kpiCompanyName,
                dimension: "network_group",
                streaming_type: "audio",
              },
            });
            let audioV2Result = await awaitJSON(audioV2Res);
            return audioV2Result;
          })(),
          (async () => {
            let streamingRes = await AdminLambdaFetch("/pacing", {
              params: {
                company: kpiCompanyName,
                start: SUMMARY_START_DATE,
                end: SUMMARY_END_DATE,
                groupByDay: 1,
              },
            });
            let streamingResult = await awaitJSON(streamingRes);
            return streamingResult;
          })(),
          (async () => {
            let linearRes = await LinearLambdaFetch("/estimates", {
              params: { company: kpiCompanyName, week: SUMMARY_START_DATE },
            });
            let linearResult = await awaitJSON(linearRes);
            return linearResult;
          })(),
          (async () => {
            let additionalRes = await MiscLambdaFetch("/additional_channel_summaries", {
              params: {
                company: kpiCompanyName,
                start: SUMMARY_START_DATE,
                end: SUMMARY_END_DATE,
              },
            });
            let additionalResult = await awaitJSON(additionalRes);
            return additionalResult;
          })(),
        ]);

        let streamingAndAudioSpendByDayMap = {};

        for (let row of streamingDeliveryData) {
          const { date, cost } = row;
          streamingAndAudioSpendByDayMap[date] = {
            ...streamingAndAudioSpendByDayMap[date],
            streaming: (R.path([date, "streaming"], streamingAndAudioSpendByDayMap) || 0) + cost,
          };
        }

        for (let row of audioDeliveryData) {
          const { date, cost } = row;
          streamingAndAudioSpendByDayMap[date] = {
            ...streamingAndAudioSpendByDayMap[date],
            audio: (R.path([date, "audio"], streamingAndAudioSpendByDayMap) || 0) + cost,
          };
        }

        for (let dataSource of additionalData) {
          for (let row of dataSource.rows) {
            const { date, cost } = row;
            streamingAndAudioSpendByDayMap[date] = {
              ...streamingAndAudioSpendByDayMap[date],
              [dataSource.type]:
                (R.path([date, dataSource.type], streamingAndAudioSpendByDayMap) || 0) + cost,
            };
          }
        }

        if (bail) {
          return;
        }

        let linearMap = R.pipe(
          R.prop("weekSummary"),
          R.groupBy(R.prop("day")),
          R.map(R.prop(0))
        )(linearData);

        // Two Weeks Ago Summary (Only used to calculate week-over-week percent change for last week)
        let twoWeeksAgoSummary = Dfns.eachDayOfInterval({
          start: Dfns.parseISO(TWO_WEEKS_AGO_START_DATE),
          end: Dfns.parseISO(TWO_WEEKS_AGO_END_DATE),
        }).map(dateRaw => {
          let date = Dfns.format(DATE_FORMAT, dateRaw);
          let streaming =
            Math.round(R.path([date, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
          let audio = Math.round(R.path([date, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          let ooh = Math.round(R.path([date, "ooh"], streamingAndAudioSpendByDayMap)) || 0;

          let linearSpend;
          if (!R.prop(date, linearMap)) {
            linearSpend = 0;
          } else {
            linearSpend =
              linearMap[date].actual + linearMap[date].estimated + linearMap[date].electronic;
          }

          return {
            date,
            streaming,
            audio,
            linear: Math.round(linearSpend) || 0,
            ooh,
          };
        });

        // Last Week Summary
        let lastWeekSummary = Dfns.eachDayOfInterval({
          start: Dfns.parseISO(LAST_WEEK_START_DATE),
          end: Dfns.parseISO(LAST_WEEK_END_DATE),
        }).map(dateRaw => {
          let date = Dfns.format(DATE_FORMAT, dateRaw);
          let prettyDate = Dfns.format(PRETTY_DATE_FORMAT, dateRaw);
          let lastWeek = R.pipe(Dfns.subDays(7), Dfns.format(DATE_FORMAT))(dateRaw);
          let lastWeekStreamingSpend =
            Math.round(R.path([lastWeek, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
          let lastWeekAudioSpend =
            Math.round(R.path([lastWeek, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          let lastWeekOohSpend =
            Math.round(R.path([lastWeek, "ooh"], streamingAndAudioSpendByDayMap)) || 0;
          let lastWeekLinearSpend;
          if (!R.prop(lastWeek, linearMap)) {
            lastWeekLinearSpend = 0;
          } else {
            lastWeekLinearSpend =
              linearMap[lastWeek].actual +
              linearMap[lastWeek].estimated +
              linearMap[lastWeek].electronic;
          }

          let streaming =
            Math.round(R.path([date, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
          let audio = Math.round(R.path([date, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          let ooh = Math.round(R.path([date, "ooh"], streamingAndAudioSpendByDayMap)) || 0;
          let linear, linearChange;
          if (!R.prop(date, linearMap)) {
            linear = 0;
            linearChange = 0;
          } else {
            linear = Math.round(
              linearMap[date].actual + linearMap[date].estimated + linearMap[date].electronic
            );
            if (isLinearProcessing(date, linear, linearMap[date].booked) === PROCESSING) {
              linear = PROCESSING;
              linearChange = PROCESSING;
            } else {
              linearChange = Math.round(
                ((linear - lastWeekLinearSpend) / lastWeekLinearSpend) * 100
              );

              if (linearChange === Infinity) {
                linearChange = 100;
              }
              if (isNaN(linearChange)) {
                linearChange = 0;
              }
            }
          }

          let streamingChange = calculateChange(streaming, lastWeekStreamingSpend);
          let audioChange = calculateChange(audio, lastWeekAudioSpend);
          let oohChange = calculateChange(ooh, lastWeekOohSpend);

          return {
            prettyDate,
            streaming,
            streamingChange,
            audio,
            audioChange,
            linear,
            linearChange,
            ooh,
            oohChange,
          };
        });

        // Current Week Summary
        let currentWeekSummary = Dfns.eachDayOfInterval({
          start: Dfns.parseISO(CURRENT_WEEK_START_DATE),
          end: Dfns.parseISO(CURRENT_WEEK_END_DATE),
        }).map(dateRaw => {
          let date = Dfns.format(DATE_FORMAT, dateRaw);
          let prettyDate = Dfns.format(PRETTY_DATE_FORMAT, dateRaw);
          let lastWeek = R.pipe(Dfns.subDays(7), Dfns.format(DATE_FORMAT))(dateRaw);
          let lastWeekStreamingSpend =
            Math.round(R.path([lastWeek, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
          let lastWeekAudioSpend =
            Math.round(R.path([lastWeek, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          let lastWeekOohSpend =
            Math.round(R.path([lastWeek, "ooh"], streamingAndAudioSpendByDayMap)) || 0;

          let lastWeekLinearSpend;
          if (!R.prop(lastWeek, linearMap)) {
            lastWeekLinearSpend = 0;
          } else {
            lastWeekLinearSpend =
              linearMap[lastWeek].actual +
              linearMap[lastWeek].estimated +
              linearMap[lastWeek].electronic;
          }

          let linear, linearChange;
          if (!R.prop(date, linearMap)) {
            linear = 0;
            linearChange = 0;
          } else {
            linear =
              linearMap[date].actual + linearMap[date].estimated + linearMap[date].electronic;
            if (isLinearProcessing(date, linear, linearMap[date].booked) === PROCESSING) {
              linear = PROCESSING;
              linearChange = PROCESSING;
            } else {
              linearChange = Math.round(
                ((linear - lastWeekLinearSpend) / lastWeekLinearSpend) * 100
              );

              if (linearChange === Infinity) {
                linearChange = 100;
              }
              if (isNaN(linearChange)) {
                linearChange = 0;
              }
            }
          }

          let streaming = 0;
          let audio = 0;

          if (date < TODAY) {
            // use delivery
            streaming =
              Math.round(R.path([date, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
            audio = Math.round(R.path([date, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          } else {
            // use pacing
            streaming = Math.round(R.path(["streaming", date, "spend"], streamingPacingData)) || 0;
            audio = Math.round(R.path(["audio", date, "spend"], streamingPacingData)) || 0;
          }
          let ooh = Math.round(R.path([date, "ooh"], streamingAndAudioSpendByDayMap)) || 0;

          let streamingChange = calculateChange(streaming, lastWeekStreamingSpend);
          let audioChange = calculateChange(audio, lastWeekAudioSpend);
          let oohChange = calculateChange(ooh, lastWeekOohSpend);

          return {
            prettyDate,
            streaming,
            streamingChange,
            audio,
            audioChange,
            linear,
            linearChange,
            ooh,
            oohChange,
          };
        });

        let fullDateRangeSummary = Dfns.eachDayOfInterval({
          start: Dfns.parseISO(SUMMARY_START_DATE),
          end: Dfns.parseISO(SUMMARY_END_DATE),
        }).map(dateRaw => {
          let date = Dfns.format(DATE_FORMAT, dateRaw);

          let streaming = 0;
          let audio = 0;

          if (date < TODAY) {
            // use delivery
            streaming =
              Math.round(R.path([date, "streaming"], streamingAndAudioSpendByDayMap)) || 0;
            audio = Math.round(R.path([date, "audio"], streamingAndAudioSpendByDayMap)) || 0;
          } else {
            // use pacing
            streaming = Math.round(R.path(["streaming", date, "spend"], streamingPacingData)) || 0;
            audio = Math.round(R.path(["audio", date, "spend"], streamingPacingData)) || 0;
          }
          let ooh = Math.round(R.path([date, "ooh"], streamingAndAudioSpendByDayMap)) || 0;

          let linear;
          if (!R.prop(date, linearMap)) {
            linear = 0;
          } else {
            linear =
              linearMap[date].actual + linearMap[date].estimated + linearMap[date].electronic;
            if (isLinearProcessing(date, linear, linearMap[date].booked) === PROCESSING) {
              linear = PROCESSING;
            }
          }

          return {
            date,
            streaming,
            audio,
            tv: linear,
            ooh,
          };
        });

        setSummaryTableMap(current => ({
          ...current,
          [kpiCompanyName]: {
            lastWeekSummary,
            currentWeekSummary,
            twoWeeksAgoSummary,
            fullDateRangeSummary,
          },
        }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
    return () => {
      bail = true;
    };
  }, [summaryTableMap, setError, kpiCompanyName]);

  // Fetch Charts Data
  useEffect(() => {
    if (data) {
      return;
    }
    let bail = false;
    (async () => {
      try {
        const dateRangeToUse = overrideDateRange(company, kpiChartDates);
        let res = await ToolsLambdaFetch("/homepage", {
          params: {
            kpi: kpi,
            start: dateRangeToUse.start,
            end: dateRangeToUse.end,
            cid: companyInfo.cid,
          },
        });
        let data = await awaitJSON(res);
        if (bail) {
          return;
        }
        setChartDataMap(current => ({ ...current, [kpiChartKey]: data }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
    return () => {
      bail = true;
    };
  }, [
    data,
    setError,
    kpi,
    companyInfo.cid,
    kpiChartDates.start,
    kpiChartDates.end,
    kpiChartKey,
    company,
    kpiChartDates,
  ]);

  // Defines X-axis ticks for charts
  const ticks = useCallback(
    (chart, dateName) => {
      if (!data) {
        return [];
      }
      if (!data[chart].length) {
        return [];
      }

      let min = data[chart][0][dateName];
      let max = data[chart][data[chart].length - 1][dateName];

      return [min, max];
    },
    [data]
  );

  const totalsRenderer = ({ data, style = {}, classes = [] }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  // Totals row on Summary Table
  const finalRow = useMemo(() => {
    if (!summaryData) {
      return;
    }

    let linearSpendTotal = weeklySpendTotal(currentWeek, summaryData, "linear");
    let streamingSpendTotal = weeklySpendTotal(currentWeek, summaryData, "streaming");
    let audioSpendTotal = weeklySpendTotal(currentWeek, summaryData, "audio");
    let oohSpendTotal = weeklySpendTotal(currentWeek, summaryData, "ooh");

    let linearChangeTotal = weekOverWeekChange(currentWeek, summaryData, "linear", "overview");
    let streamingChangeTotal = weekOverWeekChange(
      currentWeek,
      summaryData,
      "streaming",
      "overview"
    );
    let audioChangeTotal = weekOverWeekChange(currentWeek, summaryData, "audio", "overview");
    let oohChangeTotal = weekOverWeekChange(currentWeek, summaryData, "ooh", "overview");

    let finalRow = {
      prettyDate: "Summary",
      linear:
        linearSpendTotal === PROCESSING ? (
          PROCESSING
        ) : (
          <NumberFormatter value={linearSpendTotal} type={"$"} />
        ),
      linearChange: linearSpendTotal === PROCESSING ? "-" : linearChangeTotal,
      streaming: <NumberFormatter value={streamingSpendTotal} type={"$"} />,
      streamingChange: streamingChangeTotal,
      audio: <NumberFormatter value={audioSpendTotal} type={"$"} />,
      audioChange: audioChangeTotal,
      ooh: <NumberFormatter value={oohSpendTotal} type={"$"} />,
      oohChange: oohChangeTotal,
    };
    return finalRow;
  }, [currentWeek, summaryData]);

  // Spend Total for Overview List Item
  const overviewInfo = useMemo(() => {
    if (!summaryData) {
      return;
    }
    const obj = {
      count: 0,
    };

    for (let type of ["linear", "streaming", "audio"]) {
      let total = weeklySpendTotal(currentWeek, summaryData, type);

      if (total !== "0") {
        obj[type] = total;
        obj.count++;
      }
    }

    return obj;
  }, [currentWeek, summaryData]);

  const hasOoh = useMemo(() => {
    if (summaryData) {
      const oohSum = R.sum(
        R.map(row => row.ooh, summaryData[currentWeek ? "currentWeekSummary" : "lastWeekSummary"])
      );
      if (oohSum > 0) {
        return true;
      }
    }
    return false;
  }, [currentWeek, summaryData]);

  const superHeaders = useMemo(() => {
    let additionalHeaders = [];
    if (hasOoh) {
      additionalHeaders = R.concat(additionalHeaders, [{ span: 2, data: "OOH" }]);
    }

    return R.concat(SUPER_HEADERS, additionalHeaders);
  }, [hasOoh]);

  const headers = useMemo(() => {
    let additionalHeaders = [];
    if (hasOoh) {
      additionalHeaders = R.concat(additionalHeaders, [
        {
          label: "Spend",
          name: "ooh",
          flex: 1,
          nonInteractive: true,
          renderer: data => <NumberFormatter value={data.ooh} type={"$"} />,
        },
        {
          label: "Change",
          name: "oohChange",
          flex: 1,
          nonInteractive: true,
          renderer: data => (data.audioChange === 0 ? "–" : `${data.oohChange.toLocaleString()}%`),
        },
      ]);
    }

    return R.concat(
      [
        {
          label: "Date",
          name: "prettyDate",
          width: 160,
          nonInteractive: true,
          renderer: data => <span className="summaryDate">{data.prettyDate}</span>,
        },
        {
          label: "Spend",
          name: "linear",
          flex: 1,
          nonInteractive: true,
          renderer: data =>
            data.linear === PROCESSING ? (
              data.linear
            ) : (
              <NumberFormatter value={data.linear} type={"$"} />
            ),
        },
        {
          label: "Change",
          name: "linearChange",
          flex: 1,
          nonInteractive: true,
          renderer: data =>
            data.linearChange === 0 || data.linear === PROCESSING
              ? "–"
              : `${data.linearChange.toLocaleString()}%`,
        },
        {
          label: "Spend",
          name: "streaming",
          flex: 1,
          nonInteractive: true,
          renderer: data => <NumberFormatter value={data.streaming} type={"$"} />,
        },
        {
          label: "Change",
          name: "streamingChange",
          flex: 1,
          nonInteractive: true,
          renderer: data =>
            data.streamingChange === 0 ? "–" : `${data.streamingChange.toLocaleString()}%`,
        },
        {
          label: "Spend",
          name: "audio",
          flex: 1,
          nonInteractive: true,
          renderer: data => <NumberFormatter value={data.audio} type={"$"} />,
        },
        {
          label: "Change",
          name: "audioChange",
          flex: 1,
          nonInteractive: true,
          renderer: data =>
            data.audioChange === 0 ? "–" : `${data.audioChange.toLocaleString()}%`,
        },
      ],
      additionalHeaders
    );
  }, [hasOoh]);

  // Construct Overview Table
  let overviewContent = [];

  let mediaTypeDescriptions = {
    tv: { title: "Linear TV", name: "linear", icon: <LinearTvIcon /> },
    streaming: { title: "Streaming Video", name: "streaming", icon: <StreamingVideoIcon /> },
    audio: { title: "Streaming Audio", name: "audio", icon: <StreamingAudioIcon /> },
  };

  for (let type of ["tv", "streaming", "audio"]) {
    if (!data || !summaryData) {
      break;
    }

    if (fullDateRangeSpendTotal(summaryData.fullDateRangeSummary, type) > 0) {
      overviewContent.push(
        <ListGroup.Item className="overviewListItem" key={type}>
          <div className="mediaIcon">{mediaTypeDescriptions[type].icon}</div>
          <div className="mediaDescription">
            <div className="mediaName">{mediaTypeDescriptions[type].title}</div>
            <div className="mediaValues">
              <div className="mediaTotal">
                <NumberFormatter
                  value={overviewInfo[mediaTypeDescriptions[type].name] || 0}
                  type={"$"}
                />
              </div>
              {weekOverWeekChange(currentWeek, summaryData, mediaTypeDescriptions[type].name)}
            </div>
          </div>
        </ListGroup.Item>
      );
    }
  }

  const MarketingPortfolioTooltip = React.memo(({ label, payload }) => {
    let date = useMemo(() => {
      if (label) {
        return R.pipe(Dfns.parseISO, Dfns.format(TOOLTIP_LABEL_DATE))(label);
      }
      return null;
    }, [label]);

    let total = useMemo(() => {
      if (payload) {
        return Math.round(
          R.reduce(
            R.add,
            0,
            R.map(item => item.value, payload)
          )
        ).toLocaleString();
      }
      return null;
    }, [payload]);

    return (
      <div className="marketingPortfolioTooltip">
        <div className="date">{date}</div>
        <div className="total">
          <span className="totalName">Total: </span>
          <span className="totalNumber">{total}</span>
        </div>
        <div className="portfolioList">
          {payload.map((item, i) => (
            <div className="portfolioRow" key={item.dataKey}>
              <div className="circle" style={{ backgroundColor: getSeriesColor(i) }} />
              <div className="name">{`${item.dataKey}: `}</div>
              <div className="value">{Math.round(item.value).toLocaleString()}</div>
            </div>
          ))}
        </div>
      </div>
    );
  });

  let dailyResponsesGradientRanges = [];
  let liftModelGradientRanges = [];

  if (data && R.isEmpty(data.liveMediaDates)) {
    dailyResponsesGradientRanges.push({ start: 0, end: 100, live: 0 });
    liftModelGradientRanges.push({ start: 0, end: 100, live: 0 });
  }

  if (data && !R.isEmpty(data.liveMediaDates)) {
    //handle the case where liveMediaDates goes back further than the KPI chart
    const minDailyResponse = (data.dailyResponses[0] || {}).Timeslot;
    data.liveMediaDates = R.filter(row => row.date >= minDailyResponse, data.liveMediaDates);

    let globalCount = 0;
    let thisCount = 0;
    let live = (data.liveMediaDates[0] || {}).combined;
    let arrayLength = data.liveMediaDates.length;
    for (let date of data.liveMediaDates) {
      if (date.combined === live) {
        thisCount++;
      } else {
        dailyResponsesGradientRanges.push({
          start: (globalCount / arrayLength) * 100,
          end: ((globalCount + thisCount) / arrayLength) * 100,
          live,
        });
        live = date.combined;
        globalCount += thisCount;
        thisCount = 1;
      }
    }
    dailyResponsesGradientRanges.push({
      start: (globalCount / arrayLength) * 100,
      end: ((globalCount + thisCount) / arrayLength) * 100,
      live: live,
    });

    if (data.liftFromTv && data.liftFromTv.length > 0) {
      let liftModelLiveMediaDates = R.filter(
        row => row.date >= data.liftFromTv[0].date,
        data.liveMediaDates
      );
      let globalCount = 0;
      let thisCount = 0;
      let live = (liftModelLiveMediaDates[0] || {}).tv;
      let arrayLength = data.liftFromTv.length;
      for (let date of liftModelLiveMediaDates) {
        if (date.tv === live) {
          thisCount++;
        } else {
          liftModelGradientRanges.push({
            start: (globalCount / arrayLength) * 100,
            end: ((globalCount + thisCount) / arrayLength) * 100,
            live,
          });
          live = date.tv;
          globalCount += thisCount;
          thisCount = 1;
        }
      }
      liftModelGradientRanges.push({
        start: (globalCount / arrayLength) * 100,
        end: ((globalCount + thisCount) / arrayLength) * 100,
        live: live,
      });
    } else {
      liftModelGradientRanges = dailyResponsesGradientRanges;
    }
  }

  let marketingPortfolioGradientRanges = [];
  if (data && !R.isEmpty(data.marketingPortfolio)) {
    let globalCount = 0;
    let thisCount = 0;
    let active = data.marketingPortfolio[0].Interpolated;
    let arrayLength = data.marketingPortfolio.length;
    for (let date of data.marketingPortfolio) {
      if (date.Interpolated === active) {
        thisCount++;
      } else {
        marketingPortfolioGradientRanges.push({
          start: (globalCount / arrayLength) * 100,
          end: ((globalCount + thisCount) / arrayLength) * 100,
          active,
        });
        active = date.Interpolated;
        globalCount += thisCount;
        thisCount = 1;
      }
    }
    marketingPortfolioGradientRanges.push({
      start: (globalCount / arrayLength) * 100,
      end: ((globalCount + thisCount) / arrayLength) * 100,
      active,
    });
  }

  const LiftChartLegend = useCallback(
    props => {
      if (data) {
        let { payload } = props.props;
        return (
          <div className="liftChartLegend">
            <div className="withTv">
              <div className="circle" style={{ backgroundColor: LINE_STROKE_PRIMARY }} />
              <div className="label">{payload[0].dataKey}</div>
            </div>
            <div className="noTv">
              <div className="circle" style={{ backgroundColor: LINE_STROKE_SECONDARY }} />
              <div className="label">{payload[1].dataKey}</div>
            </div>
          </div>
        );
      }
    },
    [data]
  );

  const dailyResponsesChart = data && data.dailyResponses && data.dailyResponses.length > 0 && (
    <div className="homeChartContainer">
      <div className="chartHeader">
        <div className="title">
          <span className="dailyResponseKpiTitle">{`Daily ${
            (data.kpiChartTitles[0] || {}).ChartDailyKPITitle
          }`}</span>
          <span className="dailyResponseTimezone">
            {`(${(data.kpiChartTitles[0] || {}).KPITimezoneDisplay || "Pacific"})`}
          </span>
          <span className="dailyResponseSubTitle">
            {(data.kpiChartTitles[0] || {}).ChartDailyKPISubtitle}
          </span>
        </div>
        <DropdownButton
          size="sm"
          variant="light"
          title={<MdMoreVert className="dropdownIcon" />}
          className="downloadButton"
        >
          <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "kpi_calendar_daily")}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() => download(pngUri("responses_chart"), `${kpi}_responses_chart`)}
          >
            PNG
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="chartData" id="responses_chart">
        <AutoSizer>
          {({ width, height }) => (
            <AreaChart
              width={width}
              height={height}
              data={data.dailyResponses}
              margin={CHART_MARGIN_NO_LEGEND}
            >
              <defs>
                <linearGradient id="responseChartFillGradient" x1="0%" x2="100%" y1="0%" y2="0%">
                  {dailyResponsesGradientRanges.map(range => {
                    const { start, end, live } = range;
                    let opacity = live ? 0.5 : 0.3;

                    return [
                      <stop
                        key={`${start}_${end}_start`}
                        offset={`${start}%`}
                        stopColor={AREA_FILL}
                        stopOpacity={opacity}
                      />,
                      <stop
                        key={`${start}_${end}_end`}
                        offset={`${end}%`}
                        stopColor={AREA_FILL}
                        stopOpacity={opacity}
                      />,
                    ];
                  })}
                </linearGradient>
                <linearGradient id="responseChartStrokeGradient" x1="0%" x2="100%" y1="0%" y2="0%">
                  {dailyResponsesGradientRanges.map(range => {
                    const { start, end, live } = range;
                    let opacity = live ? 1 : 0;
                    return [
                      <stop
                        key={`${start}_${end}_start`}
                        offset={`${start}%`}
                        stopColor={LINE_STROKE_PRIMARY}
                        stopOpacity={opacity}
                      />,
                      <stop
                        key={`${start}_${end}_end`}
                        offset={`${end}%`}
                        stopColor={LINE_STROKE_PRIMARY}
                        stopOpacity={opacity}
                      />,
                    ];
                  })}
                </linearGradient>
              </defs>
              <CartesianGrid
                vertical={false}
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
              />
              <Area
                type="monotone"
                dataKey="responses"
                strokeWidth={LINE_STROKE_WIDTH}
                stroke="url(#responseChartStrokeGradient)"
                fill="url(#responseChartFillGradient)"
                isAnimationActive={false}
              />
              <XAxis
                dataKey="Timeslot"
                domain={X_AXIS_DOMAIN}
                padding={X_AXIS_PADDING}
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
                tickFormatter={time => Dfns.format("LLL", new Date(time))}
                ticks={ticks("dailyResponses", "Timeslot")}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
                tickFormatter={number => abbreviateNumber(number)}
              />
              <Tooltip
                isAnimationActive={false}
                labelFormatter={time =>
                  R.pipe(Dfns.parseISO, Dfns.format(TOOLTIP_LABEL_DATE))(time)
                }
                formatter={value => {
                  let formattedValue = Math.round(value).toLocaleString();

                  let title = data.kpiChartTitles[0].ChartDailyKPITitle;

                  return [formattedValue, title];
                }}
              />
            </AreaChart>
          )}
        </AutoSizer>
      </div>
    </div>
  );

  const liftFromTvChart = data &&
    data.liftFromTv.length > 0 &&
    R.includes("tv", companyInfo.media_types) && (
      <div className="homeChartContainer">
        <div className="chartHeader">
          <div className="title">Lift From Linear TV</div>
          <InfoTooltip size="reg">
            {`This chart shows the lift in daily ${data.kpiChartTitles[0].ChartDailyKPITitle} driven by TV.
              The series 'Predicted ${data.kpiChartTitles[0].ChartDailyKPITitle} - no TV'
              is a modeled response baseline representing what would be likely to happen if TV were not running.
              The difference between this series and actual daily ${data.kpiChartTitles[0].ChartDailyKPITitle}
              is the lift attributable to TV.`}
          </InfoTooltip>
          <DropdownButton
            size="sm"
            variant="light"
            title={<MdMoreVert className="dropdownIcon" />}
            className="downloadButton"
          >
            <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "liftModel_actualVsPredicted")}>
              CSV
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => download(pngUri("lift_chart"), `${kpi}_lift_chart`)}>
              PNG
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="chartData" id="lift_chart">
          <AutoSizer>
            {({ width, height }) => (
              <LineChart
                data={data.liftFromTv}
                width={width}
                height={height}
                margin={CHART_MARGIN_WITH_LEGEND}
              >
                <defs>
                  <linearGradient id="liftChartStrokeGradient1" x1="0%" x2="100%" y1="0%" y2="0%">
                    {liftModelGradientRanges.map(range => {
                      const { start, end, live } = range;
                      let opacity = live ? 1 : 0.3;

                      return [
                        <stop
                          key={`${start}_${end}_start`}
                          offset={`${start}%`}
                          stopColor={LINE_STROKE_PRIMARY}
                          stopOpacity={opacity}
                        />,
                        <stop
                          key={`${start}_${end}_end`}
                          offset={`${end}%`}
                          stopColor={LINE_STROKE_PRIMARY}
                          stopOpacity={opacity}
                        />,
                      ];
                    })}
                  </linearGradient>
                  <linearGradient id="liftChartStrokeGradient2" x1="0%" x2="100%" y1="0%" y2="0%">
                    {liftModelGradientRanges.map(range => {
                      const { start, end, live } = range;
                      let opacity = live ? 1 : 0.3;
                      return [
                        <stop
                          key={`${start}_${end}_start`}
                          offset={`${start}%`}
                          stopColor={LINE_STROKE_SECONDARY}
                          stopOpacity={opacity}
                        />,
                        <stop
                          key={`${start}_${end}_end`}
                          offset={`${end}%`}
                          stopColor={LINE_STROKE_SECONDARY}
                          stopOpacity={opacity}
                        />,
                      ];
                    })}
                  </linearGradient>
                </defs>
                <CartesianGrid
                  vertical={false}
                  stroke={CARTESIAN_GRID_STROKE}
                  strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="right"
                  wrapperStyle={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                  content={props => <LiftChartLegend props={props} />}
                />
                <Line
                  dataKey={data.liftFromTvKeys[0]}
                  type="monotone"
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  isAnimationActive={false}
                  stroke="url(#liftChartStrokeGradient1)"
                />
                <Line
                  dataKey={data.liftFromTvKeys[1]}
                  type="monotone"
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  isAnimationActive={false}
                  stroke="url(#liftChartStrokeGradient2)"
                />
                <XAxis
                  dataKey="date"
                  domain={X_AXIS_DOMAIN}
                  padding={X_AXIS_PADDING}
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  tickFormatter={time => Dfns.format("LLL", new Date(time))}
                  ticks={ticks("liftFromTv", "date")}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  tickFormatter={number => abbreviateNumber(number)}
                />
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={time =>
                    R.pipe(Dfns.parseISO, Dfns.format(TOOLTIP_LABEL_DATE))(time)
                  }
                  formatter={value => {
                    let formattedValue = Math.round(value).toLocaleString();
                    return formattedValue;
                  }}
                />
              </LineChart>
            )}
          </AutoSizer>
        </div>
      </div>
    );

  const marketingPortfolioChart = data &&
    data.marketingPortfolio &&
    data.marketingPortfolio.length > 0 && (
      <div className="homeChartContainer">
        <div className="chartHeader">
          <div className="title">Marketing Portfolio</div>
          <InfoTooltip size="reg">
            {
              "Channels that are not italicized in the legend are expressed in dollar terms; those that are italicized are expressed in hundreds of impressions."
            }
          </InfoTooltip>
          <DropdownButton
            size="sm"
            variant="light"
            title={<MdMoreVert className="dropdownIcon" />}
            className="downloadButton"
          >
            <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "channel_spend")}>
              CSV
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() =>
                download(pngUri("marketing_portfolio_chart"), `${kpi}_marketing_portfolio_chart`)
              }
            >
              PNG
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="chartData" id="marketing_portfolio_chart">
          <AutoSizer>
            {({ width, height }) => (
              <AreaChart
                width={width}
                height={height}
                data={data.marketingPortfolio}
                margin={CHART_MARGIN_WITH_LEGEND}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={CARTESIAN_GRID_STROKE}
                  strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                />
                <defs>
                  {data.marketingPortfolioKeys.map((channel, i) => {
                    let cleanChannel = channel.replace(/[^a-zA-Z]/g, "");
                    return (
                      <React.Fragment key={cleanChannel}>
                        <linearGradient
                          id={`marketingPortfolioFillGradient_${cleanChannel}`}
                          x1="0%"
                          x2="100%"
                          y1="0%"
                          y2="0%"
                        >
                          {marketingPortfolioGradientRanges.map(range => {
                            const { start, end, active } = range;
                            let opacity = active ? 0.5 : 1;
                            return [
                              <stop
                                key={`${start}_${end}_start`}
                                offset={`${start}%`}
                                stopColor={getSeriesColor(i)}
                                stopOpacity={opacity}
                              />,
                              <stop
                                key={`${start}_${end}_end`}
                                offset={`${end}%`}
                                stopColor={getSeriesColor(i)}
                                stopOpacity={opacity}
                              />,
                            ];
                          })}
                        </linearGradient>
                      </React.Fragment>
                    );
                  })}
                </defs>

                {data.marketingPortfolioKeys.map((channel, i) => {
                  let cleanChannel = channel.replace(/[^a-zA-Z]/g, "");
                  return (
                    <Area
                      type="monotone"
                      dataKey={channel}
                      stackId="1"
                      key={i}
                      activeDot={false}
                      fillOpacity={1}
                      strokeWidth={0}
                      fill={`url(#marketingPortfolioFillGradient_${cleanChannel})`}
                      isAnimationActive={false}
                    />
                  );
                })}

                <XAxis
                  dataKey="Date"
                  domain={X_AXIS_DOMAIN}
                  padding={X_AXIS_PADDING}
                  tickLine={false}
                  tick={TICK_STYLE}
                  axisLine={false}
                  tickFormatter={time => Dfns.format("LLL", new Date(time))}
                  ticks={ticks("marketingPortfolio", "Date")}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  tickFormatter={number => abbreviateNumber(number)}
                />
                <Tooltip
                  content={({ label, payload }) => (
                    <MarketingPortfolioTooltip label={label} payload={payload} />
                  )}
                  isAnimationActive={false}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  iconSize={10}
                  wrapperStyle={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                  payload={data.marketingPortfolioKeys.map((key, i) => {
                    return {
                      value: key,
                      type: "circle",
                      color: getSeriesColor(i),
                      formatter: () => (
                        <span
                          className={`marketingPortfolioStyle${data.liftModelColumnClassification[key]}`}
                        >
                          {key}
                        </span>
                      ),
                    };
                  })}
                />
              </AreaChart>
            )}
          </AutoSizer>
        </div>
      </div>
    );

  const volumeAndEfficiencyChart = data &&
    data.volumeAndEfficiency &&
    data.volumeAndEfficiency.length > 0 && (
      <div className="homeChartContainer">
        <div className="chartHeader">
          <div className="title">Volume and Efficiency</div>
          <InfoTooltip size="reg">
            {`This chart shows the two-week moving average values of ${data.volumeAndEfficiencyKeys[0]} and ${data.volumeAndEfficiencyKeys[1]}. For example, the data points on March 14th reflect the number of average daily Volume and Efficiency trends from March 1st - March 14th and the average ${data.volumeAndEfficiencyKeys[1]} over the same period. The goal of this chart is to smooth out the noise in the daily series and highlight the trends in these KPIs.`}
          </InfoTooltip>
          <DropdownButton
            size="sm"
            variant="light"
            title={<MdMoreVert className="dropdownIcon" />}
            className="downloadButton"
          >
            <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "trailingAveragesData")}>
              CSV
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() =>
                download(pngUri("volume_efficiency_chart"), `${kpi}_volume_efficiency_chart`)
              }
            >
              PNG
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="chartData" id="volume_efficiency_chart">
          <AutoSizer>
            {({ width, height }) => (
              <LineChart
                data={data.volumeAndEfficiency}
                width={width}
                height={height}
                margin={CHART_MARGIN_WITH_LEGEND}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={CARTESIAN_GRID_STROKE}
                  strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  align="right"
                  wrapperStyle={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                />
                <Line
                  dataKey={data.volumeAndEfficiencyKeys[0]}
                  type="monotone"
                  stroke={LINE_STROKE_PRIMARY}
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  yAxisId="left"
                  isAnimationActive={false}
                />
                <Line
                  dataKey={data.volumeAndEfficiencyKeys[1]}
                  type="monotone"
                  stroke={LINE_STROKE_SECONDARY}
                  strokeWidth={LINE_STROKE_WIDTH}
                  dot={false}
                  yAxisId="right"
                  isAnimationActive={false}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  yAxisId="left"
                  tickFormatter={number => abbreviateNumber(number)}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  tickFormatter={spend => `$${spend}`}
                  yAxisId="right"
                  orientation="right"
                />
                <XAxis
                  dataKey="Date"
                  domain={X_AXIS_DOMAIN}
                  padding={X_AXIS_PADDING}
                  tick={TICK_STYLE}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={time => Dfns.format("LLL", new Date(time))}
                  ticks={ticks("volumeAndEfficiency", "Date")}
                />
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={time =>
                    R.pipe(Dfns.parseISO, Dfns.format(TOOLTIP_LABEL_DATE))(time)
                  }
                  formatter={(value, key) => {
                    let formattedValue =
                      key.indexOf("CP") === 0
                        ? `$${value
                            .toFixed(data.kpiChartTitles[0].CostPerDecimalPlaces || 0)
                            .toLocaleString()}`
                        : Math.round(value).toLocaleString();
                    return formattedValue;
                  }}
                />
              </LineChart>
            )}
          </AutoSizer>
        </div>
      </div>
    );

  const conversionChart = data && data.conversionChart && data.conversionChart.length > 0 && (
    <div className="homeChartContainer">
      <div className="chartHeader">
        <div className="title">Conversion Metrics</div>
        <InfoTooltip size="reg">
          {
            "Conversion rates between key funnel steps for weekly cohorts. Cohorts are based on top-of-funnel acquisition time, thus for lengthier conversion funnels more recent observations may not be fully mature."
          }
        </InfoTooltip>
        <DropdownButton
          size="sm"
          variant="light"
          title={<MdMoreVert className="dropdownIcon" />}
          className="downloadButton"
        >
          <Dropdown.Item
            onSelect={() => {
              downloadJSONToCSV(data.conversionChart, `Conversion Funnel ${kpi.split("_")[0]}`);
            }}
          >
            CSV
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() =>
              download(pngUri("conversion_funnel_chart"), `${kpi}_conversion_funnel_chart`)
            }
          >
            PNG
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="chartData" id="conversion_funnel_chart">
        <AutoSizer>
          {({ width, height }) => (
            <BarChart
              data={data.conversionChart}
              width={width}
              height={height}
              margin={CHART_MARGIN_WITH_LEGEND}
            >
              <CartesianGrid
                vertical={false}
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
              />
              <Legend
                verticalAlign="top"
                height={36}
                iconType="circle"
                align="right"
                wrapperStyle={{
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              />
              <Tooltip
                isAnimationActive={false}
                labelFormatter={time =>
                  R.pipe(Dfns.parseISO, Dfns.format(TOOLTIP_LABEL_DATE))(time)
                }
                formatter={value => `${(Math.round(value * 1000) / 10).toFixed(1)}%`}
              />
              <Bar
                dataKey={data.conversionChartUpperLabel}
                fill={LINE_STROKE_PRIMARY}
                yAxisId="left"
                isAnimationActive={false}
              />
              {data.conversionChartUpperLabel !== data.conversionChartLowerLabel && (
                <Bar
                  dataKey={data.conversionChartLowerLabel}
                  fill={LINE_STROKE_SECONDARY}
                  yAxisId="right"
                  isAnimationActive={false}
                />
              )}
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
                yAxisId="left"
                tickFormatter={value => `${(value * 100).toFixed(1)}%`}
              />
              {data.conversionChartUpperLabel !== data.conversionChartLowerLabel && (
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tick={TICK_STYLE}
                  tickFormatter={value => `${(value * 100).toFixed(1)}%`}
                  yAxisId="right"
                  orientation="right"
                />
              )}
              <XAxis
                dataKey="week"
                domain={X_AXIS_DOMAIN}
                padding={X_AXIS_PADDING}
                tick={TICK_STYLE}
                tickLine={false}
                axisLine={false}
                tickFormatter={time => Dfns.format("LLL", Dfns.parseISO(time))}
                ticks={ticks("conversionChart", "week")}
              />
            </BarChart>
          )}
        </AutoSizer>
      </div>
    </div>
  );

  const seasonalityChart = data && data.seasonality && data.seasonality.length > 0 && (
    <div className="homeChartContainer">
      <div className="chartHeader">
        <div className="title">Seasonality</div>
        <InfoTooltip size="reg">
          {`Seasonality estimates represent the independent effect of a given month on acquisition
            volume, after accounting for other factors (such as media spend). Intuitively, this
            represents headwinds and tailwinds at various times of the year.`}
        </InfoTooltip>
        <DropdownButton
          size="sm"
          variant="light"
          title={<MdMoreVert className="dropdownIcon" />}
          className="downloadButton"
        >
          <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "liftModel_SeasonalityMonths")}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() => download(pngUri("seasonality_chart"), `${kpi}_seasonality_chart`)}
          >
            PNG
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="chartData" id="seasonality_chart">
        <AutoSizer>
          {({ width, height }) => (
            <BarChart
              data={data.seasonality}
              width={width}
              height={height}
              margin={CHART_MARGIN_NO_LEGEND}
            >
              <CartesianGrid
                vertical={false}
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
              />
              <Bar dataKey="value" minPointSize={8} fill="#009FDF" isAnimationActive={false}>
                {data.seasonality.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fillOpacity={entry.dummy ? 0.3 : 1} />;
                })}
              </Bar>
              <XAxis
                dataKey="month"
                tickCount={12}
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
              />
              <YAxis
                tickFormatter={value => {
                  value = value * 100;
                  let formattedValue = value.toLocaleString();
                  formattedValue = `${formattedValue}%`;

                  return formattedValue;
                }}
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
              />
              <Tooltip
                isAnimationActive={false}
                formatter={value => {
                  value = value * 100;
                  let formattedValue = value.toLocaleString();
                  formattedValue = `${formattedValue}%`;

                  return [formattedValue, "Change"];
                }}
                cursor={false}
                labelFormatter={month => SEASONALITY_LABEL_MAP[month]}
              />
            </BarChart>
          )}
        </AutoSizer>
      </div>
    </div>
  );

  const dayOfWeekChart = data && data.dayOfWeek && data.dayOfWeek.length > 0 && (
    <div className="homeChartContainer">
      <div className="chartHeader">
        <div className="title">Day of Week Effects</div>
        <InfoTooltip direction="left" size="reg">
          {`These estimates represent the independent effect of a given day of the week on
              acquisition volume, after accounting for other factors (such as media spend).
              Intuitively, this represents headwinds and tailwinds on various days of the week. This
              can be a useful rule of thumb for spend allocation throughout the week.`}
        </InfoTooltip>
        <DropdownButton
          size="sm"
          variant="light"
          title={<MdMoreVert className="dropdownIcon" />}
          className="downloadButton"
        >
          <Dropdown.Item onSelect={() => downloadChartCSV(kpi, "liftModel_SeasonalityDow")}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() => download(pngUri("day_of_week_chart"), `${kpi}_day_of_week_chart`)}
          >
            PNG
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <div className="chartData" id="day_of_week_chart">
        <AutoSizer>
          {({ width, height }) => (
            <BarChart
              data={data.dayOfWeek}
              width={width}
              height={height}
              margin={CHART_MARGIN_NO_LEGEND}
            >
              <CartesianGrid
                vertical={false}
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
              />
              <Bar dataKey="value" fill="#009FDF" minPointSize={8} isAnimationActive={false} />
              <XAxis
                dataKey="day"
                tickCount={7}
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
              />
              <YAxis
                tickFormatter={value => {
                  value = value * 100;
                  let formattedValue = value.toLocaleString();
                  formattedValue = `${formattedValue}%`;

                  return formattedValue;
                }}
                tickLine={false}
                axisLine={false}
                tick={TICK_STYLE}
              />
              <Tooltip
                isAnimationActive={false}
                formatter={value => {
                  value = value * 100;
                  let formattedValue = value.toLocaleString();
                  formattedValue = `${formattedValue}%`;

                  return [formattedValue, "Change"];
                }}
                cursor={false}
                labelFormatter={day => DAY_OF_WEEK_LABEL_MAP[day]}
              />
            </BarChart>
          )}
        </AutoSizer>
      </div>
    </div>
  );

  return (
    <Page
      title="Home"
      pageType="Home"
      actions={
        <div className="homePageActions">
          <KpiPickerLegacy
            onChange={newKpi => {
              setKpi(newKpi);
            }}
            defaultKpi={kpi}
          />
          <div className="rightAlignedActions">
            <DateRangePicker
              startDate={kpiChartDates.start}
              endDate={kpiChartDates.end}
              startDateId="kpiChartStartDate"
              endDateId="kpiChartEndDate"
              isOutsideRange={date => date > DATE_PICKER_CUTOFF}
              onChange={({ startDate, endDate }) =>
                setKpiChartDates({
                  start: startDate,
                  end: endDate,
                })
              }
            />
          </div>
        </div>
      }
    >
      {data && summaryData ? (
        <div className="homePageContainer">
          <div className="topRowContainer">
            <div className="overviewContainer">
              <div className="overviewHeader">Overview</div>
              <div className="overviewChart">
                <ListGroup className={`overviewListGroup count${overviewContent.length}`}>
                  {overviewContent}
                </ListGroup>
              </div>
            </div>
            <div className="summaryTableContainer">
              <div className="summaryTitle">
                <div className="title">Summary</div>
                <BPMToggleButton
                  size="sm"
                  options={[
                    {
                      key: "current",
                      label: "Current Week",
                    },
                    {
                      key: "previous",
                      label: "Previous Week",
                    },
                  ]}
                  selectedOption={currentWeek ? "current" : "previous"}
                  onChange={key => setCurrentWeek(key === "current")}
                />
                <DropdownButton
                  size="sm"
                  variant="light"
                  title={<MdMoreVert className="dropdownIcon" />}
                  className="downloadButton"
                >
                  <Dropdown.Item
                    onSelect={() => {
                      let week = currentWeek
                        ? summaryData.currentWeekSummary
                        : summaryData.lastWeekSummary;
                      downloadSummaryTableCSV(week);
                    }}
                  >
                    CSV
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      setShowSpendSummaryModal(true);
                    }}
                  >
                    Spend Summary
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      setShowPostlogsModal(true);
                    }}
                  >
                    Post Logs
                  </Dropdown.Item>
                  {isInternal && (
                    <Dropdown.Item
                      onSelect={() => {
                        setDownloadingKpiDebug(true);
                      }}
                    >
                      <span className="kpiDebugMenuItem">
                        KPI Debug {downloadingKpiDebug && <Spinner />}
                      </span>
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              </div>

              <div
                className="summaryTable"
                ref={summaryTableRef}
                style={{ marginRight: -1 * scrollBarSize.width }}
              >
                <BPMTable
                  data={currentWeek ? summaryData.currentWeekSummary : summaryData.lastWeekSummary}
                  totals={finalRow}
                  totalsRenderer={totalsRenderer}
                  filterBar={false}
                  rowHeight={35}
                  headerHeight={50}
                  superHeaders={superHeaders}
                  headers={headers}
                />
              </div>
            </div>
          </div>

          <div className="rowContainer">
            {dailyResponsesChart}
            {conversionChart ? conversionChart : liftFromTvChart}
          </div>

          <div className="rowContainer">
            {marketingPortfolioChart}
            {conversionChart ? liftFromTvChart : volumeAndEfficiencyChart}
          </div>

          <div className="rowContainer">
            {conversionChart && volumeAndEfficiencyChart}
            {seasonalityChart}
            {dayOfWeekChart}
          </div>

          {isInternal && (
            <div className="rowContainer">
              <div className="homeChartContainer">
                <div className="chartHeader">
                  <div className="title">Ingestion</div>
                </div>
                <div className="ingestionInfo">
                  <Table size="sm" className="ingestionTable">
                    <thead className="tableHead">
                      <tr>
                        <th className="record">Record</th>
                        <th className="start">Start</th>
                        <th className="end">End</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      <tr className="tableRow">
                        <td className="record">Real Post Logs</td>
                        <td className="start">
                          {!R.isNil(data.postLogsInfo[0].min)
                            ? R.pipe(
                                Dfns.parseISO,
                                Dfns.format("M/dd/yyyy h:mm:ss a")
                              )(data.postLogsInfo[0].min)
                            : "N/A"}
                        </td>
                        <td className="end">
                          {!R.isNil(data.postLogsInfo[0].max)
                            ? R.pipe(
                                Dfns.parseISO,
                                Dfns.format("M/dd/yyyy h:mm:ss a")
                              )(data.postLogsInfo[0].max)
                            : "N/A"}
                        </td>
                      </tr>
                      <tr className="tableRow">
                        <td className="record">KPI</td>
                        <td className="start">
                          {!R.isNil(data.kpiInfo.start)
                            ? R.pipe(Dfns.parseISO, Dfns.format("M/dd/yyyy"))(data.kpiInfo.start)
                            : "N/A"}
                        </td>
                        <td className="end">
                          {!R.isNil(data.kpiInfo.end)
                            ? R.pipe(Dfns.parseISO, Dfns.format("M/dd/yyyy"))(data.kpiInfo.end)
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              {data.microChart.length > 0 && (
                <div className="homeChartContainer">
                  <div className="chartHeader">
                    <div className="title">
                      <span className="microChartKpiTitle">{`Micro ${data.kpiChartTitles[0].ChartDailyKPITitle}`}</span>
                      <span className="microChartTimezone">
                        {`(${data.kpiChartTitles[0].KPITimezoneDisplay || "Pacific"})`}
                      </span>
                      <span className="microChartSubTitle">
                        {data.kpiChartTitles[0].ChartDailyKPISubtitle}
                      </span>
                    </div>
                    <DropdownButton
                      size="sm"
                      variant="light"
                      title={<MdMoreVert className="dropdownIcon" />}
                      className="downloadButton"
                    >
                      <Dropdown.Item
                        onSelect={() => downloadChartCSV(kpi, "latest_registrations_summary")}
                      >
                        CSV
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={() => download(pngUri("micro_chart"), `${kpi}_micro_chart`)}
                      >
                        PNG
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="chartData" id="micro_chart">
                    <AutoSizer>
                      {({ width, height }) => (
                        <LineChart
                          data={data.microChart}
                          width={width}
                          height={height}
                          margin={CHART_MARGIN_NO_LEGEND}
                        >
                          <CartesianGrid
                            vertical={false}
                            stroke={CARTESIAN_GRID_STROKE}
                            strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                          />
                          <Line
                            dataKey="responses"
                            type="monotone"
                            stroke={LINE_STROKE_PRIMARY}
                            strokeWidth={LINE_STROKE_WIDTH}
                            dot={false}
                            isAnimationActive={false}
                          />
                          <XAxis
                            dataKey="Timeslot"
                            domain={X_AXIS_DOMAIN}
                            padding={X_AXIS_PADDING}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={time => Dfns.format("MMM do", new Date(time))}
                            ticks={ticks("microChart", "Timeslot")}
                            tick={TICK_STYLE}
                          />
                          <YAxis tickLine={false} axisLine={false} tick={TICK_STYLE} />
                          <Tooltip
                            isAnimationActive={false}
                            labelFormatter={time =>
                              R.pipe(Dfns.parseISO, Dfns.format("M/dd/yyyy h:mm:ss a"))(time)
                            }
                            formatter={value => {
                              let formattedValue = Math.round(value).toLocaleString();
                              return [formattedValue, "Responses"];
                            }}
                          />
                        </LineChart>
                      )}
                    </AutoSizer>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
      {showPostlogsModal && (
        <Modal show onHide={() => setShowPostlogsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Export Post Logs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              startDate={postLogsDates.start}
              endDate={postLogsDates.end}
              startDateId="postLogsStartDate"
              endDateId="postLogsEndDate"
              onChange={({ startDate, endDate }) =>
                setPostLogsDates({
                  start: startDate,
                  end: endDate,
                })
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={downloadingPostlogs}
              onClick={() => exportPostlogs(postLogsDates.start, postLogsDates.end)}
            >
              {downloadingPostlogs ? <Spinner color="white" /> : "Export"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showSpendSummaryModal && (
        <Modal show onHide={() => setShowSpendSummaryModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Export Spend Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              startDate={spendSummaryDates.start}
              endDate={spendSummaryDates.end}
              startDateId="spendSummaryStartDate"
              endDateId="spendSummaryEndDate"
              onChange={({ startDate, endDate }) =>
                setSpendSummaryDates({
                  start: startDate,
                  end: endDate,
                })
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={downloadingSpendSummary}
              onClick={() => exportSpendSummary(spendSummaryDates.start, spendSummaryDates.end)}
            >
              {downloadingSpendSummary ? <Spinner color="white" /> : "Export"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Page>
  );
};

export default HomePage;
