import React from "react";
import { NumberFormatter } from "../../Components";
import "../Dashboard.scss";

const FormattedInnerCell = ({ data, type, decimals = 0 }) => {
  if (type && type.includes("$") && type !== "$") {
    decimals = parseInt(type.replace("$", ""));
    type = "$";
  }
  return Number.isFinite(data) && type && type.length ? (
    <NumberFormatter value={data} type={type} decimals={decimals} />
  ) : (
    <div className="dashboard-text-cell">{`${data || "-"}`}</div>
  );
};

export default FormattedInnerCell;
