import "./StateMap.scss";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import usData from "./states-albers-10m.json";
import { GeometryObject, Topology } from "topojson-specification";

export type StateMapProps = {
  data: { [key: string]: number | string }[];
  dataPoint: string;
  height?: number;
  resetValue: string;
  tooltipFormatter: (dataPoint: any, dataPoints: any, d: any) => string;
  width?: number;
  updateSelection: Dispatch<SetStateAction<string>>;
};

export const StateMap: React.FC<StateMapProps> = ({
  data,
  dataPoint,
  height = 610,
  resetValue,
  tooltipFormatter, // wrap in useCallback to prevent tooltip from rendering multiple times
  width = 960,
  updateSelection,
}) => {
  const ref = useRef(null);

  let selected = useRef("");

  useEffect(() => {
    const us = (usData as unknown) as Topology;
    const usObjStates = us.objects.states as GeometryObject;

    const dataPointArray: number[] = data.map(state => Number(state[dataPoint]));
    const dataPointMax = Math.max(...dataPointArray);
    const dataPointMin = Math.min(...dataPointArray);
    let dp = {};
    data.forEach(d => (dp[d.state] = d[dataPoint]));
    const svg = d3.select(ref.current);

    const removeAllChildNodes = (parent: HTMLElement | null) => {
      while (parent?.firstChild) {
        parent.removeChild(parent.firstChild);
      }
    };
    // remove previous map build
    removeAllChildNodes(document.querySelector(".state-map-svg"));

    const color = d3.scaleQuantize(
      [dataPointMin, dataPointMax],
      [
        "#EEE6F8",
        "#d3c1ef",
        "#B597E4",
        "#976bda",
        "#7F4AD3",
        "#6625ca",
        "#5C20C4",
        "#4d17bc",
        "#3D0FB5",
      ]
    );
    const topoData = topojson.feature(us, usObjStates);

    const path = d3.geoPath().projection(null);

    const zoomed = (event: any) => {
      const { transform } = event;

      svgPathWrapper.attr("transform", transform);
      svgPathWrapper.attr("stroke-width", 1 / transform.k);
    };
    const zoom = d3.zoom().scaleExtent([1, 8]).on("zoom", zoomed);

    const onClick = (event, stateLocationData) => {
      const [[x0, y0], [x1, y1]] = path.bounds(stateLocationData);

      event.stopPropagation();

      if (stateLocationData.properties.name === selected.current) {
        reset();
      } else {
        selected.current = stateLocationData.properties.name;
        updateSelection(selected.current);
        map.transition().style("fill", null);
        d3.select(this).transition().style("fill", "red");
        svg
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(width / 2, height / 2)
              .scale(Math.min(8, 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height)))
              .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            d3.pointer(event, svg.node())
          );
      }
    };

    const reset = () => {
      updateSelection(resetValue);
      selected.current = resetValue;
      map.transition().style("fill", null);
      svg
        .transition()
        .duration(750)
        .call(
          zoom.transform,
          d3.zoomIdentity,
          d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
        );
    };

    const svgPathWrapper = svg.append("g");

    svg.on("click", reset);

    svg.call(zoom);

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "state-tooltip")
      .style("opacity", 0);
    const tooltipHead = tooltip.append("div").attr("class", "state-tooltip-title");
    const tooltipStat = tooltip.append("div").attr("class", "state-tooltip-data");

    const mouseOver = function (this: any, event, d) {
      d3.selectAll(".state-path").transition().duration(200);
      d3.selectAll(".state")
        .transition()
        .duration(200)
        .style("stroke", "transparent")
        .style("cursor", "pointer")
        .style("opactiy", 0.5);

      d3.select(this)
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("stroke", "#7E7E7E")
        .attr("stroke-width", 2);
      tooltip
        .style("left", `${event.pageX + 15}px`)
        .style("top", `${event.pageY - 28}px`)
        .transition()
        .duration(400)
        .style("opacity", 1);
      tooltipHead.text(d.properties.name);

      tooltipStat.text(tooltipFormatter(dataPoint, dp, d));
    };

    const mouseLeave = function () {
      d3.selectAll(".state")
        .transition()
        .duration(200)
        .style("opacity", 1)
        .style("stroke", "transparent")
        .attr("fill", d => {
          return color(dp[d.properties.name]);
        });
      d3.selectAll(".state-path")
        .transition()
        .duration(200)
        .style("opacity", 0.5)
        .style("stroke", "#7E7E7E");
      tooltip.transition().duration(300).style("opacity", 0);
    };

    // states
    const map = svgPathWrapper
      .append("g")
      .attr("cursor", "pointer")
      .selectAll("path")
      // @ts-ignore
      .data(topoData.features)
      .join("path")
      .attr("d", path)
      .on("click", onClick)
      .attr("fill", d => {
        return color(dp[d.properties.name]);
      })
      .attr("class", "state")
      .on("mouseover", mouseOver)
      .on("mouseleave", mouseLeave);

    // state border outlines
    svgPathWrapper
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "#7E7E7E")
      .attr("stroke-linejoin", "round")
      .style("opacity", 0.5)
      .attr("class", "state-path")
      .attr("d", path(topojson.mesh(us, usObjStates as GeometryObject, (a, b) => a !== b)));
  }, [data, dataPoint, height, resetValue, tooltipFormatter, updateSelection, width]);

  return (
    <div
      className="state-map-wrapper"
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <svg
        className="state-map-svg"
        ref={ref}
        style={{ height: `${height}px`, width: `${width}px` }}
      />
    </div>
  );
};
