import { AxisDomain } from "recharts/types/util/types";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { formatMoney, formatMoneyAsInt, formatNumber } from "../../utils/format-utils";
import { useSetError } from "../../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import React from "react";

interface Label {
  value?: string;
  angle?: number;
  fill?: string;
  fontSize?: number;
  position?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "inside"
    | "outside"
    | "insideLeft"
    | "insideRight"
    | "insideTop"
    | "insideBottom"
    | "insideTopLeft"
    | "insideBottomLeft"
    | "insideTopRight"
    | "insideBottomRight"
    | "insideStart"
    | "insideEnd"
    | "end"
    | "center"
    | "centerTop"
    | "centerBottom"
    | "middle"
    | {
        x?: number;
        y?: number;
      };
  formatter?: (elem) => number | string | null | undefined;
}

interface BarChartProps {
  chartData: any[];
  chartMargins?: {};
  dataKeys?: string[];
  fill: string[];
  font?: string;
  height?: number;
  layout?: "horizontal" | "vertical" | undefined;
  isAnimationActive?: boolean;
  isSpend: boolean;
  label?: Label;
  width?: number;
  xAxisLabel?: string | Label;
  xAxisLine?: boolean;
  xAxisTickCount?: number;
  xAxisTickFontSize?: number;
  xAxisTickFormatter?: (value: any, index: number) => string;
  xAxisTicks?: string[] | number[];
  xDataKey?: string;
  xAxisType?: "number" | "category" | undefined;
  yAxisDomain?: string[] | number[];
  rightYAxisLabel?: string | Label;
  leftYAxisLabel?: string | Label;
  yAxisLine?: boolean;
  yAxisType?: "number" | "category" | undefined;
  yAxisTickCount?: number;
  yAxisTickFontSize?: number;
  yAxisTicks?: string[] | number[];
  useRightAxis?: boolean;
  ref: any;
}

export default React.memo<BarChartProps>(
  React.forwardRef(
    (
      {
        chartData,
        chartMargins = { top: 20, right: 30, left: 10 },
        dataKeys = ["count"],
        font,
        fill,
        height,
        isAnimationActive = true,
        isSpend,
        label,
        layout,
        width,
        xAxisLabel,
        xAxisLine = true,
        xAxisTickCount = 5,
        xAxisTickFontSize = 10,
        xAxisTickFormatter = (value, index) => {
          if (index % 2 === 0) {
            return Dfns.format("MM/dd/yyyy", new Date(value));
          } else {
            return "";
          }
        },
        xAxisTicks,
        xAxisType,
        xDataKey,
        yAxisType,
        yAxisDomain,
        rightYAxisLabel,
        leftYAxisLabel,
        yAxisLine = true,
        yAxisTickCount = 5,
        yAxisTickFontSize = 12,
        yAxisTicks,
        useRightAxis = false,
      },
      ref: any
    ) => {
      const setError = useSetError();
      if (dataKeys.length !== fill.length) {
        let error = new Error("Must have the same amount of bars types as fill colors");
        setError({
          message: `Error: ${error.message}`,
          reportError: error,
        });
      }

      const barChartToolTip = (
        <Tooltip
          isAnimationActive={false}
          formatter={(value: string) =>
            isSpend
              ? `${formatMoneyAsInt(parseFloat(value) / 1000)}k`
              : formatNumber(parseFloat(value))
          }
        />
      );
      const barChartXAxis = (
        <XAxis
          label={xAxisLabel}
          dataKey={xDataKey ? xDataKey : "date"}
          tickFormatter={xAxisTickFormatter}
          tick={{ fontSize: xAxisTickFontSize, fontFamily: font }}
          tickCount={xAxisTickCount}
          ticks={xAxisTicks}
          axisLine={xAxisLine}
          tickLine={xAxisLine}
          type={xAxisType}
        />
      );
      const leftBarChartYAxis = (
        <YAxis
          label={leftYAxisLabel}
          orientation="left"
          tickFormatter={
            yAxisType === "category"
              ? undefined
              : tick => {
                  if (isSpend) {
                    let base = tick >= 1000 ? "k" : "";
                    let tickValue = tick >= 1000 ? tick / 1000 : tick;
                    if (tick < 1 && tick !== 0) {
                      return `${formatMoney(tickValue)}${base}`;
                    } else {
                      return `${formatMoneyAsInt(tickValue)}${base}`;
                    }
                  } else {
                    return formatNumber(tick);
                  }
                }
          }
          tick={{ fontSize: yAxisTickFontSize, fontFamily: font }}
          tickCount={yAxisTickCount}
          ticks={yAxisTicks}
          axisLine={yAxisLine}
          tickLine={yAxisLine}
          domain={yAxisDomain as AxisDomain}
          dataKey={dataKeys ? dataKeys[0] : undefined}
          type={yAxisType ? yAxisType : "number"}
        />
      );

      const rightBarChartYAxis = (
        <YAxis
          label={rightYAxisLabel}
          orientation="right"
          tickFormatter={
            yAxisType === "category"
              ? undefined
              : tick => {
                  if (isSpend) {
                    let base = tick >= 1000 ? "k" : "";
                    let tickValue = tick >= 1000 ? tick / 1000 : tick;
                    if (tick < 1 && tick !== 0) {
                      return `${formatMoney(tickValue)}${base}`;
                    } else {
                      return `${formatMoneyAsInt(tickValue)}${base}`;
                    }
                  } else {
                    return formatNumber(tick);
                  }
                }
          }
          tick={{ fontSize: yAxisTickFontSize, fontFamily: font }}
          tickCount={yAxisTickCount}
          ticks={yAxisTicks}
          axisLine={yAxisLine}
          tickLine={yAxisLine}
          domain={yAxisDomain as AxisDomain}
          dataKey={dataKeys ? dataKeys[1] : undefined}
          type={yAxisType ? yAxisType : "number"}
        />
      );

      const barChartBars =
        layout === "vertical" && xDataKey ? (
          <Bar
            key={"Bar"}
            dataKey={xDataKey}
            fill={fill[0]}
            isAnimationActive={isAnimationActive}
            {...{ label }}
          ></Bar>
        ) : (
          R.map(index => {
            return (
              <Bar
                key={`Bar ${index}`}
                dataKey={dataKeys[index]}
                fill={fill[index]}
                isAnimationActive={isAnimationActive}
                {...{ label }}
              ></Bar>
            );
          }, Array.from(Array(dataKeys.length).keys()))
        );

      if (height && width) {
        return (
          <div className="bar-chart">
            {chartData && (
              <BarChart
                ref={ref}
                height={height}
                width={width}
                margin={chartMargins}
                data={chartData}
                layout={layout}
              >
                {barChartToolTip} {barChartXAxis}
                {leftBarChartYAxis}
                {useRightAxis && rightBarChartYAxis}
                {R.map(elem => elem, barChartBars)}
              </BarChart>
            )}
          </div>
        );
      } else {
        return (
          <div className="bar-chart">
            <AutoSizer>
              {({ width, height }) =>
                chartData && (
                  <BarChart
                    ref={ref}
                    height={height}
                    width={width}
                    margin={chartMargins}
                    data={chartData}
                  >
                    {barChartToolTip} {barChartXAxis}
                    {leftBarChartYAxis}
                    {useRightAxis && rightBarChartYAxis}
                    {R.map(elem => elem, barChartBars)}
                  </BarChart>
                )
              }
            </AutoSizer>
          </div>
        );
      }
    }
  )
);
