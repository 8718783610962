import { COLUMN_DND_TYPE, DIMENSION_COLUMN_DND_TYPE } from "./configUtils";
import { MetricsTablePreset, SortInfo } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { StateSetter } from "../../utils/types";
import { useDrop } from "react-dnd";
import { useSortAdder } from "../MetricsTable/metricsTableUtils";
import cn from "classnames";
import React, { useCallback, useRef } from "react";
import SortItem from "./SortItem";

interface SortingBoxProps {
  colorMap: Record<string, string | undefined>;
  presetChanges: MetricsTablePreset;
  setHoverItem: StateSetter<string | undefined>;
  setPresetChanges: StateSetter<MetricsTablePreset>;
}

const SortingBox = ({
  presetChanges,
  setPresetChanges,
  setHoverItem,
  colorMap,
}: SortingBoxProps): JSX.Element => {
  const newSortInfo = presetChanges.defaultSorting || [];
  const setNewSortInfo = useCallback(
    (func: SortInfo[] | ((arr: SortInfo[]) => SortInfo[])) => {
      setPresetChanges(changes => ({
        ...changes,
        defaultSorting: typeof func === "function" ? func(changes.defaultSorting || []) : func,
      }));
    },
    [setPresetChanges]
  );
  const addSorting = useSortAdder(setNewSortInfo);
  const sortingBoxRef = useRef<HTMLDivElement>(null);
  const [{ isOver }, drop] = useDrop({
    accept: [COLUMN_DND_TYPE, DIMENSION_COLUMN_DND_TYPE],
    canDrop: item => !newSortInfo?.find(existing => existing.id === item.id),
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
    drop: (item: { type: typeof COLUMN_DND_TYPE; id: string }) => addSorting(item.id),
  });
  drop(sortingBoxRef);
  return (
    <div className={cn("sortingBox", { isOver })} ref={sortingBoxRef}>
      <div>Sort Order</div>
      {!newSortInfo?.length && <div className="dragHere">Drag and drop here</div>}
      <div className="sortItemBox">
        {newSortInfo?.map(item => (
          <SortItem
            colorMap={colorMap}
            item={item}
            key={item.id}
            onClick={() => addSorting(item.id)}
            setHoverItem={setHoverItem}
            setNewSortInfo={setNewSortInfo}
          />
        ))}
      </div>
    </div>
  );
};

export default SortingBox;
