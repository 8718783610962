import React, { useMemo } from "react";
import { MdClose, MdDone } from "react-icons/md";
import { Setter } from "../utils/types";

import "./TargetingCheckBox.scss";

interface TargetingCheckBoxProps {
  checkBoxState: "red-x" | "yellow-check" | "green-check" | null;
  disabled?: boolean;
  onCheck: Setter<string | null>;
  className?: string;
  [passthroughProp: string]: any;
}

export const TargetingCheckBox: React.FC<TargetingCheckBoxProps> = ({
  checkBoxState = null,
  onCheck,
  className: providedClassName,
  disabled,
  ...passthrough
}) => {
  const className = useMemo(() => {
    let classes = ["targeting-check-box"];
    if (checkBoxState === null) {
      classes.push("blank");
    } else {
      classes.push(checkBoxState);
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (providedClassName) {
      classes.push(providedClassName);
    }
    return classes.join(" ");
  }, [checkBoxState, disabled, providedClassName]);

  return (
    <div
      className={className}
      onClick={() => {
        if (!disabled) {
          if (checkBoxState === "green-check") {
            onCheck("yellow-check");
          } else if (checkBoxState === "yellow-check") {
            onCheck("red-x");
          } else if (checkBoxState === "red-x") {
            onCheck(null);
          } else if (checkBoxState === null) {
            onCheck("green-check");
          } else {
            onCheck(null);
          }
        }
      }}
      {...passthrough}
    >
      {(checkBoxState === "green-check" || checkBoxState === "yellow-check") && <MdDone />}
      {checkBoxState === "red-x" && <MdClose />}
    </div>
  );
};

export default TargetingCheckBox;
