import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { BPMDateRange, Dropdown, DropdownToggleType, PieChart } from "../../Components";
import { convertComponentToImageUrl } from "../../utils/component-to-svg";
import { DateRange } from "../../utils/types";
import { SharedState, SlideState } from "../slideTemplateConstants";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SlideContext,
  SlideType,
} from "../slidesTypes";
import { fetchCampaignSlideData } from "./QueryServices";
import { CHART_FILL_COLORS, PROFILE_FILTER_MESSAGING, tmrRetailerFilterOptions, tmrSortByOptions, tmrKpiPull, } from "./utils";

export interface MarketplaceReportCampaignsSlideState {
  title: string;
  subTitle: string;
  sort: string;
  dates: DateRange;
  retailer: string;
  kpi: string;
  companyProfile: string;
}

interface MarketplaceReportCampaignsSlideData {
  title: string;
  subTitle: string;
}

type PieData = {
  name: string;
  value: number;
  color?: string;
};

const DATE_START = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
const DATE_END = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");

const addColorsToData = (data: any): PieData[] => {
  return data.map((item, index) => ({
    name: item.name,
    value: item.sales === 0 ? 0.001 : item.sales,
    color: CHART_FILL_COLORS[index] || "black",
  }));
};

const pieComponent = (height: number, width: number, data: {}[]) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    {/* This is because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
    {/*// @ts-ignore */}
    <PieChart data={data} size={300} />
  </div>
);

class MarketplaceReportCampaignsSlide extends SlideType {
  static typeKey = "TmrCampaignsSlide";
  static displayKey = "The Tinuiti Marketplace Report Delivery";
  static defaultState: MarketplaceReportCampaignsSlideState = {
    title: "TMR Campaigns - Search Advertising",
    subTitle: `${moment().subtract(1, "month").format("MMMM")} TMR`,
    dates: { start: DATE_START, end: DATE_END },
    sort: "sales",
    retailer: "null",
    kpi: "acos",
    companyProfile: PROFILE_FILTER_MESSAGING,
  };
  static SettingsComponent: React.FC<
    SettingsComponentProps<MarketplaceReportCampaignsSlideState>
  > = React.memo(({ state, setState, slideContext }) => {
    const { retailer, title, subTitle, sort, dates, kpi, companyProfile } = state;

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group className="flex">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={e => setState({ subTitle: e.currentTarget.value })}
            />
          </Form.Group>
          <Form.Group className="flex">
            <Form.Label>Company Profile Filter</Form.Label>
            <Form.Control
              value={companyProfile}
              onChange={e => setState({ companyProfile: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div className="tmrReportInput">
          <div>
            <Form.Label>Date Range</Form.Label>
            <BPMDateRange
              range={dates}
              onChange={option => {
                setState({ dates: { start: option.start, end: option.end } });
              }}
            />
          </div>
          <div>
            <Form.Label> Sort by</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={sort}
              options={tmrSortByOptions}
              onChange={change => setState({ sort: change })}
            />
          </div>
         <div>
            <Form.Label>KPI</Form.Label>
            <Dropdown
              type={DropdownToggleType.OUTLINED}
              value={kpi}
              options={tmrKpiPull}
              onChange={change => setState({ kpi: change })}
            />
          </div>
          <div>
          <Form.Label>Retailer Filter</Form.Label>
          <Dropdown
            type={DropdownToggleType.OUTLINED}
            value={retailer}
            options={tmrRetailerFilterOptions}
            onChange={change => setState({ retailer: change })}
          />
          </div>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<MarketplaceReportCampaignsSlideData> => {
    let { company } = context;
    const {
      title,
      subTitle,
      dates,
      sort,
      retailer,
      companyProfile,
      kpi,
    } = state as MarketplaceReportCampaignsSlideState;
    const height = 300;
    const width = 300;
    let profileNames;
    if (companyProfile === PROFILE_FILTER_MESSAGING || !companyProfile) {
      profileNames = [];
    } else {
      profileNames = companyProfile.split(",").map(cp => cp.trim());
    }
    const data = await fetchCampaignSlideData(
      company,
      dates.start,
      dates.end,
      retailer,
      sort,
      profileNames
    );
    //changeData
    const salesData = {
      byCampaignPlacement: addColorsToData(data.spendVsSales.placement),
      byCampaignType: addColorsToData(data.spendVsSales.campaign),
      byTargetEntity: addColorsToData(data.spendVsSales.targeting),
    };

    const typePieComponent = pieComponent(height, width, salesData.byCampaignType);

    let typePieUrl = await convertComponentToImageUrl(
      typePieComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const placementPieComponent = pieComponent(height, width, salesData.byCampaignPlacement);

    let placementPieUrl = await convertComponentToImageUrl(
      placementPieComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    const entityPieComponent = pieComponent(height, width, salesData.byTargetEntity);

    let entityPieUrl = await convertComponentToImageUrl(
      entityPieComponent,
      height,
      width,
      claimSandbox,
      releaseSandbox,
      addS3Image
    );

    return Promise.resolve({
      title,
      subTitle,
      company,
      typePieUrl,
      placementPieUrl,
      entityPieUrl,
      salesData,
      kpi,
      changeData: data.changeData,
      spendVsSalesData: data.spendVsSales,
    });
  };
}
export default MarketplaceReportCampaignsSlide;
