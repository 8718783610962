import React, { useRef } from "react";

import * as R from "ramda";

import cn from "classnames";

import { MdArrowUpward, MdArrowDownward } from "react-icons/md";

import { useDrag, useDrop } from "react-dnd";

import { SortInfo } from "@blisspointmedia/bpm-types/dist/Performance";

import { StateSetter } from "../../utils/types";

const SORT_ITEM_DND_TYPE = "SORT_ITEM_DND_TYPE" as const;

interface SortItemProps {
  item: SortInfo;
  colorMap: Record<string, string | undefined>;
  setHoverItem: StateSetter<string | undefined>;
  setNewSortInfo: (func: (arr: SortInfo[]) => SortInfo[]) => void;
  onClick: () => void;
}

const SortItem: React.FC<SortItemProps> = ({
  item,
  colorMap,
  setHoverItem,
  setNewSortInfo,
  onClick,
}) => {
  const { id, asc } = item;

  const ref = useRef<HTMLDivElement>(null);

  const [, drag, preview] = useDrag({
    item: { type: SORT_ITEM_DND_TYPE, id, asc },
  });

  const [{ isOver }, drop] = useDrop({
    accept: SORT_ITEM_DND_TYPE,
    canDrop: item => item.id !== id,
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
    drop: (item: SortInfo & { type: typeof SORT_ITEM_DND_TYPE }) => {
      setNewSortInfo(existing =>
        existing.reduce((agg, elem) => {
          // If it's us, then drop in front of us
          if (elem.id === id) {
            return [...agg, R.omit(["type"], item), elem];
          }
          // If it's the item we're dragging, don't include it in our new list
          if (elem.id === item.id) {
            return agg;
          }

          return [...agg, elem];
        }, [] as SortInfo[])
      );
    },
  });

  drop(drag(preview(ref)));

  return (
    <div
      ref={ref}
      style={{ borderColor: colorMap[id] || "black" }}
      className={cn("sortItem", { isOver })}
      onMouseOver={() => setHoverItem(id)}
      onMouseLeave={() => setHoverItem(current => (current === id ? undefined : current))}
      onClick={() => {
        if (!asc) {
          setHoverItem(undefined);
        }
        onClick();
      }}
    >
      {asc ? <MdArrowUpward /> : <MdArrowDownward />}
    </div>
  );
};

export default SortItem;
