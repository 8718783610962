import React, { useContext, useMemo, useCallback } from "react";

import * as R from "ramda";

import { MdExpandMore, MdExpandLess, MdInfoOutline } from "react-icons/md";
import { Button, Form, Alert } from "react-bootstrap";

import { useSetError, useSetAreYouSure } from "../redux/modals";

import { StreamingV2LambdaFetch } from "../utils/fetch-utils";

import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import { OrderViewContext } from "./OrderView";

import "./OrderView.scss";
import { CheckBox, OverlayTrigger, Tooltip } from "../Components";

export const NEW_VERSION_SEND = "new version";
export const RESEND = "resend";

const OrderControls = ({ expanded, setExpanded, isGhostBidding, setIsGhostBidding }) => {
  const setAreYouSure = useSetAreYouSure(true);
  const setError = useSetError();

  const enableBeeswaxIntegration = useExperimentFlag("enableBeeswaxIntegration");

  const {
    clearOrderChanges,
    flightChangeMap,
    pdfText,
    setPDFText,
    clearPDFText,
    hasChanges,
    setPDFTextToDefault,
    order,
    setSaving,
    onSave,
    sendFlightsToBeeswax,
    refetchOrder,
    generatePDF,
    isNewOrder,
    setShowSendView,
  } = useContext(OrderViewContext);

  const isRTB = useMemo(() => {
    const { flights } = order;
    for (let flight of flights) {
      let combo = { ...flight, ...(flightChangeMap[flight.id] || {}) };
      if (!combo.platform.includes("RTB")) {
        return false;
      }
    }
    return R.pipe(
      R.values,
      R.all(flight => (flight.platform || "RTB").includes("RTB"))
    )(flightChangeMap);
  }, [order, flightChangeMap]);

  const hasBeeswaxFlights = useMemo(() => {
    const { flights } = order;
    for (let flight of flights) {
      let combo = { ...flight, ...(flightChangeMap[flight.id] || {}) };
      if (combo.dsp === "Beeswax") {
        return true;
      }
    }
    return R.pipe(
      R.values,
      R.any(flight => (flight.dsp || "") === "Beeswax")
    )(flightChangeMap);
  }, [order, flightChangeMap]);

  const forceCommit = useCallback(async () => {
    setSaving(true);
    try {
      if (enableBeeswaxIntegration && hasBeeswaxFlights) {
        await sendFlightsToBeeswax();
      }

      await StreamingV2LambdaFetch("/orders/status", {
        method: "POST",
        body: {
          id: order.id,
          status: 20,
        },
      });
      refetchOrder();

      setSaving(false);
    } catch (e) {
      setError({
        message: `Failed to commit order. Error: ${e.message}.`,
        reportError: e,
      });
      setSaving(false);
    }
  }, [
    order,
    setError,
    setSaving,
    refetchOrder,
    enableBeeswaxIntegration,
    hasBeeswaxFlights,
    sendFlightsToBeeswax,
  ]);

  return (
    <div className="orderControls">
      <Button
        block
        variant="primary"
        size="sm"
        className={`expander${expanded ? " expanded" : ""}`}
        onClick={() => setExpanded(R.not)}
      >
        {expanded ? <MdExpandLess /> : <MdExpandMore />}
      </Button>
      {expanded && (
        <div className="orderControlsBody">
          <div className="pdfText">
            <div className="label">
              <span>PDF Text</span>
              <Button size="sm" variant="link" onClick={clearPDFText}>
                Discard changes
              </Button>
              <Button size="sm" variant="link" onClick={setPDFTextToDefault}>
                Reset to default
              </Button>
            </div>
            <Form.Control
              as="textarea"
              rows={3}
              value={pdfText || ""}
              onChange={e => setPDFText(e.target.value)}
              onBlur={() => setPDFText(text => (text || "").trim())}
            />
          </div>
          {isRTB && (
            <div className="ghostBiddingOptions">
              <CheckBox
                className="ghostBiddingCheckbox"
                checked={isGhostBidding}
                onCheck={() => setIsGhostBidding(R.not)}
                disabled={!isNewOrder}
              />{" "}
              This is a ghost bidding IO 👻
              <OverlayTrigger
                placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                overlay={
                  <Tooltip>
                    Checking this will auto-push your flights into a Ghost Bidding specific campaign
                    in Beeswax. You will not be able to change this value once you save your flight.
                  </Tooltip>
                }
              >
                <MdInfoOutline className="ghostBiddingInfo" />
              </OverlayTrigger>
            </div>
          )}
          <div className="buttonRow">
            <Button
              variant="danger"
              disabled={!hasChanges}
              onClick={() => {
                setAreYouSure({
                  title: "Discard All Changes?",
                  message:
                    "You're about to discard all your local changes. Are you sure you want to continue?",
                  cancelText: "Cancel",
                  okayText: "Continue",
                  onOkay: clearOrderChanges,
                });
              }}
            >
              Discard Changes
            </Button>
            <Button
              variant="primary"
              className="saveButton"
              disabled={!hasChanges}
              onClick={() => onSave()}
            >
              Save
            </Button>
            {/* Don't allow commit of Beeswax flights until changes are saved
                or we might not yet have derived IDs for new flights. */}
            <Button
              variant="outline-primary"
              className="saveButton"
              disabled={
                (enableBeeswaxIntegration && hasBeeswaxFlights && hasChanges) ||
                (!hasChanges && R.prop("status", order) === 20)
              }
              onClick={() => {
                if (enableBeeswaxIntegration && hasBeeswaxFlights) {
                  setAreYouSure({
                    title: "Committing changes to Beeswax",
                    message:
                      "You're about to send these changes to Beeswax.  Are you sure you want to continue?",
                    cancelText: "Cancel",
                    okayText: "Continue",
                    onOkay: () => {
                      hasChanges ? onSave(true) : forceCommit();
                    },
                  });
                } else {
                  hasChanges ? onSave(true) : forceCommit();
                }
              }}
            >
              Commit
            </Button>
            {!isRTB && (
              <Button
                variant="outline-primary"
                className="saveButton"
                disabled={hasChanges}
                onClick={() => setShowSendView(true)}
              >
                Send
              </Button>
            )}
            {!(isRTB || isNewOrder) && (
              <Button
                variant="success"
                className="saveButton"
                onClick={() => generatePDF(true)}
                disabled={hasChanges}
              >
                Generate PDF
              </Button>
            )}
            {enableBeeswaxIntegration &&
              hasBeeswaxFlights &&
              !(hasChanges || (!hasChanges && R.prop("status", order) === 20)) && (
                <Alert className="beeswaxSyncAlert" variant="warning">
                  You have made changes to Beeswax flights that have not yet been synced into
                  Beeswax. Please commit your changes to update the DSP.
                </Alert>
              )}
            {/* TODO: some day we'll have a changes view fueled by the audit table */}
            {/* <Button variant="info">Changes</Button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderControls;
