import "../OptimizationsUtils/Optimizations.scss";
import { Form } from "react-bootstrap";
import { FullPageSpinner, NumberFormatter, SelectorOption, ModalEditTable } from "../Components";
import { MdAllOut, MdAttachMoney, MdNotInterested } from "react-icons/md";
import { StreamingOptimizationsContext } from "./StreamingOptimizations";
import * as R from "ramda";
import React, { useEffect, useState, useMemo, useContext } from "react";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";

const GLOBAL_FIELD_DEFINITIONS: Record<string, string>[] = [
  {
    name: "Max per Network Spend %",
    key: "networkMaxSpendPct",
  },
  {
    name: "Unseen Max Spend %",
    key: "unseenMaxSpendPct",
  },
  {
    name: "Max Extrapolation Ratio",
    key: "maxExtrapolationRatio",
  },
];

interface ConstraintData {
  constraints: any;
}

const ConstraintView = React.memo(() => {
  const {
    constraintData,
    creativeMap,
    isciLiveOnDate,
    placements,
    setConstraintData,
    setDirtyConstraint,
    setInvalidConstraint,
  } = useContext(StreamingOptimizationsContext);
  const [data, setData] = useState<ConstraintData>();
  const [extrapolationInputErrorMessage, setExtrapolationInputErrorMessage] = useState<string>("");

  const isTraffic = useMemo(() => {
    return constraintData?.optimizationType === "traffic";
  }, [constraintData]);

  useEffect(() => {
    if (constraintData) {
      setData(constraintData);
    }
  }, [constraintData]);

  const creativeOptions: SelectorOption[] | undefined = useMemo(() => {
    if (creativeMap) {
      return R.map(
        e => ({ label: e, value: e }),
        R.sortBy(
          R.identity,
          R.uniq(
            R.pluck(
              "name",
              R.filter(
                creative =>
                  isciLiveOnDate({ isci: creative.isci, date: TODAY }) && !R.isNil(creative.name),
                R.values(creativeMap)
              ) as any[]
            )
          )
        )
      );
    }
  }, [creativeMap, isciLiveOnDate]);
  const publisherOptions: SelectorOption[] | undefined = useMemo(() => {
    if (placements) {
      return R.map(
        e => ({ label: e, value: e }),
        R.sortBy(
          R.identity,
          R.uniq(
            R.pluck(
              "publisher",
              R.filter(placement => !R.isNil(placement.publisher), placements) as any[]
            )
          )
        )
      );
    }
  }, [placements]);
  const formatOptions: SelectorOption[] | undefined = useMemo(() => {
    if (placements) {
      return R.map(
        e => ({ label: e, value: e }),
        R.sortBy(
          R.identity,
          R.uniq(
            R.pluck(
              "format",
              R.filter(placement => !R.isNil(placement.format), placements) as any[]
            )
          )
        )
      );
    }
  }, [placements]);
  const placementOptions: SelectorOption[] | undefined = useMemo(() => {
    if (placements) {
      return R.map(
        e => ({ label: e, value: e }),
        R.sortBy(
          R.identity,
          R.uniq(
            R.pluck(
              "placement",
              R.filter(placement => !R.isNil(placement.placement), placements) as any[]
            )
          )
        )
      );
    }
  }, [placements]);
  const derivedIDOptions: SelectorOption[] | undefined = useMemo(() => {
    if (placements) {
      return R.map(
        e => ({ label: e, value: e }),
        R.sortBy(
          R.identity,
          R.uniq(
            R.pluck(
              "derived_id",
              R.filter(placement => !R.isNil(placement.derived_id), placements) as any[]
            )
          )
        )
      );
    }
  }, [placements]);
  const selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    return {
      creatives: R.defaultTo([], creativeOptions),
      length: R.defaultTo(
        [],
        R.map(e => ({ label: e, value: e }), ["15", "30", "60"])
      ),
      metric: R.defaultTo(
        [],
        ["TRPs", "Impressions"].map(e => ({ label: e, value: e }))
      ),
      publishers: R.defaultTo([], publisherOptions),
      placements: R.defaultTo([], placementOptions),
      format: R.defaultTo([], formatOptions),
      derivedIDs: R.defaultTo([], derivedIDOptions),
    };
  }, [creativeOptions, derivedIDOptions, publisherOptions, formatOptions, placementOptions]);

  const budgetHeaders: Record<string, any>[] = [
    {
      type: "text",
      field: "name",
      label: "Name",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "placements",
      label: "Placements",
      isMulti: true,
      options: "placements",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "derived_ids",
      label: "DerivedIDs",
      isMulti: true,
      options: "derivedIDs",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "publishers",
      label: "Publishers",
      isMulti: true,
      options: "publishers",
      modalRow: 2,
      modalWidth: 200,
    },
    {
      type: "select",
      field: "format",
      label: "Format",
      isMulti: false,
      options: "format",
      modalRow: 2,
      modalWidth: 150,
    },
    {
      type: "currency",
      field: "minValue",
      label: "Min $",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "percent",
      field: "minPct",
      label: "Min %",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "currency",
      field: "maxValue",
      label: "Max $",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "percent",
      field: "maxPct",
      label: "Max %",
      width: 100,
      modalRow: 3,
      modalFlex: 1,
    },
  ];

  const avoidHeaders: Record<string, any>[] = [
    {
      type: "select",
      field: "placements",
      label: "Placements",
      isMulti: true,
      options: "placements",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "derived_ids",
      label: "DerivedIDs",
      isMulti: true,
      options: "derivedIDs",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "publishers",
      label: "Publishers",
      isMulti: true,
      options: "publishers",
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "select",
      field: "format",
      label: "Format",
      isMulti: false,
      options: "format",
      modalRow: 2,
      modalWidth: 250,
    },
  ];

  const globalHeaders = [
    {
      label: "Name",
      field: "name",
      flex: 1,
      minFlexWidth: 300,
      readOnly: true,
      modalRow: 0,
      modalFlex: 4,
    },
    {
      label: "Value",
      field: "value",
      type: "decimal",
      modalRow: 0,
      modalFlex: 1,
      renderer: row => {
        if (row.key.indexOf("Pct") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"%"} />
            </span>
          );
        } else if (row.key.indexOf("Spend") >= 0 || row.key.indexOf("Cost") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"$"} />
            </span>
          );
        } else if (row.key.indexOf("CountPer") >= 0) {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"#2"} />
            </span>
          );
        } else {
          return (
            <span className="modalEditTableCell">
              <NumberFormatter value={row.value} type={"#"} />
            </span>
          );
        }
      },
      rendererType: row => {
        if (row.key.indexOf("Pct") >= 0) {
          return "percent";
        } else if (row.key.indexOf("Spend") >= 0 || row.key.indexOf("Cost") >= 0) {
          return "currency";
        } else if (row.key.indexOf("CountPer") >= 0) {
          return "real";
        } else {
          return "decimal";
        }
      },
    },
  ];

  const globalConstraints = useMemo(() => {
    return (
      constraintData &&
      R.pipe(
        R.map((index: number) => ({
          ...GLOBAL_FIELD_DEFINITIONS[index],
          index,
          value: R.path(
            ["constraints", "global", GLOBAL_FIELD_DEFINITIONS[index].key],
            constraintData
          ),
        }))
      )(R.range(0, GLOBAL_FIELD_DEFINITIONS.length))
    );
  }, [constraintData]);

  if (!data) {
    return <FullPageSpinner />;
  }

  const validateUserInput: (constraint) => boolean = constraint => {
    if (constraint.name === "Max Extrapolation Ratio" && constraint.value < 1) {
      setExtrapolationInputErrorMessage(`Invalid input:
      Extrapolation Ratio must be greater than or equal to 1.`);
      return false;
    }

    return true;
  };

  const nonextrapolationNote =
    "We do not recommend values over 3.0 since you will be allowing extrapolation more than 3x beyond historical maxima.";

  const checkExtrapolationConstraint: (constraint) => boolean = constraint => {
    if (constraint.name === "Max Extrapolation Ratio") {
      return true;
    }
    return false;
  };

  return (
    <div className="constraintViewBody">
      {isTraffic ? (
        false
      ) : (
        <div className="overallBudgetContainer">
          <div className="tableHeader">
            <div className="tableName">Overall Budget</div>
          </div>
          <Form.Control
            className="overallBudget"
            value={data.constraints.total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            onChange={event => {
              setConstraintData({
                ...data,
                constraints: {
                  ...data.constraints,
                  total: parseInt(event.target.value.replace(/[^0-9]/g, "")),
                },
              });
              setDirtyConstraint(true);
            }}
          />
        </div>
      )}
      <div className="constraintView">
        <ModalEditTable
          className="constraintTable"
          headers={budgetHeaders}
          name={
            <span style={{ color: "#7e57c2" }}>
              <MdAttachMoney fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
              &nbsp;&nbsp;Budget Constraints
            </span>
          }
          tableData={data.constraints.budget}
          setTableData={tableData => {
            setConstraintData({
              ...data,
              constraints: { ...data.constraints, budget: tableData },
            });
            setDirtyConstraint(true);
          }}
          selectorOptions={selectorOptions}
          enableActionColumn={true}
        />
        <div className="avoidGlobalGroup">
          <ModalEditTable
            className="constraintTable avoidTable"
            headers={avoidHeaders}
            name={
              <span style={{ color: "#7e57c2" }}>
                <MdNotInterested fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
                &nbsp;&nbsp;Avoid Constraints
              </span>
            }
            tableData={data.constraints.avoid}
            setTableData={tableData => {
              const isInvalid = R.any(
                row => R.without(["index", "lastModified"], R.keys(row)).length === 0,
                tableData
              );

              setConstraintData({
                ...data,
                constraints: { ...data.constraints, avoid: tableData },
              });
              setDirtyConstraint(true);
              setInvalidConstraint(isInvalid);
            }}
            selectorOptions={selectorOptions}
            enableActionColumn={true}
          />
          {isTraffic ? (
            false
          ) : (
            <ModalEditTable
              className="constraintTable globalTable"
              headers={globalHeaders}
              name={
                <div style={{ color: "#7e57c2", height: "37px" }}>
                  <MdAllOut fill="#7e57c2" size="22" style={{ marginTop: "-2px" }} />
                  &nbsp;&nbsp;Global constraints
                </div>
              }
              enableAdd={false}
              checkShowFooter={checkExtrapolationConstraint}
              footerText={nonextrapolationNote}
              checkIsValid={validateUserInput}
              invalidText={extrapolationInputErrorMessage}
              tableData={R.defaultTo([], globalConstraints)}
              enableDelete={false}
              setTableData={tableData => {
                setConstraintData({
                  ...data,
                  constraints: {
                    ...data.constraints,
                    global: R.fromPairs(R.map(row => [row.key, row.value], tableData)),
                  },
                });
                setDirtyConstraint(true);
              }}
              selectorOptions={selectorOptions}
              enableActionColumn={true}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ConstraintView;
