import React, { useMemo, useContext, useCallback, useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";

import { Button, Tooltip, Modal, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { BPMTable, NumberFormatter, FullPageSpinner, OverlayTrigger, Spinner } from "../Components";
import {
  MdSave,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdBubbleChart,
  MdAttachMoney,
  MdAllOut,
  MdPeople,
  MdNotInterested,
} from "react-icons/md";

import {
  JavaLambdaFetch,
  pollS3,
  awaitJSON,
  LinearOptimizationsLambdaFetch,
} from "../utils/fetch-utils";
import { download } from "../utils/download-utils";

import { LinearOptimizationsContext } from "./LinearOptimizations";
import "./LinearOptimizations.scss";

const DOW_FOR_DAY = {
  M: "M----------",
  Tu: "-Tu--------",
  W: "---W-------",
  Th: "----Th-----",
  F: "------F----",
  Sa: "-------Sa--",
  Su: "---------Su",
  "M - Tu": "MTu--------",
  "M - W": "MTuW-------",
  "M - Th": "MTuWTh-----",
  "M - F": "MTuWThF----",
  "M - Sa": "MTuWThFSa--",
  "M - Su": "MTuWThFSaSu",
  "Tu - W": "-TuW-------",
  "Tu - Th": "-TuWTh-----",
  "Tu - F": "-TuWThF----",
  "Tu - Sa": "-TuWThFSa--",
  "Tu - Su": "-TuWThFSaSu",
  "W - Th": "---WTh-----",
  "W - F": "---WThF----",
  "W - Sa": "---WThFSa--",
  "W - Su": "---WThFSaSu",
  "Th - F": "----ThF----",
  "Th - Sa": "----ThFSa--",
  "Th - Su": "----ThFSaSu",
  "F - Sa": "------FSa--",
  "F - Su": "------FSaSu",
  "Sa - Su": "-------SaSu",
};

// Converts a list of objects with discrete values to scaled percentages
// Will add up to 100% evenly.
// Uses largest remainder method to distribute the remaining percentage values.
const CountsToPercentages = (counts, countsKey, percentageKey) => {
  if (typeof counts != "object") {
    return counts;
  }

  if (Array.isArray(counts)) {
    for (let item of counts) {
      if (!R.has(countsKey, item)) {
        throw new Error(`Not every record has the key ${countsKey}!`);
      }
    }
  }

  let clonedCounts = R.clone(counts);

  // Handle case when input is an object with values we want to process
  if (!Array.isArray(clonedCounts)) {
    let pairs = R.toPairs(clonedCounts);

    let denom = R.pipe(R.map(R.nth(1)), R.sum)(pairs);

    let scaled = pairs.map(x => {
      x[1] = (x[1] / denom) * 100;
      return x;
    });

    let delta = R.pipe(R.map(R.pipe(R.nth(1), Math.floor)), R.sum, R.subtract(100))(scaled);

    let newObject = R.pipe(
      R.sortBy(R.pipe(R.nth(1), x => Math.floor(x) - x)),
      x =>
        x.map((x, i) => {
          x[1] = i < delta ? Math.floor(x[1]) + 1 : Math.floor(x[1]);
          return x;
        }),
      R.map(R.apply(R.objOf)),
      R.mergeAll
    )(pairs);

    return newObject;
  }

  return R.pipe(
    R.map(R.prop(countsKey)),
    R.sum,
    denom => clonedCounts.map(x => ({ ...x, [percentageKey]: (x[countsKey] / denom) * 100 })),
    percents => {
      let diff = R.pipe(
        R.map(R.prop(percentageKey)),
        R.map(Math.floor),
        R.sum,
        R.subtract(100)
      )(percents);

      // Sort our scaled percentags by the remainder and distributed the remaining values starting from the largest
      return R.pipe(R.sortBy(R.compose(x => Math.floor(x) - x, R.prop(percentageKey))), x =>
        x.map((x, i) => ({
          ...x,
          [percentageKey]:
            i < diff ? Math.floor(x[percentageKey]) + 1 : Math.floor(x[percentageKey]),
        }))
      )(percents);
    }
  )(clonedCounts);
};

const ExportRunModal = ({ calculateTrimmedRows, downloadCSV, onHide, getInsightsCategories }) => {
  const [trimMornings, setTrimMornings] = useState(true);
  const [trimFridays, setTrimFridays] = useState(false);
  const [roundOdd15s, setRoundOdd15s] = useState(false);
  const [roundOddLocals, setRoundOddLocals] = useState(false);
  const [roundOddAll, setRoundOddAll] = useState(false);
  const [creativeInsight, setCreativeInsight] = useState("");
  const [downloading, setDownloading] = useState();

  const insightsCategories = getInsightsCategories();

  const trimmedRows = calculateTrimmedRows({
    trimMornings,
    trimFridays,
    roundOdd15s,
    roundOddLocals,
    roundOddAll,
    creativeInsight,
  });
  const totalSpend = useMemo(() => {
    const header = trimmedRows[0];
    const spendIndex = R.findIndex(h => h === "spend", header);
    return R.pipe(
      R.tail,
      R.map(row => row[spendIndex]),
      R.sum
    )(trimmedRows);
  }, [trimmedRows]);

  return (
    <Modal size="lg" keyboard={true} show={true} onHide={() => onHide()} className="exportRunModal">
      <Modal.Header closeButton>
        <Modal.Title>Export</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <Form.Check
          label="Trim Mornings"
          type="checkbox"
          id="checkboxTrimMornings"
          defaultChecked={true}
          onChange={() => setTrimMornings(!trimMornings)}
        />
        <Form.Check
          label="Trim Fridays"
          type="checkbox"
          id="checkboxTrimFridays"
          onChange={() => setTrimFridays(!trimFridays)}
        />
        <Form.Check
          label="Round Up Odd 15s"
          type="checkbox"
          id="checkboxRoundOdd15s"
          onChange={() => setRoundOdd15s(!roundOdd15s)}
        />
        <Form.Check
          label="Round Up Odd Locals"
          type="checkbox"
          id="checkboxRoundOddLocals"
          onChange={() => setRoundOddLocals(!roundOddLocals)}
        />
        <Form.Check
          label="Round Up All Odd Units"
          type="checkbox"
          id="checkboxRoundAllOddUnits"
          onChange={() => setRoundOddAll(!roundOddAll)}
        />
        {insightsCategories && insightsCategories.length > 0 && (
          <Form.Group className="creativeInsightSelector">
            <Form.Label className="insightsSelectorLabel">Creative Insight Category</Form.Label>
            <DropdownButton className="creativeInsightDropdown" title={creativeInsight}>
              {insightsCategories.map(category => (
                <Dropdown.Item
                  key={category}
                  eventKey={category}
                  onSelect={category => setCreativeInsight(category)}
                >
                  {category}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button
              variant="outline-primary"
              className="resetButton"
              onClick={() => {
                setCreativeInsight("");
              }}
            >
              <span>Reset Category</span>
            </Button>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer className="modalControls">
        <span className="total">
          Total: <NumberFormatter value={totalSpend} type={"$"} decimals={0} />
        </span>
        <Button
          variant="primary"
          className="sendButton"
          onClick={async () => {
            setDownloading(true);
            await downloadCSV(trimmedRows);
            setDownloading(false);
          }}
        >
          {downloading ? <Spinner /> : <span>Download</span>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DAY_ALLOCATION_MAP = {
  M: [1, 0],
  "M - Tu": [1, 0],
  "M - W": [1, 0],
  "M - Th": [1, 0],
  "M - F": [1, 0],
  "M - Sa": [5.0 / 7.0, 2.0 / 7.0],
  "M - Su": [5.0 / 7.0, 2.0 / 7.0],
  Tu: [1, 0],
  "Tu - W": [1, 0],
  "Tu - Th": [1, 0],
  "Tu - F": [1, 0],
  "Tu - Sa": [5.0 / 7.0, 2.0 / 7.0],
  "Tu - Su": [5.0 / 7.0, 2.0 / 7.0],
  W: [1, 0],
  "W - Th": [1, 0],
  "W - F": [1, 0],
  "W - Sa": [5.0 / 7.0, 2.0 / 7.0],
  "W - Su": [5.0 / 7.0, 2.0 / 7.0],
  Th: [1, 0],
  "Th - F": [1, 0],
  "Th - Sa": [5.0 / 7.0, 2.0 / 7.0],
  "Th - Su": [5.0 / 7.0, 2.0 / 7.0],
  F: [1, 0],
  "F - Sa": [5.0 / 7.0, 2.0 / 7.0],
  "F - Su": [5.0 / 7.0, 2.0 / 7.0],
  Sa: [0, 1],
  "Sa - Su": [0, 1],
  Su: [0, 1],
};

const DAYPART_NAME_ORDERING = [
  "Early Morning",
  "Daytime",
  "Early Fringe",
  "Prime",
  "Late Fringe",
  "Overnight",
  "Broad Rotator",
];

const RunView = React.memo(() => {
  const {
    runResult,
    company,
    runId,
    optimizations,
    downloadMediaPlan,
    noSpacesCreativeMap,
    selectedView,
    creativeInsightsData,
    insightsCategoriesData,
    RUNS_SUMMARY_KEY,
    RUNS_BUYABLE_KEY,
    RUNS_CONSTRAINTS_KEY,
  } = useContext(LinearOptimizationsContext);

  const [buyableFilteredData, setBuyableFilteredData] = useState([]);
  const [showExportDialog, setShowExportDialog] = useState();
  const [constraintRequestStatus, setConstraintRequestStatus] = useState(0);
  const [isDownloadConstraintRequestData, setIsDownloadConstraintRequestData] = useState(false);
  const [constraintsData, setConstraintsData] = useState([]);
  const [dynamicTokens, setDynamicTokens] = useState();

  const runInfo = useMemo(() => {
    if (!runId || !optimizations) {
      return;
    }
    return R.filter(row => `${row.build_number}` === runId, optimizations)[0];
  }, [runId, optimizations]);

  const totalSpend = useMemo(() => {
    if (!runResult) {
      return;
    }

    return R.pipe(
      R.filter(row => row.Network !== ""),
      R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
      R.sum
    )(runResult);
  }, [runResult]);

  const networkPivot = useMemo(() => {
    if (!runResult || !totalSpend) {
      return;
    }

    return R.pipe(
      R.filter(row => row.Network !== ""),
      R.groupBy(row => row.Network),
      R.map(list => {
        const spend = R.pipe(
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);

        return {
          network: list[0].Network,
          avails: R.uniq(R.map(row => row.Avail, list)).sort(),
          spend,
          pct: spend / totalSpend,
        };
      }),
      R.values,
      R.sortBy(row => -row.spend)
    )(runResult);
  }, [runResult, totalSpend]);

  const daypartPivot = useMemo(() => {
    if (!runResult || !totalSpend) {
      return;
    }

    return R.pipe(
      R.filter(row => row.Network !== ""),
      R.groupBy(row => row["Daypart Name"]),
      R.map(list => {
        const spend = R.pipe(
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);

        return {
          daypart: list[0]["Daypart Name"],
          weekday:
            R.pipe(
              R.map(
                row =>
                  parseFloat(row.Cost) *
                  parseFloat(row["Number of Spots"]) *
                  DAY_ALLOCATION_MAP[row.Day][0]
              ),
              R.sum
            )(list) / spend,
          weekend:
            R.pipe(
              R.map(
                row =>
                  parseFloat(row.Cost) *
                  parseFloat(row["Number of Spots"]) *
                  DAY_ALLOCATION_MAP[row.Day][1]
              ),
              R.sum
            )(list) / spend,
          spend,
          pct: spend / totalSpend,
        };
      }),
      R.values,
      R.sortBy(row => R.indexOf(row.daypart, DAYPART_NAME_ORDERING))
    )(runResult);
  }, [runResult, totalSpend]);

  const availLengthPivot = useMemo(() => {
    if (!runResult || !totalSpend) {
      return;
    }

    return R.pipe(
      R.filter(row => row.Network !== ""),
      R.groupBy(row => row.Avail),
      R.map(list => {
        const spend = R.pipe(
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);
        const spend15s = R.pipe(
          R.filter(row => row["Spot Length"] === "15"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);
        const spend30s = R.pipe(
          R.filter(row => row["Spot Length"] === "30"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);
        const spend60s = R.pipe(
          R.filter(row => row["Spot Length"] === "60"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);

        return {
          avail: list[0].Avail,
          pct60s: spend60s / totalSpend,
          pct30s: spend30s / totalSpend,
          pct15s: spend15s / totalSpend,
          spend,
          pct: spend / totalSpend,
        };
      }),
      R.values,
      R.sortBy(row => row.avail)
    )(runResult);
  }, [runResult, totalSpend]);

  const creativePivot = useMemo(() => {
    if (!runResult || !totalSpend) {
      return;
    }

    return R.pipe(
      R.filter(row => row.Network !== ""),
      R.groupBy(row => row.Creative.split(";")[1]),
      R.map(list => {
        const spend = R.pipe(
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(list);

        return {
          creative: list[0].Creative.split(";")[1],
          spend,
          pct: spend / totalSpend,
        };
      }),
      R.values,
      R.sortBy(row => row.creative)
    )(runResult);
  }, [runResult, totalSpend]);

  const overviewData = useMemo(() => {
    if (!runId || !runInfo) {
      return [];
    }

    return [
      { key: "#", value: runId },
      { key: "Status", value: runInfo.status },
      { key: "Date", value: runInfo.date },
      { key: "Constraint", value: runInfo.constraint_name },
      { key: "OF", value: runInfo.objective_value },
      { key: "Cost", value: runInfo.cost },
      { key: "Budget", value: runInfo.budget },
      { key: "Logs", value: runInfo.log_url },
      { key: "Excel", value: "" },
    ];
  }, [runId, runInfo]);

  const buyableDataByCreativeInsight = useMemo(() => {
    if (!runResult || !creativeInsightsData || !insightsCategoriesData) {
      return [];
    }

    const result = new Map();

    const creativeNames = R.pipe(
      R.filter(row => row.Network !== ""),
      R.map(R.prop("Creative")),
      R.uniq,
      R.sortBy(R.identity)
    )(runResult);

    R.forEach(category => {
      const categoryRows = R.pipe(
        R.filter(row => row.Network !== ""),
        R.filter(
          row =>
            creativeInsightsData[row.Creative.split(";")[1]] &&
            creativeInsightsData[row.Creative.split(";")[1]].includes(category)
        ),
        R.groupBy(row =>
          R.map(key => row[key], [
            "Network",
            "Avail",
            "Spot Length",
            "Day",
            "Daypart",
            "Secured",
          ]).join("_")
        ),
        R.map(group => {
          const totalSpots = R.sum(R.map(row => parseInt(row["Number of Spots"]), group));
          const network = group[0].Network;
          const avail = group[0].Avail;
          const spotLength = group[0]["Spot Length"];
          const day = group[0].Day;
          let daypart = group[0].Daypart;
          const daypartName = group[0]["Daypart Name"];
          const secured = group[0].Secured;
          const cost = group[0].Cost;
          const splitDaypart = daypart.split("-");
          daypart = `${splitDaypart[0].trim()} - ${R.pipe(
            Dfns.parse(new Date(), "hh:mma"),
            hoursMinutes =>
              hoursMinutes.getMinutes() === 0 ? Dfns.addMinutes(-1, hoursMinutes) : hoursMinutes,
            Dfns.format("h:mma")
          )(splitDaypart[1].trim())}`;

          return R.mergeRight(
            {
              network,
              avail,
              spotLength,
              day,
              daypart,
              daypartName,
              secured,
              cost,
              totalSpots,
              totalSpend: totalSpots * group[0].Cost,
            },
            R.fromPairs(
              R.map(
                creative => [
                  creative,
                  R.pipe(
                    R.filter(row => row.Creative === creative),
                    R.map(row => parseInt(row["Number of Spots"])),
                    R.sum
                  )(group),
                ],
                creativeNames
              )
            )
          );
        }),
        R.values,
        R.sortBy(row =>
          R.map(key => row[key], [
            "Network",
            "Avail",
            "Spot Length",
            "Day",
            "Daypart",
            "Secured",
          ]).join("_")
        )
      )(runResult);
      if (categoryRows.length > 0) {
        result.set(category, categoryRows);
      }
    }, insightsCategoriesData);
    return result;
  }, [runResult, creativeInsightsData, insightsCategoriesData]);

  const buyableData = useMemo(() => {
    if (!runResult) {
      return [];
    }

    const creativeNames = R.pipe(
      R.filter(row => row.Network !== ""),
      R.map(R.prop("Creative")),
      R.uniq,
      R.sortBy(R.identity)
    )(runResult);

    const rows = R.pipe(
      R.filter(row => row.Network !== ""),
      R.groupBy(row =>
        R.map(key => row[key], [
          "Network",
          "Avail",
          "Spot Length",
          "Day",
          "Daypart",
          "Secured",
        ]).join("_")
      ),
      R.map(group => {
        const totalSpots = R.sum(R.map(row => parseInt(row["Number of Spots"]), group));
        const network = group[0].Network;
        const avail = group[0].Avail;
        const spotLength = group[0]["Spot Length"];
        const day = group[0].Day;
        let daypart = group[0].Daypart;
        const secured = group[0].Secured;
        const cost = group[0].Cost;
        const daypartName = group[0]["Daypart Name"];
        const splitDaypart = daypart.split("-");
        daypart = `${splitDaypart[0].trim()} - ${R.pipe(
          Dfns.parse(new Date(), "hh:mma"),
          hoursMinutes =>
            hoursMinutes.getMinutes() === 0 ? Dfns.addMinutes(-1, hoursMinutes) : hoursMinutes,
          Dfns.format("h:mma")
        )(splitDaypart[1].trim())}`;

        return R.mergeRight(
          {
            network,
            avail,
            spotLength,
            day,
            daypart,
            daypartName,
            secured,
            cost,
            totalSpots,
            totalSpend: totalSpots * group[0].Cost,
          },
          R.fromPairs(
            R.map(
              creative => [
                creative,
                R.pipe(
                  R.filter(row => row.Creative === creative),
                  R.map(row => parseInt(row["Number of Spots"])),
                  R.sum
                )(group),
              ],
              creativeNames
            )
          )
        );
      }),
      R.values,
      R.sortBy(row =>
        R.map(key => row[key], [
          "Network",
          "Avail",
          "Spot Length",
          "Day",
          "Daypart",
          "Secured",
        ]).join("_")
      )
    )(runResult);
    return rows;
  }, [runResult]);

  const networkPivotHeader = [
    {
      label: "Network",
      name: "network",
    },
    {
      label: "Avail",
      name: "avails",
      renderer: row => row.avails.join(", "),
    },
    {
      label: "Spend",
      name: "spend",
      renderer: row => <NumberFormatter value={row.spend} type={"$"} decimals={0} />,
    },
    {
      label: "% of Spend",
      name: "pct",
      width: 120,
      renderer: row => <NumberFormatter value={row.pct} type={"%"} decimals={1} />,
    },
  ];

  const daypartPivotHeader = [
    {
      label: "Daypart",
      name: "daypart",
      minFlexWidth: 150,
      flex: 1,
    },
    {
      label: "Weekday",
      name: "weekday",
      renderer: row => <NumberFormatter value={row.weekday} type={"%"} decimals={1} />,
    },
    {
      label: "Weekend",
      name: "weekend",
      renderer: row => <NumberFormatter value={row.weekend} type={"%"} decimals={1} />,
    },
    {
      label: "Grand Total",
      name: "pct",
      width: 120,
      renderer: row => <NumberFormatter value={row.pct} type={"%"} decimals={1} />,
    },
  ];

  const availLengthPivotHeader = useMemo(() => {
    if (!availLengthPivot) {
      return [];
    }

    return R.filter(R.identity, [
      {
        label: "Avail",
        name: "avail",
        flex: 1,
        minFlexWidth: 100,
      },
      R.pipe(R.map(R.prop("pct60s")), R.sum)(availLengthPivot) > 0 && {
        label: "60s",
        name: "pct60s",
        renderer: row => <NumberFormatter value={row.pct60s} type={"%"} decimals={1} />,
      },
      R.pipe(R.map(R.prop("pct30s")), R.sum)(availLengthPivot) > 0 && {
        label: "30s",
        name: "pct30s",
        renderer: row => <NumberFormatter value={row.pct30s} type={"%"} decimals={1} />,
      },
      R.pipe(R.map(R.prop("pct15s")), R.sum)(availLengthPivot) > 0 && {
        label: "15s",
        name: "pct15s",
        renderer: row => <NumberFormatter value={row.pct15s} type={"%"} decimals={1} />,
      },
      {
        label: "Grand Total",
        name: "pct",
        width: 120,
        renderer: row => <NumberFormatter value={row.pct} type={"%"} decimals={1} />,
      },
    ]);
  }, [availLengthPivot]);

  const creativePivotHeader = [
    {
      label: "Creative",
      name: "creative",
      minFlexWidth: 400,
      flex: 1,
    },
    {
      label: "Spend",
      name: "spend",
      renderer: row => <NumberFormatter value={row.spend} type={"$"} decimals={0} />,
    },
    {
      label: "% of Spend",
      name: "pct",
      width: 120,
      renderer: row => <NumberFormatter value={row.pct} type={"%"} decimals={1} />,
    },
  ];

  const overviewHeader = useMemo(
    () => [
      {
        label: "Key",
        name: "key",
        nonInteractive: true,
      },
      {
        label: "Value",
        name: "value",
        nonInteractive: true,
        width: 200,
        renderer: (row, rowIndex) => {
          if (R.contains(overviewData[rowIndex].key, ["OF"])) {
            return <NumberFormatter value={row.value} type={""} decimals={1} />;
          } else if (R.contains(overviewData[rowIndex].key, ["Cost", "Budget"])) {
            return <NumberFormatter value={row.value} type={"$"} decimals={0} />;
          } else if (R.contains(overviewData[rowIndex].key, ["Date"])) {
            return R.pipe(Dfns.parseISO, Dfns.format("yyyy-MM-dd hh:mma"))(row.value);
          } else if (R.contains(overviewData[rowIndex].key, ["Logs"])) {
            return (
              <a href={row.value} target="_blank" rel="noopener noreferrer">
                Logs
              </a>
            );
          } else if (R.contains(overviewData[rowIndex].key, ["Excel"])) {
            return (
              <Button variant="link" onClick={() => downloadMediaPlan()}>
                Excel
              </Button>
            );
          } else {
            return row.value;
          }
        },
      },
    ],
    [overviewData, downloadMediaPlan]
  );

  const buyableHeader = useMemo(() => {
    if (!runResult) {
      return [];
    }

    const creativeNames = R.pipe(
      R.filter(row => row.Network !== ""),
      R.map(R.prop("Creative")),
      R.uniq,
      R.sortBy(R.identity)
    )(runResult);

    const basicHeaders = [
      {
        label: "Network",
        name: "network",
      },
      {
        label: "Avail",
        name: "avail",
      },
      {
        label: "Length",
        name: "spotLength",
      },
      {
        label: "Day",
        name: "day",
      },
      {
        label: "Daypart",
        name: "daypart",
        width: 150,
      },
      {
        label: "Rotation Name",
        name: "daypartName",
        width: 150,
      },
      {
        label: "Secured",
        name: "secured",
      },
      {
        label: "Spots",
        name: "totalSpots",
      },
      {
        label: "Spot Rate",
        name: "cost",
        renderer: row => <NumberFormatter value={parseFloat(row.cost)} type={"$"} decimals={0} />,
      },
      {
        label: "Spend",
        name: "totalSpend",
        renderer: row => <NumberFormatter value={row.totalSpend} type={"$"} decimals={0} />,
      },
    ];

    return R.concat(
      basicHeaders,
      R.map(creative => ({ label: creative, name: creative }), creativeNames)
    );
  }, [runResult]);

  const constraintsHeader = [
    {
      label: "Type",
      name: "constraintType",
      width: 150,
    },
    {
      label: "Name",
      name: "Name",
      flex: true,
      minFlexWidth: 200,
    },
    {
      label: "Min Constraint",
      name: "MinConstraint",
      width: 150,
    },
    {
      label: "Max Constraint",
      name: "MaxConstraint",
      width: 150,
    },
    {
      label: "Final Value",
      name: "FinalValue",
      flex: true,
      minFlexWidth: 200,
    },
    {
      label: "Final Value (Rounded)",
      name: "FinalValueNonFractional",
      width: 150,
    },
    {
      label: "Binding",
      name: "isBinding",
      width: 150,
    },
    {
      label: "Constraint Violation Surplus",
      name: "NonFractionalSurplus",
      width: 150,
    },
    {
      label: "Constraint Violation Deficit",
      name: "NonFractionalDeficit",
      width: 150,
    },
  ];

  const totalsRenderer = ({ data, style = {}, classes = [] }) => {
    if (Number.isFinite(data) && data <= 1) {
      data = <NumberFormatter value={data} type={"%"} decimals={1} />;
    } else if (Number.isFinite(data) && data > 1) {
      data = <NumberFormatter value={data} type={"$"} decimals={0} />;
    }
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  const buyableTotalsRenderer = ({ data, style = {}, classes = [] }) => {
    if (Number.isFinite(data)) {
      data = <NumberFormatter value={data} type={"#"} decimals={0} />;
    } else if (data && data.indexOf("$") === 0) {
      data = <NumberFormatter value={parseFloat(data.replace("$", ""))} type={"$"} decimals={0} />;
    }

    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  const networkTotalsRow = useMemo(() => ({ network: "Grand Total", spend: totalSpend, pct: 1 }), [
    totalSpend,
  ]);

  const daypartTotalsRow = useMemo(() => {
    if (!totalSpend) {
      return {};
    }

    return {
      daypart: "Grand Total",
      weekday:
        R.pipe(
          R.filter(row => row.Network !== ""),
          R.map(
            row =>
              parseFloat(row.Cost) *
              parseFloat(row["Number of Spots"]) *
              DAY_ALLOCATION_MAP[row.Day][0]
          ),
          R.sum
        )(runResult) / totalSpend,
      weekend:
        R.pipe(
          R.filter(row => row.Network !== ""),
          R.map(
            row =>
              parseFloat(row.Cost) *
              parseFloat(row["Number of Spots"]) *
              DAY_ALLOCATION_MAP[row.Day][1]
          ),
          R.sum
        )(runResult) / totalSpend,
      pct: 1,
    };
  }, [runResult, totalSpend]);

  const availLengthTotalsRow = useMemo(() => {
    if (!totalSpend) {
      return {};
    }

    return {
      avail: "Grand Total",
      pct60s:
        R.pipe(
          R.filter(row => row.Network !== "" && row["Spot Length"] === "60"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(runResult) / totalSpend,
      pct30s:
        R.pipe(
          R.filter(row => row.Network !== "" && row["Spot Length"] === "30"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(runResult) / totalSpend,
      pct15s:
        R.pipe(
          R.filter(row => row.Network !== "" && row["Spot Length"] === "15"),
          R.map(row => parseFloat(row.Cost) * parseFloat(row["Number of Spots"])),
          R.sum
        )(runResult) / totalSpend,
      pct: 1,
    };
  }, [runResult, totalSpend]);

  const creativeTotalsRow = useMemo(() => ({ network: "Grand Total", spend: totalSpend, pct: 1 }), [
    totalSpend,
  ]);

  const onBuyableDataChange = useCallback(
    data => {
      setBuyableFilteredData(data);
    },
    [setBuyableFilteredData]
  );

  const buyableTotalsRow = useMemo(() => {
    let creativeTotals = {};
    if (buyableFilteredData.length > 0) {
      let creativeNames = R.without(
        [
          "network",
          "avail",
          "day",
          "daypart",
          "daypartName",
          "Rotation ID",
          "Creative",
          "spotLength",
          "secured",
          "cost",
          "Number of Spots",
          "totalSpots",
          "totalSpend",
        ],
        R.keys(buyableFilteredData[0])
      );
      creativeTotals = R.fromPairs(
        R.map(
          creativeName => [creativeName, R.sum(R.map(R.prop(creativeName), buyableFilteredData))],
          creativeNames
        )
      );
    }

    return R.mergeRight(
      {
        totalSpend: `$${R.sum(R.map(R.prop("totalSpend"), buyableFilteredData))}`,
        totalSpots: R.sum(R.map(R.prop("totalSpots"), buyableFilteredData)),
      },
      creativeTotals
    );
  }, [buyableFilteredData]);

  const buyableHeadersRenderer = useCallback(
    ({ data }) => {
      if (noSpacesCreativeMap[data]) {
        data = noSpacesCreativeMap[data];
      }
      return (
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
          overlay={<Tooltip>{data}</Tooltip>}
        >
          <span>{data}</span>
        </OverlayTrigger>
      );
    },
    [noSpacesCreativeMap]
  );

  const getCurrentInsightsCategories = useCallback(() => {
    return [...buyableDataByCreativeInsight.keys()];
  }, [buyableDataByCreativeInsight]);

  const calculateTrimmedRows = useCallback(
    ({ trimMornings, trimFridays, roundOdd15s, roundOddLocals, roundOddAll, creativeInsight }) => {
      const buyableDataRows =
        !creativeInsight || creativeInsight === ""
          ? buyableData
          : buyableDataByCreativeInsight.get(creativeInsight);

      const allCreativesKeys = R.pipe(
        R.keys,
        R.without([
          "avail",
          "cost",
          "Creative",
          "day",
          "daypart",
          "daypartName",
          "network",
          "Number of Spots",
          "Rotation ID",
          "secured",
          "spotLength",
          "totalSpend",
          "totalSpots",
        ]),
        R.sortBy(R.identity)
      )(buyableDataRows[0]);

      const header = R.concat(
        R.concat(
          [
            "networkGroup",
            "network",
            "avail",
            "rotationName",
            "daypart12hr",
            "dow",
            "type",
            "bonus",
            "length",
            "count",
            "cost",
            "spend",
          ],
          R.map(key => `creative['${key}']`, allCreativesKeys)
        ),
        ["notes"]
      );

      const rows = R.concat(
        [header],
        R.map(row => {
          const creativePercentages = CountsToPercentages(
            R.fromPairs(R.map(key => [key, row[key]], allCreativesKeys))
          );

          let { daypart } = row;
          if (
            trimMornings &&
            row.network !== "CNBC" &&
            (row.network !== "FRFM" || row.avail !== "L")
          ) {
            if (
              row.daypartName.indexOf("Daytime") >= 0 ||
              row.daypartName.indexOf("Weekend") >= 0
            ) {
              const splitDaypart = row.daypart.split("-");
              const parsedStartTime = Dfns.parse(new Date(), "hh:mma")(splitDaypart[0].trim());
              const parsedEndTime = Dfns.parse(new Date(), "hh:mma")(splitDaypart[1].trim());
              if (Dfns.getHours(parsedStartTime) < 10) {
                if (Dfns.getHours(parsedEndTime) >= 12) {
                  splitDaypart[0] = "10:00AM";
                } else if (Dfns.getHours(parsedEndTime) === 11) {
                  splitDaypart[0] = "9:00AM";
                }
                daypart = `${splitDaypart[0].trim()} - ${splitDaypart[1].trim()}`;
              }
            }
          }

          let dow = DOW_FOR_DAY[row.day];
          if (trimFridays) {
            dow = dow.replace("F", "-");
          }

          let { totalSpots } = row;
          if (totalSpots === 1) {
            totalSpots = 2;
          }
          if (roundOdd15s && row.spotLength === "15" && totalSpots % 2 === 1) {
            totalSpots++;
          }
          if (roundOddLocals && row.avail === "L" && totalSpots % 2 === 1) {
            totalSpots++;
          }
          if (roundOddAll && totalSpots % 2 === 1) {
            totalSpots++;
          }

          return R.concat(
            R.concat(
              [
                "",
                row.network,
                row.avail,
                `${row.daypartName} (${row.day} ${row.daypart.toLowerCase().replace(/ /g, "")})`,
                daypart.toLowerCase().replace(/ /g, ""),
                dow,
                row.secured,
                "false",
                row.spotLength,
                totalSpots,
                row.cost,
                totalSpots * row.cost,
              ],
              R.map(key => creativePercentages[key], allCreativesKeys)
            ),
            [""]
          );
        }, buyableDataRows)
      );
      return rows;
    },
    [buyableData, buyableDataByCreativeInsight]
  );

  const downloadCSV = useCallback(
    async rows => {
      const csv = Papa.unparse(rows);
      const excelResult = await JavaLambdaFetch("/csv_to_excel", {
        method: "POST",
        body: {
          csv,
        },
      });
      const excelJson = await awaitJSON(excelResult);
      await pollS3({
        bucket: "bpm-cache",
        mimeType: "application/vnd.ms-excel",
        filename: excelJson.s3Out,
        overloadFilename: `Optimization_${company}_${runId}.xlsx`,
        autoDownload: true,
      });
    },
    [company, runId]
  );

  const requestConstraintData = async runInfo => {
    const { build_number, company_id, branch } = runInfo;
    setConstraintRequestStatus(1);
    let res = await LinearOptimizationsLambdaFetch("/constraintsDebugger", {
      params: {
        branch,
        kpi: company_id,
        runId: build_number,
      },
    });
    res = await awaitJSON(res);
    if (isDownloadConstraintRequestData) {
      download(res.csv, `Constraints: ${"branch"}/${"kpi"}/${"runId"}.csv`, "text/csv");
    }

    // Transform data for table
    setConstraintsData(
      res.data.map(row => {
        // Parse Type
        let parsed = row.Name.split(":");
        if (parsed.length !== 1) {
          row.Name = parsed.slice(1).join("");
          row.constraintType = parsed[0];
        } else {
          row.constraintType = "Unknown";
        }
        // isBinding
        if (row.BindingMin === "1") {
          row.isBinding = "At Minimum";
        } else if (row.BindingMax === "1") {
          row.isBinding = "At Maximum";
        } else {
          row.isBinding = "Not binding";
        }
        // String -> Number
        row.FinalValue = parseFloat(row.FinalValue);
        row.FinalValueNonFractional = row.FinalValueNonFractional
          ? parseInt(row.FinalValueNonFractional)
          : "-";
        row.MinConstraint = parseInt(row.MinConstraint);
        row.MaxConstraint = parseInt(row.MaxConstraint);
        // Surplus / Deficit
        row.NonFractionalSurplus = "None";
        row.NonFractionalDeficit = "None";
        if (row.FinalValueNonFractional !== "-" && !isNaN(row.FinalValue)) {
          if (row.FinalValueNonFractional > row.MaxConstraint) {
            row.NonFractionalSurplus = row.FinalValueNonFractional - row.MaxConstraint;
          } else if (row.FinalValueNonFractional < row.MinConstraint) {
            row.NonFractionalDeficit = row.MinConstraint - row.FinalValueNonFractional;
          }
        }
        return row;
      })
    );

    setConstraintRequestStatus(2);
  };

  if (!runResult || !creativeInsightsData) {
    return <FullPageSpinner />;
  }

  const PresetFilters = () => {
    return (
      <>
        <div className="presetFilterBar">
          <div className="presetFilterBarTitle">Constraint Types</div>
          <div>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Budget"])}
              className="filterButton"
            >
              <MdAttachMoney />
              &nbsp;Budget
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Spot"])}
              className="filterButton"
            >
              <MdBubbleChart />
              &nbsp;Spot
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Audience"])}
              className="filterButton"
            >
              <MdPeople />
              &nbsp;Audience
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Avoid"])}
              className="filterButton"
            >
              <MdNotInterested />
              &nbsp;Avoid
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => setDynamicTokens(["Type", "is", "Global"])}
              className="filterButton"
            >
              <MdAllOut />
              &nbsp;Global
            </Button>
          </div>
        </div>
        <div className="presetFilterBar">
          <div className="presetFilterBarTitle">Preset Filters</div>
          <div>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Binding", "is", "At Minimum"])}
              className="filterButton"
            >
              Binding at minimum
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Binding", "is", "At Maximum"])}
              className="filterButton"
            >
              Binding at maximum
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Final Value", "is not", "0"])}
              className="filterButton"
            >
              Non-zero
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setDynamicTokens(["Final Value (Rounded)", "is not", "0"])}
              className="filterButton"
            >
              Non-zero rounded
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() =>
                setDynamicTokens([
                  "Constraint Violation Surplus",
                  "is not",
                  "None",
                  "or",
                  "Constraint Violation Deficit",
                  "is not",
                  "None",
                ])
              }
              className="filterButton"
            >
              Rounded value violates constraint
            </Button>
          </div>
        </div>
      </>
    );
  };

  if (selectedView === RUNS_SUMMARY_KEY) {
    return (
      <div className="resultPivot">
        <div className="resultPivotColumn left">
          <BPMTable
            headerHeight={40}
            headers={networkPivotHeader}
            data={networkPivot}
            filterBar={false}
            totals={networkTotalsRow}
            totalsRenderer={totalsRenderer}
          />
        </div>
        <div className="resultPivotColumn middle">
          <BPMTable
            headerHeight={40}
            headers={daypartPivotHeader}
            data={daypartPivot}
            filterBar={false}
            totals={daypartTotalsRow}
            totalsRenderer={totalsRenderer}
          />

          <BPMTable
            headerHeight={40}
            headers={availLengthPivotHeader}
            data={availLengthPivot}
            filterBar={false}
            totals={availLengthTotalsRow}
            totalsRenderer={totalsRenderer}
          />

          <BPMTable
            headerHeight={40}
            headers={creativePivotHeader}
            data={creativePivot}
            filterBar={false}
            totals={creativeTotalsRow}
            totalsRenderer={totalsRenderer}
          />
        </div>
        <div className="resultPivotColumn right">
          <BPMTable
            headerHeight={40}
            headers={overviewHeader}
            data={overviewData}
            filterBar={false}
          />
          <Button onClick={() => setShowExportDialog(true)}>Export</Button>
        </div>
        {showExportDialog && (
          <ExportRunModal
            calculateTrimmedRows={calculateTrimmedRows}
            downloadCSV={downloadCSV}
            onHide={() => setShowExportDialog()}
            getInsightsCategories={getCurrentInsightsCategories}
          />
        )}
      </div>
    );
  } else if (selectedView === RUNS_BUYABLE_KEY) {
    return (
      <div className="resultBuyable">
        {showExportDialog && (
          <ExportRunModal
            calculateTrimmedRows={calculateTrimmedRows}
            downloadCSV={downloadCSV}
            onHide={() => setShowExportDialog()}
            getInsightsCategories={getCurrentInsightsCategories}
          />
        )}
        <BPMTable
          headerHeight={40}
          headersRenderer={buyableHeadersRenderer}
          headers={buyableHeader}
          data={buyableData}
          onFilteredDataChange={onBuyableDataChange}
          totals={buyableTotalsRow}
          totalsRenderer={buyableTotalsRenderer}
          additionalControls={
            <Button variant="outline-secondary" onClick={() => setShowExportDialog(true)}>
              <MdSave />
            </Button>
          }
        />
      </div>
    );
  } else if (selectedView === RUNS_CONSTRAINTS_KEY) {
    return (
      <div className="resultConstraints">
        {constraintRequestStatus === 0 ? (
          <div className="beforeFetch">
            <Button
              className="downloadButton"
              variant="outline-secondary"
              onClick={() => requestConstraintData(runInfo)}
              disabled={!runInfo}
            >
              Download constraint data {runInfo ? false : <Spinner />}
            </Button>
            <div className="checkboxRow">
              <Button className="acceptedButton" variant="link" disabled>
                Display in table <MdCheckBox />
              </Button>
              <Button
                className="acceptedButton"
                variant="link"
                onClick={() => setIsDownloadConstraintRequestData(!isDownloadConstraintRequestData)}
              >
                Save file locally{" "}
                {isDownloadConstraintRequestData ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
              </Button>
            </div>
          </div>
        ) : constraintRequestStatus === 1 ? (
          <div>
            <Spinner size={50} style={{ marginTop: "35px" }} />
          </div>
        ) : (
          <>
            <PresetFilters />
            <BPMTable
              headerHeight={40}
              defaultAdvancedFilter
              dynamicTokens={dynamicTokens}
              setDynamicTokens={setDynamicTokens}
              headers={constraintsHeader}
              data={constraintsData}
              onFilteredDataChange={onBuyableDataChange}
            />
          </>
        )}
      </div>
    );
  }
});

export default RunView;
