import React, { useMemo } from "react";
import * as R from "ramda";
import { EditsMap, makeSummaryData, mergeAllRows, NielsenEstimates } from "./linearBuyingUtils";
import { PlanRow } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { CellRenderer, BPMTable } from "../Components";
import { CreativeMap } from "../redux/creative";
import { formatMoney, formatMoneyAsInt, formatNumberAsInt } from "../utils/format-utils";

interface SummaryViewProps {
  rows: PlanRow[];
  editsMap: EditsMap;
  newRows: Record<string, PlanRow>;
  creativeMap: CreativeMap | undefined;
  weeks: string[];
  nielsenEstimates: NielsenEstimates;
}

interface SpendObjTable {
  spend: number | string;
  percent: number | string;
}

interface AvailLengthObjTable {
  "15"?: number | string;
  "30"?: number | string;
  totalPercentByAvail: number | string;
  totalSpendByAvail: number | string;
}

const makeTableHeaders = (primaryColumnLabel: string, primaryColumnName: string) => {
  const headers = [
    {
      label: primaryColumnLabel,
      name: primaryColumnName,
      flex: 1,
      minFlexWidth: 300,
    },
    {
      label: "Spend",
      name: "spend",
      flex: 1,
      renderer: data => formatMoneyAsInt(data.spend),
    },
    {
      label: "Percent",
      name: "percent",
      flex: 1,
      renderer: data => `${Math.round(data.percent * 100)}%`,
    },
    {
      label: "Impressions",
      name: "impressions",
      flex: 1,
      renderer: data => formatNumberAsInt(data.impressions),
    },
    {
      label: "CPM",
      name: "cpm",
      flex: 1,
      renderer: data => formatMoney(data.cpm, 2),
    },
  ];
  return headers;
};

const AVAIL_LENGTH_HEADERS = [
  {
    label: "Avail",
    name: "avail",
    flex: 1,
    minFlexWidth: 75,
  },
  {
    label: "15s",
    name: "15",
    flex: 1,
    minFlexWidth: 75,
    renderer: data => (data[15] ? `${Math.round(data[15] * 100)}%` : "-"),
  },
  {
    label: "30s",
    name: "30",
    flex: 1,
    minFlexWidth: 75,
    renderer: data => (data[30] ? `${Math.round(data[30] * 100)}%` : "-"),
  },
  {
    label: "% of Spend",
    name: "totalPercentByAvail",
    flex: 1,
    minFlexWidth: 75,
    renderer: data => `${Math.round(data.totalPercentByAvail * 100)}%`,
  },
  {
    label: "Total Spend",
    name: "totalSpendByAvail",
    flex: 1,
    minFlexWidth: 100,
    renderer: data => formatMoneyAsInt(data.totalSpendByAvail),
  },
];

const SummaryView: React.FC<SummaryViewProps> = ({
  rows,
  editsMap,
  newRows,
  creativeMap,
  weeks,

  nielsenEstimates,
}) => {
  let newRowsForCurrentWeek: Record<string, PlanRow> = {};

  let editsForCurrentWeeks: EditsMap = {};
  for (let week of weeks) {
    newRowsForCurrentWeek = {
      ...newRowsForCurrentWeek,
      ...R.pickBy(val => val.week === week, newRows),
    };
    editsForCurrentWeeks = {
      ...editsForCurrentWeeks,
      ...R.pickBy(val => val.week === week, editsMap),
    };
  }

  const allRows = mergeAllRows(rows, editsForCurrentWeeks, newRowsForCurrentWeek);

  const { totalSpend, byLength, byNetwork, byRotation, byCreative, byAvailLength } = useMemo(() => {
    return makeSummaryData({ rows: allRows, nielsenEstimates, creativeMap });
  }, [allRows, nielsenEstimates, creativeMap]);

  const prettyTotalSpend = formatMoneyAsInt(totalSpend);
  const totalsRow = { spend: prettyTotalSpend, percent: "100%" };
  const availLengthTotalsRow = useMemo(() => {
    let total15 = 0;
    let total30 = 0;
    for (let row of byAvailLength) {
      total15 += row[15] || 0;
      total30 += row[30] || 0;
    }

    const total = {
      15: total15 ? `${Math.round(total15 * 100)}%` : "-",
      30: total30 ? `${Math.round(total30 * 100)}%` : "-",
      totalPercentByAvail: "100%",
      totalSpendByAvail: prettyTotalSpend,
    };
    return total;
  }, [byAvailLength, prettyTotalSpend]);

  const renderTotals: CellRenderer<Element | number | string | undefined> = ({
    data,
    style = {},
    classes = [] as string[],
  }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  return (
    <div className="summaryView">
      <div className="leftSide">
        <BPMTable<SpendObjTable>
          data={byNetwork}
          totals={totalsRow}
          totalsRenderer={renderTotals}
          headers={makeTableHeaders("Network", "network")}
          filterBar={false}
          headerHeight={50}
          rowHeight={40}
        />
        <BPMTable<AvailLengthObjTable>
          data={byAvailLength}
          totals={availLengthTotalsRow}
          totalsRenderer={renderTotals}
          headers={AVAIL_LENGTH_HEADERS}
          filterBar={false}
          headerHeight={50}
          rowHeight={40}
        />
        <BPMTable<SpendObjTable>
          data={byLength}
          totals={totalsRow}
          totalsRenderer={renderTotals}
          headers={makeTableHeaders("Length", "length")}
          filterBar={false}
          headerHeight={50}
          rowHeight={40}
        />
      </div>
      <div className="rightSide">
        <BPMTable<SpendObjTable>
          data={byRotation}
          totals={totalsRow}
          totalsRenderer={renderTotals}
          headers={makeTableHeaders("Rotation", "rotation")}
          filterBar={false}
          headerHeight={50}
          rowHeight={40}
        />

        <BPMTable<SpendObjTable>
          data={byCreative}
          totals={totalsRow}
          totalsRenderer={renderTotals}
          headers={makeTableHeaders("Creative", "creative")}
          filterBar={false}
          headerHeight={50}
          rowHeight={40}
        />
      </div>
    </div>
  );
};

export default SummaryView;
