import "./FetchFilterBar.scss";
import { Button, ButtonType } from "../../Components";
import { Category, FilterPaneAdvancedState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import {
  filterTokenizer,
  makeSuggestionGenerator,
} from "@blisspointmedia/bpm-types/dist/advancedFilterParser";
import { Form } from "react-bootstrap";
import { StateSetter } from "../../utils/types";
import * as R from "ramda";
import React, { useCallback, useMemo, useRef, useState } from "react";

import {} from "react-icons/md";

interface FilterPaneAdvancedProps {
  categories: Category[];
  compileError: string;
  readonly?: boolean;
  setEditing: StateSetter<boolean>;
  setState: StateSetter<FilterPaneAdvancedState>;
  state: string;
}

const suggestionHelpMap = {
  "=": "= (equal to)",
  "!=": "!= (not equal to)",
  "~": "~ (like)",
  "!~": "!~ (not like)",
};

export const FilterPaneAdvanced: React.FC<FilterPaneAdvancedProps> = ({
  categories,
  compileError,
  readonly,
  setEditing,
  setState,
  state,
}) => {
  const advancedText = state;
  const setAdvancedTextRaw = useCallback((text: string) => setState(text), [setState]);

  const [cursorPosition, setCursorPosition] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateCursorPosition = useCallback(() => {
    const pos = textAreaRef.current?.selectionStart;
    if (!R.isNil(pos)) {
      setCursorPosition(pos);
    }
  }, []);

  const setAdvancedText = useCallback(
    (text: string) => {
      setAdvancedTextRaw(text);
      updateCursorPosition();
    },
    [updateCursorPosition, setAdvancedTextRaw]
  );

  const forceCursorPosition = useCallback((pos: number) => {
    if (textAreaRef.current) {
      textAreaRef.current.setSelectionRange(pos, pos);
      setCursorPosition(pos);
    }
  }, []);

  const suggestionGenerator = useMemo(() => makeSuggestionGenerator(categories), [categories]);

  const suggestionObject = useMemo(() => {
    const tokens = filterTokenizer(advancedText);
    const suggestionObject = suggestionGenerator(advancedText, tokens, cursorPosition);
    return suggestionObject;
  }, [advancedText, suggestionGenerator, cursorPosition]);

  const insertText = useCallback(
    (text: string) => {
      const [newText, newPos] = suggestionObject.inserter(text);
      setAdvancedText(newText);
      forceCursorPosition(newPos);
    },
    [forceCursorPosition, suggestionObject, setAdvancedText]
  );

  return (
    <div className="filterPaneBody">
      <div className="filterPaneAdvancedHeader">Advanced Filter</div>
      <div className="filterPaneAdvancedBoxContainer">
        <Form.Control
          disabled={readonly}
          spellCheck={false}
          ref={textAreaRef}
          className="filterPaneAdvancedBox"
          as="textarea"
          rows={6}
          value={advancedText}
          onKeyUp={updateCursorPosition}
          onClick={updateCursorPosition}
          onChange={e => setAdvancedText(e.currentTarget.value)}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
        />
      </div>
      {compileError && <div className="compileError">{compileError}</div>}
      {!readonly && (
        <div className="suggestionList">
          {suggestionObject.suggestions.map(suggestion => {
            return (
              <Button
                background="light"
                className="suggestion"
                design="secondary"
                key={suggestion}
                onClick={() => insertText(suggestion)}
                size="sm"
                type={ButtonType.FILLED}
              >
                {suggestion === ""
                  ? '""'
                  : suggestion && suggestionHelpMap[suggestion]
                  ? suggestionHelpMap[suggestion]
                  : suggestion}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterPaneAdvanced;
