import React, { useMemo, useCallback } from "react";
import * as R from "ramda";
import { MdWarning } from "react-icons/md";

import { Alert, Card, Modal, Tooltip } from "react-bootstrap";
import { BPMButton, PieSetter, OverlayTrigger } from "../../Components";
import { EditableCard } from "../../StreamingCreatives/Cards";
import { getCreativeOptions, EditsMap } from "../linearBuyingUtils";
import { PlanRow, ISCI } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { CreativeMap } from "../../redux/creative";
import { useMap } from "../../utils/hooks/useData";
import { StateSetter } from "../../utils/types";

import "./CreativeAllocationModal.scss";

interface CreativeAllocationModalProps {
  planRow: PlanRow;
  creativeMap: CreativeMap;
  colorMap: Record<string, string>;
  setEditsMap: StateSetter<EditsMap>;
  setNewRows: StateSetter<Record<string, PlanRow>>;
  onClose: () => void;
}

export const CreativeAllocationModal: React.FC<CreativeAllocationModalProps> = ({
  planRow,
  creativeMap,
  colorMap,
  setEditsMap,
  setNewRows,
  onClose,
}) => {
  const { creatives, avail, length, network, rotation, key, isNewRow, plan_id, count } = planRow;

  const creativeOptions = useMemo(() => {
    return getCreativeOptions(planRow, creativeMap, colorMap);
  }, [colorMap, creativeMap, planRow]);

  const [iscisToPercentages, setIsciToPercentage] = useMap<string, number>({});

  // If there's an edited rotation, use that. Otherwise, use the original.
  let rotationToUse = creatives;
  if (planRow.edits && R.has("creatives", planRow.edits)) {
    rotationToUse = R.path(["edits", "creatives"], planRow);
  }

  let currentPercentageMap = useMemo(() => {
    let map: Record<string, number> = {};

    if (rotationToUse) {
      for (let creative of rotationToUse) {
        if (creative.isci) {
          map[creative.isci] = creative.percent || 0;
        }
      }
    }
    return map;
  }, [rotationToUse]);

  // Combine existing rotation with changes and remove allocations that aren't valid.
  const sanitizedAllocations = useMemo(() => {
    return R.pipe(
      R.mergeRight(currentPercentageMap),
      R.pickBy(val => !R.isNil(val) && !isNaN(val))
    )(iscisToPercentages) as Record<string, number>;
  }, [currentPercentageMap, iscisToPercentages]);

  const allocationSum = useMemo(() => {
    return Object.values(sanitizedAllocations).reduce((a, b) => {
      return a + (b || 0);
    }, 0);
  }, [sanitizedAllocations]);

  // Checks if the rotation equals 100%
  const isValidTotalPercentage = useMemo(() => {
    return allocationSum === 100;
  }, [allocationSum]);

  // Checks if there are more creatives than unit count. i.e. don't allow 50/50 rotation for 1 spot
  const isValidCreativeCount = useMemo(() => {
    const allocationsWithoutZeroes = R.pickBy(
      val => !R.isNil(val) && !isNaN(val) && val !== 0,
      sanitizedAllocations
    );
    return !(R.keys(allocationsWithoutZeroes).length > count);
  }, [sanitizedAllocations, count]);

  const isValidAllocation = useMemo(() => {
    return isValidTotalPercentage && isValidCreativeCount;
  }, [isValidTotalPercentage, isValidCreativeCount]);

  const onSubmit = useCallback(() => {
    // If there are no changes, do nothing.
    if (R.isEmpty(iscisToPercentages)) {
      onClose();
      return;
    }

    // Combine existing rotation with changes and remove allocations that aren't valid.
    let sanitizedAllocations = R.pipe(
      R.mergeRight(currentPercentageMap),
      R.pickBy(val => !R.isNil(val) && !isNaN(val))
    )(iscisToPercentages) as Record<string, number>;

    // Construct metadata using new rotation.
    // If edited an existing ISCI, just change the percent. If new, create new metadata for it.
    let updatedRotation: ISCI[] = [];

    for (let isci of R.keys(sanitizedAllocations)) {
      let existingMetadata = R.find(row => row.isci === isci, planRow.creatives || []);

      if (existingMetadata) {
        let updated: ISCI = { ...existingMetadata, percent: sanitizedAllocations[isci] };
        updatedRotation.push(updated);
      } else {
        let newIsci: ISCI = {
          plan_id: plan_id || null,
          isci,
          percent: sanitizedAllocations[isci],
          isPending: true,
        };
        updatedRotation.push(newIsci);
      }
    }

    // If the updated rotation is the same as the original, do nothing.
    if (R.equals(updatedRotation, creatives)) {
      onClose();
      return;
    }

    let edit = { creatives: updatedRotation };

    if (isNewRow) {
      setNewRows(current => ({ ...current, [key]: { ...current[key], ...edit } }));
    } else {
      setEditsMap(current => ({
        ...current,
        [key]: { ...planRow, edits: { ...R.path([key, "edits"], current), ...edit } },
      }));
    }

    onClose();
  }, [
    currentPercentageMap,
    iscisToPercentages,
    planRow,
    plan_id,
    key,
    isNewRow,
    creatives,
    setEditsMap,
    setNewRows,
    onClose,
  ]);
  const hasNonLiveCreative = useMemo(() => {
    let hasNonLive = false;
    for (let creative of creativeOptions) {
      if (creative.notLive) {
        hasNonLive = true;
        break;
      }
    }
    return hasNonLive;
  }, [creativeOptions]);

  return (
    <Modal show size="xl" onHide={onClose} className="creativeAllocationModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalHeader">
          <div className="title">{`${network} - ${avail} - ${rotation} - ${length}s - ${count} spot(s)`}</div>
          {hasNonLiveCreative && (
            <OverlayTrigger
              placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
              overlay={
                <Tooltip id={network}>
                  <>
                    There is at least one creative in this rotation that is not live on linear
                    during the currently selected week. You should probably remove it from the
                    rotation.
                  </>
                </Tooltip>
              }
            >
              <MdWarning color="orange" />
            </OverlayTrigger>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="workspace">
          <Card className="workspaceCard">
            <div className="workspaceCardInner">
              <PieSetter
                pieFlex={0.5}
                percentage
                data={R.map(creative => {
                  const currentValue =
                    iscisToPercentages[creative.isci] ?? currentPercentageMap[creative.isci];
                  return {
                    ...creative,
                    key: creative.isci,
                    value: currentValue || 0,
                  };
                }, creativeOptions)}
                renderItem={elem => (
                  <EditableCard
                    {...elem}
                    onChange={(isci, value) => {
                      // If you type 0, you get 0 as the value. But if you delete the value, it becomes
                      // NaN. We want it to be 0 so that it doesn't get filtered out in the onSubmit.
                      let convertedValue = value || 0;
                      if (convertedValue < 0) {
                        convertedValue = Math.abs(convertedValue);
                      }
                      setIsciToPercentage(isci, convertedValue);
                    }}
                  />
                )}
              />
            </div>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isValidTotalPercentage && (
          <Alert variant="danger">{`Your rotation is off by ${Math.abs(
            100 - allocationSum
          )}%`}</Alert>
        )}
        {!isValidCreativeCount && (
          <Alert variant="danger">
            The rotation doesn't make sense with the current unit count
          </Alert>
        )}
        <BPMButton onClick={onClose}>Cancel</BPMButton>
        <BPMButton disabled={!isValidAllocation} onClick={onSubmit}>
          Okay
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CreativeAllocationModal;
