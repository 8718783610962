import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { isAfter, isBefore, startOfToday } from "date-fns";
import { Button, ButtonType, Spinner } from "../../Components";
import DateInputField from "../../Components/DatePicker/DateInputField/DateInputField";
import "./AddInputModal.scss";
import "../../Components/FormCheck.scss";

interface AddOutcomeDataModalProps {
  show: boolean;
  handleClose: () => void;
  createTable: (
    kpiName: string,
    kpiDescription: string,
    unitType: "Dollars" | "Count",
    startDate: Date,
    endDate?: Date
  ) => void;
}

const AddOutcomeDataModal: React.FC<AddOutcomeDataModalProps> = ({
  show,
  handleClose,
  createTable,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [kpiName, setKpiTitle] = useState("");
  const [kpiDescription, setKpiDescription] = useState("");
  const [unitType, setUnitType] = useState<"Dollars" | "Count" | "">("");
  const [startDateStr, setStartDateStr] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateStr, setEndDateStr] = useState("");
  const [endDateError, setEndDateError] = useState(false);

  // Resize textarea for kpiDescription when content is updated
  useEffect(() => {
    const textarea = document.getElementById("kpiDescriptionTextarea");

    if (textarea) {
      textarea.style.height = `${Math.max(textarea.scrollHeight, 64)}px`;
    }
  }, [kpiDescription]);

  const resetState = () => {
    setIsSubmitting(false);
    setKpiTitle("");
    setKpiDescription("");
    setUnitType("");
    setStartDateStr("");
    setStartDateError(false);
    setEndDateStr("");
    setEndDateError(false);
  };

  const submitDisabled =
    isSubmitting ||
    !kpiName ||
    !kpiDescription ||
    kpiDescription.length > 150 ||
    !unitType ||
    !startDateStr ||
    startDateError ||
    endDateError;

  return (
    <Modal
      className="addInputModal addOutcomeDataModal"
      show={show}
      onHide={handleClose}
      size="xl"
      centered
    >
      <Modal.Header>
        <h4 className="modalTitle">Add Outcome Data</h4>
        <div className="subTitle">*Required fields marked with an asterisk.</div>
      </Modal.Header>
      <Modal.Body>
        <div className="kpiNameInput">
          <div className="sectionTitle">
            KPI Name<span className="asterisk">*</span>
          </div>
          <Form.Control
            placeholder="Ex: Web Order"
            value={kpiName}
            onChange={e => setKpiTitle(e.target.value)}
          />
          <div className="inputExplanation">
            <p>A measurable target that indicates business performance.</p>
          </div>
        </div>
        <div className="kpiDescriptionInput">
          <div className="sectionTitle">
            KPI Description<span className="asterisk">*</span>
          </div>
          <Form.Control
            as="textarea"
            id="kpiDescriptionTextarea"
            placeholder="Ex: A customer places an order on our website."
            value={kpiDescription}
            onChange={e => setKpiDescription(e.target.value)}
            isInvalid={kpiDescription.length > 150}
          />
          <Form.Control.Feedback type="invalid">Max 150 characters</Form.Control.Feedback>
          <div className="inputExplanation">
            <p>A descrpition of how the KPI event happens.</p>
          </div>
        </div>
        <div className="unitTypeInput">
          <div className="sectionTitle">
            Type of Unit<span className="asterisk">*</span>
          </div>
          <Form.Check
            type="radio"
            label="Dollars"
            id="dollars"
            checked={unitType === "Dollars"}
            onChange={() => setUnitType("Dollars")}
          />
          <Form.Check
            type="radio"
            label="Count/Volume"
            id="count"
            checked={unitType === "Count"}
            onChange={() => setUnitType("Count")}
          />
        </div>
        <div className="timeFrameInput">
          <div className="sectionTitle">Time Frame</div>
          <DateInputField
            label={
              <>
                Start Date<span className="asterisk">*</span>
              </>
            }
            value={startDateStr}
            setValue={setStartDateStr}
            customValidation={[
              {
                validate: value => {
                  const parsedDate = new Date(value);

                  const isValid = isBefore(parsedDate, startOfToday());
                  setStartDateError(!isValid);
                  return isValid;
                },
                errorMessage: "Start date must be in the past.",
              },
            ]}
            infoMessage="mm/dd/yyyy"
          />
          <DateInputField
            label="End Date (optional)"
            className="optional"
            value={endDateStr}
            setValue={setEndDateStr}
            customValidation={[
              {
                validate: value => {
                  const parsedDate = new Date(value);

                  const isValid = isBefore(parsedDate, startOfToday());
                  setEndDateError(!isValid);
                  return isValid;
                },
                errorMessage: "End date must be in the past.",
              },
              {
                validate: value => {
                  const parsedDate = new Date(value);
                  const parsedStartDate = new Date(startDateStr);

                  const isValid = isAfter(parsedDate, parsedStartDate);
                  setEndDateError(!isValid);
                  return isValid;
                },
                errorMessage: "End date must be after start date.",
              },
            ]}
            infoMessage="mm/dd/yyyy"
          />
          <div className="inputExplanation dateInputExplanation">
            <p>
              Important: Data should be entered daily whenever possible. If your data has been
              aggregated, please disaggregate it to the daily level.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={ButtonType.FILLED}
          design="secondary"
          onClick={() => {
            handleClose();
            resetState();
          }}
        >
          Cancel
        </Button>
        <Button
          type={ButtonType.FILLED}
          onClick={async () => {
            setIsSubmitting(true);
            await createTable(
              kpiName,
              kpiDescription,
              unitType as "Dollars" | "Count",
              new Date(startDateStr),
              endDateStr ? new Date(endDateStr) : undefined
            );
            handleClose();
            resetState();
          }}
          disabled={submitDisabled}
        >
          {isSubmitting ? <Spinner /> : "Apply"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOutcomeDataModal;
