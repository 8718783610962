import React from "react";

import { MdFirstPage, MdLastPage } from "react-icons/md";

import { VscGripper } from "react-icons/vsc";

import { OverlayTrigger, BPMButton, Tooltip } from "../../Components";

interface ColumnTopProps {
  noHeaders?: boolean;
  hasHeader?: boolean;
  onSuperHeaderAdd?: () => void;
  handleRef: React.Ref<HTMLDivElement>;
  i: number;
  addColumn: (i: number) => void;
  color?: string;
}

const ColumnTop: React.FC<ColumnTopProps> = ({
  color,
  onSuperHeaderAdd,
  hasHeader,
  handleRef,
  i,
  addColumn,
  noHeaders,
}) => (
  <>
    <div className="stripe" style={{ backgroundColor: color || "black" }} />
    <div className="topActions">
      <OverlayTrigger placement="top left" overlay={<Tooltip>Drag and drop</Tooltip>}>
        <div className="dragHandle" ref={handleRef}>
          <VscGripper />
        </div>
      </OverlayTrigger>
      <div className="addActions">
        <div className="addLabel">Add:</div>
        <OverlayTrigger placement="top right" overlay={<Tooltip>Column to the left</Tooltip>}>
          <BPMButton variant="outline-primary" size="sm" onClick={() => addColumn(i)}>
            <MdFirstPage />
          </BPMButton>
        </OverlayTrigger>
        {!noHeaders && (
          <OverlayTrigger
            placement="top center"
            overlay={<Tooltip>{hasHeader ? "There is already a header" : "Header above"}</Tooltip>}
          >
            <BPMButton
              variant="outline-primary"
              size="sm"
              className="addHeaderButton"
              onClick={() => !hasHeader && onSuperHeaderAdd && onSuperHeaderAdd()}
            >
              <MdFirstPage className="addHeaderIcon" />
            </BPMButton>
          </OverlayTrigger>
        )}
        <OverlayTrigger placement="top left" overlay={<Tooltip>Column to the right</Tooltip>}>
          <BPMButton variant="outline-primary" size="sm" onClick={() => addColumn(i + 1)}>
            <MdLastPage />
          </BPMButton>
        </OverlayTrigger>
      </div>
    </div>
  </>
);

export default ColumnTop;
