import "../IncrementalityPlaybook.scss";
import { Img } from "../../Components";
import { INC_CDN, IncPlaybookSectionBox, navigateToSection } from "../IncrementalityPlaybook";
import { methodHeaders } from "./Shopping";
import { playbookTable } from "../PlaybookTable";
import { testHeaders } from "./Amazon";
import { typedReactMemo } from "../../utils/types";
import * as R from "ramda";

const PAGE_TITLE = "Pinterest";

const Pinterest = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection center">
            <div className="basicText">
              Pinterest is a primarily image-based social media site that uses its visual discovery
              engine for finding ideas, inspiration, and more. The platform has Pins (individual
              images) and boards (collections of pins) that users are able to browse through. With a
              plethora of users, user split incrementality is the in-platform methodology to measure
              the effectiveness of ads on the platform.
            </div>
            <br />
            <Img src={`${INC_CDN}/Pinterest_IncrementalConversions.png`} />
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {R.map(
              section => {
                return (
                  <a
                    href={`#${section.replace(/ /g, "-").toLowerCase()}`}
                    onClick={() => navigateToSection(section.replace(/ /g, "-").toLowerCase())}
                  >
                    {section}
                  </a>
                );
              },
              [
                "Incrementality Testing Approaches",
                "Considerations",
                "Sample Output",
                "Signal Enrichment",
              ]
            )}
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Contents"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Method: "Pinterest Conversion Lift",
                  "Method Type": [
                    {
                      img: "Icon_ExperimentMode_PlatformNative.png",
                      label: "Platform Native",
                    },
                  ],
                  Description: (
                    <ul className="basicList">
                      <li>
                        The Pinterest Conversion Lift test is a user-based experiment where a target
                        audience is randomly split into treatment and control groups.
                      </li>
                      <li>
                        The treatment group gets ads displayed to them while the control group is
                        withheld from seeing the ads.
                      </li>
                      <li>
                        The effect of the ads (lift) is then estimated by Lift = (Treatment sales -
                        Control Sales) / Control Sales
                      </li>
                    </ul>
                  ),
                  Pros: (
                    <ul className="basicList">
                      <li>Establishes baseline of ad effectiveness through causal inference.</li>
                      <li>Includes power analysis to help ensure detectable lift in results.</li>
                      <li>Multi-cell test coming soon.</li>
                    </ul>
                  ),
                  Cons: (
                    <ul className="basicList">
                      <li>
                        The Pinterest methodology only accounts for users who are logged in. Their
                        estimates are ~ 50% of conversions come from users that are not logged in,
                        thus roughly half the conversions are not tracked.
                      </li>
                      <li>
                        Due to only tracking users who are logged on, a user may see an ad if they
                        are on a separate device where they are not logged on.
                      </li>
                    </ul>
                  ),
                },
              ],
              methodHeaders,
              500
            )}
          </div>
        }
        id={"incrementality-testing-approaches"}
        pageTitle={PAGE_TITLE}
        title={"Incrementality Testing Approaches"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            {playbookTable(
              [
                {
                  Element: "Timeline",
                  Details: (
                    <ul className="basicList">
                      <li>To kick off the test reach out to your Pinterest partner</li>
                      <li>
                        Pre-campaign (about 4 weeks to test launch)
                        <ul>
                          <li>Get alignment on KPI, Study criteria, deliverables</li>
                          <li>Review creative and set up campaigns</li>
                        </ul>
                      </li>
                      <li>
                        Holdout activation (about 2 weeks to test launch)
                        <ul>
                          <li>Apply the holdout groups if the campaign is already live</li>
                        </ul>
                      </li>
                      <li>Test launch (test launch date to about 4+ weeks post-launch)</li>
                      <li>
                        Post Campaign (4+ weeks post-test launch)
                        <ul>
                          <li>
                            Pinterest will prepare and deliver the final deliverables on the
                            analysis
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ),
                },
                {
                  Element: "Testable Outcomes",
                  Details: (
                    <ul className="basicList">
                      <li>Value</li>
                      <li>Order Quantity</li>
                      <li>View Category</li>
                      <li>Search</li>
                      <li>Add To Cart</li>
                      <li>Watch Video</li>
                      <li>Lead</li>
                      <li>Page Visit</li>
                      <li>Signup</li>
                    </ul>
                  ),
                },
              ],
              testHeaders,
              300
            )}
          </div>
        }
        id={"considerations"}
        pageTitle={PAGE_TITLE}
        title={"Considerations"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <iframe
              allowFullScreen
              title="Sample Output"
              src="https://drive.google.com/file/d/16_5H89oOAzmuht8nZCGeHtA6Vc6byM7q/preview?authuser=0"
            />
          </div>
        }
        id={"sample-output"}
        pageTitle={PAGE_TITLE}
        title={"Sample Output"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              For Pinterest Conversion Lift, it is strongly recommended that the{" "}
              <a href="https://help.pinterest.com/en/business/article/the-pinterest-api-for-conversions">
                Pinterest API for Conversions
              </a>{" "}
              be implemented in order to strengthen conversion modeling and increase the overall
              fidelity of measurement. This is especially important for tests of lower-funnel
              outcomes.
            </div>
            <br />
            <div className="basicText">
              The Pinterest API for Conversions enables advertisers to send conversions directly to
              Pinterest via a server-to-server connection. Through the API, advertisers can send web
              conversions, in-app conversions, and offline conversions.
            </div>
          </div>
        }
        id={"signal-enrichment"}
        pageTitle={PAGE_TITLE}
        title={"Signal Enrichment"}
      />
    </div>
  );
});

export default Pinterest;
