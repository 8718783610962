import { engineeringUsers, productUsers } from "./experiment-utils";

export const shouldEnableMMMOfflineInputs = (userEmail: string): boolean => {
  const users: string[] = [
    "philip.rundall@tinuiti.com",
    "taurey.carr@tinuiti.com",
    "sean.odlum@tinuiti.com",
    "will.hammond@tinuiti.com",
    "doug.macdonald@tinuiti.com",
    "galen.murray@tinuiti.com",
    "automation@tinuiti.com",
    ...productUsers,
    ...engineeringUsers,
  ];
  return users.includes(userEmail);
};
