import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import {
  MEDIA_TYPE_TO_PRETTY_NAME,
  HOME_PAGE_MEDIA_TYPES,
  MEDIA_TYPE_TO_TITLE_COLOR,
} from "./homePageConstants";
import "./PerformanceSnapshotLegend.scss";

interface PerformanceSnapshotLegendProps {
  mediaTypes: string[];
  data: any;
  selectedPerformanceView: string;
}

const PerformanceSnapshotLegend: React.FC<PerformanceSnapshotLegendProps> = ({
  mediaTypes,
  data,
  selectedPerformanceView,
}) => {
  const dataMap = (data || []).reduce((acc, row) => {
    acc[row.mediaType] = row;
    return acc;
  }, {});
  return (
    <div className="performanceSnapshotLegend">
      {HOME_PAGE_MEDIA_TYPES.map(type => {
        const ourData = dataMap[type];
        if (mediaTypes.includes(type) && ourData) {
          const diff =
            ourData[`${selectedPerformanceView}LastMonth`] -
            ourData[`${selectedPerformanceView}LastWeek`];
          const percentDiff = Math.round(
            (Math.abs(diff) / ourData[`${selectedPerformanceView}LastMonth`]) * 100
          );
          return (
            <div className="legendItem" key={type}>
              <div className="title" style={{ color: MEDIA_TYPE_TO_TITLE_COLOR[type] }}>
                {MEDIA_TYPE_TO_PRETTY_NAME[type]}
              </div>
              <div className="info">
                <div className="label">
                  <span>{diff < 0 ? <MdArrowUpward /> : <MdArrowDownward />}</span>
                  <span>{diff < 0 ? "increase" : "decrease"}</span>
                </div>
                <div className="difference">
                  <span>
                    {" "}
                    {percentDiff === Infinity ? "" : `${diff < 0 ? "+" : "-"}${percentDiff}%`}
                  </span>
                </div>
              </div>
            </div>
          );
        } else {
          return <React.Fragment key={type}></React.Fragment>;
        }
      })}
    </div>
  );
};

export default PerformanceSnapshotLegend;
