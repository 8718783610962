import React, { useMemo, useCallback, useContext } from "react";

import Select from "react-select";

import { Img } from "../Components";

import { ActiveOTTContext } from "./ActiveOTT";

import "./CompanyDropdown.scss";

const CompanyDropdown = () => {
  const { selectedGroup, setSelectedGroup, companyOptions } = useContext(ActiveOTTContext);

  const onChange = useCallback(
    ({ value }) => {
      setSelectedGroup(value.cid);
    },
    [setSelectedGroup]
  );

  const value = useMemo(
    () => ({
      value: selectedGroup,
      label: selectedGroup.cid,
    }),
    [selectedGroup]
  );

  const options = useMemo(
    () => companyOptions.map(value => ({ value, label: value.cid })),
    [companyOptions]
  );

  return (
    <div className="activeOTTCompanyPicker">
      <div className="logo">
        <Img src={`https://cdn.blisspointmedia.com/companies/${selectedGroup.cid}/logo.png`} />
      </div>
      <div className="select">
        <Select isSearchable value={value} options={options} onChange={onChange} />
      </div>
    </div>
  );
};

export default CompanyDropdown;
