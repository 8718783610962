import { DateRange } from "../utils/types";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";

// Static dates to use for on certain pages (for client demo purposes).
const DEFAULT_WEEK = "2023-01-23";
const DEFAULT_START_DATE = "2023-05-22";
const DEFAULT_END_DATE = "2023-06-25";

/**
 * Override the date for test & prospect companies.
 */
export const overrideDate = (company: string, date: string): string => {
  if (TEST_COMPANIES.includes(company)) {
    return DEFAULT_WEEK;
  } else {
    return date;
  }
};

/**
 * Override the date range for test & prospect companies.
 */
export const overrideDateRange = (
  company: string,
  dateRange: DateRange | undefined
): DateRange | undefined => {
  if (!dateRange) {
    return;
  }
  if (TEST_COMPANIES.includes(company)) {
    return { start: DEFAULT_START_DATE, end: DEFAULT_END_DATE };
  } else {
    return dateRange;
  }
};
