import React, { useCallback, useEffect, useState } from "react";
import * as uuid from "uuid";
import * as UserRedux from "../../redux/user";
import {
  NewTableEntriesMap,
  TableEditsMap,
  prepDeleteTableReq,
  validateAndUpdateTables as validateAndUpdateTablesInternal,
  QueuedRequest,
  prepCreateTableReq,
  EntryEditsMap,
} from "./OfflineInputsUtils";
import {
  OfflineInputsTable,
  OfflineInputTabs,
  OutcomeDataEntry,
  OutcomeDataTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { useSelector } from "react-redux";
import {
  CommitSuccessDialog,
  DiscardConfirmationDialog,
  InstructionsDialog,
  ValidationErrorDialog,
} from "./OfflineInputsDialogs";
import { SetError } from "../../redux/modals";
import InputContainer from "./InputContainer";
import AddOutcomeDataModal from "./AddOutcomeDataModal";
import InputTable, { getTablesContainerInnerWidth } from "./InputTable";

interface OutcomeDataProps {
  inputType: keyof typeof OfflineInputTabs;
  originalTables: OutcomeDataTable[];
  updated: { by?: string; at?: string };
  fetching: boolean;
  refetch: () => void;
  editingUnlocked: boolean;
  setEditingUnlocked: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchStateReset: boolean;
  setDispatchStateReset: React.Dispatch<React.SetStateAction<boolean>>;
  company: string;
  setError: SetError;
}

const OutcomeData: React.FC<OutcomeDataProps> = ({
  inputType,
  originalTables,
  updated,
  fetching,
  refetch,
  editingUnlocked,
  setEditingUnlocked,
  dispatchStateReset,
  setDispatchStateReset,
  company,
  setError,
}) => {
  const [tables, setTables] = useState<OutcomeDataTable[]>([...originalTables]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tablesInEditMode, setTablesInEditMode] = useState<string[]>([]);
  const [newTableEntries, setNewTableEntries] = useState<NewTableEntriesMap>({});
  const [validationErrors, setValidationErrors] = useState<
    { tableId: string; errorDates: string[] }[]
  >([]);
  const [queuedRequests, setQueuedRequests] = useState<QueuedRequest[]>([]);
  const [deletedTables, setDeletedTables] = useState<OutcomeDataTable[]>([]);
  const [showAddOutcomeDataModal, setShowAddOutcomeDataModal] = useState(false);
  const [showCommitSuccessDialog, setShowCommitSuccessDialog] = useState(false);
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [tableEdits, setTableEdits] = useState<TableEditsMap>({});
  const [filter, setFilter] = useState<{ filter: (line: Record<string, any>) => boolean }>();
  const userFullName: string = useSelector(UserRedux.fullNameSelector) ?? "";

  const scrollToRow = (tableId, date) => {
    const idToFind = `${tableId}_${date.split("T")[0]}`;
    const foundEl = document.getElementById(idToFind);

    if (foundEl) {
      foundEl.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setTimeout(scrollToRow, 200, tableId, date);
    }
  };

  const startEditingTable = (tableId: string, blanks: OutcomeDataEntry[]) => {
    setTablesInEditMode([...tablesInEditMode, tableId]);
    setTableEdits({ ...tableEdits, [tableId]: {} });
    newTableEntries[tableId] = blanks;
    setNewTableEntries({ ...newTableEntries });

    if (blanks.length > 0) {
      scrollToRow(tableId, blanks[blanks.length - 1].date);
    }
  };

  const resetState = useCallback(() => {
    setTables([...originalTables]);
    setSubmitting(false);
    setTablesInEditMode([]);
    setNewTableEntries({});
    setValidationErrors([]);
    setQueuedRequests([]);
    setDeletedTables([]);
    setShowAddOutcomeDataModal(false);
    setShowCommitSuccessDialog(false);
    setShowInstructionsDialog(false);
    setShowDiscardDialog(false);
    setTableEdits({});
    setFilter(undefined);
  }, [originalTables]);

  useEffect(() => {
    if (dispatchStateReset) {
      resetState();
      setDispatchStateReset(false);
    }
  }, [dispatchStateReset, resetState, setDispatchStateReset]);

  useEffect(() => {
    setTables([...originalTables]);
  }, [originalTables]);

  const onCommitSuccess = () => {
    setShowCommitSuccessDialog(true);
    refetch();
    resetState();
  };

  const validateAndUpdateTables = async () => {
    setSubmitting(true);
    await validateAndUpdateTablesInternal(
      company,
      userFullName,
      inputType,
      tableEdits,
      newTableEntries,
      setValidationErrors,
      setError,
      onCommitSuccess,
      queuedRequests,
      setQueuedRequests
    );
    setSubmitting(false);
  };

  const deleteTable = (tableId: string) => {
    prepDeleteTableReq(
      tableId,
      company,
      userFullName,
      inputType,
      tables,
      setTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
      deletedTables,
      setDeletedTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
      queuedRequests,
      setQueuedRequests,
      newTableEntries,
      setNewTableEntries,
      tableEdits,
      setTableEdits
    );
  };

  return (
    <InputContainer
      dialogs={
        <>
          <AddOutcomeDataModal
            show={showAddOutcomeDataModal}
            handleClose={() => setShowAddOutcomeDataModal(false)}
            createTable={(kpiName, kpiDescription, unitType, startDate, endDate) => {
              const newTable: OutcomeDataTable = {
                tableId: uuid.v4(),
                kpiName,
                kpiDescription,
                unitType,
                hasEndDate: Boolean(endDate),
                entries: [],
              };

              prepCreateTableReq({
                company,
                updatedBy: userFullName,
                inputType: OfflineInputTabs.OUTCOME_DATA,
                startDate,
                endDate,
                newTable,
                queuedRequests,
                setQueuedRequests,
                tables,
                setTables: setTables as React.Dispatch<React.SetStateAction<OfflineInputsTable[]>>,
                tablesInEditMode,
                setTablesInEditMode,
                newTableEntries,
                setNewTableEntries,
                tableEdits,
                setTableEdits,
              });
              setEditingUnlocked(true);
            }}
          />
          <CommitSuccessDialog
            show={showCommitSuccessDialog}
            onHide={() => setShowCommitSuccessDialog(false)}
          />
          <InstructionsDialog
            show={showInstructionsDialog}
            onHide={() => {
              setShowInstructionsDialog(false);
            }}
          />
          <DiscardConfirmationDialog
            show={showDiscardDialog}
            onDiscard={() => resetState()}
            onHide={() => setShowDiscardDialog(false)}
          />
          {validationErrors.length > 0 && (
            <ValidationErrorDialog
              errorDates={validationErrors[0].errorDates}
              onResolve={() => {
                validationErrors.shift();
                setValidationErrors([...validationErrors]);

                if (validationErrors.length === 0) {
                  validateAndUpdateTables();
                }
              }}
              onReject={() => {
                setValidationErrors([]);
                setShowInstructionsDialog(true);
                scrollToRow(validationErrors[0].tableId, validationErrors[0].errorDates[0]);
              }}
              table={tables.find(table => table.tableId === validationErrors[0].tableId)}
              tableEdits={tableEdits[validationErrors[0].tableId]}
              getInputDescription={function (table: OfflineInputsTable): string {
                const outcomeDataTable = table as OutcomeDataTable;
                return outcomeDataTable.kpiName;
              }}
              createEmptyEntry={date => {
                return {
                  date,
                  volume: undefined,
                };
              }}
              normalizeEntry={() => {}}
            />
          )}
        </>
      }
      tables={
        <div
          className="tablesContainerInner"
          style={getTablesContainerInnerWidth(OfflineInputTabs.OUTCOME_DATA, tables)}
        >
          {tables?.map(table => {
            const { kpiName } = table;

            const headerLabels = (
              <div className="headerLabels">
                <div>
                  <strong>{kpiName}</strong>
                </div>
              </div>
            );

            return (
              <InputTable
                inputType={OfflineInputTabs.OUTCOME_DATA}
                table={table}
                key={table.tableId}
                deleteTable={deleteTable}
                tablesInEditMode={tablesInEditMode}
                startEditingTable={startEditingTable}
                editingUnlocked={editingUnlocked}
                edits={tableEdits[table.tableId] ?? {}}
                setEdits={(edits: EntryEditsMap) =>
                  setTableEdits({ ...tableEdits, [table.tableId]: edits })
                }
                newEntries={newTableEntries[table.tableId] ?? []}
                submitting={submitting}
                setError={setError}
                filter={filter ? filter.filter : undefined}
                headerLabels={headerLabels}
              />
            );
          })}
        </div>
      }
      inputType={inputType}
      updated={updated}
      isEmpty={tables.length + deletedTables.length === 0}
      fetching={fetching}
      submitting={submitting}
      editingUnlocked={editingUnlocked}
      setEditingUnlocked={setEditingUnlocked}
      onFilter={filter => {
        setFilter({ filter });
      }}
      validateAndUpdateTables={validateAndUpdateTables}
      setShowAddInputModal={setShowAddOutcomeDataModal}
      setShowDiscardDialog={setShowDiscardDialog}
      setError={setError}
    />
  );
};

export default OutcomeData;
