import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FormattedNumber, IntlProvider } from "react-intl";

import * as R from "ramda";

import { Button } from "react-bootstrap";

import { useSetError } from "../redux/modals";
import { awaitJSON, LinearLambdaFetch } from "../utils/fetch-utils";

import { Page, BPMTable, Skeleton, TableSkeleton, Img } from "../Components";
import { white, redOrange } from "../utils/colors";

import "./LinearCpms.scss";

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const WHITE = hexToRgb(white);
const GREEN = hexToRgb("#2BC592"); //Sean's special green
const RED = hexToRgb(redOrange);

const DEMOS_MALE_18_PLUS = [
  "Males 18 - 20",
  "Males 21 - 24",
  "Males 25 - 29",
  "Males 30 - 34",
  "Males 35 - 39",
  "Males 40 - 44",
  "Males 45 - 49",
  "Males 50 - 54",
  "Males 55 - 64",
  "Males 65+",
];

const DEMOS_MALE_UNDER_18 = [
  "Males 2 - 5",
  "Males 6 - 8",
  "Males 9 - 11",
  "Males 12 - 14",
  "Males 15 - 17",
];

const DEMOS_FEMALE_18_PLUS = [
  "Females 18 - 20",
  "Females 21 - 24",
  "Females 25 - 29",
  "Females 30 - 34",
  "Females 35 - 39",
  "Females 40 - 44",
  "Females 45 - 49",
  "Females 50 - 54",
  "Females 55 - 64",
  "Females 65+",
];

const DEMOS_FEMALE_UNDER_18 = [
  "Females 2 - 5",
  "Females 6 - 8",
  "Females 9 -  11",
  "Females 12 - 14",
  "Females 15 - 17",
];

const AVAILABLE_META_DEMOS = [
  { label: "HH", demos: ["HH"] },
  { label: "A18+", demos: R.concat(DEMOS_MALE_18_PLUS, DEMOS_FEMALE_18_PLUS) },
  { label: "M18+", demos: DEMOS_MALE_18_PLUS },
  { label: "W18+", demos: DEMOS_FEMALE_18_PLUS },
];

const determineSortOrder = rotation => {
  if (rotation.indexOf("Early Morning") >= 0) {
    return 0;
  } else if (rotation.indexOf("Daytime") >= 0) {
    return 1;
  } else if (rotation.indexOf("Early Fringe") >= 0) {
    return 2;
  } else if (rotation.indexOf("Prime") >= 0) {
    return 3;
  } else if (rotation.indexOf("Late Fringe") >= 0) {
    return 4;
  } else if (rotation.indexOf("Overnight") >= 0) {
    return 5;
  } else if (rotation.indexOf("Broad") >= 0) {
    return 6;
  } else if (rotation.indexOf("Weekend") >= 0) {
    return 7;
  } else {
    return 8;
  }
};

const minMaxForField = (list, field) => {
  const sorted = R.sortBy(
    e => e[field],
    R.filter(e => e[field] && Number.isFinite(e[field]), list)
  );

  return {
    min: sorted[0][field],
    max: sorted[sorted.length - 1][field],
  };
};

/**
 * Ported from sass implementation in C
 * https://github.com/sass/libsass/blob/0e6b4a2850092356aa3ece07c6b249f0221caced/functions.cpp#L209
 */
const mixColors = (color1, color2, amount) => {
  const weight1 = amount;
  const weight2 = 1 - amount;

  const r = Math.round(weight1 * color1.r + weight2 * color2.r);
  const g = Math.round(weight1 * color1.g + weight2 * color2.g);
  const b = Math.round(weight1 * color1.b + weight2 * color2.b);

  return { r, g, b };
};

const getColor = (a, b, weight) => {
  const rgb = mixColors(a, b, weight);
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

const LinearCpms = () => {
  const setError = useSetError();
  const [demos, setDemos] = useState(R.concat(DEMOS_MALE_18_PLUS, DEMOS_FEMALE_18_PLUS));
  const [dataMap, setDataMap] = useState({});
  const pickerValue = useMemo(() => demos.sort().join("_"), [demos]);

  useEffect(() => {
    if (dataMap[pickerValue]) {
      return;
    }

    (async () => {
      try {
        let lambdaData = await LinearLambdaFetch("/cpms", {
          method: "POST",
          body: {
            demos,
          },
        });
        lambdaData = await awaitJSON(lambdaData);

        lambdaData.sort((a, b) => {
          if (a.network !== b.network) {
            return a.network.localeCompare(b.network);
          }

          if (a.avail !== b.avail) {
            return a.avail.localeCompare(b.avail);
          }

          let aOrder = determineSortOrder(a.rotation);
          let bOrder = determineSortOrder(b.rotation);
          if (aOrder !== bOrder) {
            return aOrder - bOrder;
          } else {
            return a.rotation.localeCompare(b.rotation);
          }
        });

        let { min: minAa, max: maxAa } = minMaxForField(lambdaData, "aa");
        let { min: minCpm, max: maxCpm } = minMaxForField(lambdaData, "cpm");

        setDataMap(currentMap => ({
          ...currentMap,
          [pickerValue]: {
            rows: lambdaData,
            minAa,
            maxAa,
            minCpm,
            maxCpm,
          },
        }));
      } catch (e) {
        setError({ message: e.message, reportError: e });
      }
    })();
  }, [demos, setError, dataMap, setDataMap, pickerValue]);

  const textHeader = useCallback(
    ({ field, label, flex = 1, minFlexWidth = 90 }) => ({
      label,
      name: field,
      flex,
      minFlexWidth,
      renderer: row => row[field],
    }),
    []
  );

  const networkHeader = useCallback(
    ({ field, label, flex = 1, minFlexWidth = 90 }) => ({
      label,
      name: field,
      flex,
      minFlexWidth,
      renderer: row => (
        <Img
          src={`https://cdn.blisspointmedia.com/networks/${row.network}.png`}
          className="networkLogo"
          title={row.network}
          unloader={
            <div className="logoNoImgBox logo404">
              <span>{row.network}</span>
            </div>
          }
        />
      ),
    }),
    []
  );

  const numericHeader = useCallback(
    ({
      field,
      label,
      prefix,
      decimalPlaces = 2,
      flex = 1,
      minFlexWidth = 90,
      min,
      max,
      lowHeat,
      highHeat,
    }) => ({
      label,
      name: field,
      flex,
      minFlexWidth,
      renderer: row => {
        if (!row) {
          return "";
        }

        let backgroundColor;
        if (min && max) {
          backgroundColor = getColor(
            lowHeat,
            highHeat,
            1 - (parseFloat(row[field]) - min) / (max - min)
          );
        }

        if (backgroundColor) {
          return (
            <div
              className={`dataPill${backgroundColor ? " withBackground" : ""}`}
              style={{ backgroundColor }}
            >
              <FormattedNumber
                style={prefix ? "currency" : "decimal"}
                currency="USD"
                maximumFractionDigits={decimalPlaces}
                minimumFractionDigits={decimalPlaces}
                value={parseFloat(row[field])}
              />
            </div>
          );
        }

        return (
          <FormattedNumber
            style={prefix ? "currency" : "decimal"}
            currency="USD"
            maximumFractionDigits={decimalPlaces}
            minimumFractionDigits={decimalPlaces}
            value={parseFloat(row[field])}
          />
        );
      },
    }),
    []
  );

  const maximumHeaders = useMemo(
    () => [
      networkHeader({
        field: "network",
        label: "Network",
        minFlexWidth: 100,
      }),
      textHeader({
        field: "avail",
        label: "Avail",
        minFlexWidth: 80,
      }),
      textHeader({
        field: "status",
        label: "Status",
        minFlexWidth: 100,
      }),
      textHeader({
        field: "rotation",
        label: "Rotation",
        minFlexWidth: 350,
      }),
      numericHeader({
        field: "cost_30s",
        label: "30s Spot Cost",
        prefix: "$",
        decimalPlaces: 0,
        minFlexWidth: 100,
      }),
      numericHeader({
        field: "aa",
        label: "AA",
        decimalPlaces: 0,
        minFlexWidth: 100,
        min: !!dataMap[pickerValue] && dataMap[pickerValue].minAa,
        max: !!dataMap[pickerValue] && dataMap[pickerValue].maxAa,
        lowHeat: WHITE,
        highHeat: GREEN,
      }),
      numericHeader({
        field: "cpm",
        label: "CPM",
        prefix: "$",
        decimalPlaces: 2,
        minFlexWidth: 100,
        min: !!dataMap[pickerValue] && dataMap[pickerValue].minCpm,
        max: !!dataMap[pickerValue] && dataMap[pickerValue].maxCpm,
        lowHeat: WHITE,
        highHeat: RED,
      }),
    ],
    [numericHeader, textHeader, networkHeader, dataMap, pickerValue]
  );

  const buttonsByDemo = useCallback(
    demoList =>
      R.map(demo => {
        return (
          <Button
            key={demo}
            value={demo}
            active={R.contains(demo, demos)}
            onClick={() => {
              if (R.contains("HH", demos)) {
                setDemos([demo]);
              } else if (R.contains(demo, demos)) {
                setDemos(R.without([demo], demos));
              } else {
                setDemos(R.concat([demo], demos));
              }
            }}
          >
            {demo.replace("Males ", "").replace("Females ", "")}
          </Button>
        );
      }, demoList),
    [demos]
  );
  const maleDemoButtons = useMemo(
    () => <div>Males: {buttonsByDemo(R.concat(DEMOS_MALE_18_PLUS, DEMOS_MALE_UNDER_18))}</div>,
    [buttonsByDemo]
  );

  const femaleDemoButtons = useMemo(
    () => (
      <div>Females: {buttonsByDemo(R.concat(DEMOS_FEMALE_18_PLUS, DEMOS_FEMALE_UNDER_18))}</div>
    ),
    [buttonsByDemo]
  );

  const metaDemoButtons = useMemo(
    () => (
      <div>
        Meta:{" "}
        {R.map(
          metaDemo => (
            <Button
              key={metaDemo.label}
              value={metaDemo.label}
              active={R.all(metaDemoDemo => R.contains(metaDemoDemo, demos), metaDemo.demos)}
              onClick={() => setDemos(metaDemo.demos)}
            >
              {metaDemo.label}
            </Button>
          ),
          AVAILABLE_META_DEMOS
        )}
      </div>
    ),
    [demos]
  );

  return (
    <Page title="Linear CPMs" pageType="Linear CPMs" minHeight={600}>
      <IntlProvider locale="en">
        <div className="linearCpms">
          <div className="constraintTable">
            <div className="tableHeader">
              <div className="tableName">Demo</div>
              <div>{metaDemoButtons}</div>
              <div>{maleDemoButtons}</div>
              <div>{femaleDemoButtons}</div>
            </div>
            <div className="tableContainer">
              {dataMap[pickerValue] ? (
                <BPMTable
                  minColumnWidth={20}
                  headers={maximumHeaders}
                  data={dataMap[pickerValue].rows}
                  noRowsRenderer={() => <div>No rows to show.</div>}
                  csvDownload={"linear_cpms"}
                />
              ) : (
                <Skeleton>
                  <TableSkeleton />
                </Skeleton>
              )}
            </div>
          </div>
        </div>
      </IntlProvider>
    </Page>
  );
};

export default LinearCpms;
