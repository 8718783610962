import React from "react";
import * as R from "ramda";

import { MdDeleteForever, MdArrowForward, MdFastForward } from "react-icons/md";
import { BPMButton } from "../../Components";

import { deepMergeLinearImpressionOverrides, ExtendEditModalData } from "../linearBuyingUtils";
import { PRETTY_EDIT_NAMES } from "../linearBuyingConstants";
import { StateSetter } from "../../utils/types";

interface PendingEditProps {
  editData: ExtendEditModalData;
  deleteEdit: (key: string, editType: string) => void;
  setExtendEditModalData: StateSetter<ExtendEditModalData | undefined>;
}

const PendingEdit: React.FC<PendingEditProps> = ({
  editData,
  deleteEdit,
  setExtendEditModalData,
}) => {
  const { row, edits } = editData;
  const { network, key, rotation, length, avail } = row;

  return (
    <div className="editContainer" key={key}>
      <div className="editTitle">{`${network} - ${rotation} - ${length}s - ${avail}`}</div>
      <div className="editsList">
        {R.keys(edits).map(editType => {
          const name = PRETTY_EDIT_NAMES[editType];
          const oldValue =
            editType === "impressionOverrides" ? JSON.stringify(row[editType]) : row[editType];

          // For impression overrides edits, we want to merge the current overrides with the edits
          // to show the complete object. Otherwise, it will look like the edits object is replacing the
          // current impression overrides object.
          let newValueObject = {};
          if (editType === "impressionOverrides") {
            newValueObject = deepMergeLinearImpressionOverrides(
              row[editType] || {},
              edits[editType] || {}
            );
          }
          const newValue =
            editType === "impressionOverrides" ? JSON.stringify(newValueObject) : edits[editType];

          let newRotation: Record<string, number> = {};
          let oldRotation: Record<string, number> = {};
          if (editType === "creatives") {
            for (let creativeMetadata of edits[editType]!) {
              const { isci, percent } = creativeMetadata;
              newRotation[isci] = percent;
            }
            if (row.creatives) {
              for (let creativeMetadata of row.creatives) {
                const { isci, percent } = creativeMetadata;
                oldRotation[isci] = percent;
              }
            }
          }

          return (
            <div className="edit" key={editType}>
              <BPMButton
                className="deleteButton"
                size="sm"
                variant="outline-danger"
                onClick={() => deleteEdit(key, editType)}
              >
                <MdDeleteForever />
              </BPMButton>
              <span className="editType">{name}:</span>
              {editType === "creatives" ? (
                R.keys(newRotation).map(isci => {
                  let pct = newRotation[isci];
                  let unchanged = newRotation[isci] === oldRotation[isci];
                  return (
                    <React.Fragment key={isci}>
                      <span>{isci}</span>
                      <span className={unchanged ? "unchanged" : "oldValue"}>
                        {oldRotation[isci] ? `${oldRotation[isci]}%` : "0%"}
                      </span>
                      <MdArrowForward />
                      <span className={unchanged ? "unchanged" : "newValue"}>{`${pct}%`}</span>
                    </React.Fragment>
                  );
                })
              ) : (
                <React.Fragment key={editType}>
                  <span className="oldValue">{oldValue || "N/A"}</span>
                  <MdArrowForward />
                  <span className="newValue">{newValue}</span>
                </React.Fragment>
              )}

              <BPMButton
                disabled={editType === "impressionOverrides"}
                size="sm"
                variant="outline-primary"
                onClick={() =>
                  setExtendEditModalData({ ...editData, selectedEditToExtend: editType })
                }
              >
                <MdFastForward />
              </BPMButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PendingEdit;
