import "./PlaybookTable.scss";
import { BPMTable, Img } from "../Components";
import { CDN } from "../SingleChannel/MetricsTable/metricsTableUtils";
import { INC_PLAYBOOK_PAGES, PAGE_PREFIX } from "./IncrementalityPlaybook";
import { navigate } from "@reach/router";
import * as R from "ramda";

const directory = "incrementality";
const getIcon = (icon: string): JSX.Element => <Img src={`${CDN}/${directory}/Icon_${icon}.png`} />;
const getLogo = (logo: string): JSX.Element => <Img src={`${CDN}/${directory}/Logo_${logo}.png`} />;

const experimentPOCMap = {
  "Brian Binder": "mailto:brian.binder@tinuiti.com",
  "Chelsea Dyjak": "mailto:chelsea.dyjak@tinuiti.com",
  "Chris Stokes": "mailto:chris.stokes.com",
  "Dimond Gooden-Hilton": "mailto:dimond.gooden@tinuiti.com",
  "Google Incrementality": "mailto:googleincrementality@tinuiti.com",
  "Incrementality Playbook Slack Channel": "https://tinuiti.slack.com/archives/C05HHT6JETE",
  "Julia Pelosi": "mailto:julia,pelosi@tinuiti.com",
  "Maddie Barton": "mailto:maddie.barton@tinuiti.com",
  "Natasha Blumenkron": "mailto:natasha.blumenkron@tinuiti.com",
};

const commonExperiments = {
  "Always On Incrementality": ["Incrementality Playbook Slack Channel"],
  "Conversion Lift for Geo": ["Google Incrementality"],
  "Conversion Lift for Users": ["Google Incrementality"],
  "Geo Test": ["Incrementality Playbook Slack Channel"],
  "Ghost Ads": ["Incrementality Playbook Slack Channel"],
  "Match Market Test": ["Incrementality Playbook Slack Channel"],
  "PSA-Geo": ["Incrementality Playbook Slack Channel"],
  "Pulse Test": ["Incrementality Playbook Slack Channel"],
  "Uplift Experiment": ["Google Incrementality"],
  PSA: ["Google Incrementality"],
};

const playbookHeaders = [
  {
    flex: 19,
    label: "Channel",
    name: "Channel",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="channelNames">
          {R.map(channel => {
            const { label, size, value } = channel;
            subItemIndex++;
            return (
              <div
                className={`channelName ${row.Channel.length === 1 ? "single" : ""} ${
                  subItemIndex === 0 ? "first" : ""
                } ${subItemIndex === row.Channel.length - 1 ? "last" : ""}`}
                style={
                  size
                    ? { flex: `${size} ${size} 68px` }
                    : row.Channel.length === 1
                    ? { flex: 1 }
                    : {}
                }
              >
                {getIcon(value)}
                <div className="label"> {R.defaultTo(value, label)}</div>
              </div>
            );
          }, row.Channel)}
        </div>
      );
    },
  },
  {
    flex: 10,
    label: "Platform",
    name: "Platform",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="platforms">
          {R.map(platform => {
            const { icon, size, value } = platform;
            subItemIndex++;
            return (
              <div
                className={`platform ${subItemIndex === 0 ? "first" : ""} ${
                  subItemIndex === row.Platform.length - 1 ? "last" : ""
                }`}
                style={
                  size
                    ? { flex: `${size} ${size} 68px` }
                    : row.Platform.length === 1
                    ? { flex: 1 }
                    : {}
                }
              >
                {icon ? getIcon(value) : getLogo(value)}
              </div>
            );
          }, row.Platform)}
        </div>
      );
    },
  },
  {
    flex: 26,
    label: "Experiment Options",
    name: "Experiment Options",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="experimentOptions">
          {R.map(exp => {
            subItemIndex++;
            let currentPlatform;
            let platformIndex = 0;
            const channel = R.defaultTo(row.Channel[0].value, row.Channel[0].label);
            for (const platform of R.defaultTo([], row.Platform)) {
              if (subItemIndex < platformIndex + R.defaultTo(1, platform.size)) {
                currentPlatform = platform.value;
                break;
              }
              platformIndex += R.defaultTo(1, platform.size);
            }
            let experimentLink = "";
            if (INC_PLAYBOOK_PAGES && INC_PLAYBOOK_PAGES.Channels[channel]) {
              if (INC_PLAYBOOK_PAGES.Channels[channel].subPages) {
                const { subPages } = INC_PLAYBOOK_PAGES.Channels[channel];
                if (R.keys(subPages).includes(currentPlatform)) {
                  experimentLink = `${currentPlatform.replace(/ /g, "")}`;
                } else {
                  experimentLink = `${channel.replace(/ /g, "")}`;
                }
              } else {
                experimentLink = `${channel.replace(/ /g, "")}`;
              }
            }
            const { experimentName, poc } = commonExperiments[exp]
              ? {
                  experimentName: exp,
                  poc: commonExperiments[exp],
                }
              : exp;
            return (
              <div
                className={`experimentOption ${subItemIndex === 0 ? "first" : ""} ${
                  subItemIndex === row["Experiment Options"].length - 1 ? "last" : ""
                }`}
                style={{ flex: 1 }}
              >
                <div
                  className="experimentName"
                  onClick={() => navigate(`${PAGE_PREFIX}/${experimentLink}`)}
                >
                  {experimentName}
                </div>
                <div className="pocContainer">
                  <div className="pocLabel">Internal POC:</div>
                  {R.map(
                    contact => (
                      <div className="poc" onClick={() => navigate(experimentPOCMap[contact])}>
                        {contact}
                      </div>
                    ),
                    poc
                  )}
                </div>
              </div>
            );
          }, row["Experiment Options"])}
        </div>
      );
    },
  },
  {
    flex: 22,
    label: "Experiment Mode",
    name: "Experiment Mode",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="experimentModes">
          {R.map(model => {
            subItemIndex++;
            return (
              <div
                className={`experimentMode ${row["Experiment Mode"].length === 1 ? "single" : ""} ${
                  subItemIndex === 0 ? "first" : ""
                } ${subItemIndex === row["Experiment Mode"].length - 1 ? "last" : ""}`}
              >
                {getIcon(`ExperimentMode_${model.replace(" ", "")}`)}
                <div className="label">{model}</div>
              </div>
            );
          }, row["Experiment Mode"])}
        </div>
      );
    },
  },
  {
    flex: 23,
    label: "Service Model",
    name: "Service Model",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="serviceModels">
          {R.map(model => {
            subItemIndex++;
            return (
              <div
                className={`serviceModel ${row["Service Model"].length === 1 ? "single" : ""} ${
                  subItemIndex === 0 ? "first" : ""
                } ${subItemIndex === row["Service Model"].length - 1 ? "last" : ""}`}
              >
                {getIcon(`ServiceModel_${model.replace(" ", "")}`)}
                <div className="label">{model}</div>
              </div>
            );
          }, row["Service Model"])}
        </div>
      );
    },
  },
  {
    flex: 12,
    label: "Effort Score",
    name: "Effort Score",
    nonInteractive: true,
    renderer: row => {
      let subItemIndex = -1;
      return (
        <div className="effortScores">
          {R.map(effort => {
            subItemIndex++;
            return (
              <div
                className={`effortScore ${row["Effort Score"].length === 1 ? "single" : ""} ${
                  subItemIndex === 0 ? "first" : ""
                } ${subItemIndex === row["Effort Score"].length - 1 ? "last" : ""}`}
              >
                {getIcon(`Lift_${effort}`)}
              </div>
            );
          }, row["Effort Score"])}
        </div>
      );
    },
  },
];

export const playbookData = [
  {
    rows: 2,
    Channel: [{ value: "Search" }],
    Platform: [{ value: "Google" }],
    "Experiment Options": ["Conversion Lift for Users", "Conversion Lift for Geo"],
    "Experiment Mode": ["Platform Native", "Off Platform"],
    "Service Model": ["Managed Service", "Self Service"],
    "Effort Score": ["Low", "High"],
  },
  {
    rows: 2,
    Channel: [{ label: "Shopping", value: "Shoppable" }],
    Platform: [{ value: "Google" }],
    "Experiment Options": ["Conversion Lift for Users", "Conversion Lift for Geo"],
    "Experiment Mode": ["Platform Native", "Off Platform"],
    "Service Model": ["Managed Service", "Self Service"],
    "Effort Score": ["Low", "High"],
  },
  {
    rows: 3,
    Channel: [{ label: "Multi Channnel", value: "MultiChannel" }],
    Platform: [
      { icon: true, size: 2, value: "GoogleDiscovery" },
      { icon: true, value: "GooglePerformanceMax" },
    ],
    "Experiment Options": [
      "Conversion Lift for Users",
      "Conversion Lift for Geo",
      "Uplift Experiment",
    ],
    "Experiment Mode": ["Platform Native", "Off Platform", "Platform Native"],
    "Service Model": ["Managed Service", "Self Service", "Self Service"],
    "Effort Score": ["Low", "High", "Medium"],
  },
  {
    rows: 3,
    Channel: [{ value: "Display" }],
    Platform: [{ value: "Google", size: 2 }, { value: "Tradedesk" }],
    "Experiment Options": [
      "Conversion Lift for Users",
      "Conversion Lift for Geo",
      { experimentName: "Conversion Lift", experimentLink: "Tradedesk", poc: ["Brian Binder"] },
    ],
    "Experiment Mode": ["Platform Native", "Off Platform", "Platform Native"],
    "Service Model": ["Managed Service", "Self Service", "Managed Service"],
    "Effort Score": ["Low", "High", "Low"],
  },
  {
    rows: 6,
    Channel: [{ value: "Social" }],
    Platform: [
      { size: 2, value: "Meta" },
      { value: "Tiktok" },
      { value: "Snapchat" },
      { value: "Pinterest" },
      { value: "Reddit" },
    ],
    "Experiment Options": [
      {
        experimentName: "Conversion Lift",
        experimentLink: "Meta",
        poc: ["Natasha Blumenkron", "Chris Stokes"],
      },
      {
        experimentName: "Open Source Geo Experiment",
        experimentLink: "Meta",
        poc: ["Natasha Blumenkron", "Chris Stokes"],
      },
      {
        experimentName: "Conversion Lift",
        experimentLink: "TikTok",
        poc: ["Natasha Blumenkron", "Chelsea Dyjak"],
      },
      {
        experimentName: "Conversion Lift",
        experimentLink: "Snapchat",
        poc: ["Natasha Blumenkron", "Julia Pelosi"],
      },
      {
        experimentName: "Conversion Lift",
        experimentLink: "Pinterest",
        poc: ["Natasha Blumenkron", "Dimond Gooden-Hilton"],
      },
      {
        experimentName: "Conversion Lift",
        experimentLink: "Reddit",
        poc: ["Natasha Blumenkron"],
      },
    ],
    "Experiment Mode": [
      "Platform Native",
      "Off Platform",
      "Platform Native",
      "Platform Native",
      "Platform Native",
      "Platform Native",
    ],
    "Service Model": [
      "Managed Service",
      "Self Service",
      "Managed Service",
      "Managed Service",
      "Managed Service",
      "Managed Service",
    ],
    "Effort Score": ["Low", "High", "Low", "Low", "Low", "Low"],
  },
  {
    Channel: [{ label: "Commerce", value: "ConnectedCommerce" }],
    Platform: [{ value: "Amazon" }],
    "Experiment Options": [
      {
        experimentName: "User Based Testing (Beta)",
        experimentLink: "Amazon",
        poc: ["Maddie Barton", "Natalie Samson"],
      },
    ],
    "Experiment Mode": ["Platform Native"],
    "Service Model": ["Managed Service"],
    "Effort Score": ["Medium"],
  },
  {
    rows: 7,
    Channel: [{ label: "Streaming", value: "StreamingVideo" }],
    Platform: [
      { size: 2, value: "Youtube" },
      { value: "Spotify" },
      { icon: true, value: "PSATesting" },
      { icon: true, value: "Ghost" },
      { icon: true, value: "Geotesting" },
      { icon: true, value: "AlwaysOn" },
    ],
    "Experiment Options": [
      "Conversion Lift for Users",
      "Conversion Lift for Geo",
      {
        experimentName: "Conversion Lift",
        experimentLink: "Spotify",
        poc: ["Google Incrementality"],
      },
      "PSA",
      "Ghost Ads",
      "PSA-Geo",
      "Always On Incrementality",
    ],
    "Experiment Mode": [
      "Platform Native",
      "Off Platform",
      "Platform Native",
      "Off Platform",
      "Off Platform",
      "Off Platform",
      "Off Platform",
    ],
    "Service Model": [
      "Managed Service",
      "Self Service",
      "Self Service",
      "Managed Service",
      "Managed Service",
      "Managed Service",
      "Managed Service",
    ],

    "Effort Score": ["Low", "High", "Low", "Medium", "Medium", "High", "Medium"],
  },
  {
    rows: 2,
    Channel: [{ label: "Linear TV", value: "LinearTV" }],
    Platform: [{ value: "" }, { value: "" }],
    "Experiment Options": ["Match Market Test", "Pulse Test"],
    "Experiment Mode": ["Off Platform", "Off Platform"],
    "Service Model": ["Managed Service", "Managed Service"],
    "Effort Score": ["High", "High"],
  },
  {
    Channel: [{ value: "All Channels" }],
    Platform: [{ icon: true, value: "Geotesting" }],
    "Experiment Options": ["Geo Test"],
    "Experiment Mode": ["Off Platform"],
    "Service Model": ["Managed Service"],
    "Effort Score": ["High"],
  },
];
const REFERENCE_WINDOW_WIDTH = 1920;
const HEADER_HEIGHT = 44;
const BUFFER_HEIGHT = 10;
const DEFAULT_ROW_HEIGHT = 69;
const DEFAULT_ROW_HEIGHT_BY_INDEX = (index, data) =>
  (data && data[index] && data[index].rows ? data[index].rows : 1) * DEFAULT_ROW_HEIGHT + 1;

export const playbookTable = (
  data: any[] = playbookData,
  headers: any[] = playbookHeaders,
  rowHeight: number | ((index: number) => number) = index =>
    DEFAULT_ROW_HEIGHT_BY_INDEX(index, data)
): JSX.Element => {
  const scalingFactor = 1 + (1 - R.defaultTo(0, window.innerWidth) / REFERENCE_WINDOW_WIDTH);
  let itemIndex = -1;
  const height =
    BUFFER_HEIGHT +
    HEADER_HEIGHT +
    R.sum(
      R.map(() => {
        if (typeof rowHeight === "number") {
          return rowHeight * scalingFactor;
        } else if (rowHeight) {
          itemIndex++;
          return rowHeight(itemIndex) * scalingFactor;
        } else {
          return DEFAULT_ROW_HEIGHT_BY_INDEX(itemIndex, data) * scalingFactor;
        }
      }, data)
    );
  const scaledRowHeight =
    typeof rowHeight === "number"
      ? rowHeight * scalingFactor
      : args => rowHeight(args) * scalingFactor;
  return (
    <div style={{ height, width: "100%" }}>
      <BPMTable
        className="incrementalityPlaybookTable"
        data={data}
        filterBar={false}
        headerHeight={HEADER_HEIGHT}
        headers={headers}
        rowHeight={scaledRowHeight}
      />
    </div>
  );
};
