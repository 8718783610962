// Experiment for client tools page.

const kpiSetupUsers: string[] = [
  "benjamin.contesse@tinuiti.com",
  "justin.manus@tinuiti.com",
  "garrett.cheadle@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "kyle.chan@tinuiti.com",
  // Add users here!
];

export const shouldEnableKpiSetup = (userEmail: string): boolean => {
  return kpiSetupUsers.includes(userEmail);
};
