import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import user from "./user";
import modals from "./modals";
import company from "./company";
import networks from "./networks";
import creative from "./creative";

export let reduxStore;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createReduxStore = () => {
  const reducer = combineReducers({
    user,
    modals,
    company,
    networks,
    creative,
  });

  reduxStore = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
};

export const createTestReduxStore = testState => {
  reduxStore = createStore(state => state, testState);
};
