import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { Page, Spinner, Skeleton, TableSkeleton, ModalEditTable } from "../Components";
import "./ExpectedBookings.scss";
import {
  ExpectedBooking,
  GetExpectedBookingsResponse,
  UpdateExpectedBookingsParams,
} from "@blisspointmedia/bpm-types/dist/ExpectedBookings";
import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

interface rowData extends ExpectedBooking {
  lastmodified?: string;
}

const OldExpectedBookings: React.FC = () => {
  const setError = useSetError();
  const [deletedRows, setDeletedRows] = useState<rowData[]>([]);
  const [tableData, setTableData] = useState<rowData[]>();
  const [saving, setSaving] = useState(false);
  const [bookingsData, setBookingsData] = useState<GetExpectedBookingsResponse>();

  useEffect(() => {
    if (!tableData) {
      (async () => {
        try {
          let res = await ToolsLambdaFetch("/get_expected_bookings");
          let bookingsData = await awaitJSON<GetExpectedBookingsResponse>(res);
          setTableData(bookingsData.bookings);
          setBookingsData(bookingsData);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get expected bookings assignments data ${reportError.message}`,
            reportError,
          });
        }
      })();
    }
  }, [setError, tableData]);

  const selectorOptions = useMemo(
    () => ({
      media: R.map(e => ({ label: e, value: e }), [
        "tv",
        "audio",
        "streaming",
        "audio_secured",
        "tv_secured",
        "streaming_secured",
        "radio",
        "display",
      ]),
      company: bookingsData
        ? R.map(e => ({ label: e.cid, value: e.cid }), bookingsData.companies)
        : [],
    }),

    [bookingsData]
  );

  const saveData = useMemo(() => {
    const rowSetValuesChanged = ({ week, company, media, booking, lastmodified }: rowData) =>
      !R.isNil(lastmodified) &&
      !R.isNil(week) &&
      !R.isNil(company) &&
      !R.isNil(media) &&
      !R.isNil(booking);

    const idSet = ({ id }: rowData) => !R.isNil(id);

    if (tableData) {
      // Only want to save rows that have been modified in the current session (and therefore have a 'lastmodified' value)
      // and have all required values
      let modifiedRows = R.filter(rowSetValuesChanged, tableData);

      // Rows that have an id value were created before the current session
      let updateRows = R.filter(idSet, modifiedRows);
      let insertRows = R.without(updateRows, modifiedRows);
      // Only want to delete rows that existed before the current session (because only those 'deletedRows' are in the database)
      let validDeleteRows = R.filter(idSet, deletedRows);

      return {
        insert: insertRows,
        update: updateRows,
        delete: validDeleteRows,
      };
    }
  }, [deletedRows, tableData]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await ToolsLambdaFetch<UpdateExpectedBookingsParams>("/update_expected_bookings", {
        method: "POST",
        body: saveData,
      });
      window.location.reload();
    } catch (e) {
      const reportError = e as Error;
      let userMessage = `Failed to set expected bookings data ${reportError.message}`;
      if (
        reportError.message ===
        'duplicate key value violates unique constraint "expected_bookings_week_company_media_key"'
      ) {
        userMessage =
          "At least some of the bookings you entered are already in the database (week, company, mediatype)";
      }
      setError({
        message: userMessage,
        reportError,
      });
    }
  }, [saveData, setError]);

  const bookingsHeader = [
    {
      label: "Week",
      field: "week",
      type: "week",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      label: "Company",
      field: "company",
      type: "select",
      options: "company",
      flex: 1,
      modalRow: 0,
      modalFlex: 1,
    },
    {
      label: "Media",
      field: "media",
      type: "select",
      options: "media",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
    {
      label: "Booking Amount",
      field: "booking",
      type: "currency",
      flex: 1,
      modalRow: 1,
      modalFlex: 1,
    },
  ];

  return (
    <Page
      title="Expected Bookings"
      pageType="Expected Bookings"
      minHeight="600px"
      actions={
        <div className="oldExpectedBookingsActions">
          <Button
            variant="primary"
            onClick={save}
            className="saveButton"
            disabled={
              R.isNil(saveData) ||
              (!saveData.insert.length && !saveData.update.length && !saveData.delete.length) ||
              saving
            }
          >
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      <div className="expectedBookingsPageContainer">
        {tableData ? (
          <ModalEditTable
            className="expectedBookingsTable"
            name="Bookings"
            headers={bookingsHeader}
            tableData={tableData}
            setTableData={newTableData => setTableData(newTableData)}
            selectorOptions={selectorOptions}
            filterBar
            deletedRows={deletedRows}
            /// @ts-ignore - Can delete when ModalEditTable is TypeScripted
            setDeletedRows={setDeletedRows}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default OldExpectedBookings;
