import React, { useMemo } from "react";
import { MdDone } from "react-icons/md";
import { Setter } from "../utils/types";

import "./CheckBox.scss";

interface CheckBoxProps {
  checked: boolean;
  disabled?: boolean;
  onCheck?: Setter<boolean>;
  size?: "sm" | "md" | "lg";
  className?: string;
  [passthroughProp: string]: any;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  onCheck = () => true,
  size = "sm",
  className: providedClassName,
  disabled,
  ...passthrough
}) => {
  const className = useMemo(() => {
    let classes = ["BPMCheckBox"];
    if (size === "sm") {
      classes.push("sm");
    }
    if (checked) {
      classes.push("checked");
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (providedClassName) {
      classes.push(providedClassName);
    }
    return classes.join(" ");
  }, [size, providedClassName, checked, disabled]);
  return (
    <div className={className} onClick={() => !disabled && onCheck(!checked)} {...passthrough}>
      {checked && <MdDone />}
    </div>
  );
};
