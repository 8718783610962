import "./LinearDelivery.scss";
import {
  ActiveKeyMap,
  ActiveKeyMapMap,
  ColorMap,
  DailyDeliveryData,
  DeliveryKey,
  InfoCard,
  KeyData,
  OtherMap,
  SetActiveKeyMap,
  Totals,
  TotalSummary,
  useBrands,
  useColorMap,
} from "../StreamingDelivery/StreamingDelivery";
import {
  awaitJSON,
  LinearLambdaFetch,
  LinearPerformanceLambdaFetch,
  pollS3,
} from "../utils/fetch-utils";
import {
  BarGraphSkeleton,
  BPMDateRange,
  DropdownOption,
  OldFilterBar,
  FilterPane,
  ItemListSkeleton,
  OldDropdown as Dropdown,
  Page,
  Skeleton,
  BPMToggleButton,
  useFilterPaneState,
  DownloadDropdown,
} from "../Components";
import { Button } from "react-bootstrap";
import {
  computeResolvedDate,
  MONDAY_OF_LAST_WEEK,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { convertSVGStringToPNGURI, download } from "../utils/download-utils";
import { CreativeMap, useCreativeMap } from "../redux/creative";
import { DateRange, Setter, StateSetter } from "../utils/types";
import { formatMoney, formatMoneyAsInt, formatNumberAsInt } from "../utils/format-utils";
import { getGlobalBrand } from "../Performance/performanceUtils";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { ModalError, useSetError } from "../redux/modals";
import { ReactComponent as ECPMIcon } from "./eCPM.svg";
import { ReactComponent as ImpressionsIcon } from "./impressions.svg";
import { ReactComponent as SpendIcon } from "./spend.svg";
import { useCompanyInfo } from "../redux/company";
import * as Dfns from "date-fns/fp";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import DeliveryChart from "../StreamingDelivery/DeliveryChart";
import Legend from "../StreamingDelivery/Legend";
import Papa from "papaparse";
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from "react";
import { FilterPaneState } from "@blisspointmedia/bpm-types/dist/FilterPane";

const DEFAULT_SPEND_SETTING = true;
const DEFAULT_SCALE_SETTING = true;

interface LinearDeliveryFetchProps {
  audience: L.Audience;
  company: string;
  creativeMap: CreativeMap | undefined;
  dates: DateRange;
  dimension: string;
  filterID: number | undefined;
  filterState: FilterPaneState;
  globalBrand: string | undefined;
  prefix: string;
}

interface LinearDeliveryFetchSetters {
  setAudience: Setter<L.Audience>;
  setDates: Setter<DateRange>;
  setDimension: Setter<string>;
  setFilterID: StateSetter<number | undefined>;
  setFilterState: StateSetter<FilterPaneState>;
}

interface LinearDeliveryUISettings {
  scaleAbsolute: boolean;
  spendMetric: boolean;
}

interface LinearDeliveryUISetters {
  setScaleAbsolute: Setter<boolean>;
  setSpendMetric: Setter<boolean>;
}

interface LinearDeliveryUIState {
  activeKeyMap: Record<string, boolean>;
  brands?: { cid: string; name: string }[];
  chartLoaded: boolean;
  expanded: boolean;
  hoverKey?: string;
  keyInfo: Record<string, KeyData | undefined>;
  setActiveKeyMap: SetActiveKeyMap;
  setBrand: Setter<string>;
  setExpanded: Setter<boolean>;
  setHoverKey: StateSetter<string | undefined>;
}

type LinearDeliveryContextType = LinearDeliveryFetchProps &
  LinearDeliveryUISettings &
  LinearDeliveryUIState &
  LinearDeliveryFetchSetters &
  LinearDeliveryUISetters;

const LinearDeliveryContext = React.createContext<LinearDeliveryContextType>({
  activeKeyMap: {},
  audience: "HH",
  brands: [],
  chartLoaded: false,
  company: "",
  creativeMap: undefined,
  dates: { start: "", end: "" },
  dimension: "network",
  expanded: false,
  filterID: undefined,
  filterState: { advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false },
  globalBrand: undefined,
  hoverKey: "",
  keyInfo: {},
  prefix: "linear",
  scaleAbsolute: DEFAULT_SCALE_SETTING,
  setActiveKeyMap: () => {},
  setAudience: () => {},
  setBrand: () => {},
  setDates: () => {},
  setDimension: () => {},
  setExpanded: () => {},
  setFilterID: () => {},
  setFilterState: () => {},
  setHoverKey: () => {},
  setScaleAbsolute: () => {},
  setSpendMetric: () => {},
  spendMetric: DEFAULT_SPEND_SETTING,
});

const DIMENSIONS: Record<string, string> = {
  network: "Network",
  creative: "Creative",
  length: "Length",
  daypart: "Daypart",
  avail: "Avail",
  campaign: "Campaign",
};

export const fetchLinearDelivery: (
  props: LinearDeliveryFetchProps
) => Promise<DailyDeliveryData> = async ({
  audience,
  company,
  creativeMap,
  dates,
  dimension,
  filterID,
  filterState,
}) => {
  let newData: DailyDeliveryData = {
    keys: {},
    weekly: {},
    daily: {},
  };
  if (!(dates.start && dates.end)) {
    return newData;
  }

  let res = await LinearPerformanceLambdaFetch("/delivery", {
    params: {
      ...(dates as DateRange),
      company,
      dimensions: dimension,
      filter_id: filterID,
      filter_state: filterState ? JSON.stringify(filterState) : undefined,
      impressionSource: audience,
    },
  });
  let { dimension: actualDimension, result } = await awaitJSON(res as Response);
  for (let line of result) {
    let count = parseFloat(line.count);
    let cost = parseFloat(line.cost);
    let ratedCost = parseFloat(line.ratedcost);
    let spots = parseFloat(line.spots);
    let key = "";
    let keyData: KeyData = true;
    switch (actualDimension) {
      case "network":
        keyData = { network: line.network };
        key = line.network;
        break;
      case "daypart":
        key = line.rotation;
        break;
      case "creative":
        line.length = line.length.replace("s", "");
        key = `${line.isci}_${line.creative}_${line.length}`;
        let creativeName = line.creative;
        if (creativeMap && creativeMap[line.isci] && creativeMap[line.isci].name) {
          creativeName = creativeMap[line.isci].name;
        }
        keyData = {
          isci: line.isci,
          creative: creativeName,
          length: line.length,
          spots: line.spots,
        } as any;
        break;
      case "avail":
        key = line.avail === "L" ? "Local" : "National";
        break;
      default:
        key = line[actualDimension];
        break;
    }
    newData.keys[key] = keyData;
    let { week } = line;
    const ourData = { cost, ratedCost, count, spots };
    let dataObj = {
      [key]: ourData,
    };

    // Need a custom function because R.sum takes an array :eye-roll:
    newData = R.mergeDeepWith((a: number, b: number) => a + b, newData, {
      weekly: {
        [week]: dataObj,
      },
    }) as DailyDeliveryData;
  }

  // if a week doesn't have any data, add an empty value to have col on graph
  let end = new Date(dates.end);
  let start = new Date(dates.start);
  start = Dfns.addHours(12, start);
  for (let date = start; date <= end; date.setDate(date.getDate() + 7)) {
    let dateString = date.toISOString().split("T")[0];
    if (!(dateString in newData.weekly)) {
      newData.weekly[dateString] = {};
    }
  }

  return newData;
};

const useLinearDelivery = ({
  audience,
  company,
  creativeMap,
  dates,
  dimension,
  filterID,
  filterState,
  globalBrand,
  prefix,
  setError,
}: LinearDeliveryFetchProps & {
  setError: (error: ModalError) => Promise<void>;
}): DailyDeliveryData | undefined => {
  const [data, setData] = useState<Record<string, DailyDeliveryData | undefined>>({});
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  let filterStateHash = 0;
  if (filterState) {
    const filterStateString = JSON.stringify(filterState);
    for (let i = 0; i < filterStateString.length; i++) {
      const ch = filterStateString.charCodeAt(i);
      filterStateHash = (filterStateHash << 5) - filterStateHash + ch;
      filterStateHash = filterStateHash & filterStateHash;
    }
  }
  const currentKey = `${company}_${dates.start}_${dates.end}_${dimension}_${audience}_${filterID}_${filterStateHash}`;

  useEffect(() => {
    try {
      if (company && dates.start && dates.end) {
        if (data[currentKey] || loadingMap[currentKey]) {
          return;
        }
        setLoadingMap(map => ({ ...map, [currentKey]: true }));
        (async () => {
          try {
            let newData = await fetchLinearDelivery({
              audience,
              company,
              creativeMap,
              dates,
              dimension,
              filterID,
              filterState,
              globalBrand,
              prefix,
            });
            setData(data => ({
              ...data,
              [currentKey]: newData,
            }));
          } catch (e) {
            let error = e as Error;
            console.error(e);
            setError({
              message: `Couldn't fetch linear delivery data. Error: '${error.message}'`,
            });
          }
        })();
      }
    } catch (e) {
      let error = e as Error;
      console.error(error);
      setError(error);
    }
  }, [
    audience,
    company,
    creativeMap,
    currentKey,
    data,
    dates,
    dimension,
    filterID,
    filterState,
    globalBrand,
    loadingMap,
    prefix,
    setError,
  ]);

  return data[currentKey];
};

// Same as Streaming Delivery computeTotals but using ratedspend
export const computeTotals = ({
  data,
  activeKeyMap,
}: {
  data: DailyDeliveryData;
  activeKeyMap: ActiveKeyMap;
}): Totals => {
  let ourData = data.weekly;

  let totals: Record<string, TotalSummary> = {};
  let totalCost = 0;
  let totalRatedCost = 0;
  let totalCount = 0;

  for (let date of R.keys(ourData)) {
    let group = ourData[date];
    let cost = 0;
    let ratedCost = 0;
    let count = 0;
    for (let key of R.keys(group)) {
      if (activeKeyMap && !activeKeyMap[key]) {
        continue;
      }
      let keyData = group[key];
      cost += keyData.cost;
      ratedCost += keyData.ratedCost;
      count += keyData.count;
    }
    totalCost += cost;
    totalRatedCost += ratedCost;
    totalCount += count;
    totals[date] = {
      cost,
      count,
      ecpm: count ? (1000 * ratedCost) / count : 0,
    };
  }
  return {
    cost: totalCost,
    count: totalCount,
    ecpm: totalCount ? (1000 * totalRatedCost) / totalCount : 0,
    totals,
  };
};

const LinearDeliveryActions = () => {
  const {
    audience,
    brands,
    company,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    globalBrand,
    setAudience,
    setBrand,
    setDates,
    setDimension,
    setExpanded,
    setFilterID,
    setFilterState,
  } = useContext(LinearDeliveryContext);

  const currentBrand = useMemo(() => {
    if (!brands) {
      return null;
    }
    return R.find(({ cid }) => cid === company, brands);
  }, [brands, company]);

  const setError = useSetError();
  const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();

  useEffect(() => {
    if (R.isNil(filterData)) {
      (async () => {
        try {
          const res = await LinearPerformanceLambdaFetch<L.GetFilterOptionsParams>(
            "/getFilterOptions",
            {
              params: {
                company: globalBrand || company,
              },
            }
          );
          const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
          setFilterData(options);
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [company, filterData, setError, globalBrand, dates]);

  return (
    <div className="linearDeliveryActions">
      <div>
        {currentBrand && (
          <div className="labeledControl">
            <Dropdown
              size="sm"
              label="Brand"
              value={currentBrand.cid}
              options={(brands || []).map(({ cid, name }) => ({ value: cid, label: name }))}
              onChange={cid => setBrand(cid)}
            />
          </div>
        )}
        <div className="labeledControl">
          <Dropdown
            size="sm"
            label="Dimension"
            className="deliveryDropdown"
            value={dimension}
            options={R.pipe<Record<string, string>, string[], DropdownOption[]>(
              R.keys,
              R.map(value => ({ value, label: DIMENSIONS[value] }))
            )(DIMENSIONS)}
            onChange={val => setDimension(val)}
          />
        </div>
        <div className="labeledControl">
          <Dropdown
            size="sm"
            label="Audience"
            className="deliveryDropdown"
            value={audience}
            options={L.AUDIENCES}
            onChange={val => setAudience(val)}
          />
        </div>
        {filterData && (
          <FilterPane
            categories={filterData}
            company={company}
            filterID={filterID}
            highlightWhenFiltered
            platform={"linear"}
            save={setFilterState}
            setFilterID={setFilterID}
            setState={() => {}}
            state={filterState}
          />
        )}
        {dates.start && dates.end && (
          <BPMDateRange
            range={dates}
            onChange={newDates => setDates(newDates)}
            isDayBlocked={date =>
              date > MONDAY_OF_LAST_WEEK || !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)
            }
            fullWeeksOnly
          />
        )}
        <Button
          variant={expanded ? "primary" : "outline-primary"}
          onClick={() => setExpanded(!expanded)}
          size="sm"
        >
          {expanded ? <MdExpandLess /> : <MdExpandMore />}
        </Button>
      </div>
    </div>
  );
};

const LinearDeliverySubActions = ({ chartRef }) => {
  const {
    audience,
    chartLoaded,
    company,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    keyInfo,
    scaleAbsolute,
    setActiveKeyMap,
    setScaleAbsolute,
    setSpendMetric,
    spendMetric,
  } = useContext(LinearDeliveryContext);
  const setError = useSetError();

  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [downloadingPostLogs, setDownloadingPostLogs] = useState(false);

  const downloadCSV = useCallback(async () => {
    setDownloadingCSV(true);
    try {
      let res = await LinearPerformanceLambdaFetch("/delivery", {
        params: {
          ...(dates as DateRange),
          company,
          dimensions: dimension,
          impressionSource: audience,
          filter_id: filterID,
          filter_state: filterState ? JSON.stringify(filterState) : undefined,
        },
      });
      let { result } = await awaitJSON(res as Response);
      result.sort((a: { week: string }, b: { week: string }) =>
        a.week > b.week ? 1 : a.week < b.week ? -1 : 0
      );
      if (!result.length) {
        throw new Error();
      }
      const fields = ["company", ...R.keys(result[0])];
      const newRes = result.map((row: any) => ({ ...row, company }));
      const downloadCsvContents = Papa.unparse({
        fields,
        data: newRes,
      });
      download(
        downloadCsvContents,
        `Linear Delivery ${dates.start} - ${dates.end}.csv`,
        "text/csv"
      );
    } catch {
      setError({ message: "Failed to fetch data for CSV." });
    }
    setDownloadingCSV(false);
  }, [dates, company, dimension, audience, filterID, filterState, setError]);

  const downloadPNG = useCallback(() => {
    if (chartLoaded && chartRef.current) {
      let svg = chartRef.current.container.getElementsByTagName("svg")[0];
      let boundingBox = svg.getBoundingClientRect();
      let svgString = convertSVGStringToPNGURI(
        svg.outerHTML,
        boundingBox.width * 2,
        boundingBox.height * 2
      );
      download(svgString, "linear_delivery.png", "image/png");
    }
  }, [chartRef, chartLoaded]);

  const downloadPostLogs = useCallback(async () => {
    setDownloadingPostLogs(true);
    try {
      const response = await LinearLambdaFetch("/processed_post_logs", {
        params: {
          kpi: company,
          start: dates.start,
          end: dates.end,
        },
      });
      const data = await awaitJSON(response);
      await pollS3({
        bucket: data.bucket,
        mimeType: "text/csv",
        filename: data.key,
        overloadFilename: `Post Logs ${company} from ${dates.start} to ${dates.end}.csv`,
      });
    } catch {
      setError({ message: "Failed to fetch post logs data for CSV." });
    }
    setDownloadingPostLogs(false);
  }, [dates, company, setError]);

  const hasKeyInfo = useMemo(() => {
    if (!keyInfo) {
      return false;
    }
    let keys = R.keys(keyInfo);
    let firstElem = keys[0];
    return typeof keyInfo[firstElem] === "object";
  }, [keyInfo]);
  const filterOptions = useMemo(() => {
    if (hasKeyInfo) {
      return R.pipe(R.values, R.nth(0), R.keys)(keyInfo);
    }
    return [
      {
        label: dimension,
        name: dimension,
      },
    ];
  }, [keyInfo, dimension, hasKeyInfo]);

  const filterLines = useMemo(() => {
    if (hasKeyInfo) {
      return R.values(keyInfo);
    }
    return R.pipe(
      R.keys,
      R.map(key => ({ [dimension]: key }))
    )(keyInfo);
  }, [hasKeyInfo, keyInfo, dimension]);

  const onFilter = useCallback(
    filter => {
      let newMap = {};
      let keys = R.keys(keyInfo);
      for (let key of keys) {
        newMap[key] = filter(hasKeyInfo ? keyInfo[key] : { [dimension]: key });
      }
      setActiveKeyMap(newMap);
    },
    [keyInfo, setActiveKeyMap, dimension, hasKeyInfo]
  );

  return (
    <div
      className="subActions"
      style={{
        display: expanded ? "flex" : "none",
      }}
    >
      <div className="filterBar">
        <OldFilterBar
          options={filterOptions}
          lines={filterLines as Record<string, string | number | boolean>[]}
          onFilter={onFilter}
        />
      </div>
      <div className="labeledControl">
        <DownloadDropdown
          background="dark"
          onClickOptions={[downloadCSV, downloadPNG, downloadPostLogs]}
          menuOptions={["CSV", "PNG", "Post Logs"]}
          disabled={downloadingCSV || downloadingPostLogs}
        />
      </div>
      <BPMToggleButton
        options={[
          {
            key: "Spend",
          },
          {
            key: "Impressions",
          },
        ]}
        selectedOption={spendMetric ? "Spend" : "Impressions"}
        onChange={key => setSpendMetric(key === "Spend")}
      />
      <BPMToggleButton
        options={[
          {
            key: "Absolute",
          },
          {
            key: "Relative",
          },
        ]}
        selectedOption={scaleAbsolute ? "Absolute" : "Relative"}
        onChange={key => setScaleAbsolute(key === "Absolute")}
      />
    </div>
  );
};

const useActiveKeyMap = ({
  data,
  dimension,
}: {
  data: DailyDeliveryData | undefined;
  dimension: any;
}): [ActiveKeyMap, (key: string | Record<string, boolean>) => void] => {
  const [activeKeyMapMap, setActiveKeyMapMap] = useState<ActiveKeyMapMap>({});

  const setActiveKeyMap = useCallback(
    (arg: string | Record<string, boolean>) => {
      if (!data) {
        return;
      }
      if (typeof arg !== "string") {
        setActiveKeyMapMap(mapMap => ({
          ...mapMap,
          [dimension]: {
            ...mapMap[dimension],
            ...arg,
          },
        }));
      } else if (arg === "all" || arg === "none") {
        const newSettings = R.pipe(
          R.keys,

          R.reduce((map, subKey) => {
            map[subKey] = arg === "all";
            return map;
          }, {})
        )(data.keys);

        setActiveKeyMapMap({
          ...activeKeyMapMap,
          [dimension]: newSettings,
        });
      } else {
        let val = R.path([dimension, arg], activeKeyMapMap);
        if (R.isNil(val)) {
          val = false;
        } else {
          val = !val;
        }
        setActiveKeyMapMap(R.assocPath([dimension, arg], val));
      }
    },
    [data, dimension, setActiveKeyMapMap, activeKeyMapMap]
  );

  const activeKeyMap = useMemo(() => {
    let existingMap = activeKeyMapMap[dimension] || {};
    let keys = R.keys(data?.keys);
    return R.reduce<string, ActiveKeyMap>(
      (map, key) => {
        if (R.isNil(existingMap[key])) {
          map[key] = true;
        } else {
          map[key] = existingMap[key];
        }
        return map;
      },
      {},
      keys
    );
  }, [dimension, activeKeyMapMap, data]);

  return [activeKeyMap, setActiveKeyMap];
};

const OTHER_THRESHOLD = 10;

export const useOtherMap = (
  data: DailyDeliveryData | undefined,
  activeKeyMap: ActiveKeyMap,
  spendMetric = DEFAULT_SPEND_SETTING
): OtherMap =>
  useMemo(() => {
    if (!data) {
      return {};
    }
    const ourData = data.weekly;
    let map = {};
    for (let date of R.keys(ourData)) {
      let itemMap = ourData[date];
      let items: { key: string; value: number }[] = [];
      for (let key of R.keys(itemMap)) {
        if (activeKeyMap[key]) {
          let item = itemMap[key];
          items.push({
            key,
            value: item[spendMetric ? "cost" : "count"],
          });
        }
      }
      items = R.sort(R.descend<{ value: number }>(R.prop("value")), items);
      let mapItem: Record<DeliveryKey, boolean> = {};
      for (let i = 0; i < items.length; ++i) {
        mapItem[items[i].key] = i >= OTHER_THRESHOLD;
      }
      map[date] = mapItem;
    }
    return map;
  }, [data, activeKeyMap, spendMetric]);

const LinearDelivery: React.FC<{
  prefix: L.Prefix;
}> = ({ prefix }) => {
  const chartRef = useRef();
  const networkColorMap = useColorMap({});
  const defaultColorMap = useColorMap({});
  const [company, brands, setBrand] = useBrands();
  // Don't include current week if Monday, Tuesday, or Wednesday
  const startingDateRange = useMemo(() => {
    let currentDay = new Date().getDay();
    let isEarlyWeek = currentDay >= 1 && currentDay <= 3;
    return {
      start: computeResolvedDate({
        adjustmentType: "week",
        adjustment: isEarlyWeek ? 5 : 4,
        pivotDate: "monday",
      }),
      end: computeResolvedDate({
        adjustmentType: "week",
        adjustment: isEarlyWeek ? 2 : 1,
        pivotDate: "monday",
      }),
    };
  }, []);
  const [audience, setAudience] = useState<L.Audience>(L.AUDIENCES[0]);
  const [dates, setDates] = useState<DateRange>(startingDateRange);
  const [dimension, setDimension] = useState<string>(R.keys(DIMENSIONS)[0]);
  const [filterID, setFilterID] = useState<number | undefined>();
  const [filterState, setFilterState] = useFilterPaneState();
  const [scaleAbsolute, setScaleAbsolute] = useState<boolean>(DEFAULT_SCALE_SETTING);
  const [spendMetric, setSpendMetric] = useState<boolean>(DEFAULT_SPEND_SETTING);
  const companyInfo = useCompanyInfo();
  const globalBrand = getGlobalBrand(companyInfo, companyInfo.initial_kpi);
  const { creativeMap } = useCreativeMap({
    company: globalBrand || company,
    mediaTypes: ["linear"],
  });

  const setError = useSetError();
  const data = useLinearDelivery({
    audience,
    company,
    creativeMap,
    dates,
    dimension,
    filterID,
    filterState,
    globalBrand,
    prefix,
    setError,
  });

  const [activeKeyMap, setActiveKeyMap] = useActiveKeyMap({ data, dimension });

  const otherMap = useOtherMap(data, activeKeyMap, spendMetric);

  const { cost, ecpm, count, totals } = useMemo<Partial<Totals>>(() => {
    if (!(data && activeKeyMap)) {
      return {};
    }
    return computeTotals({
      data,
      activeKeyMap,
    });
  }, [data, activeKeyMap]);

  let colorMap: ColorMap;

  switch (dimension) {
    case "network":
      colorMap = networkColorMap;
      break;
    default:
      colorMap = defaultColorMap;
      break;
  }

  const chartLoaded = !!(data && totals);
  const [expanded, setExpanded] = useState(false);
  const [hoverKey, setHoverKey] = useState<string>();

  // Kind of a hack to make creative filter-bar-able. Would be nice to change backend to put this
  // metadata in data.keys.
  const filterKeyInfo = useMemo(() => {
    let ourKeyInfo = data?.keys || {};
    if (dimension === "creative") {
      let keys = R.keys(ourKeyInfo);
      let newOurKeyInfo = {};
      for (let key of keys) {
        const [isci, creative, length] = key.split("_");
        newOurKeyInfo[key] = { isci, creative, length };
      }
      ourKeyInfo = newOurKeyInfo;
    }
    return ourKeyInfo;
  }, [data, dimension]);

  let context = {
    activeKeyMap,
    audience,
    brands,
    chartLoaded,
    company,
    creativeMap,
    dates,
    dimension,
    expanded,
    filterID,
    filterState,
    globalBrand,
    hoverKey,
    keyInfo: filterKeyInfo,
    prefix,
    scaleAbsolute,
    setActiveKeyMap,
    setAudience,
    setBrand,
    setDates,
    setDimension,
    setExpanded,
    setFilterID,
    setFilterState,
    setHoverKey,
    setScaleAbsolute,
    setSpendMetric,
    spendMetric,
  };

  return (
    <LinearDeliveryContext.Provider value={context}>
      <Page
        minHeight="700px"
        title="Linear Delivery"
        pageType="Linear Delivery"
        actions={<LinearDeliveryActions />}
      >
        <div className="linearDelivery">
          <LinearDeliverySubActions chartRef={chartRef} />
          <div className="summaries">
            <InfoCard
              title="Impressions"
              icon={<ImpressionsIcon />}
              data={count}
              formatter={formatNumberAsInt}
              info={
                count === 0 && cost !== 0
                  ? "The selected options use networks that do not have impressions data."
                  : "The total number of impressions served between the selected start and end dates (inclusive)."
              }
              notAvailable={count === 0 && cost !== 0}
            />
            <InfoCard
              title="eCPM"
              icon={<ECPMIcon />}
              data={ecpm}
              formatter={formatMoney}
              info={
                count === 0 && cost !== 0
                  ? "The selected options use networks that do not have impressions data, and therefore no ecpm."
                  : "The effective CPM: the total cost of the media delivered divided by the total number of impressions served (in thousands) between the selected start and end dates. Any over-delivery of impressions (which reduces eCPM) will be reflected here."
              }
              notAvailable={ecpm === 0 && cost !== 0}
            />
            <InfoCard
              title="Spend"
              icon={<SpendIcon />}
              data={cost}
              formatter={formatMoneyAsInt}
              info="The total cost of media delivered between the selected start and end dates, inclusive of media cost and ad serving fees."
              onLeft
            />
          </div>
          <div className="box chartBox">
            <div className="chart">
              {chartLoaded && data && totals ? (
                <DeliveryChart
                  ref={chartRef}
                  data={data.weekly}
                  totals={totals}
                  activeKeyMap={activeKeyMap}
                  absolute={scaleAbsolute}
                  isSpend={spendMetric}
                  colorMap={colorMap}
                  daily={false}
                  dimension={dimension}
                  otherMap={otherMap}
                />
              ) : (
                <Skeleton>
                  <BarGraphSkeleton
                    barWidth={55}
                    gutter={50}
                    verticalPadding={15}
                    horizontalPadding={50}
                  />
                </Skeleton>
              )}
            </div>
            <div className="legend">
              {chartLoaded && activeKeyMap && data ? (
                <Legend
                  activeKeyMap={activeKeyMap}
                  setActiveKeyMap={setActiveKeyMap}
                  colorMap={colorMap}
                  dimension={dimension}
                  keyInfo={data?.keys}
                />
              ) : (
                <div className="loadingLegend">
                  <Skeleton>
                    <ItemListSkeleton size={50} gutter={20} verticalPadding={0} />
                    <ItemListSkeleton
                      size={15}
                      gutter={55}
                      verticalPadding={55}
                      horizontalPadding={-20}
                    />
                    <ItemListSkeleton size={50} gutter={20} verticalPadding={90} />
                    <ItemListSkeleton
                      size={15}
                      gutter={55}
                      verticalPadding={145}
                      horizontalPadding={-20}
                    />
                  </Skeleton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    </LinearDeliveryContext.Provider>
  );
};

export default LinearDelivery;
