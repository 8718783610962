import "./SpendChart.scss";
import { Spinner } from "./Spinner";
import InnerLineChartBox from "./InnerLineChartBox";
import React, { useEffect, useMemo } from "react";

export type SpendDate = string;
export interface CompetitiveData {
  date: SpendDate;
  count: number;
}

interface SpendChartProps {
  chartData: CompetitiveData[];
  color: string;
  height?: number;
  width?: number;
  onPathLoad?: (loaded: boolean) => void;
}

export const SpendChart = React.forwardRef<SVGSVGElement | null, SpendChartProps>(
  ({ chartData, width, height, color, onPathLoad }, ref) => {
    useEffect(() => {
      if (chartData.length > 1) {
        if (onPathLoad) {
          onPathLoad(true);
        }
      }
    }, [onPathLoad, chartData.length]);

    const chart = useMemo(() => {
      return chartData.length > 1 ? (
        <InnerLineChartBox
          chartData={chartData}
          isSpend={true}
          strokeColor={[color]}
          ref={ref}
          height={height}
          width={width}
          isAnimationActive={false}
          xAxisLine={false}
          xAxisFontSize={12}
          xAxisTickCount={2}
          xAxisTicks={[chartData[0].date, chartData[chartData.length - 1].date]}
          yAxisLine={false}
          yAxisFontSize={12}
          yAxisTickCount={5}
          chartMargins={{
            top: 0,
            right: 0,
            left: 10,
            bottom: -12,
          }}
        />
      ) : null;
    }, [chartData, color, height, ref, width]);

    return (
      <div className="spendChart" style={{ width, height }}>
        {chart ? (
          <svg ref={ref} viewBox={`0 0 ${width} ${height}`}>
            {chart}
          </svg>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
);
