import React, { useState, useEffect } from "react";

import * as R from "ramda";

import { useSetError } from "../redux/modals";
import { AdminLambdaFetch, awaitJSON, pollS3 } from "../utils/fetch-utils";

import { Page, Spinner, DateRangePicker } from "../Components";

import useLocation from "../utils/hooks/useLocation";

import * as Dfns from "date-fns/fp";
import { Form, Button } from "react-bootstrap";

import "./StreamingInvoicing.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const MONTH_DATE_FORMAT = "yyyy-MM";

const initDates = () => {
  let start = R.pipe(Dfns.subMonths(1), Dfns.startOfMonth, Dfns.format(DATE_FORMAT))(new Date());
  let end = R.pipe(Dfns.subMonths(1), Dfns.endOfMonth, Dfns.format(DATE_FORMAT))(new Date());
  return { start, end };
};

const StreamingInvoicing = () => {
  const [commission, setCommission] = useState([]);
  const [dates, setDates] = useState(initDates);
  const [fetched, setFetched] = useState(false);
  const [generating, setGenerating] = useState(false);
  const setError = useSetError();
  const { company } = useLocation();

  useEffect(() => {
    if (!fetched) {
      (async () => {
        try {
          const res = await AdminLambdaFetch("/createCompanyCommissionMap", {
            params: { company },
          });

          let data = await awaitJSON(res);

          let lastMonthDate = R.pipe(Dfns.subMonths(1), Dfns.format(MONTH_DATE_FORMAT))(new Date());

          let lastMonthCommission = data.commissionByMonth[lastMonthDate];

          setFetched(true);
          setCommission(lastMonthCommission);
        } catch (e) {
          setError({
            message: `Couldn't run commission check. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [commission, setError, fetched, company]);

  return (
    <Page title="Streaming Global Information" pageType="Streaming Global Information">
      <div className="streamingInvoicing">
        <DateRangePicker
          startDate={dates.start}
          endDate={dates.end}
          startDateId="streamingInvoicingStartDate"
          endDateId="streamingInvoicingEndDate"
          onChange={({ startDate, endDate }) => {
            setDates({
              start: startDate,
              end: endDate,
            });
          }}
        />

        <Form.Group>
          <Form.Label>Commission</Form.Label>
          <Form.Control
            type="number"
            value={commission}
            onChange={e => setCommission(e.target.value)}
          />
        </Form.Group>

        <Button
          className="generateButton"
          disabled={generating}
          onClick={() => {
            setGenerating(true);

            (async () => {
              let response = await AdminLambdaFetch("/invoicing/generate", {
                params: {
                  company,
                  start: dates.start,
                  end: dates.end,
                  commission,
                },
              });

              let { filename } = await awaitJSON(response);

              await pollS3({
                bucket: "bpm-invoicing",
                mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                filename: `${filename}.xlsx`,
              });

              setGenerating(false);
            })();
          }}
        >
          {generating ? (
            <>
              <div className="generatingText">Generating...</div>
              <Spinner color="white" />
            </>
          ) : (
            "Generate"
          )}
        </Button>
      </div>

      <div>
        <ul>
          <li>
            <strong>Effective Start Date:</strong> When a flight begins before the date range, this
            will be the beginning of the date range. For example, if a flight starts 5/28, but the
            date range starts 6/1, this will be 6/1
          </li>
          <li>
            <strong>Effective End Date:</strong> Same as Effective Start Date, but based on the end
            of the date range, and instead of flight end date, it uses the flight extended end date,
            if set.
          </li>
          <li>
            <strong>Total Impressions Received:</strong> All impressions that fall in the flight
            date range. This takes extended end date into account. This is <em>not</em> based on
            effective dates.
          </li>
          <li>
            <strong>Impressions Outside Effective Range:</strong> Impressions that fall outside the
            effective date range. For a flight that's entirely contained in the date range, this
            will be zero.
          </li>
          <li>
            <strong>Impressions In Effective Window:</strong> Impressions inside our effective date
            range. For a flight that's entirely contained in the date range, this will be equal to
            the Total Impressions Received.
          </li>
          <li>
            <strong>Remaining Impressions in Effective Range:</strong> The number of impressions
            remaining in an order that began before our range. For flights that start after the
            beginning the date range, this number will always equal the number of impressions
            originally ordered. For flights that begin before our date range, this is the number of
            impressions ordered minus the number of impressions that were served before this date
            range. For example, if an order has 5,000 impressions, but before our date range we
            already got 2,000 impressions, this number will be 3,000. That's the number we will use
            to compute overdelivery.
          </li>
          <li>
            <strong>Billable Impressions:</strong> The minimum of the Remaining Impressions in
            Effective Range and the ordered number of impressions. If there was underdelivery, we
            pay for the impressions we got. If there was overdelivery, we only pay for the
            impressions we ordered.
          </li>
          <li>
            <strong>Over-delivery:</strong> The total number of impressions served minus the number
            of impressions ordered. In the event of underdelivery, this will be negative. If a
            flight spans past the end of our date range, this is 0, unless there is already
            overdelivery.
          </li>
          <li>
            <strong>Fill Rate:</strong> The total number of impressions servered in the IO over the
            number of impressions ordered. For flights that start before our date range, this takes{" "}
            <em>all</em> impressions into account, not just those in our effective range. For
            flights that extend past our date range, this will be 100% unless there is already
            overdelivery.
          </li>
          <li>
            <strong>Actual Spend (AP):</strong> Billable Impressions times the CPM.
          </li>
          <li>
            <strong>BPM Fee:</strong> Actual Spend (AP) times Commission
          </li>
          <li>
            <strong>Ad Serving Fee:</strong> Total Impressions Received times Ad Serving Rate.
          </li>
        </ul>
      </div>
    </Page>
  );
};

export default StreamingInvoicing;
