import React, { useState, useEffect } from "react";
import * as Dfns from "date-fns/fp";

import { useSetError } from "../redux/modals";

import { awaitJSON, MiscLambdaFetch } from "../utils/fetch-utils";

import { Page, Spinner, BPMTable } from "../Components";

import "./IncrementalityJobStatus.scss";

const IncrementalityJobStatusTable = ({ data, headers }) => {
  if (!data) {
    return <Spinner size={75} />;
  }

  return (
    <BPMTable
      headers={headers}
      data={data}
      noRowsRenderer={() => <div>No rows to show.</div>}
      rowHeight={45}
      headerHeight={40}
      defaultTokens={{
        advanced: ["Enabled", "is", "true"],
      }}
      defaultAdvancedFilter
    />
  );
};

const IncrementalityStatusAlert = React.memo(({ status_type }) => {
  switch (status_type) {
    case "SUCCEEDED":
      return (
        <div className="incrementality-status-alert incrementality-status-alert-success">
          <h4>{status_type}</h4>
        </div>
      );
    case "FAILED":
      return (
        <div className="incrementality-status-alert incrementality-status-alert-fail">
          <h4>{status_type}</h4>
        </div>
      );
    case "RUNNING":
      return (
        <div className="incrementality-status-alert incrementality-status-alert-running">
          <h4>{status_type}</h4>
        </div>
      );
    default:
      return null;
  }
});

const EnabledAlert = React.memo(({ status_type }) => {
  switch (status_type) {
    case true:
      return (
        <div className="incrementality-status-alert incrementality-status-alert-success">
          <h4>{`${status_type}`}</h4>
        </div>
      );
    case false:
      return (
        <div className="incrementality-status-alert incrementality-status-alert-fail">
          <h4>{`${status_type}`}</h4>
        </div>
      );
    default:
      return null;
  }
});

const IncrementalityJobStatus = () => {
  const setError = useSetError();
  const [statuses, setStatuses] = useState();

  useEffect(() => {
    (async () => {
      try {
        let allStatuses = await MiscLambdaFetch("/syntheticControlJobStatus", {
          method: "GET",
          retries: 0,
          headers: {
            "Keep-Alive": "timeout=10",
          },
        });
        let parsedResult = await awaitJSON(allStatuses);
        setStatuses(parsedResult);
      } catch (e) {
        setError({ message: "Failed to fetch incrementality job statuses", reportError: e });
        setStatuses([]);
      }
    })();
  }, [setError]);

  return (
    <Page title="Incrementality Job Status" pageType="Incrementality Job Status" minHeight={600}>
      <div className="bpm-incrementality-status">
        {statuses ? (
          <IncrementalityJobStatusTable
            data={statuses}
            headers={[
              {
                flex: 2,
                label: "Company",
                name: "company",
                renderer: data => data.company,
              },
              {
                flex: 2,
                label: "Platform",
                name: "platform",
                renderer: data => data.platform,
              },
              {
                flex: 1,
                label: "Enabled",
                name: "enabled",
                renderer: data => {
                  return (
                    <div className="incrementality-status-alert-card">
                      <EnabledAlert status_type={data.enabled} />
                    </div>
                  );
                },
              },
              {
                flex: 2,
                label: "Last Delivery Date",
                name: "last_delivery_date",
                renderer: data => data.last_delivery_date,
              },
              {
                flex: 2,
                label: "Most Recent Run (your time)",
                name: "end_time",
                renderer: data => {
                  return data.end_time
                    ? Dfns.format("yyyy-MM-dd hh:mma", Dfns.parseISO(data.end_time))
                    : "-";
                },
              },
              {
                flex: 2,
                label: "Most Recent Status",
                name: "status",
                renderer: data => {
                  return (
                    <div className="incrementality-status-alert-card">
                      <IncrementalityStatusAlert status_type={data.status} />
                    </div>
                  );
                },
              },
            ]}
          />
        ) : (
          <Spinner size={100} />
        )}
      </div>
    </Page>
  );
};

export default IncrementalityJobStatus;
