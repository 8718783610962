import { QuestionCardProps } from "../../Components/QuestionCard/QuestionCard";

export const questions = [
  {
    "id": "34c2b1ec-7519-4946-8f41-4a1d066dc84f",
    "questionNumber": "6A1",
    "title": "Do you have dev resources (in-house or partner) available to assist with platform API implementations?",
    "subtitle": "If you don't have dev resources, Tinuiti can supply them free of charge.",
    "type": "radio",
    "options": ["Yes", "No"],
  },
  {
    "id": "fdf9f798-1b0b-442a-a444-7cf78aa5c75e",
    "questionNumber": "6A2",
    "title": "If using Google platforms, which site tags track the source of truth for bidding?",
    "type": "radio",
    "options": ["Floodlights", "Google Ads conversion tags", "GA4 Event tags", "N/A"],
  },
  {
    "id": "a8b48db9-b5ed-49a1-b990-2221c84fcd7f",
    "questionNumber": "6A3",
    "title": "Please list all of the conversion actions used to measure performance.",
    "subtitle": "If the main conversion KPI is purchase, please provide any site testing criteria for test purchases.",
    "type": "textarea",
    "textLabel": "List conversion actions here:",
  },
  {
    "id": "5bb71baf-535a-4199-8f0a-9f063b80b505",
    "questionNumber": "6A4",
    "title": "What is your company's marketing objective?",
    "subtitle": "If not, Tinuiti has resources available free of charge.",
    "type": "checkbox",
    "options": ["Drive Awareness", "Generate Leads", "Online Sales", "App Downloads", "Other"],
    "nestedOptionsConfig": {
      "Other": [
        {
          "inputType": "textarea",
          "placeholder": "List here"
        }
      ]
    },
  },
  {
    "id": "a0967dae-4b09-4a4b-8e8d-d3aaeb01b45a",
    "questionNumber": "6A5",
    "title": "Do you collect first party user data on your website's order confirmation pages?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": [
        {
          "inputType": "checkbox",
          "options": ["Conversions API for Offline", "OCAPI", "2nd Party Partner", "Manual Uploads"]
        }
      ]
    },
  },
  {
    "id": "8b15f9be-39bd-43bd-a0fe-cf54ac0a8947",
    "questionNumber": "6A6",
    "title": "Are you working with partner(s) to ingest offline customer data?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": [
        {
          "inputType": "textarea",
          "label": "Partner Name",
          "placeholder": "Example Text"
        }
      ]
    },
  },
  {
    "id": "a50d8160-003e-4625-91b1-4b5a115ce856",
    "questionNumber": "6A7",
    "title": "Do you have additional data sources?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": [
        {
          "inputType": "checkbox",
          "options": ["In-store sales purchase data", "Customer data submitted on website", "Loyalty program / membership", "Surveys", "Call center data", "Other"]
        }
      ]
    },
  },
  {
    "id": "96892c76-8653-48d3-af89-ef20550164f7",
    "questionNumber": "6A8",
    "title": "Do you have any difficulties or restrictions passing first party data into platforms such as Meta?",
    "subtitle": "Ex: technical limitations, messy data",
    "type": "radio",
    "options": ["Yes", "No"],
  }
] as QuestionCardProps[];
