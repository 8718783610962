import React, { useState, useEffect, useCallback, useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { awaitJSON, LinearOptimizationsLambdaFetch, LinearLambdaFetch } from "../utils/fetch-utils";

import { Page, FullPageSpinner, Spinner, ModalEditTable } from "../Components";

import "./LinearConstraints.scss";

const PRETTY_DATE_FORMAT = "yyyy-MM-dd hh:mma";

const LinearConstraints = () => {
  const setError = useSetError();
  const [buyableNetworkAvailRotations, setBuyableNetworkAvailRotations] = useState();
  const [rotationsByNetwork, setRotationsByNetwork] = useState();

  useEffect(() => {
    if (!buyableNetworkAvailRotations) {
      (async () => {
        try {
          let linearRotationsAndPricing = await LinearLambdaFetch("/get_rates");
          linearRotationsAndPricing = await awaitJSON(linearRotationsAndPricing);
          let grouped = R.pipe(
            R.filter(row => row.status === "buyable"),
            R.groupBy(row => row.network),
            R.map(networkGroup =>
              R.pipe(
                R.groupBy(row => row.avail),
                R.map(networkAvailGroup =>
                  R.fromPairs(
                    R.map(
                      row => [
                        `${row.rotation_name} (${row.day_set} ${row.daypart_begin} - ${row.daypart_end})`,
                        true,
                      ],
                      networkAvailGroup
                    )
                  )
                )
              )(networkGroup)
            )
          )(linearRotationsAndPricing);

          setBuyableNetworkAvailRotations(grouped);
          setRotationsByNetwork(
            R.pipe(
              R.filter(
                rotation =>
                  (rotation.status === "buyable" || rotation.status === "secured") &&
                  Dfns.isValid(
                    Dfns.parseISO(
                      `2016-01-01T${rotation.daypart_begin.length === 4 ? "0" : ""}${
                        rotation.daypart_begin
                      }:00`
                    )
                  ) &&
                  Dfns.isValid(
                    Dfns.parseISO(
                      `2016-01-01T${rotation.daypart_end.length === 4 ? "0" : ""}${
                        rotation.daypart_end
                      }:00.000000`
                    )
                  )
              ),
              R.map(rotation => {
                let start = Dfns.parseISO(
                  `2016-01-01T${rotation.daypart_begin.length === 4 ? "0" : ""}${
                    rotation.daypart_begin
                  }:00`
                );
                let end = Dfns.parseISO(
                  `2016-01-01T${rotation.daypart_end.length === 4 ? "0" : ""}${
                    rotation.daypart_end
                  }:00.000000`
                );
                while (Dfns.getMinutes(end) % 30 !== 0) {
                  end = Dfns.addMinutes(1, end);
                }

                rotation.fullName = `${rotation.rotation_name} (${rotation.day_set} ${Dfns.format(
                  "h:mma",
                  start
                )}-${Dfns.format("h:mma", end)})`;
                return [rotation];
              }),
              R.unnest,
              R.groupBy(R.prop("network"))
            )(linearRotationsAndPricing)
          );
        } catch (e) {
          setError({
            message: `Failed to load network rotation info: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [buyableNetworkAvailRotations, setError]);

  const [dirty, setDirty] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    if (!data) {
      (async () => {
        let lambdaData = await LinearOptimizationsLambdaFetch("/constraints", {
          params: {
            company: "global",
            name: "global",
          },
        });
        lambdaData = await awaitJSON(lambdaData);

        for (let table of ["maximum", "avoid"]) {
          for (let i = 0; i < lambdaData.constraints[table].length; i++) {
            if (!lambdaData.constraints[table][i].lastmodified) {
              lambdaData.constraints[table][i].lastmodified = "2020-01-01T00:00:00-08:00";
            }
          }
          lambdaData.constraints[table] = R.pipe(
            R.path(["constraints", table]),
            R.sortWith([R.descend(R.prop("lastmodified"))])
          )(lambdaData);
          for (let i = 0; i < lambdaData.constraints[table].length; i++) {
            lambdaData.constraints[table][i].index = i;
          }
        }
        setData(lambdaData);
      })();
    }
  }, [data]);

  const [saving, setSaving] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);
    try {
      let lambdaData = await LinearOptimizationsLambdaFetch("/constraints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      });
      lambdaData = await awaitJSON(lambdaData);
      setData(lambdaData);
      setDirty(false);
    } catch (e) {
      setError({ message: e.message, reportError: e });
    }
    setSaving(false);
  }, [data, setError]);

  const maximumHeaders = [
    {
      type: "select",
      field: "network",
      label: "Network",
      isMulti: false,
      options: "network",
      clearKeys: ["avail", "rotation"],
      modalRow: 0,
      modalFlex: 2,
    },
    {
      type: "select",
      field: "reach",
      label: "Avail",
      options: "avail",
      clearKeys: ["rotation"],
      modalRow: 0,
      modalWidth: 100,
    },
    {
      type: "select",
      field: "rotation",
      label: "Rotation",
      isMulti: false,
      width: 300,
      options: "networkRotation",
      modalRow: 1,
      modalFlex: 2,
    },
    {
      type: "numeric",
      field: "length15sUnits",
      label: "15s units",
      width: 120,
      modalRow: 2,
      modalFlex: 2,
    },
    {
      type: "numeric",
      field: "length30sUnits",
      label: "30s units",
      width: 120,
      modalRow: 2,
      modalFlex: 3,
    },
    {
      type: "numeric",
      field: "length60sUnits",
      label: "60s units",
      width: 120,
      modalRow: 2,
      modalFlex: 4,
    },
    {
      type: "currency",
      field: "spend",
      label: "Spend",
      width: 120,
      modalRow: 3,
      modalFlex: 1,
    },
    {
      type: "text",
      field: "notes",
      label: "Notes",
      flex: 1,
      modalRow: 4,
      modalFlex: 1,
    },
    {
      type: "timestamp",
      field: "lastmodified",
      label: "Last Modified",
      width: 200,
      modalRow: 5,
      modalFlex: 1,
      readOnly: true,
    },
  ];

  const avoidHeaders = [
    {
      type: "select",
      field: "network",
      label: "Network",
      isMulti: false,
      options: "network",
      clearKeys: ["avail", "rotation"],
      modalRow: 0,
      modalFlex: 2,
    },
    {
      type: "select",
      field: "avail",
      label: "Avail",
      options: "avail",
      clearKeys: ["rotation"],
      modalRow: 0,
      modalWidth: 100,
    },
    {
      type: "select",
      field: "rotation",
      label: "Rotation",
      isMulti: false,
      width: 300,
      options: "networkRotationWithoutEach",
      modalRow: 1,
      modalFlex: 2,
    },
    {
      type: "select",
      field: "length",
      label: "Length",
      options: "length",
      modalRow: 1,
      modalWidth: 100,
    },
    {
      type: "text",
      field: "notes",
      label: "Notes",
      flex: 1,
      modalRow: 2,
      modalFlex: 1,
    },
    {
      type: "timestamp",
      field: "lastmodified",
      label: "Last Modified",
      width: 200,
      modalRow: 3,
      modalFlex: 1,
      readOnly: true,
    },
  ];

  const lastMod = useMemo(
    () =>
      data
        ? `Last Modified: ${R.pipe(
            R.prop("lastuser"),
            R.defaultTo(""),
            R.replace(/@.*/, "")
          )(data)} at ${Dfns.format(PRETTY_DATE_FORMAT)(Dfns.parseISO(data.lastmodified))}`
        : "",
    [data]
  );

  const selectorOptions = useMemo(() => {
    return {
      rotation: R.map(e => ({ label: e, value: e }), [
        "Overnight",
        "Early Morning",
        "Daytime",
        "Early Fringe",
        "Prime",
        "Late Fringe",
        "Broad Rotator",
        "Weekend",
      ]),
      days: R.map(e => ({ label: e, value: e }), ["Weekday", "Weekend"]),
      avail: R.map(e => ({ label: e, value: e }), ["N", "L"]),
      length: R.map(e => ({ label: e, value: e }), ["15", "30", "60"]),
      vingtiles: R.map(e => ({ label: e, value: e }), R.range(1, 21)),
      network: R.pipe(
        R.keys,
        R.map(key => ({ value: key, label: key })),
        R.prepend({ label: "Each Network", value: "Each Network" })
      )(buyableNetworkAvailRotations),
      networkRotation: R.mapObjIndexed(
        list =>
          R.concat(
            [{ value: "Each Rotation", label: "Each Rotation" }],
            R.map(row => ({ value: row.fullName, label: row.fullName }), list)
          ),
        rotationsByNetwork
      ),
      networkRotationWithoutEach: R.mapObjIndexed(
        list => R.map(row => ({ value: row.fullName, label: row.fullName }), list),
        rotationsByNetwork
      ),
    };
  }, [buyableNetworkAvailRotations, rotationsByNetwork]);

  return (
    <Page
      title="Linear Global Constraints"
      pageType="Linear Global Constraints"
      minHeight={600}
      actions={
        <div className={`linearConstraintActions${saving ? " saving" : ""}`}>
          {data && <div>{lastMod}</div>}
          <Button variant="primary" onClick={save} disabled={!dirty} className="saveButton">
            {saving ? <Spinner color="white" /> : <MdSave />}
          </Button>
        </div>
      }
    >
      {R.prop("constraints", data) ? (
        <div className={`linearConstraints${saving ? " saving" : ""}`}>
          <ModalEditTable
            headers={maximumHeaders}
            name="Maximum constraints"
            tableData={data.constraints.maximum}
            filterBar={true}
            setTableData={tableData => {
              setData({ ...data, constraints: { ...data.constraints, maximum: tableData } });
              setDirty(true);
            }}
            selectorOptions={selectorOptions}
          />
          <ModalEditTable
            headers={avoidHeaders}
            name="Avoid constraints"
            tableData={data.constraints.avoid}
            filterBar={true}
            setTableData={tableData => {
              setData({ ...data, constraints: { ...data.constraints, avoid: tableData } });
              setDirty(true);
            }}
            selectorOptions={selectorOptions}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default LinearConstraints;
