import * as R from "ramda";

export const DATE_FORMAT = "yyyy-MM-dd";

export interface MemoizedGetter<Input, Output> {
  (input: Input): Output;
}
export const makeMemoizedGetter = <Input, Output>({
  calculate,
  makeKey = JSON.stringify,
}: {
  calculate: (val: Input) => Output;
  makeKey?: (val: Input) => string;
}): MemoizedGetter<Input, Output> => {
  let table: Record<string, Output> = {};
  return val => {
    let key = makeKey(val);
    if (Object.prototype.hasOwnProperty.call(table, key)) {
      return table[key];
    }
    let calculated = calculate(val);
    table[key] = calculated;
    return calculated;
  };
};

/**
 *  Function to shorten numbers for the y-axis
 *  It is also used in the Reporting.tsx file to shorten file sizes
 *  Will try and bucket the number into a denom (e.g 'k', 'm', 'g', 't')
 *  finds the appropriate bucket by looking at the number length and then
 *  dividing it by three in order to place it an index, that index value is then used to trim the
 *  number from least-to-most significant figures (e.g. 250,000 will become 250k and so)
 * @param {number} value
 * @returns {string} newValue
 */
export const abbreviateNumber = (value: number): string | number => {
  let newValue: string | number = value;
  if (value >= 1000) {
    const suffixes = ["", "k", "m", "g", "t"];
    const suffixNum = Math.floor((`${value}`.length - 1) / 3);
    let short = (value / 1000 ** suffixNum).toFixed(1);
    newValue = `${short}${suffixes[suffixNum]}`;
  }

  return newValue;
};

export const parseInputToInt = (input = ""): number => parseInt(input.replace(/[^\d.]/g, "")) || 0;
export const parseInputToFloat = (input = ""): number =>
  parseFloat(input.replace(/[^\d.]/g, "")) || 0;

export const mergeNumberObjects = R.mergeDeepWith((a: number, b: number) => a + b);
