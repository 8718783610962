import React, { useContext } from "react";

import * as R from "ramda";

import { Card, PieSetter } from "../Components";

import { EditableCard } from "./Cards";

import { StreamingCreativesContext } from "./StreamingCreatives";

const Workspace = () => {
  const { workspaceOptions, workspaceMap, setWorkspaceValue } = useContext(
    StreamingCreativesContext
  );
  return (
    <div className="workspace">
      <Card className="workspaceCard">
        <div className="workspaceCardInner">
          <PieSetter
            pieFlex={0.5}
            percentage
            data={R.map(
              opt => ({
                ...opt,
                key: opt.isci,
                value: workspaceMap[opt.isci] || 0,
              }),
              workspaceOptions
            )}
            renderItem={elem => <EditableCard {...elem} onChange={setWorkspaceValue} />}
          />
        </div>
      </Card>
    </div>
  );
};

export default Workspace;
