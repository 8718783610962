import React from "react";
import "./ChartTooltip.scss";

interface PayloadItem {
  label: string | number | undefined;
  value: string | number | undefined;
  color: string | undefined;
}

interface ChartTooltipProps {
  headerLabel: string;
  items: PayloadItem[];
  shape?: "circle" | "square" | "line";
  reverseItems?: boolean;
  valueFormatter?: (value: number) => string;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  headerLabel,
  items,
  shape = "square",
  reverseItems,
  valueFormatter,
}) => {
  const displayedItems = reverseItems ? [...items].reverse() : items;
  return (
    <div className="chart-tooltip">
      <div className="headerLabel">{headerLabel}</div>
      <div className="itemList">
        {displayedItems.map((item, i) => {
          const { label, value, color } = item;
          if (!value && value !== 0) {
            return <></>;
          }
          return (
            <div className="itemRow" key={i}>
              <div className={shape} style={{ backgroundColor: color }} />
              <div className="name">{`${label}: `}</div>
              <div className="value">{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChartTooltip;
