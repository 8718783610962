import React, { useContext } from "react";
import { MdDeleteForever } from "react-icons/md";
import { Button, ButtonType, OnBlurInput } from "../Components";
import {
  HandleNewRowInput,
  CombinedCrossChannelKpis,
  HandleCrossChannelKpiNameEdit,
} from "./EditKpiMapping";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { KpiMappingsContext } from "./KpiMapping";

interface CrossChannelKpiColumnProps {
  crossChannelKpis: CombinedCrossChannelKpis;
  crossChannelKpiNameEdits: Record<string, string>;
  handleCrossChannelKpiNameEdit: (input: HandleCrossChannelKpiNameEdit) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
  deleteNewRow: (id: string) => void;
}

const CrossChannelKpiColumn: React.FC<CrossChannelKpiColumnProps> = React.memo(
  ({
    crossChannelKpis,
    crossChannelKpiNameEdits,
    handleCrossChannelKpiNameEdit,
    handleNewRow,
    deleteNewRow,
  }) => {
    const { editMode } = useContext(KpiMappingsContext);
    return (
      <div className="crossChannelKpiColumn">
        <div className="columnHeader">Cross Channel KPI</div>
        <div>
          {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
            return (
              <div className="crossChannelKpiItem" key={crossChannelKpiId}>
                {isNewRow && (
                  <Button
                    className="deleteNewKpiButton"
                    type={ButtonType.EMPTY}
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdDeleteForever style={{ color: "red" }} />}
                    size="sm"
                    onClick={() => deleteNewRow(crossChannelKpiId as string)}
                  />
                )}
                <OnBlurInput
                  disabled={!editMode}
                  value={crossChannelKpiNameEdits[crossChannelKpiId] || crossChannelKpiLabel}
                  onChange={newVal =>
                    isNewRow
                      ? handleNewRow({
                          crossChannelKpiId: (crossChannelKpiId as unknown) as string,
                          kpiLabel: newVal,
                          sourceLabel: "",
                          isCrossChannelKpiLabel: true,
                        })
                      : handleCrossChannelKpiNameEdit({
                          id: crossChannelKpiId as number,
                          name: newVal,
                        })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default CrossChannelKpiColumn;
