import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { Button } from "react-bootstrap";
import { MdSave, MdCached, MdWarning } from "react-icons/md";
import Papa from "papaparse";
import { download } from "../utils/download-utils";

import {
  Img,
  Spinner,
  OldFilterBar,
  FullPageSpinner,
  BPMTable,
  NumberFormatter,
} from "../Components";
import { useSetError } from "../redux/modals";
import {
  PacingPane,
  PacingBar,
  makeCompanyLabel,
  PacingBarTypes,
} from "../StreamingPacing/StreamingPacing";

import { AdminLambdaFetch, LinearLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { formatPacingNumber } from "../utils/format-utils";

import {
  DATE_FORMAT,
  GROSS_LABEL,
  ACCEPTED_LABEL,
  COMPANY_DIMENSION,
  NETWORK_DIMENSION,
} from "./LinearPacingUtils";

import "../StreamingPacing/StreamingPacing.scss";
import "./LinearPacing.scss";

const makeNetworkLabel = ({ network }) => {
  return (
    <div className="pacingBarLabel pacingNetworkBarLabel">
      <Img
        className="logo network"
        src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
        title={network}
        unloader={<span className="logo network">{network}</span>}
      />
    </div>
  );
};

const CSV_HEADER = [
  "Date",
  "Company",
  "Network",
  "Avail",
  "Booked",
  "Logs",
  "Estimated",
  "Has BVS",
  "Has Prelogs",
  "Last Prelog Day",
];

const LinearPacingDetail = ({
  spendDimension,
  acceptedDimension,
  mediaTypeDimension,
  leftKey,
  start,
}) => {
  const [data, setData] = useState();
  const [bottomData, setBottomData] = useState();
  const [filteredBottomData, setFilteredBottomData] = useState();
  const [filters, setFilters] = useState({
    left: () => true,
    right: () => true,
  });
  const rightKey = useMemo(
    () => (leftKey === COMPANY_DIMENSION ? NETWORK_DIMENSION : COMPANY_DIMENSION),
    [leftKey]
  );
  const [selectedLeft, setSelectedLeft] = useState();
  const [selectedRight, setSelectedRight] = useState();

  const [leftFilterTokens, setLeftFilterTokens] = useState({});
  const [leftFilterAdvanced, setLeftFilterAdvanced] = useState(false);
  const leftFilterBarOptions = useMemo(() => [leftKey], [leftKey]);
  const [rightFilterTokens, setRightFilterTokens] = useState({});
  const [rightFilterAdvanced, setRightFilterAdvanced] = useState(false);
  const rightFilterBarOptions = useMemo(() => [rightKey], [rightKey]);

  useEffect(() => {
    setSelectedLeft();
    setSelectedRight();
  }, [leftKey]);

  const setError = useSetError();
  useEffect(() => {
    (async () => {
      try {
        if (start) {
          setData(null);
          const end = R.pipe(
            Dfns.parse(new Date(), DATE_FORMAT),
            Dfns.addDays(7),
            Dfns.format(DATE_FORMAT)
          )(start);

          let response = await AdminLambdaFetch(
            `/linear/pacing?start=${start}&end=${end}&grouping=${leftKey}&secured=${mediaTypeDimension.toLowerCase()}`
          );
          response = await awaitJSON(response);
          setData(response);
        }
      } catch (e) {
        setError({ message: "Could not fetch data for pacing detail page", reportError: e });
      }
    })();
  }, [start, leftKey, mediaTypeDimension, setError]);

  const { leftMax, leftData, leftCsv } = useMemo(() => {
    if (!data) {
      return {};
    }

    let leftMax = 0;
    let leftData = R.pipe(
      R.filter(filters.left),
      R.map(item => {
        const { booked, estimated, estimatedAll } = item;
        leftMax = Math.max(
          leftMax,
          booked.spend,
          acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend
        );
        item.key = item[leftKey];
        return item;
      }),
      R.filter(item => !Array.isArray(item.key)),
      // Doing "- Math.abs" is not a typo. Instead of doing an R.desc or R.reverse, this achieves the same
      // result
      R.sortBy(
        ({ booked, estimated, estimatedAll }) =>
          -Math.abs(
            booked.spend -
              (acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend)
          )
      )
    )(data);

    let csvRows = R.pipe(
      R.prop("company"),
      R.values,
      R.pluck("network"),
      R.unnest,
      R.map(flight => {
        const logsKey = acceptedDimension === ACCEPTED_LABEL ? "logs" : "logsAll";
        const estimatedKey = acceptedDimension === ACCEPTED_LABEL ? "estimated" : "estimatedAll";
        let daysForFlight = R.sortBy(R.identity)(
          R.uniq(
            R.flatten([
              R.keys(flight.booked.days),
              R.keys(flight[logsKey].days),
              R.keys(flight[estimatedKey].days),
            ])
          )
        );
        return R.map(
          day => [
            day,
            flight.company,
            flight.network,
            R.path(["booked", "days", day, "spend"], flight),
            R.path([logsKey, "days", day, "spend"], flight),
            R.path([estimatedKey, "days", day, "spend"], flight),
            flight.hasBvs,
            flight.hasPrelogs,
            flight.lastPrelogDay,
          ],
          daysForFlight
        );
      }),
      R.unnest
    )(data);
    csvRows.unshift(CSV_HEADER);
    const leftCsv = Papa.unparse(csvRows);
    return { leftMax, leftData, leftCsv };
  }, [data, filters.left, leftKey, acceptedDimension]);

  const { rightMax, rightData, rightDataFiltered, rightCsv } = useMemo(() => {
    if (!data || !selectedLeft || !R.find(row => row[leftKey] === selectedLeft, data)) {
      return {};
    }

    let csvRows = R.pipe(
      R.find(row => row[leftKey] === selectedLeft),
      R.prop("network"),
      R.filter(filters.right),
      R.map(flight => {
        const logsKey = acceptedDimension === ACCEPTED_LABEL ? "logs" : "logsAll";
        const estimatedKey = acceptedDimension === ACCEPTED_LABEL ? "estimated" : "estimatedAll";
        let daysForFlight = R.sortBy(R.identity)(
          R.uniq(
            R.flatten([
              R.keys(R.path(["booked", "days"], flight)),
              R.keys(R.path([logsKey, "days"], flight)),
              R.keys(R.path([estimatedKey, "days"], flight)),
            ])
          )
        );
        return R.map(
          day => [
            day,
            flight.company,
            flight.network,
            R.path(["booked", "days", day, "spend"], flight),
            R.path([logsKey, "days", day, "spend"], flight),
            R.path([estimatedKey, "days", day, "spend"], flight),
            flight.hasBvs,
            flight.hasPrelogs,
            flight.lastPrelogDay,
          ],
          daysForFlight
        );
      }),
      R.unnest
    )(data);
    csvRows.unshift(CSV_HEADER);
    const rightCsv = Papa.unparse(csvRows);

    let rightMax = 0;
    let rightData = R.pipe(
      R.find(({ key }) => key === selectedLeft),
      R.prop(rightKey)
    )(data);

    const estimatedKey = acceptedDimension === ACCEPTED_LABEL ? "estimated" : "estimatedAll";
    let rightDataFiltered = R.pipe(
      R.filter(filters.right),
      R.map(row => {
        rightMax = Math.max(rightMax, row.booked.spend, row[estimatedKey].spend);
        row.key = row[rightKey];
        return row;
      }),
      R.sortBy(flight => -Math.abs(flight.booked.spend - flight[estimatedKey].spend))
    )(rightData);

    return { rightMax, rightData, rightDataFiltered, rightCsv };
  }, [data, selectedLeft, filters, leftKey, rightKey, acceptedDimension]);

  const calculateSpend = (spend, commission, spendDimension) => {
    return spendDimension === GROSS_LABEL ? spend : spend * (0.85 + commission);
  };

  const PacingBarOverlay = useCallback(
    ({
      booked,
      estimated,
      estimatedAll,
      logs,
      logsAll,
      unsecuredLogs,
      unsecuredLogsAll,
      securedLogs,
      securedLogsAll,
      tvBudget,
      commission,
      assumedClearanceRate,
    }) => {
      let estimatedSpend = calculateSpend(
        acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
        commission,
        spendDimension
      );
      let logsSpend = calculateSpend(
        acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
        commission,
        spendDimension
      );
      let unsecuredLogsSpend = calculateSpend(
        acceptedDimension === ACCEPTED_LABEL ? unsecuredLogs.spend : unsecuredLogsAll.spend,
        commission,
        spendDimension
      );
      let securedLogsSpend = calculateSpend(
        acceptedDimension === ACCEPTED_LABEL ? securedLogs.spend : securedLogsAll.spend,
        commission,
        spendDimension
      );
      let bookedSpend = calculateSpend(booked.spend, commission, spendDimension);
      let budgetSpend = tvBudget && calculateSpend(tvBudget, commission, spendDimension);

      let prettyForecast = formatPacingNumber(estimatedSpend, "$");
      let prettyLogs = formatPacingNumber(logsSpend, "$");
      let prettyUnsecuredLogs = formatPacingNumber(unsecuredLogsSpend, "$");
      let prettySecuredLogs = formatPacingNumber(securedLogsSpend, "$");
      let prettyBooked = formatPacingNumber(bookedSpend, "$");

      return (
        <div style={{ textAlign: "left" }}>
          {logs.spend === 0 && bookedSpend > 1000 ? (
            <div>
              Reprocessing
              <br />
            </div>
          ) : (
            <div />
          )}
          On the logs: {prettyLogs} / Pacing: {prettyForecast} / Booked: {prettyBooked}
          {securedLogsSpend ? (
            <div>
              <br />
              Secured {prettySecuredLogs} / Unsecured: {prettyUnsecuredLogs}
            </div>
          ) : (
            <div />
          )}
          {tvBudget ? (
            <div>
              <br />
              Client Target: {formatPacingNumber(budgetSpend, "$")} / Booked Target:{" "}
              {formatPacingNumber(bookedSpend * assumedClearanceRate, "$")} / Assumed Clear:{" "}
              {(100 * assumedClearanceRate).toFixed(0)}%
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    },
    [spendDimension, acceptedDimension]
  );

  useEffect(() => {
    if (selectedLeft && selectedRight) {
      (async () => {
        try {
          const company = leftKey === COMPANY_DIMENSION ? selectedLeft : selectedRight;
          const network = leftKey === COMPANY_DIMENSION ? selectedRight : selectedLeft;
          let response = await LinearLambdaFetch(
            `/pacing_logs?week=${start}&company=${company}&network=${network}&secured=${mediaTypeDimension.toLowerCase()}`
          );
          response = await awaitJSON(response);
          setBottomData(response);
          setFilteredBottomData(response);
        } catch (e) {
          setError({
            message: "Could not fetch detailed data for pacing detail page",
            reportError: e,
          });
        }
      })();
    }
  }, [selectedLeft, selectedRight, mediaTypeDimension, start, setError, leftKey]);

  const createLogsHeaderRow = (dow, index) => ({
    label: dow,
    name: "spots[0]",
    renderer: row => <NumberFormatter value={row.spots[index].cost} type={"$"} decimals={0} />,
    width: 70,
  });

  const bottomColHeaders = [
    { label: "Avail", name: "avail", width: 70 },
    { label: "Len", name: "length", width: 70 },
    { label: "Days", name: "dow", flex: 1 },
    {
      label: "Daypart",
      name: "daypart",
      flex: 1,
      renderer: row =>
        row.daypart &&
        R.map(
          time =>
            Dfns.format("h:mma", Dfns.parseISO(`2015-01-01T${time}`))
              .replace("M", "")
              .toLowerCase(),
          row.daypart.split("-")
        ).join("-"),
    },
    {
      label: "Rotation",
      name: "rotation",
      flex: 1,
      renderer: row => row.rotation && row.rotation.match(/(.*) \(.*/)[1],
    },
    {
      label: "Type",
      name: "type",
    },
    {
      label: "#",
      name: "count",
      renderer: row => <NumberFormatter value={row.count} type={"#"} decimals={0} />,
      width: 50,
    },
    {
      label: "Cost",
      name: "cost",
      renderer: row => <NumberFormatter value={row.cost} type={"$"} decimals={0} />,
    },
    {
      label: "Spend",
      name: "spend",
      renderer: row => <NumberFormatter value={row.spend} type={"$"} decimals={0} />,
    },
    createLogsHeaderRow("M", 0),
    createLogsHeaderRow("Tu", 1),
    createLogsHeaderRow("W", 2),
    createLogsHeaderRow("Th", 3),
    createLogsHeaderRow("F", 4),
    createLogsHeaderRow("Sa", 5),
    createLogsHeaderRow("Su", 6),
    {
      label: "Total",
      name: "logSpend",
      renderer: row => (
        <NumberFormatter
          value={R.sum(R.map(spot => spot.cost, row.spots))}
          type={"$"}
          decimals={0}
        />
      ),
    },
    {
      label: "%",
      name: "pct",
      renderer: row => (
        <NumberFormatter
          value={R.sum(R.map(spot => spot.cost, row.spots)) / row.spend}
          type={"%"}
          decimals={0}
        />
      ),
      width: 70,
    },
  ];

  const bottomSuperHeaders = [
    { span: 6, data: "Summary" },
    { span: 3, data: "Ordered" },
    { span: 9, data: "Logs" },
  ];

  const onFilteredBottomDataChange = useCallback(
    data => {
      if (!R.equals(filteredBottomData, data)) {
        setFilteredBottomData(data);
      }
    },
    [filteredBottomData, setFilteredBottomData]
  );

  const bottomTotalsRow = useMemo(() => {
    if (!filteredBottomData) {
      return {};
    }

    const logSpend = R.pipe(
      R.map(row =>
        R.pipe(
          R.map(spot => spot.cost),
          R.sum
        )(row.spots)
      ),
      R.sum
    )(filteredBottomData);
    const bookedSpend = R.pipe(R.pluck("spend"), R.sum)(filteredBottomData);

    return {
      count: `#${R.pipe(R.pluck("count"), R.sum)(filteredBottomData)}`,
      spend: `$${bookedSpend}`,
      M: `$${R.pipe(
        R.map(row => row.spots[0].cost),
        R.sum
      )(filteredBottomData)}`,
      Tu: `$${R.pipe(
        R.map(row => row.spots[1].cost),
        R.sum
      )(filteredBottomData)}`,
      W: `$${R.pipe(
        R.map(row => row.spots[2].cost),
        R.sum
      )(filteredBottomData)}`,
      Th: `$${R.pipe(
        R.map(row => row.spots[3].cost),
        R.sum
      )(filteredBottomData)}`,
      F: `$${R.pipe(
        R.map(row => row.spots[4].cost),
        R.sum
      )(filteredBottomData)}`,
      Sa: `$${R.pipe(
        R.map(row => row.spots[5].cost),
        R.sum
      )(filteredBottomData)}`,
      Su: `$${R.pipe(
        R.map(row => row.spots[6].cost),
        R.sum
      )(filteredBottomData)}`,
      logSpend: `$${logSpend}`,
      pct: `%${logSpend / bookedSpend}`,
    };
  }, [filteredBottomData]);

  const totalsRenderer = ({ data, style = {}, classes = [] }) => {
    let type = "#";
    if (data && data.indexOf("$") >= 0) {
      type = "$";
    } else if (data && data.indexOf("%") >= 0) {
      type = "%";
    }

    try {
      data = parseFloat(data.replace("%", "").replace("$", "").replace("#", ""));
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (Number.isFinite(data)) {
      data = <NumberFormatter value={data} type={type} decimals={0} />;
    }

    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  return leftData ? (
    <div className="pacingDashboard">
      {leftData ? (
        <div className="chartPanes">
          <div className="leftPane">
            <PacingPane
              items={leftData}
              selectedKey={selectedLeft}
              onSelect={key => {
                setSelectedRight();
                setSelectedLeft(key);
                setBottomData();
              }}
              filterRenderer={() => (
                <div style={{ width: "100%", display: "flex" }}>
                  <OldFilterBar
                    options={leftFilterBarOptions}
                    lines={R.pipe(R.project(leftFilterBarOptions), R.uniq)(data)}
                    onFilter={filter => {
                      setFilters({
                        ...filters,
                        left: filter,
                      });
                    }}
                    tokens={leftFilterTokens}
                    onChange={leftFilterTokens => setLeftFilterTokens(leftFilterTokens)}
                    advanced={leftFilterAdvanced}
                    onSetAdvanced={advanced => setLeftFilterAdvanced(advanced)}
                  />
                  {
                    <Button
                      variant="outline-secondary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        download(leftCsv, `Linear Pacing for week of ${start}.csv`, "text/plain");
                      }}
                    >
                      <MdSave />
                    </Button>
                  }
                </div>
              )}
              overlayRenderer={PacingBarOverlay}
              labelRenderer={leftKey === COMPANY_DIMENSION ? makeCompanyLabel : makeNetworkLabel}
              lineItemRenderer={({
                key,
                booked,
                logs,
                logsAll,
                unsecuredLogs,
                unsecuredLogsAll,
                securedLogs,
                securedLogsAll,
                estimated,
                estimatedAll,
                tvBudget,
                hasPrelogs,
                commission,
                assumedClearanceRate,
              }) => {
                let estimatedSpend = calculateSpend(
                  acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
                  commission,
                  spendDimension
                );
                let logsSpend = calculateSpend(
                  acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
                  commission,
                  spendDimension
                );
                let unsecuredLogsSpend = calculateSpend(
                  acceptedDimension === ACCEPTED_LABEL
                    ? unsecuredLogs.spend
                    : unsecuredLogsAll.spend,
                  commission,
                  spendDimension
                );
                let securedLogsSpend = calculateSpend(
                  acceptedDimension === ACCEPTED_LABEL ? securedLogs.spend : securedLogsAll.spend,
                  commission,
                  spendDimension
                );
                let bookedSpend = calculateSpend(booked.spend, commission, spendDimension);
                let budgetSpend = tvBudget && calculateSpend(tvBudget, commission, spendDimension);

                let parts =
                  logsSpend > 0
                    ? R.filter(R.identity, [
                        // type, label, color, val, leftVal = 0
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          className: "pacing",
                          val: estimatedSpend,
                          leftVal: 0,
                        },
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: formatPacingNumber(unsecuredLogsSpend, "$"),
                          className: "deliveryUnsecured",
                          val: unsecuredLogsSpend + securedLogsSpend,
                          leftVal: securedLogsSpend,
                        },
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: formatPacingNumber(securedLogsSpend, "$"),
                          className: "deliverySecured",
                          val: securedLogsSpend,
                        },
                        {
                          type: PacingBarTypes.VERTICAL,
                          className: "bookedTarget",
                          val: bookedSpend * assumedClearanceRate,
                        },
                        budgetSpend && {
                          type: PacingBarTypes.VERTICAL,
                          className: "clientTarget",
                          val: budgetSpend,
                        },
                        {
                          type: PacingBarTypes.LABEL,
                          label: `${Math.round(
                            (estimatedSpend / bookedSpend) * 100
                          )}% / ${formatPacingNumber(estimatedSpend, "$")}`,
                          leftVal: estimatedSpend,
                        },
                      ])
                    : [
                        {
                          type: PacingBarTypes.HORIZONTAL,
                          label: (
                            <div>
                              Reprocessing&nbsp;
                              <MdCached />
                            </div>
                          ),
                          className: "pacing",
                          val: leftMax,
                        },
                      ];

                if (R.isNil(commission) && !hasPrelogs) {
                  parts.push({
                    type: PacingBarTypes.ALERT,
                    className: "warningIcon",
                    label: <MdWarning />,
                  });
                }

                return <PacingBar key={key} max={leftMax} parts={parts} />;
              }}
            />
          </div>
          {selectedLeft &&
            (!rightData ? (
              <div className="rightPane">
                <div className="topPane">
                  <Spinner size={100} />
                </div>
              </div>
            ) : (
              <div className="rightPane">
                <div className="topPane">
                  <PacingPane
                    items={rightDataFiltered}
                    selectedKey={selectedRight}
                    onSelect={subKey => {
                      setSelectedRight(subKey);
                      setBottomData();
                    }}
                    filterRenderer={() => (
                      <div style={{ width: "100%", display: "flex" }}>
                        <OldFilterBar
                          options={rightFilterBarOptions}
                          lines={R.pipe(R.project(rightFilterBarOptions), R.uniq)(rightData)}
                          onFilter={filter => {
                            setFilters({
                              ...filters,
                              right: filter,
                            });
                          }}
                          tokens={rightFilterTokens}
                          onChange={rightFilterTokens => setRightFilterTokens(rightFilterTokens)}
                          advanced={rightFilterAdvanced}
                          onSetAdvanced={advanced => setRightFilterAdvanced(advanced)}
                        />
                        <Button
                          variant="outline-secondary"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            download(
                              rightCsv,
                              `Linear Pacing for ${selectedLeft} week of ${start}.csv`,
                              "text/plain"
                            );
                          }}
                        >
                          <MdSave />
                        </Button>
                      </div>
                    )}
                    labelRenderer={
                      leftKey === COMPANY_DIMENSION ? makeNetworkLabel : makeCompanyLabel
                    }
                    overlayRenderer={PacingBarOverlay}
                    lineItemRenderer={({
                      key,
                      booked,
                      estimated,
                      estimatedAll,
                      logs,
                      logsAll,
                      unsecuredLogs,
                      unsecuredLogsAll,
                      securedLogs,
                      securedLogsAll,
                      commission,
                      hasPrelogs,
                    }) => {
                      const estimatedSpend = calculateSpend(
                        acceptedDimension === ACCEPTED_LABEL ? estimated.spend : estimatedAll.spend,
                        commission,
                        spendDimension
                      );
                      const logsSpend = calculateSpend(
                        acceptedDimension === ACCEPTED_LABEL ? logs.spend : logsAll.spend,
                        commission,
                        spendDimension
                      );
                      let unsecuredLogsSpend = calculateSpend(
                        acceptedDimension === ACCEPTED_LABEL
                          ? unsecuredLogs.spend
                          : unsecuredLogsAll.spend,
                        commission,
                        spendDimension
                      );
                      let securedLogsSpend = calculateSpend(
                        acceptedDimension === ACCEPTED_LABEL
                          ? securedLogs.spend
                          : securedLogsAll.spend,
                        commission,
                        spendDimension
                      );
                      const bookedSpend = calculateSpend(booked.spend, commission, spendDimension);

                      let parts =
                        logsSpend > 0
                          ? [
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                className: "pacing",
                                val: estimatedSpend,
                                leftVal: logsSpend,
                              },
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: formatPacingNumber(unsecuredLogsSpend, "$"),
                                className: "deliveryUnsecured",
                                val: unsecuredLogsSpend + securedLogsSpend,
                                leftVal: securedLogsSpend,
                              },
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: formatPacingNumber(securedLogsSpend, "$"),
                                className: "deliverySecured",
                                val: securedLogsSpend,
                              },
                              {
                                type: PacingBarTypes.VERTICAL,
                                className: "booked",
                                val: bookedSpend,
                              },
                              {
                                type: PacingBarTypes.LABEL,
                                label: `${Math.round(
                                  (estimatedSpend / bookedSpend) * 100
                                )}% / ${formatPacingNumber(estimatedSpend, "$")}`,
                                leftVal: estimatedSpend,
                              },
                            ]
                          : [
                              {
                                type: PacingBarTypes.HORIZONTAL,
                                label: (
                                  <div>
                                    Reprocessing&nbsp;
                                    <MdCached />
                                  </div>
                                ),
                                className: "pacing",
                                val: rightMax,
                              },
                            ];

                      if (!hasPrelogs) {
                        parts.push({
                          type: PacingBarTypes.ALERT,
                          className: "warningIcon",
                          label: <MdWarning />,
                        });
                      }

                      return <PacingBar key={key} max={rightMax} parts={parts} />;
                    }}
                  />
                </div>
                {selectedRight &&
                  (bottomData ? (
                    <div className="bottomPane">
                      <BPMTable
                        alternateColors
                        headers={bottomColHeaders}
                        superHeaders={bottomSuperHeaders}
                        data={bottomData}
                        totals={bottomTotalsRow}
                        totalsRenderer={totalsRenderer}
                        onFilteredDataChange={onFilteredBottomDataChange}
                      />
                    </div>
                  ) : (
                    <div className="bottomPane loadingPane">
                      <Spinner size={100} />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </div>
  ) : (
    <Spinner size={100} />
  );
};

export default LinearPacingDetail;
