import React, { useState, useEffect } from "react";
import { MdError } from "react-icons/md";
import * as R from "ramda";
import "./WtoRulesFormModal.scss";

interface WtoValidationErrorsProps {
  validationErrors: string[];
}

export const WtoValidationErrors: React.FC<WtoValidationErrorsProps> = ({ validationErrors }) => {
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    const formErrorsRulesEl: any = document.getElementById("form-errors");
    if (scrollTop) {
      formErrorsRulesEl.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      setScrollTop(false);
    }
  }, [scrollTop]);

  useEffect(() => {
    if (!R.isEmpty(validationErrors)) {
      setScrollTop(true);
    }
  }, [validationErrors]);

  if (R.isEmpty(validationErrors)) {
    return null;
  }
  return (
    <div id="form-errors" className="infoBox validations">
      <div className="icon">
        <MdError />
      </div>
      <div className="message">
        <span>Errors:</span>
        <ul>
          {validationErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WtoValidationErrors;
