import "./BPMButton.scss";
import { BPMButtonProps } from "../BPMButtonUtils";
import { Button } from "react-bootstrap";
import React, { useMemo } from "react";

export const BPMButton: React.FC<BPMButtonProps> = React.memo(
  ({ className, bordered, variant = "primary", size, icon, children, ...passthrough }) => {
    let resolvedClassName = useMemo(() => {
      let classes = ["BPMButton"];
      if (bordered) {
        classes.push("bordered");
      }
      if (size === "xs") {
        classes.push("xs");
      }
      if (className) {
        classes.push(className);
      }
      if (icon) {
        classes.push("hasIcon");
      }
      return classes.join(" ");
    }, [className, bordered, size, icon]);
    return (
      <Button
        className={resolvedClassName}
        variant={variant}
        size={size === "xs" ? "sm" : size}
        {...passthrough}
      >
        {icon ? (
          <>
            <div className="icon">{icon}</div>
            <div className="label">{children}</div>
          </>
        ) : (
          children
        )}
      </Button>
    );
  }
);
