// Experiment for Streaming Network page updates

const streamingNetworksUsers: string[] = [
  // "berto.garciacarrillo@tinuiti.com",
  // "carling.sugarman@tinuiti.com",
];

export const shouldEnableStreamingNetworksUpdates = (userEmail: string): boolean => {
  return streamingNetworksUsers.includes(userEmail);
};
