import "./DataCard.scss";
import React from "react";
import { abbreviateNumberAMC } from "../../AMCFormatters";
import DataCard from "../../../Components/DataCard/DataCard";

interface CostCardDataTypes {
  cpaAll: number;
  cpaNtb: number;
  ntbSales: number;
  spend: number;
}

interface RevenueCardDataTypes {
  cpaAll: number;
  cpaNtb: number;
  revenue: number;
  roas: number;
}

interface DataCardRowTypes {
  costCardData: CostCardDataTypes;
  revenueCardData: RevenueCardDataTypes;
}

const DataCardRow: React.FC<DataCardRowTypes> = ({ costCardData, revenueCardData }) => {
  const buildCardRow = (data, item) => {
    const formattedItem = item
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/ntb/i, "NTB")
      .replace(/roas/i, "ROAS")
      .replace(/cpa/i, "CPA");

    let formattedValue;

    switch (item) {
      case "__typename":
        break;
      case "spend":
      case "cpaNtb":
      case "cpaAll":
      case "ntbSales":
        formattedValue = `$${abbreviateNumberAMC(data[item])}`;
        break;
      default:
        formattedValue = `${abbreviateNumberAMC(data[item])}`;
    }

    return item !== "__typename" ? (
      <DataCard key={formattedItem} label={formattedItem} value={formattedValue} width={"100%"} />
    ) : null;
  };

  return (
    <React.Fragment>
      <div className="data-card-row">
        <div className="data-card-row-section">
          {costCardData &&
            Object.keys(costCardData).map(item => {
              return buildCardRow(costCardData, item);
            })}
        </div>
        <div className="data-card-row-section">
          {revenueCardData &&
            Object.keys(revenueCardData).map(item => {
              return buildCardRow(revenueCardData, item);
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataCardRow;
