import "./TagRow.scss";
import { Button, ListGroup } from "react-bootstrap";
import * as R from "ramda";
import React from "react";
import { AD_SERVER_MAP } from "../../StreamingCreatives/StreamingCreatives";

const TagRow = ({ tags, onAdd, suppressTag }) => {
  let firstTag = tags[0];
  let { derivedID, network, description, platform, dsp, hasOverride, hasPlacement } = firstTag;

  let suppressedComponent;
  if (hasOverride) {
    suppressedComponent = <span className="suppressed">Suppressed</span>;
  } else {
    suppressedComponent = (
      <Button size="sm" variant="link" onClick={() => suppressTag(derivedID)}>
        Suppress
      </Button>
    );
  }
  const tagMap = {};
  for (const tag of tags) {
    tagMap[tag.placementName] = tag;
  }
  return (
    <ListGroup.Item className="placementRow">
      <div>
        <div className="metadata">
          {`${derivedID} | ${network} |${description ? ` ${description} |` : ""} ${platform}${
            dsp ? ` | DSP: ${dsp}` : ""
          }`}
          {suppressedComponent}
        </div>
        <Button onClick={() => onAdd(firstTag)}>Create Tag</Button>
      </div>
      {hasPlacement &&
        R.map(
          tag => (
            <div key={tag.placementName} className="existingTag">
              Ad Server:{" "}
              {AD_SERVER_MAP[tag.adServer]
                ? AD_SERVER_MAP[tag.adServer]
                : tag.adServer
                ? `${tag.adServer.slice(0, 1).toUpperCase()}${tag.adServer.slice(1)}`
                : tag.adServer}
              , Placement Name: {tag.placementName}, Campaign: {tag.campaignName}
            </div>
          ),
          R.values(tagMap)
        )}
    </ListGroup.Item>
  );
};

export default TagRow;
