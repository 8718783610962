import React, { useContext, useMemo } from "react";

import * as R from "ramda";

import { useSelector } from "react-redux";

import * as UserRedux from "../../redux/user";

interface Location {
  pathname: string;
}

export const LocationContext = React.createContext<Location>({ pathname: "" });

const useLocation = (): { location: Location; company: string } => {
  const location = useContext(LocationContext);

  const companies = useSelector(UserRedux.companiesSelector) as { company: string }[];

  const company = useMemo(() => {
    if (!(location && companies)) {
      return "";
    }
    const { pathname } = location;
    let companyMatch = pathname.match(/\/([^/]*)/) || [];
    if (R.length(companyMatch) >= 2) {
      let foundCompany = R.find(elem => elem.company === companyMatch[1], companies) || {
        company: "",
      };
      return foundCompany.company;
    }
    return "";
  }, [location, companies]);

  return { location, company };
};

export default useLocation;
