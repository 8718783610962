import "./OfflineInputsDialogs.scss";
import "../../Components/FormCheck.scss";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { MdCalculate, MdCheck, MdCalendarToday, MdDelete, MdSmsFailed } from "react-icons/md";
import AlertDialog, { AlertDialogVariants } from "../../Components/AlertDialog/AlertDialog";
import { EntryEditsMap } from "./OfflineInputsUtils";
import {
  OfflineInputsEntry,
  OfflineInputsTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";

enum ValidationErrorOptions {
  "reject",
  "resolve",
}

interface BaseDialogProps {
  show: boolean;
  onHide: () => void;
}

interface DiscardConfirmationDialogProps extends BaseDialogProps {
  onDiscard: () => void;
}

interface DeleteConfirmationDialogProps extends BaseDialogProps {
  onDelete: () => void;
}

interface LeaveConfirmationDialogProps extends BaseDialogProps {
  onLeave: () => void;
  onStay: () => void;
}

interface ValidationErrorDialogProps {
  errorDates: string[];
  onResolve: () => void;
  onReject: () => void;
  tableEdits: EntryEditsMap;
  table?: OfflineInputsTable;
  getInputDescription: (table: OfflineInputsTable) => string;
  createEmptyEntry: (date: string) => OfflineInputsEntry;
  // Modify the entry in-place
  normalizeEntry: (table: OfflineInputsTable, entry: OfflineInputsEntry) => void;
}

export const InstructionsDialog: React.FC<BaseDialogProps> = ({ show, onHide }) => {
  return (
    <AlertDialog
      show={show}
      onHide={onHide}
      icon={<MdCalculate size={95} />}
      title="Please Split Your Data Out By Day"
      body="Please disaggregate your data by day and come back to enter it in."
      primaryButtonLabel="Got it!"
    />
  );
};

export const CommitSuccessDialog: React.FC<BaseDialogProps> = ({ show, onHide }) => {
  return (
    <AlertDialog
      variant={AlertDialogVariants.SUCCESS}
      show={show}
      onHide={onHide}
      icon={<MdCheck size={95} />}
      title="Success!"
      body="Your inputs have been committed and will be available in the next refresh of your MMM."
    />
  );
};

export const LeaveConfirmationDialog: React.FC<LeaveConfirmationDialogProps> = ({
  show,
  onLeave,
  onStay,
  onHide,
}) => {
  return (
    <AlertDialog
      variant={AlertDialogVariants.ERROR}
      show={show}
      onHide={onHide}
      icon={<MdSmsFailed size={72} />}
      title="Are you sure you want to leave?"
      body="If you leave now, your changes will not be saved."
      primaryAction={() => {
        onStay();
        onHide();
      }}
      secondaryAction={() => {
        onLeave();
        onHide();
      }}
      primaryButtonLabel="Stay"
      secondaryButtonLabel="Leave"
    />
  );
};

export const DiscardConfirmationDialog: React.FC<DiscardConfirmationDialogProps> = ({
  show,
  onDiscard,
  onHide,
}) => {
  return (
    <AlertDialog
      variant={AlertDialogVariants.WARNING}
      show={show}
      onHide={onHide}
      icon={<MdDelete size={95} />}
      title="Discard Changes?"
      body="Any edits you've made won't be saved and you'll have to start over."
      secondaryAction={onHide}
      primaryAction={() => {
        onDiscard();
        onHide();
      }}
      primaryButtonLabel="Discard"
    />
  );
};

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  show,
  onDelete,
  onHide,
}) => {
  return (
    <AlertDialog
      variant={AlertDialogVariants.WARNING}
      show={show}
      onHide={onHide}
      icon={<MdDelete size={95} />}
      title="Delete Data?"
      body="All data in these columns will be deleted and you'll have to enter your data in again if you want it back."
      primaryAction={() => {
        onDelete();
        onHide();
      }}
      primaryButtonLabel="Delete"
      secondaryAction={onHide}
    />
  );
};

export const ValidationErrorDialog: React.FC<ValidationErrorDialogProps> = ({
  errorDates,
  onResolve,
  onReject,
  tableEdits,
  table,
  getInputDescription,
  createEmptyEntry,
  normalizeEntry,
}) => {
  const [selectedOption, setSelectedOption] = useState<ValidationErrorOptions>(
    ValidationErrorOptions.reject
  );

  if (!table) {
    return null;
  }

  const startDate: string = errorDates[0].split("T")[0];
  const endDate: string = errorDates[errorDates.length - 1].split("T")[0];
  const datesLabel =
    errorDates.length > 1 ? `Dates: ${startDate} to ${endDate}` : `Date: ${startDate}`;
  const resolveLabel =
    errorDates.length > 1 ? "We didn't run on those dates" : "We didn't run on that date";

  return (
    <AlertDialog
      show={true}
      escapable={false}
      onHide={() => {}}
      icon={<MdCalendarToday size={95} />}
      title="We Need More Info About These Blank Dates"
      body={
        <>
          <div className="errorDates">{datesLabel}</div>
          <div className="errorInput">For Input: {getInputDescription(table)}</div>{" "}
          <div className="validationErrorOptions">
            <Form.Check
              type="radio"
              label={resolveLabel}
              checked={selectedOption === ValidationErrorOptions.resolve}
              onChange={() => setSelectedOption(ValidationErrorOptions.resolve)}
            />
            <Form.Check
              type="radio"
              label={"We aggregated data and need to split out by day"}
              checked={selectedOption === ValidationErrorOptions.reject}
              onChange={() => setSelectedOption(ValidationErrorOptions.reject)}
            />
          </div>
        </>
      }
      primaryAction={() => {
        if (selectedOption === ValidationErrorOptions.resolve) {
          errorDates.forEach(date => {
            let entry = tableEdits[date];
            if (!entry) {
              tableEdits[date] = createEmptyEntry(date);
              entry = tableEdits[date];
            }

            normalizeEntry(table, entry);
          });
          onResolve();
        } else {
          onReject();
        }
      }}
    />
  );
};
