import { CreativeInfoItem } from "@blisspointmedia/bpm-types/dist/Creative";
import { BsPlayBtn } from "react-icons/bs";
import CreativeMediaPlayer from "./CreativeMediaPlayer";
import { toPrettySpend } from "../StreamingBuying/OrderView";

interface CreativeViewProps {
  cid: string;
  selectedCreative: CreativeInfoItem | undefined;
}

const CreativeView = ({ cid, selectedCreative }: CreativeViewProps): JSX.Element => {
  if (cid && selectedCreative && Object.keys(selectedCreative).length > 0) {
    return (
      <div className="creativesView">
        <div className="creativeMedia">
          <CreativeMediaPlayer
            cid={cid}
            selectedCreative={selectedCreative}
            key={selectedCreative.file}
          />
        </div>
        <div className="creativeDescription">
          <div className="title">
            <b>Title: </b>
            {selectedCreative.name || "No title available"}
          </div>
          <div className="length">
            <b>Length: </b>
            {selectedCreative.length || "No length available"}
          </div>
          <div className="isci">
            <b>ISCI(s): </b>
            {selectedCreative.isciList.join(", ")}
          </div>
          <div className="dateRanges">
            <b>Start-End Date(s): </b>
            {selectedCreative.mostRecentDateRanges.join(", ") || "No date(s) available"}
          </div>
          <div className="spend">
            <b>Spend: </b>
            {toPrettySpend(Math.round(selectedCreative.spend)).slice(0, -3)}
          </div>
          <div className="spots">
            <b>Spots: </b>
            {selectedCreative.numSpots.toLocaleString("en-US")}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="creativesView">
        <div className="creativesViewPlaceholder">
          <div>
            <BsPlayBtn size={100} color={"lightgray"} />
          </div>
          <div>Select a video thumbnail below to watch a video and see video info.</div>
        </div>
      </div>
    );
  }
};

export default CreativeView;
