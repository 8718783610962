import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MdOutlineInsertLink } from "react-icons/md";
import { Button, ButtonType } from "../Button/Button";
import "./QuestionCard.scss";
import { Response, Selection } from "@blisspointmedia/bpm-types/dist/Onboarding";
import OnboardingUploader from "../../Onboarding/OnboardingUploader";

interface NestedOptionConfig {
  inputType: "textarea" | "checkbox";
  options?: string[];
  label?: string;
  placeholder?: string;
}

export interface QuestionCardProps {
  id: string;
  questionNumber: string;
  title: string;
  subtitle?: string;
  url?: string;
  children?: React.ReactNode;
  type?: "radio" | "checkbox" | "textarea" | "upload" | "custom";
  textLabel?: string;
  options?: string[];
  onChange?: (updatedResponse: Response) => void;
  initialValue?: Response;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig[] };
}

interface NestedValues {
  [key: string]: string;
}

const QuestionCard = ({
  id,
  questionNumber,
  title,
  subtitle,
  children,
  type,
  textLabel,
  options,
  initialValue,
  readOnly = false,
  className,
  url = window.location.href.split("#")[0],
  placeholder = "What else should we know?",
  nestedOptionsConfig = {},
  onChange,
}: QuestionCardProps): JSX.Element => {
  const [response, setResponse] = useState<Response>(
    initialValue || { questionNumber, selections: [], additionalInfo: "" }
  );
  const [nestedValues, setNestedValues] = useState<NestedValues | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const questionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialValue) {
      setResponse(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!nestedValues) {
      const newNestedValues = {};
      response.selections.forEach(selection => {
        if (selection.children) {
          selection.children.forEach(child => {
            newNestedValues[child.label] = child.openText;
          });
        }
      });
      setNestedValues(newNestedValues);
    }
  }, [nestedValues, response.selections]);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === `#${questionNumber}`) {
        setFocused(true);
        questionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        setFocused(false);
      }
    };

    handleHashChange();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [questionNumber]);

  const changeArea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const updatedResponse = { ...response, additionalInfo: e.target.value };
      setResponse(updatedResponse);
      if (onChange) {
        onChange(updatedResponse);
      }
    },
    [response, onChange]
  );

  const handleRadioChange = useCallback(
    (label: string) => {
      const newSelection: Selection = { label };
      const updatedResponse = { ...response, selections: [newSelection] };
      setResponse(updatedResponse);
      if (onChange) {
        onChange(updatedResponse);
      }
    },
    [response, onChange]
  );

  const handleCheckboxChange = useCallback(
    (label: string) => {
      const isSelected = response.selections.some(selection => selection.label === label);
      const newSelections = isSelected
        ? response.selections.filter(selection => selection.label !== label)
        : [...response.selections, { label }];

      const updatedResponse = { ...response, selections: newSelections };
      setResponse(updatedResponse);
      if (onChange) {
        onChange(updatedResponse);
      }
    },
    [response, onChange]
  );

  const handleNestedChange = useCallback(
    (parentLabel: string, nestedLabel: string, value: string, isCheckbox?: boolean) => {
      const updatedSelections = response.selections.map(selection => {
        if (selection.label === parentLabel) {
          let updatedChildren: Selection[];

          if (isCheckbox) {
            const childExists = selection.children?.some(child => child.label === nestedLabel);
            if (childExists) {
              updatedChildren = selection.children!.filter(child => child.label !== nestedLabel);
            } else {
              updatedChildren = [...(selection.children || []), { label: nestedLabel }];
            }
          } else {
            const childObject = selection.children?.find(child => child.label === nestedLabel);
            if (childObject) {
              updatedChildren = selection.children!.map(child => {
                if (child.label === nestedLabel) {
                  return { ...child, openText: value };
                }
                return child;
              });
            } else {
              updatedChildren = selection.children
                ? [...selection.children, { label: nestedLabel, openText: value }]
                : [{ label: nestedLabel, openText: value }];
            }
          }

          return { ...selection, children: updatedChildren };
        }
        return selection;
      });

      const updatedResponse = { ...response, selections: updatedSelections };
      setResponse(updatedResponse);

      if (onChange) {
        onChange(updatedResponse);
      }
    },
    [response, onChange]
  );

  const handleUploadChange = useCallback(
    (updatedResponse: Response) => {
      setResponse(updatedResponse);
      if (onChange) {
        onChange(updatedResponse);
      }
    },
    [onChange]
  );

  const renderNestedInput = useCallback(
    (option: string, isParentSelected: boolean) => {
      if (nestedOptionsConfig[option]) {
        const config = nestedOptionsConfig[option];
        const defaultPlaceholder = "Enter text here...";

        return (
          <>
            {config.map((nestedConfig, index) => {
              if (nestedConfig.inputType === "textarea") {
                const { label } = nestedConfig as NestedOptionConfig;
                return (
                  <Form.Group key={index}>
                    <Form.Label htmlFor={`${questionNumber}-${label?.replace(/\s/g, "")}-textarea`}>
                      {label}
                    </Form.Label>
                    <Form.Control
                      id={`${questionNumber}-${label?.replace(/\s/g, "")}-textarea`}
                      as="textarea"
                      value={nestedValues ? nestedValues[label ?? option] : ""}
                      onChange={e => {
                        const { value } = e.target;
                        setNestedValues(prevValues => ({
                          ...prevValues,
                          [label ?? option]: value,
                        }));
                        handleNestedChange(option, nestedConfig.label ?? option, value);
                      }}
                      placeholder={nestedConfig.placeholder || defaultPlaceholder}
                      disabled={readOnly || !isParentSelected}
                    />
                  </Form.Group>
                );
              }
              if (nestedConfig.inputType === "checkbox") {
                return (
                  <Form.Group key={index}>
                    {nestedConfig.options?.map((opt, index) => {
                      const isSelected = response.selections.some(
                        selection =>
                          selection.label === option &&
                          selection.children?.some(child => child.label === opt)
                      );
                      return (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          name={`${questionNumber}-${opt}-${index}`}
                          id={`${questionNumber}-${opt}-${index}-checkbox`}
                          label={opt}
                          checked={isSelected}
                          onChange={() => handleNestedChange(option, opt, "", true)}
                          disabled={readOnly || !isParentSelected}
                        />
                      );
                    })}
                  </Form.Group>
                );
              }
              return null;
            })}
          </>
        );
      }
      return null;
    },
    [
      nestedOptionsConfig,
      handleNestedChange,
      nestedValues,
      response.selections,
      readOnly,
      questionNumber,
    ]
  );

  const renderOptions = (
    options: string[],
    questionNumber: string,
    type: "radio" | "checkbox",
    handleChange: (val: string) => void
  ) => {
    return options.map((option, index) => {
      const isSelected =
        type === "checkbox"
          ? response.selections.some(selection => selection.label === option)
          : response.selections.length > 0 && response.selections[0].label === option;

      return (
        <div key={index}>
          <Form.Check
            type={type}
            name={questionNumber}
            id={`${questionNumber}-${option}-${type}`}
            label={option}
            checked={isSelected}
            onChange={() => {
              if (type === "checkbox") {
                handleCheckboxChange(option);
              } else {
                handleRadioChange(option);
              }
            }}
            disabled={readOnly}
          />
          {renderNestedInput(option, isSelected)}
        </div>
      );
    });
  };

  const renderUpload = () => (
    <OnboardingUploader
      questionTitle={id}
      response={response}
      onChange={e => handleUploadChange(e)}
    />
  );

  const renderCustom = () => (
    <Form.Group>
      {children}
      <Form.Label htmlFor={`${questionNumber}-custom-additionalInfo`}>Additional Info:</Form.Label>
      <Form.Control
        id={`${questionNumber}-custom-additionalInfo`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  const renderTextarea = () => (
    <Form.Group>
      <Form.Label htmlFor={`${questionNumber}-textarea`}>{textLabel}</Form.Label>
      <Form.Control
        id={`${questionNumber}-textarea`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  const renderAdditionalInfo = () => (
    <Form.Group>
      <Form.Label htmlFor={`${questionNumber}-additionalInfo`}>Additional Info:</Form.Label>
      <Form.Control
        id={`${questionNumber}-additionalInfo`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  let formElement;
  switch (type) {
    case "radio":
      formElement = (
        <>
          {renderOptions(options || [], questionNumber, "radio", handleRadioChange)}
          {renderAdditionalInfo()}
        </>
      );
      break;
    case "checkbox":
      formElement = (
        <>
          {renderOptions(options || [], questionNumber, "checkbox", handleCheckboxChange)}
          {renderAdditionalInfo()}
        </>
      );
      break;
    case "textarea":
      formElement = renderTextarea();
      break;
    case "upload":
      formElement = (
        <>
          {renderUpload()}
          {renderAdditionalInfo()}
        </>
      );
      break;
    case "custom":
      formElement = renderCustom();
      break;
    default:
      formElement = null;
  }

  return (
    <div
      id={questionNumber}
      className={`questionCard ${focused ? "focused" : ""} ${className || ""}`}
      ref={questionRef}
    >
      <div className="questionHeader">
        <p className="questionNumber">{questionNumber}</p>
        <OverlayTrigger overlay={<Tooltip id={`${questionNumber}-copyButton`}>Copy</Tooltip>}>
          <Button
            id={`${questionNumber}-copyButton`}
            type={ButtonType.EMPTY}
            className="onboardingButton"
            onClick={() => {
              navigator.clipboard.writeText(`${url}#${questionNumber}`);
            }}
            icon={<MdOutlineInsertLink />}
          />
        </OverlayTrigger>
      </div>
      <h2>{title}</h2>
      <p>{subtitle}</p>
      {formElement}
    </div>
  );
};

export default QuestionCard;
