import "./AMCGraphs.scss";
import * as R from "ramda";
import React, { useState } from "react";
import {
  Bar,
  BarChart as BarGraph,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { MdFirstPage, MdLastPage, MdOutlineSkipNext, MdOutlineSkipPrevious } from "react-icons/md";
import { IconButton } from "../Components";

interface BarChartProps {
  data: {}[];
  barKey: string;
  barFillColor?: string;
  yAxisKey: string;
  yAxisLabel: string;
  height?: number;
  fontSize?: number;
  headerHeight?: number;
  limit?: number;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  barKey,
  barFillColor,
  yAxisKey,
  yAxisLabel,
  height,
  fontSize = 16,
  limit = 10,
}) => {
  const [offset, updateOffset] = useState(0);

  // Dynamically configure y-axis width based on length of longest key
  const calculateYAxisWidth = (sliceData: typeof data) =>
    (R.filter(entry => !R.isNil(entry[yAxisKey]), sliceData)
      .map(entry => entry[yAxisKey])
      .reduce(
        (accumulator, currentValue) =>
          currentValue.length > accumulator ? currentValue.length : accumulator,
        0
      ) /
      2) *
      (fontSize * 1.25) +
    fontSize;

  const renderLegend = (props, sliceData: typeof data) => (
    <div className="legend-wrapper">
      {props.payload.map((entry, index) => (
        <div className="legend" key={index}>
          <div
            className="legend-color"
            style={{
              backgroundColor: barFillColor,
              marginLeft: calculateYAxisWidth(sliceData),
            }}
          />
          <div>{entry.value}</div>
        </div>
      ))}
    </div>
  );

  const totalData = data.length;
  const sliceData = data.slice(offset, offset + limit > data.length ? data.length : offset + limit);

  return (
    <div className="chart-wrapper">
      <ResponsiveContainer height={height || 400} width="100%">
        <BarGraph className="amc-horizontal-bar-chart" data={sliceData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" stroke="#000" />
          <YAxis
            type="category"
            stroke="#000"
            dataKey={yAxisKey}
            width={calculateYAxisWidth(sliceData)}
            tick={({ x, y, payload }) => (
              <g transform={`translate(${x},${y})`}>
                <text
                  x={0}
                  y={fontSize * -0.75}
                  dy={fontSize}
                  textAnchor="end"
                  fontSize={`${fontSize}px`}
                >
                  {payload.value}
                </text>
              </g>
            )}
            interval={0}
          />
          <Tooltip />
          <Legend content={props => renderLegend(props, sliceData)} verticalAlign="top" />
          <Bar dataKey={barKey} name={yAxisLabel} fill={barFillColor || "#8884d8"} />
        </BarGraph>
      </ResponsiveContainer>
      {data.length > limit && (
        <div className="chart-pagination">
          <IconButton
            disable={!(offset > 0)}
            variant="outline-primary"
            className="first-page-button"
            onClick={() => {
              updateOffset(0);
            }}
            icon={<MdFirstPage />}
          >
            {""}
          </IconButton>
          <IconButton
            disable={!(offset > 0)}
            variant="outline-primary"
            className="previous-button"
            onClick={() => {
              offset - limit > 0 ? updateOffset(offset - limit) : updateOffset(0);
            }}
            icon={<MdOutlineSkipPrevious />}
          >
            {""}
          </IconButton>
          <div className="pagination-data-shown">
            <div>{totalData === 0 ? 0 : offset + 1} to </div>
            <div>{offset + limit <= totalData ? offset + limit : totalData} of </div>
            <div>{totalData}</div>
          </div>
          <IconButton
            disable={offset + limit >= totalData}
            variant="outline-primary"
            className="next-button"
            onClick={() => {
              updateOffset(offset + limit);
            }}
            icon={<MdOutlineSkipNext />}
          >
            {""}
          </IconButton>
          <IconButton
            disable={offset + limit >= totalData}
            variant="outline-primary"
            className="next-button"
            onClick={() => {
              updateOffset(totalData - limit);
            }}
            icon={<MdLastPage />}
          >
            {""}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default BarChart;
