import "./LegendWithGrouping.scss";
import React from "react";
import { Neutral200 as INACTIVE_COLOR } from "../../../utils/colors";
import { MdOutlineWarningAmber } from "react-icons/md";

interface LegendWithGroupingProps {
  activeArea: string | null;
  activeGroup: string | null;
  handleLegendClick: (area: string, group?: string) => void;
  setHoveredLegend: React.Dispatch<React.SetStateAction<string | null>>;
  handleLegendEnter: (area: string) => void;
  hoveredLegend: string | null;
  incrementality?: boolean;
  colorMap: any;
  groupedAreas: any;
}

const LegendWithGrouping = ({
  activeArea,
  activeGroup,
  handleLegendClick,
  setHoveredLegend,
  handleLegendEnter,
  hoveredLegend,
  incrementality,
  colorMap,
  groupedAreas,
}: LegendWithGroupingProps): JSX.Element => {
  return groupedAreas.map(group => {
    return (
      <div className="legendWithGrouping">
        <div className="legendItem">
          <div className="legendValue">
            <div>
              <div className="legendHeaderWrapper" style={{ cursor: "default" }}>
                <div
                  className={`legendHeader ${
                    activeGroup === group.channel || activeGroup === null ? "" : "inactiveLegend"
                  }`}
                >
                  {group.channel}
                </div>
              </div>
              {group.areas.map(area => {
                const isActiveArea = activeArea === null ? true : activeArea === area.name;
                const isHoveredLegend = hoveredLegend === null ? true : hoveredLegend === area.name;
                const activeColor = isActiveArea ? colorMap?.[area.name] : INACTIVE_COLOR;
                const hoverColor = isHoveredLegend ? colorMap?.[area.name] : INACTIVE_COLOR;
                return (
                  <div
                    className="subHeaderWrapper"
                    onClick={() => handleLegendClick(area.name, group.channel)}
                    onMouseEnter={() => handleLegendEnter(area.name)}
                    onMouseLeave={() => setHoveredLegend(null)}
                  >
                    <div
                      className="square"
                      style={{
                        backgroundColor: `${activeArea ? activeColor : hoverColor}`,
                      }}
                    />
                    <div className="subHeaderTextWrap">
                      <div className={`subHeader1 ${isActiveArea ? "" : "inactiveLegend"}`}>
                        {area.grouping.platform}
                      </div>
                      {area.grouping.platform &&
                        (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                          <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                        )}
                      <div className={`subHeader2 ${isActiveArea ? "" : "inactiveLegend"}`}>
                        {`${area.grouping.tactic ? area.grouping.tactic : ""}${
                          area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""
                        }${area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""}`}
                      </div>
                      {area.incrementality && incrementality && (
                        <MdOutlineWarningAmber
                          style={{
                            color: `${
                              isActiveArea || activeArea === null ? "orange" : INACTIVE_COLOR
                            }`,
                            marginLeft: "5px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default LegendWithGrouping;
