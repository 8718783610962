import { CreativeAllocationPicker } from "./CreativeAllocationPicker";
import { MetadataForm } from "./MetadataForm";
import { Modal, ModalProps } from "react-bootstrap";
import { StateSetter } from "../../utils/types";
import { TagCreationForm } from "./TagCreationForm";
import { TagData, StagedTagData } from "./utils";
import * as R from "ramda";
import React, { useCallback, useState } from "react";

type TagCreationModalPageType = "initialForm" | "creativeAllocation" | "metadataForm";
const modalSizeMap: Record<string, ModalProps[TagCreationModalPageType]> = {
  creativeAllocation: "xl",
  metadataForm: "lg",
};

interface TagCreationModalProps {
  initialTag: TagData;
  setInitialTag: StateSetter<TagData>;
  show: boolean;
  startingPage: TagCreationModalPageType;
  onClose: any;
  stageTags: any;
  isEditMode: boolean;
  flashtalkingCampaigns?: { campaign_id: number; campaign: string; is_managed_service: boolean }[];
}

const TagCreationModal = ({
  initialTag,
  setInitialTag,
  show,
  startingPage,
  onClose,
  stageTags,
  isEditMode,
  flashtalkingCampaigns = [],
}: TagCreationModalProps): JSX.Element => {
  const [page, setPage] = useState<TagCreationModalPageType>(startingPage);
  const [tagsToStage, setTagsToStage] = useState<StagedTagData[]>([]);

  const makeTagName = useCallback(
    ({ device, length, OS }) => {
      const { company, derivedID, network, platform, description } = initialTag;
      let parts = [`${derivedID} ${company} ${network} ${platform}`];
      if (device) {
        parts.push(`Device: ${device}`);
      }
      if (OS) {
        parts.push(`OS: ${OS}`);
      }
      let endParts: string[] = [];
      if (description) {
        endParts.push(description);
      }
      if (length) {
        endParts.push(`${length}s`);
      }
      if (endParts.length) {
        parts.push(endParts.join(" "));
      }
      return parts.join(" | ");
    },
    [initialTag]
  );

  // When a tag is "created," depending on the selections for device, length, and OS, it might
  // actually mean that several tags have been created (each device x length x OS combo).
  // Each of these will have its own placement name and will need its own creative allocations.
  const breakDownTagBySelections = useCallback(() => {
    let tagsFromSelections: StagedTagData[] = [];

    let devices: any[] = [null];
    let OSes: any[] = [null];
    let lengths: any[] = [null];
    if (initialTag.deviceMap) {
      devices = [];
      for (let device of R.keys(initialTag.deviceMap)) {
        if (initialTag.deviceMap[device]) {
          devices.push(device);
        }
      }
    }
    if (initialTag.osMap) {
      OSes = [];
      for (let os of R.keys(initialTag.osMap)) {
        if (initialTag.osMap[os]) {
          OSes.push(os);
        }
      }
    }
    if (initialTag.lengthMap) {
      lengths = [];
      for (let length of R.keys(initialTag.lengthMap)) {
        if (initialTag.lengthMap[length]) {
          lengths.push(length);
        }
      }
    }
    for (let device of devices) {
      for (let length of lengths) {
        for (let OS of OSes) {
          tagsFromSelections.push({
            ...initialTag,
            device,
            length,
            OS,
            name: makeTagName({
              device,
              length,
              OS,
            }),
          });
        }
      }
    }
    setTagsToStage(tagsFromSelections);
  }, [initialTag, makeTagName]);

  const submitInitialForm = useCallback(() => {
    // We actually stage one tag per device/length/OS combo
    // if there are multiple of those values selected, so
    // break this tag into multiple depending on selections.
    breakDownTagBySelections();
    setPage("creativeAllocation");
  }, [breakDownTagBySelections]);

  const submitCreativeAllocation = useCallback(() => {
    setPage("metadataForm");
  }, []);

  const submitMetadataForm = useCallback(() => {
    stageTags(tagsToStage);
    onClose();
  }, [onClose, stageTags, tagsToStage]);

  return (
    <Modal
      size={modalSizeMap[page]}
      show={show}
      onHide={onClose}
      onExited={() => setPage(startingPage)}
      dialogClassName="tagCreationModal"
      centered
    >
      {page === "initialForm" && (
        <TagCreationForm
          tag={initialTag}
          setTag={setInitialTag}
          onSubmit={submitInitialForm}
          flashtalkingCampaigns={flashtalkingCampaigns}
        />
      )}
      {page === "creativeAllocation" && (
        <CreativeAllocationPicker
          tags={tagsToStage}
          setTags={setTagsToStage}
          onSubmit={submitCreativeAllocation}
          isEditMode={isEditMode}
        />
      )}
      {page === "metadataForm" && (
        <MetadataForm
          tags={tagsToStage}
          setTags={setTagsToStage}
          onSubmit={submitMetadataForm}
          company={initialTag.company}
          isEditMode={isEditMode}
        />
      )}
    </Modal>
  );
};

export default TagCreationModal;
