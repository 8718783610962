import { awaitJSON, FiltersLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  computeTotals,
  DIMENSIONS,
  fetchStreamingDeliveryDateExtrema,
  fetchDailyDelivery,
  useColorMap,
  DailyDeliveryData,
  useOtherMap,
  DateExtrema,
} from "../StreamingDelivery/StreamingDelivery";
import { convertSVGStringToPNGURI } from "../utils/download-utils";
import { DeviceColorMap } from "../utils/colors";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { Form } from "react-bootstrap";
import { GetFilterPresetsParams } from "@blisspointmedia/bpm-types/dist/FilterPresets";
import { getLegendInfo } from "../StreamingDelivery/Legend";
import { OldDropdown as Dropdown, RelativeDatePicker, BPMToggleButton } from "../Components";
import { SetError } from "../redux/modals";
import { SharedState, SlideState } from "./slideTemplateConstants";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import DeliveryChart from "../StreamingDelivery/DeliveryChart";
import React, { useEffect, useRef, useMemo, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

interface LegendItem {
  key: string;
  color: string;
  overrideText?: string | undefined;
}

interface SlideDeliveryChartProps {
  absolute: boolean;
  bySpend: boolean;
  daily: boolean;
  data: DailyDeliveryData;
  dimension: string;
  useLogo: boolean;
  onLoad: (deliveryChartRefCurrent: { elem: any; legend: LegendItem[] }) => void;
}

export const SlideDeliveryChart: React.FC<SlideDeliveryChartProps> = ({
  absolute,
  bySpend,
  daily,
  data,
  dimension,
  useLogo,
  onLoad,
}) => {
  const ref = useRef();
  const activeKeyMap = useMemo(() => R.mapObjIndexed(R.always(true), data.keys), [data]);
  const totals = useMemo(() => {
    return computeTotals({ data, daily, activeKeyMap }).totals;
  }, [data, daily, activeKeyMap]);

  const deviceColorMap = useColorMap(DeviceColorMap);
  const defaultColorMap = useColorMap({});

  const otherMap = useOtherMap(data, activeKeyMap, daily, bySpend);

  const colorMap = useMemo(() => {
    let colorMap;
    switch (dimension) {
      case "deviceos":
        colorMap = deviceColorMap;
        break;
      default:
        colorMap = defaultColorMap;
        break;
    }
    return colorMap;
  }, [deviceColorMap, defaultColorMap, dimension]);

  useLayoutEffect(() => {
    // We have to put this at the bottom of the event queue to wait for recharts to draw it
    // apparently.
    setTimeout(() => {
      let keys = R.keys(activeKeyMap);
      onLoad({
        elem: ref.current,
        legend: R.map(key => {
          const { color, logoSrc } = getLegendInfo({
            key,
            colorMap,
            info: data.keys[key],
            dimension,
          });
          let overrideText;
          if (dimension === "length" || !useLogo) {
            overrideText = key;
          }
          return { color, overrideText, url: logoSrc, key };
        }, keys),
      });
    }, 100);
  }, [colorMap, onLoad, activeKeyMap, data, dimension, useLogo]);
  return (
    <DeliveryChart
      ref={ref}
      powerpoint
      hasTooltip={false}
      data={data[daily ? "daily" : "weekly"]}
      totals={totals}
      activeKeyMap={activeKeyMap}
      absolute={absolute}
      isSpend={bySpend}
      colorMap={colorMap}
      daily={daily}
      dimension={dimension}
      otherMap={otherMap}
    />
  );
};

export interface StreamingDeliverySlideState {
  absolute: boolean;
  bySpend: boolean;
  daily: boolean;
  dateExtrema?: DateExtrema;
  dates: RelativeDateRange;
  dimension: string;
  prefix: S.Prefix;
  filterPreset: FilterPreset | null;
  title: string;
  vod: boolean;
  useLogo: boolean;
}

interface StreamingDeliverySlideData {
  title: string;
  chartURL: string;
  legend: LegendItem[];
}

export const strDelivSharedStateKey = "streamingDelivery" as const;
export interface StreamingDeliverySharedState {
  filterPresets: FilterPreset[];
  dateExtrema: DateExtrema;
}
export const strDelivSharedFetcher: SharedStateFetcher<StreamingDeliverySharedState> = async (
  key: string,
  setError: SetError
) => {
  const [company, platform] = key.split("_");
  let res: StreamingDeliverySharedState = {
    filterPresets: [],
    dateExtrema: {
      min: "",
      max: "",
    },
  };
  try {
    res.dateExtrema = await fetchStreamingDeliveryDateExtrema(company, platform);
  } catch (e) {
    let error = e as Error;
    setError({
      message: `Could not get delivery date range. Error: ${error.message}`,
      reportError: error,
    });
  }
  try {
    const params = {
      company,
      platform,
    };
    const filterPresetRes = await FiltersLambdaFetch<GetFilterPresetsParams>("/getFilters", {
      params,
    });
    const parsedFilterPresets = await awaitJSON(filterPresetRes);
    res.filterPresets = parsedFilterPresets;
  } catch (e) {
    let error: Error = e as Error;
    setError({
      message: `Could not get valid streaming filter presets. Error: ${error.message}`,
      reportError: error,
    });
  }
  return res;
};

class StreamingDeliverySlide extends SlideType {
  static typeKey = "streamingDelivery";
  static displayKey = "Streaming Delivery";
  static defaultState: StreamingDeliverySlideState = {
    absolute: false,
    bySpend: true,
    daily: true,
    dates: {
      start: { pivotDate: "monday", adjustment: 6, adjustmentType: "week" },
      end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
    },
    dimension: "network_group",
    prefix: "streaming",
    filterPreset: null,
    title: "Streaming Delivery & Pacing",
    useLogo: true,
    vod: false,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<StreamingDeliverySlideState>
  > = React.memo(({ state, setState, slideContext, sharedState, sharedFetch }) => {
    const { company } = slideContext;
    const {
      absolute,
      bySpend,
      daily,
      dateExtrema,
      dates,
      dimension,
      filterPreset,
      title,
      useLogo,
      vod,
    } = state;
    const extremaKey = `${company}_${state.prefix}`;
    const { filterPresets } =
      R.isNil(sharedState) ||
      R.isNil(sharedState[strDelivSharedStateKey]) ||
      R.isNil(sharedState[strDelivSharedStateKey][extremaKey])
        ? { filterPresets: [] }
        : sharedState[strDelivSharedStateKey][extremaKey];

    useEffect(() => {
      if (!R.path([strDelivSharedStateKey, extremaKey], sharedState)) {
        (async () => {
          await sharedFetch(strDelivSharedStateKey, extremaKey);
        })();
      }
    }, [sharedState, sharedFetch, extremaKey]);

    useEffect(() => {
      if (!dateExtrema) {
        const dateExtrema: DateExtrema | undefined = R.path(
          [strDelivSharedStateKey, extremaKey, "dateExtrema"],
          sharedState
        );
        if (dateExtrema) {
          setState({ dateExtrema });
        }
      }
    }, [sharedState, dateExtrema, extremaKey, setState]);

    return (
      <div className="settingsBox">
        <div>
          <Form.Group className="flex">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={e => setState({ title: e.currentTarget.value })}
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group></Form.Group>
          <Form.Group>
            <BPMToggleButton
              options={[
                {
                  key: "Network Logo",
                },
                {
                  key: "Network Name",
                },
              ]}
              selectedOption={useLogo ? "Network Logo" : "Network Name"}
              onChange={e => {
                setState({ useLogo: e === "Network Logo" });
              }}
            />
          </Form.Group>
        </div>
        <div className="wrappingColumn">
          <Dropdown
            label={"Filter"}
            value={filterPreset ? filterPreset.id.toString() : "undefined"}
            options={R.map(filterPreset => {
              return { label: filterPreset.name, value: filterPreset.id.toString() };
            }, R.defaultTo([], filterPresets) as FilterPreset[])}
            onChange={filterID => {
              let filterPreset: FilterPreset | null = null;
              for (let preset of R.defaultTo([], filterPresets)) {
                if (filterID === preset.id.toString()) {
                  filterPreset = preset;
                }
              }
              setState({ filterPreset });
            }}
          />
          <Dropdown
            label={"Dimension"}
            value={dimension}
            options={R.keys(DIMENSIONS).map(key => ({ label: DIMENSIONS[key], value: key }))}
            onChange={dimension => setState({ dimension })}
          />
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Relative",
                },
                {
                  key: "Absolute",
                },
              ]}
              selectedOption={absolute ? "Absolute" : "Relative"}
              onChange={key => setState({ absolute: key === "Absolute" })}
            />
          </Form.Group>
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "Daily",
                },
                {
                  key: "Weekly",
                },
              ]}
              selectedOption={daily ? "Daily" : "Weekly"}
              onChange={key => setState({ daily: key === "Daily" })}
            />
          </Form.Group>
          <Form.Group>
            <BPMToggleButton
              block
              bordered
              options={[
                {
                  key: "spend",
                  label: "By Spend",
                },
                {
                  key: "imps",
                  label: "By Impressions",
                },
              ]}
              selectedOption={bySpend ? "spend" : "imps"}
              onChange={key => setState({ bySpend: key === "spend" })}
            />
          </Form.Group>
          <Form.Group>
            <BPMToggleButton
              options={[
                {
                  key: "Include VOD",
                },
                {
                  key: "Exclude VOD",
                },
              ]}
              selectedOption={vod ? "Include VOD" : "Exclude VOD"}
              onChange={e => {
                setState({ vod: e === "Include VOD" });
              }}
            />
          </Form.Group>
        </div>
        <div className="wrappingColumn">
          <Form.Group>
            <Form.Label>Start</Form.Label>
            <RelativeDatePicker
              state={dates.start}
              onChange={start => setState(R.mergeDeepLeft({ dates: { start } }))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End</Form.Label>
            <RelativeDatePicker
              state={dates.end}
              onChange={end => setState(R.mergeDeepLeft({ dates: { end } }))}
            />
          </Form.Group>
        </div>
        <div>
          <small>Note: Dates will be clipped based on the available data.</small>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<StreamingDeliverySlideData> => {
    const { company } = context;
    const {
      absolute,
      bySpend,
      daily,
      dateExtrema,
      dates,
      dimension,
      filterPreset,
      prefix,
      title,
      useLogo,
      vod,
    } = state as StreamingDeliverySlideState;
    let data = await fetchDailyDelivery({
      company,
      dates: {
        start: dateExtrema
          ? R.max(computeResolvedDate(dates.start), dateExtrema?.min)
          : computeResolvedDate(dates.start),
        end: computeResolvedDate(dates.end),
      },
      dimension,
      filterID: filterPreset ? filterPreset.id : undefined,
      filterState: filterPreset
        ? filterPreset.filter_state
        : { advanced: "", basic: { notMap: {}, selectedMap: {} }, isAdvanced: false },
      prefix,
      vod,
    });

    if (R.isEmpty(data.keys) || R.isEmpty(data[daily ? "daily" : "weekly"])) {
      throw new Error(
        `We are missing data for the date range: ${
          dateExtrema
            ? R.max(computeResolvedDate(dates.start), dateExtrema?.min)
            : computeResolvedDate(dates.start)
        }-${computeResolvedDate(dates.end)} with the following settings:
        company: ${company}
        dimension: ${dimension}
        prefix: ${prefix}
        ${filterPreset ? `filter: ${filterPreset.name}` : ""}
        Please verify on the Streaming Delivery Page that there is data for these settings or reach out to the engineering team!`
      );
    }

    let sandbox = await claimSandbox();
    let { svgString, legend }: { svgString: string; legend: LegendItem[] } = await new Promise(
      resolve => {
        const load = () => {
          console.log("loading delivery chart");
          ReactDOM.render(
            <SlideDeliveryChart
              daily={daily}
              absolute={absolute}
              data={data}
              dimension={dimension}
              bySpend={bySpend}
              useLogo={useLogo}
              onLoad={({ elem, legend }) => {
                if (elem) {
                  console.log("delivery chart success!");
                  let svg = elem.container.getElementsByTagName("svg")[0];
                  let boundingBox = svg.getBoundingClientRect();
                  let svgString = convertSVGStringToPNGURI(
                    svg.outerHTML,
                    boundingBox.width,
                    boundingBox.height
                  );
                  resolve({ svgString, legend });
                } else {
                  console.log("delivery chart failed, retrying");
                  setTimeout(load, 100);
                }
              }}
            />,
            sandbox.element
          );
        };
        setTimeout(load, 100);
      }
    );

    releaseSandbox(sandbox);
    let chartURL = await addS3Image(svgString);
    return { chartURL, legend, title };
  };
}

export default StreamingDeliverySlide;
