import React, { useEffect, useMemo, useState, useCallback } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import Papa from "papaparse";

import { ToggleButtonGroup, ToggleButton, InputGroup, Button } from "react-bootstrap";
import AutoSizer from "react-virtualized-auto-sizer";
import Select from "react-select";
import { MdOutlineFileDownload } from "react-icons/md";

import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";
import { download } from "../utils/download-utils";

import { useMap } from "../utils/hooks/useData";
import { awaitJSON, S3SignedUrlFetch } from "../utils/fetch-utils";
import * as Colors from "../utils/colors";

import {
  Page,
  Spinner,
  StickyTable,
  Img,
  DateRangePicker,
  CompetitiveChartBox,
} from "../Components";

import "./CompetitiveMetrics.scss";
import { formatNumberAsInt } from "../utils/format-utils";

const EXPAND_LABELS = {
  dayPart: "Expand Dayparts",
  network: "Expand Networks",
  length: "Expand Lengths",
};
const SHOW_ESTIMATED_SPEND_LABEL = "Show Estimated Spend";
const SHOW_SPOTS_LABEL = "Show Spots";
const BUTTON_FONT_SIZE = 12;

const ALL_LENGTHS = "All Lengths";
const ALL_NETWORKS = "All Networks";

const DATE_FORMAT = "yyyy-MM-dd";

const DEFAULT_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.subWeeks(1),
  Dfns.format(DATE_FORMAT)
)(new Date());

const CUTOFF_DATE = R.pipe(Dfns.startOfISOWeek, Dfns.format(DATE_FORMAT))(new Date());

const streamingDate = R.pipe(Dfns.startOfISOWeek, Dfns.addDays(1), Dfns.subWeeks(1))(new Date());

const getGreenShade = (value, min, max) => {
  let pctVal = 1 - (value - min) / (max - min);
  let scaledRgbVal = Math.trunc(pctVal * 255);
  let scaledGreenVal = Math.trunc(pctVal * 100) + 155;

  let rgbHexVal = scaledRgbVal.toString(16).padStart(2, "0");
  let greenHexVal = scaledGreenVal.toString(16).padStart(2, "0");
  return `#${rgbHexVal}${greenHexVal}${rgbHexVal}`;
};

const getS3Data = async (filename, selectedCompetitor) => {
  if (!selectedCompetitor) {
    return null;
  }
  let res = await S3SignedUrlFetch(
    `bpm-ml-data/competitive/latest/${selectedCompetitor}_${filename}.json.gz`
  );
  try {
    let data = await awaitJSON(res);
    return data;
  } catch (e) {
    if ((R.prop("message", e) || "").includes("NoSuchKey")) {
      throw new Error("No data found");
    }
    throw e;
  }
};

const getCountsByWeek = (arr, keyName, countName) =>
  R.pipe(
    R.map(obj => {
      return {
        date: obj[keyName],
        count: R.sum(R.map(spotData => R.sum(spotData), obj[countName])),
      };
    })
  )(arr);

const filterOutInvalidDays = (countsByDate, validDays) => {
  let filteredCountsByDate = R.filter(row => R.contains(row.date, validDays), countsByDate);
  let existingDates = R.pluck("date", filteredCountsByDate);
  for (let validDay of validDays) {
    if (!R.contains(validDay, existingDates)) {
      filteredCountsByDate.push({ date: validDay, count: 0 });
    }
  }
  return filteredCountsByDate;
};

const ChartsContainer = ({ costPersRawData, creativesRawData }) => {
  const transformedCreatives = useMemo(() => {
    if (!creativesRawData) {
      return null;
    }
    return R.pipe(
      R.toPairs,
      R.map(R.zipObj(["date", "count"])),
      R.sortBy(row => row.date)
    )(creativesRawData);
  }, [creativesRawData]);

  const [transformedSpotsByWeek, transformedSpendsByWeek] = useMemo(() => {
    if (!costPersRawData) {
      return [];
    }
    let spotsByWeek = getCountsByWeek(costPersRawData.weeks, "week", "spots");
    let spendsByWeek = getCountsByWeek(costPersRawData.weeks, "week", "spend");
    return [spotsByWeek, spendsByWeek];
  }, [costPersRawData]);

  const validDays = R.pipe(
    Dfns.eachDayOfInterval,
    R.filter(Dfns.isMonday),
    R.map(Dfns.format("yyyy-MM-dd"))
  )({
    start: Dfns.subWeeks(25, streamingDate),
    end: streamingDate,
  });

  const { creativesChartData, spotsChartData, spendsChartData } = useMemo(() => {
    if (transformedCreatives && transformedSpotsByWeek && transformedSpendsByWeek) {
      return {
        creativesChartData: filterOutInvalidDays(transformedCreatives, validDays),
        spotsChartData: filterOutInvalidDays(transformedSpotsByWeek, validDays),
        spendsChartData: filterOutInvalidDays(transformedSpendsByWeek, validDays),
      };
    }
    return {};
  }, [validDays, transformedCreatives, transformedSpendsByWeek, transformedSpotsByWeek]);
  return (
    <div className="charts-container">
      {[
        { data: spendsChartData, title: "Estimated Spend" },
        { data: spotsChartData, title: "Spots" },
        { data: creativesChartData, title: "Creatives" },
      ].map(({ data, title }) => (
        <div className="chart-box" key={title}>
          <CompetitiveChartBox transformedData={R.sortBy(R.prop("date"), data)} title={title} />
        </div>
      ))}
    </div>
  );
};

const getFirstPartOfHeaderName = R.pipe(R.split("_"), R.prop(0));

const NetworkLogo = ({ network }) => (
  <Img
    src={`https://cdn.blisspointmedia.com/networks/${network}.png`}
    className="networkLogo"
    title={network}
    loader={
      <div className="logoNoImgBox">
        <Spinner />
      </div>
    }
    unloader={
      <div className="logoNoImgBox logo404">
        <span>{network}</span>
      </div>
    }
  />
);

const getRgbLimitsFromData = data => {
  const rgbLimits = {
    details: {
      min: Infinity,
      max: -Infinity,
    },
    networks: {
      min: Infinity,
      max: -Infinity,
    },
    dayparts: {
      min: Infinity,
      max: -Infinity,
    },
  };
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    let row = data[rowIndex];
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      let value = data[rowIndex][colIndex];
      // If this is the total/total box, it doesn't count toward any min/max values
      if (colIndex === 0 && rowIndex === data.length - 1) {
        continue;
      } else if (colIndex === 0) {
        // If we're in the first column, we're getting the network min/max values
        rgbLimits.networks = {
          min: Math.min(rgbLimits.networks.min, value),
          max: Math.max(rgbLimits.networks.max, value),
        };
      } else if (rowIndex === data.length - 1) {
        // If we're in the last row, we're getting the daypart min/max values
        rgbLimits.dayparts = {
          min: Math.min(rgbLimits.dayparts.min, value),
          max: Math.max(rgbLimits.dayparts.max, value),
        };
      } else {
        // If we're not in the first row/column, we're getting min/max for the interior of the table.
        rgbLimits.details = {
          min: Math.min(rgbLimits.details.min, value),
          max: Math.max(rgbLimits.details.max, value),
        };
      }
    }
  }
  return rgbLimits;
};

const MetricsTableContainer = React.memo(({ costPersRawData, weekNames, expandBy, setCsv }) => {
  if (!costPersRawData) {
    return <Spinner size={75} />;
  }
  const { weeks } = costPersRawData;
  const { colHeaders, rowHeaders } = weeks[weeks.length - 1];
  let filteredWeeks = R.filter(weekData => R.includes(weekData.name, weekNames), weeks);

  let spots = [];
  let spend = [];
  for (let i = 0; i < rowHeaders.length; i++) {
    spots.push(new Array(colHeaders.length).fill(0));
    spend.push(new Array(colHeaders.length).fill(0));
  }

  for (let weekData of filteredWeeks) {
    for (let rowIndex = 0; rowIndex < rowHeaders.length; rowIndex++) {
      for (let colIndex = 0; colIndex < colHeaders.length; colIndex++) {
        spend[rowIndex][colIndex] += weekData.spend[rowIndex][colIndex];
        spots[rowIndex][colIndex] += weekData.spots[rowIndex][colIndex];
      }
    }
  }

  let ourRowHeaders = [...rowHeaders];
  let getOurRowIndex = R.identity;
  let ourColHeaders = R.map(
    origColHeader => {
      let tokens = R.split("_", origColHeader);
      let network = tokens[1];
      let length = tokens[0];
      return {
        network: network,
        length: parseInt(length.replace("s", "")),
      };
    },
    [...colHeaders]
  );

  ourColHeaders = R.sortWith(
    [R.ascend(R.prop("network")), R.ascend(R.prop("length"))],
    ourColHeaders
  );
  let colIndexMap = {};
  for (let c = 0; c < colHeaders.length; c++) {
    let origColHeader = colHeaders[c];
    let tokens = R.split("_", origColHeader);
    let network = tokens[1];
    let length = tokens[0];
    let newColHeader = {
      network: network,
      length: parseInt(length.replace("s", "")),
    };
    colIndexMap[c] = R.indexOf(newColHeader, ourColHeaders);
  }

  let getOurColIndex = origColIndex => colIndexMap[origColIndex];
  let numericData = expandBy.spots ? spots : spend;
  const mapIndexed = R.addIndex(R.map);
  if (!expandBy.dayPart) {
    ourRowHeaders = R.uniq(R.map(getFirstPartOfHeaderName, rowHeaders));
    const dayPartHeaderMap = R.pipe(
      mapIndexed((val, idx) => ({
        [idx]: R.indexOf(getFirstPartOfHeaderName(val), ourRowHeaders),
      })),
      R.mergeAll
    )(rowHeaders);

    getOurRowIndex = origRowIndex => dayPartHeaderMap[origRowIndex];
  }

  if (!expandBy.network) {
    ourColHeaders = R.uniq(
      R.map(headerObj => {
        let keys = R.without(["network"], R.keys(headerObj));
        let newHeader = {};
        for (let key of keys) {
          newHeader[key] = headerObj[key];
        }
        return newHeader;
      }, ourColHeaders)
    );
    ourColHeaders = R.sortWith([R.ascend(R.prop("length"))], ourColHeaders);
    const colHeaderIndexMap = R.pipe(
      mapIndexed((val, idx) => {
        let length = parseInt(val.split("_")[0].replace("s", ""));
        let obj = { length: length };
        let ret = { [idx]: R.indexOf(obj, ourColHeaders) };
        return ret;
      }),
      R.mergeAll
    )(colHeaders);

    getOurColIndex = origColIndex => colHeaderIndexMap[origColIndex];
  }

  if (!expandBy.length) {
    ourColHeaders = R.uniq(
      R.map(headerObj => {
        let keys = R.without(["length"], R.keys(headerObj));
        let newHeader = {};
        for (let key of keys) {
          newHeader[key] = headerObj[key];
        }
        return newHeader;
      }, ourColHeaders)
    );
    if (R.length(ourColHeaders) <= 1) {
      ourColHeaders = [];
    } else {
      ourColHeaders = R.sortWith([R.ascend(R.prop("network"))], ourColHeaders);
    }

    const colHeaderIndexMap = R.pipe(
      mapIndexed((val, idx) => {
        let network = val.split("_")[1];
        let obj = { network: network };
        return { [idx]: R.indexOf(obj, ourColHeaders) };
      }),
      R.mergeAll
    )(colHeaders);
    getOurColIndex = origColIndex => colHeaderIndexMap[origColIndex];
  }

  let rowTotals = new Array(ourColHeaders.length).fill(0);
  let columnTotals = new Array(ourRowHeaders.length).fill(0);
  const data = [];
  for (let i = 0; i < ourRowHeaders.length; i++) {
    data.push(new Array(ourColHeaders.length).fill(0));
  }
  for (let rowIndex = 0; rowIndex < rowHeaders.length; rowIndex++) {
    let ourRowIndex = getOurRowIndex(rowIndex);
    for (let colIndex = 0; colIndex < colHeaders.length; colIndex++) {
      let ourColIndex = getOurColIndex(colIndex);
      let value = numericData[rowIndex][colIndex];
      columnTotals[ourRowIndex] += value;
      if (ourColIndex >= 0) {
        data[ourRowIndex][ourColIndex] += value;
        rowTotals[ourColIndex] += value;
      }
    }
  }

  let nonZeroColumnIndices = [];
  for (let index = rowTotals.length - 1; index >= 0; index--) {
    if (rowTotals[index] !== 0) {
      nonZeroColumnIndices.push(index);
    } else {
      rowTotals = R.remove(index, 1, rowTotals);
      ourColHeaders = R.remove(index, 1, ourColHeaders);
    }
  }
  ourRowHeaders.push("Total");
  ourColHeaders.unshift(ALL_NETWORKS);
  data.push(rowTotals);
  let totalTotal = 0;

  for (let i = 0; i < data.length - 1; i++) {
    for (let j = data[i].length - 1; j >= 0; --j) {
      if (!R.contains(j, nonZeroColumnIndices)) {
        data[i] = R.remove(j, 1, data[i]);
      }
    }
    data[i].unshift(columnTotals[i]);
    totalTotal += columnTotals[i];
  }
  data[ourRowHeaders.length - 1].unshift(totalTotal);

  const rgbLimits = getRgbLimitsFromData(data);

  const getLimit = (limit, rowIndex, columnIndex) => {
    if (columnIndex === 0) {
      return rgbLimits.networks[limit];
    } else if (rowIndex === data.length - 1) {
      return rgbLimits.dayparts[limit];
    } else {
      return rgbLimits.details[limit];
    }
  };

  const cellRenderer = ({ style, columnIndex, rowIndex, classes, data }) => (
    <div
      style={{
        ...style,
        borderTop: `1px solid ${Colors.gray}`,
        borderRight: `1px solid ${Colors.gray}`,
        backgroundColor:
          columnIndex === 0 && rowIndex === data.length - 1
            ? "white"
            : getGreenShade(
                data,
                getLimit("min", rowIndex, columnIndex),
                getLimit("max", rowIndex, columnIndex)
              ),
      }}
      className={classes.join(" ")}
    >
      {expandBy.spots || data === 0 ? "" : "$"}
      {data === 0 ? "-" : formatNumberAsInt(data)}
    </div>
  );

  const topRenderer = ({ style, columnIndex, classes, data }) => {
    if (data) {
      return (
        <div style={style} className={[...classes, "header-logos"].join(" ")}>
          {/* If we're only showing lengths, then we just display them as text. Otherwise we need the network logo. This
              also means that if we're expanding networks, we use these, and if we're not, we only use them if lengths
              are not expanded (which means it's only the "All Networks" cell, which has the styling of the big
              networks). In the event that we ARE showing the big networks, we want to back out if it's the ALL NETWORKS
              cell; we don't want the 404 on the logo and the name isn't going to properly split on _ anyway. Otherwise,
              we have a header cell that is either just a network or a network and a length. If it's both a network and
              a length, the data (name) will be `[length]_[network]_[avail]`, in which case when we split we want
              the element at index 1. Otherwise (when it's only networks expanded) the data is already parsed as the
              name of the network (this happened in the grouping/aggregating step). So we can just use the first token.
            */}
          {(expandBy.network || !expandBy.length) &&
            (data === ALL_NETWORKS ? (
              <div className="logoNoImgBox logo404">
                <span>{ALL_NETWORKS}</span>
              </div>
            ) : (
              <NetworkLogo network={data.network} />
            ))}
          {/* This gives us the length labels. We only show them when length is expanded. Additionally, we don't want to
              show it on the "All Networks" cell (at column 0) when networks are also expanded. */}

          {expandBy.length && (columnIndex > 0 || !expandBy.network) && (
            <div className="length-label">
              {data === ALL_NETWORKS ? ALL_LENGTHS : `${data.length}s`}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div style={style} className={classes.join(" ")}>
          {data}
        </div>
      );
    }
  };

  const leftRenderer = ({ style, classes, data }) => {
    let ourStyle = {
      ...style,
      borderTop: `1px solid ${Colors.gray}`,
    };
    if (data) {
      let tokens = R.split("_", data);
      return (
        <div style={ourStyle} className={[...classes, "row-labels"].join(" ")}>
          <div className="row-label">{tokens[0]}</div>
          {expandBy.dayPart && <div className="row-label-daypart">{tokens[1]}</div>}
        </div>
      );
    } else {
      return (
        <div style={ourStyle} className={classes.join(" ")}>
          {data}
        </div>
      );
    }
  };

  let csvRows = R.concat(
    [
      R.concat(
        [""],
        R.map(
          colHeader => (colHeader.network ? colHeader.network.replace(/\n/, "") : colHeader),
          ourColHeaders
        )
      ),
    ],
    R.map(i => R.concat([ourRowHeaders[i]], data[i]), R.range(0, data.length))
  );
  setCsv(Papa.unparse(csvRows));
  return (
    <div className="metrics-table-container">
      <div className="metrics-table-box">
        <AutoSizer>
          {({ width, height }) => (
            <StickyTable
              alternateColors={true}
              width={width}
              height={height}
              leftWidth={150}
              rightWidth={100}
              topHeight={65}
              topData={ourColHeaders}
              leftData={ourRowHeaders}
              data={data}
              leftRenderer={leftRenderer}
              topRenderer={topRenderer}
              cellRenderer={cellRenderer}
              rowHeight={25}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
});

const ExpandButton = ({ label, onChange, checked }) => {
  return (
    <div className="show-hide-element">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{label}</InputGroup.Text>
          <InputGroup.Checkbox
            aria-label="Checkbox for following input"
            size="sm"
            onChange={e => onChange(e.target.checked)}
            checked={checked}
          />
        </InputGroup.Prepend>
      </InputGroup>
    </div>
  );
};

const CompetitiveMetrics = () => {
  const companyInfo = useCompanyInfo();
  const setError = useSetError();

  const [selectedCompetitor, setSelectedCompetitor] = useState();
  const [competitorOptions, setCompetitorOptions] = useState();
  const [csv, setCsv] = useState("");
  const [weeks, setWeeks] = useState({
    start: DEFAULT_DATE,
    end: DEFAULT_DATE,
  });

  const [expandByMap, setExpandByValue] = useMap({
    length: false,
    network: true,
    dayPart: true,
    spots: false,
  });

  useEffect(() => {
    if (companyInfo && companyInfo.competitors) {
      setSelectedCompetitor(companyInfo.default_competitor || companyInfo.competitors[0]);
      let { competitors } = companyInfo;
      let options = R.map(item => ({
        label: item,
        value: item,
      }))(competitors || []);
      setCompetitorOptions(options);
    }
  }, [companyInfo]);

  const downloadCsv = useCallback(() => {
    download(csv, `${selectedCompetitor} ${weeks.start}-${weeks.end}.csv`, "text/csv");
  }, [csv, selectedCompetitor, weeks]);

  const [costPersRawData, setCostPersRawData] = useState();
  const [creativesRawData, setCreativesRawData] = useState();

  useEffect(() => {
    if (selectedCompetitor) {
      (async () => {
        try {
          let costPersRawData = await getS3Data("rawCostPers", selectedCompetitor);
          setCostPersRawData(costPersRawData);
        } catch (e) {
          setError({
            message: `Failed to load costPers data for ${selectedCompetitor}. Error: ${e.message}`,
            // This is a frequently failing lambda because competitors often have missing data. For now, just
            // not reporting the failure
            // reportError: e
          });
          setCostPersRawData(null);
          setCreativesRawData(null);
          return;
        }
        try {
          let creativesRawData = await getS3Data("creatives", selectedCompetitor);
          setCreativesRawData(creativesRawData);
        } catch (e) {
          setError({
            message: `Failed to load creatives data for ${selectedCompetitor}. Error: ${e.message}`,
            // This is a frequently failing lambda because competitors often have missing data. For now, just not
            // reporting the failure
            // reportError: e
          });
          setCostPersRawData(null);
          setCreativesRawData(null);
        }
      })();
    }
  }, [selectedCompetitor, setError]);

  const validDays = useMemo(() => {
    if (!weeks) {
      return [];
    }
    if (weeks.start === weeks.end) {
      return [weeks.start];
    } else {
      return Dfns.eachDayOfInterval({
        start: Dfns.parseISO(weeks.start),
        end: Dfns.parseISO(weeks.end),
      }).map(Dfns.format("yyyy-MM-dd"));
    }
  }, [weeks]);

  return (
    <Page
      title="Competitive Metrics"
      pageType="Competitive Metrics"
      minHeight={600}
      actions={
        <div className="competitiveMetricsActions">
          {selectedCompetitor && (
            <Img
              src={`https://cdn.blisspointmedia.com/competitors/${selectedCompetitor}.png`}
              className="companyLogo"
              title={selectedCompetitor}
              loader={
                <div className="logoNoImgBox">
                  <Spinner />
                </div>
              }
              unloader={
                <div className="logoNoImgBox logo404">
                  <span>{selectedCompetitor}</span>
                </div>
              }
            />
          )}
          <div className="competitorSelect">
            {selectedCompetitor && R.length(companyInfo.competitors) > 1 && (
              <Select
                onChange={value => setSelectedCompetitor(value.label)}
                value={{ label: selectedCompetitor, value: selectedCompetitor }}
                options={competitorOptions}
              />
            )}
          </div>

          <DateRangePicker
            mondayOnly
            startDate={weeks.start}
            endDate={weeks.end}
            startDateId="competitiveMetricsStartDate"
            endDateId="competitiveMetricsEndDate"
            isOutsideRange={date => date > CUTOFF_DATE}
            onChange={({ startDate, endDate }) => {
              if (startDate && endDate) {
                setWeeks({ start: startDate, end: endDate });
              }
            }}
          />
        </div>
      }
    >
      <div className="competitiveMetricsBody">
        {selectedCompetitor && creativesRawData && costPersRawData ? (
          <>
            <ChartsContainer
              creativesRawData={creativesRawData}
              costPersRawData={costPersRawData}
            />
            <div className="expand-buttons">
              <div className="show-hide-element">
                <ToggleButtonGroup
                  type="radio"
                  name="showSpendOrSpotsToggle"
                  value={expandByMap.spots ? SHOW_SPOTS_LABEL : SHOW_ESTIMATED_SPEND_LABEL}
                  onChange={val => {
                    setExpandByValue("spots", val === SHOW_SPOTS_LABEL);
                  }}
                >
                  {[SHOW_ESTIMATED_SPEND_LABEL, SHOW_SPOTS_LABEL].map(label => (
                    <ToggleButton
                      key={label}
                      variant="primary"
                      className="noOutline"
                      value={label}
                      style={{ fontSize: BUTTON_FONT_SIZE }}
                    >
                      {label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
              {["dayPart", "network", "length"].map(key => (
                <ExpandButton
                  key={key}
                  label={EXPAND_LABELS[key]}
                  onChange={val => setExpandByValue(key, val)}
                  checked={expandByMap[key]}
                />
              ))}
              <Button onClick={() => downloadCsv()}>
                <MdOutlineFileDownload />
              </Button>
            </div>
            <MetricsTableContainer
              costPersRawData={costPersRawData}
              weekNames={validDays}
              expandBy={expandByMap}
              setCsv={setCsv}
            />
          </>
        ) : (
          <Spinner size={100} />
        )}
      </div>
    </Page>
  );
};

export default CompetitiveMetrics;
