import React, { useState, useContext, useEffect, useMemo } from "react";

import * as R from "ramda";

import ContentLoader from "react-content-loader";
import AutoSizer from "react-virtualized-auto-sizer";

const SkeletonContext = React.createContext({
  width: 0,
  height: 0,
});

interface Rect {
  x: string;
  y: string;
  width: string;
  height: string;
}

export const Skeleton: React.FC<{ redesign?: boolean }> = ({ children, redesign }) => (
  <AutoSizer>
    {({ width, height }) => (
      <ContentLoader
        interval={0}
        preserveAspectRatio="none"
        {...(redesign
          ? {
              backgroundColor: "#d9d9d9",
              foregroundColor: "#cccccc",
            }
          : {})}
        style={{ width, height }}
        width={width}
        height={height}
      >
        <SkeletonContext.Provider value={{ width, height }}>{children}</SkeletonContext.Provider>
      </ContentLoader>
    )}
  </AutoSizer>
);

const DEFAULT_AXIS_SIZE = 8;

export const AxesSkeleton: React.FC<{ size?: number }> = ({
  size = DEFAULT_AXIS_SIZE,
}): JSX.Element => (
  <>
    <rect x={0} y={0} width={`${size}px`} height="100%" />
    <rect x={0} y={`calc(100% - ${size}px)`} width="100%" height={`${size}px`} />
  </>
);

const DEFAULT_BAR_WIDTH = 20;

interface BarGraphSkeletonProps {
  barWidth?: number;
  gutter?: number;
  horizontalPadding?: number;
  verticalPadding?: number;
}

// TODO: make this have no gutter on the outside
export const BarGraphSkeleton = React.memo<BarGraphSkeletonProps>(
  ({
    barWidth = DEFAULT_BAR_WIDTH,
    gutter = DEFAULT_BAR_WIDTH,
    horizontalPadding = 0,
    verticalPadding = 0,
  }) => {
    const { width, height } = useContext(SkeletonContext);
    const availableWidth = width - gutter - horizontalPadding * 2;
    const numBars = Math.floor(availableWidth / (gutter + barWidth));

    const [barHeightRatios, setBarHeightRatios] = useState<number[]>(() =>
      R.pipe<number, number[], number[]>(
        R.range(0),
        R.map(() => 0.25 + Math.random() * 0.75)
      )(numBars)
    );

    // Keep the old random height ratios so we don't make everything rerender with random heights
    // every time the dimensions change. When the width changes if we need new bars, just add them.
    useEffect(() => {
      if (numBars > barHeightRatios.length) {
        R.pipe<number[], number, number, number[], number[], number[], void>(
          R.length,
          R.subtract(numBars),
          R.range(0),
          R.map(() => 0.25 + Math.random() * 0.75),
          R.concat(barHeightRatios),
          setBarHeightRatios
        )(barHeightRatios);
      }
    }, [numBars, barHeightRatios, setBarHeightRatios]);

    const barData = useMemo(() => {
      const extra = Math.floor((availableWidth % (barWidth + gutter)) / 2);
      let x = extra + gutter + horizontalPadding;

      let totalHeight = height - verticalPadding * 2;

      let bars: Rect[] = [];
      for (let i = 0; i < numBars; ++i) {
        let barHeight = (barHeightRatios[i] || 0) * totalHeight;
        bars.push({
          x: `${x}px`,
          y: `${verticalPadding + totalHeight - barHeight}px`,
          width: `${barWidth}px`,
          height: `${barHeight}px`,
        });
        x += barWidth + gutter;
      }
      return bars;
    }, [
      height,
      horizontalPadding,
      verticalPadding,
      gutter,
      barWidth,
      numBars,
      barHeightRatios,
      availableWidth,
    ]);

    return (
      <>
        {barData.map((props, i) => (
          <rect key={i} {...props} />
        ))}
      </>
    );
  }
);

const DEFAULT_ITEM_SIZE = 50;

interface ItemListSkeletonProps {
  size?: number;
  gutter?: number;
  horizontalPadding?: number;
  verticalPadding?: number;
}

export const ItemListSkeleton = React.memo<ItemListSkeletonProps>(
  ({
    size = DEFAULT_ITEM_SIZE,
    gutter = DEFAULT_ITEM_SIZE,
    horizontalPadding = 0,
    verticalPadding = 0,
  }) => {
    const { width } = useContext(SkeletonContext);
    const availableWidth = width - gutter - horizontalPadding * 2;
    const numItems = Math.floor(availableWidth / (gutter + size));

    const itemData = useMemo(() => {
      const extra = Math.floor((availableWidth % (size + gutter)) / 2);
      let x = extra + gutter + horizontalPadding;

      let items: Rect[] = [];
      for (let i = 0; i < numItems; ++i) {
        items.push({
          x: `${x}px`,
          y: `${verticalPadding}px`,
          width: `${size}px`,
          height: `${size}px`,
        });
        x += size + gutter;
      }
      return items;
    }, [size, gutter, horizontalPadding, verticalPadding, availableWidth, numItems]);

    return (
      <>
        {itemData.map((props, i) => (
          <rect key={i} {...props} />
        ))}
      </>
    );
  }
);

const DEFAULT_LINE_HEIGHT = 10;

interface DimensionGetter {
  (dim: number): number;
}
interface ListSkeletonProps {
  lineHeight?: number;
  lineSpacing?: number;
  horizontalPadding?: number;
  verticalPadding?: number;
  borderRadius?: number;
  alignTop?: boolean;
  width?: number | DimensionGetter;
  height?: number | DimensionGetter;
  x?: number | DimensionGetter;
  y?: number | DimensionGetter;
}

export const ListSkeleton = React.memo<ListSkeletonProps>(
  ({
    lineHeight = DEFAULT_LINE_HEIGHT,
    lineSpacing = DEFAULT_LINE_HEIGHT,
    horizontalPadding = 0,
    verticalPadding = 0,
    borderRadius = lineHeight / 2,
    alignTop = false,
    width: widthProp,
    height: heightProp,
    x = 0,
    y = 0,
  }) => {
    const { width, height } = useContext(SkeletonContext);

    let ourWidth = width;
    if (!R.isNil(widthProp)) {
      ourWidth = widthProp instanceof Function ? widthProp(width) : widthProp;
    }

    let ourHeight = height;
    if (!R.isNil(heightProp)) {
      ourHeight = heightProp instanceof Function ? heightProp(height) : heightProp;
    }
    let ourX = x instanceof Function ? x(width) : x;
    let ourY = y instanceof Function ? y(height) : y;

    const availableHeight = ourHeight - verticalPadding * 2 - lineSpacing;
    const numItems = Math.floor(availableHeight / (lineHeight + lineSpacing));

    const itemData = useMemo(() => {
      const extra = Math.floor((availableHeight % (lineHeight + lineSpacing)) / 2);
      let y = ourY + (alignTop ? 0 : extra + lineSpacing) + verticalPadding;

      let items: { y: string }[] = [];
      for (let i = 0; i < numItems; ++i) {
        items.push({
          y: `${y}px`,
        });
        y += lineHeight + lineSpacing;
      }
      return items;
    }, [availableHeight, lineSpacing, verticalPadding, lineHeight, numItems, alignTop, ourY]);

    return (
      <>
        {itemData.map((props, i) => (
          <rect
            key={i}
            {...props}
            rx={borderRadius}
            ry={borderRadius}
            x={`${horizontalPadding + ourX}px`}
            width={`${ourWidth - horizontalPadding * 2}px`}
            height={`${lineHeight}px`}
          />
        ))}
      </>
    );
  }
);

export const ListGroupSkeleton = React.memo<ListSkeletonProps>(props => {
  const passProps = {
    lineHeight: 49,
    alignTop: true,
    borderRadius: 5,
    lineSpacing: 4,
    ...props,
  };
  return <ListSkeleton {...passProps} />;
});

export const FILTER_BAR_SKELETON_HEIGHT = 38;
const FILTER_BAR_FILTER_BUTTON_WIDTH = 65;
const FILTER_BAR_BASIC_BUTTON_WIDTH = 100;
const FILTER_BAR_BORDER_RADIUS = 2;
const FILTER_BAR_INTERNAL_GUTTER = 8;

interface FilterBarSkeletonProps {
  width?: number | DimensionGetter;
  height?: number | DimensionGetter;
  x?: number | DimensionGetter;
  y?: number | DimensionGetter;
  margin?: number;
}

export const FilterBarSkeleton = React.memo<FilterBarSkeletonProps>(
  ({ width: widthProp, x = 0, y = 0, margin }) => {
    const { width, height } = useContext(SkeletonContext);
    let ourWidth: number = width;
    if (!R.isNil(widthProp)) {
      ourWidth = widthProp instanceof Function ? widthProp(width) : widthProp || 0;
    }

    let ourX = x instanceof Function ? x(width) : x;
    let ourY = y instanceof Function ? y(height) : y;

    if (margin) {
      ourWidth -= margin * 2;
      ourX += margin;
      ourY += margin;
    }
    let filterBarWidth =
      ourWidth -
      FILTER_BAR_INTERNAL_GUTTER * 2 -
      FILTER_BAR_FILTER_BUTTON_WIDTH -
      FILTER_BAR_BASIC_BUTTON_WIDTH;
    return (
      <>
        <rect
          x={`${ourX}px`}
          y={`${ourY}px`}
          width={`${filterBarWidth}px`}
          height={`${FILTER_BAR_SKELETON_HEIGHT}px`}
          ry={FILTER_BAR_BORDER_RADIUS}
          rx={FILTER_BAR_BORDER_RADIUS}
        />
        <rect
          x={`${ourX + filterBarWidth + FILTER_BAR_INTERNAL_GUTTER}px`}
          y={`${ourY}px`}
          width={`${FILTER_BAR_FILTER_BUTTON_WIDTH}px`}
          height={`${FILTER_BAR_SKELETON_HEIGHT}px`}
          ry={FILTER_BAR_BORDER_RADIUS}
          rx={FILTER_BAR_BORDER_RADIUS}
        />
        <rect
          x={`${
            ourX + filterBarWidth + FILTER_BAR_INTERNAL_GUTTER * 2 + FILTER_BAR_FILTER_BUTTON_WIDTH
          }px`}
          y={`${ourY}px`}
          width={`${FILTER_BAR_BASIC_BUTTON_WIDTH}px`}
          height={`${FILTER_BAR_SKELETON_HEIGHT}px`}
          ry={FILTER_BAR_BORDER_RADIUS}
          rx={FILTER_BAR_BORDER_RADIUS}
        />
      </>
    );
  }
);

interface TableSkeletonProps {
  rowHeight?: number;
  headerHeight?: number;
  gutter?: number;
  xOffset?: number;
  yOffset?: number;
  width?: number | DimensionGetter;
  height?: number | DimensionGetter;
}

export const TableSkeleton = React.memo<TableSkeletonProps>(
  // Default BPMTable row and header heights are 50 pixels
  ({
    rowHeight = 50,
    headerHeight = 50,
    gutter = 10,
    xOffset = 0,
    yOffset = 0,
    width: widthProp,
    height: heightProp,
  }) => {
    const { width, height } = useContext(SkeletonContext);

    let ourWidth = width;
    if (!R.isNil(widthProp)) {
      ourWidth = widthProp instanceof Function ? widthProp(width) : width;
    }

    let ourHeight = height;
    if (!R.isNil(heightProp)) {
      ourHeight = heightProp instanceof Function ? heightProp(height) : height;
    }

    const FILTER_BAR_HEIGHT = 40;
    const FILTER_SUBMIT_WIDTH = 300;
    const FILTER_BAR_MARGIN = 5;
    const BORDER_HEIGHT = 5;

    rowHeight -= gutter;
    headerHeight -= gutter / 2 + BORDER_HEIGHT / 2;

    const nonTableHeight = FILTER_BAR_HEIGHT + FILTER_BAR_MARGIN + headerHeight + gutter + yOffset;

    const availableHeight = ourHeight - nonTableHeight;
    const numItems = Math.floor(availableHeight / (rowHeight + gutter));

    const itemData = useMemo(() => {
      let y = nonTableHeight;

      let items: { y: string }[] = [];
      for (let i = 0; i < numItems; ++i) {
        items.push({
          y: `${y}px`,
        });
        y += rowHeight + gutter;
      }
      return items;
    }, [rowHeight, gutter, nonTableHeight, numItems]);

    return (
      <>
        <rect
          y={yOffset}
          x={`${gutter + xOffset}px`}
          width={`${ourWidth - gutter * 3 - FILTER_SUBMIT_WIDTH - xOffset}px`}
          height={`${FILTER_BAR_HEIGHT}px`}
        />
        <rect
          y={yOffset}
          x={`${ourWidth - gutter - FILTER_SUBMIT_WIDTH - xOffset}px`}
          width={`${FILTER_SUBMIT_WIDTH}px`}
          height={`${FILTER_BAR_HEIGHT}px`}
        />
        <rect
          y={`${FILTER_BAR_HEIGHT + FILTER_BAR_MARGIN + yOffset}px`}
          x={`${gutter + xOffset}px`}
          width={`${ourWidth - gutter * 2 - xOffset}px`}
          height={`${headerHeight}px`}
        />
        <rect
          y={`${
            yOffset +
            FILTER_BAR_HEIGHT +
            FILTER_BAR_MARGIN +
            headerHeight +
            gutter / 2 -
            BORDER_HEIGHT / 2
          }px`}
          x={`${gutter + xOffset}px`}
          width={`${ourWidth - gutter * 2 - xOffset}px`}
          height={`${BORDER_HEIGHT}px`}
        />
        {itemData.map((props, i) => (
          <rect
            key={i}
            {...props}
            x={`${gutter + xOffset}px`}
            width={`${ourWidth - gutter * 2 - xOffset}px`}
            height={`${rowHeight}px`}
          />
        ))}
      </>
    );
  }
);

interface RectSkeletonProps {
  width?: number | DimensionGetter;
  height?: number | DimensionGetter;
  x?: number | DimensionGetter;
  y?: number | DimensionGetter;
  padding?: number;
  rx?: number;
  ry?: number;
}

export const RectSkeleton = React.memo<RectSkeletonProps>(
  ({ width: widthProp, height: heightProp, x = 0, y = 0, padding, rx = 0, ry = 0 }) => {
    const { width, height } = useContext(SkeletonContext);
    let ourWidth = width;
    if (!R.isNil(widthProp)) {
      ourWidth = widthProp instanceof Function ? widthProp(width) : widthProp;
    }
    let ourHeight = height;
    if (!R.isNil(heightProp)) {
      ourHeight = heightProp instanceof Function ? heightProp(height) : heightProp;
    }

    let ourX = x instanceof Function ? x(width) : x;
    let ourY = y instanceof Function ? y(height) : y;

    if (padding) {
      ourWidth -= padding * 2;
      ourHeight -= padding * 2;
      ourX += padding;
      ourY += padding;
    }

    return (
      <rect
        x={`${ourX}px`}
        y={`${ourY}px`}
        width={`${ourWidth}px`}
        height={`${ourHeight}px`}
        rx={`${rx}px`}
        ry={`${ry}px`}
      />
    );
  }
);

interface RadiusGetter {
  (width: number, height: number): number;
}
interface CircleSkeletonProps {
  r?: number | RadiusGetter;
  x?: number | DimensionGetter;
  y?: number | DimensionGetter;
  padding?: number;
}

export const CircleSkeleton = React.memo<CircleSkeletonProps>(
  ({ r: rProp, x = 0, y = 0, padding }) => {
    const { width, height } = useContext(SkeletonContext);
    let ourR = Math.min(width, height) / 2;
    if (!R.isNil(rProp)) {
      ourR = rProp instanceof Function ? rProp(width, height) : rProp;
    }

    let ourX = x instanceof Function ? x(width) : x;
    let ourY = y instanceof Function ? y(height) : y;

    if (padding) {
      ourR -= padding * 2;
      ourX += padding;
      ourY += padding;
    }

    return <circle cx={`${ourX + ourR}px`} cy={`${ourY + ourR}px`} r={`${ourR}px`} />;
  }
);

interface PathSkeletonProps {
  points: readonly (readonly [number, number])[];
  width?: number | DimensionGetter;
  height?: number | DimensionGetter;
  x?: number | DimensionGetter;
  y?: number | DimensionGetter;
  thickness?: number;
}

export const PathSkeleton = React.memo<PathSkeletonProps>(
  ({ points, width: widthProp, height: heightProp, x = 0, y = 0, thickness = 5 }) => {
    const { width, height } = useContext(SkeletonContext);
    let ourWidth = width;
    if (!R.isNil(widthProp)) {
      ourWidth = widthProp instanceof Function ? widthProp(width) : widthProp;
    }
    let ourHeight = height;
    if (!R.isNil(heightProp)) {
      ourHeight = heightProp instanceof Function ? heightProp(height) : heightProp;
    }

    let ourX = x instanceof Function ? x(width) : x;
    let ourY = y instanceof Function ? y(height) : y;

    const paths = useMemo(() => {
      let paths: string[] = [];
      let lastX = points[0][0];
      let lastY = points[0][1];
      for (let i = 1; i < points.length; ++i) {
        let [nextX, nextY] = points[i];
        let x1 = lastX * ourWidth + ourX;
        let x2 = nextX * ourWidth + ourX;
        let y1 = (1 - lastY) * ourHeight + ourY;
        let y2 = (1 - nextY) * ourHeight + ourY;
        paths.push(`${x1}, ${y1} ${x2}, ${y2} ${x2}, ${y2 + thickness} ${x1}, ${y1 + thickness} `);
        lastX = nextX;
        lastY = nextY;
      }
      return paths;
    }, [points, thickness, ourWidth, ourHeight, ourX, ourY]);

    return (
      <>
        {paths.map(path => (
          <polyline key={path} points={path} />
        ))}
      </>
    );
  }
);
