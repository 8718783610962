import React, { useMemo, useState, useRef } from "react";

import ReactDOM from "react-dom";

import * as R from "ramda";

import AutoSizer from "react-virtualized-auto-sizer";

import "./PieCharts.scss";

const UNALLOCATED_COLOR = "#bbbbbb";

const TWO_PI = Math.PI * 2;

const STROKE_WIDTH = 1;
const RADIUS = 75;

const ORIGIN = [RADIUS, 0];

const TOOLTIP_RADIUS_RATIO = 0.75;
let ratio;

const Tooltip = React.memo(({ children, x, y }) =>
  ReactDOM.createPortal(
    <div className="pieChartHoverTooltipPortal" style={{ left: x, top: y }}>
      {children}
    </div>,
    document.body
  )
);

// Takes a size and data. The data is a set of objects that have "color", "name", and "value" properties. "name" is used
// for tooltip
export const PieChart = React.memo(({ size, data, tooltip }) => {
  let correctedSize = size - (size % 2);
  const ref = useRef();
  const pieData = useMemo(() => {
    let totalValue = R.pipe(R.pluck("value"), R.sum)(data);
    let angle = 0;
    let lastCoord = ORIGIN;
    let pieData = [];
    for (let datum of data) {
      ratio = datum.value / totalValue;
      let newAngle = ratio * TWO_PI;
      let midAngle = angle + newAngle / 2;

      angle += newAngle;

      let x = Math.cos(angle) * RADIUS;
      let y = Math.sin(angle) * RADIUS;

      let end = [x, y];

      pieData.push({
        ...datum,
        startingAngle: angle,
        angle: newAngle,
        start: lastCoord,
        end,
        ratio,
        // We need to convert from the SVG's inner coordinate plane to the outer. Take the angle that's half the arc.
        // Find the cartesian coordinates of the point at that angle with r = our desired tooltip radius. Our origin is
        // in the center, but the outer coordinate system has the origin in the upper left. By adding 1 we're going from
        // a -1 -> 1 range to 0 -> 2 range. Divide by 2 to get a 0 -> 1 range. Multiply by the size of the outer
        // container to get the position of the point in the outer coordinate system.
        tooltipX: (correctedSize * (1 + TOOLTIP_RADIUS_RATIO * Math.cos(midAngle))) / 2,
        tooltipY: (correctedSize * (1 + TOOLTIP_RADIUS_RATIO * Math.sin(midAngle))) / 2,
      });
      lastCoord = end;
    }
    return pieData;
  }, [data, correctedSize]);

  const [tooltipInfo, setTooltipInfo] = useState();

  if (correctedSize <= 0) {
    return null;
  }
  if (ratio === 1) {
    return (
      <div
        className="BPMPieChart"
        style={{ height: correctedSize, width: correctedSize }}
        ref={ref}
      >
        <svg
          height={correctedSize}
          width={correctedSize}
          viewBox={`${-RADIUS} ${-RADIUS} ${RADIUS * 2} ${RADIUS * 2}`}
        >
          {pieData.map(
            ({ startingAngle, angle, name, color, start, tooltipX, tooltipY, value }) => (
              <path
                key={name + startingAngle}
                onMouseEnter={() => {
                  const rect = ref.current.getBoundingClientRect();
                  setTooltipInfo({
                    children: `${name}: ${value}`,
                    x: rect.x + tooltipX,
                    y: rect.y + tooltipY,
                  });
                }}
                onMouseLeave={() => {
                  setTooltipInfo();
                }}
                stroke="white"
                fill={color}
                strokeWidth={STROKE_WIDTH}
                d={`M ${start[0]} ${start[1]}
                  A ${RADIUS} ${RADIUS} 0 ${angle > Math.PI ? "1" : "0"} 1 ${-start[0]} ${start[1]}
                  A ${RADIUS} ${RADIUS} 0 ${angle > Math.PI ? "1" : "0"} 1 ${start[0]} ${
                  start[1]
                }Z`}
              />
            )
          )}
        </svg>
        {tooltip && tooltipInfo && <Tooltip {...tooltipInfo} />}
      </div>
    );
  }
  return (
    <div className="BPMPieChart" style={{ height: correctedSize, width: correctedSize }} ref={ref}>
      <svg
        height={correctedSize}
        width={correctedSize}
        viewBox={`${-RADIUS} ${-RADIUS} ${RADIUS * 2} ${RADIUS * 2}`}
      >
        {pieData.map(
          ({ angle, startingAngle, name, color, start, end, tooltipX, tooltipY, value }) => (
            <path
              key={name + startingAngle}
              onMouseEnter={() => {
                const rect = ref.current.getBoundingClientRect();
                setTooltipInfo({
                  children: `${name}: ${value}`,
                  x: rect.x + tooltipX,
                  y: rect.y + tooltipY,
                });
              }}
              onMouseLeave={() => {
                setTooltipInfo();
              }}
              stroke="white"
              fill={color}
              strokeWidth={STROKE_WIDTH}
              d={`M 0 0 L ${start[0]} ${start[1]} A ${RADIUS} ${RADIUS} 0 ${
                angle > Math.PI ? "1" : "0"
              } 1 ${end[0]} ${end[1]} Z`}
            />
          )
        )}
      </svg>
      {tooltip && tooltipInfo && <Tooltip {...tooltipInfo} />}
    </div>
  );
});

export const PieSetter = ({ data, pieFlex = 1, renderItem, percentage = false }) => {
  return (
    <div className="piePicker">
      <div className="pieChart" style={{ flex: pieFlex }}>
        <AutoSizer>
          {({ height, width }) => {
            let size = Math.min(height, width);
            let ourData = R.filter(({ value }) => value > 0)(data);
            if (percentage) {
              let value = Math.max(0, 100 - R.sum(R.pluck("value", data)));
              if (value) {
                ourData.unshift({
                  name: "Unallocated",
                  color: UNALLOCATED_COLOR,
                  value,
                });
              }
            }
            return (
              <div key="container" className="pieContainer" style={{ height, width }}>
                <PieChart tooltip size={size} data={ourData} />
              </div>
            );
          }}
        </AutoSizer>
      </div>
      <div className="pickerOptions">
        {data.length ? (
          <div className="pickerOptionContainer">
            {data.map(item => (
              <div key={item.key} className="pickerItem">
                {renderItem(item)}
              </div>
            ))}
          </div>
        ) : (
          <div className="noCreativesMessage">No creative choices available.</div>
        )}
      </div>
    </div>
  );
};
