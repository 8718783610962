import "./ReferenceView.scss";
import { ListGroup } from "react-bootstrap";
import { MdPictureAsPdf } from "react-icons/md";
import { Page } from "../Components";
import React from "react";

const ReferenceView = () => {
  return (
    <Page title="Reference Documents" pageType="Reference Documents">
      <ListGroup className="referenceList">
        <ListGroup.Item className="listItem">
          <div class="section">Data</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://cdn.blisspointmedia.com/reference/BPM Client Reporting.pdf"}
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Reporting</div>
          <div className="description">
            BPM's reporting capabilities and setup, along with samples.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://cdn.blisspointmedia.com/reference/BPM Data Onboarding Overview.pdf"}
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Onboarding</div>
          <div className="description">
            How BPM receives data from our clients and data requirements.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Bliss Point Pixel Installation Guide.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Pixel Implementation</div>
          <div className="description">How to implement the BPM pixel for attribution.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div class="section">Creative</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - TVAD Creative Best Practices.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Best Practices</div>
          <div className="description">Branded response best practices along with examples.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Streaming S&P Guidelines and Regulations.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">S&amp;P Guidelines</div>
          <div className="description">S&amp;P Guidelines</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn.blisspointmedia.com/reference/ExtremeReachMasterFileSpecifications.pdf"
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Video Specs</div>
          <div className="description">Specs/requirements for streaming and linear creatives.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn.blisspointmedia.com/reference/AudioSpecs.pdf"
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Audio Specs</div>
          <div className="description">Specs/requirements for audio creatives.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div class="section">Media</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://cdn.blisspointmedia.com/reference/Tinuiti - Podcast Advertising.pdf"}
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Podcast Advertising</div>
          <div className="description">Podcast partners/inventory, capabilities, and FAQ.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Streaming Ecosystem & Inventory.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Streaming Ecosystem</div>
          <div className="description">
            Overview of the streaming landscape and some of our media partners.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Streaming Ad Fraud Prevention.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Ad Fraud Protection</div>
          <div className="description">
            The protective measures we put in place against ad fraud.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div class="section">Attribution</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://cdn.blisspointmedia.com/reference/Tinuiti - Streaming Attribution.pdf"}
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Streaming Attribution with Sample</div>
          <div className="description">
            High level look at how we adjust for irresolvable identities in our streaming
            attribution.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Linear TV Analytic Approach and Measurement.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Linear Attribution</div>
          <div className="description">
            High level look at our linear attribution methodologies.
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/Tinuiti - Bliss Point - Fractional Attribution.pdf"
              }
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Fractional Attribution</div>
          <div className="description">An overview of our approach to multi-touch attribution.</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div class="section">Glossary of Terms</div>
        </ListGroup.Item>
        <ListGroup.Item className="listItem">
          <div className="pdf">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn.blisspointmedia.com/reference/BPM_PPM Media Update_ Glossary of Terms.pdf"
            >
              <MdPictureAsPdf />
            </a>
          </div>
          <div className="title">Glossary of Terms</div>
          <div className="description">
            Commonly used terms and acronyms in the video/audio space.
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Page>
  );
};

export default ReferenceView;
