import React from "react";
import "./ChartContainer.scss";
import { InfoTooltip } from "../InfoTooltip";

interface ChartContainerProps {
  title: string | React.ReactNode;
  titleAfterDashText?: string;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  subHeader?: React.ReactNode;
  enableHoverDesign?: boolean;
  height?: number;
  tooltipText?: string;
  beforeTooltipText?: string;
  rightSideText?: string;
  footerContent?: string | React.ReactNode;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  titleAfterDashText,
  leftActions,
  rightActions,
  subHeader,
  enableHoverDesign,
  height,
  tooltipText,
  beforeTooltipText,
  children,
  rightSideText,
  footerContent,
}) => {
  return (
    <div
      className={enableHoverDesign ? "chartContainer enableHoverDesign" : "chartContainer"}
      style={{ height }}
    >
      <div className="header">
        <div className="mainHeader">
          <div className="leftSide">
            <div className="headerTitle">
              {title}
              {titleAfterDashText && <span className="headerTitleDash">|</span>}
              {titleAfterDashText && (
                <span className="headerTitleDateRange">{titleAfterDashText}</span>
              )}
            </div>
            <div className="leftActions">{leftActions}</div>
          </div>
          <div className="rightSide">{rightActions}</div>
        </div>
        <div className="subHeader">{subHeader}</div>
      </div>
      <div className="toolTipTextContainer">
        {tooltipText && beforeTooltipText && (
          <div className="leftSideToolTip">
            <div>{beforeTooltipText}</div>
            <InfoTooltip>{tooltipText}</InfoTooltip>
          </div>
        )}
        {!tooltipText && beforeTooltipText && (
          <div className="leftSideToolTip">{beforeTooltipText}</div>
        )}
        {rightSideText && <div className="rightSideToolTip">{rightSideText}</div>}
      </div>
      <div className="contents">{children}</div>
      {footerContent && (
        <div className="chartContainerFooterContainer">
          <div className="chartContainerFooter">{footerContent}</div>
        </div>
      )}
    </div>
  );
};

export default ChartContainer;
