import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BPMButton, Spinner } from "./";
import { BPMFileInput } from "./BPMFileInput/BPMFileInput";

interface ImportModalProps {
  file: any;
  setFile: (file) => void;
  importFunction: () => void;
  onClose: () => void;
}

export const ImportModal: React.FC<ImportModalProps> = ({
  file,
  importFunction,
  onClose,
  setFile,
}) => {
  const [importing, setImporting] = useState<boolean>(false);

  const modalImport = () => {
    setImporting(true);
    importFunction();
    setImporting(false);
    onClose();
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import Changes (from Excel)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="importModalBody">
          <BPMFileInput
            className="file-input"
            name="myfile"
            id="file_input_id"
            onChange={e => {
              const uploadedFile = e.target.files;
              const file = uploadedFile?.[0];
              setFile(file);
            }}
          />
          <div>{file && file.name ? file.name : "No File Selected"}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton disabled={!file} variant="primary" onClick={modalImport}>
          {importing ? <Spinner /> : "Import"}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};
