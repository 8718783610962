import { CreativeMap, CreativeMapItem } from "../redux/creative";
import { DerivedNetworkMap } from "../redux/networks";
import StreamingPlans, { StreamingPlansSlideState } from "./StreamingPlans";
import TitleSlide, { TitleSlideState } from "./Title";
import StreamingDelivery, {
  strDelivSharedStateKey,
  StreamingDeliverySharedState,
  strDelivSharedFetcher,
  StreamingDeliverySlideState,
} from "./StreamingDelivery";
import LinearDelivery, {
  linDelivSharedStateKey,
  LinearDeliverySharedState,
  linDelivSharedFetcher,
  LinearDeliverySlideState,
} from "./LinearDelivery";
import YoutubeDelivery, {
  youtubeDelivSharedStateKey,
  YoutubeDeliverySharedState,
  youtubeDelivSharedFetcher,
  YoutubeDeliverySlideState,
} from "./YouTubeDelivery";
import Housekeeping, { HousekeepingSlideState } from "./Housekeeping";
import LinearPlans, { LinearPlansSlideState } from "./LinearPlans";
import StreamingGeo, {
  StreamingGeoLagsSharedState,
  StreamingGeoSlideState,
  strGeoLagsSharedFetcher,
  strGeoLagsSharedStateKey,
} from "./StreamingGeo";
import CompetitiveLandscape, {
  competitiveLandscapeSharedFetcher,
  CompetitiveLandscapeSharedState,
  competitiveLandscapeSharedStateKey,
  CompetitiveLandscapeSlideState,
} from "./CompetitiveLandscape";
import ExecutiveSummary, { ExecutiveSummarySlideState } from "./ExecutiveSummary";
import NotableAirings, { NotableAiringsSlideState } from "./NotableAirings";
import FunnelDynamics, { FunnelDynamicsSlideState } from "./FunnelDynamics";
import BonusAndCreditSummary, { BonusAndCreditSummarySlideState } from "./BonusAndCreditSummary";
import StreamingPerformanceNew, {
  StreamingPerformanceNewSlideState,
  StreamingPerformanceSharedState,
  strPerformanceNewSharedStateKey,
  strPerformanceSharedFetcher,
} from "./StreamingPerformanceNew";
import LinearPerformanceNew, {
  linearPerformanceNewSharedFetcher,
  LinearPerformanceNewSharedState,
  linearPerformanceNewSharedStateKey,
  LinearPerformanceNewSlideState,
} from "./LinearPerformanceNew";
import YoutubePerformance, {
  YoutubePerformanceSlideState,
  YoutubePerformanceSharedState,
  youtubePerformanceSharedStateKey,
  youtubePerformanceSharedFetcher,
} from "./YouTubePerformance";
// import SitewideStatsSlide, { SitewideStatsSlideState } from "./SitewideStats";
// import SitewideDataSlide, { SitewideDataSlideState } from "./SitewideData";
import PaidMediaBreakdownSlide, { PaidMediaBreakdownSlideState } from "./PaidMediaBreakdown";
import MarketplaceReportSummarySlide, {
  MarketplaceReportSummarySlideState,
} from "./TinuitiMarketplaceReportSlides/Summary";
import MarketplaceReportCampaignsSlide, {
  MarketplaceReportCampaignsSlideState,
} from "./TinuitiMarketplaceReportSlides/CommerceCampaigns";
import MarketplaceReportProductTargetingSlide, {
  MarketplaceReportProductTargetingSlideState,
} from "./TinuitiMarketplaceReportSlides/ProductTargeting";
import MarketplaceReportKeywordsSlide, {
  MarketplaceReportKeywordsSlideState,
} from "./TinuitiMarketplaceReportSlides/Keywords";
import MarketplaceReportDspSlide, {
  MarketplaceReportDspSlideState,
} from "./TinuitiMarketplaceReportSlides/Dsp";
import MarketplaceReportBiggestMoverSlide, {
  MarketplaceReportBiggestMoverSlideState,
} from "./TinuitiMarketplaceReportSlides/BiggestMovers";
import MarketplaceReportSeasonalitySlide, {
  MarketplaceReportSeasonalitySlideState,
} from "./TinuitiMarketplaceReportSlides/Seasonality";
import MarketplaceReportFreeFormSlide, {
  MarketplaceReportFreeFormSlideState,
} from "./TinuitiMarketplaceReportSlides/FreeForm";
import MarketplaceReportCategorySlide, {
  MarketplaceReportCategorySlideState,
} from "./TinuitiMarketplaceReportSlides/Category";
// import ImpressionShareSlide, { ImpressionShareSlideState } from "./ImpressionShare";
import OpportunityBannerSlide, { OpportunityBannerSlideState } from "./OpportunityBanner";
import { MetricsPagePreset } from "@blisspointmedia/bpm-types/dist/MetricsPage";
import { PresetIDGroups as SimpleMetricsTablePreset } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import SingleChannelMetricsTableSlide, {
  SingleChannelMetricsTableSlideState,
  SingleChannelMetricsTableSharedState,
  singleChannelMetricsTableSharedStateKey,
  singleChannelMetricsTableSharedFetcher,
} from "./SingleChannelMetricsTableSlide";
import { CompanyInfo } from "../redux/company";

const SLIDE_TYPES = [
  TitleSlide,
  PaidMediaBreakdownSlide,
  MarketplaceReportSummarySlide,
  MarketplaceReportCampaignsSlide,
  MarketplaceReportProductTargetingSlide,
  MarketplaceReportKeywordsSlide,
  MarketplaceReportDspSlide,
  MarketplaceReportBiggestMoverSlide,
  MarketplaceReportSeasonalitySlide,
  MarketplaceReportFreeFormSlide,
  MarketplaceReportCategorySlide,
  // SitewideStatsSlide,
  // SitewideDataSlide,
  // ImpressionShareSlide,
  OpportunityBannerSlide,
  LinearPlans,
  StreamingDelivery,
  LinearDelivery,
  YoutubeDelivery,
  StreamingGeo,
  StreamingPlans,
  Housekeeping,
  CompetitiveLandscape,
  ExecutiveSummary,
  NotableAirings,
  FunnelDynamics,
  BonusAndCreditSummary,
  StreamingPerformanceNew,
  LinearPerformanceNew,
  YoutubePerformance,
  SingleChannelMetricsTableSlide,
] as const;

export const SLIDE_TYPE_MAP: Record<string, typeof SLIDE_TYPES[number]> = SLIDE_TYPES.reduce(
  (map, type) => ({
    ...map,
    [type.typeKey]: type,
  }),
  {}
);

export interface SharedState {
  [competitiveLandscapeSharedStateKey]: Record<string, CompetitiveLandscapeSharedState>;
  [linDelivSharedStateKey]: Record<string, LinearDeliverySharedState>;
  [linearPerformanceNewSharedStateKey]: Record<string, LinearPerformanceNewSharedState>;
  [strDelivSharedStateKey]: Record<string, StreamingDeliverySharedState>;
  [youtubeDelivSharedStateKey]: Record<string, YoutubeDeliverySharedState>;
  [strGeoLagsSharedStateKey]: Record<string, StreamingGeoLagsSharedState>;
  [strPerformanceNewSharedStateKey]: Record<string, StreamingPerformanceSharedState>;
  [youtubePerformanceSharedStateKey]: Record<string, YoutubePerformanceSharedState>;
  [singleChannelMetricsTableSharedStateKey]: Record<string, SingleChannelMetricsTableSharedState>;
  companyInfo: CompanyInfo | undefined;
  derivedNetworkMap: DerivedNetworkMap | undefined;
  creativeMap: CreativeMap | undefined;
  creativeNameMap: Record<string, CreativeMapItem> | undefined;
  isGraph: {
    audio: boolean | undefined;
    display: boolean | undefined;
    streaming: boolean | undefined;
  };
  globalBrand:
    | {
        globalBrand: string;
      }
    | undefined;
  pagePresets: Record<string, MetricsPagePreset[] | "fetching"> | undefined;
  tablePresets: Record<string, SimpleMetricsTablePreset[] | undefined | "fetching"> | undefined;
}

export const SHARED_FETCHER_MAP = {
  [competitiveLandscapeSharedStateKey]: competitiveLandscapeSharedFetcher,
  [linDelivSharedStateKey]: linDelivSharedFetcher,
  [linearPerformanceNewSharedStateKey]: linearPerformanceNewSharedFetcher,
  [strDelivSharedStateKey]: strDelivSharedFetcher,
  [youtubeDelivSharedStateKey]: youtubeDelivSharedFetcher,
  [strGeoLagsSharedStateKey]: strGeoLagsSharedFetcher,
  [strPerformanceNewSharedStateKey]: strPerformanceSharedFetcher,
  [youtubePerformanceSharedStateKey]: youtubePerformanceSharedFetcher,
  [singleChannelMetricsTableSharedStateKey]: singleChannelMetricsTableSharedFetcher,
};

export const INIT_SHARED_STATE = {
  [competitiveLandscapeSharedStateKey]: {},
  [linDelivSharedStateKey]: {},
  [linearPerformanceNewSharedStateKey]: {},
  [strDelivSharedStateKey]: {},
  [youtubeDelivSharedStateKey]: {},
  [strGeoLagsSharedStateKey]: {},
  [strPerformanceNewSharedStateKey]: {},
  [youtubePerformanceSharedStateKey]: {},
  [singleChannelMetricsTableSharedStateKey]: {},
  companyInfo: undefined,
  derivedNetworkMap: undefined,
  creativeMap: undefined,
  creativeNameMap: undefined,
  isGraph: {
    audio: false,
    display: false,
    streaming: false,
  },
  globalBrand: undefined,
  pagePresets: undefined,
  tablePresets: undefined,
};

export type SlideState =
  | TitleSlideState
  | PaidMediaBreakdownSlideState
  | MarketplaceReportSummarySlideState
  | MarketplaceReportCampaignsSlideState
  | MarketplaceReportProductTargetingSlideState
  | MarketplaceReportKeywordsSlideState
  | MarketplaceReportDspSlideState
  | MarketplaceReportBiggestMoverSlideState
  | MarketplaceReportSeasonalitySlideState
  | MarketplaceReportFreeFormSlideState
  // | SitewideStatsSlideState
  // | SitewideDataSlideState
  // | ImpressionShareSlideState
  | OpportunityBannerSlideState
  | StreamingDeliverySlideState
  | LinearDeliverySlideState
  | YoutubeDeliverySlideState
  | LinearPlansSlideState
  | StreamingPlansSlideState
  | HousekeepingSlideState
  | StreamingGeoSlideState
  | CompetitiveLandscapeSlideState
  | ExecutiveSummarySlideState
  | NotableAiringsSlideState
  | FunnelDynamicsSlideState
  | BonusAndCreditSummarySlideState
  | StreamingPerformanceNewSlideState
  | LinearPerformanceNewSlideState
  | YoutubePerformanceSlideState
  | SingleChannelMetricsTableSlideState;

export interface SlideTemplate<S extends SlideState = SlideState> {
  type: string;
  name: string;
  caption?: string;
  state: Partial<S>;
}

const TITLE_SLIDE_TEMPLATE: SlideTemplate<TitleSlideState> = {
  type: "title",
  name: "Title",
  caption: "Cover slide with big image.",
  state: {},
};

const PAID_MEDIA_BREAKDOWN_SLIDE_TEMPLATE: SlideTemplate<PaidMediaBreakdownSlideState> = {
  type: "PaidMediaBreakdown",
  name: "Paid Media Breakdown",
  caption: "Table displaying metrics categorized by month or channel",
  state: {},
};

const MARKETPLACE_REPORT_SUMMARY_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportSummarySlideState> = {
  type: "TmrSummarySlide",
  name: "TMR Summary",
  caption: "The Marketing Report Summary Slide",
  state: {},
};

const MARKETPLACE_REPORT_CAMPAIGNS_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportCampaignsSlideState> = {
  type: "TmrCampaignsSlide",
  name: "TMR Campaigns",
  caption: "The Marketing Report Campaigns Slide",
  state: {},
};
const MARKETPLACE_REPORT_KEYWORDS_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportKeywordsSlideState> = {
  type: "TmrKeywordsSlide",
  name: "TMR Keywords",
  caption: "The Marketing Report Keywords Slide",
  state: {},
};

const MARKETPLACE_REPORT_PRODUCT_TARGETING_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportProductTargetingSlideState> = {
  type: "TmrProductTargetingSlide",
  name: "TMR Product Targeting",
  caption: "The Marketing Report Product Targeting Slide",
  state: {},
};

const MARKETPLACE_REPORT_DSP_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportDspSlideState> = {
  type: "TmrDspSlide",
  name: "TMR DSP",
  caption: "The Marketing Report DSP Slide",
  state: {},
};

const MARKETPLACE_REPORT_BIGGEST_MOVER_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportBiggestMoverSlideState> = {
  type: "TmrBiggestMoverSlide",
  name: "TMR Biggest Mover",
  caption: "The Marketing Report Biggest Movers Slide",
  state: {},
};

const MARKETPLACE_REPORT_SEASONALITY_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportSeasonalitySlideState> = {
  type: "TmrSeasonalitySlide",
  name: "TMR Seasonality",
  caption: "The Marketing Report Seasonality Slide",
  state: {},
};
const MARKETPLACE_REPORT_FREE_FORM_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportFreeFormSlideState> = {
  type: "TmrFreeFormSlide",
  name: "TMR Free Form",
  caption: "The Marketing Report Free Form Slide",
  state: {},
};

const MARKETPLACE_REPORT_CATEGORY_SLIDE_TEMPLATE: SlideTemplate<MarketplaceReportCategorySlideState> = {
  type: "TmrCategorySlide",
  name: "TMR Product Category",
  caption: "The Marketing Report Product Category Slide",
  state: {},
};

// const SITEWIDE_STATS_SLIDE_TEMPLATE: SlideTemplate<SitewideStatsSlideState> = {
//   type: "SitewideStats",
//   name: "Sitewide Stats",
//   caption: "Cover slide with big image.",
//   state: {},
// };

// const SITEWIDE_DATA_SLIDE_TEMPLATE: SlideTemplate<SitewideDataSlideState> = {
//   type: "SitewideData",
//   name: "Sitewide Data",
//   caption: "Cover slide with big image.",
//   state: {},
// };

// const IMPRESSION_SHARE_SLIDE_TEMPLATE: SlideTemplate<ImpressionShareSlideState> = {
//   type: "ImpressionShare",
//   name: "Impression Share",
//   caption: "Cover slide with big image.",
//   state: {},
// };

const OPPORTUNITY_BANNER_SLIDE_TEMPLATE: SlideTemplate<OpportunityBannerSlideState> = {
  type: "OpportunityBanner",
  name: "Opportunity Banner",
  caption: "Blank template slide",
  state: {},
};

const LIN_PLANS_TEMPLATE: SlideTemplate<LinearPlansSlideState> = {
  type: "linearPlans",
  name: "Linear Plan",
  caption: "Information about upcoming linear bookings.",
  state: {},
};

const STR_DELIV_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Streaming Delivery",
  caption: "Chart based on streaming delivery page.",
  state: {
    prefix: "streaming",
  },
};

const STR_DELIV_NET_GROUP_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Streaming Delivery (Network Group)",
  state: {
    prefix: "streaming",
    dimension: "network_group",
    absolute: true,
  },
};

const STR_DELIV_NET_GROUP_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Streaming Delivery (Network Group) (Relative)",
  state: {
    prefix: "streaming",
    dimension: "network_group",
  },
};

const STR_DELIV_DEVICEOS_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Streaming Delivery (Device/OS)",
  state: {
    prefix: "streaming",
    dimension: "deviceos",
    absolute: true,
  },
};

const STR_DELIV_DEVICEOS_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Streaming Delivery (Device/OS) (Relative)",
  state: {
    prefix: "streaming",
    dimension: "deviceos",
  },
};

export const LIN_DELIV_TEMPLATE: SlideTemplate<LinearDeliverySlideState> = {
  type: "linearDelivery",
  name: "Linear Delivery",
  caption: "Chart based on linear delivery page.",
  state: {
    prefix: "tv",
  },
};

export const YOUTUBE_DELIVERY_TEMPLATE: SlideTemplate<YoutubeDeliverySlideState> = {
  type: "youtubeDelivery",
  name: "Youtube Delivery",
  caption: "Chart based on youtube delivery page.",
  state: {},
};

const AUDIO_DELIV_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Audio Delivery",
  caption: "Chart based on audio delivery page.",
  state: {
    prefix: "audio",
    title: "Audio Delivery & Pacing",
  },
};

const AUDIO_DELIV_NET_GROUP_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Audio Delivery (Network Group)",
  state: {
    prefix: "audio",
    dimension: "network_group",
    absolute: true,
    title: "Audio Delivery & Pacing",
  },
};

const AUDIO_DELIV_NET_GROUP_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Audio Delivery (Network Group) (Relative)",
  state: {
    prefix: "audio",
    dimension: "network_group",
    title: "Audio Delivery & Pacing",
  },
};

const AUDIO_DELIV_DEVICEOS_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Audio Delivery (Device/OS)",
  state: {
    prefix: "audio",
    dimension: "deviceos",
    absolute: true,
    title: "Audio Delivery & Pacing",
  },
};

const AUDIO_DELIV_DEVICEOS_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Audio Delivery (Device/OS) (Relative)",
  state: {
    prefix: "audio",
    dimension: "deviceos",
    title: "Audio Delivery & Pacing",
  },
};

const DISPLAY_DELIV_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Display Delivery",
  caption: "Chart based on display delivery page.",
  state: {
    prefix: "display",
    title: "Display Delivery & Pacing",
  },
};

const DISPLAY_DELIV_NET_GROUP_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Display Delivery (Network Group)",
  state: {
    prefix: "display",
    dimension: "network_group",
    absolute: true,
    title: "Display Delivery & Pacing",
  },
};

const DISPLAY_DELIV_NET_GROUP_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Display Delivery (Network Group) (Relative)",
  state: {
    prefix: "display",
    dimension: "network_group",
    title: "Display Delivery & Pacing",
  },
};

const DISPLAY_DELIV_DEVICEOS_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Display Delivery (Device/OS)",
  state: {
    prefix: "display",
    dimension: "deviceos",
    absolute: true,
    title: "Display Delivery & Pacing",
  },
};

const DISPLAY_DELIV_DEVICEOS_REL_TEMPLATE: SlideTemplate<StreamingDeliverySlideState> = {
  type: "streamingDelivery",
  name: "Display Delivery (Device/OS) (Relative)",
  state: {
    prefix: "display",
    dimension: "deviceos",
    title: "Display Delivery & Pacing",
  },
};

const STR_GEO_TEMPLATE: SlideTemplate<StreamingGeoSlideState> = {
  type: "streamingGeo",
  name: "Streaming Geo",
  caption: "Color-coded US DMA map and table based on streaming geo page.",
  state: {},
};
const STR_GEO_ALL_BIZ_TEMPLATE: SlideTemplate<StreamingGeoSlideState> = {
  type: "streamingGeo",
  name: "Streaming Geo (All Business)",
  state: {
    title: "Business Performance by DMA",
    lag: "all_business",
  },
};

const STR_PLANS_TEMPLATE: SlideTemplate<StreamingPlansSlideState> = {
  type: "streamingPlans",
  name: "Streaming Plan",
  caption: "Information about upcoming streaming bookings.",
  state: {},
};

const AUDIO_PLANS_TEMPLATE: SlideTemplate<StreamingPlansSlideState> = {
  type: "streamingPlans",
  name: "Audio Plan",
  caption: "Information about upcoming audio bookings.",
  state: {
    audio: true,
  },
};

const DISPLAY_PLANS_TEMPLATE: SlideTemplate<StreamingPlansSlideState> = {
  type: "streamingPlans",
  name: "Display Plan",
  caption: "Information about upcoming display bookings.",
  state: {
    display: true,
  },
};

const HOUSEKEEPING_TEMPLATE: SlideTemplate<HousekeepingSlideState> = {
  type: "housekeeping",
  name: "Housekeeping",
  caption: "Booking table going out several weeks and thumbnails of live creatives.",
  state: {},
};

export const COMPETITIVE_LANDSCAPE_TEMPLATE: SlideTemplate<CompetitiveLandscapeSlideState> = {
  type: "competitiveLandscape",
  name: "Competitive Landscape",
  caption: "Estimated Spend of Competitors",
  state: {},
};

export const EXECUTIVE_SUMMARY_TEMPLATE: SlideTemplate<ExecutiveSummarySlideState> = {
  type: "executiveSummary",
  name: "Executive Summary",
  caption: "Executive Summary of Spend, KPI, CPX, and ROAS",
  state: {},
};

export const NOTABLE_AIRINGS_TEMPLATE: SlideTemplate<NotableAiringsSlideState> = {
  type: "notableAirings",
  name: "Notable Airings",
  caption: "Spike Chart and Table of this week's linear airings",
  state: {},
};

export const FUNNEL_DYNAMICS_TEMPLATE: SlideTemplate<FunnelDynamicsSlideState> = {
  type: "funnelDynamics",
  name: "Funnel Dynamics",
  caption: "Conversion Metrics for KPI Funnel",
  state: {},
};

export const BONUS_AND_CREDIT_SUMMARY_TEMPLATE: SlideTemplate<BonusAndCreditSummarySlideState> = {
  type: "bonusAndCreditSummary",
  name: "Bonus & Credit Summary",
  caption: "Summary of Bonus and Credited Media",
  state: {},
};

export const STREAMING_PERFORMANCE_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Streaming Performance New",
  caption: "New Streaming Performance Slide that uses presets from Streaming Performance Page!",
  state: { mediatype: "streaming" },
};

export const STREAMING_PERFORMANCE_GRAPH_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Streaming Performance Graph",
  caption:
    "New Streaming Performance Slide that uses presets from Streaming Performance Page(Graph)!",
  state: { mediatype: "streaming", graphSlideOverride: true },
};

export const AUDIO_PERFORMANCE_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Audio Performance New",
  caption: "New Audio Performance Slide that uses presets from Audio Performance Page!",
  state: { mediatype: "audio" },
};

export const AUDIO_PERFORMANCE_GRAPH_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Audio Performance Graph",
  caption: "New Audio Performance Slide that uses presets from Audio Performance Page(Graph)!",
  state: { mediatype: "audio", graphSlideOverride: true },
};

export const DISPLAY_PERFORMANCE_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Display Performance New",
  caption: "New Display Performance Slide that uses presets from Display Performance Page!",
  state: { mediatype: "display" },
};

export const DISPLAY_PERFORMANCE_GRAPH_NEW_TEMPLATE: SlideTemplate<StreamingPerformanceNewSlideState> = {
  type: "streamingPerformanceNew",
  name: "Display Performance Graph",
  caption: "New Display Performance Slide that uses presets from Display Performance Page(Graph)!",
  state: { mediatype: "display", graphSlideOverride: true },
};

export const LINEAR_PERFORMANCE_NEW_TEMPLATE: SlideTemplate<LinearPerformanceNewSlideState> = {
  type: "linearPerformanceNew",
  name: "Linear Performance New",
  caption: "New Linear Performance Slide that uses presets from Linear Performance Page!",
  state: {},
};

export const YOUTUBE_PERFORMANCE_NEW_TEMPLATE: SlideTemplate<YoutubePerformanceSlideState> = {
  type: "youtubePerformance",
  name: "Youtube Performance",
  caption: "Youtube Performance Slide that uses presets from Youtube Performance Page!",
  state: {},
};

export const STREAMING_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "Streaming Single Channel Metrics Table",
  caption:
    "Slide that creates the metrics table from the Streaming Single Channel Metrics Table Tab",
  state: {
    mediatype: "streaming",
  },
};

export const LINEAR_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "Linear Single Channel Metrics Table",
  caption: "Slide that creates the metrics table from the Linear Single Channel Metrics Table Tab",
  state: {
    mediatype: "linear",
  },
};

export const AUDIO_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "Audio Single Channel Metrics Table",
  caption: "Slide that creates the metrics table from the Audio Single Channel Metrics Table Tab",
  state: {
    mediatype: "audio",
  },
};

export const DISPLAY_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "Display Single Channel Metrics Table",
  caption: "Slide that creates the metrics table from the Display Single Channel Metrics Table Tab",
  state: {
    mediatype: "display",
  },
};

export const YOUTUBE_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "YouTube Single Channel Metrics Table",
  caption: "Slide that creates the metrics table from the YouTube Single Channel Metrics Table Tab",
  state: {
    mediatype: "youtube",
  },
};

export const SOCIAL_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE: SlideTemplate<SingleChannelMetricsTableSlideState> = {
  type: "singleChannelMetricsTable",
  name: "Social Single Channel Metrics Table",
  caption: "Slide that creates the metrics table from the Social Single Channel Metrics Table Tab",
  state: {
    mediatype: "social",
  },
};

export const NEW_SLIDE_OPTIONS: SlideTemplate[] = [
  TITLE_SLIDE_TEMPLATE,
  PAID_MEDIA_BREAKDOWN_SLIDE_TEMPLATE,
  // SITEWIDE_STATS_SLIDE_TEMPLATE,
  // SITEWIDE_DATA_SLIDE_TEMPLATE,
  // IMPRESSION_SHARE_SLIDE_TEMPLATE,
  OPPORTUNITY_BANNER_SLIDE_TEMPLATE,
  LIN_PLANS_TEMPLATE,
  STR_DELIV_TEMPLATE,
  LIN_DELIV_TEMPLATE,
  YOUTUBE_DELIVERY_TEMPLATE,
  AUDIO_DELIV_TEMPLATE,
  DISPLAY_DELIV_TEMPLATE,
  STR_GEO_TEMPLATE,
  STR_PLANS_TEMPLATE,
  AUDIO_PLANS_TEMPLATE,
  DISPLAY_PLANS_TEMPLATE,
  HOUSEKEEPING_TEMPLATE,
  COMPETITIVE_LANDSCAPE_TEMPLATE,
  EXECUTIVE_SUMMARY_TEMPLATE,
  NOTABLE_AIRINGS_TEMPLATE,
  FUNNEL_DYNAMICS_TEMPLATE,
  BONUS_AND_CREDIT_SUMMARY_TEMPLATE,
  STREAMING_PERFORMANCE_NEW_TEMPLATE,
  LINEAR_PERFORMANCE_NEW_TEMPLATE,
  AUDIO_PERFORMANCE_NEW_TEMPLATE,
  DISPLAY_PERFORMANCE_NEW_TEMPLATE,
  YOUTUBE_PERFORMANCE_NEW_TEMPLATE,
  STREAMING_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
  LINEAR_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
  AUDIO_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
  DISPLAY_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
  YOUTUBE_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
  SOCIAL_SINGLE_CHANNEL_METRICS_TABLE_NEW_TEMPLATE,
];

export interface DeckTemplate {
  name: string;
  caption: string;
  template: SlideTemplate[];
}

const BLANK_TEMPLATE: DeckTemplate = {
  name: "Blank",
  caption: "A blank canvas with no slides.",
  template: [],
};
const CLASSIC_TEMPLATE: DeckTemplate = {
  name: "The Classic",
  caption: "The original slide deck. Comes with the works!",
  template: [
    TITLE_SLIDE_TEMPLATE,
    PAID_MEDIA_BREAKDOWN_SLIDE_TEMPLATE,
    // SITEWIDE_STATS_SLIDE_TEMPLATE,
    // SITEWIDE_DATA_SLIDE_TEMPLATE,
    // IMPRESSION_SHARE_SLIDE_TEMPLATE,
    OPPORTUNITY_BANNER_SLIDE_TEMPLATE,
    LINEAR_PERFORMANCE_NEW_TEMPLATE,
    LIN_PLANS_TEMPLATE,
    STR_DELIV_NET_GROUP_TEMPLATE,
    STR_DELIV_NET_GROUP_REL_TEMPLATE,
    STR_DELIV_DEVICEOS_TEMPLATE,
    STR_DELIV_DEVICEOS_REL_TEMPLATE,
    STREAMING_PERFORMANCE_NEW_TEMPLATE,
    STR_GEO_TEMPLATE,
    STR_GEO_ALL_BIZ_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_REL_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_REL_TEMPLATE,
    AUDIO_PERFORMANCE_NEW_TEMPLATE,
    STR_PLANS_TEMPLATE,
    AUDIO_PLANS_TEMPLATE,
    DISPLAY_PLANS_TEMPLATE,
    HOUSEKEEPING_TEMPLATE,
    COMPETITIVE_LANDSCAPE_TEMPLATE,
  ],
};

const BASIC_STREAMING_TEMPLATE: DeckTemplate = {
  name: "Basic Streaming",
  caption: "Just performance and delivery slides.",
  template: [
    STR_DELIV_NET_GROUP_TEMPLATE,
    STR_DELIV_NET_GROUP_REL_TEMPLATE,
    STR_DELIV_DEVICEOS_TEMPLATE,
    STR_DELIV_DEVICEOS_REL_TEMPLATE,
    STREAMING_PERFORMANCE_NEW_TEMPLATE,
  ],
};

const FULL_STREAMING_TEMPLATE: DeckTemplate = {
  name: "Full Streaming",
  caption: "A full streaming deck, including geo and plans slides.",
  template: [
    TITLE_SLIDE_TEMPLATE,
    STR_DELIV_NET_GROUP_TEMPLATE,
    STR_DELIV_NET_GROUP_REL_TEMPLATE,
    STR_DELIV_DEVICEOS_TEMPLATE,
    STR_DELIV_DEVICEOS_REL_TEMPLATE,
    STREAMING_PERFORMANCE_NEW_TEMPLATE,
    STR_GEO_TEMPLATE,
    STR_GEO_ALL_BIZ_TEMPLATE,
    STR_PLANS_TEMPLATE,
    HOUSEKEEPING_TEMPLATE,
  ],
};

const FULL_STREAMING_AUDIO_TEMPLATE: DeckTemplate = {
  name: "Full Streaming + Audio",
  caption: "The full streaming deck, plus audio slides.",
  template: [
    TITLE_SLIDE_TEMPLATE,
    STR_DELIV_NET_GROUP_TEMPLATE,
    STR_DELIV_NET_GROUP_REL_TEMPLATE,
    STR_DELIV_DEVICEOS_TEMPLATE,
    STR_DELIV_DEVICEOS_REL_TEMPLATE,
    STREAMING_PERFORMANCE_NEW_TEMPLATE,
    STR_GEO_TEMPLATE,
    STR_GEO_ALL_BIZ_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_REL_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_REL_TEMPLATE,
    AUDIO_PERFORMANCE_NEW_TEMPLATE,
    STR_PLANS_TEMPLATE,
    AUDIO_PLANS_TEMPLATE,
    HOUSEKEEPING_TEMPLATE,
  ],
};

const BASIC_LINEAR_TEMPLATE: DeckTemplate = {
  name: "Basic Linear",
  caption: "Just performance and delivery slides.",
  template: [LINEAR_PERFORMANCE_NEW_TEMPLATE, LIN_PLANS_TEMPLATE],
};

export const COPY_TEMPLATE: DeckTemplate = {
  name: "Copy Current Config",
  caption: "Make a new deck using the settings you currently have loaded.",
  template: [],
};

const BASIC_AUDIO_TEMPLATE: DeckTemplate = {
  name: "Basic Audio",
  caption: "Just performance and delivery slides.",
  template: [
    AUDIO_DELIV_NET_GROUP_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_REL_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_REL_TEMPLATE,
    AUDIO_PERFORMANCE_NEW_TEMPLATE,
  ],
};

const FULL_AUDIO_TEMPLATE: DeckTemplate = {
  name: "Full Audio",
  caption: "An audio streaming deck, including plans and housekeeping slides.",
  template: [
    TITLE_SLIDE_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_TEMPLATE,
    AUDIO_DELIV_NET_GROUP_REL_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_TEMPLATE,
    AUDIO_DELIV_DEVICEOS_REL_TEMPLATE,
    AUDIO_PERFORMANCE_NEW_TEMPLATE,
    AUDIO_PLANS_TEMPLATE,
    HOUSEKEEPING_TEMPLATE,
  ],
};

const BASIC_DISPLAY_TEMPLATE: DeckTemplate = {
  name: "Basic Display",
  caption: "Just performance and delivery slides.",
  template: [
    DISPLAY_DELIV_NET_GROUP_TEMPLATE,
    DISPLAY_DELIV_NET_GROUP_REL_TEMPLATE,
    DISPLAY_DELIV_DEVICEOS_TEMPLATE,
    DISPLAY_DELIV_DEVICEOS_REL_TEMPLATE,
    DISPLAY_PERFORMANCE_NEW_TEMPLATE,
  ],
};

const FULL_DISPLAY_TEMPLATE: DeckTemplate = {
  name: "Full Display",
  caption: "An display streaming deck, including housekeeping slides.",
  template: [
    TITLE_SLIDE_TEMPLATE,
    DISPLAY_DELIV_NET_GROUP_TEMPLATE,
    DISPLAY_DELIV_NET_GROUP_REL_TEMPLATE,
    DISPLAY_DELIV_DEVICEOS_TEMPLATE,
    DISPLAY_DELIV_DEVICEOS_REL_TEMPLATE,
    DISPLAY_PERFORMANCE_NEW_TEMPLATE,
    DISPLAY_PLANS_TEMPLATE,
    HOUSEKEEPING_TEMPLATE,
  ],
};

const MARKETPLACE_REPORT_TEMPLATE: DeckTemplate = {
  name: "Tinuiti Marketplace Reports",
  caption: "A grouping of Tinuiti Marketplace Reports (TMRs).",
  template: [
    TITLE_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_SUMMARY_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_CAMPAIGNS_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_PRODUCT_TARGETING_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_KEYWORDS_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_DSP_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_FREE_FORM_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_CATEGORY_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_BIGGEST_MOVER_SLIDE_TEMPLATE,
    MARKETPLACE_REPORT_SEASONALITY_SLIDE_TEMPLATE,
  ],
};

export const DECK_TEMPLATE_OPTIONS = [
  BLANK_TEMPLATE,
  CLASSIC_TEMPLATE,
  BASIC_STREAMING_TEMPLATE,
  FULL_STREAMING_TEMPLATE,
  FULL_STREAMING_AUDIO_TEMPLATE,
  BASIC_LINEAR_TEMPLATE,
  BASIC_AUDIO_TEMPLATE,
  FULL_AUDIO_TEMPLATE,
  BASIC_DISPLAY_TEMPLATE,
  FULL_DISPLAY_TEMPLATE,
  MARKETPLACE_REPORT_TEMPLATE,
];

// Add Slide Options Here:
export const MOBIUS_NEW_SLIDE_OPTIONS: SlideTemplate[] = [
  PAID_MEDIA_BREAKDOWN_SLIDE_TEMPLATE,
  // SITEWIDE_STATS_SLIDE_TEMPLATE,
  // SITEWIDE_DATA_SLIDE_TEMPLATE,
  // IMPRESSION_SHARE_SLIDE_TEMPLATE,
  OPPORTUNITY_BANNER_SLIDE_TEMPLATE,
];
export const MOBIUS_DECK: DeckTemplate = {
  name: "Mobius Slides",
  caption: "All New Mobius Slide Templates!",
  template: [
    // SITEWIDE_STATS_SLIDE_TEMPLATE,
    // SITEWIDE_DATA_SLIDE_TEMPLATE,
    PAID_MEDIA_BREAKDOWN_SLIDE_TEMPLATE,
    // IMPRESSION_SHARE_SLIDE_TEMPLATE,
    OPPORTUNITY_BANNER_SLIDE_TEMPLATE,
  ],
};
export const MOBIUS_DECK_TEMPLATE_OPTIONS: DeckTemplate[] = [BLANK_TEMPLATE, MOBIUS_DECK];
