import { useContext } from "react";
import { Button, ButtonType } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { HiOutlinePlus, HiOutlineUpload } from "react-icons/hi";
import * as R from "ramda";
import { FormContext, formObjDefault } from "./WtoFormContext";

export interface MenuBarWtoProps {
  breadcrumbPath: string[];
  setBreadcrumbPath: React.Dispatch<React.SetStateAction<string[]>>;
  loading: boolean;
  setModal: React.Dispatch<React.SetStateAction<string>>;
}

export const MenuBarWTO: React.FC<MenuBarWtoProps> = ({
  breadcrumbPath,
  setBreadcrumbPath,
  loading,
  setModal,
}) => {
  const { setWtoFormObj } = useContext(FormContext);

  return (
    <div className="WebTrafficOptimizationActions">
      {R.isEmpty(breadcrumbPath) && !loading && (
        <>
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<HiOutlinePlus />}
            onClick={() => {
              setWtoFormObj(formObjDefault);
              setBreadcrumbPath(["Route Details"]);
            }}
          >
            Create Route
          </Button>
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={<HiOutlineUpload />}
            onClick={() => setModal("Bulk Plover Upload")}
          >
            Bulk Plover Upload
          </Button>
        </>
      )}
    </div>
  );
};

export default MenuBarWTO;
