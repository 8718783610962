// Will be able to see streaming creatives import feature
const streamingCreativesImporterUsers: string[] = [
  "alex.echeverria@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "justin.manus@tinuiti.com",
];

export const shouldEnableStreamingCreativesImporter = (userEmail: string): boolean => {
  return streamingCreativesImporterUsers.includes(userEmail);
};
