// Abbreviate numbers to be up to 2 decimals long with corresponding suffix
// 151 => 151
// 15012 => 15.0K
// 15123 => 15.1K
// 15193 => 15.2K
export const abbreviateNumberAMC = (value: number): string => {
  let newValue: number | string = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;

  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  // Round to 2 decimals max
  newValue = Math.round((newValue + Number.EPSILON) * 100) / 100;

  return `${newValue}${suffixes[suffixNum]}`;
};

export const capitalizeFirstLetter = (item: string): string =>
  `${item.charAt(0).toUpperCase()}${item.slice(1)}`;
