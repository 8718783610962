import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as R from "ramda";

import { navigate, RouteComponentProps } from "@reach/router";

import { Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";

import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";

import * as UserRedux from "../../redux/user";
import * as uuid from "uuid";
import { DateRange, typedReactMemo } from "../../utils/types";
import { mergeNumberObjects } from "../../utils/data";

import {
  Page,
  BPMDateRange,
  OldDropdown,
  BPMButton,
  FilterPane,
  useFilterPaneState,
  KpiPicker,
  FullPageSpinner,
  SuggestionInput,
} from "../../Components";
import { ReactComponent as Save } from "../icons/Save.svg";

import useLocation from "../../utils/hooks/useLocation";
import { useSetError } from "../../redux/modals";
import {
  awaitJSON,
  DagLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  StreamingPerformanceLambdaFetch,
} from "../../utils/fetch-utils";
import { useMap } from "../../utils/hooks/useData";
import { useCreativeMap } from "../../redux/creative";
import { useDerivedNetworkMap } from "../../redux/networks";
import { useSelector } from "react-redux";
import { mediaTypesSelector, useCompanyInfo } from "../../redux/company";
import OptionsDropdown from "../Config/OptionsDropdown";
import ConfigToggle from "../Config/ConfigToggle";
import DateConfig from "../Config/DateConfig";
import OverviewMetricsConfig from "../Config/OverviewMetricsConfig";
import PerformanceGridConfig from "../Config/PerformanceGridConfig";
import PerformanceGrid from "../PerformanceGrid";
import {
  encodePrettyUrl,
  decodePrettyUrl,
  getGlobalBrand,
  PerformanceContext,
  getPresetOptions,
  getBaseURL,
  exportCSV,
  ALL_GROUP_NAME,
  STANDARD_GROUP_NAME,
  getGroupOptions,
} from "../performanceUtils";
import {
  constructIndexTotalsMap,
  DIMENSION_COLUMN_METADATA_MAP,
  getColumnMetadataMap,
  performanceRowToTableRow,
  RowWithTabularData,
  makeFetchKey,
  BETWEEN_OPTIONS,
} from "./streamingPerformanceUtils";
import { OverviewMetrics, OverviewData } from "../OverviewMetrics";
import { computeResolvedDate, TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import PerformanceGridSkeleton from "../PerformanceGridSkeleton";

import "../Performance.scss";
import { overrideDateRange } from "../../utils/test-account-utils";

interface StreamingPerformanceProps extends RouteComponentProps {
  urlPresetName: string;
  prefix: S.Prefix;
  isGraph: boolean;
  isTransunionGraph: boolean;
}

const StreamingPerformance = typedReactMemo<React.FC<StreamingPerformanceProps>>(
  ({ urlPresetName, prefix, isGraph = false, isTransunionGraph = false, location }) => {
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();

    const setError = useSetError();

    const mediaTypes = useSelector(mediaTypesSelector(company));

    const mediaTypeEnabled = useMemo(() => mediaTypes.includes(prefix), [mediaTypes, prefix]);

    const baseUrl = useMemo(() => {
      return getBaseURL(urlPresetName, location);
    }, [urlPresetName, location]);

    const isInternal = useSelector(UserRedux.isInternalSelector);

    const derivedNetworkMap = useDerivedNetworkMap(company);

    const [editMode, setEditModeRaw] = useState<true | false | "new" | "quick">(false);

    const [presetChanges, setPresetChanges] = useState<P.PresetChanges<S.PerformancePreset>>({
      columns: [],
      performanceDimensionColumns: [],
      headers: [],
      adminOnly: false,
    });

    const [kpi, setKpi] = useState<string>("");
    const [lag, setLag] = useState<S.LagOption | "">("");

    const pickerSetKpi = useCallback(
      (kpi: string) => {
        if (editMode) {
          setPresetChanges(preset => ({ ...preset, globalKpi: kpi }));
        } else {
          setKpi(kpi);
        }
      },
      [editMode]
    );

    const pickerSetLag = useCallback(
      (lag: S.LagOption) => {
        if (editMode) {
          setPresetChanges(preset => ({ ...preset, globalLag: lag }));
        } else {
          setLag(lag);
        }
      },
      [editMode]
    );

    const [groupName, setGroupName] = useState<string>("");
    const [newGroupName, setNewGroupName] = useState<string>("");
    const [selectGroupOptions, setSelectGroupOptions] = useState<string[]>([]);
    const [presetName, setPresetNameRaw] = useState<string>("");
    const [newName, setNewName] = useState("");

    const setPresetName = useCallback(
      (name: string) => {
        setPresetNameRaw(name);
        navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
      },
      [location, baseUrl]
    );

    useEffect(() => {
      setPresetNameRaw(decodePrettyUrl(urlPresetName));
    }, [urlPresetName]);
    const [presets, setPresets] = useState<P.PresetIDGroups[]>();

    const applyGroupSelection = useCallback((groupName: string) => {
      setGroupName(groupName);
    }, []);

    const applyPresetSelection = useCallback(
      (name: string) => {
        localStorage.removeItem(`${company}_streamingPerfDateRange`); // Remove cached dates and use default dates of selected preset.
        setPresetName(name);
      },
      [company, setPresetName]
    );

    const presetID = useMemo(() => {
      if (presets) {
        return R.find((preset: P.PresetIDGroups) => preset.name === presetName, presets);
      }
    }, [presets, presetName]);

    const [branchBuild, setBranchBuild] = useState("v2/latest");

    const [presetMap, setPresetMap] = useMap<string, S.PerformancePreset | undefined>();

    const preset = presetMap[`${presetID?.id}`];

    const defaultDateState = useMemo(
      () => ({
        start:
          presetChanges.defaultDateState?.start ||
          preset?.defaultDateState?.start ||
          S.DEFAULT_DATE_RANGE_STATE.start,
        end:
          presetChanges.defaultDateState?.end ||
          preset?.defaultDateState?.end ||
          S.DEFAULT_DATE_RANGE_STATE.end,
      }),
      [presetChanges, preset]
    );

    const startingDateRange = useMemo(() => {
      const localDateRangeString = localStorage.getItem(`${company}_streamingPerfDateRange`);
      const localDateRange = localDateRangeString ? JSON.parse(localDateRangeString) : null;

      return {
        start: localDateRange?.start || computeResolvedDate(defaultDateState.start),
        end: localDateRange?.end || computeResolvedDate(defaultDateState.end),
      };
    }, [company, defaultDateState]);

    useEffect(() => {
      if (preset) {
        setKpi(preset?.globalKpi || companyInfo.streaming_performance_default_kpi);
        setLag(preset?.globalLag || companyInfo.streaming_performance_default_lag);
        setDates(startingDateRange);
      }
    }, [preset, companyInfo, startingDateRange]);

    // The global kpi/lag can come from three places: the picker, the preset, or the company
    // defaults, with the priority in that order (if one is undefined, it falls back to the next
    // one). It's a little tricky, however, because we want to initiate our fetch BEFORE we fetch
    // all the preset metadata. We use the "fetchKey" to figure out if we need to fetch new data,
    // and that requires a kpi and lag (since if those change, it will need to refetch). The first
    // inclination is to just use empty strings for those fields in the fetch key. However, after we
    // get the preset info, we'll fill in the kpi/lag, which will "fill in" those fields and trigger
    // a refetch that is actually for the same information. So we need to say that the "resolved
    // kpi/lag" is the same as "blank", then use that for the fetch. However, we'll also be able to
    // resolve what the true global kpi/lag are (the lambda uses the same priority logic) and the
    // fetch keys in the payload will be based on those names, so we want a fully-resolved global
    // kpi/lag name to reference those. So we need four values: fully resolved kpi/lag and kpi/lag
    // for fetch purposes.
    const [resolvedKpi, resolvedLag, fetchKpi, fetchLag] = useMemo(() => {
      let defaultKpi = companyInfo.streaming_performance_default_kpi;
      let defaultLag = companyInfo.streaming_performance_default_lag;
      let fetchKpi;
      let fetchLag;

      if (preset && !editMode) {
        if (preset.globalKpi) {
          defaultKpi = preset.globalKpi;
        }
        if (preset.globalLag) {
          defaultLag = preset.globalLag;
        }
        fetchKpi = kpi;
        fetchLag = lag;
      }

      let resolvedKpi = kpi || defaultKpi;
      let resolvedLag = lag || defaultLag;

      if (editMode) {
        resolvedKpi = presetChanges.globalKpi || defaultKpi;
        resolvedLag = presetChanges.globalLag || defaultLag;
      }

      return [resolvedKpi, resolvedLag, fetchKpi, fetchLag];
    }, [preset, companyInfo, kpi, lag, editMode, presetChanges]);

    const globalBrand = getGlobalBrand(companyInfo, resolvedKpi);

    useEffect(() => {
      if (company && !presets && mediaTypeEnabled) {
        (async () => {
          try {
            let params = {
              company,
              prefix,
              isGraph,
              isTransunionGraph,
              //groups: "", // TODO: Make this a UI input
            };
            let res = await StreamingPerformanceLambdaFetch<S.GetPresetsParams>("/presets", {
              params,
            });
            let parsedPresets = await awaitJSON(res);
            let presets: P.PresetIDGroups[] = [];
            if (!parsedPresets.errorMessage) {
              presets = parsedPresets as P.PresetIDGroups[];
              setPresets(presets);
            }
            let urlPreset = R.find(
              preset => encodePrettyUrl(preset.name) === urlPresetName,
              presets
            );
            if (urlPreset) {
              setPresetName(urlPreset.name);
              if (urlPreset.groups.length > 0) {
                setGroupName(urlPreset.groups[0]);
              } else {
                setGroupName(ALL_GROUP_NAME);
              }
            } else {
              setPresetName("Network");
              setGroupName(STANDARD_GROUP_NAME);
            }
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch presets. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [
      company,
      setError,
      urlPresetName,
      presets,
      setPresetName,
      prefix,
      mediaTypeEnabled,
      isGraph,
      isTransunionGraph,
    ]);

    useEffect(() => {
      if (!preset && presetID) {
        (async () => {
          try {
            let res = await StreamingPerformanceLambdaFetch<S.GetPresetParams>("/preset", {
              params: {
                id: presetID.id,
                company,
                prefix,
                isGraph,
              },
            });
            let row = await awaitJSON<S.PresetRow>(res);
            setPresetMap(`${presetID.id}`, row.preset as S.PerformancePreset);
            setKpi(row.preset.globalKpi || companyInfo.streaming_performance_default_kpi);
            setLag(row.preset.globalLag || companyInfo.streaming_performance_default_lag);
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        })();
      }
    }, [
      preset,
      presetID,
      setError,
      company,
      setPresetMap,
      prefix,
      companyInfo,
      isGraph,
      isTransunionGraph,
      startingDateRange,
    ]);

    const [dates, setDates] = useState<DateRange>();
    const [otherDates, setOtherDates] = useState<DateRange>();

    const onDatePickerChange = useCallback(
      (dates: DateRange) => {
        localStorage.setItem(`${company}_streamingPerfDateRange`, JSON.stringify(dates));
        setDates(dates);
      },
      [company]
    );

    const resetDefaultDates = useCallback(() => {
      localStorage.removeItem(`${company}_streamingPerfDateRange`);
      setDates({
        start: computeResolvedDate(defaultDateState.start),
        end: computeResolvedDate(defaultDateState.end),
      });
    }, [company, defaultDateState]);

    const fetchKey = useMemo(() => {
      if (preset && dates && presetID && branchBuild && branchBuild && fetchKpi && fetchLag) {
        return makeFetchKey(presetID?.id, dates, branchBuild, fetchKpi, fetchLag);
      }
      return "";
    }, [preset, dates, presetID, branchBuild, fetchKpi, fetchLag]);

    const otherDatesFetchKey = useMemo(() => {
      if (preset && otherDates && presetID && branchBuild && branchBuild && fetchKpi && fetchLag) {
        return makeFetchKey(presetID.id, otherDates, branchBuild, fetchKpi, fetchLag);
      }
      return "";
    }, [preset, presetID, branchBuild, fetchKpi, fetchLag, otherDates]);

    const [dataStatusMap, setDataStatusMap] = useMap<string, string | undefined>();
    const [dataMap, setDataMap] = useMap<string, S.GetPageDataResponse | undefined>();
    const data = dataMap[fetchKey];
    const otherData = dataMap[otherDatesFetchKey];

    const { creativeMap } = useCreativeMap({
      company: globalBrand || company,
      mediaTypes: ["streaming", "audio", "display"],
    });

    const fetchData = useCallback(
      (key, dates) => {
        (async () => {
          try {
            const [branch, build] = branchBuild.split("/");
            const dateRangeToUse = overrideDateRange(company, dates);
            let params: S.GetPageDataParams = {
              ...(dateRangeToUse || {}),
              id: R.defaultTo({ id: 0 }, presetID).id,
              company,
              branch,
              build,
              isGraph,
              isTransunionGraph,
            };
            if (fetchKpi) {
              params.kpi = fetchKpi;
            }
            if (fetchLag) {
              params.lag = fetchLag;
            }
            // To debug the lambda locally, uncomment this and comment out the MiscLambdaFetch
            // let res = await StreamingPerformanceLambdaFetch<S.GetPageDataParams>("/", {
            // params,
            // });
            // let data = await awaitJSON<S.GetPageDataResponse>(res);
            const result = await MiscLambdaFetch("/kickOffLambda", {
              method: "POST",
              body: {
                fileType: "txt",
                lambdaArgs: params,
                lambdaName: "streamingPerformance-getPageData",
              },
            });
            const uuid = await awaitJSON(result);
            const content = await pollS3({
              autoDownload: false,
              bucket: "bpm-cache",
              filename: `${uuid}.txt`,
              mimeType: "text/plain",
              period: 3000,
            });
            const textContent = await content.text();
            const data = JSON.parse(textContent) as S.GetPageDataResponse;
            if ((data as any).cometErrorMessage) {
              throw new Error((data as any).cometErrorMessage);
            }
            setDataMap(key, data);
          } catch (e) {
            setDataStatusMap(key, "readyToFetch");
            let error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        })();
      },
      [
        branchBuild,
        company,
        fetchKpi,
        fetchLag,
        isGraph,
        isTransunionGraph,
        presetID,
        setDataMap,
        setDataStatusMap,
        setError,
      ]
    );

    useEffect(() => {
      if (fetchKey && dataStatusMap[fetchKey] === "readyToFetch") {
        if (R.isNil(dataMap[fetchKey])) {
          try {
            fetchData(fetchKey, dates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${fetchKey}, ${dates}. Refresh to retry`
            );
            setDataStatusMap(fetchKey, "failed");
          }
        }
        setDataStatusMap(fetchKey, "fetched");
      }
      if (
        fetchKey &&
        otherDatesFetchKey &&
        fetchKey !== otherDatesFetchKey &&
        dataStatusMap[otherDatesFetchKey] === "readyToFetch"
      ) {
        if (R.isNil(dataMap[otherDatesFetchKey])) {
          try {
            fetchData(otherDatesFetchKey, otherDates);
          } catch (e) {
            console.error(
              `Failed to fetch performance data for ${company}, ${otherDatesFetchKey}, ${otherDates}. Refresh to retry`
            );
            setDataStatusMap(otherDatesFetchKey, "failed");
          }
        }
        setDataStatusMap(otherDatesFetchKey, "fetched");
      }
    }, [
      company,
      dataMap,
      dataStatusMap,
      dates,
      fetchData,
      fetchKey,
      otherDates,
      otherDatesFetchKey,
      setDataStatusMap,
    ]);

    useEffect(() => {
      if (fetchKey && R.isNil(dataStatusMap[fetchKey]) && !data) {
        setDataStatusMap(fetchKey, "readyToFetch");
      }
      if (
        fetchKey !== otherDatesFetchKey &&
        otherDates &&
        otherDatesFetchKey &&
        R.isNil(dataStatusMap[otherDatesFetchKey]) &&
        !otherData
      ) {
        setDataStatusMap(otherDatesFetchKey, "readyToFetch");
      }
    }, [
      branchBuild,
      company,
      data,
      dataStatusMap,
      dates,
      editMode,
      fetchData,
      fetchKey,
      fetchKpi,
      fetchLag,
      isGraph,
      isTransunionGraph,
      otherData,
      otherDates,
      otherDatesFetchKey,
      presetID,
      setDataMap,
      setDataStatusMap,
      setError,
    ]);

    const [filterState, setFilterState, resetFilterState] = useFilterPaneState();
    const [filterID, setFilterID] = useState<number | undefined>();

    useEffect(() => {
      if (preset) {
        if (preset.filterID) {
          resetFilterState();
          setFilterID(preset.filterID);
        } else {
          setFilterID(undefined);
          if (preset.filterState) {
            setFilterState(preset.filterState);
          }
        }
      } else {
        resetFilterState();
      }
    }, [preset, resetFilterState, setFilterID, setFilterState]);

    const [filterTerm, setFilterTerm] = useState("");

    const [tabularData, otherTabularData] = useMemo(() => {
      if (!(data && preset)) {
        return [undefined, undefined];
      }
      let datas = [data];
      if (otherData) {
        datas.push(otherData);
      }
      return datas.map(data => {
        let indexTotalsMap = constructIndexTotalsMap(
          preset.columns,
          data.data,
          resolvedKpi,
          resolvedLag
        );
        let rows: RowWithTabularData[] = [];
        for (let row of data.data) {
          rows.push({
            ...row,
            tabularRow: performanceRowToTableRow(
              row,
              preset.columns,
              indexTotalsMap,
              company,
              resolvedKpi,
              resolvedLag,
              isInternal
            ),
          });
        }
        return rows;
      });
    }, [data, preset, company, isInternal, otherData, resolvedKpi, resolvedLag]);

    const otherDataRowMap = useMemo<Record<string, RowWithTabularData | undefined>>(() => {
      if (!otherTabularData) {
        return {};
      }
      let map: Record<string, RowWithTabularData | undefined> = {};
      for (let row of otherTabularData) {
        map[JSON.stringify(row.dimensions)] = row;
      }
      return map;
    }, [otherTabularData]);

    const creativeNameMap = useMemo(() => {
      let res = {};
      if (creativeMap) {
        for (let key of R.keys(creativeMap)) {
          const { name } = creativeMap[key];
          if (R.isNil(res[name]) || !res[name].file) {
            res[name] = creativeMap[key];
          }
        }
      }
      return res;
    }, [creativeMap]);

    const filteredRows = useMemo(() => {
      if (!(tabularData && filterTerm && preset && creativeNameMap && derivedNetworkMap)) {
        return tabularData;
      }

      let lcFilterTerm = filterTerm.toLowerCase();
      let rows: RowWithTabularData[] = [];
      for (let row of tabularData) {
        for (let dimCol of preset.performanceDimensionColumns) {
          let check: (row: RowWithTabularData) => boolean;
          if (dimCol.dimension === "Network" && dimCol.type !== "Derived ID") {
            check = row =>
              (
                (derivedNetworkMap[row.dimensions.Network || ""] || {})[
                  dimCol.type === "Description" ? "description" : "network"
                ] || ""
              )
                .toLowerCase()
                .includes(lcFilterTerm);
          } else if (dimCol.dimension === "Creative") {
            check = row =>
              !!creativeNameMap[row.dimensions.Creative || ""]?.name
                .toLowerCase()
                .includes(lcFilterTerm);
          } else {
            check = row =>
              `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes(lcFilterTerm);
          }
          if (check(row)) {
            rows.push(row);
            break;
          }
        }
      }

      return rows;
    }, [filterTerm, tabularData, creativeNameMap, derivedNetworkMap, preset]);

    const overviewData = useMemo(() => {
      if (!(filteredRows && preset)) {
        return;
      }

      let overviewFetchKey = S.getFetchKey({ kpi: resolvedKpi, lag: resolvedLag });

      let aggregatedData = filteredRows.reduce((agg, row) => {
        let data = row.fetches[overviewFetchKey];
        if (!data) {
          return agg;
        }
        return mergeNumberObjects(agg, data);
      }, {} as S.ColumnDataRow);

      let imps = aggregatedData.imps || 0;
      let residImps = aggregatedData.residImps || 0;
      let resps = aggregatedData.resps || 0;
      let overviewData: OverviewData = {
        imps,
        spend: aggregatedData.spend || 0,
        ecpm: S.calculateCPM(aggregatedData),
        lastUpdated: data?.lastModified || "Unknown",
        directConversions: imps ? (1000 * (aggregatedData.volumeDirect || 0)) / imps : 0,
        fractionalConversions: imps ? (1000 * (aggregatedData.volumeFractional || 0)) / imps : 0,
        avgDirectRevenue: S.calculateAvgRevenue(aggregatedData, "Direct"),
        totalDirectRevenue: aggregatedData.revenueDirect || 0,

        streaming: {
          residentialDelivRate: imps ? (residImps || 0) / imps : 0,
          residentialRespRate: resps ? (aggregatedData.residResps || 0) / resps : 0,
          avgFractionalRevenue: S.calculateAvgRevenue(aggregatedData, "Fractional"),
          totalFractionalRevenue: aggregatedData.revenueFractional || 0,
          haloConversions: imps ? (1000 * (aggregatedData.volumeHalo || 0)) / imps : 0,
          avgHaloRevenue: S.calculateAvgRevenue(aggregatedData, "Halo"),
          totalHaloRevenue: aggregatedData.revenueHalo || 0,
        },
      };

      if (isGraph) {
        overviewData = {
          imps,
          spend: aggregatedData.spend || 0,
          ecpm: S.calculateCPM(aggregatedData),
          lastUpdated: data?.lastModified || "Unknown",

          streaming: {
            residentialDelivRate: imps ? (residImps || 0) / imps : 0,
            residentialRespRate: resps ? (aggregatedData.residResps || 0) / resps : 0,
            fullFunnelConversions: imps
              ? (1000 * (aggregatedData.volumeFullFunnel || 0) +
                  (aggregatedData.volumeIncremental || 0)) /
                imps
              : 0,
            incrementalConversions: imps
              ? (1000 * (aggregatedData.volumeIncremental || 0)) / imps
              : 0,
            avgIncrementalRevenue: S.calculateAvgRevenue(aggregatedData, "Incremental"),
            totalIncrementalRevenue: aggregatedData.revenueIncremental || 0,
            avgFullFunnelRevenue: S.calculateAvgRevenue(aggregatedData, "FullFunnel"),
            totalFullFunnelRevenue: aggregatedData.revenueFullFunnel || 0,
          },
        };
      }

      return overviewData;
    }, [filteredRows, preset, data, resolvedKpi, resolvedLag, isGraph]);

    const [impsBetween, setImpsBetween] = useState<boolean>(true);

    const groupOptions = useMemo(() => {
      let allGroups = getGroupOptions(presets);
      allGroups.push(ALL_GROUP_NAME);
      return allGroups;
    }, [presets]);

    const createViewGroupOptions = useMemo(() => {
      let viewGroupOptions = groupOptions.filter(
        groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
      );
      return viewGroupOptions;
    }, [groupOptions]);

    const presetOptions = useMemo(() => {
      const allPresets = getPresetOptions(presets, groupName);
      return allPresets;
    }, [presets, groupName]);

    const allPresetsNames = useMemo(() => {
      const opts = presets ? presets.map(preset => preset.name) : [];
      return opts;
    }, [presets]);

    const setEditMode = useCallback(
      (val: typeof editMode) => {
        if (preset) {
          setImpsBetween(preset.impsBetween);
          setEditModeRaw(val);
          if (presetID && presetID.groups.includes(STANDARD_GROUP_NAME)) {
            setSelectGroupOptions([STANDARD_GROUP_NAME]);
            setNewGroupName(STANDARD_GROUP_NAME);
          } else {
            let selectOptions = groupOptions.filter(
              groupName => groupName !== STANDARD_GROUP_NAME && groupName !== ALL_GROUP_NAME
            );
            setSelectGroupOptions(selectOptions);
            let currentGroupName = presetID && presetID.groups.length > 0 ? presetID.groups[0] : "";
            setNewGroupName(currentGroupName);
          }
        }
        if (!val) {
          setNewName("");
          setNewGroupName("");
          setSelectGroupOptions([]);
          // TODO: what else needs to be reset if they hit cancel?
        }
      },
      [preset, groupOptions, presetID]
    );

    // This effect should only happen when the preset changes
    useEffect(() => {
      if (preset) {
        setImpsBetween(preset.impsBetween);
        setPresetChanges(R.omit(["filterState", "filterTokens"], preset));
      }
    }, [preset, editMode]);

    const [saving, setSaving] = useState(false);

    const savePresetToDBAndReload = useCallback(
      async (body: S.SavePresetParams) => {
        try {
          const res = await StreamingPerformanceLambdaFetch<S.SavePresetParams>("/preset", {
            method: "POST",
            body,
          });
          let { name } = await awaitJSON<P.SavePresetResponse>(res);
          navigate && navigate(`${location?.origin}${baseUrl}/${encodePrettyUrl(name)}`);
          window.location.reload();
        } catch (e) {
          let error = e as Error;
          setError({
            title: "Couldn't save view",
            message: error.message,
            reportError: error,
          });
          setSaving(false);
        }
      },
      [baseUrl, location, setError]
    );

    const saveView = useCallback(async () => {
      if (preset && presetID) {
        if (editMode !== "quick" && newName === "") {
          setError({
            title: "Blank Name",
            message: "Your view must have a name.",
          });
          return;
        }
        if (
          (newGroupName === STANDARD_GROUP_NAME) !==
          presetID.groups.includes(STANDARD_GROUP_NAME)
        ) {
          setError({
            title: "Protected Group",
            message: "Your view cannot change the default group.",
          });
          return;
        }
        setSaving(true);
        const newPreset: S.PerformancePreset = {
          ...preset,
          ...presetChanges,
          impsBetween,
          filterState,
          filterID: editMode === "new" ? undefined : filterID,
        };
        let body: S.SavePresetParams = {
          company: globalBrand || company,
          companyToUseInDB: company,
          id: editMode === "new" ? "new" : presetID.id,
          preset: newPreset,
        };

        if (editMode === "quick") {
          body.temporary = true;
        } else {
          body.name = newName;
          body.groups = newGroupName ? [newGroupName] : [];
        }
        body.isGraph = isGraph;
        await savePresetToDBAndReload(body);
      }
    }, [
      company,
      editMode,
      filterID,
      filterState,
      globalBrand,
      impsBetween,
      isGraph,
      newGroupName,
      newName,
      preset,
      presetChanges,
      presetID,
      savePresetToDBAndReload,
      setError,
    ]);

    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();

    useEffect(() => {
      if (company && !filterData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<S.GetFilterOptionsParams>(
              "/filter_options",
              {
                params: {
                  company: globalBrand || company,
                  prefix,
                },
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, filterData, setError, prefix, globalBrand]);

    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await StreamingPerformanceLambdaFetch<P.GetKpiMetaDataParams>("/kpis", {
              params: {
                company,
              },
            });
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            let error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [kpiMetaData, company, setError]);

    const exportStreamingCSV = useCallback(() => {
      exportCSV({
        preset,
        tabularData,
        kpiMetaData,
        creativeMap,
        isInternal,
        kpi,
        DIMENSION_COLUMN_METADATA_MAP,
        COLUMN_METADATA_MAP: getColumnMetadataMap(isGraph, company),
        company,
        presetID,
        derivedNetworkMap,
      });
    }, [
      isInternal,
      tabularData,
      preset,
      kpiMetaData,
      derivedNetworkMap,
      creativeMap,
      company,
      presetID,
      kpi,
      isGraph,
    ]);

    const [timeTravelOpen, setTimeTravelOpenRaw] = useState(false);
    const [tempBranchBuild, setTempBranchBuild] = useState(branchBuild);

    const setTimeTravelOpen = useCallback(
      (open: boolean) => {
        if (open) {
          setTempBranchBuild(branchBuild);
        } else {
          setBranchBuild(tempBranchBuild);
        }
        setTimeTravelOpenRaw(open);
      },
      [tempBranchBuild, branchBuild]
    );

    const saveFilter = useCallback(
      async newFilterState => {
        if (editMode) {
          setFilterState(newFilterState);
        } else if (preset) {
          setSaving(true);
          let body: S.SavePresetParams = {
            company: globalBrand || company,
            companyToUseInDB: company,
            id: "new",
            temporary: true,
            preset: {
              ...preset,
              impsBetween,
              filterState:
                typeof newFilterState === "function" ? newFilterState(filterState) : newFilterState,
            },
            isGraph,
          };
          if (filterID) {
            body.preset.filterID = filterID;
          } else if (body.preset.filterID) {
            delete body.preset.filterID;
          }
          await savePresetToDBAndReload(body);
        }
      },
      [
        company,
        editMode,
        filterID,
        filterState,
        globalBrand,
        impsBetween,
        isGraph,
        preset,
        savePresetToDBAndReload,
        setFilterState,
      ]
    );

    const setAdminOnly = useCallback(
      newVal => setPresetChanges(current => ({ ...current, adminOnly: newVal })),
      []
    );

    const [percentAOIPlaceholder, setPercentAOIPlaceholder] = useState();

    useEffect(() => {
      if (company && dates) {
        (async () => {
          try {
            let res = await DagLambdaFetch("/get_percent_aoi_placeholder", {
              params: {
                company,
                platform: prefix,
                start: dates.start,
                end: dates.end,
              },
            });
            let placeholder = await awaitJSON(res);
            setPercentAOIPlaceholder(placeholder);
          } catch (e) {
            let error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch % Incremental placeholder value. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [company, prefix, dates, setError]);

    return (
      <PerformanceContext.Provider
        value={{
          kpiMetaData: kpiMetaData || {},
          globalKpi: resolvedKpi,
          globalLag: resolvedLag,
          prefix,
          branchBuild,
          impsBetween,
          globalBrand: globalBrand,
          isGraph,
        }}
      >
        <Page
          minWidth="500px"
          title={
            <div className="performanceTitle">
              <div>
                {`${
                  prefix === "olv"
                    ? "OLV "
                    : `${prefix.charAt(0).toUpperCase()}${prefix.substring(1)}`
                } Performance`}
              </div>
              {presets && (
                <div className="viewControls">
                  {editMode !== "quick" &&
                    (editMode ? (
                      <div className="viewGroupControls">
                        <SuggestionInput
                          isDisabled={newGroupName === STANDARD_GROUP_NAME}
                          placeholder="Select Group..."
                          value={newGroupName}
                          options={selectGroupOptions.map(groupOption => ({
                            value: groupOption,
                            label: groupOption,
                          }))}
                          formatCreateLabel={value => `New group: ${value}`}
                          nonEmpty
                          className="groupInput"
                          classNamePrefix="groupInput"
                          maxMenuHeight={250}
                          onChange={option => {
                            if (option !== STANDARD_GROUP_NAME && option !== ALL_GROUP_NAME) {
                              setNewGroupName(option);
                            }
                          }}
                        />
                        <Form.Control
                          size="sm"
                          className="viewNameInput"
                          value={newName}
                          disabled={newGroupName === STANDARD_GROUP_NAME}
                          placeholder="New view name"
                          onChange={e => setNewName(e.currentTarget.value)}
                        />
                      </div>
                    ) : (
                      <div className="multipleDropdowns">
                        <OldDropdown
                          key={uuid.v4()}
                          searchable
                          label="Group"
                          size="sm"
                          className="presetPicker"
                          placeholder="Select Group..."
                          value={groupName}
                          options={groupOptions}
                          onChange={applyGroupSelection}
                        />
                        <OldDropdown
                          key={uuid.v4()}
                          searchable
                          label="View"
                          size="sm"
                          className="presetPicker"
                          placeholder="Select View..."
                          value={presetName}
                          options={presetOptions}
                          onChange={applyPresetSelection}
                        />
                      </div>
                    ))}
                  {preset &&
                    (editMode ? (
                      <div className="editControls">
                        <BPMButton size="sm" icon={<Save />} disabled={saving} onClick={saveView}>
                          {editMode === "quick" ? "Go" : "Save"}
                        </BPMButton>
                        <BPMButton
                          variant="outline-primary"
                          size="sm"
                          icon={<MdClose />}
                          disabled={saving}
                          onClick={() => {
                            // TODO: are you sure?
                            setEditMode(false);
                          }}
                        >
                          Cancel
                        </BPMButton>
                      </div>
                    ) : (
                      <OptionsDropdown
                        location={location}
                        isInternal={isInternal}
                        preset={preset}
                        presetID={presetID}
                        baseUrl={baseUrl}
                        presetName={presetName}
                        timeTravelOpen={timeTravelOpen}
                        presetOptions={allPresetsNames}
                        groupOptions={createViewGroupOptions}
                        hasExportCSVData={
                          !!(tabularData && kpiMetaData && derivedNetworkMap && creativeMap)
                        }
                        tempBranchBuild={tempBranchBuild}
                        datePickerDates={dates || startingDateRange}
                        exportCSV={exportStreamingCSV}
                        setNewName={setNewName}
                        setPresetChanges={setPresetChanges}
                        setEditMode={setEditMode}
                        setSaving={setSaving}
                        setError={setError}
                        setTimeTravelOpen={setTimeTravelOpen}
                        setTempBranchBuild={setTempBranchBuild}
                      />
                    ))}

                  {filterData && preset && (
                    <FilterPane
                      categories={filterData}
                      company={company}
                      filterID={filterID}
                      highlightWhenFiltered
                      platform={prefix}
                      save={saveFilter}
                      setFilterID={setFilterID}
                      setState={setFilterState}
                      state={filterState}
                    />
                  )}
                </div>
              )}
            </div>
          }
          pageType="Streaming Performance"
          actions={
            !editMode &&
            !R.isNil(dates) && (
              <div className="performanceActions">
                {localStorage.getItem(`${company}_streamingPerfDateRange`) && (
                  <BPMButton size="sm" onClick={resetDefaultDates}>
                    Reset Default Dates
                  </BPMButton>
                )}
                <BPMDateRange
                  range={dates}
                  onChange={onDatePickerChange}
                  isDayBlocked={date => date >= TODAY}
                />
              </div>
            )
          }
        >
          <div className="performance">
            {editMode && (
              <div className="miscControlContainer">
                <div
                  className="kpiPickerConfigContainer"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="label">Global KPI and Lag:</div>
                  <KpiPicker
                    kpi={resolvedKpi}
                    onChange={pickerSetKpi}
                    mediaType="streaming"
                    disableTypeSelector
                  />
                  <OldDropdown
                    size="sm"
                    options={S.LAGS}
                    value={resolvedLag}
                    onChange={pickerSetLag}
                    label="Lag"
                  />
                  <BPMButton
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      setPresetChanges(R.omit(["globalKpi", "globalLag"]));
                      setKpi(preset?.globalKpi || companyInfo.streaming_performance_default_kpi);
                      setLag(preset?.globalLag || companyInfo.streaming_performance_default_lag);
                    }}
                  >
                    Reset to Default
                  </BPMButton>
                </div>
                <div className="editControls">
                  <OldDropdown
                    bold
                    size="sm"
                    value={impsBetween ? "Imp's Served Between" : "Response Between"}
                    options={BETWEEN_OPTIONS}
                    onChange={option => setImpsBetween(option === "Imp's Served Between")}
                  />
                  <ConfigToggle
                    toggledProperty={!!presetChanges.adminOnly}
                    setToggledProperty={setAdminOnly}
                    toggleText={"Admin Only"}
                    tooltipText={"Clients will not be able to see this preset listed."}
                  />
                </div>
                <DateConfig
                  defaultDateState={defaultDateState}
                  setPresetChanges={setPresetChanges}
                />
                <OverviewMetricsConfig
                  presetChanges={presetChanges}
                  setPresetChanges={setPresetChanges}
                />
              </div>
            )}
            {mediaTypeEnabled && !editMode && dates && (
              <OverviewMetrics
                preset={preset}
                data={overviewData}
                start={dates.start}
                end={dates.end}
                filterTokens={preset?.filterTokens}
                presetID={presetID?.id}
              >
                <div
                  className="overviewPickers"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {preset && kpiMetaData && (
                    <div
                      className="kpiPickerContainer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <KpiPicker
                        kpi={resolvedKpi}
                        onChange={pickerSetKpi}
                        mediaType="streaming"
                        bold
                        normalTitle
                        disableTypeSelector
                      />
                      <OldDropdown
                        size="sm"
                        options={S.LAGS}
                        value={resolvedLag}
                        onChange={pickerSetLag}
                        label="Lag"
                        bold
                        normalTitle
                      />
                    </div>
                  )}
                  {branchBuild !== "v2/latest" && (
                    <div className="timeTravelLink" onClick={() => setTimeTravelOpen(true)}>
                      (Currently time traveling)
                    </div>
                  )}
                </div>
              </OverviewMetrics>
            )}

            {mediaTypeEnabled ? (
              editMode ? (
                preset && companyInfo.streaming_performance_default_kpi ? (
                  <PerformanceGridConfig
                    preset={preset}
                    presetChanges={presetChanges}
                    setPresetChanges={setPresetChanges}
                    isInternal={isInternal}
                  />
                ) : (
                  <PerformanceGridSkeleton />
                )
              ) : preset &&
                filteredRows &&
                companyInfo.streaming_performance_default_kpi &&
                creativeMap &&
                derivedNetworkMap ? (
                <PerformanceGrid
                  preset={preset}
                  data={filteredRows}
                  filterTerm={filterTerm}
                  setFilterTerm={setFilterTerm}
                  otherDataRowMap={otherDataRowMap}
                  otherDates={otherDates}
                  setOtherDates={setOtherDates}
                  creativeMap={creativeMap}
                  percentAOIPlaceholder={percentAOIPlaceholder}
                />
              ) : (
                <PerformanceGridSkeleton />
              )
            ) : (
              <div className="notAllowed">This company does not support this media type.</div>
            )}
          </div>
          {saving && <FullPageSpinner transparent fixed />}
        </Page>
      </PerformanceContext.Provider>
    );
  }
);

export default StreamingPerformance;
