import React from "react";
import "./ModalCustomLegend.scss";

interface ModalCustomLegendProps {
  className?: string;
  graphView?: boolean;
}

const ModalCustomLegend: React.FC<ModalCustomLegendProps> = ({ className, graphView }) => {
  return (
    <div>
      {graphView && (
        <div className="modalLegend">
          <div className="modalLegendSection">
            <div className="modalLegendtitle">Actual Delivery</div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
              >
                <path
                  d="M2 2.5C6.68629 2.5 9.31371 2.5 14 2.5"
                  stroke="#2AE0F6"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
              <div className="modalLabel">Actual</div>
            </div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
              >
                <path
                  d="M2 2.5C6.68629 2.5 9.31371 2.5 14 2.5"
                  stroke="#2AE0F6"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeDasharray="1 4"
                />
              </svg>
              <div className="modalLabel">Projected</div>
            </div>
          </div>
          <div className="modalLegendSection">
            <div className="modalLegendtitle">Ideal Delivery</div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
              >
                <path
                  d="M2 2.5C6.68629 2.5 9.31371 2.5 14 2.5"
                  stroke="#7ED600"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
              <div className="modalLabel">Actual</div>
            </div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
              >
                <path
                  d="M2 2.5C6.68629 2.5 9.31371 2.5 14 2.5"
                  stroke="#7ED600"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeDasharray="1 4"
                />
              </svg>
              <div className="modalLabel">Projected</div>
            </div>
          </div>
          <div className="modalLegendSection">
            <div className="modalLegendtitle">Budget</div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
              >
                <path
                  d="M2 2.5C6.68629 2.5 9.31371 2.5 14 2.5"
                  stroke="#007F8E"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
              <div className="modalLabel">Budget</div>
            </div>
          </div>
        </div>
      )}
      {!graphView && (
        <div className="modalLegend">
          <div className="modalLegendSection">
            <div className="modalLegendtitle">Actual Delivery</div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
              >
                <g clipPath="url(#clip0_2400_19722)">
                  <path
                    d="M0 6.5C6.24839 6.5 9.75161 6.5 16 6.5"
                    stroke="#2AE0F7"
                    strokeWidth="12"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2400_19722">
                    <path
                      d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H15C15.5523 0.5 16 0.947715 16 1.5V11.5C16 12.0523 15.5523 12.5 15 12.5H1C0.447715 12.5 0 12.0523 0 11.5V1.5Z"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="modalLabel">Actual</div>
            </div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
              >
                <g clipPath="url(#clip0_2400_22984)">
                  <path
                    opacity="0.3"
                    d="M0 6.5C6.24839 6.5 9.75161 6.5 16 6.5"
                    stroke="#AEF3FC"
                    strokeWidth="12"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2400_22984">
                    <path
                      d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H15C15.5523 0.5 16 0.947715 16 1.5V11.5C16 12.0523 15.5523 12.5 15 12.5H1C0.447715 12.5 0 12.0523 0 11.5V1.5Z"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="modalLabel">Projected</div>
            </div>
          </div>
          <div className="modalLegendSection">
            <div className="modalLegendtitle">Ideal Delivery</div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
              >
                <g clipPath="url(#clip0_2400_19876)">
                  <path
                    d="M0 6.5C6.24839 6.5 9.75161 6.5 16 6.5"
                    stroke="#7ED600"
                    strokeWidth="12"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2400_19876">
                    <path
                      d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H15C15.5523 0.5 16 0.947715 16 1.5V11.5C16 12.0523 15.5523 12.5 15 12.5H1C0.447715 12.5 0 12.0523 0 11.5V1.5Z"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="modalLabel">Actual</div>
            </div>
            <div className="modalItems">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
              >
                <g clipPath="url(#clip0_2400_19882)">
                  <path
                    opacity="0.3"
                    d="M0 6.5C6.24839 6.5 9.75161 6.5 16 6.5"
                    stroke="#7ED600"
                    strokeWidth="12"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2400_19882">
                    <path
                      d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H15C15.5523 0.5 16 0.947715 16 1.5V11.5C16 12.0523 15.5523 12.5 15 12.5H1C0.447715 12.5 0 12.0523 0 11.5V1.5Z"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="modalLabel">Projected</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalCustomLegend;
