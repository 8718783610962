import React, { useCallback, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import { useSetError } from "../redux/modals";
import { awaitJSON, pollS3, StreamingPerformanceLambdaFetch } from "../utils/fetch-utils";
import { BPMButton, BPMDateRange, OldDropdown, KpiPicker, Spinner } from "../Components";
import { BETWEEN_OPTIONS } from "./StreamingPerformance/streamingPerformanceUtils";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { PerformanceContext } from "./performanceUtils";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

interface MatchedResponsesModalProps {
  start: string;
  end: string;
  onClose: () => void;
}

export const MatchedResponsesModal: React.FC<MatchedResponsesModalProps> = ({
  onClose,
  start,
  end,
}): JSX.Element => {
  const setError = useSetError();
  const { impsBetween, prefix, globalKpi: kpi } = useContext(PerformanceContext);
  const [ourImpsBetween, setOurImpsBetween] = useState(impsBetween);
  const [ourKpi, setOurKpi] = useState(kpi);
  const [ourRange, setOurRange] = useState({ start, end });
  const shouldEnableGraphPerformance = useExperimentFlag("enableGraphPerformance");

  const [status, setStatus] = useState<"" | "Requesting" | "Generating">("");
  const downloadFile = useCallback(async () => {
    try {
      setStatus("Requesting");
      let res = await StreamingPerformanceLambdaFetch<S.GetMatchedResponsesParams>(
        "/matched_responses",
        {
          params: {
            kpi: ourKpi,
            start: ourRange.start,
            end: ourRange.end,
            impsBetween: ourImpsBetween,
            prefix: prefix as S.Prefix,
            agency: "bpm", //default to BPM,
            isGraph: shouldEnableGraphPerformance === true,
          },
        }
      );
      let { id, bucket, ext } = await awaitJSON<S.GetMatchedResponsesResponse>(res);
      setStatus("Generating");
      await pollS3({
        bucket,
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ext,
        filename: id,
        autoDownload: true,
        overloadFilename: `matched_responses_${ourKpi}.xlsx`,
        withErrors: true,
        noTimeout: true,
      });
    } catch (e) {
      setError({
        reportError: e,
        message: e.message,
      });
    }
    setStatus("");
  }, [setError, ourKpi, ourRange, ourImpsBetween, prefix, shouldEnableGraphPerformance]);

  return (
    <Modal
      show
      size="lg"
      onHide={onClose}
      className="streamingPerformanceMatchedResponsesDownloadModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Download Matched Response</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <KpiPicker mediaType="streaming" onChange={setOurKpi} kpi={ourKpi} />
        </div>
        <div className="row">
          <BPMDateRange
            bordered
            range={ourRange}
            onChange={dates => setOurRange(dates)}
            isDayBlocked={date => date >= TODAY}
          />
          <OldDropdown
            bold
            size="sm"
            value={ourImpsBetween ? "Imp's Served Between" : "Response Between"}
            options={BETWEEN_OPTIONS}
            onChange={option => setOurImpsBetween(option === "Imp's Served Between")}
          />
        </div>
        <div className="blurb">
          <p>
            <span className="pleaseNote">Please note:</span> The number of records in this file will
            not match the volume figures you see in the Bliss Point UI. The records in this file are
            the "raw" matches made on resolvable identifiers; because some impressions are served
            to, and some traffic is received from, irresolvable identifiers, we apply a correction
            factor to statistically de-bias the final volume calculations. For additional details on
            this correction, please refer to{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://cdn.blisspointmedia.com/reference/BPM_Presentation_StreamingAttribution.pdf"
              }
            >
              Streaming Attribution with Sampling
            </a>
            . If you have further questions on this topic please reach out to your Tinuiti team.
          </p>
          <p>
            As a reminder, this file will omit all Disney/Hulu matches per our contractual
            agreements with the publisher.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="outline-primary" onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton className="downloadButton" disabled={!!status} onClick={downloadFile}>
          {status || "Download"}
          {!!status && <Spinner color="white" />}
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};
