import React, { useMemo } from "react";

import * as R from "ramda";

import * as Colors from "../utils/colors";

import "./CompetitiveChartBox.scss";
import { formatMoney, formatNumber } from "../utils/format-utils";
import InnerLineChartBox from "./InnerLineChartBox";

const getFormattedPercentLabel = num => {
  if (!Number.isFinite(num)) {
    return null;
  }

  let prefix = num > 0 ? "+" : "";
  let value = (100 * num).toFixed(0);
  let result = `${prefix}${value}%`;
  return result;
};

const getTrendColor = signedValueLabel => {
  if (!signedValueLabel || isNaN(signedValueLabel)) {
    return Colors.sameColor;
  }
  return signedValueLabel.startsWith("+")
    ? Colors.oceanGreen
    : signedValueLabel.startsWith("-")
    ? Colors.burntSienna
    : Colors.sameColor;
};

export const getPrevWeekTrend = (values: number[]): number | null => {
  let length = R.length(values);
  if (!length) {
    return null;
  }
  return length === 1 ? 1 : (values[length - 1] - values[length - 2]) / values[length - 2];
};

export const getAllWeeksTrend = (values: number[]): number | null => {
  let length = R.length(values);
  if (!length) {
    return null;
  }
  if (length === 1) {
    return 1;
  }
  let previousValues = R.slice(0, -1, values);
  let previousAverage = R.sum(previousValues) / previousValues.length;
  let mostRecent = values[values.length - 1];
  return (mostRecent - previousAverage) / previousAverage;
};

interface TransformedData {
  count: number;
}
interface ChartBoxParams {
  transformedData: TransformedData[];
  title: string;
}

export const CompetitiveChartBox: {} = (params: ChartBoxParams) => {
  let { transformedData, title } = params;
  const { prevWeekTrendLabel, allWeeksTrendLabel, prevWeeksCount, mostRecentWeekValue, isMoney } =
    useMemo(() => {
      if (!transformedData || !title) {
        return {};
      }
      let values = R.map(row => row.count, transformedData);
      return {
        isMoney: title.toLowerCase().includes("spend"),
        prevWeekTrendLabel: R.pipe(getPrevWeekTrend, getFormattedPercentLabel)(values),
        allWeeksTrendLabel: R.pipe(getAllWeeksTrend, getFormattedPercentLabel)(values),
        prevWeeksCount: values ? R.length(values) - 1 : null,
        mostRecentWeekValue: R.length(values) ? values[R.length(values) - 1] / 1 : null,
      };
    }, [transformedData, title]);
  if (transformedData && mostRecentWeekValue != null) {
    let chartColor = Colors.tacao;
    if (isMoney) {
      chartColor = Colors.fountainBlue;
    } else if (title.toLowerCase().includes("creative")) {
      chartColor = Colors.viola;
    }

    return (
      <>
        <div className="chart-inner-label-box">
          <div className="chart-header-box">
            <div className="chart-title-box" style={{ color: chartColor }}>
              {title}
            </div>
            <div className="last-week-value-box">
              Latest Week:{" "}
              {isMoney ? formatMoney(mostRecentWeekValue) : formatNumber(mostRecentWeekValue)}
            </div>
            <div className="trend-box">
              <div className="trend-box-prev-wtd">
                <div className="trend-header-wtd">∆ vs. Prev WTD:</div>
                <div className="trend-header-wtd">∆ vs. Prev {prevWeeksCount} WTD:</div>
              </div>
              <div className="trend-box-prev-wtd">
                <div
                  className="trend-value-wtd"
                  style={{ color: `${getTrendColor(prevWeekTrendLabel)}` }}
                >
                  {prevWeekTrendLabel || "-"}
                </div>
                <div
                  className="trend-value-wtd"
                  style={{ color: `${getTrendColor(allWeeksTrendLabel)}` }}
                >
                  {allWeeksTrendLabel || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chart-inner-full-box">
          <InnerLineChartBox
            chartData={transformedData}
            isSpend={title.toLowerCase().includes("spend")}
            strokeColor={[chartColor]}
            ref={undefined}
          />
        </div>
      </>
    );
  } else {
    return <div />;
  }
};

export default CompetitiveChartBox;
