import React from "react";
import { Wave } from "better-react-spinkit";
import cn from "classnames";

import "./Spinner.scss";

export const Spinner = React.memo<{
  size?: number;
  color?: string;
}>(props => (
  <div className="BPMSpinner">
    <Wave reverse {...props} />
  </div>
));

interface FullPageSpinnerProps {
  transparent?: boolean;
  fixed?: boolean;
}

export const FullPageSpinner: React.FC<FullPageSpinnerProps> = ({ transparent, fixed }) => (
  <div className={cn("bpmFullPageSpinner", { transparent, fixed })}>
    <Spinner size={100} />
  </div>
);
