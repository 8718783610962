import React, { useEffect, useState } from "react";
import "./StreamingMonitoring.scss";
import { BPMTable, Page, Skeleton, TableSkeleton } from "../Components";
import { useSetError } from "../redux/modals";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";

interface DataRow {
  company: string;
  derived_id: string;
  content: string;
  additional: string;
  created: string;
}

const StreamingMonitoring = (): JSX.Element => {
  const setError = useSetError();

  const headers = [
    {
      name: "company",
      label: "Company",
      flex: 1,
    },
    {
      name: "derived_id",
      label: "Derived Id",
      flex: 1,
    },
    {
      name: "Content",
      label: "Content",
      flex: 2,
    },
    {
      name: "additional",
      label: "Additional",
      flex: 2,
    },
    {
      name: "created",
      label: "Created",
      flex: 2,
    },
  ];
  const [data, setData] = useState<DataRow[]>();

  useEffect(() => {
    if (!data) {
      (async () => {
        try {
          let result = await StreamingV2LambdaFetch("/streaming_monitoring");
          const resData: DataRow[] = await awaitJSON(result);
          setData(resData);
        } catch (e) {
          setError({ message: e.message, reportError: e });
        }
      })();
    }
  }, [setError, data]);

  return (
    <Page title="Derived Id Monitoring" pageType="Derived Id Monitoring" minHeight="600px">
      <div className="streamingMonitoringPageContainer">
        {data ? (
          <BPMTable
            headers={headers}
            data={data}
            noRowsRenderer={() => <div>No rows to show.</div>}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
      </div>
    </Page>
  );
};

export default StreamingMonitoring;
