import "../IncrementalityPlaybook.scss";
import "./UserLevelCalculator.scss";
import { IncPlaybookSectionBox } from "../IncrementalityPlaybook";
import { typedReactMemo } from "../../utils/types";

const PAGE_TITLE = "User Level Calculator";

const createDefinition = (
  term: string,
  definition: string,
  recommendations?: string | JSX.Element
) => (
  <>
    <div className="basicText">
      <u>{term}:</u> {definition}
    </div>
    {recommendations && (
      <ul className="basicList">
        <li> {recommendations}</li>
      </ul>
    )}
  </>
);

const UserLevelCalculator = typedReactMemo<React.FC>(() => {
  return (
    <div className="incPlaybookPage">
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicText">
              This user level power calculator will provide sample size calculations for prospective
              incrementality tests and an estimate for the required spend to reach statistical
              significance.
            </div>
            <br />
            <div className="basicText">
              This calculator will take in inputted assumptions and will calculate a recommended
              spend based off of those assumptions. For the statistical parameters, it's advisable
              to stick with the default values as altering them can substantially impact the
              recommendations. Definitions for all the inputs and instructions for filling out the
              calculator are provided below.
            </div>
            <br />
            <div className="basicText">
              Most platforms offer ghost bidding which provides a no cost control group, so the
              calculator will assume 0 cost to the control group.
            </div>
            <br />
            <div className="basicText">
              When speccing out an incrementality test it is best to include safeguards against a
              test that fails to detect results. By default we include a safeguard by designing the
              test for significance level of 99%, when most clients will accept 90-95% significance
              levels as indicative of conclusive results. Secondly, we apply a high statistical
              power target, which reflects a higher probability of detecting smaller differences
              between the Treatment and Control groups when they are truly present -- this yields
              more reliable results and requires larger sample sizes.
            </div>
          </div>
        }
        pageTitle={PAGE_TITLE}
        title={"Overview"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="basicSection">
            <div className="basicHeader">Definitions</div>
            <br />
            {createDefinition(
              "Hypothesized Response Rate",
              "The anticipated number of responses the client will receive for every thousand impressions which can be calculated using the following equation: responses / (impressions/1000) . A response rate can be calculated for all KPIs but will be lower for lower funnel KPIs and thus making it harder to reach significance for lower funnel kpis.",
              "This response rate can be pulled from historical attribution data, how many attributed conversions do we get for each thousand impressions. This will allow the calculator to provide the most accurate recommendations."
            )}
            <br />
            {createDefinition(
              "Avg Frequency",
              "This represents how many impressions we expect users to receive on average during the test period, which will be used to back into required number of impressions needed for the test altogether. The best way to get this is to look at historical campaign or network data."
            )}
            <br />
            {createDefinition(
              "Incrementality Level",
              "There is some underlying/background response rate that would've happened whether or not we advertised or not. Since our hypothesized response rate is based off of historical attribution data, we want to account for how many of those users would have converted anyways and how many are incremental. This value should be between 0 and 1 to represent an incrementality percentage. For more info on incrementality, please visit: Incrementality 101."
            )}
            <br />
            {createDefinition(
              "CPM",
              "The base CPM (ex. fees) for the network(s) where the campaign is running"
            )}
            <br />
            {createDefinition(
              "Significance Level",
              "The chosen threshold that affects the likelihood of concluding that results are not due to chance. It determines the probability of making a Type I error, which is rejecting a true null hypothesis. It helps you decide how certain you want to be that the observed results are not random.",
              <>
                We highly recommend going with <b>the default significance level of 0.99.</b>
              </>
            )}
            <br />
            {createDefinition(
              "Power",
              "The probability of correctly concluding that an effect or difference exists when it truly does. In marketing, it helps determine how likely you are to detect a real impact from your advertising efforts. Higher power means a better chance of not missing important insights in your marketing data.",
              <>
                We highly recommend going with <b>the default power level of 0.8.</b>
              </>
            )}
            <br />
            {createDefinition(
              "Control Percent",
              "The percentage of impression that will be going towards the control group, PSAs or ghost bidding. By increasing the percentage allocated towards control (up to 50%), you can increase the power of the test/reduce the number of impressions needed to serve to reach statistical significance and thus reduce the overall cost of the test.",
              "Note that certain ghost bidding options and platforms limit control allocation to a specific percentage so we recommended double checking on required allocations by the platform before finalizing the test."
            )}
            <br />
            {createDefinition(
              "Cross-contamination Percentage",
              "In evaluating the data following the test, we need to exclude from the Control group anyone who did see a client advertisement, either due to test network leakage or due to running on other networks/platforms during the test. The cross-contamination percentage is an estimate of the percentage of the Control group that will need to be removed due to this leakage.",
              "As a default, we recommend using 30% as a base level here. This figure can be raised if the Client is running on a significant number of additional non-test networks."
            )}
          </div>
        }
        id={"filling-out-the-user-level-calculator"}
        title={"Filling Out the User Level Calculator"}
      />
      <IncPlaybookSectionBox
        content={
          <div className="userLevelCalculatorContainer">
            <iframe
              allowFullScreen
              className="userLevelCalculator"
              id="userLevelCalculator"
              name="userLevelCalculator"
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-storage-access-by-user-activation"
              src="https://bppm.shinyapps.io/user_level_power_calcs/"
              title="userLevelCalculator"
            />
          </div>
        }
        id={"user-level-calculator"}
        title={"User Level Calculator"}
      />
    </div>
  );
});

export default UserLevelCalculator;
