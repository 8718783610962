interface ShadedPatternProps {
  color: string;
  backgroundColor?: string;
  id?: string;
  visibleTag?: boolean;
}

export const ShadedPattern = ({
  color,
  backgroundColor = "transparent",
  id = color,
  visibleTag = true,
}: ShadedPatternProps): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <defs>
      <pattern id={`shadedStripe${id}`} width="10" height="10" patternUnits="userSpaceOnUse">
        <rect width="10" height="10" fill={backgroundColor} />
        <rect width="2" height="2" x="0" y="8" fill={color} />
        <rect width="2" height="2" x="2" y="6" fill={color} />
        <rect width="2" height="2" x="4" y="4" fill={color} />
        <rect width="2" height="2" x="6" y="2" fill={color} />
        <rect width="2" height="2" x="8" y="0" fill={color} />
      </pattern>
    </defs>
    {visibleTag && <rect width="100%" height="100%" fill={`url(#shadedStripe${id})`} />}
  </svg>
);
